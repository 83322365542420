import {ICache} from '@retrixhouse/salesapp-shared/lib/caching';
import {ICrudDataService, IDataService} from '../../interfaces/data-service';
import {
  BaseCrudDataService,
  BaseDataService,
  BaseReadonlyDataService,
  CachingOptions,
} from '../data';
import {
  BehaviorSubject,
  Observable,
  Subscription,
  from,
  of,
  throwError,
  using,
} from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  share,
  skip,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import {Inject} from '@angular/core';
import {Filter} from '@loopback/filter';
import {InputSelectOption} from '../../components';
import {sortByKey} from '../../globals';
import {isArray} from 'lodash';
import {arrayToMap} from '../../utils/utils';

export abstract class OldBaseStorageService<
  T extends {id?: string},
  ST,
  F = Filter<T>,
> {
  protected _dataStorage$: BehaviorSubject<DataStorage<ST>>;
  cachingOptions?: CachingOptions;

  data$ = using(
    () => this.fetchData().subscribe(),
    () => this.select(this.selectDataStorageValue),
  );
  dataFetching$: Observable<boolean>;
  dataSuccess$: Observable<boolean>;
  dataObsoleted$: Observable<boolean>;
  dataCommandInProgress$: Observable<boolean>;
  dataState$: Observable<StorageDataStateEnum>;

  dataAsSelectOptions$: Observable<InputSelectOption<T>[]> = this.data$.pipe(
    map(data => {
      if (isArray(data)) {
        return sortByKey(
          (data as any[]).map(this.entityToInputSelectOption),
          'name',
        );
      }

      return [];
    }),
  );

  dataById$: Observable<Map<string, T>> = this.data$.pipe(
    map(data => {
      // TODO(milan): change typing later
      return arrayToMap(data as any[], 'id');
    }),
  );

  protected _filter$: BehaviorSubject<F>;

  protected dataObsoletedListener: Subscription;
  protected filterChangedListener: Subscription;

  get dataStorage$(): Observable<DataStorage<ST>> {
    return this._dataStorage$.asObservable();
  }

  get dataState(): DataStorage<ST> {
    return this._dataStorage$.getValue();
  }

  get filter(): F {
    return this._filter$.getValue();
  }

  constructor(
    @Inject('')
    protected dataService:
      | BaseCrudDataService<AnyEntity, ICrudDataService<AnyEntity>, ICache>
      | BaseReadonlyDataService<AnyEntity, ICrudDataService<AnyEntity>, ICache>
      | BaseDataService<IDataService>,
    @Inject('') protected defaultStorageState?: DataStorage<ST>,
    @Inject('') protected defaultFilter?: F,
  ) {
    this._dataStorage$ = new BehaviorSubject(
      this.defaultStorageState || INITIAL_DEFAULT_STORAGE_STATE,
    );
    this._filter$ = new BehaviorSubject(this.defaultFilter || null);
    this.dataService = dataService;
    // set common selectors
    this.dataFetching$ = this.select(this.selectDataStorageDataFetching);
    this.dataSuccess$ = this.select(this.selectDataStorageDataSuccess);
    this.dataObsoleted$ = this.select(this.selectDataStorageDataObsoleted);
    this.dataCommandInProgress$ = this.select(this.selectDataCommandInProgress);
    this.dataState$ = this.select(storage => storage.state);

    // automatically fetch data when marked as obsoleted
    this.dataObsoletedListener = this.dataObsoleted$
      .pipe(
        filter(Boolean),
        switchMap(() => this.fetchData()),
      )
      .subscribe();

    this.filterChangedListener = this._filter$
      .pipe(
        skip(1),
        distinctUntilChanged(),
        tap(filter => {
          this.updateDataState(state => ({
            state: StorageDataStateEnum.Obsoleted,
          }));
        }),
      )
      .subscribe();
  }

  entityToInputSelectOption(entity: T & {name?: string}) {
    return {
      name: entity.name || 'This Entity does not have name property',
      value: entity.id,
      data: entity,
    };
  }

  setFilter(data: F) {
    this._filter$.next(data);
  }

  /**
   * fetchData method will always fetch data based on type used in extending service
   */
  fetchData(): Observable<ST> {
    return this.query({
      queryMethod$: this.getData.bind(this),
    });
  }

  // TODO(milan): fix filter typing later
  getData(input?: {filter?: F}): Observable<ST> {
    if (
      this.dataService instanceof BaseCrudDataService ||
      this.dataService instanceof BaseReadonlyDataService
    ) {
      return this.dataService?.getList(
        (input?.filter as Filter<any>) || this.filter,
        this.cachingOptions,
      ) as any;
    }
  }

  create(input: T) {
    if (this.dataService instanceof BaseCrudDataService) {
      return this.command({
        commandMethod$: this.dataService.create.bind(this.dataService),
        commandInput: input,
        commandType: 'create',
      });
    } else {
      throw new Error('There is no command available for readonly storage!');
    }
  }

  update<I = Partial<T>>(input: {id: string; data: I}) {
    if (this.dataService instanceof BaseCrudDataService) {
      return this.command({
        commandMethod$: this.updateData.bind(this),
        commandInput: input,
        commandType: 'update',
      });
    } else {
      throw new Error('There is no command available for readonly storage!');
    }
  }

  delete(input: {id: string; version?: number}) {
    if (this.dataService instanceof BaseCrudDataService) {
      return this.command({
        commandMethod$: this.deleteData.bind(this),
        commandInput: input,
        commandType: 'delete',
      });
    } else {
      throw new Error('There is no command available for readonly storage!');
    }
  }

  markAsObsoleted() {
    this.updateDataState(storage => ({
      state: StorageDataStateEnum.Obsoleted,
    }));
  }

  protected select<K>(
    selector: (storage: DataStorage<ST>) => K,
  ): Observable<K> {
    return this.dataStorage$.pipe(map(selector), distinctUntilChanged());
  }

  protected selectDataStorage(storage: DataStorage<ST>) {
    return storage;
  }

  protected selectDataStorageValue(storage: DataStorage<ST>) {
    return storage.value;
  }

  protected selectDataStorageDataFetching(storage: DataStorage<ST>) {
    return [StorageDataStateEnum.Idle, StorageDataStateEnum.Fetching].includes(
      storage.state,
    );
  }

  protected selectDataStorageDataSuccess(storage: DataStorage<ST>) {
    return storage.state === StorageDataStateEnum.Success;
  }

  protected selectDataStorageDataObsoleted(storage: DataStorage<ST>) {
    return storage.state === StorageDataStateEnum.Obsoleted;
  }

  protected selectDataCommandInProgress(storage: DataStorage<ST>) {
    return storage.state === StorageDataStateEnum.CommandInProgress;
  }

  protected updateDataState<
    K extends keyof DataStorage<ST>,
    E extends Partial<Pick<DataStorage<ST>, K>>,
  >(fn: (storage: DataStorage<ST>) => E): void {
    const updatedState = fn(this.dataState);
    this._dataStorage$.next({...this.dataState, ...updatedState});
  }

  updateDataStorageOnQueryDataStart() {
    this.updateDataState(storage => ({
      state: StorageDataStateEnum.Fetching,
    }));
  }

  updateDataStorageOnQueryDataSuccess(value: ST) {
    this.updateDataState(storage => ({
      value,
      state: StorageDataStateEnum.Success,
    }));
  }

  // TODO(milan): change type later
  updateDataStorageOnQueryDataError(error: any) {
    this.updateDataState(storage => ({
      error,
      state: StorageDataStateEnum.Error,
    }));
  }

  /**
   * Setting Idle state is happening only when there is api call canceled and state is stucked in fetching state
   */
  updateDataStorageOnQueryDataOnComplete() {
    this.updateDataState(storage => ({
      state:
        storage.state === StorageDataStateEnum.Fetching
          ? StorageDataStateEnum.Idle
          : storage.state,
    }));
  }

  // API CALL AND STORAGE UPDATING
  protected query<QI, R extends ST>(options: QueryDataOptions<QI, R>) {
    const {queryMethod$, queryMethodInput, forceQuery} = options;
    return of(null).pipe(
      withLatestFrom(this.dataStorage$),
      filter(([observable, state]) => {
        if (forceQuery) {
          return true;
        }

        return [
          StorageDataStateEnum.Idle,
          StorageDataStateEnum.Obsoleted,
        ].includes(state.state);
      }),
      switchMap(() => {
        this.updateDataStorageOnQueryDataStart();
        return from(queryMethod$(queryMethodInput)).pipe(
          tap(data => {
            this.updateDataStorageOnQueryDataSuccess(data);
          }),
          catchError(error => {
            this.updateDataStorageOnQueryDataError(error);
            return throwError(() => error);
          }),
          finalize(() => {
            this.updateDataStorageOnQueryDataOnComplete();
          }),
        );
      }),
      share(),
    );
  }

  protected command<I extends object, E, R>(options: CommandOptions<I, E, R>) {
    const {commandInput, commandMethod$, commandType} = options;
    this.updateDataStorageOnCommandStart();
    return this.doCommand(
      from(commandMethod$(commandInput)).pipe(
        tap(data => {
          this.updateDataStorageOnCommandSuccess<I, R>(
            data,
            commandInput,
            commandType,
          );
        }),
        catchError(error => {
          this.updateDataStorageOnCommandError(error);
          return throwError(error);
        }),
      ),
      options,
    );
  }

  private doCommand<I = unknown, E = unknown, R = unknown>(
    observable$: Observable<any>,
    options: DoCommandOptions<I, E, R>,
  ) {
    return doCommand(observable$.pipe(share()));
  }

  private deleteData(input: {id: string; version?: number}) {
    return (this.dataService as BaseCrudDataService<any, any>).delete(
      input.id,
      input.version,
    );
  }

  protected updateData<I = Partial<T>>(input: {id: string; data: I}) {
    return (this.dataService as BaseCrudDataService<any, any>).update(
      input.id,
      input.data,
    );
  }

  // commands storage methdos
  // data storage manipulation
  private updateDataStorageOnCommandStart() {
    this.updateDataState(storage => ({
      state: StorageDataStateEnum.CommandInProgress,
    }));
  }

  private updateDataStorageOnCommandSuccess<I, R>(
    value: R,
    input: I,
    commandType?: 'create' | 'update' | 'delete',
  ) {
    switch (commandType) {
      case 'create':
        this.updateDataState(storage => ({
          state: StorageDataStateEnum.Success,
          value: (Array.isArray(storage.value)
            ? [...storage.value, value]
            : value) as ST,
        }));
        break;
      case 'update':
        this.updateDataState(storage => ({
          state: StorageDataStateEnum.Success,
          value: (Array.isArray(storage.value)
            ? storage.value.map(obj => {
                if (obj.id === (value as any).id) {
                  return {
                    ...obj,
                    ...value,
                  };
                }
                return obj;
              })
            : {
                ...storage.value,
                ...input,
              }) as ST,
        }));
        break;
      case 'delete':
        this.updateDataState(storage => ({
          state: StorageDataStateEnum.Success,
          value: Array.isArray(storage.value)
            ? (storage.value.filter(obj => obj.id !== (input as any).id) as ST)
            : null,
        }));
        break;
      default:
        this.updateDataState(storage => ({
          state: StorageDataStateEnum.Obsoleted,
        }));
        break;
    }
  }

  private updateDataStorageOnCommandError(error: any) {
    this.updateDataState(storage => ({
      error,
      state: StorageDataStateEnum.Error,
    }));
  }

  private updateDataStorageOnCommandOnComplete() {
    this.updateDataState(storage => ({
      state:
        storage.state === StorageDataStateEnum.CommandInProgress
          ? StorageDataStateEnum.Idle
          : storage.state,
    }));
  }
}

interface AnyEntity {
  id: string;
}

export interface DataStorage<T, E = unknown> {
  state: StorageDataStateEnum;
  value: T;
  error: E;
}

export enum StorageDataStateEnum {
  Idle = 'Idle',
  Fetching = 'Fetching',
  CommandInProgress = 'CommandInProgress',
  Success = 'Success',
  Error = 'Error',
  Obsoleted = 'Obsoleted',
}

const INITIAL_DEFAULT_STORAGE_STATE: DataStorage<any, unknown> = {
  state: StorageDataStateEnum.Idle,
  value: [],
  error: null,
};

export interface QueryDataOptions<QI, R = unknown> {
  // cachingOptions: CachingOptions;
  forceQuery?: boolean;
  queryMethodInput?: QI;
  queryMethod$: (input?: QI) => Observable<R>;
}

export interface CommandOptions<CI = unknown, E = unknown, R = unknown> {
  commandInput: CI;
  commandMethod$: (input: CI) => Observable<R>;
  commandType?: 'create' | 'update' | 'delete';
}

type DoCommandOptions<I, E, R> = Omit<
  CommandOptions<I, E, R>,
  'commandMethod$'
>;

function doCommand<T>(action$: Observable<T>) {
  action$.subscribe();
  return action$;
}
