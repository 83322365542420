import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseCrudHttpService} from './base.http-service';
import {NewsPost, NewsPostWithUserInfo} from '../../models';
import {INewsPostDataService} from '../../interfaces/data-service';

@Injectable()
export class NewsPostHttpService
  extends BaseCrudHttpService<NewsPost>
  implements INewsPostDataService
{
  constructor(http: HttpClient) {
    super(http, '/sys/news-post');
  }

  public getActualNewsPosts(): Promise<NewsPostWithUserInfo[]> {
    return this.GET<NewsPostWithUserInfo[]>('/sys/news-post', {
      params: {
        actual: true,
      },
    });
  }
}
