import {DIALOG_DATA, DialogRef} from '@angular/cdk/dialog';
import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {DataProvider} from '@salesapp/data-provider';
import {DialogService} from '@salesapp/dialog';
import {generateRandomString} from '@salesapp/shared/globals';
import {CustomValidators} from '@salesapp/utils/reactive-form';
import {from} from 'rxjs';
import {filter, switchMap, take} from 'rxjs/operators';

@Component({
  selector: 'app-user-management-reset-password-dialog',
  templateUrl: './user-management-reset-password-dialog.component.html',
  styleUrls: ['./user-management-reset-password-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserManagementResetPasswordDialogComponent {
  form: FormGroup;

  constructor(
    private dialogRef: DialogRef<UserManagementResetPasswordDialogComponent>,
    @Inject(DIALOG_DATA) private data: UserManagementResetPasswordDialogData,
    private formBuilder: FormBuilder,
    private dataProvider: DataProvider,
    private dialogService: DialogService,
    private translateService: TranslateService,
  ) {}

  ngOnInit() {
    this.initForm();
  }

  getFormControl(name: 'password') {
    return this.form.get(name) as FormControl;
  }

  onGeneratePassword() {
    const generatedPassword = generateRandomString(
      PASSWORD_MIN_LENGTH,
      true,
      true,
    );
    this.form.controls.password.patchValue(generatedPassword);
  }

  onClose() {
    this.dialogRef.close();
  }

  onSave() {
    this.dialogService
      .warning({
        acceptLabel: this.translateService.instant('buttons.reset'),
        rejectLabel: this.translateService.instant('buttons.cancel'),
        title: this.translateService.instant(
          'entity.user-management.dialog.reset-password.warning.title',
        ),
        description: this.translateService.instant(
          'entity.user-management.dialog.reset-password.warning.description',
        ),
        showCloseButton: false,
      })
      .closed.pipe(
        filter(response => response === 'accepted'),
        switchMap(() => {
          return from(
            this.dataProvider.user.resetUserPassword(
              this.data.userId,
              this.form.getRawValue().password,
            ),
          );
        }),
      )
      .subscribe();
    this.dialogRef.close();
  }

  private initForm() {
    this.form = this.formBuilder.group({
      password: this.formBuilder.control(null, [
        CustomValidators.required,
        CustomValidators.minLength(PASSWORD_MIN_LENGTH),
      ]),
    });
  }
}

export interface UserManagementResetPasswordDialogData {
  userId: string;
}

const PASSWORD_MIN_LENGTH = 6;
