import {Injectable} from '@angular/core';
import {DataProvider} from '../data.provider/data-provider';
import {TourPlanState} from '@retrixhouse/salesapp-shared/lib/models';
import {SettingNames} from '@retrixhouse/salesapp-shared/lib/settings';

@Injectable({
  providedIn: 'root',
})
export class TourPlanColorService {
  private defaultColor = '#9e9e9e';
  private customColorMap: Map<TourPlanState, string>;

  constructor(private dataProvider: DataProvider) {
    this.initCustomColorMap();
  }

  private initCustomColorMap(): void {
    const settingKeys = Object.keys(TourPlanState).map(
      stateName => this.stateToSettingName(stateName) as SettingNames,
    );

    const colorSettingValues =
      this.dataProvider.settingResolver.getValues(settingKeys);
    this.customColorMap = new Map<TourPlanState, string>(
      Object.keys(TourPlanState).map(state => {
        const settingName = this.stateToSettingName(state);
        return [TourPlanState[state], colorSettingValues[settingName]];
      }),
    );
  }

  private stateToSettingName(state: string): string {
    return `tour-plan.status-color.${state.toLowerCase()}`;
  }

  getColourByState(state: TourPlanState): string {
    return this.customColorMap &&
      this.customColorMap.has(state) &&
      !!this.customColorMap.get(state)
      ? this.customColorMap.get(state)
      : this.defaultColor;
  }
}
