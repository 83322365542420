import {Injectable} from '@angular/core';
import {
  PersonalArrangement,
  PersonalArrangementReport,
  PersonalArrangementRequest,
} from '../../models';
import {IPersonalArrangementDataService} from '../../interfaces/data-service';
import {BaseCrudOfflineService} from './base.offline-service';

@Injectable()
export class PersonalArrangementOfflineService
  extends BaseCrudOfflineService<PersonalArrangement>
  implements IPersonalArrangementDataService
{
  public getReport(
    request: PersonalArrangementRequest,
  ): Promise<PersonalArrangementReport[]> {
    return Promise.reject('Offline service - not yet implemented');
  }
}
