<app-object-detail-dialog-form [availableForRoles]="availableForRoles">
  <div ngProjectAs="toolbar"
       class="toolbar">
    <dx-button *ngIf="canEdit"
               type="normal"
               [text]="'entity.store-periodic-limit.button.add-periodic-limit.label' | translate"
               (onClick)="onAddPeriodicLimit()"></dx-button>

  </div>
  <app-form ngProjectAs="content"
            [formGroup]="form"
            [formActions]="formActions"
            [actionInProgress]=""
            (action)="onFormAction()"
            (cancel)="onFormCancel()">
    <div class="container"
         *ngIf="dataById$ | async; let dataById">
      <div *ngIf="form"
           class="body"
           formArrayName="periodicLimits">
        <ng-container *ngIf="periodicLimits.controls.length; else noPeriodicLimits">
          <app-form-row class="header">
            <app-form-column>
              {{'entity.store-periodic-limit.properties.project-id.label' | translate}}
            </app-form-column>
            <app-form-column>
              {{'entity.store-periodic-limit.properties.periodic-limit-id.label' | translate}}
            </app-form-column>
            <div *ngIf="canEdit"
                 class="control-buttons"></div>
          </app-form-row>
          <app-form-row *ngFor="let periodicLimit of periodicLimits.controls; let i = index"
                        [class.added-row]="periodicLimit.disabled">
            <ng-container [formGroup]="periodicLimit">
              <ng-container *ngIf="periodicLimit.disabled; else controls">
                <app-form-column class="center">
                  <div class="project">
                    {{ dataById.projects.get(periodicLimit.controls.projectId.value)?.name }}
                  </div>
                </app-form-column>
                <app-form-column class="center">
                  <div class="periodicLimit">
                    {{ dataById.limits.get(periodicLimit.controls.periodicLimitId.value)?.name }}
                  </div>
                </app-form-column>
                <div class="control-buttons">
                  <dx-button *ngIf="canEdit"
                             stylingMode="text"
                             type="danger"
                             icon="fa fa-trash"
                             (onClick)="onDeletePeriodicLimit(i)"></dx-button>
                </div>
              </ng-container>

              <ng-template #controls>
                <app-form-column>
                  <app-input-select [formControl]="periodicLimit.controls.projectId"
                                    [formControlRef]="periodicLimit.get('projectId')"
                                    [label]="'entity.store-periodic-limit.properties.project-id.label' | translate"
                                    [options]="projectSelectOptions$ | async"></app-input-select>
                </app-form-column>
                <app-form-column>
                  <app-input-select [formControl]="periodicLimit.controls.periodicLimitId"
                                    [formControlRef]="periodicLimit.get('periodicLimitId')"
                                    [label]="'entity.store-periodic-limit.properties.periodic-limit-id.label' | translate"
                                    [options]="periodicLimitSelectOptions$ | async"></app-input-select>
                </app-form-column>
                <div *ngIf="canEdit"
                     class="control-buttons">
                  <dx-button stylingMode="text"
                             type="danger"
                             icon="fa fa-trash"
                             (onClick)="onDeletePeriodicLimit(i)"></dx-button>
                </div>

              </ng-template>
            </ng-container>
          </app-form-row>
        </ng-container>
        <ng-template #noPeriodicLimits>
          <span class="empty-message">{{'entity.store-periodic-limit.message.no-periodic-limits-for-this-store' | translate}}</span>
        </ng-template>
      </div>
    </div>
  </app-form>
  <app-form-actions ngProjectAs="footer"
                    #formActions
                    actionIcon="fa fa-save"
                    cancelIcon="fa fa-times"
                    [actionLabel]="'form.action.save' | translate"
                    [actionDisabled]="actionInProgress$ | async"
                    [actionVisible]="canEdit"
                    [cancelLabel]="'form.action.close' | translate"
                    [cancelDisabled]="actionInProgress$ | async"
                    [cancelVisible]="true"></app-form-actions>
</app-object-detail-dialog-form>