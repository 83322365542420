<app-object-detail [tabs]="tabs"
                   [activeTabId]="activeTabId$ | async"
                   (activeTabChanged)="onTabChange($event)">
  <ng-container *ngIf="!loading">
    <ng-container [ngSwitch]="activeTabId$ | async">
      <app-store-form *ngSwitchCase="'detail'"
                      #form
                      [store]="store"
                      (actionCompleted)="actionCompleted.emit()"
                      (cancel)="cancel.emit()"></app-store-form>
      <app-store-detail-periodic-limit *ngSwitchCase="'periodicLimits'"
                                       #form
                                       [store]="store"
                                       (actionCompleted)="actionCompleted.emit()"
                                       (cancel)="cancel.emit()"></app-store-detail-periodic-limit>
    </ng-container>
  </ng-container>
</app-object-detail>