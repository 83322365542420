import {DialogRef} from '@angular/cdk/dialog';
import {ChangeDetectionStrategy, Component} from '@angular/core';

@Component({
  selector: 'app-attachment-template-legend-dialog',
  templateUrl: './attachment-template-legend-dialog.component.html',
  styleUrls: ['./attachment-template-legend-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttachmentTemplateLegendDialogComponent {
  mode: 'list' | 'legend' = 'list';
  selectedContextOption: {name: string; path: string} | null = null;

  constructor(
    private dialogRef: DialogRef<AttachmentTemplateLegendDialogComponent>,
  ) {}

  ngOnInit() {}

  onClose() {
    this.dialogRef.close();
  }

  changeMode(mode: 'list' | 'legend', data?: {name: string; path: string}) {
    this.mode = mode;
    if (mode === 'list') {
      this.selectedContextOption = null;
    } else {
      this.selectedContextOption = data;
    }
  }
}
