import {Injectable} from '@angular/core';
import {Filter} from '@loopback/filter';
import {TranslateService} from '@ngx-translate/core';
import {IProduct} from '@retrixhouse/salesapp-shared/lib/models';
import {Observable, from} from 'rxjs';
import {DataProvider} from '../../data.provider/data-provider';
import {ProductDataService} from '../data';
import {
  OldBaseStorageService,
  StorageDataStateEnum,
} from './old-base-storage-service';

@Injectable()
export class ProductStorageService extends OldBaseStorageService<
  IProduct,
  IProduct[],
  IProductCustomFilter
> {
  constructor(
    public dataProvider: DataProvider,
    private translateService: TranslateService,
  ) {
    super(dataProvider.product);
  }

  // // NOTE: override method for storage service
  getData(input?: {filter?: IProductCustomFilter}): Observable<IProduct[]> {
    const filter = input?.filter || this.filter;
    if (filter) {
      return from(this.dataProvider.product.getList(filter)) as Observable<
        IProduct[]
      >;
    }

    return super.getData(input);
  }

  updatePicture(input: {id: string; version: number; pictureUrl: string}) {
    return this.command({
      commandMethod$: this.updatePictureMethod.bind(this),
      commandInput: input,
    });
  }

  deleteMultiple(input: {id: string; version?: number}[]) {
    // TODO(milan): change this code after discussion with Marek
    Promise.all(
      input.map(product => {
        return this.dataProvider.product.delete(product.id, product.version);
      }),
    )
      .then(response => {
        this.updateDataState(storage => ({
          state: StorageDataStateEnum.Obsoleted,
        }));
      })
      .catch(error => {
        throw new Error(
          this.translateService.instant('messages.error.deleting-data'),
        );
      });
  }

  private updatePictureMethod(input: {
    id: string;
    version: number;
    pictureUrl: string;
  }) {
    return (this.dataService as ProductDataService).updatePicture(
      input.id,
      input.version,
      input.pictureUrl,
    );
  }
}

interface IProductCustomFilter extends Filter<IProduct> {
  customerId?: string;
}
