<app-dialog [title]="'entity.attachment.dialog.generate.title' | translate"
            [actionVisible]="false"
            [cancelLabel]="'form.action.close' | translate"
            [cancelDisabled]="false"
            [cancelVisible]="true"
            [cancelIcon]=""
            [noPadding]="true"
            (cancelClicked)="onClose()">
    <app-attachment-templates-grid dialogContent
                                   [mode]="'generate'"
                                   [objectTypeId]="objectTypeId"
                                   [objectId]="objectId"></app-attachment-templates-grid>
</app-dialog>