import {Injectable} from '@angular/core';
import {DialogService} from '../../dialog/services/dialog.service';
import {TranslateService} from '@ngx-translate/core';
import {FeedbackStorageService} from './feedback-storage.service';
import {
  FeedbackDetailDialogComponent,
  FeedbackDetailDialogData,
} from '../components/feedback-detail-dialog/feedback-detail-dialog.component';
import {
  IFeedback,
  IFeedbackWithUserAndOpenedInfo,
} from '@retrixhouse/salesapp-shared/lib/models';

@Injectable()
export class FeedbackService {
  constructor(
    private translationService: TranslateService,
    private dialogService: DialogService,
    private storageService: FeedbackStorageService,
  ) {}

  openDetail(feedback: IFeedback) {
    this.dialogService.open<
      FeedbackDetailDialogComponent,
      FeedbackDetailDialogData
    >(FeedbackDetailDialogComponent, {
      data: {
        feedbackId: feedback.id,
      },
      width: '800px',
      height: '800px',
    });
  }

  resolveFeedback(data: IFeedbackWithUserAndOpenedInfo) {
    return this.confirmBeforeAction({
      title: this.translationService.instant(
        'entity.feedback.dialog.resolve.title',
      ),
      description: this.translationService.instant(
        'entity.feedback.dialog.resolve.description',
      ),
    }).subscribe({
      next: response => {
        if (response === 'accepted') {
          this.storageService.update({
            id: data.id,
            data: {
              feedback: {
                id: data.id,
                resolved: true,
                tourPlanId: data.tourPlanId,
              },
              visitId: data.tourPlanId,
            },
          });
        }
      },
    });
  }

  unresolveFeedback(data: IFeedbackWithUserAndOpenedInfo) {
    return this.confirmBeforeAction({
      title: this.translationService.instant(
        'entity.feedback.dialog.unresolve.title',
      ),
      description: this.translationService.instant(
        'entity.feedback.dialog.unresolve.description',
      ),
    }).subscribe({
      next: response => {
        if (response === 'accepted') {
          this.storageService.update({
            id: data.id,
            data: {
              feedback: {
                id: data.id,
                resolved: false,
                tourPlanId: data.tourPlanId,
              },
              visitId: data.tourPlanId,
            },
          });
        }
      },
    });
  }

  private confirmBeforeAction(input: {title: string; description: string}) {
    return this.dialogService.warning({
      acceptLabel: this.translationService.instant('buttons.confirm'),
      rejectLabel: this.translationService.instant('buttons.cancel'),
      showCloseButton: false,
      title: input.title,
      description: input.description,
    }).closed;
  }
}
