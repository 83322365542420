import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Language} from 'src/app/shared/models/language.model';
import {BaseReadonlyHttpService} from './base.http-service';
import {ILanguageDataService} from '../../interfaces/data-service';

@Injectable()
export class LanguageHttpService
  extends BaseReadonlyHttpService<Language>
  implements ILanguageDataService
{
  constructor(http: HttpClient) {
    super(http, '/core/languages');
  }

  public getEnabled(): Promise<Language[]> {
    return this.GET<Language[]>(`/core/languages/enabled`);
  }
}
