import {AnswerType, IProject} from '@retrixhouse/salesapp-shared/lib/models';
import {safeParseTimeOfDay} from '../../utils/utils';
import {Address} from '../address.model';
import {Chain} from '../chain.model';
import {Country} from '../country.model';
import {Customer} from '../customer.model';
import {GenericList, GenericListItem} from '../generic-list.model';
import {Position} from '../position.model';
import {ProductCategory} from '../product-category.model';
import {Product} from '../product.model';
import {Project, ProjectSetting} from '../project.model';
import {parseAnswer, QuestionnaireAnswer} from '../questionnaire-answer.model';
import {Questionnaire, QuestionnaireItem} from '../questionnaire-model';
import {QuestionnaireResult} from '../questionnaire-result.model';
import {OpeningHours, Store} from '../store.model';
import {TodoActionResult} from '../todo-action-result.model';
import {TodoAction} from '../todo-action.model';
import {TodoList, TodoListItem} from '../todo-list.model';
import {TourPlan} from '../tour-plan.model';
import {UserProfile} from '../user-profile.model';
import {User} from '../user.model';
import {PromoAction} from '../promo-action.model';
import {UsernameResponse} from '../username-response.model';
import {BillingInfo} from '../billing-info.model';

const DaysOfWeek = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

/**
 * Class wrapping time of a day.
 */
export class TimeOfDayWrapper {
  hour: number; // 0-23
  minute: number; // 0-59
  second: number; // 0-59

  constructor(str: string) {
    const t = safeParseTimeOfDay(str);
    this.hour = t?.h;
    this.minute = t?.m;
    this.second = t?.s;
  }
}

/**
 * Class wrapping date & time.
 */
export class DateTimeWrapper {
  year: number;
  month: number; // 1-12
  day: number; // 1-31
  hour: number; // 0-23
  minute: number; // 0-59
  second: number; // 0-59
  epoch: number; // number of seconds since epoch
  dayOfWeek: string; // day of week
  dayOfWeekNumber: number; // 1-7 day of week

  constructor(date: Date | string) {
    if (date == null) {
      return;
    }

    if (typeof date === 'string') {
      date = new Date(date);
    }
    this.year = date.getFullYear();
    this.month = date.getMonth() + 1;
    this.day = date.getDate();
    this.hour = date.getHours();
    this.minute = date.getMinutes();
    this.second = date.getSeconds();
    this.epoch = date.getTime();
    this.dayOfWeek = DaysOfWeek[date.getDay()];
    this.dayOfWeekNumber = date.getDay() + 1;
  }
}

/**
 * Base class for wrappers (contains id only).
 */
export abstract class WrapperBase {
  id: string;

  constructor(id: string) {
    this.id = id;
  }
}

/**
 * Base class for wrappers (id, uid, version).
 */
export abstract class WrapperUidVersionBase extends WrapperBase {
  uid: string;
  version: number;

  constructor(id: string, uid: string, version: number) {
    super(id);
    this.uid = uid;
    this.version = version;
  }
}

/**
 * Class wrapping a generic list item.
 */
export class GenericListItemWrapper extends WrapperBase {
  name: string;
  description?: string;
  position: number;
  value?: any;
  visibleFrom?: DateTimeWrapper;
  visibleTo?: DateTimeWrapper;
  visibleFlag?: boolean;
  list?: GenericListWrapper;

  constructor(item: GenericListItem, list?: GenericListWrapper) {
    super(item.id);
    this.name = item.name;
    this.description = item.description;
    this.position = item.position;
    this.value = item.value;
    this.visibleFlag = item.visibleFlag;
    this.list = list;

    if (item.visibleFrom) {
      this.visibleFrom = new DateTimeWrapper(new Date(item.visibleFrom));
    }

    if (item.visibleTo) {
      this.visibleTo = new DateTimeWrapper(new Date(item.visibleTo));
    }
  }
}

/**
 * Class wrapping a generic list.
 */
export class GenericListWrapper extends WrapperBase {
  name: string;
  description?: string;
  valueType?: string;
  items?: GenericListItemWrapper[];

  constructor(list: GenericList) {
    super(list.id);
    this.name = list.name;
    this.description = list.description;
    this.valueType = list.valueType;

    if (list.items) {
      this.items = list.items.map(
        item => new GenericListItemWrapper(item, this),
      );
    }
  }
}

/**
 * Class wrapping a country.
 */
export class CountryWrapper extends WrapperBase {
  name: string;
  officialName?: string;
  isoCode2: string;
  isoCode3: string;
  numericCode: string;
  ccTld?: string;

  constructor(country: Country) {
    super(country.id);
    this.name = country.name;
    this.officialName = country.officialName;
    this.isoCode2 = country.isoCode2;
    this.isoCode3 = country.isoCode3;
    this.numericCode = country.numericCode;
    this.ccTld = country.ccTld;
  }
}

/**
 * Class wrapping an address.
 */
export class AddressWrapper extends WrapperBase {
  line1?: string;
  line2?: string;
  zipCode?: string;
  city?: string;
  region?: string;
  state?: string;
  country?: CountryWrapper;

  constructor(address: Address) {
    super(address.id);
    this.line1 = address.line1;
    this.line2 = address.line2;
    this.zipCode = address.zipCode;
    this.city = address.city;
    this.region = address.region;
    this.state = address.state;
    if (address.country) {
      this.country = new CountryWrapper(address.country);
    }
  }
}

/**
 * Class wrapping a customer.
 */
export class CustomerWrapper extends WrapperUidVersionBase {
  name: string;
  companyId?: string;
  vatId?: string;
  active: boolean;
  logo?: string;
  web?: string;
  legalForm?: GenericListItemWrapper;
  address?: AddressWrapper;

  constructor(customer: Customer) {
    super(customer.id, customer.uid, customer.version);
    this.name = customer.name;
    this.companyId = customer.companyId;
    this.vatId = customer.vatId;

    if (customer.legalForm) {
      this.legalForm = new GenericListItemWrapper(customer.legalForm);
    }

    if (customer.address) {
      this.address = new AddressWrapper(customer.address);
    }
  }
}

/**
 * Class wrapping a product category.
 */
export class ProductCategoryWrapper extends WrapperUidVersionBase {
  name: string;
  textColor?: string;
  backgroundColor?: string;
  customer?: CustomerWrapper;
  parent?: ProductCategoryWrapper;
  subCategories?: ProductCategoryWrapper[];

  constructor(
    category: ProductCategory,
    parent?: ProductCategoryWrapper,
    subCategories?: ProductCategoryWrapper[],
  ) {
    super(category.id, category.uid, category.version);
    this.name = category.name;
    this.textColor = category.textColor;
    this.backgroundColor = category.backgroundColor;
    if (category.customer) {
      this.customer = new CustomerWrapper(category.customer);
    }
    this.parent = parent;
    this.subCategories = subCategories;
  }
}

/**
 * Class wrapping a product.
 */
export class ProductWrapper extends WrapperUidVersionBase {
  name: string;
  description?: string;
  category?: ProductCategoryWrapper;
  price?: number;
  currency?: string;
  picture?: string;
  unit?: string;
  eanCode?: string;
  customerCode?: string;
  unitsPerPackage?: number;
  productType?: GenericListItemWrapper;
  enabled: boolean;
  enabledSampling: boolean;
  enabledOrders: boolean;
  enabledPersonalArrangement: boolean;
  productPictureUrl?: string;
  customer?: CustomerWrapper;
  extendedProperties: any;

  constructor(product: Product, category?: ProductCategoryWrapper) {
    super(product.id, product.uid, product.version);
    this.name = product.name;
    this.description = product.description;
    this.category = category;
    this.price = product.price;
    this.currency = product.currency?.isoCode;
    this.picture = product.picture;
    this.unit = product?.unit?.abbreviation;
    this.eanCode = product.eanCode;
    this.customerCode = product.customerCode;
    this.unitsPerPackage = product.unitsPerPackage;
    this.enabled = product.enabled;
    this.enabledSampling = product.enabledSampling;
    this.enabledOrders = product.enabledOrders;
    this.enabledPersonalArrangement = product.enabledPersonalArrangement;
    this.productPictureUrl = product.productPictureUrl;
    this.extendedProperties = product.extendedProperties;
    if (product.customer) {
      this.customer = new CustomerWrapper(product.customer);
    }
    if (product.productType) {
      this.productType = new GenericListItemWrapper(product.productType);
    }
  }
}

/**
 * Class wrapping a chain.
 */
export class ChainWrapper extends WrapperUidVersionBase {
  name: string;
  companyId?: string;
  vatId?: string;
  logo?: string;
  web?: string;
  legalForm?: GenericListItemWrapper;
  address?: AddressWrapper;
  stores?: StoreWrapper[];

  constructor(chain: Chain) {
    super(chain.id, chain.uid, chain.version);
    this.name = chain.name;
    this.companyId = chain.companyId;
    this.vatId = chain.vatId;
    this.logo = chain.logo;
    this.web = chain.web;
    if (chain.legalForm) {
      this.legalForm = new GenericListItemWrapper(chain.legalForm);
    }
    if (chain.address) {
      this.address = new AddressWrapper(chain.address);
    }
    if (chain.stores) {
      this.stores = chain.stores.map(s => new StoreWrapper(s));
    }
  }
}

/**
 * Class wrapping single item of opening hours.
 */
export class OpeningHoursWrapper {
  nonstop: boolean;
  dayOfWeek: string;
  from?: TimeOfDayWrapper;
  to?: TimeOfDayWrapper;

  constructor(oh: OpeningHours) {
    this.nonstop = oh.nonstop;
    this.dayOfWeek = oh.dayOfWeek;
    if (oh.from) {
      this.from = new TimeOfDayWrapper(oh.from);
    }
    if (oh.to) {
      this.to = new TimeOfDayWrapper(oh.to);
    }
  }
}

/**
 * Class wrapping a store.
 */
export class StoreWrapper extends WrapperUidVersionBase {
  name: string;
  chain?: ChainWrapper;
  validFrom?: DateTimeWrapper;
  validTo?: DateTimeWrapper;
  chainSpecificId?: string;
  subChain?: GenericListItemWrapper;
  segment?: GenericListItemWrapper;
  agencyRegion?: GenericListItemWrapper;
  type?: GenericListItemWrapper;
  size?: GenericListItemWrapper;
  parkingLot: boolean;
  address?: AddressWrapper;
  latitude?: number;
  longitude?: number;
  altitude?: number;
  notes?: string;
  openingHours: OpeningHoursWrapper[];
  extendedProperties: any;

  constructor(store: Store) {
    super(store.id, store.uid, store.version);
    this.name = store.name;
    this.latitude = store.latitude;
    this.longitude = store.longitude;
    this.altitude = store.altitude;
    this.notes = store.notes;
    this.extendedProperties = store.extendedProperties;
    if (store.chain) {
      this.chain = new ChainWrapper(store.chain);
    }
    if (store.validFrom) {
      this.validFrom = new DateTimeWrapper(new Date(store.validFrom));
    }
    if (store.validTo) {
      this.validTo = new DateTimeWrapper(new Date(store.validTo));
    }
    this.chainSpecificId = store.chainSpecificId;
    if (store.subChain) {
      this.subChain = new GenericListItemWrapper(store.subChain);
    }
    if (store.segment) {
      this.segment = new GenericListItemWrapper(store.segment);
    }
    if (store.agencyRegion) {
      this.agencyRegion = new GenericListItemWrapper(store.agencyRegion);
    }
    if (store.type) {
      this.type = new GenericListItemWrapper(store.type);
    }
    if (store.size) {
      this.size = new GenericListItemWrapper(store.size);
    }
    this.parkingLot = store.parkingLot;
    if (store.address) {
      this.address = new AddressWrapper(store.address);
    }
    if (store.openingHours) {
      this.openingHours = store.openingHours.map(
        oh => new OpeningHoursWrapper(oh),
      );
    }
  }
}

/**
 * Class wrapping a position (tree of positions).
 */
export class PositionWrapper extends WrapperBase {
  name: string;
  abbreviation: string;
  parentId?: string;
  customer?: CustomerWrapper;
  superior?: PositionWrapper;
  subordinates?: PositionWrapper[];

  constructor(
    position: Position,
    customer?: CustomerWrapper,
    superior?: PositionWrapper,
    subordinates?: PositionWrapper[],
  ) {
    super(position.id);
    this.name = position.name;
    this.abbreviation = position.abbreviation;
    this.parentId = position.parentId;
    this.customer = customer;
    this.superior = superior;
    this.subordinates = subordinates;
  }
}

/**
 * Class wrapping user profile.
 */
export class UserProfileWrapper extends WrapperUidVersionBase {
  userId: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  titlesBefore?: string;
  titlesAfter?: string;
  gender?: string;
  picture?: string;
  language?: string;
  locale?: string;
  currency?: string;
  email?: string;
  phone?: string;
  birthDate?: DateTimeWrapper;
  personalId?: string;
  iban?: string;
  companyId?: string;
  vatId?: string;
  drivingLicense?: boolean;
  homeAddress?: AddressWrapper;
  correspondenceAddress?: AddressWrapper;
  extendedProperties: any;

  constructor(profile: UserProfile) {
    super(profile.id, profile.uid, profile.version);
    this.userId = profile.userId;
    this.firstName = profile.firstName;
    this.middleName = profile.middleName;
    this.lastName = profile.lastName;
    this.titlesBefore = profile.titlesBefore;
    this.titlesAfter = profile.titlesAfter;
    this.gender = profile.gender;
    this.picture = profile.picture;
    this.language = profile?.language?.isoCode1;
    this.locale = profile?.locale?.tag;
    this.currency = profile?.currency?.isoCode;
    this.email = profile.email;
    this.phone = profile.phone;
    this.extendedProperties = profile.extendedProperties;
    this.personalId = profile.personalId;
    this.iban = profile.iban;
    this.companyId = profile.extendedProperties?.companyId;
    this.vatId = profile.extendedProperties?.vatId;
    this.drivingLicense = profile.extendedProperties?.drivingLicense;
    if (profile.birthDate) {
      this.birthDate = new DateTimeWrapper(profile.birthDate);
    }

    if (profile.homeAddress) {
      this.homeAddress = new AddressWrapper(profile.homeAddress);
    }

    if (profile.correspondenceAddress) {
      this.correspondenceAddress = new AddressWrapper(
        profile.correspondenceAddress,
      );
    }
  }
}

/**
 * Class wrapping user object.
 */
export class UserWrapper extends WrapperUidVersionBase {
  username: string;
  internal: boolean;
  emailVerified: boolean;
  phoneVerified: boolean;
  accountBlocked: boolean;
  cooperationBanned: boolean;
  usesOwnCar: boolean;
  contractType?: GenericListItemWrapper;
  contractStatus?: GenericListItemWrapper;
  position?: PositionWrapper;
  customer?: CustomerWrapper;
  roles?: string[];
  profile?: UserProfileWrapper;
  extendedProperties: any;

  constructor(
    user: User,
    position?: PositionWrapper,
    profile?: UserProfileWrapper,
  ) {
    super(user.id, user.uid, user.version);
    this.username = user.username;
    this.internal = user.internal;
    this.emailVerified = user.emailVerified;
    this.phoneVerified = user.phoneVerified;
    this.cooperationBanned = user.extendedProperties?.cooperationBanned;
    this.usesOwnCar = user.extendedProperties?.usesOwnCar;
    this.position = position;
    this.roles = []; // TODO: user roles
    this.profile = profile;
    this.extendedProperties = user.extendedProperties;

    if (user.extendedProperties?.contractType) {
      this.contractType = new GenericListItemWrapper(
        user.extendedProperties.contractType,
      );
    }

    if (user.extendedProperties?.contractStatus) {
      this.contractStatus = new GenericListItemWrapper(
        user.extendedProperties.contractStatus,
      );
    }

    if (user.customer) {
      this.customer = new CustomerWrapper(user.customer);
    }
  }
}

/**
 * Class wrapping a question (tree of questions).
 */
export class QuestionWrapper extends WrapperUidVersionBase {
  text: string;
  answerType: AnswerType;
  position: number;
  required: boolean;
  measured: boolean;
  hint: string;
  number?: string;
  parentId?: string;
  parent?: QuestionWrapper;
  subQuestions?: QuestionWrapper[];

  photoCount?: number;
  openedAt?: DateTimeWrapper;
  timeSpent?: number;
  answer?: any;

  constructor(
    qi: QuestionnaireItem,
    answer?: QuestionnaireAnswer,
    parent?: QuestionWrapper,
    subQuestions?: QuestionWrapper[],
  ) {
    super(qi.id, qi.question.uid, qi.question.version);
    this.text = qi.question.text;
    this.answerType = qi.question.answerType;
    this.position = qi.position;
    this.required = qi.required;
    this.measured = qi.measured;
    this.hint = qi.hint;
    this.number = qi.questionNumber;
    this.parentId = qi.parentId;
    this.parent = parent;
    this.subQuestions = subQuestions;

    if (answer?.openedAt) {
      this.openedAt = new DateTimeWrapper(answer.openedAt);
    }
    this.timeSpent = answer?.timeSpent;
    this.answer = parseAnswer(answer?.answer, this.answerType);
  }
}

/**
 * Class wrapping a questionnaire.
 */
export class QuestionnaireWrapper extends WrapperUidVersionBase {
  name: string;
  description: string;
  presentationMode: string;
  fullScreen: boolean;
  theme: GenericListItemWrapper;
  mode?: string;
  startedAt?: DateTimeWrapper;
  finishedAt?: DateTimeWrapper;
  preloadResultId?: string;

  constructor(questionnaire: Questionnaire, result?: QuestionnaireResult) {
    super(questionnaire.id, questionnaire.uid, questionnaire.version);
    this.name = questionnaire.name;
    this.description = questionnaire.description;
    this.presentationMode = questionnaire.presentationMode;
    this.fullScreen = questionnaire.fullScreen;
    if (questionnaire.theme) {
      this.theme = new GenericListItemWrapper(questionnaire.theme);
    }
    this.mode = result?.mode;
    this.preloadResultId = result?.preloadResultId;
    if (result?.startedAt) {
      this.startedAt = new DateTimeWrapper(result.startedAt);
    }
    if (result?.finishedAt) {
      this.finishedAt = new DateTimeWrapper(result.finishedAt);
    }
  }
}

/**
 * Class wrapping TODO action.
 */
export class TodoActionWrapper extends WrapperUidVersionBase {
  text: string;
  description: string;
  position: number;
  measured: boolean;
  reasonList: GenericListItemWrapper[];
  parentId?: string;
  parent?: TodoActionWrapper;
  subActions?: TodoActionWrapper[];
  questionnaire?: QuestionnaireWrapper;

  openedAt?: DateTimeWrapper;
  timeSpent: number;
  completed: boolean;
  notes: string;
  reasonId?: string;
  reason?: GenericListItem;

  constructor(
    action: TodoAction,
    item: TodoListItem,
    reasonList: GenericListItem[],
    actionResult?: TodoActionResult,
    parent?: TodoActionWrapper,
    subActions?: TodoActionWrapper[],
    questionnaire?: QuestionnaireWrapper,
  ) {
    super(item.id, action.uid, action.version);
    this.text = action.text;
    this.description = action.description;
    this.position = item.position;
    this.measured = item.measured;
    if (reasonList) {
      this.reasonList = reasonList.map(r => new GenericListItemWrapper(r));
    }
    this.parent = parent;
    this.parentId = item.parentId;
    this.subActions = subActions;
    this.questionnaire = questionnaire;

    if (actionResult) {
      this.completed = actionResult.completed;
      this.openedAt = new DateTimeWrapper(new Date(actionResult.openedAt));
      this.timeSpent = actionResult.timeSpent;
      this.notes = actionResult.notes;
      this.reason = actionResult.reason;
    }
  }
}

/**
 * Class wrapping a TODO list.
 */
export class TodoListWrapper extends WrapperUidVersionBase {
  name: string;
  description: string;
  itemPresentation: string;
  actions: TodoActionWrapper[];

  constructor(list: TodoList, actions?: TodoActionWrapper[]) {
    super(list.id, list.uid, list.version);
    this.name = list.name;
    this.description = list.description;
    this.itemPresentation = list.itemPresentation;

    this.actions = actions;
  }
}

/**
 * Class wrapping a responsible user within a project.
 */
export class ResponsibleUserWrapper {
  position: PositionWrapper; // the position user has in this project
  user: UserWrapper; // user assigned to the project
  assignedAt: DateTimeWrapper; // the time when user was assigned to this project

  constructor(user: UserWrapper, assignedAt: DateTimeWrapper) {
    this.user = user;
    if (user) {
      this.position = user.position;
    }
    this.assignedAt = assignedAt;
  }
}

/**
 * Class wrapping a project.
 */
export class ProjectWrapper extends WrapperUidVersionBase {
  name: string;
  type?: GenericListItemWrapper;
  category?: GenericListItemWrapper;
  begin: DateTimeWrapper;
  end: DateTimeWrapper;
  customer: CustomerWrapper;
  todoList: TodoListWrapper;
  settings: any;
  responsibleUsers?: ResponsibleUserWrapper[];

  constructor(
    project: Project,
    settings: ProjectSetting[],
    responsibleUsers?: ResponsibleUserWrapper[],
  ) {
    super(project.id, project.uid, project.version);
    this.name = project.name;
    if (project.type) {
      this.type = new GenericListItemWrapper(project.type);
    }
    if (project.category) {
      this.category = new GenericListItemWrapper(project.category);
    }
    this.begin = new DateTimeWrapper(new Date(project.begin));
    this.end = new DateTimeWrapper(new Date(project.end));
    if (project.customer) {
      this.customer = new CustomerWrapper(project.customer);
    }
    if (project.todoList) {
      this.todoList = new TodoListWrapper(project.todoList);
    }
    // make project settings a simple key-value object
    this.settings = {};
    if (project.settings) {
      for (const value of project.settings) {
        const setting = settings.find(s => s.id === value.settingId);
        if (setting) {
          this.settings[setting.name] = value.value;
        }
      }
    }
    this.responsibleUsers = responsibleUsers;
  }
}

/**
 * Class wrapping a tour plan visit.
 */
export class VisitWrapper extends WrapperUidVersionBase {
  state: string;
  createdAt: DateTimeWrapper;
  scheduledStart: DateTimeWrapper;
  scheduledFinish?: DateTimeWrapper;
  startedAt?: DateTimeWrapper;
  finishedAt?: DateTimeWrapper;
  adHoc: boolean;
  differentFieldMarketers: boolean;
  notes: string;
  personalArrangement: boolean;
  substituted: boolean;
  executors: UserWrapper[];
  type: GenericListItemWrapper;

  constructor(visit: TourPlan, executors?: UserWrapper[]) {
    super(visit.id, visit.uid, visit.version);
    this.state = visit.state;
    this.createdAt = new DateTimeWrapper(new Date(visit.createdAt));
    this.scheduledStart = new DateTimeWrapper(new Date(visit.scheduledStart));
    if (visit.scheduledFinish) {
      this.scheduledFinish = new DateTimeWrapper(
        new Date(visit.scheduledFinish),
      );
    }
    if (visit.startedAt) {
      this.startedAt = new DateTimeWrapper(new Date(visit.startedAt));
    }
    if (visit.finishedAt) {
      this.finishedAt = new DateTimeWrapper(new Date(visit.finishedAt));
    }
    this.adHoc = visit.adHoc;
    this.differentFieldMarketers = visit.differentFieldMarketers;
    this.notes = visit.notes;
    this.personalArrangement = visit.personalArrangement;
    this.substituted = visit.substituted;
    if (visit.type) {
      this.type = new GenericListItemWrapper(visit.type);
    }

    this.executors = executors;
  }
}

/**
 * Class wrapping a promo action.
 */
export class PromoActionWrapper extends WrapperUidVersionBase {
  name: string;
  type: GenericListItemWrapper;
  active: boolean;
  hideFilledOut: boolean;
  mandatory: string;
  mandatoryDays?: number;
  validFrom: DateTimeWrapper;
  validTo: DateTimeWrapper;
  ordersFrom?: DateTimeWrapper;
  projectId: string;
  assignStoreExpression: string;
  notes: string;
  productCategoriesString: string;
  productCategories: ProductCategoryWrapper[];
  productsString: string;
  products: ProductWrapper[];

  constructor(
    promoAction: PromoAction,
    productCategories: ProductCategory[],
    products: Product[],
  ) {
    super(promoAction.id, promoAction.uid, promoAction.version);
    this.name = promoAction.name ?? '';
    if (promoAction.type) {
      this.type = new GenericListItemWrapper(promoAction.type);
    }
    this.active = promoAction.active;
    this.hideFilledOut = promoAction.hideFilledOut;
    this.mandatory = promoAction.mandatory;
    this.mandatoryDays = promoAction.mandatoryDays;
    this.validFrom = new DateTimeWrapper(promoAction.validFrom);
    this.validTo = new DateTimeWrapper(promoAction.validTo);
    if (promoAction.ordersFrom) {
      this.ordersFrom = new DateTimeWrapper(promoAction.ordersFrom);
    }
    this.projectId = promoAction.projectId;
    this.assignStoreExpression = promoAction.assignStoreExpression ?? '';
    this.notes = promoAction.notes ?? '';
    this.productCategoriesString = '';
    this.productCategories = [];
    this.productsString = '';
    this.products = [];

    //productsMap?: Map<string, ProductWrapper>,

    if (
      Array.isArray(promoAction.productCategories) &&
      promoAction.productCategories.length
    ) {
      const productCategoriesMap = new Map<string, ProductCategoryWrapper>(
        productCategories.map(pc => [pc.id, new ProductCategoryWrapper(pc)]),
      );
      promoAction.productCategories.forEach(c => {
        const pc = productCategoriesMap.get(c.productCategoryId);
        if (pc) {
          this.productCategories.push(pc);
          this.productCategoriesString += `${pc.uid},${pc.name}|`;
        }
      });
    }

    if (
      Array.isArray(promoAction.products) &&
      promoAction.products.length > 0
    ) {
      const productsMap = new Map<string, ProductWrapper>(
        products.map(p => [p.id, new ProductWrapper(p)]),
      );
      promoAction.products.forEach(p => {
        const prod = productsMap.get(p.productId);
        if (prod) {
          this.products.push(prod);
          this.productsString += `${prod.uid},${prod.name}|`;
        }
      });
    }
  }
}

/**
 * Class wrapping BillingInfoData
 */

export class BillingInfoWrapper extends WrapperBase {
  id: string;
  userId: string;
  projectId: string;
  month: number;
  year: number;
  hoursScheduled: number;
  hoursRealized: number;
  hoursConfirmedPerc: number;
  remunerationVisits: number;
  remunerationPersArrs: number;
  remunerationDrives: number;
  remunerationOthers: number;
  deductionUnrealized: number;
  deductionKpis: number;
  vacations: number;
  holidays: number;
  travelExpenses: number;
  total: number;
  userInfo?: UsernameResponse;
  project?: IProject;

  constructor(
    billingInfo: BillingInfo,
    user: UsernameResponse,
    project: IProject,
  ) {
    super(billingInfo.id);
    this.id = billingInfo.id;
    this.userId = billingInfo.userId;
    this.projectId = billingInfo.projectId;
    this.month = billingInfo.month;
    this.year = billingInfo.year;
    this.hoursScheduled = billingInfo.hoursScheduled;
    this.hoursRealized = billingInfo.hoursRealized;
    this.hoursConfirmedPerc = billingInfo.hoursConfirmedPerc;
    this.remunerationVisits = billingInfo.remunerationVisits;
    this.remunerationPersArrs = billingInfo.remunerationPersArrs;
    this.remunerationDrives = billingInfo.remunerationDrives;
    this.remunerationOthers = billingInfo.remunerationOthers;
    this.deductionUnrealized = billingInfo.deductionUnrealized;
    this.deductionKpis = billingInfo.deductionKpis;
    this.vacations = billingInfo.vacations;
    this.holidays = billingInfo.holidays;
    this.travelExpenses = billingInfo.travelExpenses;
    this.total = billingInfo.total;
    this.userInfo = user;
    this.project = project;
  }
}
