import {DialogRef} from '@angular/cdk/dialog';
import {ChangeDetectionStrategy, Component} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {IUser} from '@retrixhouse/salesapp-shared/lib/models';
import {DataProvider} from '@salesapp/data-provider';
import {generateRandomString} from '@salesapp/shared/globals';
import {CreateUserRequest} from '@salesapp/shared/models';
import {SharedFormService} from '@salesapp/shared/services/shared-form-builder.service';
import {
  CustomerStorageService,
  LocaleStorageService,
  PositionStorageService,
  UserProfileStorageService,
  UserStorageService,
} from '@salesapp/storage';
import {AutoUnsubscribe} from '@salesapp/utils/angular.utils';
import {CustomValidators, FormControlsOf} from '@salesapp/utils/reactive-form';
import {Observable, Subscription, combineLatest, of} from 'rxjs';
import {debounceTime, filter, map, switchMap, tap} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import {UserManagementService} from '../../services/user-management.service';

@Component({
  selector: 'app-user-management-create-user-dialog',
  templateUrl: './user-management-create-user-dialog.component.html',
  styleUrls: ['./user-management-create-user-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@AutoUnsubscribe()
export class UserManagementCreateUserDialogComponent {
  form: FormGroup<CreateUserFormGroup>;

  // loading$ = new BehaviorSubject<boolean>(true);
  positionOptions$ = this.positionStorageService.dataAsSelectOptions$;
  customerOptions$ = this.customerStorageService.dataAsSelectOptions$;
  disableActions$ = this.userStorageService.dataCommandInProgress$;
  usernameStatus$: Observable<'exists' | 'available' | null>;

  private createUserSubscription: Subscription;
  private localeSubscription: Subscription;

  constructor(
    private dialogRef: DialogRef<
      IUser,
      UserManagementCreateUserDialogComponent
    >,
    private sharedFormService: SharedFormService,
    private formBuilder: FormBuilder,
    private positionStorageService: PositionStorageService,
    private customerStorageService: CustomerStorageService,
    private userStorageService: UserStorageService,
    private dataProvider: DataProvider,
    private userProfileStorageService: UserProfileStorageService,
    private userMangementService: UserManagementService,
    private localestorageService: LocaleStorageService,
  ) {}

  ngOnInit() {
    this.initForm();
  }

  onClose() {
    this.dialogRef.close();
  }

  onSave() {
    const value = this.form.getRawValue();
    // TODO(milan): fix as any after new storage with generated api lib will be added
    this.createUserSubscription = this.userStorageService
      .create({
        ...value,
        username: value.username.trim().toLowerCase(),
      } as any)
      .pipe(
        tap(response => {
          this.userProfileStorageService.markAsObsoleted();
        }),
        switchMap(response => {
          return combineLatest([
            of(response),
            this.userProfileStorageService.dataFetching$,
          ]).pipe(
            filter(([response, userProfileDataFetching]) => {
              return !userProfileDataFetching;
            }),
          );
        }),
      )
      .subscribe({
        next: ([response, userProfileDataFetching]) => {
          this.dialogRef.close();
          this.userMangementService.openUserDetail(
            response.user.id,
            response.userProfile.id,
          );
        },
      });
    // this.dialogRef.close(formData as IUser);
  }

  onGeneratePassword() {
    const generatedPassword = generateRandomString(
      PASSWORD_MIN_LENGTH,
      true,
      true,
    );
    this.form.controls.otp.patchValue(generatedPassword);
  }

  getFormControl(name: keyof CreateUserRequest) {
    return this.form.get(name) as FormControl;
  }

  private initForm() {
    const form = this.formBuilder.group<CreateUserFormGroup>({
      username: this.formBuilder.control(null, [
        CustomValidators.required,
        CustomValidators.minLength(USERNAME_MIN_LENGTH),
        CustomValidators.maxLength(100),
      ]),
      otp: this.formBuilder.control(null, [
        CustomValidators.required,
        CustomValidators.minLength(PASSWORD_MIN_LENGTH),
      ]),
      positionId: this.formBuilder.control(null, [CustomValidators.required]),
      customerId: this.formBuilder.control(null),
      firstName: this.formBuilder.control(null),
      middleName: this.formBuilder.control(null),
      lastName: this.formBuilder.control(null),
      localeId: this.formBuilder.control(null),
    });

    this.form = form;

    this.usernameStatus$ = this.form.controls.username.valueChanges.pipe(
      debounceTime(600),
      switchMap(username => {
        if (username?.length >= USERNAME_MIN_LENGTH) {
          return this.dataProvider.user.existsUsername(username, {
            skipCache: true,
          });
        }
        return of(null);
      }),
      map(exists => {
        if (exists === null) {
          return null;
        }
        return (exists ? 'exists' : 'available') as
          | 'exists'
          | 'available'
          | null;
      }),
      tap(existsStatus => {
        if (existsStatus === 'exists') {
          this.form.controls.username.setErrors({usernameExists: true});
        } else {
          const errors = this.form.controls.username.errors;
          if (errors?.usernameExists) {
            delete errors.usernameExists;
          }
          this.form.controls.username.setErrors(errors);
        }
      }),
    );

    this.localeSubscription = this.localestorageService.data$.subscribe(
      locales => {
        const defaultLocale = locales.find(
          locale => locale.tag === environment.defaultLocale,
        );
        this.form.controls.localeId.patchValue(defaultLocale?.id);
      },
    );
  }
}

export interface UserManagementCreateUserDialogData {}

type CreateUserFormGroup = FormControlsOf<CreateUserRequest>;

const PASSWORD_MIN_LENGTH = 6;
const USERNAME_MIN_LENGTH = 6;
