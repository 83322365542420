import {Question, QuestionnaireAnswer, QuestionWrapper} from '../models';
import {QuestionnaireItem} from '../models/questionnaire-model';
import {TreeBase} from './tree-base';

/**
 * Helper class providing operations on the tree of questionnaire items.
 */
export class QuestionnaireItemTree extends TreeBase<QuestionnaireItem> {
  private _questionsMap: Map<string, Question>;

  /**
   * Constructor.
   * @param {QuestionnaireItem[]} items - array of questionnaire items
   * @param {Question[]} questions - array of questions to construct tree from
   */
  constructor(items: QuestionnaireItem[], questions: Question[]) {
    super(items);

    items = items.map(item => {
      item.questionNumber = this.getNodeNumberFromTree(item);
      return item;
    });

    this._questionsMap = new Map<string, Question>(
      questions.map(q => [q.id, q]),
    );
  }

  /**
   * Finds particular question by id.
   * @param {string} id - id of the question to find
   * @returns {Question | undefined} question or undefined if not found
   */
  public findQuestion(id: string): Question | undefined {
    return this._questionsMap.get(id);
  }

  /**
   * Finds particular questionnaire item by question id.
   * @param {string} questionId - id of the question to find questionnaire item by
   * @returns {QuestionnaireItem | undefined} questionnaire item or undefined if not found
   */
  public findItemByQuestionId(
    questionId: string,
  ): QuestionnaireItem | undefined {
    return this.allItems.find(fi => fi.question?.id === questionId);
  }

  /**
   * Finds question by questionnaire item id.
   * @param {string} id - id of the questionnaire item to find question for
   */
  public findQuestionByItem(id: string): Question | undefined {
    const item = this.findItem(id);
    if (item) {
      return this.findQuestion(item.questionId);
    }
  }

  /**
   * Creates a QuesitonItemId to QuestionWrapper Map.
   * @param {QuestionnaireAnswer[]} answerList - answer list of the actions.
   * @param {string} matrixItemId - the id of matrix item to get the answers for.
   * @returns {Map<string, QuestionWrapper>} QuesitonItemId to QuestionWrapper map.
   */
  createQuestionWrapper(
    answerList: QuestionnaireAnswer[],
    matrixItemId?: string,
  ): Map<string, QuestionWrapper> {
    const questionWrappersMap = new Map<string, QuestionWrapper>();
    this.allItems.forEach(item => {
      let answer;
      if (matrixItemId) {
        answer = answerList?.find(
          answer =>
            answer.questionnaireItemId === item.id &&
            (answer.productId === matrixItemId ||
              answer.freeTextId === matrixItemId),
        );
      } else {
        answer = answerList?.find(
          answer => answer.questionnaireItemId === item.id,
        );
      }

      questionWrappersMap.set(item.id, new QuestionWrapper(item, answer));
    });

    questionWrappersMap.forEach(questionWrapper => {
      const questionnaireItem = this.findItem(questionWrapper.id);

      const childWrappers = [...questionWrappersMap.values()].filter(
        wrapper => wrapper.parentId === questionnaireItem.id,
      );
      questionWrapper.subQuestions = childWrappers;

      if (!questionnaireItem.parentId) {
        questionWrapper.parent = null;
      } else {
        const parentItem = this.findItem(questionnaireItem.parentId);
        questionWrapper.parent = questionWrappersMap.get(parentItem.id);
      }
    });

    return questionWrappersMap;
  }
}
