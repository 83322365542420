export class Role {
  id: string;
  name: string;
  description?: string;
  scopeParamRequired?: boolean;
  composite?: boolean;
  composites?: Composites;
  clientRole?: boolean;
  containerId?: string;
  attributes?: {
    [index: string]: string[];
  };
  selected?: boolean;
}

export interface Composites {
  realm?: string[];
  client?: {
    [index: string]: string[];
  };
  application?: {
    [index: string]: string[];
  };
}
