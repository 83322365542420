import {RoleNames} from '@retrixhouse/salesapp-shared/lib/common';
import {Action} from '../actions/actions';

export class AttachmentTemplateActions {
  static ATTACHMENT_TEMPLATE_CREATE = new Action({
    id: 'attachmentTemplateCreate',
    icon: 'fa-solid fa-plus-circle',
    i18nIdentifier: 'entity.attachment-template.actions.create',
    permissions: {
      or: {
        roles: {
          any: [
            RoleNames.Admin,
            RoleNames.AttachmentTemplates,
            RoleNames.AttachmentTemplatesEdit,
          ],
        },
      },
    },
  });

  static ATTACHMENT_TEMPLATE_EDIT = new Action({
    id: 'attachmentTemplateEdit',
    icon: 'fa-solid fa-pen-to-square',
    i18nIdentifier: 'entity.attachment-template.actions.edit',
    permissions: {
      or: {
        roles: {
          any: [
            RoleNames.Admin,
            RoleNames.AttachmentTemplates,
            RoleNames.AttachmentTemplatesEdit,
          ],
        },
      },
    },
  });

  static ATTACHMENT_TEMPLATE_DELETE = new Action({
    id: 'attachmentTemplateDelete',
    icon: 'fa-solid fa-trash',
    i18nIdentifier: 'entity.attachment-template.actions.delete',
    permissions: {
      or: {
        roles: {
          any: [
            RoleNames.Admin,
            RoleNames.AttachmentTemplates,
            RoleNames.AttachmentTemplatesDelete,
          ],
        },
      },
    },
  });

  static ATTACHMENT_TEMPLATE_DOWNLOAD = new Action({
    id: 'attachmentTemplateDownload',
    icon: 'fa-solid fa-download',
    i18nIdentifier: 'entity.attachment-template.actions.download',
    permissions: {
      or: {
        roles: {
          any: [
            RoleNames.Admin,
            RoleNames.AttachmentTemplates,
            RoleNames.AttachmentTemplatesView,
          ],
        },
      },
    },
  });

  static ATTACHMENT_TEMPLATE_GENERATE_ATTACHMENT = new Action({
    id: 'attachmentTemplateGenerateAttachment',
    icon: 'fa-regular fa-file-contract',
    i18nIdentifier: 'entity.attachment-template.actions.generate-attachment',
    permissions: {
      or: {
        roles: {
          any: [
            RoleNames.Admin,
            RoleNames.AttachmentTemplates,
            RoleNames.AttachmentTemplatesGenerateAttachment,
          ],
        },
      },
    },
  });

  static ATTACHMENT_TEMPLATE_SHOW_LEGEND = new Action({
    id: 'attachmentTemplateShowLegend',
    icon: 'fa-regular fa-square-list',
    i18nIdentifier: 'entity.attachment-template.actions.show-legend',
    permissions: {
      or: {
        roles: {
          any: [
            RoleNames.Admin,
            RoleNames.AttachmentTemplates,
            RoleNames.AttachmentTemplatesGenerateAttachment,
          ],
        },
      },
    },
  });
}
