import {BaseModel} from './base.model';
import {GenericListItem} from './generic-list.model';
import {TourPlan} from './tour-plan.model';
import {UsernameResponse} from './username-response.model';

export class TourPlanChangeRequest extends BaseModel {
  uid: string;
  version: number;
  createdAt: Date;
  requestedById: string;
  userInfo: UsernameResponse;
  from: Date;
  to: Date;
  requestTypeId: string;
  requestType: GenericListItem;
  notes: string;
  tourPlans: TourPlan[];
  state: TourPlanChangeRequestState;
  assignedToId: string;
  assignedTo: UsernameResponse;

  constructor() {
    super();
    this.from = null;
    this.to = null;
    this.state = TourPlanChangeRequestState.Pending;
    this.requestTypeId = null;
    this.notes = null;
  }

  static getColorByState(state: TourPlanChangeRequestState): string {
    return tourPlanChangeRequestStateColorMap.get(state);
  }
}

export enum TourPlanChangeRequestState {
  Pending = 'Pending',
  Approved = 'Approved',
  Rejected = 'Rejected',
}

const tourPlanChangeRequestStateColorMap = new Map<
  TourPlanChangeRequestState,
  string
>([
  [TourPlanChangeRequestState.Pending, '#a1abab'],
  [TourPlanChangeRequestState.Rejected, '#ff0000'],
  [TourPlanChangeRequestState.Approved, '#93b952'],
]);
