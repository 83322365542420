import {Injectable} from '@angular/core';
import {OldBaseStorageService} from './old-base-storage-service';
import {DataProvider} from '../../data.provider/data-provider';
import {IUnit} from '@retrixhouse/salesapp-shared/lib/models';

@Injectable()
export class UnitStorageService extends OldBaseStorageService<IUnit, IUnit[]> {
  constructor(dataProvider: DataProvider) {
    super(dataProvider.unit);
  }
}
