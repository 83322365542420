import {
  ObjectMapCache,
  ObjectSingleCache,
} from '@retrixhouse/salesapp-shared/lib/caching';
import {IPositionDataService} from '../../interfaces/data-service';
import {Position, UserPositionInProject} from '../../models';
import {PositionHttpService} from '../http';
import {PositionOfflineService} from '../offline';
import {
  BaseCrudDataService,
  CachingOptions,
  TTL_DAY,
} from './base.data-service';

export class PositionDataService extends BaseCrudDataService<
  Position,
  IPositionDataService,
  ObjectMapCache<string, Position>
> {
  protected _cacheMyPosition: ObjectSingleCache<Position>;

  constructor(
    onlineService: PositionHttpService,
    offlineService: PositionOfflineService,
    cache: ObjectMapCache<string, Position>,
    cacheMyPosition: ObjectSingleCache<Position>,
  ) {
    super(onlineService, offlineService, cache);
    this._cacheMyPosition = cacheMyPosition;
  }

  private async initCacheMyPosition(
    cachingOptions?: CachingOptions,
  ): Promise<void> {
    if (cachingOptions?.forceReload || !this._cacheMyPosition.isValid) {
      const position = await this.service.getForUser();
      this._cacheMyPosition.init(position, cachingOptions?.ttl ?? TTL_DAY);
    }
  }

  public async getForCustomer(
    customerId?: string,
    cachingOptions?: CachingOptions,
  ): Promise<Position[]> {
    if (cachingOptions?.skipCache) {
      return this.service.getForCustomer(customerId);
    }

    if (cachingOptions?.forceReload || !this._cache.isValid) {
      const positions = await this.service.getList();
      this.initCache(positions, cachingOptions?.ttl ?? TTL_DAY);
    }
    return this._cache.getValues().filter(p => p.customerId === customerId);
  }

  public async getForUser(cachingOptions?: CachingOptions): Promise<Position> {
    if (cachingOptions?.skipCache) {
      return this.service.getForUser();
    }

    await this.initCacheMyPosition(cachingOptions);
    return this._cacheMyPosition.get();
  }

  public resetPositionStructure(customerId?: string): Promise<Position[]> {
    this._cache.invalidate();
    return this.service.resetPositionStructure(customerId);
  }

  public getUserPositionInProjectContext(
    userProjectPairs: {userId: string; projectId: string}[],
  ): Promise<UserPositionInProject[]> {
    return this.service.getUserPositionInProjectContext(userProjectPairs);
  }

  public getRoles(id: string): Promise<string[]> {
    return this.service.getRoles(id);
  }

  public setRoles(id: string, roles: string[]): Promise<void> {
    return this.service.setRoles(id, roles);
  }

  public async setMenuItems(id: string, itemIds: string[]): Promise<Position> {
    const position = await this.service.setPositionMenuItems(id, itemIds);
    this._cache.set(position.id, position);
    return position;
  }

  public async setRoutes(id: string, routeIds: string[]): Promise<Position> {
    const position = await this.service.setPositionRoutes(id, routeIds);
    this._cache.set(position.id, position);
    return position;
  }
}
