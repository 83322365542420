import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {RoleNames} from '@retrixhouse/salesapp-shared/lib/common';
import {PermissionResolverService} from '@salesapp/services';

@Component({
  selector: 'app-object-detail-dialog-form',
  templateUrl: './object-detail-dialog-form.component.html',
  styleUrls: ['./object-detail-dialog-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ObjectDetailDialogFormComponent {
  @Input() availableForRoles: RoleNames[];

  get visible() {
    if (this.availableForRoles?.length) {
      return this.permissionResolverService.hasRoleAny(this.availableForRoles);
    }
    return true;
  }

  constructor(private permissionResolverService: PermissionResolverService) {}
}
