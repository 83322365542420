<dx-action-sheet #actionSheet
                 [title]="'labels.photo-actions' | translate"
                 [items]="[
    {
      text: 'buttons.rotate-left' | translate,
      icon: 'fa-solid fa-rotate-left',
      command: 'rotate-left',
      disabled: !this.enableRotation || this.readOnly,
      width: '50%'
    },
    {
      text: 'buttons.rotate-right' | translate,
      icon: 'fa-solid fa-rotate-right',
      command: 'rotate-right',
      disabled: !this.enableRotation || this.readOnly,
      width: '50%'
    },
    {
      text: 'buttons.comments' | translate,
      icon: 'fa-solid fa-comment-pen',
      type: 'default',
      stylingMode: 'contained',
      command: 'comments',
      disabled: !this.enableComments
    },
    {
      text: 'buttons.tags' | translate,
      icon: 'fa-solid fa-tags',
      type: 'default',
      stylingMode: 'contained',
      command: 'tags',
      disabled: !this.enableTags
    },
    {
      text: 'buttons.delete' | translate,
      icon: 'fa-solid fa-trash',
      type: 'danger',
      stylingMode: 'contained',
      command: 'delete',
      disabled: !this.enableDelete || this.readOnly
    }
  ]"
                 [visible]="false"
                 [showTitle]="true"
                 [showCancelButton]="true"
                 [cancelText]="'buttons.cancel' | translate"
                 (onCancelClick)="actionCancelClick($event)"
                 (onItemClick)="actionItemClick($event.itemData.command)"
                 [width]="isLargeScreen ? '40%' : '100%'">
</dx-action-sheet>

<dx-popup #cmntTagsPopup
          [showTitle]="true"
          [dragEnabled]="false"
          [showCloseButton]="false"
          container=".dx-viewport"
          [fullScreen]="!isLargeScreen"
          (onHidden)="onCmntTagsPopupHidden($event)"
          [contentTemplate]="popupContent">
  <dxi-toolbar-item *ngIf="popupContent === 'commentsContent'"
                    [text]="'labels.comments' | translate"
                    location="before"> </dxi-toolbar-item>
  <dxi-toolbar-item *ngIf="popupContent === 'tagsContent'"
                    [text]="'labels.tags' | translate"
                    location="before"> </dxi-toolbar-item>
  <dxi-toolbar-item widget="dxButton"
                    location="after"
                    [options]="{
      text: 'buttons.ok' | translate | uppercase,
      onClick: hidePopup
    }">
  </dxi-toolbar-item>

  <div *dxTemplate="let data of 'tagsContent'">
    <dx-scroll-view width="100%"
                    height="100%">
      <app-tag [disablePhotoTagsValidation]="validation.disablePhotoTagsValidation"
               [tags]="tags"
               [tagProperties]="tagProperties"
               [readOnly]="!enableTags || readOnly"
               [selectedTags]="_photoSelection?._selectedTagIds"
               (onSelectionChanged)="tagsChanged($event)"></app-tag>
    </dx-scroll-view>
  </div>

  <div *dxTemplate="let data of 'commentsContent'">
    <dx-scroll-view width="100%"
                    height="100%">
      <app-comment [readOnly]="!enableComments || readOnly"
                   (onCommentDeleted)="commentDeleted($event)"
                   (onCommentAdded)="commentAdded($event)"
                   [objectId]="_photoSelection?._selectedPhoto?.id"
                   [commentList]="_photoSelection?._selectedPhoto?.commentList"></app-comment>
    </dx-scroll-view>
  </div>
</dx-popup>

<!-- START: this component is used to show the component in a popup and allow the pinch in/out -->
<app-img-popup [imgTemplate]="imgTemplate"
               [imageUrl]="_photoSelection?._selectedPhoto?.imageUrl"
               [(visible)]="_photoSelection._photoPopupVisible"
               (onImageLoaded)="onImageLoaded($event, _photoSelection?._selectedPhoto?.id)"
               (onImageLoadError)="onImgLoadError($event, _photoSelection?._selectedPhoto?.id, _photoSelection?._selectedPhoto?.imageUrl)"
               (onShown)="onFullScreenPopupShown($event)">
  <i [id]="actionIconId"
     (click)="selectPhotoClicked(_photoSelection?._selectedPhoto)"
     class="fa-solid fa-circle-ellipsis-vertical"></i>
</app-img-popup>
<!-- START: this component is used to show the component in a popup and allow the pinch in/out -->

<div id="images-header-container">
  <div>{{ 'labels.photos' | translate }} ({{ _photoSelection?._photosCopy?.length }})</div>
  <div [ngClass]="{'images-container-lrg': isLargeScreen, 'images-container-sml': !isLargeScreen}">
    <div *ngFor="let photo of _photoSelection?._photosCopy"
         class="img-container"
         [ngClass]="{'img-error': _photoSelection._invalidTagPhotos?.ids?.has(photo.id)}">
      <div [ngClass]="{'action-icons-container-lrg': isLargeScreen, 'action-icons-container-sml': !isLargeScreen}">
        <i (click)="photoZoomClicked(photo)"
           class="fa-solid fa-magnifying-glass"></i>

        <i [id]="actionIconId"
           (click)="selectPhotoClicked(photo)"
           class="fa-solid fa-circle-ellipsis-vertical"></i>

        <i *ngIf="enableTags"
           (click)="showPopup(photo, 'tags')"
           class="fa-solid fa-tag"
           [ngClass]="{'cmnt-tags-active': photo?.photoTagList?.length > 0}"></i>

        <i *ngIf="enableComments"
           (click)="showPopup(photo, 'comments')"
           class="fa-solid fa-comment"
           [ngClass]="{'cmnt-tags-active': photo?.commentList?.length > 0}"></i>
      </div>

      <img [id]="photo.id + '-img'"
           longPress
           (click)="photoZoomClicked(photo)"
           (mouseLongPress)="selectPhotoClicked(photo)"
           [src]="(photo?.imageThumbnailUrl ?? photo?.imageUrl) + '?t=' + timestamp"
           [ngStyle]="{height: imagesSize?.large ?? '100%', width: imagesSize?.large ?? '100%'}"
           style="object-fit: cover; user-select: none; -webkit-user-select: none; -webkit-user-drag: none; cursor: pointer"
           (error)="onImgLoadError($event, photo?.id, photo?.imageUrl)"
           (load)="onImageLoaded($event, photo?.id)" />
      <dx-load-indicator *ngIf="(rotateInProgress$ | async)[photo.id]"
                         [class]="'photo-loading-indicator'"></dx-load-indicator>
    </div>
  </div>
  <div style="text-align: center; border-bottom: solid 1px #888">
    <ng-container *visibleIf="{platform: ['app']}">
      <dx-button *ngIf="enableCamera && _photoSelection._photosCopy.length < maxPhotos && !readOnly"
                 stylingMode="text"
                 icon="fa-solid fa-circle-camera"
                 [text]="'buttons.camera' | translate"
                 (onClick)="capturePhoto()"></dx-button>
      <dx-button *ngIf="allowPhotoGallery && !readOnly && enableGallery && _photoSelection._photosCopy.length < maxPhotos"
                 stylingMode="text"
                 icon="fa-solid fa-images"
                 [text]="'buttons.gallery' | translate"
                 (onClick)="selectFromGallery()"></dx-button>
    </ng-container>
    <ng-container *visibleIf="{platform: ['browser']}">
      <dx-button [disabled]="uploadInProgress$ | async"
                 class="custom-button"
                 *ngIf="!readOnly && enableGallery && enableCamera && _photoSelection._photosCopy.length < maxPhotos"
                 icon="fa-solid fa-images"
                 [text]="'buttons.add-photo' | translate">
        <ng-container *ngIf="uploadInProgress$ | async; else uploadFromBrowserButtonLabel">
          <div class="button-loading"></div>
        </ng-container>
        <ng-template #uploadFromBrowserButtonLabel>
          {{ 'buttons.add-photo' | translate }}
        </ng-template>
        <input class="hidden-file-input"
               type="file"
               accept="image/jpg,jpeg"
               [value]="null"
               (change)="onBroswerUploadPicture($event)" />
      </dx-button>
    </ng-container>
  </div>
</div>
<ng-container *ngIf="messageList$ | async; let messageList">
  <dx-list *ngIf="messageList.length"
           [items]="messageList"
           [allowItemDeleting]="true"
           [itemDeleteMode]="'static'"
           (onItemDeleted)="onItemDeleted($event)"></dx-list>
</ng-container>

<dx-validator [elementAttr]="{id: elementAttr?.id + '-validator', editorName: 'dxPhotoGallery'}"
              [validationGroup]="validation.validationGroup"
              [adapter]="adapterConfig"
              [name]="validation.name">
  <dxi-validation-rule type="custom"
                       [validationCallback]="validateMinPhotos"
                       [message]="'messages.validation.min-photos' | translate : {minPhotos: minPhotos}"></dxi-validation-rule>
  <ng-container *ngFor="let customValidationCB of validation?.customValidationCBs">
    <dxi-validation-rule type="custom"
                         [validationCallback]="customValidationCB"
                         [reevaluate]="true"></dxi-validation-rule>
  </ng-container>
</dx-validator>

<dx-validator [elementAttr]="{id: elementAttr?.id + '-tags-validator', editorName: 'dxPhotoGallery'}"
              [validationGroup]="validation.validationGroup"
              [adapter]="tagsValidatorAdapter"
              [name]="validation.name">
  <dxi-validation-rule type="custom"
                       [validationCallback]="validateTags"
                       [reevaluate]="true"
                       [message]="'messages.validation.tags' | translate"></dxi-validation-rule>
</dx-validator>

<!-- START: The gallery is used to display all available images and navigate between them -->
<ng-template #imgTemplate>
  <dx-gallery #gallery
              id="custom-gallery"
              height="100%"
              width="100%"
              [dataSource]="_photoSelection._photosCopy"
              [loop]="false"
              [showNavButtons]="true"
              [showIndicator]="false"
              [selectedIndex]="_photoSelection._selectedPhotoIndex"
              (onSelectionChanged)="onPhotoGalleryChanged($event)"
              (keydown.escape)="onGalleryEscapeBtn()">
    <div *dxTemplate="let photo of 'item'">
      <pinch-zoom style="height: 100%">
        <img [src]="photo?.imageUrl + '?t=' + timestamp" />
        <dx-load-indicator *ngIf="(rotateInProgress$ | async)[photo.id]"
                           [class]="'photo-loading-indicator-gallery'"></dx-load-indicator>
      </pinch-zoom>
    </div>
  </dx-gallery>
</ng-template>
<!-- END: The gallery is used to display all available images and navigate between them -->

<!-- START: The context menu is displayed only on large screens -->
<dx-context-menu *ngIf="isLargeScreen"
                 showEvent="dxcontextmenu click"
                 [dataSource]="[
    {
      text: 'buttons.rotate-left' | translate,
      icon: 'fa-solid fa-rotate-left',
      command: 'rotate-left',
      disabled: !this.enableRotation || this.readOnly,
      width: '50%'
    },
    {
      text: 'buttons.rotate-right' | translate,
      icon: 'fa-solid fa-rotate-right',
      command: 'rotate-right',
      disabled: !this.enableRotation || this.readOnly,
      width: '50%'
    },
    {
      text: 'buttons.comments' | translate,
      icon: 'fa-solid fa-comment-pen',
      type: 'default',
      stylingMode: 'contained',
      command: 'comments',
      disabled: !this.enableComments
    },
    {
      text: 'buttons.tags' | translate,
      icon: 'fa-solid fa-tags',
      type: 'default',
      stylingMode: 'contained',
      command: 'tags',
      disabled: !this.enableTags
    },
    {
      text: 'buttons.delete' | translate,
      icon: 'fa-solid fa-trash',
      type: 'danger',
      stylingMode: 'contained',
      command: 'delete',
      disabled: !this.enableDelete || this.readOnly
    }
  ]"
                 [width]="200"
                 [target]="'#' + actionIconId"
                 (onItemClick)="showImageRelationPopup($event)">
</dx-context-menu>
<!-- START: The context menu is displayed only on large screens -->