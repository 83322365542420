import {Injectable} from '@angular/core';
import {BaseHttpService} from './base.http-service';
import {HttpClient} from '@angular/common/http';
import {RequestLog} from 'src/app/shared/models';
import {DEFAULT_LIMIT} from '../../globals';
import {IRequestLogDataService} from '../../interfaces/data-service';

@Injectable()
export class RequestLogHttpService
  extends BaseHttpService
  implements IRequestLogDataService
{
  constructor(http: HttpClient) {
    super(http);
  }

  public getLogs(searchOptions: {
    userIds?: string[];
    from?: Date;
    to?: Date;
    fullText?: string;
    limit?: number;
  }): Promise<RequestLog[]> {
    const filter = {
      order: 'time desc',
      where: undefined,
      limit: searchOptions.limit,
    };

    if (!filter.limit) {
      filter.limit = DEFAULT_LIMIT;
    }

    if (Object.keys(searchOptions).length) {
      filter.where = {and: []};

      if (searchOptions.fullText) {
        filter.where.and.push({
          or: [
            {hostname: {like: `%${searchOptions.fullText}%`}},
            {ip: {like: `%${searchOptions.fullText}%`}},
            {url: {like: `%${searchOptions.fullText}%`}},
            {method: {like: `%${searchOptions.fullText}%`}},
            {target: {like: `%${searchOptions.fullText}%`}},
            {error: {like: `%${searchOptions.fullText}%`}},
          ],
        });
      }

      if (searchOptions.userIds?.length) {
        filter.where.and.push({userId: {inq: searchOptions.userIds}});
      }

      if (searchOptions.from) {
        filter.where.and.push({time: {gte: searchOptions.from}});
      }

      if (searchOptions.to) {
        filter.where.and.push({time: {lte: searchOptions.to}});
      }
    }

    return this.GET<RequestLog[]>(
      `/sys/request-logs?filter=${encodeURI(JSON.stringify(filter))}`,
    );
  }
}
