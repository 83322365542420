import {ObjectMapCache} from '@retrixhouse/salesapp-shared/lib/caching';
import {IProductCategoryDataService} from '../../interfaces/data-service';
import {
  Product,
  ProductCategory,
  ProductCategoryPositionUpdate,
} from '../../models';
import {ProductCategoryHttpService} from '../http';
import {ProductCategoryOfflineService} from '../offline';
import {
  BaseCrudDataService,
  CachingOptions,
  TTL_DAY,
} from './base.data-service';

export class ProductCategoryDataService extends BaseCrudDataService<
  ProductCategory,
  IProductCategoryDataService,
  ObjectMapCache<string, ProductCategory>
> {
  constructor(
    onlineService: ProductCategoryHttpService,
    offlineService: ProductCategoryOfflineService,
    cache: ObjectMapCache<string, ProductCategory>,
  ) {
    super(onlineService, offlineService, cache);
  }

  public async getForCustomer(
    customerId: string,
    cachingOptions?: CachingOptions,
  ): Promise<ProductCategory[]> {
    if (cachingOptions?.skipCache) {
      return this.service.getForCustomer(customerId);
    }

    if (cachingOptions?.forceReload || !this._cache.isValid) {
      const productCategories = await this.service.getList();
      this.initCache(productCategories, cachingOptions?.ttl ?? TTL_DAY);
    }

    return this._cache.getValues().filter(pc => pc.customerId === customerId);
  }

  public getProductsInCategory(categoryId: string): Promise<Product[]> {
    return this.service.getProductsInCategory(categoryId);
  }

  public checkIfCategoryCanBeDeleted(categoryId: string): Promise<number> {
    return this.service.checkIfCategoryCanBeDeleted(categoryId);
  }

  public getProccesProgress(id: string) {
    return this.service.getProccesProgress(id);
  }

  public async updatePosition(pcPositionList: ProductCategoryPositionUpdate[]): Promise<void> {
    await this.service.updateProductCategoryPosition(pcPositionList);
    if (this._cache.isValid) {
      // if cache is valid, update positions in cache
      pcPositionList.forEach(pc => {
        const productCategory = this._cache.get(pc.id);
        if (productCategory) {
          productCategory.position = pc.position;
        }
      });
    } else {
      console.warn('Product category cache is not valid, skipping update');
    }
  }
}
