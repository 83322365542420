import {AutoUnsubscribe} from '@salesapp/utils/angular.utils';
import {ControlValueAccessor} from '../../utils/reactive-form/control-value-accessor';
import {CountryStorageService} from '@salesapp/storage';
import {createFormControlProvider} from '../../utils/reactive-form/form.utils';
import {FormControlComponent} from '../../utils/reactive-form/form-control.component';
import {IAddress} from '@retrixhouse/salesapp-shared/lib/models';
import {map} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-input-google-places',
  templateUrl: './input-google-places.component.html',
  styleUrls: ['./input-google-places.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [createFormControlProvider(() => InputGooglePlacesComponent)],
})
@ControlValueAccessor()
@AutoUnsubscribe()
export class InputGooglePlacesComponent extends FormControlComponent<string> {
  @Output() longitudeLatitudeValue =
    new EventEmitter<InputGooglePlacesLongitudeLatitudeValueEvent>();
  @Output() addressValue =
    new EventEmitter<InputGooglePlacesAddressValueEvent>();
  // @Output() openingHoursChange()

  private countrySubscription: Subscription;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    private countryStorageService: CountryStorageService,
  ) {
    super(changeDetectorRef);
  }

  onAddressChange(address: google.maps.places.PlaceResult) {
    if (address.place_id) {
      this.valueChange.emit(address.place_id);
    }

    // setup longitude, latitude
    if (address.geometry) {
      const longitude = address.geometry.location.lng();
      const latitude = address.geometry.location.lat();
      this.longitudeLatitudeValue.emit({longitude, latitude});
    }

    this.emitAddress(address);
    // this.updateOpeningHours(address);

    // if (this.locationSet.observers.length) {
    //   this.locationSet.emit();
    // }
  }

  private emitAddress(address: google.maps.places.PlaceResult) {
    const getAddressComponentValue = (
      componentType: string,
      returnValue: 'long' | 'short' = 'long',
    ) => {
      const component = address.address_components.find(i =>
        i.types.includes(componentType),
      );
      if (component) {
        return returnValue === 'long'
          ? component.long_name
          : component.short_name;
      }
      return null;
    };
    const googleCountryIso = getAddressComponentValue('country', 'short');

    this.countrySubscription = this.countryStorageService.data$
      .pipe(
        map(countries => {
          const countryId = countries.find(
            country => country.isoCode2 === googleCountryIso,
          )?.id;

          const addressData = {
            line1: getAddressComponentValue('route'),
            line2: getAddressComponentValue('street_number'),
            zipCode: getAddressComponentValue('postal_code'),
            city:
              getAddressComponentValue('locality') ||
              getAddressComponentValue('sublocality_level_1'),
            region: getAddressComponentValue('administrative_area_level_2'),
            state: getAddressComponentValue('administrative_area_level_1'),
            countryId: countryId || null,
          };
          return addressData;
        }),
      )
      .subscribe({
        next: addressData => this.addressValue.emit(addressData),
      });
  }

  private updateOpeningHours(address: google.maps.places.PlaceResult) {
    // if (address.opening_hours) {
    //   const formValue = this.formGroup.getRawValue();
    //   const openingHoursFormGroup = this.formGroup.get('openingHours');
    //   // if opening hours are set for store, ask before overwrite
    //   if (formValue.openingHours && formValue.openingHours.length > 0) {
    //     this.confirmationService
    //       .confirm(
    //         ConfirmType.CONFIRM,
    //         this.translateService.instant('captions.overwrite-opening-hours'),
    //         this.translateService.instant(
    //           'messages.confirmation.overwrite-opening-hours',
    //         ),
    //         {
    //           acceptText: this.translateService.instant('buttons.overwrite'),
    //         },
    //       )
    //       .then(dialogResult => {
    //         if (dialogResult && openingHoursFormGroup) {
    //           openingHoursFormGroup.patchValue(this.parseOpeningHours(address));
    //         }
    //       });
    //   } else {
    //     if (openingHoursFormGroup) {
    //       openingHoursFormGroup.patchValue(this.parseOpeningHours(address));
    //     }
    //   }
    // }
  }
}

export interface InputGooglePlacesLongitudeLatitudeValueEvent {
  longitude: number;
  latitude: number;
}

export type InputGooglePlacesAddressValueEvent = Omit<IAddress, 'id'>;
