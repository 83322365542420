import {CommonModule} from '@angular/common';
import {
  Component,
  Input,
  NgModule,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {TranslateModule} from '@ngx-translate/core';
import {DevExtremeModule} from 'devextreme-angular';
import {environment} from 'src/environments/environment';
import {ActionsService, AppInfoService, ScreenService} from '../../services';
import {Subscription} from 'rxjs';
import {AppInterfaceService} from '../../app-interface/app-interface.service';
import {RouterModule} from '@angular/router';
import {CustomDirectivesModule} from '../../directives';
import {RoleNames} from '@retrixhouse/salesapp-shared/lib/common';

@Component({
  selector: 'app-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss'],
})
export class UserAvatar implements OnChanges, OnDestroy {
  @Input() username: string = '';
  @Input() phoneNumber: string = '';
  @Input() email: string = '';
  @Input() firstName: string = '';
  @Input() lastName: string = '';
  @Input() position: string = '';
  @Input() picture: string = '';
  @Input() pictureSize: 'small' | 'normal' = 'normal';
  @Input() mode: 'singleLine' | 'multiLine' | 'contact' = 'multiLine';
  @Input() padding: string = '10px';
  @Input() fontSize: string = '1em';
  @Input() interactive = false;
  @Input() userUid: string;
  @Input() userId: string;
  @Input() userProfileId: string;
  @Input() showDetailNavigationLink = false;

  imgSrc: string = '/assets/no-avatar.png';
  isSmallScreen: boolean;
  $screenChangeSubscription: Subscription;
  RoleNames = RoleNames;

  constructor(
    private screenService: ScreenService,
    public appInfoService: AppInfoService,
    public appInterfaceService: AppInterfaceService,
    private actionsService: ActionsService,
  ) {
    this.isSmallScreen = this.screenService.isSmallScreen();
    this.$screenChangeSubscription = this.screenService.changed.subscribe(
      n => (this.isSmallScreen = this.screenService.isSmallScreen()),
    );
  }

  ngOnDestroy() {
    if (this.$screenChangeSubscription) {
      this.$screenChangeSubscription.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.picture?.currentValue !== changes?.picture?.previousValue) {
      if (changes?.picture?.currentValue) {
        this.imgSrc = `${environment.profilePictureBaseUrl}${changes.picture.currentValue}`;
      } else {
        this.imgSrc = '/assets/no-avatar.png';
      }
    }
  }

  openUserDetail() {
    if (this.userProfileId) {
      this.actionsService.openUserDetail({userProfileId: this.userProfileId});
    }
  }

  openContactUrl(url): void {
    if (!this.appInfoService.isMobileVersion) {
      return;
    }

    this.appInterfaceService.openUrl(url).catch(error => console.error(error));
  }
}

@NgModule({
  imports: [
    DevExtremeModule,
    CommonModule,
    TranslateModule,
    FlexLayoutModule,
    RouterModule,
    CustomDirectivesModule,
  ],
  declarations: [UserAvatar],
  exports: [UserAvatar],
})
export class UserAvatarModule {}

export class AvatarInfo {
  username?: string;
  firstName?: string;
  lastName?: string;
  position?: string;
  picture?: string;
  id?: string;
  uid?: string;
}
