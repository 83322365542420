import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {DxDateRangeBoxModule, DxNumberBoxModule} from 'devextreme-angular';
import {FormFieldModule} from '../form-field';
import {InputDateRangeComponent} from './input-date-range.component';

const DX_MODULES = [DxDateRangeBoxModule];
const UI_MODULES = [FormFieldModule];

@NgModule({
  imports: [CommonModule, ...DX_MODULES, ...UI_MODULES],
  declarations: [InputDateRangeComponent],
  exports: [InputDateRangeComponent],
})
export class InputDateRangeModule {}

export * from './input-date-range.component';
