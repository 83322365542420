<ng-container *ngIf="loading; else content">
  <div class="loading-container"
       [class.reverse]="reverse"
       [ngClass]="{reverse: reverse, 'with-logo': showLogo}">
    <div *ngIf="showLogo"
         class='logo-wrapper'>
      <img class='logo'
           src='/assets/salesapp.png' />
    </div>
    <app-loading [reverse]="reverse"></app-loading>
  </div>
</ng-container>
<ng-template #content>
  <ng-content *ngIf="!loading"></ng-content>
</ng-template>
<!-- <ng-container *ngIf="!loading">
</ng-container> -->