import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {InputLocationComponent} from './input-location.component';
import {FormModule} from '@salesapp/form';
import {InputNumberModule} from '../input-number';
import {DxMapModule} from 'devextreme-angular';
import {DxoZoomAndPanModule} from 'devextreme-angular/ui/nested';
import {ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';

const SALESAPP_MODULES = [FormModule];

const UI_MOULES = [InputNumberModule];

const DX_MODULES = [DxMapModule, DxoZoomAndPanModule];

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    ...SALESAPP_MODULES,
    ...UI_MOULES,
    ...DX_MODULES,
  ],
  declarations: [InputLocationComponent],
  providers: [],
  exports: [InputLocationComponent],
})
export class InputLocationModule {}
