import {EventEmitter} from '@angular/core';
import {ObjectArrayCache} from '@retrixhouse/salesapp-shared/lib/caching';
import {ISettingValues} from '@retrixhouse/salesapp-shared/lib/models';
import {Observable} from 'rxjs';
import {ISettingsDataService} from '../../interfaces/data-service';
import {
  BulkSetSettingRequest,
  ResolveSettingsRequest,
  Setting,
} from '../../models';
import {SettingsHttpService} from '../http';
import {SettingsOfflineService} from '../offline';
import {BaseDataService, CachingOptions, TTL_DAY} from './base.data-service';

export class SettingsDataService extends BaseDataService<ISettingsDataService> {
  private _cacheSettingValues: ObjectArrayCache<ISettingValues>;
  private _cacheSettingValuesExpired: EventEmitter<void>;

  constructor(
    onlineService: SettingsHttpService,
    offlineService: SettingsOfflineService,
    cacheSettingValues: ObjectArrayCache<ISettingValues>,
  ) {
    super(onlineService, offlineService);
    this._cacheSettingValuesExpired = new EventEmitter<void>();
    this._cacheSettingValues = cacheSettingValues;
    this._cacheSettingValues.onExpired.on(() => {
      this._cacheSettingValuesExpired.emit();
    });
  }

  public onSettingValuesExpired(): Observable<void> {
    return this._cacheSettingValuesExpired.asObservable();
  }

  public resolveSettingValue(name: string, projectId: string): Promise<any> {
    return this.service.resolveSettingValue(name, projectId);
  }

  public resolveSettingValues(
    request: ResolveSettingsRequest,
  ): Promise<{[prop: string]: any}> {
    return this.service.resolveSettingValues(request);
  }

  public async resolveAllValues(
    cachingOptions?: CachingOptions,
  ): Promise<ISettingValues[]> {
    if (cachingOptions?.skipCache) {
      return this.service.resolveAllValues();
    }

    if (cachingOptions?.forceReload || !this._cacheSettingValues.isValid) {
      const values = await this.service.resolveAllValues();
      this._cacheSettingValues.init(values, cachingOptions?.ttl ?? TTL_DAY);
    }

    return this._cacheSettingValues.getAll();
  }

  public create(setting: Setting): Promise<Setting> {
    return this.service.create(setting);
  }

  public getAll(): Promise<Setting[]> {
    return this.service.getAll();
  }

  public getOne<T = any>(idOrName: string): Promise<Setting<T>> {
    return this.service.getOne<T>(idOrName);
  }

  public set(idOrName: string, value?: any): Promise<void> {
    return this.service.set(idOrName, value);
  }

  public bulkSet(settingsToUpdate: BulkSetSettingRequest[]): Promise<void> {
    return this.service.bulkSet(settingsToUpdate);
  }

  public deleteByIdOrName(settingId: string): Promise<void> {
    return this.service.deleteByIdOrName(settingId);
  }
}
