import {DIALOG_DATA, DialogRef} from '@angular/cdk/dialog';
import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {ObjectTypeIds} from '@retrixhouse/salesapp-shared/lib/common';

@Component({
  selector: 'app-object-attachment-generate-dialog',
  templateUrl: './object-attachment-generate-dialog.component.html',
  styleUrls: ['./object-attachment-generate-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ObjectAttachmentGenerateDialogComponent {
  get objectTypeId() {
    return this.data.objectTypeId;
  }

  get objectId() {
    return this.data.objectId;
  }

  constructor(
    private dialogRef: DialogRef<void>,
    @Inject(DIALOG_DATA) private data: ObjectAttachmentGenerateDialogData,
  ) {}

  ngOnInit() {}

  onClose() {
    this.dialogRef.close();
  }

  onSave() {
    this.dialogRef.close();
  }
}

export interface ObjectAttachmentGenerateDialogData {
  objectTypeId: ObjectTypeIds;
  objectId: string;
}
