import {Injectable} from '@angular/core';
import {IUserStorageDataService} from '../../interfaces/data-service';
import {BaseOfflineService} from './base.offline-service';

@Injectable()
export class UserStorageOfflineService
  extends BaseOfflineService
  implements IUserStorageDataService
{
  public getAll(): Promise<{key: string; value: any}[]> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public getByKey<T>(key: string): Promise<T> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public set<T>(key: string, data: T): Promise<void> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public deleteByKey(key: string): Promise<void> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public deleteByKeys(keys: string[]): Promise<void> {
    return Promise.reject('Offline service - not yet implemented');
  }
}
