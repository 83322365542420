import {Injectable} from '@angular/core';
import {ObjectAttachment} from '@salesapp/api';
import {Observable, Subject} from 'rxjs';
import {filter} from 'rxjs/operators';
import {
  AttachmentTemplateEvents,
  EventTypes,
  ObjectAttachmentEvents,
} from './event-bus.interfaces';

@Injectable({providedIn: 'root'})
export class EventBus {
  get eventBus() {
    return this._eventBus.asObservable();
  }

  private _eventBus = new Subject<{type: EventTypes; data: any}>();

  listenTo(events: ObjectAttachmentEvents[]): Observable<{
    type: ObjectAttachmentEvents;
    data: ObjectAttachment[];
  }>;
  listenTo(events: AttachmentTemplateEvents[]): Observable<{
    type: AttachmentTemplateEvents;
    data: ObjectAttachment | ObjectAttachment[];
  }>;
  listenTo(
    events: EventTypes[],
  ): Observable<{type: EventTypes; data: unknown}> {
    return this.eventBus.pipe(filter(event => events.includes(event.type)));
  }

  publish(type: ObjectAttachmentEvents, data: ObjectAttachment[]): void;
  publish(
    type: AttachmentTemplateEvents,
    data: ObjectAttachment | ObjectAttachment[],
  ): void;
  publish(type: EventTypes, data: any): void {
    this._eventBus.next({type, data});
  }
}
