<app-form-field [label]="label"
                [hint]="hint"
                [formControlRef]="formControlRef">
  <div class="dx-texteditor-container">
    <div class="dx-texteditor-input-container">
      <input #placesRef="ngx-places"
             (onAddressChange)="onAddressChange($event)"
             class="dx-texteditor-input"
             id="ngx-places"
             ngx-gp-autocomplete
             [disabled]="disabled"
             [placeholder]="placeholder || ''"
             type="text" />
    </div>
  </div>
</app-form-field>