import {Injectable} from '@angular/core';
import {BaseCrudHttpService} from './base.http-service';
import {HttpClient} from '@angular/common/http';
import {
  PromoAction,
  PromoActionReportRequest,
  PromoActionReportResult,
} from '../../models/promo-action.model';
import {IPromoActionDataService} from '../../interfaces/data-service';
import {Filter} from '@loopback/filter';

@Injectable()
export class PromoActionHttpService
  extends BaseCrudHttpService<PromoAction>
  implements IPromoActionDataService
{
  constructor(http: HttpClient) {
    super(http, '/promo-actions');
  }

  getListWithChainIds(filter?: Filter): Promise<(PromoAction & {chainIds: string[]})[]> {
    let params = {includeChainIds: true};

    if (filter) {
      params = Object.assign(params, {filter: JSON.stringify(filter)});
    }
    return this.GET<(PromoAction & {chainIds: string[]})[]>(
      '/promo-actions',
      {params},
    );
  }

  public getForCustomer(customerId: string): Promise<PromoAction[]> {
    return this.GET<PromoAction[]>(`/customer/${customerId}/promo-actions`);
  }

  public getPromoActionsWithRelations(
    projectIds: string[],
  ): Promise<PromoAction[]> {
    const now = new Date();
    return this.POST<PromoAction[]>('/promo-actions/filter', {
      where: {
        projectId: {inq: projectIds},
        and: [{validFrom: {lte: now}}, {validTo: {gte: now}}],
        active: 1,
      },
      include: [
        {
          relation: 'questionnaire',
          scope: {
            include: [
              {
                relation: 'questions',
                scope: {
                  include: [
                    {
                      relation: 'question',
                    },
                    {
                      relation: 'optionList',
                      scope: {
                        include: ['items'],
                      },
                    },
                    {
                      relation: 'tags',
                    },
                    {
                      relation: 'productCategories',
                    },
                    {
                      relation: 'products',
                    },
                  ],
                },
              },
              {
                relation: 'theme',
              },
            ],
          },
        },
        {relation: 'products'},
        {relation: 'productCategories'},
        {relation: 'stores'},
      ],
    });
  }

  public getReport(
    request: PromoActionReportRequest,
  ): Promise<PromoActionReportResult> {
    return this.POST(`/promo-actions/report`, request);
  }
}
