import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseCrudHttpService} from './base.http-service';
import {Tag} from '../../models';
import {ITagDataService} from '../../interfaces/data-service';

@Injectable()
export class TagHttpService
  extends BaseCrudHttpService<Tag>
  implements ITagDataService
{
  constructor(http: HttpClient) {
    super(http, '/tags');
  }

  public updateItemPositions(
    positions: {id: string; position: number; parentId: string}[],
  ): Promise<void> {
    return this.PATCH<void>(`/tags/positions`, positions);
  }

  public checkIfTagIsUsed(tagId: string): Promise<boolean> {
    return this.GET(`/tags/${tagId}/used`);
  }

  public getTagsUsage(): Promise<{id: string; used: boolean}[]> {
    return this.GET(`/tags/usage`);
  }
}
