/**
 * Defines standard widths of columns per grid
 */
export enum GridColumnWidth {
  ShortText = 100,
  MediumText = 150,
  MiddleText = 200,
  LongText = 360,

  Date = 120,
  Time = 120,
  DateTime = 200,

  Boolean = 100,
  ShortNumber = 100,

  Email = 250,
  UserName = 150,
  GeneralName = 200,
  UsernameWithEmail = 400,

  UserAvatar = 200,
  UserContact = 300,
  VisitAvatar = 400,
  StoreAvatar = 200,
  StoreAvatarWithLogo = 250,

  Color = 200,
  Version = 80,
  UID = 110,
  Buttons = 100,
}
