import {Injectable} from '@angular/core';
import {BaseCrudHttpService} from './base.http-service';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {
  TourPlan,
  TourPlanExchangeInfo,
  TourPlanQuickEditRequest,
  TourPlanSearchRequest,
  TourPlanWithExchangeInfo,
} from 'src/app/shared/models';

import {
  ITourPlanExchangeCheckRequest,
  ITourPlanExchangeRequest,
  ITourPlanExchangeSearchRequest,
  ITourPlanCopyRequest,
} from '@retrixhouse/salesapp-shared/lib/requests';
import {IProcessingProgress} from '@retrixhouse/salesapp-shared/lib/responses';
import {TourPlanState} from '@retrixhouse/salesapp-shared/lib/models';
import {
  ITourPlanDataService,
  SearchInLocation,
} from '../../interfaces/data-service';

@Injectable()
export class TourPlanHttpService
  extends BaseCrudHttpService<TourPlan>
  implements ITourPlanDataService
{
  constructor(http: HttpClient) {
    super(http, '/tour-plans');
  }

  public updateIgnoreVersion(
    objectId: string,
    objectData: Partial<TourPlan>,
  ): Promise<TourPlan> {
    return this.PATCH<TourPlan>(
      `/tour-plans/${objectId}?ignoreVersion=true`,
      objectData,
    );
  }

  public async search(filter: TourPlanSearchRequest): Promise<TourPlan[]> {
    const tourPlans = await this.POST<TourPlan[]>(`/tour-plans/search`, filter);
    tourPlans.forEach(tp => TourPlan.formatDateProperties(tp));
    return tourPlans;
  }

  public bulkCreate(tourPlans: TourPlan[]): Promise<TourPlan[]> {
    return this.POST<TourPlan[]>('/tour-plans/bulk', tourPlans);
  }

  public searchInLocation(search: SearchInLocation): Promise<TourPlan[]> {
    return this.GET<TourPlan[]>(`/tour-plans/in-location`, {
      params: {
        north: search.north,
        south: search.south,
        west: search.west,
        east: search.east,
        limit: search.limit,
      },
    });
  }

  public isScheduledStartValid(
    scheduledStart: Date,
    projectId: string,
  ): Promise<boolean> {
    return this.GET<boolean>(`/tour-plans/is-scheduled-start-valid`, {
      params: {
        scheduledStart: scheduledStart.getTime(),
        projectId,
      },
    });
  }

  public updateStateIgnoreVersion(
    tourPlanId: string,
    state: TourPlanState,
  ): Promise<TourPlan> {
    return this.PATCH<TourPlan>(
      `/tour-plans/update-state-ignore-version/${tourPlanId}`,
      {},
      {
        params: {
          state,
        },
      },
    );
  }

  public deleteVisitForUser(visitId: string, version: number): Promise<void> {
    return this.DELETE(`/tour-plans/delete-for-user/${visitId}`, {
      params: {version},
    });
  }

  public exchangeSearch(
    request: ITourPlanExchangeSearchRequest,
  ): Promise<TourPlanWithExchangeInfo[]> {
    return this.POST<TourPlanWithExchangeInfo[]>(
      '/tour-plans/exchange/search',
      request,
    );
  }

  public exchangeCheck(
    request: ITourPlanExchangeCheckRequest[],
  ): Promise<TourPlanExchangeInfo[]> {
    return this.POST<TourPlanWithExchangeInfo[]>(
      '/tour-plans/exchange/check',
      request,
    );
  }

  public exchange(request: ITourPlanExchangeRequest[]): Promise<string[]> {
    return this.POST<string[]>('/tour-plans/exchange', request);
  }

  public quickEdit(request: TourPlanQuickEditRequest): Promise<TourPlan[]> {
    return this.POST<TourPlan[]>('/tour-plans/quick-edit', request);
  }

  unlockTourPlans(tourPlanIds: string[]): Promise<TourPlan[]> {
    return this.PATCH<TourPlan[]>('/tour-plans/unlock-visit', tourPlanIds);
  }

  approveTourPlans(
    request: {
      tourPlanId: string;
      approved: boolean;
    }[],
  ): Promise<TourPlan[]> {
    return this.PATCH<TourPlan[]>('/tour-plans/approve-visit', request);
  }

  copyTourPlans(
    request: ITourPlanCopyRequest,
  ): Promise<{createdTourPlans: TourPlan[]; ignoredTourPlans?: TourPlan[]}> {
    return this.POST<{
      createdTourPlans: TourPlan[];
      ignoredTourPlans?: TourPlan[];
    }>('/tour-plans/copy', request);
  }

  getCopyProgress(operationId: string): Promise<IProcessingProgress> {
    return this.POST<IProcessingProgress>(`/tour-plans/copy/progress`, {
      operationId,
    });
  }

  getCopyAvailability(): Promise<undefined> {
    return this.GET<undefined>(`/tour-plans/copy/available`);
  }

  groupReportAsXlsx(
    request: TourPlanSearchRequest,
  ): Promise<HttpResponse<Blob>> {
    return this.POST<HttpResponse<Blob>>(
      '/tour-plans/report/grouped',
      request,
      {
        params: {format: 'xlsx'},
        observe: 'response',
        responseType: 'blob',
      },
    );
  }
}
