import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ProjectContextPermissions} from '@retrixhouse/salesapp-shared/lib/common';
import {IUsernameResponse} from '@retrixhouse/salesapp-shared/lib/responses';
import {Observable, combineLatest, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {Action, IAction} from '../domain';
import {PermissionResolverService} from './permission-resolver.service';
import {CurrentUserStorageService} from './storage/current-user-storage.service';

@Injectable({
  providedIn: 'root',
})
export class ActionsBuilderService {
  constructor(
    private translate: TranslateService,
    private permissionResolver: PermissionResolverService,
    private currentUserStorageService: CurrentUserStorageService,
  ) {}

  /**
   * Gets list of actions and validates if user has permissions to see them and they will also be formatted if formatting function will be provided
   * when object different than Action class instance is provided in array it will be ignored and added to returned array automatically
   * @param actions
   * @param params
   * @param context
   * @returns list of formatted and validated actions for user
   */
  init<T>(
    actions: (Action | any)[],
    params?: {
      projectId?: string;
      formatFn?: (action: Action) => T;
      data?: unknown;
    },
  ): Observable<T[]> {
    return combineLatest([
      this.hasAnyActionProjectPermissions(actions)
        ? this.permissionResolver.getUserProjectsPermissions()
        : of(null),
      this.currentUserStorageService.data$,
    ]).pipe(
      map(([userProjectsPermissions, currentUserUsername]) => {
        const preparedActions = [];

        actions.forEach(action => {
          // NOTE: for case when there is custom object between actions in array
          if (action instanceof Action) {
            const preparedAction = this.prepareAction(action, {
              ...params,
              userProjectsPermissions,
              currentUserUsername: currentUserUsername,
            });

            if (preparedAction) {
              preparedActions.push(preparedAction);
            }
          } else {
            preparedActions.push(action);
          }
        });

        return preparedActions;
      }),
    );
  }

  /**
   * Returns true when at least one action in array has projectContext
   * @param actions
   * @returns when any action in array has project context
   */
  private hasAnyActionProjectPermissions(actions: IAction[]) {
    return actions
      .filter(action => action instanceof Action)
      .some(
        action =>
          !!(action.permissions?.and || action.permissions?.or).projectContext,
      );
  }

  /**
   * Gets action and returns translated text based on i18nIdentifier
   * @param action
   * @param context is used for translation without angular DI
   * @returns translated text
   */
  private translateText(action: Action): string {
    return this.translate.instant(action.i18nIdentifier);
  }

  /**
   * Resolves action permissions based on Action setting or custom resolver
   * @param action
   * @param params
   * @param context
   * @returns true when logged in user passed resolvers
   */
  private resolveActionPermissions(
    action: Action,
    params: {
      projectId?: string;
      currentUserUsername: IUsernameResponse;
      data?: unknown;
    },
  ) {
    if (action.customPermissionResolver) {
      const {resolver, resolverParams} = action.customPermissionResolver;
      return resolver(resolverParams);
    }
    return this.permissionResolver.resolveAction(action, params);
  }

  /**
   * Gets Action and when resolver will return true action will be translated
   * and later may also be formatted by custom formatting fn
   * @param action
   * @param params
   * @param context
   * @returns Formatted and translated action or undefined when user has no permission for this action
   */
  private prepareAction<T>(
    action: Action,
    params: {
      projectId?: string;
      formatFn?: (action: Action) => T;
      userProjectsPermissions?: {
        [projectId: string]: ProjectContextPermissions[];
      };
      data?: unknown;
      currentUserUsername: IUsernameResponse;
    },
  ): Action | T | undefined {
    if (this.resolveActionPermissions(action, params)) {
      const translated = action.withTranslation(this.translateText(action));

      if (params.formatFn) {
        return params.formatFn(translated);
      }
      return translated;
    }
    return;
  }
}
