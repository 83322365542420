import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {
  BulkSetSettingRequest,
  ResolveSettingsRequest,
  Setting,
} from '../../models';
import {BaseHttpService} from './base.http-service';
import {ISettingValues} from '@retrixhouse/salesapp-shared/lib/models';
import {ISettingsDataService} from '../../interfaces/data-service';

@Injectable()
export class SettingsHttpService
  extends BaseHttpService
  implements ISettingsDataService
{
  constructor(http: HttpClient) {
    super(http);
  }

  public resolveSettingValue(name: string, projectId: string): Promise<any> {
    return this.GET<any>('/core/setting-value', {
      params: {
        name: name,
        projectId: projectId,
      },
    });
  }

  public resolveSettingValues(
    request: ResolveSettingsRequest,
  ): Promise<{[prop: string]: any}> {
    return this.POST<{[prop: string]: any}>('/core/setting-values', request);
  }

  public resolveAllValues(): Promise<ISettingValues[]> {
    return this.GET<ISettingValues[]>('/core/setting-values-all');
  }

  public create(setting: Setting): Promise<Setting> {
    return this.POST<Setting>('/core/settings/', setting);
  }

  public getAll(): Promise<Setting[]> {
    return this.GET<Setting[]>('/core/settings');
  }

  public getOne<T = any>(idOrName: string): Promise<Setting<T>> {
    return this.GET<Setting>(`/core/settings/${idOrName}`);
  }

  public set(idOrName: string, value?: any): Promise<void> {
    return this.PATCH<void>(`/core/settings/${idOrName}`, {
      value: value,
    });
  }

  public bulkSet(settingsToUpdate: BulkSetSettingRequest[]): Promise<void> {
    return this.PATCH<void>('/core/settings/', settingsToUpdate);
  }

  public deleteByIdOrName(settingId: string): Promise<void> {
    return this.DELETE<void>(`/core/settings/${settingId}`);
  }
}
