import {DialogModule} from '@angular/cdk/dialog';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule, UntypedFormBuilder} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {DxButtonModule} from 'devextreme-angular';
import {FormControlErrorModule} from '../../components/form-control-error';
import {FormActionsComponent} from './components/form-actions/form-actions.component';
import {FormColumnComponent} from './components/form-column/form-column.component';
import {FormDiscardDialogComponent} from './components/form-discard-dialog/form-discard-dialog.component';
import {FormRowComponent} from './components/form-row/form-row.component';
import {FormSectionComponent} from './components/form-section/form-section.component';
import {FormComponent} from './components/form/form.component';

const FORM_COMPONENTS: any = [
  FormActionsComponent,
  FormColumnComponent,
  FormComponent,
  FormDiscardDialogComponent,
  FormRowComponent,
  FormSectionComponent,
];

const DX_MODULES = [DxButtonModule];

const UI_MODULES = [FormControlErrorModule];

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DialogModule,
    TranslateModule,
    ...DX_MODULES,
    ...UI_MODULES,
  ],
  providers: [UntypedFormBuilder],
  declarations: [...FORM_COMPONENTS],
  exports: [...FORM_COMPONENTS],
})
export class FormModule {}

export * from './components/form-actions/form-actions.component';
export * from './components/form/form.component';
