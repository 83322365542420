import {Injectable} from '@angular/core';
import {Filter} from '@loopback/filter';
import {Product} from 'src/app/shared/models/product.model';
import {IProductDataService} from '../../interfaces/data-service';
import {BaseCrudOfflineService} from './base.offline-service';

@Injectable()
export class ProductOfflineService
  extends BaseCrudOfflineService<Product>
  implements IProductDataService
{
  public getForCustomer(
    customerId: string,
    enabledOnly: boolean = false,
    filter: Filter<Product> = {},
  ): Promise<Product[]> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public moveProducts(categoryId: string, productIds: string[]): Promise<void> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public updateProductPicture(
    productId: string,
    version: number,
    picture: string,
  ): Promise<Product> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public deleteProductPicture(productId: string): Promise<void> {
    return Promise.reject('Offline service - not yet implemented');
  }
}
