import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {FormControlComponent} from '../../utils/reactive-form/form-control.component';
import {createFormControlProvider} from '../../utils/reactive-form/form.utils';
import {ControlValueAccessor} from '../../utils/reactive-form/control-value-accessor';
import {dxHtmlEditorToolbar} from 'devextreme/ui/html_editor';

@Component({
  selector: 'app-input-rich-text',
  templateUrl: './input-rich-text.component.html',
  styleUrls: ['./input-rich-text.component.scss'],
  providers: [createFormControlProvider(() => InputRichTextComponent)],
})
@ControlValueAccessor()
export class InputRichTextComponent extends FormControlComponent<string> {
  @Input() height: string = '200px';

  toolbar: dxHtmlEditorToolbar = TOOLBAR;

  constructor(private changeDetector: ChangeDetectorRef) {
    super(changeDetector);
  }

  onValueChange(event) {
    this.valueChange.emit(event);
  }
}

const TOOLBAR: dxHtmlEditorToolbar = {
  items: [
    'undo',
    'redo',
    'separator',
    {
      name: 'header',
      acceptedValues: [false, 1, 2, 3, 4, 5],
    },
    'separator',
    {
      name: 'size',
      acceptedValues: ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'],
    },
    {
      name: 'font',
      acceptedValues: [
        'Arial',
        'Courier New',
        'Georgia',
        'Impact',
        'Lucida Console',
        'Tahoma',
        'Times New Roman',
        'Verdana',
      ],
    },
    'separator',
    'bold',
    'italic',
    'strike',
    'underline',
    'separator',
    'color',
    'background',
    'separator',
    'alignLeft',
    'alignCenter',
    'alignRight',
    'alignJustify',
    'separator',
    'orderedList',
    'bulletList',
    'separator',
    'link',
  ],
};
