<app-dialog [title]="(isUpdate ? 'entity.external-report.dialog.update.title' : 'entity.external-report.dialog.create.title') | translate"
            [actionInProgress]="actionInProgress$ | async"
            [actionLabel]="'form.action.save' | translate"
            [actionVisible]="true"
            [cancelLabel]="'form.action.close' | translate"
            [cancelVisible]="true"
            [formGroup]="form"
            (cancelClicked)="onClose()"
            (actionClicked)="onSave()">
  <app-form-row>
    <app-form-column>
      <app-input-text [formControl]="getFormControl('name')"
                      [formControlRef]="getFormControl('name')"
                      [label]="'entity.external-report.properties.name.label' | translate"></app-input-text>
    </app-form-column>
  </app-form-row>
  <app-form-row>
    <app-form-column>
      <app-input-text [formControl]="getFormControl('url')"
                      [formControlRef]="getFormControl('url')"
                      [label]="'entity.external-report.properties.url.label' | translate"></app-input-text>
    </app-form-column>
  </app-form-row>
  <app-form-row>
    <app-form-column>
      <app-input-select [formControl]="getFormControl('viewMode')"
                        [formControlRef]="getFormControl('viewMode')"
                        [label]="'entity.external-report.properties.view-mode.label' | translate"
                        [options]="viewModeOptions"></app-input-select>
    </app-form-column>
  </app-form-row>
  <app-form-row>
    <app-form-column>
      <app-input-number [formControl]="getFormControl('width')"
                        [formControlRef]="getFormControl('width')"
                        [label]="'entity.external-report.properties.width.label' | translate"
                        [hint]="'entity.external-report.properties.width.hint' | translate"></app-input-number>
    </app-form-column>
    <app-form-column>
      <app-input-number [formControl]="getFormControl('height')"
                        [formControlRef]="getFormControl('height')"
                        [label]="'entity.external-report.properties.height.label' | translate"
                        [hint]="'entity.external-report.properties.height.hint' | translate"></app-input-number>
    </app-form-column>
  </app-form-row>
  <app-form-row>
    <app-form-column>
      <app-input-multiselect [formControl]="getFormControl('userIds')"
                             [formControlRef]="getFormControl('userIds')"
                             [label]="'entity.external-report.properties.user-ids.label' | translate"
                             [options]="userOptions$ | async"></app-input-multiselect>
    </app-form-column>
  </app-form-row>
</app-dialog>