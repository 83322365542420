<form [formGroup]="formGroup"
      [attr.id]="formId"
      autocomplete="off"
      (ngSubmit)="onAction()"
      (submit)="$event.stopPropagation()">
  <div class="form-body">
    <ng-content></ng-content>
    <!-- <app-form-control-error *ngIf="formGroup && hasError"
                            [formControlRef]="formGroup"> </app-form-control-error> -->
  </div>
  <div class="form-actions">
    <ng-content select="[actions]"></ng-content>
  </div>
</form>