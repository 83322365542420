import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';

@Component({
  selector: 'app-base-dialog',
  templateUrl: './base-dialog.component.html',
  styleUrls: ['./base-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BaseDialogComponent {
  @Input() noPadding: boolean;
  @Input() hideHeader: boolean;
  @Input() displayFooter: boolean = true;

  @Input()
  @HostBinding('style.border-top-color')
  borderTopColor = '#cddc39';
}
