import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormFieldComponent} from './form-field.component';
import {FormControlErrorModule} from '../form-control-error';

const UI_MODULES = [FormControlErrorModule];

@NgModule({
  imports: [CommonModule, ...UI_MODULES],
  declarations: [FormFieldComponent],
  exports: [FormFieldComponent],
})
export class FormFieldModule {}
