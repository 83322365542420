import {OldBaseStorageService} from './old-base-storage-service';
import {DataProvider} from '../../data.provider/data-provider';
import {ICountry} from '@retrixhouse/salesapp-shared/lib/models';
import {Injectable} from '@angular/core';

@Injectable()
export class CountryStorageService extends OldBaseStorageService<
  ICountry,
  ICountry[]
> {
  constructor(dataProvider: DataProvider) {
    super(dataProvider.country as any);
  }
}
