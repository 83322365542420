import {Injectable} from '@angular/core';
import {UsernameResponse} from '../../models/username-response.model';
import {IUserDataService} from '../../interfaces/data-service';
import {
  CreateUserRequest,
  User,
  UserProfile,
  WhoAmIResponse,
} from '../../models';
import {BaseCrudOfflineService} from './base.offline-service';
import {Filter} from '@loopback/filter';

@Injectable()
export class UserOfflineService
  extends BaseCrudOfflineService<User>
  implements IUserDataService
{
  public resetUserPassword(
    userId: string,
    oneTimePassword: string,
  ): Promise<void> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public createUser(
    request: CreateUserRequest,
  ): Promise<{user: User; userProfile: UserProfile}> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public existsUsername(username: string): Promise<boolean> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public getUsernames(): Promise<UsernameResponse[]> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public whoAmI(): Promise<WhoAmIResponse> {
    return Promise.reject('Offline service - not yet implemented');
  }
  getUserProfiles(filter?: Filter<UserProfile>): Promise<UserProfile[]> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public getUserProfile(id: string): Promise<UserProfile> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public getUserProfileByUserId(userId: string) {
    return Promise.reject('Offline service - not yet implemented');
  }

  public updateUserProfile(
    id: string,
    profile: Partial<UserProfile>,
  ): Promise<UserProfile> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public updateUserProfilePicture(
    id: string,
    version: number,
    picture: string,
  ): Promise<UserProfile> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public getMyProfile(): Promise<UserProfile> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public getMyUsernameResponse(): Promise<UsernameResponse> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public updateMyProfile(profile: UserProfile): Promise<UserProfile> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public uploadMyProfilePicture(pictureFile: FormData): Promise<string> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public uploadUserProfilePicture(
    profileId: string,
    pictureFile: FormData,
  ): Promise<string> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public updateMyProfilePicture(
    id: string,
    version: number,
    picture: string,
  ): Promise<UserProfile> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public getProfileIdsForUserIds(
    userIds?: string[],
  ): Promise<{[key: string]: string}> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public getMySubordinatesForProject(
    projectId: string,
    includeAdministrators?: boolean,
  ): Promise<UsernameResponse[]> {
    return Promise.reject('Offline service - not yet implemented');
  }

  getVisibleUsers(
    projectIds?: string[],
    includeAdministrators?: boolean,
  ): Promise<UsernameResponse[]> {
    return Promise.reject('Offline service - not yet implemented');
  }
}
