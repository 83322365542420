import * as uuid from 'uuid';
import {Unit} from './unit.model';

export class UnitConversion {
  id: string;
  srcUnitId: string;
  dstUnitId: string;
  expression: string;
  sourceUnit: Unit;
  destinationUnit: Unit;
}

export enum UnitConversionErrors {
  Exists = 'exists',
  SameType = 'sametype',
}
