import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseCrudHttpService} from './base.http-service';
import {KPISet} from '../../models';
import {IKPISetDataService} from '../../interfaces/data-service';

@Injectable()
export class KPISetHttpService
  extends BaseCrudHttpService<KPISet>
  implements IKPISetDataService
{
  constructor(http: HttpClient) {
    super(http, '/kpi-sets');
  }
}
