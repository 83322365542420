import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {InputRichTextComponent} from './input-rich-text.component';
import {DxHtmlEditorModule} from 'devextreme-angular';
import {FormFieldModule} from '../form-field';

const DX_MODULES = [DxHtmlEditorModule];
const UI_MODULES = [FormFieldModule];

@NgModule({
  imports: [CommonModule, ...DX_MODULES, ...UI_MODULES],
  declarations: [InputRichTextComponent],
  exports: [InputRichTextComponent],
})
export class InputRichTextModule {}

export * from './input-rich-text.component';
