<div id="breadcrumbs-wrapper" *ngIf="!isFullScreen">
  <div *ngIf="isSmallScreen; then smallBreadcrumb; else normalBreadcrumb"></div>

  <ng-template #smallBreadcrumb>
    <div class="breadcrumb-small">
      <span [routerLink]="!readOnly ? '/' : null" routerLinkActive="router-link-active"><i class="fa fa-home"></i></span>
      <span *ngFor="let breadcrumb of breadcrumbs || defaultBreadcrumbs" class=""> <i class="fa fa-angle-right"></i> {{ breadcrumb.label }} </span>
    </div>
  </ng-template>

  <ng-template #normalBreadcrumb>
    <ol class="breadcrumb">
      <li>
        <span [routerLink]="!readOnly ? '/' : null" routerLinkActive="router-link-active">
          <i class="fa fa-home"></i>
        </span>
        <span class="separator">
          <i class="fa fa-angle-right"></i>
        </span>
      </li>
      <li *ngFor="let breadcrumb of breadcrumbs || defaultBreadcrumbs">
        <span [routerLink]="!readOnly ? breadcrumb.url : null" routerLinkActive="router-link-active">
          {{ breadcrumb.label }}
        </span>
        <span class="separator">
          <i class="fa fa-angle-right"></i>
        </span>
      </li>
    </ol>
  </ng-template>

  <div class="dx-clearfix"></div>
</div>
