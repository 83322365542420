<div *ngIf="feedback$ | async; let feedback">
  <div class="feedback-header">
    <app-user-avatar [padding]="'none'"
                     [mode]="'singleLine'"
                     [firstName]="feedback.createdBy?.firstName"
                     [lastName]="feedback.createdBy?.lastName"
                     [picture]="feedback.createdBy?.profilePicture"
                     [position]="feedback.createdBy?.positionAbbreviation"
                     [username]="feedback.createdBy?.username"
                     [userUid]="feedback.createdBy?.uid"></app-user-avatar>
    <div class="feedback-header-dates">
      <span>{{ feedback.createdAt | dateFormat:'L LT' }}</span>
      <span class="feedback-header-dates-diff">({{ 'views.feedback.create-diff' | translate:{diff:(feedback.visitFinishedAt |
        dateDiff:feedback.createdAt)} }})</span>
    </div>
  </div>
  <div class="feedback-info">
    <app-pill [text]="(feedback.resolved ? 'views.feedback.resolved' : 'views.feedback.unresolved') | translate"
              [color]="feedback.resolved ? '#008000' : '#ff0000'"
              [textColor]="'#ffffff'"></app-pill>
    <ng-container *ngIf="feedback.comment || feedback.commentRequired">
      <div class="separator"></div>
      <app-pill [text]="(feedback.comment ? 'views.feedback.has-response' : 'views.feedback.comment-required') | translate"
                [color]="feedback.comment ? '#008000' : '#ffc107'"
                [textColor]="'#ffffff'"></app-pill>
    </ng-container>
    <ng-container *ngIf="feedback.photoRequired || feedback.comment?.photo">
      <div class="separator"></div>
      <app-pill [text]="(feedback.comment?.photo ? 'views.feedback.response-has-photo' : 'views.feedback.photo-required') | translate"
                [color]="feedback.comment?.photo ? '#008000' : '#ffc107'"
                [textColor]="'#ffffff'"></app-pill>
    </ng-container>
  </div>
  <div class="feedback-body">
    <app-feedback-detail-item *ngFor="let kpiSet of feedback.kpiSets; trackBy: trackByFn"
                              [kpiSet]="kpiSet"
                              (openImage)="onOpenImageFromDetail($event)"></app-feedback-detail-item>
  </div>
  <div class="feedback-footer">
    <div class="feedback-response">
      <div class="feedback-response-title">{{ 'view.feedback.response' | translate }}</div>
      <div *ngIf="feedback.comment; else noComment"
           class="feedback-comment">
        <div class="feedback-header">
          <app-user-avatar [padding]="'none'"
                           [mode]="'singleLine'"
                           [firstName]="feedback.comment.createdBy?.firstName"
                           [lastName]="feedback.comment.createdBy?.lastName"
                           [picture]="feedback.comment.createdBy?.profilePicture"
                           [position]="feedback.comment.createdBy?.positionAbbreviation"
                           [username]="feedback.comment.createdBy?.username"
                           [userUid]="feedback.comment.createdBy?.uid"></app-user-avatar>
          <div class="feedback-header-dates">
            <span>{{ feedback.comment.createdAt | dateFormat:'L LT' }}</span>
            <span class="feedback-header-dates-diff">({{ 'views.feedback.comment-diff' | translate:{diff:(feedback.createdAt |
              dateDiff:feedback.comment.createdAt)} }})</span>
          </div>
        </div>
        <div class="feedback-comment-content">
          {{ feedback.comment?.text }}
        </div>
        <div *ngIf="feedback.comment.photo"
             class="photos">
          <img class="image"
               (click)="onOpenImage(feedback.comment.photo)"
               [src]="(baseUrl + feedback.comment.photo.uriThumbnail)" />
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #noComment>
  <span class="no-comment">{{ 'views.feedback.no-response-placeholder' | translate }}</span>
</ng-template>

<!-- NOTE: COPIED FROM OLD DETAIL (I dont like this solution) -->
<app-img-gallery *ngIf="originalFeedback$ | async; let feedback"
                 [photos]="photos"
                 [(visible)]="imageDetailVisible"
                 [selectedPhotoIndex]="selectedPhotoIndex"
                 [myKPISets]="feedback.kpiSets"
                 [myFeedback]="feedback"
                 [tourPlanId]="feedback.tourPlanId"
                 [tags]="[]"
                 [photoTagRules]="[]"
                 [permissions]="{feedback: false, comment: false, tag: false}"></app-img-gallery>