import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Unit} from 'src/app/shared/models/unit.model';
import {BaseCrudHttpService} from './base.http-service';
import {IUnitDataService} from '../../interfaces/data-service';

@Injectable()
export class UnitHttpService
  extends BaseCrudHttpService<Unit>
  implements IUnitDataService
{
  constructor(http: HttpClient) {
    super(http, '/core/units');
  }
}
