import {Injectable} from '@angular/core';
import {DataProvider} from '../data.provider/data-provider';
import {UsernameResponse} from '../models';
import {AuthGuardService} from './auth.service';
import {UserPosition} from '@retrixhouse/salesapp-shared/lib/models';

@Injectable()
export class SubordinatesService {

  subordinatesPerProjectCache: Map<string, UsernameResponse[]>;
  private _currentUser: UsernameResponse;

  constructor(
    private dataProvider: DataProvider,
    private authGuardService: AuthGuardService,
  ) {
    this.subordinatesPerProjectCache = new Map<string, UsernameResponse[]>();
  }

  private async getCurrentUser(): Promise<UsernameResponse | undefined> {
    if (!this._currentUser) {
      try {
        this._currentUser = await this.authGuardService.getMyUsernameResponse();
      } catch (e) {
        // ignored
      }
    }

    return this._currentUser;
  }

  public async getSubordinatesPerProject(
    projectIds: string[] | null,
  ): Promise<UsernameResponse[]> {
    // admin should see all users
    if (this.authGuardService.isAdmin()) {
      return this.dataProvider.user.getUsernames();
    }

    const currentUser = await this.getCurrentUser();
    const includeAdministrators = currentUser && currentUser.positionId === UserPosition.Administrator;
    if (!projectIds || projectIds.length === 0) {
      projectIds = [null];
    }

    await Promise.all(projectIds
      .filter(projectId => !this.subordinatesPerProjectCache.has(projectId))
      .map(projectId => this.fetchSubordinates(projectId, includeAdministrators)),
    );

    const subordinates = projectIds
      .map(projectId => this.subordinatesPerProjectCache.get(projectId) ?? [])
      .flatMap(i => i)
      .filter(
        (value, index, self) => index === self.findIndex(t => t.id === value.id),
      );

    // add current user to subordinates if he is not in the list
    if (currentUser && !subordinates.some(s => s.id === currentUser.id)) {
      subordinates.push(currentUser);
    }

    return subordinates;
  }

  private async fetchSubordinates(projectId, includeAdministrators: boolean): Promise<void> {
    return this.dataProvider.user
      .getMySubordinatesForProject(
        projectId,
        includeAdministrators,
      ).then(subs => {
        this.subordinatesPerProjectCache.set(projectId, subs);
      });
  }
}
