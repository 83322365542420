import {Injectable} from '@angular/core';
import {BaseHttpService} from './base.http-service';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {
  ContextItem,
  ObjectProperty,
  ObjectPropertyValue,
  ObjectType,
  RevisionsResponse,
  SetExtendedPropertiesRequest,
  StateTransitionRecord,
} from '../../models';
import {IObjectDataService} from '../../interfaces/data-service';
import {IObjectForm} from '@retrixhouse/salesapp-shared/lib/models';

@Injectable()
export class ObjectHttpService
  extends BaseHttpService
  implements IObjectDataService
{
  constructor(http: HttpClient) {
    super(http);
  }

  public getNextAvailableUID(typeIdOrName: string): Promise<{uid: string}> {
    return this.GET<{uid: string}>(`/core/object/${typeIdOrName}/next-uid`);
  }

  public getRevisions(
    id: string,
    limit?: number,
  ): Promise<RevisionsResponse[]> {
    if (limit !== undefined && limit > 0) {
      return this.GET<RevisionsResponse[]>(
        `/core/revisions/${id}?limit=${limit}`,
      );
    }

    return this.GET<RevisionsResponse[]>(`/core/revisions/${id}?limit=10`);
  }

  public getStateTransitions(
    id: string,
    limit?: number,
  ): Promise<StateTransitionRecord[]> {
    if (!limit || limit < 0) {
      limit = 1000000;
    }
    return this.GET<StateTransitionRecord[]>(
      `/core/workflow/${id}?limit=${limit}`,
    );
  }

  public getObjectType(id: string): Promise<ObjectType> {
    return this.GET<ObjectType>(`/core/object/types/${id}`);
  }

  public getContextItems(objectNameOrId: string): Promise<ContextItem[]> {
    return this.GET<ContextItem[]>(
      `/core/object/context-items/${objectNameOrId}`,
    );
  }

  public getObjectTypes(): Promise<ObjectType[]> {
    return this.GET<ObjectType[]>(`/core/object/types`);
  }

  public getObjectProperties(typeIdOrName: string): Promise<ObjectProperty[]> {
    return this.GET<ObjectProperty[]>(
      `/core/object/${typeIdOrName}/properties`,
    );
  }

  public getPropertiesValues(objectId: string): Promise<ObjectPropertyValue[]> {
    return this.GET<ObjectPropertyValue[]>(
      `/core/object/properties-values/${objectId}`,
    );
  }

  public getAllPropertyValuesForType(
    typeIdOrName: string,
  ): Promise<ObjectPropertyValue[]> {
    return this.GET<ObjectPropertyValue[]>(
      `/core/object/properties-values/type/${typeIdOrName}`,
    );
  }

  public deletePropertiesValues(objectId: string): Promise<void> {
    return this.DELETE<void>(`/core/object/properties-values/${objectId}`);
  }

  public createOrUpdatePropertyValues(
    objectId: string,
    propertyValueList: SetExtendedPropertiesRequest,
  ): Promise<ObjectPropertyValue[]> {
    return this.POST<ObjectPropertyValue[]>(
      `/core/object/properties-values/${objectId}`,
      propertyValueList,
    );
  }

  public downloadPropertyValueFile(
    objectId: string,
    propertyId: string,
  ): Promise<HttpResponse<Blob>> {
    return this.GET(
      `/core/object/properties-values/download-file?objectId=${objectId}&propertyId=${propertyId}`,
      {
        observe: 'response',
        responseType: 'blob',
      },
    );
  }

  public createObjectProperty(
    property: Partial<ObjectProperty>,
  ): Promise<ObjectProperty> {
    return this.POST<ObjectProperty>('/core/object/properties', property);
  }

  public updateObjectProperty(
    id: string,
    property: Partial<ObjectProperty>,
  ): Promise<ObjectProperty> {
    return this.PATCH<ObjectProperty>(
      `/core/object/properties/${id}`,
      property,
    );
  }

  public deleteObjectProperty(id: string): Promise<void> {
    return this.DELETE<void>(`/core/object/properties/${id}`);
  }

  public createObjectForm(form: Partial<IObjectForm>): Promise<IObjectForm> {
    return this.POST<IObjectForm>('/core/object/form', form);
  }

  public updateObjectForm(
    id: string,
    form: Partial<IObjectForm>,
  ): Promise<IObjectForm> {
    return this.PATCH<IObjectForm>(`/core/object/form/${id}`, form);
  }

  public deleteObjectForm(id: string): Promise<void> {
    return this.DELETE<void>(`/core/object/form/${id}`);
  }
}
