import {Injectable} from '@angular/core';
import {
  Product,
  ProductCategory,
  ProductCategoryPositionUpdate,
} from '../../models';
import {IProductCategoryDataService} from '../../interfaces/data-service';
import {BaseCrudOfflineService} from './base.offline-service';

@Injectable()
export class ProductCategoryOfflineService
  extends BaseCrudOfflineService<ProductCategory>
  implements IProductCategoryDataService
{
  public getForCustomer(customerId: string): Promise<ProductCategory[]> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public getProductsInCategory(categoryId: string): Promise<Product[]> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public checkIfCategoryCanBeDeleted(categoryId: string): Promise<number> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public getProccesProgress(id: string) {
    return Promise.reject('Offline service - not yet implemented');
  }

  public updateProductCategoryPosition(
    pcPositionList: ProductCategoryPositionUpdate[],
  ) {
    return Promise.reject('Offline service - not yet implemented');
  }
}
