<header>
  <!-- Normal version -->
  <ng-container *ngIf="!appInfoService.isMobileVersion || clientModeService.isClientOnline; else offlineModeToolbar">
    <dx-toolbar class="header-toolbar">
      <dxi-item *ngIf="menuToggleEnabled"
                location="before"
                widget="dxButton"
                cssClass="menu-button"
                [options]="{
          disabled: isDataTransferView,
          icon: 'menu',
          stylingMode: 'text',
          onClick: toggleMenu
        }">
      </dxi-item>
      <dxi-item location="before"
                cssClass="header-title"
                *ngIf="title"
                [text]="title"> </dxi-item>
      <dxi-item location="center">
        <div *dxTemplate>
          <div fxFlex="100%"
               fxFlex.gt-sm="400px">
            <dx-select-box #globalSearch
                           id="global-search"
                           [visible]="false"
                           [acceptCustomValue]="true"
                           [items]="searchHistory"
                           (onFocusIn)="openSelectBox($event)"
                           (onValueChanged)="onValueChanged($event)"
                           (onEnterKey)="onGlobalSearchClick($event)"
                           [searchEnabled]="true"
                           [tabIndex]="1"
                           [(text)]="searchValue"
                           customItemCreateEvent="focusout"
                           [buttons]="[
                {
                  name: 'Search',
                  location: 'after',
                  options: searchOptionButton
                }
              ]"
                           placeholder="{{ 'placeholders.search' | translate }}"
                           noDataText="{{ 'labels.global-search.no-items' | translate }}"
                           itemTemplate="historyBoxTemplate">
              <div class="history-template"
                   *dxTemplate="let history of 'historyBoxTemplate'">
                <div class="history-item-text">{{ history }}</div>
                <dx-button class="history-item-button"
                           icon="fa fa-times"
                           preventDefault
                           (onClick)="historyDeleteItem($event, history)"></dx-button>
              </div>
            </dx-select-box>
          </div>
        </div>
      </dxi-item>
      <dxi-item *ngIf="this.appInfoService.isNewClientVersion"
                location="after"
                fxHide
                fxShow.gt-sm>
        <i class="fa-regular fa-cloud-arrow-down"
           style="cursor: pointer"
           id="new-client-version"
           (click)="updateClient()"
           (mouseenter)="toggleTooltipNewClientVersion()"
           (mouseleave)="toggleTooltipNewClientVersion()"></i>
        <dx-tooltip target="#new-client-version"
                    [(visible)]="tooltipNewClientVersion">
          <div *dxTemplate="let data = data; of: 'content'">{{ 'header.new-client-version-available' | translate }}
          </div>
        </dx-tooltip>
      </dxi-item>
      <!--
      <dxi-item location="after" *ngIf="localStorageService.dataSaving | async">
        <span class="data-saving-notification fa fa-download"></span>
      </dxi-item>
      -->
      <dxi-item location="after"
                class="expiration-time"
                [style.color]="fewMinutesLeft ? 'red' : '#afafaf'"
                fxHide
                fxShow.gt-sm>
        <span class="fas fa-hourglass-end"
              title="Session expiration time"></span> {{ tokenExpire }}
      </dxi-item>

      <dxi-item location="before">
        <app-admin-utility-menu></app-admin-utility-menu>
      </dxi-item>

      <dxi-item location="after">
        <div [ngSwitch]="connectivity$ | async">
          <!-- da sa toto zapisat nejako lepsie? ngSwitchCase pre viacero hodnot? -->
          <span *ngSwitchCase="'none'"
                class="fas fa-cloud-slash"
                title="No internet"
                style="color: red"></span>
          <span *ngSwitchCase="'unknown'"
                class="fas fa-cloud-question"
                title="Unkown connection status"
                style="color: darkorange"></span>
          <span *ngSwitchCase="'2G'"
                class="fas fa-cloud-exclamation"
                title="Slow internet"
                style="color: darkorange"></span>
          <span *ngSwitchCase="'3G'"
                class="fas fa-cloud-exclamation"
                title="Slow internet"
                style="color: darkorange"></span>
        </div>
      </dxi-item>

      <dxi-item location="after">
        <app-notifications-icon *ngIf="isMobileVersion"></app-notifications-icon>
      </dxi-item>

      <dxi-item location="after"
                locateInMenu="auto"
                menuItemTemplate="menuItem">
        <div *dxTemplate="let data of 'item'">
          <dx-button class="user-button authorization"
                     height="100%"
                     stylingMode="text"
                     [disabled]="isDataTransferView">
            <div *dxTemplate="let data of 'content'">
              <app-user-panel *ngIf="user$ | async; let user"
                              [user]="user"
                              [menuItems]="userMenuItems"
                              menuMode="context"></app-user-panel>
            </div>
          </dx-button>
        </div>
      </dxi-item>
      <div *dxTemplate="let data of 'menuItem'">
        <app-user-panel *ngIf="user$ | async; let user"
                        [user]="user"
                        [menuItems]="userMenuItems"
                        menuMode="list"></app-user-panel>
      </div>
    </dx-toolbar>
  </ng-container>
  <!-- Normal version end -->

  <!-- Mobile device offline version -->
  <ng-template #offlineModeToolbar>
    <dx-toolbar class="header-toolbar offline">
      <dxi-item *ngIf="menuToggleEnabled"
                location="before"
                widget="dxButton"
                cssClass="menu-button"
                [options]="{
          disabled: isDataTransferView,
          icon: 'menu',
          stylingMode: 'text',
          onClick: toggleMenu
        }">
      </dxi-item>
      <dxi-item location="center">
        <div *dxTemplate>
          <div class="offline-message"
               fxFlex="100%"
               fxFlex.gt-sm="400px"><span class="fa fa-cloud-slash"></span>&nbsp;{{
            'client-mode.toolbar-offline-message' | translate }}
          </div>
        </div>
      </dxi-item>

      <!--
      <dxi-item location="after" *ngIf="localStorageService.dataSaving | async">
        <span class="data-saving-notification fa fa-download"></span>
      </dxi-item>
      -->

      <dxi-item location="after"
                locateInMenu="auto"
                menuItemTemplate="menuItem">
        <div *dxTemplate="let data of 'item'">
          <dx-button class="user-button authorization"
                     height="100%"
                     stylingMode="text"
                     [disabled]="isDataTransferView">
            <div *dxTemplate="let data of 'content'">
              <app-user-panel *ngIf="user$ | async; let user"
                              [user]="user"
                              [menuItems]="userMenuItems"
                              menuMode="context"></app-user-panel>
            </div>
          </dx-button>
        </div>
      </dxi-item>
      <div *dxTemplate="let data of 'menuItem'">
        <app-user-panel *ngIf="user$ | async; let user"
                        [user]="user"
                        [menuItems]="userMenuItems"
                        menuMode="list"></app-user-panel>
      </div>
    </dx-toolbar>
  </ng-template>
  <!-- Mobile device offline version end -->

  <dx-popup #popupCacheManager
            [showTitle]="true"
            [title]="'captions.local-cache' | translate"
            [dragEnabled]="false"
            [showCloseButton]="true"
            container=".dx-viewport"
            [(visible)]="localCacheManagerVisible"
            [fullScreen]="isSmallScreen">
    <dx-button [disabled]="!btnDeleteCachesEnabled"
               [type]="'danger'"
               [icon]="'fa fa-trash'"
               [text]="'buttons.delete' | translate"
               (onClick)="btnDeleteCacheManagerClick($event)"></dx-button>
    <dx-button [icon]="'fa fa-times'"
               [text]="'buttons.cancel' | translate"
               (onClick)="btnCancelCacheManagerClick($event)"></dx-button>
    <dx-list #listCacheManager
             style="margin-top: 10px"
             [dataSource]="caches"
             [keyExpr]="'id'"
             [height]="'100%'"
             [selectionMode]="'all'"
             [selectAllMode]="'allPages'"
             [showSelectionControls]="true"
             [selectedItemKeys]="[]"
             (onSelectionChanged)="listCacheManagerSelectionChanged($event)"></dx-list>
  </dx-popup>
</header>