import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {ScrollEvent} from 'devextreme/ui/scroll_view';
import {map} from 'rxjs/operators';

@Injectable()
/**
 * The service that handles the scroll event.
 */
export class AppScrollService {
  private _scrollEvent$ = new BehaviorSubject<ScrollEvent>(undefined);

  /**
   * The scroll event.
   */
  get scrollEvent$(): Observable<ScrollEvent> {
    return this._scrollEvent$.asObservable();
  }

  /**
   * Whether the scroll has reached the bottom page.
   */
  reachedBottom$ = this.scrollEvent$.pipe(map(event => !!event && !!event.reachedBottom));

  /**
   * Whether the scroll has reached the left page.
   */
  reachedLeft$ = this.scrollEvent$.pipe(map(event => !!event && !!event.reachedLeft));
  /**
   * Whether the scroll has reached the right page.
   */
  reachedRight$ = this.scrollEvent$.pipe(map(event => !!event && !!event.reachedRight));
  /**
   * Whether the scroll has reached the top page.
   */
  reachedTop$ = this.scrollEvent$.pipe(map(event => !!event && !!event.reachedTop));
  /**
   * The scroll offset.
   */
  scrollOffset$ = this.scrollEvent$.pipe(map(event => event.scrollOffset));

  setScrollEvent(scrollEvent: ScrollEvent) {
    this._scrollEvent$.next(scrollEvent);
  }
}
