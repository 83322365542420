import {ClientMode} from '../enums/client-mode.enum';
import {BaseModel} from './base.model';
import {TodoActionResult} from './todo-action-result.model';
import {TodoList} from './todo-list.model';
import {User} from './user.model';
import {TourPlanState} from '@retrixhouse/salesapp-shared/lib/models';

export class TodoListResult extends BaseModel {
  version: number;
  startedAt?: Date;
  finishedAt?: Date;
  mode?: ClientMode;
  tourPlanVisitId: string;
  todoListId: string;
  userId: string;
  todoList: TodoList;
  todoActionResults?: TodoActionResult[];
  user: User;
  editedBy?: string;
  editedAt?: Date;
  editedTourPlanState?: TourPlanState;
  highlightColor?: string;

  constructor() {
    super();
    this.version = 1;
  }
}

export class ProjectStoreTodoListResult {
  projectId: string;
  storeId: string;
  result: TodoListResult;
}
