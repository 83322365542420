import {OldBaseStorageService} from './old-base-storage-service';
import {DataProvider} from '../../data.provider/data-provider';
import {IClientSideError} from '@retrixhouse/salesapp-shared/lib/models';
import {Injectable} from '@angular/core';
import {ClientSideErrorDataService} from '../data';

@Injectable()
export class ClientSideErrorStorageService extends OldBaseStorageService<
  IClientSideError,
  IClientSideError[]
> {
  get clientSideErrorDataService() {
    return this.dataService as ClientSideErrorDataService;
  }

  constructor(dataProvider: DataProvider) {
    super(dataProvider.clientSideError);
  }

  uploadErrors(input: IClientSideError[]) {
    return this.command({
      commandMethod$: this.upload.bind(this),
      commandInput: input,
    });
  }

  private upload(errors: IClientSideError[]) {
    return this.clientSideErrorDataService.addErrors(errors);
  }
}
