import * as uuid from 'uuid';
import {GenericList, GenericListItem} from './generic-list.model';
import {Product} from './product.model';
import {Questionnaire} from './questionnaire-model';
import {TodoAction} from './todo-action.model';
import {MatrixMode} from '@retrixhouse/salesapp-shared/lib/models';

export class TodoList {
  id: string;
  uid: string;
  version: number;
  name: string;
  description: string;
  itemPresentation: string;
  customerId: string;
  todoActions: TodoListItem[];

  constructor() {
    this.id = uuid.v4();
    this.name = null;
    this.description = null;
  }
}

export class TodoListItem {
  id: string;
  actionId: string;
  listId: string;
  questionnaireId: string;
  position: number;
  measured: boolean;
  conditionExpression: string;
  requiredCondition?: string;
  parentId: string;
  reasonListId?: string | null;
  type: TodoListItemType;
  required: boolean;
  requiredPhotos: boolean;
  allowMultipleQuestionnaires: boolean;
  productSelection: ProductSelectionType;
  questionnaireResultPreload: QuestionnairePreload;
  todoListItemProductList?: TodoListItemProduct[];
  todoListItemProductCategoryList?: TodoListItemProductCategory[];
  todoListItemFreeTextList?: TodoListItemFreeText[];
  matrixMode: MatrixMode;
  todoAction: TodoAction;
  questionnaire: Questionnaire;
  reasonList: GenericList;

  // extra properties
  hasSubQuestions?: boolean;
  hasParent?: boolean;
  questionNumber?: string;
  productIdList: string[];
  productCategoryIdList: string[];
  reasonListItems: (GenericListItem & {
    visible?: boolean;
    disabled?: boolean;
  })[];

  constructor() {
    this.id = uuid.v4();
    this.questionnaireId = null;
    this.parentId = null;
    this.position = null;
    this.measured = false;
    this.conditionExpression = null;
  }
}

export class TodoListItemProduct {
  id: string;
  productId: string;
  todoListItemId: string;
  product: Product;
}

export class TodoListItemFreeText {
  id: string;
  freeTextId: string;
  todoListItemId: string;
  position: number;
  freeText: FreeText;
}

export class FreeText {
  id: string;
  text: string;
}

export class TodoListItemProductCategory {
  id: string;
  productCategoryId: string;
  todoListItemId: string;
}

export enum ItemPresentation {
  OneByOne = 'OneByOne',
  AllAtOnce = 'AllAtOnce',
}

export enum TodoListItemType {
  General = 'General',
  Questionnaire = 'Questionnaire',
  QuestionnaireProductMatrix = 'QuestionnaireProductMatrix',
  QuestionnaireFreeTextMatrix = 'QuestionnaireFreeTextMatrix',
  PersonalArrangement = 'PersonalArrangement',
  Actions = 'Actions',
  Order = 'Order',
  Sampling = 'Sampling',
}

export enum ProductSelectionType {
  ProductListingAll = 'ProductListingAll',
  ProductListingSelected = 'ProductListingSelected',
  ProductsAndCategories = 'ProductsAndCategories',
}

export enum QuestionnairePreload {
  NoPreload = 'no-preload',
  PreloadAsHint = 'preload-as-hint',
  PreloadAsValue = 'preload-as-value',
}
