import {EventEmitter, Injectable, OnDestroy} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import {RoleNames} from '@retrixhouse/salesapp-shared/lib/common';
import {
  KeycloakAuthGuard,
  KeycloakEvent,
  KeycloakService,
} from 'keycloak-angular';
import {KeycloakProfile, KeycloakTokenParsed} from 'keycloak-js';
import {Subscription} from 'rxjs';
import {DataProvider} from 'src/app/shared/data.provider/data-provider';
import {Position, UserProfile, UsernameResponse} from 'src/app/shared/models';
import {AppInterfaceService} from '../app-interface/app-interface.service';
import {UserStorageKeys} from '../globals';
import {AppInfoService} from './app-info.service';

@Injectable()
export class AuthGuardService extends KeycloakAuthGuard implements OnDestroy {
  public profilePictureUpdated: EventEmitter<string>;
  private position: Position;
  private enabledRouteIds: Set<string>;
  private $keycloakEventsSubscription: Subscription;

  constructor(
    protected readonly router: Router,
    protected readonly keycloak: KeycloakService,
    protected readonly dataProvider: DataProvider,
    protected readonly appInterfaceService: AppInterfaceService,
    protected readonly appInfoService: AppInfoService,
  ) {
    super(router, keycloak);
    this.profilePictureUpdated = new EventEmitter<string>();
    this.$keycloakEventsSubscription = this.keycloakAngular.keycloakEvents$
      .asObservable()
      .subscribe(this.keycloakEventHandler);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean | UrlTree> {
    // NOTE: I don't like this implementation
    return super.canActivate(route, state).then(canActivate => {
      if (!canActivate && this.appInfoService.isMobileVersion) {
        this.appInterfaceService.clientMessage('userInactive');
      }

      return canActivate;
    });
  }

  keycloakEventHandler(e: KeycloakEvent): void {
    console.log('---> AuthGuardService.keycloakEventHandler()', e);
  }

  ngOnDestroy(): void {
    if (this.$keycloakEventsSubscription) {
      this.$keycloakEventsSubscription.unsubscribe();
    }
  }

  public async isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean> {
    // Force the user to log in if currently unauthenticated.
    if (!this.authenticated) {
      await this.keycloak.login({
        redirectUri: window.location.toString(),
      });
      return false;
    }

    if (!this.position) {
      this.position = await this.dataProvider.position.getForUser();
      this.enabledRouteIds = new Set<string>(this.position?.appRoutes ?? []);
    }

    if (route.data?.id) {
      const isAllowed = this.enabledRouteIds.has(route.data.id);
      if (!isAllowed) {
        console.error(`Requested route is not allowed`);
      }
      return isAllowed;
    }

    return true;
  }

  public accountManagement() {
    return window.open(this.keycloak.getKeycloakInstance().createAccountUrl());
  }

  public isTokenExpired(): boolean {
    return this.keycloakAngular.isTokenExpired();
  }

  public isTokenAboutToExpire(): boolean {
    return this.keycloakAngular.isTokenExpired(60);
  }

  public getParsedKeycloakToken(): KeycloakTokenParsed {
    return this.keycloak.getKeycloakInstance().idTokenParsed;
  }

  public isAuthenticated(): boolean {
    return this.authenticated;
  }

  public login(options?: Keycloak.KeycloakLoginOptions): Promise<void> {
    return this.keycloakAngular.login(options).then(() => {
      if (this.appInfoService.isMobileVersion) {
        this.appInterfaceService.clientMessage('userActive').then(
          () => {
            console.log(
              'AppComponent.constructor - clientMessage:userActive succeeded',
            );
          },
          error => {
            console.log(
              'AppComponent.constructor - clientMessage:userActive failed',
              error,
            );
          },
        );
      }
    });
  }

  // When user logout manually or automatically,
  // store current time to user storage
  public async logout(): Promise<void> {
    if (this.authenticated) {
      await this.dataProvider.userStorage.set(
        UserStorageKeys.LastLogoutTimestamp,
        new Date().getTime(),
      );
    }
    return this.keycloak.logout(window.location.toString()).then(() => {
      if (this.appInfoService.isMobileVersion) {
        this.appInterfaceService.clientMessage('userInactive').then(
          () => {
            console.log(
              'AppComponent.constructor - clientMessage:userInactive succeeded',
            );
          },
          error => {
            console.log(
              'AppComponent.constructor - clientMessage:userInactive failed',
              error,
            );
          },
        );
      }
    });
  }

  public async getToken(): Promise<string> {
    return this.keycloak.getToken();
  }

  public async getUsername(): Promise<string> {
    await this.keycloak.loadUserProfile(false);
    return this.keycloak.getUsername();
  }

  public async getProfile(): Promise<KeycloakProfile> {
    return this.keycloak.loadUserProfile(false);
  }

  public async getUserProfile(): Promise<UserProfile> {
    return this.dataProvider.user.getMyProfile();
  }

  public async getMyUsernameResponse(): Promise<UsernameResponse> {
    return this.dataProvider.user.getMyUsernameResponse();
  }

  public async getUserPosition(): Promise<Position> {
    return this.dataProvider.position.getForUser();
  }

  public updateProfilePicture(picture: string): void {
    this.profilePictureUpdated.emit(picture);
  }

  // USER ROLES
  public hasRole(role: string): boolean {
    const roles = this.keycloak.getUserRoles();
    return roles.includes(role);
  }

  public hasRoleAny(...roles: string[]): boolean {
    if (roles) {
      const userRoles = this.keycloak.getUserRoles();
      for (const role of roles) {
        if (role.endsWith('*')) {
          const prefix = role.slice(0, -1);
          if (userRoles.findIndex(r => r.startsWith(prefix)) >= 0) {
            return true;
          }
        } else {
          if (userRoles.includes(role)) {
            return true;
          }
        }
      }
    }

    return false;
  }

  public hasRoleAll(...roles: string[]): boolean {
    if (roles) {
      const userRoles = this.keycloak.getUserRoles();
      if (Array.isArray(userRoles) && userRoles.length > 0) {
        for (const role of roles) {
          if (role.endsWith('*')) {
            const prefix = role.slice(0, -1);
            if (userRoles.findIndex(r => r.startsWith(prefix)) === -1) {
              return false;
            }
          } else {
            if (!userRoles.includes(role)) {
              return false;
            }
          }
        }
        return true;
      }
    }
    return false;
  }

  public isAdmin(): boolean {
    return this.hasRole(RoleNames.Admin);
  }

  public async isSystem(): Promise<boolean> {
    return this.hasRole(RoleNames.System);
  }

  public hasAccess(...roles: string[]): boolean {
    return (
      !Array.isArray(roles) ||
      roles.length === 0 ||
      this.isAdmin() ||
      this.hasRoleAny(...roles)
    );
  }
}
