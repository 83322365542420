import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {CopyIconModule} from '../copy-icon';
import {CopyTextComponent} from './copy-text.component';

const UI_MODULES = [CopyIconModule];

@NgModule({
  imports: [CommonModule, ...UI_MODULES],
  declarations: [CopyTextComponent],
  exports: [CopyTextComponent],
})
export class CopyTextModule {}
