import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FlexLayoutModule} from '@angular/flex-layout';
import {DxListComponent, DxListModule} from 'devextreme-angular/ui/list';
import {DevExtremeModule, DxTextAreaComponent} from 'devextreme-angular';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {environment} from 'src/environments/environment';
import {Comment} from '../../models/comment.model';

import {
  ConfirmationService,
  ConfirmType,
} from '../../services/confirmation.service';
import {AuthGuardService, ScreenService} from '../../services';
import {CustomPipesModule} from '../../pipes';
import {UserInfo} from '../../models/user-info.model';
import {UserAvatarModule} from '../user-avatar/user-avatar.component';
import {v4 as uuid} from 'uuid';
import {UsernameResponse} from '../../models/username-response.model';
import {sortByDate} from '../../globals';
import {User} from '../../models';
import {Subscription} from 'rxjs';

const urlValidation =
  /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;

@Component({
  selector: 'app-comment',
  templateUrl: 'comment.component.html',
  styleUrls: ['./comment.component.scss'],
})
export class CommentComponent implements OnInit, OnChanges, OnDestroy {
  /**
   * The id object to which the comments are related.
   */
  @Input() objectId: string;
  /**
   * The list of comments related to the provided object id.
   */
  @Input() commentList: Comment[];
  /**
   * Whether the component is read-only or not.
   */
  @Input() readOnly: boolean = false;
  /**
   * An event emits when a new comment is added.
   * Emitted data is the new comment.
   */
  @Output() onCommentAdded = new EventEmitter<Comment>();
  /**
   * An event emits when comment/s deleted.
   * Emitted data is the list of deleted comments.
   */
  @Output() onCommentDeleted = new EventEmitter<string[]>();

  @ViewChild(DxListComponent, {static: false})
  dxList: DxListComponent;
  @ViewChild(DxTextAreaComponent, {static: false})
  dxTextArea: DxTextAreaComponent;

  maxLength: number = 32265;
  isAdmin: boolean = false;
  lastAddedCmnt: string = '';
  currentUser: UserInfo = {};
  br = '<br/>';
  largeScreen: boolean;

  $screenChangeSubscription: Subscription;

  constructor(
    private translate: TranslateService,
    private confirmationService: ConfirmationService,
    private authService: AuthGuardService,
    private screenService: ScreenService,
  ) {
    this.largeScreen = this.screenService.isLargeScreen();
    this.$screenChangeSubscription = this.screenService.changed.subscribe(
      () => (this.largeScreen = this.screenService.isLargeScreen()),
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    const commentList =
      (changes['commentList']?.currentValue as Comment[]) ?? [];
    if (commentList.length > 0) {
      sortByDate(commentList, 'postedAt');
      this.commentList = commentList.map(item => {
        // check if comment includes valid url
        if (urlValidation.test(item.comment)) {
          //if yes, create hyperlink from it
          //otherwise return item
          item.comment.match(urlValidation).map(url => {
            const replaced = item.comment.replace(
              url,
              `<a href="${url}" target="_blank">${url}</a>`,
            );
            item.comment = replaced;
          });
          return item;
        }

        return item;
      });
    } else {
      this.commentList = [];
    }
  }

  async ngOnInit() {
    this.isAdmin = this.authService.isAdmin();

    // TODO from where to get this during the visit?
    // this.dataProvider
    //   .getJsonSchemas(ObjectTypeNames.Comment)
    //   .pipe(
    //     map(object => {
    //       this.maxLength = object['comment'].maxLength;
    //     }),
    //   )
    //   .toPromise();

    this.authService
      .getUsername()
      .then(username => (this.currentUser.username = username));
    this.authService.getUserProfile().then(p => {
      this.currentUser.firstName = p.firstName;
      this.currentUser.lastName = p.lastName;
      this.currentUser.email = p.email;
      this.currentUser.profile = p;
      (this.currentUser.user ??= <User>{}).id = p.userId;
      this.currentUser.uid = p.uid;
    });
    this.authService
      .getUserPosition()
      .then(p => (this.currentUser.position = p));
  }

  ngOnDestroy(): void {
    this.$screenChangeSubscription.unsubscribe();
  }

  async onDeleteAll(e) {
    const dialogResult = await this.confirmationService.confirm(
      ConfirmType.DELETE,
      this.translate.instant('view.comment.delete-all-comment'),
      this.translate.instant('view.comment.delete-all-comment-confirm'),
    );

    if (!dialogResult) {
      return;
    }

    this.onCommentDeleted.emit(this.commentList.map(m => m.id));
    this.commentList = [];
  }

  async btnDeleteClick(item) {
    const dialogResult = await this.confirmationService.confirm(
      ConfirmType.DELETE,
      this.translate.instant('view.comment.delete-comment'),
      this.translate.instant('view.comment.delete-comment-confirm'),
    );

    if (!dialogResult) {
      return;
    }

    this.commentList = this.commentList.filter(f => f.id !== item.id);
    this.onCommentDeleted.emit([item.id]);
  }

  btnSaveClick(e) {
    const comment = new Comment();
    comment.comment = this.dxTextArea.text;
    comment.objectId = this.objectId;
    comment.postedAt = new Date();
    comment.postedById = this.currentUser.user.id;
    comment.id = uuid();
    this.lastAddedCmnt = comment.id;
    this.setProfilePicture(comment, this.currentUser?.profile?.picture);
    comment.userInfo = <UsernameResponse>{
      id: this.currentUser?.id,
      username: this.currentUser?.username,
      firstName: this.currentUser?.profile?.firstName,
      lastName: this.currentUser?.profile?.lastName,
      middleName: this.currentUser?.profile?.middleName,
      profilePicture: this.currentUser?.profile?.picture,
      positionName: this.currentUser?.position?.name,
      uid: this.currentUser?.uid,
    };
    this.commentList.push(comment);
    this.dxTextArea.instance.reset();
    this.onCommentAdded.emit(comment);
  }

  onContentReady(e) {
    const commentId = this.lastAddedCmnt;
    if (commentId) {
      setTimeout(() => {
        document.getElementById(commentId).scrollIntoView({behavior: 'smooth'});
      });

      this.lastAddedCmnt = '';
    }
  }

  private setProfilePicture(comment: Comment, picture: string) {
    if (this.currentUser?.profile?.picture) {
      const timestamp = new Date().getTime();
      comment.pictureUrl = `${environment.profilePictureBaseUrl}${picture}?t=${timestamp}`;
    } else {
      comment.pictureUrl = `${environment.profilePictureBaseUrl}/assets/no-avatar.png`;
    }
  }
}

@NgModule({
  imports: [
    DxListModule,
    DevExtremeModule,
    CommonModule,
    FlexLayoutModule,
    TranslateModule,
    CustomPipesModule,
    UserAvatarModule,
  ],
  declarations: [CommentComponent],
  exports: [CommentComponent],
})
export class CommentModule {}
