import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ReportExternal} from '@salesapp/api';
import {DialogService} from '@salesapp/dialog';
import {ExternalReportStorage} from '@salesapp/storage';
import {ExternalReportDetailDialogComponent} from '../components/external-report-detail-dialog/external-report-detail-dialog.component';
import {
  ExternalReportEditDialogComponent,
  ExternalReportEditDialogData,
} from '../components/external-report-edit-dialog/external-report-edit-dialog.component';

@Injectable()
export class ExternalReportService {
  constructor(
    private dialogService: DialogService,
    private translateService: TranslateService,
    private externalReportStorage: ExternalReportStorage,
  ) {}

  openExternalReport(externalReport: ReportExternal) {
    switch (externalReport.viewMode) {
      case 'NewTab':
        window.open(externalReport.url, '_blank');
        break;
      default:
        const width =
          externalReport.viewMode === 'FullScreenPopup'
            ? '100%'
            : externalReport.width
            ? `${externalReport.width}px`
            : '80%';
        const height =
          externalReport.viewMode === 'FullScreenPopup'
            ? '100%'
            : externalReport.height
            ? `${externalReport.height}px`
            : '80%';

        this.dialogService.open(ExternalReportDetailDialogComponent, {
          width,
          height,
          data: {
            externalReport,
          },
        });
        break;
    }
  }

  createExternalReport() {
    this.openEditExternalReportDialog();
  }

  editExternalReport(externalReport: ReportExternal) {
    this.openEditExternalReportDialog({externalReport});
  }

  deleteExternalReport(externalReport: ReportExternal) {
    return this.dialogService
      .danger({
        acceptLabel: this.translateService.instant('buttons.delete'),
        rejectLabel: this.translateService.instant('buttons.cancel'),
        showCloseButton: false,
        title: this.translateService.instant(
          'entity.external-report.dialog.delete.title',
        ),
      })
      .closed.subscribe({
        next: response => {
          if (response === 'accepted') {
            this.externalReportStorage.delete({
              id: externalReport.id,
            });
          }
        },
      });
  }

  private openEditExternalReportDialog(data?: ExternalReportEditDialogData) {
    this.dialogService.open<ExternalReportEditDialogComponent>(
      ExternalReportEditDialogComponent,
      {
        data,
        width: '60%',
        height: '60%',
      },
    );
  }
}
