import {BaseModel} from './base.model';
import {SettingValueType} from '@retrixhouse/salesapp-shared/lib/models';

export class Setting<T = any> extends BaseModel {
  id: string;
  name: string;
  group: string;
  valueType: SettingValueType;
  value?: T;
  defaultValue?: string;
  position: number;
  groupPosition: number;
  validationExpression?: string;
  genericListId?: string;
  objectTypeId?: string;

  constructor() {
    super();
    this.name = null;
    this.group = null;
    this.valueType = null;
  }
}
