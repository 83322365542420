import * as uuid from 'uuid';
import {UsernameResponse} from './username-response.model';
import {IDataStorageFile} from '@retrixhouse/salesapp-shared/lib/models';

export class DataStorageFile implements IDataStorageFile {
  id: string;
  name: string;
  contentType: string;
  size: number;
  type: string;
  visibleFlag: boolean;
  visibleFrom: Date;
  visibleTo: Date;
  uploadedAt: Date;
  folderId: string;
  userId: string;
  [key: string]: any;
  assignedIcon: string;
  readableSize: string;
  userInfo?: UsernameResponse;

  constructor() {
    this.id = uuid.v4();
    this.name = null;
    this.contentType = null;
    this.size = 0;
    this.type = null;
    this.visibleFlag = true;
    this.visibleFrom = null;
    this.visibleTo = null;
    this.uploadedAt = null;
    this.folderId = null;
    this.userId = null;
  }
}

export class UploadedFileDescriptor {
  fieldName: string;
  originalName: string;
  encoding: string;
  mimetype: string;
  size: number;
  // data: Buffer;
  filename: string;
  path: string;
  errorMessage: string;
}
