import {BaseCrudOfflineService} from './base.offline-service';
import {IClientSideError} from '@retrixhouse/salesapp-shared/lib/models';
import {IClientSideErrorDataService} from '../../interfaces/data-service/client-side-error.iface';
import {Injectable} from '@angular/core';

@Injectable()
export class ClientSideErrorOfflineService
  extends BaseCrudOfflineService<IClientSideError>
  implements IClientSideErrorDataService
{
  uploadErrors(errors: IClientSideError[]): Promise<number> {
    throw new Error('Method not implemented.');
  }
}
