import {ObjectMapCache} from '@retrixhouse/salesapp-shared/lib/caching';
import {IKPISetDataService} from '../../interfaces/data-service';
import {KPISet} from '../../models';
import {KPISetHttpService} from '../http';
import {KPISetOfflineService} from '../offline';
import {BaseCrudDataService} from './base.data-service';

export class KPISetDataService extends BaseCrudDataService<
  KPISet,
  IKPISetDataService,
  ObjectMapCache<string, KPISet>
> {
  constructor(
    onlineService: KPISetHttpService,
    offlineService: KPISetOfflineService,
    cache: ObjectMapCache<string, KPISet>,
  ) {
    super(onlineService, offlineService, cache);
  }
}
