import {Injectable} from '@angular/core';
import {BaseHttpService} from './base.http-service';
import {HttpClient} from '@angular/common/http';
import {IObjectReadDataService} from '../../interfaces/data-service';

@Injectable()
export class ObjectReadHttpService
  extends BaseHttpService
  implements IObjectReadDataService
{
  constructor(http: HttpClient) {
    super(http);
  }

  public isObjectRead(objectId: string): Promise<boolean> {
    return this.GET<boolean>('/core/object-read/' + objectId);
  }

  public areObjectsRead(
    objectIds: string | string[],
  ): Promise<{[objectId: string]: boolean}> {
    const config = {headers: {'Content-Type': 'application/json'}};
    return this.POST<{[objectId: string]: boolean}>(
      '/core/object-read',
      Array.isArray(objectIds) ? objectIds : JSON.stringify(objectIds),
      config,
    );
  }

  public markObjectAsRead(objectIds: string | string[]): Promise<void> {
    const config = {headers: {'Content-Type': 'application/json'}};
    return this.POST<void>(
      '/core/object-read/mark',
      Array.isArray(objectIds) ? objectIds : JSON.stringify(objectIds),
      config,
    );
  }

  public markObjectAsUnread(objectIds: string | string[]): Promise<void> {
    const config = {headers: {'Content-Type': 'application/json'}};
    return this.POST<void>(
      '/core/object-read/unmark',
      Array.isArray(objectIds) ? objectIds : JSON.stringify(objectIds),
      config,
    );
  }
}
