import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Country} from '../../models';
import {BaseReadonlyHttpService} from './base.http-service';
import {ICountryDataService} from '../../interfaces/data-service';

@Injectable()
export class CountryHttpService
  extends BaseReadonlyHttpService<Country>
  implements ICountryDataService
{
  constructor(http: HttpClient) {
    super(http, '/core/countries');
  }
}
