import {Component, Input, NgModule, OnDestroy, OnInit} from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterModule,
} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {distinctUntilChanged, filter} from 'rxjs/operators';
import {IBreadCrumb} from './breadcrumb.interface';
import {CommonModule} from '@angular/common';
import {FullScreenService} from '../../services/full-screen.service';
import {ScreenService} from '../../services';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit, OnDestroy {
  @Input() public breadcrumbs: IBreadCrumb[];
  @Input() public readOnly = false;
  public defaultBreadcrumbs: IBreadCrumb[];
  isFullScreen: boolean = false;
  isSmallScreen: boolean;

  $screenChangeSubscription: Subscription;
  $fullScreenChangeSubscription: Subscription;
  $routeChangeSubscription: Subscription;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService,
    private fullScreen: FullScreenService,
    private screenService: ScreenService,
  ) {
    if (!this.breadcrumbs) {
      this.defaultBreadcrumbs = this.buildBreadCrumb(this.activatedRoute.root);
    }

    // this.translate.onLangChange.subscribe(() => {
    //   if (this.defaultBreadcrumbs) {
    //     for (const b of this.defaultBreadcrumbs) {
    //       if (b.i18n) {
    //         b.label = this.translate.instant(b.i18n);
    //       }
    //     }
    //   }
    // });

    this.$fullScreenChangeSubscription = this.fullScreen.fullScreen.subscribe(
      value => (this.isFullScreen = value),
    );
    this.isSmallScreen = this.screenService.isSmallScreen();

    this.$screenChangeSubscription = this.screenService.changed.subscribe(
      n => (this.isSmallScreen = this.screenService.isSmallScreen()),
    );
  }

  ngOnInit(): void {
    if (!this.breadcrumbs) {
      this.$routeChangeSubscription = this.router.events
        .pipe(
          filter(event => event instanceof NavigationEnd),
          distinctUntilChanged(),
        )
        .subscribe(() => {
          this.defaultBreadcrumbs = this.buildBreadCrumb(
            this.activatedRoute.root,
          );
        });
    }
  }

  ngOnDestroy(): void {
    if (this.$screenChangeSubscription) {
      this.$screenChangeSubscription.unsubscribe();
    }

    if (this.$fullScreenChangeSubscription) {
      this.$fullScreenChangeSubscription.unsubscribe();
    }

    if (this.$routeChangeSubscription) {
      this.$routeChangeSubscription.unsubscribe();
    }
  }


  /**
   * Recursively build breadcrumb according to activated route.
   * @param route
   * @param url
   * @param breadcrumbs
   */
  buildBreadCrumb(
    route: ActivatedRoute,
    url: string = '',
    breadcrumbs: IBreadCrumb[] = [],
  ): IBreadCrumb[] {
    // If no routeConfig is avalailable we are on the root path
    let path = route?.routeConfig?.path ?? '';
    let i18n = route?.routeConfig?.data?.i18n;
    let label;

    if (route?.routeConfig?.data?.i18n) {
      label = this.translate.instant(route?.routeConfig?.data?.i18n);
    } else if (route?.routeConfig?.data?.breadcrumb) {
      label = route.routeConfig.data.breadcrumb;
    } else {
      label = '';
    }

    // If the route is dynamic route such as ':id', remove it
    const lastRoutePart = path.split('/').pop();
    const isDynamicRoute = lastRoutePart.startsWith(':');
    if (isDynamicRoute && !!route.snapshot) {
      const paramName = lastRoutePart.split(':')[1];
      path = path.replace(lastRoutePart, route.snapshot.params[paramName]);

      if (!label) {
        label = route.snapshot.params[paramName];
      }
    }

    // In the routeConfig the complete path is not available,
    // so we rebuild it each time
    const nextUrl = path ? `${url}/${path}` : url;

    const breadcrumb: IBreadCrumb = {
      label,
      url: nextUrl,
      i18n: i18n,
    };

    // Only adding route with non-empty label
    const newBreadcrumbs = breadcrumb.label
      ? [...breadcrumbs, breadcrumb]
      : [...breadcrumbs];
    if (route.firstChild) {
      // If we are not on our current path yet,
      // there will be more children to look after, to build our breadcumb
      return this.buildBreadCrumb(route.firstChild, nextUrl, newBreadcrumbs);
    }

    return newBreadcrumbs;
  }
}

@NgModule({
  declarations: [BreadcrumbComponent],
  imports: [CommonModule, RouterModule],
  exports: [BreadcrumbComponent],
})
export class BreadcrumbModule {}
