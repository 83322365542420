import {DynamicFormAvatarUploadedEvent} from '@salesapp/dynamic-form';
import {DynamicFormComponent} from '../../../dynamic-form/components/dynamic-form/dynamic-form.component';
import {PermissionResolverService} from '@salesapp/services';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  ObjectTypeIds,
  RoleNames,
} from '@retrixhouse/salesapp-shared/lib/common';

@Component({
  selector: 'app-object-detail-dynamic-form',
  templateUrl: './object-detail-dynamic-form.component.html',
  styleUrls: ['./object-detail-dynamic-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ObjectDetailDynamicFormComponent {
  @Input() initialValue: any;
  @Input() objectTypeId: ObjectTypeIds;
  @Input() readonlyRoles: RoleNames[];
  @Input() editRoles: RoleNames[];
  @Input() actionDisabled: boolean;
  @Input() cancelDisabled: boolean;

  @Output() actionClicked = new EventEmitter<any>();
  @Output() cancelClicked = new EventEmitter<unknown>();

  // TODO: REFACTORING SA-3375 remove this emitter when photo upload will be implemented the new way
  @Output() avatarPhotoUploaded =
    new EventEmitter<DynamicFormAvatarUploadedEvent>();

  @ViewChild(DynamicFormComponent) private dynamicForm: DynamicFormComponent;

  get hasChanges() {
    // NOTE: in case when dynamic form is not rendered (user do not have access based on roles)
    return this.dynamicForm ? this.dynamicForm.hasChanges : false;
  }

  get visible() {
    return this.readonlyAccess || this.canEdit;
  }

  get readonlyAccess() {
    return (
      this.permissionResolverService.hasRoleAny(this.readonlyRoles) &&
      !this.canEdit
    );
  }

  get canEdit() {
    return this.permissionResolverService.hasRoleAny(this.editRoles);
  }

  constructor(private permissionResolverService: PermissionResolverService) {}
}
