import {GenericListItem} from './generic-list.model';
import {Photo} from './photo.model';
import {Product} from './product.model';
import * as moment from 'moment';
import {ProductListingWithProductIds} from './responses/visit-data.response';
import {UsernameResponse} from './username-response.model';

export class PersonalArrangement {
  id: string;
  displayId: string;
  display?: GenericListItem;
  driveId?: string;
  drive?: GenericListItem;
  positionId?: string;
  position?: GenericListItem;
  displayFrom: Date;
  displayTo: Date;
  retakePhotoRequired?: Date;
  invalid?: boolean;
  reason?: string;
  amount: number;
  todoActionResultId: string;
  products?: Product[];
  notes: string;

  constructor(pa: PersonalArrangement) {
    this.id = pa.id;
    this.displayId = pa.displayId;
    this.display = pa.display;
    this.driveId = pa.driveId;
    this.drive = pa.drive;
    this.positionId = pa.positionId;
    this.position = pa.position;
    this.displayFrom = pa.displayFrom;
    this.displayTo = pa.displayTo;
    this.retakePhotoRequired = pa.retakePhotoRequired;
    this.invalid = pa.invalid;
    this.reason = pa.reason;
    this.amount = pa.amount;
    this.todoActionResultId = pa.todoActionResultId;
    this.products = pa.products;
    this.notes = pa.notes;
  }
}

export class PersonalArrangementReport extends PersonalArrangement {
  createdAt: Date;
  displayName?: string;
  driveName?: string;
  positionName?: string;
  projectId: string;
  projectUid: string;
  projectName: string;
  productIds: string;
  products: Product[];
  productUids: string;
  productNames: string;
  visitId: string;
  visitUid: string;
  userId: string;
  username: string;
  userUid: string;
  firstName: string;
  lastName: string;
  superior: {
    internal?: UsernameResponse[][] | null,
    external?: UsernameResponse[][] | null
  };
  profilePicture: string;
  storeAgencyRegionId: string;
  storeName: string;
  storeUid: string;
  storeId: string;
  storeAddressLine1: string;
  storeAddressLine2: string;
  storeAddressZip: string;
  storeAddressCity: string;
  storeAddressState: string;
  storeChainSpecificId: string;
  chainName: string;
  chainLogo: string;
  photo: string;
  photoIds: string;
  photos: Photo[];
  listedProductIdList: ProductListingWithProductIds[];
}

export class PersonalArrangementRequest {
  from: Date;
  to: Date;
  projectId: string;
  executorIds?: string[];
  displayId?: string;
  driveId?: string;
  positionId?: string;
  chainId?: string;
  agencyRegionId?: string;
  city?: string;
}

/**
 * Returns `true` when the retakePhotoRequired date of the provided personal arrangement is less than the current date.
 * @param {PersonalArrangement} pa
 * @returns {boolean} `true` when the photo retaking is required. Otherwise, `false`.
 */
export const retakePhoto = (pa: PersonalArrangement): boolean => {
  if (!pa) {
    return false;
  }
  const now = new Date();
  if (moment(pa.retakePhotoRequired).isBefore(now)) {
    return true;
  } else {
    return false;
  }
};
