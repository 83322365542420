import {ChangeDetectionStrategy, Component} from '@angular/core';
import {getGridServiceProvider} from '@salesapp/grid';
import {FeedbackGridService} from '../../services/feedback-grid.service';

@Component({
  selector: 'app-feedback-grid',
  templateUrl: './feedback-grid.component.html',
  styleUrls: ['./feedback-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [getGridServiceProvider(FeedbackGridService)],
})
export class FeedbackGridComponent {}
