<!-- TODO: remove unused attributes -->
<app-dialog [title]="'form-designer.dialog.edit-group.title' | translate"
            [actionLabel]="'form.action.save' | translate"
            [actionVisible]="true"
            [actionIcon]=""
            [cancelLabel]="'form.action.close' | translate"
            [cancelDisabled]=""
            [cancelVisible]="true"
            [cancelIcon]=""
            [formGroup]="form"
            (cancelClicked)="onClose()"
            (actionClicked)="onSave()">
  <app-form-row>
    <app-form-column>
      <app-input-text [formControl]="getFormControl('title')"
                      [formControlRef]="getFormControl('title')"
                      [label]="'form-designer.form-group.title.label' | translate"></app-input-text>
    </app-form-column>
  </app-form-row>
  <app-form-row>
    <app-form-column>
      <app-input-text-area [formControl]="getFormControl('visibilityExpression')"
                           [formControlRef]="getFormControl('visibilityExpression')"
                           [label]="'form-designer.form-group.visibility-expression.label' | translate"
                           [height]="120"></app-input-text-area>
    </app-form-column>
  </app-form-row>
</app-dialog>