import {InclusionFilter} from '@loopback/filter';
import {
  AddressPropertyIds,
  CountryPropertyIds,
  CurrencyPropertyIds,
  CustomerPropertyIds,
  ObjectTypeIds,
  PositionPropertyIds,
  ProductCategoryPropertyIds,
  ProductPropertyIds,
  UserProfilePropertyIds,
  UserPropertyIds,
} from '@retrixhouse/salesapp-shared/lib/common';
import {IObjectProperty} from '@retrixhouse/salesapp-shared/lib/models';

export const defaultPropertiesByObjectTypeId: {
  [k in ObjectTypeIds]?: string[];
} = {
  [ObjectTypeIds.Address]: [AddressPropertyIds.Line1, AddressPropertyIds.City],
  [ObjectTypeIds.Country]: [CountryPropertyIds.Name],
  [ObjectTypeIds.Currency]: [CurrencyPropertyIds.Name],
  [ObjectTypeIds.Customer]: [CustomerPropertyIds.Name],
  [ObjectTypeIds.Position]: [PositionPropertyIds.Name],
  [ObjectTypeIds.Product]: [
    ProductPropertyIds.Name,
    ProductPropertyIds.Category,
    ProductPropertyIds.Customer,
    ProductPropertyIds.Price,
    ProductPropertyIds.Currency,
    ProductPropertyIds.Enabled,
    ProductPropertyIds.EnabledPersonalArrangement,
    ProductPropertyIds.EnabledOrders,
    ProductPropertyIds.EnabledSampling,
  ],
  [ObjectTypeIds.ProductCategory]: [ProductCategoryPropertyIds.Name],
  [ObjectTypeIds.User]: [
    UserPropertyIds.Username,
    UserPropertyIds.Position,
    UserPropertyIds.Internal,
  ],
  [ObjectTypeIds.UserProfile]: [
    UserProfilePropertyIds.FirstName,
    UserProfilePropertyIds.LastName,
    UserProfilePropertyIds.Email,
    UserProfilePropertyIds.Phone,
    UserProfilePropertyIds.HomeAddress,
    UserProfilePropertyIds.User,
  ],
};

export interface GridColumnSetting {
  propertyId: string;
  relationName?: string;
  columns?: GridColumnSetting[];
}

export function gridColumnSettingsToApiExtendRelations(params: {
  settings: GridColumnSetting[];
  propertyById: Map<string, IObjectProperty>;
}) {
  const {settings, propertyById} = params;
  const relations: InclusionFilter[] = [];

  settings.forEach(setting => {
    const relation: InclusionFilter = {
      relation:
        setting.relationName || propertyById.get(setting.propertyId).name,
    };

    if (setting.columns) {
      relation.scope = {
        include: gridColumnSettingsToApiExtendRelations({
          settings: setting.columns,
          propertyById,
        }),
      };
    }

    relations.push(relation);
  });

  return relations;
}

export const USER_PROFILE_GRID_COLUMN_SETTINGS: GridColumnSetting[] = [
  {
    propertyId: UserProfilePropertyIds.User,
    columns: [
      {
        propertyId: UserPropertyIds.Position,
      },
      {
        propertyId: UserPropertyIds.Customer,
      },
    ],
  },
  {
    propertyId: UserProfilePropertyIds.HomeAddress,
    columns: [
      {
        propertyId: AddressPropertyIds.Country,
      },
    ],
  },
  {
    propertyId: UserProfilePropertyIds.CorrespondenceAddress,
    columns: [
      {
        propertyId: AddressPropertyIds.Country,
      },
    ],
  },
  {
    propertyId: UserProfilePropertyIds.Locale,
  },
];

export const PRODUCT_GRID_COLUMN_SETTINGS: GridColumnSetting[] = [
  {
    propertyId: ProductPropertyIds.Customer,
  },
  {
    propertyId: ProductPropertyIds.Currency,
  },
  {
    propertyId: ProductPropertyIds.Category,
    relationName: 'productCategory',
  },
  {
    propertyId: ProductPropertyIds.Unit,
  },
];

export const OBJECT_GRID_COLUMN_SETTINGS_MAP = {
  [ObjectTypeIds.UserProfile]: USER_PROFILE_GRID_COLUMN_SETTINGS,
  [ObjectTypeIds.Product]: PRODUCT_GRID_COLUMN_SETTINGS,
};
