import * as uuid from 'uuid';

export class Holiday {
  id: string;
  name: string;
  date: string;

  constructor() {
    this.id = uuid.v4();
    this.name = null;
    this.date = null;
  }
}
