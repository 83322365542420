import * as uuid from 'uuid';
import {KPISet} from './kpi.model';
import {TourPlan} from './tour-plan.model';
import {User} from './user.model';
import {UsernameResponse} from './username-response.model';
import {IFeedbackKPISet} from '@retrixhouse/salesapp-shared/lib/models';
import {Comment} from './comment.model';
import {Photo} from './photo.model';

export class Feedback {
  id: string;
  createdAt: Date;
  updatedAt?: Date;
  createdById: string;
  createdBy: User;
  tourPlanId: string;
  tourPlan: TourPlan;
  kpiValues?: FeedbackKPIValue[];
  kpiValueSets?: IFeedbackKPISet[];

  commentRequired: boolean;
  photoRequired: boolean;
  resolved: boolean;

  constructor() {
    this.id = uuid.v4();
    this.createdAt = new Date();
  }
}

export class FeedbackKPIValue {
  id: string;
  feedbackId: string;
  kpiSetId: string;
  kpiId: string;
  value: number;
}

export class FeedbackWithUserInfo extends Feedback {
  userInfo?: UsernameResponse;
}

export class FeedbackWithUserAndOpenedInfo extends Feedback {
  userInfo?: UsernameResponse;
  tourPlanUserInfo?: UsernameResponse[];
  userRead?: boolean;
  kpiSets?: KPISet[];
  comment?: Comment;
  photos?: Photo[];
}
