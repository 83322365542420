import {Component, OnInit} from '@angular/core';
/*
import {
  Chain,
  Country,
  GenericList,
  GenericListItem,
  Holiday,
  Language,
  Locale,
  ObjectPropertyValue,
  ObjectType,
  PeriodicLimit,
  Position,
  Product,
  ProductCategory,
  Project,
  ProjectProductListing,
  ProjectSetting,
  ProjectStoreTodoListResult,
  ProjectUserStore,
  Setting,
  Store,
  Tag,
  TourPlan,
  TourPlanState,
  UserPositionInProject,
  UserProfile,
  VisitDataResponse,
  VisitResultRequest,
  ProjectStorePeriodicLimit,
  PromoAction,
  UsernameResponse,
  VisitFrequencyCalculation,
  Photo,
  ProjectSettingValue,
} from '../../../../shared/models';
import * as moment from 'moment';
import {NotificationService} from '../../../../shared/services/notification.service';
import {Router} from '@angular/router';
import {
  ChainHttpService,
  CountryHttpService,
  GenericListItemHttpService,
  GenericListHttpService,
  ProductHttpService,
  ProjectHttpService,
  SettingsHttpService,
  StoreHttpService,
  TourPlanHttpService,
  UserHttpService,
  UserStorageHttpService,
  VisitHttpService,
  PeriodicLimitHttpService,
  PositionHttpService,
  TagHttpService,
  ProjectUserStoreHttpService,
  ProductListingHttpService,
  PromoActionHttpService,
  LanguageHttpService,
  I18NHttpService,
  ObjectHttpService,
  ProductCategoryHttpService,
  JsonSchemaHttpService,
  HolidayHttpService,
  StorageAccountHttpService,
  PhotoObjectHttpService,
  UserTaskHttpService,
} from '../../../../shared/services/http';
import {TranslateService} from '@ngx-translate/core';
import {
  ClientModeService,
  LocalStorageService,
  NavigationService,
  SupportedEntity,
} from '../../../../shared/services';
import {
  ConfirmationService,
  ConfirmType,
} from '../../../../shared/services/confirmation.service';
import {ClientMode} from '../../../../shared/enums/client-mode.enum';

import {AppInterfaceService} from 'src/app/shared/app-interface/app-interface.service';
import {ObjectTypeIds} from '../../../../shared/globals';
import {DataTransferDownloadProgress} from './data-transfer-download.progress';
import {
  DataTransferDownloadPhotoProgress as DTDPP,
  PhotoSource,
} from './data-transfer-download-photo.progress';
import {resolveSetting} from '../../../../shared/utils/setting.utils';
import {UserTask} from '../../../../shared/models/user-task.model';
import {SettingNames} from '@retrixhouse/salesapp-shared/lib/settings';
*/

@Component({
  selector: 'app-data-transfer-download',
  templateUrl: 'data-transfer-download.component.html',
  styleUrls: ['data-transfer-download.component.scss'],
})
export class DataTransferDownloadComponent /*implements OnInit*/ {
  /*
  KEY_CHAINS = 'chains';
  KEY_STORES = 'stores';
  KEY_PRODUCTS = 'products';
  KEY_PRODUCT_CATEGORY = 'product_categories';
  KEY_COUNTRIES = 'countries';
  KEY_USERNAMES = 'usernames';
  KEY_GENERIC_LISTS = 'genericLists';
  KEY_GENERIC_LIST_ITEMS = 'genericListItems';
  KEY_PROJECT_SETTINGS = 'projectSettings';
  KEY_SYSTEM_SETTINGS = 'systemSettings';
  KEY_LANGUAGES = 'languages';
  KEY_LOCALES = 'locales';
  KEY_TAGS = 'tags';
  KEY_PERIODIC_LIMITS = 'periodicLimits';
  KEY_HOLIDAYS = 'holidays';
  KEY_POSITIONS = 'positions';
  KEY_CURRENT_USER_PROFILE = 'userProfile';
  KEY_CURRENT_USER_POSITION = 'userPosition';
  KEY_USER_STORE_RELATIONS = 'userStoreRelations';
  KEY_STORE_FREQUENCIES = 'storeFrequencies';
  KEY_VISIT_FREQUENCY_CALCULATIONS = 'carriedVisits';
  KEY_PERSONAL_ARRANGEMENTS = 'personalArrangements';
  KEY_USER_STORAGE = 'userStorage';
  KEY_PROJECTS = 'projects';
  KEY_PROJECT_POSITIONS = 'projectPositions';
  KEY_TOUR_PLANS = 'tourPlans';
  KEY_VISIT_DATA = 'visitData';
  KEY_VISIT_DATA_TEMPLATE = 'visitDataTemplate';
  KEY_PREVIOUS_RESULTS = 'previousResults';
  KEY_PRODUCT_LISTINGS = 'productListings';
  KEY_PROMO_ACTIONS = 'promoActions';
  KEY_OPENAPI = 'openapi';
  KEY_OBJECT_TYPES = 'objectTypes';
  KEY_STORE_OBJECT_VALUES = 'storeObjectValues';
  KEY_PHOTOS = 'thumbnails';
  KEY_USER_TASKS = 'userTasks';

  progressMap: Map<string, DataTransferDownloadProgress>;
  userProfile: UserProfile;

  showCompletedProgressBars = false;
  pendingObservablesCount: number;
  errorCount: number;
  dataSize: number;
  projects: Project[];
  systemSettings: Setting[];
  allProjectSettings: ProjectSetting[];

  constructor(
    private router: Router,
    private navigationService: NavigationService,
    public translate: TranslateService,
    private localStorageService: LocalStorageService,
    private confirmationService: ConfirmationService,
    private clientModeService: ClientModeService,
    private appInterfaceService: AppInterfaceService,
    // services for models
    private chainHttpService: ChainHttpService,
    private storeHttpService: StoreHttpService,
    private productHttpService: ProductHttpService,
    private productCategoryHttpService: ProductCategoryHttpService,
    private countryHttpService: CountryHttpService,
    private userHttpService: UserHttpService,
    private genericListHttpService: GenericListHttpService,
    private genericListItemHttpService: GenericListItemHttpService,
    private settingHttpService: SettingsHttpService,
    private languageHttpService: LanguageHttpService,
    private i18NHttpService: I18NHttpService,
    private tagHttpService: TagHttpService,
    private periodicLimitHttpService: PeriodicLimitHttpService,
    private holidayHttpService: HolidayHttpService,
    private jsonSchemaHttpService: JsonSchemaHttpService,
    private objectHttpService: ObjectHttpService,
    private userStorageHttpService: UserStorageHttpService,
    private projectHttpService: ProjectHttpService,
    private tourPlanHttpService: TourPlanHttpService,
    private visitHttpService: VisitHttpService,
    private positionHttpService: PositionHttpService,
    private projectUserStoreHttpService: ProjectUserStoreHttpService,
    private productListingHttpService: ProductListingHttpService,
    private promoActionHttpService: PromoActionHttpService,
    private photoObjectHttpService: PhotoObjectHttpService,
    private storageAccountHttpService: StorageAccountHttpService,
    private userTaskHttpService: UserTaskHttpService,
  ) {}

  ngOnInit(): void {
    // @formatter:off
    this.progressMap = new Map<string, DataTransferDownloadProgress>([
      [
        this.KEY_CHAINS,
        new DataTransferDownloadProgress(
          'chains',
          'fa fa-building',
          this.fetchChains,
        ),
      ],
      [
        this.KEY_STORES,
        new DataTransferDownloadProgress(
          'stores',
          'fa fa-shop',
          this.fetchStores,
        ),
      ],
      [
        this.KEY_STORE_OBJECT_VALUES,
        new DataTransferDownloadProgress(
          'store-values',
          'fa fa-shop',
          this.fetchStoreObjectPropertyValues,
        ),
      ],
      [
        this.KEY_PRODUCTS,
        new DataTransferDownloadProgress(
          'products',
          'fa fa-box-open-full',
          this.fetchProducts,
        ),
      ],
      [
        this.KEY_PRODUCT_CATEGORY,
        new DataTransferDownloadProgress(
          'product-categories',
          'fa fa-list-tree',
          this.fetchProductCategories,
        ),
      ],
      [
        this.KEY_COUNTRIES,
        new DataTransferDownloadProgress(
          'countries',
          'fa fa-globe',
          this.fetchCountries,
        ),
      ],
      [
        this.KEY_USERNAMES,
        new DataTransferDownloadProgress(
          'usernames',
          'fa fa-users',
          this.fetchUsernames,
        ),
      ],
      [
        this.KEY_GENERIC_LISTS,
        new DataTransferDownloadProgress(
          'generic-lists',
          'fa fa-rectangle-list',
          this.fetchGenericLists,
        ),
      ],
      [
        this.KEY_GENERIC_LIST_ITEMS,
        new DataTransferDownloadProgress(
          'generic-list-items',
          'fa fa-list-check',
          this.fetchGenericListItems,
        ),
      ],
      [
        this.KEY_PROJECT_SETTINGS,
        new DataTransferDownloadProgress(
          'project-settings',
          'fa fa-gears',
          this.fetchProjectSettings,
        ),
      ],
      [
        this.KEY_SYSTEM_SETTINGS,
        new DataTransferDownloadProgress(
          'system-settings',
          'fa fa-gears',
          this.fetchSystemSettings,
        ),
      ],
      [
        this.KEY_PERIODIC_LIMITS,
        new DataTransferDownloadProgress(
          'periodic-limits',
          'fa fa-users',
          this.fetchPeriodicLimits,
        ),
      ],
      [
        this.KEY_HOLIDAYS,
        new DataTransferDownloadProgress(
          'holidays',
          'fa fa-calendar-heart',
          this.fetchHolidays,
        ),
      ],
      [
        this.KEY_POSITIONS,
        new DataTransferDownloadProgress(
          'positions',
          'fa fa-user-group-crown',
          this.fetchPositionForCustomer,
        ),
      ],
      [
        this.KEY_OPENAPI,
        new DataTransferDownloadProgress(
          'object-definitions',
          'fa fa-standard-definition',
          this.fetchOpenAPI,
        ),
      ],
      [
        this.KEY_OBJECT_TYPES,
        new DataTransferDownloadProgress(
          'object-types',
          'fa fa-standard-definition',
          this.fetchObjectTypes,
        ),
      ],
      [
        this.KEY_CURRENT_USER_PROFILE,
        new DataTransferDownloadProgress(
          'user-profile',
          'fa fa-user-circle',
          this.fetchUserProfile,
        ),
      ],
      [
        this.KEY_CURRENT_USER_POSITION,
        new DataTransferDownloadProgress(
          'user-position',
          'fa fa-user-group-crown',
          this.fetchUserPosition,
        ),
      ],
      [
        this.KEY_USER_STORE_RELATIONS,
        new DataTransferDownloadProgress(
          'store-relations',
          'fa fa-shop',
          this.fetchUserStoreRelations,
        ),
      ],
      [
        this.KEY_STORE_FREQUENCIES,
        new DataTransferDownloadProgress(
          'store-frequencies',
          'fa fa-shop',
          this.fetchUserStoreFrequencies,
        ),
      ],
      [
        this.KEY_VISIT_FREQUENCY_CALCULATIONS,
        new DataTransferDownloadProgress(
          'carried-visits',
          'fa fa-tally',
          this.fetchVisitFrequencyCalculations,
        ),
      ],
      [
        this.KEY_USER_STORAGE,
        new DataTransferDownloadProgress(
          'user-storage',
          'fa fa-folder-user',
          this.fetchUserStorage,
        ),
      ],
      [
        this.KEY_PERSONAL_ARRANGEMENTS,
        new DataTransferDownloadProgress(
          'personal-arrangements',
          'fa fa-people-carry-box',
          this.fetchPersonalArrangements,
        ),
      ],
      [
        this.KEY_PROJECTS,
        new DataTransferDownloadProgress(
          'user-projects',
          'fa fa-diagram-project',
          this.fetchProjects,
        ),
      ],
      [
        this.KEY_PROJECT_POSITIONS,
        new DataTransferDownloadProgress(
          'user-project-positions',
          'fa fa-diagram-project',
          this.fetchProjectPositions,
          false,
        ),
      ],
      [
        this.KEY_TOUR_PLANS,
        new DataTransferDownloadProgress(
          'user-visits',
          'fa fa-calendar-days',
          this.fetchTourPlans,
          false,
        ),
      ],
      [
        this.KEY_VISIT_DATA,
        new DataTransferDownloadProgress(
          'user-visit-data',
          'fa fa-binary',
          this.fetchTourPlans,
        ),
      ],
      [
        this.KEY_VISIT_DATA_TEMPLATE,
        new DataTransferDownloadProgress(
          'user-visit-data-template',
          'fa fa-binary',
          this.fetchVisitDataTemplate,
          false,
        ),
      ],
      [
        this.KEY_PREVIOUS_RESULTS,
        new DataTransferDownloadProgress(
          'previous-results',
          'fa fa-flag-checkered',
          this.fetchPreviousResults,
        ),
      ],
      [
        this.KEY_USER_TASKS,
        new DataTransferDownloadProgress(
          'user-tasks',
          'fa-diagram-subtask',
          this.fetchUserTasks,
        ),
      ],
      [
        this.KEY_PRODUCT_LISTINGS,
        new DataTransferDownloadProgress(
          'product-listings',
          'fa fa-shelves',
          this.fetchProductListings,
          false,
        ),
      ],
      [
        this.KEY_PROMO_ACTIONS,
        new DataTransferDownloadProgress(
          'promo-actions',
          'fa fa-badge-dollar',
          this.fetchPromoActions,
          false,
        ),
      ],
      [
        this.KEY_LANGUAGES,
        new DataTransferDownloadProgress(
          'enabled-languages',
          'fa fa-language',
          this.fetchLanguages,
        ),
      ],
      [
        this.KEY_LOCALES,
        new DataTransferDownloadProgress(
          'locales',
          'fa fa-earth-africa',
          this.fetchLocales,
        ),
      ],
      [
        this.KEY_TAGS,
        new DataTransferDownloadProgress('tags', 'fa fa-tags', this.fetchTags),
      ],
      [
        this.KEY_PHOTOS,
        new DTDPP('photos', 'fa-regular fa-image', () =>
          this.handleInit(this.KEY_PHOTOS),
        ),
      ],
    ]);
    // @formatter:on

    this.userHttpService.getMyProfile().then(up => {
      this.userProfile = up;
      //UserBasedOfflineService.setUserProfile(this.userProfile);
      this.fetchData();
    });
  }

  retryFailed(key?: string): void {
    if (key) {
      const progress = this.progressMap.get(key);
      if (!progress) {
        return;
      }
      const callback = progress.fetchCallback.bind(this);
      callback();
      return;
    }

    this.progressMap.forEach((v, k) => {
      if (v.error) {
        v.fetchCallback.bind(this)();
      }
    });
  }

  fetchData(): void {
    if (!this.progressMap) {
      this.progressMap = new Map<string, DataTransferDownloadProgress>();
    }

    this.localStorageService.initDataMap<string, VisitResultRequest>(
      'UserVisitResultRequest',
      [],
      this.userProfile.userId,
    );

    this.progressMap.forEach((v, k) => {
      if (v.fireCallbackOnInit) {
        v.fetchCallback.bind(this)();
      }
    });
  }

  basicFetch<T>(
    key: string,
    entity: SupportedEntity,
    obs: Promise<T>,
    type: 'Single' | 'Array' | 'Map',
    transformer?: (this: void, value: T) => [any, any][],
    userSpecific: boolean = false,
  ): void {
    const userId = userSpecific ? this.userProfile.userId : undefined;
    this.handleInit(key);
    obs.then(
      async data => {
        try {
          let size: number;
          if (type === 'Single') {
            size = this.localStorageService.initDataSingle<T>(
              entity,
              data,
              userId,
            );
          } else if (type === 'Array') {
            size = await this.localStorageService.initDataArray<T>(
              entity,
              data as unknown as T[],
              userId,
            );
          } else if (type === 'Map') {
            size = await this.localStorageService.initDataMap<any, any>(
              entity,
              transformer(data),
              userId,
            );
          }

          this.handleSuccess(key, size);
        } catch (ignored) {
          this.handleError(key);
        }
      },
      () => this.handleError(key),
    );
  }

  fetchChains(): void {
    const obs = this.chainHttpService.getList();
    this.basicFetch<Chain[]>(this.KEY_CHAINS, 'Chain', obs, 'Map', chains =>
      chains.map(ch => [ch.id, ch]),
    );
  }

  fetchStores(): void {
    const obs = this.storeHttpService.getList();
    this.basicFetch<Store[]>(this.KEY_STORES, 'Store', obs, 'Map', stores =>
      stores.map(s => [s.id, s]),
    );
  }

  fetchStoreObjectPropertyValues(): void {
    const obs = this.objectHttpService.getAllPropertyValuesForType(
      ObjectTypeIds.Store,
    );
    this.basicFetch<ObjectPropertyValue[]>(
      this.KEY_STORE_OBJECT_VALUES,
      'StorePropertyValues',
      obs,
      'Map',
      propValues => propValues.map(pv => [pv.id, pv]),
    );
  }

  fetchProducts(): void {
    const obs = this.productHttpService.getList();
    this.basicFetch<Product[]>(
      this.KEY_PRODUCTS,
      'Product',
      obs,
      'Map',
      products => products.map(p => [p.id, p]),
    );
  }

  fetchProductCategories(): void {
    const obs = this.productCategoryHttpService.getList();
    this.basicFetch<ProductCategory[]>(
      this.KEY_PRODUCT_CATEGORY,
      'ProductCategory',
      obs,
      'Map',
      pcs => pcs.map(pc => [pc.id, pc]),
    );
  }

  fetchCountries(): void {
    const obs = this.countryHttpService.getList();
    this.basicFetch<Country[]>(
      this.KEY_COUNTRIES,
      'Country',
      obs,
      'Map',
      countries => countries.map(c => [c.id, c]),
    );
  }

  fetchUsernames(): void {
    const obs = this.userHttpService.getUsernames();
    this.basicFetch<UsernameResponse[]>(
      this.KEY_USERNAMES,
      'Username',
      obs,
      'Map',
      usernames => usernames.map(u => [u.id, u]),
    );
  }

  fetchGenericLists(): void {
    const obs = this.genericListHttpService.getList();
    this.basicFetch<GenericList[]>(
      this.KEY_GENERIC_LISTS,
      'GenericList',
      obs,
      'Map',
      gls => gls.map(gl => [gl.id, gl]),
    );
  }

  fetchGenericListItems(): void {
    const obs = this.genericListItemHttpService.getList();
    this.basicFetch<GenericListItem[]>(
      this.KEY_GENERIC_LIST_ITEMS,
      'GenericListItem',
      obs,
      'Map',
      glis => glis.map(gli => [gli.id, gli]),
    );
  }

  fetchProjectSettings(): void {
    const obs = this.projectHttpService
      .getAllProjectSettings()
      .then(settings => {
        this.allProjectSettings = settings;
        return settings;
      });
    this.basicFetch<ProjectSetting[]>(
      this.KEY_PROJECT_SETTINGS,
      'ProjectSettings',
      obs,
      'Map',
      pss => pss.map(ps => [ps.id, ps]),
    );
  }

  fetchSystemSettings(): void {
    const obs = this.settingHttpService.getAll().then(settings => {
      this.systemSettings = settings;
      return settings;
    });

    this.basicFetch<Setting[]>(
      this.KEY_SYSTEM_SETTINGS,
      'SystemSetting',
      obs,
      'Map',
      settings => settings.map(s => [s.id, s]),
    );
  }

  fetchLanguages(): void {
    const obs = this.languageHttpService.getEnabled();
    this.basicFetch<Language[]>(
      this.KEY_LANGUAGES,
      'Languages',
      obs,
      'Map',
      langs => langs.map(l => [l.id, l]),
    );
  }

  fetchLocales(): void {
    const obs = this.i18NHttpService.getEnabledLocales();
    this.basicFetch<Locale[]>(
      this.KEY_LOCALES,
      'Locales',
      obs,
      'Map',
      locales => locales.map(l => [l.id, l]),
    );
  }

  fetchTags(): void {
    const obs = this.tagHttpService.getList();
    this.basicFetch<Tag[]>(this.KEY_TAGS, 'Tag', obs, 'Map', tags =>
      tags.map(t => [t.id, t]),
    );
  }

  fetchPeriodicLimits(): void {
    const obs = this.periodicLimitHttpService.getList();
    this.basicFetch<PeriodicLimit[]>(
      this.KEY_PERIODIC_LIMITS,
      'PeriodicLimit',
      obs,
      'Map',
      pls => pls.map(pl => [pl.id, pl]),
    );
  }

  fetchHolidays(): void {
    const obs = this.holidayHttpService.getList();
    this.basicFetch<Holiday[]>(
      this.KEY_HOLIDAYS,
      'Holiday',
      obs,
      'Map',
      holidays => holidays.map(h => [h.id, h]),
    );
  }

  fetchPositionForCustomer(): void {
    const positionObs = this.positionHttpService.getForCustomer(null);
    this.handleInit(this.KEY_POSITIONS);

    positionObs.then(
      async res => {
        // HOTFIX: remove routes from response object
        res?.forEach(p => {
          p.appRoutes = undefined;
          p.menuItems = undefined;
        });
        const size = await this.localStorageService.initDataMap<
          string,
          Position
        >(
          'CustomerPositions',
          res.map(p => [p.id, p]),
        );
        this.handleSuccess(this.KEY_POSITIONS, size);
      },
      () => this.handleError(this.KEY_POSITIONS),
    );
  }

  fetchOpenAPI(): void {
    const openApiPromise = this.jsonSchemaHttpService.getOpenApiDefinition();
    this.handleInit(this.KEY_OPENAPI);

    openApiPromise.then(
      async openApi => {
        const size = this.localStorageService.initDataSingle<any>(
          'OpenAPI',
          openApi,
        );
        this.handleSuccess(this.KEY_OPENAPI, size);
      },
      () => this.handleError(this.KEY_OPENAPI),
    );
  }

  fetchObjectTypes(): void {
    const obs = this.objectHttpService.getObjectTypes();
    this.basicFetch<ObjectType[]>(
      this.KEY_OBJECT_TYPES,
      'ObjectType',
      obs,
      'Map',
      ots => ots.map(ot => [ot.id, ot]),
    );
  }

  fetchUserProfile(): void {
    const size = this.localStorageService.initDataSingle<UserProfile>(
      'UserProfile',
      this.userProfile,
    );
    this.handleSuccess(this.KEY_CURRENT_USER_PROFILE, size);
  }

  fetchUserPosition(): void {
    const userPositionObs = this.positionHttpService.getForUser();
    this.handleInit(this.KEY_CURRENT_USER_POSITION);

    userPositionObs.then(
      async res => {
        const size = this.localStorageService.initDataSingle<Position>(
          'UserPosition',
          res,
          this.userProfile.userId,
        );
        this.handleSuccess(this.KEY_CURRENT_USER_POSITION, size);
      },
      () => this.handleError(this.KEY_CURRENT_USER_POSITION),
    );
  }

  fetchUserStoreRelations(): void {
    const obs = this.projectUserStoreHttpService.getUserStoreRelationForUser();
    this.basicFetch<ProjectUserStore[]>(
      this.KEY_USER_STORE_RELATIONS,
      'UserStoreRelation',
      obs,
      'Map',
      puss => puss.map(pus => [pus.id, pus]),
      true,
    );
  }

  fetchUserStoreFrequencies(): void {
    const obs =
      this.periodicLimitHttpService.getPeriodicLimitsForAvailableStoresStore();
    this.basicFetch<ProjectStorePeriodicLimit[]>(
      this.KEY_STORE_FREQUENCIES,
      'UserStoreFrequency',
      obs,
      'Map',
      pls => pls.map(pl => [pl.id, pl]),
      true,
    );
  }

  fetchVisitFrequencyCalculations(): void {
    const obs = this.projectHttpService.calculateFrequenciesForUser();
    this.basicFetch<VisitFrequencyCalculation[]>(
      this.KEY_VISIT_FREQUENCY_CALCULATIONS,
      'UserVisitFrequencyCalculation',
      obs,
      'Array',
      undefined,
      true,
    );
  }

  fetchUserStorage(): void {
    const obs = this.userStorageHttpService.getAll();
    this.basicFetch<{key: string; value: any}[]>(
      this.KEY_USER_STORAGE,
      'UserStorage',
      obs,
      'Map',
      kvs => kvs.map(kv => [kv.key, {key: kv.key, value: kv.value}]),
      true,
    );
  }

  fetchUserTasks(): void {
    const obs = this.userTaskHttpService.getActiveUserTasksForUser();
    this.basicFetch<UserTask[]>(
      this.KEY_USER_TASKS,
      'UserTask',
      obs,
      'Map',
      uts => uts.map(ut => [ut.id, ut]),
      true,
    );
  }

  fetchPersonalArrangements(): void {
    // reset flag to save photos
    (
      this.progressMap.get(this.KEY_PHOTOS) as DTDPP
    ).personalArrangementPhotosDownloaded = false;
    this.handleInit(this.KEY_PERSONAL_ARRANGEMENTS);

    const obs = this.visitHttpService.getPersonalArrangementsForUser();
    obs.then(async res => {
      try {
        const size = await this.localStorageService.initDataArray(
          'UserPersonalArrangement',
          res,
          this.userProfile.userId,
        );
        const downloadPhotos = !!resolveSetting(
          SettingNames.PhotoGallery_ThumbnailDownloadOffline,
          null,
          null,
          null,
          this.systemSettings,
        );
        let fetchPhotosPromise: Promise<Photo[]>;
        if (res && res.length > 0 && downloadPhotos) {
          fetchPhotosPromise =
            this.photoObjectHttpService.getPhotoListForObjectIdList(
              res
                .map(i => i.personalArrangement.id)
                .reduce((x, y) => x.concat(y), []),
            );
        } else {
          fetchPhotosPromise = Promise.resolve([]);
        }

        fetchPhotosPromise.then(photos =>
          this.downloadPhotos('personalArrangements', photos),
        );

        this.handleSuccess(this.KEY_PERSONAL_ARRANGEMENTS, size);
      } catch (e) {
        this.handleError(this.KEY_PERSONAL_ARRANGEMENTS);
      }
    });
  }

  fetchProjectPositions(): void {
    this.handleInit(this.KEY_PROJECT_POSITIONS);

    if (!this.projects || this.projects.length === 0) {
      this.handleSuccess(this.KEY_PROJECT_POSITIONS, 0);
      return;
    }

    // get user position in project context
    const obs = this.positionHttpService.getUserPositionInProjectContext(
      this.projects.map(p => ({
        userId: this.userProfile.userId,
        projectId: p.id,
      })),
    );
    this.basicFetch<UserPositionInProject[]>(
      this.KEY_PROJECT_POSITIONS,
      'UserProjectPosition',
      obs,
      'Array',
      undefined,
      true,
    );
  }

  fetchVisitDataTemplate(): void {
    this.handleInit(this.KEY_VISIT_DATA_TEMPLATE);
    if (!this.projects || this.projects.length === 0) {
      this.handleSuccess(this.KEY_VISIT_DATA_TEMPLATE, 0);
      return;
    }

    // get visit data response template
    const obs = this.visitHttpService.getVisitResultTemplate(
      this.projects.map(p => p.id),
    );
    this.basicFetch<
      {projectId: string; template: Partial<VisitDataResponse>}[]
    >(
      this.KEY_VISIT_DATA_TEMPLATE,
      'UserVisitDataTemplate',
      obs,
      'Array',
      undefined,
      true,
    );
  }

  fetchProductListings(): void {
    this.handleInit(this.KEY_PRODUCT_LISTINGS);
    if (!this.projects || this.projects.length === 0) {
      this.handleSuccess(this.KEY_PRODUCT_LISTINGS, 0);
      return;
    }

    // get product listings
    const obs =
      this.productListingHttpService.getActiveProductListingsForProjects(
        this.projects.map(p => p.id),
      );
    this.basicFetch<ProjectProductListing[]>(
      this.KEY_PRODUCT_LISTINGS,
      'UserProductListing',
      obs,
      'Array',
      undefined,
      true,
    );
  }

  fetchPromoActions(): void {
    this.handleInit(this.KEY_PROMO_ACTIONS);
    if (!this.projects || this.projects.length === 0) {
      this.handleSuccess(this.KEY_PROMO_ACTIONS, 0);
      return;
    }

    // get promo actions
    const obs = this.promoActionHttpService.getPromoActionsWithRelations(
      this.projects.map(p => p.id),
    );
    this.basicFetch<PromoAction[]>(
      this.KEY_PROMO_ACTIONS,
      'UserPromoAction',
      obs,
      'Map',
      pas => pas.map(pa => [pa.id, pa]),
      true,
    );
  }

  fetchProjects(): void {
    const projectsObs = this.projectHttpService.getProjectsWithRelations();
    this.handleInit(this.KEY_PROJECTS);

    projectsObs.then(
      async res => {
        const size = await this.localStorageService.initDataMap<
          string,
          Project
        >(
          'UserProject',
          res ? res.map(r => [r.id, r]) : [],
          this.userProfile.userId,
        );
        this.projects = res;
        this.handleSuccess(this.KEY_PROJECTS, size);

        this.fetchProjectPositions();
        this.fetchProductListings();
        this.fetchPromoActions();
        this.fetchVisitDataTemplate();
      },
      () => this.handleError(this.KEY_PROJECTS),
    );
  }

  fetchPreviousResults(): void {
    const obs = this.visitHttpService.getPreviousVisitResultsForUser();
    this.basicFetch<ProjectStoreTodoListResult[]>(
      this.KEY_PREVIOUS_RESULTS,
      'UserVisitPreviousResult',
      obs,
      'Array',
      undefined,
      true,
    );
  }

  fetchTourPlans(): void {
    const tourPlanObs = this.tourPlanHttpService.search({
      executorIds: [this.userProfile.userId],
      dateFrom: moment().startOf('day').toISOString(),
      dateTo: moment().endOf('day').toISOString(),
    });
    this.handleInit(this.KEY_TOUR_PLANS);
    this.handleInit(this.KEY_VISIT_DATA);

    let size: number;
    tourPlanObs.then(
      async res => {
        size = await this.localStorageService.initDataMap<string, TourPlan>(
          'UserVisit',
          res ? res.map(tp => [tp.id, tp]) : [],
          this.userProfile.userId,
        );
        this.handleSuccess(this.KEY_TOUR_PLANS, size);

        if (res.length > 0) {
          try {
            this.localStorageService.initDataMap<string, TourPlanState>(
              'UserVisitOriginalStates',
              res.map(i => [i.id, i.state]),
              this.userProfile.userId,
            );
          } catch (e) {
            console.error(e);
            this.handleError(this.KEY_TOUR_PLANS);
          }

          try {
            // reset flag to save photos
            (
              this.progressMap.get(this.KEY_PHOTOS) as DTDPP
            ).visitDataPhotosDownloaded = false;
            const visitDataRes = await this.visitHttpService.getVisitDataBulk(
              res.map(tp => tp.id),
            );
            size = await this.localStorageService.initDataMap<
              string,
              VisitDataResponse
            >(
              'UserVisitData',
              visitDataRes ? visitDataRes.map(r => [r.visit.id, r]) : [],
              this.userProfile.userId,
            );

            const photosToDownload = visitDataRes
              .filter(vdr => {
                const projectId = vdr.visit.projectId;
                const projectSettingValues: ProjectSettingValue[] =
                  vdr.visit.project.settings;
                return !!resolveSetting(
                  SettingNames.PhotoGallery_ThumbnailDownloadOffline,
                  projectId,
                  projectSettingValues,
                  this.allProjectSettings,
                  this.systemSettings,
                );
              })
              .map(i => i.photos)
              .reduce((x, y) => x.concat(y), []);
            this.downloadPhotos('visitData', photosToDownload);
            this.handleSuccess(this.KEY_VISIT_DATA, size);
          } catch (e) {
            this.handleError(this.KEY_VISIT_DATA);
          }
        } else {
          this.downloadPhotos('visitData', []);
          this.handleSuccess(this.KEY_VISIT_DATA, 0);
        }
      },
      () => this.handleError(this.KEY_TOUR_PLANS),
    );
  }

  downloadPhotos(source: PhotoSource, photos: Photo[]): void {
    const progress = this.progressMap.get(this.KEY_PHOTOS) as DTDPP;
    let photoSize = 0;
    const promises = [];
    for (const photo of photos ?? []) {
      if (progress.photoIds.has(photo.id)) {
        // photo was already downloaded, avoid duplicated downloading
        continue;
      }
      progress.photoIds.add(photo.id);

      // download photo
      const p = this.storageAccountHttpService
        .downloadPhotoObjectThumbnail(photo.id)
        .then((img: string) => {
          // save photo binary to the local storage
          photoSize += img.length;
          return this.localStorageService.photoStorageSave(photo.id, img);
        })
        .catch(e => {
          console.error(photo.id, source, e);
        });
      promises.push(p);
    }

    // handle success
    Promise.allSettled(promises).then(() => {
      progress.handlePhotoSuccess(source, photoSize);
      this.refreshCount();
    });
  }

  toggleShowMode(e): void {
    this.showCompletedProgressBars = !this.showCompletedProgressBars;
  }

  refreshCount(): void {
    this.refreshPendingObservablesCount();
    this.refreshErrorCount();
    this.refreshTotalDownloadedDataSize();
  }

  handleInit(key): void {
    this.progressMap.get(key)?.handleInit();
    this.refreshCount();
  }

  handleSuccess(key: string, size: number): void {
    this.progressMap.get(key)?.handleSuccess(size);
    this.refreshCount();
  }

  handleError(key: string): void {
    this.progressMap.get(key)?.handleError();
    this.refreshCount();
    NotificationService.notifyError(
      this.translate.instant('messages.error.loading-data'),
    );
  }

  refreshPendingObservablesCount(): void {
    this.pendingObservablesCount = Array.from(this.progressMap.keys())
      .map(k => {
        const progress = this.progressMap.get(k);
        return progress.error || !progress.completed ? 1 : 0;
      })
      .reduce((a, b) => a + b, 0);
  }

  refreshErrorCount(): void {
    this.errorCount = Array.from(this.progressMap.keys())
      .map(k => {
        const progress = this.progressMap.get(k);
        return progress.error ? 1 : 0;
      })
      .reduce((a, b) => a + b, 0);
  }

  refreshTotalDownloadedDataSize(): void {
    let dataSize = 0;
    this.progressMap.forEach((value, key) => {
      dataSize += value.dataSize ?? 0;
    });

    this.dataSize = dataSize;
  }

  formatBtnContinueText(): string {
    if (this.pendingObservablesCount === 0) {
      return this.translate.instant('buttons.continue');
    }

    return `${this.translate.instant('buttons.continue')} (${
      this.progressMap.size - this.pendingObservablesCount
    } ${this.translate.instant('labels.of')} ${this.progressMap.size})`;
  }

  btnContinueClick(): void {
    this.router.navigate(['/']);
  }

  async btnCancelClick(): Promise<void> {
    const confirmResult = await this.confirmationService.confirm(
      ConfirmType.CONFIRM,
      this.translate.instant('views.data-transfer.leave-transfer'),
      this.translate.instant('view.visit.leave-transfer-confirm'),
    );

    if (confirmResult) {
      this.clientModeService.clientMode = ClientMode.ONLINE;
      this.router.navigate(['/']);
    }
  }*/
}
