import {Pipe, PipeTransform} from '@angular/core';
import {FileUtils} from '@retrixhouse/salesapp-shared/lib/utils';

@Pipe({name: 'fileSize'})
export class FileSizePipe implements PipeTransform {
  constructor() {}

  transform(value: number) {
    return FileUtils.fileSizeToHumanReadableFileSize(value);
  }
}
