import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';

@Pipe({name: 'dateDiff'})
export class DateDiffPipe implements PipeTransform {
  transform(date: Date | string, dateToCompare: Date | string) {
    const firstDate = moment(date);
    const secondDate = moment(dateToCompare);
    const humanizedDiff = moment
      .duration(firstDate.diff(secondDate))
      .humanize();
    return humanizedDiff;
  }
}
