import * as uuid from 'uuid';
import {BehaviorSubject, Subscription} from 'rxjs';
import {cloneDeep} from 'lodash';
import {DIALOG_DATA, DialogRef} from '@angular/cdk/dialog';
import {
  IObjectForm,
  IObjectFormInputControl,
  IObjectFormInputControlColumn,
  IObjectFormRow,
  IObjectFormRowColumn,
} from '@retrixhouse/salesapp-shared/lib/models';
import {ObjectStorageService} from '@salesapp/storage';
import {ObjectTypeIds} from '@retrixhouse/salesapp-shared/lib/common';
import {Optional} from '@salesapp/utils/types';
import {TranslateService} from '@ngx-translate/core';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import {map} from 'rxjs/operators';
import {AutoUnsubscribe} from '@salesapp/utils/angular.utils';

@Component({
  selector: 'app-form-designer-dialog',
  templateUrl: './form-designer-dialog.component.html',
  styleUrls: ['./form-designer-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@AutoUnsubscribe()
export class FormDesignerDialogComponent implements OnInit {
  actionDisabled$ = new BehaviorSubject<boolean>(false);
  formUpdated$ = new BehaviorSubject<IObjectForm>(null);

  private validationState: 'valid' | 'invalid';
  private formSubscription: Subscription;

  get isUpdate() {
    return !!this.data?.formId;
  }

  get formUpdated() {
    return this.formUpdated$.getValue();
  }

  get readonly() {
    return this.data.readonly;
  }

  constructor(
    private dialogRef: DialogRef<IObjectForm>,
    @Inject(DIALOG_DATA) private data: FormDesignerDialogData,
    private objectStorageService: ObjectStorageService,
    private translateService: TranslateService,
  ) {}

  ngOnInit() {
    this.setForm();
  }

  onSave() {
    // validate and save
    if (this.validationState === 'valid') {
      if (this.isUpdate) {
        this.objectStorageService
          .updateForm({
            id: this.formUpdated.id,
            data: {...this.formUpdated},
          })
          .subscribe({
            next: () => this.onClose(),
          });
      } else {
        this.objectStorageService.createForm(this.formUpdated).subscribe({
          next: () => this.onClose(),
        });
      }
    }
  }

  onClose() {
    this.dialogRef.close();
  }

  onFormChange(event) {
    this.formUpdated$.next(event);
  }

  onValidationStateChange($event: 'valid' | 'invalid') {
    this.validationState = $event;
  }

  private setForm() {
    if (this.isUpdate) {
      this.formSubscription = this.objectStorageService.formsByObjectTypeId$
        .pipe(
          map(formsByObjectTypeId => {
            const formsForObject = formsByObjectTypeId.get(
              this.data.objectTypeId,
            );
            const formToUpdate = formsForObject.find(
              form => form.id === this.data.formId,
            );
            this.formUpdated$.next(formToUpdate);
          }),
        )
        .subscribe();
    } else {
      let newForm: IObjectForm;
      if (this.data.formToCopy) {
        newForm = this.copyForm(this.data.formToCopy);
      } else {
        newForm = {
          id: uuid.v4(),
          name: this.translateService.instant('form-designer.new-form-name'),
          default: false,
          objectTypeId: this.data.objectTypeId,
          positionIds: null,
          formSchema: {
            rows: [],
          },
        };
      }
      this.formUpdated$.next(newForm);
    }
  }

  private copyForm(originalForm: IObjectForm) {
    const rows = [];

    originalForm.formSchema.rows.forEach((row, index) => {
      const rowCopy: IObjectFormRow = {
        id: uuid.v4(),
        columns: [],
      };

      row.columns.forEach(rowColumn => {
        const rowColumnCopy: IObjectFormRowColumn = {
          id: uuid.v4(),
          group: {
            id: uuid.v4(),
            controlColumns: [],
            title: rowColumn.group.title,
            visibilityExpression: rowColumn.group.visibilityExpression,
          },
        };

        rowColumn.group.controlColumns.forEach(controlColumn => {
          const controlColumnCopy: IObjectFormInputControlColumn = {
            id: uuid.v4(),
            inputControls: [],
          };

          controlColumn.inputControls.forEach(inputControl => {
            const inputControlCopy: IObjectFormInputControl = {
              id: uuid.v4(),
              inputType: inputControl.inputType,
              propertyId: inputControl.propertyId,
              hint: inputControl.hint,
              label: inputControl.label,
              readonlyExpression: inputControl.readonlyExpression,
              requiredExpression: inputControl.requiredExpression,
              visibilityExpression: inputControl.visibilityExpression,
              validationExpression: inputControl.validationExpression,
            };

            if (inputControl.settings) {
              inputControlCopy.settings = inputControl.settings;
            }

            controlColumnCopy.inputControls.push(inputControlCopy);
          });

          rowColumnCopy.group.controlColumns.push(controlColumnCopy);
        });

        rowCopy.columns.push(rowColumnCopy);
      });

      rows.push(rowCopy);
    });

    const copy = {
      name: `${this.translateService.instant('copy-of')} ${originalForm.name}`,
      id: uuid.v4(),
      default: false,
      objectTypeId: originalForm.objectTypeId,
      positionIds: null,
      formSchema: {rows},
    };

    return copy;
  }
}

export interface FormDesignerDialogData {
  formToCopy?: IObjectForm;
  formId?: string;
  objectTypeId: ObjectTypeIds;
  readonly: boolean;
}

type ObjectFormForDesigner = Optional<IObjectForm, 'formSchema'>;
