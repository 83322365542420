<div *ngIf="label"
     class="label-container"
     [class.error]="hasError">
      <label *ngIf="label"
             class="label">{{ label }}</label>
      <span *ngIf="label && isRequired"
            class="required">*</span>
</div>
<div class="field">
      <ng-content></ng-content>
</div>
<span *ngIf="hint"
      class="label hint">{{ hint }}</span>
<app-form-control-error *ngIf="formControlRef && hasError"
                        [errors]="formControlRef.errors"></app-form-control-error>