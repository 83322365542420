import { AppInfoService } from './app-info.service';
import { AppInterfaceService } from '../app-interface/app-interface.service';
import { AuthGuardService } from './auth.service';
import { ClientSideErrorConfStorageService } from './storage/client-side-error-conf-storage.service';
import { ConfirmationService, ConfirmType } from './confirmation.service';
import { DialogService } from '../modules/dialog/services/dialog.service';
import { I18nStorageService } from './storage/i18n-storage.service';
import { Injectable } from '@angular/core';
import { LocalizedValue, NewsPost } from '../models';
import { NewsPostStorageService, ObjectStorageService } from './storage';
import { ProductStorageService } from './storage/product-storage.service';
import { Router } from '@angular/router';
import { StoreStorageService } from './storage/store-storage.service';
import { TranslateService } from '@ngx-translate/core';
import { UserStorageService } from './storage/user-storage.service';
import {
  ObjectTypeIds,
  RoleNames,
} from '@retrixhouse/salesapp-shared/lib/common';
import {
  IClientSideErrorConf,
  IStore,
} from '@retrixhouse/salesapp-shared/lib/models';
import {
  ClientSideErrorConfDialogComponent,
  ClientSideErrorConfDialogData,
} from '../dialogs/client-side-error-conf-dialog/client-side-error-conf-dialog.component';
import {
  NewsPostEditDialogComponent,
  NewsPostEditDialogData,
} from '../dialogs/news-post-edit-dialog/news-post-edit-dialog.component';
import {
  ObjectPropertyDialogComponent,
  ObjectPropertyDialogData,
} from '../dialogs/object-property-dialog/object-property-dialog.component';
import {
  RawDataDialogComponent,
  RawDataDialogData,
} from '../dialogs/raw-data-dialog/raw-data-dialog.component';
import {
  TranslationEditDialogComponent,
  TranslationEditDialogData,
} from '../dialogs/translation-edit-dialog/translation-edit-dialog.component';
import {
  Actions,
  ProductCreateDialogComponent,
  ProductDetailDialogComponent,
  StoreCreateDialogComponent,
  StoreDetailDialogComponent,
  StoreDetailDialogData,
  UserManagementCreateUserDialogComponent,
  UserManagementDetailDialogComponent,
  UserManagementDetailDialogData,
  UserManagementResetPasswordDialogComponent,
  UserManagementResetPasswordDialogData,
} from '../domain';
import {
  FormDesignerDialogComponent,
  FormDesignerDialogData,
} from '../modules/form-designer';

@Injectable()
export class ActionsService {
  constructor(
    private appInterfaceService: AppInterfaceService,
    private appInfoService: AppInfoService,
    private confirmationService: ConfirmationService,
    private translateService: TranslateService,
    private storeStorageService: StoreStorageService,
    private userStorageService: UserStorageService,
    private productStorageService: ProductStorageService,
    private dialogService: DialogService,
    private clientSideErrorConfStorageService: ClientSideErrorConfStorageService,
    private i18nStorageService: I18nStorageService,
    private newsPostSorageService: NewsPostStorageService,
    private router: Router,
    private objectStorageService: ObjectStorageService,
    private authGuardService: AuthGuardService,
  ) {}

  handleActionById(actionId: string, data: any) {
    switch (actionId) {
      case Actions.USER_DELETE.id:
        break;
      default:
        console.error('There is no handler for action: ', actionId);
        break;
    }
  }

  openUrl(url: string) {
    if (this.appInfoService.isMobileVersion) {
      this.appInterfaceService.openUrl(url);
    } else {
      window.open(url);
    }
  }

  openProductDetail(productId: string) {
    this.dialogService.open(ProductDetailDialogComponent, {
      data: {productId},
      width: '90%',
      height: '90%',
    });
  }

  createProduct() {
    this.dialogService.open<ProductCreateDialogComponent>(
      ProductCreateDialogComponent,
      {
        width: '90%',
        height: '90%',
      },
    );
  }

  resetPassword(userId: string) {
    this.dialogService.open<
      UserManagementResetPasswordDialogComponent,
      UserManagementResetPasswordDialogData
    >(UserManagementResetPasswordDialogComponent, {
      data: {userId},
      width: '600px',
      height: '600px',
    });
  }

  createUser() {
    this.dialogService.open<UserManagementCreateUserDialogComponent>(
      UserManagementCreateUserDialogComponent,
      {
        width: '800px',
        height: '540px',
      },
    );
  }

  openUserDetail(params: {
    userProfileId: string;
    forceProfileUpdate?: boolean;
  }) {
    this.dialogService.open<
      UserManagementDetailDialogComponent,
      UserManagementDetailDialogData
    >(UserManagementDetailDialogComponent, {
      data: params,
      width: '90%',
      height: '90%',
    });
  }

  deleteUsers(input: ActionDelete[]) {
    this.confirmBeforeDelete({
      caption: this.translateService.instant('views.users.delete-users'),
      message: this.translateService.instant(
        'views.users.delete-users-confirm',
        {selected: input.length},
      ),
    }).then(dialogResult => {
      if (dialogResult) {
        this.userStorageService.deleteMultiple(input);
      }
    });
  }

  createStore() {
    return this.dialogService.open<StoreCreateDialogComponent, void, IStore>(
      StoreCreateDialogComponent,
      {
        width: '90%',
        height: '90%',
      },
    ).closed;
  }

  openStoreDetail(storeId: string) {
    this.dialogService.open<StoreDetailDialogComponent, StoreDetailDialogData>(
      StoreDetailDialogComponent,
      {
        data: {storeId},
        width: '90%',
        height: '90%',
      },
    );
  }

  deleteStore(input: ActionDelete) {
    return this.confirmBeforeDelete({
      caption: this.translateService.instant(
        'entity.store.actions.delete.header',
      ),
      message: this.translateService.instant(
        'entity.store.actions.delete.message',
      ),
    }).then(dialogResult => {
      if (dialogResult) {
        this.storeStorageService.delete(input);
      }
      return dialogResult;
    });
  }

  deleteObjectProperty(input: ActionDelete) {
    return this.newConfirmBeforeDelete({
      title: this.translateService.instant(
        'entity.object-property.dialog.delete.title',
      ),
      description: this.translateService.instant(
        'entity.object-property.dialog.delete.description',
      ),
    }).subscribe({
      next: response => {
        if (response === 'accepted') {
          this.objectStorageService.deleteProperty(input);
        }
      },
    });
  }

  createObjectProperty(objectTypeId: ObjectTypeIds) {
    return this.dialogService.open<
      ObjectPropertyDialogComponent,
      ObjectPropertyDialogData
    >(ObjectPropertyDialogComponent, {
      width: '500px',
      height: '520px',
      data: {
        objectTypeId,
      },
    }).closed;
  }

  updateObjectProperty(props: ObjectPropertyDialogData) {
    return this.dialogService.open<
      ObjectPropertyDialogComponent,
      ObjectPropertyDialogData
    >(ObjectPropertyDialogComponent, {
      width: '500px',
      height: '520px',
      data: props,
    });
  }

  createObjectForm(objectTypeId: ObjectTypeIds) {
    const canEdit = this.authGuardService.hasRoleAny(
      RoleNames.ObjectProperties,
      RoleNames.ObjectPropertiesEdit,
      RoleNames.Admin,
    );

    const canRead = this.authGuardService.hasRoleAny(
      RoleNames.ObjectProperties,
      RoleNames.ObjectPropertiesEdit,
      RoleNames.ObjectPropertiesView,
      RoleNames.Admin,
    );
    return this.dialogService.open<
      FormDesignerDialogComponent,
      FormDesignerDialogData
    >(FormDesignerDialogComponent, {
      width: '90vw',
      height: '90vh',
      data: {objectTypeId, readonly: canRead && !canEdit},
    });
  }

  updateObjectForm(props: FormDesignerDialogData) {
    return this.dialogService.open<
      FormDesignerDialogComponent,
      FormDesignerDialogData
    >(FormDesignerDialogComponent, {
      width: '90vw',
      height: '90vh',
      data: props,
    });
  }

  deleteObjectForm(input: ActionDelete) {
    return this.newConfirmBeforeDelete({
      title: this.translateService.instant(
        'entity.object-form.dialog.delete.title',
      ),
      description: this.translateService.instant(
        'entity.object-form.dialog.delete.description',
      ),
    }).subscribe({
      next: response => {
        if (response === 'accepted') {
          this.objectStorageService.deleteForm(input);
        }
      },
    });
  }

  deleteProducts(input: ActionDelete[]) {
    this.confirmBeforeDelete({
      caption: this.translateService.instant('views.products.delete-products'),
      message: this.translateService.instant(
        'views.products.delete-products-confirm',
        {selected: input.length},
      ),
    }).then(dialogResult => {
      if (dialogResult) {
        this.productStorageService.deleteMultiple(input);
      }
    });
  }

  createClientSideErrorConf() {
    return this.dialogService.open<
      ClientSideErrorConfDialogComponent,
      ClientSideErrorConfDialogData
    >(ClientSideErrorConfDialogComponent, {
      width: '500px',
      height: '520px',
    });
  }

  updateClientSideErrorConf(data: IClientSideErrorConf) {
    return this.dialogService.open<
      ClientSideErrorConfDialogComponent,
      ClientSideErrorConfDialogData
    >(ClientSideErrorConfDialogComponent, {
      width: '500px',
      height: '620px',
      data: {
        setting: data,
      },
    });
  }

  deleteClientSideErrorConf(input: {id: string}) {
    return this.dialogService
      .danger({
        acceptLabel: this.translateService.instant('buttons.delete'),
        rejectLabel: this.translateService.instant('buttons.cancel'),
        showCloseButton: false,
        title: this.translateService.instant(
          'entity.client-side-error-conf.dialogs.delete.title',
        ),
        description: this.translateService.instant(
          'entity.client-side-error-conf.dialogs.delete.description',
        ),
      })
      .closed.subscribe({
        next: response => {
          if (response === 'accepted') {
            this.clientSideErrorConfStorageService.delete(input);
          }
        },
      });
  }

  openRawData(data: any) {
    return this.dialogService.open<RawDataDialogComponent, RawDataDialogData>(
      RawDataDialogComponent,
      {
        data: {
          json: data,
        },
        width: '80%',
        height: '80%',
      },
    );
  }

  createTranslation() {
    return this.dialogService.open<
      TranslationEditDialogComponent,
      TranslationEditDialogData
    >(TranslationEditDialogComponent, {
      width: '600px',
      height: '600px',
    });
  }

  updateTranslation(data: LocalizedValue) {
    return this.dialogService.open<
      TranslationEditDialogComponent,
      TranslationEditDialogData
    >(TranslationEditDialogComponent, {
      width: '600px',
      height: '600px',
      data: {
        localizedValue: data as any,
      },
    });
  }

  deleteTranslation(input: ActionDelete[]) {
    return this.newConfirmBeforeDelete({
      title: this.translateService.instant(
        'entity.translation.dialog.delete.title',
      ),
    }).subscribe({
      next: response => {
        if (response === 'accepted') {
          this.i18nStorageService.deleteMultiple(input);
        }
      },
    });
  }

  createNewsPost() {
    return this.dialogService.open<
      NewsPostEditDialogComponent,
      NewsPostEditDialogData
    >(NewsPostEditDialogComponent, {
      width: '80%',
      height: '80%',
    });
  }

  updateNewsPost(data: NewsPost) {
    return this.dialogService.open<
      NewsPostEditDialogComponent,
      NewsPostEditDialogData
    >(NewsPostEditDialogComponent, {
      width: '80%',
      height: '80%',
      data: {
        newsPostValue: data as any,
      },
    });
  }

  deleteNewsPost(input: ActionDelete[]) {
    return this.newConfirmBeforeDelete({
      title: this.translateService.instant(
        'entity.news-post.dialog.delete.title',
      ),
      description: this.translateService.instant(
        'entity.news-post.dialog.delete.description',
      ),
    }).subscribe({
      next: response => {
        if (response === 'accepted') {
          this.newsPostSorageService.deleteMultiple(input);
        }
      },
    });
  }

  openVisit(params: {data: any; tourPlanId: string}) {
    if (this.appInfoService.isMobileVersion) {
      this.router.navigate([`/data-collection/visit/${params.tourPlanId}`], {
        state: params.data,
      });
    } else {
      const stateJson = JSON.stringify(params.data);
      const url = this.router.serializeUrl(
        this.router.createUrlTree(
          [`/data-collection/visit/${params.tourPlanId}`],
          {
            queryParams: {state: encodeURIComponent(stateJson)},
          },
        ),
      );
      window.open(url, '_blank');
    }
  }

  private newConfirmBeforeDelete(input: {title: string; description?: string}) {
    return this.dialogService.danger({
      acceptLabel: this.translateService.instant('buttons.delete'),
      rejectLabel: this.translateService.instant('buttons.cancel'),
      showCloseButton: false,
      title: input.title,
      description: input.description,
    }).closed;
  }

  private confirmBeforeDelete(input: {caption: string; message: string}) {
    return this.confirmationService.confirm(
      ConfirmType.DELETE,
      input.caption,
      input.message,
    );
  }
}

export interface ActionDelete {
  id: string;
  version?: number;
}

export interface ActionUpdate<T> {
  data: Partial<T>;
  id: string;
}
