import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {DataProvider} from '../data.provider/data-provider';
import {
  Customer,
  ExecuterClaims,
  ExtendedPhoto,
  Feedback,
  FeedbackWithPhotosRequest,
  FeedbackWithUserInfo,
  generateFullPath,
  GenericList,
  getPurePhoto,
  KPISet,
  MobilePhoto,
  ObjectTypePropertyValues,
  OpenVisitResponse,
  PersonalArrangement,
  Photo,
  PhotoCreateRequest,
  PhotoTag,
  PhotoTagComment,
  Position,
  prepareMobilePhoto,
  Product,
  ProductCategory,
  QuestionnaireAnswer,
  QuestionnaireResult,
  SetExtendedPropertiesRequest,
  Tag,
  TodoActionResult,
  TodoList,
  TodoListItem,
  TodoListItemType,
  TodoListResult,
  TourPlan,
  TourPlanExecutor,
  User,
  UserKPISets,
  UsernameResponse,
  UserProfile,
  VisitDataResponse,
  VisitHistoryData,
  VisitResultRequest,
  VisitResultResponse,
  WhoAmIResponse,
} from '../models';
import * as moment from 'moment';
import {
  formatUser,
  getDecimalPart,
  getTimeAsDecimal,
  safeParseJSON,
} from '../utils/utils';
import {
  PromoAction,
  PromoActionWithRelationMandatory,
} from '../models/promo-action.model';
import {AuthGuardService} from './auth.service';
import {Questionnaire, QuestionnaireItem} from '../models/questionnaire-model';
import {AppInterfaceService} from '../app-interface/app-interface.service';
import cloneDeep from 'clone-deep';
import query from 'devextreme/data/query';
import {Comment} from '../models/comment.model';
import deepEqual from 'deep-equal';
import {NotificationService} from './notification.service';
import {TranslateService} from '@ngx-translate/core';
import {sortByName} from '../globals';
import {ClientModeService} from './client-mode.service';
import {ConnectionSpeed} from '../enums/connection-type.enum';
import {ConfirmationService, ConfirmType} from './confirmation.service';
import {AppInfoService} from './app-info.service';
import {ClientMode} from '../enums/client-mode.enum';
import {NavigationExtras, Router} from '@angular/router';
import {
  PhotoLibraryRequest,
  ResamplingOptions,
} from '../app-interface/request-model';
import {PersonalArrangementChange} from '../components/personal-arrangement/personal-arrangement-grid.component';
import {PhotoTransferService} from './photo-transfer.service';
import {SettingNames} from '@retrixhouse/salesapp-shared/lib/settings';
import {
  IProjectTag,
  VisitResultStatus,
  TourPlanState,
  AnswerType,
  PhotoSource,
} from '@retrixhouse/salesapp-shared/lib/models';
import {BehaviorSubject, Observable, Subject, combineLatest} from 'rxjs';
import {
  ProjectContextPermissions,
  StoragePrefixes,
} from '@retrixhouse/salesapp-shared/lib/common';
import {map, tap} from 'rxjs/operators';
import {RequestProgressService} from '../components';
import {UploadPhotoResponse} from '@retrixhouse/salesapp-shared/lib/responses/upload-photo';
import {Filter} from '@loopback/filter';
import {AnyObject} from '@loopback/filter/dist/types';

const VISIT_SNAPSHOT_KEY = 'visit-snapshot';
export const OPENED_VISIT_KEY = 'opened-visit-id';
export const VISIT_EXECUTOR = 'visit-executor';

export enum WhoAmIResult {
  OK,
  HTTP_401_NO_ACTION,
  HTTP_401_RELOAD,
  HTTP_5xx,
  HTTP_TIMEOUT,
  HTTP_OTHER,
  OFFLINE,
}

export type AppLastState = {
  todoListItemId?: string;
  questionnaireId?: string;
  questionnaireResultId?: string;
  questionnaireItemId?: string;
  personalArrangementId?: string;
  promoActionId?: string;
  leaveReason?: 'capture' | 'gallery' | 'barcode' | 'unknown';
  // the upload status of the app before crashed/forcly-closed
  pendingUploadsStatus?: 'error-data-uploading' | 'error-photo-uploading';
  // the new state of the visit after the user clicked on suspend/finish buttons.
  visitUpdatedState?: TourPlanState;
  appInterfacePayload?: ResamplingOptions | PhotoLibraryRequest;
  photoObjectId?: string;
  userId: string;
  paChanges?: PersonalArrangementChange[];
  questionnaireOrActionResults?: any;
  // the ids of the sucessfully uploaded photos.
  uploadedPhotos?: string[];
};
type Snapshot = {
  todoListResult: TodoListResult;
  mobilePhotoList: MobilePhoto[];
  visitId: string;
  photoList: Photo[];
  executer: TourPlanExecutor;
  storePropertiesValues: SetExtendedPropertiesRequest;
  lastState: AppLastState;
  todoList: TodoList;
};

@Injectable()
export class VisitDataService {
  private _visitData: VisitDataResponse;
  private _currentTodoAction: TodoListItem;
  private _currentPA: PromoAction | undefined;
  private _currentExecuter: TourPlanExecutor;
  private _currentUserDetails: UsernameResponse;
  private _qrId: string;
  private _storeObjectType: ObjectTypePropertyValues;
  private _productPropertyValues: {
    [key: string]: {name: string; values: string[]};
  } = {};
  private _storePropertiesValues: SetExtendedPropertiesRequest;
  private _currentQuestion: QuestionnaireItem;
  /**
   * The callback url that is used when the user leaves the visit.
   */
  public visitLeaveCbUrl = new BehaviorSubject<string>(undefined);
  private _mobilePhotoList: MobilePhoto[];
  private _currentPersonalArrangementId: string;
  /**
   * The original executor of the visit. Only available when the visit is being executed by a superior user.
   */
  public originalExecutorId$ = new BehaviorSubject<string>(undefined);
  private _visitCommentsModifier = new CommentsModifier([]);
  private myKPISets: KPISet[] = [];
  private projectUsersKPISets: UserKPISets[] = [];
  private feedback: Feedback;
  private visitFeedbackList: FeedbackWithUserInfo[] = [];
  private userProjectPermissions: string[] = [];
  private feedbackComments: Comment[];
  private _tempData = new Map<string, any>();
  private _removedPaIdList: string[];
  private allTags: Tag[];
  private projectTags: IProjectTag[];
  /**
   * The current state of the app.
   */
  private _appState: AppLastState;
  /**
   * The state before the app was crashed.
   */
  private _appPreviousState: AppLastState;
  /**
   * Emits `true` when the there are some changes in the Finalized visits.
   */
  finalizedVisitChanged$ = new BehaviorSubject<boolean>(false);
  /**
   * Used to put the app in the loading state. It is a GENERAL event.
   * Emits `true` when the app enters the loading state.
   * Emits `false` when the app exits the loading state.
   */
  isLoading$ = new Subject<boolean>();
  /**
   * Emits `true` when the visit is being uploaded and `false` when the visit is being uploaded.
   */
  isUploading$ = new Subject<boolean>();
  /**
   * false when the process of taking snapshot is not in progress.
   * While in progress, contains NodeJS.Timeout the timer of taking a snapshot.
   */
  snapshotInProgress: NodeJS.Timeout | false;
  /**
   * A helper used to manage the visits available in the history.
   */
  visitsHistory: VisitsHistory;
  /**
   * Contains the details of the pending uploads.
   */
  pendingUploads: {
    status: 'error-data-uploading' | 'error-photo-uploading' | undefined;
    visitUpdatedState: TourPlanState;
    uploadedPhotos?: string[];
  };
  readonlyMode$ = new BehaviorSubject<boolean>(undefined);
  isViewVisitSuperior$ = new BehaviorSubject<boolean>(false);
  photosMetadataAllowed: boolean = true;
  modifiedFeedbackIds: string[] = [];
  photosMetadataAllowed$: Observable<boolean> = combineLatest([
    this.isViewVisitSuperior$,
    this.originalExecutorId$,
  ]).pipe(
    map(([isViewVisitSuperior, originalExecutorId]) => {
      if (isViewVisitSuperior) {
        // disable photos metadata when the user has the permission ViewVisitSuperior and visit is being executed by a superior user.
        if (originalExecutorId) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    }),
    tap(
      photosMetadataAllowed =>
        (this.photosMetadataAllowed = photosMetadataAllowed),
    ),
  );

  get editingBySuperUser() {
    return !!this.originalExecutorId$.value;
  }

  get visitId() {
    return this._visitData?.visit?.id;
  }

  get projectId() {
    return this._visitData?.visit?.projectId;
  }

  get storeId() {
    return this._visitData?.visit?.storeId;
  }

  get readonlyMode() {
    return this.readonlyMode$.getValue();
  }

  get store() {
    return this._visitData.visit.store;
  }

  get todoActions() {
    return this._visitData?.visit?.project?.todoList?.todoActions ?? [];
  }

  constructor(
    private dataProvider: DataProvider,
    public authGuardService: AuthGuardService,
    private appInterfaceService: AppInterfaceService,
    private translate: TranslateService,
    public clientModeService: ClientModeService,
    private confirmationService: ConfirmationService,
    public appInfoService: AppInfoService,
    private photoTransferService: PhotoTransferService,
    private router: Router,
    private requestProgressService: RequestProgressService,
  ) {}

  /**
   * Loads all data related to a specific visit
   * @param {string} visitId - the id of the visit.
   */
  private async loadVisitData(visitId: string): Promise<VisitDataResponse> {
    this._visitData = await this.dataProvider.visit.getVisitData(
      visitId,
      this.originalExecutorId$.value,
    );

    return this._visitData;
  }

  private async loadVisitComments(visitId: string) {
    if (visitId && this.clientModeService.isClientOnline) {
      try {
        const comments =
          await this.dataProvider.comment.getCommentListByObjectId(visitId);
        return comments;
      } catch (error) {
        console.error('Visit - Comments loading error', error);
        return Promise.resolve([]);
      }
    } else {
      return Promise.resolve([]);
    }
  }

  private async loadMyKPISets(projectId: string): Promise<KPISet[]> {
    if (projectId && this.clientModeService.isClientOnline) {
      try {
        const kpiSets = await this.dataProvider.project.getMyKPISets(
          this.projectId,
        );

        return kpiSets;
      } catch (error) {
        console.error('load my KPI sets error:', error);
        return Promise.resolve([]);
      }
    }
    return Promise.resolve([]);
  }

  async loadProjectUsersKPISets(projectId: string): Promise<UserKPISets[]> {
    if (projectId && this.clientModeService.isClientOnline) {
      try {
        const kpiSets = await this.dataProvider.project.getProjectUsersKPISets(
          this.projectId,
        );

        return kpiSets;
      } catch (error) {
        console.error('load projectusers KPI sets error:', error);
        return Promise.resolve([]);
      }
    }
    return Promise.resolve([]);
  }

  private async loadMyFeedback(visitId: string): Promise<Feedback> {
    if (visitId && this.clientModeService.isClientOnline) {
      try {
        // always use the user id of the current user (doesn't matter whther the user is the original one or a superior one)
        const up = await this.authGuardService.getUserProfile();

        const feedbackList = await this.dataProvider.feedback.getList({
          where: {
            createdById: up.userId,
            tourPlanId: this.visitId,
          },
          include: [{relation: 'kpiValueSets'}, {relation: 'kpiValues'}],
        });

        return feedbackList?.[0];
      } catch (error) {
        console.error('load my feedback error:', error);
        return Promise.resolve(undefined);
      }
    }
    return Promise.resolve(undefined);
  }

  async loadVisitFeedbacks(visitId: string) {
    if (visitId && this.clientModeService.isClientOnline) {
      try {
        const visitFeedbacks = await this.dataProvider.feedback.getList({
          where: {
            tourPlanId: visitId,
          },
          include: [{relation: 'kpiValueSets'}, {relation: 'kpiValues'}],
        });

        // add visit data to feedback
        visitFeedbacks.forEach(f => (f.tourPlan = this._visitData.visit));
        return visitFeedbacks;
      } catch (error) {
        console.error('load visit feedbacks error:', error);
        return Promise.resolve([]);
      }
    } else {
      return Promise.resolve([]);
    }
  }

  getVisitsHistory() {
    return this.visitsHistory;
  }

  setVisitsHistory(): VisitsHistory {
    this.visitsHistory = new VisitsHistory(
      {
        id: this.visitId,
        startedAt: this._visitData.visit.startedAt,
      },
      this._visitData?.previousVisitData ?? [],
    );

    return this.visitsHistory;
  }

  /**
   * Saves the data of the current visit.
   */
  private async saveVisitData() {
    const todoListResult = this.getCurrentTodoListResult();

    // delete any extra properties added on client side
    delete this._currentExecuter?.resourceType;
    if (Array.isArray(todoListResult.todoActionResults)) {
      todoListResult.todoActionResults.forEach(ar => {
        if (Array.isArray(ar.personalArrangements)) {
          ar.personalArrangements.forEach(pa => {
            delete pa.display;
            delete pa.position;
            delete pa.drive;
          });
        }
      });
    }

    // prepare the comments and tags of photos
    // also remove any extra properties added on cilent side
    const photoTagCommentList: PhotoTagComment[] = [];
    this._visitData?.photos?.forEach(ph => {
      if (Array.isArray(ph.commentList)) {
        ph.commentList.forEach(c => {
          delete c.userInfo;
          delete c.pictureUrl;
        });
      }

      photoTagCommentList.push({
        photoId: ph.id,
        commentList: ph.commentList,
        tagList: ph.photoTagList,
      });
    });

    try {
      if (
        // when everything is normal
        !this.pendingUploads ||
        // or when the visit starts with a pending uploads, ignore the visit result uploading if it was uploaded previously.
        this.pendingUploads?.status === 'error-data-uploading'
      ) {
        // Set the state immediately before sending the request.
        // If no error happens, the state will be cleared at the end before leaving the visit.
        this.saveAppState({
          // set that all photos (if exist) were uploaded successfully when the app reaches this point (to use in the future when the app is restored from carsh)
          uploadedPhotos: this._appState?.uploadedPhotos ?? [],
          visitUpdatedState: this._visitData?.visit.state,
          pendingUploadsStatus: 'error-data-uploading',
          userId: this._currentUserDetails?.id,
        });
        const saveVisitResult = await this.requestProgressService.addProgress<
          (visitDataRequest: VisitResultRequest) => Promise<VisitResultResponse>
        >(
          this.translate.instant('labels.visit-result'),
          'fa-solid fa-calendar-pen',
          this.translate.instant(
            'views.visit.pending-upload-abort-confirmation',
          ),
          this.translate.instant('views.visit.pending-upload-abort-message'),
          this.dataProvider.visit.saveVisitData.bind(this.dataProvider.visit),
          [
            {
              clientVersion: this.appInfoService.clientVersion,
              todoListResult: todoListResult,
              claims: this._currentExecuter,
              storeObjectPropertyValues: this._storePropertiesValues,
              photoTagCommentList: photoTagCommentList,
              removedPersonalArrangementIdList: this._removedPaIdList,
              editingOnBehalfOf: this.editingBySuperUser
                ? this.originalExecutorId$.value
                : undefined,
              feedbackComments: this.feedbackComments,
              modifiedFeedbackIds: this.modifiedFeedbackIds,
              photos: this._mobilePhotoList
                ?.filter(mp => !mp.isUploaded)
                .map(
                  mp =>
                    <PhotoCreateRequest>{
                      photo: getPurePhoto(mp.photo),
                      visitId: this.visitId,
                    },
                ),
            },
          ],
        );

        if (saveVisitResult.success === true) {
          const saveVisitResponse = saveVisitResult.response;
          if (saveVisitResponse.status === VisitResultStatus.Warning) {
            console.warn('Visit result saved with warning:', saveVisitResponse);
          }
        }
      }

      // Save the state immediately before sending the request.
      // If no error happens, the state will be cleared at the end before leaving the visit.
      this.saveAppState({
        visitUpdatedState: this._visitData?.visit.state,
        pendingUploadsStatus: 'error-photo-uploading',
        userId: this._currentUserDetails?.id,
      });

      await this.uploadMobilePhotos();
    } catch (error) {
      console.error(error);
      NotificationService.notifyError(
        this.translate.instant('views.visit.error-while-saving-visit'),
      );
      this.navigateOut();
    } finally {
      await this.resetVisitVariables();
    }
  }

  /**
   * Uploads the photos captured during the visit on the mobile app.
   */
  async uploadMobilePhotos(ids: string[] = []): Promise<void> {
    let errorHappened = false;
    const filteredPhotoList = (this._mobilePhotoList || []).filter(
      photo =>
        !photo.isUploaded && (ids.length ? ids.includes(photo.photo.id) : true),
    );
    if (filteredPhotoList?.length > 0) {
      let currentPhotoNum = 0;
      const successfullyUploadedPhotos: string[] = [];
      const todoActionWithDuplicates = new Map<string, string>();
      for await (const mp of this._mobilePhotoList) {
        try {
          currentPhotoNum++;

          // Whether the photo was uploaded before the app was forcly closed/crashed.
          const uploadedPreviously =
            this.pendingUploads?.uploadedPhotos?.includes(mp.photo.id);

          if (!uploadedPreviously) {
            const photoUploadingResult =
              await this.requestProgressService.addProgress<
                (
                  mobilePhoto: MobilePhoto,
                  visitId: string,
                  uploadStatus?: (
                    currentChunkNumber: number,
                    numberOfChunks: number,
                    currentChunkSize: number,
                  ) => void,
                ) => Promise<UploadPhotoResponse>
              >(
                `${currentPhotoNum} / ${this._mobilePhotoList.length}`,
                'fa-regular fa-image',
                this.translate.instant(
                  'views.visit.pending-upload-abort-confirmation',
                ),
                this.translate.instant(
                  'views.visit.pending-upload-abort-message',
                ),
                this.photoTransferService.uploadInChunks.bind(
                  this.photoTransferService,
                ),
                [mp, this.visitId],
                true,
              );

            const photoUploadingResponse = photoUploadingResult.response;
            if (
              photoUploadingResult.success &&
              photoUploadingResponse?.message === 'photo-duplicate'
            ) {
              const todoAction = this.getTodoActionByPhotoObjectId(
                mp.photo.objectId,
              );

              todoActionWithDuplicates.set(mp.photo.id, todoAction?.text);
              this.requestProgressService.addMessage({
                type: 'warn',
                text: this.translate.instant('views.visit.photo-duplicate', {
                  currentPhotoNum: currentPhotoNum,
                }),
              });
              continue;
            }
            // if the user aborts uploading any photo, don't proceed with the remaining ones.
            else if (!photoUploadingResult) {
              break;
            }
          }

          mp.isUploaded = true;
          // save the successfully uploaded photos to the state.
          successfullyUploadedPhotos.push(mp.photo.id);
          this.saveAppState({
            pendingUploadsStatus: 'error-photo-uploading',
            userId: this._currentUserDetails?.id,
            uploadedPhotos: successfullyUploadedPhotos,
            visitUpdatedState: this._visitData?.visit?.state,
          });
        } catch (e) {
          errorHappened = true;
        }
      }

      if (errorHappened) {
        NotificationService.notifyError(
          this.translate.instant('views.visit.some-invalid-photos'),
        );
      }

      await this.cleanPhotoStorage(ids);

      if (todoActionWithDuplicates?.size > 0) {
        const duplicatePhotoIds = Array.from(todoActionWithDuplicates.keys());

        await this.confirmationService.confirm(
          ConfirmType.DEFAULT,
          this.translate.instant('views.visit.duplicate-photos-title'),
          this.translate.instant('views.visit.duplicate-photos-message', {
            todoActions: Array.from(todoActionWithDuplicates.values())
              ?.filter(v => !!v)
              ?.join(', '),
          }),
        );

        // in case of feedback, we don't leave the visit after uploading the photos, so we need to remove the duplicate photos from the visits.
        await this.removePhotos(duplicatePhotoIds);
      }
    }
  }

  getTodoActionByPhotoObjectId(objectId: string) {
    const todoListResult = this.getCurrentTodoListResult();
    const todoActionResults = todoListResult?.todoActionResults ?? [];
    const todoActionResult = todoActionResults?.find(f => objectId === f.id);

    if (todoActionResult?.todoListItemId) {
      return this.todoActions.find(
        ta => ta.id === todoActionResult.todoListItemId,
      )?.todoAction;
    } else {
      // todoListResult?.questionnaireResults?.map(m=> m.questionnaireAnswers)?.flat()
      const taResult = todoActionResults?.find(tar => {
        return tar.questionnaireResults?.find(qr => {
          const questionnaire = this.getQuestionnaire(qr.questionnaireId);
          if (!questionnaire) {
            return false;
          }
          const questionnaireItems = questionnaire.questions;
          return qr.questionnaireAnswers?.find(qa => {
            const qItem = questionnaireItems.find(
              qi => qi.id === qa.questionnaireItemId,
            );
            if (!qItem) {
              return false;
            }

            if (qItem?.question?.answerType === AnswerType.SinglePhoto) {
              return qa.answer.includes(objectId);
            } else if (qItem?.question?.answerType === AnswerType.MultiPhoto) {
              return qa.answer?.includes(objectId);
            } else {
              return false;
            }
          });
        });
      });

      return this.todoActions.find(ta => ta.id === taResult?.todoListItemId)
        ?.todoAction;
    }
  }

  /**
   * Ignores the ongoing uploads and leaves the visit.
   */
  async ignoreVisitUploadingAndLeave() {
    await this.cleanPhotoStorage();
    await this.resetVisitVariables();
    this.navigateOut();
  }

  /**
   * Returns todo list result object of the current visit.
   * @returns {TodoListResult} - todo list result object.
   */
  getCurrentTodoListResult(): TodoListResult {
    return this._visitData?.todoListResult;
  }

  /**
   * Returns the active feedbacks
   * @returns {FeedbackWithUserInfo[]}
   */
  getActiveFeedbacks(): FeedbackWithUserInfo[] {
    return this._visitData?.activeFeedbacks ?? [];
  }

  modifyFeedbackComment(comment: Comment) {
    if (!this.feedbackComments) {
      this.feedbackComments = [];
    }

    const feedbackComment = this.feedbackComments.find(
      c => c.objectId === comment.objectId,
    );
    if (feedbackComment) {
      Object.assign(feedbackComment, {comment: comment.comment});
    } else {
      this.feedbackComments.push(comment);
    }
  }

  getFeedbackComments(asFeedbackIdCmntMap: boolean = false) {
    if (!asFeedbackIdCmntMap) {
      return this._visitData.feedbackComment ?? [];
    }

    if (this._visitData.feedbackComment?.length > 0) {
      const feedIdToComment: Map<string, string> = new Map();

      const feedbackComments = this.feedbackComments;

      feedbackComments.forEach(fdcm => {
        feedIdToComment.set(fdcm.objectId, fdcm.comment);
      });

      return feedIdToComment;
    } else {
      return new Map<string, string>();
    }
  }

  getFeedbackPhotos(feedbackIds: string[]): Photo[] {
    return this.getPhotoList(ph => feedbackIds.includes(ph.objectId));
  }

  validateActiveFeedbacks() {
    const activeFeedbacks = this.getActiveFeedbacks();

    const notValidFeedbacks: {
      text: string;
      html: string;
      errorBlockId: string;
    }[] = [];
    let notFilledComment = false;
    let notTakenPhoto = false;
    activeFeedbacks
      // only validate the feedbacks that have kpi values (because the others are hidden)
      .filter(af => af.kpiValues?.some(s => s.value > 0))
      .forEach(af => {
        if (af.commentRequired) {
          const fbComments = this.feedbackComments.filter(
            f => f.objectId === af.id && f.comment,
          );
          if (fbComments.length === 0 && !notFilledComment) {
            notFilledComment = true;
            const translation = this.translate.instant(
              'visit.feedback-required-comments',
            );
            notValidFeedbacks.push({
              html: `<span style="color:red;">${translation}</span>`,
              text: translation,
              errorBlockId: 'active-feedbacks',
            });
          }
        }

        if (af.photoRequired) {
          const fbPhotos = this.getPhotoList(ph => ph.objectId === af.id);

          if (fbPhotos.length === 0 && !notTakenPhoto) {
            notTakenPhoto = true;
            const translation = this.translate.instant(
              'visit.feedback-required-photos',
            );
            notValidFeedbacks.push({
              html: `<span style="color:red;">${translation}</span>`,
              text: translation,
              errorBlockId: 'active-feedbacks',
            });
          }
        }
      });

    return notValidFeedbacks;
  }

  /**
   * Resolve multiple setting values from project settings then system settings.
   * @param {string[]} names - setting names to get the value of.
   * @returns {{ [prop: string]: any }} - object of setting values.
   */
  resolveSettings(
    names: SettingNames[],
    projectId: string,
  ): {[prop: string]: any} | undefined {
    return this.dataProvider.settingResolver.getValues(names, projectId);
  }

  /**
   * Resolve a setting value from project settings then system settings.
   * @param {string} settingName - setting name to get the value of.
   * @returns {any} setting value
   */
  resolveSetting(settingName: SettingNames, projectId: string): any {
    return this.dataProvider.settingResolver.getValue(settingName, projectId);
  }

  /**
   * Returns the settings of the personal arrangement component.
   * @returns {{ Promise<{[prop: string]: any}> }} - object of product avatar settings values.
   */
  getPersonalArrangementSettings(): {[prop: string]: any} {
    return this.resolveSettings(
      [
        SettingNames.PersonalArrangement_DurationRestriction,
        SettingNames.PersonalArrangement_DurationFixDays,
        SettingNames.PersonalArrangement_DurationMinDays,
        SettingNames.PersonalArrangement_DurationMaxDays,
        SettingNames.PersonalArrangement_RetakePhotoRequired,
        SettingNames.PersonalArrangement_PhotoRequired,
        SettingNames.PersonalArrangement_DisablePhotoTagsValidation,
      ],
      this.projectId,
    );
  }

  getPhotosSettings() {
    const settings = this.resolveSettings(
      [
        SettingNames.PhotoGallery_MaxWidth,
        SettingNames.PhotoGallery_MaxHeight,
        SettingNames.PhotoGallery_AutoJpegCompression,
        SettingNames.PhotoGallery_MaxFileSize,
        SettingNames.PhotoGallery_MinHeight,
        SettingNames.PhotoGallery_MinWidth,
        SettingNames.PhotoGallery_RestrictGalleryToPosition,
      ],
      this.projectId,
    );

    const restrictSetting = (settings[
      SettingNames.PhotoGallery_RestrictGalleryToPosition
    ] ?? []) as string[];
    settings[SettingNames.PhotoGallery_RestrictGalleryToPosition] =
      restrictSetting.includes(this._currentUserDetails.positionId);

    return settings;
  }

  /**
   * Updates the state of the current visit.
   * @param {TourPlanState} state - the new state of the visit.
   */
  private async updateVisitState(state: TourPlanState): Promise<void> {
    const result = await this.requestProgressService.addProgress<
      (tourPlanId: string, state: TourPlanState) => Promise<TourPlan>
    >(
      this.translate.instant('labels.visit-state'),
      'fa-solid fa-calendar-pen',
      this.translate.instant('views.visit.pending-upload-abort-confirmation'),
      this.translate.instant('views.visit.pending-upload-abort-message'),
      this.dataProvider.tourPlan.updateStateIgnoreVersion.bind(
        this.dataProvider.tourPlan,
      ),
      [this.visitId, state],
    );

    if (result.success === false) {
      return;
    } else {
      this.updateCurrentVisit(result.response);
    }
  }

  /**
   * Removes the deleted photos during visit from photos list of the current visit.
   * @param {string[]} deletedPhotoIdList - photo id list to delete.
   */
  async removePhotos(deletedPhotoIdList: string[]) {
    this._visitData.photos = this._visitData.photos?.filter(
      fi => !deletedPhotoIdList.includes(fi.id),
    );

    // Try to remove images from the blob storage.
    await Promise.all(
      deletedPhotoIdList?.map(phId =>
        this.dataProvider.photoObject.deletePhoto(phId).catch(ignored => {}),
      ),
    );

    if (this.appInfoService.isMobileVersion) {
      if (!this._mobilePhotoList) {
        this._mobilePhotoList = [];
      }

      this._mobilePhotoList = this._mobilePhotoList.filter(
        mp => !deletedPhotoIdList.includes(mp.photo.id),
      );

      await this.removePhotosFromStorage(deletedPhotoIdList);
    }
  }

  async removePhotosFromStorage(photoIds: string[]) {
    const kyesExist = await this.appInterfaceService.photoStorageKeysExist(
      photoIds,
    );

    const storagePhotos = Object.entries(kyesExist)
      .filter(v => v[1])
      .map(m => m[0]);

    if (storagePhotos.length > 0) {
      await this.appInterfaceService.photoStorageDelete(storagePhotos);
    }
  }

  /**
   * Appends the uploaded new photo to the visit photos list.
   * @param {photo[]} photoList - new photos to add.
   */
  addNewPhotos(photoList: Photo[]) {
    if (this._visitData) {
      photoList.forEach(ph => {
        ph.tourPlanId = this.visitId;
        ph.projectId = this.projectId;
        ph.storeId = this.storeId;
        ph.todoListResultId = this.getCurrentTodoListResult().id;
      });

      this._visitData.photos ??= [];
      this._visitData.photos.push(...photoList);
    }
  }

  addComment(comment: Comment) {
    const photo = this._visitData.photos.find(ph => ph.id === comment.objectId);
    photo.commentList ??= [];
    photo.commentList.push(comment);

    this.finalizedVisitChanged$.next(true);
  }

  deleteComment(commentIds: string[], photoId: string) {
    const photo = this._visitData.photos.find(ph => ph.id === photoId);
    photo.commentList ??= [];
    photo.commentList = photo.commentList.filter(
      c => !commentIds.includes(c.id),
    );

    this.finalizedVisitChanged$.next(true);
  }

  setPhotoTags(photoTags: PhotoTag[], photoId: string) {
    const photo = this._visitData.photos.find(ph => ph.id === photoId);
    photo.photoTagList = photoTags;

    this.finalizedVisitChanged$.next(true);
  }

  /**
   * Updates the tags or comments of a specific photo.
   * @param { 'TAG' | 'COMMENT' } relation - the relation to update (either tags or comments)
   * @param {Comment[] | PhotoTag[]} items - comment list or photo list to use for updating
   * @param {string} photoId - the id of the photo
   */
  updatePhotoRelations(
    relation: 'TAG' | 'COMMENT',
    items: Comment[] | PhotoTag[],
    photoId: string,
  ) {
    const photo = this._visitData.photos.find(ph => ph.id === photoId);

    // the mapping below is used to get rid of extra properties
    if (relation === 'COMMENT') {
      const commentList = (items as Comment[]).map(
        m =>
          <Comment>{
            id: m.id,
            comment: m.comment,
            objectId: m.objectId,
            postedAt: m.postedAt,
            postedById: m.postedById,
          },
      );
      photo.commentList = commentList;
    } else {
      const tagList = (items as PhotoTag[]).map(
        m =>
          <PhotoTag>{
            id: m.id,
            photoId: m.photoId,
            tagId: m.tagId,
            time: m.time,
            userId: m.userId,
          },
      );
      photo.photoTagList = tagList;
    }
  }

  /**
   * Gets position of the current user.
   * @returns {Promise<Position>} current user position
   */
  async getCurrentUserPosition(): Promise<Position> {
    return this.dataProvider.position.getForUser();
  }

  /**
   * Returns the current visit.
   * @returns {TourPlan}
   */
  getCurrentVisit(): TourPlan {
    return this._visitData?.visit;
  }

  getExecutors(): UsernameResponse[] {
    return this._visitData?.users;
  }

  /**
   * Downloads and returns the current visit's data.
   * If the visit data has not been loaded previously it loads it.
   * If there is a loaded visit data and the provided visit id is different from the loaded visit data's id, it returns undefined and prints an error.
   * @param {string} visitId - the visit id.
   */
  async downloadVisit(visitId?: string): Promise<TourPlan> {
    // if the visitId is not provided, return the current visit data.
    if (!visitId && this._visitData) {
      return this._visitData?.visit;
    }

    // if the visitId is not provided and the there is not active visit, returns undefined.
    if (!visitId) {
      return;
    }

    if (!this._visitData) {
      await this.loadVisitData(visitId);
      await this.setCurrentUserAndExecutor();
      const [
        comments,
        myKPISets,
        projectUsersKPISets,
        feedback,
        visitFeedbackList,
        userProjectPermissions,
      ] = await Promise.all([
        this.loadVisitComments(visitId),
        this.loadMyKPISets(this.projectId),
        this.loadProjectUsersKPISets(this.projectId),
        this.loadMyFeedback(this.visitId),
        this.loadVisitFeedbacks(this.visitId),
        this.dataProvider.project.getUserPermissions(),
      ]);

      this._visitCommentsModifier = new CommentsModifier(comments);
      this.myKPISets = myKPISets;
      this.projectUsersKPISets = projectUsersKPISets;
      this.feedback = feedback;
      this.visitFeedbackList = visitFeedbackList;
      this.userProjectPermissions =
        userProjectPermissions?.[this.projectId] ?? [];

      this.isViewVisitSuperior$.next(
        this.userProjectPermissions.includes(
          ProjectContextPermissions.ViewVisitSuperior,
        ),
      );

      this.feedbackComments = this._visitData.feedbackComment ?? [];
      this._storeObjectType = this._visitData.storeObjectType;
      this._productPropertyValues = this.getFilteredProductPropertyValues(
        this._visitData.productObjectType,
        this._visitData.visit,
      );

      generateFullPath(this._visitData?.productCategories ?? []);

      if (Array.isArray(this._visitData.todoListResult?.todoActionResults)) {
        this._visitData.todoListResult?.todoActionResults.forEach(ar => {
          if (Array.isArray(ar.personalArrangements)) {
            ar.personalArrangements.forEach(pa => {
              if (
                Array.isArray(
                  this._visitData.personalArrangementDisplayList?.items,
                )
              ) {
                pa.display =
                  this._visitData.personalArrangementDisplayList.items.find(
                    i => i.id === pa.displayId,
                  );
              }
            });
          }
        });
      }
      await this.loadSnapshot();
    }
    if (visitId && this._visitData?.visit?.id !== visitId) {
      console.error('There is an already started visit with a different id.');
      return;
    }
    return this._visitData?.visit;
  }

  getFilteredProductPropertyValues(
    productProperty: ObjectTypePropertyValues,
    visit: TourPlan,
  ) {
    const properties = productProperty?.type?.properties ?? [];
    const productPropertyValues = productProperty?.propertyValues ?? [];

    const filteredProductPropertyValues: {
      [key: string]: {name: string; values: string[]};
    } = {};
    if (productPropertyValues?.length > 0) {
      productPropertyValues.forEach(prValue => {
        if (prValue?.extendedContext) {
          const propertyContext = prValue.extendedContext;
          const chainId = visit.store.chainId;
          const storeId = visit.storeId;
          const customerId = visit.project.customerId;
          switch (propertyContext.entity) {
            case 'chain':
              {
                if (chainId === propertyContext.id) {
                  if (!filteredProductPropertyValues[prValue.objectId]) {
                    filteredProductPropertyValues[prValue.objectId] = {
                      name: properties.find(f => f.id === prValue.propertyId)
                        ?.name,
                      values: [],
                    };
                  }

                  filteredProductPropertyValues[prValue.objectId].values.push(
                    JSON.parse(prValue.value ?? null),
                  );
                }
              }
              break;

            case 'store':
              {
                if (storeId === propertyContext.id) {
                  if (!filteredProductPropertyValues[prValue.objectId]) {
                    filteredProductPropertyValues[prValue.objectId] = {
                      name: properties.find(f => f.id === prValue.propertyId)
                        ?.name,
                      values: [],
                    };
                  }

                  filteredProductPropertyValues[prValue.objectId].values.push(
                    JSON.parse(prValue.value ?? null),
                  );
                }
              }
              break;

            case 'customer':
              {
                if (customerId === propertyContext.id) {
                  if (!filteredProductPropertyValues[prValue.objectId]) {
                    filteredProductPropertyValues[prValue.objectId] = {
                      name: properties.find(f => f.id === prValue.propertyId)
                        ?.name,
                      values: [],
                    };
                  }

                  filteredProductPropertyValues[prValue.objectId].values.push(
                    JSON.parse(prValue.value ?? null),
                  );
                }
              }
              break;

            default:
              break;
          }
        }
      });
    }
    return filteredProductPropertyValues;
  }

  /**
   * Updates the current visit's data.
   * @param {TourPlan} newVisit - the updated visit object.
   * @returns {TourPlan} - returns the updated visit object.
   */
  updateCurrentVisit(newVisit: TourPlan): TourPlan {
    Object.assign(this._visitData?.visit, newVisit);
    return this._visitData?.visit;
  }

  /**
   * Returns the reason list of a specific todo action of the current visit.
   * @param {string} todoListItemId - the id of the todo action.
   */
  getReasonList(todoListItemId: string): GenericList {
    return this.todoActions.find(
      todoListItem => todoListItem.id === todoListItemId,
    )?.reasonList;
  }

  /**
   * Returns all available reason lists in the current visit.
   */
  getAllReasonLists(): GenericList[] {
    return this.todoActions
      ?.filter(todoListItem => todoListItem.reasonList !== undefined)
      .map(todoListItem => todoListItem.reasonList);
  }

  /**
   * If the userId is not provided, returns the User model of the current (original) user.
   * Otherwise, returns the User model for the provided id.
   * @param {string} userId - the id of the user.
   * @returns {User} - the user object if exist.
   */
  async getUserById(userId?: string): Promise<User> {
    const usernameResponse = await this.getUsernameResponse(userId);
    const user: User = new User();
    if (usernameResponse) {
      user.id = usernameResponse.id;
      user.uid = usernameResponse.uid;
      user.username = usernameResponse.username;
      user.positionId = usernameResponse.positionId;
      user.position = new Position();
      user.position.name = usernameResponse.positionName;
      user.position.abbreviation = usernameResponse.positionAbbreviation;
      user.profile = new UserProfile();
      user.profile.id = usernameResponse.profileId;
      user.profile.firstName = usernameResponse.firstName;
      user.profile.lastName = usernameResponse.lastName;
      user.profile.middleName = usernameResponse.middleName;
      user.profile.userId = userId;
      user.profile.picture = usernameResponse.profilePicture;
    }

    return user;
  }

  /**
   * Returns the current username response if the user id is not provided.
   * If the useId is provided, returns the username response of the provided user id.
   * @param {string} userId The id of the user.
   * @returns {UsernameResponse}
   */
  private async getUsernameResponse(
    userId?: string,
  ): Promise<UsernameResponse> {
    // If the userId is not provided, return the username object of the current user.
    if (!userId) {
      // If the visit is being edited by a superior user, use the id of the original executor.
      if (this.editingBySuperUser) {
        return this._visitData?.users?.find(
          f => f.id === this.originalExecutorId$.value,
        );
      }
      // If not, use the current user id.
      else {
        const up = await this.authGuardService.getUserProfile();
        return this._visitData?.users?.find(f => f.id === up.userId);
      }
    } else {
      return this._visitData?.users?.find(f => f.id === userId);
    }
  }

  /**
   * Returns a specific user profile.
   * @param {string} userId - the id of the user.
   * @returns {UserProfile} - the user profile object if exist.
   */
  async getUserProfileByUserId(userId: string): Promise<UserProfile> {
    const usernameResponse = await this.getUsernameResponse(userId);
    const userProfile: UserProfile = new UserProfile();
    if (usernameResponse) {
      userProfile.id = usernameResponse.profileId;
      userProfile.firstName = usernameResponse.firstName;
      userProfile.lastName = usernameResponse.lastName;
      userProfile.middleName = usernameResponse.middleName;
    }

    return userProfile;
  }

  /**
   * Returns the customer related to the current project in which the visit is carried.
   * @returns {Customer} - customer object
   */
  getCurrentVisitCustomer(): Customer {
    return this._visitData?.visit?.project?.customer;
  }

  /**
   * Returns the position list of the customer related to the current project in which the visit is carried.
   * @returns {Position[]} - position list.
   */
  getPositionListOfCurrentCustomer(): Position[] {
    return this._visitData?.customerPositionList;
  }

  /**
   * Returns the products of the current visit's customer.
   * @param {(value: Product) => boolean} predicate - a predicate to apply to the list in order to narrow it down.
   * @param {boolean} onlyListed - whether to return only listed products in the product listing or not.
   */
  getCustomerProducts(
    predicate: (value: Product) => boolean = (value: Product) => true,
    onlyListed: boolean = false,
  ): Product[] {
    const productCategoriesMap = this.getProductCategoriesMap();
    let filteringResult =
      this._visitData?.products?.filter(predicate).map(p => {
        // create pictures if has any
        if (p.picture) {
          const timestamp = new Date().getTime();
          p.productPictureUrl = `${environment.productPictureBaseUrl}/${p.picture}?t=${timestamp}`;
        } else {
          p.productPictureUrl = `${environment.productPictureBaseUrl}/assets/no-image.png`;
        }

        // append full path category and customer to product
        p.category = productCategoriesMap.get(p.categoryId);
        p.customer = this._visitData.visit.project.customer;
        return p;
      }) ?? [];

    if (onlyListed) {
      if (this._visitData.productListings?.length > 0) {
        const listedProductIdList = this._visitData.productListings
          .map(m => m.listedProductIds)
          .reduce((acc, val) => acc.concat(val));
        filteringResult = filteringResult.filter(p =>
          listedProductIdList.includes(p.id),
        );
      } else {
        filteringResult = [];
      }
    }

    sortByName(filteringResult);

    return filteringResult;
  }

  /**
   * Gets array of product categories for the current visit's customer.
   * @returns array of product categories or empty array if no visit data
   */
  public getCustomerProductCategories(): ProductCategory[] {
    return this._visitData?.productCategories ?? [];
  }

  /**
   * Returns categoyrId-category map using the current customer's categories.
   * @returns {Map<string, ProductCategory>} - categoyrId-category map
   */
  private getProductCategoriesMap(): Map<string, ProductCategory> {
    if (this._visitData?.productCategories) {
      return new Map<string, ProductCategory>(
        this._visitData?.productCategories.map(c => [c.id, c]),
      );
    }

    return new Map<string, ProductCategory>();
  }

  /**
   * Returns the generic list for todo actions with type PersonalArrangement of the current visit.
   * @param {| 'PersonalArrangementDisplay'| 'PersonalArrangemenDrive'| 'PersonalArrangementPosition'} listName - generic list name
   */
  getPersonalArrangementGenericList(
    listName:
      | 'PersonalArrangementDisplay'
      | 'PersonalArrangemenDrive'
      | 'PersonalArrangementPosition',
  ): GenericList {
    switch (listName) {
      case 'PersonalArrangemenDrive':
        return this._visitData?.personalArrangementDriveList;

      case 'PersonalArrangementDisplay':
        return this._visitData?.personalArrangementDisplayList;

      case 'PersonalArrangementPosition':
        return this._visitData?.personalArrangementPositionList;
    }
  }

  getPersonalArrangements(): PersonalArrangement[] {
    return this._visitData?.personalArrangements ?? [];
  }

  setPersonalArrangements(
    personalArrangementList: PersonalArrangement[],
  ): void {
    if (this._visitData) {
      if (!this._visitData?.personalArrangements) {
        this._visitData.personalArrangements = [];
      }

      this._visitData.personalArrangements = personalArrangementList;
    }
  }

  updateRemovedPaList(removedPaId: string) {
    if (!this._removedPaIdList) {
      this._removedPaIdList = [];
    }

    const uniqueList = new Set(this._removedPaIdList);
    uniqueList.add(removedPaId);
    this._removedPaIdList = Array.from(uniqueList);
  }

  /**
   * Sets the current Todo Action of the current visit.
   *
   * When the current todo action is set, the view scrolls to it automatically when the user navigates out (to a questionnaire) then back.
   * @param {TodoListItem} todoAction - the current todo action.
   */
  setCurrentTodoAction(todoAction: TodoListItem): void {
    this._currentTodoAction = todoAction;
  }

  /**
   * Returns the current Todo Action of the current visit.
   *
   * When the current todo action is set, the view scrolls to it automatically when the user navigates out (to a questionnaire) then back.
   * @returns {TodoListItem} - the current todo action
   */
  getCurrentTodoAction(): TodoListItem {
    return this._currentTodoAction;
  }

  /**
   * Returns the current Questionnaire.
   * @returns {Questionnaire} - the current Questionnaire
   */
  getCurrentQuestionnaire(): Questionnaire {
    if (this._currentTodoAction.type === TodoListItemType.Actions) {
      return this.getCurrentPromoAction()?.questionnaire;
    } else {
      return this._currentTodoAction.questionnaire;
    }
  }

  getQuestionnaire(questionnaireId: string): Questionnaire {
    const questionnaire = this.todoActions?.find(
      ta => ta.questionnaireId === questionnaireId,
    )?.questionnaire;

    if (!questionnaire) {
      return this.getPromoActions()?.find(
        pa => pa.questionnaireId === questionnaireId,
      )?.questionnaire;
    } else {
      return questionnaire;
    }
  }
  /**
   * Sets the current promo action object if the type of the todo action is Actions.
   * This method is called when the user clicks to start the questionnaire related to a promo action.
   * @param {PromoAction} pa - promo action object.
   */
  setCurrentPromoAction(pa: PromoAction): void {
    this._currentPA = pa;
  }

  /**
   * Returns the current promo action object if exists.
   * @returns {PromoAction}
   */
  getCurrentPromoAction(): PromoAction | undefined {
    return this._currentPA;
  }

  /**
   * Returns the products associated to the current promo action.
   * Returned products are filtered so they are enabled and listed.
   * @returns {Product[]} -
   */
  getPromoActionProducts(): Product[] {
    if (this._currentPA) {
      // promo action category id list.
      const categoryIdList =
        this._currentPA?.productCategories?.map(m => m.productCategoryId) ?? [];
      // promo action product id list.
      const productIdList =
        this._currentPA?.products?.map(m => m.productId) ?? [];
      // products under selected categories.
      const categoryProducts = this.getCustomerProducts(p =>
        categoryIdList.includes(p.categoryId),
      );
      // products related to promo action.
      const products = this.getCustomerProducts(p =>
        productIdList.includes(p.id),
      );
      // unique id list of all selected products.
      const allProducts = new Set<string>([
        ...products.map(m => m.id),
        ...categoryProducts.map(m => m.id),
      ]);

      const resultProducts = this.getCustomerProducts(
        p => allProducts.has(p.id) && p.enabled,
        true,
      );
      return resultProducts;
    }
    return [];
  }

  /**
   * If exists, returns promo action list of the current todo action.
   * @returns {PromoAction[]}
   */
  getPromoActions(): PromoActionWithRelationMandatory[] {
    return sortByName(this._visitData?.promoActions ?? []);
  }

  getFuturePromoActions(): PromoAction[] {
    return sortByName(this._visitData?.futurePromoActions ?? []);
  }

  /**
   * Set the id of the current questionnaire result.
   * @param {string} qrId - questionnaire result id.
   */
  setCurrentQR(qrId: string): void {
    this._qrId = qrId;
  }

  /**
   * Returns the current question of the current questionnaire.
   * This function is used for questions with type matrix and when the user starts camera/gallery (in this case the question is saved because the system might kills the app process so we can return to the same question).
   * @returns {QuestionnaireItem} - the current question
   */
  getCurrentQuestion(): QuestionnaireItem {
    return this._currentQuestion;
  }

  /**
   * Sets the current question of the current questionnaire.
   * This function is used for questions with type matrix and when the user starts camera/gallery (in this case the question is saved because the system might kills the app process so we can return to the same question).
   * @param {QuestionnaireItem} currentQust - the current question
   */
  setCurrentQuestion(currentQust: QuestionnaireItem): void {
    this._currentQuestion = currentQust;
  }

  /**
   * Returns the current personal arrangement id.
   * @returns {string} - The id
   */
  getCurrentPersonalArrangementId(): string {
    return this._currentPersonalArrangementId;
  }

  /**
   * Sets the current personal arrangement id.
   * @param {string} paId The id
   */
  setCurrentPersonalArrangementId(paId: string) {
    this._currentPersonalArrangementId = paId;
  }

  /**
   * Updates the current visit object.
   * @param {Partial<TodoListResult>} todoListResult - the updated object.
   * @returns {TodoListResult} - the updated todo list result.
   */
  updateVisit(todoListResult: Partial<TodoListResult>): TodoListResult {
    Object.assign(this._visitData.todoListResult, todoListResult);

    return this._visitData?.todoListResult;
  }

  /**
   * Returns a photo list of the current visit according to a predicate function.
   * @param {Partial<TodoListResult>} todoListResult - a predicate function to narrow down the photos before return.
   */
  getPhotoList(
    predicate: (value: Photo) => boolean = (value: Photo) => true,
  ): Photo[] {
    const filterResult = cloneDeep(this._visitData?.photos?.filter(predicate));
    filterResult?.forEach(
      (photo: ExtendedPhoto) => (photo.visitId = this._visitData?.visit?.id),
    );
    return filterResult ?? [];
  }

  /**
   * Creates or updates the visit todo actions results of the current visit.
   * @param {Partial<TodoListResult>} todoActionResults - a list of new or updated todo action results
   */
  createOrUpdateTodoActionResults(
    todoActionResults: Partial<TodoActionResult>[],
  ): void {
    if (!this._visitData.todoListResult) {
      this._visitData.todoListResult = new TodoListResult();
    }

    for (const todoActionResult of todoActionResults) {
      let existingTodoActionResult =
        this._visitData.todoListResult?.todoActionResults?.find(
          f => f.todoListItemId === todoActionResult.todoListItemId,
        );

      if (!existingTodoActionResult) {
        if (!this._visitData?.todoListResult?.todoActionResults) {
          this._visitData.todoListResult.todoActionResults = [];
        }
        this._visitData.todoListResult.todoActionResults.push(
          todoActionResult as TodoActionResult,
        );
      } else {
        delete todoActionResult.openedAt;
        delete todoActionResult.id;
        Object.assign(existingTodoActionResult, todoActionResult);
      }
    }
  }

  /**
   * Removes a todo action result from the current visit.
   * @param {string} tarId The id of the todo action result to remove.
   */
  removeTodoActionResult(tarId: string) {
    if (Array.isArray(this._visitData.todoListResult?.todoActionResults)) {
      this._visitData.todoListResult.todoActionResults =
        this._visitData.todoListResult?.todoActionResults.filter(
          tar => tar.id !== tarId,
        );
    }
  }

  /**
   * Creates new todo list result if not exists.
   * @param {TodoListResult} - todoListResult - a todo list result.
   * @returns {TodoListResult} - todo list result
   */
  createTodoListResultIfNotExists(
    todoListResult: TodoListResult,
  ): TodoListResult {
    if (!this._visitData.todoListResult) {
      this._visitData.todoListResult = todoListResult;
    }
    return this._visitData.todoListResult;
  }

  /**
   * Returns the current todo action result list.
   * @returns {TodoActionResult[]} - todo action result list.
   */
  getTodoActionResultList(): TodoActionResult[] {
    return this._visitData.todoListResult?.todoActionResults ?? [];
  }

  /**
   * Creates a questionnaire result if not exists.
   * @param {QuestionnaireResult} questionnaireResult - a questionnaire result.
   * @returns {QuestionnaireResult} - questionnaire result.
   */
  createQuestionnaireResultIfNotExists(
    questionnaireResult: QuestionnaireResult,
  ): QuestionnaireResult {
    const todoActionResult =
      this._visitData.todoListResult.todoActionResults.find(
        f => f.todoListItemId === this._currentTodoAction.id,
      );

    if (!todoActionResult.questionnaireResults) {
      todoActionResult.questionnaireResults = [];
      todoActionResult.questionnaireResults.push(questionnaireResult);
    } else if (
      this._currentTodoAction.allowMultipleQuestionnaires ||
      this._currentTodoAction.type === TodoListItemType.Actions
    ) {
      if (
        !todoActionResult.questionnaireResults.some(
          s => s.id === questionnaireResult.id,
        )
      ) {
        todoActionResult.questionnaireResults.push(questionnaireResult);
      }
    }
    return questionnaireResult;
  }

  /**
   * Returns the questionnaire result of the current todo action.
   * @returns {QuestionnaireResult} - current questionnaire result.
   */
  getCurrentQuestionnaireResult(): QuestionnaireResult | undefined {
    const todoActionResult: TodoActionResult =
      this._visitData.todoListResult?.todoActionResults?.find(
        f => f.todoListItemId === this._currentTodoAction.id,
      );
    if (todoActionResult) {
      if (this._currentTodoAction.type === TodoListItemType.Actions) {
        return todoActionResult.questionnaireResults.find(
          f => f.promotionalActionId === this._currentPA.id,
        );
      } else if (this._currentTodoAction.allowMultipleQuestionnaires) {
        return todoActionResult.questionnaireResults.find(
          f => f.id === this._qrId,
        );
      } else {
        return todoActionResult.questionnaireResults[0];
      }
    }

    return;
  }

  /**
   * Returns the questionnaire result list of a provided todo action id.
   * If todoActionId is not provided, returns the questionnaire result list of the current todo action.
   * @param {string} todoActionId - the id of the todo action result.
   * @returns {QuestionnaireResult} - questionnaire result.
   */
  getQuestionnaireResults(
    todoActionId: string = undefined,
  ): QuestionnaireResult[] | undefined {
    let questionnaireResultList: QuestionnaireResult[] = [];

    if (!todoActionId && this._currentTodoAction) {
      questionnaireResultList =
        this._visitData.todoListResult?.todoActionResults?.find(
          f => f.todoListItemId === this._currentTodoAction.id,
        )?.questionnaireResults;
    } else {
      questionnaireResultList =
        this._visitData.todoListResult?.todoActionResults?.find(
          f => f.todoListItemId === todoActionId,
        )?.questionnaireResults;
    }
    if (questionnaireResultList && questionnaireResultList.length > 0) {
      return questionnaireResultList;
    }
    return undefined;
  }

  /**
   * Returns questionnaire result of a todo action.
   * @param {string} qrId - questionnaire result id.
   * @param {string} todoListItemId - todo list item id.
   * @returns {QuestionnaireResult | undefined} - questionnaire result if exist, otherwise underfined.
   */
  getQuestionnaireResultById(
    qrId: string,
    todoListItemId: string,
  ): QuestionnaireResult | undefined {
    return this._visitData.todoListResult?.todoActionResults
      ?.find(f => f.todoListItemId === todoListItemId)
      ?.questionnaireResults?.find(_qr => _qr.id === qrId);
  }

  /**
   * Returns the result of a specific todo action.
   * @param {string} todoListItemId - the id of todo action.
   * @returns {TodoActionResult} - todo action result.
   */
  getTodoActionResult(todoListItemId: string): TodoActionResult | undefined {
    return this._visitData.todoListResult?.todoActionResults?.find(
      f => f.todoListItemId === todoListItemId,
    );
  }

  /**
   * Returns the result of the current todo action.
   * @returns
   */
  getCurrentTodoActionResult(): TodoActionResult | undefined {
    return this._visitData.todoListResult?.todoActionResults?.find(
      f => f.todoListItemId === this._currentTodoAction.id,
    );
  }

  /**
   * Creates or updates questionnaire answers.
   * @param {Partial<QuestionnaireAnswer>[]} questionnaireAnswserList - a questionnaire answer list to create or update.
   */
  createOrUpdateQuestionnaireAnswers(
    questionnaireAnswserList: Partial<QuestionnaireAnswer>[],
    uploadedPhotos: Photo[] = [],
  ): void {
    let questionnaireResult = this.getCurrentQuestionnaireResult();
    if (!questionnaireResult?.questionnaireAnswers) {
      questionnaireResult.questionnaireAnswers = [];
    }
    for (const questionnaireAnswer of questionnaireAnswserList) {
      let existingQuestionnaireAnswer: QuestionnaireAnswer;
      if (
        this._currentTodoAction.type ===
          TodoListItemType.QuestionnaireProductMatrix ||
        this._currentQuestion?.question?.answerType === AnswerType.Matrix
      ) {
        existingQuestionnaireAnswer =
          questionnaireResult?.questionnaireAnswers?.find(
            f =>
              f.questionnaireItemId ===
                questionnaireAnswer.questionnaireItemId &&
              f.productId === questionnaireAnswer.productId,
          );
      } else if (
        this._currentTodoAction.type ===
        TodoListItemType.QuestionnaireFreeTextMatrix
      ) {
        existingQuestionnaireAnswer =
          questionnaireResult?.questionnaireAnswers?.find(
            f =>
              f.questionnaireItemId ===
                questionnaireAnswer.questionnaireItemId &&
              f.freeTextId === questionnaireAnswer.freeTextId,
          );
      } else {
        existingQuestionnaireAnswer =
          questionnaireResult?.questionnaireAnswers?.find(
            f =>
              f.questionnaireItemId === questionnaireAnswer.questionnaireItemId,
          );
      }

      if (!existingQuestionnaireAnswer) {
        if (!questionnaireAnswer.openedAt) {
          questionnaireAnswer.openedAt = new Date();
        }
        questionnaireResult?.questionnaireAnswers?.push(
          questionnaireAnswer as QuestionnaireAnswer,
        );
      } else {
        delete questionnaireAnswer.openedAt;
        delete questionnaireAnswer.id;
        Object.assign(existingQuestionnaireAnswer, questionnaireAnswer);
      }
    }
    if (uploadedPhotos.length > 0) {
      this.addNewPhotos(uploadedPhotos);
    }
  }

  /**
   * Removes the questionnaire answer of a specific questionnaire item.
   * @param {QuestionnaireItem} questionnaireItem the questionnaire item to remove the answer of.
   */
  removeQuestionnaireAnswer(questionnaireItem: QuestionnaireItem) {
    let questionnaireResult = this.getCurrentQuestionnaireResult();
    let questionnaireAnswers = questionnaireResult?.questionnaireAnswers ?? [];
    if (questionnaireAnswers.length == 0) {
      return;
    }

    questionnaireResult.questionnaireAnswers = questionnaireAnswers.filter(
      qa => qa.questionnaireItemId !== questionnaireItem.id,
    );
    this.updateQuestionnaireResult(questionnaireResult);
  }

  /**
   * Updates questionnaire result of the current todo action.
   * @param {Partial<QuestionnaireResult>} updatedQuestionnaireResult - updated questionnaire result object
   * @returns {QuestionnaireResult} - current questionnaire result.
   */
  updateQuestionnaireResult(
    updatedQuestionnaireResult: Partial<QuestionnaireResult>,
  ): QuestionnaireResult {
    let questionnaireResult = this.getCurrentQuestionnaireResult();
    Object.assign(questionnaireResult, updatedQuestionnaireResult);
    return questionnaireResult;
  }

  /**
   * Returns the questionnaire result of the previous visit.
   * @returns {QuestionnaireResult} - the questionnaire result of the previous visit.
   */
  getPreviousQR(): QuestionnaireResult {
    let previousQRs: QuestionnaireResult[] = [];

    // If the type is Actions, return the questionnaire result of the current promo action (because it is possible to have multiple promo actions in one todo action)
    if (this._currentTodoAction?.type === TodoListItemType.Actions) {
      previousQRs =
        this._visitData.promoActionRecentQResults?.filter(
          qr => qr.promotionalActionId === this._currentPA?.id,
        ) ?? [];
    } else {
      previousQRs =
        this._visitData.previousVisit?.todoActionResults?.find(
          f => f.todoListItemId === this._currentTodoAction.id,
        )?.questionnaireResults ?? [];
    }

    const sortedQRs = query(previousQRs).sortBy('startedAt', true).toArray();
    return sortedQRs?.[0];
  }

  getPreviousPromoActionResults(): QuestionnaireResult[] {
    return  this._visitData.promoActionRecentQResults;
  }

  getPreviousTodoListResult() {
    return this._visitData?.previousVisit;
  }

  /**
   * Returns claims of user.
   * @param {string} userId - user id to get claims for.
   * @returns {ExecuterClaims} - user claims
   */
  getUserClaims(): ExecuterClaims {
    const claims: ExecuterClaims = {};

    claims.claimBreakStart =
      getTimeAsDecimal(
        moment(this._currentExecuter.claimBreakStart).toDate(),
      ) ?? 0;
    if (this._currentExecuter.claimBreakFinish) {
      claims.claimBreakFinish =
        getTimeAsDecimal(
          moment(this._currentExecuter.claimBreakFinish).toDate(),
        ) ?? 23.75;
    }
    claims.claimBreakDuration = this._currentExecuter.claimBreakDuration ?? 0;

    claims.claimWorkStart =
      getTimeAsDecimal(moment(this._currentExecuter.claimWorkStart).toDate()) ??
      0;
    claims.claimWorkFinish =
      getTimeAsDecimal(
        moment(this._currentExecuter.claimWorkFinish).toDate(),
      ) ?? 23.75;
    claims.claimWorkDuration = this._currentExecuter.claimWorkDuration ?? 0;

    claims.claimExpensesAmount = this._currentExecuter.claimExpensesAmount;
    claims.claimExpensesNote = this._currentExecuter.claimExpensesNote;
    claims.claimTravelDistance = this._currentExecuter.claimTravelDistance ?? 0;

    return claims;
  }

  /**
   * Saves claims to the visit data.
   * @param {ExecuterClaims} claims - claims object to save.
   * @param {string} userId - id of the user claims are associated to.
   */
  saveUserClaims(claims: ExecuterClaims): void {
    if (claims) {
      if (
        claims.claimBreakStart !== undefined &&
        claims.claimBreakFinish !== undefined
      ) {
        const claimBreakStart = moment()
          .set({
            hours: parseInt(`${claims.claimBreakStart}`),
            minutes: getDecimalPart(claims.claimBreakStart) * 60,
          })
          .format();

        const claimBreakFinish = moment()
          .set({
            hours: parseInt(`${claims.claimBreakFinish}`),
            minutes: getDecimalPart(claims.claimBreakFinish) * 60,
          })
          .format();

        this._currentExecuter.claimBreakFinish = claimBreakFinish;
        this._currentExecuter.claimBreakStart = claimBreakStart;
      }

      if (
        claims.claimWorkFinish !== undefined &&
        claims.claimWorkStart !== undefined
      ) {
        const claimWorkFinish = moment()
          .set({
            hours: parseInt(`${claims.claimWorkFinish}`),
            minutes: getDecimalPart(claims.claimWorkFinish) * 60,
          })
          .format();

        const claimWorkStart = moment()
          .set({
            hours: parseInt(`${claims.claimWorkStart}`),
            minutes: getDecimalPart(claims.claimWorkStart) * 60,
          })
          .format();

        this._currentExecuter.claimWorkFinish = claimWorkFinish;
        this._currentExecuter.claimWorkStart = claimWorkStart;
      }

      this._currentExecuter.claimBreakDuration = claims.claimBreakDuration;

      this._currentExecuter.claimWorkDuration = claims.claimWorkDuration;
      this._currentExecuter.claimExpensesAmount = claims.claimExpensesAmount;
      this._currentExecuter.claimExpensesNote = claims.claimExpensesNote;
      this._currentExecuter.claimTravelDistance = claims.claimTravelDistance;
    }
  }

  getCurrenctExecuter() {
    return this._currentExecuter;
  }

  getStoreObjectType(): ObjectTypePropertyValues {
    return this._storeObjectType;
  }

  getProductProperty(productId: string): {name: string; values: string[]} {
    return this._productPropertyValues[productId];
  }

  getAllProductProperties(): {[key: string]: {name: string; values: string[]}} {
    return this._productPropertyValues;
  }

  /**
   * updates object propery value list.
   * ignore the updates if they are same with the original values (when the visit has started)
   * @param {SetExtendedPropertiesRequest} objPropUpdatePayload
   */
  setStorePropertiesValue(objPropUpdatePayload: SetExtendedPropertiesRequest) {
    const newValues = objPropUpdatePayload.propertyValueList.map(pv => {
      const {propertyId, value} = pv;
      return {propertyId, value};
    });
    const originalValues = this._storeObjectType?.propertyValues?.map(pv => {
      const {propertyId, value} = pv;
      return {propertyId, value};
    });
    // if no property has changed, don't send the values along with request
    if (!deepEqual(newValues, originalValues)) {
      this._storePropertiesValues = objPropUpdatePayload;
    } else {
      this._storePropertiesValues = undefined;
    }
  }

  triggerLoadingIndicator(loading: boolean) {
    this.isLoading$.next(loading);
  }

  /**
   * Saves the photo taken on the mobile phone to save it later.
   * @param {MobilePhoto} photoDetails - details of the photo taken.
   */
  addBase64Photos(photoDetails: MobilePhoto[]): void {
    if (!this._mobilePhotoList) {
      this._mobilePhotoList = [];
    }

    this._mobilePhotoList.push(...photoDetails);
  }

  /**
   * Removes the photos taken on mobile device from the list.
   * @param {string[]} photoIdList - list of id of the photos.
   */
  async removeBase64Photos(photoIdList: string[]): Promise<void> {
    if (!this._mobilePhotoList) {
      this._mobilePhotoList = [];
    }

    this._mobilePhotoList = this._mobilePhotoList.filter(
      mp => !photoIdList.includes(mp.photo.id),
    );

    await this.removePhotosFromStorage(photoIdList);
  }

  /**
   * Cancels the current visit.
   * All associated results and photos will be removed.
   */
  async cancelVisit() {
    this.triggerLoadingIndicator(true);
    await this.dataProvider.visit.cancelVisit(this.visitId);
    this.resetVisitVariables();
  }

  async approveVisit(approved: boolean) {
    return this.dataProvider.tourPlan.approveTourPlans([
      {tourPlanId: this.visitId, approved},
    ]);
  }

  /**
   * Sets visit variables to undefined.
   */
  async resetVisitVariables() {
    this._currentTodoAction = undefined;
    this._currentExecuter = undefined;
    this._visitData = undefined;
    this._currentPA = undefined;
    this._currentQuestion = undefined;
    this._storePropertiesValues = undefined;
    this._removedPaIdList = undefined;
    this._appPreviousState = undefined;
    this._appState = undefined;
    this._currentPersonalArrangementId = undefined;
    this.myKPISets = undefined;
    this.projectUsersKPISets = undefined;
    this.feedback = undefined;
    this.visitFeedbackList = undefined;
    this.userProjectPermissions = undefined;
    this.feedbackComments = undefined;
    this.pendingUploads = undefined;
    this.modifiedFeedbackIds = [];
    this._tempData = new Map();
    this.originalExecutorId$.next(undefined);
    this.finalizedVisitChanged$.next(false);
    this.readonlyMode$.next(false);
    this.requestProgressService.clearProgresses();
    this.requestProgressService.cleanMessages();

    await this.cleanVisitResultSnapshot();
  }

  async cleanPhotoStorage(ids: string[] = []) {
    if (
      this.appInfoService.isMobileVersion &&
      this._mobilePhotoList?.length > 0
    ) {
      if (ids?.length > 0) {
        await Promise.all(
          ids.map(id => this.appInterfaceService.photoStorageDelete(id)),
        );
        this._mobilePhotoList = this._mobilePhotoList.filter(
          mp => !ids.includes(mp.photo.id),
        );
      } else {
        await this.appInterfaceService.photoStorageDelete(
          this._mobilePhotoList.map(m => m.photo.id),
        );
        this._mobilePhotoList = [];
      }
    }
  }

  /**
   * Updates the state then save the visit data.
   * If the state is not provided, only save the visit.
   * @param state
   */
  async updateStateSaveVisit(state?: TourPlanState) {
    this.isUploading$.next(true);
    // If the current user is a super user, set the related properties to indicate that.
    const currentState = this._visitData?.visit.state;
    if (this.editingBySuperUser) {
      // the current user is the super user.
      const {userId} = await this.authGuardService.getUserProfile();
      const todoListResult = this.getCurrentTodoListResult();
      todoListResult.editedAt = new Date();
      todoListResult.editedBy = userId;
      todoListResult.editedTourPlanState = currentState;
    }

    if (state != null && currentState !== TourPlanState.Completed) {
      await this.updateVisitState(state);
    }

    await this.saveVisitData();
  }

  /**
   * Sends "who am I" request to back-end to check session status and connectivity.
   * @returns {Promise<WhoAmIResult>} "who am I" result
   */
  async whoAmI(): Promise<WhoAmIResult> {
    let result: WhoAmIResult;
    let response: WhoAmIResponse;
    try {
      response = await this.dataProvider.user.whoAmI();
      result = WhoAmIResult.OK;
    } catch (e) {
      // if unauthorized: required login refresh client if user confirmed
      if (e.originalError?.status === 401) {
        const proceed = await this.confirmationService.confirm(
          ConfirmType.CONFIRM,
          this.translate.instant('views.visit.login-required-title'),
          this.translate.instant('views.visit.login-required-message'),
        );
        result = proceed
          ? WhoAmIResult.HTTP_401_RELOAD
          : WhoAmIResult.HTTP_401_NO_ACTION;
      }
      // if server error: wait & try again later
      else if (
        e.originalError?.status >= 500 &&
        e.originalError?.status <= 599
      ) {
        await this.confirmationService.confirm(
          ConfirmType.DEFAULT,
          this.translate.instant('views.visit.http-5xx-title'),
          this.translate.instant('views.visit.http-5xx-message'),
        );
        result = WhoAmIResult.HTTP_5xx;
      }
      // if timeout error: probably no internet connection
      else if (e.originalError?.name === 'TimeoutError') {
        await this.confirmationService.confirm(
          ConfirmType.DEFAULT,
          this.translate.instant('views.visit.request-timeout-title'),
          this.translate.instant('views.visit.request-timeout-message'),
        );
        result = WhoAmIResult.HTTP_TIMEOUT;
      }
      // if unknown error: no internet connection
      else if (e.originalError?.status === 0) {
        await this.confirmationService.confirm(
          ConfirmType.DEFAULT,
          this.translate.instant('views.visit.no-connection-title'),
          this.translate.instant('views.visit.no-connection-message'),
        );
        result = WhoAmIResult.OFFLINE;
      } else {
        await this.confirmationService.confirm(
          ConfirmType.DEFAULT,
          this.translate.instant('views.visit.http-other-title'),
          this.translate.instant('views.visit.http-other-message'),
        );
        result = WhoAmIResult.HTTP_OTHER;
      }
    } finally {
      if (result === WhoAmIResult.OK) {
        // if no response: no internet connection
        if (response === undefined) {
          await this.confirmationService.confirm(
            ConfirmType.DEFAULT,
            this.translate.instant('views.visit.no-connection-title'),
            this.translate.instant('views.visit.no-connection-message'),
          );
          result = WhoAmIResult.OFFLINE;
        }
        // if anonymous: required login refresh client if user confirmed
        else if (response.anonymous) {
          const proceed = await this.confirmationService.confirm(
            ConfirmType.CONFIRM,
            this.translate.instant('views.visit.login-required-title'),
            this.translate.instant('views.visit.login-required-message'),
          );
          result = proceed
            ? WhoAmIResult.HTTP_401_RELOAD
            : WhoAmIResult.HTTP_401_NO_ACTION;
        }
      }
    }

    return result;
  }

  /**
   * Checks the internet connectivity and return true when the user confirms and it is possible to proceed.
   * Otherwise, returns false.
   * @returns {boolean} - whether to proceed or not
   */
  async checkConnection(): Promise<boolean> {
    // check connectivity only on mobile phones
    if (!this.appInfoService.isMobileVersion) {
      return true;
    }

    // do not check connectivity when switched into off-line mode
    if (this.clientModeService.clientMode === ClientMode.OFFLINE) {
      return true;
    }

    // do not check connectivity if not configured so
    const check = this.resolveSetting(
      SettingNames.TourPlan_Visit_CheckConnectivity,
      this.projectId,
    );
    if (!check) {
      return true;
    }

    const connectionSpeed = this.clientModeService.connectionSpeed;
    let proceed = true;
    if (connectionSpeed === ConnectionSpeed.NONE) {
      await this.confirmationService.confirm(
        ConfirmType.DEFAULT,
        this.translate.instant('views.visit.no-connection'),
        this.translate.instant('views.visit.no-connection-confirm'),
      );
      return false;
    } else if (connectionSpeed === ConnectionSpeed.SLOW) {
      proceed = await this.confirmationService.confirm(
        ConfirmType.CONFIRM,
        this.translate.instant('views.visit.slow-connection'),
        this.translate.instant('views.visit.slow-connection-confirm'),
      );
      return proceed;
    } else if (connectionSpeed === ConnectionSpeed.OK) {
      return true;
    }
  }

  getStorePropertiesValues(): SetExtendedPropertiesRequest {
    return this._storePropertiesValues;
  }

  async setCurrentUserAndExecutor() {
    this._currentUserDetails = await this.getUsernameResponse();
    this._currentExecuter = this._visitData.visit.executors.find(
      f => f.userId === this._currentUserDetails.id,
    );
  }

  /**
   * Returns the id of the current user.
   * @returns {string} - id of the user.
   */
  getUserId(): string {
    return this._currentUserDetails.id;
  }

  startDataSnapshot() {
    if (
      this.appInfoService.isMobileVersion &&
      this._visitData?.visit.state !== TourPlanState.Finalized
    ) {
      if (!this.snapshotInProgress) {
        console.log('snapshot capturing started.');
        this.snapshotInProgress = setInterval(() => {
          this.takeSnapshot();
        }, environment.snapshotDelay ?? 60_000);
      }
    }
  }

  takeSnapshot() {
    if (!this.appInfoService.isMobileVersion) {
      console.warn('snapshot is not supported on web version.');
      return;
    }
    const todoListResult = this.getCurrentTodoListResult() ?? undefined;
    const visitSnapshot: Snapshot = {
      todoListResult: todoListResult,
      mobilePhotoList: this._mobilePhotoList ?? [],
      visitId: this.visitId,
      photoList: this.getPhotoList(),
      executer: this.getCurrenctExecuter(),
      storePropertiesValues: this.getStorePropertiesValues(),
      lastState: this._appState,
      todoList: this._visitData?.visit.project.todoList,
    };

    let executroObj = undefined;
    if (this.editingBySuperUser) {
      executroObj = {
        [VISIT_EXECUTOR]: JSON.stringify({
          id: this._currentUserDetails.id,
          username: formatUser(this._currentUserDetails),
        }),
      };
    }

    this.appInterfaceService.storageSave({
      [VISIT_SNAPSHOT_KEY]: JSON.stringify(visitSnapshot),
      [OPENED_VISIT_KEY]: this.visitId,
      ...executroObj,
    });

    console.log('A snapshot has been taken.');
  }

  saveAppState(state?: AppLastState) {
    if (this.appInfoService.isMobileVersion) {
      this._appState = state;
      this.takeSnapshot();
    }
  }

  async loadSnapshot() {
    if (this.appInfoService.isMobileVersion) {
      // If the visit state is Finalized, always clear the snapshot because it is no more possible to do any changes to the visit.
      if (this._visitData?.visit?.state === TourPlanState.Finalized) {
        await this.cleanVisitResultSnapshot();
        return;
      }

      const visitResultSnapshotJson = (
        await this.appInterfaceService.storageLoad(VISIT_SNAPSHOT_KEY)
      )?.[VISIT_SNAPSHOT_KEY];

      const visitResultSnapshot: Snapshot = safeParseJSON(
        visitResultSnapshotJson,
      );

      if (visitResultSnapshot) {
        const lastState = visitResultSnapshot.lastState;
        this._appPreviousState = visitResultSnapshot.lastState;

        const todoListResult: TodoListResult =
          visitResultSnapshot.todoListResult;

        this._visitData.todoListResult = todoListResult;
        this._mobilePhotoList = visitResultSnapshot.mobilePhotoList ?? [];
        this._visitData.photos = visitResultSnapshot.photoList ?? [];

        // set store properties values
        const storePropertiesValues: SetExtendedPropertiesRequest =
          visitResultSnapshot.storePropertiesValues;
        this._storePropertiesValues = storePropertiesValues;
        if (storePropertiesValues) {
          this._visitData.storeObjectType?.propertyValues?.forEach(pv => {
            const propertyValue = storePropertiesValues.propertyValueList?.find(
              p => p.propertyId === pv.propertyId,
            )?.value;

            pv.value = propertyValue;
          });
        }

        // set claims
        const executer = visitResultSnapshot.executer;
        if (executer) {
          const currenctExecuter = this._visitData?.visit?.executors.find(
            e => e.id === executer.id,
          );
          Object.assign(currenctExecuter, executer);
        }

        // set personal arrangements
        this._visitData.personalArrangements = todoListResult?.todoActionResults
          ?.filter(f => f.personalArrangements?.length > 0)
          .map(tar => tar.personalArrangements)
          .reduce((p, c) => p.concat(c), []);

        // the lastState is the last state saved before the camera/gallery/barcode/uploading/app crash.
        if (lastState) {
          if (lastState.pendingUploadsStatus) {
            this.pendingUploads = {
              status: lastState.pendingUploadsStatus,
              uploadedPhotos: lastState.uploadedPhotos,
              visitUpdatedState: lastState.visitUpdatedState,
            };
          }
          const todoActions = visitResultSnapshot.todoList.todoActions;
          const promoActions = this._visitData.promoActions;
          const todoAction = todoActions.find(
            f => f.id === lastState.todoListItemId,
          );
          this.setCurrentTodoAction(todoAction);

          if (lastState.questionnaireId) {
            let questionnaireItem: QuestionnaireItem;
            if (lastState.promoActionId) {
              const promoAction = promoActions.find(
                pa => pa.id === lastState.promoActionId,
              );
              questionnaireItem = promoAction.questionnaire.questions.find(
                qi => qi.id === lastState.questionnaireItemId,
              );
              this.setCurrentPromoAction(promoAction);
            } else {
              questionnaireItem = todoAction.questionnaire.questions.find(
                qi => qi.id === lastState.questionnaireItemId,
              );
            }

            this.setCurrentQR(lastState.questionnaireResultId);
            this.setCurrentQuestion(questionnaireItem);
            await this.navigateToUrl(
              `/data-collection/questionnaire-answering`,
              {
                queryParams: {
                  visitId: visitResultSnapshot.visitId,
                },
              },
            );
          } else if (lastState.personalArrangementId) {
            this.setCurrentPersonalArrangementId(undefined);
            this._currentPersonalArrangementId =
              lastState.personalArrangementId;
          }

          const todoActionResult =
            visitResultSnapshot?.todoListResult?.todoActionResults?.find(
              tar => tar.todoListItemId === lastState.todoListItemId,
            );

          const questionnaireAnswer = todoActionResult?.questionnaireResults
            ?.find(qr => qr.id === lastState.questionnaireResultId)
            ?.questionnaireAnswers?.find(
              qa => qa.questionnaireItemId === lastState.questionnaireItemId,
            );

          switch (lastState.leaveReason) {
            case 'capture':
              {
                const photo =
                  await this.appInterfaceService.photoCapturePending(
                    lastState.appInterfacePayload as ResamplingOptions,
                  );
                if (photo && !photo.cancelled) {
                  const mobilePhoto = prepareMobilePhoto(
                    photo,
                    lastState.photoObjectId,
                    lastState.userId,
                    PhotoSource.MobileCamera,
                  );

                  mobilePhoto.photo = {
                    ...mobilePhoto.photo,
                    tourPlanId: visitResultSnapshot.visitId,
                    projectId: this.projectId,
                    storeId: this.storeId,
                    todoListResultId: visitResultSnapshot.todoListResult.id,
                    questionnaireResultId: lastState.questionnaireResultId,
                    todoActionResultId: todoActionResult?.id,
                    questionnaireItemId: lastState.questionnaireItemId,
                    questionnaireAnswerId: questionnaireAnswer?.id,
                    promoActionId: lastState.promoActionId,
                    personalArrangementId: lastState.personalArrangementId,
                  };

                  visitResultSnapshot.mobilePhotoList =
                    visitResultSnapshot.mobilePhotoList ?? [];
                  visitResultSnapshot.photoList =
                    visitResultSnapshot.photoList ?? [];

                  visitResultSnapshot.mobilePhotoList.push(mobilePhoto);
                  visitResultSnapshot.photoList.push(mobilePhoto.photo);
                }
              }
              break;

            case 'gallery':
              {
                const selectedPhotos =
                  await this.appInterfaceService.photoLibraryPending(
                    lastState.appInterfacePayload as PhotoLibraryRequest,
                  );

                visitResultSnapshot.mobilePhotoList =
                  visitResultSnapshot.mobilePhotoList ?? [];
                visitResultSnapshot.photoList =
                  visitResultSnapshot.photoList ?? [];

                if (
                  Array.isArray(selectedPhotos) &&
                  selectedPhotos[0].cancelled !== true
                ) {
                  selectedPhotos.forEach(ph => {
                    const mobilePhoto = prepareMobilePhoto(
                      ph,
                      lastState.photoObjectId,
                      lastState.userId,
                      PhotoSource.MobilePhotoLibrary,
                    );
                    mobilePhoto.photo = {
                      ...mobilePhoto.photo,
                      tourPlanId: this.visitId,
                      projectId: this.projectId,
                      storeId: this.storeId,
                      todoListResultId: visitResultSnapshot.todoListResult.id,
                      questionnaireResultId: lastState.questionnaireResultId,
                      todoActionResultId: todoActionResult?.id,
                      questionnaireItemId: lastState.questionnaireItemId,
                      questionnaireAnswerId: questionnaireAnswer?.id,
                      promoActionId: lastState.promoActionId,
                      personalArrangementId: lastState.personalArrangementId,
                    };
                    visitResultSnapshot.mobilePhotoList.push(mobilePhoto);
                    visitResultSnapshot.photoList.push(mobilePhoto.photo);
                  });
                }
              }
              break;
          }
        }
      }
    }
  }

  getPendingUploadsStatus() {
    return this.pendingUploads;
  }

  private async cleanVisitResultSnapshot() {
    if (this.appInfoService.isMobileVersion) {
      await this.appInterfaceService.storageDelete([
        VISIT_SNAPSHOT_KEY,
        OPENED_VISIT_KEY,
      ]);

      if (this.snapshotInProgress) {
        clearInterval(this.snapshotInProgress as NodeJS.Timeout);
        this.snapshotInProgress = false;
        console.log('visit snapshot has been cleared.');
      }
    }
  }

  /**
   * Sends a request to open a visit.
   * @param {string} tourPlanId the id of the visit to open.
   * @returns
   */
  openVisit(tourPlanId: string): Promise<OpenVisitResponse> {
    return this.dataProvider.visit.openVisit({
      tourPlanId: tourPlanId,
      onBehalfOf: this.editingBySuperUser
        ? this.originalExecutorId$.value
        : undefined,
    });
  }

  /**
   * Navigates out when an error happens.
   * @param fallbackUrl the url to use when the callback url is no available.
   * @param {NavigationExtras} extras navigation extra object.
   */
  navigateOut(fallbackUrl?: string, extras?: NavigationExtras) {
    this.router
      .navigate([this.visitLeaveCbUrl.value ?? fallbackUrl ?? '/'], extras)
      .then(_ => {
        this.isUploading$.next(false);
        this.triggerLoadingIndicator(false);
      });
  }

  /**
   * Navigates to a different view.
   * @param url The url of the view to navigate to.
   * @param {NavigationExtras} extras Navigation extra object.
   */
  navigateToUrl(url: string, extras?: NavigationExtras) {
    return this.router.navigate([url], extras);
  }

  /**
   * Returns all tags from visit data response
   * */
  getAllTags(): Tag[] {
    if (!this.allTags) {
      this.allTags = this._visitData.tags ?? [];
    }

    return this.allTags;
  }

  /**
   * Returns project tags from visit data response
   * */
  getProjectTags(): IProjectTag[] {
    this.projectTags = this._visitData.visit.project.tags ?? [];
    return this.projectTags;
  }

  /**
   *
   * Returns the state of the app before it was crashed. The reset the state.
   */
  getLastState() {
    const tmp = cloneDeep(this._appPreviousState);
    this._appPreviousState = undefined;
    return tmp;
  }

  getUserProjectPermissions() {
    return this.userProjectPermissions;
  }

  getVisitComments() {
    return this._visitCommentsModifier.toList();
  }

  async modifyVisitComments(
    action: 'DELETE' | 'ADD',
    payload: Comment | string[],
  ) {
    switch (action) {
      case 'ADD':
        if (payload instanceof Comment) {
          this._visitCommentsModifier.add(payload);
          await this.dataProvider.comment.create(
            this._visitCommentsModifier.getPureComment(payload),
          );
        }
        break;

      case 'DELETE':
        {
          if (Array.isArray(payload)) {
            this._visitCommentsModifier.delete(payload as string[]);
            await this.dataProvider.comment.deleteCommentList(payload);
          }
        }
        break;

      default:
        break;
    }
  }

  getMyFeedback() {
    return this.feedback;
  }

  getMyKPISets() {
    return this.myKPISets;
  }

  getProjectUsersKPISets() {
    return this.projectUsersKPISets;
  }

  getVisitFeedbacks() {
    return this.visitFeedbackList;
  }

  async modifyFeedback(
    action: 'DELETE' | 'ADD-UPDATE' | 'CANCEL' | 'ABORT',
    payload: Feedback | string,
    fbPhotoIds: string[] = [],
  ) {
    let actionResult: boolean = true;
    switch (action) {
      case 'ADD-UPDATE':
        if (typeof payload === 'object') {
          const feedbackData = {
            id: payload.id,
            createdAt: payload.createdAt,
            createdById: payload.createdById,
            tourPlanId: payload.tourPlanId,
            kpiValues: payload.kpiValues,
            kpiValueSets: payload.kpiValueSets,
            commentRequired: payload.commentRequired,
            photoRequired: payload.photoRequired,
            resolved: payload.resolved,
          };

          const photosToUpload =
            this._mobilePhotoList?.filter(ph =>
              fbPhotoIds.includes(ph.photo.id),
            ) ?? [];

          const feedbackExistsResult =
            await this.requestProgressService.addProgress<
              (filter?: Filter<AnyObject>) => Promise<boolean>
            >(
              this.translate.instant('labels.feedback-existence-check'),
              'fa-solid fa-message-smile',
              this.translate.instant(
                'views.visit.feedback-upload-abort-confirmation',
              ),
              this.translate.instant(
                'views.visit.feedback-upload-abort-message',
              ),
              this.dataProvider.feedback.exists.bind(
                this.dataProvider.feedback,
              ),
              [
                {
                  where: {id: feedbackData.id},
                },
              ],
            );

          if (feedbackExistsResult.success == false) {
            actionResult = false;
          } else {
            const createOrUpdate = feedbackExistsResult.response
              ? this.dataProvider.feedback.updateWithPhotos.bind(
                  this.dataProvider.feedback,
                )
              : this.dataProvider.feedback.createWithPhotos.bind(
                  this.dataProvider.feedback,
                );

            const createOrUpdateResult =
              await this.requestProgressService.addProgress<
                (payload: FeedbackWithPhotosRequest) => Promise<void>
              >(
                this.translate.instant('labels.feedback-saving'),
                'fa-solid fa-message-smile',
                this.translate.instant(
                  'views.visit.feedback-upload-abort-confirmation',
                ),
                this.translate.instant(
                  'views.visit.feedback-upload-abort-message',
                ),
                createOrUpdate,
                [
                  {
                    feedback: feedbackData as Feedback,
                    photos: photosToUpload.map(
                      m =>
                        <PhotoCreateRequest>{
                          photo: m.photo,
                          visitId: this.visitId,
                        },
                    ),
                    visitId: this.visitId,
                  },
                ],
              );
            actionResult = createOrUpdateResult.success;
          }

          if (actionResult == false) {
            NotificationService.notifyError(
              this.translate.instant('views.visit.feedback-save-error'),
            );
            await this.removeBase64Photos(fbPhotoIds);
            this.requestProgressService.clearProgresses();
            this.requestProgressService.cleanMessages();
            return actionResult;
          }

          this.feedback = cloneDeep(payload);

          if (fbPhotoIds.length > 0) {
            await this.uploadMobilePhotos(fbPhotoIds);
            this._visitData.photos =
              this.getPhotoList()?.map(ph => {
                if (fbPhotoIds.includes(ph.id)) {
                  ph.uri = `/${StoragePrefixes.PhotoObject}/${ph.id}`;
                  ph.uriThumbnail = `/${StoragePrefixes.PhotoObjectThumbnail}/${ph.id}`;
                  return ph;
                } else {
                  return ph;
                }
              }) ?? [];
          }

          NotificationService.notifySuccess(
            this.translate.instant('views.visit.feedback-saved'),
          );
          this.requestProgressService.clearProgresses();
          this.requestProgressService.cleanMessages();
        }
        break;

      case 'DELETE':
        {
          if (typeof payload === 'string') {
            this.feedback = undefined;
            try {
              const deleteResult =
                await this.requestProgressService.addProgress(
                  this.translate.instant('labels.feedback-deleting'),
                  'fa fa-trash',
                  this.translate.instant(
                    'views.visit.feedback-delete-abort-confirmation',
                  ),
                  this.translate.instant(
                    'views.visit.feedback-delete-abort-message',
                  ),
                  this.dataProvider.feedback.delete.bind(
                    this.dataProvider.feedback,
                  ),
                  [payload],
                );

              if (fbPhotoIds.length > 0) {
                await this.removeBase64Photos(fbPhotoIds);
              }

              actionResult = deleteResult.success;
            } catch (error) {
              console.error(error);
            }

            NotificationService.notifySuccess(
              this.translate.instant('views.visit.feedback-deleted'),
            );
            this.requestProgressService.clearProgresses();
            this.requestProgressService.cleanMessages();
          }
        }
        break;

      case 'CANCEL':
        {
          actionResult = false;
          await this.removeBase64Photos(fbPhotoIds);
        }

        break;

      case 'ABORT':
        {
          actionResult = false;
          await this.removeBase64Photos(fbPhotoIds);
        }
        break;
    }

    if (actionResult != false) {
      this.visitFeedbackList = await this.loadVisitFeedbacks(this.visitId);
    }

    return actionResult;
  }

  /**
   * Stores data in the temporary storage.
   * @param key
   * @param value
   */
  storeData(key: string, value: any) {
    this._tempData.set(key, value);
  }

  /**
   * Returns data from the temporary storage.
   * @param {string} key the key of the data to return.
   * @returns {T}
   */
  getData<T = any>(key: string): T {
    return this._tempData.get(key);
  }
}

/**
 * A class to manage the available visits in history.
 */
export class VisitsHistory {
  /**
   * The id of the first visit at which the history is set and the navigation starts.
   */
  private _baseVisitData: VisitHistoryData;

  /**
   * visit id -> visit number Map
   */
  private _visitsIdNumMap: Map<string, number> = new Map();
  /**
   * visit number -> visit history data Map
   */
  private _visitsNumDataMap: Map<number, VisitHistoryData> = new Map();
  /**
   * visit id -> visit history data Map
   */
  private visitIdDataMap: Map<string, VisitHistoryData> = new Map();

  /**
   *
   * @param {VisitHistoryData} baseVisitId - id of the main visit (the one at which the history is set and the navigation starts.)
   * @param {VisitHistoryData[]} previousVisitIds - id list of the previous visits
   */
  constructor(
    baseVisitData: VisitHistoryData,
    previousVisitData: VisitHistoryData[],
  ) {
    this._baseVisitData = baseVisitData;
    const _previousVisitIds = [...(previousVisitData?.map(m => m.id) ?? [])];

    const visitsCount = _previousVisitIds.length;

    previousVisitData.forEach((visitData, index) => {
      this.visitIdDataMap.set(visitData.id, visitData);

      // the visit list returned from DB is sorted like the newest visit (which happened directly before the base visit) is at the first element with index = 0
      // the follwing lines sort the list like the newest visit has the biggest number. (the list is reversed)
      // E.g. [4 -> 'the newest visit', 3 -> 'the one before the newest', ...., 1 -> 'the oldest one (the last one and at it the previous button is disabled)']
      const visitNumber = visitsCount - index; // for a list with four elements. 4,3,2,1
      this._visitsIdNumMap.set(visitData.id, visitNumber);
      this._visitsNumDataMap.set(visitNumber, visitData);
    });
  }

  /**
   * Returns the number of the newest visit (the last next). (The base visit is not included)
   */
  private get mostNextVisitNum() {
    return this.visitsCount();
  }

  /**
   * Returns the number of the oldest (the last previous) visit.
   */
  private get mostPreviousVisitNum() {
    return 1;
  }

  /**
   * Returns the id of the base visit.
   * @returns {string} - id of the visit.
   */
  getBaseVisitId(): string {
    return this._baseVisitData?.id;
  }

  /**
   * Returns `true` if the provided id is the base visit id. Otherwise, returns `false`.
   * @param {string} visitId - visit id
   * @returns {boolean} - `true` if visitId is the base visit id. Otherwise `false`.
   */
  isBaseVisit(visitId: string): boolean {
    return this._baseVisitData?.id === visitId;
  }

  /**
   * Returns `true` if the provided id is the id of the last next visit. (which means it is the base visit)
   * @param {string} visitId - a visit id
   * @returns {boolean} - `true` if the provided id is the id of the last next visit. Otherwise, `false`.
   */
  isLastNextVisit(visitId: string): boolean {
    return visitId === this._baseVisitData?.id;
  }

  /**
   * Returns `true` if the provided id is the id of the last previous visit. (which means it is the very first visit)
   * @param {string} visitId - a visit id
   * @returns {boolean} - `true` if the provided id is the id of the last previous visit. Otherwise, `false`.
   */
  isLastPreviousVisit(visitId: string): boolean {
    const visitNum = this._visitsIdNumMap.get(visitId);
    return visitNum === this.mostPreviousVisitNum;
  }

  /**
   * Returns `true` if the provided id is the id of a visit that has visit/s after AND before.
   * @param {string} visitId - a visit id
   * @returns {boolean} - `true` if there is visit/s after AND before. Otherwise, `false`.
   */
  isMiddleVisit(visitId: string): boolean {
    return !this.isLastNextVisit(visitId) && !this.isLastPreviousVisit(visitId);
  }

  /**
   * Returns the number of the available visits in history. (base visit in excluded).
   * @returns {number} - the number of the available visits.
   */
  visitsCount(): number {
    return this._visitsIdNumMap.size;
  }

  /**
   * Returns id of the visit before the provided visit.
   * @param {string} currentVisitId - the id of the current visit.
   * @returns {string} - the id of the visit before the provided visit.
   */
  getPreviousVisitData(currentVisitId: string): VisitHistoryData {
    const visitNum = this._visitsIdNumMap.get(currentVisitId);
    // if the provided id is the id of the base visit, returns the first visit in the history.
    if (this.isBaseVisit(currentVisitId)) {
      return this._visitsNumDataMap.get(this.mostNextVisitNum);
    } else if (visitNum === this.mostPreviousVisitNum) {
      return undefined;
    } else {
      return this._visitsNumDataMap.get(visitNum - 1);
    }
  }

  getNextVisitData(currentVisitId: string): VisitHistoryData {
    const visitNum = this._visitsIdNumMap.get(currentVisitId);
    // It is not possible to navigate to next visit after the base visit.
    if (this.isBaseVisit(currentVisitId)) {
      return undefined;
    }
    // navigate to the base visit if the current visit is the most next visit.
    else if (visitNum === this.mostNextVisitNum) {
      return this._baseVisitData;
    } else {
      return this._visitsNumDataMap.get(visitNum + 1);
    }
  }
}

export class CommentsModifier {
  private _comments: Comment[];
  constructor(comments: Comment[]) {
    this._comments = comments ?? [];
  }

  toList() {
    return this._comments;
  }

  add(comment: Comment | Comment[]) {
    if (comment) {
      if (Array.isArray(comment)) {
        this._comments.push(...comment);
      } else {
        this._comments.push(comment);
      }
    }
    return this._comments;
  }

  delete(commentIds: string[]) {
    this._comments = this._comments.filter(c => !commentIds.includes(c.id));
    return this._comments;
  }

  getPureComment(comment: Comment): Comment {
    return {
      id: comment.id,
      comment: comment.comment,
      objectId: comment.objectId,
      postedAt: comment.postedAt,
      postedById: comment.postedById,
    };
  }
}
