import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {IProject} from '@retrixhouse/salesapp-shared/lib/models';

@Component({
  selector: 'app-project-avatar',
  templateUrl: './project-avatar.component.html',
  styleUrls: ['./project-avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectAvatarComponent {
  @Input() project: IProject;
}
