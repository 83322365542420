import {forwardRef, ForwardRefFn} from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALUE_ACCESSOR,
  FormGroup,
  FormArray,
  FormControl,
} from '@angular/forms';

export function createFormControlProvider(forwardRefFn: ForwardRefFn) {
  return {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(forwardRefFn),
    multi: true,
  };
}

export function markFormGroupAsTouched(formGroup: UntypedFormGroup) {
  if (formGroup.controls) {
    const keys = Object.keys(formGroup.controls);
    for (let i = 0; i < keys.length; i++) {
      const control = formGroup.controls[keys[i]];

      if (control instanceof UntypedFormControl) {
        control.markAsTouched({onlySelf: true});
      } else if (control instanceof UntypedFormArray) {
        control.markAsTouched({onlySelf: true});
        control.controls.forEach(arrayControl => {
          markFormGroupAsTouched(arrayControl as UntypedFormGroup);
        });
      } else if (control instanceof UntypedFormGroup) {
        markFormGroupAsTouched(control);
      }
    }
  }
}

export function removeEmptyValues<T>(obj: T): T {
  const newObj = {} as T;
  Object.entries(obj).forEach(([k, v]) => {
    if (Array.isArray(v) && v.length > 0) {
      newObj[k] = obj[k];
    } else if (v instanceof Date) {
      newObj[k] = v;
    } else if (v === Object(v)) {
      const childObj = removeEmptyValues(v);
      if (Object.keys(childObj).length) {
        newObj[k] = childObj;
      }
    } else if (v !== null && v !== undefined && v !== '') {
      newObj[k] = obj[k];
    }
  });
  return newObj;
}

export function getFormUpdatedValues(form: FormGroup) {
  let updatedValues = {};
  processFormUpdatedValues(form, updatedValues);
  return updatedValues as any;
}

function processFormUpdatedValues(
  formItem: FormGroup | FormArray | FormControl,
  updatedValues: any,
  name?: string,
) {
  if (formItem instanceof FormControl) {
    if (name && formItem.dirty) {
      updatedValues[name] = formItem.getRawValue();
    }
  } else {
    for (const formControlName in formItem.controls) {
      if (formItem.controls.hasOwnProperty(formControlName)) {
        const formControl = formItem.controls[formControlName];

        if (formControl instanceof FormControl) {
          processFormUpdatedValues(formControl, updatedValues, formControlName);
        } else if (
          formControl instanceof FormArray &&
          formControl.dirty &&
          formControl.controls.length > 0
        ) {
          updatedValues[formControlName] = [];
          processFormUpdatedValues(formControl, updatedValues[formControlName]);
        } else if (formControl instanceof FormGroup && formControl.dirty) {
          updatedValues[formControlName] = {};
          processFormUpdatedValues(formControl, updatedValues[formControlName]);
        }
      }
    }
  }
}

// function processForm(control: FormGroup | FormControl | FormArray) {
//   let updatedValues: any;

//   if (control instanceof FormControl) {
//     return isFromControlUpdated(control) ? control.getRawValue() : undefined;
//   } else {
//     for (const formControlName in control.controls) {
//       if (Object.prototype.hasOwnProperty.call(control, formControlName)) {
//         const formControl = control.controls[formControlName];

//         if (formControl instanceof FormControl) {
//           if (isFromControlUpdated(formControl)) {
//             updatedValues[formControlName] = formControl.getRawValue();
//           }
//         } else if (
//           formControl instanceof FormArray &&
//           formControl.dirty &&
//           formControl.controls.length > 0
//         ) {
//           const updatedArray = [];
//         } else if (formControl instanceof FormGroup && formControl.dirty) {
//         }
//       }
//     }
//   }
// }

// function getFormGroupUpdatedValues(formGroup: FormGroup) {
//   const updatedValues = {};
// }

// function getFormArrayUpdatedValues(formArray: FormArray) {

// }

// function isFromControlUpdated(formControl: FormControl) {
//   return formControl.dirty;
// }
