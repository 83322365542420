import {Pipe, PipeTransform} from '@angular/core';
import {PositionStorageService} from '@salesapp/storage';
import {map} from 'rxjs/operators';

@Pipe({
  name: 'positionNames$',
})
export class PositionNamesPipe implements PipeTransform {
  constructor(private positionStorageService: PositionStorageService) {}

  transform(value: string[]) {
    return this.positionStorageService.dataById$.pipe(
      map(dataById =>
        value
          .map(positionId => dataById.get(positionId)?.name || 'n/a')
          .join(', '),
      ),
    );
  }
}
