<dx-tree-list
  id="positions"
  [dataSource]="dataSource"
  keyExpr="id"
  parentIdExpr="parentId"
  [showRowLines]="true"
  [showBorders]="true"
  [columnAutoWidth]="true"
  [rootValue]="null"
  [hoverStateEnabled]="true"
  [rowAlternationEnabled]="true"
  (onToolbarPreparing)="onToolbarPreparing($event)"
  (onContentReady)="onContentReady($event)"
>
  <dxo-row-dragging [onReorder]="onReorder" [onDragChange]="onDragChange" [allowDropInsideItem]="true" [allowReordering]="true" [showDragIcons]="true"></dxo-row-dragging>

  <dxo-toolbar>
    <dxi-item location="after">
      <dx-button [icon]="'fa-regular fa-arrows-to-dotted-line'" (onClick)="expandCollapseRows('COLLAPSE')" [hint]="'hints.collapse-all' | translate"></dx-button>
      <dx-button [icon]="'fa-regular fa-arrows-from-dotted-line'" (onClick)="expandCollapseRows('EXPAND')" [hint]="'hints.expand-all' | translate"></dx-button>
    </dxi-item>
  </dxo-toolbar>

  <dxo-editing mode="row" [allowUpdating]="true" [allowDeleting]="true" [allowAdding]="true"> </dxo-editing>

  <dxi-column dataField="name" [editorOptions]="{maxLength: this.maxValues.name}" caption="{{ 'columns.position' | translate }}"></dxi-column>

  <dxi-column
    dataField="abbreviation"
    [width]="GridColumnWidth.MediumText"
    [editorOptions]="{maxLength: this.maxValues.abbreviation}"
    caption="{{ 'columns.abbreviation' | translate }}"
  ></dxi-column>

  <dxi-column type="buttons">
    <dxi-button name="add" icon="fa-regular fa-square-plus"></dxi-button>
    <dxi-button name="edit" icon="fa-regular fa-edit"></dxi-button>
    <dxi-button name="delete" icon="fa-regular fa-trash"></dxi-button>
    <dxi-button icon="fa fa-shield-check" hint="Permissions" [onClick]="onClickPermissions"></dxi-button>
  </dxi-column>
</dx-tree-list>
