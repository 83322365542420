<app-base-dialog *ngIf="!formGroup"
                 [noPadding]="noPadding"
                 [displayFooter]="!mobileVersion">
  <ng-container ngProjectAs="header">
    <ng-container *ngIf="!mobileVersion">
      <div class="title">
        {{ title }}
      </div>
    </ng-container>

    <ng-container *ngIf="mobileVersion">
      <dx-button *ngIf="cancelVisible"
                 [disabled]="cancelDisabled"
                 [icon]="'fa-regular fa-xmark'"
                 stylingMode="text"
                 type="normal"
                 (click)="onCancel()"></dx-button>
      <div class="title">
        {{ title }}
      </div>
      <dx-button *ngIf="actionVisible"
                 [disabled]="actionDisabled"
                 [icon]="actionIcon"
                 [text]="actionLabel"
                 stylingMode="text"
                 type="default"
                 (click)="onAction()"></dx-button>
      <ng-container *ngIf="additionalActions && additionalActions.length">
        <dx-drop-down-button [dropDownOptions]="{ width: 230 }"
                             [items]="additionalActions"
                             [icon]="'fa-solid fa-ellipsis-vertical'"
                             [showArrowIcon]="false"
                             displayExpr="text"
                             keyExpr="id"
                             stylingMode="'text'"
                             (onItemClick)="onAdditionalAction($event.itemData)"></dx-drop-down-button>
      </ng-container>
    </ng-container>

  </ng-container>
  <ng-container ngProjectAs="content">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ng-container>
  <div ngProjectAs="footer"
       class="footer">
    <ng-container *ngIf="!mobileVersion">
      <dx-button *ngIf="actionVisible"
                 [disabled]="actionDisabled"
                 [icon]="actionIcon"
                 [text]="actionLabel"
                 type="default"
                 (click)="onAction()"></dx-button>

      <dx-button *ngIf="cancelVisible"
                 [disabled]="cancelDisabled"
                 [icon]="cancelIcon"
                 [text]="cancelLabel"
                 type="normal"
                 (click)="onCancel()"></dx-button>
    </ng-container>
  </div>


</app-base-dialog>

<app-base-dialog *ngIf="formGroup && !mobileVersion"
                 [displayFooter]="true"
                 [noPadding]="noPadding">
  <ng-container ngProjectAs="header">
    <div class="title">
      {{ title }}
    </div>
  </ng-container>
  <div ngProjectAs="content">
    <app-form [formGroup]="formGroup"
              [formActions]="formActions"
              [actionInProgress]="actionInProgress"
              [disableChangedFormCheck]="disableChangedFormCheck"
              (action)="onAction()"
              (cancel)="onCancel()">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </app-form>
  </div>
  <div ngProjectAs="footer"
       class="footer">
    <app-form-actions #formActions
                      actionIcon="fa fa-save"
                      cancelIcon="fa fa-times"
                      [actionLabel]="actionLabel"
                      [actionDisabled]="actionDisabled || actionInProgress"
                      [actionVisible]="actionVisible"
                      [cancelLabel]="cancelLabel"
                      [cancelDisabled]="cancelDisabled || actionInProgress"
                      [cancelVisible]="cancelVisible">
      <ng-container formActionCenter
                    *ngIf="additionalActions">
        <ng-container *ngIf="additionalActions.length === 1; else multipleActions">
          <dx-button [disabled]="actionDisabled"
                     [icon]="additionalActions[0].icon"
                     [text]="additionalActions[0].text"
                     type="normal"
                     (click)="onAdditionalAction(additionalActions[0])"></dx-button>
        </ng-container>
        <ng-template #multipleActions>
          <dx-drop-down-button [dropDownOptions]="{ width: 230 }"
                               [items]="additionalActions"
                               [icon]="'fa-solid fa-ellipsis-vertical'"
                               [showArrowIcon]="false"
                               displayExpr="text"
                               keyExpr="id"
                               stylingMode="'text'"
                               (onItemClick)="onAdditionalAction($event.itemData)"></dx-drop-down-button>
        </ng-template>
      </ng-container>
    </app-form-actions>
  </div>
</app-base-dialog>

<app-base-dialog *ngIf="formGroup && mobileVersion"
                 [displayFooter]="false"
                 [noPadding]="noPadding">

  <ng-container ngProjectAs="header">
    <app-form-actions #formActionsHeader
                      [actionIcon]="mobileVersion ? null : 'fa fa-save'"
                      cancelIcon="fa-regular fa-xmark"
                      [mobileDialogVersion]="mobileVersion"
                      [actionLabel]="actionLabel"
                      [actionDisabled]="actionDisabled || actionInProgress"
                      [actionVisible]="actionVisible"
                      [cancelLabel]="mobileVersion ? null : cancelLabel"
                      [cancelDisabled]="cancelDisabled || actionInProgress"
                      [cancelVisible]="cancelVisible">
      <div formActionCenter
           class="title">
        {{ title }}
      </div>
      <ng-container *ngIf="additionalActions && additionalActions.length"
                    formActionEnd>
        <dx-button [disabled]="actionDisabled"
                   [icon]="'fa-solid fa-ellipsis-vertical'"
                   stylingMode="text"
                   type="normal"
                   (click)="actionSheet.visible = true"></dx-button>
      </ng-container>
    </app-form-actions>

  </ng-container>
  <div ngProjectAs="content">
    <app-form [formGroup]="formGroup"
              [formActions]="formActionsHeader"
              [actionInProgress]="actionInProgress"
              [disableChangedFormCheck]="disableChangedFormCheck"
              (action)="onAction()"
              (cancel)="onCancel()">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </app-form>
  </div>
</app-base-dialog>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>

<dx-action-sheet #actionSheet
                 [items]="additionalActions"
                 [visible]="false"
                 [showTitle]="false"
                 (onItemClick)="onAdditionalAction($event.itemData)">