import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {InputCheckboxModule, UserAvatarModule} from '@salesapp/components';
import {CustomPipesModule} from '@salesapp/pipes';
import {PillModule} from '@salesapp/shared/components/pill';
import {ProjectAvatarModule} from '@salesapp/shared/components/project-avatar';
import {PropertyValueRendererModule} from '@salesapp/shared/components/property-value-renderer';
import {StoreAvatarModule} from '@salesapp/shared/components/store-avatar/store-avatar.component';
import {
  DxDataGridModule,
  DxDropDownButtonModule,
  DxToolbarModule,
} from 'devextreme-angular';
import {DxButtonModule} from 'devextreme-angular/ui/button';
import {GridFilterComponent} from './components/grid-filter/grid-filter.component';
import {GridRowActionsComponent} from './components/grid-row-actions/grid-row-actions.component';
import {GridComponent} from './components/grid/grid.component';
import {ObjectGridComponent} from './components/object-grid/object-grid.component';
import {GridSetupService} from './services/grid-setup.service';

const DX_MODULES = [
  DxDataGridModule,
  DxButtonModule,
  DxDropDownButtonModule,
  DxToolbarModule,
];

const UI_MODULES = [
  UserAvatarModule,
  StoreAvatarModule,
  ProjectAvatarModule,
  PillModule,
  PropertyValueRendererModule,
  InputCheckboxModule,
];

const SALESAPP_MODULES = [CustomPipesModule];

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    ...DX_MODULES,
    ...UI_MODULES,
    ...SALESAPP_MODULES,
  ],
  declarations: [
    GridComponent,
    GridRowActionsComponent,
    GridFilterComponent,
    ObjectGridComponent,
  ],
  exports: [GridComponent, GridFilterComponent, ObjectGridComponent],
  providers: [GridSetupService],
})
export class GridModule {}

export * from './components/grid-filter/grid-filter.component';
export * from './grid.factory';
export * from './grid.interfaces';
export * from './grid.utils';
export * from './object-grid';
export * from './services/grid-setup.service';
export * from './services/grid.service';
export * from './services/object-grid.service';
