<ng-container *ngIf="(loading$ | async) === false">
  <dx-toolbar *ngIf="options.toolbarVisible"
              [visible]="options.toolbarVisible">
    <dxi-item *ngFor="let toolbarButton of toolbarButtons$ | async"
              [location]="toolbarButton.location">
      <div *dxTemplate>
        <dx-button [icon]="toolbarButton.icon"
                   [type]="toolbarButton.buttonType"
                   [stylingMode]="toolbarButton.buttonStyle"
                   [hint]="toolbarButton.i18nIdentifierHint | translate"
                   [text]="((toolbarButton.text || toolbarButton.i18nIdentifier) + (!!toolbarButton.count ? ' (' + toolbarButton.count + ')' : ''))"
                   [disabled]="toolbarButton.disabledWhenEmptySelection ? !(this.selectedData$ | async).length : false"
                   (onClick)="onToolbarButtonClicked(toolbarButton)">
        </dx-button>
      </div>
    </dxi-item>
  </dx-toolbar>
  <dx-data-grid [dataSource]="data$ | async"
                [columns]="columns$ | async"
                [width]="'100%'"
                [height]="'100%'"
                [columnAutoWidth]="true"
                [columnHidingEnabled]="false"
                [hoverStateEnabled]="options.hoverStateEnabled"
                [rowAlternationEnabled]="options.rowAlternationEnabled"
                [allowColumnReordering]="options.allowColumnReordering"
                [allowColumnResizing]="options.allowColumnResizing"
                [noDataText]="'labels.no-data' | translate"
                [wordWrapEnabled]="true"
                (onRowDblClick)="onRowDoubleClick($event)"
                (onSelectionChanged)="onSelectionChanged($event)"
                (onExporting)="onExporting($event)"
                (onRowPrepared)="onRowPrepared($event)">
    <dxo-pager [showPageSizeSelector]="true"
               [showInfo]="true"
               [allowedPageSizes]="[10, 20, 50, 100]"></dxo-pager>
    <dxo-toolbar>
      <dxi-item name="groupPanel"></dxi-item>
      <dxi-item name="exportButton"></dxi-item>
      <dxi-item *ngIf="options.allowRestoreDefaultLayout"
                [location]="'after'">
        <div *dxTemplate>
          <dx-button [icon]="'columnfield'"
                     [className]=""
                     [stylingMode]="'text'"
                     [hint]="'hints.restore-default-layout' | translate"
                     (onClick)="resetState()"></dx-button>
        </div>
      </dxi-item>
      <dxi-item name="columnChooserButton"></dxi-item>
    </dxo-toolbar>
    <dxo-column-chooser [enabled]="options.allowColumnChooser"
                        [search]="{enabled: true}"
                        [height]="800"
                        [width]="400"
                        [position]="{
            my: 'right top',
            at: 'right bottom',
            of: '.dx-datagrid-column-chooser-button'
          }"
                        [selection]="{
            recursive: true,
            selectByClick: true,
            allowSelectAll: true
          }"
                        sortOrder="asc"
                        mode="select"></dxo-column-chooser>
    <dxo-grouping [contextMenuEnabled]="true">
    </dxo-grouping>
    <dxo-group-panel [visible]="true">
    </dxo-group-panel>
    <dxo-sorting mode="multiple"></dxo-sorting>
    <dxo-selection *ngIf="options.showSelection"
                   [selectAllMode]="options.selectAllMode"
                   [showCheckBoxesMode]="'always'"
                   [mode]="options.selectionMode"></dxo-selection>
    <dxo-export [enabled]="options.allowExport"
                [allowExportSelectedData]="options.allowExportSelectedData"></dxo-export>

    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-state-storing [customLoad]="loadState"
                       [customSave]="saveState"
                       [enabled]="!!options.userStorageKey"
                       type="custom"></dxo-state-storing>

    <dxo-summary>
      <dxi-group-item name='group-summary'
                      summaryType="count"
                      displayFormat="{0}"
                      [showInGroupFooter]="false"
                      [alignByColumn]="false"></dxi-group-item>
    </dxo-summary>
    <!-- CUSTOM RENDERING -->
    <div *dxTemplate="let data of 'actionsTemplate'">
      <app-grid-row-actions [actions]="gridRowActions$ | async"
                            [data]="data.data"
                            (actionClicked)="onGridRowActionClicked($event, data)"></app-grid-row-actions>
    </div>

    <div *dxTemplate="let data of 'headerTemplate'"
         class="column-header">
      <span>{{ data.column.caption }}</span>
      <i *ngIf="!data.column.columns"
         class="fa-regular fa-layer-plus"></i>
    </div>

    <div *dxTemplate="let data of 'objectPropertyValueCellTemplate'">
      <app-property-value-renderer [property]="data.column.property"
                                   [value]="data.value"></app-property-value-renderer>
    </div>

    <div *dxTemplate="let group of 'objectPropertyGroupTemplate'"
         class="group">
      <div class="group-name">
        {{ group.column.caption}}:
      </div>
      <app-property-value-renderer class="group-value"
                                   [property]="group.column.property"
                                   [value]="group.data.key"></app-property-value-renderer>
      <app-pill class="group-count"
                [text]="group.summaryItems[0]?.value"
                [color]="'#e0e0e0'"></app-pill>
    </div>

  </dx-data-grid>
</ng-container>

<ng-template #emptyTemplate>
  <span class="empty">
    {{ 'grid.cell-template.empty' | translate }}
  </span>
</ng-template>

<ng-template #unavailableTemplate>
  <span class="empty">
    {{ 'grid.cell-template.unavailable' | translate }}
  </span>
</ng-template>