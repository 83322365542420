import {CommonModule} from '@angular/common';
import {
  Component,
  Input,
  NgModule,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {DxDrawerModule} from 'devextreme-angular/ui/drawer';
import {
  DxScrollViewComponent,
  DxScrollViewModule,
} from 'devextreme-angular/ui/scroll-view';
import {DxToolbarModule} from 'devextreme-angular/ui/toolbar';
import {ScrollEvent} from 'devextreme/ui/scroll_view';
import {Subscription} from 'rxjs';
import {FullScreenService} from 'src/app/shared/services/full-screen.service';
import {HeaderModule, SideNavigationMenuModule} from '../../shared/components';
import {AppScrollService, ScreenService} from '../../shared/services';

@Component({
  selector: 'app-side-nav-inner-toolbar',
  templateUrl: './side-nav-inner-toolbar.component.html',
  styleUrls: ['./side-nav-inner-toolbar.component.scss'],
})
export class SideNavInnerToolbarComponent implements OnInit, OnDestroy {
  @ViewChild(DxScrollViewComponent, {static: true})
  scrollView: DxScrollViewComponent;
  selectedRoute = '';

  menuOpened: boolean;
  temporaryMenuOpened = false;

  @Input()
  title: string;

  menuMode = 'shrink';
  menuRevealMode = 'expand';
  minMenuSize = 0;
  shaderEnabled = false;
  isFullScreen: boolean = false;
  $routeChangeSubscription: Subscription;
  $fullScreenChangeSubscription: Subscription;
  $screenChangeSubscription: Subscription;

  get footerVisible() {
    return this.router.url === '/';
  }

  constructor(
    private screenService: ScreenService,
    private router: Router,
    private fullScreen: FullScreenService,
    private appScrollService: AppScrollService,
  ) {}

  ngOnInit() {
    this.menuOpened = this.screenService.sizes['screen-large'];

    this.$routeChangeSubscription = this.router.events.subscribe(val => {
      if (val instanceof NavigationEnd) {
        this.selectedRoute = val.url;
      }
    });

    this.$screenChangeSubscription = this.screenService.changed.subscribe(() =>
      this.updateDrawer(),
    );
    this.$fullScreenChangeSubscription = this.fullScreen.fullScreen.subscribe(
      isFullScreen => {
        const leftNavigationElement = document
          .querySelector(`[id='navigation-menu-drawer']`)
          .querySelector(`.dx-drawer-panel-content`) as HTMLElement;

        const contentBlockElement = document.querySelector(
          `.content-block-card`,
        ) as HTMLElement;

        const drawerContent = document.querySelector(
          `.dx-drawer-content`,
        ) as HTMLElement;

        const scrollViewContent = document.querySelector(
          `.dx-scrollview-content`,
        ) as HTMLElement;

        if (isFullScreen) {
          contentBlockElement.style.marginLeft = '0px';
          contentBlockElement.style.marginRight = '0px';
          contentBlockElement.style.marginTop = '0px';

          leftNavigationElement.style.width = '0px';
          leftNavigationElement.style.minWidth = '0px';

          drawerContent.style.paddingLeft = '0px';
          scrollViewContent.style.height = '100%';
          scrollViewContent.style.width = '100%';
        } else {
          if (this.menuOpened) {
            contentBlockElement.style.marginLeft = '40px';
            contentBlockElement.style.marginRight = '40px';
            contentBlockElement.style.marginTop = '20px';

            leftNavigationElement.style.width = '250px';
            leftNavigationElement.style.minWidth = '60px';

            drawerContent.style.paddingLeft = '00px';
          } else {
            contentBlockElement.style.marginLeft = '40px';
            contentBlockElement.style.marginRight = '40px';
            contentBlockElement.style.marginTop = '20px';

            leftNavigationElement.style.width = '60px';
            leftNavigationElement.style.minWidth = '60px';

            drawerContent.style.paddingLeft = '00px';
            this.menuOpened = true;
            setTimeout(() => {
              this.menuOpened = false;
            }, 500);
          }
        }
        this.isFullScreen = isFullScreen;
      },
    );

    this.updateDrawer();
  }

  ngOnDestroy(): void {
    if (this.$screenChangeSubscription) {
      this.$screenChangeSubscription.unsubscribe();
    }

    if (this.$fullScreenChangeSubscription) {
      this.$fullScreenChangeSubscription.unsubscribe();
    }

    if (this.$routeChangeSubscription) {
      this.$routeChangeSubscription.unsubscribe();
    }
  }

  updateDrawer() {
    const isXSmall = this.screenService.sizes['screen-x-small'];
    const isLarge = this.screenService.sizes['screen-large'];
    const isSmall = this.screenService.isSmallScreen();

    this.menuMode = !isSmall ? 'shrink' : 'overlap';
    this.menuRevealMode = isSmall ? 'slide' : 'expand';
    this.minMenuSize = isSmall ? 0 : 60;
    this.shaderEnabled = isSmall;
  }

  toggleMenu = e => {
    this.menuOpened = !this.menuOpened;
    this.selectedRoute = this.router.url;
    e.event.stopPropagation();
  };

  get hideMenuAfterNavigation() {
    return this.menuMode === 'overlap' || this.temporaryMenuOpened;
  }

  get showMenuAfterClick() {
    return !this.menuOpened;
  }

  navigationChanged(event) {
    const path = event.itemData.path;
    const pointerEvent = event.event;

    if (path && this.menuOpened) {
      pointerEvent.preventDefault();
      this.router.navigate([path]);
      this.scrollView.instance.scrollTo(0);

      if (this.hideMenuAfterNavigation) {
        this.temporaryMenuOpened = false;
        this.menuOpened = false;
        pointerEvent.stopPropagation();
      }
    } else {
      pointerEvent.preventDefault();
    }
  }

  navigationClick() {
    this.selectedRoute = this.router.url;
    if (this.showMenuAfterClick) {
      this.temporaryMenuOpened = false;
      this.menuOpened = true;
    }
  }

  onAppContentScroll(e: ScrollEvent) {
    this.appScrollService.setScrollEvent(e);
  }
}

@NgModule({
  imports: [
    SideNavigationMenuModule,
    DxDrawerModule,
    HeaderModule,
    DxToolbarModule,
    DxScrollViewModule,
    CommonModule,
  ],
  exports: [SideNavInnerToolbarComponent],
  declarations: [SideNavInnerToolbarComponent],
})
export class SideNavInnerToolbarModule {}
