import {BHSAggregatedDataHttpService} from './bhs-aggregated-data.http-service';
import {BillingInfoHttpService} from './billing-info.http-service';
import {ChainHttpService} from './chain.http-service';
import {ClientSideErrorConfHttpService} from './client-side-error-conf.http-service';
import {ClientSideErrorHttpService} from './client-side-error.http-service';
import {CommentHttpService} from './comment.http-service';
import {CountryHttpService} from './country.http-service';
import {CurrencyHttpService} from './currency.http-service';
import {CustomerHttpService} from './customer.http-service';
import {DataStorageFileHttpService} from './data-storage-file.http-service';
import {DataStorageFolderHttpService} from './data-storage-folder.http-service';
import {FailSafeHttpService} from './fail-safe.http-service';
import {FeedbackHttpService} from './feedback.http-service';
import {GenericListItemHttpService} from './generic-list-item.http-service';
import {GenericListHttpService} from './generic-list.http-service';
import {GlobalSearchHttpService} from './global-search.http-service';
import {HolidayHttpService} from './holiday.http-service';
import {I18NHttpService} from './i18n.http-service';
import {ImexTemplateHttpService} from './imex-template.http-service';
import {JsonSchemaHttpService} from './json-schema.http-service';
import {KPISetHttpService} from './kpi-set.http-service';
import {KPIHttpService} from './kpi.http-service';
import {LanguageHttpService} from './language.http-service';
import {MobileDeviceHttpService} from './mobile-device.http-service';
import {NewsPostHttpService} from './news-post.http-service';
import {NotificationTemplateHttpService} from './notification-template.http-service';
import {ObjectReadHttpService} from './object-read.http-service';
import {ObjectHttpService} from './object.http-service';
import {OrderHttpService} from './order.http-service';
import {PeriodicLimitHttpService} from './periodic-limit.http-service';
import {PersonalArrangementHttpService} from './personal-arrangement.http-service';
import {PhotoObjectHttpService} from './photo-object.http-service';
import {PingHttpService} from './ping.http-service';
import {PositionHttpService} from './position.http-service';
import {ProductCategoryHttpService} from './product-category.http-service';
import {ProductListingTemplateHttpService} from './product-listing-template.http-service';
import {ProductListingHttpService} from './product-listing.http-service';
import {ProductHttpService} from './product.http-service';
import {ProjectUserStoreHttpService} from './project-store.http-service';
import {ProjectHttpService} from './project.http-service';
import {PromoActionHttpService} from './promo-action.http-service';
import {QuestionHttpService} from './question.http-service';
import {QuestionnaireItemHttpService} from './questionnaire-item.http-service';
import {QuestionnaireHttpService} from './questionnaire.http-service';
import {ReportDashboardHttpService} from './report-dashboard.http-service';
import {ReportProjectHttpService} from './report-project.http-service';
import {RequestLogHttpService} from './request-log.http-service';
import {RoleHttpService} from './role.http-service';
import {ScopeHttpService} from './scope.http-service';
import {SettingsHttpService} from './settings.http-service';
import {StorageAccountHttpService} from './storage-account.http-service';
import {StoreHttpService} from './store.http-service';
import {TagHttpService} from './tag.http-service';
import {TodoActionHttpService} from './todo-action.http-service';
import {TodoListHttpService} from './todo-list.http-service';
import {TourPlanChangeRequestHttpService} from './tour-plan-change-request.http-service';
import {TourPlanHttpService} from './tour-plan.http-service';
import {TriggerHttpService} from './trigger.http-service';
import {UnitConversionHttpService} from './unit-conversion.http-service';
import {UnitHttpService} from './unit.http-service';
import {UserStorageHttpService} from './user-storage.http-service';
import {UserTaskHttpService} from './user-task.http-service';
import {UserHttpService} from './user.http-service';
import {VisitHttpService} from './visit.http-service';
import {SystemHttpService} from './system.http-service';

export * from './base.http-service';
export * from './bhs-aggregated-data.http-service';
export * from './billing-info.http-service';
export * from './chain.http-service';
export * from './client-side-error-conf.http-service';
export * from './client-side-error.http-service';
export * from './comment.http-service';
export * from './country.http-service';
export * from './currency.http-service';
export * from './customer.http-service';
export * from './data-storage-file.http-service';
export * from './data-storage-folder.http-service';
export * from './fail-safe.http-service';
export * from './feedback.http-service';
export * from './generic-list-item.http-service';
export * from './generic-list.http-service';
export * from './global-search.http-service';
export * from './holiday.http-service';
export * from './i18n.http-service';
export * from './imex-template.http-service';
export * from './json-schema.http-service';
export * from './kpi-set.http-service';
export * from './kpi.http-service';
export * from './language.http-service';
export * from './mobile-device.http-service';
export * from './news-post.http-service';
export * from './notification-template.http-service';
export * from './object-read.http-service';
export * from './object.http-service';
export * from './order.http-service';
export * from './periodic-limit.http-service';
export * from './personal-arrangement.http-service';
export * from './photo-object.http-service';
export * from './ping.http-service';
export * from './position.http-service';
export * from './product-category.http-service';
export * from './product-listing-template.http-service';
export * from './product-listing.http-service';
export * from './product.http-service';
export * from './project-store.http-service';
export * from './project.http-service';
export * from './promo-action.http-service';
export * from './question.http-service';
export * from './questionnaire-item.http-service';
export * from './questionnaire.http-service';
export * from './report-dashboard.http-service';
export * from './report-project.http-service';
export * from './request-log.http-service';
export * from './role.http-service';
export * from './scope.http-service';
export * from './settings.http-service';
export * from './storage-account.http-service';
export * from './store.http-service';
export * from './system.http-service';
export * from './tag.http-service';
export * from './todo-action.http-service';
export * from './todo-list.http-service';
export * from './tour-plan-change-request.http-service';
export * from './tour-plan.http-service';
export * from './trigger.http-service';
export * from './unit-conversion.http-service';
export * from './unit.http-service';
export * from './user-storage.http-service';
export * from './user-task.http-service';
export * from './user.http-service';
export * from './visit.http-service';

export const HTTP_SERVICES = [
  BHSAggregatedDataHttpService,
  BillingInfoHttpService,
  ChainHttpService,
  ClientSideErrorConfHttpService,
  ClientSideErrorHttpService,
  CommentHttpService,
  CountryHttpService,
  CurrencyHttpService,
  CustomerHttpService,
  DataStorageFileHttpService,
  DataStorageFolderHttpService,
  FailSafeHttpService,
  FeedbackHttpService,
  GenericListHttpService,
  GenericListItemHttpService,
  GlobalSearchHttpService,
  HolidayHttpService,
  I18NHttpService,
  ImexTemplateHttpService,
  JsonSchemaHttpService,
  KPIHttpService,
  KPISetHttpService,
  LanguageHttpService,
  MobileDeviceHttpService,
  NewsPostHttpService,
  NotificationTemplateHttpService,
  ObjectHttpService,
  ObjectReadHttpService,
  OrderHttpService,
  PeriodicLimitHttpService,
  PersonalArrangementHttpService,
  PhotoObjectHttpService,
  PingHttpService,
  PositionHttpService,
  ProductCategoryHttpService,
  ProductHttpService,
  ProductListingHttpService,
  ProductListingTemplateHttpService,
  ProjectHttpService,
  ProjectUserStoreHttpService,
  PromoActionHttpService,
  QuestionHttpService,
  QuestionnaireHttpService,
  QuestionnaireItemHttpService,
  ReportDashboardHttpService,
  ReportProjectHttpService,
  RequestLogHttpService,
  RoleHttpService,
  ScopeHttpService,
  SettingsHttpService,
  StorageAccountHttpService,
  StoreHttpService,
  SystemHttpService,
  TagHttpService,
  TodoActionHttpService,
  TodoListHttpService,
  TourPlanChangeRequestHttpService,
  TourPlanHttpService,
  TriggerHttpService,
  UnitConversionHttpService,
  UnitHttpService,
  UserHttpService,
  UserStorageHttpService,
  UserTaskHttpService,
  VisitHttpService,
];
