import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {
  ObjectTypeIds,
  ObjectTypeNames,
} from '@retrixhouse/salesapp-shared/lib/common';
import {ReportExternal} from '@salesapp/api';
import {
  GridColumn,
  GridNavigationalPropertySetting,
  GridOptions,
  GridRowAction,
  GridServiceImplementor,
  GridToolbarButton,
} from '@salesapp/grid';
import {ActionsService} from '@salesapp/services';
import {ExternalReportStorage} from '@salesapp/storage';
import {Observable, of} from 'rxjs';
import {Action, Actions} from '../../actions/actions';
import {ExternalReportActions} from '../external-report.actions';
import {ExternalReportService} from './external-report.service';

@Injectable()
export class ExternalReportGridService
  implements GridServiceImplementor<ReportExternal>
{
  gridOptions: GridOptions = {
    allowColumnChooser: false,
    allowRestoreDefaultLayout: false,
  };
  defaultVisibleProperties: string[] = [];
  ignoredProperties: string[];
  navigationalProperties?: GridNavigationalPropertySetting[];
  objectTypeId?: ObjectTypeIds;
  objectTypeName?: ObjectTypeNames;
  data$: Observable<ReportExternal[]>;
  toolbarButtons$: Observable<GridToolbarButton[]>;
  gridRowActions$?: Observable<GridRowAction[]>;

  constructor(
    private translateService: TranslateService,
    private externalReportService: ExternalReportService,
    private externalReportStorage: ExternalReportStorage,
    private actionsService: ActionsService,
  ) {
    this.initGrid();
  }

  getColumns?(): Observable<GridColumn[]> {
    return of([
      {
        caption: this.translateService.instant(
          'entity.external-report.properties.name.label',
        ),
        dataField: 'name',
        width: 400,
        minWidth: 80,
        columnHidingEnabled: false,
        propertyId: '',
        dataType: 'string',
        cssClass: 'ellipsis',
      },
    ]);
  }

  openDetail(data: ReportExternal) {
    this.externalReportService.openExternalReport(data);
  }

  handleToolbarButtonClick(
    button: GridToolbarButton,
    selection: ReportExternal[],
  ) {
    this.handleAction(button, selection);
  }

  handleGridRowActionClick?(action: GridRowAction, data: ReportExternal) {
    this.handleAction(action, data);
  }

  private initGrid() {
    this.data$ = this.externalReportStorage.data$;
    this.setToolbarButtons();
    this.setGridRowActions();
  }

  private setToolbarButtons() {
    this.toolbarButtons$ = of([
      ExternalReportActions.EXTERNAL_REPORT_CREATE.withCustomProps({
        location: 'before',
        buttonType: 'default',
        buttonStyle: 'contained',
      }) as GridToolbarButton,
    ]);
  }

  private setGridRowActions() {
    this.gridRowActions$ = of([
      ExternalReportActions.EXTERNAL_REPORT_EDIT,
      Actions.SHOW_RAW_DATA,
      ExternalReportActions.EXTERNAL_REPORT_DELETE,
    ]);
  }

  private handleAction(
    action: Action,
    data: ReportExternal | ReportExternal[],
  ) {
    switch (action.id) {
      case ExternalReportActions.EXTERNAL_REPORT_CREATE.id:
        this.externalReportService.createExternalReport();
        break;
      case ExternalReportActions.EXTERNAL_REPORT_EDIT.id:
        this.externalReportService.editExternalReport(data as ReportExternal);
        break;
      case ExternalReportActions.EXTERNAL_REPORT_DELETE.id:
        this.externalReportService.deleteExternalReport(data as ReportExternal);
        break;
      case Actions.SHOW_RAW_DATA.id:
        this.actionsService.openRawData(data as ReportExternal);
        break;
      default:
        alert(`There is no handler implemented for action id: ${action.id}`);
        break;
    }
  }
}
