<ng-container *ngFor="let error of listOfErrors">
      <ng-container [ngSwitch]="error">
            <!-- angular errors -->
            <span *ngSwitchCase="FormValidationErrorKeysEnum.Required"
                  class="error-message">
                  {{ 'form.error.required' | translate }}
            </span>
            <span *ngSwitchCase="FormValidationErrorKeysEnum.MaxLength"
                  class="error-message">{{
                  'form.error.max-length' | translate : {requiredLength: errors[error].requiredLength, actualLength:
                  errors[error].actualLength}
                  }}</span>
            <span *ngSwitchCase="FormValidationErrorKeysEnum.MinLength"
                  class="error-message">{{
                  'form.error.min-length' | translate : {requiredLength: errors[error].requiredLength, actualLength:
                  errors[error].actualLength}
                  }}</span>
            <span *ngSwitchCase="FormValidationErrorKeysEnum.ValidationExpression"
                  class="error-message">{{'form.error.validation-expression' | translate }}</span>
            <span *ngSwitchCase="FormValidationErrorKeysEnum.DateRangeMissingDate"
                  class="error-message">{{'form.error.date-range-missing-date' | translate }}</span>
            <span *ngSwitchCase="FormValidationErrorKeysEnum.DateRangeExceeded"
                  class="error-message">{{'form.error.date-range-exceeded' | translate : { days: errors[error] }
                  }}</span>
            <span *ngSwitchCase="FormValidationErrorKeysEnum.MinimumDateExceeded"
                  class="error-message">{{'form.error.minimum-date-exceeded-date' | translate }}</span>
            <span *ngSwitchCase="FormValidationErrorKeysEnum.AsyncValueNotUnique"
                  class="error-message">{{'form.error.async-value-not-unique' | translate }}</span>


            <!-- custom errors -->
            <!-- <span *ngSwitchCase="FormValidationErrorKeysEnum." class="error-message">
        {{ '' | translate }}
      </span> -->
            <span *ngSwitchCase="FormValidationErrorKeysEnum.Custom"
                  class="error-message">{{ errors[error] }}</span>
      </ng-container>
</ng-container>