import {Injectable} from '@angular/core';
import {DataStorageFile} from '../../models';
import {OldBaseStorageService} from './old-base-storage-service';
import {CachingOptions} from '../data';
import {DataProvider} from '../../data.provider/data-provider';
import {map} from 'rxjs/operators';
import {visibilityFilter} from '@retrixhouse/salesapp-shared/lib/utils/visibility-filter';

@Injectable()
export class FileStorageService extends OldBaseStorageService<
  DataStorageFile,
  DataStorageFile[]
> {
  cachingOptions: CachingOptions = {
    skipCache: true,
  };

  relevantData$ = this.data$.pipe(
    map(files => {
      if (files) {
        return files.filter(file => visibilityFilter(file));
      }
      return [];
    }),
  );

  constructor(public dataProvider: DataProvider) {
    super(dataProvider.dataStorageFile);
  }

  copy(file: DataStorageFile) {
    return this.command({
      commandMethod$: this.copyFile.bind(this),
      commandInput: file,
    });
  }

  upload(input: {folderId: string; file: FormData}) {
    return this.command({
      commandMethod$: this.uploadFile.bind(this),
      commandInput: input,
    });
  }

  private copyFile(input: DataStorageFile) {
    return this.dataProvider.dataStorageFile.copy(input);
  }

  private uploadFile(input: {folderId: string; file: FormData}) {
    return this.dataProvider.dataStorageFile.upload(input.folderId, input.file);
  }
}
