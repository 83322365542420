import {Injectable} from '@angular/core';
import {NotificationApiService, PushNotificationRead} from '@salesapp/api';
import {MarkPushNotificationAsRead$Params} from '@salesapp/api/notification/mark-push-notification-as-read';
import {Observable} from 'rxjs';
import {BaseStorage} from './base.storage';

export type PushNotificationMarkAsReadParams =
  MarkPushNotificationAsRead$Params;

@Injectable()
export class PushNotificationStorage extends BaseStorage<
  PushNotificationRead,
  PushNotificationRead[],
  never,
  never,
  never,
  never
> {
  constructor(protected apiService: NotificationApiService) {
    super(apiService);
  }

  getListMethod(params?: never): Observable<PushNotificationRead[]> {
    return this.apiService.getAllPushNotificationsForCurrentUser();
  }

  markAsRead(input: PushNotificationMarkAsReadParams) {
    return this.command({
      commandMethod$: this.markAsReadMethod.bind(this),
      commandInput: input,
      updateState: (data, input) => {
        this.updateDataState(store => ({
          value: store.value.map(item => {
            if (item.notificationId === input.notificationId) {
              return {
                ...item,
                read: true,
              };
            }
            return item;
          }),
        }));
      },
    });
  }

  private markAsReadMethod(input: PushNotificationMarkAsReadParams) {
    return this.apiService.markPushNotificationAsRead(input);
  }
}
