import {Injectable} from '@angular/core';
import {DataProvider} from '../../data.provider/data-provider';
import {
  OldBaseStorageService,
  StorageDataStateEnum,
} from './old-base-storage-service';
import {IUser} from '@retrixhouse/salesapp-shared/lib/models';
import {Observable, from} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {UserProfileStorageService} from './user-profile-storage.service';
import {InputSelectOption} from '../../components';
import {map, share, startWith} from 'rxjs/operators';
import {UsernameResponse} from '../../models';
import {UserDataService} from '../data';

@Injectable()
export class UserStorageService extends OldBaseStorageService<IUser, IUser[]> {
  dataAsSelectOptions$: Observable<InputSelectOption<IUser>[]> =
    this.data$.pipe(
      map(users => {
        return (users || []).map(user => ({
          value: user.id,
          name: user.username,
          data: user,
        }));
      }),
    );
  // TODO(milan): remove translate service when delte multiple will be refactored
  constructor(
    private dataProvider: DataProvider,
    private translateService: TranslateService,
    private userProfileStorageService: UserProfileStorageService,
  ) {
    super(dataProvider.user);
  }
  // TODO(milan): remove
  // getMySubordinatesForProject(
  //   projectId?: string,
  //   includeAdministrators?: boolean,
  // ): Observable<UsernameResponse[]> {
  //   return from(
  //     (this.dataService as UserDataService).getMySubordinatesForProject(
  //       projectId,
  //       includeAdministrators,
  //     ),
  //   ).pipe(startWith([]), share());
  // }

  // getUsernames(projectId?: string): Observable<UsernameResponse[]> {
  //   return from((this.dataService as UserDataService).getUsernames()).pipe(
  //     startWith([]),
  //     share(),
  //   );
  // }

  deleteMultiple(input: {id: string; version?: number}[]) {
    // TODO(milan): change this code after discussion with Marek
    Promise.all(
      input.map(user => {
        return this.dataProvider.user.delete(user.id, user.version);
      }),
    )
      .then(response => {
        this.updateDataState(storage => ({
          state: StorageDataStateEnum.Obsoleted,
        }));
        this.userProfileStorageService.markAsObsoleted();
      })
      .catch(error => {
        throw new Error(
          this.translateService.instant('messages.error.deleting-data'),
        );
      });
  }
}
