import {ObjectPropertyValue} from '../object-property-value.model';

export class SetExtendedPropertiesRequest {
  objectData: any;
  propertyValueList: ObjectPropertyValue[];

  constructor() {
    this.propertyValueList = [];
  }
}
