<ng-container [ngSwitch]="type">

  <div *ngSwitchCase="ObjectFormInputControlTypeEnum.Address">
    <app-form-row>
      <app-form-column>
        <app-input-text [label]="'labels.address-line1' | translate"
                        [disabled]="true"></app-input-text>
      </app-form-column>
    </app-form-row>
    <app-form-row>
      <app-form-column>
        <app-input-text [label]="'labels.address-line2' | translate"
                        [disabled]="true"></app-input-text>
      </app-form-column>
    </app-form-row>
    <app-form-row>
      <app-form-column>
        <app-input-text [label]="'labels.address-zip' | translate"
                        [disabled]="true"></app-input-text>
      </app-form-column>
      <app-form-column>
        <app-input-text [label]="'labels.address-city' | translate"
                        [disabled]="true"></app-input-text>
      </app-form-column>
    </app-form-row>
    <app-form-row>
      <app-form-column>
        <app-input-text [label]="'labels.address-region' | translate"
                        [disabled]="true"></app-input-text>
      </app-form-column>
      <app-form-column>
        <app-input-text [label]="'labels.address-state' | translate"
                        [disabled]="true"></app-input-text>
      </app-form-column>
    </app-form-row>
    <app-form-row>
      <app-form-column>
        <app-input-select [label]="'labels.address-country' | translate"
                          [options]="[]"
                          [disabled]="true"></app-input-select>
      </app-form-column>
    </app-form-row>
  </div>

  <div *ngSwitchCase="ObjectFormInputControlTypeEnum.Avatar"
       class="avatar-placeholder">
    <div class="avatar-placeholder-background">
      <i class="fa-solid fa-camera-retro"></i>
    </div>
  </div>

  <app-input-date *ngSwitchCase="ObjectFormInputControlTypeEnum.Date"
                  [label]="label$ | async"
                  [hint]="hint"
                  [disabled]="true"></app-input-date>

  <app-input-datetime *ngSwitchCase="ObjectFormInputControlTypeEnum.Datetime"
                      [label]="label$ | async"
                      [hint]="hint"
                      [disabled]="true"></app-input-datetime>


  <app-input-select *ngSwitchCase="ObjectFormInputControlTypeEnum.GoogleLocationId"
                    [label]="label$ | async"
                    [hint]="hint"
                    [disabled]="true"></app-input-select>

  <div *ngSwitchCase="ObjectFormInputControlTypeEnum.Location">
    <app-form-row>
      <app-form-column>
        <app-input-text [label]="'form-designer.control.longitude.label' | translate"
                        [hint]="hint"
                        [disabled]="true"></app-input-text>
      </app-form-column>
      <app-form-column>
        <app-input-text [label]="'form-designer.control.latitude.label' | translate"
                        [hint]="hint"
                        [disabled]="true"></app-input-text>
      </app-form-column>
      <app-form-column>
        <app-input-text [label]="'form-designer.control.altitude.label' | translate"
                        [hint]="hint"
                        [disabled]="true"></app-input-text>
      </app-form-column>
    </app-form-row>
    <div class="map-placeholder">
      <i class="fa-solid fa-map"></i>
    </div>
  </div>

  <app-input-select *ngSwitchCase="ObjectFormInputControlTypeEnum.MultiSelect"
                    [label]="label$ | async"
                    [hint]="hint"
                    [disabled]="true"></app-input-select>

  <app-input-number *ngSwitchCase="ObjectFormInputControlTypeEnum.Number"
                    [label]="label$ | async"
                    [hint]="hint"
                    [disabled]="true"></app-input-number>

  <app-input-rich-text *ngSwitchCase="ObjectFormInputControlTypeEnum.RichText"
                       [label]="label$ | async"
                       [hint]="hint"
                       [disabled]="true"></app-input-rich-text>

  <app-input-select *ngSwitchCase="ObjectFormInputControlTypeEnum.Select"
                    [label]="label$ | async"
                    [hint]="hint"
                    [disabled]="true"></app-input-select>

  <app-input-switch *ngSwitchCase="ObjectFormInputControlTypeEnum.Switch"
                    [label]="label$ | async"
                    [hint]="hint"
                    [disabled]="true"></app-input-switch>

  <app-input-text *ngSwitchCase="ObjectFormInputControlTypeEnum.Tags"
                  [label]="label$ | async"
                  [hint]="hint"
                  [disabled]="true"></app-input-text>

  <app-input-text *ngSwitchCase="ObjectFormInputControlTypeEnum.Text"
                  [label]="label$ | async"
                  [hint]="hint"
                  [disabled]="true"></app-input-text>

</ng-container>