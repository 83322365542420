import {BaseHttpService} from './base.http-service';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Ping} from 'src/app/shared/models';
import {IPingDataService} from '../../interfaces/data-service';

@Injectable()
export class PingHttpService
  extends BaseHttpService
  implements IPingDataService
{
  constructor(http: HttpClient) {
    super(http);
  }

  public ping(): Promise<Ping> {
    return this.GET<Ping>('/ping');
  }
}
