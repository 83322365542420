export class UsernameResponse {
  username: string;
  id: string;
  uid: string;
  internal?: boolean;
  profileId?: string;
  profilePicture?: string;
  positionId?: string;
  positionName?: string;
  positionAbbreviation?: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  phone?: string;
  email?: string;
  extendedPropertiesUser?: any;
  extendedPropertiesProfile?: any;
}
