import {Injectable} from '@angular/core';
import {Order, OrderReport, ReportRangeRequest} from '../../models';
import {IOrderDataService} from '../../interfaces/data-service';
import {BaseCrudOfflineService} from './base.offline-service';

@Injectable()
export class OrderOfflineService
  extends BaseCrudOfflineService<Order>
  implements IOrderDataService
{
  public getReport(rangeRequest: ReportRangeRequest): Promise<OrderReport[]> {
    return Promise.reject('Offline service - not yet implemented');
  }
}
