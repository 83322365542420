import {DIALOG_DATA, DialogRef} from '@angular/cdk/dialog';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {DataProvider} from '@salesapp/data-provider';
import {ObjectTypeIds} from '@salesapp/shared/globals';
import {NewsPost} from '@salesapp/shared/models';
import {
  ChainStorageService,
  NewsPostStorageService,
  PositionStorageService,
  ProjectStorageService,
  StoreStorageService,
  UsernameStorageService,
} from '@salesapp/storage';
import {CustomValidators} from '@salesapp/utils/reactive-form/form-validators';
import {getFormUpdatedValues} from '@salesapp/utils/reactive-form/form.utils';
import {FormControlsOf} from '@salesapp/utils/reactive-form/reactive-form.interface';
import {BehaviorSubject, Observable} from 'rxjs';
import {StoresService} from 'src/app/modules/stores/stores/services/stores.service';
import * as uuid from 'uuid';

@Component({
  selector: 'app-news-post-edit-dialog',
  templateUrl: './news-post-edit-dialog.component.html',
  styleUrls: ['./news-post-edit-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [StoresService],
})
export class NewsPostEditDialogComponent implements OnInit {
  form: FormGroup<NewsPostFormGroup>;
  disableAction$: Observable<boolean>;
  loading$ = new BehaviorSubject<boolean>(true);

  chainOptions$ = this.chainsStorageService.dataAsSelectOptions$;
  projectOptions$ = this.projectStorageService.dataAsSelectOptions$;
  positionOptions$ =
    this.positionStorageService.DataWithoutCustomerAsSelectOptions$;
  storeOptions$ = this.storeStorageService.dataAsSelectOptions$;
  userOptions$ = this.userStorageService.dataAsSelectOptions$;

  get isUpdate() {
    return !!this.data?.newsPostValue;
  }

  constructor(
    private dialogRef: DialogRef<any>,
    @Inject(DIALOG_DATA)
    private data: NewsPostEditDialogData,
    private formBuilder: FormBuilder,
    private newsPostStorageService: NewsPostStorageService,
    private projectStorageService: ProjectStorageService,
    private chainsStorageService: ChainStorageService,
    private positionStorageService: PositionStorageService,
    private dataProvider: DataProvider,
    private translateService: TranslateService,
    private storeStorageService: StoreStorageService,
    private userStorageService: UsernameStorageService,
  ) {}

  // TODO(milan): remove async and change promises to observables
  async ngOnInit(): Promise<void> {
    this.initForm();
    this.disableAction$ = this.newsPostStorageService.dataCommandInProgress$;
  }

  private async initForm() {
    const form = this.formBuilder.group<NewsPostFormGroup>({
      id: this.formBuilder.control(uuid.v4()),
      uid: this.formBuilder.control({value: null, disabled: true}),
      caption: this.formBuilder.control(null, [CustomValidators.required]),
      validUntil: this.formBuilder.control(null),
      projectIds: this.formBuilder.control(null),
      chainIds: this.formBuilder.control(null),
      positionIds: this.formBuilder.control(null),
      storeIds: this.formBuilder.control(null),
      userIds: this.formBuilder.control(null),
      important: this.formBuilder.control({value: false, disabled: false}),
      published: this.formBuilder.control({value: false, disabled: false}),
      body: this.formBuilder.control(null, [CustomValidators.required]),
      version: this.formBuilder.control(null),
      authorId: this.formBuilder.control(null),
      createdAt: this.formBuilder.control(null),
    });

    if (this.isUpdate) {
      form.patchValue(this.data.newsPostValue);
    } else {
      const uid = await this.dataProvider.object.getNextAvailableUID(
        ObjectTypeIds.NewsPost,
      );
      form.controls.uid.patchValue(uid.uid);
    }

    this.form = form;
    this.loading$.next(false);
  }

  onClose() {
    this.dialogRef.close();
  }

  onSave() {
    const closeDialog = () => {
      this.dialogRef.close();
    };

    const input = getFormUpdatedValues(this.form);

    if (this.isUpdate) {
      if (Object.keys(input).length !== 0) {
        this.newsPostStorageService
          .update({
            id: this.form.getRawValue().id,
            data: this.form.getRawValue(),
          })
          .subscribe({
            next: closeDialog,
          });
      } else {
        closeDialog();
      }
    } else {
      const input = this.form.getRawValue();
      this.newsPostStorageService.create(input).subscribe({
        next: closeDialog,
      });
    }
  }

  getFormControl(name: keyof NewsPostValueExtended) {
    return this.form.get(name) as FormControl;
  }
}

interface NewsPostValueExtended extends Omit<NewsPost, 'news-post'> {
  positionIds: string[];
}

export interface NewsPostEditDialogData {
  newsPostValue?: NewsPostValueExtended;
}

type NewsPostFormGroup = FormControlsOf<NewsPostValueExtended>;
