import * as uuid from 'uuid';
import {isNumberOrString} from '../utils/utils';
import {Customer} from './customer.model';
import {Project} from './project.model';

export class ProductListing {
  id: string;
  uid: string;
  version: number;
  name: string;
  validFrom: Date;
  validTo: Date;
  projectId: string;
  project: Project;
  customer: Customer;

  constructor() {
    this.id = uuid.v4();
    this.name = null;
    this.validFrom = null;
    this.validTo = null;
  }

  public static formatDateProperties(pl?: ProductListing): void {
    if (isNumberOrString(pl?.validFrom)) {
      pl.validFrom = new Date(pl.validFrom);
    }

    if (isNumberOrString(pl?.validTo)) {
      pl.validTo = new Date(pl.validTo);
    }
  }
}
