import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {DxCheckBoxModule} from 'devextreme-angular/ui/check-box';
import {FormFieldModule} from '../form-field';
import {InputCheckboxComponent} from './input-checkbox.component';

const DX_MODULES = [DxCheckBoxModule];

const UI_MODULES = [FormFieldModule];

@NgModule({
  imports: [CommonModule, ...DX_MODULES, ...UI_MODULES],
  declarations: [InputCheckboxComponent],
  exports: [InputCheckboxComponent],
})
export class InputCheckboxModule {}

export * from './input-checkbox.component';
