import {Injectable} from '@angular/core';
import {
  ProductListing,
  ProductListingStore,
  ProductListingStoreRequest,
  ProjectProductListing,
} from '../../models';
import {IProductListingDataService} from '../../interfaces/data-service';
import {BaseCrudOfflineService} from './base.offline-service';
import {Filter} from '@loopback/filter';
import {IProductListingCopy} from '@retrixhouse/salesapp-shared/lib/requests';
import {IProductListing} from '@retrixhouse/salesapp-shared/lib/models';
import {IProcessingProgress} from '@retrixhouse/salesapp-shared/lib/responses';

@Injectable()
export class ProductListingOfflineService
  extends BaseCrudOfflineService<ProductListing>
  implements IProductListingDataService
{
  public getProductListingStoreList(filter: Filter<ProductListingStore>) {
    return Promise.reject('Offline service - not yet implemented');
  }

  public updateProductListingStore(
    productListingStoreRequest: ProductListingStoreRequest,
  ) {
    return Promise.reject('Offline service - not yet implemented');
  }

  public getActiveProductListingsForProjects(
    projectIds: string[],
  ): Promise<ProjectProductListing[]> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public copyListing(
    productListingCopyRequest: IProductListingCopy,
  ): Promise<{createdProductListing: IProductListing}> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public getCopyProgress(): Promise<IProcessingProgress> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public getCopyAvailability(): Promise<IProcessingProgress[]> {
    return Promise.reject('Offline service - not yet implemented');
  }
}
