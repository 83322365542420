import {BaseModel} from './base.model';
import {Project} from './project.model';
import {Store} from './store.model';
import {TourPlan} from './tour-plan.model';
import {UsernameResponse} from './username-response.model';

export class UserTask extends BaseModel {
  assignedToId: string;
  assignedTo: UsernameResponse;
  createdById: string;
  createdBy: UsernameResponse;
  createdVisitId: string;
  createdVisit: TourPlan;
  closedVisitId: string;
  closedVisit: TourPlan;
  projectId: string;
  project: Project;
  storeId: string;
  store: Store;
  createdAt: Date;
  closedAt: Date;
  dueAt: Date;
  state: UserTaskState;
  text: string;

  [key: string]: any;

  constructor() {
    super();
    this.state = UserTaskState.Pending;
  }

  static getColorByState(state: UserTaskState): string {
    return userTaskStateColorMap.get(state);
  }
}

export enum UserTaskState {
  Pending = 'Pending',
  Done = 'Done',
  InProgress = 'InProgress',
  Blocked = 'Blocked',
}

const userTaskStateColorMap = new Map<UserTaskState, string>([
  [UserTaskState.Pending, '#a1abab'],
  [UserTaskState.Blocked, '#ff0000'],
  [UserTaskState.InProgress, '#ffa93f'],
  [UserTaskState.Done, '#93b952'],
]);
