import {ChangeDetectionStrategy, Component} from '@angular/core';
import {getGridServiceProvider} from '@salesapp/grid';
import {ExternalReportGridService} from '../../services/external-report-grid.service';

@Component({
  selector: 'app-external-report-grid',
  templateUrl: './external-report-grid.component.html',
  styleUrls: ['./external-report-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [getGridServiceProvider(ExternalReportGridService)],
})
export class ExternalReportGridComponent {}
