<div class="comment">
  <div
    class="create-comment"
    *ngIf="!readOnly"
  >
    <div class="comment-header">
      <app-user-avatar
        mode="singleLine"
        [firstName]="this.currentUser?.firstName"
        [lastName]="this.currentUser?.lastName"
        [picture]="this.currentUser?.profile?.picture"
        [userUid]="this.currentUser?.uid"
        [pictureSize]="'small'"
      ></app-user-avatar>
      <!-- <div *ngIf="largeScreen" class="comment-header-right">
        <div id="comment-counter" [style.color]="textArea?.text?.length < maxLength ? 'black' : 'red'">{{ (textArea?.text?.length ?? 0) + '/' + maxLength }}</div>
      </div> -->
    </div>

    <dx-text-area
      #textArea
      valueChangeEvent="change input"
      id="comment-text-area"
      placeholder="{{ 'view.comment.placeholder' | translate }}"
      [maxLength]="maxLength"
    >
    </dx-text-area>
    <div class="comment-footer">
      <dx-button
        type="default"
        text="{{ 'buttons.post' | translate }}"
        icon="fas fa-comment"
        (onClick)="btnSaveClick($event)"
        [disabled]="readOnly || !textArea.value || textArea.value.trim().length === 0"
      >
      </dx-button>
      <dx-button
        class="delete-all"
        *ngIf="isAdmin"
        type="danger"
        text="{{ 'buttons.delete-all' | translate }}"
        icon="fas fa-trash-alt"
        (onClick)="onDeleteAll($event)"
        [disabled]="readOnly"
      >
      </dx-button>
    </div>
  </div>
  <div id="commentList">
    <dx-list
      [dataSource]="commentList"
      itemTemplate="commentsData"
      (onContentReady)="onContentReady($event)"
      [hoverStateEnabled]="false"
      [focusStateEnabled]="false"
      [activeStateEnabled]="false"
      pageLoadMode="scrollBottom"
      noDataText="{{ 'labels.no-data' | translate }}"
    >
      <div
        *dxTemplate="let item of 'commentsData'"
        class="comment-list"
        style="padding: 0 0 10px 0"
      >
        <div
          class="list-header"
          id="{{ item.id }}"
        >
          <dx-responsive-box>
            <dxi-row></dxi-row>
            <dxi-col [ratio]="3"></dxi-col>
            <dxi-col [ratio]="2"></dxi-col>

            <dxi-item>
              <dxi-location
                [row]="0"
                [col]="0"
              ></dxi-location>
              <app-user-avatar
                mode="singleLine"
                [firstName]="item.userInfo?.firstName"
                [lastName]="item.userInfo?.lastName"
                [picture]="item.userInfo?.profilePicture"
                [userUid]="item.userInfo?.uid"
                [pictureSize]="'small'"
              ></app-user-avatar>
            </dxi-item>

            <dxi-item>
              <dxi-location
                [row]="0"
                [col]="1"
              ></dxi-location>
              <div class="comment-date">
                {{ item.postedAt | dateFormat : 'D MMM LT' }}
                <ng-container *ngIf="isAdmin">
                  <dx-button
                    [disabled]="readOnly"
                    stylingMode="text"
                    class="btn-delete"
                    type="danger"
                    icon="fa fa-trash"
                    (onClick)="btnDeleteClick(item)"
                  >
                  </dx-button>
                </ng-container>
              </div>
            </dxi-item>
          </dx-responsive-box>
        </div>
        <div
          class="comment-text rich-text-content"
          [innerHtml]="item.comment.replaceAll('\n', br)"
        ></div>
      </div>
    </dx-list>
  </div>
</div>
