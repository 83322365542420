import {Component, Input, NgModule, OnDestroy, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FlexLayoutModule} from '@angular/flex-layout';

import {DxListModule} from 'devextreme-angular/ui/list';
import {DxContextMenuModule} from 'devextreme-angular/ui/context-menu';
import {UserProfile} from '../../models';
import {environment} from 'src/environments/environment';
import {
  AppInfoService,
  AuthGuardService,
  ClientModeService,
} from '../../services';
import {Router} from '@angular/router';
import {ClientMode} from '../../enums/client-mode.enum';
import {TranslateService} from '@ngx-translate/core';
import {RoleNames} from '@retrixhouse/salesapp-shared/lib/common';
import {TSimpleChanges} from '@salesapp/utils/angular.utils';

@Component({
  selector: 'app-user-panel',
  templateUrl: 'user-panel.component.html',
  styleUrls: ['./user-panel.component.scss'],
})
export class UserPanelComponent implements OnInit {
  @Input()
  menuItems: any;

  @Input()
  menuMode: string;

  @Input()
  user: {
    email?: string;
    firstName?: string;
    lastName?: string;
    profile?: UserProfile;
    picture?: string;
  };

  userImageStyle: string;
  hasDashboardRole: boolean;

  constructor(
    public clientModeService: ClientModeService,
    public translate: TranslateService,
    private authGuardService: AuthGuardService,
    private appInfoService: AppInfoService,
    private router: Router,
  ) {
    this.onMenuShowing = this.onMenuShowing.bind(this);
    this.userImageStyle = `background-image: url(${environment.profilePictureBaseUrl}/assets/no-avatar.png)`;
  }

  ngOnChanges(changes: TSimpleChanges<UserPanelComponent>): void {
    if (changes.user) {
      const timestamp = new Date().getTime();
      this.userImageStyle = `background-image: url(${environment.profilePictureBaseUrl}${this.user.picture}?t=${timestamp})`;
    }
  }

  async ngOnInit(): Promise<void> {
    this.hasDashboardRole =
      this.authGuardService.isAdmin() ||
      this.authGuardService.hasRole(RoleNames.CustomDashboard);
  }

  onMenuShowing(e): void {
    if (Array.isArray(this.menuItems)) {
      this.menuItems.forEach(i => {
        if (i.id === 'client-mode-switch') {
          i.visible =
            this.appInfoService.isMobileVersion && this.router.url === '/';
        }

        if (i.id === 'reorder-dashboard') {
          i.visible =
            this.clientModeService.clientMode === ClientMode.ONLINE &&
            this.hasDashboardRole &&
            this.router.url === '/';
        }
      });
    }
  }
}

@NgModule({
  imports: [DxListModule, DxContextMenuModule, CommonModule, FlexLayoutModule],
  declarations: [UserPanelComponent],
  exports: [UserPanelComponent],
})
export class UserPanelModule {}
