import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgModule,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  DxTreeViewComponent,
  DxTreeViewModule,
} from 'devextreme-angular/ui/tree-view';
import {navigation} from '../../../app-navigation';

import * as events from 'devextreme/events';
import {TranslateService} from '@ngx-translate/core';
import {AuthGuardService, ClientModeService} from '../../services';

import {isLikeUid, UserStorageKeys} from '../../globals';
import {DataProvider} from '../../data.provider/data-provider';
import * as uuid from 'uuid';
import {Router} from '@angular/router';
import {ClientMode} from '../../enums/client-mode.enum';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-side-navigation-menu',
  templateUrl: './side-navigation-menu.component.html',
  styleUrls: ['./side-navigation-menu.component.scss'],
})
export class SideNavigationMenuComponent
  implements AfterViewInit, OnDestroy, OnInit
{
  @ViewChild(DxTreeViewComponent, {static: true})
  menu: DxTreeViewComponent;

  @Output()
  selectedItemChanged = new EventEmitter<string>();

  @Output()
  openMenu = new EventEmitter<any>();

  private _selectedItem: string;

  @Input()
  set selectedItem(value: string) {
    this._selectedItem = value;

    if (!this.menu.instance) {
      return;
    }

    if (value.includes('/history/')) {
      value = '/system/history/0';
    }

    value = this.recognizeIdOrUID(value);

    this.menu.instance.selectItem(value);
  }

  private _items;

  get items() {
    // if items is undefined display at least home button
    return (
      this._items ?? [
        {
          text: this.translate.instant('navigation.home'),
          path: '/',
          icon: 'fa fa-house-user',
        },
      ]
    );
  }

  private _compactMode = false;

  @Input()
  get compactMode() {
    return this._compactMode;
  }

  set compactMode(val) {
    // this.saveMenuOpenCloseState(!val);
    this._compactMode = val;

    if (!this.menu.instance) {
      return;
    }

    if (val) {
      this.menu.instance.collapseAll();
    } else {
      this.menu.instance.expandItem(this._selectedItem);
    }

    this.menu.instance.selectItem(this.router.url);
  }

  @Output()
  compactModeChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  private storedUserMenu: {
    menuOpened: boolean;
    expandedMenus: {[menuId: string]: boolean};
  } = {
    expandedMenus: {},
    menuOpened: true,
  };
  positionMenuItemsLoaded: boolean;
  $clientModeChangeSubscription: Subscription;

  constructor(
    private elementRef: ElementRef,
    private translate: TranslateService,
    private authService: AuthGuardService,
    private dataProvider: DataProvider,
    private router: Router,
    private clientModeService: ClientModeService,
  ) {
    this.positionMenuItemsLoaded = false;
    // event handler that fires when user switches device mode
    this.$clientModeChangeSubscription = this.clientModeService
      .clientModeChangeEvent()
      .subscribe(foo => {
        if (this.positionMenuItemsLoaded) {
          this.buildMenu();
        }
      });

    // this.translate.onLangChange.subscribe(() => this.buildMenu());
  }

  async ngOnInit(): Promise<void> {
    this.authService.getUserPosition().then(position => {
      this.positionMenuItemsLoaded = true;
      const menuItemIds = new Set<string>(position?.menuItems ?? []);

      navigation.forEach(item => {
        item.visible = menuItemIds.has(item.id);
        item.visibleForUser = item.visible;

        if (item.visible && Array.isArray(item.items)) {
          for (const i of item.items) {
            i.visible = menuItemIds.has(i.id);
            i.visibleForUser = i.visible;
          }
        }
      });
      this.buildMenu(); // build side menu after position permissions were leaded
    });
    // await this.loadNavigationMenu();

    if (this.router.url.includes('/history/')) {
      this.selectedItem = '/system/history/0';
    }

    if (!this.storedUserMenu.menuOpened) {
      this.compactModeChange.emit(false);
    }
  }

  ngAfterViewInit(): void {
    events.on(this.elementRef.nativeElement, 'dxclick', e => {
      this.openMenu.next(e);
    });
  }

  ngOnDestroy(): void {
    events.off(this.elementRef.nativeElement, 'dxclick');
    if (this.$clientModeChangeSubscription) {
      this.$clientModeChangeSubscription.unsubscribe();
    }
  }

  recognizeIdOrUID(selectedItem: string): string {
    const urlArray = selectedItem.split('/');
    const uuidOrUIDfound = urlArray.find(p => {
      return uuid.validate(p) || isLikeUid(p);
    });

    if (uuidOrUIDfound) {
      selectedItem = selectedItem.replace(uuidOrUIDfound, '').slice(0, -1);
    }
    return selectedItem;
  }

  async onItemClick(event): Promise<void> {
    for (const item in this.storedUserMenu.expandedMenus) {
      if (item === event.node.itemData.id) {
        await this.menu.instance.expandItem(item);
        this.storedUserMenu.expandedMenus[item] = event.node.expanded;
      }
    }

    // await this.saveNavigationMenu();

    this.selectedItemChanged.emit(event);
  }

  buildMenu(): void {
    this._items = navigation.map(item => {
      if (item.i18n) {
        item.text = this.translate.instant(item.i18n);
      }

      if (this.clientModeService.clientMode === ClientMode.OFFLINE) {
        item.visible = !!item.visibleForUser && !!item.showInOfflineMode;
      } else {
        item.visible = !!item.visibleForUser;
      }

      if (item.items) {
        for (const i of item.items) {
          if (this.clientModeService.clientMode === ClientMode.OFFLINE) {
            i.visible = !!i.visibleForUser && !!i.showInOfflineMode;
          } else {
            i.visible = !!i.visibleForUser;
          }

          if (i.i18n) {
            i.text = this.translate.instant(i.i18n);
          }
        }
      }

      if (item.path && !/^\//.test(item.path)) {
        item.path = `/${item.path}`;
      }

      const storedItem = this.storedUserMenu.expandedMenus[item.id] ?? false;

      let itemExpanded = this.storedUserMenu.menuOpened ? storedItem : false;

      if (item.path !== this.router.url && !storedItem) {
        for (const subItem in item.items) {
          if (item.items[subItem].path === this.router.url && !storedItem) {
            itemExpanded = true;
          }
        }
      }

      return {
        ...item,
        expanded: itemExpanded,
      };
    });
  }

  async saveMenuOpenCloseState(menuOpened: boolean): Promise<void> {
    await this.loadNavigationMenu();
    this.storedUserMenu.menuOpened = menuOpened;
    this.saveNavigationMenu();
  }

  async saveNavigationMenu(): Promise<void> {
    await this.dataProvider.userStorage.set(
      UserStorageKeys.NavigationMenu,
      this.storedUserMenu,
    );
  }

  async loadNavigationMenu(): Promise<void> {
    this.storedUserMenu = await this.dataProvider.userStorage.get<{
      menuOpened: boolean;
      expandedMenus: {[menuId: string]: boolean};
    }>(UserStorageKeys.NavigationMenu);
  }
}

@NgModule({
  imports: [DxTreeViewModule],
  declarations: [SideNavigationMenuComponent],
  exports: [SideNavigationMenuComponent],
})
export class SideNavigationMenuModule {}
