import {Component, ViewChild} from '@angular/core';
import {ObjectTypeIds} from '@retrixhouse/salesapp-shared/lib/common';
import {paramCase} from 'change-case';
import {DxDataGridComponent} from 'devextreme-angular';
import {
  ExportingEvent,
  RowPreparedEvent,
  SelectionChangedEvent,
} from 'devextreme/ui/data_grid';
import {GridToolbarButton} from '../../grid.interfaces';
import {GridService} from '../../services/grid.service';

@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
})
export class GridComponent {
  objectTypeIdsMap: Map<ObjectTypeIds, string>;

  @ViewChild(DxDataGridComponent, {static: false})
  dataGrid: DxDataGridComponent;
  loading$ = this.gridService.loading$;
  genericListItemsById$ = this.gridService.genericListItemsById$;

  paramCase = paramCase;

  get formatters() {
    return {
      tourPlanStatusToColor: this.gridService.helpers.getTourPlanColorByState,
    };
  }

  get data$() {
    return this.gridService.data$;
  }

  get columns$() {
    return this.gridService.columns$;
  }

  get toolbarButtons$() {
    return this.gridService.toolbarButtons$;
  }

  get gridRowActions$() {
    return this.gridService.gridRowActions$;
  }

  get gridOptions() {
    return this.gridService.gridOptions;
  }

  get selectedData$() {
    return this.gridService.selectedData$;
  }

  constructor(private gridService: GridService<unknown>) {
    this.objectTypeIdsMap = this.createObjectTypeIdsMap();
  }

  onToolbarButtonClicked(button: GridToolbarButton) {
    this.gridService.handleToolbarButtonClick(button);
  }

  onExporting(exportingEvent: ExportingEvent) {
    this.gridService.exportData(exportingEvent);
  }

  onGridRowActionClicked(event, data) {
    this.gridService.handleGridRowActionClick(event, data.data);
  }

  onRowDoubleClick(event: {data: {id: string}}) {
    this.gridService.openDetail(event.data);
  }

  onRowPrepared(event: RowPreparedEvent) {
    this.gridService.handleRowPrepared(event);
  }

  loadState = () => {
    return this.gridService?.loadState();
  };

  saveState = state => {
    return this.gridService?.saveState(state);
  };

  resetState = () => {
    // return this.gridService.resetState();
    this.gridService.resetState(this.dataGrid);
  };

  onSelectionChanged(event: SelectionChangedEvent) {
    this.gridService.handleRowSelectionChanged(event);
  }

  private createObjectTypeIdsMap() {
    const objectTypeIdsMap = new Map();
    Object.keys(ObjectTypeIds).forEach(key => {
      objectTypeIdsMap.set(ObjectTypeIds[key], key);
    });
    return objectTypeIdsMap;
  }
}
