<!-- TODO: remove unused attributes -->
<app-dialog [title]="'form-designer.dialog.edit-form-control.title' | translate"
            [actionLabel]="'form.action.save' | translate"
            [actionVisible]="true"
            [cancelLabel]="'form.action.close' | translate"
            [cancelVisible]="true"
            [formGroup]="form"
            [noPadding]="false"
            (cancelClicked)="onClose()"
            (actionClicked)="onSave()">

  <app-form-row>
    <app-form-column>
      <app-input-text [formControl]="getFormControl('label')"
                      [formControlRef]="getFormControl('label')"
                      [label]="'form-designer.form-control.label.label' | translate"
                      [placeholder]="placeholder$ | async"></app-input-text>
    </app-form-column>
  </app-form-row>
  <app-form-row>
    <app-form-column>
      <app-input-text-area [formControl]="getFormControl('hint')"
                           [formControlRef]="getFormControl('hint')"
                           [label]="'form-designer.form-control.hint.label' | translate"
                           [height]="120"></app-input-text-area>
    </app-form-column>
  </app-form-row>
  <app-form-row>
    <app-form-column>
      <app-input-select [formControl]="getFormControl('propertyId')"
                        [formControlRef]="getFormControl('propertyId')"
                        [label]="'form-designer.form-control.property-id.label' | translate"
                        [options]="propertyOptions$ | async"></app-input-select>
    </app-form-column>
  </app-form-row>
  <app-form-row>
    <app-form-column>
      <app-input-select [formControl]="getFormControl('inputType')"
                        [formControlRef]="getFormControl('inputType')"
                        [label]="'form-designer.form-control.input-type.label' | translate"
                        [options]="inputTypeOptions"></app-input-select>
    </app-form-column>
  </app-form-row>

  <app-form-row>
    <ng-container [ngSwitch]="controlType">
      <app-form-designer-google-places-control-settings *ngSwitchCase="ObjectFormInputControlTypeEnum.GoogleLocationId"
                                                        [settingsFormGroup]="googlePlacesSettingsFormGroup">
      </app-form-designer-google-places-control-settings>
      <app-form-designer-location-control-settings *ngSwitchCase="ObjectFormInputControlTypeEnum.Location"
                                                   [settingsFormGroup]="locationPlacesSettingsFormGroup"></app-form-designer-location-control-settings>
    </ng-container>
  </app-form-row>

  <app-form-section [title]="'form-designer.dialog.edit-form-control.expression-settings.title' | translate">

    <app-form-row>
      <app-form-column>
        <app-input-text-area [formControl]="getFormControl('visibilityExpression')"
                             [formControlRef]="getFormControl('visibilityExpression')"
                             [label]="'form-designer.form-control.visibility-expression.label' | translate"
                             [height]="120"></app-input-text-area>
      </app-form-column>
    </app-form-row>
    <app-form-row>
      <app-form-column>
        <app-input-text-area [formControl]="getFormControl('validationExpression')"
                             [formControlRef]="getFormControl('validationExpression')"
                             [label]="'form-designer.form-control.validation-expression.label' | translate"
                             [height]="120"></app-input-text-area>
      </app-form-column>
    </app-form-row>
    <app-form-row>
      <app-form-column>
        <app-input-text-area [formControl]="getFormControl('requiredExpression')"
                             [formControlRef]="getFormControl('requiredExpression')"
                             [label]="'form-designer.form-control.required-expression.label' | translate"
                             [height]="120"></app-input-text-area>
      </app-form-column>
    </app-form-row>
    <app-form-row>
      <app-form-column>
        <app-input-text-area [formControl]="getFormControl('readonlyExpression')"
                             [formControlRef]="getFormControl('readonlyExpression')"
                             [label]="'form-designer.form-control.readonly-expression.label' | translate"
                             [height]="120"></app-input-text-area>
      </app-form-column>
    </app-form-row>
  </app-form-section>

</app-dialog>