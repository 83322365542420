import {ObjectMapCache} from '@retrixhouse/salesapp-shared/lib/caching';
import * as moment from 'moment';
import {IHolidayDataService} from '../../interfaces/data-service';
import {Holiday} from '../../models';
import {HolidayHttpService} from '../http';
import {HolidayOfflineService} from '../offline';
import {BaseCrudDataService, CachingOptions} from './base.data-service';

export class HolidayDataService extends BaseCrudDataService<
  Holiday,
  IHolidayDataService,
  ObjectMapCache<string, Holiday>
> {
  constructor(
    onlineService: HolidayHttpService,
    offlineService: HolidayOfflineService,
    cache: ObjectMapCache<string, Holiday>,
  ) {
    super(onlineService, offlineService, cache);
  }

  public weekendOrHoliday(
    date: Date,
    cachingOptions?: CachingOptions,
  ): Promise<boolean> {
    // no caching
    if (cachingOptions?.skipCache || !this._cache) {
      return this.service.weekendOrHoliday(date);
    }

    return Promise.resolve(
      this._cache.getValues().some(h => moment(h.date).isSame(date, 'day')),
    );
  }
}
