import {Injectable} from '@angular/core';
import {FormGroup, UntypedFormBuilder} from '@angular/forms';
import {ObjectTypeIds} from '@retrixhouse/salesapp-shared/lib/common';
import {
  IObjectForm,
  IObjectFormGroup,
  IObjectFormInputControl,
  IObjectFormLocationControlSetting,
  IObjectProperty,
  IUserProfileWithRelations,
  ObjectFormInputControlTypeEnum,
  ValueType,
} from '@retrixhouse/salesapp-shared/lib/models';
import {
  CurrentUserStorageService,
  GenericListItemStorageService,
  ObjectStorageService,
  StorageDataStateEnum,
  UserProfileStorageService,
  UserStorageService,
} from '@salesapp/storage';
import {AutoUnsubscribe} from '@salesapp/utils/angular.utils';
import {arrayToMap} from '@salesapp/utils/utils';
import {
  eval as evalExpression,
  parse as parseExpression,
} from 'expression-eval';
import {BehaviorSubject, Observable, Subscription, combineLatest} from 'rxjs';
import {filter, map, switchMap} from 'rxjs/operators';
import {CustomValidators} from '../../../utils/reactive-form/form-validators';

@Injectable()
@AutoUnsubscribe()
export class DynamicFormService {
  objectProperties$: Observable<IObjectProperty[]>;
  visibleFormInputControlIds$ = new BehaviorSubject<string[]>([]);
  visibleGroupIds$ = new BehaviorSubject<string[]>([]);

  initialized$ = new BehaviorSubject<boolean>(false);

  get objectForm() {
    return this.formForUser;
  }

  get propertiesByIdMap() {
    return this.propertiesById;
  }

  get properties() {
    return this._properties;
  }

  private _properties: IObjectProperty[];
  private propertiesById: Map<string, IObjectProperty>;
  private propertiesByName: Map<string, IObjectProperty>;
  private formForUser: IObjectForm;
  private formControlsByPropertyName: Map<string, IObjectFormInputControl>;
  private groups: IObjectFormGroup[];
  private objectTypeId: ObjectTypeIds;

  private initSubscription: Subscription;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private objectStorageService: ObjectStorageService,
    private currentUserStorageService: CurrentUserStorageService,
    private userProfileStorageService: UserProfileStorageService,
    private userStorageService: UserStorageService,
    private genericListItemStorageService: GenericListItemStorageService,
  ) {}

  init(objectTypeId: ObjectTypeIds) {
    this.initSubscription = this.userProfileStorageService.dataState$
      .pipe(
        filter(userProfileDataState => {
          return userProfileDataState === StorageDataStateEnum.Success;
        }),
        switchMap(() => {
          return combineLatest([
            this.objectStorageService.formsByObjectTypeId$.pipe(
              filter(data => !!data?.size),
            ),
            this.objectStorageService.propertiesByObjectTypeId$.pipe(
              filter(data => !!data?.size),
            ),
            this.currentUserStorageService.data$.pipe(
              filter(data => !!data),
              switchMap(data => {
                return this.userProfileStorageService.dataById$.pipe(
                  map(
                    usersById =>
                      (
                        usersById.get(
                          data.profileId,
                        ) as IUserProfileWithRelations
                      ).user,
                  ),
                );
              }),
            ),
          ]).pipe(
            filter(
              ([
                formsByObjectTypeId,
                propertiesByObjectTypeId,
                currentUser,
              ]) => {
                return !!currentUser;
              },
            ),
          );
        }),
      )
      .subscribe({
        next: ([
          formsByObjectTypeId,
          propertiesByObjectTypeId,
          currentUser,
        ]) => {
          const objectForms = formsByObjectTypeId.get(objectTypeId);
          this.objectTypeId = objectTypeId;
          let defaultForm: IObjectForm;
          let formForUser: IObjectForm;

          objectForms.forEach(form => {
            if (form.default) {
              defaultForm = form;
            }

            if (form.positionIds?.includes(currentUser.positionId)) {
              formForUser = form;
            }
          });

          this.formForUser = formForUser || defaultForm;
          this._properties = propertiesByObjectTypeId.get(objectTypeId);
          this.propertiesById = arrayToMap(this.properties, 'id');
          this.propertiesByName = arrayToMap(this.properties, 'name');
          const {formControlsMap, groups} = this.getFormData();
          this.formControlsByPropertyName = formControlsMap;
          this.groups = groups;
          this.initialized$.next(true);
        },
      });
  }

  generateForm(initialValue: any, readonlyMode: boolean) {
    const form = this.formBuilder.group({});

    this.properties.forEach(property => {
      const formInputControl = this.formControlsByPropertyName.get(
        property.name,
      );
      if (formInputControl) {
        switch (formInputControl.inputType) {
          case ObjectFormInputControlTypeEnum.Address:
            this.addAddressFromControlToForm({form, property});
            break;
          default:
            this.addFormControlToForm({
              form,
              initialValue,
              property,
              formInputControl,
              readonlyMode,
            });
            break;
        }
      } else {
        // NOTE: we have to change how address is handled;
        if (
          ![ValueType.RelationToOneData, ValueType.RelationToManyData].includes(
            property.valueType,
          )
        ) {
          // in case when property is not on form we will add default control because we always need id and version on object form;
          form.addControl(property.name, this.formBuilder.control(null));
        }
      }
    });
    // this.processForm(form, initialValue);
    const formattedInitialValue = this.formatInitialData(initialValue);
    form.patchValue(formattedInitialValue);
    if (readonlyMode) {
      form.disable();
    }
    // this.updateVisibleGroups(formattedInitialValue);
    this.updateFormVisibility(formattedInitialValue);
    return form;
  }

  addFormControlToForm(props: {
    form: FormGroup;
    formInputControl: IObjectFormInputControl;
    initialValue: any;
    property: IObjectProperty;
    readonlyMode: boolean;
  }) {
    const {form, formInputControl, initialValue, property, readonlyMode} =
      props;

    const formControl = this.formBuilder.control(
      this.getEmptyValueForProperty(property),
    );

    const isControlVisible = this.isControlVisible({
      formInputControl,
      formData: initialValue,
    });
    const isControlReadonly =
      readonlyMode ||
      this.isControlReadonly({
        formInputControl,
        formData: initialValue,
      });

    if (isControlVisible && !isControlReadonly) {
      // add validations;
      formControl.enable({emitEvent: false});
      formControl.addValidators(
        this.resolveControlValidators({
          formInputControl,
          formData: initialValue,
        }),
      );
    } else {
      formControl.disable({emitEvent: false});
      formControl.addValidators([]);
    }

    form.addControl(property.name, formControl);
  }

  addAddressFromControlToForm(props: {
    form: FormGroup;
    property: IObjectProperty;
  }) {
    const {form, property} = props;
    const addressPropertyWithData = this.propertiesById.get(
      property.navigationalPropertyId,
    );

    const group = this.formBuilder.group({
      id: this.formBuilder.control(null),
      line1: this.formBuilder.control(null),
      line2: this.formBuilder.control(null),
      zipCode: this.formBuilder.control(null),
      city: this.formBuilder.control(null),
      region: this.formBuilder.control(null),
      state: this.formBuilder.control(null),
      countryId: this.formBuilder.control(null),
    });

    form.addControl(addressPropertyWithData.name, group);
  }

  updateFormAfterValueChange(form: FormGroup) {
    const formData = form.getRawValue();
    this.properties.forEach(property => {
      const formInputControl = this.formControlsByPropertyName.get(
        property.name,
      );
      // if control is readonly or not visible then ignore validations
      if (formInputControl) {
        let formControlName = property.name;
        if (
          formInputControl.inputType === ObjectFormInputControlTypeEnum.Address
        ) {
          const addressProperty = this.propertiesById.get(
            property.navigationalPropertyId,
          );
          formControlName = addressProperty.name;
        }

        const formControl = form.get(formControlName);
        const isControlVisible = this.isControlVisible({
          formInputControl,
          formData,
        });

        const isControlReadonly = this.isControlReadonly({
          formInputControl,
          formData,
        });

        if (isControlVisible && !isControlReadonly) {
          formControl.enable({emitEvent: false});
          formControl.addValidators(
            this.resolveControlValidators({formInputControl, formData}),
          );
        } else {
          formControl.disable({emitEvent: false});
          formControl.addValidators([]);
        }
      }
    });

    this.updateFormVisibility(formData);
  }

  formatInitialData(data: {
    id: string;
    extendedProperties: {[k: string]: any};
  }) {
    let flattenedData = {
      ...data,
    };

    if (
      !!data.extendedProperties &&
      !!Object.keys(data.extendedProperties).length
    ) {
      flattenedData = {
        ...flattenedData,
        ...data.extendedProperties,
      };
    }

    delete flattenedData.extendedProperties;

    return flattenedData;
  }

  private getEmptyValueForProperty(property: IObjectProperty) {
    switch (property.valueType) {
      case ValueType.Boolean:
        return false;
      case ValueType.PlainTextList:
        return [];
      default:
        return null;
    }
  }

  private getInputFormControls() {
    const inputFormControls: IObjectFormInputControl[] = [];
    this.formForUser.formSchema.rows.forEach(row => {
      row.columns.forEach(columns => {
        columns.group.controlColumns.forEach(controlColumn => {
          inputFormControls.push(...controlColumn.inputControls);
        });
      });
    });
    return inputFormControls;
  }

  formatFormDataBeforeSave(formData: {[k: string]: any}) {
    const formattedData = {};
    const extendedProperties = {};
    const inputFormControls = this.getInputFormControls();

    const addPropertyToCorrectGroup = (propertyId: string) => {
      const property = this.propertiesById.get(propertyId);
      if (property.native) {
        formattedData[property.name] = formData[property.name];
      } else {
        extendedProperties[property.name] = formData[property.name];
      }
    };

    inputFormControls.forEach(inputControl => {
      switch (inputControl.inputType) {
        case ObjectFormInputControlTypeEnum.Location:
          const locationSettings =
            inputControl.settings as IObjectFormLocationControlSetting;
          addPropertyToCorrectGroup(locationSettings.longitudePropertyId);
          addPropertyToCorrectGroup(locationSettings.latitudePropertyId);

          if (locationSettings.altitudePropertyId) {
            addPropertyToCorrectGroup(locationSettings.altitudePropertyId);
          }
          break;
        case ObjectFormInputControlTypeEnum.Address:
          const addressProperty = this.propertiesById.get(
            inputControl.propertyId,
          );
          const addressNavigationalProperty = this.propertiesById.get(
            addressProperty.navigationalPropertyId,
          );
          const value = formData[addressNavigationalProperty.name];
          if (value.countryId) {
            formattedData[addressNavigationalProperty.name] = value;
          }
        default:
          addPropertyToCorrectGroup(inputControl.propertyId);
          break;
      }
    });
    this.properties.forEach(property => {
      // for adding id uid and version
      if (property.native && property.systemRequired && property.readonly) {
        formattedData[property.name] = formData[property.name];
      }
    });

    return {
      ...formattedData,
      extendedProperties,
    };
  }

  private resolveControlValidators(input: {
    formInputControl: IObjectFormInputControl;
    formData: {[k: string]: any};
  }) {
    const {formInputControl, formData} = input;
    const validators = [];

    if (formInputControl?.validationExpression) {
      validators.push(
        CustomValidators.validationExpression(
          formInputControl.validationExpression,
        ),
      );
    }

    if (this.isControlRequired({formInputControl, formData})) {
      validators.push(CustomValidators.required);
    }

    return validators;
  }

  private isGroupVisible(input: {
    group: IObjectFormGroup;
    formData: {[k: string]: any};
  }) {
    const {group, formData} = input;
    if (group.visibilityExpression && !!Object.keys(formData).length) {
      try {
        const groupVisible = evalExpression(
          parseExpression(group.visibilityExpression),
          {
            data: formData,
          },
        );
        return groupVisible;
      } catch (error) {
        console.warn(
          'VISIBILITY RESOLVER: There is a missing property in data.',
          error.message,
        );
      }
    }

    return true;
  }

  private isControlVisible(input: {
    formInputControl: IObjectFormInputControl;
    formData: {[k: string]: any};
  }) {
    const {formInputControl, formData} = input;
    if (
      formInputControl.visibilityExpression &&
      !!Object.keys(formData).length
    ) {
      try {
        const controlVisible = evalExpression(
          parseExpression(formInputControl.visibilityExpression),
          {
            data: formData,
          },
        );
        return controlVisible;
      } catch (error) {
        console.warn(
          'VISIBILITY RESOLVER: There is a missing property in data.',
          error.message,
        );
      }
    }

    return true;
  }

  private isControlReadonly(input: {
    formInputControl: IObjectFormInputControl;
    formData: {[k: string]: any};
  }) {
    const {formInputControl, formData} = input;
    const property = this.propertiesById.get(formInputControl.propertyId);

    if (property && property.readonly) {
      return true;
    }

    if (formInputControl.readonlyExpression && !!Object.keys(formData).length) {
      try {
        const controlReadonly = evalExpression(
          parseExpression(formInputControl.readonlyExpression),
          {
            data: formData,
          },
        );
        return controlReadonly;
      } catch (error) {
        console.warn(
          'VISIBILITY RESOLVER: There is a missing property in data.',
          error.message,
        );
      }
    }

    return false;
  }

  private isControlRequired(input: {
    formInputControl: IObjectFormInputControl;
    formData: {[k: string]: any};
  }) {
    const {formInputControl, formData} = input;

    const property = this.propertiesById.get(formInputControl.propertyId);

    if (property && property.systemRequired) {
      return true;
    }

    if (formInputControl.requiredExpression) {
      try {
        const controlRequired = evalExpression(
          parseExpression(formInputControl.requiredExpression),
          {data: formData},
        );
        return controlRequired;
      } catch (error) {
        console.warn(
          'REQUIRED RESOLVER: There is a missing property in data.',
          error.message,
        );
      }
    }

    return false;
  }

  private getFormData() {
    const formControlsMap = new Map();
    const formControls = [];
    const groups = [];
    const addControlToLists = (
      inputControl: IObjectFormInputControl,
      propertyId: string,
    ) => {
      const propertyData = this.propertiesById.get(propertyId);
      formControlsMap.set(propertyData.name, inputControl);
      formControls.push(inputControl);
    };
    this.formForUser.formSchema.rows.forEach(row => {
      row.columns.forEach(column => {
        groups.push(column.group);
        column.group.controlColumns.forEach(controlColumn => {
          controlColumn.inputControls.forEach(inputControl => {
            switch (inputControl.inputType) {
              case ObjectFormInputControlTypeEnum.Location:
                const settings =
                  inputControl.settings as IObjectFormLocationControlSetting;
                if (settings.altitudePropertyId) {
                  addControlToLists(inputControl, settings.altitudePropertyId);
                }
                addControlToLists(inputControl, settings.longitudePropertyId);
                addControlToLists(inputControl, settings.latitudePropertyId);
                break;

              default:
                addControlToLists(inputControl, inputControl.propertyId);
                break;
            }
          });
        });
      });
    });

    return {formControlsMap, formControls, groups};
  }

  private updateFormVisibility(formData: {[k: string]: any}) {
    const visibleGroupIds = [];
    const visibleFormControlIds = [];
    this.groups.forEach(group => {
      if (this.isGroupVisible({group, formData})) {
        visibleGroupIds.push(group.id);

        group.controlColumns.forEach(controlColumn => {
          controlColumn.inputControls.forEach(inputControl => {
            if (
              this.isControlVisible({formInputControl: inputControl, formData})
            ) {
              visibleFormControlIds.push(inputControl.id);
            }
          });
        });
      }
    });

    this.visibleGroupIds$.next(visibleGroupIds);
    this.visibleFormInputControlIds$.next(visibleFormControlIds);
  }
}

const SYSTEM_DISABLED: string[] = ['uid', 'id', 'version'];
