import {Injectable} from '@angular/core';
import {
  OldBaseStorageService,
  StorageDataStateEnum,
} from './old-base-storage-service';
import {NewsPost} from '@salesapp/shared/models';
import {DataProvider} from '@salesapp/data-provider';

@Injectable()
export class NewsPostStorageService extends OldBaseStorageService<
  NewsPost,
  NewsPost[]
> {
  constructor(private dataProvider: DataProvider) {
    super(dataProvider.newsPost);
  }

  deleteMultiple(input: {id: string; version?: number}[]) {
    Promise.all(
      input.map(post => {
        return this.dataProvider.newsPost.delete(post.id, post.version);
      }),
    ).then(response => {
      this.updateDataState(storage => ({
        state: StorageDataStateEnum.Obsoleted,
      }));
    });
  }
}
