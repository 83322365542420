import {Injectable} from '@angular/core';
import {Language} from 'src/app/shared/models/language.model';
import {ILanguageDataService} from '../../interfaces/data-service';
import {BaseReadonlyOfflineService} from './base.offline-service';

@Injectable()
export class LanguageOfflineService
  extends BaseReadonlyOfflineService<Language>
  implements ILanguageDataService
{
  public getEnabled(): Promise<Language[]> {
    return Promise.reject('Offline service - not yet implemented');
  }
}
