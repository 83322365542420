import {AnyObject} from '@loopback/filter/dist/types';
import {OldBaseStorageService} from './old-base-storage-service';
import {CurrencyDataService} from '../data';
import {DataProvider} from '../../data.provider/data-provider';
import {Filter} from '@loopback/filter';
import {from, Observable} from 'rxjs';
import {ICurrency} from '@retrixhouse/salesapp-shared/lib/models';
import {Injectable} from '@angular/core';

@Injectable()
export class CurrencyStorageService extends OldBaseStorageService<
  ICurrency,
  ICurrency[]
> {
  constructor(public dataProvider: DataProvider) {
    super(dataProvider.currency);
  }

  getData(input?: {filter?: Filter<AnyObject>}): Observable<ICurrency[]> {
    return from((this.dataService as CurrencyDataService).getEnabled());
  }

  getDefaultCurrency() {
    return from((this.dataService as CurrencyDataService).getDefault());
  }
}
