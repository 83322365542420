import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseCrudHttpService} from './base.http-service';
import {Holiday} from '../../models';
import {IHolidayDataService} from '../../interfaces/data-service';

@Injectable()
export class HolidayHttpService
  extends BaseCrudHttpService<Holiday>
  implements IHolidayDataService
{
  constructor(http: HttpClient) {
    super(http, '/sys/holiday');
  }

  public weekendOrHoliday(date: Date): Promise<boolean> {
    return this.GET<boolean>(`/sys/holiday/weekend-or-holiday`, {
      params: {date: date},
    });
  }
}
