import {Injectable} from '@angular/core';
import {IBHSAggregatedDataService} from '../../interfaces/data-service';
import {BaseOfflineService} from './base.offline-service';

@Injectable()
export class BHSAggregatedDataOfflineService
  extends BaseOfflineService
  implements IBHSAggregatedDataService
{
  public getPersonalArrangements(
    userId: string,
    from?: Date,
    to?: Date,
  ): Promise<any> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public getPromoActions(userId: string, from?: Date, to?: Date): Promise<any> {
    return Promise.reject('Offline service - not yet implemented');
  }
}
