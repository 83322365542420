<app-breadcrumb [readOnly]="true"></app-breadcrumb>

<div class='content-block-card responsive-paddings' id='data-transfer-wrapper'>

  <ng-container *ngIf='connectionSpeedChecked'>
    <div *ngIf='transferDirection && transferDirection === transferDirectionEnum.DOWNLOAD'>
      <app-data-transfer-download></app-data-transfer-download>
    </div>

    <div *ngIf='transferDirection && transferDirection === transferDirectionEnum.UPLOAD'>
      <app-data-transfer-upload></app-data-transfer-upload>
    </div>
  </ng-container>
</div>
