import {DialogModule as AngularDialogModule} from '@angular/cdk/dialog';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {LoadingContainerModule} from '@salesapp/shared/components/loading-container';
import {
  DxActionSheetModule,
  DxButtonModule,
  DxDropDownButtonModule,
} from 'devextreme-angular';
import {FormModule} from '../form';
import {BaseDialogComponent} from './components/base-dialog/base-dialog.component';
import {DialogComponent} from './components/dialog/dialog.component';
import {ObjectDetailDialogFormComponent} from './components/object-detail-dialog/object-detail-dialog-form/object-detail-dialog-form.component';
import {ObjectDetailDialogComponent} from './components/object-detail-dialog/object-detail-dialog.component';
import {DialogService} from './services/dialog.service';

const DIALOG_COMPONENTS = [
  DialogComponent,
  BaseDialogComponent,
  ObjectDetailDialogComponent,
  ObjectDetailDialogFormComponent,
];
const UI_MODULES = [LoadingContainerModule];
const SALESAPP_MODULES = [FormModule];
const DX_MODULES = [
  DxButtonModule,
  DxActionSheetModule,
  DxDropDownButtonModule,
];

@NgModule({
  imports: [
    CommonModule,
    AngularDialogModule,
    TranslateModule,
    ...SALESAPP_MODULES,
    ...DX_MODULES,
    ...UI_MODULES,
  ],
  exports: [...DIALOG_COMPONENTS],
  providers: [DialogService],
  declarations: [...DIALOG_COMPONENTS],
})
export class DialogModule {}

export * from './components/dialog/dialog.component';
export * from './components/object-detail-dialog/object-detail-dialog-form/object-detail-dialog-form.component';
export * from './components/object-detail-dialog/object-detail-dialog.component';
export * from './services/dialog.service';
