import {DIALOG_DATA, DialogRef} from '@angular/cdk/dialog';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {ObjectTypeNames} from '@retrixhouse/salesapp-shared/lib/common';
import {IProductListing} from '@retrixhouse/salesapp-shared/lib/models';
import {IProductListingCopy} from '@retrixhouse/salesapp-shared/lib/requests';
import {IProcessingProgress} from '@retrixhouse/salesapp-shared/lib/responses';
import {NotificationService, SharedFormService} from '@salesapp/services';
import {ProductListing} from '@salesapp/shared/models';
import {ProductListingStorageService} from '@salesapp/storage';
import {CustomValidators, FormControlsOf} from '@salesapp/utils/reactive-form';
import {DxProgressBarComponent} from 'devextreme-angular';
import * as moment from 'moment';
import {BehaviorSubject} from 'rxjs';
import {filter, take} from 'rxjs/operators';
import {v4 as uuid} from 'uuid';

@Component({
  selector: 'app-product-listing-copy-dialog',
  templateUrl: './product-listing-copy-dialog.component.html',
  styleUrls: ['./product-listing-copy-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductListingCopyDialogComponent implements OnInit {
  form: FormGroup<ProductListingFormGroup>;

  copyProgress$ = new BehaviorSubject<IProcessingProgress>({
    totalRows: 0,
    processedRows: 0,
  });
  copyActionEnabled$ = new BehaviorSubject<boolean>(false);
  copyProgressInterval;

  @ViewChild(DxProgressBarComponent, {static: false})
  progressBar: DxProgressBarComponent;

  minimumDate: Date = new Date();

  constructor(
    private dialogRef: DialogRef<ProductListingDialogData>,
    @Inject(DIALOG_DATA) private data: ProductListingDialogData,
    private formBuilder: FormBuilder,
    private productListingStorageService: ProductListingStorageService,
    private sharedFormService: SharedFormService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.checkAvailability();
  }

  private initForm() {
    this.sharedFormService
      .getNextUidForObject(ObjectTypeNames.ProductListing)
      .pipe(
        filter(uid => !!uid),
        take(1),
      )
      .subscribe(uidResponse => this.form.patchValue({uid: uidResponse.uid}));

    this.form = this.formBuilder.group<ProductListingFormGroup>({
      id: this.formBuilder.control(uuid()),
      uid: this.formBuilder.control({value: null, disabled: true}),
      name: this.formBuilder.control(null, [CustomValidators.required]),
      validFrom: this.formBuilder.control(null, [CustomValidators.required]),
      validTo: this.formBuilder.control(null),
      projectId: this.formBuilder.control(null),
      projectName: this.formBuilder.control({value: null, disabled: true}),
    });

    this.productListingStorageService.dataById$
      .pipe(filter(data => data.size !== 0))
      .subscribe(listings => {
        this.updateFormData(listings.get(this.data.originalListingId));
        this.form.controls.validFrom.addValidators(
          CustomValidators.validateDateMinimum(
            listings.get(this.data.originalListingId).validTo ?? new Date(),
          ),
        );
      });

    this.copyProgress$.subscribe(data => this.checkCopyProgress(data));
  }

  private updateFormData(data: IProductListing) {
    let validFrom = new Date(data.validTo ?? '');

    if (data.validTo && moment(data.validTo).diff(new Date(), 'days') > 0) {
      validFrom.setDate(validFrom.getDate() + 1);
    } else {
      validFrom = new Date();
    }
    this.minimumDate = validFrom;

    const formData = {
      ...data,
      validFrom: validFrom,
      validTo: null,
      name: `${data.name} - copy`,
    };

    this.form.patchValue(formData);
  }

  getFormControl(name: keyof ProductListingFormGroup) {
    return this.form.get(name) as FormControl;
  }

  onClose() {
    this.dialogRef.close();
  }

  onCopy() {
    const rx = this.createRequest();
    this.productListingStorageService.copyListing(rx).catch(error => {
      clearInterval(this.copyProgressInterval);
      NotificationService.notifyError(
        this.translateService.instant(
          'views.product-listing.product-listing-copy-error',
        ),
      );
      this.dialogRef.close();
    });

    this.form.disable();

    this.copyActionEnabled$.next(false);
    this.progressBar.visible = true;
    this.createCheckInterval(rx.operationId);
  }

  private createCheckInterval(operationId: string) {
    this.copyProgressInterval = setInterval(
      operationId => this.getCopyProgress(operationId),
      2000,
      operationId,
    );
  }

  private createRequest(): IProductListingCopy {
    let request: IProductListingCopy = {
      originalListingId: this.data.originalListingId,
      operationId: uuid(),
      name: this.getFormControl('name').value,
      from: moment(this.getFormControl('validFrom').value)
        .startOf('day')
        .toDate(),
    };
    if (this.getFormControl('validTo').value) {
      request.to = moment(this.getFormControl('validTo').value)
        .endOf('day')
        .subtract(1, 'second')
        .toDate();
    }
    return request;
  }

  private async getCopyProgress(operationId: string) {
    this.productListingStorageService
      .getCopyProgress(operationId)
      .subscribe(data => this.copyProgress$.next(data));
  }

  private checkCopyProgress(data: IProcessingProgress) {
    if (data.totalRows <= data.processedRows && data.processedRows > 0) {
      clearInterval(this.copyProgressInterval);

      this.form.markAsPristine();
      this.form.enable();

      this.progressBar.visible = false;
      this.copyActionEnabled$.next(true);
      this.copyProgress$.next({
        totalRows: 0,
        processedRows: -1,
      });

      NotificationService.notifySuccess(
        this.translateService.instant(
          'views.product-listing.product-listing-copy-success',
        ),
      );

      this.dialogRef.close();
    }
  }

  private checkAvailability() {
    this.productListingStorageService.getCopyAvailability().subscribe(data => {
      const length = data.length;
      if (length !== 0 && data[length - 1].operationId) {
        this.form.disable();
        this.progressBar.visible = true;
        this.createCheckInterval(data[length - 1].operationId);
      } else {
        this.copyActionEnabled$.next(true);
      }
    });
  }
}

interface ProductListingValue
  extends Omit<ProductListing, 'version' | 'project' | 'customer'> {
  projectName: string;
}

export interface ProductListingDialogData {
  originalListingId?: string;
}

type ProductListingFormGroup = FormControlsOf<ProductListingValue>;
