<ng-container *ngIf="formGroup">

  <app-form-row>
    <app-form-column>
      <app-input-multiselect [formControl]="getFormControl('readPositionIds')"
                             [formControlRef]="getFormControl('readPositionIds')"
                             [label]="'entity.object-permissions.properties.read-position-ids.label' | translate"
                             [options]="positionOptions$ | async"></app-input-multiselect>
    </app-form-column>
  </app-form-row>
  <app-form-row>
    <app-form-column>
      <app-input-multiselect [formControl]="getFormControl('readUserIds')"
                             [formControlRef]="getFormControl('readUserIds')"
                             [label]="'entity.object-permissions.properties.read-user-ids.label' | translate"
                             [options]="userOptions$ | async"></app-input-multiselect>
    </app-form-column>
  </app-form-row>
  <app-form-row>
    <app-form-column>
      <app-input-multiselect [formControl]="getFormControl('updatePositionIds')"
                             [formControlRef]="getFormControl('updatePositionIds')"
                             [label]="'entity.object-permissions.properties.update-position-ids.label' | translate"
                             [options]="positionOptions$ | async"></app-input-multiselect>
    </app-form-column>
  </app-form-row>
  <app-form-row>
    <app-form-column>
      <app-input-multiselect [formControl]="getFormControl('updateUserIds')"
                             [formControlRef]="getFormControl('updateUserIds')"
                             [label]="'entity.object-permissions.properties.update-user-ids.label' | translate"
                             [options]="userOptions$ | async"></app-input-multiselect>
    </app-form-column>
  </app-form-row>
  <app-form-row>
    <app-form-column>
      <app-input-multiselect [formControl]="getFormControl('deletePositionIds')"
                             [formControlRef]="getFormControl('deletePositionIds')"
                             [label]="'entity.object-permissions.properties.delete-position-ids.label' | translate"
                             [options]="positionOptions$ | async"></app-input-multiselect>
    </app-form-column>
  </app-form-row>
  <app-form-row>
    <app-form-column>
      <app-input-multiselect [formControl]="getFormControl('deleteUserIds')"
                             [formControlRef]="getFormControl('deleteUserIds')"
                             [label]="'entity.object-permissions.properties.delete-user-ids.label' | translate"
                             [options]="userOptions$ | async"></app-input-multiselect>
    </app-form-column>
  </app-form-row>

</ng-container>