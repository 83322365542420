import {ImexColumn} from '../imex-column';
import {ImexErrorRow} from './imex-error-row.response';

export class ImexFileValidationResponse {
  success: boolean;
  columns: ImexColumn[];
  importId: string;
  numberOfRowsToImport: number;
  imexErrorRows: ImexErrorRow[];
}
