import {IUserTaskDataService} from '../../interfaces/data-service';
import {UserTask} from '../../models';
import {UserTaskHttpService} from '../http';
import {UserTaskOfflineService} from '../offline';
import {BaseCrudDataService} from './base.data-service';

export class UserTaskDataService extends BaseCrudDataService<
  UserTask,
  IUserTaskDataService
> {
  constructor(
    onlineService: UserTaskHttpService,
    offlineService: UserTaskOfflineService,
  ) {
    super(onlineService, offlineService);
  }
}
