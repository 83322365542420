import {Component, OnInit, ViewChild} from '@angular/core';
import {AppInterfaceService} from '../../../../shared/app-interface/app-interface.service';
import {DxFormComponent} from 'devextreme-angular';
import {AppInfoService} from '../../../../shared/services';
import {Router} from '@angular/router';
import {generateRandomString} from '../../../../shared/globals';
import {FileUtils} from '@retrixhouse/salesapp-shared/lib/utils';

@Component({
  selector: 'app-interface-test',
  templateUrl: 'app-interface.test.component.html',
  styleUrls: ['app-interface.test.component.scss'],
})
export class AppInterfaceTestComponent implements OnInit {
  @ViewChild(DxFormComponent, {static: false})
  form: DxFormComponent;
  formData: {cycleCount: number; chunkSize: string; dataSize: string};

  storageKeySource = ['gotFather', 'starWars', 'marvel'];
  logs: {
    timestamp: Date;
    level: 'info' | 'success' | 'error';
    message: string;
  }[] = [];
  startTestBtnDisabled: boolean;

  ngOnInit(): void {
    this.formData = {cycleCount: 1, chunkSize: '1 MB', dataSize: '5 MB'};
    this.startTestBtnDisabled = false;
  }

  constructor(
    private appInterfaceService: AppInterfaceService,
    private appInfoService: AppInfoService,
    private router: Router,
  ) {
    if (!appInfoService.isMobileVersion) {
      router.navigate(['/']);
    }
  }

  async btnStartTestClick(type: 'simple' | 'performance'): Promise<void> {
    if (type === 'performance') {
      const validateResult = this.form.instance.validate();
      if (!validateResult.isValid) {
        this.addLog(
          'Missing or invalid mandatory parameters for performance test',
          'error',
        );
        return;
      }

      const chunkSize = FileUtils.humanReadableFileSizeToBytes(
        this.formData.chunkSize,
      );
      if (!chunkSize) {
        this.addLog('Invalid value for chunk size', 'error');
        return;
      }

      const dataSize = FileUtils.humanReadableFileSizeToBytes(
        this.formData.dataSize,
      );
      if (!dataSize) {
        this.addLog('Invalid value for data size', 'error');
        return;
      }
    }

    this.startTestBtnDisabled = true;
    this.addLog('------------------------');
    this.addLog(`Test started, type: ${type}`);
    try {
      if (type === 'performance') {
        await this.performanceTest();
      } else {
        await this.simpleTest();
      }
      this.addLog('Test finished', 'success');
    } catch (error) {
      this.addLog('Error performing test', 'error');
      this.addLog(error, 'error');
      this.addLog('Test finished', 'error');
    }
    this.addLog('------------------------');

    this.startTestBtnDisabled = false;
  }

  async simpleTest(): Promise<void> {
    const key =
      this.storageKeySource[
        Math.floor(Math.random() * this.storageKeySource.length)
      ];
    const value = generateRandomString(1024, true, true);

    this.addLog(`Generated random key: ${key}`);
    this.addLog(`Generated random value with length: ${value.length}`);

    // storageSave
    await this.appInterfaceService.storageSave({[key]: value});
    this.addLog(
      `Using 'storageSave' to store under key: ${key} data with length ${value.length}`,
    );

    // storageLoad
    this.addLog(
      `Using 'storageLoad' with offset=undefined and length=undefined to get content under key ${key}`,
    );
    const loadRes = await this.appInterfaceService.storageLoad(key);
    const loadVal = loadRes ? loadRes[key] : null;
    const loadLength = loadVal ? loadVal.length : 0;
    this.addLog(
      `Value under key ${key} has size: ${loadLength}`,
      loadLength === 1024 ? 'success' : 'error',
    );

    // storageKeys
    await this.storageKeys(false);

    // storageDelete
    this.addLog(`Using 'storageDelete' to delete value under key ${key}`);
    await this.appInterfaceService.storageDelete(key);

    // re-introduce value for further testing
    this.addLog('Putting random generated keypair back for further testing ');
    await this.appInterfaceService.storageSave({[key]: value});

    // storageKeysSize
    await this.storageKeysSize();

    // storageClear
    await this.storageClear();

    // storageKeys
    await this.storageKeys(true);
  }

  async storageKeysSize(): Promise<void> {
    this.addLog(`Using 'storageKeysSize' to get size of values under keys`);
    const storageKeysSize = await this.appInterfaceService.storageKeysSize(
      this.storageKeySource,
    );
    this.addLog('Storage key sizes: ' + JSON.stringify(storageKeysSize));
  }

  async storageKeys(shouldBeEmpty: boolean): Promise<void> {
    this.addLog(`Using 'storageKeys' to get list of persisted keys`);
    const storageKeys = await this.appInterfaceService.storageKeys();
    this.addLog(
      `Number of existing keys: ${storageKeys.length}`,
      (shouldBeEmpty && storageKeys.length === 0) ||
        (!shouldBeEmpty && storageKeys.length > 0)
        ? 'success'
        : 'error',
    );
  }

  async storageClear(): Promise<void> {
    this.addLog(`Using 'storageClear' to clear content of storage`);
    await this.appInterfaceService.storageClear();
  }

  async performanceTest(): Promise<void> {
    const chunkSize = FileUtils.humanReadableFileSizeToBytes(
      this.formData.chunkSize,
    );
    const dataSize = FileUtils.humanReadableFileSizeToBytes(
      this.formData.dataSize,
    );
    const chunkCount =
      Math.floor(dataSize / chunkSize) + (dataSize % chunkSize !== 0 ? 1 : 0);
    const iterations = this.formData.cycleCount;

    for (let i = 0; i < iterations; i++) {
      this.addLog(`Iteration ${i + 1} of ${iterations}`);
      const key =
        this.storageKeySource[
          Math.floor(Math.random() * this.storageKeySource.length)
        ];
      const value = generateRandomString(dataSize, true, true);

      this.addLog(`Generated random key: ${key}`);
      this.addLog(`Generated random value with length: ${value.length}`);

      let substrStart = 0;
      let substrEnd = chunkSize;
      let substr = value.substring(substrStart, substrEnd);

      for (let j = 0; j < chunkCount; j++) {
        this.addLog(
          `Using 'storageSaveAppend' to append chunk of data ${
            substr.length
          } to key ${key} (${j + 1}/${chunkCount})`,
        );
        await this.appInterfaceService.storageSaveAppend({[key]: substr});
        substrStart += chunkSize;
        substrEnd += chunkSize;
        substr = value.substring(substrStart, substrEnd);
      }

      let offset = 0;
      let loadedData: string;
      const loadedValues = [];
      for (let j = 0; j < chunkCount; j++) {
        this.addLog(
          `Using 'storageLoad' with offset=${offset} and length=${chunkSize} to get content under key ${key}(${
            j + 1
          }/${chunkCount})`,
        );
        const loadRes = await this.appInterfaceService.storageLoad(
          key,
          offset,
          chunkSize,
        );
        loadedData = loadRes[key];
        if (loadedData && loadedData.trim().length > 0) {
          loadedValues.push(loadedData);
        }
        offset += chunkSize;
      }
      const loadedValue = loadedValues.join('');

      this.addLog(
        `Comparing generatedLength: ${value.length}  with loadedLength: ${loadedValue.length}`,
        value.length === loadedValue.length ? 'success' : 'error',
      );
      this.addLog(
        `Comparing generatedValue and loadedValue: ${value === loadedValue}`,
        value === loadedValue ? 'success' : 'error',
      );

      // storageDelete
      this.addLog(`Using 'storageDelete' to delete value under key ${key}`);
      await this.appInterfaceService.storageDelete(key);
    }

    // storageClear
    await this.storageClear();

    // storageKeys
    await this.storageKeys(true);
  }

  addLog(message: string, level: 'info' | 'success' | 'error' = 'info'): void {
    this.logs.push({
      timestamp: new Date(),
      level,
      message,
    });
  }

  clearLogs(): void {
    this.logs = [];
  }
}
