import {Request} from './request-model';

export class DeferredPromise {
  request: Request;
  promise: Promise<any>;
  resolve: (value: any) => void;
  reject: (reason?: any) => void;

  constructor(request: Request) {
    this.request = request;
    this.promise = new Promise((resolve, reject) => {
      this.reject = reject;
      this.resolve = resolve;
    });
  }
}
