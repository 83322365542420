import {HttpClient, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {UploadPhotoResponse} from '@retrixhouse/salesapp-shared/lib/responses/upload-photo';
import {IPhotoObjectDataService} from '../../interfaces/data-service';
import {
  ExportPhotosAsZipRequest,
  Photo,
  PhotoCreateRequest,
  PhotoTag,
  RotatePhotoRequest,
  SendPhotosByEmailRequest,
} from '../../models';
import {BaseCrudHttpService} from './base.http-service';

@Injectable()
export class PhotoObjectHttpService
  extends BaseCrudHttpService<Photo>
  implements IPhotoObjectDataService
{
  constructor(http: HttpClient) {
    super(http, '/photo/photo-object');
  }

  public getPhotoListByObjectId(objectId: string): Promise<Photo[]> {
    return this.GET<Photo[]>(`/photo/photo-object/${objectId}`);
  }

  public getPhotoListForObjectIdList(objectIds: string[]): Promise<Photo[]> {
    return this.POST<Photo[]>('/photo/photo-object/find-for-object', objectIds);
  }

  public deletePhoto(photoId: string): Promise<void> {
    return this.DELETE(`/photo/photo-object/${photoId}`);
  }

  public deletePhotos(photoIds: string[]): Promise<void> {
    return this.DELETE(`/photo/photo-object/`, {body: photoIds});
  }

  public createPhoto(photo: PhotoCreateRequest): Promise<void> {
    return this.POST(`/photo/photo-object/create`, photo);
  }

  public rotatePhoto(payload: RotatePhotoRequest): Promise<void> {
    return this.POST(`/photo/photo-object/rotate/`, payload);
  }

  public addPhotoTags(photoId: string, tagIdList: string): Promise<void> {
    return this.POST(
      `/photo/photo-object/tags/${photoId}?tagIdList=${tagIdList}`,
      {},
    );
  }

  public getPhotoTags(objectId: string): Promise<PhotoTag[]> {
    return this.GET(`/photo/photo-object/tags/${objectId}`);
  }

  public exportAsPdf(
    photoIdList: string[],
    tempUuid?: string,
  ): Promise<HttpResponse<Blob>> {
    return this.POST(`/photo/photo-object/export-as-pdf`, photoIdList, {
      params: {tempUuid: tempUuid},
      observe: 'response',
      responseType: 'blob' as 'json',
    });
  }

  public exportAsZip(
    payload: ExportPhotosAsZipRequest,
    tempUuid?: string,
  ): Promise<HttpResponse<Blob>> {
    return this.POST(`/photo/photo-object/export-as-zip`, payload, {
      params: {tempUuid: tempUuid},
      observe: 'response',
      responseType: 'blob' as 'json',
    });
  }

  public sendByEmail(payload: SendPhotosByEmailRequest): Promise<void> {
    return this.POST('/photo/photo-object/send-by-email', payload);
  }

  public downloadPhoto(
    photoId: string,
    thumbnail?: boolean,
  ): Promise<HttpResponse<Blob>> {
    return this.GET(`photo/photo-object/download-photo`, {
      params: {photoId, thumbnail: !!thumbnail},
      observe: 'response',
      responseType: 'blob' as 'json',
    });
  }

  public uploadVisitPhoto(
    form: FormData,
    visitId: string,
    objectId: string,
  ): Promise<UploadPhotoResponse> {
    return this.POST(
      `/photo/photo-object/upload-photo/${visitId}/${objectId}`,
      form,
    );
  }
}
