import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {sortByKey} from '@salesapp/shared/globals';
import {TSimpleChanges} from '@salesapp/utils/angular.utils';

@Component({
  selector: 'app-grid-filter',
  templateUrl: './grid-filter.component.html',
  styleUrls: ['./grid-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridFilterComponent {
  @Input() formattedFilter: GridFilterDataFormatted[];
  @Input() collapsed: boolean;

  @Output() removeFilterValue = new EventEmitter<string>();
  @Output() expandFilter = new EventEmitter<void>();

  dataSorted: GridFilterDataFormatted[] = [];

  ngOnChanges(changes: TSimpleChanges<GridFilterComponent>): void {
    if (!!changes.formattedFilter?.currentValue) {
      this.sortData(this.formattedFilter);
    }
  }

  private sortData(formattedFilter: GridFilterDataFormatted[]) {
    this.dataSorted = sortByKey(formattedFilter, 'position');
  }
}

export interface GridFilterDataFormatted {
  label: string;
  value: string;
  key: string;
  position: number;
  required?: boolean;
}
