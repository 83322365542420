import {RoleNames} from '@retrixhouse/salesapp-shared/lib/common';
import {ObjectAttachment} from '@salesapp/api';
import {Action} from '../actions/actions';
import {ActionCustomContextResolver} from '../actions/actions.iface';

export class ObjectAttachmentActions {
  static ATTACHMENT_CREATE = new Action({
    id: 'attachmentCreate',
    icon: 'fa-solid fa-plus-circle',
    i18nIdentifier: 'entity.object-attachment.actions.create',
    permissions: {
      or: {
        roles: {
          any: [RoleNames.Admin, RoleNames.ObjectAttachmentsCreate],
        },
      },
    },
  });

  static ATTACHMENT_EDIT = new Action({
    id: 'attachmentEdit',
    icon: 'fa-solid fa-pen-to-square',
    i18nIdentifier: 'entity.object-attachment.actions.edit',
    permissions: {
      or: {
        roles: {any: [RoleNames.Admin]},
        customContextResolver: (
          ctx: ActionCustomContextResolver<ObjectAttachment>,
        ) => {
          const userId = ctx.currentUserUsername.id;
          const userPositionId = ctx.currentUserUsername.positionId;
          const updatePositionIds = ctx.data.permissions.updatePositionIds;
          const updateUserIds = ctx.data.permissions.updateUserIds;

          let available = false;

          if (
            updatePositionIds &&
            updatePositionIds.length &&
            updatePositionIds.includes(userPositionId)
          ) {
            available = true;
          }

          if (
            updateUserIds &&
            updateUserIds.length &&
            updateUserIds.includes(userId)
          ) {
            available = true;
          }

          return available;
        },
      },
    },
  });

  static ATTACHMENT_DELETE = new Action({
    id: 'attachmentDelete',
    icon: 'fa-solid fa-trash',
    i18nIdentifier: 'entity.object-attachment.actions.delete',
    permissions: {
      or: {
        roles: {any: [RoleNames.Admin]},
        customContextResolver: (
          ctx: ActionCustomContextResolver<ObjectAttachment>,
        ) => {
          const userId = ctx.currentUserUsername.id;
          const userPositionId = ctx.currentUserUsername.positionId;
          const deletePositionIds = ctx.data.permissions.deletePositionIds;
          const deleteUserIds = ctx.data.permissions.deleteUserIds;

          let available = false;

          if (
            deletePositionIds &&
            deletePositionIds.length &&
            deletePositionIds.includes(userPositionId)
          ) {
            available = true;
          }

          if (
            deleteUserIds &&
            deleteUserIds.length &&
            deleteUserIds.includes(userId)
          ) {
            available = true;
          }

          return available;
        },
      },
    },
  });

  static ATTACHMENT_DOWNLOAD = new Action({
    id: 'attachmentDownload',
    icon: 'fa-solid fa-download',
    i18nIdentifier: 'entity.object-attachment.actions.download',
    permissions: {
      or: {
        roles: {
          any: [RoleNames.Admin, RoleNames.ObjectAttachmentsView],
        },
      },
    },
  });

  static ATTACHMENT_GENERATE = new Action({
    id: 'attachmentGenerate',
    icon: 'fa-regular fa-file-contract',
    i18nIdentifier: 'entity.object-attachment.actions.generate',
    permissions: {
      or: {
        roles: {
          any: [
            RoleNames.Admin,
            RoleNames.AttachmentTemplatesGenerateAttachment,
          ],
        },
      },
    },
  });
}
