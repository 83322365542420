export class Position {
  id: string;
  parentId: string | null;
  customerId: string | null;
  name: string;
  abbreviation: string;
  menuItems?: string;
  appRoutes?: string;
}

export class UserPositionInProject {
  userId: string;
  projectId: string;
  alias: string;
  abbreviation: string;
}
