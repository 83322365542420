import {GenericListItem} from './generic-list.model';
import {Address} from './address.model';
import {Store} from './store.model';
import * as uuid from 'uuid';

export class Chain {
  id: string;
  uid: string;
  version: number;
  name: string;
  companyId?: string;
  vatId?: string;
  logo?: string;
  web?: string;
  legalForm?: GenericListItem;
  legalFormId?: string;
  address?: Address;
  addressId?: string;
  stores?: Store[];

  constructor() {
    this.id = uuid.v4();
    this.name = null;
    this.companyId = null;
    this.vatId = null;
    this.logo = null;
    this.web = null;
    this.legalFormId = null;
    this.addressId = null;
    this.stores = [];
  }
}
