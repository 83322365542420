import {AutoUnsubscribe, TSimpleChanges} from '../../utils/angular.utils';
import {CountryStorageService} from '../../services/storage/country-storage.service';
import {CustomValidators} from '../../utils/reactive-form/form-validators';
import {distinctUntilChanged} from 'rxjs/operators';
import {FormGroup, UntypedFormControl} from '@angular/forms';
import {Subscription} from 'rxjs';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';

@Component({
  selector: 'app-input-address',
  templateUrl: './input-address.component.html',
  styleUrls: ['./input-address.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@AutoUnsubscribe()
export class InputAddressComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Input() disabled: boolean;

  private formGroupChangeSubscription: Subscription;

  get options$() {
    return this.countryStorageService.dataAsSelectOptions$;
  }

  constructor(
    private countryStorageService: CountryStorageService,
    private changeDetector: ChangeDetectorRef,
  ) {}

  ngOnChanges(changes: TSimpleChanges<InputAddressComponent>): void {
    if (changes.disabled) {
      if (!!changes.disabled.currentValue) {
        this.formGroup.disable();
      } else {
        this.formGroup.enable();
      }
    }
  }

  ngOnInit(): void {
    this.requireCountry();
  }

  ngDoCheck() {
    // NOTE(milan): because of  change detection is not working here
    this.changeDetector.markForCheck();
  }

  getControl(name: string) {
    return this.formGroup.get(name) as UntypedFormControl;
  }

  private requireCountry() {
    this.formGroupChangeSubscription = this.formGroup.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe({
        next: data => {
          const values = Object.entries(data)
            .filter(([key, value]) => !['id', 'countryId'].includes(key))
            .map(([key, value]) => value)
            .filter(value => ![null, ''].includes(value as any));
          const control = this.getControl('countryId');
          if (values.length) {
            control.addValidators(CustomValidators.required);
            control.updateValueAndValidity({
              onlySelf: true,
            });
            this.changeDetector.detectChanges();
            this.changeDetector.markForCheck();
          } else {
            control.removeValidators(CustomValidators.required);
            this.formGroup.reset(this.formGroup.getRawValue(), {
              onlySelf: true,
              emitEvent: false,
            });
            control.updateValueAndValidity({onlySelf: true});
            this.changeDetector.detectChanges();
            this.changeDetector.markForCheck();
          }
        },
      });
  }
}
