import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {trackByIndex} from '@salesapp/utils/angular.utils';

@Component({
  selector: 'app-attachment-template-legend-list',
  templateUrl: './attachment-template-legend-list.component.html',
  styleUrls: ['./attachment-template-legend-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttachmentTemplateLegendListComponent {
  @Output() optionSelected = new EventEmitter<{name: string; path: string}>();

  contextOptions = [
    {
      name: this.translateService.instant(
        'entity.attachment-template.legend.list.context.subject',
      ),
      path: 'subject',
    },
    {
      name: this.translateService.instant(
        'entity.attachment-template.legend.list.context.current-user-profile',
      ),
      path: 'currentUserProfile',
    },
  ];

  trackByIndex = trackByIndex;

  constructor(private translateService: TranslateService) {}
}
