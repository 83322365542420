import {OrderType} from '../../globals';

export class ReportRangeRequest {
  projectIds?: string[];
  from?: Date;
  to?: Date;
  limit?: number;
}

export class OrderReportRequest extends ReportRangeRequest {
  type?: OrderType;
}
