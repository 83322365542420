import {CommonModule} from '@angular/common';
import {DialogModule} from '../../modules/dialog';
import {DxButtonModule} from 'devextreme-angular';
import {DxiButtonModule} from 'devextreme-angular/ui/nested';
import {FormModule} from '../../modules/form';
import {NgModule} from '@angular/core';
import {ObjectDetailModule} from '@salesapp/shared/modules';
import {ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {UserManagementCreateUserDialogComponent} from './components/user-management-create-user-dialog/user-management-create-user-dialog.component';
import {UserManagementDetailComponent} from './components/user-management-detail/user-management-detail.component';
import {UserManagementDetailDialogComponent} from './components/user-management-detail-dialog/user-management-detail-dialog.component';
import {UserManagementResetPasswordDialogComponent} from './components/user-management-reset-password-dialog/user-management-reset-password-dialog.component';
import {UserManagementService} from './services/user-management.service';
import {UserManagementUserFormComponent} from './components/user-management-user-form/user-management-user-form.component';
import {UserManagementUserProfileFormComponent} from './components/user-management-user-profile-form/user-management-user-profile-form.component';
import {
  InputSelectModule,
  InputTextModule,
  LoadingContainerModule,
  NoticeModule,
} from '@salesapp/components';
import {ObjectAttachmentModule} from '../attachments';

const SALESAPP_MODULES = [
  ObjectAttachmentModule,
  ObjectDetailModule,
  DialogModule,
  FormModule,
];

const UI_COMPONENTS = [
  InputTextModule,
  InputSelectModule,
  LoadingContainerModule,
  NoticeModule,
];

const DX_MODULES = [DxButtonModule, DxiButtonModule];

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    ...SALESAPP_MODULES,
    ...UI_COMPONENTS,
    ...DX_MODULES,
  ],
  declarations: [
    UserManagementCreateUserDialogComponent,
    UserManagementDetailComponent,
    UserManagementDetailDialogComponent,
    UserManagementResetPasswordDialogComponent,
    UserManagementUserFormComponent,
    UserManagementUserProfileFormComponent,
  ],
  providers: [UserManagementService],
  exports: [
    UserManagementCreateUserDialogComponent,
    UserManagementDetailDialogComponent,
    UserManagementResetPasswordDialogComponent,
  ],
})
export class UserManagementModule {}

export * from './components/user-management-create-user-dialog/user-management-create-user-dialog.component';
export * from './components/user-management-detail-dialog/user-management-detail-dialog.component';
export * from './components/user-management-reset-password-dialog/user-management-reset-password-dialog.component';
