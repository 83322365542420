import {ObjectMapCache} from '@retrixhouse/salesapp-shared/lib/caching';
import {IGenericListItemDataService} from '../../interfaces/data-service';
import {GenericListItem} from '../../models';
import {GenericListItemHttpService} from '../http';
import {GenericListItemOfflineService} from '../offline';
import {
  BaseCrudDataService,
  CachingOptions,
  TTL_DAY,
} from './base.data-service';

export class GenericListItemDataService extends BaseCrudDataService<
  GenericListItem,
  IGenericListItemDataService,
  ObjectMapCache<string, GenericListItem>
> {
  constructor(
    onlineService: GenericListItemHttpService,
    offlineService: GenericListItemOfflineService,
    cache: ObjectMapCache<string, GenericListItem>,
  ) {
    super(onlineService, offlineService, cache);
  }

  public async updateItemPositions(
    positions: {id: string; position: number}[],
  ): Promise<void> {
    await this.service.updateItemPositions(positions);

    // update positions in the cache
    if (this._cache.isValid) {
      positions.forEach(p => {
        const item = this._cache.get(p.id);
        if (item) {
          item.position = p.position;
        }
      });
    }
  }

  public async getListAll(
    cachingOptions?: CachingOptions,
  ): Promise<GenericListItem[]> {
    if (cachingOptions?.skipCache) {
      return this.service.getListAll();
    }

    if (cachingOptions?.forceReload || !this._cache.isValid) {
      const positions = await this.service.getListAll();
      this.initCache(positions, cachingOptions?.ttl ?? TTL_DAY);
    }
    return this._cache.getValues();
  }
}
