import {
  ObjectArrayCache,
  ObjectMapCache,
} from '@retrixhouse/salesapp-shared/lib/caching';
import {II18nDataService} from '../../interfaces/data-service';
import {Locale, LocalizedIdentifier, LocalizedValue} from '../../models';
import {I18NHttpService} from '../http';
import {I18NOfflineService} from '../offline';
import {
  BaseCrudDataService,
  CachingOptions,
  TTL_DAY,
} from './base.data-service';
import deepEqual from 'deep-equal';
import cloneDeep from 'clone-deep';
import {Filter} from '@loopback/filter';

export class I18NDataService extends BaseCrudDataService<
  LocalizedValue,
  II18nDataService,
  ObjectMapCache<string, LocalizedValue>
> {
  private _cacheLocales: ObjectArrayCache<Locale>;
  private _cacheEnabledLocales: ObjectArrayCache<Locale>;
  private _cacheLocalizedIdentifier: ObjectArrayCache<LocalizedIdentifier>;
  private _lastIdentifiersFilter?: Filter<LocalizedIdentifier>;

  constructor(
    onlineService: I18NHttpService,
    offlineService: I18NOfflineService,
    cache: ObjectMapCache<string, LocalizedValue>,
    cacheLocales: ObjectArrayCache<Locale>,
    cacheEnabledLocales: ObjectArrayCache<Locale>,
    cacheLocalizedIdentifier: ObjectArrayCache<LocalizedIdentifier>,
  ) {
    super(onlineService, offlineService, cache);
    this._cacheLocales = cacheLocales;
    this._cacheEnabledLocales = cacheEnabledLocales;
    this._cacheLocalizedIdentifier = cacheLocalizedIdentifier;
  }

  public async getLocales(cachingOptions?: CachingOptions): Promise<Locale[]> {
    if (cachingOptions?.skipCache) {
      return this.service.getLocales();
    }

    if (cachingOptions?.forceReload || !this._cacheLocales.isValid) {
      const locales = await this.service.getLocales();
      this._cacheLocales.init(locales, cachingOptions?.ttl ?? TTL_DAY);
    }

    return this._cacheLocales.getAll();
  }

  public async getEnabledLocales(
    cachingOptions?: CachingOptions,
  ): Promise<Locale[]> {
    if (cachingOptions?.skipCache) {
      return this.service.getEnabledLocales();
    }

    if (cachingOptions?.forceReload || !this._cacheEnabledLocales.isValid) {
      const locales = await this.service.getEnabledLocales();
      this._cacheEnabledLocales.init(locales, cachingOptions?.ttl ?? TTL_DAY);
    }

    return this._cacheEnabledLocales.getAll();
  }

  public async getIdentifiers(
    filter?: Filter<LocalizedIdentifier>,
    cachingOptions?: CachingOptions,
  ): Promise<LocalizedIdentifier[]> {
    if (cachingOptions?.skipCache) {
      return this.service.getIdentifiers(filter);
    }

    if (
      cachingOptions?.forceReload ||
      !this._cacheLocalizedIdentifier.isValid ||
      !deepEqual(this._lastIdentifiersFilter, filter)
    ) {
      this._lastIdentifiersFilter = cloneDeep(filter);
      const identifiers = await this.service.getIdentifiers(filter);
      this._cacheLocalizedIdentifier.init(
        identifiers,
        cachingOptions?.ttl ?? TTL_DAY,
      );
    }

    return this._cacheLocalizedIdentifier.getAll();
  }
}
