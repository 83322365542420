<ng-container *ngIf="list$ | async; let list">
  <dx-list [items]="list" (onItemClick)="onItemClick($event)" [grouped]="true" [collapsibleGroups]="true">
    <div *dxTemplate="let item of 'group'">
      <div *ngIf="getFolderData(item.key); let folder" class="folder">
        <i class="fa-solid fa-folder"></i>
        <div class="name">{{ folder.name }}</div>
      </div>
    </div>
    <div *dxTemplate="let item of 'item'">
      <div class="file">
        <i [class]="item.icon"></i>
        <div class="file-data">
          <div class="name" [class.visited]="getVisitedInfo(item.id)">{{ item.name }}</div>
          <div class="created-at">{{ item.uploadedAt | dateFormat : 'LLL' }}</div>
        </div>
      </div>
    </div>
  </dx-list>
</ng-container>
