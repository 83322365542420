export class ProductListingStoreRequest {
  perspective: 'product' | 'store';
  productListingId: string;
  productOrStoreId: string;
  productListingStoreList: ProductListingStoreValue[];
}

export class ProductListingStoreValue {
  productId: string;
  storeId: string;
  value: boolean;
}
