import {ObjectMapCache} from '@retrixhouse/salesapp-shared/lib/caching';
import {IUnitConversionDataService} from '../../interfaces/data-service';
import {UnitConversion} from '../../models';
import {UnitConversionHttpService} from '../http';
import {UnitConversionOfflineService} from '../offline';
import {BaseCrudDataService} from './base.data-service';

export class UnitConversionDataService extends BaseCrudDataService<
  UnitConversion,
  IUnitConversionDataService,
  ObjectMapCache<string, UnitConversion>
> {
  constructor(
    onlineService: UnitConversionHttpService,
    offlineService: UnitConversionOfflineService,
    cache: ObjectMapCache<string, UnitConversion>,
  ) {
    super(onlineService, offlineService, cache);
  }
}
