<app-base-dialog [borderTopColor]="borderTopColor"
                 [hideHeader]="!dialogTitle">
  <ng-container ngProjectAs="header">
    <div class="dialog-title">
      {{ dialogTitle }}
    </div>
    <div class="close"
         (click)="onClose()">
      <i class="fa-solid fa-xmark"></i>
    </div>
  </ng-container>

  <div ngProjectAs="content"
       class="content">
    <p class="title">
      {{ title }}
    </p>
    <p class="description">
      {{ description }}
    </p>
  </div>

  <div ngProjectAs="footer"
       class="footer buttons">
    <dx-button [icon]="options.acceptIcon"
               [text]="options.acceptLabel"
               [type]="actionButtonType"
               [class.warning]="options.acceptButtonType === 'warning'"
               (click)="onAccept()"></dx-button>
    <dx-button [icon]="options.rejectIcon"
               [text]="options.rejectLabel"
               [type]="rejectButtonType"
               stylingMode="text"
               (click)="onReject()"></dx-button>
  </div>
</app-base-dialog>