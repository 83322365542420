import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import {DataProvider} from '../data.provider/data-provider';
import * as moment from 'moment/moment';
import {NotificationService} from '../services/notification.service';
import {TranslateService} from '@ngx-translate/core';
import {SettingNames} from '@retrixhouse/salesapp-shared/lib/settings';

/**
 * This guard prevents to navigate off user profile screen if
 */
@Injectable()
export class UserProfileNavigationGuard  {
  constructor(
    private dataProvider: DataProvider,
    private translate: TranslateService,
  ) {}

  async canDeactivate(
    component: any,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot,
  ): Promise<boolean | UrlTree> {
    const [profile, username] = await Promise.all([
      this.dataProvider.user.getMyProfile(),
      this.dataProvider.user.getMyUsernameResponse()
    ]);

    const settings = this.dataProvider.settingResolver.getValues([
      SettingNames.UserProfile_UpdateDays,
      SettingNames.UserProfile_UpdateMaxDelay,
      SettingNames.UserProfile_UpdatePositions
    ]);


    const updatePositions = settings[SettingNames.UserProfile_UpdatePositions] ?? [];
    const maxOutdatedDays = settings[SettingNames.UserProfile_UpdateDays];
    const delay = settings[SettingNames.UserProfile_UpdateMaxDelay] ?? 0;
    const updateRequiredAt = profile.updateRequiredAt
      ? moment(profile.updateRequiredAt)
      : null;
    const now = moment();

    const deactivateResult =
      !maxOutdatedDays ||
      !(updatePositions.length === 0 || updatePositions.includes(username.positionId)) ||
      (updateRequiredAt && updateRequiredAt.add(delay, 'day').isAfter(now));
    if (!deactivateResult) {
      NotificationService.notifyError(
        this.translate.instant('messages.error.profile-update-required'),
      );
    }

    return deactivateResult;
  }
}
