<app-form-field [label]="label"
                [hint]="hint"
                [formControlRef]="formControlRef">
  <dx-tag-box *ngIf="options"
              #tagBox
              [value]="value"
              [placeholder]="placeholder"
              [disabled]="disabled"
              [isValid]="isValid"
              [showClearButton]="false"
              [showDropDownButton]="false"
              [acceptCustomValue]="true"
              [items]="options"
              (onInitialized)="updateBehavior($event)"
              (onCustomItemCreating)="onValueCreate($event)"
              (valueChange)="onValueChange($event)"></dx-tag-box>
</app-form-field>