import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {InputGooglePlacesComponent} from './input-google-places.component';
import {DxButtonModule} from 'devextreme-angular';
import {NgxGpAutocompleteModule} from '@angular-magic/ngx-gp-autocomplete';
import {environment} from '../../../../environments/environment';
import {TranslateModule} from '@ngx-translate/core';
import {FormFieldModule} from '../form-field';

const DX_MODULES = [DxButtonModule];
const UI_MODULES = [FormFieldModule];

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    NgxGpAutocompleteModule.forRoot({
      loaderOptions: {
        apiKey: environment.googleMapApiKey,
        libraries: ['places'],
      },
    }),
    ...DX_MODULES,
    ...UI_MODULES,
  ],
  declarations: [InputGooglePlacesComponent],
  providers: [],
  exports: [InputGooglePlacesComponent],
})
export class InputGooglePlacesModule {}
