import {ObjectMapCache} from '@retrixhouse/salesapp-shared/lib/caching';
import {IQuestionnaireDataService} from '../../interfaces/data-service';
import {Questionnaire} from '../../models';
import {QuestionnaireHttpService} from '../http';
import {QuestionnaireOfflineService} from '../offline';
import {BaseCrudDataService} from './base.data-service';

export class QuestionnaireDataService extends BaseCrudDataService<
  Questionnaire,
  IQuestionnaireDataService,
  ObjectMapCache<string, Questionnaire>
> {
  constructor(
    onlineService: QuestionnaireHttpService,
    offlineService: QuestionnaireOfflineService,
    cache: ObjectMapCache<string, Questionnaire>,
  ) {
    super(onlineService, offlineService, cache);
  }
}
