export class OpenVisitRequest {
  tourPlanId: string;
  missingStoreLocation?: boolean;
  missingUserLocation?: boolean;
  userNotInRange?: boolean;
  startLatitude?: number;
  startLongitude?: number;
  startAltitude?: number;
  startAccuracy?: number;
  onBehalfOf?: string;
}
