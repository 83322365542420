import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GridRowAction} from '../../grid.interfaces';
import {TSimpleChanges} from '@salesapp/utils/angular.utils';
import {ActionsBuilderService} from '@salesapp/shared/services/actions-builder.service';
import {BehaviorSubject, Observable, combineLatest} from 'rxjs';
import {switchMap} from 'rxjs/operators';

@Component({
  selector: 'app-grid-row-actions',
  templateUrl: './grid-row-actions.component.html',
  styleUrls: ['./grid-row-actions.component.scss'],
})
export class GridRowActionsComponent {
  @Input() actions: GridRowAction[];
  @Input() data: unknown;

  @Output() actionClicked = new EventEmitter<GridRowAction>();

  filteredActions$: Observable<GridRowAction[]>;

  private data$ = new BehaviorSubject<unknown>(null);
  private actions$ = new BehaviorSubject<GridRowAction[]>(null);

  constructor(private actionsBuilderService: ActionsBuilderService) {}

  ngOnInit() {
    this.filteredActions$ = combineLatest([this.data$, this.actions$]).pipe(
      switchMap(([data, actions]) => {
        return this.actionsBuilderService.init<GridRowAction>(
          this.filterVisibleActions(actions),
          {data},
        );
      }),
    );
  }

  ngOnChanges(changes: TSimpleChanges<GridRowActionsComponent>): void {
    if (changes.data || changes.actions) {
      this.data$.next(this.data);
      this.actions$.next(this.actions);
    }
  }

  onItemClick(event: any) {
    this.actionClicked.emit(event);
  }

  private filterVisibleActions(actions: GridRowAction[]) {
    return actions.filter(action => {
      if (action.visibilityResolver) {
        return action.visibilityResolver(this.data);
      }
      return true;
    });
  }
}
