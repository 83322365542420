import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {DxTextBoxModule} from 'devextreme-angular';
import {InputTextComponent} from './input-text.component';
import {FormControlErrorModule} from '../form-control-error';
import {FormFieldModule} from '../form-field';

const DX_MODULES = [DxTextBoxModule];
const UI_MODULES = [FormFieldModule];

@NgModule({
  imports: [CommonModule, ...DX_MODULES, ...UI_MODULES],
  declarations: [InputTextComponent],
  exports: [InputTextComponent],
})
export class InputTextModule {}

export * from './input-text.component';
