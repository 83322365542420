import * as moment from 'moment';
import {BaseModel} from './base.model';
import {IPeriodicLimit} from '@retrixhouse/salesapp-shared/lib/models';
import {PeriodicLimitType} from '@retrixhouse/salesapp-shared/lib/models/enums';

export class PeriodicLimit extends BaseModel implements IPeriodicLimit {
  name: string;
  limit: number;
  intervalDuration: number;
  offset: number;
  start: PeriodicLimitType;

  constructor() {
    super();
    this.name = null;
    this.limit = null;
    this.intervalDuration = null;
    this.offset = null;
    this.start = null;
  }
}

export function isPeriodLimitReached(
  previousOccurrences: Date[],
  periodicLimitObject: PeriodicLimit,
): boolean {
  let primaryIntervalStart;

  const today = moment();
  moment.updateLocale('en', {
    weekdays: [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ],
  });

  // year (offset 1-365)
  if (periodicLimitObject.start === PeriodicLimitType.Year) {
    console.log('year');
    primaryIntervalStart = moment().startOf('year').startOf('day');
  }
  // month (offset 1-31)
  if (periodicLimitObject.start === PeriodicLimitType.Month) {
    console.log('month');
    primaryIntervalStart = moment().startOf('month').startOf('day');
  }

  // week (offset 1-7)
  if (periodicLimitObject.start === PeriodicLimitType.Week) {
    console.log('days');
    primaryIntervalStart = moment().startOf('isoWeek').startOf('day');
  }

  const startOfInterval = moment(primaryIntervalStart).add(
    periodicLimitObject.offset - 1,
    'days',
  );

  console.log(startOfInterval);
  const endOfInterval = moment(startOfInterval)
    .add(periodicLimitObject.intervalDuration - 1, 'days')
    .endOf('day');
  console.log(endOfInterval);
  const result = previousOccurrences.filter(occurence =>
    moment(occurence).isBetween(startOfInterval, endOfInterval),
  ).length;

  // check whether limit was reached
  if (result >= periodicLimitObject.limit) {
    return true;
  }

  return false;
}
