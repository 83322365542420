import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Chain} from 'src/app/shared/models/chain.model';
import {BaseCrudHttpService} from './base.http-service';
import {IChainDataService} from '../../interfaces/data-service';

@Injectable()
export class ChainHttpService
  extends BaseCrudHttpService<Chain>
  implements IChainDataService
{
  constructor(http: HttpClient) {
    super(http, '/chains');
  }

  public updatePicture(
    chainId: string,
    version: number,
    picture: string,
  ): Promise<Chain> {
    return this.PATCH(`/chains/${chainId}`, {
      id: chainId,
      version,
      logo: picture,
    });
  }

  public deletePicture(chainId: string): Promise<void> {
    return this.DELETE<void>(`/chains/${chainId}/picture`);
  }
}
