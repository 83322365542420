import * as uuid from 'uuid';
import {AuthGuardService} from '@salesapp/services';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {InputSelectOption} from '@salesapp/components';
import {isNotFirstChange, TSimpleChanges} from '@salesapp/utils/angular.utils';
import {map} from 'rxjs/operators';
import {ObjectDetailForm} from '@salesapp/shared/modules';
import {RoleNames} from '@retrixhouse/salesapp-shared/lib/common';
import {
  IPeriodicLimit,
  IProject,
  IProjectStorePeriodicLimit,
  IStoreWithRelations,
} from '@retrixhouse/salesapp-shared/lib/models';
import {
  PeriodicLimitStorageService,
  ProjectStorageService,
  StoreStorageService,
} from '@salesapp/storage';
import {
  CustomValidators,
  FormControlsOf,
  markFormGroupAsTouched,
} from '@salesapp/utils/reactive-form';

@Component({
  selector: 'app-store-detail-periodic-limit',
  templateUrl: './store-detail-periodic-limit.component.html',
  styleUrls: ['./store-detail-periodic-limit.component.scss'],
})
export class StoreDetailPeriodicLimitComponent
  implements ObjectDetailForm<StorePeriodicLimit>
{
  @Input() store: IStoreWithRelations;

  @Output() actionCompleted = new EventEmitter<StorePeriodicLimit>();
  @Output() cancel = new EventEmitter<void>();

  form: FormGroup<PeriodicLimitsFormGroup>;

  projectSelectOptions$: Observable<InputSelectOption[]>;
  periodicLimitSelectOptions$: Observable<InputSelectOption[]>;
  actionInProgress$: Observable<boolean>;
  dataById$: Observable<{
    projects: Map<string, IProject>;
    limits: Map<string, IPeriodicLimit>;
  }>;

  availableForRoles = [
    RoleNames.StoreFrequency,
    RoleNames.StoreFrequencyView,
    RoleNames.StoreFrequencyEdit,
    RoleNames.Admin,
  ];

  private selectedProjects$ = new BehaviorSubject<string[]>([]);

  get periodicLimits() {
    return this.form.get('periodicLimits') as FormArray<
      FormGroup<PeriodicLimitFormGroup>
    >;
  }

  get hasChanges() {
    return this.form.touched;
  }

  get canEdit() {
    return this.authGuardService.hasRoleAny(
      RoleNames.StoreFrequencyEdit,
      RoleNames.StoreFrequency,
      RoleNames.Admin,
    );
  }

  constructor(
    private formBuilder: FormBuilder,
    private projectStorageService: ProjectStorageService,
    private periodicLimitStorageService: PeriodicLimitStorageService,
    private storeStorageService: StoreStorageService,
    private authGuardService: AuthGuardService,
  ) {}

  ngOnChanges(
    changes: TSimpleChanges<StoreDetailPeriodicLimitComponent>,
  ): void {
    if (isNotFirstChange(changes.store)) {
      this.form.patchValue(changes.store.currentValue);
    }
  }

  ngOnInit() {
    this.initForm();
    this.initOptions();
    this.dataById$ = combineLatest([
      this.projectStorageService.dataById$,
      this.periodicLimitStorageService.dataById$,
    ]).pipe(
      map(([projects, limits]) => {
        return {projects, limits};
      }),
    );
    this.actionInProgress$ = this.storeStorageService.dataCommandInProgress$;
  }

  onSavePeriodicLimit(index: number) {
    const periodicLimitGroup = this.periodicLimits.controls[index];
    if (periodicLimitGroup.valid) {
      periodicLimitGroup.disable();
      this.selectedProjects$.next([
        ...this.selectedProjects$.getValue(),
        periodicLimitGroup.controls.projectId.value,
      ]);
    } else {
      periodicLimitGroup.updateValueAndValidity();
      markFormGroupAsTouched(periodicLimitGroup);
    }
  }

  onDeletePeriodicLimit(index: number) {
    this.periodicLimits.removeAt(index, {emitEvent: true});
    this.form.markAsDirty();
  }

  onAddPeriodicLimit() {
    if (this.form.valid) {
      this.periodicLimits.push(this.createPeriodicLimitFormGroup());
    } else {
      this.form.updateValueAndValidity();
      markFormGroupAsTouched(this.form);
    }
  }

  onFormAction() {
    if (this.form.dirty) {
      this.periodicLimits.controls.forEach(control => control.disable());
      const storeData = this.form.getRawValue();
      this.storeStorageService
        .update({id: storeData.id, data: storeData})
        .subscribe({
          next: () => {
            this.actionCompleted.emit(storeData);
          },
        });
    }
  }

  onFormCancel() {
    this.cancel.emit();
  }

  private initForm() {
    const form = this.formBuilder.group<PeriodicLimitsFormGroup>({
      id: this.formBuilder.control(null, [CustomValidators.required]),
      version: this.formBuilder.control(null, [CustomValidators.required]),
      periodicLimits: this.formBuilder.array([]) as FormArray,
    });

    if (this.store?.periodicLimits?.length) {
      this.store.periodicLimits.forEach(periodicLimit => {
        const group = this.createPeriodicLimitFormGroup(periodicLimit);
        group.disable();
        (form.get('periodicLimits') as FormArray).push(group);
      });
    }

    form.patchValue(this.store, {emitEvent: true});

    this.form = form;
  }

  private initOptions() {
    this.selectedProjects$.next(
      (this.store.periodicLimits || []).map(
        periodicLimit => periodicLimit.projectId,
      ),
    );
    this.projectSelectOptions$ = combineLatest([
      this.selectedProjects$,
      this.projectStorageService.dataAsSelectOptions$,
    ]).pipe(
      map(([selectedProjects, projectsOptions]) => {
        return projectsOptions.filter(
          project => !selectedProjects.includes(project.data.id),
        );
      }),
    );
    this.periodicLimitSelectOptions$ =
      this.periodicLimitStorageService.dataAsSelectOptions$;
  }

  private createPeriodicLimitFormGroup(
    periodicLimit?: IProjectStorePeriodicLimit,
  ) {
    return this.formBuilder.group({
      id: this.formBuilder.control(periodicLimit?.id || uuid.v4()),
      projectId: this.formBuilder.control(periodicLimit?.projectId || null, [
        CustomValidators.required,
      ]),
      storeId: this.formBuilder.control(
        periodicLimit?.storeId || this.store.id,
      ),
      periodicLimitId: this.formBuilder.control(
        periodicLimit?.periodicLimitId || null,
        [CustomValidators.required],
      ),
    });
  }
}

type StorePeriodicLimit = Pick<
  IStoreWithRelations,
  'id' | 'version' | 'periodicLimits'
>;
type PeriodicLimitsFormGroup = FormControlsOf<StorePeriodicLimit>;
type PeriodicLimitFormGroup = FormControlsOf<IProjectStorePeriodicLimit>;
