import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {
  CopyTextModule,
  InputFileModule,
  InputSelectModule,
  InputTextModule,
  LoadingModule,
  NoticeModule,
  ObjectPermissionsModule,
} from '@salesapp/components';
import {DialogModule} from '@salesapp/dialog';
import {FormModule} from '@salesapp/form';
import {GridModule} from '@salesapp/grid';
import {DxButtonModule} from 'devextreme-angular';
import {CustomDirectivesModule} from '../../directives/index';
import {AttachmentTemplateEditDialogComponent} from './components/attachment-template-edit-dialog/attachment-template-edit-dialog.component';
import {AttachmentTemplateLegendDialogComponent} from './components/attachment-template-legend-dialog/attachment-template-legend-dialog.component';
import {AttachmentTemplateLegendListComponent} from './components/attachment-template-legend-list/attachment-template-legend-list.component';
import {AttachmentTemplateLegendComponent} from './components/attachment-template-legend/attachment-template-legend.component';
import {AttachmentTemplatesGenerateDialogComponent} from './components/attachment-templates-generate-dialog/attachment-templates-generate-dialog.component';
import {AttachmentTemplatesGridComponent} from './components/attachment-templates-grid/attachment-templates-grid.component';
import {AttachmentTemplatesGridService} from './services/attachment-templates-grid.service';
import {AttachmentTemplatesService} from './services/attachment-templates.service';

const SALESAPP_MODULES = [
  DialogModule,
  GridModule,
  FormModule,
  CustomDirectivesModule,
];

const SALESAPP_UI = [
  InputFileModule,
  InputSelectModule,
  InputTextModule,
  ObjectPermissionsModule,
  LoadingModule,
  CopyTextModule,
  NoticeModule,
];

const DX_MODULES = [DxButtonModule];

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    ...SALESAPP_MODULES,
    ...SALESAPP_UI,
    ...DX_MODULES,
  ],
  declarations: [
    AttachmentTemplatesGridComponent,
    AttachmentTemplateEditDialogComponent,
    AttachmentTemplatesGenerateDialogComponent,
    AttachmentTemplateLegendComponent,
    AttachmentTemplateLegendDialogComponent,
    AttachmentTemplateLegendListComponent,
  ],
  exports: [AttachmentTemplatesGridComponent],
  providers: [AttachmentTemplatesService, AttachmentTemplatesGridService],
})
export class AttachmentTemplatesModule {}

export * from './components/attachment-templates-grid/attachment-templates-grid.component';
