import {DialogRef} from '@angular/cdk/dialog';
import {ChangeDetectionStrategy, Component} from '@angular/core';

@Component({
  selector: 'app-form-discard-dialog',
  templateUrl: './form-discard-dialog.component.html',
  styleUrls: ['./form-discard-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormDiscardDialogComponent {
  constructor(private dialogRef: DialogRef<string>) {}

  onReject() {
    this.dialogRef.close('rejected');
  }

  onAccept() {
    this.dialogRef.close('accepted');
  }
}
