import {combineLatest, Observable} from 'rxjs';
import {CustomValidators} from '@salesapp/utils/reactive-form/form-validators';
import {DIALOG_DATA, DialogRef} from '@angular/cdk/dialog';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {FormControlsOf} from '@salesapp/utils/reactive-form/reactive-form.interface';
import {InputSelectOption} from '@salesapp/components';
import {map} from 'rxjs/operators';
import {ObjectStorageService, PositionStorageService} from '@salesapp/storage';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import {
  FormLayoutService,
  FormSettings,
} from '../../services/form-layout.service';

@Component({
  selector: 'app-form-designer-form-setting-dialog',
  templateUrl: './form-designer-form-setting-dialog.component.html',
  styleUrls: ['./form-designer-form-setting-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormDesignerFormSettingDialogComponent implements OnInit {
  form: FormGroup<FormDesignerFormSettingDialogFormControls>;

  positionOptions$: Observable<InputSelectOption[]>;

  constructor(
    private dialogRef: DialogRef<FormSettings>,
    @Inject(DIALOG_DATA) private data: FormDesignerFormSettingDialogData,
    private formBuilder: FormBuilder,
    private positionStorageService: PositionStorageService,
    private objectStorageService: ObjectStorageService,
    private formLayoutService: FormLayoutService,
  ) {}

  ngOnInit() {
    this.initForm();
    this.positionOptions$ = combineLatest(
      this.positionStorageService.dataAsSelectOptions$,
      this.getPositionsUsedInFormsForObject(),
    ).pipe(
      map(([options, usedPositions]) =>
        options.filter(option => {
          if (this.data.formSettings.positionIds?.includes(option.data.id)) {
            return option;
          }
          return (
            !option.data.customerId && !usedPositions.includes(option.data.id)
          );
        }),
      ),
    );
  }

  getFormControl(name: keyof FormSettings) {
    return this.form.get(name) as FormControl;
  }

  onClose() {
    this.dialogRef.close();
  }

  onSave() {
    this.dialogRef.close(this.form.getRawValue());
  }

  private initForm() {
    const form =
      this.formBuilder.group<FormDesignerFormSettingDialogFormControls>({
        name: this.formBuilder.control(null, [CustomValidators.required]),
        positionIds: this.formBuilder.control(null),
      });

    if (this.data.formSettings) {
      form.patchValue(this.data.formSettings);
    }

    this.form = form;
  }

  private getPositionsUsedInFormsForObject() {
    return this.objectStorageService.formsByObjectTypeId$.pipe(
      map(formsByObjectTypeId => {
        const forms = formsByObjectTypeId.get(
          this.formLayoutService.objectTypeId,
        );

        const usedPositions = [];

        forms.forEach(form => {
          if (form.positionIds) {
            usedPositions.push(...form.positionIds);
          }
        });

        return usedPositions;
      }),
    );
  }
}

export interface FormDesignerFormSettingDialogData {
  formSettings: FormSettings;
}

type FormDesignerFormSettingDialogFormControls = FormControlsOf<FormSettings>;
