import {Injectable} from '@angular/core';
import {TourPlanChangeRequest} from '../../models';
import {ITourPlanChangeRequestDataService} from '../../interfaces/data-service';
import {BaseCrudOfflineService} from './base.offline-service';

@Injectable()
export class TourPlanChangeRequestOfflineService
  extends BaseCrudOfflineService<TourPlanChangeRequest>
  implements ITourPlanChangeRequestDataService
{
  createMany(objectData: Partial<TourPlanChangeRequest>): Promise<TourPlanChangeRequest[]> {
    return Promise.reject('Offline service - not yet implemented');

  }

  public getMyTourPlanChangeRequests(): Promise<TourPlanChangeRequest[]> {
    return Promise.reject('Offline service - not yet implemented');
  }
}
