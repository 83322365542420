<div class="title">Native application interface sandbox</div>

<div class='content-block-card responsive-paddings'>
  <app-interface-test></app-interface-test>
  <!--  <dx-tab-panel [selectedIndex]='2'>-->
  <!--    <dxi-item class='tab' title='Requests / Responses'>-->
  <!--      <div class='dx-card responsive-paddings'>-->
  <!--        <div class='card-title'>Create new request</div>-->
  <!--        <dx-form #createRequestForm [formData]='createFormData' id='createRequestForm' labelLocation='top'>-->
  <!--          <dxi-item-->
  <!--            [editorOptions]="{-->
  <!--              items: [-->
  <!--                'storageSave',-->
  <!--                'storageDelete',-->
  <!--                'storageLoad',-->
  <!--                'storageKeys',-->
  <!--                'storageSize',-->
  <!--                'storageClear',-->
  <!--                'photoCapture',-->
  <!--                'photoLibrary',-->
  <!--                'locationGet',-->
  <!--                'locationTrack',-->
  <!--                'scanBarcode',-->
  <!--                'updateClient'-->
  <!--              ]-->
  <!--            }"-->
  <!--            dataField="requestType"-->
  <!--            editorType="dxSelectBox"-->
  <!--          >-->
  <!--            <dxi-validation-rule message="Select Request Type" type="required"></dxi-validation-rule>-->
  <!--          </dxi-item>-->

  <!--          <dxi-item-->
  <!--            [editorOptions]="{-->
  <!--              autoResizeEnabled: true,-->
  <!--              maxLength: 1024,-->
  <!--              placeholder: 'Insert here request parameters in JSON format'-->
  <!--            }"-->
  <!--            dataField="params"-->
  <!--            editorType="dxTextArea"-->
  <!--          >-->
  <!--            <dxo-label text="Request parameters"></dxo-label>-->
  <!--          </dxi-item>-->

  <!--          <dxi-item-->
  <!--            [buttonOptions]="{-->
  <!--              text: 'Create Request',-->
  <!--              useSubmitBehavior: false,-->
  <!--              onClick: createRequest-->
  <!--            }"-->
  <!--            horizontalAlignment="left"-->
  <!--            itemType="button"-->
  <!--          ></dxi-item>-->
  <!--        </dx-form>-->
  <!--      </div>-->
  <!--      &lt;!&ndash; Request create end    &ndash;&gt;-->

  <!--      <div class="dx-card responsive-paddings my-3">-->
  <!--        <div class="card-title">Pending Requests</div>-->
  <!--        <dx-list (onItemClick)="promiseClicked($event)" [items]="promiseList" displayExpr="displayName"></dx-list>-->
  <!--      </div>-->
  <!--      &lt;!&ndash; Request list end    &ndash;&gt;-->

  <!--      <div class="dx-card responsive-paddings my-3">-->
  <!--        <div class="card-title">Resolve / Reject request</div>-->
  <!--        <dx-form #resolveRequestForm [formData]="resolveRequestData" id="resolveRequestForm" labelLocation="top">-->
  <!--          <dxi-item dataField="id" editorType="dxTextBox">-->
  <!--            <dxi-validation-rule message="Id is required" type="required"></dxi-validation-rule>-->
  <!--          </dxi-item>-->

  <!--          <dxi-item-->
  <!--            [editorOptions]="{-->
  <!--              autoResizeEnabled: true,-->
  <!--              maxLength: 1024,-->
  <!--              placeholder: 'Insert here request parameters in JSON format'-->
  <!--            }"-->
  <!--            dataField="params"-->
  <!--            editorType="dxTextArea"-->
  <!--          >-->
  <!--            <dxo-label text="Request parameters"></dxo-label>-->
  <!--          </dxi-item>-->

  <!--          <dxi-item-->
  <!--            [buttonOptions]="{-->
  <!--              text: 'Resolve Request',-->
  <!--              useSubmitBehavior: false,-->
  <!--              onClick: resolveRequest-->
  <!--            }"-->
  <!--            horizontalAlignment="left"-->
  <!--            itemType="button"-->
  <!--          ></dxi-item>-->
  <!--        </dx-form>-->
  <!--      </div>-->
  <!--      &lt;!&ndash; Request resolve end    &ndash;&gt;-->
  <!--    </dxi-item>-->
  <!--    &lt;!&ndash; Requests / Responses tab end    &ndash;&gt;-->
  <!--    <dxi-item class="tab" title="Events">-->
  <!--      <div class="dx-card responsive-paddings">-->
  <!--        <div class="card-title">Subscribe to event</div>-->
  <!--        <dx-form #subscribeEventForm [formData]="subscribeEventFormData" id="subscribeEventForm" labelLocation="top">-->
  <!--          <dxi-item-->
  <!--            [editorOptions]="{-->
  <!--              items: ['appInfo', 'connectivity', 'location', 'pushNotification', 'newVersion']-->
  <!--            }"-->
  <!--            dataField="eventType"-->
  <!--            editorType="dxSelectBox"-->
  <!--          >-->
  <!--            <dxi-validation-rule message="Select Event Type" type="required"></dxi-validation-rule>-->
  <!--          </dxi-item>-->
  <!--          <dxi-item-->
  <!--            [buttonOptions]="{-->
  <!--              text: 'Subscribe to event',-->
  <!--              useSubmitBehavior: false,-->
  <!--              onClick: subscribeToEvent-->
  <!--            }"-->
  <!--            horizontalAlignment="left"-->
  <!--            itemType="button"-->
  <!--          ></dxi-item>-->
  <!--        </dx-form>-->
  <!--      </div>-->
  <!--      &lt;!&ndash; Subscribe to event end    &ndash;&gt;-->

  <!--      <div class="dx-card responsive-paddings my-3">-->
  <!--        <div class="card-title">Subscribed Events</div>-->
  <!--        <dx-list (onItemDeleting)="deleteEvent($event)" [items]="subscribedEventNames" [allowItemDeleting]="true" itemDeleteMode="static"></dx-list>-->
  <!--      </div>-->
  <!--      &lt;!&ndash; Pending events end    &ndash;&gt;-->

  <!--      <div class="dx-card responsive-paddings my-3">-->
  <!--        <div class="card-title">Fire Events</div>-->
  <!--        <dx-form #fireEventForm [formData]="fireEventFormData" id="fireEventForm" labelLocation="top">-->
  <!--          <dxi-item-->
  <!--            [editorOptions]="{-->
  <!--              items: subscribedEventNames-->
  <!--            }"-->
  <!--            dataField="eventType"-->
  <!--            editorType="dxSelectBox"-->
  <!--          >-->
  <!--            <dxi-validation-rule message="Select Event Type" type="required"></dxi-validation-rule>-->
  <!--          </dxi-item>-->
  <!--          <dxi-item-->
  <!--            [editorOptions]="{-->
  <!--              autoResizeEnabled: true,-->
  <!--              maxLength: 1024,-->
  <!--              placeholder: 'Insert here event parameters in JSON format'-->
  <!--            }"-->
  <!--            dataField="params"-->
  <!--            editorType="dxTextArea"-->
  <!--          >-->
  <!--            <dxo-label text="Event parameters"></dxo-label>-->
  <!--          </dxi-item>-->
  <!--          <dxi-item-->
  <!--            [buttonOptions]="{-->
  <!--              text: 'Fire Event',-->
  <!--              useSubmitBehavior: false,-->
  <!--              onClick: fireEvent-->
  <!--            }"-->
  <!--            horizontalAlignment='left'-->
  <!--            itemType='button'-->
  <!--          ></dxi-item>-->
  <!--        </dx-form>-->
  <!--      </div>-->
  <!--      &lt;!&ndash; Fire events end    &ndash;&gt;-->
  <!--    </dxi-item>-->
  <!--    <dxi-item class='tab' title='Interface test'>-->
  <!--      <app-interface-test></app-interface-test>-->
  <!--    </dxi-item>-->
  <!--  </dx-tab-panel>-->
</div>
