import {DIALOG_DATA, DialogRef} from '@angular/cdk/dialog';
import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';

@Component({
  selector: 'app-raw-data-dialog',
  templateUrl: './raw-data-dialog.component.html',
  styleUrls: ['./raw-data-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RawDataDialogComponent {
  get json() {
    return this.data.json;
  }

  constructor(
    private dialogRef: DialogRef<string>,
    @Inject(DIALOG_DATA) private data: RawDataDialogData,
  ) {}
  onClose() {
    this.dialogRef.close();
  }
}

export interface RawDataDialogData {
  json: any;
}
