import {Injectable} from '@angular/core';
import {Currency} from 'src/app/shared/models';
import {ICurrencyDataService} from '../../interfaces/data-service';
import {BaseCrudOfflineService} from './base.offline-service';

@Injectable()
export class CurrencyOfflineService
  extends BaseCrudOfflineService<Currency>
  implements ICurrencyDataService
{
  public getEnabled(): Promise<Currency[]> {
    return Promise.reject('Offline service - not yet implemented');
  }

  getDefault(): Promise<Currency> {
    return Promise.reject('Offline service - not yet implemented');
  }
}
