import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {AuthGuardService} from './auth.service';
import {loadMessages, locale} from 'devextreme/localization';
import * as moment from 'moment';
import {CookieService} from 'ngx-cookie-service';
import {environment} from 'src/environments/environment';

@Injectable()
export class LocaleService {
  constructor(
    private authGuardService: AuthGuardService,
    private cookieService: CookieService,
    public translate: TranslateService,
  ) {}

  public switchLocaleAndReload(locale: string): void {
    this.cookieService.set('sa-locale-switch', locale);
    window.location.reload();
  }

  /**
   * Sets the locale of the system.
   * If the locale is not provided, en-US is used.
   * @param {string} localeCode - the locale code.
   */
  async setLocale(localeCode: string = undefined): Promise<void> {
    // check whether a locale has been manually switched by the user (highest priority)
    const localeSwitch = this.cookieService.get('sa-locale-switch');
    let selectedLocale;

    if (localeSwitch) {
      this.cookieService.delete('sa-locale-switch');
      selectedLocale = localeSwitch;
    } else {
      const userPrfoile = await this.authGuardService.getUserProfile();
      selectedLocale =
        localeCode ?? (userPrfoile?.locale?.tag || environment.defaultLocale);
    }

    // DevExtreme language files are saved like: en.json tr.json de.json
    // That's why, the following code gets the language part of the locale shortcode (e.g. en part of en-EN or tr part of tr-TR) and import the file dynamically.
    const language = selectedLocale?.split('-')?.[0];

    let messages: any;

    if (language === 'sk') {
      messages = skMessages;
    } else if (language) {
      try {
        messages = await import(
          `devextreme/localization/messages/${language}.json`
        );
      } catch {
        messages = await import(`devextreme/localization/messages/en.json`);
      }
    } else {
      messages = await import(`devextreme/localization/messages/en.json`);
    }

    this.translate.use(selectedLocale);
    moment.locale(selectedLocale);
    loadMessages(messages);
    locale(selectedLocale);
  }
}

// devextreme messages for Slovak language
// prettier-ignore
const skMessages = {
	"sk": {
		"Yes": "Áno",
		"No": "Nie",
		"Cancel": "Zrušiť",
		"Close": "Zavrieť",
		"Clear": "Zmazať",
		"Done": "Hotovo",
		"Loading": "Nahrávanie...",
		"Select": "Výber...",
		"Search": "Hľadať",
		"Back": "Späť",
		"OK": "OK",
		"dxCollectionWidget-noDataText": "Žiadne údaje na zobrazenie",
		"dxDropDownEditor-selectLabel": "Výber",
		"validation-required": "povinné",
		"validation-required-formatted": "Povinné: '{0}'",
		"validation-numeric": "Hodnota musí byť číslo",
		"validation-numeric-formatted": "{0} musí byť číslo",
		"validation-range": "Hodnota je mimo rozsahu",
		"validation-range-formatted": "{0} je mimo rozsahu",
		"validation-stringLength": "Dĺžka textového reťazca nie je správna",
		"validation-stringLength-formatted": "Dĺžka textu {0} nie je správna",
		"validation-custom": "Neplatná hodnota",
		"validation-custom-formatted": "Neplatné: '{0}'",
		"validation-async": "Neplatná hodnota",
		"validation-async-formatted": "Neplatné: '{0}'",
		"validation-compare": "Hodnoty sa nezhodujú",
		"validation-compare-formatted": "{0} sa nezhoduje",
		"validation-pattern": "Hodnota nezodpovedá vzoru",
		"validation-pattern-formatted": "{0} nezodpovedá vzoru",
		"validation-email": "Neplatný email",
		"validation-email-formatted": "{0} nie je platný",
		"validation-mask": "Hodnota nie je platná",
		"dxLookup-searchPlaceholder": "Minimálny počet znakov: {0}",
		"dxList-pullingDownText": "Stiahnite nadol pre obnovenie...",
		"dxList-pulledDownText": "Uvoľnite pre obnovenie...",
		"dxList-refreshingText": "Obnovujem...",
		"dxList-pageLoadingText": "Nahrávam...",
		"dxList-nextButtonText": "Viac",
		"dxList-selectAll": "Vybrať všetko",
		"dxListEditDecorator-delete": "Zmazať",
		"dxListEditDecorator-more": "Viac",
		"dxScrollView-pullingDownText": "Stiahnite nadol pre obnovenie...",
		"dxScrollView-pulledDownText": "Uvoľnite pre obnovenie...",
		"dxScrollView-refreshingText": "Obnovujem...",
		"dxScrollView-reachBottomText": "Nahrávam...",
		"dxDateBox-simulatedDataPickerTitleTime": "Vyberte čas",
		"dxDateBox-simulatedDataPickerTitleDate": "Vyberte dátum",
		"dxDateBox-simulatedDataPickerTitleDateTime": "Vyberte dátum a čas",
		"dxDateBox-validation-datetime": "Hodnota musí byť dátum alebo čas",
		"dxFileUploader-selectFile": "Vyberte súbor",
		"dxFileUploader-dropFile": "alebo preneste súbor sem",
		"dxFileUploader-bytes": "bytov",
		"dxFileUploader-kb": "kb",
		"dxFileUploader-Mb": "Mb",
		"dxFileUploader-Gb": "Gb",
		"dxFileUploader-upload": "Nahrať",
		"dxFileUploader-uploaded": "Nahrané",
		"dxFileUploader-readyToUpload": "Pripravené na nahranie",
		"dxFileUploader-uploadAbortedMessage": "Nahrávanie zrušené",
		"dxFileUploader-uploadFailedMessage": "Nahrávanie zlyhalo",
		"dxFileUploader-invalidFileExtension": "Nepovolený typ súboru",
		"dxFileUploader-invalidMaxFileSize": "Súbor je príliš veľký",
		"dxFileUploader-invalidMinFileSize": "Súbor je príliš malý",
		"dxRangeSlider-ariaFrom": "Od",
		"dxRangeSlider-ariaTill": "Do",
		"dxSwitch-switchedOnText": "ZAP",
		"dxSwitch-switchedOffText": "VYP",
		"dxForm-optionalMark": "voliteľný",
		"dxForm-requiredMessage": "{0} je vyžadované",
		"dxNumberBox-invalidValueMessage": "Hodnota musí byť číslo",
		"dxNumberBox-noDataText": "Žiadne dáta",
		"dxDataGrid-columnChooserTitle": "Výber stĺpcov",
		"dxDataGrid-columnChooserEmptyText": "Presuňte stĺpec tu pre skytí",
		"dxDataGrid-groupContinuesMessage": "Pokračovať na ďalšej strane",
		"dxDataGrid-groupContinuedMessage": "Pokračovanie z predchádzajúcej strany",
		"dxDataGrid-groupHeaderText": "Zlúčiť stĺpce",
		"dxDataGrid-ungroupHeaderText": "Oddeliť",
		"dxDataGrid-ungroupAllText": "Oddeliť všetko",
		"dxDataGrid-editingEditRow": "Upraviť",
		"dxDataGrid-editingSaveRowChanges": "Uložiť",
		"dxDataGrid-editingCancelRowChanges": "Zrušiť",
		"dxDataGrid-editingDeleteRow": "Zmazať",
		"dxDataGrid-editingUndeleteRow": "Obnoviť",
		"dxDataGrid-editingConfirmDeleteMessage": "Naozaj chcete zmazať tento záznam?",
		"dxDataGrid-validationCancelChanges": "Zrušiť zmeny",
		"dxDataGrid-groupPanelEmptyText": "Preneste hlavičku stĺpca tu pre zlúčenie",
		"dxDataGrid-noDataText": "Žiadne dáta",
		"dxDataGrid-searchPanelPlaceholder": "Hľadanie...",
		"dxDataGrid-filterRowShowAllText": "(Všetko)",
		"dxDataGrid-filterRowResetOperationText": "Reset",
		"dxDataGrid-filterRowOperationEquals": "Rovná sa",
		"dxDataGrid-filterRowOperationNotEquals": "Nerovná sa",
		"dxDataGrid-filterRowOperationLess": "Menšie",
		"dxDataGrid-filterRowOperationLessOrEquals": "Menšie alebo rovné",
		"dxDataGrid-filterRowOperationGreater": "Väčšie",
		"dxDataGrid-filterRowOperationGreaterOrEquals": "Väčšie alebo rovné",
		"dxDataGrid-filterRowOperationStartsWith": "Začína na",
		"dxDataGrid-filterRowOperationContains": "Obsahuje",
		"dxDataGrid-filterRowOperationNotContains": "Neobsahuje",
		"dxDataGrid-filterRowOperationEndsWith": "Končí na",
		"dxDataGrid-filterRowOperationBetween": "Medzi",
		"dxDataGrid-filterRowOperationBetweenStartText": "Začína",
		"dxDataGrid-filterRowOperationBetweenEndText": "Končí",
		"dxDataGrid-applyFilterText": "Použiť filter",
		"dxDataGrid-trueText": "Platí",
		"dxDataGrid-falseText": "Neplatí",
		"dxDataGrid-sortingAscendingText": "Porovnať vzostupne",
		"dxDataGrid-sortingDescendingText": "Porovnať zostupne",
		"dxDataGrid-sortingClearText": "Zrušiť rovnanie",
		"dxDataGrid-editingSaveAllChanges": "Uložiť zmeny",
		"dxDataGrid-editingCancelAllChanges": "Zrušiť zmeny",
		"dxDataGrid-editingAddRow": "Pridať riadok",
		"dxDataGrid-summaryMin": "Min: {0}",
		"dxDataGrid-summaryMinOtherColumn": "Min {1} je {0}",
		"dxDataGrid-summaryMax": "Max: {0}",
		"dxDataGrid-summaryMaxOtherColumn": "Max {1} je {0}",
		"dxDataGrid-summaryAvg": "Priem.: {0}",
		"dxDataGrid-summaryAvgOtherColumn": "Priemer zo {1} je {0}",
		"dxDataGrid-summarySum": "Suma: {0}",
		"dxDataGrid-summarySumOtherColumn": "Suma {1} je {0}",
		"dxDataGrid-summaryCount": "Počet: {0}",
		"dxDataGrid-columnFixingFix": "Uchytiť",
		"dxDataGrid-columnFixingUnfix": "Uvoľniť",
		"dxDataGrid-columnFixingLeftPosition": "Vľavo",
		"dxDataGrid-columnFixingRightPosition": "Vpravo",
		"dxDataGrid-exportTo": "Export",
		"dxDataGrid-exportToExcel": "Export do zošita Excel",
		"dxDataGrid-exporting": "Export...",
		"dxDataGrid-excelFormat": "súbor Excel",
		"dxDataGrid-selectedRows": "Vybrané riadky",
		"dxDataGrid-exportSelectedRows": "Export vybraných riadkov",
		"dxDataGrid-exportAll": "Exportovať všetky záznamy",
		"dxDataGrid-headerFilterEmptyValue": "(prázdne)",
		"dxDataGrid-headerFilterOK": "OK",
		"dxDataGrid-headerFilterCancel": "Zrušiť",
		"dxDataGrid-ariaColumn": "Stĺpec",
		"dxDataGrid-ariaValue": "Hodnota",
		"dxDataGrid-ariaFilterCell": "Filtrovať bunku",
		"dxDataGrid-ariaCollapse": "Zbaliť",
		"dxDataGrid-ariaExpand": "Rozbaliť",
		"dxDataGrid-ariaDataGrid": "Dátová mriežka",
		"dxDataGrid-ariaSearchInGrid": "Hľadať v dátovej mriežke",
		"dxDataGrid-ariaSelectAll": "Vybrať všetko",
		"dxDataGrid-ariaSelectRow": "Vybrať riadok",
		"dxDataGrid-filterBuilderPopupTitle": "Tvorba Filtra",
		"dxDataGrid-filterPanelCreateFilter": "Vytvoriť Filter",
		"dxDataGrid-filterPanelClearFilter": "Zmazať",
		"dxDataGrid-filterPanelFilterEnabledHint": "Povoliť Filter",
		"dxTreeList-ariaTreeList": "Hierarchické zobrazenie",
		"dxTreeList-editingAddRowToNode": "Pridať",
		"dxPager-infoText": "Strana {0} zo {1} ({2} položiek)",
		"dxPager-pagesCountText": "zo",
		"dxPager-pageSizesAllText": "Všetko",
		"dxPivotGrid-grandTotal": "Celkom",
		"dxPivotGrid-total": "{0} Celkom",
		"dxPivotGrid-fieldChooserTitle": "Výber poľa",
		"dxPivotGrid-showFieldChooser": "Zobraziť výber poľa",
		"dxPivotGrid-expandAll": "Rozbaliť všetko",
		"dxPivotGrid-collapseAll": "Zbaliť všetko",
		"dxPivotGrid-sortColumnBySummary": "Porovnať \"{0}\" podľa tohto stĺpca",
		"dxPivotGrid-sortRowBySummary": "Porovnať \"{0}\" podľa tohto riadku",
		"dxPivotGrid-removeAllSorting": "Odstrániť všetky triedenia",
		"dxPivotGrid-dataNotAvailable": "nedostupné",
		"dxPivotGrid-rowFields": "Pole riadkov",
		"dxPivotGrid-columnFields": "Pole stĺpcov",
		"dxPivotGrid-dataFields": "Pole dát",
		"dxPivotGrid-filterFields": "Filtrovať pole",
		"dxPivotGrid-allFields": "Všetky polia",
		"dxPivotGrid-columnFieldArea": "Tu vložte pole stĺpcov",
		"dxPivotGrid-dataFieldArea": "Tu vložte pole dát",
		"dxPivotGrid-rowFieldArea": "Tu vložte pole riadkov",
		"dxPivotGrid-filterFieldArea": "Tu vložte filter poľa",
		"dxScheduler-editorLabelTitle": "Predmet",
		"dxScheduler-editorLabelStartDate": "Počiatočný dátum",
		"dxScheduler-editorLabelEndDate": "Koncový dátum",
		"dxScheduler-editorLabelDescription": "Popis",
		"dxScheduler-editorLabelRecurrence": "Opakovať",
		"dxScheduler-openAppointment": "Otvoriť schôdzku",
		"dxScheduler-recurrenceNever": "Nikdy",
		"dxScheduler-recurrenceMinutely": "Minútovo",
		"dxScheduler-recurrenceHourly": "Hodinovo",
		"dxScheduler-recurrenceDaily": "Denne",
		"dxScheduler-recurrenceWeekly": "Týždenne",
		"dxScheduler-recurrenceMonthly": "Mesačne",
		"dxScheduler-recurrenceYearly": "Ročne",
		"dxScheduler-recurrenceRepeatEvery": "Každý",
		"dxScheduler-recurrenceRepeatOn": "Začiatok opakovania",
		"dxScheduler-recurrenceEnd": "Koniec opakovania",
		"dxScheduler-recurrenceAfter": "Po",
		"dxScheduler-recurrenceOn": "Zap",
		"dxScheduler-recurrenceRepeatMinutely": "minút(u)",
		"dxScheduler-recurrenceRepeatHourly": "hodín(u)",
		"dxScheduler-recurrenceRepeatDaily": "dní",
		"dxScheduler-recurrenceRepeatWeekly": "týždňov",
		"dxScheduler-recurrenceRepeatMonthly": "mesiacov",
		"dxScheduler-recurrenceRepeatYearly": "rokov",
		"dxScheduler-switcherDay": "Deň",
		"dxScheduler-switcherWeek": "Týždeň",
		"dxScheduler-switcherWorkWeek": "Pracovný týždeň",
		"dxScheduler-switcherMonth": "Mesiac",
		"dxScheduler-switcherAgenda": "Agenda",
		"dxScheduler-switcherTimelineDay": "Časová os deň",
		"dxScheduler-switcherTimelineWeek": "Časová os týždeň",
		"dxScheduler-switcherTimelineWorkWeek": "Časová os pracovný týždeň",
		"dxScheduler-switcherTimelineMonth": "Časová os mesiac",
		"dxScheduler-recurrenceRepeatOnDate": "na deň",
		"dxScheduler-recurrenceRepeatCount": "výskytov",
		"dxScheduler-allDay": "Celý deň",
		"dxScheduler-confirmRecurrenceEditMessage": "Chcete upraviť iba túto schôdzku alebo celú sériu?",
		"dxScheduler-confirmRecurrenceDeleteMessage": "Chcete zmazať iba túto schôdzku alebo celú sériu?",
		"dxScheduler-confirmRecurrenceEditSeries": "Upraviť sériu",
		"dxScheduler-confirmRecurrenceDeleteSeries": "Zmazať sériu",
		"dxScheduler-confirmRecurrenceEditOccurrence": "Upraviť schôdzku",
		"dxScheduler-confirmRecurrenceDeleteOccurrence": "Zmazať schôdzku",
		"dxScheduler-noTimezoneTitle": "Bez časovej zóny",
		"dxScheduler-moreAppointments": "{0} navyše",
		"dxCalendar-todayButtonText": "Dnes",
		"dxCalendar-ariaWidgetName": "Kalendár",
		"dxColorView-ariaRed": "Červená",
		"dxColorView-ariaGreen": "Zelená",
		"dxColorView-ariaBlue": "Modrá",
		"dxColorView-ariaAlpha": "Priehľadná",
		"dxColorView-ariaHex": "Kód farby",
		"dxTagBox-selected": "{0} vybrané",
		"dxTagBox-allSelected": "Všetko vybrané ({0})",
		"dxTagBox-moreSelected": "{0} navyše",
		"vizExport-printingButtonText": "Tlač",
		"vizExport-titleMenuText": "Export/import",
		"vizExport-exportButtonText": "{0} súborov",
		"dxFilterBuilder-and": "A",
		"dxFilterBuilder-or": "ALEBO",
		"dxFilterBuilder-notAnd": "NAND",
		"dxFilterBuilder-notOr": "NOR",
		"dxFilterBuilder-addCondition": "Pridať podmienku",
		"dxFilterBuilder-addGroup": "Pridať skupinu",
		"dxFilterBuilder-enterValueText": "<vložte hodnotu>",
		"dxFilterBuilder-filterOperationEquals": "Rovná sa",
		"dxFilterBuilder-filterOperationNotEquals": "Nerovná sa",
		"dxFilterBuilder-filterOperationLess": "Menšie ako",
		"dxFilterBuilder-filterOperationLessOrEquals": "Menšie alebo rovné ako",
		"dxFilterBuilder-filterOperationGreater": "Väčšie ako",
		"dxFilterBuilder-filterOperationGreaterOrEquals": "Väčšie alebo rovné ako",
		"dxFilterBuilder-filterOperationStartsWith": "Začína na",
		"dxFilterBuilder-filterOperationContains": "Obsahuje",
		"dxFilterBuilder-filterOperationNotContains": "Neobsahuje",
		"dxFilterBuilder-filterOperationEndsWith": "Končí na",
		"dxFilterBuilder-filterOperationIsBlank": "Je prázdne",
		"dxFilterBuilder-filterOperationIsNotBlank": "Nie je prázdne",
		"dxFilterBuilder-filterOperationBetween": "Medzi",
		"dxFilterBuilder-filterOperationAnyOf": "Ľubovoľný z",
		"dxFilterBuilder-filterOperationNoneOf": "Žiadny z",
		"dxHtmlEditor-dialogUpdateImageCaption": "!TODO",
		"dxHtmlEditor-dialogImageUpdateButton": "!TODO",
		"dxHtmlEditor-dialogImageAddButton": "!TODO",
		"dxHtmlEditor-dialogImageSpecifyUrl": "!TODO",
		"dxHtmlEditor-dialogImageSelectFile": "!TODO",
		"dxHtmlEditor-dialogImageKeepAspectRatio": "!TODO",
		"dxHtmlEditor-dialogImageEncodeToBase64": "!TODO",
		"dxHtmlEditor-background": "TODO",
		"dxHtmlEditor-bold": "TODO",
		"dxHtmlEditor-color": "TODO",
		"dxHtmlEditor-font": "TODO",
		"dxHtmlEditor-italic": "TODO",
		"dxHtmlEditor-link": "TODO",
		"dxHtmlEditor-image": "TODO",
		"dxHtmlEditor-size": "TODO",
		"dxHtmlEditor-strike": "TODO",
		"dxHtmlEditor-subscript": "TODO",
		"dxHtmlEditor-superscript": "TODO",
		"dxHtmlEditor-underline": "TODO",
		"dxHtmlEditor-blockquote": "TODO",
		"dxHtmlEditor-header": "TODO",
		"dxHtmlEditor-increaseIndent": "TODO",
		"dxHtmlEditor-decreaseIndent": "TODO",
		"dxHtmlEditor-orderedList": "TODO",
		"dxHtmlEditor-bulletList": "TODO",
		"dxHtmlEditor-alignLeft": "TODO",
		"dxHtmlEditor-alignCenter": "TODO",
		"dxHtmlEditor-alignRight": "TODO",
		"dxHtmlEditor-alignJustify": "TODO",
		"dxHtmlEditor-codeBlock": "TODO",
		"dxHtmlEditor-variable": "TODO",
		"dxHtmlEditor-undo": "TODO",
		"dxHtmlEditor-redo": "TODO",
		"dxHtmlEditor-clear": "TODO",
		"dxHtmlEditor-insertTable": "TODO",
		"dxHtmlEditor-insertHeaderRow": "TODO",
		"dxHtmlEditor-insertRowAbove": "TODO",
		"dxHtmlEditor-insertRowBelow": "TODO",
		"dxHtmlEditor-insertColumnLeft": "TODO",
		"dxHtmlEditor-insertColumnRight": "TODO",
		"dxHtmlEditor-deleteColumn": "TODO",
		"dxHtmlEditor-deleteRow": "TODO",
		"dxHtmlEditor-deleteTable": "TODO",
		"dxHtmlEditor-cellProperties": "TODO",
		"dxHtmlEditor-tableProperties": "TODO",
		"dxHtmlEditor-insert": "TODO",
		"dxHtmlEditor-delete": "TODO",
		"dxHtmlEditor-border": "TODO",
		"dxHtmlEditor-style": "TODO",
		"dxHtmlEditor-width": "TODO",
		"dxHtmlEditor-height": "TODO",
		"dxHtmlEditor-borderColor": "TODO",
		"dxHtmlEditor-tableBackground": "TODO",
		"dxHtmlEditor-dimensions": "TODO",
		"dxHtmlEditor-alignment": "TODO",
		"dxHtmlEditor-horizontal": "TODO",
		"dxHtmlEditor-vertical": "TODO",
		"dxHtmlEditor-paddingVertical": "TODO",
		"dxHtmlEditor-paddingHorizontal": "TODO",
		"dxHtmlEditor-pixels": "TODO",
		"dxHtmlEditor-list": "TODO",
		"dxHtmlEditor-ordered": "TODO",
		"dxHtmlEditor-bullet": "TODO",
		"dxHtmlEditor-align": "TODO",
		"dxHtmlEditor-center": "TODO",
		"dxHtmlEditor-left": "TODO",
		"dxHtmlEditor-right": "TODO",
		"dxHtmlEditor-indent": "TODO",
		"dxHtmlEditor-justify": "TODO",
		"dxHtmlEditor-dialogColorCaption": "Zmena farby písma",
		"dxHtmlEditor-dialogBackgroundCaption": "Zmena farby pozadia",
		"dxHtmlEditor-dialogLinkCaption": "Vložiť odkaz",
		"dxHtmlEditor-dialogLinkUrlField": "URL",
		"dxHtmlEditor-dialogLinkTextField": "Text",
		"dxHtmlEditor-dialogLinkTargetField": "Otvoriť odkaz v novom okne",
		"dxHtmlEditor-dialogImageCaption": "Vložiť obrázok",
		"dxHtmlEditor-dialogImageUrlField": "URL",
		"dxHtmlEditor-dialogImageAltField": "Alternatívny text",
		"dxHtmlEditor-dialogImageWidthField": "Šírka (px)",
		"dxHtmlEditor-dialogImageHeightField": "Výška (px)",
		"dxHtmlEditor-dialogInsertTableRowsField": "Riadkov",
		"dxHtmlEditor-dialogInsertTableColumnsField": "Stĺpcov",
		"dxHtmlEditor-dialogInsertTableCaption": "Vložiť tabuľku",
		"dxHtmlEditor-heading": "Nadpis",
		"dxHtmlEditor-normalText": "Normálny text",
		"dxFileManager-newDirectoryName": "Nepomenovaná zložka",
		"dxFileManager-rootDirectoryName": "Súbory",
		"dxFileManager-errorNoAccess": "Prístup zablokovaný. Operácia nemôže byť dokončená.",
		"dxFileManager-errorDirectoryExistsFormat": "Zložka '{0}' už existuje.",
		"dxFileManager-errorFileExistsFormat": "Súbor '{0}' už existuje.",
		"dxFileManager-errorFileNotFoundFormat": "Súbor '{0}' nenájdený.",
		"dxFileManager-errorDirectoryNotFoundFormat": "Priečinok '{0}' nebol nájdený.",
		"dxFileManager-errorWrongFileExtension": "Typ súboru nie je povolený. Zakázaná prípona",
		"dxFileManager-errorMaxFileSizeExceeded": "Súbor prekročil maximálnu povolenú veľkosť.",
		"dxFileManager-errorInvalidSymbols": "Zadaný názov obsahuje nepovolené znaky.",
		"dxFileManager-errorDefault": "Neznáma chyba.",
		"dxFileManager-errorDirectoryOpenFailed": "Nie je možné otvoriť zložku",
		"dxFileManager-commandCreate": "Nová zložka",
		"dxFileManager-commandRename": "Premenovať",
		"dxFileManager-commandMove": "Presunúť",
		"dxFileManager-commandCopy": "Kopírovať",
		"dxFileManager-commandDelete": "Zmazať",
		"dxFileManager-commandDownload": "Stiahnuť",
		"dxFileManager-commandUpload": "Nahrať súbory",
		"dxFileManager-commandRefresh": "Obnoviť",
		"dxFileManager-commandThumbnails": "Náhľady súborov",
		"dxFileManager-commandDetails": "Detailný prehľad",
		"dxFileManager-commandClearSelection": "Zrušiť výber",
		"dxFileManager-commandShowNavPane": "Prepnúť navigačný panel",
		"dxFileManager-dialogDirectoryChooserMoveTitle": "Presunúť do",
		"dxFileManager-dialogDirectoryChooserMoveButtonText": "Presunúť",
		"dxFileManager-dialogDirectoryChooserCopyTitle": "Kopírovať do",
		"dxFileManager-dialogDirectoryChooserCopyButtonText": "Kopírovať",
		"dxFileManager-dialogRenameItemTitle": "Premenovať",
		"dxFileManager-dialogRenameItemButtonText": "Uložiť",
		"dxFileManager-dialogCreateDirectoryTitle": "Nová zložka",
		"dxFileManager-dialogCreateDirectoryButtonText": "Vytvoriť",
		"dxFileManager-dialogDeleteItemTitle": "Odstrániť",
		"dxFileManager-dialogDeleteItemButtonText": "Odstrániť",
		"dxFileManager-dialogDeleteItemSingleItemConfirmation": "Naozaj chcete odstrániť {0}?",
		"dxFileManager-dialogDeleteItemMultipleItemsConfirmation": "Naozaj chcete odstrániť {0} položiek?",
		"dxFileManager-dialogButtonCancel": "Storno",
		"dxFileManager-editingCreateSingleItemProcessingMessage": "Vytvára sa zložka v {0}",
		"dxFileManager-editingCreateSingleItemSuccessMessage": "Vytvorená zložka v {0}",
		"dxFileManager-editingCreateSingleItemErrorMessage": "Priečinok nebol vytvorený",
		"dxFileManager-editingCreateCommonErrorMessage": "Priečinok nebol vytvorený",
		"dxFileManager-editingRenameSingleItemProcessingMessage": "Premenovanie položky v {0}",
		"dxFileManager-editingRenameSingleItemSuccessMessage": "Premenovaná položka v {0}",
		"dxFileManager-editingRenameSingleItemErrorMessage": "Položka nebola premenovaná",
		"dxFileManager-editingRenameCommonErrorMessage": "Položka nebola premenovaná",
		"dxFileManager-editingDeleteSingleItemProcessingMessage": "Odstraňovanie položky z {0}",
		"dxFileManager-editingDeleteMultipleItemsProcessingMessage": "Odstraňuje sa {0} položiek z {1}",
		"dxFileManager-editingDeleteSingleItemSuccessMessage": "Odstránená položka z {0}",
		"dxFileManager-editingDeleteMultipleItemsSuccessMessage": "Odstránené {0} položiek z {1}",
		"dxFileManager-editingDeleteSingleItemErrorMessage": "Položka nebola odstránená",
		"dxFileManager-editingDeleteMultipleItemsErrorMessage": "{0} položiek nebolo odstránené",
		"dxFileManager-editingDeleteCommonErrorMessage": "Niektoré z položiek neboli odstránené",
		"dxFileManager-editingMoveSingleItemProcessingMessage": "Presúvanie položky do {0}",
		"dxFileManager-editingMoveMultipleItemsProcessingMessage": "Presúvanie {0} položiek do {1}",
		"dxFileManager-editingMoveSingleItemSuccessMessage": "Položka presunutá do {0}",
		"dxFileManager-editingMoveMultipleItemsSuccessMessage": "Presunuté {0} položiek do {1}",
		"dxFileManager-editingMoveSingleItemErrorMessage": "Položka nebola presunutá",
		"dxFileManager-editingMoveMultipleItemsErrorMessage": "{0} položiek nebolo presunuté",
		"dxFileManager-editingMoveCommonErrorMessage": "Niektoré položky neboli presunuté",
		"dxGantt-dialogConstraintCriticalViolationSeveralTasksMessage": "TODO",
		"dxGantt-dialogConstraintViolationSeveralTasksMessage": "TODO",
		"dxGantt-dialogDeleteDependenciesMessage": "TODO",
		"dxGantt-dialogMoveTaskAndKeepDependenciesMessage": "TODO",
		"dxGantt-quarter": "TODO",
		"dxGantt-sortingAscendingText": "Porovnať vzostupne",
		"dxGantt-sortingDescendingText": "Porovnať zostupne",
		"dxGantt-sortingClearText": "Zrušiť rovnanie",
		"dxGantt-showResources": "TODO",
		"dxGantt-showDependencies": "TODO",
		"dxGantt-dialogStartDateValidation": "TODO",
		"dxGantt-dialogEndDateValidation": "TODO",
		"dxFileManager-editingCopySingleItemProcessingMessage": "Kopírovanie položky do {0}",
		"dxFileManager-editingCopyMultipleItemsProcessingMessage": "Kopírovanie {0} položiek do {1}",
		"dxFileManager-editingCopySingleItemSuccessMessage": "Kopíruje sa položka do {0}",
		"dxFileManager-editingCopyMultipleItemsSuccessMessage": "Skopírované {0} položiek do {1}",
		"dxFileManager-editingCopySingleItemErrorMessage": "Položka nebola skopírovaná",
		"dxFileManager-editingCopyMultipleItemsErrorMessage": "{0} položiek nebolo skopírované",
		"dxFileManager-editingCopyCommonErrorMessage": "Niektoré položky neboli skopírované",
		"dxFileManager-editingUploadSingleItemProcessingMessage": "Nahráva sa položka {0}",
		"dxFileManager-editingUploadMultipleItemsProcessingMessage": "Nahráva sa {0} položiek do {1}",
		"dxFileManager-editingUploadSingleItemSuccessMessage": "Položka nahraná do {0}",
		"dxFileManager-editingUploadMultipleItemsSuccessMessage": "Nahrané {0} položiek do {1}",
		"dxFileManager-editingUploadSingleItemErrorMessage": "Položka nebola nahratá",
		"dxFileManager-editingUploadMultipleItemsErrorMessage": "{0} položiek nebolo nahrané",
		"dxFileManager-editingUploadCanceledMessage": "Stornované",
		"dxFileManager-listDetailsColumnCaptionName": "Názov",
		"dxFileManager-listDetailsColumnCaptionDateModified": "Dátum zmeny",
		"dxFileManager-listDetailsColumnCaptionFileSize": "Veľkosť súboru",
		"dxFileManager-listThumbnailsTooltipTextSize": "Veľkosť",
		"dxFileManager-listThumbnailsTooltipTextDateModified": "Dátum zmeny",
		"dxFileManager-notificationProgressPanelTitle": "Priebeh",
		"dxFileManager-notificationProgressPanelEmptyListText": "Žiadna operácia",
		"dxFileManager-notificationProgressPanelOperationCanceled": "Stornované",
		"dxDiagram-categoryGeneral": "Všeobecné",
		"dxDiagram-categoryFlowchart": "Flowchart",
		"dxDiagram-categoryOrgChart": "Organizačný graf",
		"dxDiagram-categoryContainers": "Kontajnery",
		"dxDiagram-categoryCustom": "Vlastné",
		"dxDiagram-commandExportToSvg": "Export do SVG",
		"dxDiagram-commandExportToPng": "Export do PNG",
		"dxDiagram-commandExportToJpg": "Export do JPEG",
		"dxDiagram-commandUndo": "Späť",
		"dxDiagram-commandRedo": "Znovu",
		"dxDiagram-commandFontName": "Písmo",
		"dxDiagram-commandFontSize": "Veľkosť písma",
		"dxDiagram-commandBold": "Tučné",
		"dxDiagram-commandItalic": "Kurzíva",
		"dxDiagram-commandUnderline": "Podčiarknuté",
		"dxDiagram-commandTextColor": "Farba písma",
		"dxDiagram-commandLineColor": "Farba čiary",
		"dxDiagram-commandLineWidth": "Šírka čiary",
		"dxDiagram-commandLineStyle": "Štýl čiary",
		"dxDiagram-commandLineStyleSolid": "Plná",
		"dxDiagram-commandLineStyleDotted": "Bodkovaná",
		"dxDiagram-commandLineStyleDashed": "Čiarkovaná",
		"dxDiagram-commandFillColor": "Farba výplne",
		"dxDiagram-commandAlignLeft": "Zarovnať vľavo",
		"dxDiagram-commandAlignCenter": "Zarovnať na stred",
		"dxDiagram-commandAlignRight": "Zarovnať vpravo",
		"dxDiagram-commandConnectorLineType": "Typ čiary",
		"dxDiagram-commandConnectorLineStraight": "Priama",
		"dxDiagram-commandConnectorLineOrthogonal": "Lomená",
		"dxDiagram-commandConnectorLineStart": "Tvar na začiatku",
		"dxDiagram-commandConnectorLineEnd": "Tvar na konci",
		"dxDiagram-commandConnectorLineNone": "Žiadna",
		"dxDiagram-commandConnectorLineArrow": "Šípka",
		"dxDiagram-commandFullscreen": "Celá obrazovka",
		"dxDiagram-commandUnits": "Jednotky",
		"dxDiagram-commandPageSize": "Veľkosť stránky",
		"dxDiagram-commandPageOrientation": "Orientácia stránky",
		"dxDiagram-commandPageOrientationLandscape": "Na výšku",
		"dxDiagram-commandPageOrientationPortrait": "Na šírku",
		"dxDiagram-commandPageColor": "Farba stránky",
		"dxDiagram-commandShowGrid": "Zobraziť mriežku",
		"dxDiagram-commandSnapToGrid": "Prichytiť k mriežke",
		"dxDiagram-commandGridSize": "Veľkosť mriežky",
		"dxDiagram-commandZoomLevel": "Mierka",
		"dxDiagram-commandAutoZoom": "Automaticky",
		"dxDiagram-commandFitToContent": "Prispôsobiť obsahu",
		"dxDiagram-commandFitToWidth": "Prispôsobiť šírke",
		"dxDiagram-commandAutoZoomByContent": "Automaticky prispôsobovať obsahu",
		"dxDiagram-commandAutoZoomByWidth": "Automaticky prispôsobovať šírke",
		"dxDiagram-commandSimpleView": "Jednoduché zobrazenie",
		"dxDiagram-commandCut": "Vystrihnúť",
		"dxDiagram-commandCopy": "Kopírovať",
		"dxDiagram-commandPaste": "Vložiť",
		"dxDiagram-commandSelectAll": "Vybrať všetko",
		"dxDiagram-commandDelete": "Odstrániť",
		"dxDiagram-commandBringToFront": "Preniesť do popredia",
		"dxDiagram-commandSendToBack": "Preniesť do pozadia",
		"dxDiagram-commandLock": "Zamknúť",
		"dxDiagram-commandUnlock": "Odomknúť",
		"dxDiagram-commandInsertShapeImage": "Vložiť obrázok...",
		"dxDiagram-commandEditShapeImage": "Zmeniť obrázok...",
		"dxDiagram-commandDeleteShapeImage": "Odstrániť obrázok",
		"dxDiagram-commandLayoutLeftToRight": "Zľava-do-prava",
		"dxDiagram-commandLayoutRightToLeft": "Sprava-do-ľava",
		"dxDiagram-commandLayoutTopToBottom": "Zhora-dole",
		"dxDiagram-commandLayoutBottomToTop": "Zdola-hore",
		"dxDiagram-unitIn": "in",
		"dxDiagram-unitCm": "cm",
		"dxDiagram-unitPx": "px",
		"dxDiagram-dialogButtonOK": "Ok",
		"dxDiagram-dialogButtonCancel": "Zrušiť",
		"dxDiagram-dialogInsertShapeImageTitle": "Vložiť obrázok",
		"dxDiagram-dialogEditShapeImageTitle": "Zmeniť obrázok",
		"dxDiagram-dialogEditShapeImageSelectButton": "Vyberte obrázok",
		"dxDiagram-dialogEditShapeImageLabelText": "alebo pretiahnite súbor tu",
		"dxDiagram-uiExport": "Export",
		"dxDiagram-uiProperties": "Vlastnosti",
		"dxDiagram-uiSettings": "Nastavenia",
		"dxDiagram-uiShowToolbox": "Zobraziť panel nástrojov",
		"dxDiagram-uiSearch": "Hľadať",
		"dxDiagram-uiStyle": "Štýl",
		"dxDiagram-uiLayout": "Rozvrhnutie",
		"dxDiagram-uiLayoutTree": "Strom",
		"dxDiagram-uiLayoutLayered": "Organizácia",
		"dxDiagram-uiDiagram": "Diagram",
		"dxDiagram-uiText": "Text",
		"dxDiagram-uiObject": "Objekt",
		"dxDiagram-uiConnector": "Konektor",
		"dxDiagram-uiPage": "Stránka",
		"dxDiagram-shapeText": "Text",
		"dxDiagram-shapeRectangle": "Obdĺžnik",
		"dxDiagram-shapeEllipse": "Elipsa",
		"dxDiagram-shapeCross": "Kríž",
		"dxDiagram-shapeTriangle": "Trojuholník",
		"dxDiagram-shapeDiamond": "Diamant",
		"dxDiagram-shapeHeart": "Srdce",
		"dxDiagram-shapePentagon": "Päťuholník",
		"dxDiagram-shapeHexagon": "Šesťuholník",
		"dxDiagram-shapeOctagon": "Osemuholník",
		"dxDiagram-shapeStar": "Hviezda",
		"dxDiagram-shapeArrowLeft": "Šípka vľavo",
		"dxDiagram-shapeArrowUp": "Šípka hore",
		"dxDiagram-shapeArrowRight": "Šípka vpravo",
		"dxDiagram-shapeArrowDown": "Šípka dole",
		"dxDiagram-shapeArrowUpDown": "Šípka hore a dole",
		"dxDiagram-shapeArrowLeftRight": "Šípka vľavo a vpravo",
		"dxDiagram-shapeProcess": "Proces",
		"dxDiagram-shapeDecision": "Rozhodnutie",
		"dxDiagram-shapeTerminator": "Ukončenie",
		"dxDiagram-shapePredefinedProcess": "Podprogram",
		"dxDiagram-shapeDocument": "Dokument",
		"dxDiagram-shapeMultipleDocuments": "Dokumenty",
		"dxDiagram-shapeManualInput": "Manuálny vstup",
		"dxDiagram-shapePreparation": "Príprava",
		"dxDiagram-shapeData": "Dáta",
		"dxDiagram-shapeDatabase": "Databáza",
		"dxDiagram-shapeHardDisk": "Pevný disk",
		"dxDiagram-shapeInternalStorage": "Interné úložisko",
		"dxDiagram-shapePaperTape": "Papierová páska",
		"dxDiagram-shapeManualOperation": "Manuálna operácia",
		"dxDiagram-shapeDelay": "Oneskorenie",
		"dxDiagram-shapeStoredData": "Uložené údaje",
		"dxDiagram-shapeDisplay": "Zobraziť",
		"dxDiagram-shapeMerge": "Zlúčiť",
		"dxDiagram-shapeConnector": "Konektor",
		"dxDiagram-shapeOr": "Alebo",
		"dxDiagram-shapeSummingJunction": "A zároveň",
		"dxDiagram-shapeContainerDefaultText": "Kontajner",
		"dxDiagram-shapeVerticalContainer": "Vertikálny kontajner",
		"dxDiagram-shapeHorizontalContainer": "Horizontálny kontajner",
		"dxDiagram-shapeCardDefaultText": "Karta osoby",
		"dxDiagram-shapeCardWithImageOnLeft": "Karta s obrázkom vľavo",
		"dxDiagram-shapeCardWithImageOnTop": "Karta s obrázkom hore",
		"dxDiagram-shapeCardWithImageOnRight": "Karta s obrázkom vpravo",
		"dxGantt-dialogTitle": "Názov",
		"dxGantt-dialogStartTitle": "Začiatok",
		"dxGantt-dialogEndTitle": "Koniec",
		"dxGantt-dialogProgressTitle": "Priebeh",
		"dxGantt-dialogResourcesTitle": "Zdroje",
		"dxGantt-dialogResourceManagerTitle": "Správca zdrojov",
		"dxGantt-dialogTaskDetailsTitle": "Detail úlohy",
		"dxGantt-dialogEditResourceListHint": "Editovať zoznam zdrojov",
		"dxGantt-dialogEditNoResources": "Žiadne zdroje",
		"dxGantt-dialogButtonAdd": "Pridať",
		"dxGantt-contextMenuNewTask": "Nová úloha",
		"dxGantt-contextMenuNewSubtask": "Nový podúloh",
		"dxGantt-contextMenuDeleteTask": "Odstrániť úlohu",
		"dxGantt-contextMenuDeleteDependency": "Odstrániť závislosť",
		"dxGantt-dialogTaskDeleteConfirmation": "Odstránením úlohy dôjde tiež k odstráneniu všetkých podriadených úloh. Naozaj si prajete úlohu odstrániť?",
		"dxGantt-dialogDependencyDeleteConfirmation": "Naozaj si prajete odstrániť závislosť z úlohy?",
		"dxGantt-dialogResourcesDeleteConfirmation": "Odstránením zdroja dôjde tiež k jeho odstráneniu zo všetkých úloh, ku ktorým je zdroj pridelený Naozaj si prajete zdroj odstrániť? Počet zdrojov: {0}",
		"dxGantt-dialogConstraintCriticalViolationMessage": "Úloha, ktorú sa pokúšate presunúť je prepojená s ďalšou úlohou pomocou závislosti. Táto zmena môže spôsobiť konflikt medzi pravidlami závislostí. Ako si prajete pokračovať?",
		"dxGantt-dialogConstraintViolationMessage": "Úloha, ktorú sa pokúšate presunúť je prepojená s ďalšou úlohou pomocou závislosti. Ako si prajete pokračovať?",
		"dxGantt-dialogCancelOperationMessage": "Stornovať operáciu",
		"dxGantt-dialogDeleteDependencyMessage": "Odstrániť závislosť",
		"dxGantt-dialogMoveTaskAndKeepDependencyMessage": "Presunúť úlohu a zachovať závislosť",
		"dxGantt-undo": "Späť",
		"dxGantt-redo": "Znovu",
		"dxGantt-expandAll": "Rozbaliť všetko",
		"dxGantt-collapseAll": "Zbaliť všetko",
		"dxGantt-addNewTask": "Pridať novú úlohu",
		"dxGantt-deleteSelectedTask": "Odstrániť vybranú úlohu",
		"dxGantt-zoomIn": "Priblížiť",
		"dxGantt-zoomOut": "Oddialiť",
		"dxGantt-fullScreen": "Celá obrazovka"
	}
};
