import * as moment from 'moment';
import {ITourPlanSearchRequest} from '@retrixhouse/salesapp-shared/lib/requests';
import {
  TourPlanState,
  TourPlanApprovedState,
} from '@retrixhouse/salesapp-shared/lib/models';

export class TourPlanSearchRequest implements ITourPlanSearchRequest {
  dateFrom: Date;
  dateTo: Date;
  customerId?: string;
  projectIds?: string[];
  storeIds?: string[];
  typeIds?: string[];
  states?: TourPlanState[];
  executorIds: string[];
  superiorIds?: string[];
  chainIds: string[];
  city?: string;
  fetchStore?: boolean;
  superiorLevels: number;
  approved?: TourPlanApprovedState;

  constructor() {
    this.dateFrom = moment().startOf('week').toDate();
    this.dateTo = moment().endOf('week').toDate();
    this.superiorLevels = 1;
  }
}
