import {Injectable} from '@angular/core';
import {ClientMode} from '../enums/client-mode.enum';
import {BehaviorSubject, Observable} from 'rxjs';
import {
  AppInterfaceService,
  AppPlatform,
} from '../app-interface/app-interface.service';
import {ConnectionSpeed, ConnectionType} from '../enums/connection-type.enum';

/**
 * Service providing functionality for switching online and offline mode
 * for mobile devices
 */
@Injectable()
export class ClientModeService {
  public static readonly CLIENT_MODE_STORAGE_KEY = 'g:clientMode';

  private _connectionType: ConnectionType;
  private _clientMode = ClientMode.ONLINE;
  private _clientModeSubject = new BehaviorSubject<ClientMode>(
    ClientMode.ONLINE,
  );

  constructor(private appInterfaceService: AppInterfaceService) {
    this._connectionType = ConnectionType.UNKNOWN;
    if (
      this.appInterfaceService.platform === AppPlatform.AndroidApp ||
      this.appInterfaceService.platform === AppPlatform.IosApp
    ) {
      this.appInterfaceService.onConnectivity().subscribe({
        next: params => {
          this._connectionType = params?.type ?? ConnectionType.UNKNOWN;
          console.log('===> ClientModeService onConnectivity', params);
        },
      });
    }
  }

  public get clientMode(): ClientMode {
    return this._clientMode;
  }

  public get isClientOnline(): boolean {
    return this._clientMode === ClientMode.ONLINE;
  }

  public set clientMode(newMode: ClientMode) {
    if (this._clientMode === newMode) {
      return;
    }

    this._clientMode = newMode;

    this.appInterfaceService.storageSave({
      [ClientModeService.CLIENT_MODE_STORAGE_KEY]: newMode,
    });
    this._clientModeSubject.next(this._clientMode);
  }

  public clientModeChangeEvent(): Observable<ClientMode> {
    return this._clientModeSubject;
  }

  private get connectionType(): ConnectionType {
    return this._connectionType;
    /*
    let connectionType = ConnectionType.UNKNOWN;
    if (connection) {
      if (connection.type === 'wifi') {
        connectionType = ConnectionType.WIFI;
      } else if (connection.type === 'cellular') {
        // @ts-ignore
        const type = (connection?.effectiveType as string)?.toLowerCase();
        if (type === '5g') {
          connectionType = ConnectionType.CT_5G;
        } else if (type === '4g') {
          connectionType = ConnectionType.CT_4G;
        } else if (type === '3g') {
          connectionType = ConnectionType.CT_3G;
        } else if (type.includes('2g')) {
          connectionType = ConnectionType.CT_2G;
        } else {
          connectionType = ConnectionType.UNKNOWN;
        }
      } else if (connection.type === 'none') {
        connectionType = ConnectionType.NONE;
      } else {
        connectionType = ConnectionType.UNKNOWN;
      }
    }

    return connectionType;*/
  }

  public get connectionSpeed(): ConnectionSpeed {
    if (this.connectionType === ConnectionType.NONE) {
      return ConnectionSpeed.NONE;
    } else if (
      [ConnectionType.CT_2G, ConnectionType.CT_3G].includes(this.connectionType)
    ) {
      return ConnectionSpeed.SLOW;
    } else {
      // wifi, 4G, 5G, unknown
      return ConnectionSpeed.OK;
    }
  }
}
