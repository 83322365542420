import {GenericListItem} from './generic-list.model';
import {Address} from './address.model';
import * as uuid from 'uuid';
import {Chain} from './chain.model';
import {isNumberOrString} from '../utils/utils';

export class OpeningHours {
  id: string;
  storeId: string;
  nonstop: boolean;
  dayOfWeek: string;
  from: string;
  to: string;

  constructor() {
    this.id = uuid.v4();
    this.storeId = null;
    this.nonstop = false;
    this.dayOfWeek = null;
    this.from = null;
    this.to = null;
  }
}

export class Store {
  id: string;
  uid: string;
  version: number;
  name: string;
  chainId: string;
  chain: Chain;
  validFrom?: Date;
  validTo?: Date;
  chainSpecificId: string;
  subChainId: string;
  subChain: GenericListItem;
  segmentId: string;
  segment: GenericListItem;
  agencyRegionId: string;
  agencyRegion: GenericListItem;
  typeId: string;
  type: GenericListItem;
  sizeId: string;
  size: GenericListItem;
  parkingLot: boolean;
  addressId: string;
  address: Address;
  latitude: number;
  longitude: number;
  altitude: number;
  notes: string;
  openingHours: OpeningHours[];
  googlePlacesId?: string;
  extendedProperties: any;

  [key: string]: any;

  constructor() {
    this.id = uuid.v4();
    this.name = null;
    this.chainId = null;
    this.validFrom = undefined;
    this.validTo = undefined;
    this.chainSpecificId = null;
    this.subChainId = null;
    this.segmentId = null;
    this.agencyRegionId = null;
    this.typeId = null;
    this.sizeId = null;
    this.parkingLot = false;
    this.addressId = null;
    this.latitude = null;
    this.longitude = null;
    this.altitude = null;
    this.notes = null;
    this.openingHours = [];
  }

  public static formatDateProperties(s: Store): void {
    if (isNumberOrString(s.validFrom)) {
      s.validFrom = new Date(s.validFrom);
    }

    if (isNumberOrString(s.validTo)) {
      s.validTo = new Date(s.validTo);
    }
  }
}
