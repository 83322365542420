import {Injectable} from '@angular/core';
import {BaseOfflineService} from './base.offline-service';
import {ISystemDataService} from '../../interfaces/data-service';

@Injectable()
export class SystemOfflineService
  extends BaseOfflineService
  implements ISystemDataService {
  refreshCaches(caches: string[]): Promise<string[]> {
    return Promise.reject('Offline service - not yet implemented');
  }
}
