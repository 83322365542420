import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {InputAddressComponent} from './input-address.component';
import {InputTextModule} from '../input-text';
import {InputSelectModule} from '../input-select';
import {TranslateModule} from '@ngx-translate/core';
import {ReactiveFormsModule} from '@angular/forms';
import {FormModule} from '../../modules/form';

const FORM_CONTROLS_MODULES = [InputSelectModule, InputTextModule];

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    FormModule,
    ...FORM_CONTROLS_MODULES,
  ],
  declarations: [InputAddressComponent],
  exports: [InputAddressComponent],
})
export class InputAddressModule {}

export * from './input-address.component';
