import {PhotoResult} from '../app-interface/response-model';
import {BaseModel} from './base.model';
import {Comment} from './comment.model';
import {v4 as uuid} from 'uuid';
import {SettingNames} from '@retrixhouse/salesapp-shared/lib/settings';
import {PhotoTag} from './photo-tag.model';
import {User} from './user.model';
import {safeParseInt} from '@retrixhouse/salesapp-shared/lib/utils';
import {
  IPhoto,
  IPhotoWithRelations,
  PhotoSource,
} from '@retrixhouse/salesapp-shared/lib/models';

export class Photo extends BaseModel implements IPhoto {
  uri: string;
  uriThumbnail: string;
  sha256: string;
  source?: PhotoSource;
  fileName: string;
  originalFileName?: string;
  fileSize: number;
  width: number;
  height: number;
  uploadedAt: Date;
  uploadedByUserId: string;
  objectId: string;
  exif?: any;

  projectId?: string;
  tourPlanId?: string;
  storeId?: string;
  todoListResultId?: string;
  todoActionResultId?: string;
  questionnaireResultId?: string;
  questionnaireItemId?: string;
  questionnaireAnswerId?: string;
  promoActionId?: string;
  personalArrangementId?: string;
  feedbackId?: string;

  commentList?: Comment[];
  photoTagList?: PhotoTag[];
  uploadedBy?: User;
}

export class ExtendedPhoto extends Photo {
  isStoragePhoto?: boolean;
  isSelected: boolean;
  photoIndex: number;
  visitId: string;
  blobLink: string;
}

export class PhotoGalleryInputPhoto extends Photo {
  isStoragePhoto?: boolean;
  imageUrl: string;
  imageThumbnailUrl: string;
}

export type MobilePhoto = {
  photo: Photo;
  base64?: string;
  isUploaded?: boolean;
};

export class MobilePhotoChunk {
  photoId: string;
  visitId: string;
  data: string;
  numberOfChunks: number;
  currentChunk: number;
}

export class PhotoTagComment {
  photoId: string;
  commentList: Comment[];
  tagList: PhotoTag[];
}

export const PHOTO_STORAGE_EXTENSION = 'jpg';

export function prepareMobilePhoto(
  photoResult: PhotoResult,
  objectId: string,
  userId: string,
  source: PhotoSource,
): MobilePhoto {
  let photoId = uuid();
  if (photoResult.uuid) {
    photoId = photoResult.uuid;
  }

  // capturedPhoto?.fileData.substring(
  //   'data:image/'.length,
  //   capturedPhoto?.fileData.indexOf(';base64'),
  // );

  const photoName = `${photoId}.${PHOTO_STORAGE_EXTENSION}`;
  const photo: Photo = {
    id: photoId,
    uploadedByUserId: userId,
    sha256: photoResult.checksum ?? '<sha256>',
    uri: `/photo-storage/${photoName}?t=${new Date().getTime()}`,
    uriThumbnail: `/photo-storage/${photoName}?t=${new Date().getTime()}`,
    source: source,
    fileName: photoName,
    originalFileName: photoResult.fileName,
    fileSize: 0, // will be set on back-end
    height:
      photoResult?.height ??
      photoResult?.metaData?.height ??
      safeParseInt(photoResult?.metaData?.exif?.ImageHeight) ??
      safeParseInt(photoResult?.metaData?.exif?.ExifImageHeight) ??
      0,
    width:
      photoResult?.width ??
      photoResult?.metaData?.width ??
      safeParseInt(photoResult?.metaData?.exif?.ImageWidth) ??
      safeParseInt(photoResult?.metaData?.exif?.ExifImageWidth) ??
      0,
    objectId: objectId,
    uploadedAt: new Date(), // will be set on back-end
    exif: photoResult?.metaData?.exif,
  };

  return <MobilePhoto>{
    base64: photoResult.fileData,
    photo: photo,
  };
}

export const validateMobilePhoto = (
  photoResult: PhotoResult,
  photosSettings: {[prop: string]: any},
): true | TranslationInstant => {
  let instant: TranslationInstant;

  const allowedMaxFileSize =
    photosSettings[SettingNames.PhotoGallery_MaxFileSize];
  if (allowedMaxFileSize) {
    // see: https://stackoverflow.com/questions/13378815/base64-length-calculation
    // see: https://stackoverflow.com/questions/34109053/what-file-size-is-data-if-its-450kb-base64-encoded/34109103#34109103
    // we used the following way to calculate image size from base64 string eventhough it is 100% not accurate.
    // the reason is that we don't want to put a lot of load on the client
    const fileSizeWithPadding = ((photoResult?.fileData?.length ?? 0) / 4) * 3;
    const paddingCount =
      photoResult?.fileData?.match(new RegExp('=', 'g'))?.length ?? 0;
    const totalSize = fileSizeWithPadding - paddingCount;
    if (totalSize > allowedMaxFileSize) {
      instant = {
        key: 'views.photo-object.file-size-exceeds',
      };
      return instant;
    }
  }

  const allowedMinHeight = photosSettings[SettingNames.PhotoGallery_MinHeight];
  const allowedMinWidth = photosSettings[SettingNames.PhotoGallery_MinWidth];

  const imageHeight =
    photoResult?.metaData?.exif?.ImageHeight ??
    photoResult?.metaData?.exif?.ExifImageHeight;
  const imageWidth =
    photoResult?.metaData?.exif?.ImageHeight ??
    photoResult?.metaData?.exif?.ExifImageWidth;

  if (imageHeight < allowedMinHeight || imageWidth < allowedMinWidth) {
    instant = {
      key: 'views.photo-object.wrong-image-height-width',
      interpolateParams: {
        width: allowedMinWidth,
        height: allowedMinHeight,
      },
    };

    return instant;
  }

  return true;
};

/**
 * Fix the rotation of a provided photo by rotating it to the normal position.
 * @see https://sirv.com/help/articles/rotate-photos-to-be-upright/
 * @param capturedPhoto
 */
export const shouldRotate = async (capturedPhoto: PhotoResult) => {
  const metaData = capturedPhoto?.metaData;
  // let orientation: 'landscape' | 'portrait';
  let shouldRotate: 'left' | 'right' | '180';
  // const width: number = metaData.width ?? metaData?.exif?.ImageWidth;
  // const height: number = metaData.height ?? metaData?.exif?.ImageLength;

  if (metaData?.exif) {
    Object.keys(metaData?.exif).forEach(k => {
      if (k.toLowerCase().includes('orientation')) {
        const exif = metaData?.exif;
        const orientation =
          typeof exif[k] === 'string' ? `${exif[k]}`.toLowerCase() : exif[k];

        switch (orientation) {
          case 1:
            // If exif is 1, then the photo is displayed correctly.
            break;

          case 2:
          case 4:
          case 5:
          case 7:
            // cannot be handled
            break;

          case 3:
          case '3':
          case 'rotate 180':
            shouldRotate = '180';
            break;

          case 6:
          case '6':
          case 'rotate 90 cw':
            shouldRotate = 'right';

            break;

          case 8:
          case '8':
          case 'rotate 270 cw':
            shouldRotate = 'left';

            break;
        }
      }
    });
    return shouldRotate;
  }
};

export type TranslationInstant = {
  key: string | Array<string>;
  interpolateParams?: Object;
};

export class PhotoHelpers {
  private _photos: IPhotoWithRelations[] = [];

  constructor(photos: IPhotoWithRelations[]) {
    this._photos = photos;
  }

  get allPhotos() {
    return this._photos;
  }

  addOne(photos: IPhotoWithRelations[]) {
    this._photos.push(...photos);
  }

  getOne(id: string): IPhotoWithRelations {
    return this._photos.find(f => f.id === id);
  }

  getOneByIndex(index: number): IPhotoWithRelations {
    return this._photos.find((ph, indx) => indx === index);
  }

  getMulti(ids: string[]) {
    return this._photos.filter(ph => ids.includes(ph.id));
  }

  updateOne(id: string, values: Partial<IPhotoWithRelations>) {
    const photo = this.getOne(id);
    Object.assign(photo, values);
  }

  count() {
    return this._photos.length;
  }
}

export const getPurePhoto = (photo: Photo): Photo => {
  if (!photo) {
    return undefined;
  }

  return {
    id: photo.id,
    sha256: photo.sha256,
    fileName: photo.fileName,
    originalFileName: photo.originalFileName,
    fileSize: photo.fileSize,
    height: photo.height,
    objectId: photo.objectId,
    uploadedAt: photo.uploadedAt,
    uploadedByUserId: photo.uploadedByUserId,
    uri: photo.uri,
    uriThumbnail: photo.uriThumbnail,
    width: photo.width,
    source: photo.source,
    projectId: photo.projectId,
    tourPlanId: photo.tourPlanId,
    storeId: photo.storeId,
    todoActionResultId: photo.todoActionResultId,
    todoListResultId: photo.todoListResultId,
    questionnaireResultId: photo.questionnaireResultId,
    questionnaireItemId: photo.questionnaireItemId,
    questionnaireAnswerId: photo.questionnaireAnswerId,
    promoActionId: photo.promoActionId,
    personalArrangementId: photo.personalArrangementId,
    feedbackId: photo.feedbackId,
    exif: photo.exif,
  };
};
