import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseCrudHttpService} from './base.http-service';
import {GenericList} from '../../models';
import {IGenericListDataService} from '../../interfaces/data-service';

@Injectable()
export class GenericListHttpService
  extends BaseCrudHttpService<GenericList>
  implements IGenericListDataService
{
  constructor(http: HttpClient) {
    super(http, '/core/generic-lists');
  }
  getSingleForEdit(id: string): Promise<GenericList> {
    return this.GET<GenericList>(`${this.endpoint.toLowerCase()}/${id}`, {
      params: {all: true},
    });
  }
}
