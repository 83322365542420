import {ChangeDetectorRef, Component, Input} from '@angular/core';
import DevExpress from 'devextreme';
import {Format} from 'devextreme/common';
import {ControlValueAccessor} from '../../utils/reactive-form/control-value-accessor';
import {FormControlComponent} from '../../utils/reactive-form/form-control.component';
import {createFormControlProvider} from '../../utils/reactive-form/form.utils';

@Component({
  selector: 'app-input-number',
  templateUrl: './input-number.component.html',
  styleUrls: ['./input-number.component.scss'],
  providers: [createFormControlProvider(() => InputNumberComponent)],
})
@ControlValueAccessor()
export class InputNumberComponent extends FormControlComponent<number> {
  @Input() step?: number;
  @Input() format?: Format;
  @Input() mode?: 'number' | 'text' | 'tel';

  get buttons(): (string | DevExpress.common.TextEditorButton)[] {
    return this.disabled
      ? null
      : [
          {
            name: 'decrease',
            location: 'after',
            options: {
              icon: 'minus',
              onClick: this.decrease.bind(this),
            },
          },
          {
            name: 'increase',
            location: 'after',
            options: {icon: 'plus', onClick: this.increase.bind(this)},
          },
        ];
  }

  constructor(private changeDetector: ChangeDetectorRef) {
    super(changeDetector);
  }

  onValueChange(event) {
    this.valueChange.emit(event);
  }

  private increase() {
    this.valueChange.emit(this.value + (this.step || 1.0));
  }

  private decrease() {
    this.valueChange.emit(this.value - (this.step || 1.0));
  }
}
