<app-form-row>
  <app-form-column>
    <app-input-number [formControl]="latitudeFormControl"
                      [formControlRef]="latitudeFormControl"
                      [label]="'entity.store.properties.latitude.label' | translate"></app-input-number>
  </app-form-column>
  <app-form-column>
    <app-input-number [formControl]="longitudeFormControl"
                      [formControlRef]="longitudeFormControl"
                      [label]="'entity.store.properties.longitude.label' | translate"></app-input-number>
  </app-form-column>
  <app-form-column *ngIf="altitudeFormControl">
    <app-input-number [formControl]="altitudeFormControl"
                      [formControlRef]="altitudeFormControl"
                      [label]="'entity.store.properties.altitude.label' | translate"></app-input-number>
  </app-form-column>
</app-form-row>

<dx-map provider="google"
        [apiKey]="apiKey"
        width="100%"
        [height]="400"
        [zoom]="mapMarkerLocation ? 14 : 5"
        [controls]="true"
        [center]="'48.49, 16.53'"
        [markers]="latitudeFormControl.value && longitudeFormControl ? [{iconSrc: markerUrl, location: mapMarkerLocation}] : []"
        (onClick)="onMapClicked($event)"
        (onReady)="onMapReady($event)">
  <dxo-zoom-and-pan valueAxis="both"
                    argumentAxis="both"
                    [dragToZoom]="false"
                    [allowMouseWheel]="false"
                    panKey="shift"></dxo-zoom-and-pan>
</dx-map>