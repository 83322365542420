import {ObjectMapCache} from '@retrixhouse/salesapp-shared/lib/caching';
import {INewsPostDataService} from '../../interfaces/data-service';
import {NewsPost, NewsPostWithUserInfo} from '../../models';
import {NewsPostHttpService} from '../http';
import {NewsPostOfflineService} from '../offline';
import {BaseCrudDataService} from './base.data-service';

export class NewsPostDataService extends BaseCrudDataService<
  NewsPost,
  INewsPostDataService,
  ObjectMapCache<string, NewsPostWithUserInfo>
> {
  constructor(
    onlineService: NewsPostHttpService,
    offlineService: NewsPostOfflineService,
    cache: ObjectMapCache<string, NewsPostWithUserInfo>,
  ) {
    super(onlineService, offlineService, cache);
  }

  public getActualNewsPosts(): Promise<NewsPostWithUserInfo[]> {
    return this.service.getActualNewsPosts();
  }
}
