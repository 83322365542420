import {Injectable} from '@angular/core';
import {OldBaseStorageService} from './old-base-storage-service';
import {DataProvider} from '../../data.provider/data-provider';
import {IChainWithRelations} from '@retrixhouse/salesapp-shared/lib/models';
import {Observable} from 'rxjs';

@Injectable()
export class ChainStorageService extends OldBaseStorageService<
  IChainWithRelations,
  IChainWithRelations[]
> {
  constructor(dataProvider: DataProvider) {
    super(dataProvider.chain);
  }
}
