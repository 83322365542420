import {Injectable} from '@angular/core';
import {Tag} from '../../models';
import {ITagDataService} from '../../interfaces/data-service';
import {BaseCrudOfflineService} from './base.offline-service';

@Injectable()
export class TagOfflineService
  extends BaseCrudOfflineService<Tag>
  implements ITagDataService
{
  public updateItemPositions(
    positions: {id: string; position: number; parentId: string}[],
  ): Promise<void> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public checkIfTagIsUsed(tagId: string): Promise<boolean> {
    return Promise.reject('Offline service - not yet implemented');
  }

  getTagsUsage(): Promise<{id: string; used: boolean}[]> {
    return Promise.reject('Method not implemented.');
  }
}
