<ng-container *ngIf="(loading$ | async) === false">
  <dx-data-grid [dataSource]="data$ | async"
                [columns]="columns$ | async"
                [width]="'100%'"
                [height]="'100%'"
                [columnAutoWidth]="true"
                [columnHidingEnabled]="false"
                [hoverStateEnabled]="gridOptions.hoverStateEnabled"
                [rowAlternationEnabled]="gridOptions.rowAlternationEnabled"
                [allowColumnReordering]="gridOptions.allowColumnReordering"
                [allowColumnResizing]="gridOptions.allowColumnResizing"
                [noDataText]="'labels.no-data' | translate"
                [wordWrapEnabled]="true"
                (onRowDblClick)="onRowDoubleClick($event)"
                (onSelectionChanged)="onSelectionChanged($event)"
                (onExporting)="onExporting($event)"
                (onRowPrepared)="onRowPrepared($event)">
    <dxo-pager [showPageSizeSelector]="true"
               [showInfo]="true"
               [allowedPageSizes]="[10, 20, 50, 100]"></dxo-pager>
    <dxo-column-chooser [enabled]="gridOptions.allowColumnChooser"
                        [search]="{enabled: true}"
                        [height]="450"
                        [width]="350"
                        [position]="{
        my: 'right top',
        at: 'right bottom',
        of: '.dx-datagrid-column-chooser-button'
      }"
                        [selection]="{
        recursive: true,
        selectByClick: true,
        allowSelectAll: true
      }"
                        sortOrder="asc"
                        mode="select"></dxo-column-chooser>
    <dxo-toolbar *ngIf="gridOptions.toolbarVisible"
                 [visible]="gridOptions.toolbarVisible">
      <dxi-item *ngFor="let toolbarButton of toolbarButtons$ | async"
                [location]="toolbarButton.location">
        <div *dxTemplate>
          <dx-button [icon]="toolbarButton.icon"
                     [type]="toolbarButton.buttonType"
                     [stylingMode]="toolbarButton.buttonStyle"
                     [hint]="toolbarButton.i18nIdentifierHint | translate"
                     [text]="toolbarButton.text || toolbarButton.i18nIdentifier"
                     [disabled]="toolbarButton.disabledWhenEmptySelection ? !(this.selectedData$ | async).length : false"
                     (onClick)="onToolbarButtonClicked(toolbarButton)"></dx-button>
        </div>
      </dxi-item>
      <dxi-item name="exportButton"></dxi-item>
      <dxi-item *ngIf="gridOptions.allowRestoreDefaultLayout"
                [location]="'after'">
        <div *dxTemplate>
          <dx-button [icon]="'columnfield'"
                     [className]=""
                     [stylingMode]="'text'"
                     [hint]="'hints.restore-default-layout' | translate"
                     (onClick)="resetState()"></dx-button>
        </div>
      </dxi-item>

      <dxi-item name="columnChooserButton"></dxi-item>
    </dxo-toolbar>
    <dxo-sorting mode="multiple"></dxo-sorting>
    <dxo-selection *ngIf="gridOptions.showSelection"
                   [selectAllMode]="gridOptions.selectAllMode"
                   [showCheckBoxesMode]="'always'"
                   [mode]="gridOptions.selectionMode"></dxo-selection>
    <dxo-export [enabled]="gridOptions.allowExport"
                [allowExportSelectedData]="gridOptions.allowExportSelectedData"></dxo-export>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-state-storing [customLoad]="loadState"
                       [customSave]="saveState"
                       [enabled]="!!gridOptions.userStorageKey"
                       type="custom"></dxo-state-storing>
    <!-- <dxo-scrolling rowRenderingMode="virtual"></dxo-scrolling> -->
    <!-- CUSTOM RENDERING -->
    <div *dxTemplate="let data of 'actionsTemplate'">
      <app-grid-row-actions [actions]="gridRowActions$ | async"
                            [data]="data.data"
                            (actionClicked)="onGridRowActionClicked($event, data)"></app-grid-row-actions>
    </div>

    <div *dxTemplate="let d of 'genericListCellTemplate'">
      {{ (genericListItemsById$ | async)?.get(d.value)?.name }}
    </div>

    <div *dxTemplate="let options of 'booleanCellTemplate'">
      <div class="data-grid-flag-wrapper">
        <div *ngIf="options.value; else flagFalseBlock">
          <span class="data-grid-flag flag-true fa fa-check"></span>
        </div>
        <ng-template #flagFalseBlock><span class="data-grid-flag flag-false fa fa-times"></span></ng-template>
      </div>
    </div>

    <div *dxTemplate="let row of 'projectAvatarCellTemplate'">
      <app-project-avatar *ngIf="row.value; else unavailableTemplate"
                          [project]="row.value"></app-project-avatar>
    </div>

    <div *dxTemplate="let row of 'storeAvatarCellTemplate'">
      <app-store-avatar *ngIf=" row.data; else unavailableTemplate"
                        [store]="row.value"
                        [showChainLogo]="false"
                        [projectId]="row.data?.projectId"></app-store-avatar>
    </div>

    <div *dxTemplate="let row of 'projectAvatarsCellTemplate'">
      <ng-container *ngFor="let project of row.value">
        <app-project-avatar *ngIf="project; else unavailableTemplate"
                            [project]="project"></app-project-avatar>
      </ng-container>
    </div>

    <div *dxTemplate="let row of 'relationToManyCellTemplate'">
      <app-property-value-renderer *ngIf="row.value; else emptyTemplate"
                                   [property]="row.column.property"
                                   [value]="row.value"></app-property-value-renderer>
    </div>

    <div *dxTemplate="let row of 'chainsCellTemplate'">
      <ng-container *ngFor="let chain of row.value; let isLast = last"
                    class="ellipsis">
        <span>{{ chain.name }}{{ isLast ? '' : ', ' }}</span>
      </ng-container>
    </div>

    <div *dxTemplate="let row of 'positionsCellTemplate'">
      <ng-container *ngFor="let position of row.value; let isLast = last"
                    class="ellipsis">
        <span>{{ position.name }}{{ isLast ? '' : ', ' }}</span>
      </ng-container>
    </div>

    <div *dxTemplate="let row of 'userAvatarCellTemplate'">
      <app-user-avatar *ngIf="row.value; else unavailableTemplate"
                       [pictureSize]="'small'"
                       [padding]="'none'"
                       [firstName]="row.value.firstName"
                       [lastName]="row.value.lastName"
                       [picture]="row.value.profilePicture"
                       [position]="row.value.positionAbbreviation"
                       [username]="row.value.username"
                       [userUid]="row.value.uid"></app-user-avatar>
    </div>

    <div *dxTemplate="let row of 'tourplanStatusCellTemplate'">
      <div>
        <span [ngStyle]="{color: formatters.tourPlanStatusToColor(row.value)}"
              class="fa fa-circle"></span>&nbsp;{{ 'tour-plan-state.' + row.value?.toLowerCase() | translate }}
      </div>
    </div>

    <div *dxTemplate="let row of 'pillValueOrEmptyCellTemplate'">
      <app-pill *ngIf="row.value?.value || row.value?.value === 0; else emptyTemplate"
                [text]="row.value?.value"
                [color]="row.value?.color"></app-pill>
    </div>

    <div *dxTemplate="let row of 'valueOrEmptyCellTemplate'"
         class="ellipsis">
      <span *ngIf="row.value || row.value === 0; else emptyTemplate">
        {{ row.value }}
      </span>
    </div>

    <div *dxTemplate="let row of 'enumValueCellTemplate'"
         class="ellipsis">
      <span *ngIf="row.value; else emptyTemplate">
        {{ row.column.enumTranslationMarker + '.' + row.value.toLowerCase() | translate }}
      </span>
    </div>

    <div *dxTemplate="let row of 'fileSizeCellTemplate'"
         class="ellipsis">
      <span *ngIf="row.value; else emptyTemplate">
        {{ row.value | fileSize }}
      </span>

    </div>

    <div *dxTemplate="let row of 'localizedDateTimeCellTemplate'"
         class="ellipsis">
      <span *ngIf="row.value; else emptyTemplate">
        {{ row.value | dateFormat: 'L LT' }}
      </span>

    </div>

    <div *dxTemplate="let row of 'fileTypeCellTemplate'"
         class="ellipsis">
      <span *ngIf="row.value; else emptyTemplate">
        {{ row.value | fileType }}
      </span>

    </div>

    <div *dxTemplate="let row of 'objectTypeIdCellTemplate'">
      {{ 'object-type-ids' + '.' + paramCase(objectTypeIdsMap.get(row.value)) | translate}}
    </div>

  </dx-data-grid>
</ng-container>

<ng-template #emptyTemplate>
  <span class="empty">
    {{ 'grid.cell-template.empty' | translate }}
  </span>
</ng-template>

<ng-template #unavailableTemplate>
  <span class="empty">
    {{ 'grid.cell-template.unavailable' | translate }}
  </span>
</ng-template>