import * as uuid from 'uuid';
import {DataStorageFile} from './data-storage-file.model';

export class DataStorageFolder {
  id: string;
  name: string;
  parentId?: string;
  isDirectory: boolean = true;
  dataStorageFiles: DataStorageFile[];
  permissions?: {
    projects?: string[];
    positions?: string[];
    chains?: string[];
  };

  constructor() {
    this.id = uuid.v4();
    this.name = null;
    this.parentId = null;
  }
}
