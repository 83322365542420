import * as uuid from 'uuid';

export class KPI {
  id: string;
  name: string;
  description?: string;

  constructor() {
    this.id = uuid.v4();
    this.name = null;
    this.description = null;
  }
}

export class KPISetItem {
  id: string;
  kpiId: string;
  kpi: KPI;
  setId: string;
  weight: number;
  position?: number;
}

export class KPISet {
  id: string;
  name: string;
  items?: KPISetItem[];
}
