import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {IImexTemplateDataService} from '../../interfaces/data-service';
import {ImexFileValidationResponse} from '../../models';
import {ImexFormat, ImexTemplate} from '../../models/imex-template.model';
import {BaseReadonlyHttpService} from './base.http-service';

@Injectable()
export class ImexTemplateHttpService
  extends BaseReadonlyHttpService<ImexTemplate>
  implements IImexTemplateDataService
{
  constructor(http: HttpClient) {
    super(http, '/core/imex-template');
  }

  public uploadFileForValidation(
    file: FormData,
    templateId: string
  ): Promise<ImexFileValidationResponse> {
    return this.POST<ImexFileValidationResponse>(
      `/core/imex-template/upload-for-validation`,
      file,
      {params: {templateId}}
    );
  }

  public importFile(operationId: string, templateId: string) {
    return this.GET<any>(
      `/core/imex-template/import-file`, {params: {operationId, templateId}},
    );
  }

  public exportFile(
    imexTemplateId: string,
    imexCustomColumnsOrder: string[],
    imexFormat: ImexFormat,
    exportId: string,
    filterBuilderValue: string,
  ) {
    return this.POST<any>(
      `/core/imex-template/export-file`,
      {
        imexTemplateId: imexTemplateId,
        imexFormat: imexFormat,
        imexCustomColumnsOrder: imexCustomColumnsOrder.join(','),
        exportId: exportId,
        filterBuilderValue: filterBuilderValue,
      },
      {
        observe: 'response',
        responseType: 'blob' as 'json',
      },
    );
  }

  public getFileProcessingProgress(importExportId: string) {
    return this.GET<any>(
      `/core/imex-template/file-processing-progress/${importExportId}`,
    );
  }

  public getCurrentProcessingForUser(): Promise<any> {
    return this.GET<any>(
      `/core/imex-template/current-progress-for-user`,
    );
  }

  public getNumberOfRowsToExport(
    imexTemplateId: string,
    filterBuilderValue: string,
  ) {
    return this.GET<any>(
      `/core/imex-template/number-of-rows-to-export?imexTemplateId=${imexTemplateId}`,
      {params: {filterBuilderValue: filterBuilderValue}},
    );
  }

  public getImexValidationResponse(
    operationId: string,
  ): Promise<ImexFileValidationResponse> {
    return this.GET<any>(
      `/core/imex-template/result`, {params: {importExportId: operationId}},
    );
  }
}
