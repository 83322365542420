import {DIALOG_DATA, DialogRef} from '@angular/cdk/dialog';
import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {ObjectTypeIds} from '@retrixhouse/salesapp-shared/lib/common';
import {AttachmentTemplate, ObjectAttachmentApiService} from '@salesapp/api';
import {InputSelectOption} from '@salesapp/components';
import {EventBus} from '@salesapp/storage';
import {FILE_ICONS, MimeTypes} from '@salesapp/utils/file.utils';
import {CustomValidators, FormControlsOf} from '@salesapp/utils/reactive-form';
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: 'app-attachment-templates-generate-dialog',
  templateUrl: './attachment-templates-generate-dialog.component.html',
  styleUrls: ['./attachment-templates-generate-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttachmentTemplatesGenerateDialogComponent {
  form: FormGroup<AttachmentTemplatesGenerateDialogFormControls>;

  fileTypesOptions: InputSelectOption[] = [
    {
      value: MimeTypes.PDF,
      name: this.translateService.instant('mime-types.pdf'),
      icon: FILE_ICONS[MimeTypes.PDF],
      disabled: true,
    },
    {
      value: MimeTypes.DOCX,
      name: this.translateService.instant('mime-types.docx'),
      icon: FILE_ICONS[MimeTypes.DOCX],
    },
  ];

  generating$ = new BehaviorSubject(false);

  get objectTypeId() {
    return this.data.objectTypeId;
  }

  constructor(
    private dialogRef: DialogRef<void>,
    @Inject(DIALOG_DATA) private data: AttachmentTemplatesGenerateDialogData,
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private objectAttachmentApiService: ObjectAttachmentApiService,
    private eventBus: EventBus,
  ) {}

  ngOnInit() {
    this.initForm();
  }

  getFormControl(name: keyof AttachmentTemplatesGenerateDialogFormControls) {
    return this.form.get(name) as FormControl;
  }

  onClose() {
    this.dialogRef.close();
  }

  onGenerate() {
    this.generating$.next(true);
    if (this.data.templates.length > 1) {
      this.objectAttachmentApiService
        .generateFromTemplates({
          body: {
            objectTypeId: this.data.objectTypeId,
            objectId: this.data.objectId,
            templateIds: this.data.templates.map(template => template.id),
          },
        })
        .subscribe({
          next: data => {
            this.generating$.next(false);
            this.eventBus.publish(
              'attachment-template.attachment-generated',
              data,
            );
            this.dialogRef.close();
          },
          error: () => this.generating$.next(false),
        });
    } else {
      this.objectAttachmentApiService
        .generateFromTemplate({
          body: {
            objectTypeId: this.data.objectTypeId,
            objectId: this.data.objectId,
            templateId: this.data.templates[0].id,
          },
        })
        .subscribe({
          next: data => {
            this.generating$.next(false);
            this.eventBus.publish(
              'attachment-template.attachment-generated',
              data,
            );
            this.dialogRef.close();
          },
          error: () => this.generating$.next(false),
        });
    }
  }

  private initForm() {
    this.form = this.formBuilder.group({
      fileType: this.formBuilder.control<string>(MimeTypes.DOCX, [
        CustomValidators.required,
      ]),
    });
  }
}

export interface AttachmentTemplatesGenerateDialogData {
  objectTypeId: ObjectTypeIds;
  objectId: string;
  templates: AttachmentTemplate[];
}

type AttachmentTemplatesGenerateDialogFormControls = FormControlsOf<{
  fileType: string;
}>;
