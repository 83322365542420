<!-- <ng-container *visibleIf="{role:}"> -->
<div class="toolbar">
  <ng-container *ngIf="visible">
    <ng-content select="toolbar"></ng-content>
  </ng-container>
</div>
<div class="content">
  <ng-container *ngIf="visible; else notVisible">
    <ng-content select="content"></ng-content>
  </ng-container>
</div>
<div class="footer">
  <ng-content select="footer"></ng-content>
</div>

<ng-template #notVisible>
  <div class="not-visible-message">
    <i class="fa-solid fa-ban"></i>
    <span>
      {{ 'object-detail-dialog-form.not-visible-message' | translate }}
    </span>
  </div>
</ng-template>