import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {DxSwitchModule} from 'devextreme-angular';
import {FormFieldModule} from '../form-field';
import {InputSwitchComponent} from './input-switch.component';

const DX_MODULES = [DxSwitchModule];

const UI_MODULES = [FormFieldModule];

@NgModule({
  imports: [CommonModule, ...DX_MODULES, ...UI_MODULES],
  declarations: [InputSwitchComponent],
  exports: [InputSwitchComponent],
})
export class InputSwitchModule {}

export * from './input-switch.component';
