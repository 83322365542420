import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseCrudHttpService} from './base.http-service';
import {NotificationTemplate} from '../../models';
import {INotificationTemplateDataService} from '../../interfaces/data-service';

@Injectable()
export class NotificationTemplateHttpService
  extends BaseCrudHttpService<NotificationTemplate>
  implements INotificationTemplateDataService
{
  constructor(http: HttpClient) {
    super(http, '/sys/notification-templates');
  }

  public getPlaceholders(): Promise<string[]> {
    return this.GET<string[]>(`/sys/notification-templates/placeholders`);
  }
}
