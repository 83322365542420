<app-dialog [title]="'form-designer.dialog.title' | translate"
            [actionInProgress]="false"
            [actionLabel]="'form.action.save' | translate"
            [actionDisabled]="actionDisabled$ | async"
            [actionVisible]="!readonly"
            [actionIcon]=""
            [cancelLabel]="'form.action.close' | translate"
            [cancelDisabled]="actionDisabled$ | async"
            [cancelVisible]="true"
            [cancelIcon]=""
            [noPadding]="true"
            (cancelClicked)="onClose()"
            (actionClicked)="onSave()">
  <app-form-designer *ngIf="formUpdated$ | async; let formUpdated"
                     dialogContent
                     [readonlyMode]="readonly"
                     [objectForm]="formUpdated"
                     (formChanged)="onFormChange($event)"
                     (validationStateChanged)="onValidationStateChange($event)"></app-form-designer>
</app-dialog>