import {DialogService} from '@salesapp/dialog';
import {Injectable} from '@angular/core';
import {
  StoreDetailDialogComponent,
  StoreDetailDialogData,
} from '../components/store-detail-dialog/store-detail-dialog.component';

@Injectable()
export class StoreService {
  constructor(private dialogService: DialogService) {}

  openDetail(storeId: string) {
    this.dialogService.open<StoreDetailDialogComponent, StoreDetailDialogData>(
      StoreDetailDialogComponent,
      {
        data: {storeId},
        width: '90%',
        height: '90%',
      },
    );
  }
}
