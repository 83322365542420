import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  Host,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';

@Directive({selector: '[visibilityObserver]'})
export class VisibilityObserverDirective implements AfterViewInit, OnDestroy {
  @Input() visibilityObserverChildSelector?: string;

  @Output() visibilityChanged = new EventEmitter<boolean>();

  private observer: IntersectionObserver;

  constructor(@Host() private elementRef: ElementRef) {}

  ngAfterViewInit(): void {
    const options = {root: null, rootMargin: '0px', threshold: 0.0};
    this.observer = new IntersectionObserver(
      this.intersectionObserverCallback,
      options,
    );
    if (this.visibilityObserverChildSelector) {
      const element = this.elementRef.nativeElement.querySelector(
        this.visibilityObserverChildSelector,
      );
      this.observer.observe(element);
    } else {
      this.observer.observe(this.elementRef.nativeElement);
    }
  }

  ngOnDestroy() {
    this.observer.disconnect();
  }

  private intersectionObserverCallback = (entries, observer) => {
    entries.forEach(entry => {
      this.visibilityChanged.emit(entry.isIntersecting);
    });
  };
}
