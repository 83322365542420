import {BaseCrudOfflineService} from './base.offline-service';
import {IClientSideErrorConf} from '@retrixhouse/salesapp-shared/lib/models';
import {IClientSideErrorConfDataService} from '../../interfaces/data-service/client-side-error-conf.iface';
import {Injectable} from '@angular/core';

@Injectable()
export class ClientSideErrorConfOfflineService
  extends BaseCrudOfflineService<IClientSideErrorConf>
  implements IClientSideErrorConfDataService
{
  getUserConfig(): Promise<IClientSideErrorConf[] | null> {
    throw new Error('Method not implemented.');
  }
}
