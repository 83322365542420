import {NgModule} from '@angular/core';
import {DxDateBoxModule, DxValidatorModule} from 'devextreme-angular';
import {FormFieldModule} from '../form-field';
import {CommonModule} from '@angular/common';
import {InputTimeSelectComponent} from './input-time-select.component';

const DX_MODULES = [DxDateBoxModule, DxValidatorModule];

const UI_MODULES = [FormFieldModule];

@NgModule({
  imports: [CommonModule, ...DX_MODULES, ...UI_MODULES],
  declarations: [InputTimeSelectComponent],
  exports: [InputTimeSelectComponent],
})
export class InputTimeSelectModule {}

export * from './input-time-select.component';
