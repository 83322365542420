import {CommonModule} from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  DevExtremeModule,
  DxActionSheetComponent,
  DxGalleryComponent,
  DxPopupComponent,
} from 'devextreme-angular';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {CommentModule} from '../comment/comment.component';
import {CustomPipesModule} from '../../pipes';
import {
  Comment,
  MobilePhoto,
  Photo,
  PhotoGalleryInputPhoto,
  PhotoTag,
  prepareMobilePhoto,
  shouldRotate,
  Tag,
  validateMobilePhoto,
} from '../../models';
import {CustomDirectivesModule} from '../../directives';
import {AppInterfaceService} from '../../app-interface/app-interface.service';
import {sortByDate} from '../../globals';
import {PhotoResult} from '../../app-interface/response-model';
import {environment} from 'src/environments/environment';
import {NotificationService} from '../../services/notification.service';
import cloneDeep from 'clone-deep';
import {TagModule} from '../tag/tag.component';
import {v4 as uuid} from 'uuid';
import {ImgPopupModule} from '../img-popup/img-popup.component';
import {SettingNames} from '@retrixhouse/salesapp-shared/lib/settings';
import {AppInfoService, ScreenService} from '../../services';
import {PinchZoomModule} from '@meddv/ngx-pinch-zoom';
import {
  IProjectTag,
  PhotoSource,
  ProjectTagSelectionMode,
} from '@retrixhouse/salesapp-shared/lib/models';
import {TSimpleChanges} from '../../utils/angular.utils';
import {FileService} from '../../services/file.service';
import {BehaviorSubject, throwError} from 'rxjs';
import {catchError, switchMap, tap} from 'rxjs/operators';
import {DataProvider} from '../../data.provider/data-provider';
import {MimeTypes} from '@salesapp/utils/file.utils';

@Component({
  selector: 'app-photo-gallery-input',
  templateUrl: './photo-gallery-input.component.html',
  styleUrls: ['./photo-gallery-input.component.scss'],
})
export class PhotoGalleryInputComponent implements OnChanges, OnInit {
  private readonly LOADING_MAX_RETRIES = 5;
  private readonly LOADING_RETRY_TIMEOUT = 5_000;

  @ViewChild('actionSheet') _actionSheet: DxActionSheetComponent;
  @ViewChild('gallery') gallery: DxGalleryComponent;
  @ViewChild('cmntTagsPopup') imgPopup: DxPopupComponent;
  /**
   * The id of the object the photos are related to.
   */
  @Input() objectId: string;
  /**
   * Array of photos.
   */
  @Input() readonly photos: Photo[];
  /**
   * Array of available tags for photo tagging.
   */
  @Input() tags: Tag[];
  /**
   * Array of root tags with display / validation information
   */
  @Input() tagProperties: IProjectTag[];
  /**
   * The id of the visit to which the photos are related to.
   */
  @Input() visitId: string;
  /**
   * The id of the project ot which the visit related to.
   */
  @Input() projectId: string;
  /**
   * When set to `true`, the camera button will be enabled.
   */
  @Input() enableCamera: boolean = true;
  /**
   * When set to `true`, the gallery button will be enabled.
   */
  @Input() enableGallery: boolean = true;
  /**
   * When set to `true`, the rotation will be enabled.
   */
  @Input() enableRotation: boolean = true;
  /**
   * When set to `true`, add/remove comments will be enabled.
   */
  @Input() enableComments: boolean = true;
  /**
   * When set to `true`, add/remove tags will be enabled.
   */
  @Input() enableTags: boolean = true;
  /**
   * When set to `true`, deleting photos will be enabled.
   */
  @Input() enableDelete: boolean = true;
  /**
   * The allowed maximum number of photos to uplaod.
   */
  @Input() maxPhotos: number;
  /**
   * The minimum number of photos to uplaod.
   */
  @Input() minPhotos: number = undefined;
  @Input() elementAttr: {[key: string]: any} = {};
  /**
   * When set to `true`, the component will be in read-only state.
   */
  @Input() readOnly: boolean = false;
  /**
   * System/project photo settings.
   */
  @Input() photoSettings: {[prop: string]: any} = {};
  /**
   * The id of the user who is going to uplaod photos.
   */
  @Input() userId: string;
  @Input() validation: {
    validationGroup?: string;
    name?: string;
    /**
     * When set to `true`, ignores the validation of the photo tags.
     */
    disablePhotoTagsValidation: boolean;
    customValidationCBs?: Function[];
  } = {
    disablePhotoTagsValidation: false,
    customValidationCBs: [],
  };

  /**
   * An event fires when a new photo is added.
   */
  @Output()
  onPhotoAdded = new EventEmitter<MobilePhoto | MobilePhoto[]>();
  /**
   * An event fires when a new photo is rotated.
   */
  @Output()
  onPhotoRotated = new EventEmitter<string>();
  /**
   * An event fires when a photo deleted.
   */
  @Output()
  onPhotoDeleted = new EventEmitter<string>();
  /**
   * An event fires when a new comment is added.
   */
  @Output()
  onCommentAdded = new EventEmitter<{newComment: Comment; photoId: string}>();
  /**
   * An event fires when a comment is deleted.
   */
  @Output()
  onCommentsDeleted = new EventEmitter<{
    commentIds: string[];
    photoId: string;
  }>();
  /**
   * An event fires when the tags popup is hidden.
   */
  @Output()
  onPhotoTagsChanged = new EventEmitter<{
    photoTagList: PhotoTag[];
    photoId: string;
  }>();

  /**
   * Fires when the user clicks on either the capture or gallery button.
   * Returns the action details after the clicking event and then returns undefined when the action is done.
   */
  @Output() onActionStarted = new EventEmitter<{
    userId: string;
    objectId: string;
    type: 'capture' | 'gallery';
    payload: {};
  }>();

  @Output() photoUploadInProgress = new EventEmitter<boolean>();

  /**
   * The content of the popup. Only applies when the popup is visible.
   */
  popupContent: 'tagsContent' | 'commentsContent';
  /**
   * An object used to to manage all selection related properties in one place.
   */
  public _photoSelection: {
    /**
     * The currently selected photo on which actions will be applied.
     */
    _selectedPhoto?: PhotoGalleryInputPhoto | undefined;
    /**
     * The index of the currently selected photo.
     */
    _selectedPhotoIndex?: number;
    /**
     * The list of the selected tag ids.
     */
    _selectedTagIds?: string[];
    /**
     * An object to keep the photos provided by the input property `photos` in a local array.
     * The reason is that modifying the provided `photos` property causes some unexpected problem as the property is passed by REFERENCE.
     */
    _photosCopy: PhotoGalleryInputPhoto[];
    /**
     * When set to `true`, a popup displaying the currently selected photo will be shown.
     */
    _photoPopupVisible: boolean;
    /**
     * Index and id of photos with invalid tags
     * */
    _invalidTagPhotos?: {ids: Set<string>; indexes: Set<number>};
  } = {
    _photosCopy: [],
    _selectedTagIds: [],
    _photoPopupVisible: false,
  };
  /**
   * An object to keep the retrying count of a specific photo.
   */
  public imgLoadingRetryCount: {[id: string]: number} = {};
  /**
   * An object to keep the loaded photos ids.
   */
  public imgLoaded: {[id: string]: boolean} = {};
  /**
   * The list of the messages to show to the user under the buttons.
   */
  messageList$ = new BehaviorSubject<
    {id: string; text: string; html: string}[]
  >([]);
  /**
   * Calculates the size of the images according to how many images wanted to be displayed.
   */
  imagesSize?: {
    small: string;
    large: string;
  };
  isLargeScreen: boolean = false;
  actionIconId: string = 'pg-action-icon';

  adapterConfig = {
    getValue: () => {
      return this._photoSelection._photosCopy;
    },
    applyValidationResults: e => {
      if (!e.isValid) {
        this.insertMessage(e.brokenRule.message, 'error');
      }
    },
    validationRequestsCallbacks: [],
  };

  tagsValidatorAdapter = {
    getValue: () => {
      return null; // we do not need it
    },
    applyValidationResults: e => {
      if (!e.isValid) {
        this.insertMessage(
          this.translate.instant('views.photo-gallery-input.invalid-tags', {
            photoIdx: Array.from(
              this._photoSelection._invalidTagPhotos?.indexes,
            ).join(','),
          }),
          'error',
        );
      }
    },
  };

  allowPhotoGallery = true;
  tagLeavesForRootCache = new Map<string, string[]>();
  uploadInProgress$ = new BehaviorSubject<boolean>(false);
  rotateInProgress$ = new BehaviorSubject<{[photoId: string]: boolean}>({});
  timestamp = Date.now();

  constructor(
    private appInterfaceService: AppInterfaceService,
    private translate: TranslateService,
    private screenService: ScreenService,
    private fileService: FileService,
    private dataProvider: DataProvider,
    private appInfoService: AppInfoService,
  ) {
    this.validateMinPhotos = this.validateMinPhotos.bind(this);
    this.validateTags = this.validateTags.bind(this);
    this.hidePopup = this.hidePopup.bind(this);

    this.isLargeScreen = this.screenService.isLargeScreen();
  }

  ngOnInit(): void {
    this.calculateImageWidth();
  }

  ngOnChanges(changes: TSimpleChanges<PhotoGalleryInputComponent>): void {
    // use the max photo count only when the projectId is set AND the maxPhotos input is not set by the user.
    if (this.projectId && typeof this.maxPhotos !== 'number') {
      this.maxPhotos = this.dataProvider.settingResolver.getValue(
        SettingNames.PhotoGallery_MaxPhotoCount,
        this.projectId,
      );
    }

    const photos = changes['photos']?.currentValue;

    if (photos && Array.isArray(photos)) {
      // reset message list when the ds changes.
      this.messageList$.next([]);
      this.updateLocalPhotosArray(cloneDeep(photos));
    }

    // tags, changes recreate cache map - needed for tag validation
    // key - tagId (id of the root tag)
    // value - ids of tags that do not have any child nodes
    if (changes.tags || changes.tagProperties) {
      const tags = this.tags ?? [];
      const tagProperties = this.tagProperties ?? [];

      this.tagLeavesForRootCache.clear();

      // create help map to get child nodes for each tag
      const tagChildMap = new Map<string, Tag[]>();
      for (const tag of tags) {
        tagChildMap.set(
          tag.id,
          tags.filter(t => t.parentId === tag.id),
        );
      }

      // returns all tags, that have no child nodes, thus are clickable
      const getTagLeavesFromTree = (tagId: string, fooResult = []) => {
        const childTags = tagChildMap.get(tagId) ?? [];
        if (childTags.length === 0) {
          fooResult.push(tagId);
        }

        childTags.forEach(ct => getTagLeavesFromTree(ct.id, fooResult));
        return fooResult;
      };

      for (const rule of tagProperties) {
        this.tagLeavesForRootCache.set(
          rule.tagId,
          getTagLeavesFromTree(rule.tagId),
        );
      }
    }

    if (changes.elementAttr?.currentValue?.id) {
      this.actionIconId = 'action-icon-' + this.elementAttr?.id;
    }

    if (changes.photoSettings) {
      this.allowPhotoGallery =
        !this.photoSettings ||
        !this.photoSettings[
          SettingNames.PhotoGallery_RestrictGalleryToPosition
        ];
    }
  }

  selectPhotoClicked(photo: PhotoGalleryInputPhoto): void {
    if (this.isLargeScreen) {
      // here, the context menu will AUTOMATICALLY appear as it is configured to be displayed on clicking event of the icon
    } else {
      this._actionSheet.visible = true;
    }

    const allPhotos = this._photoSelection._photosCopy;

    Object.assign(this._photoSelection, {
      ...this._photoSelection,
      _selectedPhoto: cloneDeep(photo),
      _selectedPhotoIndex: allPhotos.findIndex(i => i.id === photo.id),
      _selectedTagIds: photo?.photoTagList.map(m => m.tagId) ?? [],
    });
  }

  onPhotoGalleryChanged(e) {
    if (e.addedItems?.length > 0) {
      const photo = e.addedItems?.[0];

      const allPhotos = this._photoSelection._photosCopy;
      Object.assign(this._photoSelection, {
        ...this._photoSelection,
        _selectedPhoto: cloneDeep(photo),
        _selectedPhotoIndex: allPhotos.findIndex(i => i.id === photo.id),
        _selectedTagIds: photo?.photoTagList.map(m => m.tagId) ?? [],
      });
    }
  }

  onGalleryEscapeBtn() {
    this._photoSelection._photoPopupVisible = false;
  }

  photoZoomClicked(photo: PhotoGalleryInputPhoto) {
    const allPhotos = this._photoSelection._photosCopy;
    Object.assign(this._photoSelection, {
      ...this._photoSelection,
      _selectedPhoto: cloneDeep(photo),
      _selectedPhotoIndex: allPhotos.findIndex(i => i.id === photo.id),
      _selectedTagIds: photo?.photoTagList.map(m => m.tagId) ?? [],
      _photoPopupVisible: true,
    });
  }

  actionCancelClick(e: any): void {
    Object.assign(this._photoSelection, {
      ...this._photoSelection,
      _selectedPhoto: undefined,
      _selectedPhotoIndex: -1,
      _selectedTagIds: [],
    });
    this._actionSheet.visible = false;
  }

  actionItemClick(cmd: string): void {
    if (this._photoSelection._selectedPhoto) {
      switch (cmd) {
        case 'rotate-left':
          this.rotate(this._photoSelection._selectedPhoto, 'left');
          break;
        case 'rotate-right':
          this.rotate(this._photoSelection._selectedPhoto, 'right');
          break;
        case 'comments':
          this.imgPopup.instance.show();
          this.popupContent = 'commentsContent';
          break;
        case 'tags':
          this.imgPopup.instance.show();
          this.popupContent = 'tagsContent';

          break;
        case 'delete':
          this.deletePhoto(this._photoSelection._selectedPhoto);
          break;
      }
    }
  }

  async showImageRelationPopup(e) {
    if (!e.itemData.items) {
      switch (e.itemData.command) {
        case 'comments':
          {
            this.imgPopup.instance.show();
            this.popupContent = 'commentsContent';
          }
          break;

        case 'tags':
          {
            this.imgPopup.instance.show();
            this.popupContent = 'tagsContent';
          }
          break;

        case 'rotate-left':
          {
            this.rotate(this._photoSelection._selectedPhoto, 'left');
          }
          break;
        case 'rotate-right':
          {
            this.rotate(this._photoSelection._selectedPhoto, 'right');
          }
          break;
        case 'delete':
          {
            this.deletePhoto(this._photoSelection._selectedPhoto);
          }
          break;
      }
    }
  }

  async rotate(
    selectedPhoto: PhotoGalleryInputPhoto,
    direction: 'right' | 'left',
  ) {
    this.rotateInProgress$.next({[selectedPhoto.id]: true});
    if (this.appInfoService.isMobileVersion && selectedPhoto.isStoragePhoto) {
      await this.appInterfaceService.photoStorageRotate({
        rotation: direction,
        uuid: selectedPhoto.id,
        compressionQuality:
          this.photoSettings[SettingNames.PhotoGallery_AutoJpegCompression],
      });
    } else {
      await this.dataProvider.photoObject.rotatePhoto({
        deg: direction === 'left' ? 90 : -90,
        photoId: selectedPhoto.id,
      });
    }

    setTimeout(async () => {
      const img = document.getElementById(
        `${selectedPhoto.id}-img`,
      ) as HTMLImageElement;
      if (img) {
        const timestamp = new Date().getTime();
        const isStoragePhoto = await this.isStoragePhoto(selectedPhoto.id);
        if (isStoragePhoto) {
          // FOR STORAGE PHOTOS, URI AND URITHUMBNAILS ARE ALWAYS SAME.
          selectedPhoto.uri = selectedPhoto.uri.replace(
            /(t=)[^\&]+/,
            '$1' + timestamp,
          );
          selectedPhoto.imageUrl = selectedPhoto.uri;

          selectedPhoto.uriThumbnail = selectedPhoto.uri.replace(
            /(t=)[^\&]+/,
            '$1' + timestamp,
          );
          selectedPhoto.imageThumbnailUrl = selectedPhoto.uriThumbnail;
        } else {
          selectedPhoto.uri = `${environment.photoObjectBaseUrl}${selectedPhoto.uri}?t=${timestamp}`;
          selectedPhoto.imageUrl = selectedPhoto.uri;

          selectedPhoto.uriThumbnail = `${environment.photoObjectBaseUrl}${selectedPhoto.uriThumbnail}?t=${timestamp}`;
          selectedPhoto.imageThumbnailUrl = selectedPhoto.uriThumbnail;
        }
        const photo = this._photoSelection._photosCopy.find(
          ph => ph.id === selectedPhoto.id,
        );
        photo.imageUrl = selectedPhoto.imageUrl;
        photo.imageThumbnailUrl = selectedPhoto.imageThumbnailUrl;
        Object.assign(this._photoSelection, {
          ...this._photoSelection,
          _photosCopy: cloneDeep(this._photoSelection._photosCopy),
        });

        this.onPhotoRotated.emit(selectedPhoto.id);
        this.rotateInProgress$.next({[selectedPhoto.id]: false});
      }
    }, 500);
  }

  deletePhoto(photo: Photo) {
    Object.assign(this._photoSelection, {
      ...this._photoSelection,
      _photosCopy: this._photoSelection._photosCopy.filter(
        p => p.id !== photo.id,
      ),
    });

    this.onPhotoDeleted.emit(photo.id);
  }

  async selectFromGallery() {
    const payload = {
      max: this.maxPhotos ?? 100,
      compressionQuality:
        this.photoSettings[SettingNames.PhotoGallery_AutoJpegCompression],
      maxHeight: this.photoSettings[SettingNames.PhotoGallery_MaxHeight],
      maxWidth: this.photoSettings[SettingNames.PhotoGallery_MaxWidth],
      photoStorage: true,
    };

    this.onActionStarted.emit({
      objectId: this.objectId,
      userId: this.userId,
      type: 'gallery',
      payload: payload,
    });

    const selectedPhotos = await this.appInterfaceService.photoLibrary(payload);

    this.onActionStarted.emit(undefined);

    if (
      !Array.isArray(selectedPhotos) ||
      selectedPhotos.length === 0 ||
      (selectedPhotos.length === 1 && selectedPhotos[0].cancelled)
    ) {
      return;
    }

    const selectedMobilePhotos: MobilePhoto[] = [];

    for await (const selectedPhoto of selectedPhotos) {
      // check whether the uploaded photos number doesn't exceeds the max number
      if (this._photoSelection._photosCopy.length + 1 > this.maxPhotos) {
        NotificationService.notifyWarning(
          this.translate.instant('photo-gallery.max-number-should-be', {
            maxPhotos: this.maxPhotos,
          }),
        );
        break;
      }

      const validationResult = validateMobilePhoto(
        selectedPhoto,
        this.photoSettings,
      );
      if (typeof validationResult === 'object') {
        this.insertMessage(
          this.translate.instant(
            validationResult.key,
            validationResult.interpolateParams,
          ),
          'error',
        );
        continue;
      }

      const mobilePhoto = prepareMobilePhoto(
        selectedPhoto,
        this.objectId,
        this.userId,
        PhotoSource.MobilePhotoLibrary,
      );

      const rotate = await shouldRotate(selectedPhoto);
      if (rotate) {
        await this.appInterfaceService.photoStorageRotate({
          rotation: rotate,
          uuid: selectedPhoto.uuid,
          compressionQuality:
            this.photoSettings[SettingNames.PhotoGallery_AutoJpegCompression],
        });
      }

      Object.assign(this._photoSelection, {
        ...this._photoSelection,
        _photosCopy: [
          ...this._photoSelection._photosCopy,
          {
            ...mobilePhoto.photo,
            commentList: [],
            photoTagList: [],
            imageUrl: mobilePhoto.photo.uri,
            imageThumbnailUrl: mobilePhoto.photo.uriThumbnail,
            isStoragePhoto: true,
          },
        ],
      });
      selectedMobilePhotos.push(mobilePhoto);
    }

    if (selectedMobilePhotos.length > 0) {
      this.onPhotoAdded.emit(cloneDeep(selectedMobilePhotos));
    }
  }

  async capturePhoto() {
    // check whether the uploaded photos number doesn't exceeds the max number
    if (this._photoSelection._photosCopy.length + 1 > this.maxPhotos) {
      NotificationService.notifyWarning(
        this.translate.instant('photo-gallery.max-number-should-be', {
          maxPhotos: this.maxPhotos,
        }),
      );
      return;
    }

    const payload = {
      compressionQuality:
        this.photoSettings[SettingNames.PhotoGallery_AutoJpegCompression],
      maxHeight: this.photoSettings[SettingNames.PhotoGallery_MaxHeight],
      maxWidth: this.photoSettings[SettingNames.PhotoGallery_MaxWidth],
      photoStorage: true,
    };

    this.onActionStarted.emit({
      objectId: this.objectId,
      userId: this.userId,
      type: 'capture',
      payload: payload,
    });

    const capturedPhoto: PhotoResult =
      await this.appInterfaceService.photoCapture(payload);

    //console.log('...> capturedPhoto', capturedPhoto);

    this.onActionStarted.emit(undefined);

    if (!capturedPhoto || capturedPhoto?.cancelled) {
      return;
    }

    const validationResult = validateMobilePhoto(
      capturedPhoto,
      this.photoSettings,
    );

    if (typeof validationResult === 'object') {
      this.insertMessage(
        this.translate.instant(
          validationResult.key,
          validationResult.interpolateParams,
        ),
        'error',
      );
      return;
    }

    const rotate = await shouldRotate(capturedPhoto);
    if (rotate) {
      await this.appInterfaceService.photoStorageRotate({
        rotation: rotate,
        uuid: capturedPhoto.uuid,
        compressionQuality:
          this.photoSettings[SettingNames.PhotoGallery_AutoJpegCompression],
      });
    }

    const mobilePhoto = prepareMobilePhoto(
      capturedPhoto,
      this.objectId,
      this.userId,
      PhotoSource.MobileCamera,
    );
    Object.assign(this._photoSelection, {
      ...this._photoSelection,
      _photosCopy: [
        ...this._photoSelection._photosCopy,
        {
          ...mobilePhoto.photo,
          commentList: [],
          photoTagList: [],
          imageUrl: mobilePhoto.photo.uri,
          imageThumbnailUrl: mobilePhoto.photo.uriThumbnail,
          isStoragePhoto: true,
        },
      ],
    });
    this.onPhotoAdded.emit(cloneDeep(mobilePhoto));
  }

  commentDeleted(commentIds: string[]): void {
    const photo = this._photoSelection._photosCopy.find(
      c => c.id === this._photoSelection._selectedPhoto.id,
    );
    photo.commentList = photo.commentList.filter(
      c => !commentIds.includes(c.id),
    );
    this.onCommentsDeleted.emit({
      commentIds: commentIds,
      photoId: this._photoSelection._selectedPhoto.id,
    });
  }

  commentAdded(comment: Comment): void {
    const photo = this._photoSelection._photosCopy.find(
      c => c.id === this._photoSelection._selectedPhoto.id,
    );
    photo.commentList.push(comment);
    this.onCommentAdded.emit({
      newComment: cloneDeep(comment),
      photoId: photo.id,
    });
  }

  tagsChanged(tags: Tag[]) {
    if (this._photoSelection._selectedPhoto) {
      const photo = this._photoSelection._photosCopy.find(
        c => c.id === this._photoSelection._selectedPhoto.id,
      );
      const photoTagList = tags.map(tag => {
        return {
          id: uuid(),
          photoId: this._photoSelection._selectedPhoto.id,
          tagId: tag.id,
          time: new Date(),
          userId: this.userId,
        };
      });
      photo.photoTagList = photoTagList;
    }
  }

  onCmntTagsPopupHidden(e) {
    const photo = this._photoSelection._photosCopy.find(
      c => c.id === this._photoSelection._selectedPhoto.id,
    );
    this.onPhotoTagsChanged.emit({
      photoId: this._photoSelection._selectedPhoto.id,
      photoTagList: photo.photoTagList ?? [],
    });
  }

  private updateLocalPhotosArray(photos: any[]) {
    sortByDate(photos, 'uploadedAt');

    // clone the photos array so the changes don't reflect to the original array (because of reference)
    Object.assign(this._photoSelection, {
      ...this._photoSelection,
      _photosCopy: photos.map((photo: PhotoGalleryInputPhoto) => {
        // when the uri includes 'storage', it means that the photo is stored on mobile storage so it should retrieved from there.
        // when the uri includes 'http', it means the url is already a valid url so we don't need to do anything.
        // if not, we set the url to get the photos from azure storage.
        photo.isStoragePhoto = photo.uri.includes('storage');
        const uriAlreadyValid = photo.uri.includes('http');
        photo.imageUrl =
          photo.isStoragePhoto || uriAlreadyValid
            ? photo.uri
            : environment.photoObjectBaseUrl + photo.uri;
        photo.imageThumbnailUrl =
          photo.isStoragePhoto || uriAlreadyValid
            ? photo.uri
            : environment.photoObjectBaseUrl + photo.uriThumbnail;
        photo.uploadedAt = new Date(photo.uploadedAt);
        photo.commentList = photo.commentList ?? [];

        photo.photoTagList = photo.photoTagList ?? [];
        return photo;
      }),
    });
  }

  validateMinPhotos(e) {
    if (this.minPhotos && this.minPhotos > 0) {
      return this._photoSelection._photosCopy?.length >= this.minPhotos;
    } else {
      return true;
    }
  }

  /**
   * Tagging can have project specific rules based on selection mode and required property
   * Validate those rules on all photos if applicable
   */
  validateTags(e): boolean {
    // clear invalid photos
    this._photoSelection._invalidTagPhotos = {
      indexes: new Set<number>(),
      ids: new Set<string>(),
    };
    if (this.validation.disablePhotoTagsValidation) {
      return true;
    }

    let result = true;
    let idx = 1;
    for (const photo of this._photoSelection._photosCopy) {
      for (const rule of this.tagProperties ?? []) {
        const leaves = this.tagLeavesForRootCache.get(rule.tagId); // clickable tags
        const selectedTags = (photo.photoTagList ?? []).map(pt => pt.tagId);

        const intersection = new Set(
          leaves.filter(x => selectedTags.includes(x)),
        );

        if (rule.required && intersection.size === 0) {
          this._photoSelection._invalidTagPhotos.indexes.add(idx);
          this._photoSelection._invalidTagPhotos.ids.add(photo.id);
          result = false;
        }

        if (
          rule.selectionMode === ProjectTagSelectionMode.Single &&
          intersection.size > 1
        ) {
          this._photoSelection._invalidTagPhotos.indexes.add(idx);
          this._photoSelection._invalidTagPhotos.ids.add(photo.id);
          result = false;
        }
      }

      idx++;
    }
    return result;
  }

  async isStoragePhoto(key: string) {
    if (this.appInfoService.isMobileVersion) {
      const keys = await this.appInterfaceService.photoStorageKeysExist([key]);
      return keys[key] === true;
    } else {
      return false;
    }
  }

  onImgLoadError(e, id: string, src: string) {
    const img = e.target as HTMLImageElement;

    let currentRetryCount = this.imgLoadingRetryCount[id] ?? 0;
    if (currentRetryCount === this.LOADING_MAX_RETRIES) {
      img.src = '/assets/no-image.png';
      return;
    }

    if (typeof currentRetryCount === 'number') {
      currentRetryCount++;
    } else {
      currentRetryCount = 0;
    }
    this.imgLoadingRetryCount[id] = currentRetryCount;

    setTimeout(() => {
      const timestamp = new Date().getTime();
      img.src = `${src}?t=${timestamp}`;
    }, this.LOADING_RETRY_TIMEOUT);
  }

  onImageLoaded(e, id) {
    this.imgLoaded[id] = true;
  }

  onFullScreenPopupShown(e) {
    this.gallery.instance.focus();
  }

  /**
   * Inserts a message into the list under the photo gallery.
   * @param {string} message The text of the message.
   * @param {'success' | 'error' | 'warning'} type The type of the message.
   */
  insertMessage(message: string, type: 'success' | 'error' | 'warning') {
    const html = `<span style="color: ${
      type === 'success' ? 'green;' : type === 'error' ? 'red;' : 'orange;'
    }">${new Date().toLocaleTimeString()} - ${message}</span>`;

    // push the message to the list
    this.messageList$.next(
      this.messageList$.getValue().concat({
        id: uuid(),
        text: message,
        html: html,
      }),
    );
  }

  showPopup(photo: PhotoGalleryInputPhoto, popup: 'comments' | 'tags') {
    const allPhotos = this._photoSelection._photosCopy;

    Object.assign(this._photoSelection, {
      ...this._photoSelection,
      _selectedPhoto: cloneDeep(photo),
      _selectedTagIds: photo?.photoTagList.map(m => m.tagId) ?? [],
      _selectedPhotoIndex: allPhotos.findIndex(i => i.id === photo.id),
    });

    if (popup === 'comments') {
      this.imgPopup.instance.show();
      this.popupContent = 'commentsContent';
    } else if (popup === 'tags') {
      this.imgPopup.instance.show();
      this.popupContent = 'tagsContent';
    }
  }

  hidePopup(e) {
    this.imgPopup.instance.hide();
  }

  calculateImageWidth() {
    // The follwoing code is used to calculate the proper width that should be used to show a specific number of photos aligned horizontally

    const containerWidth = document.getElementById('images-header-container')?.[
      'offsetWidth'
    ];

    // containerWidth / numberOfPhotosToShow
    if (this.screenService.isLargeScreen()) {
      // 6 is the gap between photos
      const smallImgs = containerWidth ? (containerWidth - 6) / 8 : undefined;
      const largeImgs = containerWidth ? (containerWidth - 6) / 4 : undefined;
      this.imagesSize = {small: `${smallImgs}px`, large: `${largeImgs}px`};
    } else {
      // show only one.
      this.imagesSize = {small: `100%`, large: `100%`};
    }
  }

  onBroswerUploadPicture(event: Event) {
    const target = event.target as HTMLInputElement;
    const files = target.files;

    this.uploadInProgress$.next(true);
    this.photoUploadInProgress.emit(true);
    this.fileService
      .processFile({
        file: files[0],
        availableMimeTypes: [MimeTypes.JPEG],
        fileFormat: 'base64',
      })
      .pipe(
        switchMap(file => {
          const form = new FormData();
          form.append('file', files[0]);
          return this.dataProvider.photoObject.uploadVisitPhoto(
            form,
            this.visitId,
            this.objectId,
          );
        }),
        catchError(error => {
          NotificationService.notifyError(error);
          this.uploadInProgress$.next(false);
          this.photoUploadInProgress.emit(false);
          return throwError(error);
        }),
        tap(response => {
          if (response.result === 'error') {
            let errorMessage = response.message;
            if (response.message === 'photo-duplicate') {
              errorMessage = this.translate.instant(
                'views.photo-gallery-input.photo-duplicate',
              );
            }
            NotificationService.notifyError(errorMessage);
            this.uploadInProgress$.next(false);
            this.photoUploadInProgress.emit(false);
            return;
          }
          const mobilePhoto = {
            ...response,
            isUploaded: true,
          };
          this.addToLocalPhotos(mobilePhoto as MobilePhoto);
          this.onPhotoAdded.emit(mobilePhoto as MobilePhoto);
          this.uploadInProgress$.next(false);
          this.photoUploadInProgress.emit(false);
        }),
      )
      .subscribe();
  }

  private addToLocalPhotos(photo: MobilePhoto) {
    Object.assign(this._photoSelection, {
      ...this._photoSelection,
      _photosCopy: [
        ...this._photoSelection._photosCopy,
        {
          ...photo.photo,
          commentList: [],
          photoTagList: [],
          imageUrl: environment.photoObjectBaseUrl + photo.photo.uri,
          imageThumbnailUrl:
            environment.photoObjectBaseUrl + photo.photo.uriThumbnail,
          isStoragePhoto: false,
        },
      ],
    });
  }

  onItemDeleted(e) {
    const item = e.itemData;
    this.messageList$.next(
      this.messageList$.getValue().filter(m => m.id !== item.id),
    );
  }
}

@NgModule({
  declarations: [PhotoGalleryInputComponent],
  imports: [
    CommonModule,
    DevExtremeModule,
    TranslateModule,
    CommentModule,
    CustomPipesModule,
    CustomDirectivesModule,
    TagModule,
    ImgPopupModule,
    PinchZoomModule,
  ],
  exports: [PhotoGalleryInputComponent],
})
export class PhotoGalleryInputModule {}
