import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {
  ObjectTypeIds,
  RoleNames,
} from '@retrixhouse/salesapp-shared/lib/common';
import {IUser, IUserProfile} from '@retrixhouse/salesapp-shared/lib/models';
import {ObjectDetailTab} from '@salesapp/shared/modules';
import {UserProfileStorageService, UserStorageService} from '@salesapp/storage';
import {BehaviorSubject, Observable, combineLatest} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-user-management-detail',
  templateUrl: './user-management-detail.component.html',
  styleUrls: ['./user-management-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserManagementDetailComponent {
  @Input() user: IUser;
  @Input() userProfile: IUserProfile;
  @Input() loading: boolean;
  @Input() forceProfileUpdate: boolean;

  @Output() cancel = new EventEmitter<void>();
  @Output() actionCompleted = new EventEmitter<void>();

  tabs: ObjectDetailTab<UserManagementDetailTabs>[];
  activeTabId$ = new BehaviorSubject<UserManagementDetailTabs>('userProfile');
  tabsDisabled$: Observable<boolean>;

  ObjectTypeIds = ObjectTypeIds;

  constructor(
    private translateService: TranslateService,
    private userProfileStorageService: UserProfileStorageService,
    private userStorageService: UserStorageService,
  ) {}

  ngOnInit() {
    this.initTabs();
  }

  onTabChange(tabId: string) {
    this.activeTabId$.next(tabId as UserManagementDetailTabs);
  }

  private initTabs() {
    this.tabs = [
      {
        id: 'userProfile',
        text: this.translateService.instant(
          'entity.user-management.detail.tabs.user-profile.label',
        ),
        enabledForRoles: [
          RoleNames.Admin,
          RoleNames.UserProfiles,
          RoleNames.UserProfilesEdit,
          RoleNames.UserProfilesView,
        ],
      },
      {
        id: 'user',
        text: this.translateService.instant(
          'entity.user-management.detail.tabs.user.label',
        ),
        enabledForRoles: [
          RoleNames.Admin,
          RoleNames.Users,
          RoleNames.UsersEdit,
          RoleNames.UsersView,
        ],
      },
      {
        id: 'attachments',
        text: this.translateService.instant(
          'entity.user-management.detail.tabs.attachments.label',
        ),
        enabledForRoles: [RoleNames.Admin, RoleNames.ObjectAttachmentsView],
      },
    ];

    this.tabsDisabled$ = combineLatest([
      this.userProfileStorageService.dataCommandInProgress$,
      this.userStorageService.dataCommandInProgress$,
    ]).pipe(
      map(
        ([userProfileCommandInProgress, userCommandInProgress]) =>
          userProfileCommandInProgress || userCommandInProgress,
      ),
    );
  }
}

type UserManagementDetailTabs = 'user' | 'userProfile' | 'attachments';
