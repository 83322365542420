import {CommonModule} from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {
  DevExtremeModule,
  DxPopupComponent,
  DxSelectBoxComponent,
  DxTagBoxComponent,
} from 'devextreme-angular';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import {Product} from '../../models';
import {AppInfoService, ScreenService} from '../../services';
import {ProductAvatarModule} from '../product-avatar/product-avatar.component';
import query from 'devextreme/data/query';
import {NotificationService} from '../../services/notification.service';
import {
  BarcodeScannerLivestreamComponent,
  BarcodeScannerLivestreamModule,
} from 'ngx-barcode-scanner';
import {BarcodeService} from '../../services/bar-code.service';
import {sortByName} from '@retrixhouse/salesapp-shared/lib/utils';
const deepEqual = require('deep-equal');

@Component({
  selector: 'app-product-selector',
  templateUrl: './product-selector.component.html',
  styleUrls: ['./product-selector.component.scss'],
})
export class ProductSelectorComponent implements OnInit, OnChanges {
  @Input() selectionMode: SelectionMode = 'single';
  @Input() viewMode: ViewMode = 'lookup';
  @Input() dataSource: Product[];
  @Input() maxProducts: Number = undefined;

  @Input() readOnly: boolean = false;
  @Input() selectedProducts: Product[];
  @Input() selectedProductsIdList: string[];
  @Input() allowSearch: boolean = true;
  @Input() showBarCodeScannerButton: boolean = true;
  @Input() projectId: string | undefined;
  @Input() elementAttr: any = {};
  @Input() validation: {
    validationGroup?: string;
    name?: string;
    isRequired?: boolean;
    customValidationCBs?: Function[];
  };
  @Input() disabled: boolean = false;
  @Input() placeholder: string;

  @Output() onSelectedProductsChanged: EventEmitter<Product[]> =
    new EventEmitter<Product[]>();

  @Output() onFocusIn = new EventEmitter<any>();
  @Output() onFocusOut = new EventEmitter<any>();
  @Output() onOptionChanged = new EventEmitter<any>();

  @ViewChild('productSelectorTagbox')
  productSelectorTagbox: DxTagBoxComponent;
  @ViewChild('productSelectorSelectbox')
  productSelectorSelectBox: DxSelectBoxComponent;
  @ViewChild(BarcodeScannerLivestreamComponent)
  barcodeScanner: BarcodeScannerLivestreamComponent;
  @ViewChild('barcodePopup')
  barcodePopup: DxPopupComponent;

  selectedProductsCount: number = 0;
  selectionDatasource: DataSource;
  products: Product[] = [];
  barcodeSubscription: any;
  dropDownOptions: any;

  clearButtonOptions = {
    text: this.translateService.instant('product-selector.clear-selection'),
    type: 'normal',
    stylingMode: 'contained',
    onClick: () => {
      this.dataSource.forEach(
        (pro: ExtendedProduct) => (pro.isSelected = false),
      );
      this.selectedProductsCount = 0;
      this.selectedProducts = this.getSelectedProducts;
      this.onSelectedProductsChanged.emit(this.selectedProducts);
    },
  };
  barcodeScannerButton = {
    icon: 'fas fa-barcode',
    type: 'normal',
    stylingMode: 'contained',
    onClick: async () => {
      if (this.appInfoService.isMobileVersion) {
        const barcode = await this.barcodeService.scanBarcode(this.projectId);
        if (barcode) {
          this.findSelectProduct(barcode);
        }
      } else {
        if (this.barcodePopup.visible) {
          await this.barcodePopup.instance.hide();

          this.barcodeScanner.stop();
          this.barcodeSubscription.unsubscribe();
        } else {
          await this.barcodePopup.instance.show();

          this.barcodeScanner.start();
          this.barcodeSubscription = this.barcodeScanner.valueChanges.subscribe(
            result => {
              if (result?.codeResult?.code) {
                this.findSelectProduct(result?.codeResult?.code);
              }
              this.barcodeScanner.stop();
              this.barcodeSubscription.unsubscribe();
              this.barcodePopup.instance.hide();
            },
          );
        }
      }
    },
  };

  get getSelectedProducts() {
    return this.dataSource.filter((pr: ExtendedProduct) => pr.isSelected);
  }

  constructor(
    private screenService: ScreenService,
    private translateService: TranslateService,
    private barcodeService: BarcodeService,
    private appInfoService: AppInfoService,
  ) {
    if (this.screenService.isSmallScreen()) {
      this.dropDownOptions = <DxPopupComponent>{
        fullScreen: true,
        showTitle: true,
        toolbarItems: [
          {
            text: this.translateService.instant('labels.products'),
            location: 'before',
          },
          {
            widget: 'dxButton',
            location: 'after',
            options: {
              text: this.translateService.instant('buttons.ok')?.toUpperCase(),
              onClick: e => {
                this.productSelectorTagbox?.instance?.close();
                this.productSelectorSelectBox?.instance?.close();
              },
            },
          },
        ],
      };
    }
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    const selectedProducts: ExtendedProduct[] =
      changes['selectedProducts']?.currentValue;
    if (selectedProducts) {
      if (Array.isArray(selectedProducts)) {
        this.selectedProducts = selectedProducts;
      } else {
        this.selectedProducts = [selectedProducts];
      }
      this.selectedProductsCount = this.selectedProducts.length;
    }

    const dataSource = changes['dataSource']?.currentValue;
    if (dataSource) {
      this.products = sortByName(dataSource);
      this.selectionDatasource = new DataSource({
        store: new ArrayStore({
          data: dataSource,
        }),
        paginate: true,
        pageSize: 10,
      });
    }

    if (this.selectionMode === 'single') {
      this.maxProducts = 1;
    }

    if (this.dataSource && this.selectedProducts) {
      this.selectedProductsIdList = this.selectedProducts.map(pr => pr.id);
      this.dataSource.forEach((pro: ExtendedProduct) => {
        pro.isSelected = this.selectedProductsIdList.includes(pro.id);
      });
    }

    let selectedProductsIdList =
      changes['selectedProductsIdList']?.currentValue;
    if (selectedProductsIdList) {
      if (typeof selectedProductsIdList === 'string') {
        selectedProductsIdList = [selectedProductsIdList];
      }
      this.selectedProductsIdList = selectedProductsIdList;
    }
  }
  ngOnInit(): void {}

  onTileProductClick(e) {
    const numberOfSelectedProducts = this.getSelectedProducts.length;

    const existingProduct = this.dataSource.find(
      pr => pr.id === e.itemData.id,
    ) as ExtendedProduct;

    if (this.selectionMode === 'single') {
      this.dataSource.forEach((pr: ExtendedProduct) => {
        pr.isSelected = false;
      });
      this.selectedProductsCount = 1;
      existingProduct.isSelected = !existingProduct.isSelected;
    } else if (this.selectionMode === 'multiple') {
      if (
        (existingProduct.isSelected === undefined ||
          existingProduct.isSelected === false) &&
        numberOfSelectedProducts === this.maxProducts
      ) {
        return;
      }

      if (existingProduct.isSelected === true) {
        this.selectedProductsCount -= 1;
        existingProduct.isSelected = false;
      } else {
        this.selectedProductsCount += 1;
        existingProduct.isSelected = true;
      }
    }

    this.selectedProducts = this.getSelectedProducts;
    this.onSelectedProductsChanged.emit(this.selectedProducts);
  }

  onSelectedProductChanged(e) {
    if (this.selectionMode === 'single') {
      if (this.productSelectorSelectBox.selectedItem) {
        this.selectedProducts = [this.productSelectorSelectBox.selectedItem];
      } else {
        this.selectedProducts = [];
      }
      this.onSelectedProductsChanged.emit(this.selectedProducts);
    } else if (this.selectionMode === 'multiple') {
      if ((e.value?.length ?? 0) > this.maxProducts) {
        e.component.option('value', e.previousValue);
        return;
      }

      if (
        this.dataSource?.length > 0 &&
        !deepEqual(
          this.selectedProducts,
          this.productSelectorTagbox.selectedItems,
          {strict: true},
        )
      ) {
        this.selectedProducts = this.productSelectorTagbox.selectedItems;
        this.onSelectedProductsChanged.emit(this.selectedProducts);
      }
    }
  }

  findSelectProduct(barcode: string) {
    const product = query(this.products)
      .filter([
        ['eanCode', 'contains', barcode],
        'or',
        [barcode, 'contains', 'eanCode'],
        'or',
        ['eanCode', '=', barcode],
      ])
      .toArray();

    if (product && product?.length === 1) {
      if (this.selectionMode === 'single') {
        this.productSelectorSelectBox.instance.option('value', product[0]?.id);
      } else {
        const selectedProductId = product[0]?.id;
        const selectedItems = [
          ...new Set([
            selectedProductId,
            ...(this.productSelectorTagbox.value ?? []),
          ]),
        ];
        this.productSelectorTagbox.instance.option('value', selectedItems);
      }
      NotificationService.notifySuccess(
        this.translateService.instant(
          'product-selector.product-successfully-selected',
        ),
      );
    } else {
      NotificationService.notifyWarning(
        this.translateService.instant('product-selector.product-not-found'),
      );
    }
  }

  onEditorFocusIn(e) {
    this.onFocusIn.emit(e);
  }
  onEditorFocusOut(e) {
    this.onFocusOut.emit(e);
  }
  onEditorOptionChanged(e) {
    this.onOptionChanged.emit(e);
  }

  onBarcodePopupHidden(e) {
    this.barcodeScanner.stop();
  }
}

@NgModule({
  imports: [
    DevExtremeModule,
    CommonModule,
    TranslateModule,
    BarcodeScannerLivestreamModule,
    ProductAvatarModule,
  ],
  declarations: [ProductSelectorComponent],
  exports: [ProductSelectorComponent],
})
export class ProductSelectorModule {}

type SelectionMode = 'single' | 'multiple';
type ViewMode = 'lookup' | 'tile';

class ExtendedProduct extends Product {
  isSelected: boolean;
}
