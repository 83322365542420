import {Injectable} from '@angular/core';
import {AppInterfaceService} from '../app-interface/app-interface.service';
import {shouldRotate, validateMobilePhoto} from '../models';
import {NotificationService} from './notification.service';
import {TranslateService} from '@ngx-translate/core';
import {PhotoResult} from '../app-interface/response-model';
import {DataProvider} from '../data.provider/data-provider';
import {SettingNames} from '@retrixhouse/salesapp-shared/lib/settings';

@Injectable()
export class PhotoService {
  get photoSettings() {
    return this.dataProvider.settingResolver.getValues([
      SettingNames.PhotoGallery_AutoJpegCompression,
      SettingNames.PhotoGallery_MaxHeight,
      SettingNames.PhotoGallery_MaxWidth,
    ]);
  }

  constructor(
    private appInterfaceService: AppInterfaceService,
    private translateService: TranslateService,
    private dataProvider: DataProvider,
  ) {}

  async selectFromGallery() {
    const payload = {
      max: 1,
      compressionQuality:
        this.photoSettings[SettingNames.PhotoGallery_AutoJpegCompression],
      maxHeight: this.photoSettings[SettingNames.PhotoGallery_MaxHeight],
      maxWidth: this.photoSettings[SettingNames.PhotoGallery_MaxWidth],
    };

    const selectedPhotos = await this.appInterfaceService.photoLibrary(payload);
    if (selectedPhotos.length === 0) {
      return;
    }

    const mobilePhoto = selectedPhotos[0];

    const validationResult = validateMobilePhoto(
      mobilePhoto,
      this.photoSettings,
    );
    if (typeof validationResult === 'object') {
      NotificationService.notifyError(
        this.translateService.instant(
          validationResult.key,
          validationResult.interpolateParams,
        ),
      );
      return;
    }

    const rotate = await shouldRotate(mobilePhoto);
    if (rotate) {
      await this.appInterfaceService.rotatePhoto({
        rotation: rotate,
        uuid: mobilePhoto.uuid,
        compressionQuality:
          this.photoSettings[SettingNames.PhotoGallery_AutoJpegCompression],
      });
    }
    return mobilePhoto;
  }

  async capturePhoto() {
    const payload = {
      compressionQuality:
        this.photoSettings[SettingNames.PhotoGallery_AutoJpegCompression],
      maxHeight: this.photoSettings[SettingNames.PhotoGallery_MaxHeight],
      maxWidth: this.photoSettings[SettingNames.PhotoGallery_MaxWidth],
    };

    const mobilePhoto: PhotoResult =
      await this.appInterfaceService.photoCapture(payload);

    if (!mobilePhoto || mobilePhoto?.cancelled) {
      return;
    }

    const validationResult = validateMobilePhoto(
      mobilePhoto,
      this.photoSettings,
    );

    if (typeof validationResult === 'object') {
      NotificationService.notifyError(
        this.translateService.instant(
          validationResult.key,
          validationResult.interpolateParams,
        ),
      );
      return;
    }

    const rotate = await shouldRotate(mobilePhoto);
    if (rotate) {
      await this.appInterfaceService.rotatePhoto({
        rotation: rotate,
        uuid: mobilePhoto.uuid,
        compressionQuality:
          this.photoSettings[SettingNames.PhotoGallery_AutoJpegCompression],
      });
    }

    return mobilePhoto;
  }
}
