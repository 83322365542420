import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BillingInfo, BillingInfoFilterRequest} from '../../models';
import {BaseHttpService} from './base.http-service';
import {IBillingInfoDataService} from '../../interfaces/data-service';

@Injectable()
export class BillingInfoHttpService
  extends BaseHttpService
  implements IBillingInfoDataService
{
  constructor(http: HttpClient) {
    super(http);
  }

  public getFilteredList(
    filter: BillingInfoFilterRequest,
  ): Promise<BillingInfo[]> {
    return this.POST('/billing-info/filter', filter);
  }
}
