import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {DxNumberBoxModule} from 'devextreme-angular';
import {InputNumberComponent} from './input-number.component';
import {FormFieldModule} from '../form-field';

const DX_MODULES = [DxNumberBoxModule];
const UI_MODULES = [FormFieldModule];

@NgModule({
  imports: [CommonModule, ...DX_MODULES, ...UI_MODULES],
  declarations: [InputNumberComponent],
  exports: [InputNumberComponent],
})
export class InputNumberModule {}

export * from './input-number.component';
