import {ObjectMapCache} from '@retrixhouse/salesapp-shared/lib/caching';
import {IProjectDataService} from '../../interfaces/data-service';
import {
  Project,
  ProjectSetting,
  UserKPISets,
  VisitFrequencyCalculation,
} from '../../models';
import {ProjectHttpService} from '../http';
import {ProjectOfflineService} from '../offline';
import {
  BaseCrudDataService,
  CachingOptions,
  TTL_DAY,
} from './base.data-service';
import {IProject, IProjectUser} from '@retrixhouse/salesapp-shared/lib/models';
import { IProjectCopyRequest } from '@retrixhouse/salesapp-shared/lib/requests';
import { IProcessingProgress } from '@retrixhouse/salesapp-shared/lib/responses';

export class ProjectDataService extends BaseCrudDataService<
  Project,
  IProjectDataService,
  ObjectMapCache<string, Project>
> {
  constructor(
    onlineService: ProjectHttpService,
    offlineService: ProjectOfflineService,
    cache: ObjectMapCache<string, Project>,
  ) {
    super(onlineService, offlineService, cache);
  }

  public getActiveList(): Promise<Project[]> {
    return this.service.getActiveList();
  }

  public getSettings(projectTypeId: string): Promise<ProjectSetting[]> {
    return this.service.getProjectSettings(projectTypeId);
  }

  public getAllSettings(): Promise<ProjectSetting[]> {
    return this.service.getAllProjectSettings();
  }

  public async getForCustomer(
    customerId: string,
    cachingOptions?: CachingOptions,
  ): Promise<Project[]> {
    if (cachingOptions?.skipCache) {
      return this.service.getProjectsForCustomer(customerId);
    }

    if (cachingOptions?.forceReload || !this._cache.isValid) {
      const projects = await this.service.getList();
      this.initCache(projects, cachingOptions?.ttl ?? TTL_DAY);
    }

    return customerId
      ? this._cache.getValues().filter(p => p.customerId === customerId)
      : this._cache.getValues();
  }

  public getTourPlansForPeriodicLimitCalculation(
    projectStores: {projectId: string; storeId: string}[],
  ): Promise<VisitFrequencyCalculation[]> {
    return this.service.getTourPlansForPeriodicLimitCalculation(projectStores);
  }

  public calculateFrequenciesForUser(): Promise<VisitFrequencyCalculation[]> {
    return this.service.calculateFrequenciesForUser();
  }

  public getAvailablePermissions(): Promise<string[]> {
    return this.service.getAvailableProjectPermissions();
  }

  public getUserPermissions(): Promise<{[projectId: string]: string[]}> {
    return this.service.getUserProjectPermissions();
  }

  public getWithRelations(relations?: {}): Promise<Project[]> {
    return this.service.getProjectsWithRelations(relations);
  }

  public getMyKPISets(projectId: string) {
    return this.service.getMyKPISets(projectId);
  }

  public getProjectUsersKPISets(projectId: string): Promise<UserKPISets[]> {
    return this.service.getProjectUsersKPISets(projectId);
  }

  public getProjectUser(projectId: string, userId: string): Promise<IProjectUser> {
    return this.service.getProjectUser(projectId, userId);
  }

  public updateProjectUser(
    projectId: string,
    userId: string,
    projectUser: Partial<IProjectUser>
  ): Promise<IProjectUser> {
    return this.service.updateProjectUser(projectId, userId, projectUser);
  }

  public async copyProject(
    request: IProjectCopyRequest,
  ): Promise<void> {
    const copyResult = await this.service.copyProject(request);
    return copyResult;
  }

  public async getCopyProgress(
    operationId: string,
  ): Promise<IProcessingProgress> {
    return this.service.getCopyProgress(operationId);
  }

  public async getCopyAvailability(): Promise<IProcessingProgress[]> {
    return this.service.getCopyAvailability();
  }
}
