import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  NgModule,
  OnChanges,
  OnInit,
} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {TranslateModule} from '@ngx-translate/core';
import {DevExtremeModule} from 'devextreme-angular';
import {environment} from 'src/environments/environment';
import {Product} from '../../models';
import {ScreenService} from '../../services';
import {BehaviorSubject, combineLatest} from 'rxjs';
import {CustomPipesModule} from '../../pipes';
import {
  ProductAvatarSettings,
  SettingNames,
} from '@retrixhouse/salesapp-shared/lib/settings';
import {IProductWithRelations} from '@retrixhouse/salesapp-shared/lib/models';
import {TSimpleChanges} from '../../utils/angular.utils';
import {DataProvider} from '../../data.provider/data-provider';
import {formatProduct} from '@retrixhouse/salesapp-shared/lib/utils';
import {ObjectTypeIds} from '@retrixhouse/salesapp-shared/lib/common';
import {ObjectTypeStorageService} from '../../services/storage/object-type-storage.service';
import {map, share} from 'rxjs/operators';
import {GenericListStorageService} from '../../services/storage/generic-list-storage.service';
@Component({
  selector: 'app-product-avatar',
  templateUrl: './product-avatar.component.html',
  styleUrls: ['./product-avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductAvatar implements OnChanges, OnInit {
  @Input() product: Product;
  @Input() projectId: string;
  /**
   * Whether to show the picture of the product or not.
   * If set, it will override the value from settings.
   */
  @Input() showPicture: boolean;
  /**
   * Whether to make the first line bold or not.
   * If set, it will override the value from settings.
   */
  @Input() line1Bold: boolean;
  /**
   * The template for the first line.
   * If set, it will override the value from settings.
   */
  @Input() line1Template: string;
  /**
   * The template for the second line.
   * If set, it will override the value from settings.
   */
  @Input() line2Template: string;

  @Input() pictureSize: 'small' | 'normal' = 'normal';

  isSmallScreen: boolean;
  settingsEnum = SettingNames;
  product$ = new BehaviorSubject<Product>(null);
  projectId$ = new BehaviorSubject<string>(null);
  showPicture$ = new BehaviorSubject(null);
  line1Bold$ = new BehaviorSubject(null);
  line1Template$ = new BehaviorSubject(null);
  line2Template$ = new BehaviorSubject(null);

  productSettings$ = combineLatest([
    this.projectId$,
    this.showPicture$,
    this.line1Bold$,
    this.line1Template$,
    this.line2Template$,
  ]).pipe(
    map(([projectId, showPicture, line1Bold, line1Template, line2Template]) => {
      const settings =
        this.dataProvider.settingResolver.getProductAvatarSettings(
          projectId,
        ) as ProductAvatarSettings;

      // the input properties have priority over the settings
      return {
        [SettingNames.ProductAvatar_ShowPicture]:
          showPicture != null
            ? showPicture
            : settings[SettingNames.ProductAvatar_ShowPicture],

        [SettingNames.ProductAvatar_Line1Bold]:
          line1Bold != null
            ? line1Bold
            : settings[SettingNames.ProductAvatar_Line1Bold],

        [SettingNames.ProductAvatar_Line1Template]:
          line1Template != null
            ? line1Template
            : settings[SettingNames.ProductAvatar_Line1Template],

        [SettingNames.ProductAvatar_Line2Template]:
          line2Template != null
            ? line2Template
            : settings[SettingNames.ProductAvatar_Line2Template],
      };
    }),
  );

  productDisplayText$ = combineLatest([
    this.product$,
    this.objctTypeStorageService.getObjectTypeById(ObjectTypeIds.Product),
    this.genericListStorageService.data$,
    this.productSettings$,
  ]).pipe(
    map(([product, productObjectType, genericLists, productSettings]) => {
      if (product && genericLists?.length > 0 && productObjectType) {
        return formatProduct<{line1: string; line2: string}>(
          this.product as IProductWithRelations,
          productSettings,
          false,
          {
            properties: productObjectType?.properties ?? [],
            genericLists: genericLists as any,
          },
        );
      }
    }),
    share(),
  );

  constructor(
    private genericListStorageService: GenericListStorageService,
    private objctTypeStorageService: ObjectTypeStorageService,
    private dataProvider: DataProvider,
    private screenService: ScreenService,
  ) {
    this.isSmallScreen = screenService.isSmallScreen();
  }

  async ngOnInit() {}

  async ngOnChanges(changes: TSimpleChanges<ProductAvatar>): Promise<void> {
    if (changes.product) {
      this.setPicture(changes.product.currentValue);
      this.product$.next(changes.product.currentValue);
    }

    if (changes.pictureSize) {
      this.pictureSize = changes.pictureSize?.currentValue;
    }

    if (changes.showPicture?.currentValue != null) {
      this.showPicture$.next(changes.showPicture.currentValue);
    }

    if (changes.line1Bold?.currentValue != null) {
      this.line1Bold$.next(changes.line1Bold.currentValue);
    }

    if (changes.line1Template?.currentValue != null) {
      this.line1Template$.next(changes.line1Template.currentValue);
    }

    if (changes.line2Template?.currentValue != null) {
      this.line2Template$.next(changes.line2Template.currentValue);
    }

    if (changes.projectId?.currentValue) {
      this.projectId$.next(changes.projectId.currentValue);
    }
  }

  setPicture(product: Product): void {
    if (product) {
      if (product.picture) {
        const timestamp = new Date().getTime();
        product.productPictureUrl = `${environment.productPictureBaseUrl}/${product.picture}?t=${timestamp}`;
      } else {
        product.productPictureUrl = `${environment.productPictureBaseUrl}/assets/no-image.png`;
      }
    }
  }
}

@NgModule({
  imports: [
    DevExtremeModule,
    CommonModule,
    TranslateModule,
    FlexLayoutModule,
    CustomPipesModule,
  ],
  declarations: [ProductAvatar],
  exports: [ProductAvatar],
})
export class ProductAvatarModule {}
