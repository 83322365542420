import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'app-notice',
  templateUrl: './notice.component.html',
  styleUrls: ['./notice.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoticeComponent {
  @Input() title: string;
  @Input() description: string;
  @Input() type: 'info' | 'warning' | 'danger';
}
