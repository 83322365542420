import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';

@Component({
  selector: 'app-form-actions',
  templateUrl: './form-actions.component.html',
  styleUrls: ['./form-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormActionsComponent {
  @Input() customActions: TemplateRef<any>;
  @Input() mobileDialogVersion: boolean;

  @Input() actionLabel: string;
  @Input() actionIcon?: string;
  @Input() actionDisabled?: boolean;
  @Input() actionVisible?: boolean;
  @Input() actionInProgress?: boolean; // Note: use for disabling action and cancel button e.g. when uploading to server

  @Input() cancelLabel?: string;
  @Input() cancelIcon?: string;
  @Input() cancelDisabled?: boolean;
  @Input() cancelVisible?: boolean;

  @Input()
  position: 'right' | 'left' = 'left';

  @Output() action = new EventEmitter<null>();
  @Output() cancel = new EventEmitter<null>();

  // Note: this part will be setup in form component
  formId: string;
  onAction = () => {};
  onCancel = () => {};

  get isActionDisabled() {
    return this.actionDisabled || this.actionInProgress;
  }

  get isCancelDisabled() {
    return this.cancelDisabled || this.actionInProgress;
  }

  constructor(public changeDetectorRef: ChangeDetectorRef) {}
}
