import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {FormGroup, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {ObjectTypeIds} from '@retrixhouse/salesapp-shared/lib/common';
import {
  IObjectFormGooglePlacesControlSetting,
  IObjectFormInputControl,
  IObjectFormLocationControlSetting,
  IObjectProperty,
  IProduct,
  IProductCategory,
  ObjectFormInputControlTypeEnum,
  ValueType,
} from '@retrixhouse/salesapp-shared/lib/models';
import {InputSelectOption} from '@salesapp/components';
import {FormActionsComponent} from '@salesapp/form';
import {
  InputGooglePlacesAddressValueEvent,
  InputGooglePlacesLongitudeLatitudeValueEvent,
} from '@salesapp/shared/components/input-google-places/input-google-places.component';
import {StorageService} from '@salesapp/storage';
import {InputSelectOptions} from '@salesapp/utils/input-select-options';
import {
  BehaviorSubject,
  Observable,
  Subscription,
  combineLatest,
  of,
} from 'rxjs';
import {map, pairwise, startWith} from 'rxjs/operators';
import {GenericListItemStorageService} from '../../../../services/storage/generic-list-item-storage.service';
import {
  AutoUnsubscribe,
  TSimpleChanges,
  isNotFirstChange,
  trackById,
} from '../../../../utils/angular.utils';
import {getTranslationMarkersForObjectProperty} from '../../../../utils/translation.utils';
import {DynamicFormService} from '../../services/dynamic-form.service';

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss'],
  providers: [DynamicFormService],
})
@AutoUnsubscribe()
export class DynamicFormComponent implements OnInit {
  @Input() objectTypeId: ObjectTypeIds;
  @Input() initialValue: unknown;
  @Input() formActions: FormActionsComponent;
  @Input() readonlyMode: boolean;

  @Output() actionClicked = new EventEmitter<any>();
  @Output() cancelClicked = new EventEmitter<unknown>();

  // TODO: REFACTORING SA-3375 remove this emitter when photo upload will be implemented the new way
  @Output() avatarPhotoUploaded =
    new EventEmitter<DynamicFormAvatarUploadedEvent>();

  form: FormGroup;

  options$: {
    [k: string]: Observable<InputSelectOption[]>;
  };

  visibleFormInputControlIds$: Observable<string[]>;
  visibleGroupIds$: Observable<string[]>;
  initialized$ = new BehaviorSubject(false);

  ValueType = ValueType;
  ObjectFormInputControlTypeEnum = ObjectFormInputControlTypeEnum;

  trackById = trackById;

  get formSchema() {
    return this.dynamicFormService.objectForm.formSchema;
  }

  get hasChanges() {
    return this.form.dirty;
  }

  private initializedSubscription: Subscription;
  private formValueChangedSubscription: Subscription;

  constructor(
    private dynamicFormService: DynamicFormService,
    private changeDetectorRef: ChangeDetectorRef,
    private storageService: StorageService,
    private genericListItemStorageService: GenericListItemStorageService,
    private translateService: TranslateService,
  ) {}

  ngOnChanges(changes: TSimpleChanges<DynamicFormComponent>): void {
    if (
      isNotFirstChange(changes.initialValue) &&
      !!changes.initialValue &&
      this.form
    ) {
      // NOTE: this will update version after save because we are subscribed to storageService in parent and after update we will get new values but updated values are marked as dirty
      this.form.patchValue(
        this.dynamicFormService.formatInitialData(
          changes.initialValue.currentValue as any,
        ),
        {
          emitEvent: false,
        },
      );

      this.form.markAsPristine();
      this.form.markAsUntouched();
    }
  }

  ngOnInit(): void {
    this.initDynamicFormService();
    this.visibleGroupIds$ =
      this.dynamicFormService.visibleGroupIds$.asObservable();
    this.visibleFormInputControlIds$ =
      this.dynamicFormService.visibleFormInputControlIds$.asObservable();
    this.initializedSubscription =
      this.dynamicFormService.initialized$.subscribe({
        next: initialized => {
          if (initialized) {
            this.initForm();
          }
        },
      });
  }

  onAvatarPhotoUploadFinished(
    responseState: any,
    formInputControl: IObjectFormInputControl,
  ) {
    const property = this.getFormInputControlProperty(formInputControl);
    const value = this.getFormControl(property.id).value;

    this.avatarPhotoUploaded.emit({
      responseState,
      formInputControl,
      property,
      value,
      objectId: this.form.getRawValue().id,
      version: this.form.getRawValue()?.version,
    });
  }

  onFormAction() {
    const formDataFormatted = this.dynamicFormService.formatFormDataBeforeSave(
      this.form.getRawValue(),
    );
    this.actionClicked.emit(formDataFormatted);
  }
  onFormCancel() {
    this.cancelClicked.emit();
  }

  getLabel(formInputControl: IObjectFormInputControl) {
    if (formInputControl.label) {
      return formInputControl.label;
    }

    const propertyData = this.getFormInputControlProperty(formInputControl);

    const translationMarker = getTranslationMarkersForObjectProperty({
      objectTypeId: this.objectTypeId,
      propertyName: propertyData.name,
    });

    return this.translateService.instant(translationMarker.label);
  }

  getHint(formInputControl: IObjectFormInputControl) {
    if (formInputControl.hint) {
      return formInputControl.hint;
    }

    const propertyData = this.getFormInputControlProperty(formInputControl);

    const translationMarker = getTranslationMarkersForObjectProperty({
      objectTypeId: this.objectTypeId,
      propertyName: propertyData.name,
    });
    const translation = this.translateService.instant(translationMarker.hint);

    return translation === translationMarker.hint ? null : translation;
  }

  getFormControl(propertyId: string) {
    const name =
      this.dynamicFormService.propertiesByIdMap.get(propertyId)?.name;
    return this.form.get(name) as UntypedFormControl;
  }

  getFormGroup(formInputControl: IObjectFormInputControl) {
    const property = this.dynamicFormService.propertiesByIdMap.get(
      formInputControl.propertyId,
    );
    switch (formInputControl.inputType) {
      case ObjectFormInputControlTypeEnum.Address:
        const addressPropertyWithData =
          this.dynamicFormService.propertiesByIdMap.get(
            property.navigationalPropertyId,
          );
        return this.form.get(addressPropertyWithData.name) as UntypedFormGroup;

      default:
        return this.form.get(property.name) as UntypedFormGroup;
    }
  }

  getLocationFormControls(formInputControl: IObjectFormInputControl) {
    let latitudeControl;
    let longitudeControl;
    let altitudeControl;
    const setting =
      formInputControl.settings as IObjectFormLocationControlSetting;
    if (setting.longitudePropertyId) {
      const longitudeProperty = this.dynamicFormService.propertiesByIdMap.get(
        setting.longitudePropertyId,
      );

      longitudeControl = this.form.get(longitudeProperty.name);
    }

    if (setting.latitudePropertyId) {
      const latitudeProperty = this.dynamicFormService.propertiesByIdMap.get(
        setting.latitudePropertyId,
      );

      latitudeControl = this.form.get(latitudeProperty.name);
    }

    if (setting.altitudePropertyId) {
      const altitudeProperty = this.dynamicFormService.propertiesByIdMap.get(
        setting.altitudePropertyId,
      );

      altitudeControl = this.form.get(altitudeProperty.name);
    }

    return {latitudeControl, longitudeControl, altitudeControl};
  }

  onGooglePlacesInputAddressChange(
    event: InputGooglePlacesAddressValueEvent,
    inputFormControl: IObjectFormInputControl,
  ) {
    const settings =
      inputFormControl.settings as IObjectFormGooglePlacesControlSetting;
    if (settings.address) {
      const propertyToUpdate = this.dynamicFormService.propertiesByIdMap.get(
        settings.address,
      );
      const addressPropertyWithData =
        this.dynamicFormService.propertiesByIdMap.get(
          propertyToUpdate.navigationalPropertyId,
        );
      const control = this.form.get(addressPropertyWithData.name) as FormGroup;
      control.patchValue(event);
    }
  }
  onGooglePlacesInputLongitudeLatitudeChange(
    event: InputGooglePlacesLongitudeLatitudeValueEvent,
    inputFormControl: IObjectFormInputControl,
  ) {
    const settings =
      inputFormControl.settings as IObjectFormGooglePlacesControlSetting;
    if (settings.latitude) {
      const propertyToUpdate = this.dynamicFormService.propertiesByIdMap.get(
        settings.latitude,
      );
      this.form.get(propertyToUpdate.name).setValue(event.latitude);
    }

    if (settings.longitude) {
      const propertyToUpdate = this.dynamicFormService.propertiesByIdMap.get(
        settings.longitude,
      );
      this.form.get(propertyToUpdate.name).setValue(event.longitude);
    }
  }

  private initForm() {
    const form = this.dynamicFormService.generateForm(
      this.dynamicFormService.formatInitialData(this.initialValue as any),
      this.readonlyMode,
    );
    this.form = form;
    this.initOptionsForControls();
    this.initFormValueChange();
    this.initialized$.next(true);
    this.changeDetectorRef.detectChanges();
  }

  private initDynamicFormService() {
    this.dynamicFormService.init(this.objectTypeId);
  }

  private getFormInputControlProperty(
    formInputControl: IObjectFormInputControl,
  ) {
    const propertyData = this.dynamicFormService.propertiesByIdMap.get(
      formInputControl.propertyId,
    );

    return propertyData;
  }

  private initOptionsForControls() {
    const options: {[k: string]: Observable<InputSelectOption[]>} = {};

    this.dynamicFormService.properties.forEach(property => {
      switch (property.valueType) {
        case ValueType.RelationToManyForeignKeys:
        case ValueType.RelationToOneForeignKey:
          if (property.objectTypeId !== ObjectTypeIds.Address) {
            options[property.id] = this.getSelectOptionsForObjectTypeId$(
              property.objectTypeId as ObjectTypeIds,
            );
          }
          break;
        case ValueType.SingleChoice:
        case ValueType.MultiChoice:
          options[property.id] =
            this.genericListItemStorageService.getItemsForListAsSelectOptions(
              property.listId,
            );
          break;
        case ValueType.Enum:
          options[property.id] = of(
            InputSelectOptions.enumToSelectOptions({
              translateService: this.translateService,
              enumName: property['enumName'],
            }),
          );

        default:
          break;
      }
    });

    this.options$ = options;
  }

  private getSelectOptionsForObjectTypeId$(
    propertyObjectTypeId: ObjectTypeIds,
  ) {
    switch (this.objectTypeId) {
      case ObjectTypeIds.Product:
        return this.getSelectOptionsForProduct$(propertyObjectTypeId);

      default:
        return this.storageService.getStorageByObjectTypeId(
          propertyObjectTypeId as ObjectTypeIds,
        ).dataAsSelectOptions$;
    }
  }

  private getSelectOptionsForProduct$(objectTypeId: ObjectTypeIds) {
    switch (objectTypeId) {
      case ObjectTypeIds.ProductCategory:
        return combineLatest([
          this.form.get('customerId').valueChanges.pipe(startWith(null)),
          // this.form.get('customerId').valueChanges.pipe(startWith(null)),
          this.storageService.getStorageByObjectTypeId(
            ObjectTypeIds.ProductCategory,
          ).dataAsSelectOptions$,
        ]).pipe(
          map(([selectedCustomerId, productCategoriesOptions]) => {
            const selectedCustomer =
              selectedCustomerId || this.form.controls.customerId.value;
            if (!selectedCustomer) {
              return [];
            }

            return productCategoriesOptions.filter(
              option =>
                (option.data as IProductCategory).customerId ===
                selectedCustomer,
            );
          }),
        );

      default:
        return this.storageService.getStorageByObjectTypeId(objectTypeId)
          .dataAsSelectOptions$;
    }
  }
  private initFormValueChange() {
    this.formValueChangedSubscription = this.form.valueChanges
      .pipe(startWith(null), pairwise())
      .subscribe({
        next: ([previousValue, currentValue]) => {
          this.dynamicFormService.updateFormAfterValueChange(this.form);
          switch (this.objectTypeId) {
            case ObjectTypeIds.Product:
              this.productPostProcessAfterFormValueChanged(
                previousValue,
                currentValue,
              );
              break;

            default:
              break;
          }
        },
      });
  }

  private productPostProcessAfterFormValueChanged(
    previousValue: IProduct,
    currentValue: IProduct,
  ) {
    if (!!previousValue) {
      if (previousValue.customerId !== currentValue.customerId) {
        this.form.get('categoryId').setValue(null, {emitEvent: false});
      }
    }
  }
}

export interface DynamicFormAvatarUploadedEvent {
  responseState: 'success' | 'error';
  formInputControl: IObjectFormInputControl;
  property: IObjectProperty;
  value: any;
  version: number;
  objectId: string;
}
