import * as uuid from 'uuid';

export class NotificationTemplate {
  id: string;
  uid: string;
  version: number;
  name: string;
  enabled: boolean;
  emailType: EmailType;
  emailSubject: string;
  emailBody: string;
  smsBody: string;

  constructor() {
    this.id = uuid.v4();
  }
}

export enum EmailType {
  PlainText = 'PlainText',
  HTML = 'HTML',
}
