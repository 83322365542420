export function ControlValueAccessor() {
  return function (constructor: Function) {
    const targetNgOnDestroy = constructor.prototype.ngOnDestroy;

    constructor.prototype.ngOnDestroy = function () {
      this.writeValue = () => {};
      this.setDisabledState = () => {};
      if (this._valueChangeSubscription) {
        this._valueChangeSubscription.unsubscribe();
      }

      if (targetNgOnDestroy) {
        targetNgOnDestroy.call(this);
      }
    };

    constructor.prototype.writeValue = function (value: any) {
      const previousValue = this.value;

      this.value = value;

      if (this.ngOnChanges) {
        this.ngOnChanges.call(this, {
          value: {
            currentValue: this.value,
            previousValue,
            firstChange: !this._firstChange,
          },
        });
        this._firstChange = true;
      }

      this.changeDetectorRef.detectChanges();
    };

    constructor.prototype.registerOnChange = function (fn: any) {
      if (this._valueChangeSubscription) {
        this._valueChangeSubscription.unsubscribe();
      }

      this._onChange = fn;
      this._valueChangeSubscription = this.valueChange.subscribe(
        (value: any) => {
          const previousValue = this.value;
          this.value = value;
          this._onChange(value);

          if (this.ngOnChanges) {
            this.ngOnChanges.call(this, {
              value: {
                currentValue: this.value,
                previousValue,
                firstChange: false,
              },
            });
          }

          setTimeout(() => this.changeDetectorRef.markForCheck(), 1);
        },
      );
    };

    constructor.prototype.registerOnTouched = function (fn: any) {
      this._onTouched = fn;
    };

    constructor.prototype.setDisabledState = function (disabled: boolean) {
      this.disabled = disabled;
      this.changeDetectorRef.detectChanges();
    };
  };
}
