import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ObjectTypeIds} from '@retrixhouse/salesapp-shared/lib/common';
import {
  ObjectWrapperDescriptor,
  WrapperDescriptor,
  WrapperDescriptorForRelation,
} from '@retrixhouse/salesapp-shared/lib/expr-eval';
import {
  Gender,
  IObjectProperty,
  ValueType,
} from '@retrixhouse/salesapp-shared/lib/models';
import {ObjectWrapperBuilderService} from '@salesapp/services';
import {ObjectStorageService} from '@salesapp/storage';
import {trackByIndex} from '@salesapp/utils/angular.utils';
import {getTranslationMarkersForObjectProperty} from '@salesapp/utils/translation.utils';
import {paramCase} from 'change-case';
import {BehaviorSubject, Observable, combineLatest, from} from 'rxjs';
import {debounceTime, filter, map, switchMap, tap} from 'rxjs/operators';
import * as uuid from 'uuid';

@Component({
  selector: 'app-attachment-template-legend',
  templateUrl: './attachment-template-legend.component.html',
  styleUrls: ['./attachment-template-legend.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttachmentTemplateLegendComponent {
  @Input() path: string;

  @Output() backClicked = new EventEmitter<void>();

  loading: boolean = true;
  selectedObjectTypeId = ObjectTypeIds.UserProfile;

  legend$: Observable<ObjectWrapperDescriptor>;
  filteredLegend$: Observable<ObjectWrapperDescriptor>;
  search$ = new BehaviorSubject<string>(null);

  trackByFn = trackByIndex;
  paramCase = paramCase;
  getTranslationMarkersForObjectProperty =
    getTranslationMarkersForObjectProperty;

  ValueType = ValueType;

  constructor(
    private objectStorageService: ObjectStorageService,
    private objectWrapperBuilderService: ObjectWrapperBuilderService,
    private translateService: TranslateService,
  ) {}

  ngOnInit() {
    this.initData();
  }

  isRelation(descriptor: any) {
    return [
      ValueType.RelationToOneData,
      ValueType.RelationToManyData,
      ValueType.RelationToOneForeignKey,
      ValueType.RelationToManyForeignKeys,
    ].includes(descriptor.property.valueType);
  }

  onSearchChange(search: string) {
    this.loading = true;
    this.search$.next(search);
  }

  private initData() {
    this.legend$ = this.objectStorageService.propertiesByObjectTypeId$.pipe(
      filter(propertiesByObjectId => propertiesByObjectId.size > 0),
      switchMap(propertiesByObjectId => {
        const data = mockDataForObjectWrapper({
          propertiesByObjectId,
          objectTypeId: this.selectedObjectTypeId,
        });
        return from(
          this.objectWrapperBuilderService.build({
            objectTypeId: this.selectedObjectTypeId,
            data,
            descriptorMode: true,
            path: this.path,
          }) as Promise<ObjectWrapperDescriptor>,
        );
      }),
      map(descriptor => {
        console.log('wrapper', descriptor);
        const transform = (
          objectDescriptor: ObjectWrapperDescriptor,
          objectTypeId: ObjectTypeIds,
        ) => {
          Object.entries(objectDescriptor).forEach(([key, value]) => {
            if (objectDescriptor.hasOwnProperty(key)) {
              const descriptor = value as WrapperDescriptor;
              const descriptorAsRelation =
                descriptor as WrapperDescriptorForRelation<any>;
              descriptor.path = descriptor.path.replace(/[ . ]/g, '?.');

              descriptor['propertyNameTranslation'] =
                this.translateService.instant(
                  getTranslationMarkersForObjectProperty({
                    objectTypeId: objectTypeId,
                    propertyName: descriptor.property.name,
                  }).label,
                );

              if (
                [
                  ValueType.RelationToManyData,
                  ValueType.RelationToOneData,
                ].includes(descriptorAsRelation.property.valueType)
              ) {
                descriptorAsRelation.relation = transform(
                  descriptorAsRelation.relation,
                  descriptor.property.objectTypeId as ObjectTypeIds,
                );
              }

              if (typeof descriptor.wrapper === 'object') {
                const wrapper = Array.isArray(descriptor.wrapper)
                  ? descriptor.wrapper[0]
                  : descriptor.wrapper;
                const paths = [];
                Object.entries(wrapper).forEach(([key, value]) => {
                  paths.push({
                    path: `${descriptor.path}${
                      descriptor.property.valueType === ValueType.MultiChoice
                        ? '?.[n]'
                        : ''
                    }?.${key}`,
                    key,
                  });
                });
                descriptor['paths'] = paths;
              }
            }
          });
          return objectDescriptor;
        };

        return transform(descriptor, this.selectedObjectTypeId);
      }),
    );

    this.filteredLegend$ = combineLatest([
      this.legend$,
      this.search$.pipe(debounceTime(400)),
    ]).pipe(
      map(([legend, search]) => {
        if (!search) {
          return legend;
        }

        const filterWrapper = (descriptor: WrapperDescriptor<any>) => {
          const filteredItems = {};

          Object.entries(descriptor).forEach(([key, value]) => {
            const descriptor = value as WrapperDescriptor;
            const descriptorAsRelation =
              descriptor as WrapperDescriptorForRelation<any>;
            if (descriptorAsRelation?.relation) {
              const relationPropertyFilterResult = descriptor[
                'propertyNameTranslation'
              ]
                .toLowerCase()
                .includes(search.toLowerCase());

              if (relationPropertyFilterResult) {
                filteredItems[key] = descriptor;
              } else {
                const relationItems = filterWrapper(
                  descriptorAsRelation.relation as any,
                );
                if (relationItems && Object.keys(relationItems).length) {
                  filteredItems[key] = {
                    ...descriptor,
                    relation: relationItems,
                  };
                }
              }
            } else {
              if (
                descriptor['propertyNameTranslation']
                  .toLowerCase()
                  .includes(search.toLowerCase())
              ) {
                filteredItems[key] = descriptor;
              }
            }
          });

          return Object.keys(filteredItems).length ? filteredItems : null;
        };
        return filterWrapper(legend as any);
      }),
      tap(() => (this.loading = false)),
    );
  }
}

function mockDataForObjectWrapper(params: {
  propertiesByObjectId: Map<string, IObjectProperty[]>;
  objectTypeId: string;
}) {
  const {propertiesByObjectId, objectTypeId} = params;
  const properties = propertiesByObjectId.get(objectTypeId);
  const objectData = {
    extendedProperties: {},
  };

  function addToData(property: IObjectProperty, value: any) {
    if (property.native) {
      objectData[property.name] = value;
    } else {
      objectData.extendedProperties[property.name] = value;
    }
  }

  properties.forEach(property => {
    switch (property.valueType) {
      case ValueType.Boolean:
        addToData(property, false);
        break;
      case ValueType.Date:
      case ValueType.DateTime:
        addToData(property, new Date());
        break;
      case ValueType.Enum:
        addToData(property, Gender.Female);
        break;
      case ValueType.Float:
      case ValueType.Integer:
        addToData(property, 0);
        break;
      case ValueType.MultiChoice:
        addToData(property, ['4544bf89-bd4c-4bb1-b226-603d04cd7151']);
        break;
      case ValueType.SingleChoice:
        addToData(property, '4544bf89-bd4c-4bb1-b226-603d04cd7151');

        break;
      case ValueType.RelationToOneForeignKey:
        if (!property.native) {
          throw new Error(
            'MOCK DATA: RelationToOne property is not supported for extended properties. Implement solution if needed.',
          );
        }
        objectData[property.name] = uuid.v4();
        break;
      case ValueType.RelationToOneData:
        objectData[property.name] = mockDataForObjectWrapper({
          propertiesByObjectId,
          objectTypeId: property.objectTypeId,
        });
        break;
      case ValueType.RelationToManyForeignKeys:
        if (!property.native) {
          throw new Error(
            'MOCK DATA: RelationToOne property is not supported for extended properties. Implement solution if needed.',
          );
        }
        objectData[property.name] = [uuid.v4()];
        break;
      case ValueType.RelationToManyData:
        objectData[property.name] = [
          mockDataForObjectWrapper({
            propertiesByObjectId,
            objectTypeId: property.objectTypeId,
          }),
        ];
        break;
      default:
        addToData(property, 'abc');
        break;
    }
  });

  return objectData;
}
