export class Response {
  id: string;
  result?: string;
  error?: {
    code: string;
    message: string;
    stackTrace?: string;
  };

  constructor(responseData: any) {
    this.id = responseData.id;
    this.result = responseData.result;
    this.error = responseData.error;
  }

  public isValid(): boolean {
    return !!this.id && (this.result === undefined || this.error === undefined);
  }
}

// export types for each response / event result
export type LocationResponseResult = {
  latitude?: number;
  longitude?: number;
  altitude?: number;
  accuracy?: number;
};

export type AppInfoResult = {
  platform: string;
  appVersion: string;
  osVersion: string;
  firebaseId: string;
  clientVersion?: string;
};

export type PhotoResult = {
  fileName?: string;
  width?: number;
  height?: number;
  checksum?: string;
  cancelled?: boolean;
  fileData?: string;
  uuid?: string;
  isSupported?: boolean;
  metaData: {
    width?: number;
    height?: number;
    orientation?: 'landscape' | 'portrait';
    location?: {
      latitude?: number;
      longitude?: number;
    };
    exif?: {
      [prop: string]: any;
    };
  };
};

export type PhotoFileResult = PhotoResult & {
  fileName: string;
};

export type StorageSizeResult = {
  keys: number;
  keysTotal: number;
  total?: number;
  available?: number;
};

export type RotationResult = {
  id: string;
  result: boolean;
};
export type KeyValuePairs = {
  [prop: string]: string;
};

export type PermissionsResultValue = 'granted' | 'denied' | 'undefined';

export type PermissionsResult = {
  photo: PermissionsResultValue;
  library: PermissionsResultValue;
  location: PermissionsResultValue;
  pushNotifications: PermissionsResultValue;
};
