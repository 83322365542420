import {Injectable} from '@angular/core';
import {
  PromoAction,
  PromoActionReportRequest,
  PromoActionReportResult,
} from '../../models/promo-action.model';
import {IPromoActionDataService} from '../../interfaces/data-service';
import {BaseCrudOfflineService} from './base.offline-service';
import {Filter} from '@loopback/filter';

@Injectable()
export class PromoActionOfflineService
  extends BaseCrudOfflineService<PromoAction>
  implements IPromoActionDataService
{
  getListWithChainIds(filter?: Filter): Promise<(PromoAction & {chainIds: string[]})[]> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public getForCustomer(customerId: string): Promise<PromoAction[]> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public getPromoActionsWithRelations(
    projectIds: string[],
  ): Promise<PromoAction[]> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public getReport(
    request: PromoActionReportRequest,
  ): Promise<PromoActionReportResult> {
    return Promise.reject('Offline service - not yet implemented');
  }
}
