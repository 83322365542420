import {HttpResponse} from '@angular/common/http';
import {IFailSafeDataService} from '../../interfaces/data-service';
import {DataTransferErrorDump} from '../../models/data-transfer-error-dump.model';
import {FailSafeHttpService} from '../http';
import {BaseReadonlyDataService} from './base.data-service';

export class FailSafeDataService extends BaseReadonlyDataService<
  DataTransferErrorDump,
  IFailSafeDataService
> {
  constructor(onlineService: FailSafeHttpService) {
    super(onlineService);
  }

  public initTransfer(data: {
    id: string;
    userId: string;
    clientVersion: string;
  }): Promise<{id: string}> {
    return this.service.initTransfer(data);
  }

  public resetTransfer(id: string): Promise<{id: string}> {
    return this.service.resetTransfer(id);
  }

  public finishTransfer(id: string): Promise<{id: string}> {
    return this.service.finishTransfer(id);
  }

  public downloadDumpFile(objectId): Promise<HttpResponse<Blob>> {
    return this.service.downloadDumpFile(objectId);
  }

  public remove(objectId: string): Promise<void> {
    return this.service.remove(objectId);
  }
}
