import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {ObjectTypeIds} from '@retrixhouse/salesapp-shared/lib/common';
import {
  IObjectProperty,
  ValueType,
} from '@retrixhouse/salesapp-shared/lib/models';
import {visibilityFilter} from '@retrixhouse/salesapp-shared/lib/utils/visibility-filter';
import {GenericListItemStorageService} from '@salesapp/storage';
import {TSimpleChanges, trackByIndex} from '@salesapp/utils/angular.utils';
import {paramCase} from 'change-case';
import {filter, map} from 'rxjs/operators';
import * as uuid from 'uuid';

@Component({
  selector: 'app-property-value-renderer',
  templateUrl: './property-value-renderer.component.html',
  styleUrls: ['./property-value-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PropertyValueRendererComponent {
  @Input() value: any;
  @Input() property: IObjectProperty;

  relationToManyValue: unknown[];
  unavailableCount: number;
  uuid: string;

  ValueType = ValueType;
  ObjectTypeIds = ObjectTypeIds;

  trackByIndex = trackByIndex;

  get enumTranslationMarker() {
    return `${paramCase(this.property.enumName)}.${paramCase(this.value)}`;
  }

  get isValueEmpty() {
    if (this.property.valueType === ValueType.MultiChoice) {
      return !this.value || this.value.length === 0;
    }
    return this.value === null || this.value === undefined;
  }

  constructor(
    private genericListItemStorageService: GenericListItemStorageService,
  ) {
    this.uuid = uuid.v4();
  }

  ngOnChanges(changes: TSimpleChanges<PropertyValueRendererComponent>): void {
    if (changes.value) {
      this.handleValueChanged();
    }
  }

  getGenericListItems(genericListItemIds: string[]) {
    return this.genericListItemStorageService.dataById$.pipe(
      filter(data => !!data.size),
      map(data =>
        genericListItemIds.map(id => {
          const item = data.get(id);
          const visible = visibilityFilter(item);
          return {
            ...item,
            visible,
          };
        }),
      ),
    );
  }

  getGenericListItem(genericListItemId: string) {
    return this.genericListItemStorageService.dataById$.pipe(
      filter(data => !!data.size),
      map(data => {
        const item = data.get(genericListItemId);
        const visible = visibilityFilter(item);
        return {
          ...item,
          visible,
        };
      }),
    );
  }

  private handleValueChanged() {
    if (this.property.valueType === ValueType.RelationToManyData) {
      let unavailableCount = 0;
      const availableValues = [];

      this.value.forEach(value => {
        if (value) {
          availableValues.push(value);
        } else {
          unavailableCount++;
        }
      });

      this.relationToManyValue = availableValues;
      this.unavailableCount = unavailableCount;
    }
  }
}
