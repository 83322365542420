import {ObjectMapCache} from '@retrixhouse/salesapp-shared/lib/caching';
import {IQuestionnaireItemDataService} from '../../interfaces/data-service';
import {QuestionnaireItem} from '../../models';
import {QuestionnaireItemHttpService} from '../http';
import {QuestionnaireItemOfflineService} from '../offline';
import {BaseCrudDataService} from './base.data-service';

export class QuestionnaireItemDataService extends BaseCrudDataService<
  QuestionnaireItem,
  IQuestionnaireItemDataService,
  ObjectMapCache<string, QuestionnaireItem>
> {
  constructor(
    onlineService: QuestionnaireItemHttpService,
    offlineService: QuestionnaireItemOfflineService,
    cache: ObjectMapCache<string, QuestionnaireItem>,
  ) {
    super(onlineService, offlineService, cache);
  }
}
