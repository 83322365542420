import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import {ControlValueAccessor} from '@salesapp/utils/reactive-form/control-value-accessor';
import {FormControlComponent} from '@salesapp/utils/reactive-form/form-control.component';
import {createFormControlProvider} from '@salesapp/utils/reactive-form/form.utils';

@Component({
  selector: 'app-input-date-range',
  templateUrl: './input-date-range.component.html',
  styleUrls: ['./input-date-range.component.scss'],
  providers: [createFormControlProvider(() => InputDateRangeComponent)],
})
@ControlValueAccessor()
export class InputDateRangeComponent extends FormControlComponent<string[]> {
  constructor(private changeDetector: ChangeDetectorRef) {
    super(changeDetector);
  }

  onValueChange(event) {
    this.valueChange.emit(event);
    this.changeDetector.markForCheck();
  }
}
