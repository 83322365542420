import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {InputTagsModule} from '@salesapp/shared/components/input-tags';
import {LoadingModule} from '@salesapp/shared/components/loading';
import {
  InputAddressModule,
  InputAvatarModule,
  InputDateModule,
  InputDatetimeModule,
  InputGooglePlacesModule,
  InputLocationModule,
  InputMultiSelectModule,
  InputNumberModule,
  InputRichTextModule,
  InputSelectModule,
  InputSwitchModule,
  InputTextModule,
} from '../../components';
import {CustomPipesModule} from '../../pipes';
import {FormModule} from '../form';
import {DynamicFormComponent} from './components/dynamic-form/dynamic-form.component';

const UI_MODULES = [
  InputAvatarModule,
  InputAddressModule,
  InputDateModule,
  InputDatetimeModule,
  InputGooglePlacesModule,
  InputLocationModule,
  InputMultiSelectModule,
  InputNumberModule,
  InputRichTextModule,
  InputSelectModule,
  InputSwitchModule,
  InputTagsModule,
  InputTextModule,
  LoadingModule,
];

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    ...UI_MODULES,
    FormModule,
    CustomPipesModule,
  ],
  declarations: [DynamicFormComponent],
  exports: [DynamicFormComponent],
})
export class DynamicFormModule {}

export * from './components/dynamic-form/dynamic-form.component';
