import {Pipe, PipeTransform} from '@angular/core';
import {MIME_TYPE_TO_FILE_EXTENSION} from '@salesapp/utils/file.utils';

@Pipe({name: 'fileType'})
export class FileTypePipe implements PipeTransform {
  constructor() {}
  transform(value: any, ...args: any[]) {
    const fileExtension = MIME_TYPE_TO_FILE_EXTENSION[value];

    return fileExtension || 'unknown';
  }
}
