export class GenericList {
  id: string;
  name: string;
  native: boolean;
  immutable: boolean;
  description?: string;
  valueType?: string;
  items: GenericListItem[];
}

export class GenericListItem {
  id: string;
  listId: string;
  name: string;
  description?: string;
  position: number;
  value?: any;
  visibleFrom?: Date;
  visibleTo?: Date;
  visibleFlag?: boolean;
}

export enum GenericListItemValueType {
  Integer = 'Integer',
  Float = 'Float',
  String = 'String',
  Boolean = 'Boolean',
  Date = 'Date',
  Time = 'Time',
  DateTime = 'DateTime',
  Duration = 'Duration',
}
