import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import {AbstractControl} from '@angular/forms';

@Component({template: ''})
export class FormControlComponent<T> {
  @Input() value: T;
  @Input() disabled: boolean;

  // inputs for form-field component
  @Input() label: string;
  @Input() placeholder?: string;
  @Input() hint?: string;
  @Input() required?: boolean;
  @Input() formControlRef?: AbstractControl;

  @Output() valueChange = new EventEmitter<T>();

  // getter for dx
  get isValid() {
    return this.formControlRef?.touched ? !this.formControlRef.invalid : true;
  }

  //NOTE: for scrolling to first invalid control. When control is more complex component and value passed to that component is parent form then there is a problem with scrolling to nearest invalid form control
  @HostBinding('class.ng-form-control')
  get cssClass() {
    return true;
  }

  constructor(private changeDetectorRef: ChangeDetectorRef) {}
}
