import {AuthGuardService} from '@salesapp/services';
import {DialogService} from '@salesapp/dialog';
import {RoleNames} from '@retrixhouse/salesapp-shared/lib/common';
import {TranslateService} from '@ngx-translate/core';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
} from '@angular/core';

@Component({
  selector: 'app-object-detail',
  templateUrl: './object-detail.component.html',
  styleUrls: ['./object-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ObjectDetailComponent implements OnInit {
  @Input() tabs: ObjectDetailTab[];
  @Input() activeTabId: string;
  @Input() tabsDisabled: boolean;

  @Output() activeTabChanged = new EventEmitter<string>();

  filteredTabs: ObjectDetailTab[];

  @ContentChildren('form')
  private components: QueryList<any>;

  get selectedTab() {
    return [this.activeTabId];
  }

  constructor(
    private dialogService: DialogService,
    private translateService: TranslateService,
    private authGuardService: AuthGuardService,
  ) {}

  ngOnInit() {
    this.initTabs();
  }

  onTabClick(event) {
    const dynamicFormComponents = this.components.toArray();

    const hasChanges = dynamicFormComponents.find(
      dynamicForm => dynamicForm.hasChanges,
    );

    if (hasChanges) {
      this.dialogService
        .warning({
          acceptLabel: this.translateService.instant(
            'object-detail.discrad-form-changes.accept',
          ),
          rejectLabel: this.translateService.instant(
            'object-detail.discard-form-changes.reject',
          ),
          showCloseButton: false,
          title: this.translateService.instant(
            'object-detail.discard-form-changes.title',
          ),
          description: this.translateService.instant(
            'object-detail.discard-form-changes.description',
          ),
        })
        .closed.subscribe({
          next: response => {
            if (response === 'accepted') {
              this.activeTabChanged.emit(event.itemData.id);
            }
          },
        });
    } else {
      this.activeTabChanged.emit(event.itemData.id);
    }
  }

  private initTabs() {
    this.filteredTabs = this.tabs.map(tab => {
      if (tab.enabledForRoles) {
        const disabled = !this.authGuardService.hasRoleAny(
          ...tab.enabledForRoles,
        );
        return {
          ...tab,
          disabled,
        };
      }

      return tab;
    });
  }
}

export interface ObjectDetailTab<T = string> {
  id: T;
  text: string;
  enabledForRoles?: RoleNames[];
}

export interface ObjectDetailForm<T> {
  actionCompleted: EventEmitter<T>;
  cancel: EventEmitter<void>;
  hasChanges: boolean;
}
