import * as uuid from 'uuid';
import {User} from './user.model';

export class Comment {
  id: string;
  objectId: string;
  postedById?: string;
  postedAt: Date;
  comment: string;
  postedBy?: User;

  [prop: string]: any;

  constructor() {
    this.id = uuid.v4();
    this.objectId = null;
    this.postedById = null;
    this.postedAt = null;
    this.comment = null;
  }
}
