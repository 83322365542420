<footer *ngIf="smallScreen; then smallFooter; else normalFooter"></footer>

<div id="app-footer">
  <ng-template #smallFooter>
    <small>
      © 2020-{{ appInfoService.currentYear }} RETRIX House s.r.o. | P.O.P. Hungary Zrt. | MPG d.o.o. - Client: {{ appInfoService.clientVersion }}
      <span *ngIf="appInfoService.isNewClientVersion">(<i class="fa-regular fa-cloud-arrow-down"></i> {{ appInfoService.clientVersionOnline }})</span>
      <ng-container [ngSwitch]="appInfoService.platformInfo">
        <ng-container *ngSwitchCase="'firefox'">
          &nbsp;<span>[<i class="platform-info fa-brands fa-firefox-browser" id="platform-firefox"></i> Firefox]</span>
        </ng-container>
        <ng-container *ngSwitchCase="'edge'">
          &nbsp;<span>[<i class="platform-info fa-brands fa-edge" id="platform-edge"></i> Edge]</span>
        </ng-container>
        <ng-container *ngSwitchCase="'safari'">
          &nbsp;<span>[<i class="platform-info fa-brands fa-safari" id="platform-safari"></i> Safari]</span>
        </ng-container>
        <ng-container *ngSwitchCase="'chrome'">
          &nbsp;<span>[<i class="platform-info fa-brands fa-chrome" id="platform-chrome"></i> Chrome]</span>
        </ng-container>
        <ng-container *ngSwitchCase="'opera'">
          &nbsp;<span>[<i class="platform-info fa-brands fa-opera" id="platform-opera"></i> Opera]</span>
        </ng-container>
        <ng-container *ngSwitchCase="'ie'">
          &nbsp;<span>[<i class="platform-info fa-brands fa-internet-explorer" id="platform-ie"></i> Internet Explorer]</span>
        </ng-container>
        <ng-container *ngSwitchCase="'ios-app'">
          &nbsp;<span>[<i class="platform-info fa-brands fa-apple" id="platform-ios-app"></i> SalesApp&reg; iOS]</span>
        </ng-container>
        <ng-container *ngSwitchCase="'android-app'">
          &nbsp;<span>[<i class="platform-info fa-brands fa-android" id="platform-android-app"></i> SalesApp&reg; Android]</span>
        </ng-container>
        <ng-container *ngSwitchDefault>
          &nbsp;<span>[<i class="platform-info fa fa-browser" id="platform-unknown"></i> Unknown]</span>
        </ng-container>
      </ng-container>

      Server: {{ appInfoService.serverVersion }} -
      <a style="cursor: pointer" (click)="restartClient()"
        ><u>{{ 'views.footer.restart-client' | translate }}</u></a
      >
    </small>
  </ng-template>

  <ng-template #normalFooter>
    <small>
      Copyright © 2020-{{ appInfoService.currentYear }}
      <a href="https://www.saleshouse.eu" target="_blank">RETRIX House s.r.o.</a>
      | Copyright © 2020-{{ appInfoService.currentYear }} <a href="http://popzrt.hu" target="_blank">P.O.P. Hungary Zrt.</a> | Copyright © 2020-{{ appInfoService.currentYear }}
      <a href="https://mpg.hr" target="_blank">MPG d.o.o.</a> |
      {{ 'footer.software-development' | translate }}
      <a href="https://www.theracomm.sk" target="_blank">TheraComm, s.r.o.</a>
      <br />
      {{ 'footer.trademarks' | translate }} <br />
      {{ 'footer.client-version' | translate }}: {{ appInfoService.clientVersion }}
      <span *ngIf="appInfoService.isNewClientVersion">(<i class="fa-regular fa-cloud-arrow-down"></i> {{ appInfoService.clientVersionOnline }})</span>

      <ng-container [ngSwitch]="appInfoService.platformInfo">
        <ng-container *ngSwitchCase="'firefox'">
          &nbsp;<span>[<i class="platform-info fa-brands fa-firefox-browser" id="platform-firefox"></i> Firefox]</span>
        </ng-container>
        <ng-container *ngSwitchCase="'edge'">
          &nbsp;<span>[<i class="platform-info fa-brands fa-edge" id="platform-edge"></i> Edge]</span>
        </ng-container>
        <ng-container *ngSwitchCase="'safari'">
          &nbsp;<span>[<i class="platform-info fa-brands fa-safari" id="platform-safari"></i> Safari]</span>
        </ng-container>
        <ng-container *ngSwitchCase="'chrome'">
          &nbsp;<span>[<i class="platform-info fa-brands fa-chrome" id="platform-chrome"></i> Chrome]</span>
        </ng-container>
        <ng-container *ngSwitchCase="'opera'">
          &nbsp;<span>[<i class="platform-info fa-brands fa-opera" id="platform-opera"></i> Opera]</span>
        </ng-container>
        <ng-container *ngSwitchCase="'ie'">
          &nbsp;<span>[<i class="platform-info fa-brands fa-internet-explorer" id="platform-ie"></i> Internet Explorer]</span>
        </ng-container>
        <ng-container *ngSwitchCase="'ios-app'">
          &nbsp;<span>[<i class="platform-info fa-brands fa-apple" id="platform-ios-app"></i> SalesApp&reg; iOS]</span>
        </ng-container>
        <ng-container *ngSwitchCase="'android-app'">
          &nbsp;<span>[<i class="platform-info fa-brands fa-android" id="platform-android-app"></i> SalesApp&reg; Android]</span>
        </ng-container>
        <ng-container *ngSwitchDefault>
          &nbsp;<span>[<i class="platform-info fa fa-browser" id="platform-unknown"></i> Unknown]</span>
        </ng-container>
      </ng-container>

      {{ 'footer.server-version' | translate }}: {{ appInfoService.serverVersion }}
    </small>
  </ng-template>
</div>
