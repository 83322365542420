import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {LocationStrategy} from '@angular/common';
import {AppInfoService} from '../services';

/**
 * This guard prevents to navigate to / off data transfer using back button
 * Can be extended to reject while some data is still being transferred
 */
@Injectable()
export class DataTransferNavigationGuard  {

  static usedBackButton: boolean | undefined;

  constructor(
    private router: Router,
    private location: LocationStrategy,
    private appInfoService: AppInfoService
  ) {
    // check if back or forward button is pressed.
    this.location.onPopState((event) => {
      // set isBackButtonClicked to true.
      DataTransferNavigationGuard.usedBackButton = true;
    });
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this.appInfoService.isMobileVersion) {
      return false;
    }
    if (DataTransferNavigationGuard.usedBackButton) {
      history.pushState(null, null, location.href);
      DataTransferNavigationGuard.usedBackButton = undefined;
      return false;
    }

    return true;
  }

  canDeactivate(
    component: any,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (DataTransferNavigationGuard.usedBackButton) {
      history.pushState(null, null, location.href);
      DataTransferNavigationGuard.usedBackButton = undefined;
      return false;
    }

    return true;
  }
}
