import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {FormControlComponent} from '../../utils/reactive-form/form-control.component';
import {ControlValueAccessor} from '../../utils/reactive-form/control-value-accessor';
import {createFormControlProvider} from '../../utils/reactive-form/form.utils';

@Component({
  selector: 'app-input-text',
  templateUrl: './input-text.component.html',
  styleUrls: ['./input-text.component.scss'],
  providers: [createFormControlProvider(() => InputTextComponent)],
})
@ControlValueAccessor()
export class InputTextComponent extends FormControlComponent<string> {
  @Input() mask?: string;
  @Input() buttonIcon?: string;

  @Output() buttonClick = new EventEmitter<void>();

  constructor(private changeDetector: ChangeDetectorRef) {
    super(changeDetector);
  }

  onValueChange(event) {
    this.valueChange.emit(event);
  }

  onButtonClick() {
    this.buttonClick.emit();
  }
}
