<div class="info-message"
     [ngClass]="{
      'info-message-danger': type === 'danger',
      'info-message-warning': type === 'warning',
}">
  <i [ngClass]="{
    'fa-circle-info': type === 'info',
    'fa-circle-exclamation': type === 'danger',
    'fa-triangle-exclamation': type === 'warning'
  }"
     class="fa-solid"></i>
  <div class="info-message-body">
    <span *ngIf="title"
          class="info-message-title">
      {{ title }}
    </span>
    <span *ngIf="description"
          class="info-message-description">
      {{ description }}
    </span>
  </div>
</div>