import {DIALOG_DATA, DialogRef} from '@angular/cdk/dialog';
import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {Router} from '@angular/router';
import {RoleNames} from '@retrixhouse/salesapp-shared/lib/common';
import {PushNotificationRead} from '@salesapp/api';
import {ObjectDialogService, PushNotificationService} from '@salesapp/services';
import {Observable} from 'rxjs';
// import {ObjectDialogService} from '@salesapp/shared/services/object-dialog.service';

@Component({
  selector: 'app-notifications-dialog',
  templateUrl: './notifications-dialog.component.html',
  styleUrls: ['./notifications-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationsDialogComponent {
  notifications$: Observable<PushNotificationRead[]>;

  RoleNames = RoleNames;

  constructor(
    private dialogRef: DialogRef<unknown, NotificationsDialogComponent>,
    @Inject(DIALOG_DATA) private data: any,
    private pushNotificationService: PushNotificationService,
  ) {
    this.notifications$ = this.pushNotificationService.notifications$;
  }

  ngOnInit() {}

  onClose() {
    this.dialogRef.close();
  }

  onNotificationClick(notification: PushNotificationRead) {
    this.dialogRef.close();
    this.pushNotificationService.notificationClicked(notification);
  }
}
