export class Scope {
  id: string;
  userDefined: boolean;
  name: string;
  description?: string | null;
  defaultAccess: string;
  tableDef?: string | null;
  exprDef?: string | null;
}

export enum AccessType {
  NoAccess = 'na',
  ReadOnly = 'ro',
  ReadWrite = 'rw',
}
