import notify from 'devextreme/ui/notify';

export class NotificationService {
  static pendingNotifications: string[] = [];
  private static showNotificationMillis = 3_000;
  private static showNotificationMillisError = 10_000;

  public static notifyWarning(message: string): void {
    this.notifyMessage(message, 'warning');
  }

  public static notifySuccess(message: string): void {
    this.notifyMessage(message, 'success');
  }

  public static notifyError(message: string): void {
    this.notifyMessage(message, 'error');
  }

  private static notifyMessage(
    message: string,
    type: 'success' | 'warning' | 'error',
  ): void {
    if (NotificationService.pendingNotifications.includes(message)) {
      return;
    }

    const displayTime =
      type === 'error'
        ? NotificationService.showNotificationMillisError
        : NotificationService.showNotificationMillis;

    // add to array
    NotificationService.pendingNotifications.push(message);

    notify(
      {
        message,
        hideOnOutsideClick: true,
        closeOnClick: true,
        closeButton: true,
        onHiding: () => NotificationService.onHiding(message),
        onShowing: NotificationService.onShowing,
        position: {
          my: 'center top',
          at: 'center top',
          offset: `0 ${50 + this.getOffset()}`,
        },
      },
      type,
      displayTime,
    );
  }

  private static getOffset(): number {
    const allToasts = document.getElementsByClassName('dx-toast');
    // 60 because 50 is height of toast plus 10px padding
    return allToasts && allToasts.length > 0 ? 60 * allToasts.length : 0;
  }

  private static onShowing(): void {
    const toastContentElem = document.getElementsByClassName('dx-toast-content');
    Array.from(toastContentElem).forEach(e => {
      if (!e.classList.contains('dx-toast-closable')) {
        e.classList.add('dx-toast-closable');
      }
    });
  }

  private static onHiding(message): void {
    NotificationService.pendingNotifications.splice(
      NotificationService.pendingNotifications.indexOf(message, 1),
    );
  }
}
