import {Injectable, isDevMode} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, Router} from '@angular/router';
import {
  IClientSideError,
  IClientSideErrorConf,
  IUserProfile,
} from '@retrixhouse/salesapp-shared/lib/models';
import {DataProvider} from '@salesapp/data-provider';
import {BehaviorSubject, Subscription, interval, of} from 'rxjs';
import {filter, switchMap, take, tap} from 'rxjs/operators';
import * as uuid from 'uuid';
import {AppInfoService} from '../../shared/services/app-info.service';
import {AuthGuardService} from '../../shared/services/auth.service';
import {ClientSideErrorConfStorageService} from '../../shared/services/storage/client-side-error-conf-storage.service';
import {ClientSideErrorStorageService} from '../../shared/services/storage/client-side-error-storage.service';
import {AutoUnsubscribe} from '../../shared/utils/angular.utils';

@Injectable()
@AutoUnsubscribe()
export class ErrorLoggerService {
  private errors$ = new BehaviorSubject<IClientSideError[]>([]);

  private currentUser$ = new BehaviorSubject<IUserProfile>(null);
  private currentUserConf: IClientSideErrorConf;

  private subscriptions: Subscription[] = [];

  get errors() {
    return this.errors$.getValue();
  }

  constructor(
    private clientSideErrorConfStorageService: ClientSideErrorConfStorageService,
    private clientSideErrorStorageService: ClientSideErrorStorageService,
    private router: Router,
    private appInfoService: AppInfoService,
    private authService: AuthGuardService,
    private activeRoute: ActivatedRoute,
    private dataProvider: DataProvider,
  ) {
    this.autoUploadErrors();
  }

  logError(error: Error) {
    this.clientSideErrorConfStorageService
      .currentUserConfig$()
      // of(null)
      .pipe(
        filter(config => {
          return !!config;
        }),
        take(1),
        switchMap(config => {
          const currentUrlConfig = `/${getActiveRouteFullConfigUrlFromSnapshot(
            this.activeRoute.snapshot,
          ).join('/')}`;
          if (
            config &&
            config.enabled &&
            config.routes.includes(currentUrlConfig)
          ) {
            this.addError(error, config);

            if (isDevMode()) {
              console.log('logged errors: ', this.errors);
            }

            if (this.errors.length === 10) {
              return this.uploadErrors();
            }

            return of(null);
          } else {
            return of(null);
          }
        }),
      )
      .subscribe();
  }

  private addError(error: Error, config: IClientSideErrorConf) {
    const formattedError = this.formatError(error, config.userId);
    const match = this.errors.find(
      e =>
        e.route === formattedError.route &&
        e.stackTrace === formattedError.stackTrace,
    );

    if (!match) {
      this.errors$.next([...this.errors, formattedError]);
    }
  }

  private formatError(error: Error, userId: string): IClientSideError {
    const clientSideError: IClientSideError = {
      userId,
      time: new Date(),
      id: uuid.v4(),
      clientPlatform: this.appInfoService.platformInfo,
      clientVersion: this.appInfoService.clientVersion,
      serverVersion: this.appInfoService.serverVersion,
      route: this.router.url,
      message: error.message,
      stackTrace: error.stack,
    };
    return clientSideError;
  }

  private autoUploadErrors() {
    this.subscriptions.push(
      // 5 minutes
      interval(60000)
        .pipe(
          switchMap(() => {
            return this.uploadErrors();
          }),
        )
        .subscribe(),
    );
  }

  private uploadErrors() {
    if (this.errors.length) {
      return this.clientSideErrorStorageService.uploadErrors(this.errors).pipe(
        tap(() => {
          this.errors$.next([]);
        }),
      );
    } else {
      return of(null);
    }
  }
}

export const getActiveRouteFullConfigUrlFromSnapshot = (
  routeSnapshot: ActivatedRouteSnapshot,
): any => {
  let route = routeSnapshot;
  let path = [];
  while (route.firstChild) {
    const config = route.firstChild?.routeConfig;

    if (config && config.path) {
      path.push(config.path);
    }
    route = route.firstChild;
  }
  return path;
};
