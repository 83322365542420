import {Injectable} from '@angular/core';
import {BaseHttpService} from './base.http-service';
import {HttpClient} from '@angular/common/http';
import {IUserStorageDataService} from '../../interfaces/data-service';

@Injectable()
export class UserStorageHttpService
  extends BaseHttpService
  implements IUserStorageDataService
{
  constructor(http: HttpClient) {
    super(http);
  }

  public getAll(): Promise<{key: string; value: any}[]> {
    return this.GET(`/auth/user-storage/`);
  }

  public getByKey<T>(key: string): Promise<T> {
    return this.GET<T>(`/auth/user-storage/${key}`);
  }

  public set<T>(key: string, data: T): Promise<void> {
    return this.POST<void>(`/auth/user-storage/${key}`, data);
  }

  public deleteByKey(key: string): Promise<void> {
    return this.DELETE(`/auth/user-storage/${key}`);
  }

  public deleteByKeys(keys: string[]): Promise<void> {
    return this.DELETE('/auth/user-storage/delete-values', {body: keys});
  }
}
