import {TranslateService} from '@ngx-translate/core';
import {ObjectTypeIds} from '@retrixhouse/salesapp-shared/lib/common';
import {
  Gender,
  ObjectFormInputControlTypeEnum,
  ReportExternalViewMode,
  TourPlanState,
  UnitSystem,
  ValueType,
} from '@retrixhouse/salesapp-shared/lib/models';
import {paramCase} from 'change-case';
import {InputSelectOption} from '../components/input-select';
import {sortByKey, ProjectOverviewTileInterval} from '../globals';
import {MimeTypes} from './file.utils';
import {TourPlan} from '../models';

export class InputSelectOptions {
  static enumToSelectOptions(params: {
    enumName: string;
    translateService: TranslateService;
  }): InputSelectOption[] {
    const {enumName, translateService} = params;
    const selectedEnum = ENUM_MAP[enumName];
    if (!selectedEnum) {
      console.error(
        'There is no enum for provided enumName. If enum is missing in ENUM_MAP you have to add it there before using it',
      );
    }
    return Object.keys(selectedEnum).map(enumKey => ({
      value: enumKey,
      name: translateService.instant(
        `${paramCase(enumName)}.${paramCase(enumKey)}`,
      ),
    }));
  }

  static tourPlanStateOptions(translateService: TranslateService) {
    return Object.keys(TourPlanState).map(tps => ({
      value: tps,
      name: translateService.instant('tour-plan-state.' + tps.toLowerCase()),
    }));
  }

  static inputTypeOptions(translateService: TranslateService) {
    return Object.entries(ObjectFormInputControlTypeEnum).map(
      ([key, value]) => ({
        value: value,
        name: translateService.instant(
          'object-form-input-control-type-enum.' + paramCase(key),
        ),
      }),
    );
  }

  static inputValueTypeOptions(translateService: TranslateService) {
    return sortByKey(
      Object.entries(ValueType).map(([key, value]) => ({
        value: value,
        name: translateService.instant('value-type-enum.' + paramCase(key)),
      })),
      'name',
    );
  }
}

const ENUM_MAP: {[k: string]: {[k: string]: string | number}} = {
  ['Gender']: Gender,
  ['MimeTypes']: MimeTypes,
  ['ObjectTypeIds']: ObjectTypeIds,
  ['ReportExternalViewMode']: ReportExternalViewMode,
  ['UnitSystem']: UnitSystem,
  ['ProjectOverviewTileInterval']: ProjectOverviewTileInterval,
  ['TourPlanState']: TourPlanState,
};
