import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnInit,
} from '@angular/core';
import {AbstractControl} from '@angular/forms';
import {Subscription} from 'rxjs';
import {tap} from 'rxjs/operators';
import {AutoUnsubscribe} from '../../utils/angular.utils';

@Component({
  selector: 'app-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@AutoUnsubscribe()
export class FormFieldComponent implements OnInit {
  @Input() label: string;
  @Input() hint: string;
  @Input() formControlRef: AbstractControl;
  @Input() required!: boolean;

  private statusChangeSubscription: Subscription;
  private valueChangeSubscription: Subscription;

  get isRequired() {
    if (this.formControlRef && this.formControlRef.validator) {
      const validators = this.formControlRef.validator({} as AbstractControl);
      return validators?.hasOwnProperty('required');
    }
    return !!this.required;
  }

  @HostBinding('class.salesapp-form-field--error')
  get hasError() {
    return this.formControlRef
      ? this.formControlRef.invalid && this.formControlRef.touched
      : false;
  }

  constructor(private changeDetector: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.detectStatusChanges();
  }

  ngDoCheck() {
    // NOTE(milan): because of ngIf in FormFieldErrorComponent in template change detection is not working properly here. investigate and fix this problem later
    if (this.hasError) {
      this.changeDetector.markForCheck();
    }
  }

  private detectStatusChanges() {
    if (this.formControlRef) {
      this.statusChangeSubscription = this.formControlRef.statusChanges
        .pipe(
          tap(data => {
            this.changeDetector.markForCheck();
          }),
        )
        .subscribe();
      this.valueChangeSubscription = this.formControlRef.valueChanges
        .pipe(
          tap(data => {
            this.changeDetector.markForCheck();
          }),
        )
        .subscribe();
    }
  }
}
