import * as uuid from 'uuid';

export type RequestName =
  | 'clientMessage'
  | 'getAppInfo'
  | 'setBadgeCount'
  | 'storageSave'
  | 'storageSaveAppend'
  | 'storageDelete'
  | 'storageLoad'
  | 'storageKeysSize'
  | 'storageKeys'
  | 'storageSize'
  | 'storageClear'
  | 'photoCapture'
  | 'photoLibrary'
  | 'locationGet'
  | 'locationTrack'
  | 'scanBarcode'
  | 'updateClient'
  | 'openUrl'
  | 'photoRotate'
  | 'photoStorageDelete'
  | 'photoStorageClear'
  | 'photoStorageKeys'
  | 'photoStorageKeysExist'
  | 'photoStorageKeysSize'
  | 'photoStorageLoad'
  | 'photoStorageSave'
  | 'photoStorageSaveAppend'
  | 'photoStorageRotate'
  | 'fileStorageSave'
  | 'fileStorageSaveAppend'
  | 'fileStorageDisplay'
  | 'photoCapturePending'
  | 'photoLibraryPending'
  | 'permissions'
  | 'openSettings';

export type RequestParams = {
  [prop: string]: any;
};

export class Request {
  id: string;
  name: RequestName;
  params?: RequestParams;

  constructor(name: RequestName, params?: RequestParams) {
    this.id = uuid.v4();
    this.name = name;
    this.params = params;
  }
}

export type ResamplingOptions = {
  compressionQuality?: number;
  maxWidth?: number;
  maxHeight?: number;
  photoStorage?: boolean;
};

export type RotationOption = {
  uuid: string;
  rotation: 'left' | 'right' | '180';
  compressionQuality?: number;
};

export type PhotoLibraryRequest = ResamplingOptions & {
  max: number;
};
