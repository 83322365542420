<div class="kpi-set">
  <div class="kpi-set-header">
    <span class="kpi-set-header-name">
      {{ kpiSet.name }}
    </span>
    <div class="kpi-set-header-rating">
      <ng-container *ngIf="kpiSet.rating.description">
        {{ kpiSet.rating.description }}
      </ng-container>
      <app-pill [text]="kpiSet.rating.value"
                [fontSize]="14"
                [color]="kpiSet.rating.color"></app-pill>
    </div>
  </div>
  <div class="kpi-set-kpis">
    <div *ngFor="let kpi of kpiSet.kpiItems; trackBy: trackByFn"
         class="kpi-set-kpi">
      <div class="kpi-set-kpi-label">
        {{ kpi.label }}
      </div>
      <app-pill [text]="kpi.value"
                [textColor]="'#ffffff'"
                [color]="kpi.color"></app-pill>
    </div>
  </div>

  <div class="kpi-set-description">
    <span class="kpi-set-description-label">{{ 'view.feedback.text.label' | translate }}</span>
    <div class="kpi-set-description-content">
      {{ kpiSet.description }}
    </div>
  </div>

  <div class="photos">
    <ng-container *ngFor="let photo of kpiSet.photos; let i = index; trackBy: trackByFn">
      <img class="image"
           [src]="(baseUrl + photo.uriThumbnail)"
           (click)="onImageClicked(kpiSet.photos, i)" />
    </ng-container>
  </div>
</div>