import {ChangeDetectorRef, Component} from '@angular/core';
import {TSimpleChanges} from '@salesapp/utils/angular.utils';
import {ControlValueAccessor} from '../../utils/reactive-form/control-value-accessor';
import {FormControlComponent} from '../../utils/reactive-form/form-control.component';
import {createFormControlProvider} from '../../utils/reactive-form/form.utils';

@Component({
  selector: 'app-input-tags',
  templateUrl: './input-tags.component.html',
  styleUrls: ['./input-tags.component.scss'],
  providers: [createFormControlProvider(() => InputTagsComponent)],
})
@ControlValueAccessor()
export class InputTagsComponent extends FormControlComponent<string[]> {
  options: any[];

  constructor(private changeDetector: ChangeDetectorRef) {
    super(changeDetector);
  }

  ngOnChanges(changes: TSimpleChanges<InputTagsComponent>): void {
    if (changes.value) {
      this.options = this.value || [];
    }
  }

  onValueChange(event) {
    if (event !== this.value) {
      this.valueChange.emit(event);
    }
  }

  updateBehavior(event) {
    event.component.option('openOnFieldClick', false);
  }

  onValueCreate(event) {
    const values: string[] = this.value;
    const optionExists = values?.includes(event?.text);

    if (optionExists) {
      return;
    }
    event.customItem = event.text;
  }
}
