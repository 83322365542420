import {CommonModule} from '@angular/common';
import {DialogModule} from '../../modules/dialog';
import {DxButtonModule} from 'devextreme-angular';
import {FormModule} from '../../modules/form';
import {InputSelectModule, LoadingContainerModule} from '@salesapp/components';
import {NgModule} from '@angular/core';
import {ObjectDetailModule} from '@salesapp/shared/modules';
import {ReactiveFormsModule} from '@angular/forms';
import {StoreCreateDialogComponent} from './components/store-create-dialog/store-create-dialog.component';
import {StoreDetailComponent} from './components/store-detail/store-detail.component';
import {StoreDetailDialogComponent} from './components/store-detail-dialog/store-detail-dialog.component';
import {StoreDetailPeriodicLimitComponent} from './components/store-detail-periodic-limit/store-detail-periodic-limit.component';
import {StoreFormComponent} from './components/store-form/store-form.component';
import {TranslateModule} from '@ngx-translate/core';
import {StoreService} from './services/store.service';

const SALESAPP_MODULES = [ObjectDetailModule, DialogModule, FormModule];
const UI_COMPONENTS = [LoadingContainerModule, InputSelectModule];

const DX_MODULES = [DxButtonModule];

const STORE_MODULE_COMPONENTS = [
  StoreCreateDialogComponent,
  StoreDetailDialogComponent,
  StoreDetailComponent,
  StoreFormComponent,
  StoreDetailPeriodicLimitComponent,
];
@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    ...SALESAPP_MODULES,
    ...UI_COMPONENTS,
    ...DX_MODULES,
  ],
  declarations: [...STORE_MODULE_COMPONENTS],
  providers: [StoreService],
  exports: [...STORE_MODULE_COMPONENTS],
})
export class StoreModule {}

export * from './components/store-create-dialog/store-create-dialog.component';
export * from './components/store-detail-dialog/store-detail-dialog.component';
