import {Injectable} from '@angular/core';
import {NewsPost, NewsPostWithUserInfo} from '../../models';
import {INewsPostDataService} from '../../interfaces/data-service';
import {BaseCrudOfflineService} from './base.offline-service';

@Injectable()
export class NewsPostOfflineService
  extends BaseCrudOfflineService<NewsPost>
  implements INewsPostDataService
{
  public getActualNewsPosts(): Promise<NewsPostWithUserInfo[]> {
    return Promise.reject('Offline service - not yet implemented');
  }
}
