import {User} from './user.model';

export class RequestLog {
  id: string;
  userId?: string;
  user?: User;
  time: Date;
  duration: number;
  hostname?: string;
  ip?: string;
  url?: string;
  method?: string;
  target?: string;
  responseCode?: string;
  error?: string;
}
