<ng-container *ngIf="formGroup">
  <app-form-row>
    <app-form-column>
      <app-input-text [formControl]="getControl('line1')"
                      [formControlRef]="getControl('line1')"
                      [label]="'labels.address-line1' | translate"></app-input-text>
    </app-form-column>
  </app-form-row>
  <app-form-row>
    <app-form-column>
      <app-input-text [formControl]="getControl('line2')"
                      [formControlRef]="getControl('line2')"
                      [label]="'labels.address-line2' | translate"></app-input-text>
    </app-form-column>
  </app-form-row>
  <app-form-row>
    <app-form-column>
      <app-input-text [formControl]="getControl('zipCode')"
                      [formControlRef]="getControl('zipCode')"
                      [label]="'labels.address-zip' | translate"></app-input-text>
    </app-form-column>
    <app-form-column>
      <app-input-text [formControl]="getControl('city')"
                      [formControlRef]="getControl('city')"
                      [label]="'labels.address-city' | translate"></app-input-text>
    </app-form-column>
  </app-form-row>
  <app-form-row>
    <app-form-column>
      <app-input-text [formControl]="getControl('region')"
                      [formControlRef]="getControl('region')"
                      [label]="'labels.address-region' | translate"></app-input-text>
    </app-form-column>
    <app-form-column>
      <app-input-text [formControl]="getControl('state')"
                      [formControlRef]="getControl('state')"
                      [label]="'labels.address-state' | translate"></app-input-text>
    </app-form-column>
  </app-form-row>
  <app-form-row>
    <app-form-column>
      <app-input-select [formControl]="getControl('countryId')"
                        [formControlRef]="getControl('countryId')"
                        [label]="'labels.address-country' | translate"
                        [options]="options$ | async"></app-input-select>
    </app-form-column>
  </app-form-row>
</ng-container>