import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {CurrencyFormatPipe} from './currency-format.pipe';
import {DateDiffPipe} from './date-diff.pipe';
import {SafeHtmlPipe} from './dom-sanitizer.pipe';
import {FileSizePipe} from './file-size.pipe';
import {FileTypePipe} from './file-type.pipe';
import {FormatProjectPipe} from './format-project.pipe';
import {FormatUserPipe} from './format-user.pipe';
import {LocalizedDatetimePipe} from './localized-datetime.pipe';
import {LocalizedOnlyTimePipe} from './localized-onlytime.pipe';
import {LogPipe} from './log.pipe';
import {PositionNamesPipe} from './positions.pipe';
import {StringConcatPipe} from './string-concat.pipe';
import {TranslateExtendedPipe} from './translate-extended.pipe';

@NgModule({
  declarations: [
    CurrencyFormatPipe,
    DateDiffPipe,
    FileSizePipe,
    FileTypePipe,
    FormatProjectPipe,
    FormatUserPipe,
    LocalizedDatetimePipe,
    LocalizedOnlyTimePipe,
    LogPipe,
    PositionNamesPipe,
    SafeHtmlPipe,
    StringConcatPipe,
    TranslateExtendedPipe,
  ],
  imports: [CommonModule, RouterModule],
  providers: [
    CurrencyFormatPipe,
    DateDiffPipe,
    FileSizePipe,
    FileTypePipe,
    FormatProjectPipe,
    FormatUserPipe,
    LocalizedDatetimePipe,
    LocalizedOnlyTimePipe,
    LogPipe,
    PositionNamesPipe,
    SafeHtmlPipe,
    StringConcatPipe,
    TranslateExtendedPipe,
  ],
  exports: [
    CurrencyFormatPipe,
    DateDiffPipe,
    FileSizePipe,
    FileTypePipe,
    FormatProjectPipe,
    FormatUserPipe,
    LocalizedDatetimePipe,
    LocalizedOnlyTimePipe,
    LogPipe,
    PositionNamesPipe,
    SafeHtmlPipe,
    StringConcatPipe,
    TranslateExtendedPipe,
  ],
})
export class CustomPipesModule {}
