import {v4 as uuid} from 'uuid';
import {GenericListItem} from './generic-list.model';
import {ObjectType} from './object-type.model';

export class Trigger {
  id: string;
  uid: string;
  version: number;
  eventId: string;
  event: GenericListItem;
  objectTypeId: string;
  objectType: ObjectType;
  enabled: boolean;
  name: string;
  definition: string;
  notes?: string;

  constructor() {
    this.id = uuid();
    this.eventId = null;
    this.objectTypeId = null;
    this.enabled = false;
    this.definition = null;
    this.name = null;
    this.notes = '';
  }
}

export class TriggerExecutionResult {
  ctx?: any;
  err?: any;
  success: boolean;
  logs: string[];
  startTime?: Date;
  endTime?: Date;
  duration?: number;
}
