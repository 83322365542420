import {Injectable} from '@angular/core';
import {ProjectStorePeriodicLimit} from '../../models/project-store-periodic-limit.model';
import {PeriodicLimit} from '../../models';
import {IPeriodicLimitDataService} from '../../interfaces/data-service';
import {BaseCrudOfflineService} from './base.offline-service';

@Injectable()
export class PeriodicLimitOfflineService
  extends BaseCrudOfflineService<PeriodicLimit>
  implements IPeriodicLimitDataService
{
  public getPeriodicLimitsForAvailableStoresStore(): Promise<
    ProjectStorePeriodicLimit[]
  > {
    return Promise.reject('Offline service - not yet implemented');
  }

  public getPeriodicLimitsForStore(
    storeId: string,
  ): Promise<ProjectStorePeriodicLimit[]> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public createPeriodicLimitForStore(
    periodicLimit: ProjectStorePeriodicLimit,
  ): Promise<ProjectStorePeriodicLimit> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public deletePeriodicLimitForStore(id: string): Promise<void> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public setPeriodicLimitsForStore(
    storeId: string,
    periodicLimits: ProjectStorePeriodicLimit[],
  ): Promise<ProjectStorePeriodicLimit[]> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public validatePeriodicLimit(
    type: string,
    offset: number,
    duration: number,
  ): Promise<boolean> {
    return Promise.reject('Offline service - not yet implemented');
  }
}
