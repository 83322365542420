<!-- TODO: remove unused attributes -->
<app-dialog [title]="'entity.feedback.dialog.detail.title' | translate"
            [actionVisible]="false"
            [cancelVisible]="true"
            [cancelIcon]=""
            [cancelLabel]="'form.action.close' | translate"
            (cancelClicked)="onClose()"
            [noPadding]="true">

  <app-feedback-detail dialogContent
                       [feedbackId]="this.feedbackId"></app-feedback-detail>

</app-dialog>