<div id="data-upload-wrapper">
  <div>NOT IMPLEMENTED</div>

  <!--
  <div class='my-1'>
    <app-data-transfer-summary
      [totalCount]='progressMap.size'
      [dataSize]='dataSize ?? 0'
      [showCompletedProgressBars]='showCompletedProgressBars'
      [pendingObservablesCount]='pendingObservablesCount ?? 0'
      [errorCount]='errorCount?.total ?? 0'
      [repeatableErrorCount]='errorCount?.repeatable ?? 0'
      (toggleShowMode)='toggleShowMode($event)'
      (retryFailed)='retryFailed()'
    ></app-data-transfer-summary>
  </div>

  <ng-container *ngFor="let progress of progressMap | keyvalue" >
    <div *ngIf='showCompletedProgressBars || !progress.value.completed' class='progress-wrapper my-2'>
      <app-data-transfer-upload-progress
        [modelName]="progress.value.label | translate"
        [icon]="progress.value.icon"
        [completed]="progress.value.completed"
        [error]="progress.value.error && progress.value.error.length > 0"
        [synchronizedCount]='progress.value.synchronizedCount'
        [modifiedCount]='progress.value.modifiedCount'
        [totalDataSize]='progress.value.totalDataSize'
        [synchronizedDataSize]='progress.value.synchronizedDataSize'
        (retryWhenFailed)='retryFailed(progress.key)'
        ></app-data-transfer-upload-progress>
    </div>
  </ng-container>

  <ng-container *ngIf='errorCount?.fixable > 0'>
    <div class='fixable-error-wrapper my-2'>
      <div class='fixable-error-header'>{{'views.data-transfer.label-fixable-error' | translate}}</div>
      <div *ngFor='let keyval of fixableErrorsCache | keyvalue' class='fixable-error'
           (click)='fixUrClick(keyval.value.url)'>
        <ng-container [ngSwitch]='keyval.value.type'>
          <ng-container *ngSwitchCase='KEY_STORES'>
            <div (click)='fixUrClick(keyval.value.url)' class='error store-error'>
              <div class='store-selector-body'>
                <div>
                  <span class='store-name' [title]='keyval.value.data.name'>
                    {{keyval.value.data.storeName}}
                  </span>
                  <small class='store-uid'>({{ keyval.value.data.uid }})</small>
                </div>
                <div class='store-address'>
                  {{ keyval.value.data.address }}
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase='KEY_TOUR_PLANS'>
            <div (click)='fixUrClick(keyval.value.url)' class='error tour-plan-error'>
              <app-visit-avatar [tourPlan]='keyval.value.data' [showProject]='false'
                                [showActionButtons]='false'></app-visit-avatar>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase='KEY_VISIT_RESULTS'>
            <div (click)='fixUrClick(keyval.value.url)' class='error visit-result-error'>
              <app-visit-avatar [tourPlan]='keyval.value.data' [showProject]='false'
                                [showActionButtons]='false'></app-visit-avatar>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf='!syncPending && failSafe?.hasErrors()'>
    <app-data-transfer-fail-safe
      [failSafe]='failSafe'
      [userId]='userProfile.userId'
      (failSafeFinish)='failSafeFinish()'
    ></app-data-transfer-fail-safe>
  </ng-container>

  <ng-container *ngIf='!syncPending && failSafe?.hasErrors()'>
    <div class='my-1'>
      <dx-button
        icon='fa fa-folder-open'
        text="{{ 'views.data-transfer.file-manager' | translate }}"
        [width]="'100%'"
        (click)='btnOpenFileManagerClick()'
      ></dx-button>
    </div>
  </ng-container>

  <div class='group-container'>
    <dx-button
      (onClick)='btnContinueClick()'
      [disabled]='!(pendingObservablesCount !== undefined && (pendingObservablesCount === 0 || (pendingObservablesCount > 0 && errorCount?.total !== 0 && errorCount?.total === errorCount?.ignore)))'
      [useSubmitBehavior]='false'
      icon='fa fa-save'
      [text]='formatBtnContinueText()'
      type='default'
      [ngStyle.lt-md]="{'width': '100%'}"
    ></dx-button>
    &nbsp;
    <dx-button
      type="normal"
      text="{{ 'buttons.cancel' | translate }}"
      icon="fa fa-times"
      (onClick)="btnCancelClick()"
      [ngStyle.lt-md]="{'width': '100%'}"
    ></dx-button>
  </div>
  -->
</div>
