import {Injectable} from '@angular/core';
import {BaseCrudHttpService} from './base.http-service';
import {HttpClient} from '@angular/common/http';
import {Product} from 'src/app/shared/models/product.model';
import {IProductDataService} from '../../interfaces/data-service';
import {Filter} from '@loopback/filter';

@Injectable()
export class ProductHttpService
  extends BaseCrudHttpService<Product>
  implements IProductDataService
{
  constructor(http: HttpClient) {
    super(http, '/products');
  }

  public getForCustomer(
    customerId: string,
    enabledOnly: boolean = false,
    filter: Filter<Product> = {},
  ): Promise<Product[]> {
    return this.GET<Product[]>(
      `/customer/${customerId}/products`,
      filter
        ? {params: {filter: JSON.stringify(filter), enabledOnly: enabledOnly}}
        : {enabledOnly: enabledOnly},
    );
  }

  public moveProducts(categoryId: string, productIds: string[]): Promise<void> {
    return this.POST<void>(`/products/move`, {categoryId, productIds});
  }

  public updateProductPicture(
    productId: string,
    version: number,
    picture: string,
  ): Promise<Product> {
    return this.PATCH(`/products/${productId}`, {
      id: productId,
      version,
      picture,
    });
  }

  public deleteProductPicture(productId: string): Promise<void> {
    return this.DELETE<void>(`/products/${productId}/picture`);
  }
}
