import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseCrudHttpService} from './base.http-service';
import {DataStorageFolder} from '../../models';
import {IDataStorageFolderDataService} from '../../interfaces/data-service';

@Injectable()
export class DataStorageFolderHttpService
  extends BaseCrudHttpService<DataStorageFolder>
  implements IDataStorageFolderDataService
{
  constructor(http: HttpClient) {
    super(http, '/data-storage/folders');
  }
}
