import {Injectable} from '@angular/core';
import {BillingInfo, BillingInfoFilterRequest} from '../../models';
import {IBillingInfoDataService} from '../../interfaces/data-service';
import {BaseOfflineService} from './base.offline-service';

@Injectable()
export class BillingInfoOfflineService
  extends BaseOfflineService
  implements IBillingInfoDataService
{
  public getFilteredList(
    filter: BillingInfoFilterRequest,
  ): Promise<BillingInfo[]> {
    return Promise.reject('Offline service - not yet implemented');
  }
}
