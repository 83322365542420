<dx-popup contentTemplate="content"
          [showTitle]="false"
          [dragEnabled]="false"
          [showCloseButton]="true"
          container=".dx-viewport"
          [(visible)]="visible"
          [fullScreen]="true"
          (onContentReady)="contentReady($event)"
          (onHidden)="onPopupHidden($event)"
          (onShown)="onPopupShown($event)">
  <div *dxTemplate="let data of 'content'">
    <div class="side-panel-container"
         *ngIf="allowSidePanle && !isSmallScreen">
      <dx-scroll-view width="100%"
                      height="100%">
        <div class="side-panel-content">
          <ng-content select="[side-panel]"> </ng-content>
        </div>
      </dx-scroll-view>
    </div>

    <div [ngClass]="{'icons-container': !allowSidePanle, 'icons-container-side-panel': allowSidePanle && !isSmallScreen}">
      <i (click)="closePhotoViewMode($event)"
         class="fa-solid fa-circle-xmark"></i>
      <ng-content> </ng-content>
    </div>
    <ng-container *ngIf="imgTemplate; else normalImage">
      <ng-container *ngTemplateOutlet="imgTemplate"> </ng-container>
    </ng-container>
  </div>
</dx-popup>

<ng-template #normalImage>
  <pinch-zoom style="height: 100%">
    <img [src]="imageUrl"
         style="height: 100%; width: 100%; object-fit: contain"
         (error)="imgLoadError($event)"
         (load)="imageLoaded($event)" />
  </pinch-zoom>
</ng-template>