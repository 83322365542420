import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {UserProfileStorageService} from '../storage/user-profile-storage.service';

@Injectable()
export class UserProfileReadService {
  constructor(private userProfileStorage: UserProfileStorageService) {}

  getUserIdByUserProfileId(userProfileId: string): Observable<string> {
    return this.userProfileStorage.dataById$.pipe(
      map(userProfileMap => {
        const userProfile = userProfileMap.get(userProfileId);

        if (userProfile) {
          return userProfile.userId;
        }

        return null;
      }),
    );
  }
}
