import {
  ICache,
  ObjectArrayCache,
  ObjectMapCache,
} from '@retrixhouse/salesapp-shared/lib/caching';
import {ClientMode} from '../../enums/client-mode.enum';
import {
  ICrudDataService,
  IDataService,
  IReadonlyDataService,
} from '../../interfaces/data-service';
import deepEqual from 'deep-equal';
import cloneDeep from 'clone-deep';
import {Filter} from '@loopback/filter';
import {EventEmitter} from '@angular/core';

export const TTL_HOUR = 60 * 60 * 1000;
export const TTL_DAY = 24 * TTL_HOUR;

/**
 * Caching options.
 */
export type CachingOptions = {
  /**
   * Skip cache.
   */
  skipCache?: boolean;
  /**
   * Time-to-live in milliseconds of the data in the cache.
   */
  ttl?: number;
  /**
   * Force data reload in the cache.
   */
  forceReload?: boolean;
};

/**
 * Cache initialization options.
 */
export type InitCacheOptions = {
  /**
   * Behavior to use for cache initialization.
   */
  initBehavior?: 'list' | 'single';
  /**
   * Filter to use to initialize the cache.
   */
  initFilter?: Filter;
};

/**
 * Base class for all data services.
 */
export abstract class BaseDataService<
  TDataService extends IDataService = IDataService,
  TCache extends ICache = ICache,
> implements IDataService
{
  /**
   * Current operating mode of the client (online / offline).
   */

  protected _mode: ClientMode;
  /**
   * Instance of the internal cache.
   */
  protected _cache?: TCache;

  /**
   * Default time to live of data in the cache in milliseconds.
   */
  protected _defaultTtl?: number;

  /**
   * Instance of the online HTTP service.
   */
  protected _onlineService: TDataService;

  /**
   * Instance of the offline service.
   */
  protected _offlineService?: TDataService;

  /**
   * Gets supported client mode.
   */
  public get supportedMode(): ClientMode | 'both' {
    if (this._onlineService && this._offlineService) {
      return 'both';
    }

    if (this._onlineService) {
      return ClientMode.ONLINE;
    }

    if (this._offlineService) {
      return ClientMode.OFFLINE;
    }

    throw new Error(
      'Unable to determine supported mode - wrongly constructed data service.',
    );
  }

  /**
   * Determines whether this data service supports online mode.
   */
  public get supportsOnline(): boolean {
    return !!this._onlineService;
  }

  /**
   * Determines whether this data service supports offline mode.
   */
  public get supportsOffline(): boolean {
    return !!this._offlineService;
  }

  /**
   * Determines whether this data service supports caching.
   */
  public get supportsCaching(): boolean {
    return !!this._cache;
  }

  /**
   * Gets current client operating mode.
   */
  public get mode(): ClientMode {
    return this._mode;
  }

  /**
   * Sets current client operating mode.
   */
  public set mode(m: ClientMode) {
    if (this._onlineService === undefined && m === ClientMode.ONLINE) {
      throw new Error('This data service does not support online mode.');
    }

    if (this._offlineService === undefined && m === ClientMode.OFFLINE) {
      throw new Error('This data service does not support offline mode.');
    }

    this._mode = m;
  }

  /**
   * Determines whether this data service currently operates in online mode.
   */
  public get isOnline(): boolean {
    return this._mode === ClientMode.ONLINE;
  }

  /**
   * Determines whether this data service currently operates in offline mode.
   */
  public get isOffline(): boolean {
    return this._mode === ClientMode.OFFLINE;
  }

  /**
   * Invalidates cache (if any).
   */
  public invalidateCache(): void {
    if (!this._cache) {
      throw new Error('This data service does not support caching.');
    }

    this._cache.invalidate();
  }

  /**
   * Checks current client operating mode and throws error if corresponding service is missing.
   */
  protected checkMode(): void {
    if (!this._onlineService && this._mode === ClientMode.ONLINE) {
      throw new Error('Data service for online mode not available.');
    }

    if (!this._offlineService && this._mode === ClientMode.OFFLINE) {
      throw new Error('Data service for offline mode not available.');
    }
  }

  /**
   * Gets currently operating service.
   */
  protected get service(): TDataService {
    this.checkMode();
    return this.isOnline ? this._onlineService : this._offlineService;
  }

  /**
   * Checks whether the caching options are properly set.
   * @param {CachingOptions & InitCacheOptions} cachingOptions - caching options
   */
  protected checkCachingOptions(
    cachingOptions?: CachingOptions & InitCacheOptions,
  ): void {
    // do nothing if there are no caching options
    if (!cachingOptions) {
      return;
    }

    if (cachingOptions.skipCache && cachingOptions.forceReload) {
      throw new Error(
        'Invalid caching options: skipCache & forceReload are mutually exclusive.',
      );
    }

    if (cachingOptions.ttl !== undefined && cachingOptions.ttl <= 0) {
      throw new Error('Invalid caching options: time-to-live must be > 0 ms.');
    }

    if (cachingOptions.initBehavior === 'single' && cachingOptions.initFilter) {
      throw new Error(
        'Invalid caching options: init filter can not be used when init behavior is single.',
      );
    }
  }

  /**
   * Constructor.
   * @param {TDataService} onlineService - online service instance
   * @param {TDataService} offlineService - offline service instance (optional)
   * @param {TCache} cache - cache instance (optional)
   */
  constructor(
    onlineService: TDataService,
    offlineService?: TDataService,
    cache?: TCache,
  ) {
    this._mode = ClientMode.ONLINE;
    this._defaultTtl = TTL_DAY;
    this._onlineService = onlineService;
    this._offlineService = offlineService;
    this._cache = cache;
  }
}

/**
 * Base class for read-only data service.
 */
export abstract class BaseReadonlyDataService<
  TData extends {id: string},
  TDataService extends IReadonlyDataService<TData>,
  TCache extends ICache = ICache,
> extends BaseDataService<TDataService, TCache> {
  /**
   * Last filter used to initialize the cache.
   */
  protected _lastFilter?: Filter;

  /**
   * Constructor.
   * @param {TDataService} onlineService - online service instance
   * @param {TDataService} offlineService - offline service instance (optional)
   * @param {TCache} cache - cache instance (optional)
   */
  constructor(
    onlineService: TDataService,
    offlineService?: TDataService,
    cache?: TCache,
  ) {
    super(onlineService, offlineService, cache);
  }

  /**
   * Initializes the cache.
   * @param {TData[]} list - array of data objects to initialize cache with
   * @param {number} ttl - time to live in milliseconds of the data in cache
   */
  protected initCache(list: TData[], ttl?: number): void {
    if (!this.supportsCaching) {
      throw new Error('This data provider does not support caching.');
    }

    if (this._cache instanceof ObjectArrayCache) {
      const cache = this._cache as ObjectArrayCache<TData>;
      cache.init(list, ttl ?? this._defaultTtl);
    } else if (this._cache instanceof ObjectMapCache) {
      const cache = this._cache as ObjectMapCache<string, TData>;
      cache.init(
        list.map(i => [i.id, i]),
        ttl ?? this._defaultTtl,
      );
    }
  }

  /**
   * Gets list of entities.
   * @param {Filter} filter - Loopback filter
   * @param {CachingOptions} cachingOptions - caching options
   * @returns {Promise<TData[]>} array of entities
   */
  public async getList(
    filter?: Filter<TData>,
    cachingOptions?: CachingOptions,
  ): Promise<TData[]> {
    this.checkCachingOptions(cachingOptions);

    // no caching
    if (cachingOptions?.skipCache || !this.supportsCaching) {
      return this.service.getList(filter);
    }

    // with caching
    if (
      cachingOptions?.forceReload ||
      !this._cache.isValid ||
      !deepEqual(this._lastFilter, filter)
    ) {
      this._lastFilter = cloneDeep(filter);
      const list = await this.service.getList(filter);
      this.initCache(list, cachingOptions?.ttl);
    }

    if (this._cache instanceof ObjectArrayCache) {
      const cache = this._cache as ObjectArrayCache<TData>;
      return cache.getAll();
    } else if (this._cache instanceof ObjectMapCache) {
      const cache = this._cache as ObjectMapCache<string, TData>;
      return cache.getValues();
    }

    // should not happen
    return Promise.reject('Unsupported cache type.');
  }

  /**
   * Gets single entity by id.
   * @param {string} id - id of the entity to get
   * @param {CachingOptions & InitCacheOptions} cachingOptions - caching options
   * @returns {Promise<TData[]>} entity instance if found; undefined otherwise
   */
  public async getSingle(
    id: string,
    cachingOptions?: CachingOptions & InitCacheOptions,
  ): Promise<TData> {
    this.checkCachingOptions(cachingOptions);

    // no caching
    if (cachingOptions?.skipCache || !this.supportsCaching) {
      return this.service.getSingle(id);
    }

    // with caching
    if (cachingOptions?.forceReload || !this._cache.isValid) {
      // initializing with a single object
      if (cachingOptions?.initBehavior === 'single') {
        this._lastFilter = undefined;
        const single = await this.service.getSingle(id);
        this.initCache([single], cachingOptions?.ttl);
      }
      // initializing with list
      else {
        this._lastFilter = cloneDeep(cachingOptions?.initFilter);
        const list = await this.service.getList(cachingOptions?.initFilter);
        this.initCache(list, cachingOptions?.ttl);
      }
    }

    if (this._cache instanceof ObjectArrayCache) {
      const cache = this._cache as ObjectArrayCache<TData>;
      return cache.find(i => i.id === id);
    } else if (this._cache instanceof ObjectMapCache) {
      const cache = this._cache as ObjectMapCache<string, TData>;
      return cache.get(id);
    }

    // should not happen
    return Promise.reject('Unsupported cache type.');
  }

  /**
   * Gets count of entities meeting filter criteria.
   * @param {Filter} filter - Loopback filter
   */
  public getCount(filter?: Filter): Promise<number> {
    return this.service.getCount(filter);
  }

  /**
   * Determines whether an entity exists meeting filter criteria.
   * @param {Filter} filter - Loopback filter
   */
  public exists(filter?: Filter): Promise<boolean> {
    return this.service.exists(filter);
  }
}

/**
 * Base class for CRUD data service.
 */
export abstract class BaseCrudDataService<
  TData extends {id: string},
  TDataService extends ICrudDataService<TData>,
  TCache extends ICache = ICache,
> extends BaseReadonlyDataService<TData, TDataService, TCache> {
  /**
   * Constructor.
   * @param {TDataService} onlineService - online service instance
   * @param {TDataService} offlineService - offline service instance (optional)
   * @param {TCache} cache - cache instance (optional)
   */
  constructor(
    onlineService: TDataService,
    offlineService?: TDataService,
    cache?: TCache,
  ) {
    super(onlineService, offlineService, cache);
  }

  /**
   * Creates new entity.
   * @param {TData} objectData - entity data
   * @returns {Promise<TData>} created entity
   */
  public async create(objectData: TData): Promise<TData> {
    // create object using the underlying data service
    const createdObject = await this.service.create(objectData);

    // update the object in the cache
    if (this.supportsCaching) {
      if (this._cache instanceof ObjectArrayCache) {
        const cache = this._cache as ObjectArrayCache<TData>;
        if (cache.isValid) {
          cache.push(createdObject);
        } else {
          console.warn(
            'Initializing cache after create operation with a single object.',
          );
          cache.init([createdObject], this._defaultTtl);
        }
      } else if (this._cache instanceof ObjectMapCache) {
        const cache = this._cache as ObjectMapCache<string, TData>;
        if (cache.isValid) {
          cache.set(createdObject.id, createdObject);
        } else {
          console.warn(
            'Initializing cache after create operation with a single object.',
          );
          cache.init([[createdObject.id, createdObject]], this._defaultTtl);
        }
      } else if (this._cache) {
        console.warn('Unsupported cache type.');
      }
    }

    return createdObject;
  }

  /**
   * Updates existing entity.
   * @param {string} id - id of the entity to update
   * @param {Partial<TData>} objectData - entity data
   * @returns {Promise<TData>} updated entity
   */
  public async update(id: string, objectData: Partial<TData>): Promise<TData> {
    // update object using the underlying data service
    const updatedObject = await this.service.update(id, objectData);

    // update the object in the cache
    if (this.supportsCaching) {
      if (this._cache instanceof ObjectArrayCache) {
        const cache = this._cache as ObjectArrayCache<TData>;
        if (cache.isValid) {
          const idx = cache.findIndex(i => i.id === updatedObject.id);
          if (idx >= 0) {
            cache.setAt(idx, updatedObject);
          } else {
            console.warn(
              'Updated object not found in the cache.',
              updatedObject,
            );
          }
        } else {
          console.warn(
            'Initializing cache after update operation with a single object.',
          );
          cache.init([updatedObject], this._defaultTtl);
        }
      } else if (this._cache instanceof ObjectMapCache) {
        const cache = this._cache as ObjectMapCache<string, TData>;
        if (cache.isValid) {
          if (cache.has(updatedObject.id)) {
            cache.set(updatedObject.id, updatedObject);
          } else {
            console.warn(
              'Updated object not found in the cache.',
              updatedObject,
            );
          }
        } else {
          console.warn(
            'Initializing cache after update operation with a single object.',
          );
          cache.init([[updatedObject.id, updatedObject]], this._defaultTtl);
        }
      } else if (this._cache) {
        console.warn('Unsupported cache type.');
      }
    }

    return updatedObject;
  }

  /**
   * Deletes existing entity.
   * @param {string} id - id of the entity to delete
   * @param {number} version - entity version (if applicable)
   */
  public async delete(id: string, version?: number): Promise<void> {
    await this.service.delete(id, version);

    // delete the object in the cache
    if (this.supportsCaching) {
      if (this._cache instanceof ObjectArrayCache) {
        const cache = this._cache as ObjectArrayCache<TData>;
        if (cache.isValid) {
          const idx = cache.findIndex(i => (i as any).id === id);
          if (idx >= 0) {
            cache.deleteAt(idx);
          } else {
            console.warn(`Object with id '${id}' not found in the cache.`);
          }
        } else {
          console.warn(
            'Delete operation performed but cache is not initialized.',
          );
        }
      } else if (this._cache instanceof ObjectMapCache) {
        const cache = this._cache as ObjectMapCache<string, TData>;
        if (cache.isValid) {
          if (cache.has(id)) {
            cache.delete(id);
          } else {
            console.warn(`Object with id '${id}' not found in the cache.`);
          }
        } else {
          console.warn(
            'Delete operation performed but cache is not initialized.',
          );
        }
      } else if (this._cache) {
        console.warn('Unsupported cache type.');
      }
    }
  }
}
