import {Injectable} from '@angular/core';
import {NotificationTemplate} from '../../models';
import {INotificationTemplateDataService} from '../../interfaces/data-service';
import {BaseCrudOfflineService} from './base.offline-service';

@Injectable()
export class NotificationTemplateOfflineService
  extends BaseCrudOfflineService<NotificationTemplate>
  implements INotificationTemplateDataService
{
  public getPlaceholders(): Promise<string[]> {
    return Promise.reject('Offline service - not yet implemented');
  }
}
