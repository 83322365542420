import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';

@Injectable()
export class StorageAccountHttpService {
  constructor(private httpClient: HttpClient) {}

  /**
   * Get binary data of photo object
   */
  public async downloadPhotoObject(photoId: string): Promise<string> {
    const response = await this.httpClient
      .get(`${this.getPhotoObjectBase(photoId)}`, {responseType: 'arraybuffer'})
      .toPromise();

    return this.readFile(response);
  }

  /**
   * Generate photo object url based on photo id
   */
  public getPhotoObjectUrl(photoId: string): string {
    return `${this.getPhotoObjectBase(photoId)}.jpg?t=${new Date().getTime()}}`;
  }

  /**
   * Get binary data of photo thumbnail
   */
  public async downloadPhotoObjectThumbnail(photoId: string): Promise<string> {
    const response = await this.httpClient
      .get(`${this.getPhotoObjectThumbnailBase(photoId)}`, {
        responseType: 'arraybuffer',
      })
      .toPromise();

    return this.readFile(response);
  }

  /**
   * Generate photo thumbnail url based on photo id
   */
  public getPhotoObjectThumbnailUrl(photoId: string): string {
    return `${this.getPhotoObjectThumbnailBase(
      photoId,
    )}.jpg?t=${new Date().getTime()}}`;
  }

  /**
   * Generate url for chain picture based on chain id
   */
  public getChainPictureUrl(chainId: string): string {
    return `${
      environment.chainPictureBaseUrl
    }/chain-pictures/${chainId}.jpg?t=${new Date().getTime()}}`;
  }

  /**
   * Generate url for customer picture based on customer id
   */
  public getCustomerPictureUrl(customerId: string): string {
    return `${
      environment.customerPictureBaseUrl
    }/customer-pictures/${customerId}.jpg?t=${new Date().getTime()}}`;
  }

  /**
   * Generate url for product picture based on product id
   */
  public getProductPictureUrl(productId: string): string {
    return `${
      environment.productPictureBaseUrl
    }/product-pictures/${productId}.jpg?t=${new Date().getTime()}}`;
  }

  /**
   * Generate url for profile picture based on profile id
   */
  public getProfilePictureUrl(pictureId: string): string {
    return `${
      environment.profilePictureBaseUrl
    }/profile-pictures/${pictureId}.jpg?t=${new Date().getTime()}}`;
  }

  /**
   * Private helper function to get photo object base url (without extension)
   */
  private getPhotoObjectBase(photoId: string): string {
    return `${environment.photoObjectBaseUrl}/photo-object/${photoId}`;
  }

  /**
   * Private helper function to get photo thumbnail base url (without extension)
   */
  private getPhotoObjectThumbnailBase(photoId: string): string {
    return `${environment.photoObjectBaseUrl}/photo-object-thumbnail/${photoId}`;
  }

  /**
   * Extracts image response and return content as string
   */
  private readFile(response: ArrayBuffer): string {
    return Array.from(new Uint8Array(response), byte =>
      String.fromCharCode(byte),
    ).join('');
  }
}
