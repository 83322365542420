import {CommonModule} from '@angular/common';
import {DialogModule} from '../../modules/dialog';
import {DynamicFormModule} from '../../modules/dynamic-form';
import {FormModule} from '../../modules/form';
import {LoadingContainerModule} from '@salesapp/shared/components/loading-container';
import {NgModule} from '@angular/core';
import {ObjectDetailModule} from '@salesapp/shared/modules';
import {ProductCreateDialogComponent} from './components/product-create-dialog/product-create-dialog.component';
import {ProductDetailDialogComponent} from './components/product-detail-dialog/product-detail-dialog.component';
import {ProductFormComponent} from './components/product-form/product-form.component';
import {TranslateModule} from '@ngx-translate/core';
import {ProductService} from './services/product.service';

const SALESAPP_MODULES = [
  ObjectDetailModule,
  DynamicFormModule,
  DialogModule,
  FormModule,
];
const UI_COMPONENTS = [LoadingContainerModule, ObjectDetailModule];

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    ...SALESAPP_MODULES,
    ...UI_COMPONENTS,
  ],
  declarations: [
    ProductDetailDialogComponent,
    ProductCreateDialogComponent,
    ProductFormComponent,
  ],
  providers: [ProductService],
  exports: [
    ProductDetailDialogComponent,
    ProductCreateDialogComponent,
    ProductFormComponent,
  ],
})
export class ProductModule {}

export * from './components/product-create-dialog/product-create-dialog.component';
export * from './components/product-detail-dialog/product-detail-dialog.component';
export * from './components/product-form/product-form.component';
