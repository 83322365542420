import {CdkDragDrop, CdkDragExit} from '@angular/cdk/drag-drop';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ObjectTypeIds} from '@retrixhouse/salesapp-shared/lib/common';
import {
  IObjectForm,
  IObjectFormGroup,
  IObjectFormInputControl,
  IObjectFormSchema,
  IObjectProperty,
  ObjectFormInputControlTypeEnum,
  ValueType,
} from '@retrixhouse/salesapp-shared/lib/models';
import {DialogService} from '@salesapp/dialog';
import {sortByKey} from '@salesapp/shared/globals';
import {
  AutoUnsubscribe,
  TSimpleChanges,
  trackByIndex,
} from '@salesapp/utils/angular.utils';
import {getTranslationMarkersForObjectProperty} from '@salesapp/utils/translation.utils';
import {paramCase} from 'change-case';
import {BehaviorSubject, Observable, Subscription, combineLatest} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {
  FormControlOption,
  FormLayoutService,
  FormSettings,
  GroupLayout,
  RowLayout,
} from '../../services/form-layout.service';
import {
  FormDesignerFormSettingDialogComponent,
  FormDesignerFormSettingDialogData,
} from '../form-designer-form-setting-dialog/form-designer-form-setting-dialog.component';
import {
  FormDesignerUpdateControlDialogComponent,
  FormDesignerUpdateControlDialogData,
} from '../form-designer-update-control-dialog/form-designer-update-control-dialog.component';
import {FormDesignerUpdateGroupDialogComponent} from '../form-designer-update-group-dialog/form-designer-update-group-dialog.component';

@Component({
  selector: 'app-form-designer',
  templateUrl: './form-designer.component.html',
  styleUrls: ['./form-designer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@AutoUnsubscribe()
export class FormDesignerComponent implements OnInit {
  @Input() objectForm: IObjectForm;
  @Input() readonlyMode?: boolean;

  @Output() formChanged = new EventEmitter<IObjectForm>();
  @Output() validationStateChanged = new EventEmitter<'valid' | 'invalid'>();

  tabs: {id: string; text: string}[];
  selectedTab: 'layout' | 'formControls' | 'prefilledFormControls' = 'layout';

  rowLayouts: RowLayout[] = ROW_LAYOUTS;
  groupLayouts: GroupLayout[] = GROUP_LAYOUTS;
  formControls: FormControlOption[] = [];

  formSettings: Pick<IObjectForm, 'name' | 'positionIds'>;

  draggedItem = null;

  prefilledFormControls$ = new BehaviorSubject<FormControlOption[]>(null);
  prefilledSystemRequiredFormControls$ = new BehaviorSubject<
    FormControlOption[]
  >(null);
  rowColumnConnectedListsIds$ = new BehaviorSubject<string[]>([]);
  columnConnectedListsIds$ = new BehaviorSubject<string[]>([]);
  rowColumnGroupFormControlColumnIds$ = new BehaviorSubject<string[]>([]);
  controlColumnConnectedListIds$ = new BehaviorSubject<string[]>([]);

  prefilledSearchValue$ = new BehaviorSubject<string>(null);

  availableProperties$ = new BehaviorSubject<IObjectProperty[]>([]);
  propertyFormControls$ = new BehaviorSubject<IObjectFormInputControl[]>([]);

  invalidGroupIds$ = new BehaviorSubject<string[]>([]);
  invalidControlColumnIds$ = new BehaviorSubject<string[]>([]);
  invalidRowColumnIds$ = new BehaviorSubject<string[]>([]);
  invalidFormInputControlIds$ = new BehaviorSubject<string[]>([]);
  availableSystemRequiredProperties$ = new BehaviorSubject<IObjectProperty[]>(
    [],
  );

  validationState$: Observable<any>;

  private propertiesById: Map<string, IObjectProperty>;
  private objectForm$ = new BehaviorSubject<IObjectForm>(null);

  trackByFn = trackByIndex;

  Array = Array;

  private layoutChangedSubscription: Subscription;
  private prefilledFormControlsSubscription: Subscription;
  private formValidationSubscription: Subscription;

  get formSchema() {
    return this.objectForm.formSchema;
  }

  get layout$() {
    return this.formLayoutService.layout$;
  }

  constructor(
    private translateService: TranslateService,
    private dialogService: DialogService,
    private formLayoutService: FormLayoutService,
  ) {}

  ngOnChanges(changes: TSimpleChanges<FormDesignerComponent>): void {
    if (
      changes.objectForm &&
      changes.objectForm.previousValue !== changes.objectForm.currentValue
    ) {
      this.objectForm$.next(changes.objectForm.currentValue);
    }
  }

  ngOnInit() {
    this.initTabs();
    this.initLayout();
    this.setFormControlOptions();
    this.initPrefilledFormControls();
  }

  ngOnDestroy() {
    this.formLayoutService.destroy();
  }

  onTabClick(event) {
    this.selectedTab = event.itemData.id;
  }

  onSearchPrefilledFormControlsChange(event) {
    this.prefilledSearchValue$.next(event);
  }

  onEditFormSettings() {
    this.dialogService
      .open<
        FormDesignerFormSettingDialogComponent,
        FormDesignerFormSettingDialogData,
        FormSettings
      >(FormDesignerFormSettingDialogComponent, {
        width: '700px',
        height: '500px',
        data: {
          formSettings: this.objectForm,
        },
      })
      .closed.subscribe({
        next: response => {
          if (response) {
            this.formChanged.emit({
              ...this.objectForm,
              ...response,
              formSchema: this.formLayoutService.layout,
            });
          }
        },
      });
  }

  onDragListEntered() {
    this.draggedItem = null;
  }

  onDragListExited(event: CdkDragExit<any>) {
    this.draggedItem = event.item.data;
  }

  onDeleteRow(rowId: string) {
    this.dialogService
      .danger({
        acceptLabel: this.translateService.instant('buttons.delete'),
        rejectLabel: this.translateService.instant('buttons.cancel'),
        showCloseButton: false,
        title: this.translateService.instant(
          'form-designer.confirm-row-deletion-title',
        ),
        description: this.translateService.instant(
          'form-designer.confirm-row-deletion-description',
        ),
      })
      .closed.subscribe({
        next: response => {
          if (response === 'accepted') {
            this.formLayoutService.deleteRow(rowId);
          }
        },
      });
  }

  onEditGroup(group: IObjectFormGroup) {
    this.dialogService.open(FormDesignerUpdateGroupDialogComponent, {
      width: '700px',
      height: '530px',
      data: {group},
    });
  }

  onDeleteGroup(groupId: string) {
    this.dialogService
      .danger({
        acceptLabel: this.translateService.instant('buttons.delete'),
        rejectLabel: this.translateService.instant('buttons.cancel'),
        showCloseButton: false,
        title: this.translateService.instant(
          'form-designer.confirm-group-deletion-title',
        ),
        description: this.translateService.instant(
          'form-designer.confirm-group-deletion-description',
        ),
      })
      .closed.subscribe({
        next: response => {
          if (response === 'accepted') {
            this.formLayoutService.deleteGroup(groupId);
          }
        },
      });
  }

  onEditFormControl(formControl: IObjectFormInputControl) {
    this.dialogService.open<
      FormDesignerUpdateControlDialogComponent,
      FormDesignerUpdateControlDialogData
    >(FormDesignerUpdateControlDialogComponent, {
      width: '700px',
      height: '90%',
      data: {formControl},
    });
  }

  onDeleteFormControl(controlId: string) {
    this.dialogService
      .danger({
        acceptLabel: this.translateService.instant('buttons.delete'),
        rejectLabel: this.translateService.instant('buttons.cancel'),
        showCloseButton: false,
        title: this.translateService.instant(
          'form-designer.confirm-form-control-deletion-title',
        ),
        description: this.translateService.instant(
          'form-designer.confirm-form-control-deletion-description',
        ),
      })
      .closed.subscribe({
        next: response => {
          if (response === 'accepted') {
            this.formLayoutService.deleteFormControl(controlId);
          }
        },
      });
  }

  noReturnPredicate() {
    return false;
  }

  onFormLayoutDrop(
    event: CdkDragDrop<IObjectFormSchema, IObjectFormSchema, RowLayout>,
  ) {
    if (event.previousContainer.id === event.container.id) {
      this.formLayoutService.reorderRows(event);
    } else {
      this.formLayoutService.addRow({
        data: event.item.data,
        addToIndex: event.currentIndex,
      });
      this.draggedItem = null;
    }
  }

  onFormRowColumnDrop(event: CdkDragDrop<any, any, any>) {
    if (event.previousContainer.id === 'group') {
      this.formLayoutService.addGroupToRowColumn({
        group: event.item.data,
        columnId: event.container.id,
      });
      this.draggedItem = null;
    } else {
      this.formLayoutService.moveGroup(event);
    }
  }

  onFormRowColumnFormControlColumnDrop(event: CdkDragDrop<any, any, any>) {
    if (event.previousContainer.id === 'form-controls') {
      this.formLayoutService.addFormControlToGroupColumn({
        controlColumnId: event.container.data.id,
        addToIndex: event.currentIndex,
        formControl: event.item.data,
      });
      this.draggedItem = null;
    } else {
      this.formLayoutService.moveFormControl({
        previousControlColumnId: event.previousContainer.data.id,
        currentControlColumnId: event.container.data.id,
        previousIndex: event.previousIndex,
        currentIndex: event.currentIndex,
        formControl: event.item.data,
      });
    }
  }

  isPropertySystemReadOnly(propertyId: string) {
    if (propertyId) {
      const property = this.formLayoutService.allPropertiesById.get(propertyId);
      return property?.readonly;
    }
  }

  private initTabs() {
    this.tabs = [
      {
        id: 'layout',
        text: this.translateService.instant('tabs.layout'),
      },
      {
        id: 'prefilledFormControls',
        text: this.translateService.instant('tabs.prefilled-form-controls'),
      },
      {
        id: 'formControls',
        text: this.translateService.instant('tabs.form-controls'),
      },
    ];
  }

  private initLayout() {
    this.formLayoutService.init(this.formSchema, this.objectForm.objectTypeId);
    this.layoutChangedSubscription = this.formLayoutService.layout$.subscribe({
      next: layout => {
        const allRowColumnIds = [];
        const controlColumnIds = [];

        layout?.rows.forEach(row => {
          row.columns.forEach(column => {
            allRowColumnIds.push(`row-column-${column.id}`);
            column.group?.controlColumns.forEach(controlColumn => {
              controlColumnIds.push(
                `row-column-group-form-control-column-${controlColumn.id}`,
              );
            });
          });
        });

        this.rowColumnConnectedListsIds$.next(allRowColumnIds);
        this.columnConnectedListsIds$.next([...allRowColumnIds, 'group']);
        this.rowColumnGroupFormControlColumnIds$.next(controlColumnIds);
        this.controlColumnConnectedListIds$.next([
          ...controlColumnIds,
          'form-controls',
        ]);
        this.formChanged.emit({
          ...this.objectForm,
          formSchema: this.formLayoutService.layout,
        });
      },
    });
    this.formValidationSubscription = this.formLayoutService
      .formValidationState()
      .subscribe({
        next: state => {
          this.invalidGroupIds$.next(state.invalidGroupIds);
          this.invalidControlColumnIds$.next(state.invalidControlColumnIds);
          this.invalidRowColumnIds$.next(state.invalidRowColumnIds);
          this.invalidFormInputControlIds$.next(
            state.invalidFormInputControlIds,
          );
          this.availableSystemRequiredProperties$.next(
            state.availableSystemRequiredProperties,
          );
        },
      });

    this.validationState$ = combineLatest([
      this.invalidGroupIds$,
      this.invalidControlColumnIds$,
      this.invalidRowColumnIds$,
      this.invalidFormInputControlIds$,
      this.availableSystemRequiredProperties$,
      this.objectForm$,
    ]).pipe(
      map(
        ([
          invalidGroupIds,
          invalidControlColumnIds,
          invalidRowColumnIds,
          invalidFormInputControlIds,
          availableSystemRequiredProperties,
          objectForm,
        ]) => {
          return invalidGroupIds.length ||
            invalidControlColumnIds.length ||
            invalidRowColumnIds.length ||
            invalidFormInputControlIds.length ||
            availableSystemRequiredProperties.length ||
            (objectForm.default
              ? false
              : !objectForm.positionIds || !objectForm.positionIds?.length)
            ? 'invalid'
            : 'valid';
        },
      ),
      tap(state => this.validationStateChanged.emit(state)),
    );
  }

  private setFormControlOptions() {
    const options: FormControlOption[] = Object.values(
      ObjectFormInputControlTypeEnum,
    )
      .filter(
        controlType => controlType !== ObjectFormInputControlTypeEnum.DateRange,
      )
      .map(value => ({
        icon: FormControlsIconMap[value],
        type: value,
        name: this.translateService.instant(
          `object-form-input-control-type-enum.${paramCase(value)}`,
        ),
        systemRequired: false,
      }));
    this.formControls = options;
  }

  private getDefaultFormControlForProperty(property: IObjectProperty) {
    if (
      [
        ValueType.RelationToManyForeignKeys,
        ValueType.RelationToOneForeignKey,
      ].includes(property.valueType)
    ) {
      return this.getDefaultFormControlForPropertyTypeRelation(property);
    }

    return VALUE_TYPE_TO_CONTROL_TYPE[property.valueType];
  }

  private getDefaultFormControlForPropertyTypeRelation(
    property: IObjectProperty,
  ) {
    if (property.valueType === ValueType.RelationToOneForeignKey) {
      switch (property.objectTypeId) {
        case ObjectTypeIds.Address:
          return ObjectFormInputControlTypeEnum.Address;
        default:
          return ObjectFormInputControlTypeEnum.Select;
      }
    }

    if (property.valueType === ValueType.RelationToManyForeignKeys) {
      return ObjectFormInputControlTypeEnum.MultiSelect;
    }
  }

  private initPrefilledFormControls() {
    this.prefilledFormControlsSubscription = combineLatest([
      this.formLayoutService.availableProperties$,
      this.prefilledSearchValue$,
    ])
      .pipe(
        // tap(([properties, searchValue]) => {
        //   this.propertiesById = arrayToMap(properties, 'id');
        // }),
        map(([properties, searchValue]) => {
          const options = properties
            .filter(
              property =>
                ![
                  ValueType.RelationToOneData,
                  ValueType.RelationToManyData,
                ].includes(property.valueType),
            )
            .map(property => {
              const controlType =
                this.getDefaultFormControlForProperty(property);

              const option: FormControlOption = {
                icon: FormControlsIconMap[controlType],
                type: controlType,
                name: this.translateService.instant(
                  getTranslationMarkersForObjectProperty({
                    objectTypeId: this.objectForm.objectTypeId,
                    propertyName: property.name,
                  }).label,
                ),
                propertyId: property.id,
                systemRequired: property.systemRequired,
                readonly: property.readonly,
              };
              return option;
            });
          let allOptions: FormControlOption[] = [];

          if (searchValue) {
            allOptions = sortByKey(
              options.filter(option =>
                option.name.toLowerCase().includes(searchValue.toLowerCase()),
              ),
              'name',
            );
          } else {
            allOptions = sortByKey(options, 'name');
          }
          this.prefilledFormControls$.next(
            allOptions.filter(
              option =>
                !option.systemRequired ||
                (option.systemRequired && option.readonly),
            ),
          );
          this.prefilledSystemRequiredFormControls$.next(
            allOptions.filter(
              option => option.systemRequired && !option.readonly,
            ),
          );
        }),
      )
      .subscribe();
  }
}

const ROW_LAYOUTS = [
  {
    numberOfColumns: 1,
  },
  {
    numberOfColumns: 2,
  },
  {
    numberOfColumns: 3,
  },
  {
    numberOfColumns: 4,
  },
];

const GROUP_LAYOUTS: GroupLayout[] = [
  {
    numberOfColumns: 1,
  },
  {
    numberOfColumns: 2,
  },
  {
    numberOfColumns: 3,
  },
  {
    numberOfColumns: 4,
  },
];

const FormControlsIconMap: {[k in ObjectFormInputControlTypeEnum]: string} = {
  [ObjectFormInputControlTypeEnum.Address]: 'fa-regular fa-address-book',
  [ObjectFormInputControlTypeEnum.Avatar]: 'fa-regular fa-camera',
  [ObjectFormInputControlTypeEnum.Date]: 'fa-light fa-calendar-days',
  [ObjectFormInputControlTypeEnum.Datetime]: 'fa-light fa-calendar-clock',
  [ObjectFormInputControlTypeEnum.DateRange]: 'fa-regular fa-calendar-range',
  [ObjectFormInputControlTypeEnum.GoogleLocationId]:
    'fa-regular fa-location-dot',
  [ObjectFormInputControlTypeEnum.Location]: 'fa-regular fa-map-location-dot',
  [ObjectFormInputControlTypeEnum.MultiSelect]: 'fa-regular fa-list-check',
  [ObjectFormInputControlTypeEnum.Number]: 'fa-solid fa-1',
  [ObjectFormInputControlTypeEnum.OpeningHours]: 'far fa-business-time',
  [ObjectFormInputControlTypeEnum.RichText]: 'fa-regular fa-underline',
  [ObjectFormInputControlTypeEnum.Select]: 'fa-light fa-ballot-check',
  [ObjectFormInputControlTypeEnum.Switch]: 'fa-regular fa-toggle-on',
  [ObjectFormInputControlTypeEnum.Tags]: 'fa-regular fa-tag',
  [ObjectFormInputControlTypeEnum.Text]: 'fa-regular fa-text',
};

const VALUE_TYPE_TO_CONTROL_TYPE: {
  [k in ValueType]?: ObjectFormInputControlTypeEnum;
} = {
  // []: [ObjectFormInputControlTypeEnum.Address],
  [ValueType.Date]: ObjectFormInputControlTypeEnum.Date,
  [ValueType.DateTime]: ObjectFormInputControlTypeEnum.Datetime,
  [ValueType.Duration]: ObjectFormInputControlTypeEnum.DateRange,
  // []: ObjectFormInputControlTypeEnum.Map,
  [ValueType.MultiChoice]: ObjectFormInputControlTypeEnum.MultiSelect,
  [ValueType.Integer]: ObjectFormInputControlTypeEnum.Number,
  [ValueType.Float]: ObjectFormInputControlTypeEnum.Number,
  [ValueType.Enum]: ObjectFormInputControlTypeEnum.Select,
  // []: ObjectFormInputControlTypeEnum.OpeningHours,
  [ValueType.PictureUrl]: ObjectFormInputControlTypeEnum.Avatar,
  [ValueType.RichText]: ObjectFormInputControlTypeEnum.RichText,
  [ValueType.SingleChoice]: ObjectFormInputControlTypeEnum.Select,
  [ValueType.Boolean]: ObjectFormInputControlTypeEnum.Switch,
  [ValueType.PlainText]: ObjectFormInputControlTypeEnum.Text,
  [ValueType.PlainTextList]: ObjectFormInputControlTypeEnum.Tags,
};
