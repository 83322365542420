import {
  IObjectProperty,
  ValueType,
} from '@retrixhouse/salesapp-shared/lib/models';
import {GenericList} from './generic-list.model';
export class ObjectProperty implements IObjectProperty {
  navigationalProperty?: string;
  objectTypeId?: string;
  systemRequired: boolean;
  readonly: boolean;
  typeId: string;
  scopeId?: string;
  name: string;
  description?: string;
  valueType: ValueType;
  native: boolean;
  position: number;
  group: string;
  colorForeground?: string;
  colorBackground?: string;
  requiredExpression?: string;
  validationExpression?: string;
  visibilityExpression?: string;
  listId?: string;
  list?: GenericList;
  specificInContext?: any;
  id: string;
}

export enum ObjectPropertyValueType {
  Integer = 'Integer',
  Float = 'Float',
  String = 'String',
  Boolean = 'Boolean',
  Date = 'Date',
  Time = 'Time',
  DateTime = 'DateTime',
  Duration = 'Duration',
  SingleChoice = 'SingleChoice',
  MultiChoice = 'MultiChoice',
  FileUpload = 'FileUpload',
}
