import {TranslateService} from '@ngx-translate/core';
import {FeedbackKpiInputDisplayOptions} from '@retrixhouse/salesapp-shared/lib/common';

export class FeedbackUtils {
  /**
   * The function `fromatKpiDisplayValue` takes in a formatting style and a value, and returns a
   * formatted display value based on the formatting style.
   * @param props - The `props` parameter is an object that contains two properties:
   * @returns a string value based on the input formattingStyle and value. If the formattingStyle is
   * "Percentage", it returns the value as is. If the formattingStyle is "PositiveNegative", it checks
   * the value and returns correct translated value when TranslateService is provided.
   */
  public static fromatKpiValueDisplayValue(props: {
    formattingStyle: FeedbackKpiInputDisplayOptions;
    value: number | null;
    translateService?: TranslateService;
  }) {
    const {formattingStyle, value, translateService} = props;

    if (formattingStyle === FeedbackKpiInputDisplayOptions.Precentage) {
      return value;
    }

    if (formattingStyle === FeedbackKpiInputDisplayOptions.PositiveNegative) {
      let formattedValue: string;
      if (value === 0) {
        formattedValue = 'negative';
      }

      if (value === 50) {
        formattedValue = 'neutral';
      }

      if (value === 100) {
        formattedValue = 'positive';
      }

      if (translateService) {
        return translateService.instant(
          `feedback.kpi-value.value.${formattedValue}`,
        );
      }

      return formattedValue;
    }
  }
}
