import {DIALOG_DATA, DialogRef} from '@angular/cdk/dialog';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {IObjectFormGroup} from '@retrixhouse/salesapp-shared/lib/models';
import {CustomValidators} from '@salesapp/utils/reactive-form/form-validators';
import {FormControlsOf} from '@salesapp/utils/reactive-form/reactive-form.interface';
import {FormLayoutService} from '../../services/form-layout.service';

@Component({
  selector: 'app-form-designer-update-group-dialog',
  templateUrl: './form-designer-update-group-dialog.component.html',
  styleUrls: ['./form-designer-update-group-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormDesignerUpdateGroupDialogComponent implements OnInit {
  form: FormGroup<ObjectFormGroupFormGroup>;

  constructor(
    private dialogRef: DialogRef<void>,
    @Inject(DIALOG_DATA) private data: FormDesignerUpdateGroupDialogData,
    private formBuilder: FormBuilder,
    private formLayoutService: FormLayoutService,
  ) {}

  ngOnInit() {
    this.initForm();
  }

  onClose() {
    this.dialogRef.close();
  }

  onSave() {
    this.formLayoutService.updateGroup(this.form.getRawValue());
    this.dialogRef.close();
  }

  getFormControl(name: keyof ObjectFormGroup) {
    return this.form.get(name) as FormControl;
  }

  private initForm() {
    const form = this.formBuilder.group<ObjectFormGroupFormGroup>({
      id: this.formBuilder.control(null, [CustomValidators.required]),
      title: this.formBuilder.control(null, [CustomValidators.required]),
      visibilityExpression: this.formBuilder.control(null),
    });

    if (this.data?.group) {
      form.patchValue(this.data.group);
    }

    this.form = form;
  }
}

export interface FormDesignerUpdateGroupDialogData {
  group: IObjectFormGroup;
}

type ObjectFormGroup = Omit<IObjectFormGroup, 'controlColumns'>;

type ObjectFormGroupFormGroup = FormControlsOf<ObjectFormGroup>;
