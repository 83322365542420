import {Injectable} from '@angular/core';
import {Filter} from '@loopback/filter';
import {
  Locale,
  LocalizedIdentifier,
  LocalizedValue,
} from 'src/app/shared/models';
import {II18nDataService} from '../../interfaces/data-service';
import {BaseCrudOfflineService} from './base.offline-service';

@Injectable()
export class I18NOfflineService
  extends BaseCrudOfflineService<LocalizedValue>
  implements II18nDataService
{
  public getLocales(): Promise<Locale[]> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public getEnabledLocales(): Promise<Locale[]> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public getIdentifiers(
    filter?: Filter<LocalizedIdentifier>,
  ): Promise<LocalizedIdentifier[]> {
    return Promise.reject('Offline service - not yet implemented');
  }
}
