import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';

@Pipe({name: 'dateFormat', pure: false})
export class LocalizedDatetimePipe implements PipeTransform {
  transform(value: Date | string, format: string): string {
    return formatDateTime(value, format);
  }
}

export function formatDateTime(value: Date | string, format: string): string {
  if (value == null) {
    return null;
  }
  return moment(value).format(format);
}
