<ng-container *ngIf="!(loading$ | async); else loadingTemplate">
  <ng-container *ngIf="isAuthenticated(); else unauthenticated">
    <app-side-nav-inner-toolbar title="{{ appInfoService.title }}">
      <router-outlet></router-outlet>
      <app-footer></app-footer>
    </app-side-nav-inner-toolbar>
  </ng-container>
  <ng-template #unauthenticated>
    <app-unauthenticated-content></app-unauthenticated-content>
  </ng-template>
</ng-container>

<ng-template #loadingTemplate>
  <app-loading-container [loading]="loading$ | async"
                         [showLogo]="true">
  </app-loading-container>
</ng-template>

<dx-load-panel [hideOnOutsideClick]="false"
               [container]="loadPanelConf.target"
               [position]="{of: loadPanelConf.target}"
               [shading]="true"
               [showIndicator]="true"
               [showPane]="true"
               [visible]="loadPanelConf.visible"
               shadingColor="rgba(0,0,0,0.4)"></dx-load-panel>