import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseCrudHttpService} from './base.http-service';
import {Order, OrderReport, ReportRangeRequest} from '../../models';
import {IOrderDataService} from '../../interfaces/data-service';

@Injectable()
export class OrderHttpService
  extends BaseCrudHttpService<Order>
  implements IOrderDataService
{
  constructor(http: HttpClient) {
    super(http, '/order');
  }

  public getReport(rangeRequest: ReportRangeRequest): Promise<OrderReport[]> {
    return this.POST<OrderReport[]>('/order/report', rangeRequest);
  }
}
