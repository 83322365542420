import {Injectable} from '@angular/core';
import {IClientSideErrorConf} from '@retrixhouse/salesapp-shared/lib/models';
import {combineLatest} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {DataProvider} from '../../data.provider/data-provider';
import {CurrentUserStorageService} from './current-user-storage.service';
import {OldBaseStorageService} from './old-base-storage-service';

@Injectable()
export class ClientSideErrorConfStorageService extends OldBaseStorageService<
  IClientSideErrorConf,
  IClientSideErrorConf[]
> {
  currentUserConfig$() {
    return combineLatest([this.data$, this.currentUserStorage.data$]).pipe(
      filter(([confs, currentUsername]) => !!currentUsername && !!confs),
      map(([confs, currentUsername]) =>
        confs.find(conf => conf.userId === currentUsername.id),
      ),
    );
  }

  constructor(
    protected dataProvider: DataProvider,
    private currentUserStorage: CurrentUserStorageService,
  ) {
    super(dataProvider.clientSideErrorConf);
  }
}
