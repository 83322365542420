<div>
  <div fxLayout="row" fxLayoutGap="10px" *ngIf="product" class="product-template">
    <img
      *ngIf="(productSettings$ | async)[settingsEnum.ProductAvatar_ShowPicture]"
      class="product-avatar-image-{{ pictureSize }}"
      [src]="product.productPictureUrl"
      loading="lazy"
    />
    <div class="avatar-body">
      <ng-container *ngIf="productDisplayText$ | async; let displayText">
        <div *ngIf="displayText.line1" class="line1 text-with-dots" [ngClass]="{'line1-bold': (productSettings$ | async)[settingsEnum.ProductAvatar_Line1Bold]}">
          {{ displayText.line1 }}
        </div>
        <div *ngIf="displayText.line2" class="line2 text-with-dots">{{ displayText.line2 }}</div>
      </ng-container>
    </div>
  </div>
</div>
