import {ObjectMapCache} from '@retrixhouse/salesapp-shared/lib/caching';
import {ITagDataService} from '../../interfaces/data-service';
import {Tag} from '../../models';
import {TagHttpService} from '../http';
import {TagOfflineService} from '../offline';
import {BaseCrudDataService} from './base.data-service';

export class TagDataService extends BaseCrudDataService<
  Tag,
  ITagDataService,
  ObjectMapCache<string, Tag>
> {
  constructor(
    onlineService: TagHttpService,
    offlineService: TagOfflineService,
    cache: ObjectMapCache<string, Tag>,
  ) {
    super(onlineService, offlineService, cache);
  }

  public async updateItemPositions(
    positions: {id: string; position: number; parentId: string}[],
  ): Promise<void> {
    await this.service.updateItemPositions(positions);

    // update positions in the cache
    if (!this._cache.isEmpty) {
      positions.forEach(p => {
        const item = this._cache.get(p.id);
        if (item) {
          item.position = p.position;
        }
      });
    }
  }

  public checkIfTagIsUsed(tagId: string): Promise<boolean> {
    return this.service.checkIfTagIsUsed(tagId);
  }

  public getTagsUsage(): Promise<{id: string; used: boolean}[]> {
    return this.service.getTagsUsage();
  }
}
