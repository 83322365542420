import {ObjectMapCache} from '@retrixhouse/salesapp-shared/lib/caching';
import {IObjectDataService} from '../../interfaces/data-service';
import {
  ContextItem,
  ObjectProperty,
  ObjectPropertyValue,
  ObjectType,
  RevisionsResponse,
  SetExtendedPropertiesRequest,
  StateTransitionRecord,
} from '../../models';
import {ObjectHttpService} from '../http';
import {ObjectOfflineService} from '../offline';
import {BaseDataService, CachingOptions} from './base.data-service';
import {validate as uuidValidate} from 'uuid';
import {HttpResponse} from '@angular/common/http';
import {
  IObjectForm,
  IObjectProperty,
} from '@retrixhouse/salesapp-shared/lib/models';

export class ObjectDataService extends BaseDataService<IObjectDataService> {
  protected _cacheObjectTypes: ObjectMapCache<string, ObjectType>;
  protected _cacheObjectProperties: ObjectMapCache<string, ObjectProperty>;
  // protected _cacheObjectForms: ObjectMapCache<string, IObjectForm>;

  constructor(
    onlineService: ObjectHttpService,
    offlineService: ObjectOfflineService,
    cacheObjectTypes: ObjectMapCache<string, ObjectType>,
    cacheObjectProperties: ObjectMapCache<string, ObjectProperty>,
    // cacheObjectForms: ObjectMapCache<string, IObjectForm>,
  ) {
    super(onlineService, offlineService);
    this._cacheObjectTypes = cacheObjectTypes;
    this._cacheObjectProperties = cacheObjectProperties;
    // this._cacheObjectForms = cacheObjectForms;
  }

  private async initCacheObjectTypes(
    cachingOptions?: CachingOptions,
  ): Promise<void> {
    if (cachingOptions?.forceReload || !this._cacheObjectTypes.isValid) {
      const objectTypes = await this.service.getObjectTypes();
      this._cacheObjectTypes.init(objectTypes.map(ot => [ot.id, ot]));
    }
  }

  private async initCacheObjectProperties(
    cachingOptions?: CachingOptions,
  ): Promise<void> {
    if (cachingOptions?.forceReload || !this._cacheObjectProperties.isValid) {
      const objectProperties = await this.service.getObjectProperties('*');
      this._cacheObjectProperties.init(objectProperties.map(op => [op.id, op]));
    }
  }

  // private async initCacheObjectForms(
  //   cachingOptions?: CachingOptions
  // ): Promise<void> {
  //   if (cachingOptions?.forceReload || !this._cacheObjectProperties.isValid) {
  //     const objectForms = await this.service.getObjectForms('*');
  //   }
  // }

  public getNextAvailableUID(typeIdOrName: string): Promise<{uid: string}> {
    return this.service.getNextAvailableUID(typeIdOrName);
  }

  public getRevisions(
    id: string,
    limit?: number,
  ): Promise<RevisionsResponse[]> {
    return this.service.getRevisions(id, limit);
  }

  public getStateTransitions(
    id: string,
    limit?: number,
  ): Promise<StateTransitionRecord[]> {
    return this.service.getStateTransitions(id, limit);
  }

  public async getObjectType(
    id: string,
    cachingOptions?: CachingOptions,
  ): Promise<ObjectType | undefined> {
    if (cachingOptions?.skipCache) {
      return this.service.getObjectType(id);
    }

    await Promise.all([
      this.initCacheObjectTypes(cachingOptions),
      this.initCacheObjectProperties(cachingOptions),
    ]);

    return Promise.resolve(this._cacheObjectTypes.get(id));
  }

  public getContextItems(objectIdOrName: string): Promise<ContextItem[]> {
    return this.service.getContextItems(objectIdOrName);
  }

  public async getObjectTypes(
    cachingOptions?: CachingOptions,
  ): Promise<ObjectType[]> {
    if (cachingOptions?.skipCache) {
      return this.service.getObjectTypes();
    }

    await Promise.all([
      this.initCacheObjectTypes(cachingOptions),
      this.initCacheObjectProperties(cachingOptions),
    ]);

    return Promise.resolve(this._cacheObjectTypes.getValues());
  }

  public async getObjectProperties(
    typeIdOrName: string,
    cachingOptions?: CachingOptions,
  ): Promise<ObjectProperty[]> {
    if (cachingOptions?.skipCache) {
      return this.service.getObjectProperties(typeIdOrName);
    }

    await Promise.all([
      this.initCacheObjectTypes(cachingOptions),
      this.initCacheObjectProperties(cachingOptions),
    ]);

    let objectType: ObjectType | undefined;

    if (uuidValidate(typeIdOrName)) {
      objectType = this._cacheObjectTypes.get(typeIdOrName);
    } else {
      const kv = this._cacheObjectTypes.find(kv => kv[1].name === typeIdOrName);
      objectType = kv?.[1];
    }

    if (objectType) {
      return Promise.resolve(
        this._cacheObjectProperties
          .getValues()
          .filter(op => op.typeId === objectType.id),
      );
    }

    return Promise.resolve([]);
  }

  public getPropertiesValues(objectId: string): Promise<ObjectPropertyValue[]> {
    return this.service.getPropertiesValues(objectId);
  }

  public getAllPropertyValuesForType(
    typeIdOrName: string,
  ): Promise<ObjectPropertyValue[]> {
    return this.service.getAllPropertyValuesForType(typeIdOrName);
  }

  public deletePropertiesValues(objectId: string): Promise<void> {
    return this.service.deletePropertiesValues(objectId);
  }

  public createOrUpdatePropertyValues(
    objectId: string,
    propertyValueList: SetExtendedPropertiesRequest,
  ): Promise<ObjectPropertyValue[]> {
    return this.service.createOrUpdatePropertyValues(
      objectId,
      propertyValueList,
    );
  }

  public downloadPropertyValueFile(
    objectId: string,
    propertyId: string,
  ): Promise<HttpResponse<Blob>> {
    return this.service.downloadPropertyValueFile(objectId, propertyId);
  }

  public async createObjectProperty(
    property: Partial<ObjectProperty>,
  ): Promise<ObjectProperty> {
    const created = await this.service.createObjectProperty(property);
    await Promise.all([
      this.initCacheObjectTypes(),
      this.initCacheObjectProperties(),
    ]);

    if (this._cacheObjectProperties.isValid) {
      this._cacheObjectProperties.set(created.id, created);
    }

    if (this._cacheObjectTypes.isValid) {
      const objectType = this._cacheObjectTypes.get(created.typeId);
      if (objectType) {
        if (Array.isArray(objectType.properties)) {
          objectType.properties.push(created);
        } else {
          objectType.properties = [created];
        }
      }
    }

    return Promise.resolve(created);
  }

  public async updateObjectProperty(
    id: string,
    property: Partial<ObjectProperty>,
  ): Promise<ObjectProperty> {
    const updated = await this.service.updateObjectProperty(id, property);

    await Promise.all([
      this.initCacheObjectTypes(),
      this.initCacheObjectProperties(),
    ]);

    if (this._cacheObjectProperties.isValid) {
      this._cacheObjectProperties.set(updated.id, updated);
    }

    if (this._cacheObjectTypes.isValid) {
      const objectType = this._cacheObjectTypes.get(updated.typeId);
      if (Array.isArray(objectType?.properties)) {
        const idx = objectType.properties.findIndex(p => p.id === updated.id);
        if (idx >= 0) {
          objectType.properties[idx] = updated;
        }
      }
    }

    return Promise.resolve(updated);
  }

  public async deleteObjectProperty(id: string): Promise<void> {
    await this.service.deleteObjectProperty(id);
    await Promise.all([
      this.initCacheObjectTypes({forceReload: true}),
      this.initCacheObjectProperties(),
    ]);

    if (this._cacheObjectProperties.isValid) {
      this._cacheObjectProperties.delete(id);
    }

    if (this._cacheObjectTypes.isValid) {
      const objectType = this._cacheObjectTypes
        .getValues()
        .find(ot => ot.properties?.some(p => p.id === id));

      if (objectType) {
        const idx = objectType.properties.findIndex(p => p.id === id);

        if (idx >= 0) {
          objectType.properties.splice(idx, 1);
        }
      }
    }
  }

  public async createObjectForm(
    form: Partial<IObjectForm>,
  ): Promise<IObjectForm> {
    const created = await this.service.createObjectForm(form);
    await this.initCacheObjectTypes();
    // this.initCacheObjectForms()

    // if (this._cacheObjectForms.isValid) {
    //   this._cacheObjectForms.set(created.id, created);
    // }

    await this.initCacheObjectTypes();

    if (this._cacheObjectTypes.isValid) {
      const objectType = this._cacheObjectTypes.get(created.objectTypeId);

      if (objectType) {
        if (Array.isArray(objectType.forms)) {
          objectType.forms.push(created);
        } else {
          objectType.forms = [created];
        }
      }
    }

    return Promise.resolve(created);
  }

  public async updateObjectForm(
    id: string,
    form: Partial<IObjectForm>,
  ): Promise<IObjectForm> {
    const updated = await this.service.updateObjectForm(id, form);

    await this.initCacheObjectTypes();

    if (this._cacheObjectTypes.isValid) {
      const objectType = this._cacheObjectTypes.get(updated.objectTypeId);
      if (Array.isArray(objectType?.forms)) {
        const idx = objectType.forms.findIndex(p => p.id === updated.id);
        if (idx >= 0) {
          objectType.forms[idx] = updated;
        }
      }
    }

    return Promise.resolve(updated);
  }

  public async deleteObjectForm(id: string): Promise<void> {
    await this.service.deleteObjectForm(id);
    if (this._cacheObjectTypes.isValid) {
      const objectType = this._cacheObjectTypes
        .getValues()
        .find(ot => ot.forms?.some(p => p.id === id));

      if (objectType) {
        const idx = objectType.forms.findIndex(p => p.id === id);

        if (idx >= 0) {
          objectType.forms.splice(idx, 1);
        }
      }
    }
  }
}
