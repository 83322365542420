<app-form-field [label]="label"
                [hint]="hint"
                [formControlRef]="formControlRef">
  <dx-tag-box [value]="value"
              [placeholder]="placeholder"
              [disabled]="disabled"
              [dataSource]="dataSource$ | async"
              [isValid]="isValid"
              [showClearButton]="true"
              [showDropDownButton]="true"
              [showSelectionControls]="true"
              [searchEnabled]="true"
              [tagTemplate]="'tag'"
              searchExpr="name"
              valueExpr="value"
              displayExpr="name"
              (valueChange)="onValueChange($event)">
    <!-- CUSTOM ITEM RENDERING -->
    <ng-container *ngIf="itemType">
      <div *dxTemplate="let data of 'item'">
        <ng-container [ngSwitch]="itemType">
          <ng-container *ngSwitchCase="'username'">
            <app-user-avatar [pictureSize]="'small'"
                             [padding]="'none'"
                             [firstName]="data.data.firstName"
                             [lastName]="data.data.lastName"
                             [picture]="data.data.profilePicture"
                             [position]="data.data.positionAbbreviation"
                             [username]="data.data.username"
                             [userUid]="data.data.uid"></app-user-avatar>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>
    <div *dxTemplate="let item of 'tag'"
         class="value-renderer">
      <div [class.inactive]="item?.inactive"
           class="pill">
        <i *ngIf="item.inactive"
           class="fa-solid fa-square-exclamation"></i>
        <span>
          {{item.name}}
        </span>
        <i class="fa-regular fa-circle-xmark"
           (click)="removeSelectedItem(item)"></i>
      </div>
    </div>
  </dx-tag-box>
</app-form-field>