import {Injectable} from '@angular/core';
import {Project, ProjectSetting, UserKPISets} from '../../models';
import {VisitFrequencyCalculation} from '../../models/visit-frequency-calculation';
import {IProjectDataService} from '../../interfaces/data-service';
import {BaseCrudOfflineService} from './base.offline-service';
import {IProjectUser} from '@retrixhouse/salesapp-shared/lib/models';
import { IProjectCopyRequest } from '@retrixhouse/salesapp-shared/lib/requests';

@Injectable()
export class ProjectOfflineService
  extends BaseCrudOfflineService<Project>
  implements IProjectDataService
{
  getActiveList(): Promise<Project[]> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public getProjectSettings(projectTypeId: string): Promise<ProjectSetting[]> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public getAllProjectSettings(): Promise<ProjectSetting[]> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public getProjectsForCustomer(customerId: string): Promise<Project[]> {
    const filter = customerId ? {where: {customerId}} : {};
    return Promise.reject('Offline service - not yet implemented');
  }

  public getTourPlansForPeriodicLimitCalculation(
    projectStores: {projectId: string; storeId: string}[],
  ): Promise<VisitFrequencyCalculation[]> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public calculateFrequenciesForUser(): Promise<VisitFrequencyCalculation[]> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public getAvailableProjectPermissions(): Promise<string[]> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public getUserProjectPermissions(): Promise<{[projectId: string]: string[]}> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public getProjectsWithRelations(relations?: {}): Promise<Project[]> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public getMyKPISets(projectId: string) {
    return Promise.reject('Offline service - not yet implemented');
  }

  public getProjectUsersKPISets(projectId: string): Promise<UserKPISets[]> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public getProjectUser(projectId: string, userId: string) {
    return Promise.reject('Offline service - not yet implemented');
  }

  public updateProjectUser(projectId: string, userId: string, projectUser: Partial<IProjectUser>) {
    return Promise.reject('Offline service - not yet implemented');
  }

  public copyProject(
    request: IProjectCopyRequest,
  ): Promise<void> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public getCopyProgress(operationId: string) {
    return Promise.reject('Offline service - not yet implemented');
  }

  public getCopyAvailability() {
    return Promise.reject('Offline service - not yet implemented');
  }
}
