import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ObjectPermissionsFormGroupComponent} from './components/object-permissions-form-group/object-permissions-form-group.component';
import {FormModule} from '@salesapp/form';
import {InputMultiSelectModule} from '../input-multiselect';
import {ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';

const SALESAPP_MODULES = [FormModule];
const UI_MODULES = [InputMultiSelectModule];

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    ...SALESAPP_MODULES,
    ...UI_MODULES,
  ],
  declarations: [ObjectPermissionsFormGroupComponent],
  providers: [],
  exports: [ObjectPermissionsFormGroupComponent],
})
export class ObjectPermissionsModule {}

export * from './components/object-permissions-form-group/object-permissions-form-group.component';
