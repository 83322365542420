import {ObjectMapCache} from '@retrixhouse/salesapp-shared/lib/caching';
import {IUserStorageDataService} from '../../interfaces/data-service';
import {UserStorageHttpService} from '../http';
import {UserStorageOfflineService} from '../offline';
import {BaseDataService, CachingOptions, TTL_DAY} from './base.data-service';
import deepEqual from 'deep-equal';

export class UserStorageDataService extends BaseDataService<IUserStorageDataService> {
  private _cacheUserStorage: ObjectMapCache<string, any>;

  constructor(
    onlineService: UserStorageHttpService,
    offlineService: UserStorageOfflineService,
    cacheUserStorage: ObjectMapCache<string, any>,
  ) {
    super(onlineService, offlineService);
    this._cacheUserStorage = cacheUserStorage;
  }

  private async initCache(cachingOptions?: CachingOptions) {
    if (cachingOptions?.forceReload || !this._cacheUserStorage.isValid) {
      const all = await this.service.getAll();
      this._cacheUserStorage.init(
        all.map(i => [i.key, i.value]),
        cachingOptions?.ttl ?? TTL_DAY,
      );
    }
  }

  public async getAll(
    cachingOptions?: CachingOptions,
  ): Promise<{key: string; value: any}[]> {
    if (cachingOptions?.skipCache) {
      return this.service.getAll();
    }

    await this.initCache(cachingOptions);

    // transform it back
    const result: {key: string; value: any}[] = [];
    this._cacheUserStorage.getKeys().forEach(key => {
      result.push({key: key, value: this._cacheUserStorage.get(key)});
    });

    return result;
  }

  public async get<T>(
    key: string,
    cachingOptions?: CachingOptions,
  ): Promise<T> {
    if (cachingOptions?.skipCache) {
      return this.service.getByKey<T>(key);
    }

    await this.initCache(cachingOptions);
    return this._cacheUserStorage.get(key);
  }

  public async set<T>(key: string, data: T): Promise<void> {
    if (this._cacheUserStorage.isValid) {
      const storedData = this._cacheUserStorage.get(key);

      // don't do anything if the same value is being stored
      // if (deepEqual(storedData, data)) {
      //   return;
      // }

      this._cacheUserStorage.set(key, data);
    }

    await this.service.set<T>(key, data);
  }

  public async delete(keys: string | string[]): Promise<void> {
    if (Array.isArray(keys)) {
      await this.service.deleteByKeys(keys);
    } else {
      await this.service.deleteByKey(keys);
    }

    if (!this._cacheUserStorage.isEmpty) {
      this._cacheUserStorage.delete(keys);
    }
  }
}
