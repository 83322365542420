import {ObjectSingleCache} from '@retrixhouse/salesapp-shared/lib/caching';
import {pascalCase} from 'pascal-case';
import {IJsonSchemaDataService} from '../../interfaces/data-service';
import {JsonSchemaHttpService} from '../http';
import {JsonSchemaOfflineService} from '../offline';
import {BaseDataService, CachingOptions, TTL_DAY} from './base.data-service';

export class JsonSchemaDataService extends BaseDataService<
  IJsonSchemaDataService,
  ObjectSingleCache<any>
> {
  constructor(
    onlineService: JsonSchemaHttpService,
    offlineService: JsonSchemaOfflineService,
    cache: ObjectSingleCache<any>,
  ) {
    super(onlineService, offlineService, cache);
  }

  public async getOpenApiDefinition(
    cachingOptions?: CachingOptions,
  ): Promise<any> {
    if (cachingOptions?.skipCache) {
      return this.service.getOpenApiDefinition();
    }

    if (cachingOptions?.forceReload || !this._cache.isValid) {
      const schema = await this.service.getOpenApiDefinition();
      this._cache.init(schema, cachingOptions?.ttl ?? TTL_DAY);
    }

    return Promise.resolve(this._cache.get());
  }

  public async getProperties(
    name: string,
    cachingOptions?: CachingOptions,
  ): Promise<any> {
    const definition = await this.getOpenApiDefinition(cachingOptions);
    const modifiedName = name.toUpperCase() === name ? name : pascalCase(name);
    const schema = definition?.components?.schemas[modifiedName];

    if (schema) {
      return schema['properties'];
    }

    return {};
  }
}
