import {EventEmitter, Injectable, OnDestroy, Output} from '@angular/core';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {Subscription} from 'rxjs';

@Injectable()
export class ScreenService implements OnDestroy {
  @Output() changed = new EventEmitter();

  private $breakPointChangeSubscription: Subscription;

  constructor(private breakpointObserver: BreakpointObserver) {
    this.$breakPointChangeSubscription = this.breakpointObserver
      .observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
      ])
      .subscribe(() => this.changed.next());
  }

  ngOnDestroy(): void {
    if (this.$breakPointChangeSubscription) {
      this.$breakPointChangeSubscription.unsubscribe();
    }
  }

  public isLargeScreen(): boolean {
    const isLarge = this.breakpointObserver.isMatched(Breakpoints.Large);
    const isXLarge = this.breakpointObserver.isMatched(Breakpoints.XLarge);

    return isLarge || isXLarge;
  }

  public isSmallScreen(): boolean {
    const isSmall = this.breakpointObserver.isMatched(Breakpoints.Small);
    const isXSmall = this.breakpointObserver.isMatched(Breakpoints.XSmall);

    return isSmall || isXSmall;
  }

  public get sizes() {
    return {
      'screen-x-small': this.breakpointObserver.isMatched(Breakpoints.XSmall),
      'screen-small': this.breakpointObserver.isMatched(Breakpoints.Small),
      'screen-medium': this.breakpointObserver.isMatched(Breakpoints.Medium),
      'screen-large': this.isLargeScreen(),
    };
  }
}
