import {Comment} from '../comment.model';
import {PhotoTagComment} from '../photo.model';
import {TodoListResult} from '../todo-list-result.model';
import {TourPlanExecutor} from '../tour-plan.model';
import {PhotoCreateRequest} from './create-photo.request';
import {SetExtendedPropertiesRequest} from './set-extended-properties.request';

export class VisitResultRequest {
  todoListResult: TodoListResult;
  claims?: Partial<TourPlanExecutor>;
  photoTagCommentList?: PhotoTagComment[];
  storeObjectPropertyValues?: SetExtendedPropertiesRequest;
  removedPersonalArrangementIdList?: string[];
  editingOnBehalfOf?: string;
  feedbackComments?: Comment[];
  modifiedFeedbackIds?: string[];
  photos?: PhotoCreateRequest[];
  clientVersion?: string;
}
