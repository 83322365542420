import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ObjectTypeIds} from '@retrixhouse/salesapp-shared/lib/common';
import {
  IGenericListItem,
  IGenericListWithRelations,
  IObjectProperty,
  ValueType,
} from '@retrixhouse/salesapp-shared/lib/models';
import {resizeWorksheetCells} from '@retrixhouse/salesapp-shared/lib/utils/excel';
import {ActionsBuilderService} from '@salesapp/shared/services/actions-builder.service';
import {
  GenericListStorageService,
  ObjectStorageService,
  UserStorageStorageService,
} from '@salesapp/storage';
import {AutoUnsubscribe, unsubscribe} from '@salesapp/utils/angular.utils';
import {InputSelectOptions} from '@salesapp/utils/input-select-options';
import {getTranslationMarkersForObjectProperty} from '@salesapp/utils/translation.utils';
import {arrayToMap} from '@salesapp/utils/utils';
import {exportDataGrid} from 'devextreme/excel_exporter';
import {DataType, ExportingEvent} from 'devextreme/ui/data_grid';
import {Workbook} from 'exceljs';
import * as saveAs from 'file-saver';
import {Subscription, combineLatest} from 'rxjs';
import {map} from 'rxjs/operators';
import {
  FilterOperator,
  GridColumn,
  ObjectGridServiceImplementor,
} from '../grid.interfaces';
import {GridUtils} from '../grid.utils';
import {
  GridColumnSetting,
  OBJECT_GRID_COLUMN_SETTINGS_MAP,
  defaultPropertiesByObjectTypeId,
} from '../object-grid';
import {BaseGridService} from './base-grid-service';

@Injectable()
@AutoUnsubscribe()
export class ObjectGridService<T> extends BaseGridService<T> {
  private genericListItemSubscription: Subscription;

  get objectTypeId() {
    return this.implementor.objectTypeId;
  }

  get objectTypeGridColumnSettings() {
    const settings = OBJECT_GRID_COLUMN_SETTINGS_MAP[this.objectTypeId];

    if (!settings) {
      throw new Error(
        `ObjectGridService: No column settings for object type ${this.objectTypeId}`,
      );
    }

    return settings;
  }

  constructor(
    protected implementor: ObjectGridServiceImplementor<T>,
    private userStorageStorage: UserStorageStorageService,
    protected actionsBuilderService: ActionsBuilderService,
    private objectStorageService: ObjectStorageService,
    private genericListStorage: GenericListStorageService,
    protected translateService: TranslateService,
  ) {
    super(
      implementor,
      userStorageStorage,
      actionsBuilderService,
      translateService,
    );
    this.initColumns();
    this.exportData = this.exportData.bind(this);
  }

  ngOnDestroy() {}

  exportData = (exportingEvent: ExportingEvent<T>) => {
    unsubscribe(this.genericListItemSubscription);
    this.genericListItemSubscription =
      this.genericListStorage.genericListItemsById$.subscribe(
        genericListItemsById =>
          exportData({
            exportingEvent,
            genericListItemsById,
            translateService: this.translateService,
          }),
      );
  };

  private initColumns() {
    this.columns$ = combineLatest([
      this.objectStorageService.propertiesByObjectTypeId$,
      this.genericListStorage.dataById$,
    ]).pipe(
      map(([propertiesByObjectTypeId, genericListById]) => {
        if (!propertiesByObjectTypeId.size) {
          return null;
        }

        const objectColumns = this.objectPropertiesToColumns({
          genericListById,
          propertiesByObjectTypeId,
          objectTypeId: this.objectTypeId,
          parentVisible: true,
          gridColumnSettings: this.objectTypeGridColumnSettings,
        });
        const gridColumns = [
          {
            type: 'selection',
            propertyId: null,
            fixed: true,
            allowReordering: false,
            allowResizing: false,
            allowHiding: false,
            showInColumnChooser: false,
          },
          ...objectColumns,
        ];

        if (this.implementor.gridRowActions$) {
          gridColumns.push({
            type: 'buttons',
            propertyId: null,
            cellTemplate: 'actionsTemplate',
            fixed: true,
            allowReordering: false,
            allowResizing: false,
            allowHiding: false,
            showInColumnChooser: false,
            width: '80px',
          });
        }
        return gridColumns;
      }),
    );
    this.loading$ = this.columns$.pipe(
      map(columns => {
        return !(columns && columns.length);
      }),
    );
  }

  private objectPropertiesToColumns(params: {
    genericListById: Map<string, IGenericListWithRelations>;
    objectTypeId: ObjectTypeIds;
    propertiesByObjectTypeId: Map<string, IObjectProperty[]>;
    gridColumnSettings: GridColumnSetting[];
    parentPath?: string;
    parentVisible?: boolean;
  }) {
    const {
      genericListById,
      objectTypeId,
      propertiesByObjectTypeId,
      parentPath,
      parentVisible,
      gridColumnSettings,
    } = params;
    const defaultVisibleProperties =
      defaultPropertiesByObjectTypeId[objectTypeId];
    let properties = propertiesByObjectTypeId.get(objectTypeId);

    if (defaultVisibleProperties) {
      properties =
        properties?.sort(
          (a, b) =>
            defaultVisibleProperties.indexOf(a.id) -
            defaultVisibleProperties.indexOf(b.id),
        ) || [];
    }
    const settingMap = arrayToMap(gridColumnSettings, 'propertyId');

    const columns = [];

    properties.forEach(property => {
      switch (property.valueType) {
        case ValueType.RelationToManyData:
          throw new Error(
            'ObjectGridService: RelationToManyData not supported. Implementation required.',
          );
        case ValueType.RelationToOneData:
          if (settingMap.has(property.id)) {
            const visible = parentVisible
              ? defaultPropertiesByObjectTypeId[objectTypeId].includes(
                  property.id,
                )
              : false;
            columns.push({
              caption: this.translateService.instant(
                getTranslationMarkersForObjectProperty({
                  objectTypeId: objectTypeId as ObjectTypeIds,
                  propertyName: property.name,
                }).label,
              ),
              visible,
              dataField: `${parentPath ? `${parentPath}.` : ''}${
                property.name
              }`,
              columns: this.objectPropertiesToColumns({
                propertiesByObjectTypeId,
                objectTypeId: property.objectTypeId as ObjectTypeIds,
                parentPath: `${parentPath ? `${parentPath}.` : ''}${
                  property.name
                }`,
                parentVisible: visible,
                genericListById,
                gridColumnSettings: settingMap.get(property.id).columns || [],
              }),
              minWidth: 80,
              columnHidingEnabled: false,
              cssClass: 'ellipsis',
              allowHiding: true,
            });
          }
          break;
        default:
          columns.push(
            this.propertyToColumn({
              genericListById,
              property,
              parentPath,
              parentVisible,
            }),
          );
          break;
      }
    });
    return columns;
  }

  private propertyToColumn(params: {
    property: IObjectProperty;
    parentPath: string | null;
    genericListById: Map<string, IGenericListWithRelations>;
    parentVisible: boolean;
  }) {
    const {genericListById, property, parentPath, parentVisible} = params;
    const dataField = property.native
      ? `${parentPath ? `${parentPath}.` : ''}${property.name}`
      : `${parentPath ? `${parentPath}.` : ''}extendedProperties.${
          property.name
        }`;
    let visible = false;

    if (parentVisible) {
      visible = defaultPropertiesByObjectTypeId[property.typeId]
        ? defaultPropertiesByObjectTypeId[property.typeId].includes(property.id)
        : false;
    }

    const column: GridColumn = {
      caption: this.translateService.instant(
        getTranslationMarkersForObjectProperty({
          objectTypeId: property.typeId as ObjectTypeIds,
          propertyName: property.name,
        }).label,
      ),
      dataField,
      visible,
      width: getDefaultWidthForColumn(property.valueType),
      minWidth: 80,
      property: property,
      cssClass: 'ellipsis',
      cellTemplate: 'objectPropertyValueCellTemplate',
      groupCellTemplate: 'objectPropertyGroupTemplate',
      headerCellTemplate: 'headerTemplate',
      calculateCellValue(rowData) {
        const value = this.defaultCalculateCellValue(rowData);
        if (value === null || value === undefined) {
          return null;
        }
        return value;
      },
    };

    switch (property.valueType) {
      case ValueType.SingleChoice:
        const singleChoiceOptions =
          genericListById.get(property.listId)?.items || [];
        column.lookup = {
          allowClearing: true,
          dataSource: singleChoiceOptions,
          displayExpr: 'name',
          valueExpr: 'id',
        };
        break;
      case ValueType.MultiChoice:
        const multiChoiceOptions =
          genericListById.get(property.listId)?.items || [];
        column.lookup = {
          allowClearing: true,
          dataSource: multiChoiceOptions,
          displayExpr: 'name',
          valueExpr: 'id',
        };
        column.calculateFilterExpression = function (
          filterValue,
          selectedFilterOperation,
        ) {
          return function (data) {
            const filteredValue = GridUtils.accessPropertyData(
              column.dataField,
              data,
            );
            return filteredValue?.some(value => value === filterValue);
          };
        };
        break;
      case ValueType.Date:
        column.dataType = 'date';
        break;
      case ValueType.DateTime:
        column.dataType = 'datetime';
        break;
      case ValueType.Enum:
        const enumOptions = InputSelectOptions.enumToSelectOptions({
          translateService: this.translateService,
          enumName: property['enumName'],
        });
        column.lookup = {
          allowClearing: true,
          dataSource: enumOptions,
          displayExpr: 'name',
          valueExpr: 'value',
        };
        break;
      case ValueType.PlainText:
      case ValueType.RichText:
        column.calculateFilterExpression = function (
          filterValue,
          selectedFilterOperation,
        ) {
          return function (data) {
            return GridUtils.filterString({
              filterValue,
              operator: selectedFilterOperation as FilterOperator,
              filteredValue: GridUtils.accessPropertyData(
                column.dataField,
                data,
              ),
            });
          };
        };
        break;
      case ValueType.PlainTextList:
        column.dataType = 'string';
        column.calculateFilterExpression = function (
          filterValue,
          selectedFilterOperation,
        ) {
          return function (data) {
            const filteredValue = GridUtils.accessPropertyData(
              column.dataField,
              data,
            );
            return filteredValue?.some(value =>
              GridUtils.filterString({
                filterValue,
                operator: selectedFilterOperation as FilterOperator,
                filteredValue: value,
              }),
            );
          };
        };
        break;
      default:
        break;
    }

    return column;
  }
}

const typeMap: {[k in ValueType]?: DataType} = {
  [ValueType.Boolean]: 'boolean',
  [ValueType.Color]: 'string',
  [ValueType.Date]: 'date',
  [ValueType.DateTime]: 'datetime',
  [ValueType.Duration]: 'string',
  [ValueType.FileSize]: 'number',
  [ValueType.Float]: 'number',
  [ValueType.GenericList]: 'string',
  [ValueType.Integer]: 'number',
  [ValueType.MultiChoice]: 'string',
  [ValueType.PlainText]: 'string',
  [ValueType.PlainTextList]: 'string',
  [ValueType.RichText]: 'string',
  [ValueType.SingleChoice]: 'string',
  [ValueType.Time]: 'datetime',
};

function getDefaultWidthForColumn(valueType: ValueType) {
  switch (valueType) {
    case ValueType.Boolean:
      return 100;
    case ValueType.Color:
      return 100;
    case ValueType.Date:
      return 150;
    case ValueType.DateTime:
      return 150;
    case ValueType.Duration:
      return 100;
    case ValueType.FileSize:
      return 100;
    case ValueType.Float:
      return 100;
    case ValueType.GenericList:
      return 100;
    case ValueType.Integer:
      return 100;
    case ValueType.MultiChoice:
      return 200;
    case ValueType.PlainText:
      return 200;
    case ValueType.PlainTextList:
      return 200;
    case ValueType.RichText:
      return 200;
    case ValueType.SingleChoice:
      return 200;
    case ValueType.Time:
      return 100;
    default:
      return 100;
  }
}

function exportData<T>(params: {
  exportingEvent: ExportingEvent<T>;
  genericListItemsById: Map<string, IGenericListItem>;
  translateService: TranslateService;
}) {
  const {exportingEvent, genericListItemsById, translateService} = params;
  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet('Main sheet');
  exportDataGrid({
    component: exportingEvent.component,
    worksheet: worksheet,
    customizeCell: function ({gridCell, excelCell}) {
      excelCell.font = {name: 'Arial', size: 12};
      excelCell.alignment = {horizontal: 'left'};

      if (gridCell.rowType === 'data') {
        switch ((gridCell.column as GridColumn).property.valueType) {
          case ValueType.Boolean:
            excelCell.value = translateService.instant(
              `property-value.${gridCell.value}`,
            );
            break;
          case ValueType.SingleChoice:
            const singleChoiceReadableValue =
              genericListItemsById.get(gridCell.value)?.name || null;
            excelCell.value = singleChoiceReadableValue;
            break;

          case ValueType.MultiChoice:
            const multiChoiceReadableValues = gridCell.value
              ?.map((item: string) => genericListItemsById.get(item)?.name)
              .filter(Boolean)
              .join(', ');
            excelCell.value = multiChoiceReadableValues;
            break;
          case ValueType.PlainTextList:
            const plainTextListValues =
              gridCell.value && gridCell.value.length
                ? gridCell.value
                    ?.map((item: string) => item)
                    .filter(Boolean)
                    .join(', ')
                : null;
            excelCell.value = plainTextListValues;
            break;
        }
      }

      if (gridCell.rowType === 'group') {
        const translatedColumnName = gridCell.column.caption;
        const emptyValueTranslation = translateService.instant(
          'property-value-empty',
        );
        switch ((gridCell.column as GridColumn).property.valueType) {
          case ValueType.Boolean:
            excelCell.value = `${translatedColumnName}: ${translateService.instant(
              `property-value.${gridCell.value}`,
            )}`;
            break;
          case ValueType.SingleChoice:
            const singleChoiceReadableValue =
              genericListItemsById.get(gridCell.value)?.name || null;
            excelCell.value = `${translatedColumnName}: ${
              singleChoiceReadableValue || emptyValueTranslation
            }`;
            break;

          case ValueType.MultiChoice:
            const multiChoiceReadableValues = gridCell.value?.length
              ? gridCell.value
                  .map((item: string) => genericListItemsById.get(item)?.name)
                  .filter(Boolean)
                  .join(', ')
              : null;
            excelCell.value = `${translatedColumnName}: ${
              multiChoiceReadableValues || emptyValueTranslation
            }`;
            break;
          case ValueType.PlainTextList:
            const plainTextListValues =
              gridCell.value && gridCell.value.length
                ? gridCell.value
                    ?.map((item: string) => item)
                    .filter(Boolean)
                    .join(', ')
                : null;
            excelCell.value = `${translatedColumnName}: ${
              plainTextListValues || emptyValueTranslation
            }`;
            break;
          default:
            excelCell.value = `${translatedColumnName}: ${
              [undefined, null].includes(gridCell.value)
                ? emptyValueTranslation
                : gridCell.value
            }`;
            break;
        }
      }
    },
  }).then(function () {
    resizeWorksheetCells(worksheet);

    workbook.xlsx.writeBuffer().then(function (buffer: BlobPart) {
      saveAs(
        new Blob([buffer], {type: 'application/octet-stream'}),
        'DataGrid.xlsx',
      );
    });
  });
}
