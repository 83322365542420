import {Injectable} from '@angular/core';
import {Chain} from 'src/app/shared/models/chain.model';
import {IChainDataService} from '../../interfaces/data-service';
import {BaseCrudOfflineService} from './base.offline-service';

@Injectable()
export class ChainOfflineService
  extends BaseCrudOfflineService<Chain>
  implements IChainDataService
{
  public updatePicture(
    chainId: string,
    version: number,
    picture: string,
  ): Promise<Chain> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public deletePicture(chainId: string): Promise<void> {
    return Promise.reject('Offline service - not yet implemented');
  }
}
