export class VisitsPivotReportRequest {
  projectId: string;
  todoListId: string;
  todoListItemIds: string[];
  from?: Date;
  to?: Date;
  executors: string[];
  chains: string[];

  constructor() {
    this.todoListItemIds = [];
    this.executors = [];
    this.chains = [];
  }
}
