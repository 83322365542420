import {ObjectMapCache} from '@retrixhouse/salesapp-shared/lib/caching';
import {INotificationTemplateDataService} from '../../interfaces/data-service';
import {NotificationTemplate} from '../../models';
import {NotificationTemplateHttpService} from '../http';
import {NotificationTemplateOfflineService} from '../offline';
import {BaseCrudDataService} from './base.data-service';

export class NotificationTemplateDataService extends BaseCrudDataService<
  NotificationTemplate,
  INotificationTemplateDataService,
  ObjectMapCache<string, NotificationTemplate>
> {
  constructor(
    onlineService: NotificationTemplateHttpService,
    offlineService: NotificationTemplateOfflineService,
    cache: ObjectMapCache<string, NotificationTemplate>,
  ) {
    super(onlineService, offlineService, cache);
  }

  public getPlaceholders(): Promise<string[]> {
    return this.service.getPlaceholders();
  }
}
