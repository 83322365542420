<div class="toolbar">
  <ng-container *ngIf="visible">
    <ng-content select="toolbar"></ng-content>
  </ng-container>
</div>
<ng-container *ngIf="visible; else notVisible">
  <div class="content">
    <app-dynamic-form [initialValue]="initialValue"
                      [objectTypeId]="objectTypeId"
                      [formActions]="formActions"
                      [readonlyMode]="readonlyAccess"
                      (actionClicked)="actionClicked.emit($event)"
                      (cancelClicked)="cancelClicked.emit()"
                      (avatarPhotoUploaded)="avatarPhotoUploaded.emit($event)"></app-dynamic-form>
  </div>
  <div class="footer">
    <app-form-actions #formActions
                      actionIcon="fa fa-save"
                      cancelIcon="fa fa-times"
                      [actionLabel]="'object-detail.dynamic-form.action' | translate"
                      [actionDisabled]="actionDisabled"
                      [actionVisible]="canEdit"
                      [cancelLabel]="'object-detail.dynamic-form.cancel' | translate"
                      [cancelDisabled]="cancelDisabled"
                      [cancelVisible]="true"></app-form-actions>
  </div>
</ng-container>

<ng-template #notVisible>
  <div class="content">
    <div class="not-visible-message">
      <i class="fa-solid fa-ban"></i>
      <span>
        {{ 'object-detail-dialog-form.not-visible-message' | translate }}
      </span>
    </div>
  </div>
  <div class="footer">
    <app-form-actions #formActions
                      cancelIcon="fa fa-times"
                      [cancelLabel]="'object-detail.dynamic-form.cancel' | translate"
                      [cancelVisible]="true"
                      (cancel)="cancelClicked.emit()"></app-form-actions>
  </div>
</ng-template>