import {ICustomer} from '@retrixhouse/salesapp-shared/lib/models';
import {OldBaseStorageService} from './old-base-storage-service';
import {Injectable} from '@angular/core';
import {DataProvider} from '../../data.provider/data-provider';
import {InputSelectOption} from '../../components';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {sortByKey} from '../../globals';

@Injectable()
export class CustomerStorageService extends OldBaseStorageService<
  ICustomer,
  ICustomer[]
> {
  // dataAsSelectOptions$: Observable<InputSelectOption[]> = this.data$.pipe(
  //   map(data =>
  //     sortByKey(
  //       data.map(customer => ({name: customer.name, value: customer.id})),
  //       'name',
  //     ),
  //   ),
  // );
  constructor(dataProvider: DataProvider) {
    super(dataProvider.customer);
  }
}
