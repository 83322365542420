export enum MimeTypes {
  PNG = 'image/png',
  JPEG = 'image/jpeg',
  DOC = 'application/msword',
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  PDF = 'application/pdf',
}

export const MIME_TYPE_TO_FILE_EXTENSION: {[k in MimeTypes]: string} = {
  [MimeTypes.JPEG]: 'jpeg',
  [MimeTypes.PNG]: 'png',
  [MimeTypes.DOC]: 'doc',
  [MimeTypes.DOCX]: 'docx',
  [MimeTypes.PDF]: 'pdf',
};

export const FILE_EXTENSION_TO_MIME_TYPE: {[k: string]: MimeTypes} = {
  jpeg: MimeTypes.JPEG,
  jpg: MimeTypes.JPEG,
  png: MimeTypes.PNG,
  doc: MimeTypes.DOC,
  docx: MimeTypes.DOCX,
  pdf: MimeTypes.PDF,
};

export const FILE_ICONS = {
  [MimeTypes.JPEG]: 'fa-solid fa-file-jpg',
  [MimeTypes.PNG]: 'fa-solid fa-file-png',
  [MimeTypes.DOC]: 'fa-solid fa-file-doc',
  [MimeTypes.DOCX]: 'fa-solid fa-file-doc',
  [MimeTypes.PDF]: 'fa-solid fa-file-pdf',
};

export class FileUtils {
  static getIconByMimeType(mimeType: MimeTypes) {
    return FILE_ICONS[mimeType];
  }
}
