import {BaseCrudHttpService} from './base.http-service';
import {HttpClient} from '@angular/common/http';
import {IClientSideError} from '@retrixhouse/salesapp-shared/lib/models';
import {IClientSideErrorDataService} from '../../interfaces/data-service/client-side-error.iface';
import {Injectable} from '@angular/core';

const ENDPOINT = '/sys/client-side/errors';

@Injectable()
export class ClientSideErrorHttpService
  extends BaseCrudHttpService<IClientSideError>
  implements IClientSideErrorDataService
{
  constructor(http: HttpClient) {
    super(http, ENDPOINT);
  }

  uploadErrors(errors: IClientSideError[]): Promise<number> {
    return this.POST(ENDPOINT, errors);
  }
}
