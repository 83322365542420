import {
  IProjectAggregatedDataResponse
} from '@retrixhouse/salesapp-shared/lib/responses';
import * as moment from 'moment';
import {BaseDataService} from './base.data-service';
import {ReportProjectHttpService} from '../http';
import {
  IReportProjectDataService
} from '../../interfaces/data-service';

export class ReportProjectDataService extends BaseDataService<
  IReportProjectDataService
>{

  private simpleCache: Map<string, IProjectAggregatedDataResponse>;
  constructor(
    private onlineService: ReportProjectHttpService,
  ) {
    super(onlineService);
    this.simpleCache = new Map<string, IProjectAggregatedDataResponse>();
  }
  public async getProjectTileData(
    projectId: string,
    from: Date,
    to: Date
  ): Promise<IProjectAggregatedDataResponse> {
    const cacheKey = `${projectId}:${moment(from).format('L')}:${moment(to).format('L')}`;

    // check if data is in cache
    if (this.simpleCache.has(cacheKey)) {
      return this.simpleCache.get(cacheKey);
    }

    // load data otherwise
    // const data = datesAreSame(from, to)  ? mockOneDayJSON : mockJSON;

    // load data otherwise
    const data = await this.service.getProjectTileData(projectId, from, to);

    this.simpleCache.set(cacheKey, data);
    return data;
  }
}
