import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Filter} from '@loopback/filter';
import {environment} from 'src/environments/environment';
import {ClientMode} from '../../enums/client-mode.enum';
import {timeout} from 'rxjs/operators';

// three minutes
export const DEFAULT_HTTP_REQUEST_TIMEOUT = 180_000;

export class BaseHttpService {
  private _errorHandler;

  constructor(private http: HttpClient) {
    this._errorHandler = (e: HttpErrorResponse) => {
      let errorMessage: string;
      if (e.error?.error?.message) {
        errorMessage = `Error: ${e.error?.error?.message}`;
      } else {
        errorMessage = this.getServerErrorMessage(e);
      }
      const error = new Error(errorMessage);
      error['originalError'] = e;
      error['isHttp'] = true;
      throw error;
    };
  }

  public get supportedMode(): ClientMode {
    return ClientMode.ONLINE;
  }

  private toUrl(path: string): string {
    return `${environment.backEndBaseUrl}${path}`.replace(/([^:]\/)\/+/g, '$1');
  }

  private getTimeoutValue(options?: {}): number {
    if (
      options !== undefined &&
      typeof options['requestTimeout'] === 'number' &&
      options['requestTimeout'] > 0
    ) {
      return options['requestTimeout'];
    }

    return DEFAULT_HTTP_REQUEST_TIMEOUT;
  }

  protected GET<T>(path: string, options?: {}): Promise<T> {
    return this.http
      .get<T>(this.toUrl(path), options)
      .pipe(timeout(this.getTimeoutValue(options)))
      .toPromise()
      .catch(this._errorHandler);
  }

  protected POST<T>(path: string, body: {}, options?: {}): Promise<T> {
    return this.http
      .post<T>(this.toUrl(path), body, options)
      .pipe(timeout(this.getTimeoutValue(options)))
      .toPromise()
      .catch(this._errorHandler);
  }

  protected PATCH<T>(path: string, body: {}, options?: {}): Promise<T> {
    return this.http
      .patch<T>(this.toUrl(path), body, options)
      .pipe(timeout(this.getTimeoutValue(options)))
      .toPromise()
      .catch(this._errorHandler);
  }

  protected PUT<T>(path: string, body: {}, options?: {}): Promise<T> {
    return this.http
      .put<T>(this.toUrl(path), body, options)
      .pipe(timeout(this.getTimeoutValue(options)))
      .toPromise()
      .catch(this._errorHandler);
  }

  protected DELETE<T>(path: string, options?: {}): Promise<T> {
    return this.http
      .delete<T>(this.toUrl(path), options)
      .pipe(timeout(this.getTimeoutValue(options)))
      .toPromise()
      .catch(this._errorHandler);
  }

  private getServerErrorMessage(error: HttpErrorResponse): string {
    switch (error.status) {
      case 400: {
        return `[${error.status}] Validation error: ${error.error.error.message}`;
      }
      case 404: {
        return `[${error.status}] Not found: ${error.message}`;
      }
      case 401:
      case 403: {
        return `[${error.status}] Access denied: ${error.message}`;
      }
      case 500: {
        return `[${error.status}] Internal server error: ${error.message}`;
      }
      default: {
        return `[${error.status}] Unrecognized server error: ${error.message}`;
      }
    }
  }
}

export class BaseReadonlyHttpService<T> extends BaseHttpService {
  constructor(http: HttpClient, protected endpoint: string) {
    super(http);
  }

  public getList(filter?: Filter): Promise<T[]> {
    return this.GET<T[]>(
      this.endpoint.toLowerCase(),
      filter ? {params: {filter: JSON.stringify(filter)}} : {},
    );
  }

  public getSingle(id: string): Promise<T> {
    return this.GET<T>(`${this.endpoint.toLowerCase()}/${id}`);
  }

  public async getCount(filter?: Filter): Promise<number> {
    const result = await this.GET<number>(this.endpoint.toLowerCase(), {
      params: {
        filter: filter ? JSON.stringify(filter) : undefined,
        meta: 'count',
      },
    });

    if (typeof result === 'number') {
      return Promise.resolve(result);
    }

    return Promise.reject(
      `Response value type is not a number: probably the endpoint GET '${this.endpoint.toLowerCase()}' doesn't support meta param.`,
    );
  }

  public async exists(filter?: Filter): Promise<boolean> {
    const result = await this.GET<boolean>(this.endpoint.toLowerCase(), {
      params: {
        filter: filter ? JSON.stringify(filter) : undefined,
        meta: 'exists',
      },
    });

    if (typeof result === 'boolean') {
      return Promise.resolve(result);
    }

    return Promise.reject(
      `Response value type is not a boolean: probably the endpoint GET '${this.endpoint.toLowerCase()}' doesn't support meta param.`,
    );
  }
}

export class BaseCrudHttpService<T> extends BaseReadonlyHttpService<T> {
  constructor(http: HttpClient, protected endpoint: string) {
    super(http, endpoint);
  }

  public create(objectData: Partial<T>): Promise<T> {
    return this.POST<T>(this.endpoint.toLowerCase(), objectData);
  }

  public update(id: string, objectData: Partial<T>): Promise<T> {
    return this.PATCH<T>(`${this.endpoint.toLowerCase()}/${id}`, objectData);
  }

  public delete(id: string, version?: number): Promise<void> {
    const params = version ? {params: {version}} : {};
    return super.DELETE<void>(`${this.endpoint.toLowerCase()}/${id}`, params);
  }
}
