import {CurrencyStorageService} from './storage/currency-storage.service';
import {CustomerStorageService} from './storage/customer-storage.service';
import {DataProvider} from '../data.provider/data-provider';
import {UntypedFormBuilder} from '@angular/forms';
import {from} from 'rxjs';
import {GenericListItemStorageService} from './storage/generic-list-item-storage.service';
import {Injectable} from '@angular/core';
import {IUnit} from '@retrixhouse/salesapp-shared/lib/models';
import {map} from 'rxjs/operators';
import {ObjectTypeNames} from '@retrixhouse/salesapp-shared/lib/common';
import {ProductCategoryStorageService} from './storage/product-category-storage.service';
import {sortByKey} from '../globals';
import {sortByName} from '@retrixhouse/salesapp-shared/lib/utils';
import {UnitStorageService} from './storage/unit-storage.service';

@Injectable()
export class SharedFormService {
  currencySelectOptions$ = this.currencyStorageService.dataAsSelectOptions$;
  customersSelectOptions$ = this.customerStorageService.dataAsSelectOptions$;
  productCategoriesSelectOptions$ =
    this.productCategoryStorageService.dataAsSelectOptions$;
  unitSelectOptions$ = this.unitStorageService.data$.pipe(
    map(units => this.formatUnitsWithGroups(units)),
  );

  defaultCurrency$ = this.currencyStorageService.getDefaultCurrency();

  get productCategories() {
    return this.productCategoryStorageService.dataState.value;
  }

  get customers() {
    return this.customerStorageService.dataState.value;
  }

  constructor(
    private formBuilder: UntypedFormBuilder,
    private genericListItemStorageService: GenericListItemStorageService,
    private customerStorageService: CustomerStorageService,
    private productCategoryStorageService: ProductCategoryStorageService,
    private currencyStorageService: CurrencyStorageService,
    private unitStorageService: UnitStorageService,
    private dataProvider: DataProvider,
  ) {}

  getGenericListItemsForProperty(listId: string) {
    return this.genericListItemStorageService.getItemsForList(listId).pipe(
      map(items =>
        sortByKey(
          items.map(item => ({name: item.name, value: item.id})),
          'name',
        ),
      ),
    );
  }

  getNextUidForObject(objectTypeName: ObjectTypeNames) {
    return from(this.dataProvider.object.getNextAvailableUID(objectTypeName));
  }

  createAddressFormGroup() {
    return this.formBuilder.group({
      id: this.formBuilder.control(null),
      line1: this.formBuilder.control(null),
      line2: this.formBuilder.control(null),
      zipCode: this.formBuilder.control(null),
      city: this.formBuilder.control(null),
      region: this.formBuilder.control(null),
      state: this.formBuilder.control(null),
      countryId: this.formBuilder.control(null),
    });
  }

  private formatUnitsWithGroups(units: IUnit[]) {
    if (!units) {
      return [];
    }
    const unitList = [];
    sortByName(units);
    units.forEach(unit => {
      const match = unitList.find(u => u.key === unit.category);
      if (match) {
        match.items.push({value: unit.id, name: unit.name, data: unit});
      } else {
        unitList.push({
          key: unit.category,
          items: [{value: unit.id, name: unit.name, data: unit}],
        });
      }
    });
    return unitList;
  }
}
