import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {LazyForDirective} from './lazyFor.directive';
import {LongPressDirective} from './long-press.directive';
import {VisibilityObserverDirective} from './visiblity-observer.directive';
import {VisibleIfDirective} from './visibile-if.directive';
import {FileDropZoneDirective} from './file-drop-zone.directive';

const CUSTOM_DIRECTIVES = [
  VisibleIfDirective,
  LazyForDirective,
  LongPressDirective,
  VisibilityObserverDirective,
  FileDropZoneDirective,
];

@NgModule({
  declarations: [...CUSTOM_DIRECTIVES],
  imports: [CommonModule, RouterModule],
  exports: [...CUSTOM_DIRECTIVES],
})
export class CustomDirectivesModule {}
