import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {DIALOG_DATA, DialogRef} from '@angular/cdk/dialog';
import {IStore} from '@retrixhouse/salesapp-shared/lib/models';
import {map} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {StoreStorageService} from '@salesapp/storage';

@Component({
  selector: 'app-store-detail-dialog',
  templateUrl: './store-detail-dialog.component.html',
  styleUrls: ['./store-detail-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StoreDetailDialogComponent {
  loading$ = this.storeStorageService.dataFetching$;
  store$: Observable<IStore | null>;

  constructor(
    private dialogRef: DialogRef<StoreDetailDialogData>,
    @Inject(DIALOG_DATA) private data: StoreDetailDialogData,
    private storeStorageService: StoreStorageService,
  ) {}

  ngOnInit() {
    if (this.data.storeId) {
      this.store$ = this.storeStorageService.dataById$.pipe(
        map(storesById => {
          return storesById.get(this.data.storeId);
        }),
      );
    } else {
      this.store$ = of(null);
    }
  }

  onClose() {
    this.dialogRef.close();
  }
}

export interface StoreDetailDialogData {
  storeId: string;
}
