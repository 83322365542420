<div id="data-download-wrapper">
  <div>NOT IMPLEMENTED</div>
  <!--
  <div class='my-1'>
    <app-data-transfer-summary
        [totalCount]='progressMap.size'
        [dataSize]='dataSize'
        [showCompletedProgressBars]='showCompletedProgressBars'
        [pendingObservablesCount]='pendingObservablesCount'
        [errorCount]='errorCount'
        [repeatableErrorCount]='errorCount'
        (toggleShowMode)='toggleShowMode($event)'
        (retryFailed)='retryFailed()'
    ></app-data-transfer-summary>
  </div>

  <ng-container *ngFor="let progress of progressMap | keyvalue" >
    <div *ngIf='showCompletedProgressBars || !progress.value.completed' class='progress-wrapper my-2'>
      <app-data-transfer-download-progress
        [modelName]="progress.value.label | translate"
        [icon]="progress.value.icon"
        [completed]="progress.value.completed"
        [error]="progress.value.error"
        [dataSize]="progress.value.dataSize"
        (retryWhenFailed)='retryFailed(progress.key)'></app-data-transfer-download-progress>
    </div>
  </ng-container>

  <div class="group-container">
    <dx-button
      (onClick)="btnContinueClick()"
      [disabled]="pendingObservablesCount > 0"
      [useSubmitBehavior]="false"
      icon="fa fa-save"
      [text]="formatBtnContinueText()"
      type="default"
      [ngStyle.lt-md]="{'width': '100%'}"
    ></dx-button>
    &nbsp;
    <dx-button
      type="normal"
      text="{{ 'buttons.cancel' | translate }}"
      icon="fa fa-times"
      (onClick)="btnCancelClick()"
      [ngStyle.lt-md]="{'width': '100%'}"
    ></dx-button>
  </div>
  -->
</div>
