import {ChangeDetectorRef, Component, Input} from '@angular/core';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import {BehaviorSubject} from 'rxjs';
import {TSimpleChanges} from '../../utils/angular.utils';
import {ControlValueAccessor} from '../../utils/reactive-form/control-value-accessor';
import {FormControlComponent} from '../../utils/reactive-form/form-control.component';
import {createFormControlProvider} from '../../utils/reactive-form/form.utils';

@Component({
  selector: 'app-input-multiselect',
  templateUrl: './input-multiselect.component.html',
  styleUrls: ['./input-multiselect.component.scss'],
  providers: [createFormControlProvider(() => InputMultiselectComponent)],
})
@ControlValueAccessor()
export class InputMultiselectComponent extends FormControlComponent<string[]> {
  @Input() options: InputMultiselectOption[];
  @Input() itemType: 'username';
  dataSource$ = new BehaviorSubject<DataSource>(null);

  constructor(private changeDetector: ChangeDetectorRef) {
    super(changeDetector);
  }

  ngOnChanges(changes: TSimpleChanges<InputMultiselectComponent>): void {
    if (changes.options) {
      this.dataSource$.next(
        new DataSource({
          paginate: true,
          pageSize: 100,
          store: new ArrayStore({
            key: 'value',
            data: this.options.map(option => ({
              ...option,
              visible: !option.inactive,
            })),
          }),
          sort: ['name'],
        }),
      );
    }
  }

  onValueChange(event) {
    if (event !== this.value) {
      this.valueChange.emit(event);
    }
  }

  removeSelectedItem(item) {
    const value = this.value.filter(valueItem => valueItem !== item.value);
    this.valueChange.emit(value);
  }
}

export interface InputMultiselectOption<T = unknown> {
  value: string;
  name: string;
  data?: T;
  disabled?: boolean;
  html?: string;
  template?: any;
  visible?: boolean;
  inactive?: boolean;
}
