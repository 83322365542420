import DOMComponent from 'devextreme/core/dom_component';
import dxButton, {Properties as ButtonProperties} from 'devextreme/ui/button';
import dxDateBox, {Properties as DateProperties} from 'devextreme/ui/date_box';
import dxFileUploader, {
  Properties as FileUploaderProperties,
} from 'devextreme/ui/file_uploader';
import dxNumberBox, {
  Properties as NumberBoxProperties,
} from 'devextreme/ui/number_box';
import dxRadioGroup, {
  Properties as RadioGroupProperties,
} from 'devextreme/ui/radio_group';
import dxSelectBox, {
  Properties as SelectBoxProperties,
} from 'devextreme/ui/select_box';
import dxTagBox, {Properties as TagBoxProperties} from 'devextreme/ui/tag_box';
import dxTextArea, {
  Properties as TextAreaProperties,
} from 'devextreme/ui/text_area';
import dxTextBox, {
  Properties as TextBoxProperties,
} from 'devextreme/ui/text_box';
import {Properties as CheckBoxProperties} from 'devextreme/ui/check_box';
import {Properties as ColorBoxProperties} from 'devextreme/ui/color_box';
import dxForm, {Properties as FormProperties} from 'devextreme/ui/form';
import {formatDateTime} from 'src/app/shared/pipes/localized-datetime.pipe';
import {ExpressionEvalutaionService} from './expression-evaluation.service';
import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import dxAutocomplete from 'devextreme/ui/autocomplete';
import dxDropDownBox from 'devextreme/ui/drop_down_box';
import dxToolbar from 'devextreme/ui/toolbar';
import dxButtonGroup from 'devextreme/ui/button_group';
import dxDataGrid from 'devextreme/ui/data_grid';
import DataSource, {Options as DSOptions} from 'devextreme/data/data_source';
import ArrayStore, {Options as StoreOptions} from 'devextreme/data/array_store';
import Validator from 'devextreme/ui/validator';
import * as moment from 'moment';
import {ValidationCallbackData} from 'devextreme/common';
import dxValidationGroup from 'devextreme/ui/validation_group';
import dxDropDownButton from 'devextreme/ui/drop_down_button';
import {FileUtils, isValidDate} from '@retrixhouse/salesapp-shared/lib/utils';

@Injectable()
export class DevextremeService {
  constructor(
    private expressionEvalutaionService: ExpressionEvalutaionService,
    private translate: TranslateService,
  ) {}

  /**
   * Creates a devextreme data source.
   * @param {any[]} data Data to feed the data source.
   * @param {string[]} sort Specifies data sorting properties.
   * @param {paginate: boolean; pageSize: number} paging paginate property specifies the maximum number of data items per page. Applies only if paginate is true. Paging property specifies whether the DataSource loads data items by pages or all at once. Defaults to false if group is set; otherwise, true.
   * @param storeOptions The options of the store
   * @param datasourceOptions The options of the DataSource
   * @returns
   */
  static createDataSource<T = any>(
    data: T[],
    sort: (keyof T)[] = [],
    paging: {paginate: boolean; pageSize: number} = {
      pageSize: 10,
      paginate: true,
    },
    storeOptions: Partial<StoreOptions> = {},
    datasourceOptions: Partial<DSOptions> = {},
  ): DataSource<any, any> {
    return new DataSource({
      store: new ArrayStore({
        data: data,
        ...storeOptions,
      }),
      pageSize: paging?.pageSize,
      paginate: paging?.paginate,
      sort: sort as string[],
      ...datasourceOptions,
    });
  }

  /**
   * Returns the related type name of devextreme's editor for a given value type. (e.g. for Boolean returns dxSwitch)
   * @param {string} valueType - the name of the type to get the proper editor for. (e.g. Boolean, Date, Float ...)
   * @returns { DevexEditorTypes} the editor name for the given value type.
   */
  getEditorTypeForValueType = (valueType: string): DevexEditorTypes => {
    let editorName: DevexEditorTypes = 'dxTextBox';

    switch (valueType) {
      case 'Date':
      case 'DateRange':
      case 'DateTime':
      case 'Time':
      case 'TimeRange':
        editorName = 'dxDateBox';
        break;

      case 'Float':
      case 'FloatRange':
      case 'Integer':
      case 'IntegerRange':
        editorName = 'dxNumberBox';
        break;

      case 'Duration':
      case 'DurationRange':
        editorName = 'dxTextBox';
        break;

      case 'FreeText':
        editorName = 'dxTextArea';
        break;

      case 'MultiChoice':
      case 'MultiChoiceProduct':
      case 'ObjectProperties':
      case 'ObjectExtendedProperties':
        editorName = 'dxTagBox';
        break;

      case 'SingleChoice':
      case 'SingleChoiceProduct':
      case 'GenericList':
        editorName = 'dxSelectBox';
        break;

      case 'SinglePhoto':
      case 'MultiPhoto':
        editorName = 'dxFileUploader';
        break;

      case 'YesNo':
      case 'YesNoDontKnow':
        editorName = 'dxRadioGroup';
        break;

      case 'Boolean':
        editorName = 'dxSwitch';
        break;
      case 'Color':
        editorName = 'dxColorBox';
        break;
      default:
        editorName = 'dxTextBox';
        break;
    }

    return editorName;
  };

  /**
   * Returns a devextreme instance for an HTML element.
   * @param {DevexEditorTypes} editorName - the name of the editor to get the devextreme instance of it.
   * @param {HTMLElement} element - the HTML element wich represents the element to get the devextreme instance for it.
   * @returns { DOMComponent} the devextreme editor instance.
   */
  getEditorInstance = (
    editorName: DevexEditorTypes,
    element: HTMLElement,
  ): DOMComponent => {
    switch (editorName) {
      case 'dxTextBox':
        const textBoxInstance = dxTextBox.getInstance(element);
        return textBoxInstance;

      case 'dxDateBox':
        const dateBoxInstance = dxDateBox.getInstance(element);
        return dateBoxInstance;

      case 'dxNumberBox':
        const numberBoxInstance = dxNumberBox.getInstance(element);
        return numberBoxInstance;

      case 'dxFileUploader':
        const fileUploaderBoxInstance = dxFileUploader.getInstance(element);
        return fileUploaderBoxInstance;

      case 'dxRadioGroup':
        const radioGroupElementInstance = dxRadioGroup.getInstance(element);
        return radioGroupElementInstance;

      case 'dxSelectBox':
        const selectBoxElementInstance = dxSelectBox.getInstance(element);
        return selectBoxElementInstance;

      case 'dxTagBox':
        const tagBoxElementInstance = dxTagBox.getInstance(element);
        return tagBoxElementInstance;

      case 'dxTextArea':
        const textAreaElementInstance = dxTextArea.getInstance(element);
        return textAreaElementInstance;

      case 'dxButton':
        const dxButtonElementInstance = dxButton.getInstance(element);
        return dxButtonElementInstance;

      case 'dxAutocomplete':
        const dxAutocompleteElementInstance =
          dxAutocomplete.getInstance(element);
        return dxAutocompleteElementInstance;

      case 'dxDropDownBox':
        const dxdropDownElementInstance = dxDropDownBox.getInstance(element);
        return dxdropDownElementInstance;

      case 'dxToolbar':
        const dxToolbarElementInstance = dxToolbar.getInstance(element);
        return dxToolbarElementInstance;

      case 'dxButtonGroup':
        const dxButtonGroupElementInstance = dxButtonGroup.getInstance(element);
        return dxButtonGroupElementInstance;

      case 'dxForm':
        const dxFormElementInstance = dxForm.getInstance(element);
        return dxFormElementInstance;

      case 'dxDataGrid':
        const dxDataGridElementInstance = dxDataGrid.getInstance(element);
        return dxDataGridElementInstance;

      case 'dxValidationGroup':
        const dxValidationGroupInstance =
          dxValidationGroup.getInstance(element);
        return dxValidationGroupInstance;

      case 'dxDropDownButton':
        const dxDropDownBoxInstance = dxDropDownButton.getInstance(element);
        return dxDropDownBoxInstance;
    }
  };

  /**
   * Validator for date range input
   * @param {ValidationCallbackData} e The event object of the validationCallback.
   * @param opts validation options
   * @param {boolean} opts.fromRequired flag if start of range is required
   * @param {boolean} opts.toRequired flag if end of range is required
   * @param {number} opts.maxDifference number representing maximum days between start and end of range
   * */
  dateRangeValidation(
    e: ValidationCallbackData,
    opts?: {
      fromRequired?: boolean,
      toRequired?: boolean,
      maxDifference?: number,
    }
  ): boolean {
    const [from, to] = e.value;

    if (
      (!from && opts?.fromRequired) ||
      (!to && opts?.toRequired)
    ) {
      e.rule.message = this.translate.instant('visit-data-report.messages.validation-date-range-required');
      return false;
    }

    const maxDifference = opts?.maxDifference;
    if (maxDifference) {
      const diff = moment(to).diff(moment(from), 'days');
      const maxDifExceeded = diff > maxDifference;

      if (maxDifExceeded) {
        e.rule.message = this.translate.instant(
          'messages.validation.difference-not-more-than',
          {difference: maxDifference},
        );
        return false;
      }
    }

    return true;
  }

  /**
   * Returns a validator instance of the element with the provided unique id.
   * @param {string} elementId The unique id of the element.
   * @returns {Validator} The instance of the validator.
   */
  getValidatorInstance(elementId: string) {
    let element = document.getElementById(elementId);
    return Validator.getInstance(element) as Validator;
  }

  /**
   *
   * @param {ValidationCallbackData} e The event object of the validationCallback.
   * @param {'dxDateBox' | 'dxNumberBox' | 'dxTextBox'} editorType The type of the editor that is being validated.
   * @param {string} fromEditorId The unique id of the `from` editor.
   * @param {string} toEditorId The unique id of the `to` editor.
   * @param {boolean} nullableTo `true` when it is allowed to have a null `to` value. Otherwise, `false`.
   * @param {number} maxDifference The max allowed difference between the `from` and the `to` editors.
   * @param {(value) => any} valueTransformerFunc A function used to tranform the values before comparing them.
   * @returns {boolean} `true` when the `from` and `to` editors are valid. Otherwise, `false`.
   */
  rangeValidationFrom(
    e: ValidationCallbackData,
    editorType: 'dxDateBox' | 'dxNumberBox' | 'dxTextBox',
    fromEditorId: string,
    toEditorId: string,
    nullableTo: boolean = false,
    maxDifference: number = undefined,
    valueTransformerFunc: (value) => any = value => value,
  ): boolean {
    e.rule.message = this.translate.instant(
      'messages.validation.range-validation-error',
    );

    const toEditorInstance = this.getEditorInstance(
      editorType,
      document.getElementById(toEditorId),
    );
    let toValue: any = valueTransformerFunc(toEditorInstance?.option('value'));
    const fromValue = valueTransformerFunc(e.value);

    if (toValue == null && nullableTo) {
      return true;
    }

    if (toValue == null && fromValue != null) {
      return false;
    }

    if (fromValue > toValue) {
      return false;
    } else {
      if (maxDifference && toValue != null) {
        const differenceValid = this.validateDifference(
          fromValue,
          toValue,
          maxDifference,
          editorType,
        );

        if (!differenceValid) {
          e.rule.message = this.translate.instant(
            'messages.validation.difference-not-more-than',
            {difference: maxDifference},
          );
          return differenceValid;
        }
      }

      const currentValidator = this.getValidatorInstance(fromEditorId);
      currentValidator.option('isValid', true);

      const toValidator = this.getValidatorInstance(toEditorId);
      if (!toValidator.option('isValid')) {
        toValidator.validate();
      }
      return true;
    }
  }

  /**
   *
   * @param {ValidationCallbackData} e The event object of the validationCallback.
   * @param {'dxDateBox' | 'dxNumberBox' | 'dxTextBox'} editorType The type of the editor that is being validated.
   * @param {string} fromEditorId The unique id of the `from` editor.
   * @param {string} toEditorId The unique id of the `to` editor.
   * @param {boolean} nullableTo `true` when it is allowed to have a null `to` value. Otherwise, `false`.
   * @param {number} maxDifference The max allowed difference between the `from` and the `to` editors.
   * @param {(value) => any} valueTransformerFunc A function used to tranform the values before comparing them.
   * @returns {boolean} `true` when the `from` and `to` editors are valid. Otherwise, `false`.
   */
  rangeValidationTo(
    e,
    editorType: 'dxDateBox' | 'dxNumberBox' | 'dxTextBox',
    fromEditorId: string,
    toEditorId: string,
    nullableTo: boolean = false,
    maxDifference: number = undefined,
    valueTransformerFunc: (value) => any = value => value,
  ) {
    e.rule.message = this.translate.instant(
      'messages.validation.range-validation-error',
    );

    const fromEditorInstance = this.getEditorInstance(
      editorType,
      document.getElementById(fromEditorId),
    );
    let fromValue: any = valueTransformerFunc(
      fromEditorInstance?.option('value'),
    );
    const fromValidator = this.getValidatorInstance(fromEditorId);

    if (fromValue == null) {
      const toValidator = this.getValidatorInstance(toEditorId);
      toValidator?.option('isValid', true);

      if (!fromValidator?.option('isValid')) {
        fromValidator?.validate();
      }
      return true;
    }

    let toValue = valueTransformerFunc(e.value);

    if (toValue == null && nullableTo) {
      return true;
    }

    if (editorType === 'dxDateBox') {
      if (isValidDate(toValue)) {
        toValue = new Date(toValue);
      }
      if (isValidDate(fromValue)) {
        fromValue = new Date(fromValue);
      }
    }

    if (toValue == null && fromValue != null) {
      return false;
    }

    if (toValue < fromValue) {
      return false;
    } else {
      if (maxDifference) {
        const differenceValid = this.validateDifference(
          fromValue,
          toValue,
          maxDifference,
          editorType,
        );

        if (!differenceValid) {
          e.rule.message = this.translate.instant(
            'messages.validation.difference-not-more-than',
            {difference: maxDifference},
          );
          return differenceValid;
        }
      }

      if (!fromValidator.option('isValid')) {
        fromValidator.validate();
      }

      return true;
    }
  }

  /**
   * Returns the common editor options for a given value type.
   * @param {string} valueType - value type (e.g. String, Integer ...).
   * @param {any} value - the value of the editor.
   * @param {any} defaultValue - the default value to use when the value is not available.
   * @param {any[]} choiceItems - (optional) the items of the select box if the value type is SingleChoice or MultiChoice.
   * @returns { EditorOptions} the common editor options.
   */
  getEditorOptions = (
    valueType: string,
    value: any,
    defaultValue: any,
    choiceItems: {id: any; name: any}[] = [],
  ): EditorOptions => {
    let options: {};
    switch (valueType) {
      case 'String':
        options = {showClearButton: true, value: value ?? defaultValue};
        break;
      case 'Integer':
        options = {
          format: 'fixedPoint',
          showClearButton: true,
          value: value ?? defaultValue,
        };
        break;
      case 'Float':
        options = {
          format: 'decimal',
          showClearButton: true,
          value: value ?? defaultValue,
        };
        break;
      case 'SingleChoice':
        options = {
          items: choiceItems ?? [],
          displayExpr: 'name',
          valueExpr: 'id',
          value: value ?? defaultValue,
        };
        break;
      case 'GenericList':
        options = {
          items: choiceItems ?? [],
          displayExpr: 'name',
          valueExpr: 'id',
          value: value ?? defaultValue,
          showClearButton: true,
        };
        break;
      case 'MultiChoice':
        options = {
          items: choiceItems ?? [],
          showDropDownButton: true,
          searchEnabled: true,
          searchExpr: 'name',
          displayExpr: 'name',
          valueExpr: 'id',
          value: value ?? defaultValue,
        };
        break;
      case 'ObjectProperties':
      case 'ObjectExtendedProperties':
        options = {
          items: choiceItems ?? [],
          displayExpr: 'name',
          valueExpr: 'id',
          value: value ?? defaultValue,
          showClearButton: true,
        };
        break;
      case 'DateTime':
        options = {
          showClearButton: true,
          type: 'datetime',
          value: value ?? defaultValue,
          displayFormat: formatDateTime(value ?? defaultValue, 'L LT'),
        };
        break;
      case 'Date':
        options = {
          showClearButton: true,
          type: 'date',
          value: value ?? defaultValue,
        };
        break;
      case 'Time':
        options = {
          showClearButton: true,
          type: 'time',
          value: value ?? defaultValue,
        };
        break;
      case 'Duration':
        options = {
          placeholder: '1d 12h 34m 2s',
          showClearButton: true,
          value: value ?? defaultValue,
        };
        break;
      case 'FileSize':
        options = {
          format: 'fixedPoint',
          showClearButton: true,
          value:
            value || defaultValue
              ? FileUtils.fileSizeToHumanReadableFileSize(value ?? defaultValue)
              : null,
        };
        break;
      case 'Color':
        options = {
          applyValueMode: 'instantly',
          editAlphaChannel: true,
          showClearButton: true,
          value: value ?? defaultValue,
        };
        break;
      default:
        options = {value: value ?? defaultValue};
    }

    return options;
  };

  /**
   * Returns the validation rules for a provided object.
   * @returns {any[]} the validation rules.
   */
  getValidationRules = <
    T extends {validationExpression?: string; required?: boolean; name: string},
  >(
    object: T,
    valueType: string,
    customEvaluationCallback: (options: any) => boolean = undefined,
    label?: string,
  ) => {
    let validationRules = [];

    if (!object) {
      return validationRules;
    }

    if (object?.validationExpression) {
      let validationMessage = '';
      if (label) {
        validationMessage += `${label}: `;
      }
      validationMessage += this.translate.instant(
        'messages.validation.expression-evaluation-message',
        {
          expression: object.validationExpression,
        },
      );
      validationRules.push({
        type: 'custom',
        message: validationMessage,
        validationCallback: cbOptions => {
          if (customEvaluationCallback) {
            return customEvaluationCallback(cbOptions);
          }

          // add convert function to the context
          if (valueType === 'FileSize') {
            cbOptions.value = cbOptions.value
              ? FileUtils.humanReadableFileSizeToBytes(cbOptions.value)
              : null;
          }

          return this.defaultValidationExpCB(
            cbOptions,
            object,
            this.expressionEvalutaionService,
          );
        },
      });
    }

    if (object?.required && valueType !== 'Boolean') {
      const rule = {
        type: 'required',
      };

      if (label) {
        rule['message'] = this.translate.instant(
          `messages.validation.${label}-invalid`,
        );
      }
      validationRules.push(rule);
    }

    if (valueType === 'FileSize') {
      validationRules.push({
        type: 'pattern',
        pattern: '^(([0-9]+(\\.[0-9]+)*)?) (KB|MB|B)$',
        message:
          this.translate.instant(`setting.${object.name}`) +
          ': ' +
          this.translate.instant('messages.settings.validation.file-size'),
      });
    }

    return validationRules;
  };

  /**
   * A devextreme validation callback for evaluating validation expression.
   * @param {any} cbOptions - devextreme's option parameter of callback function.
   * @param {any} object - the object which contains the information required for the validation.
   * @param {ExpressionEvalutaionService} expressionEvalutaionService - the service instance which will do the evaluation
   * @returns {boolean} - evaluation result.
   */
  private defaultValidationExpCB = <
    T extends {validationExpression?: string; required?: boolean},
  >(
    cbOptions: {value: any},
    object: T,
    expressionEvalutaionService: ExpressionEvalutaionService,
  ): boolean => {
    const evaluationResult = expressionEvalutaionService.evaluateExpression(
      object.validationExpression,
      {value: cbOptions.value},
    );

    return typeof evaluationResult === 'boolean' ? evaluationResult : false;
  };

  private validateDifference(
    fromValue: any,
    toValue: any,
    maxDifference: number,
    editorName: 'dxDateBox' | 'dxNumberBox' | 'dxTextBox',
  ) {
    let differenceValid: boolean = true;
    if (editorName === 'dxDateBox') {
      const diff = moment(toValue).diff(fromValue, 'days', true);
      differenceValid = diff <= maxDifference;
    } else {
      const diff = toValue - fromValue;
      differenceValid = diff <= maxDifference;
    }
    return differenceValid;
  }
}

export type DevexEditorTypes =
  | 'dxAutocomplete'
  | 'dxCalendar'
  | 'dxCheckBox'
  | 'dxColorBox'
  | 'dxDateBox'
  | 'dxDropDownBox'
  | 'dxHtmlEditor'
  | 'dxLookup'
  | 'dxNumberBox'
  | 'dxRadioGroup'
  | 'dxRangeSlider'
  | 'dxSelectBox'
  | 'dxSlider'
  | 'dxSwitch'
  | 'dxTagBox'
  | 'dxTextArea'
  | 'dxTextBox'
  | 'dxFileUploader'
  | 'dxButton'
  | 'dxToolbar'
  | 'dxButtonGroup'
  | 'dxForm'
  | 'dxDataGrid'
  | 'dxValidationGroup'
  | 'dxDropDownButton';

export type EditorOptions =
  | TextBoxProperties
  | NumberBoxProperties
  | DateProperties
  | TextAreaProperties
  | TagBoxProperties
  | SelectBoxProperties
  | FileUploaderProperties
  | RadioGroupProperties
  | ButtonProperties
  | CheckBoxProperties
  | ColorBoxProperties
  | FormProperties;
