import {IVisitDataReportRequest} from '@retrixhouse/salesapp-shared/lib/requests';
import * as moment from 'moment/moment';

export class VisitsReportRequest implements IVisitDataReportRequest {
  projectId: string;
  todoListId: string;
  todoListItemIds: string[];
  from: Date;
  to: Date;
  executors?: string[];
  chainIds?: string[];
  storeIds?: string[];
  productCategoryIds?: string[];
  productIds?: string[];
  showEmptyAnswerColumns?: boolean;
  showEmptyVisitsInReport?: boolean;
  fetchProductListings?: boolean;
  productPropNames?: string[];

  constructor() {
    this.todoListItemIds = [];
    this.executors = [];
    this.chainIds = [];
    this.storeIds = [];
    this.productCategoryIds = [];
    this.productIds = [];
    this.showEmptyAnswerColumns = false;
    this.showEmptyVisitsInReport = false;
    this.fetchProductListings = false;

    this.from = moment(new Date())
      .startOf('month')
      .startOf('day')
      .toDate();
    this.to = moment(new Date()).endOf('month').endOf('day').toDate();
  }
}
