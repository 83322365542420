import {DIALOG_DATA, DialogRef} from '@angular/cdk/dialog';
import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {ButtonType} from 'devextreme/common';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmDialogComponent {
  get actionButtonType(): ButtonType | 'warning' {
    return this.data?.acceptButtonType || 'default';
  }

  get rejectButtonType() {
    return this.data?.rejectButtonType || 'default';
  }

  get title() {
    return this.data?.title;
  }

  get dialogTitle() {
    return this.data.dialogTitle;
  }

  get description() {
    return this.data?.description?.replace('@@', '\n');
  }

  get options() {
    return this.data;
  }

  get borderTopColor() {
    return colorMap[this.data.type];
  }

  constructor(
    private dialogRef: DialogRef<string>,
    @Inject(DIALOG_DATA) private data: ConfirmDialogOptions,
  ) {}

  onReject() {
    this.dialogRef.close('rejected');
  }

  onAccept() {
    this.dialogRef.close('accepted');
  }

  onClose() {
    this.dialogRef.close('closed');
  }
}

export interface ConfirmDialogOptions {
  acceptLabel: string;
  acceptIcon?: string;
  acceptButtonType?: ButtonType | 'warning';
  rejectLabel: string;
  rejectIcon?: string;
  rejectButtonType?: ButtonType;
  type: 'warning' | 'danger' | 'confirm';
  showCloseButton?: boolean;
  title: string;
  dialogTitle?: string;
  description?: string;
}

export type ConfirmDialogResponse = 'accepted' | 'rejected' | 'closed';

const colorMap = {
  ['warning']: '#ffc107',
  ['danger']: '#f44336',
  ['confirm']: '#cddc39',
};
