import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {DxTagBoxModule} from 'devextreme-angular';
import {FormFieldModule} from '../form-field';
import {InputTagsComponent} from './input-tags.component';

const DX_MODULES = [DxTagBoxModule];
const UI_MODULES = [FormFieldModule];

@NgModule({
  imports: [CommonModule, ...DX_MODULES, ...UI_MODULES],
  declarations: [InputTagsComponent],
  exports: [InputTagsComponent],
})
export class InputTagsModule {}

export * from './input-tags.component';
