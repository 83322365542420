<div class="header">
  <dx-button stylingMode="text"
             type="normal"
             [text]="'buttons.back' | translate"
             icon="fa-solid fa-chevron-left"
             (onClick)="backClicked.emit()">
  </dx-button>

  <app-input-text [value]="search$ | async"
                  [placeholder]="'placeholders.search' | translate"
                  (valueChange)="onSearchChange($event)"></app-input-text>
</div>

<ng-container *ngIf="loading">
  <app-loading></app-loading>
</ng-container>

<ng-container *ngIf="filteredLegend$ | async; let legend">
  <div *ngIf="!loading"
       class="container">
    <ng-container *ngFor="let descriptor of legend | keyvalue; trackBy: trackByFn">
      <ng-container *ngTemplateOutlet="wrapperTemplate; context: {
            descriptor: descriptor.value
        }"></ng-container>
    </ng-container>
  </div>
</ng-container>

<ng-template #wrapperTemplate
             let-descriptor="descriptor">
  <div class="property">
    <span class="property-name">{{ descriptor?.propertyNameTranslation }}</span>

    <app-notice *ngIf="descriptor.property.valueType === ValueType.SingleChoice"
                type="info"
                [description]="'entity.attachment-template.legend.wrapper.single-choice.description' | translate"></app-notice>
    <app-notice *ngIf="descriptor.property.valueType === ValueType.MultiChoice"
                type="warning"
                [description]="'entity.attachment-template.legend.wrapper.multi-choice.description' | translate"></app-notice>
    <app-notice *ngIf="[ValueType.Date, ValueType.DateTime].includes(descriptor.property.valueType)"
                type="info"
                [description]="'entity.attachment-template.legend.wrapper.time.description' | translate"></app-notice>

    <span class="property-wrapper">
      <ng-container [ngSwitch]="descriptor.property.valueType">
        <div *ngSwitchCase="ValueType.RelationToOneData">
          <ng-container *ngFor="let descriptor of descriptor.relation | keyvalue; trackBy: trackByFn">
            <ng-container *ngTemplateOutlet="wrapperTemplate; context: {
                        descriptor: descriptor.value
                    }"></ng-container>
          </ng-container>
        </div>
        <div *ngSwitchDefault>
          <div *ngIf="descriptor.paths?.length; else path"
               class="property-wrapper-paths">
            <div *ngFor="let path of descriptor.paths"
                 class="property-wrapper-path">
              <span class="property-wrapper-path-key">
                {{ 'wrapper.' + paramCase(path.key) | translate }}:
              </span>
              <app-copy-text textToCopy="{{ '{' + path.path + '}'}}"></app-copy-text>
            </div>
          </div>


          <ng-template #path>
            <div class="property-wrapper-paths">
              <div class="property-wrapper-path">
                <app-copy-text textToCopy="{{ '{' + descriptor.path + '}'}}"></app-copy-text>
              </div>
            </div>
          </ng-template>
        </div>
      </ng-container>
    </span>
  </div>
</ng-template>