import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {IMobileDeviceDataService} from '../../interfaces/data-service';
import {MobileDevice, RegisterDeviceRequest} from '../../models';
import {BaseReadonlyHttpService} from './base.http-service';

@Injectable()
export class MobileDeviceHttpService
  extends BaseReadonlyHttpService<MobileDevice>
  implements IMobileDeviceDataService
{
  constructor(http: HttpClient) {
    super(http, '/sys/mobile-devices');
  }

  public register(request: RegisterDeviceRequest): Promise<MobileDevice> {
    return this.POST<MobileDevice>('/sys/mobile-devices/register', request);
  }
}
