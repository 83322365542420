import {Injectable} from '@angular/core';
import {GenericListItem} from '../../models';
import {IGenericListItemDataService} from '../../interfaces/data-service';
import {BaseCrudOfflineService} from './base.offline-service';

@Injectable()
export class GenericListItemOfflineService
  extends BaseCrudOfflineService<GenericListItem>
  implements IGenericListItemDataService
{
  public updateItemPositions(
    positions: {id: string; position: number}[],
  ): Promise<void> {
    return Promise.reject('Offline service - not yet implemented');
  }
  public getListAll(): Promise<GenericListItem[]> {
    return Promise.reject('Offline service - not yet implemented');
  }
}
