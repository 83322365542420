<div class="picture-item">
  <div class="form-avatar">
    <img [src]="imageUrl" />
  </div>
  <div class="upload-content">
    <ng-container *visibleIf="{
      platform: ['browser'],
    }">
      <dx-file-uploader #fileUploader
                        [visible]="true"
                        width="100%"
                        labelText=""
                        selectButtonText="{{ 'buttons.change' | translate }}"
                        [uploadHeaders]="headers$ | async"
                        [uploadUrl]="uploadUrl"
                        [multiple]="false"
                        [disabled]="disabled"
                        accept="image/*"
                        uploadMode="instantly"
                        (onUploaded)="onPhotoUploaded($event)">
      </dx-file-uploader>
    </ng-container>

    <ng-container *visibleIf="{
      platform: ['app'],
      clientMode: 'online'
    }">
      <dx-button stylingMode="text"
                 icon="fa-solid fa-circle-camera"
                 text=""
                 [disabled]="disabled"
                 (onClick)="capturePhoto()"></dx-button>
      <span>/</span>
      <dx-button stylingMode="text"
                 icon="fa-solid fa-images"
                 [disabled]="disabled"
                 text=""
                 (onClick)="selectFromGallery()"></dx-button>
    </ng-container>
  </div>
</div>