import {ObjectMapCache} from '@retrixhouse/salesapp-shared/lib/caching';
import {IUnitDataService} from '../../interfaces/data-service';
import {Unit} from '../../models';
import {UnitHttpService} from '../http';
import {UnitOfflineService} from '../offline';
import {BaseCrudDataService} from './base.data-service';

export class UnitDataService extends BaseCrudDataService<
  Unit,
  IUnitDataService,
  ObjectMapCache<string, Unit>
> {
  constructor(
    onlineService: UnitHttpService,
    offlineService: UnitOfflineService,
    cache: ObjectMapCache<string, Unit>,
  ) {
    super(onlineService, offlineService, cache);
  }
}
