import {ChangeDetectorRef, Component, Input} from '@angular/core';
import {ControlValueAccessor} from '@salesapp/utils/reactive-form/control-value-accessor';
import {FormControlComponent} from '@salesapp/utils/reactive-form/form-control.component';
import {createFormControlProvider} from '@salesapp/utils/reactive-form/form.utils';

@Component({
  selector: 'app-input-text-area',
  templateUrl: './input-text-area.component.html',
  styleUrls: ['./input-text-area.component.scss'],
  providers: [createFormControlProvider(() => InputTextAreaComponent)],
})
@ControlValueAccessor()
export class InputTextAreaComponent extends FormControlComponent<string> {
  @Input() height?: number;
  @Input() autoResizeEnabled?: boolean;

  constructor(private changeDetector: ChangeDetectorRef) {
    super(changeDetector);
  }

  onValueChange(event) {
    this.valueChange.emit(event);
  }
}
