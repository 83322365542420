import {ChangeDetectionStrategy, Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Action, Actions} from '@salesapp/domain';
import {
  ActionsService,
  AppInfoService,
  AuthGuardService,
} from '@salesapp/services';
import {ActionsBuilderService} from '@salesapp/shared/services/actions-builder.service';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-admin-utility-menu',
  templateUrl: './admin-utility-menu.component.html',
  styleUrls: ['./admin-utility-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminUtilityMenuComponent {
  actions$: Observable<Action[]>;

  get isAdmin() {
    return this.authService.isAdmin();
  }

  get isMobileVersion() {
    return this.appInfoService.isMobileVersion;
  }

  constructor(
    private authService: AuthGuardService,
    private actionsService: ActionsService,
    private actionsBuilderService: ActionsBuilderService,
    private appInfoService: AppInfoService,
  ) {
    this.actions$ = this.actionsBuilderService.init([
      Actions.TRANSLATION_CREATE,
      Actions.CLIENT_SIDE_ERROR_CONF_CREATE,
    ]);
  }

  onItemClick(item: Action) {
    switch (item.id) {
      case Actions.TRANSLATION_CREATE.id:
        this.actionsService.createTranslation();
        break;
      case Actions.CLIENT_SIDE_ERROR_CONF_CREATE.id:
        this.actionsService.createClientSideErrorConf();
        break;
    }
  }
}
