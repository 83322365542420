import {Injectable} from '@angular/core';
import {
  AppInterfaceService,
  AppPlatform,
} from '../app-interface/app-interface.service';
import {LocationResponseResult} from '../app-interface/response-model';

@Injectable()
export class LocationService {
  constructor(private appInterfaceService: AppInterfaceService) {}

  public calculateSquareArea(
    latitude: number,
    longitude: number,
    distance: number,
  ): {latNorth: number; latSouth: number; longWest: number; longEast: number} {
    const RAD_CON = Math.PI / 180; // Radian conversion constant
    const R_EARTH = 6378; // Radius of the earth in Km

    const METER_DEGREES = 1 / (((2 * Math.PI) / 360) * R_EARTH) / 1000; // Meters per degree lat/lng

    const meters = distance * 1000;

    return {
      latNorth: latitude + meters * METER_DEGREES,
      latSouth: latitude - meters * METER_DEGREES,
      longWest:
        longitude + (meters * METER_DEGREES) / Math.cos(latitude * RAD_CON),
      longEast:
        longitude - (meters * METER_DEGREES) / Math.cos(latitude * RAD_CON),
    };
  }

  public async getLocation(): Promise<LocationResponseResult> {
    if (
      this.appInterfaceService.platform === AppPlatform.AndroidApp ||
      this.appInterfaceService.platform === AppPlatform.IosApp
    ) {
      return this.appInterfaceService.locationGet();
    }

    if (window.navigator.geolocation) {
      const position = await new Promise((res, err) =>
        window.navigator.geolocation.getCurrentPosition(res, err),
      );

      return Promise.resolve({
        latitude: position['coords']?.latitude,
        longitude: position['coords']?.longitude,
        altitude: position['coords']?.altitude,
        accuracy: position['coords']?.accuracy,
      });
    }

    return Promise.resolve({});
  }
}
