import {
  GenericList,
  GenericListItem,
  TodoAction,
  TodoActionWrapper,
  TodoListItem,
  TodoListWrapper,
} from '../models';
import {TreeBase} from './tree-base';

/**
 * Helper class providing operations on the tree of TODO list items.
 */
export class TodoListItemTree extends TreeBase<TodoListItem> {
  private _actionsMap: Map<string, TodoAction>;
  private _allActions: TodoAction[];

  /**
   * Constructor.
   * @param {TodoListItem[]} items - array of TODO list items
   * @param {TodoAction[]} actions - array of TODO actions
   */
  constructor(items: TodoListItem[], actions: TodoAction[]) {
    super(items);

    items = items.map(item => {
      item.questionNumber = this.getNodeNumberFromTree(item);
      return item;
    });
    this._allActions = actions;
    this._actionsMap = new Map<string, TodoAction>(actions.map(a => [a.id, a]));
  }

  /**
   * Gets array of all items.
   */
  public get allActions(): TodoAction[] {
    return this._allActions;
  }

  /**
   * Finds particular action by id.
   * @param {string} id - id of the action to find
   * @returns {TodoAction | undefined} action or undefined if not found
   */
  public findAction(id: string): TodoAction | undefined {
    return this._actionsMap.get(id);
  }

  /**
   * Finds action by TODO list item id.
   * @param {string} id - id of the TODO list item to find action for
   */
  public findActionByItem(id: string): TodoAction | undefined {
    const item = this.findItem(id);
    if (item) {
      return this.findAction(item.actionId);
    }
  }

  /**
   * Finds TODO list item by action id.
   * @param {string} id - id of the action to find TODO list item for
   */
  public findItemByAction(id: string): TodoListItem | undefined {
    const action = this.findAction(id);
    if (action) {
      return this.allItems.find(i => i.actionId == action.id);
    }
  }

  /**
   * Creates a TodoListItemId to TodoActionWrapper Map.
   * @param {GenericList[]} reasonList - reason list of all todo actiom items.
   * @returns {Map<string, TodoActionWrapper>} TodoListItemId id to TodoActionWrapper map.
   */

  createTodoActionWrapperMap(
    reasonList: GenericList[],
  ): Map<string, TodoActionWrapper> {
    let questionWrappersMap = new Map<string, TodoActionWrapper>();
    this.allItems.forEach(item => {
      const action = this.allActions.find(act => act.id === item.actionId);
      questionWrappersMap.set(
        item.id,
        new TodoActionWrapper(
          action,
          item,
          reasonList.find(reason => reason.id === item.reasonListId)?.items ??
            [],
        ),
      );
    });

    questionWrappersMap.forEach(todoActionWrapper => {
      const todoListItem = this.findItem(todoActionWrapper.id);

      const childWrappers = [...questionWrappersMap.values()].filter(
        wrapper => wrapper.parentId === todoListItem.id,
      );
      todoActionWrapper.subActions = childWrappers;

      if (!todoListItem.parentId) {
        todoActionWrapper.parent = null;
      } else {
        const parentItem = this.findItem(todoListItem.parentId);
        todoActionWrapper.parent = questionWrappersMap.get(parentItem.id);
      }
    });

    return questionWrappersMap;
  }
}
