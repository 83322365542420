/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AttachmentTemplate } from '../models/attachment-template';
import { create } from '../fn/attachment-template/create';
import { Create$Params } from '../fn/attachment-template/create';
import { delete$ } from '../fn/attachment-template/delete';
import { Delete$Params } from '../fn/attachment-template/delete';
import { download } from '../fn/attachment-template/download';
import { Download$Params } from '../fn/attachment-template/download';
import { fetchAllForObjectType } from '../fn/attachment-template/fetch-all-for-object-type';
import { FetchAllForObjectType$Params } from '../fn/attachment-template/fetch-all-for-object-type';
import { update } from '../fn/attachment-template/update';
import { Update$Params } from '../fn/attachment-template/update';

@Injectable({ providedIn: 'root' })
export class AttachmentTemplateApiService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `attachmentTemplateControllerDownload()` */
  static readonly AttachmentTemplateControllerDownloadPath = '/core/attachment-templates/download/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `download()` instead.
   *
   * This method doesn't expect any request body.
   */
  download$Response(params: Download$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return download(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `download$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  download(params: Download$Params, context?: HttpContext): Observable<void> {
    return this.download$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `attachmentTemplateControllerDelete()` */
  static readonly AttachmentTemplateControllerDeletePath = '/core/attachment-templates/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `delete()` instead.
   *
   * This method doesn't expect any request body.
   */
  delete$Response(params: Delete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return delete$(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `delete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  delete(params: Delete$Params, context?: HttpContext): Observable<void> {
    return this.delete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `attachmentTemplateControllerUpdate()` */
  static readonly AttachmentTemplateControllerUpdatePath = '/core/attachment-templates/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `update()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  update$Response(params: Update$Params, context?: HttpContext): Observable<StrictHttpResponse<AttachmentTemplate>> {
    return update(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `update$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  update(params: Update$Params, context?: HttpContext): Observable<AttachmentTemplate> {
    return this.update$Response(params, context).pipe(
      map((r: StrictHttpResponse<AttachmentTemplate>): AttachmentTemplate => r.body)
    );
  }

  /** Path part for operation `attachmentTemplateControllerFetchAllForObjectType()` */
  static readonly AttachmentTemplateControllerFetchAllForObjectTypePath = '/core/attachment-templates/{objectTypeId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fetchAllForObjectType()` instead.
   *
   * This method doesn't expect any request body.
   */
  fetchAllForObjectType$Response(params: FetchAllForObjectType$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AttachmentTemplate>>> {
    return fetchAllForObjectType(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fetchAllForObjectType$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fetchAllForObjectType(params: FetchAllForObjectType$Params, context?: HttpContext): Observable<Array<AttachmentTemplate>> {
    return this.fetchAllForObjectType$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AttachmentTemplate>>): Array<AttachmentTemplate> => r.body)
    );
  }

  /** Path part for operation `attachmentTemplateControllerCreate()` */
  static readonly AttachmentTemplateControllerCreatePath = '/core/attachment-templates';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create$Response(params?: Create$Params, context?: HttpContext): Observable<StrictHttpResponse<AttachmentTemplate>> {
    return create(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `create$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create(params?: Create$Params, context?: HttpContext): Observable<AttachmentTemplate> {
    return this.create$Response(params, context).pipe(
      map((r: StrictHttpResponse<AttachmentTemplate>): AttachmentTemplate => r.body)
    );
  }

}
