import {IObjectReadDataService} from '../../interfaces/data-service';
import {ObjectReadHttpService} from '../http';
import {ObjectReadOfflineService} from '../offline';
import {BaseDataService} from './base.data-service';

export class ObjectReadDataService extends BaseDataService<IObjectReadDataService> {
  constructor(
    onlineService: ObjectReadHttpService,
    offlineService: ObjectReadOfflineService,
  ) {
    super(onlineService, offlineService);
  }

  public isRead(objectId: string): Promise<boolean> {
    return this.service.isObjectRead(objectId);
  }

  public areRead(
    objectIds: string | string[],
  ): Promise<{[objectId: string]: boolean}> {
    return this.service.areObjectsRead(objectIds);
  }

  public markAsRead(objectIds: string | string[]): Promise<void> {
    return this.service.markObjectAsRead(objectIds);
  }

  public markAsUnread(objectIds: string | string[]): Promise<void> {
    return this.service.markObjectAsUnread(objectIds);
  }
}
