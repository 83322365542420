<!-- TODO: remove unused attributes -->
<app-dialog [title]="(isUpdate ? 'entity.attachment-template.dialog.update.title' : 'entity.attachment-template.dialog.create.title') | translate"
            [actionInProgress]=""
            [actionLabel]="'form.action.save' | translate"
            [actionDisabled]=""
            [actionVisible]="true"
            [actionIcon]=""
            [cancelLabel]="'form.action.close' | translate"
            [cancelDisabled]=""
            [cancelVisible]="true"
            [cancelIcon]=""
            [formGroup]="form"
            (cancelClicked)="onClose()"
            (actionClicked)="onSave()">
  <ng-container [formGroup]="form.controls.template">
    <app-form-row>
      <app-form-column>
        <app-input-text [formControl]="getAttachmentTemplateFormControl('name')"
                        [formControlRef]="getAttachmentTemplateFormControl('name')"
                        [label]="'entity.attachment-template.properties.name.label' | translate"></app-input-text>
      </app-form-column>
    </app-form-row>
    <app-form-row *ngIf="!isUpdate">
      <app-form-column>
        <app-input-file [formControlRef]="getFormControl('fileData')"
                        [formControl]="getFormControl('fileData')"
                        [label]="'entity.attachment.properties.file-data.label' | translate"
                        [allowedFileTypes]="allowedFileTypes"
                        [initialFiles]="initialFiles"
                        (filesChange)="onFilesChange($event)"></app-input-file>
      </app-form-column>
    </app-form-row>
  </ng-container>
</app-dialog>