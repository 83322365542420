import { Injectable } from '@angular/core';
import { DialogService } from '@salesapp/dialog';
import {
  ProjectCopyDialogComponent,
  ProjectCopyDialogData
} from '../components/project-copy-dialog/project-copy-dialog.component';
import { Observable, of } from 'rxjs';
import { ProjectStorageService } from '@salesapp/storage';
import { filter, map } from 'rxjs/operators';

@Injectable()
export class ProjectService {
  constructor(
    private dialogService: DialogService,
    private projectStorageService: ProjectStorageService,
  ) { }

  copy(projectId: string) {
    this.dialogService.open<
      ProjectCopyDialogComponent,
      ProjectCopyDialogData
    >(ProjectCopyDialogComponent, {
      data: {
        sourceProjectId: projectId,
      },
      width: '850px',
      height: '600px',
    });
  }

  extRefExists(externalRef: string): Observable<boolean> {
    return this.projectStorageService.data$.pipe(
      filter(data => !!data),
      map(data => data.some(project => project.externalRef === externalRef)),
    );
  }

  nameExists(name: string): Observable<boolean> {
    return this.projectStorageService.data$.pipe(
      filter(data => !!data),
      map(data => data.some(project => project.name === name)),
    );
  }
}
