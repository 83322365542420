import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseCrudHttpService} from './base.http-service';
import {
  ProductListing,
  ProductListingStore,
  ProductListingStoreRequest,
  ProjectProductListing,
} from '../../models';
import {IProductListingDataService} from '../../interfaces/data-service';
import {Filter} from '@loopback/filter';
import {IProductListingCopy} from '@retrixhouse/salesapp-shared/lib/requests';
import {IProductListing} from '@retrixhouse/salesapp-shared/lib/models';
import {IProcessingProgress} from '@retrixhouse/salesapp-shared/lib/responses';

@Injectable()
export class ProductListingHttpService
  extends BaseCrudHttpService<ProductListing>
  implements IProductListingDataService
{
  constructor(http: HttpClient) {
    super(http, '/product-listing');
  }

  public getProductListingStoreList(filter: Filter<ProductListingStore>) {
    return this.GET<ProductListingStore[]>(
      `/product-listing-store`,
      filter ? {params: {filter: JSON.stringify(filter)}} : {},
    );
  }

  public updateProductListingStore(
    productListingStoreRequest: ProductListingStoreRequest,
  ) {
    return this.POST<void>(
      `/product-listing-store`,
      productListingStoreRequest,
    );
  }

  public getActiveProductListingsForProjects(
    projectIds: string[],
  ): Promise<ProjectProductListing[]> {
    return this.POST('/product-listing/projects', projectIds);
  }

  public copyListing(
    productListingCopyRequest: IProductListingCopy,
  ): Promise<{createdProductListing: IProductListing}> {
    return this.POST('/product-listing/copy', productListingCopyRequest);
  }

  public getCopyProgress(operationId: string) {
    return this.POST<IProcessingProgress>(`/product-listing/copy/progress`, {
      operationId,
    });
  }

  public getCopyAvailability() {
    return this.GET<undefined>(`/product-listing/copy/available`);
  }
}
