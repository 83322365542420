import {HttpErrorResponse} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {resizeWorksheetCells} from '@retrixhouse/salesapp-shared/lib/utils/excel';
import {exportDataGrid} from 'devextreme/excel_exporter';
import DxDataGrid, {Column, ExportingEvent} from 'devextreme/ui/data_grid';
import {Workbook} from 'exceljs';
import {saveAs} from 'file-saver';
import {orderBy} from 'lodash';
import {Project} from '@salesapp/shared/models';
import * as moment from 'moment';

const UID_REGEXP =
  /^(?<prefix>[A-Z]{2})(?<sequenceNumber>\d+)(?<suffix>[A-Z]{2})$/;

export enum UserStorageKeys {
  DashboardLayout = 'dashboard-layout',
  FilterViewMyTourPlans = 'ui-filter-view-my-tour-plans',
  ProductCategoriesCustomer = 'ui-filter-view-product-categories',
  FilterViewProducts = 'ui-filter-view-products',
  FilterViewProjects = 'ui-filter-view-projects',
  FilterViewPromoActions = 'ui-filter-view-promo-actions',
  FilterViewStores = 'ui-filter-view-stores',
  FilterViewTourPlanExchanges = 'ui-filter-view-tour-plan-exchanges',
  FilterViewTourPlans = 'ui-filter-view-tour-plans',
  GlobalSearchHistory = 'global-search-history',
  LastLoginTimestamp = 'last-login-timestamp',
  LastLogoutTimestamp = 'last-logout-timestamp',
  NavigationMenu = 'ui-navigation-menu',
  NewsPostInvalidSwitch = 'ui-news-posts-invalid-switch',
  TabVewMyTourPlans = 'ui-tab-view-my-tour-plans',
  TabVewTourPlans = 'ui-tab-view-tour-plans',
  TourPlanQuickCreateStoreRoute = 'tour-plan-quick-create-store-route',
  DataGridBillingInfo = 'ui-data-grid-billing-info',
  DataGridCentralPhotoGallery = 'ui-data-grid-central-photo-gallery',
  DataGridCurrencies = 'ui-data-grid-currencies',
  DataGridCustomers = 'ui-data-grid-customers',
  DataGridDataTransferDumps = 'ui-data-grid-data-transfer-dumps',
  DataGridGenericListItems = 'ui-data-grid-generic-list-items',
  DataGridGenericLists = 'ui-data-grid-generic-lists',
  DataGridHistory = 'ui-data-grid-history',
  DataGridHoliday = 'ui-data-grid-holiday',
  DataGridChains = 'ui-data-grid-chains',
  DataGridKpi = 'ui-data-grid-kpi',
  DataGridKpiSet = 'ui-data-grid-kpi-set',
  DataGridMyFeedback = 'ui-data-grid-my-feedback',
  DataGridMyTourPlans = 'ui-data-grid-my-tour-plans',
  NewsPosts = 'ui-data-grid-news-posts',
  DataGridNotificationTemplates = 'ui-data-grid-notification-templates',
  DataGridNewsPosts = 'ui-data-grid-news-post',
  DataGridObjectProperties = 'ui-data-grid-object-properties',
  DataGridOrders = 'ui-data-grid-orders',
  DataGridPeriodicLimit = 'ui-data-grid-periodic-limit',
  DataGridPersonalArrangementReport = 'ui-data-grid-personal-arrangement-report',
  DataGridProductListing = 'ui-data-grid-product-listing',
  DataGridProducts = 'ui-data-grid-products',
  DataGridProjectPositionPermissions = 'ui-data-grid-project-position-permissions',
  DataGridProjectResponsibleUsers = 'ui-data-grid-project-responsible-users',
  DataGridProjects = 'ui-data-grid-projects',
  DataGridPromoActionReport = 'ui-data-grid-promo-action-report',
  DataGridPromoActions = 'ui-data-grid-promo-actions',
  DataGridQuestionnaireResult = 'ui-data-grid-questionnaire-result',
  DataGridQuestionnaires = 'ui-data-grid-questionnaires',
  DataGridQuestions = 'ui-data-grid-questions',
  DataGridRequestLogs = 'ui-data-grid-request-logs',
  DataGridRoles = 'ui-data-grid-roles',
  DataGridSamplings = 'ui-data-grid-samplings',
  DataGridScopes = 'ui-data-grid-scopes',
  DataGridStateTransition = 'ui-data-grid-state-transition',
  DataGridStoreActivityFeedback = 'ui-data-grid-store-activity-feedback',
  DataGridStores = 'ui-data-grid-stores',
  DataGridTodoActions = 'ui-data-grid-todo-actions',
  DataGridTodoLists = 'ui-data-grid-todo-lists',
  DataGridTourPlanExecutor = 'ui-data-grid-tour-plan-executor',
  DataGridTourPlanExchange = 'ui-data-grid-tour-plan-exchange',
  DataGridTourPlanMyRequestChange = 'ui-data-grid-tour-plan-my-request-change',
  DataGridTourPlanRequestChange = 'ui-data-grid-tour-plan-request-change',
  DataGridTourPlans = 'ui-data-grid-tour-plans',
  DataGridTourPlanVisitResult = 'ui-data-grid-tour-plan-visit-result',
  DataGridTranslations = 'ui-data-grid-translations',
  DataGridTrigger = 'ui-data-grid-trigger',
  DataGridUnitConversions = 'ui-data-grid-unit-conversions',
  DataGridUnits = 'ui-data-grid-units',
  DataGridUserProfiles = 'ui-data-grid-user-profiles',
  DataGridUsers = 'ui-data-grid-users',
  DataGridUserTask = 'ui-data-grid-user-task',
  DataGridVisitDataReport = 'ui-data-grid-visit-data-report',
  DataGridUnpivotediVisitDataReport = 'ui-data-grid-unpivoted-visit-data-report',
  PivotGridVisitDataReport = 'ui-pivot-grid-visit-data-report',
  FilterViewStoreActivity = 'ui-filter-view-store-activity',
  FilterViewUserTask = 'ui-filter-view-user-task',
  ListColumnsOrder = 'ui-list-columns-order-{template-name}',
  SwitchMatrixMode = 'ui-switch-matrix-mode',
  TreeListTags = 'ui-tree-list-tags',
  DataGridVisitDataReportProductProps = 'ui-data-grid-visit-data-report-product-props-{project-id}',
  TileProjectOverviewSettings = 'tile-project-overview-settings',
  ReportVisitDataView = 'report-visit-data-view',
  FilterViewPersonalArrangementReport = 'ui-filter-personal-arrangement-report',
  FilterViewVisitDataReport = 'ui-filter-visit-data-report',
  FilterViewPromoActionReport = 'ui-filter-promo-action-report',
  FilterViewCentralPhotoGallery = 'ui-filter-central-photo-gallery'
}

export enum GenericListNames {
  LegalForm = 'legal-form',
  ContractType = 'contract-type',
  ContractStatus = 'contract-status',
  TriggerEvent = 'trigger-event',
}

export enum GenericListIds {
  LegalForm = '73afe5e0-ef54-4877-ad81-c9bb1f91dc83',
  ContractType = 'acbc9f43-aa40-45d2-b495-bd9eb293311b',
  ContractStatus = '174e0e8f-46ca-458b-840c-4b7bdfacf787',
  ProductType = '9e3006a2-d62e-4f5f-921e-aecb78b6d8b7',
  StoreSubChain = '66d4160f-cccc-46b6-84dc-176841071ca4',
  StoreSegment = 'f0f62fae-1141-45ab-b8ff-ac19dfe2bf4e',
  StoreAgencyRegion = '5ea6e43f-b503-4c51-8245-67997d4f7102',
  StoreType = 'ca365255-2c8f-499a-8365-6dfc004a7169',
  StoreSize = '6e453042-418d-43a0-976e-055ba8682d04',
  DataStorageFileType = '68a3ab72-4574-46ff-98fa-40d0aeb56e32',
  ProjectType = 'e9baf9a1-79e7-43d8-a70d-25aea39875ba',
  ProjectCategory = '36bab501-f6ff-4eed-9a4f-f7ab45eea237',
  TourPlanType = '90d22238-3ea9-49b9-84c2-9e24d3d4162a',
  TourPlanExecutorResourceType = 'd04b800e-7b28-4648-9302-e424afa810b0',
  TriggerEvent = '75334995-18ef-4695-b867-1a30b244b72b',
  TourPlanActionResultReason = '8cc7e726-18cd-47e5-8c30-bcc7f91cfc77',
  PersonalArrangementDisplay = 'fa232b4d-127e-451a-8f53-9fc2ba8cdfa1',
  PersonalArrangemenDrive = '160fb71c-d5a2-4735-916e-34ae6eefe5e8',
  PersonalArrangementPosition = '81b8697c-1066-497a-b1b5-eff0bfac1354',
  PromoActionType = 'a0851cb9-7e79-4e0e-b8e4-d95b508e5a91',
  TourPlanChangeRequest = '37f78731-7d2f-47ff-8bc4-5968f5a9ec2c',
  AllowedBarCodeTypes = '9bb7abc9-6cf5-4d1e-b7b3-3ba66add84a5',
}

export enum ObjectTypePrefixes {
  Customer = 'CU',
  User = 'US',
  UserProfile = 'UP',
}

export enum ObjectTypeNames {
  AddressWithRelations = 'address-with-relations',
  Address = 'address',
  Country = 'country',
  Currency = 'currency',
  Customer = 'customer',
  Comment = 'comment',
  ExchangeRate = 'exchange-rate',
  Language = 'language',
  GenericList = 'generic-list',
  Position = 'position',
  Role = 'role',
  Unit = 'unit',
  UnitConversion = 'unit-conversion',
  User = 'user',
  UserProfile = 'user-profile',
  Product = 'product',
  Chain = 'chain',
  Store = 'store',
  Question = 'question',
  Questionnaire = 'questionnaire',
  QuestionnaireItem = 'questionnaire-item',
  NewsPost = 'news-post',
  TodoAction = 'todo-action',
  TodoList = 'todo-list',
  Project = 'project',
  ProductListing = 'product-listing',
  ProductCategory = 'product-category',
  Order = 'order',
  NotificationTemplate = 'notification-template',
  TourPlan = 'tour-plan',
  Tag = 'tag',
  Scope = 'scope',
  DataStorageFolder = 'data-storage-folder',
  ObjectProperty = 'object-property',
  LocalizedIdentifier = 'localized-identifier',
  PeriodicLimit = 'periodic-limit',
  Holiday = 'holiday',
  KPI = 'KPI',
  Trigger = 'trigger',
  PromoAction = 'promo-action',
  UserTask = 'user-task',
}

export enum ObjectTypeIds {
  Address = '050ac00b-3820-412e-b01c-d94cdd559ca7',
  Country = '7a7c7654-f697-4f0a-bfd1-7ded932d65ed',
  Currency = '47af6d32-ba3b-4c64-a0b4-a9f352e56bce',
  Customer = '0284ea84-722d-4397-a575-606df268bc9c',
  ExchangeRate = '02ae36b2-6981-454b-bfe9-b3350cc462d3',
  Language = '6058789a-d9e8-41f7-8062-d7c7962ed3af',
  Position = '6fa589b4-ba15-4ef4-b1ae-7a5df41cacfa',
  Role = '5b4c585d-063a-4a6b-92ea-b0d97941686b',
  Unit = '86ad082e-6501-441d-9ac8-ebe8149e5c3c',
  UnitConversion = 'b43bbb90-bfca-4aba-949c-7125b31e90e8',
  User = '11103ab9-e66c-4c28-a885-617fa92898c9',
  UserProfile = '68711585-022b-4974-bd19-723d7a8273a3',
  Product = '2130558a-1693-40cf-a34c-73b633acdeee',
  Chain = '3f2da679-0772-42df-8193-791403e5d16a',
  Store = 'fd370f22-cfa4-4066-9e58-1d8b89bd5dc8',
  Question = '313fda8d-5c22-49d0-b86c-7f7c326eb044',
  Questionnaire = 'f9f206e4-c0b1-4c63-be71-7e802cd3569e',
  NewsPost = '00301ad5-47ba-4e1a-bb82-71ae84ef9053',
  Project = '8c42e95b-79e2-432e-a4ca-26ff287199fa',
  TourPlan = '87b75884-9ccc-4bd3-9857-cc74b55362b7',
  Trigger = '04aab33e-9d28-44b3-aa0e-cbd268fc9f48',
  TourPlanChangeRequest = '2feda896-fbbd-4058-8277-3f18758943e3',
}

export enum OrderState {
  Submitted = 'Submitted',
  Approved = 'Approved',
  Rejected = 'Rejected',
  Delivered = 'Delivered',
  Expired = 'Expired',
  Canceled = 'Canceled',
}

export enum OrderType {
  Order = 'Order',
  Sampling = 'Sampling',
}

export enum SelectorMode {
  Multi = 'multi',
  Single = 'single',
}

export enum ClaimBreakModes {
  FromTo = 'c3b08bb7-d543-4abc-8292-dda6e093b9f6',
  Disabled = 'bb247808-ec8e-4c28-91c1-348cf5a625f3',
  Duration = '85895386-9dfb-4ef2-9fac-82ad681c382e',
}

export enum ClaimWorkModes {
  FromTo = '25063358-6e48-4588-bf72-99a050ad0f77',
  Disabled = '918bba29-fc51-4d17-8be0-f6a7d62d6597',
  Duration = '63830369-e20d-40ab-8e46-34af4abe5623',
}

export enum ProjectOverviewTileInterval {
  Yesterday = 'Yesterday',
  CurrentWeek = 'CurrentWeek',
  CurrentMonth = 'CurrentMonth',
  LastWeek = 'LastWeek',
  LastMonth = 'LastMonth',
}

export const DEFAULT_LIMIT = 10000;
export const DEFAULT_MAX_REPORT_DAYS = 32;

export const weekDays = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

export const htmlEditorToolbarConf: any[] = [
  'undo',
  'redo',
  'separator',
  {
    name: 'header',
    acceptedValues: [false, 1, 2, 3, 4, 5],
  },
  'separator',
  {
    name: 'size',
    acceptedValues: ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'],
  },
  {
    name: 'font',
    acceptedValues: [
      'Arial',
      'Courier New',
      'Georgia',
      'Impact',
      'Lucida Console',
      'Tahoma',
      'Times New Roman',
      'Verdana',
    ],
  },
  'separator',
  'bold',
  'italic',
  'strike',
  'underline',
  'separator',
  'color',
  'background',
  'separator',
  'alignLeft',
  'alignCenter',
  'alignRight',
  'alignJustify',
  'separator',
  'orderedList',
  'bulletList',
  'separator',
  'link',
];

export function exportToXlsxWithDefaultColumns(
  e: ExportingEvent,
  rowValueResolver: {
    fixedExecutorUid: (rowData: any) => string;
    fixedExecutor: (rowData: any) => string;
    fixedStoreUid: (rowData: any) => string;
    fixedStore: (rowData: any) => string;
    fixedStoreAgencyRegion: (rowData: any) => string;
    fixedTourPlanUid: (rowData: any) => string;
    fixedStoreChainSpecificId: (rowData: any) => string;
  },
  translate?: TranslateService,
  customizeCellHighlightColor?: (cell: any) => string,
) {
  const defaultExportColumns = [
    {
      dataField: 'fixedTourPlanUid',
      caption: translate.instant('visits.columns.uid'),
    },
    {
      dataField: 'fixedExecutorUid',
      caption: translate.instant('labels.user-uid'),
    },
    {
      dataField: 'fixedExecutor',
      caption: translate.instant('columns.executor'),
    },
    {
      dataField: 'fixedStoreUid',
      caption: translate.instant('columns.store-uid'),
    },
    {
      dataField: 'fixedStore',
      caption: translate.instant('columns.store'),
    },
    {
      dataField: 'fixedStoreChainSpecificId',
      caption: translate.instant('columns.chain-specific-id'),
    },
    {
      dataField: 'fixedStoreAgencyRegion',
      caption: translate.instant('columns.agency-region'),
    },
  ];

  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet('Main sheet');

  e.component.beginUpdate();

  defaultExportColumns.forEach((c, index) => {
    const dataField = c.dataField;
    e.component.addColumn({
      dataField: dataField,
      visibleIndex: index,
      caption: c.caption,
      calculateCellValue: (rowData: any) => {
        const valueResolveFn = rowValueResolver?.[dataField];
        if (valueResolveFn) {
          return rowValueResolver[dataField](rowData);
        }
      },
    });
  });

  exportDataGrid({
    component: e.component,
    worksheet: worksheet,
    customizeCell: function (options) {
      options.excelCell.font = {name: 'Arial', size: 12};
      options.excelCell.alignment = {horizontal: 'left'};

      if (customizeCellHighlightColor) {
        const highlightColor = customizeCellHighlightColor(options.gridCell.data);
        if (highlightColor) {
          options.excelCell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {argb: highlightColor.replace('#', '').toUpperCase()},
          };
        }
      }

      if (
        options.gridCell.rowType === 'data' &&
        options.gridCell.column.dataType === 'boolean'
      ) {
        options.excelCell.value = options.gridCell.value;
      }
    },
  }).then(function () {
    defaultExportColumns.forEach((c, index) => {
      e.component.deleteColumn(c.dataField);
    });

    resizeWorksheetCells(worksheet);

    e.component.endUpdate();

    workbook.xlsx.writeBuffer().then(function (buffer: BlobPart) {
      saveAs(
        new Blob([buffer], {type: 'application/octet-stream'}),
        'DataGrid.xlsx',
      );
    });
  });
}

export function customExportToXlsx(
  e: ExportingEvent,
  customCellTransform?: {[dataField: string]: (rowData: any) => string},
  extraExportColumns?: {
    dataField: string;
    caption: string;
    valueResolveFn: (rowData: any) => string;
  }[],
) {
  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet('Main sheet');

  e.component.beginUpdate();

  extraExportColumns?.forEach((c, index) => {
    e.component.addColumn({
      dataField: c.dataField,
      visibleIndex: index,
      caption: c.caption,
      calculateCellValue: (rowData: any) => {
        return c.valueResolveFn(rowData);
      },
    });
  });

  exportDataGrid({
    component: e.component,
    worksheet,
    customizeCell: ({gridCell, excelCell}) => {
      excelCell.font = {name: 'Arial', size: 12};
      excelCell.alignment = {horizontal: 'left'};

      if (
        gridCell.rowType === 'data' &&
        Object.keys(customCellTransform ?? []).includes(
          gridCell.column.dataField,
        )
      ) {
        excelCell.value = customCellTransform[gridCell.column.dataField](
          gridCell.data,
        );
      }
    },
  }).then(() => {
    extraExportColumns?.forEach(c => {
      e.component.deleteColumn(c.dataField);
    });

    resizeWorksheetCells(worksheet);
    e.component.endUpdate();
    workbook.xlsx.writeBuffer().then((buffer: BlobPart) => {
      saveAs(
        new Blob([buffer], {type: 'application/octet-stream'}),
        'DataGrid.xlsx',
      );
    });
  });
}

export function exportToXlsx(e: ExportingEvent | {component: DxDataGrid}) {
  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet('Main sheet');

  exportDataGrid({
    component: e.component,
    worksheet: worksheet,
    customizeCell: function (options) {
      options.excelCell.font = {name: 'Arial', size: 12};
      options.excelCell.alignment = {horizontal: 'left'};
    },
  }).then(function () {
    workbook.xlsx.writeBuffer().then(function (buffer: BlobPart) {
      saveAs(
        new Blob([buffer], {type: 'application/octet-stream'}),
        'DataGrid.xlsx',
      );
    });
  });
}

/**
 * Returns true if the search string includes all provided tokens or there are no tokens.
 * @param {string} str - string to search in
 * @param {string[]} tokens - tokens to search in the search string
 * @returns {boolean} true if the search string includes all provided tokens or there are no tokens; false otherwise
 */
export function includesAllTokens(str: string, tokens: string[]): boolean {
  for (const token of tokens) {
    if (!str.includes(token)) {
      return false;
    }
  }

  return true;
}

export function getItemProperty<E, T>(
  object: E,
  path: string,
  defaultValue?: T,
): T {
  const val = path.split('.').reduce((o, x) => {
    return typeof o === 'undefined' || o === null ? defaultValue : o[x];
  }, object);
  if (typeof val === 'undefined' || val === null) {
    return defaultValue;
  }
  return val;
}

export function setItemProperty<T extends {name: string}>(
  items: T[],
  name: string,
  property: string,
  value: any,
): T {
  const item = items.find(item => item.name === name);

  if (item) {
    item[property] = value;
  }
  return item;
}

export function sortByName<T extends {name: string}>(items: T[]): T[] {
  return (items ?? []).sort((a, b) => a.name.localeCompare(b.name));
}

export function sortByPosition<T extends {position?: number}>(items: T[]): T[] {
  return (items ?? []).sort((a, b) => (a?.position ?? 0) - (b?.position ?? 0));
}

export function sortByPositionThenName<
  T extends {position?: number; name?: string},
>(items: T[]) {
  return (items ?? []).sort((a, b) => {
    if (a?.position !== undefined && b?.position === undefined) {
      return 1;
    }

    if (a?.position === undefined && b?.position !== undefined) {
      return -1;
    }

    if (a?.position !== undefined && b?.position !== undefined) {
      const diff = a.position - b.position;
      if (diff !== 0) {
        return diff;
      }
    }

    if (a?.name !== undefined && b?.name !== undefined) {
      return a.name.localeCompare(b.name);
    }

    if (a?.name !== undefined && b?.name === undefined) {
      return 1;
    }

    if (a?.name === undefined && b?.name !== undefined) {
      return -1;
    }

    return 0;
  });
}

/**
 * Performs stable soerting on objects bz selected properties
 *
 * @param {T[]} items Objects to be sorted
 * @param {K[]} keys List of keys to be compared
 * @param {string[]} asc Holds the sort orders of objects
 * @returns
 */
export function sortByKeys<T, K extends keyof T>(
  items: T[],
  keys: K[],
  order: ('asc' | 'desc')[] = [],
) {
  if (order.length == 0) {
    return orderBy(items, keys, order);
  }
  return orderBy(items, keys);
}

export function sortByKey<T>(items: T[], key: keyof T, asc: boolean = true) {
  return items.sort((a, b) => {
    if (asc) {
      return a[key] && b[key]
        ? a[key].toString().localeCompare(b[key].toString())
        : 1;
    }
    return a[key] && b[key]
      ? b[key].toString().localeCompare(a[key].toString())
      : 1;
  });
}

/**
 * Sorts any given array by a given property.
 * @param {T[]} items Array to sort.
 * @param {string} propertyName Name of property to sort by. When not provided, array items are considered as date objects.
 * @param {boolean} asc If `true` ascending sort. Otherwise, descending sort.
 * @returns {T[]} The sorted array.
 */
export function sortByDate<T>(
  items: T[],
  propertyName: string = undefined,
  asc: boolean = true,
): T[] {
  return (items ?? []).sort((a, b) => {
    let aDate;
    let bDate;
    if (propertyName) {
      aDate = a[propertyName];
      bDate = b[propertyName];
    } else {
      aDate = a;
      bDate = b;
    }

    const ad = typeof aDate === 'string' ? new Date(aDate) : aDate;
    const bd = typeof bDate === 'string' ? new Date(bDate) : bDate;

    if (asc) {
      return ad.getTime() - bd.getTime();
    } else {
      return bd.getTime() - ad.getTime();
    }
  });
}

export function className(
  ...input: {className: string; applyClass?: boolean}[]
): string {
  return input
    .map(i => (i.applyClass === undefined || i.applyClass ? i.className : null))
    .filter(i => !!i)
    .join(' ');
}

export function isLikeUid(uidLike: string): boolean {
  return UID_REGEXP.test(uidLike);
}

export function orderColumnsByUID(state: any[] | null): any[] {
  const sortedColumn = state['columns'].find(col => {
    return col.sortOrder;
  });

  if (!sortedColumn) {
    state['columns'].find(col => {
      if (col.name === 'uid') {
        col.sortOrder = 'desc';
      }
    });
  }
  return state;
}

export function setFreshGridState(state: any[]): any[] {
  state['columns'].map(column => {
    if (column.dataField === 'uid') {
      column.sortOrder = 'desc';
    }
  });
  return state;
}

export function positionColumnChooser(event): void {
  var columnChooserView = event.component.getView('columnChooserView');
  if (!columnChooserView._popupContainer) {
    columnChooserView._initializePopupContainer();
    columnChooserView.render();
    columnChooserView._popupContainer.option('position', {
      of: event.element,
      my: 'right top',
      at: 'right top',
      offset: '0 50',
    });
  }
}

/**
 * In situation where columns are different at the init and after init,
 * dataGrid config is always rewritten with defaultColumns config
 * In case we have some state remembered, apply on applicable columns
 */
export function applyDataGridStateFromConfig(
  dataGridConfig: any,
  dataGridColumns: Column[],
): void {
  const applyState = (columns: any[]) => {
    columns.forEach(dc => {
      const match = dataGridConfig.columns.find(
        i => i.dataField === dc.dataField,
      );
      if (match) {
        dc.width = match.width;
        dc.visible = match.visible;
        dc.visibleIndex = match.visibleIndex;
        dc.filterValue = match.filterValue;
        dc.sortIndex = match.sortIndex;
        dc.sortOrder = match.sortOrder;
        dc.groupIndex = match.groupIndex;
      }

      if (dc.columns) {
        applyState(dc.columns);
      }
    });
  };

  if (dataGridConfig?.columns) {
    applyState(dataGridColumns);
  }
}

export const NotificationTemplatePlaceHolders = [
  'placeholder.customer-name',
  'placeholder.phone-number',
  'placeholder.service-price',
];

export interface DashboardTile {
  name: string;
  description: string;
  column: string;
  value: string;
  icon: string;
  offline?: boolean;
  smallScreen?: boolean;
  width?: number;
  height?: number;
}

export const Tiles: DashboardTile[] = [
  {
    name: 'tiles.news-post-name',
    description: 'tiles.news-post-description',
    column: '',
    value: 'news-post',
    icon: 'fas fa-newspaper',
  },
  {
    name: 'tiles.data-storage-name',
    description: 'tiles.data-storage-description',
    column: '',
    value: 'data-storage',
    icon: 'fa fa-database',
  },
  {
    name: 'tiles.tour-plan-name',
    description: 'tiles.tour-plan-description',
    column: '',
    value: 'tour-plan',
    icon: 'fa fa-map-signs',
    offline: true,
  },
  {
    name: 'tiles.last-visit-name',
    description: 'tiles.last-vist-description',
    column: '',
    value: 'last-visit',
    icon: 'fas fa-street-view',
  },
  {
    name: 'tiles.todo-task-name',
    description: 'tiles.todo-task-description',
    column: '',
    value: 'todo-task',
    icon: 'fas fa-street-view',
  },
  {
    name: 'tiles.my-status-name',
    description: 'tiles.my-status-description',
    column: '',
    value: 'my-status',
    icon: 'fas fa-chart-pie',
  },
  {
    name: 'tiles.weekly-overview-name',
    description: 'tiles.weekly-overview-description',
    column: '',
    value: 'weekly-overview',
    icon: 'fas fa-chart-bar',
  },
  {
    name: 'tiles.bhs-promo-actions-name',
    description: 'tiles.bhs-promo-actions-description',
    column: '',
    value: 'bhs-promo-actions',
    icon: 'fas fa-chart-bar',
  },
  {
    name: 'tiles.bhs-personal-arrangements-name',
    description: 'tiles.bhs-personal-arrangements-description',
    column: '',
    value: 'bhs-personal-arrangements',
    icon: 'fas fa-chart-bar',
  },
  {
    name: 'tiles.project-overview-name',
    description: 'tiles.project-overview-description',
    column: '',
    value: 'project-overview',
    icon: 'fas fa-clipboard-list-check',
    smallScreen: false,
    width: 4,
    height: 3,
  },
];

export function isValidTile(name: string): boolean {
  return Tiles.find(t => t.value === name) !== undefined;
}

export function isTileAvailableOffline(name: string): boolean {
  return Tiles.find(t => t.value === name)?.offline;
}

export const uniqueFilter = <T>(
  value: T,
  index: number,
  self: Array<T>,
): boolean => {
  return self.indexOf(value) === index;
};

/**
 * Determines whether the object represents HTTP error with given code.
 * @param e error object
 * @param status HTTP status code to check
 * @returns true if the object represents given HTTP error code; false otherwise
 */
export function isHttpError(e: any, status: number): boolean {
  if (e instanceof HttpErrorResponse) {
    return e.status === status;
  }

  if (typeof e === 'string') {
    // see BaseHttpService.getServerErrorMessage()
    return e.startsWith(`[${status}]`);
  }

  return false;
}

export function isHttpErrorBadRequest(e: any): boolean {
  return isHttpError(e, 400);
}

export function isHttpErrorUnauthorized(e: any): boolean {
  return isHttpError(e, 401);
}

export function isHttpErrorForbidden(e: any): boolean {
  return isHttpError(e, 403);
}

export function isHttpErrorNotFound(e: any): boolean {
  return isHttpError(e, 404);
}

export function isHttpErrorInternalServerError(e: any): boolean {
  return isHttpError(e, 500);
}

export function normalizeString(input: string): string {
  return input.normalize('NFD').replace(/\p{Diacritic}/gu, '');
}

/**
 * Compares two lists contains same elements. Elements must be type of BaseModel e.g. must have id property
 * @param listA first list
 * @param listB second list
 * @param fn function to get value to compare from element
 */
export function listsAreSame(
  listA: any[],
  listB: any[],
  fn = (item: any) => item.id,
): boolean {
  // both undefined - return true
  if (!listA && !listB) {
    return true;
  }

  // one is undefined, but second is an empty array - from value perspective they are same - return true
  if (
    (!listA && listB && listB.length === 0) ||
    (!listB && listA && listA.length === 0)
  ) {
    return true;
  }

  // one is undefined, but second one is not - return false
  if ((!listA && listB) || (listA && !listB)) {
    return false;
  }

  // different lengths of arrays - not same - return false
  if (listA.length !== listB.length) {
    return false;
  }

  // check if every element from listA is in listB and vice versa
  return (
    listA.every(a => listB.some(b => fn(b) === fn(a))) &&
    listB.every(b => listA.some(a => fn(a) === fn(b)))
  );
}

export function datesAreSame(a: Date, b: Date): boolean {
  return (
    a.getFullYear() === b.getFullYear() &&
    a.getMonth() === b.getMonth() &&
    a.getDate() === b.getDate()
  );
}
export function generateRandomString(
  length: number,
  useLetters = true,
  useNumbers = false,
  useSpecialCharacters = false,
): string {
  const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  const numbers = '0123456789';
  const specialChars = '!@#$%^&*';

  if (length <= 0 || (!useLetters && !useNumbers && !useSpecialCharacters)) {
    return null;
  }

  const characterSet = [];
  if (useLetters) {
    characterSet.push(letters);
  }

  if (useNumbers) {
    characterSet.push(numbers);
  }

  if (useSpecialCharacters) {
    characterSet.push(specialChars);
  }

  const characters = characterSet.join('');
  const result = [];
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result.push(
      characters.charAt(Math.floor(Math.random() * charactersLength)),
    );
  }

  return result.join('');
}

export function groupBy<T>(
  array: T[],
  predicate: (value: T, index: number, array: T[]) => string,
) {
  return array.reduce((acc, value, index, arr) => {
    (acc[predicate(value, index, arr)] ||= []).push(value);
    return acc;
  }, {} as {[key: string]: T[]});
}

export function zip2DArrays<T>(
  array1: T[][],
  array2: T[][],
  uniqueItemFn?: (item: any) => any,
): T[][] {
  const result: T[][] = [];
  const maxLength = Math.max(array1.length, array2.length);
  for (let i = 0; i < maxLength; i++) {
    const innerResult: T[] = [];
    (array1[i] ?? []).forEach(e => {
      const match = uniqueItemFn
        ? innerResult.find(r => uniqueItemFn(r) === uniqueItemFn(e))
        : undefined;

      if (!match) {
        innerResult.push(e);
      }
    });

    (array2[i] ?? []).forEach(e => {
      const match = uniqueItemFn
        ? innerResult.find(r => uniqueItemFn(r) === uniqueItemFn(e))
        : undefined;

      if (!match) {
        innerResult.push(e);
      }
    });

    result.push(innerResult);
  }

  return result;
}

export function filterActiveProjectsForRange(
  projects: Project[],
  range: {from?: Date, to?: Date}
): Project[] {
  return projects.filter(p => {
    const projectStart = moment(p.begin);
    const projectFinish = moment(p.end);

    if (range.from && range.to) {
      return projectStart.isSameOrBefore(range.to) &&
        projectFinish.isSameOrAfter(range.from);
    } else if (range.from) {
      return moment(range.from).isSameOrBefore(moment(p.end));
    } else if (range.to) {
      return moment(range.to).isSameOrAfter(moment(p.begin));
    } else {
      return true;
    }
  });
}

export function filterActiveProjectsForDate(
  projects: Project[],
  date: Date
): Project[] {
  return projects.filter(p =>
      moment(date).isBetween(moment(p.begin), moment(p.end))
  );
}
