import {HttpErrorResponse, HttpStatusCode} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject} from 'rxjs';
import {ConfirmType, ConfirmationService} from 'src/app/shared/services';
import {v4 as uuid} from 'uuid';

/**
 * How many time the app will retry when an error happens.
 *
 * NOTE: Retry indefinitely
 */
export const RETRY_COUNT = 1_000;
/**
 * The total count of the MSs to wait before retrying a request.
 */
export const RETRY_WAIT_MS = 5_000;

/**
 * The progress object that is used to track the progress of a request.
 */
export type Progress<T extends (...args: any[]) => any> = {
  id: string;
  label: string;
  labelIcon: string;
  abortConfirmTitle: string;
  abortConfirmMsg: string;
  status:
    | 'ready'
    | 'in-progress'
    | 'waiting'
    | 'finished'
    | 'retry-waiting'
    | 'retry-in-progress'
    | 'retry-finished'
    | 'retry-failed'
    | 'abort-waiting'
    | 'aborted';
  secondsToRetry?: number;
  currentTry?: number;
  fn: T;
  args?: Parameters<T>;
};

@Injectable()
export class RequestProgressService {
  private _prgoresses$ = new BehaviorSubject<Progress<any>[]>([]);
  private progressIntervalMap = new Map<string, NodeJS.Timeout>();
  private confrimDialogPromise: Promise<boolean>;
  private _messages$ = new BehaviorSubject<
    {type: 'error' | 'warn'; text: string}[]
  >([]);
  progresses$ = this._prgoresses$.asObservable();
  progressBar$ = new BehaviorSubject<{[progressId: string]: number}>({});
  messages$ = this._messages$.asObservable();

  constructor(
    private confirmationService: ConfirmationService,
    private translate: TranslateService,
  ) {}

  /**
   *
   * @param label the label of the progress.
   * @param icon the icon of the progress.
   * @param abortConfirmTitle the title of the abort confirmation dialog.
   * @param abortConfirmMsg the message of the abort confirmation dialog.
   * @param fn the function to be called and display the progress of it.
   * @param args the args of the provided function.
   * @param removeAfterFinish whether to remove the progress after the function is executed successfully.
   * @returns the return type of the provided function if the function is executed successfully; otherwise, false.
   */
  async addProgress<T extends (...args: any[]) => any>(
    label: string,
    icon: string,
    abortConfirmTitle: string,
    abortConfirmMsg: string,
    fn: T,
    args: Parameters<T> = [] as Parameters<T>,
    removeAfterFinish: boolean = false,
  ): Promise<{response?: Awaited<ReturnType<T>>; success: boolean}> {
    const id = uuid();
    const progresses = this._prgoresses$.value ?? [];
    progresses.push({
      id,
      label: label,
      labelIcon: icon,
      abortConfirmTitle: abortConfirmTitle,
      abortConfirmMsg: abortConfirmMsg,
      status: 'ready',
      fn: fn,
      args: args ?? [],
    });
    this._prgoresses$.next(progresses);

    const result = await this.startProgress(id);

    if (removeAfterFinish) {
      this.clearProgresses(id);
    }

    return result;
  }

  addMessage(message: {type: 'error' | 'warn'; text: string}) {
    const messages = this._messages$.value ?? [];
    messages.push(message);
    this._messages$.next(messages);
  }

  /**
   * Clears all progresses. If the id is provided, it will clear the progress with the provided id.
   * @param id the id of the progress to be cleared.
   */
  clearProgresses(id?: string) {
    if (id) {
      this._prgoresses$.next(this._prgoresses$.value.filter(p => p.id !== id));
    } else {
      this._prgoresses$.next([]);
    }
  }

  cleanMessages() {
    this._messages$.next([]);
  }

  /**
   * Updates the progress with the provided id.
   * @param id the id of the progress to be updated.
   * @param data the data to be updated.
   */
  updateProgress<T extends (...args: any[]) => any>(
    id: string,
    data: Partial<
      Pick<Progress<T>, 'status' | 'currentTry' | 'secondsToRetry'>
    >,
  ) {
    const progresses = this._prgoresses$.value.map(p => {
      if (p.id === id) {
        return {
          ...p,
          ...data,
        };
      }
      return p;
    });
    this._prgoresses$.next(progresses);

    this.updateProgressBarStatus(id);
  }

  /**
   * Shows a confirmation dialog to the user to confirm aborting or continuing the progress.
   * @param progressId the id of the progress to be aborted.
   * @returns a promise that resolves to true if the user decides to abort the progress; otherwise, false.
   */
  async showAbortConfirmationMsj(progressId: string) {
    const progress = this.getProgress(progressId);

    // it is possible to click on abort button only while waiting for the retry.
    if (progress.status === 'retry-waiting') {
      this.updateProgress(progressId, {
        status: 'abort-waiting',
      });

      this.confrimDialogPromise = this.confirmationService.confirm(
        ConfirmType.DELETE,
        progress.abortConfirmTitle,
        progress.abortConfirmMsg,

        {
          acceptText: this.translate.instant('views.visit.abort'),
          acceptIcon: 'check',
        },
      );

      this.updateProgress(progressId, {status: 'abort-waiting'});

      return this.confrimDialogPromise;
    } else {
      return false;
    }
  }

  /**
   * Removes the progress with the provided id.
   * @param id the id of the progress to be removed.
   * @returns the removed progress.
   */
  getProgress(id: string) {
    return this._prgoresses$.value.find(p => p.id === id);
  }

  /**
   * Calls the provided async function and retry the call when an error happens.
   * When Http error happens, the function will behave as follows:
   * InternalServerError (500) - retry max. 3 times.
   * NotFound (404)            - retry max. 3 times.
   * Forbidden (403)           - retry max. 3 times.
   * Unauthorized (401)        - no retry; a confirmation dialog will be shown to the user to login again.
   * RequestTimeout (408)      - retry max. 100 times.
   * GatewayTimeout (504)      - retry max. 100 times.
   * other errors              - retry max. 3 times.
   * @param progressId the id of the progress to be started.
   * @returns {Promise<{response?: ReturnType<T>; success: boolean}>} An object that contains the response of the provided function and a boolean that indicates whether the function is executed successfully.
   */
  private async startProgress<T extends (...args: any[]) => any>(
    progressId: string,
  ): Promise<{response?: ReturnType<T>; success: boolean}> {
    const progress = this.getProgress(progressId);
    if (!progress) {
      throw new Error(`Progress with id ${progressId} not found.`);
    }

    let response: ReturnType<T>;
    try {
      this.updateProgress(progressId, {status: 'in-progress'});
      response = await progress.fn(...progress.args);
      this.updateProgress(progressId, {status: 'finished'});
      return {response, success: true};
    } catch (e) {
      const error = e.originalError as HttpErrorResponse;
      let maxTryCount = await this.getMaxTryCount(
        error.status ?? HttpStatusCode.InternalServerError,
      );
      let tryNumber = 1;
      while (maxTryCount > 0) {
        try {
          this.updateProgress(progressId, {
            status: 'retry-waiting',
            secondsToRetry: RETRY_WAIT_MS / 1_000,
            currentTry: tryNumber,
          });
          this.updateRetryingProgBarStatus(progressId);
          await new Promise(f => setTimeout(f, RETRY_WAIT_MS));

          // The use could click on abort button while waiting. That's why get the progress again.
          let progress = this.getProgress(progressId);

          // don't do anything until the user decides whether to abort or not.
          if (progress.status === 'abort-waiting') {
            if (await this.confrimDialogPromise) {
              this.updateProgress(progressId, {status: 'aborted'});
              progress.status = 'aborted';
            } else {
              this.updateProgress(progressId, {status: 'retry-in-progress'});
              progress.status = 'retry-in-progress';
              // when the user decides not to abort, don't count the try when the user clicked on abort button.
              maxTryCount++;
            }
          }

          if (progress.status === 'aborted') {
            break;
          }

          this.updateProgress(progressId, {
            status: 'retry-in-progress',
            currentTry: tryNumber,
          });

          response = await progress.fn(...progress.args);
          // reset the maxTryCount to stop the while loop.
          maxTryCount = 0;
          this.updateProgress(progressId, {
            status: 'retry-finished',
            currentTry: tryNumber,
          });
          return {response, success: true};
        } catch (e) {
          tryNumber++;
          maxTryCount--;
          // When the loop reaches the last try with no success, return false.
          if (maxTryCount === 0) {
            this.updateProgress(progressId, {
              status: 'retry-failed',
              currentTry: tryNumber,
            });
            return {success: false};
          }
        }
      }
    }
  }

  /**
   * Updates the seconds to retry of the progress with the provided id.
   * @param progressId the id of the progress to be triggered.
   */
  private updateRetryingProgBarStatus(progressId: string) {
    const progress = this.getProgress(progressId);
    if (progress?.status === 'retry-waiting') {
      const prevInterval = this.progressIntervalMap.get(`${progressId}-retry`);
      if (prevInterval) {
        clearInterval(prevInterval);
      }

      const interval = setInterval(() => {
        const current = this.getProgress(progressId);
        if (current?.status === 'retry-waiting') {
          const secondsToRetry = current.secondsToRetry ?? 0;
          if (secondsToRetry > 0) {
            this.updateProgress(progressId, {
              secondsToRetry: secondsToRetry - 1,
            });
          } else {
            clearInterval(interval);
          }
        } else {
          clearInterval(interval);
        }
      }, 1_000);

      this.progressIntervalMap.set(`${progressId}-retry`, interval);
    }
  }

  /**
   * Updates the progress bar status of the progress with the provided id.
   * @param progressId the id of the progress to be updated.
   */
  private updateProgressBarStatus(progressId: string) {
    const progress = this.getProgress(progressId);

    // it is possible to clear the progress at any time.
    if (!progress) {
      return;
    }
    const previousInterval = this.progressIntervalMap.get(progressId);
    if (previousInterval) {
      clearInterval(previousInterval);
    }

    if (
      progress.status === 'in-progress' ||
      progress.status === 'retry-in-progress'
    ) {
      this.progressBar$.next({
        ...this.progressBar$.value,
        [progressId]: 0,
      });

      if (previousInterval) {
        clearInterval(previousInterval);
      }

      this.progressIntervalMap.set(
        progressId,
        setInterval(() => {
          // Reset the progress bar of the visit result while the request is being waited.
          if (progress?.status === 'retry-waiting') {
            this.progressBar$.next({
              ...this.progressBar$.value,
              [progressId]: 0,
            });
            clearInterval(previousInterval);
          } else {
            const current = this.progressBar$.value[progressId];
            if (current === 90) {
              this.progressBar$.next({
                ...this.progressBar$.value,
                [progressId]: current,
              });
            } else {
              this.progressBar$.next({
                ...this.progressBar$.value,
                [progressId]: current + 2,
              });
            }
          }
        }, 1_000),
      );
    } else if (
      progress.status === 'finished' ||
      progress.status === 'retry-finished'
    ) {
      this.progressBar$.next({
        ...this.progressBar$.value,
        [progressId]: 100,
      });
    }
  }

  /**
   * Returns the track by function for the ngFor directive.
   * @param index The index of the item.
   * @param item The item itself.
   * @returns {string} the id of the item.
   */
  ngForTrackFn(index: number, item: Progress<any>): string {
    return item.id;
  }

  /**
   * Returns the max try count based on the provided status code.
   * @param {HttpStatusCode} status the status code of the error.
   * @returns {HttpStatusCode} the max try count
   */
  private async getMaxTryCount(status: HttpStatusCode): Promise<number> {
    let maxTryCount: number = RETRY_COUNT ?? 1;
    switch (status) {
      case HttpStatusCode.InternalServerError:
        maxTryCount = 3;
        break;

      case HttpStatusCode.NotFound:
      case HttpStatusCode.BadRequest:
      case HttpStatusCode.Forbidden:
        maxTryCount = 1;
        break;

      case HttpStatusCode.Unauthorized:
        await this.confirmationService.confirm(
          ConfirmType.DEFAULT,
          this.translate.instant('views.visit.login-required-title'),
          this.translate.instant('views.visit.login-required-message'),
        );
        location.href = '/';
        maxTryCount = 0;
        break;

      case HttpStatusCode.RequestTimeout:
      case HttpStatusCode.GatewayTimeout:
        maxTryCount = 100;
        break;
      default:
        maxTryCount = 720;
        break;
    }
    return maxTryCount;
  }
}
