import * as moment from 'moment';
import {AuthGuardService} from '@salesapp/services';
import {combineLatest, from, Observable} from 'rxjs';
import {CustomValidators} from '@salesapp/utils/reactive-form/form-validators';
import {DataProvider} from '@salesapp/data-provider';
import {FeedbackFilter} from '../../feedback.interfaces';
import {FeedbackGridService} from '../../services/feedback-grid.service';
import {formatDateTime} from '@salesapp/shared/pipes/localized-datetime.pipe';
import {formatUser} from '@salesapp/utils/utils';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {FormControlsOf} from '@salesapp/utils/reactive-form/reactive-form.interface';
import {GridFilterDataFormatted} from '@salesapp/grid';
import {InputMultiselectOption} from '@salesapp/components';
import {IUsernameResponse} from '@retrixhouse/salesapp-shared/lib/responses';
import {map} from 'rxjs/operators';
import {paramCase} from 'change-case';
import {removeEmptyValues} from '@salesapp/utils/reactive-form/form.utils';
import {SettingNames} from '@retrixhouse/salesapp-shared/lib/settings';
import {sortByKey} from '@salesapp/shared/globals';
import {TranslateService} from '@ngx-translate/core';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import {
  ProjectStorageService,
  StoreStorageService,
  VisibleUsersStorageService,
} from '@salesapp/storage';
import {formatStore} from '@retrixhouse/salesapp-shared/lib/utils';

@Component({
  selector: 'app-feedback-grid-filter',
  templateUrl: './feedback-grid-filter.component.html',
  styleUrls: ['./feedback-grid-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeedbackGridFilterComponent implements OnInit {
  form: FormGroup<FeedbackFilterFormGroup>;

  collapsed = false;

  projectOptions$ = this.projectStorageService.dataAsSelectOptions$;
  storeOptions$ = this.storeStorageService.dataAsSelectOptions$;
  usernameOptions$: Observable<InputMultiselectOption<IUsernameResponse>[]>;

  filterFormatted$: Observable<GridFilterDataFormatted[]>;

  get formValue() {
    return this.form.getRawValue();
  }

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthGuardService,
    private projectStorageService: ProjectStorageService,
    private storeStorageService: StoreStorageService,
    private visibleUsersStorageService: VisibleUsersStorageService,
    private translationService: TranslateService,
    private feedbackGridService: FeedbackGridService,
    private dataProvider: DataProvider,
    private changeDetector: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.feedbackGridService.updateFilter({
      from: moment().startOf('month').toDate(),
      to: moment().endOf('month').toDate(),
    });
    this.initForm();
    this.setUsernameOptions();
    this.initFormattedData();
  }

  getFormGroupControl(name: string) {
    return this.form.get(name) as FormControl;
  }

  onFormAction() {
    this.collapsed = !this.collapsed;
    this.updateFilter();
  }

  onDeleteFilterItem(key: string) {
    const control = this.form.get(key);

    if (control) {
      control.setValue(null);
      this.updateFilter();
    }
  }

  private updateFilter() {
    this.feedbackGridService.updateFilter(
      this.filterToFeedbackFilter(this.form.getRawValue() as any),
    );
  }

  private initForm() {
    const maxReportDays = this.dataProvider.settingResolver.getValue(
      SettingNames.Common_MaxReportDays,
    );
    this.form = this.formBuilder.group<FeedbackFilterFormGroup>({
      projects: this.formBuilder.control(null),
      store: this.formBuilder.control(null),
      fromTo: this.formBuilder.control(
        [moment().startOf('month').toDate(), moment().endOf('month').toDate()],
        [
          CustomValidators.required,
          CustomValidators.validateDateRange(maxReportDays || 93),
        ],
      ) as any,
      executors: this.formBuilder.control(null),
      creators: this.formBuilder.control(null),
    });
  }

  private setUsernameOptions() {
    this.usernameOptions$ =
      this.visibleUsersStorageService.dataAsSelectOptions$;
  }

  private filterToFeedbackFilter(
    data: Partial<FeedbackFilterForm>,
  ): FeedbackFilter {
    const filter = {
      storeId: data.store,
      executorIds: data.executors,
      createdByIds: data.creators,
      projectIds: data.projects,
      from: data.fromTo[0],
      to: data.fromTo[1],
      isMyFeedback: false,
    };

    return removeEmptyValues(filter);
  }

  private createTranslationMarker(key: string) {
    return `entity.feedback.filter.${paramCase(key)}.label`;
  }

  private initFormattedData() {
    this.filterFormatted$ = combineLatest([
      this.form.valueChanges,
      this.projectStorageService.dataById$,
      this.storeStorageService.dataById$,
      this.visibleUsersStorageService.dataById$,
    ]).pipe(
      map(([formValue, projectById, storeById, usernameById]) => {
        const filterFormatted: GridFilterDataFormatted[] = [];
        Object.entries(formValue).forEach(([key, value]) => {
          if (value) {
            switch (key) {
              case 'projects':
                filterFormatted.push({
                  label: this.translationService.instant(
                    this.createTranslationMarker(key),
                  ),
                  value: (value as string[])
                    .map(id => projectById.get(id as string).name)
                    .join(', '),
                  position: 1,
                  key,
                });
                break;
              case 'store':
                filterFormatted.push({
                  label: this.translationService.instant(
                    this.createTranslationMarker(key),
                  ),
                  value: formatStore(storeById.get(value as string)),
                  position: 2,
                  key,
                });
                break;
              case 'fromTo':
                filterFormatted.push({
                  label: this.translationService.instant(
                    this.createTranslationMarker(key),
                  ),
                  value: (value as string[])
                    .map(date => formatDateTime(date, 'L'))
                    .join(' - '),
                  position: 3,
                  key,
                  required: true,
                });
                break;
              case 'executors':
                let executors = [];
                (value as string[]).forEach(id => {
                  const username = usernameById.get(id);
                  executors.push(formatUser(username));
                  key;
                });
                filterFormatted.push({
                  label: this.translationService.instant(
                    this.createTranslationMarker(key),
                  ),
                  value: executors.join(', '),
                  position: 4,
                  key,
                });
                break;
              case 'creators':
                let creators = [];
                (value as string[]).forEach(id => {
                  const username = usernameById.get(id);
                  creators.push(formatUser(username));
                  key;
                });
                filterFormatted.push({
                  label: this.translationService.instant(
                    this.createTranslationMarker(key),
                  ),
                  value: creators.join(', '),
                  position: 5,
                  key,
                });
                break;
            }
          }
        });
        return sortByKey(filterFormatted, 'position');
      }),
    );
  }
}

interface FeedbackFilterForm {
  projects: string[];
  store: string;
  fromTo: Date[];
  executors: string[];
  creators: string[];
}

type FeedbackFilterFormGroup = FormControlsOf<FeedbackFilterForm>;
