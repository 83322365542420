export class AccessRuleDef {
  customerIds?: string[];
  projectIds?: string[];
  chainIds?: string[];
  storeIds?: string[];
  regionIds?: string[];

  roleIds?: string[];
  userIds?: string[];
}

export class ScopeTableDef {
  na: AccessRuleDef;
  ro: AccessRuleDef;
  rw: AccessRuleDef;

  constructor() {
    this.na = new AccessRuleDef();
    this.ro = new AccessRuleDef();
    this.rw = new AccessRuleDef();
  }
}
