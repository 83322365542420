import {Injectable} from '@angular/core';
import {UntypedFormBuilder} from '@angular/forms';
import {SharedFormService} from '../../../../shared/services/shared-form-builder.service';
import {CustomValidators} from '../../../../shared/utils/reactive-form/form-validators';
import {IStoreWithRelations} from '@retrixhouse/salesapp-shared/lib/models';
import {StoreStorageService} from '../../../../shared/services/storage/store-storage.service';
import {ChainStorageService} from '../../../../shared/services/storage/chain-storage.service';
import {GenericListItemStorageService} from '../../../../shared/services/storage/generic-list-item-storage.service';
import {map} from 'rxjs/operators';
import {GenericListIds, sortByKey} from '../../../../shared/globals';
import {ActionsService} from '../../../../shared/services/actions.service';
import {removeEmptyValues} from '../../../../shared/utils/reactive-form/form.utils';
import {PeriodicLimitStorageService} from '../../../../shared/services/storage/periodic-limit-storage.service';
import {ProjectStorageService} from '../../../../shared/services/storage/project-storage.service';
import {v4 as uuid} from 'uuid';
import {from} from 'rxjs';
import {Router} from '@angular/router';

@Injectable()
export class StoresService {
  selectedChainId: string;

  stores$ = this.storeStorageService.data$;
  dataLoading$ = this.storeStorageService.dataFetching$;
  storesActionInProgress$ = this.storeStorageService.dataCommandInProgress$;

  periodicLimits$ = this.periodicLimitStorageService.data$;
  projects$ = this.projectStorageService.data$;

  chainsOptions$ = this.chainStorageService.dataAsSelectOptions$;
  subChainsOptions$ = this.getGenericListItemsForProperty(
    GenericListIds.StoreSubChain,
  );
  segmentsOptions$ = this.getGenericListItemsForProperty(
    GenericListIds.StoreSegment,
  );
  storeAgencyRegionsOptions$ = this.getGenericListItemsForProperty(
    GenericListIds.StoreAgencyRegion,
  );
  sizesOptions$ = this.getGenericListItemsForProperty(GenericListIds.StoreSize);
  typesOptions$ = this.getGenericListItemsForProperty(GenericListIds.StoreType);

  constructor(
    private formBuilder: UntypedFormBuilder,
    private SharedFormService: SharedFormService,
    private storeStorageService: StoreStorageService,
    private chainStorageService: ChainStorageService,
    private periodicLimitStorageService: PeriodicLimitStorageService,
    private projectStorageService: ProjectStorageService,
    private genericListItemStorageService: GenericListItemStorageService,
    private actionsService: ActionsService,
    private router: Router,
  ) {}

  saveStore(data: IStoreWithRelations) {
    const filteredData = removeEmptyValues(data);

    if (filteredData.address) {
      filteredData.address.id = uuid();
    }
    if (data.version) {
      return this.storeStorageService.update({
        id: data.id,
        data: filteredData,
      });
    } else {
      return this.storeStorageService.create(filteredData);
    }
  }

  deleteStore(data: {id: string; version: number}) {
    from(
      this.actionsService.deleteStore({id: data.id, version: data.version}),
    ).subscribe({
      next: response => {
        if (response) {
          this.router.navigate(['/chains-and-stores/stores']);
        }
      },
    });
  }

  // createForm(store?: IStoreWithRelations) {
  //   const form = this.formBuilder.group({
  //     id: this.formBuilder.control(uuid()),
  //     version: this.formBuilder.control(null),
  //     uid: this.formBuilder.control({value: null, disabled: true}),
  //     name: this.formBuilder.control(null, [CustomValidators.required]),
  //     validFrom: this.formBuilder.control(null),
  //     validTo: this.formBuilder.control(null),
  //     chainSpecificId: this.formBuilder.control(null),
  //     subChainId: this.formBuilder.control(null),
  //     segmentId: this.formBuilder.control(null),
  //     agencyRegionId: this.formBuilder.control(null),
  //     typeId: this.formBuilder.control(null),
  //     sizeId: this.formBuilder.control(null),
  //     parkingLot: this.formBuilder.control(false),
  //     latitude: this.formBuilder.control(null),
  //     longitude: this.formBuilder.control(null),
  //     altitude: this.formBuilder.control(null),
  //     notes: this.formBuilder.control(null),
  //     googlePlacesId: this.formBuilder.control(null),
  //     address: this.SharedFormService.createAddressFormGroup(),
  //     openingHours: this.formBuilder.control([]),
  //     chainId: this.formBuilder.control(this.selectedChainId || null),
  //     addressId: this.formBuilder.control(null),
  //     extendedProperties: this.formBuilder.group({}),
  //     periodicLimits: this.formBuilder.control([]), // TODO: rename to storeFrequencies in the future
  //   });

  //   if (store) {
  //     form.patchValue(store, {emitEvent: false});
  //   }

  //   return form;
  // }

  getGenericListItemsForProperty(listId: string) {
    return this.genericListItemStorageService.getItemsForList(listId).pipe(
      map(items =>
        sortByKey(
          items.map(item => ({name: item.name, value: item.id})),
          'name',
        ),
      ),
    );
  }
}
