import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {FeedbackWithUserAndOpenedInfo} from '@salesapp/shared/models';
import {FeedbackStorageService} from '../../services/feedback-storage.service';
import {
  IFeedbackWithUserAndOpenedInfo,
  IKPISetWithRelations,
} from '@retrixhouse/salesapp-shared/lib/models';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {IUsernameResponse} from '@retrixhouse/salesapp-shared/lib/responses';
import {arrayToMap} from '@salesapp/utils/utils';
import {FeedbackMapper} from '../../domain/feedback.mapper';
import {trackByIndex} from '@salesapp/utils/angular.utils';
import {environment} from 'src/environments/environment';
import {DataProvider} from '@salesapp/data-provider';
import {SettingNames} from '@retrixhouse/salesapp-shared/lib/settings';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-feedback-detail',
  templateUrl: './feedback-detail.component.html',
  styleUrls: ['./feedback-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeedbackDetailComponent implements OnInit {
  @Input() feedbackId: string;

  feedback$: Observable<FeedbackDetail>;

  originalFeedback$: Observable<FeedbackWithUserAndOpenedInfo>;

  trackByFn = trackByIndex;

  photos: any;
  imageDetailVisible = false;
  selectedPhotoIndex = 0;

  baseUrl = environment.frontEndBaseUrl;

  constructor(
    private feedbackStorageService: FeedbackStorageService,
    private dataProvider: DataProvider,
    private translateService: TranslateService,
  ) {}

  ngOnInit() {
    this.originalFeedback$ = this.feedbackStorageService.dataById$.pipe(
      map(dataById => {
        return dataById?.get(this.feedbackId) as any;
      }),
    );

    this.feedback$ = this.feedbackStorageService.dataById$.pipe(
      map(dataById => {
        const feedback = dataById?.get(this.feedbackId);
        const kpiValueRenderingFormat =
          this.dataProvider.settingResolver.getValue(
            SettingNames.TourPlan_Feedback_KpiInputDisplay,
            feedback.tourPlan.projectId,
          );
        return FeedbackMapper.feedbackToFeedbackDetail(
          feedback,
          kpiValueRenderingFormat,
          this.translateService,
        );
      }),
    );
  }

  onOpenImage(photo) {
    this.photos = [photo];
    this.imageDetailVisible = true;
  }

  onOpenImageFromDetail(event: {photos: any; index: number}) {
    this.photos = event.photos;
    this.selectedPhotoIndex = event.index;
    this.imageDetailVisible = true;
  }
}

export interface FeedbackDetail {
  createdBy: IUsernameResponse;
  createdAt: Date;
  visitFinishedAt: Date;
  commentRequired: boolean;
  photoRequired: boolean;
  resolved: boolean;
  comment?: {
    text: string;
    createdBy: IUsernameResponse;
    createdAt: Date;
    photo: any;
  };
  kpiSets: FeedbackDetailKpiSet[];
}

export interface FeedbackDetailKpiSet {
  rating: {
    color: string;
    value: number | string;
    description: string | null;
  };
  description: string;
  photos: any;
  name: string;
  kpiItems: {
    label: string;
    value: number;
    color: string;
  }[];
}
