import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {FileService, NotificationService} from '@salesapp/services';
import {AutoUnsubscribe, trackByIndex} from '@salesapp/utils/angular.utils';
import {
  FileUtils,
  MIME_TYPE_TO_FILE_EXTENSION,
  MimeTypes,
} from '@salesapp/utils/file.utils';
import {
  ControlValueAccessor,
  FormControlComponent,
} from '@salesapp/utils/reactive-form';
import {BehaviorSubject, Subscription} from 'rxjs';
import {delay} from 'rxjs/operators';
import {createFormControlProvider} from '../../utils/reactive-form/form.utils';

@Component({
  selector: 'app-input-file',
  templateUrl: './input-file.component.html',
  styleUrls: ['./input-file.component.scss'],
  providers: [createFormControlProvider(() => InputFileComponent)],
})
@ControlValueAccessor()
@AutoUnsubscribe()
export class InputFileComponent extends FormControlComponent<
  string | string[]
> {
  @Input() allowedFileTypes: MimeTypes[];
  @Input() mode: 'single' | 'multiple' = 'single';
  @Input() maxFiles: number;
  @Input() initialFiles?: File[];

  @Output() filesChange = new EventEmitter<File[]>();

  fileOver: boolean = false;

  files: File[] = [];

  processing$ = new BehaviorSubject<boolean>(false);

  private subscriptions: Subscription[] = [];

  trackByIndex = trackByIndex;

  constructor(
    private changeDetector: ChangeDetectorRef,
    private fileService: FileService,
  ) {
    super(changeDetector);
  }

  ngOnInit() {
    this.setInitialFiles();
  }

  onFilesDropped(event: File[]) {
    if (this.mode === 'single') {
      this.processSingleMode(event);
    } else {
      this.processMultipleMode(event);
    }
  }

  onFileOver(fileOver: boolean) {
    this.fileOver = fileOver;
  }

  onBrowserUploadFile(event: Event) {
    const target = event.target as HTMLInputElement;
    const files = target.files;
    if (this.mode === 'single') {
      this.processSingleMode(Array.from(files));
    } else {
    }
  }

  getAllowedFileTypes() {
    const fileExtensions = this.allowedFileTypes.map(
      fileType => MIME_TYPE_TO_FILE_EXTENSION[fileType],
    );

    return fileExtensions.map(extension => `.${extension}`).join(', ');
  }

  getIconForFile(file: File) {
    return FileUtils.getIconByMimeType(file.type as MimeTypes);
  }

  onDeleteFile(index: number) {
    if (this.mode === 'single') {
      this.files = [];
      this.valueChange.emit(null);
      this.filesChange.emit(null);
    } else {
      // TODO(milan): implement multiple files when needed
    }
  }

  private processSingleMode(files: File[]) {
    if (files.length > 1) {
      // throw error??
    } else {
      this.processing$.next(true);
      this.subscriptions.push(
        this.fileService
          .processFile({
            file: files[0],
            availableMimeTypes: this.allowedFileTypes,
            fileFormat: 'base64',
          })
          .pipe(delay(1000))
          .subscribe({
            next: base64File => {
              this.valueChange.next(base64File);
              this.files = files;
              this.processing$.next(false);
              this.filesChange.next(files);
            },
            error: error => {
              console.error(error);
              this.processing$.next(false);
              NotificationService.notifyError(error);
            },
          }),
      );
      // what should we do???
    }
  }

  private setInitialFiles() {
    if (this.initialFiles) {
      this.files = this.initialFiles;
    }
  }

  private processMultipleMode(files: File[]) {
    // TODO(milan): implement multiple files when needed
    if (files.length > this.maxFiles) {
      // throw error??
    } else {
    }
  }
}
