import {Dialog} from '@angular/cdk/dialog';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import * as uuid from 'uuid';
import {markFormGroupAsTouched} from '../../../../utils/reactive-form/form.utils';
import {FormActionsComponent} from '../form-actions/form-actions.component';
import {FormDiscardDialogComponent} from '../form-discard-dialog/form-discard-dialog.component';

import {
  TSimpleChanges,
  isNotFirstChange,
} from '../../../../utils/angular.utils';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() formGroup: UntypedFormGroup;
  @Input() formActions?: FormActionsComponent;
  @Input() actionInProgress?: boolean | null;
  // whether allow to close form containing changes
  @Input() disableChangedFormCheck: boolean;

  @Output() action = new EventEmitter();
  @Output() cancel = new EventEmitter();

  @ContentChild(FormActionsComponent, {static: false})
  contentFormActions: FormActionsComponent;

  formId = `salesapp-form-${uuid.v4()}`;

  markForCheckTimeout: any;

  get actions() {
    return this.formActions || this.contentFormActions;
  }

  get hasError() {
    return !!this.formGroup.errors && this.formGroup.dirty;
  }

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private elementRef: ElementRef,
    private dialog: Dialog,
  ) {}

  ngOnInit(): void {
    this.setupFormActions();
  }

  ngOnChanges(changes: TSimpleChanges<FormComponent>): void {
    if (isNotFirstChange(changes.action)) {
      this.setupFormActions();
    }

    if (isNotFirstChange(changes.actionInProgress)) {
      this.changeActionInProgressInFormActions();
    }
  }

  ngOnDestroy(): void {
    clearTimeout(this.markForCheckTimeout);
  }

  // when using in dialog component to trigger valdation and mark error controls
  onAction(action?: {clickHandleFn: () => void}) {
    this.formGroup.updateValueAndValidity();
    if (!this.formGroup.valid) {
      markFormGroupAsTouched(this.formGroup);
      clearTimeout(this.markForCheckTimeout);
      this.markForCheckTimeout = setTimeout(
        () => this.changeDetectorRef.markForCheck(),
        1,
      );
      this.changeDetectorRef.detectChanges();
      this.scrollToFirstInvalidControl();
      return;
    }

    if (action?.clickHandleFn) {
      action.clickHandleFn();
    } else {
      this.action.emit();
    }
  }

  onCancel() {
    if (this.formGroup.dirty && !this.disableChangedFormCheck) {
      this.dialog
        .open(FormDiscardDialogComponent, {disableClose: true})
        .closed.subscribe({
          next: response => {
            if (response === 'accepted') {
              this.cancel.emit();
            }
          },
        });
    } else {
      this.cancel.emit();
    }
  }

  private setupFormActions() {
    if (this.actions) {
      this.actions.onAction = () => this.onAction();
      this.actions.onCancel = () => this.onCancel();
      this.actions.formId = this.formId;
      this.actions.changeDetectorRef.detectChanges();
    }
  }

  private changeActionInProgressInFormActions() {
    if (this.actions) {
      this.actions.actionInProgress = this.actionInProgress || false;
      this.actions.changeDetectorRef.detectChanges();
    }
  }

  private scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement =
      this.elementRef.nativeElement.querySelector(
        'form .ng-form-control.ng-invalid',
      );
    if (firstInvalidControl) {
      firstInvalidControl.scrollIntoView({behavior: 'smooth', block: 'center'});
    }
  }
}
