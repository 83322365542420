import {Injectable} from '@angular/core';
import {BaseCrudHttpService} from './base.http-service';
import {HttpClient} from '@angular/common/http';
import {ProductListingTemplate} from '../../models';
import {IProductListingTemplateDataService} from '../../interfaces/data-service';

@Injectable()
export class ProductListingTemplateHttpService
  extends BaseCrudHttpService<ProductListingTemplate>
  implements IProductListingTemplateDataService
{
  constructor(http: HttpClient) {
    super(http, '/product-listing-template');
  }

  public createForProductListing(
    productListingId: string,
    productListingTemplate: ProductListingTemplate,
  ): Promise<void> {
    return this.POST<void>(
      `/product-listing-template/${productListingId}`,
      productListingTemplate,
      {
        params: {productListingId},
      },
    );
  }

  public overwriteExistingProductListingTemplate(
    productListingTemplateId: string,
    productListingId: string,
  ): Promise<void> {
    return this.PATCH<void>(
      `/product-listing-template/overwrite/${productListingTemplateId}`,
      {},
      {
        params: {productListingId},
      },
    );
  }
}
