import {Injectable} from '@angular/core';
import {GenericList} from '../../models';
import {IGenericListDataService} from '../../interfaces/data-service';
import {BaseCrudOfflineService} from './base.offline-service';

@Injectable()
export class GenericListOfflineService
  extends BaseCrudOfflineService<GenericList>
  implements IGenericListDataService
{
  getSingleForEdit(id: string): Promise<GenericList> {
    return Promise.reject('Offline service - not yet implemented');
  }
}
