import {FormArray, FormControl, FormGroup} from '@angular/forms';

export type FormControlsOf<T extends Record<string, any>> = {
  [K in keyof T]: T[K] extends Record<any, any>
    ? T[K] extends (infer U)[]
      ? U extends Record<any, any>
        ? FormArray<FormGroup<FormControlsOf<U>>>
        : FormControl<U[]>
      : T[K] extends Date
      ? FormControl<T[K]>
      : FormGroup<FormControlsOf<T[K]>>
    : // : // : FormControl<T[K]>;
    T[K] extends null
    ? FormControl<T[K] | null>
    : FormControl<T[K]>;
};
