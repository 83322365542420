import {DragDropModule} from '@angular/cdk/drag-drop';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {
  InputAddressModule,
  InputAvatarModule,
  InputDateModule,
  InputDatetimeModule,
  InputGooglePlacesModule,
  InputMultiSelectModule,
  InputNumberModule,
  InputRichTextModule,
  InputSelectModule,
  InputSwitchModule,
  InputTagsModule,
  InputTextAreaModule,
  InputTextModule,
  SkeletonModule,
} from '@salesapp/components';
import {CustomPipesModule} from '@salesapp/pipes';
import {DxButtonModule, DxTabsModule} from 'devextreme-angular';
import {DialogModule} from '../dialog/index';
import {FormModule} from '../form';
import {FormDesignerControlComponent} from './components/form-designer-control/form-designer-control.component';
import {FormDesignerDialogComponent} from './components/form-designer-dialog/form-designer-dialog.component';
import {FormDesignerFormSettingDialogComponent} from './components/form-designer-form-setting-dialog/form-designer-form-setting-dialog.component';
import {FormDesignerGooglePlacesControlSettingsComponent} from './components/form-designer-google-places-control-settings/form-designer-google-places-control-settings.component';
import {FormDesignerLocationControlSettingsComponent} from './components/form-designer-location-control-settings/form-designer-location-control-settings.component';
import {FormDesignerUpdateControlDialogComponent} from './components/form-designer-update-control-dialog/form-designer-update-control-dialog.component';
import {FormDesignerUpdateGroupDialogComponent} from './components/form-designer-update-group-dialog/form-designer-update-group-dialog.component';
import {FormDesignerComponent} from './components/form-designer/form-designer.component';
import {FormLayoutService} from './services/form-layout.service';

const SALESAPP_MODULES = [DialogModule, FormModule];
const SALESAPP_COMPONENTS = [
  InputAddressModule,
  InputAvatarModule,
  InputDateModule,
  InputDatetimeModule,
  InputGooglePlacesModule,
  InputMultiSelectModule,
  InputNumberModule,
  InputRichTextModule,
  InputSelectModule,
  InputSwitchModule,
  InputTagsModule,
  InputTextAreaModule,
  InputTextModule,
  SkeletonModule,
];
const DX_MODULES = [DxTabsModule, DxButtonModule];

const COMPONENTS = [
  FormDesignerComponent,
  FormDesignerControlComponent,
  FormDesignerDialogComponent,
  FormDesignerFormSettingDialogComponent,
  FormDesignerGooglePlacesControlSettingsComponent,
  FormDesignerLocationControlSettingsComponent,
  FormDesignerUpdateControlDialogComponent,
  FormDesignerUpdateGroupDialogComponent,
];

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DragDropModule,
    TranslateModule,
    CustomPipesModule,
    ...SALESAPP_MODULES,
    ...SALESAPP_COMPONENTS,
    ...DX_MODULES,
  ],
  declarations: [...COMPONENTS],
  providers: [FormLayoutService],
  exports: [...COMPONENTS],
})
export class FormDesignerModule {}

export * from './components/form-designer-dialog/form-designer-dialog.component';
export * from './components/form-designer/form-designer.component';
