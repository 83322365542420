import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {createFormControlProvider} from '../../utils/reactive-form/form.utils';
import {ControlValueAccessor} from '../../utils/reactive-form/control-value-accessor';
import {FormControlComponent} from '../../utils/reactive-form/form-control.component';
import {formatDateTime} from '@retrixhouse/salesapp-shared/lib/utils';

@Component({
  selector: 'app-input-datetime',
  templateUrl: './input-datetime.component.html',
  styleUrls: ['./input-datetime.component.scss'],
  providers: [createFormControlProvider(() => InputDatetimeComponent)],
})
@ControlValueAccessor()
export class InputDatetimeComponent extends FormControlComponent<string> {
  constructor(private changeDetector: ChangeDetectorRef) {
    super(changeDetector);
  }
  onValueChange($event) {
    if (!!$event) {
      this.valueChange.emit(new Date($event).toISOString());
    } else {
      this.valueChange.emit($event);
    }
  }

  dateFormat(date: Date): string {
    return formatDateTime(date, 'L LT');
  }
}
