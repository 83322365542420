import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {AuthGuardService, NavigationAwayGuard} from './shared/services';
import {DevExtremeModule} from 'devextreme-angular';
import {FlexLayoutModule} from '@angular/flex-layout';
import {BreadcrumbModule} from './shared/components/breadcrumb/breadcrumb.component';
import {PositionModule} from './shared/components/position/position.component';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {AppInterfaceComponent} from './modules/home/app-interface/app-interface.component';
import {DataTransferComponent} from './modules/home/data-transfer/data-transfer.component';
import {DataTransferNavigationGuard} from './shared/guards/data-transfer.navigation.guard';

const routes: Routes = [
  {
    path: 'system',
    loadChildren: () =>
      import('./modules/system/system.module').then(m => m.SystemModule),
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('./modules/profile/profile.module').then(m => m.ProfileModule),
  },
  {
    path: 'administration',
    loadChildren: () =>
      import('./modules/administration/administration.module').then(
        m => m.AdministrationModule,
      ),
  },
  {
    path: 'customer-agenda',
    loadChildren: () =>
      import('./modules/customer-agenda/customer-agenda.module').then(
        m => m.CustomerAgendaModule,
      ),
  },
  {
    path: 'reports',
    loadChildren: () =>
      import('./modules/reports/report.module').then(m => m.ReportModule),
  },
  {
    path: 'chains-and-stores',
    loadChildren: () =>
      import('./modules/stores/stores.module').then(m => m.StoresModule),
  },
  {
    path: 'data-collection',
    loadChildren: () =>
      import('./modules/field-data/questionnaires.module').then(
        m => m.QuestionnairesModule,
      ),
  },
  {
    path: 'data-storage',
    loadChildren: () =>
      import('./modules/data-storage/data-storage.module').then(
        m => m.DataStorageModule,
      ),
  },
  {
    path: 'tour-plans',
    loadChildren: () =>
      import('./modules/tour/tour-plan.module').then(m => m.TourPlanModule),
  },
  {
    path: 'app-interface',
    component: AppInterfaceComponent,
    canActivate: [AuthGuardService],
    data: {
      id: '6376e5da-dce4-4ddf-81ed-8350f4fed2bd',
      visible: true,
    },
  },
  {
    path: 'data-transfer',
    component: DataTransferComponent,
    canActivate: [AuthGuardService, DataTransferNavigationGuard],
    canDeactivate: [DataTransferNavigationGuard],
    data: {
      id: '05542afb-7492-4c00-8fa2-911f4a2eb03e',
      breadcrumb: 'Data Transfer',
      i18n: 'navigation.data-transfer',
    },
  },
  {
    path: '**',
    loadChildren: () =>
      import('./modules/home/home.module').then(m => m.HomeModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      onSameUrlNavigation: 'reload',
    }),
    DevExtremeModule,
    FlexLayoutModule,
    BreadcrumbModule,
    PositionModule,
    CommonModule,
    TranslateModule,
  ],
  providers: [AuthGuardService, NavigationAwayGuard],
  exports: [RouterModule],
})
export class AppRoutingModule {}
