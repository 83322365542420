import {Injectable} from '@angular/core';
import {OldBaseStorageService} from './old-base-storage-service';
import {DataProvider} from '../../data.provider/data-provider';
import {Filter} from '@loopback/filter';
import {formatStore, Tokenizer} from '@retrixhouse/salesapp-shared/lib/utils';
import {
  IAddress,
  IStoreWithRelations,
} from '@retrixhouse/salesapp-shared/lib/models';

@Injectable()
export class StoreStorageService extends OldBaseStorageService<
  IStoreWithRelations,
  IStoreWithRelations[]
> {
  constructor(dataProvider: DataProvider) {
    super(dataProvider.store, undefined, DEFAULT_FILTER);
  }

  entityToInputSelectOption(entity: IStoreWithRelations) {
    return {
      name: formatStore(entity),
      value: entity.id,
      data: entity,
      searchValue: Array.from(Tokenizer.words(getSearchString(entity))),
    };
  }
}

function getSearchString(s: IStoreWithRelations): string {
  return `${s.name} ${s.uid} ${formatAddressForSearchString(s.address)} ${
    s.chain?.name
  }`;
}

function formatAddressForSearchString(address: IAddress | undefined): string {
  if (!address) {
    return '';
  }
  const result: string[] = [];
  if (address.line1) {
    result.push(address.line1);
  }

  if (address.line2) {
    result.push(address.line2);
  }

  if (address.city) {
    result.push(address.city);
  }

  return result.join(' ');
}

const DEFAULT_FILTER: Filter<IStoreWithRelations> = {
  include: [
    {relation: 'periodicLimits'},
    {relation: 'address'},
    {relation: 'periodicLimits'},
  ],
};
