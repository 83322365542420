import * as moment from 'moment';
import {AbstractControl, AsyncValidatorFn, ValidationErrors, Validators} from '@angular/forms';
import {
  eval as evalExpression,
  parse as parseExpression,
} from 'expression-eval';
import { Observable, of, timer } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, first, map, switchMap } from 'rxjs/operators';

export enum FormValidationErrorKeysEnum {
  // angular errors
  Min = 'min',
  Max = 'max',
  Required = 'required',
  Email = 'email',
  MinLength = 'minlength',
  MaxLength = 'maxlength',
  Pattern = 'pattern',
  // custom errors
  Custom = 'custom',
  ValidationExpression = 'validationExpression',
  DateRangeMissingDate = 'dateRangeMissingDate',
  DateRangeExceeded = 'dateRangeExceeded',
  MinimumDateExceeded = 'minimumDateExceeded',
  AsyncValueNotUnique = 'asyncValueNotUnique',
}

export interface CustomValidationErrors extends ValidationErrors {
  // [FormValidationErrorKeysEnum]?: boolean;
  [FormValidationErrorKeysEnum.ValidationExpression]?: string;
  [FormValidationErrorKeysEnum.DateRangeMissingDate]?: boolean;
  [FormValidationErrorKeysEnum.DateRangeExceeded]?: number;
  [FormValidationErrorKeysEnum.MinimumDateExceeded]?: number;
  [FormValidationErrorKeysEnum.AsyncValueNotUnique]?: boolean;
}

export class CustomValidators extends Validators {
  // FORM CONTROL VALIDATORS
  static validationExpression(expression: string, ctx?: any) {
    return (control: AbstractControl) => {
      // NOTE: when form is build in dynamic form builder there is no control available at the time
      if (!control.parent) {
        return null;
      }

      try {
        const validationExpression = parseExpression(expression);
        const formGroupValue = control.parent?.getRawValue();
        const isValid = evalExpression(validationExpression, {
          ...ctx,
          data: formGroupValue,
        });
        return isValid ? null : {validationExpression: expression};
      } catch (error) {
        console.warn(
          'VALIDATE EXPRESSION: There is a missing property provided object.',
          error.message,
        );
        return null;
      }
    };
  }
  static validateDateRange(maxDays: number) {
    return (control: AbstractControl) => {
      // NOTE: when form is build in dynamic form builder there is no control available at the time
      if (!control || !control.value) {
        return null;
      }

      const from = control.value[0];
      const to = control.value[1];

      if (from === null || to === null) {
        return {dateRangeMissingDate: true};
      }

      const diff = moment(to).diff(from, 'days');
      return diff > maxDays ? {dateRangeExceeded: diff - maxDays} : null;
    };
  }

  static validateDateMinimum(minimumDate: Date) {
    return (control: AbstractControl) => {
      if (!control || !control.value) {
        return null;
      }

      const value = new Date(control.value);
      const diff = moment(minimumDate).diff(value, 'days');
      return diff > 0 ? {minimumDateExceeded: diff + 1} : null;
    };
  }
  // FORM GROUP VALIDATORS

  // FORM CONTROL ASYNC VALIDATORS
  static asyncUniqeValue(
    uniqueCheck: (value: any) => Observable<boolean>,
  ): AsyncValidatorFn {
    return (control: AbstractControl): Observable<any> => {
      return control.valueChanges.pipe(
        debounceTime(400),
        distinctUntilChanged(),
        switchMap(() => uniqueCheck(control.value)),
        map(result => {
          if (!!result) {
            return {
              [FormValidationErrorKeysEnum.AsyncValueNotUnique]: true,
            };
          }
          return null;
        }),
        catchError(error => {
          console.warn('Async validation error', error);
          return of(null);
        }),
        first(),
      );
    };
  }
}
