import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
} from '@angular/core';
import {TSimpleChanges} from 'src/app/shared/utils/angular.utils';
import {
  CustomValidationErrors,
  FormValidationErrorKeysEnum,
} from '../../utils/reactive-form/form-validators';

// TODO(milan): cleanup needed because there might be calls which are not needed
@Component({
  selector: 'app-form-control-error',
  templateUrl: './form-control-error.component.html',
  styleUrls: ['./form-control-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormControlErrorComponent {
  @Input() errors: CustomValidationErrors;

  listOfErrors: FormValidationErrorKeysEnum[];

  FormValidationErrorKeysEnum = FormValidationErrorKeysEnum;

  constructor(private changeDetector: ChangeDetectorRef) {}

  ngOnChanges(changes: TSimpleChanges<FormControlErrorComponent>): void {
    if (this.errors) {
      this.listOfErrors = Object.keys(
        this.errors,
      ) as FormValidationErrorKeysEnum[];
    } else {
      this.listOfErrors = [];
    }
  }
}
