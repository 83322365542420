import {Injectable} from '@angular/core';
import {Holiday} from '../../models';
import {IHolidayDataService} from '../../interfaces/data-service';
import {BaseCrudOfflineService} from './base.offline-service';

@Injectable()
export class HolidayOfflineService
  extends BaseCrudOfflineService<Holiday>
  implements IHolidayDataService
{
  public weekendOrHoliday(date: Date): Promise<boolean> {
    return Promise.reject('Offline service - not yet implemented');
  }
}
