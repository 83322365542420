import DevExpress from 'devextreme';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {DataProvider} from '../../data.provider/data-provider';
import {filter, map} from 'rxjs/operators';
import {sortByDate, sortByKey} from '../../globals';
import {TreeBase} from '../../utils/tree-base';
import {TSimpleChanges} from '../../utils/angular.utils';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {FileUtils} from '@retrixhouse/salesapp-shared/lib/utils';

@Component({
  selector: 'app-simple-file-list',
  templateUrl: './simple-file-list.component.html',
  styleUrls: ['./simple-file-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimpleFileListComponent implements OnInit {
  @Input() items: SimpleFileListItem[];
  @Input() openedItems: {[k: string]: boolean};
  @Input() sorting: SimpleFileListSorting;

  @Output() itemClicked = new EventEmitter<any>();

  list$: Observable<DevExpress.ui.dxList.Item[]>;

  private treeHelper$ = new BehaviorSubject<FilesTree>(null);
  private sorting$ = new BehaviorSubject<SimpleFileListSorting>({
    sortBy: 'name',
    sort: 'asc',
  });

  get treeHelper() {
    return this.treeHelper$.getValue();
  }

  constructor(
    private dataProvider: DataProvider,
    private changeDetector: ChangeDetectorRef,
  ) {}

  ngOnChanges(changes: TSimpleChanges<SimpleFileListComponent>): void {
    if (changes.items && changes.items.currentValue) {
      this.createTreeFromItems();
    }

    if (changes.sorting && changes.sorting.currentValue) {
      this.sorting$.next(changes.sorting.currentValue);
    }
    if (changes.openedItems) {
      this.changeDetector.detectChanges();
    }
  }

  ngOnInit(): void {
    this.generateListItems();
  }

  // NOTE: event type any is there beacuse dx has no event type it is just plain object
  onItemClick(event: any) {
    this.itemClicked.emit({
      itemData: event.itemData,
    });
  }

  getFolderData(folderId: string) {
    return this.treeHelper.getFolderData(folderId);
  }

  getVisitedInfo(id: string): boolean {
    return this.openedItems?.[id] || false;
  }

  private createTreeFromItems() {
    this.treeHelper$.next(new FilesTree(this.items));
  }

  private generateListItems() {
    this.list$ = combineLatest([this.treeHelper$, this.sorting$]).pipe(
      filter(([treeHelper, sorting]) => !!treeHelper),
      map(([treeHelper, sorting]) => {
        const flattenedTree = treeHelper.flattenTrees(treeHelper.trees);
        const flattenedTreeWithFiles = [
          ...flattenedTree,
          // NOTE: it is for files which don't have parent id
          {parentId: null, isFolder: true, id: null, name: '../'},
        ]
          .map(folder => {
            const folderFiles = treeHelper.folderFilesMap.get(folder.id) || [];
            return {
              ...folder,
              children: this.sortArrayBySetting(
                [
                  ...folderFiles.map(file => ({
                    ...file,
                    icon: FileUtils.getFileIcon(file.fileType, 'solid'),
                    name: file.name,
                  })),
                ],
                sorting,
              ),
            };
          })
          .filter(folder => folder.children.length);

        const sortedFolders =
          sorting.sortBy === 'uploadedAt'
            ? flattenedTreeWithFiles
            : this.sortArrayBySetting(flattenedTreeWithFiles, sorting);

        const formattedItems = sortedFolders.map(item => {
          const {children, ...folder} = item;
          return {
            key: folder.id,
            items: children,
          };
        });

        return formattedItems;
      }),
    );
  }

  private sortArrayBySetting(array: any[], sorting: SimpleFileListSorting) {
    if (sorting.sortBy === 'uploadedAt') {
      return sortByDate(array, 'uploadedAt', sorting.sort === 'asc');
    }

    return sortByKey(array, 'name', sorting.sort === 'asc');
  }
}

export interface SimpleFileListItem<T = any> {
  parentId: string | null;
  isFolder: boolean;
  data?: T;
  id: string;
  name: string;
  fileType?: string;
  uploadedAt?: string;
}

export interface SimpleFileListSorting {
  sortBy: 'name' | 'uploadedAt';
  sort: 'asc' | 'desc';
}

export interface SimpleFileListItemClicked<T = any> {
  itemData: SimpleFileListItem<T>;
}

export type TreeNode<T extends {parentId: string; id: string}> = T & {
  id: string;
  children: any[];
};

export type LeveledTreeNode<T extends {parentId: string; id: string}> =
  TreeNode<T> & {level: number};

class FilesTree extends TreeBase<SimpleFileListItem> {
  trees: (SimpleFileListItem & {children: SimpleFileListItem[]})[];
  folderFilesMap = new Map<string, any[]>();
  foldersMap = new Map<string, SimpleFileListItem>();
  folderTrees: (SimpleFileListItem & {children: SimpleFileListItem[]})[];

  get parents() {
    return this._parentsMap;
  }

  constructor(items: SimpleFileListItem[]) {
    super(items);
    const updatedItems = items.map(item => {
      if (item.isFolder) {
        const path = this.findPathToRoot(item.id);
        const absoluteName = path
          .map(path => path.name)
          .filter(name => !!name)
          .join(' / ');
        return {
          ...item,
          name: absoluteName
            ? `../ ${absoluteName} / ${item.name}`
            : `../ ${item.name}`,
        };
      }
      return item;
    });
    this.trees = this.createTrees(
      updatedItems.filter(item => item.isFolder),
      this.createFileTree,
    );
    this.processItems(updatedItems);
  }

  getFolderData(folderId: string) {
    if (folderId) {
      return this.foldersMap.get(folderId);
    }
    // NOTE: it is for files which don't have parent id
    return {parentId: null, isFolder: true, id: null, name: '../'};
  }

  flattenTrees(trees: any) {
    const flattened = this.flattenTree({id: '', children: trees} as any);
    // removes dummy root provided in flatten tree in line above
    flattened.shift();
    return flattened;
  }

  flattenTree(
    root: TreeNode<SimpleFileListItem>,
    level = 0,
  ): LeveledTreeNode<SimpleFileListItem>[] {
    const flatten = [{...root, level} as LeveledTreeNode<SimpleFileListItem>];

    if (root.children && root.children.length > 0) {
      return [
        ...flatten,
        ...root.children
          .map(child => this.flattenTree(child, level + 1))
          .reduce(
            (a, b) => [...a, ...b],
            [] as LeveledTreeNode<SimpleFileListItem>[],
          ),
      ];
    } else {
      return flatten;
    }
  }

  cleanTrees() {
    return this.trees.filter(tree => this.cleanTree(tree));
  }

  cleanTree(node: TreeNode<SimpleFileListItem>) {
    let found = false;
    if (node.children.length) {
      found = true;
    }

    if (node.children.length) {
      [...node.children].forEach(childNode => {
        if (this.cleanTree(childNode)) {
          found = true;
        } else {
          node.children.splice(node.children.indexOf(childNode), 1);
        }
      });
    }

    return found;
  }

  findPathToRoot(id: string): SimpleFileListItem[] | undefined {
    let i = this._itemsMap.get(id);
    if (i) {
      const path = [];
      let p = this._parentsMap.get(i.id);
      while (p) {
        path.unshift(p);
        i = this._itemsMap.get(p.id);
        p = i ? this._parentsMap.get(i.id) : undefined;
      }
      return path;
    }
  }

  private processItems(items: SimpleFileListItem[]) {
    items.forEach(item => {
      if (item.isFolder) {
        this.addToFoldersMap(item);
      } else {
        this.addToFolderFilesMap(item);
      }
    });
  }

  private addToFoldersMap(folder: SimpleFileListItem) {
    this.foldersMap.set(folder.id, folder);
  }

  private addToFolderFilesMap(file: SimpleFileListItem) {
    const folderId = file.parentId;
    if (this.folderFilesMap.has(folderId)) {
      const folderFiles = this.folderFilesMap.get(folderId);
      this.folderFilesMap.set(folderId, [...folderFiles, file]);
    } else {
      this.folderFilesMap.set(folderId, [file]);
    }
  }

  private createTrees<T extends {id: string; parentId: string}>(
    files: T[],
    createNodeFn: (file: T) => TreeNode<SimpleFileListItem>,
  ) {
    const roots = [];
    const nodes = [];
    const fileIdToNodeIndex = {};

    files.forEach((file, index) => {
      fileIdToNodeIndex[file.id] = index;
      nodes.push(createNodeFn(file));
    });
    files.forEach(file => {
      const node = nodes[fileIdToNodeIndex[file.id]];
      if (file.parentId === null) {
        roots.push(node);
      } else {
        nodes[fileIdToNodeIndex[file.parentId]].children.push(node);
      }
    });

    return roots;
  }

  private createFileTree(
    item: SimpleFileListItem,
  ): SimpleFileListItem & {children: any[]} {
    const {id, name, isFolder, parentId} = item;
    return {
      ...item,
      id,
      name,
      parentId,
      isFolder,
      children: [],
    };
  }
}
