import {DIALOG_DATA, DialogRef} from '@angular/cdk/dialog';
import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {ObjectTypeIds} from '@retrixhouse/salesapp-shared/lib/common';
import {
  IObjectFormGooglePlacesControlSetting,
  IObjectFormInputControl,
  IObjectFormLocationControlSetting,
  IObjectProperty,
  ObjectFormInputControlTypeEnum,
  ValueType,
} from '@retrixhouse/salesapp-shared/lib/models';
import {InputSelectOption} from '@salesapp/components';
import {sortByKey} from '@salesapp/shared/globals';
import {InputSelectOptions} from '@salesapp/utils/input-select-options';
import {CustomValidators} from '@salesapp/utils/reactive-form/form-validators';
import {FormControlsOf} from '@salesapp/utils/reactive-form/reactive-form.interface';
import {getTranslationMarkersForObjectProperty} from '@salesapp/utils/translation.utils';
import {Observable, combineLatest} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {FormLayoutService} from '../../services/form-layout.service';

@Component({
  selector: 'app-form-designer-update-control-dialog',
  templateUrl: './form-designer-update-control-dialog.component.html',
  styleUrls: ['./form-designer-update-control-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormDesignerUpdateControlDialogComponent {
  form: FormGroup<FormDesignerUpdateControlDialogFormControls>;

  tabs: {id: 'settings' | 'advancedSettings'; text: string}[];
  selectedTab: 'settings' | 'advancedSettings' = 'settings';

  inputTypeOptions: InputSelectOption[];

  propertyOptions$: Observable<InputSelectOption[]>;
  propertiesById$: Observable<Map<string, IObjectProperty>>;
  placeholder$: Observable<string>;

  get formControlPropertyId() {
    return this.data.formControl.propertyId;
  }

  get objectTypeId() {
    return this.formLayoutService.objectTypeId;
  }

  get tabsVisible() {
    return [
      ObjectFormInputControlTypeEnum.GoogleLocationId,
      ObjectFormInputControlTypeEnum.Location,
    ].includes(this.data.formControl.inputType);
  }

  get googlePlacesSettingsFormGroup() {
    return this.form.controls
      .settings as FormGroup<FormDesignerObjectFormGooglePlacesControlSettingFormControls>;
  }

  get locationPlacesSettingsFormGroup() {
    return this.form.controls
      .settings as FormGroup<FormDesignerObjectFormLocationControlSettingFormControls>;
  }

  get controlType() {
    return this.data.formControl.inputType;
  }

  ObjectFormInputControlTypeEnum = ObjectFormInputControlTypeEnum;

  constructor(
    private dialogRef: DialogRef<void>,
    @Inject(DIALOG_DATA) private data: FormDesignerUpdateControlDialogData,
    private formBuilder: FormBuilder,
    private formLayoutService: FormLayoutService,
    private translateService: TranslateService,
  ) {}

  ngOnInit() {
    this.initForm();
    this.initPropertyOptions();
    this.initInputTypeOptions();
    this.initPlaceholder();
    this.initTabs();
  }

  onClose() {
    this.dialogRef.close();
  }

  onSave() {
    this.formLayoutService.updateFormControl(this.form.getRawValue());
    this.dialogRef.close();
  }

  onTabClick(event) {
    this.selectedTab = event.itemData.id;
  }

  getFormControl(name: keyof IObjectFormInputControl) {
    return this.form.get(name) as FormControl;
  }

  private initForm() {
    const form =
      this.formBuilder.group<FormDesignerUpdateControlDialogFormControls>({
        id: this.formBuilder.control(null, [CustomValidators.required]),
        label: this.formBuilder.control(null),
        hint: this.formBuilder.control(null),
        propertyId: this.formBuilder.control(null, [CustomValidators.required]),
        inputType: this.formBuilder.control({value: null, disabled: true}, [
          CustomValidators.required,
        ]),
        visibilityExpression: this.formBuilder.control(null),
        validationExpression: this.formBuilder.control(null),
        requiredExpression: this.formBuilder.control(null),
        readonlyExpression: this.formBuilder.control(null),
        // settings: IObjectFormGooglePlacesControlSetting | IObjectFormLocationControlSetting;
      });
    if (
      this.data.formControl.inputType ===
      ObjectFormInputControlTypeEnum.Location
    ) {
      form.controls.propertyId.disable();
      form.controls.propertyId.addValidators([]);
    }

    if (
      this.data.formControl.inputType ===
      ObjectFormInputControlTypeEnum.Location
    ) {
      const locationFormGroup: FormGroup<FormDesignerObjectFormLocationControlSettingFormControls> =
        this.formBuilder.group<FormDesignerObjectFormLocationControlSettingFormControls>(
          {
            longitudePropertyId: this.formBuilder.control(null, [
              CustomValidators.required,
            ]),
            latitudePropertyId: this.formBuilder.control(null, [
              CustomValidators.required,
            ]),
            altitudePropertyId: this.formBuilder.control(null),
          },
        );
      form.addControl(
        'settings' as keyof IObjectFormInputControl,
        locationFormGroup,
      );
    }

    if (
      this.data.formControl.inputType ===
      ObjectFormInputControlTypeEnum.GoogleLocationId
    ) {
      const googlePlacesFormGroup: FormGroup<FormDesignerObjectFormGooglePlacesControlSettingFormControls> =
        this.formBuilder.group<FormDesignerObjectFormGooglePlacesControlSettingFormControls>(
          {
            longitude: this.formBuilder.control(null),
            latitude: this.formBuilder.control(null),
            address: this.formBuilder.control(null),
            openingHours: this.formBuilder.control(null),
          },
        );
      form.addControl(
        'settings' as keyof IObjectFormInputControl,
        googlePlacesFormGroup,
      );
    }

    if (this.data.formControl) {
      form.patchValue(this.data.formControl, {emitEvent: true});

      if (this.formControlPropertyId) {
        form.get('propertyId').disable();
      }
    }

    this.form = form;
  }

  private initTabs() {
    this.tabs = [
      {
        id: 'settings',
        text: this.translateService.instant(
          'form-designer.dialog.edit-form-control.tabs.settings',
        ),
      },
      {
        id: 'advancedSettings',
        text: this.translateService.instant(
          'form-designer.dialog.edit-form-control.tabs.advanced-settings',
        ),
      },
    ];
  }

  private initPlaceholder() {
    this.placeholder$ = combineLatest([
      this.formLayoutService.propertiesById$.pipe(startWith(null)),
      this.form.controls.propertyId.valueChanges.pipe(startWith(null)),
    ]).pipe(
      map(([propertiesById, propertyId]) => {
        if (!propertiesById) {
          return null;
        }

        if (propertyId || this.data.formControl.propertyId) {
          const property = propertiesById.get(
            propertyId || this.data.formControl.propertyId,
          );
          return this.translateService.instant(
            getTranslationMarkersForObjectProperty({
              objectTypeId: this.objectTypeId,
              propertyName: property.name,
            }).label,
          );
        }

        return null;
      }),
    );
  }

  private initPropertyOptions() {
    this.propertyOptions$ = combineLatest([
      this.formLayoutService.availableProperties$,
      this.formLayoutService.allProperties$,
    ]).pipe(
      map(([availableProperties, allProperties]) => {
        const options = this.filterPropertiesForControlType(
          availableProperties,
          this.controlType,
        ).map(property => ({
          value: property.id,
          name: this.translateService.instant(
            getTranslationMarkersForObjectProperty({
              objectTypeId: this.objectTypeId,
              propertyName: property.name,
            }).label,
          ),
          data: property,
        }));

        if (this.formControlPropertyId) {
          const currentControlProperty = allProperties.find(
            property => property.id === this.formControlPropertyId,
          );

          const currentControlPropertyOption = {
            value: currentControlProperty.id,
            name: this.translateService.instant(
              getTranslationMarkersForObjectProperty({
                objectTypeId: this.objectTypeId,
                propertyName: currentControlProperty.name,
              }).label,
            ),
            data: currentControlProperty,
          };

          return sortByKey([...options, currentControlPropertyOption], 'name');
        }

        return sortByKey(options, 'name');
      }),
    );
  }

  private filterPropertiesForControlType(
    properties: IObjectProperty[],
    controlType: ObjectFormInputControlTypeEnum,
  ) {
    switch (controlType) {
      case ObjectFormInputControlTypeEnum.Address:
        return properties.filter(
          property => property.objectTypeId === ObjectTypeIds.Address,
        );
      default:
        return properties.filter(property =>
          CONTROL_TYPE_COMPATIBILITY[controlType].includes(property.valueType),
        );
        break;
    }
  }

  private initInputTypeOptions() {
    this.inputTypeOptions = InputSelectOptions.inputTypeOptions(
      this.translateService,
    );
  }
}

export interface FormDesignerUpdateControlDialogData {
  formControl?: IObjectFormInputControl;
}

type FormDesignerUpdateControlDialogFormControls =
  FormControlsOf<IObjectFormInputControl>;

export type FormDesignerObjectFormLocationControlSettingFormControls =
  FormControlsOf<IObjectFormLocationControlSetting>;

export type FormDesignerObjectFormGooglePlacesControlSettingFormControls =
  FormControlsOf<IObjectFormGooglePlacesControlSetting>;

const CONTROL_TYPE_COMPATIBILITY: {
  [k in ObjectFormInputControlTypeEnum]: string[];
} = {
  [ObjectFormInputControlTypeEnum.Address]: [],
  [ObjectFormInputControlTypeEnum.Avatar]: [ValueType.PictureUrl],
  [ObjectFormInputControlTypeEnum.Date]: [ValueType.Date],
  [ObjectFormInputControlTypeEnum.Datetime]: [ValueType.DateTime],
  [ObjectFormInputControlTypeEnum.DateRange]: [ValueType.Duration],
  [ObjectFormInputControlTypeEnum.GoogleLocationId]: [ValueType.PlainText],
  [ObjectFormInputControlTypeEnum.Location]: [],
  [ObjectFormInputControlTypeEnum.MultiSelect]: [
    ValueType.MultiChoice,
    ValueType.RelationToManyForeignKeys,
  ],
  [ObjectFormInputControlTypeEnum.Number]: [ValueType.Integer],
  [ObjectFormInputControlTypeEnum.OpeningHours]: [],
  [ObjectFormInputControlTypeEnum.RichText]: [ValueType.RichText],
  [ObjectFormInputControlTypeEnum.Select]: [
    ValueType.SingleChoice,
    ValueType.Enum,
    ValueType.RelationToOneForeignKey,
  ],
  [ObjectFormInputControlTypeEnum.Switch]: [ValueType.Boolean],
  [ObjectFormInputControlTypeEnum.Tags]: [ValueType.MultiChoice],
  [ObjectFormInputControlTypeEnum.Text]: [ValueType.PlainText],
};
