import * as uuid from 'uuid';
import {UsernameResponse} from './username-response.model';

export class NewsPost {
  id: string;
  uid: string;
  version: number;
  authorId: string;
  published: boolean;
  important: boolean;
  validUntil: Date;
  createdAt: Date;
  caption: string;
  body: string;
  read?: boolean;
  projectIds?: string[];
  chainIds?: string[];
  positionIds?: string[];
  userIds?: string[];
  storeIds?: string[];

  constructor() {
    this.id = uuid.v4();
    this.authorId = null;
    this.published = false;
    this.important = false;
    this.validUntil = null;
    this.createdAt = null;
    this.caption = null;
    this.body = null;
  }
}

export class NewsPostWithUserInfo extends NewsPost {
  userInfo?: UsernameResponse;
}
