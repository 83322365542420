import {Injectable} from '@angular/core';
import {ObjectTypeIds} from '@retrixhouse/salesapp-shared/lib/common';
import {ChainStorageService} from './chain-storage.service';
import {CountryStorageService} from './country-storage.service';
import {CurrencyStorageService} from './currency-storage.service';
import {CustomerStorageService} from './customer-storage.service';
import {OldBaseStorageService} from './old-base-storage-service';
import {ProductCategoryStorageService} from './product-category-storage.service';
import {UnitStorageService} from './unit-storage.service';
import {PositionStorageService} from './position-storage.service';
import {LocaleStorageService} from './locale-storage.service';
import {UserStorageService} from './user-storage.service';

@Injectable()
export class StorageService {
  constructor(
    private chainStorageService: ChainStorageService,
    private countryStorageService: CountryStorageService,
    private currencyStorageService: CurrencyStorageService,
    private customerStorageService: CustomerStorageService,
    private localeStorageService: LocaleStorageService,
    private positionStorageService: PositionStorageService,
    private productCategoryStorageService: ProductCategoryStorageService,
    private unitStorageService: UnitStorageService,
    private userStorageService: UserStorageService,
  ) {}

  getStorageByObjectTypeId(
    objectTypeId: ObjectTypeIds,
  ): OldBaseStorageService<unknown, unknown, unknown> {
    const map = {
      [ObjectTypeIds.Chain]: this.chainStorageService,
      [ObjectTypeIds.Country]: this.countryStorageService,
      [ObjectTypeIds.Currency]: this.currencyStorageService,
      [ObjectTypeIds.Customer]: this.customerStorageService,
      [ObjectTypeIds.Position]: this.positionStorageService,
      [ObjectTypeIds.Locale]: this.localeStorageService,
      [ObjectTypeIds.ProductCategory]: this.productCategoryStorageService,
      [ObjectTypeIds.Unit]: this.unitStorageService,
      [ObjectTypeIds.User]: this.userStorageService,
    };

    return map[objectTypeId];
  }
}
