import {CommonModule} from '@angular/common';
import {Component, EventEmitter, Input, NgModule, Output} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {DevExtremeModule} from 'devextreme-angular';
import {Project, ReportRangeRequest} from '../../models';

@Component({
  selector: 'app-report-range-selector',
  templateUrl: './report-range-selector.component.html',
  styleUrls: ['./report-range-selector.component.scss'],
})
export class ReportRangeSelector {
  /**
   * List of projects.
   */
  @Input() projects: Project[] = [];
  /**
   * Report range.
   */
  @Input() range: ReportRangeRequest = new ReportRangeRequest();
  /**
   * Emits when search button is pressed.
   */
  @Output() search: EventEmitter<ReportRangeRequest> =
    new EventEmitter<ReportRangeRequest>();

  formatProject = Project.formatProject;
  constructor() {
    this.btnSearchClick = this.btnSearchClick.bind(this);
  }

  btnSearchClick(e): void {
    this.search.emit(this.range);
  }
}

@NgModule({
  imports: [DevExtremeModule, CommonModule, TranslateModule],
  declarations: [ReportRangeSelector],
  exports: [ReportRangeSelector],
})
export class ReportRangeSelectorModule {}
