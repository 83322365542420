<div class="user-panel">
  <div class="user-info">
    <div class="image-container">
      <div class="user-image" [style]="userImageStyle"></div>
    </div>
    <div class="user-name" fxHide fxShow.gt-sm>{{ user.firstName }} {{ user.lastName }} <i class="fa fa-angle-down" style="margin-left: 10px"></i></div>
  </div>
  <dx-context-menu
    *ngIf="menuMode === 'context'"
    [items]="menuItems"
    target=".user-button"
    showEvent="dxclick"
    width="210px"
    [position]="{my: {y: 'top', x: 'center'}, at: {y: 'bottom', x: 'center'}}"
    cssClass="user-menu"
    (onShowing)="onMenuShowing($event)"
  >
  </dx-context-menu>
  <dx-list *ngIf="menuMode === 'list'" class="dx-toolbar-menu-action" [items]="menuItems"> </dx-list>
</div>
