<div class="store-template-wrapper dx-clearfix {{ size }}">
  <div class="store-template float-left"
       [ngClass]="{'with-action-button': showActionButton}">
    <img loading="lazy"
         *ngIf="storeSettings[settingsEnum.StoreAvatar_ShowChainLogo]"
         class="store-avatar-image-{{ size }}"
         [src]="pictureUrl" />
    <div class="store-selector-body">
      <ng-container *ngIf="storeDisplayText$ | async; let storeDisplayText">
        <div class="store-line1"
             [ngClass]="{'store-line1-bold': storeSettings[settingsEnum.StoreAvatar_Line1Bold]}">
          {{ storeDisplayText.line1 }}
        </div>
        <div *ngIf="storeDisplayText.line2"
             class="store-line2">
          {{ storeDisplayText.line2 }}
        </div>
        <small *ngIf="showPeriodicLimitSummary && store.periodicLimit"
               class="store-periodic-limit">
          <span>(</span>
          <ng-container *ngIf="store.periodicLimit.name">{{ store.periodicLimit.name }}:</ng-container>
          {{ store.periodicLimit.actual }} / {{ store.periodicLimit.expected }})
        </small>
      </ng-container>
    </div>
  </div>
  <div *ngIf="showActionButton"
       class="floating-action-buttons">
    <ng-container *ngIf="showActionButton === 'add'">
      <dx-button stylingMode="text"
                 icon="fa-regular fa-plus-circle"
                 type="default"
                 (onClick)="actionButtonClicked()"></dx-button>
    </ng-container>
    <ng-container *ngIf="showActionButton === 'remove'">
      <dx-button stylingMode="text"
                 icon="fa-regular fa-square-xmark"
                 type="danger"
                 (onClick)="actionButtonClicked()"></dx-button>
    </ng-container>
  </div>
</div>