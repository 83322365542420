import {Injectable} from '@angular/core';
import {AppInfoService} from './app-info.service';
import {AppInterfaceService} from '../app-interface/app-interface.service';
import {GenericListIds} from '../globals';
import {DataProvider} from '../data.provider/data-provider';
import {SettingNames} from '@retrixhouse/salesapp-shared/lib/settings';

@Injectable()
export class BarcodeService {
  constructor(
    private appInfoService: AppInfoService,
    private appInterfaceService: AppInterfaceService,
    private dataProvider: DataProvider,
  ) {}

  public async scanBarcode(projectId?: string): Promise<string> {
    // check if device is mobile device
    if (!this.appInfoService.isMobileVersion) {
      return null;
    }

    const [barCodeTypes, allowedBarcodeIds] = await Promise.all([
      this.dataProvider.genericList.getSingle(
        GenericListIds.AllowedBarCodeTypes,
      ),
      this.dataProvider.settingResolver.getValue(
        SettingNames.Common_AllowedBarCodes,
        projectId,
      ),
    ]);

    const allowedBarcodes = (allowedBarcodeIds ?? [])
      .map(bc => {
        return barCodeTypes.items.find(i => i.id === bc)?.name;
      })
      .filter(i => !!i);

    return this.appInterfaceService.scanBarcode(allowedBarcodes);
  }
}
