import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {AdminUtilityMenuComponent} from './admin-utility-menu.component';
import {DxDropDownButtonModule} from 'devextreme-angular';

const DX_MODULES = [DxDropDownButtonModule];

@NgModule({
  imports: [CommonModule, ...DX_MODULES],
  declarations: [AdminUtilityMenuComponent],
  providers: [],
  exports: [AdminUtilityMenuComponent],
})
export class AdminUtilityMenuModule {}
