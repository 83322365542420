import {IRequestLogDataService} from '../../interfaces/data-service';
import {RequestLog} from '../../models';
import {RequestLogHttpService} from '../http';
import {BaseDataService} from './base.data-service';

export class RequestLogDataService extends BaseDataService<IRequestLogDataService> {
  constructor(onlineService: RequestLogHttpService) {
    super(onlineService);
  }

  public getLogs(searchOptions: {
    userIds?: string[];
    from?: Date;
    to?: Date;
    fullText?: string;
    limit?: number;
  }): Promise<RequestLog[]> {
    return this.service.getLogs(searchOptions);
  }
}
