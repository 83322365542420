import {Injectable} from '@angular/core';
import {DataProvider} from '../../data.provider/data-provider';
import {OldBaseStorageService} from './old-base-storage-service';
import {Filter} from '@loopback/filter';
import {from, Observable} from 'rxjs';
import {IUserProfile} from '@retrixhouse/salesapp-shared/lib/models';
import {UserDataService} from '../data';
import {map} from 'rxjs/operators';
import {arrayToMap} from '@salesapp/utils/utils';

@Injectable()
export class UserProfileStorageService extends OldBaseStorageService<
  IUserProfile,
  IUserProfile[]
> {
  get userDataService() {
    return this.dataService as UserDataService;
  }
  constructor(public dataProvider: DataProvider) {
    super(dataProvider.user, undefined, DEFAULT_FILTER);
  }

  dataByUserId$: Observable<Map<string, IUserProfile>> = this.data$.pipe(
    map(data => {
      return arrayToMap(data as any[], 'userId');
    }),
  );

  getData(input?: {filter?: Filter}): Observable<IUserProfile[]> {
    return from(
      (this.dataService as UserDataService).getUserProfiles(
        (input?.filter as Filter<any>) || this.filter,
        this.cachingOptions,
      ),
    );
  }

  updateData(input: {id: string; data: Partial<IUserProfile>}) {
    return (this.dataService as UserDataService).updateUserProfile(
      input.id,
      input.data,
    );
  }

  updateUserProfilePicture(input: {
    id: string;
    version: number;
    pictureUrl: string;
  }) {
    return this.command({
      commandMethod$: this.updateUserProfilePictureMethod.bind(this),
      commandInput: input,
      commandType: 'update',
    });
  }

  private updateUserProfilePictureMethod(input: {
    id: string;
    version: number;
    pictureUrl: string;
  }) {
    return this.userDataService.updateUserProfilePicture(
      input.id,
      input.version,
      input.pictureUrl,
    );
  }
}

const DEFAULT_FILTER: Filter<IUserProfile> = {
  include: [
    {relation: 'user'},
    {relation: 'homeAddress'},
    {relation: 'correspondenceAddress'},
  ],
};
