import {Injectable} from '@angular/core';
import {IJsonSchemaDataService} from '../../interfaces/data-service';
import {BaseOfflineService} from './base.offline-service';

@Injectable()
export class JsonSchemaOfflineService
  extends BaseOfflineService
  implements IJsonSchemaDataService
{
  public getOpenApiDefinition(): Promise<any> {
    return Promise.reject('Offline service - not yet implemented');
  }
}
