<dx-drawer [elementAttr]="{id: 'navigation-menu-drawer'}"
           class="drawer"
           position="before"
           [closeOnOutsideClick]="shaderEnabled"
           [openedStateMode]="menuMode"
           [revealMode]="menuRevealMode"
           [minSize]="minMenuSize"
           [shading]="shaderEnabled"
           [(opened)]="menuOpened">
  <ng-container *dxTemplate="let dataMenu of 'panel'">
    <app-side-navigation-menu *ngIf="!isFullScreen"
                              [(compactMode)]="!menuOpened"
                              [selectedItem]="selectedRoute"
                              class="dx-swatch-additional"
                              (selectedItemChanged)="navigationChanged($event)"
                              (openMenu)="navigationClick()">
      <dx-toolbar id="navigation-header">
        <dxi-item *ngIf="minMenuSize !== 0"
                  location="before"
                  cssClass="menu-button"
                  widget="dxButton"
                  [options]="{
            icon: 'menu',
            stylingMode: 'text',
            onClick: toggleMenu
          }">
        </dxi-item>
        <dxi-item location="before"
                  cssClass="header-title"
                  [text]="title">
        </dxi-item>
      </dx-toolbar>
    </app-side-navigation-menu>
  </ng-container>

  <div class="container">
    <app-header *ngIf="!isFullScreen"
                [menuToggleEnabled]="minMenuSize === 0"
                (menuToggle)="menuOpened = !menuOpened">
    </app-header>

    <dx-scroll-view class="layout-body with-footer"
                    (onScroll)="onAppContentScroll($event)">
      <div class="content">
        <ng-content></ng-content>
      </div>

      <ng-container *ngIf="footerVisible">
        <div class="content-block"
             *ngIf="!isFullScreen">
          <ng-content select="app-footer"></ng-content>
        </div>
      </ng-container>
    </dx-scroll-view>
  </div>
</dx-drawer>