import {CommonModule} from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {TranslateModule} from '@ngx-translate/core';
import {DevExtremeModule} from 'devextreme-angular';
import {Tag} from '../../models';
import {DxTreeViewComponent} from 'devextreme-angular/ui/tree-view';
import {Node} from 'devextreme/ui/tree_view';
import {
  IProjectTag,
  ProjectTagSelectionMode,
} from '@retrixhouse/salesapp-shared/lib/models';
import {CustomPipesModule} from '../../pipes';
import {TSimpleChanges} from '../../utils/angular.utils';

type LocalTag = Tag & {
  expanded: boolean;
  selected: boolean;
  disabled?: boolean;
  hasItems?: boolean;
};

@Component({
  selector: 'app-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss'],
})
export class TagComponent implements OnInit, OnChanges {
  @Input() tags: Tag[];
  @Input() selectedTags: string[] = [];
  @Input() tagProperties: IProjectTag[] = [];
  @Input() readOnly = false;
  /**
   * When set to `true`, ignores the validation of the photo tags.
   */
  @Input() disablePhotoTagsValidation: boolean = false;
  @Output() onSelectionChanged = new EventEmitter<Tag[]>();

  @ViewChild(DxTreeViewComponent, {static: false})
  treeView: DxTreeViewComponent;

  _localTags: LocalTag[];

  errorKeys: Set<string>;

  constructor() {}

  ngOnInit(): void {
    this.errorKeys = new Set<string>();
  }

  ngOnChanges(changes: TSimpleChanges<SimpleChanges>): void {
    if (this.tags && this.selectedTags && this.tagProperties) {
      const tags = this.tags ?? [];
      const selectedTags = this.selectedTags ?? [];
      const tagProperties = this.tagProperties ?? [];

      this._localTags = tags
        .filter(t => this.useTag(t, tags, tagProperties))
        .sort((a, b) => {
          const positionA =
            tagProperties.find(pt => pt.tagId === a.id)?.position ?? a.position;
          const positionB =
            tagProperties.find(pt => pt.tagId === b.id)?.position ?? b.position;

          return positionA - positionB;
        })
        .map(t => {
          return {
            ...t,
            expanded: true,
            selected: selectedTags.includes(t.id),
            disabled: false,
            hasItems: tags.some(i => i.parentId === t.id),
          };
        });
    }
  }

  useTag(
    candidate: Tag,
    allTags: Tag[],
    tagProperties: IProjectTag[],
  ): boolean {
    // if no properties were set, or is empty, all tags should be used
    if (!tagProperties || tagProperties.length === 0) {
      return false;
    }

    const projectTag = tagProperties.find(i => i.tagId === candidate.id);
    if (projectTag) {
      return true;
    }

    const tagParentIds = [];
    let parentId = candidate.parentId;
    while (parentId) {
      tagParentIds.push(parentId);
      parentId = allTags.find(t => t.id === parentId)?.parentId;
    }

    return tagProperties.some(pt => tagParentIds.includes(pt.tagId));
  }

  onContentReady(e): void {
    const rootNodes = this.treeView?.instance?.getNodes() ?? [];
    for (const root of rootNodes) {
      const rules = this.tagProperties.find(tp => tp.tagId === root.key);
      if (!rules) {
        console.warn(`No rules found for tag ${root.key}`);
        return;
      }

      let allChildren = [];
      if (rules && rules.selectionMode === ProjectTagSelectionMode.Single) {
        allChildren = this.getAllNodes([root]);
        if (allChildren.some(i => this.selectedTags.includes(i.key))) {
          allChildren
            .filter(
              n =>
                n.children.length === 0 && !this.selectedTags.includes(n.key),
            )
            .map(l => this._localTags.find(i => i.id === l.key))
            .filter(i => !!i)
            .forEach(l => {
              l.disabled = true;
              // disable checkbox
              const treeListItemElement = this.treeView.instance
                .element()
                .querySelector(`[data-item-id='${l.id}']`);
              const checkBoxElements =
                treeListItemElement.getElementsByClassName('dx-checkbox');
              const checkBoxElement =
                checkBoxElements?.length > 0 ? checkBoxElements[0] : null;

              if (checkBoxElement) {
                checkBoxElement.classList.add('dx-state-disabled');
              }
            });
        }
      }

      // check if required rule is fulfilled
      if (rules && rules.required && this.disablePhotoTagsValidation !== true) {
        // if we already used allChildren in selection mode, we do not have to load it again
        allChildren =
          allChildren.length > 0 ? allChildren : this.getAllNodes([root]);
        const atLeastOneSelected = allChildren.some(i =>
          this.selectedTags.includes(i.key),
        );
        const rootElement = this.treeView.instance
          .element()
          .querySelector(`[data-item-id='${root.key}']`);
        if (!atLeastOneSelected) {
          rootElement.classList.add('dx-invalid');
          this.errorKeys.add(root.key);
        } else {
          rootElement.classList.remove('dx-invalid');
          this.errorKeys.delete(root.key);
        }
      }
    }
  }

  onItemRendered(e): void {
    if (e.node.children?.length > 0) {
      const container = e.itemElement.parentNode;
      container.classList.remove('dx-treeview-item-with-checkbox');

      const checkbox = container.querySelector('.dx-checkbox');
      if (checkbox) {
        checkbox.style.display = 'none';
      }
    }
  }

  itemSelectionChanged(e): void {
    if (!e.event) {
      return;
    } else {
      e.event.preventDefault();
    }

    this.handleNodeChange(e.node);
  }

  itemClicked(e): void {
    const node = e.node;
    // click on leaf - select / deselect item
    if (node.children?.length === 0) {
      this.toggleItemSelection(node);
      this.handleNodeChange(node);
    } else {
      // click on node - expand / collapse
      if (node.expanded) {
        this.treeView.instance.collapseItem(node.key);
      } else {
        this.treeView.instance.expandItem(node.key);
      }
    }
  }

  handleNodeChange(changedNode: Node<string>): void {
    // selection of node is not allowed for parent - return node selection to original state
    // should not happen, leave it here just to be sure
    const isParent = changedNode.children.length > 0;
    if (isParent) {
      this.toggleItemSelection(changedNode);
      return;
    }

    // need to find parent node to search for rules - only parent tag has rules associated
    let root;
    if (!changedNode.parent) {
      root = changedNode;
    } else {
      root = changedNode.parent;
      while (root?.parent) {
        root = root.parent;
      }
    }

    const rules = this.tagProperties.find(tp => tp.tagId === root.key);
    if (!rules) {
      console.warn(`No rules found for tag ${root.key}`);
    }

    // if selection mode is single - disable / enable all nodes without children
    let allNodes = [];
    if (rules && rules.selectionMode === ProjectTagSelectionMode.Single) {
      allNodes = this.getAllNodes([root]);
      allNodes
        .filter(n => n.children.length === 0 && n.key !== changedNode.key)
        .map(l => this._localTags.find(i => i.id === l.key))
        .filter(i => !!i)
        .forEach(l => {
          l.disabled = changedNode.selected;
          // disable checkbox
          const treeListItemElement = this.treeView.instance
            .element()
            .querySelector(`[data-item-id='${l.id}']`);
          const checkBoxElements =
            treeListItemElement.getElementsByClassName('dx-checkbox');
          const checkBoxElement =
            checkBoxElements?.length > 0 ? checkBoxElements[0] : null;

          if (checkBoxElement) {
            if (l.disabled) {
              checkBoxElement.classList.add('dx-state-disabled');
            } else {
              checkBoxElement.classList.remove('dx-state-disabled');
            }
          }
        });
    }

    // selected child - react on selection by selecting / deselecting parent nodes
    let parent = changedNode.parent;
    while (parent) {
      // check if parent should be selected
      this.toggleItemSelection(
        parent,
        n => n.selected && this.getNodeSelectedChildren(n).length === 0,
      );
      parent = parent.parent;
    }

    // check if required rule is fulfilled
    if (rules && rules.required && this.disablePhotoTagsValidation !== true) {
      // if we already used allChildren in selection mode, we do not have to load it again
      allNodes = allNodes.length > 0 ? allNodes : this.getAllNodes([root]);
      const selectedChildren = this.getNodeSelectedChildren(root);
      const atLeastOneSelected = allNodes.some(i =>
        selectedChildren.some(sn => sn.key === i.key),
      );
      const rootElement = this.treeView.instance
        .element()
        .querySelector(`[data-item-id='${root.key}']`);
      if (!atLeastOneSelected) {
        this.errorKeys.add(root.key);
        rootElement.classList.add('dx-invalid');
      } else {
        this.errorKeys.delete(root.key);
        rootElement.classList.remove('dx-invalid');
      }
    }

    // save result
    this.saveSelectedTags();
  }

  toggleItemSelection(node: Node<string>, predicate = n => n.selected): void {
    if (predicate(node)) {
      this.treeView.instance.unselectItem(node.key);
    } else {
      this.treeView.instance.selectItem(node.key);
    }
  }

  saveSelectedTags(): void {
    this.onSelectionChanged.emit(
      this.treeView.instance
        .getSelectedNodes()
        .map(n => n.key)
        .map(k => this.tags.find(t => t.id === k))
        .filter(i => !!i),
    );
  }

  getAllNodes(
    nodes: Array<Node<string>>,
    result: Node<string>[] = [],
  ): Node<string>[] {
    for (const node of nodes ?? []) {
      // just to be sure that there are no duplicates
      if (!result.some(rn => rn.key === node.key)) {
        result.push(node);
      }

      this.getAllNodes(node.children, result);
    }

    return result;
  }

  getNodeSelectedChildren(
    node: Node<string>,
    result: Node<string>[] = [],
  ): Node<string>[] {
    for (const child of node.children ?? []) {
      if (child.selected) {
        result.push(child);
      }

      this.getNodeSelectedChildren(child, result);
    }

    return result;
  }
}

@NgModule({
  imports: [
    DevExtremeModule,
    CommonModule,
    FlexLayoutModule,
    TranslateModule,
    CustomPipesModule,
  ],
  declarations: [TagComponent],
  exports: [TagComponent],
})
export class TagModule {}
