import {
  ChangeDetectionStrategy,
  Component,
  Input,
  TemplateRef,
} from '@angular/core';

@Component({
  selector: 'app-object-detail-dialog',
  templateUrl: './object-detail-dialog.component.html',
  styleUrls: ['./object-detail-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ObjectDetailDialogComponent {
  @Input() title: string;
  @Input() content: TemplateRef<any>;
  @Input() loading: boolean;
}
