import {Filter} from '@loopback/filter';
import {
  ObjectMapCache,
  ObjectArrayCache,
} from '@retrixhouse/salesapp-shared/lib/caching';
import {ITourPlanChangeRequestDataService} from '../../interfaces/data-service';
import {TourPlanChangeRequest} from '../../models';
import {TourPlanChangeRequestHttpService} from '../http';
import {TourPlanChangeRequestOfflineService} from '../offline';
import {
  BaseCrudDataService,
  CachingOptions,
  TTL_DAY,
} from './base.data-service';

export class TourPlanChangeRequestDataService extends BaseCrudDataService<
  TourPlanChangeRequest,
  ITourPlanChangeRequestDataService,
  ObjectMapCache<string, TourPlanChangeRequest>
> {
  private _lastGetListOperation: 'getList' | 'getMyList';

  constructor(
    onlineService: TourPlanChangeRequestHttpService,
    offlineService: TourPlanChangeRequestOfflineService,
    cache: ObjectMapCache<string, TourPlanChangeRequest>,
  ) {
    super(onlineService, offlineService, cache);
  }

  public async createMany(
    entity: Partial<TourPlanChangeRequest>,
  ): Promise<TourPlanChangeRequest[]> {
    // create object using the underlying data service
    const createdObjects = await this.service.createMany(entity);

    // update the object in the cache
    const cache = this._cache as ObjectMapCache<string, TourPlanChangeRequest>;
    createdObjects.forEach(createdObject => {
      if (cache.isValid) {
        cache.set(createdObject.id, createdObject);
      } else {
        console.warn(
          'Initializing cache after create operation with a single object.',
        );
        cache.init([[createdObject.id, createdObject]], this._defaultTtl);
      }
    });

    return createdObjects;
  }

  public override getList(
    filter?: Filter<TourPlanChangeRequest>,
    cachingOptions?: CachingOptions,
  ): Promise<TourPlanChangeRequest[]> {
    this._lastGetListOperation = 'getList';
    return super.getList(
      filter,
      Object.assign(
        {},
        {forceReload: this._lastGetListOperation !== 'getList'},
        cachingOptions,
      ),
    );
  }

  public async getMyList(
    cachingOptions?: CachingOptions,
  ): Promise<TourPlanChangeRequest[]> {
    if (cachingOptions?.skipCache) {
      return this.service.getMyTourPlanChangeRequests();
    }

    if (
      this._lastGetListOperation !== 'getMyList' ||
      cachingOptions?.forceReload
    ) {
      const myList = await this.service.getMyTourPlanChangeRequests();
      this._cache.init(
        myList.map(cr => [cr.id, cr], cachingOptions?.ttl || TTL_DAY),
      );
    }

    this._lastGetListOperation = 'getMyList';
    return this._cache.getValues();
  }
}
