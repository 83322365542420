import {ProjectUserStore, Store} from '../../models';
import {Injectable} from '@angular/core';
import {
  SetUserStoreProjectRequest,
} from '../../models/requests/set-user-store-project.request';
import {IProjectStoreDataService} from '../../interfaces/data-service';
import {BaseOfflineService} from './base.offline-service';

@Injectable()
export class ProjectUserStoreOfflineService
  extends BaseOfflineService
  implements IProjectStoreDataService
{
  public getStoresForCurrentUserInProjectContext(
    projectId: string,
  ): Promise<Store[]> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public getStoresForUserInProjectContext(
    projectId: string,
    userId: string,
    directAssignment?: boolean,
  ): Promise<Store[]> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public getUserStoresRelatedToProject(
    projectId: string,
    onlyResponsibleUsers?: boolean,
  ): Promise<ProjectUserStore[]> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public getUserStoreRelationForUser(): Promise<ProjectUserStore[]> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public saveUserStoresRelatedToProject(
    request: SetUserStoreProjectRequest,
  ): Promise<ProjectUserStore[]> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public checkAccessToStore(
    request: ProjectUserStore[],
  ): Promise<ProjectUserStore & {hasAccess: boolean}[]> {
    return Promise.reject('Offline service - not yet implemented');
  }
}
