import {BaseHttpService} from './base.http-service';
import {HttpClient} from '@angular/common/http';
import {ProjectUserStore, Store} from '../../models';
import {Injectable} from '@angular/core';
import {
  SetUserStoreProjectRequest,
} from '../../models/requests/set-user-store-project.request';
import {IProjectStoreDataService} from '../../interfaces/data-service';

@Injectable()
export class ProjectUserStoreHttpService
  extends BaseHttpService
  implements IProjectStoreDataService
{
  constructor(http: HttpClient) {
    super(http);
  }

  public getStoresForCurrentUserInProjectContext(
    projectId: string,
  ): Promise<Store[]> {
    return this.GET<Store[]>(`/project-stores/${projectId}/stores`);
  }

  public getStoresForUserInProjectContext(
    projectId: string,
    userId: string,
    directAssignment?: boolean,
  ): Promise<Store[]> {
    return this.GET<Store[]>(
      `/project-stores/${projectId}/user/${userId}/stores`,
      directAssignment ? {params: {directAssignment}} : undefined,
    );
  }

  public getUserStoresRelatedToProject(
    projectId: string,
    onlyResponsibleUsers?: boolean,
  ): Promise<ProjectUserStore[]> {
    return this.GET<ProjectUserStore[]>(
      `/project-stores/${projectId}?onlyResponsibleUsers=${
        onlyResponsibleUsers ? 'true' : 'false'
      }`,
    );
  }

  public getUserStoreRelationForUser(): Promise<ProjectUserStore[]> {
    return this.GET<ProjectUserStore[]>(`/project-stores/user-relations`);
  }

  public saveUserStoresRelatedToProject(
    request: SetUserStoreProjectRequest,
  ): Promise<ProjectUserStore[]> {
    return this.POST<ProjectUserStore[]>(`/project-stores/`, request);
  }

  public checkAccessToStore(
    request: ProjectUserStore[],
  ): Promise<ProjectUserStore & {hasAccess: boolean}[]> {
    return this.POST<ProjectUserStore & {hasAccess: boolean}[]>(
      `/project-stores/check-access`,
      request,
    );
  }
}
