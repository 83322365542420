import {AnswerType} from '@retrixhouse/salesapp-shared/lib/models';
import {convertSecondsToDuration} from '../utils/utils';
import {BaseModel} from './base.model';

export class QuestionnaireAnswer extends BaseModel {
  openedAt: Date;
  timeSpent: number;
  answer?: string;
  notes?: string;
  questionnaireResultId: string;
  questionnaireItemId: string;
  productId?: string;
  freeTextId?: string;
  highlightColor?: string;

  constructor() {
    super();
  }
}

/**
 * Prases the answer json and returns the value structured according to the answer type.
 * @param {any} answerJson - answer json.
 * @param {AnswerType} answerType - question answer type.
 * @returns {any} - the answer
 */
export const parseAnswer = (answerJson: any, answerType: AnswerType): any => {
  let parsedAnswer;
  try {
    parsedAnswer = JSON.parse(answerJson);
  } catch {
    parsedAnswer = answerJson;
  }
  let answer;
  if (
    answerType === AnswerType.Duration ||
    answerType === AnswerType.DurationRange
  ) {
    // replaceAll = .split(search).join(replacement) https://stackoverflow.com/a/1145525/1930348
    if (parsedAnswer && typeof parsedAnswer === 'object') {
      answer = {
        from: convertSecondsToDuration(parsedAnswer.from)
          .split('.')
          .join('')
          .split(':')
          .join(''),
        to: convertSecondsToDuration(parsedAnswer.to)
          .split('.')
          .join('')
          .split(':')
          .join(''),
      };
    } else if (parsedAnswer) {
      answer = convertSecondsToDuration(parsedAnswer)
        .split('.')
        .join('')
        .split(':')
        .join('');
    }
  } else if (answerType === AnswerType.DateRange) {
    if (parsedAnswer && typeof parsedAnswer === 'object') {
      answer = {
        from: parsedAnswer.from,
        to: parsedAnswer.to,
      };
    }
  } else if (
    parsedAnswer &&
    typeof parsedAnswer === 'object' &&
    Object.keys(parsedAnswer).includes('from')
  ) {
    answer = {
      from: parsedAnswer.from,
      to: parsedAnswer.to,
    };
  } else {
    answer = parsedAnswer;
  }

  return answer;
};
