import {HttpResponse} from '@angular/common/http';
import {ObjectMapCache} from '@retrixhouse/salesapp-shared/lib/caching';
import {IDataStorageFileDataService} from '../../interfaces/data-service';
import {DataStorageFile} from '../../models';
import {DataStorageFileHttpService} from '../http';
import {DataStorageFileOfflineService} from '../offline';
import {BaseCrudDataService} from './base.data-service';

export class DataStorageFileDataService extends BaseCrudDataService<
  DataStorageFile,
  IDataStorageFileDataService,
  ObjectMapCache<string, DataStorageFile>
> {
  constructor(
    onlineService: DataStorageFileHttpService,
    offlineService: DataStorageFileOfflineService,
    cache: ObjectMapCache<string, DataStorageFile>,
  ) {
    super(onlineService, offlineService, cache);
  }

  public upload(folderId: string, file: FormData): Promise<DataStorageFile> {
    return this.service.uploadDataStorageFile(folderId, file);
  }

  public download(fileId: string): Promise<any> {
    return this.service.downloadDataStorageFile(fileId);
  }

  public downloadAsZip(fileIds: string[]): Promise<HttpResponse<Blob>> {
    return this.service.downloadAsZip(fileIds);
  }

  public copy(file: DataStorageFile): Promise<any> {
    return this.service.copyDataStorageFile(file);
  }

  public getPathById(
    fileId: string,
  ): Promise<{fileName: string; filePath: string}> {
    return this.service.getFilePathById(fileId);
  }
}
