import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {DIALOG_DATA, DialogRef} from '@angular/cdk/dialog';
import {IProduct} from '@retrixhouse/salesapp-shared/lib/models';
import {map} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {ProductStorageService} from '@salesapp/storage';

@Component({
  selector: 'app-product-detail-dialog',
  templateUrl: './product-detail-dialog.component.html',
  styleUrls: ['./product-detail-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductDetailDialogComponent {
  loading$ = this.productStorageService.dataFetching$;
  product$: Observable<IProduct | null>;

  constructor(
    private dialogRef: DialogRef<ProductDetailDialogData>,
    @Inject(DIALOG_DATA) private data: ProductDetailDialogData,
    private productStorageService: ProductStorageService,
  ) {}

  ngOnInit() {
    if (this.data.productId) {
      this.product$ = this.productStorageService.dataById$.pipe(
        map(productsById => {
          return productsById.get(this.data.productId);
        }),
      );
    } else {
      this.product$ = of(null);
    }
  }

  onClose() {
    this.dialogRef.close();
  }

  onSave() {
    this.dialogRef.close();
  }
}

export interface ProductDetailDialogData {
  productId: string;
}
