import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseCrudHttpService} from './base.http-service';
import {UserTask} from '../../models/user-task.model';
import {UserTaskSearchRequest} from '../../models/requests/user-task-search.request';
import {IUserTaskDataService} from '../../interfaces/data-service';

@Injectable()
export class UserTaskHttpService
  extends BaseCrudHttpService<UserTask>
  implements IUserTaskDataService
{
  constructor(http: HttpClient) {
    super(http, '/user-task');
  }

  public getActiveUserTasksForUser(): Promise<UserTask[]> {
    return this.GET<UserTask[]>('/user-task/my-active');
  }

  public getUserTasks(body: UserTaskSearchRequest): Promise<UserTask[]> {
    return this.POST<UserTask[]>('/user-task/search', body);
  }
}
