import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {FormControl} from '@angular/forms';
import {environment} from '../../../../environments/environment';
import {IObjectFormLocationControlSetting} from '@retrixhouse/salesapp-shared/lib/models';
import {ObjectStorageService} from '@salesapp/storage';

@Component({
  selector: 'app-input-location',
  templateUrl: './input-location.component.html',
  styleUrls: ['./input-location.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputLocationComponent {
  @Input() longitudeFormControl: FormControl;
  @Input() latitudeFormControl: FormControl;
  @Input() altitudeFormControl: FormControl;

  @Input() inputFormControlSetting: IObjectFormLocationControlSetting;

  markerUrl: string;

  get apiKey() {
    return environment.googleMapApiKey;
  }

  get mapMarkerLocation() {
    return [this.latitudeFormControl.value, this.longitudeFormControl.value];
  }

  constructor(private objectStorageService: ObjectStorageService) {}

  ngOnInit() {}

  onMapClicked(event) {}

  onMapReady(e) {
    e.originalMap.setOptions({
      gestureHandling: 'cooperative',
    });
  }
}
