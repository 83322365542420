import {Injectable} from '@angular/core';
import {Comment} from '../../models/comment.model';
import {ICommentDataService} from '../../interfaces/data-service';
import {BaseCrudOfflineService} from './base.offline-service';

@Injectable()
export class CommentOfflineService
  extends BaseCrudOfflineService<Comment>
  implements ICommentDataService
{
  public getCommentListByObjectId(objectId: string): Promise<Comment[]> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public deleteCommentsForObject(objectId: string): Promise<void> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public deleteCommentList(commentIdList: string[]): Promise<void> {
    return Promise.reject('Offline service - not yet implemented');
  }
}
