export class Revision {
  id: string;
  typeId: string;
  objectId: string;
  requestId?: string;
  revision: number;
  time: Date;
  action: string;
  data: any;
}
