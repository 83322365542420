import {ObjectMapCache} from '@retrixhouse/salesapp-shared/lib/caching';
import {IKPIDataService} from '../../interfaces/data-service';
import {KPI} from '../../models';
import {KPIHttpService} from '../http';
import {KPIOfflineService} from '../offline';
import {BaseCrudDataService} from './base.data-service';

export class KPIDataService extends BaseCrudDataService<
  KPI,
  IKPIDataService,
  ObjectMapCache<string, KPI>
> {
  constructor(
    onlineService: KPIHttpService,
    offlineService: KPIOfflineService,
    cache: ObjectMapCache<string, KPI>,
  ) {
    super(onlineService, offlineService, cache);
  }
}
