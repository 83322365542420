import {Injectable, OnDestroy} from '@angular/core';
import {Location, LocationStrategy} from '@angular/common';
import {NavigationEnd, Router} from '@angular/router';
import {Subscription} from 'rxjs';

@Injectable({providedIn: 'root'})
export class NavigationService implements OnDestroy {
  private history: string[] = [];

  $routeChangeSubscription: Subscription;

  constructor(
    private router: Router,
    private location: Location,
    private locationStrategy: LocationStrategy,
  ) {
    this.$routeChangeSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.history.push(event.urlAfterRedirects);
      }
    });
  }

  ngOnDestroy(): void {
    if (this.$routeChangeSubscription) {
      this.$routeChangeSubscription.unsubscribe();
    }
  }

  preventBackButton(): void {
    history.pushState(null, null, location.href);
    this.locationStrategy.onPopState(() => {
      history.pushState(null, null, location.href);
    });
  }

  getHistory(): string[] {
    // return cloned array so we would not modify history
    return [...this.history];
  }

  getPreviousUrl(): string | null {
    if (this.history.length === 0) {
      return null;
    }

    const reversedHistory = this.getHistory().reverse();
    for (const url of reversedHistory) {
      if (url === this.router.url) {
        continue;
      }

      return url;
    }

    return null;
  }

  back(): void {
    if (this.history.length > 0) {
      this.location.back();
    } else {
      this.router.navigateByUrl('/');
    }
  }
}
