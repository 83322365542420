import {Injectable, OnDestroy} from '@angular/core';
import {
  ICache,
  ObjectArrayCache,
  ObjectMapCache,
  ObjectSingleCache,
} from '@retrixhouse/salesapp-shared/lib/caching';
import {
  IClientSideError,
  IClientSideErrorConf,
  IReportDashboard,
  ISettingValues,
} from '@retrixhouse/salesapp-shared/lib/models';
import {SettingValueResolver} from '@retrixhouse/salesapp-shared/lib/settings';
import {Subscription} from 'rxjs';
import {ClientMode} from '../enums/client-mode.enum';
import {
  Chain,
  Country,
  Currency,
  Customer,
  DataStorageFile,
  DataStorageFolder,
  GenericList,
  GenericListItem,
  Holiday,
  KPI,
  KPISet,
  Language,
  Locale,
  LocalizedIdentifier,
  LocalizedValue,
  NewsPostWithUserInfo,
  NotificationTemplate,
  ObjectProperty,
  ObjectType,
  PeriodicLimit,
  PersonalArrangement,
  Position,
  Product,
  ProductCategory,
  ProductListing,
  ProductListingTemplate,
  Project,
  PromoAction,
  Question,
  Questionnaire,
  QuestionnaireItem,
  Role,
  Scope,
  Store,
  Tag,
  TodoAction,
  TodoList,
  TourPlan,
  TourPlanChangeRequest,
  Trigger,
  Unit,
  UnitConversion,
  User,
  UserProfile,
  UsernameResponse,
} from '../models';
import {ClientModeService} from '../services';
import {
  BHSAggregatedDataService,
  BaseDataService,
  BillingInfoDataService,
  ChainDataService,
  ClientSideErrorConfDataService,
  ClientSideErrorDataService,
  CommentDataService,
  CountryDataService,
  CurrencyDataService,
  CustomerDataService,
  DataStorageFileDataService,
  DataStorageFolderDataService,
  FailSafeDataService,
  FeedbackDataService,
  GenericListDataService,
  GenericListItemDataService,
  GlobalSearchDataService,
  HolidayDataService,
  I18NDataService,
  ImexTemplateDataService,
  JsonSchemaDataService,
  KPIDataService,
  KPISetDataService,
  LanguageDataService,
  MobileDeviceDataService,
  NewsPostDataService,
  NotificationTemplateDataService,
  ObjectDataService,
  ObjectReadDataService,
  OrderDataService,
  PeriodicLimitDataService,
  PersonalArrangementDataService,
  PhotoObjectDataService,
  PingDataService,
  PositionDataService,
  ProductCategoryDataService,
  ProductDataService,
  ProductListingDataService,
  ProductListingTemplateDataService,
  ProjectDataService,
  ProjectStoreDataService,
  PromoActionDataService,
  QuestionDataService,
  QuestionnaireDataService,
  QuestionnaireItemDataService,
  ReportDashboardDataService,
  RequestLogDataService,
  RoleDataService,
  ScopeDataService,
  SettingsDataService,
  StoreDataService,
  SystemDataService,
  TagDataService,
  TodoActionDataService,
  TodoListDataService,
  TourPlanChangeRequestDataService,
  TourPlanDataService,
  TriggerDataService,
  UnitConversionDataService,
  UnitDataService,
  UserDataService,
  UserStorageDataService,
  UserTaskDataService,
  VisitDataService,
} from '../services/data';
import {
  BHSAggregatedDataHttpService,
  BillingInfoHttpService,
  ChainHttpService,
  ClientSideErrorConfHttpService,
  ClientSideErrorHttpService,
  CommentHttpService,
  CountryHttpService,
  CurrencyHttpService,
  CustomerHttpService,
  DataStorageFileHttpService,
  DataStorageFolderHttpService,
  FailSafeHttpService,
  FeedbackHttpService,
  GenericListHttpService,
  GenericListItemHttpService,
  GlobalSearchHttpService,
  HolidayHttpService,
  I18NHttpService,
  ImexTemplateHttpService,
  JsonSchemaHttpService,
  KPIHttpService,
  KPISetHttpService,
  LanguageHttpService,
  MobileDeviceHttpService,
  NewsPostHttpService,
  NotificationTemplateHttpService,
  ObjectHttpService,
  ObjectReadHttpService,
  OrderHttpService,
  PeriodicLimitHttpService,
  PersonalArrangementHttpService,
  PhotoObjectHttpService,
  PingHttpService,
  PositionHttpService,
  ProductCategoryHttpService,
  ProductHttpService,
  ProductListingHttpService,
  ProductListingTemplateHttpService,
  ProjectHttpService,
  ProjectUserStoreHttpService,
  PromoActionHttpService,
  QuestionHttpService,
  QuestionnaireHttpService,
  QuestionnaireItemHttpService,
  ReportDashboardHttpService,
  RequestLogHttpService,
  RoleHttpService,
  ScopeHttpService,
  SettingsHttpService,
  StorageAccountHttpService,
  StoreHttpService,
  SystemHttpService,
  TagHttpService,
  TodoActionHttpService,
  TodoListHttpService,
  TourPlanChangeRequestHttpService,
  TourPlanHttpService,
  TriggerHttpService,
  UnitConversionHttpService,
  UnitHttpService,
  UserHttpService,
  UserStorageHttpService,
  UserTaskHttpService,
  VisitHttpService, ReportProjectHttpService,
} from '../services/http';
import {
  BHSAggregatedDataOfflineService,
  BillingInfoOfflineService,
  ChainOfflineService,
  ClientSideErrorConfOfflineService,
  ClientSideErrorOfflineService,
  CommentOfflineService,
  CountryOfflineService,
  CurrencyOfflineService,
  CustomerOfflineService,
  DataStorageFileOfflineService,
  DataStorageFolderOfflineService,
  FeedbackOfflineService,
  GenericListItemOfflineService,
  GenericListOfflineService,
  GlobalSearchOfflineService,
  HolidayOfflineService,
  I18NOfflineService,
  ImexTemplateOfflineService,
  JsonSchemaOfflineService,
  KPIOfflineService,
  KPISetOfflineService,
  LanguageOfflineService,
  NewsPostOfflineService,
  NotificationTemplateOfflineService,
  ObjectOfflineService,
  ObjectReadOfflineService,
  OrderOfflineService,
  PeriodicLimitOfflineService,
  PersonalArrangementOfflineService,
  PhotoObjectOfflineService,
  PositionOfflineService,
  ProductCategoryOfflineService,
  ProductListingOfflineService,
  ProductListingTemplateOfflineService,
  ProductOfflineService,
  ProjectOfflineService,
  ProjectUserStoreOfflineService,
  PromoActionOfflineService,
  QuestionOfflineService,
  QuestionnaireItemOfflineService,
  QuestionnaireOfflineService,
  ReportDashboardOfflineService,
  RequestLogOfflineService,
  RoleOfflineService,
  ScopeOfflineService,
  SettingsOfflineService,
  StoreOfflineService,
  SystemOfflineService,
  TagOfflineService,
  TodoActionOfflineService,
  TodoListOfflineService,
  TourPlanChangeRequestOfflineService,
  TourPlanOfflineService,
  TriggerOfflineService,
  UnitConversionOfflineService,
  UnitOfflineService,
  UserOfflineService,
  UserStorageOfflineService,
  UserTaskOfflineService,
  VisitOfflineService,
} from '../services/offline';
import {
  ReportProjectDataService
} from '../services/data/report-project.data-service';
import {
  IProjectAggregatedDataResponse
} from '@retrixhouse/salesapp-shared/lib/responses';

export type CacheName =
  | 'chain'
  | 'client-side-error-conf'
  | 'client-side-error'
  | 'country'
  | 'currency'
  | 'customer'
  | 'data-storage-file'
  | 'data-storage-folder'
  | 'generic-list-item'
  | 'generic-list'
  | 'holiday'
  | 'i18n'
  | 'json-schema'
  | 'kpi-set'
  | 'kpi'
  | 'language'
  | 'news-post'
  | 'notification-template'
  | 'object'
  | 'periodic-limit'
  | 'personal-arrangement'
  | 'position'
  | 'my-position'
  | 'product-category'
  | 'product-listing-template'
  | 'product-listing'
  | 'product'
  | 'project'
  | 'promo-action'
  | 'question'
  | 'questionnaire-item'
  | 'questionnaire'
  | 'report-dashboard'
  | 'role'
  | 'scope'
  | 'setting-value'
  | 'store'
  | 'tag'
  | 'todo-action'
  | 'todo-list'
  | 'tour-planchangerequest'
  | 'tour-plan'
  | 'tour-plan-cache'
  | 'tour-plan-cache-search-in-location'
  | 'trigger'
  | 'unit-conversion'
  | 'unit'
  | 'user'
  | 'username'
  | 'my-profile'
  | 'user-profile'
  | 'user-storage';

export const MAX_DATA_TRANSFER_SIZE = 1_048_576; // 1 MB

@Injectable()
export class DataProvider implements OnDestroy {
  private $clientModeChangeSubscription: Subscription;
  private $settingValuesExpiredSubscription: Subscription;
  private $visitCancelledSubscription: Subscription;

  private _settingValueResolver: SettingValueResolver;
  // #region data services
  private _dataServices: BaseDataService[];
  private _bhsAggregatedDataService: BHSAggregatedDataService;
  private _billingInfoDataService: BillingInfoDataService;
  private _chainDataService: ChainDataService;
  private _clientSideErrorConfDataService: ClientSideErrorConfDataService;
  private _clientSideErrorDataService: ClientSideErrorDataService;
  private _commentDataService: CommentDataService;
  private _countryDataService: CountryDataService;
  private _currencyDataService: CurrencyDataService;
  private _customerDataService: CustomerDataService;
  private _dataStorageFileDataService: DataStorageFileDataService;
  private _dataStorageFolderDataService: DataStorageFolderDataService;
  private _failSafeDataService: FailSafeDataService;
  private _feedbackDataService: FeedbackDataService;
  private _genericListItemDataService: GenericListItemDataService;
  private _genericListDataService: GenericListDataService;
  private _globalSearchDataService: GlobalSearchDataService;
  private _holidayDataService: HolidayDataService;
  private _i18NDataService: I18NDataService;
  private _imexTemplateDataService: ImexTemplateDataService;
  private _jsonSchemaDataService: JsonSchemaDataService;
  private _kpiSetDataService: KPISetDataService;
  private _kpiDataService: KPIDataService;
  private _languageDataService: LanguageDataService;
  private _mobileDeviceDataService: MobileDeviceDataService;
  private _newsPostDataService: NewsPostDataService;
  private _notificationTemplateDataService: NotificationTemplateDataService;
  private _objectReadDataService: ObjectReadDataService;
  private _objectDataService: ObjectDataService;
  private _orderDataService: OrderDataService;
  private _periodicLimitDataService: PeriodicLimitDataService;
  private _personalArrangementDataService: PersonalArrangementDataService;
  private _photoObjectDataService: PhotoObjectDataService;
  private _pingDataService: PingDataService;
  private _positionDataService: PositionDataService;
  private _productCategoryDataService: ProductCategoryDataService;
  private _productListingTemplateDataService: ProductListingTemplateDataService;
  private _productListingDataService: ProductListingDataService;
  private _productDataService: ProductDataService;
  private _projectStoreDataService: ProjectStoreDataService;
  private _projectDataService: ProjectDataService;
  private _promoActionDataService: PromoActionDataService;
  private _questionDataService: QuestionDataService;
  private _questionnaireItemDataService: QuestionnaireItemDataService;
  private _questionnaireDataService: QuestionnaireDataService;
  private _requestLogDataService: RequestLogDataService;
  private _reportDashoardDataService: ReportDashboardDataService;
  private _reportProjectDataService: ReportProjectDataService;
  private _roleDataService: RoleDataService;
  private _scopeDataService: ScopeDataService;
  private _settingsDataService: SettingsDataService;
  private _storeDataService: StoreDataService;
  private _systemDataService: SystemDataService;
  private _tagDataService: TagDataService;
  private _todoActionDataService: TodoActionDataService;
  private _todoListDataService: TodoListDataService;
  private _tourPlanChangeRequestDataService: TourPlanChangeRequestDataService;
  private _tourPlanDataService: TourPlanDataService;
  private _triggerDataService: TriggerDataService;
  private _unitConversionDataService: UnitConversionDataService;
  private _unitDataService: UnitDataService;
  private _userStorageDataService: UserStorageDataService;
  private _userTaskDataService: UserTaskDataService;
  private _userDataService: UserDataService;
  private _visitDataService: VisitDataService;
  // #endregion
  // #region caches
  private _caches: {
    [key in CacheName]: {instances: ICache | ICache[]; defaultTtl?: number};
  };
  private _chainCache: ObjectMapCache<string, Chain>;
  private _clientSideErrorConfCache: ObjectMapCache<
    string,
    IClientSideErrorConf
  >;
  private _clientSideErrorCache: ObjectMapCache<string, IClientSideError>;
  private _countryCache: ObjectMapCache<string, Country>;
  private _currencyCache: ObjectMapCache<string, Currency>;
  private _currencyEnabledCache: ObjectArrayCache<Currency>;
  private _customerCache: ObjectMapCache<string, Customer>;
  private _dataStorageFileCache: ObjectMapCache<string, DataStorageFile>;
  private _dataStorageFolderCache: ObjectMapCache<string, DataStorageFolder>;
  private _genericListItemCache: ObjectMapCache<string, GenericListItem>;
  private _genericListCache: ObjectMapCache<string, GenericList>;
  private _holidayCache: ObjectMapCache<string, Holiday>;
  private _i18nCache: ObjectMapCache<string, LocalizedValue>;
  private _jsonSchemaCache: ObjectSingleCache<any>;
  private _kpiSetCache: ObjectMapCache<string, KPISet>;
  private _kpiCache: ObjectMapCache<string, KPI>;
  private _languageCache: ObjectArrayCache<Language>;
  private _languageEnabledCache: ObjectArrayCache<Language>;
  private _newsPostCache: ObjectMapCache<string, NewsPostWithUserInfo>;
  private _notificationTemplateCache: ObjectMapCache<
    string,
    NotificationTemplate
  >;
  private _periodicLimitCache: ObjectMapCache<string, PeriodicLimit>;
  private _personalArrangementCache: ObjectMapCache<
    string,
    PersonalArrangement
  >;
  private _positionCache: ObjectMapCache<string, Position>;
  private _myPositionCache: ObjectSingleCache<Position>;
  private _objectTypeCache: ObjectMapCache<string, ObjectType>;
  private _objectPropertyCache: ObjectMapCache<string, ObjectProperty>;
  private _productCategoryCache: ObjectMapCache<string, ProductCategory>;
  private _productListingTemplateCache: ObjectMapCache<
    string,
    ProductListingTemplate
  >;
  private _productListingCache: ObjectMapCache<string, ProductListing>;
  private _productCache: ObjectMapCache<string, Product>;
  private _projectCache: ObjectMapCache<string, Project>;
  private _promoActionCache: ObjectMapCache<string, PromoAction>;
  private _questionCache: ObjectMapCache<string, Question>;
  private _questionnaireItemCache: ObjectMapCache<string, QuestionnaireItem>;
  private _questionnaireCache: ObjectMapCache<string, Questionnaire>;
  private _reportDashboardCache: ObjectMapCache<string, IReportDashboard>;
  private _roleCache: ObjectArrayCache<Role>;
  private _scopeCache: ObjectMapCache<string, Scope>;
  private _settingValueCache: ObjectArrayCache<ISettingValues>;
  private _storeCache: ObjectMapCache<string, Store>;
  private _tagCache: ObjectMapCache<string, Tag>;
  private _todoActionCache: ObjectMapCache<string, TodoAction>;
  private _todoListCache: ObjectMapCache<string, TodoList>;
  private _tourPlanChangeRequestCache: ObjectMapCache<
    string,
    TourPlanChangeRequest
  >;
  private _tourPlanCache: ObjectMapCache<string, TourPlan>;
  private _tourPlanCacheSearch: ObjectArrayCache<TourPlan>;
  private _tourPlanCacheSearchInLocation: ObjectArrayCache<TourPlan>;
  private _triggerCache: ObjectMapCache<string, Trigger>;
  private _unitConversionCache: ObjectMapCache<string, UnitConversion>;
  private _unitCache: ObjectMapCache<string, Unit>;
  private _userCache: ObjectMapCache<string, User>;
  private _userStorageCache: ObjectMapCache<string, any>;
  private _usernameCache: ObjectArrayCache<UsernameResponse>;
  private _myProfileCache: ObjectSingleCache<UserProfile>;
  private _userProfileCache: ObjectMapCache<string, UserProfile>;
  private _localeCache: ObjectArrayCache<Locale>;
  private _localeEnabledCache: ObjectArrayCache<Locale>;
  private _localizedIdentifierCache: ObjectArrayCache<LocalizedIdentifier>;

  // #endregion

  // prettier-ignore
  constructor(
    private _clientModeService: ClientModeService,
    // #region HTTP services
    bhsAggregatedDataHttpService: BHSAggregatedDataHttpService,
    billingInfoHttpService: BillingInfoHttpService,
    chainHttpService: ChainHttpService,
    clientSideErrorConfHttpService: ClientSideErrorConfHttpService,
    clientSideErrorHttpService: ClientSideErrorHttpService,
    commentHttpService: CommentHttpService,
    countryHttpService: CountryHttpService,
    currencyHttpService: CurrencyHttpService,
    customerHttpService: CustomerHttpService,
    dataStorageFileHttpService: DataStorageFileHttpService,
    dataStorageFolderHttpService: DataStorageFolderHttpService,
    failSafeHttpService: FailSafeHttpService,
    feedbackHttpService: FeedbackHttpService,
    genericListItemHttpService: GenericListItemHttpService,
    genericListHttpService: GenericListHttpService,
    globalSearchHttpService: GlobalSearchHttpService,
    holidayHttpService: HolidayHttpService,
    i18NHttpService: I18NHttpService,
    imexTemplateHttpService: ImexTemplateHttpService,
    jsonSchemaHttpService: JsonSchemaHttpService,
    kpiSetHttpService: KPISetHttpService,
    kpiHttpService: KPIHttpService,
    languageHttpService: LanguageHttpService,
    mobileDeviceHttpService: MobileDeviceHttpService,
    newsPostHttpService: NewsPostHttpService,
    notificationTemplateHttpService: NotificationTemplateHttpService,
    objectReadHttpService: ObjectReadHttpService,
    objectHttpService: ObjectHttpService,
    orderHttpService: OrderHttpService,
    periodicLimitHttpService: PeriodicLimitHttpService,
    personalArrangementHttpService: PersonalArrangementHttpService,
    photoObjectHttpService: PhotoObjectHttpService,
    pingHttpService: PingHttpService,
    positionHttpService: PositionHttpService,
    productCategoryHttpService: ProductCategoryHttpService,
    productListingTemplateHttpService: ProductListingTemplateHttpService,
    productListingHttpService: ProductListingHttpService,
    productHttpService: ProductHttpService,
    projectUserStoreHttpService: ProjectUserStoreHttpService,
    projectHttpService: ProjectHttpService,
    promoActionHttpService: PromoActionHttpService,
    questionHttpService: QuestionHttpService,
    questionnaireItemHttpService: QuestionnaireItemHttpService,
    questionnaireHttpService: QuestionnaireHttpService,
    reportProjectHttpService: ReportProjectHttpService,
    requestLogHttpService: RequestLogHttpService,
    roleHttpService: RoleHttpService,
    scopeHttpService: ScopeHttpService,
    settingsHttpService: SettingsHttpService,
    storageAccountHttpService: StorageAccountHttpService,
    storeHttpService: StoreHttpService,
    systemHttpService: SystemHttpService,
    tagHttpService: TagHttpService,
    todoActionHttpService: TodoActionHttpService,
    todoListHttpService: TodoListHttpService,
    tourPlanChangeRequestHttpService: TourPlanChangeRequestHttpService,
    tourPlanHttpService: TourPlanHttpService,
    triggerHttpService: TriggerHttpService,
    unitConversionHttpService: UnitConversionHttpService,
    unitHttpService: UnitHttpService,
    userStorageHttpService: UserStorageHttpService,
    userTaskHttpService: UserTaskHttpService,
    userHttpService: UserHttpService,
    visitHttpService: VisitHttpService,
    // #endregion
    // #region offline services
    bhsAggregatedDataOfflineService: BHSAggregatedDataOfflineService,
    billingInfoOfflineService: BillingInfoOfflineService,
    chainOfflineService: ChainOfflineService,
    clientSideErrorConfOfflineService: ClientSideErrorConfOfflineService,
    clientSideErrorOfflineService: ClientSideErrorOfflineService,
    commentOfflineService: CommentOfflineService,
    countryOfflineService: CountryOfflineService,
    currencyOfflineService: CurrencyOfflineService,
    customerOfflineService: CustomerOfflineService,
    dataStorageFileOfflineService: DataStorageFileOfflineService,
    dataStorageFolderOfflineService: DataStorageFolderOfflineService,
    feedbackOfflineService: FeedbackOfflineService,
    genericListItemOfflineService: GenericListItemOfflineService,
    genericListOfflineService: GenericListOfflineService,
    globalSearchOfflineService: GlobalSearchOfflineService,
    holidayOfflineService: HolidayOfflineService,
    i18NOfflineService: I18NOfflineService,
    imexTemplateOfflineService: ImexTemplateOfflineService,
    jsonSchemaOfflineService: JsonSchemaOfflineService,
    kpiSetOfflineService: KPISetOfflineService,
    kpiOfflineService: KPIOfflineService,
    languageOfflineService: LanguageOfflineService,
    newsPostOfflineService: NewsPostOfflineService,
    notificationTemplateOfflineService: NotificationTemplateOfflineService,
    objectReadOfflineService: ObjectReadOfflineService,
    objectOfflineService: ObjectOfflineService,
    orderOfflineService: OrderOfflineService,
    periodicLimitOfflineService: PeriodicLimitOfflineService,
    personalArrangementOfflineService: PersonalArrangementOfflineService,
    photoObjectOfflineService: PhotoObjectOfflineService,
    positionOfflineService: PositionOfflineService,
    productCategoryOfflineService: ProductCategoryOfflineService,
    productListingTemplateOfflineService: ProductListingTemplateOfflineService,
    productListingOfflineService: ProductListingOfflineService,
    productOfflineService: ProductOfflineService,
    projectUserStoreOfflineService: ProjectUserStoreOfflineService,
    projectOfflineService: ProjectOfflineService,
    promoActionOfflineService: PromoActionOfflineService,
    questionOfflineService: QuestionOfflineService,
    questionnaireItemOfflineService: QuestionnaireItemOfflineService,
    questionnaireOfflineService: QuestionnaireOfflineService,
    requestLogOfflineService: RequestLogOfflineService,
    roleOfflineService: RoleOfflineService,
    scopeOfflineService: ScopeOfflineService,
    settingsOfflineService: SettingsOfflineService,
    storeOfflineService: StoreOfflineService,
    systemOfflineService: SystemOfflineService,
    tagOfflineService: TagOfflineService,
    todoActionOfflineService: TodoActionOfflineService,
    todoListOfflineService: TodoListOfflineService,
    tourPlanChangeRequestOfflineService: TourPlanChangeRequestOfflineService,
    tourPlanOfflineService: TourPlanOfflineService,
    triggerOfflineService: TriggerOfflineService,
    unitConversionOfflineService: UnitConversionOfflineService,
    unitOfflineService: UnitOfflineService,
    userStorageOfflineService: UserStorageOfflineService,
    userTaskOfflineService: UserTaskOfflineService,
    userOfflineService: UserOfflineService,
    visitOfflineService: VisitOfflineService,
    reportDashboardHttpService: ReportDashboardHttpService,
    reportDashboardOfflineService: ReportDashboardOfflineService,
    // #endregion
  )
  {
    this._chainCache = new ObjectMapCache<string, Chain>();
    this._clientSideErrorConfCache = new ObjectMapCache<string, IClientSideErrorConf>();
    this._clientSideErrorCache = new ObjectMapCache<string, IClientSideError>();
    this._countryCache = new ObjectMapCache<string, Country>();
    this._currencyCache = new ObjectMapCache<string, Currency>();
    this._currencyEnabledCache = new ObjectArrayCache<Currency>();
    this._customerCache = new ObjectMapCache<string, Customer>();
    this._dataStorageFileCache = new ObjectMapCache<string, DataStorageFile>();
    this._dataStorageFolderCache = new ObjectMapCache<string, DataStorageFolder>();
    this._genericListItemCache = new ObjectMapCache<string, GenericListItem>();
    this._genericListCache = new ObjectMapCache<string, GenericList>();
    this._holidayCache = new ObjectMapCache<string, Holiday>();
    this._i18nCache = new ObjectMapCache<string, LocalizedValue>();
    this._jsonSchemaCache = new ObjectSingleCache<any>();
    this._kpiSetCache = new ObjectMapCache<string, KPISet>();
    this._kpiCache = new ObjectMapCache<string, KPI>();
    this._languageCache = new ObjectArrayCache<Language>();
    this._languageEnabledCache = new ObjectArrayCache<Language>();
    this._localeCache = new ObjectArrayCache<Locale>();
    this._localeEnabledCache = new ObjectArrayCache<Locale>();
    this._localizedIdentifierCache = new ObjectArrayCache<LocalizedIdentifier>();
    this._newsPostCache = new ObjectMapCache<string, NewsPostWithUserInfo>();
    this._notificationTemplateCache = new ObjectMapCache<string, NotificationTemplate>();
    this._objectTypeCache = new ObjectMapCache<string, ObjectType>();
    this._objectPropertyCache = new ObjectMapCache<string, ObjectProperty>();
    this._periodicLimitCache = new ObjectMapCache<string, PeriodicLimit>();
    this._personalArrangementCache = new ObjectMapCache<string, PersonalArrangement>();
    this._positionCache = new ObjectMapCache<string, Position>();
    this._myPositionCache = new ObjectSingleCache<Position>();
    this._productCategoryCache = new ObjectMapCache<string, ProductCategory>();
    this._productListingTemplateCache = new ObjectMapCache<string, ProductListingTemplate>();
    this._productListingCache = new ObjectMapCache<string, ProductListing>();
    this._productCache = new ObjectMapCache<string, Product>();
    this._projectCache = new ObjectMapCache<string, Project>();
    this._promoActionCache = new ObjectMapCache<string, PromoAction>();
    this._questionCache = new ObjectMapCache<string, Question>();
    this._questionnaireItemCache = new ObjectMapCache<string, QuestionnaireItem>();
    this._questionnaireCache = new ObjectMapCache<string, Questionnaire>();
    this._reportDashboardCache = new ObjectMapCache<string, IReportDashboard>()
    this._roleCache = new ObjectArrayCache<Role>();
    this._scopeCache = new ObjectMapCache<string, Scope>();
    this._settingValueCache = new ObjectArrayCache<ISettingValues>();
    this._storeCache = new ObjectMapCache<string, Store>();
    this._tagCache = new ObjectMapCache<string, Tag>();
    this._todoActionCache = new ObjectMapCache<string, TodoAction>();
    this._todoListCache = new ObjectMapCache<string, TodoList>();
    this._tourPlanChangeRequestCache = new ObjectMapCache<string, TourPlanChangeRequest>();
    this._tourPlanCache = new ObjectMapCache<string, TourPlan>();
    this._tourPlanCacheSearch = new ObjectArrayCache<TourPlan>();
    this._tourPlanCacheSearchInLocation = new ObjectArrayCache<TourPlan>();
    this._triggerCache = new ObjectMapCache<string, Trigger>();
    this._unitConversionCache = new ObjectMapCache<string, UnitConversion>();
    this._unitCache = new ObjectMapCache<string, Unit>();
    this._userCache = new  ObjectMapCache<string, User>();
    this._userStorageCache = new ObjectMapCache<string, any>();
    this._usernameCache = new ObjectArrayCache<UsernameResponse>();
    this._myProfileCache = new ObjectSingleCache<UserProfile>();
    this._userProfileCache = new ObjectMapCache<string, UserProfile>();

    this._caches = {
      'chain': { instances: this._chainCache },
      'client-side-error-conf': {instances: this._clientSideErrorConfCache},
      'client-side-error': {instances: this._clientSideErrorCache},
      'country': { instances: this._countryCache },
      'currency': { instances: [this._currencyCache, this._currencyEnabledCache] },
      'customer': { instances: this._customerCache },
      'data-storage-file': { instances: this._dataStorageFileCache },
      'data-storage-folder': { instances: this._dataStorageFolderCache },
      'generic-list-item': { instances: this._genericListItemCache },
      'generic-list': { instances: this._genericListCache },
      'holiday': { instances: this._holidayCache },
      'i18n': { instances: [this._i18nCache, this._localeCache, this._localeEnabledCache, this._localizedIdentifierCache] },
      'json-schema': { instances: this._jsonSchemaCache },
      'kpi-set': { instances: this._kpiSetCache },
      'kpi': { instances: this._kpiCache },
      'language': { instances: [this._languageCache, this._languageEnabledCache] },
      'news-post': { instances: this._newsPostCache },
      'notification-template': { instances: this._notificationTemplateCache },
      'object': { instances: [this._objectTypeCache, this._objectPropertyCache] },
      'periodic-limit': { instances: this._periodicLimitCache },
      'personal-arrangement': { instances: this._personalArrangementCache },
      'position': { instances: this._positionCache },
      'my-position': { instances: this._myPositionCache },
      'product-category': { instances: this._productCategoryCache },
      'product-listing-template': { instances: this._productListingTemplateCache },
      'product-listing': { instances: this._productListingCache },
      'product': { instances: this._productCache },
      'project': { instances: this._projectCache },
      'promo-action': { instances: this._promoActionCache },
      'question': { instances: this._questionCache },
      'questionnaire-item': { instances: this._questionnaireItemCache },
      'questionnaire': { instances: this._questionnaireCache },
      'report-dashboard': { instances: this._reportDashboardCache},
      'role': { instances: this._roleCache },
      'scope': { instances: this._scopeCache },
      'setting-value': { instances: this._settingValueCache },
      'store': { instances: this._storeCache },
      'tag': { instances: this._tagCache },
      'todo-action': { instances: this._todoActionCache },
      'todo-list': { instances: this._todoListCache },
      'tour-planchangerequest': { instances: this._tourPlanChangeRequestCache },
      'tour-plan': { instances: this._tourPlanCache },
      'tour-plan-cache': { instances: this._tourPlanCacheSearch },
      'tour-plan-cache-search-in-location': { instances: this._tourPlanCacheSearchInLocation },
      'trigger': { instances: this._triggerCache },
      'unit-conversion': { instances: this._unitConversionCache },
      'unit': { instances: this._unitCache },
      'user': { instances: this._userCache },
      'username': { instances: this._usernameCache },
      'my-profile': { instances: this._myProfileCache },
      'user-profile': { instances: this._userProfileCache },
      'user-storage': { instances: this._userStorageCache },
    };

    this._bhsAggregatedDataService = new BHSAggregatedDataService(bhsAggregatedDataHttpService, bhsAggregatedDataOfflineService);
    this._billingInfoDataService = new BillingInfoDataService(billingInfoHttpService, billingInfoOfflineService);
    this._chainDataService = new ChainDataService(chainHttpService, chainOfflineService, this._chainCache);
    this._clientSideErrorConfDataService = new ClientSideErrorConfDataService(clientSideErrorConfHttpService, clientSideErrorConfOfflineService, this._clientSideErrorConfCache)
    this._clientSideErrorDataService = new ClientSideErrorDataService(clientSideErrorHttpService, clientSideErrorOfflineService, this._clientSideErrorCache),
    this._commentDataService = new CommentDataService(commentHttpService, commentOfflineService);
    this._countryDataService = new CountryDataService(countryHttpService, countryOfflineService, this._countryCache);
    this._currencyDataService = new CurrencyDataService(currencyHttpService, currencyOfflineService, this._currencyCache, this._currencyEnabledCache);
    this._customerDataService = new CustomerDataService(customerHttpService, customerOfflineService, this._customerCache);
    this._dataStorageFileDataService = new DataStorageFileDataService(dataStorageFileHttpService, dataStorageFileOfflineService, this._dataStorageFileCache);
    this._dataStorageFolderDataService = new DataStorageFolderDataService(dataStorageFolderHttpService, dataStorageFolderOfflineService, this._dataStorageFolderCache);
    this._failSafeDataService = new FailSafeDataService(failSafeHttpService);
    this._feedbackDataService = new FeedbackDataService(feedbackHttpService, feedbackOfflineService);
    this._genericListItemDataService = new GenericListItemDataService(genericListItemHttpService, genericListItemOfflineService, this._genericListItemCache);
    this._genericListDataService = new GenericListDataService(genericListHttpService, genericListOfflineService, this._genericListCache);
    this._globalSearchDataService = new GlobalSearchDataService(globalSearchHttpService, globalSearchOfflineService);
    this._holidayDataService = new HolidayDataService(holidayHttpService, holidayOfflineService, this._holidayCache);
    this._i18NDataService = new I18NDataService(i18NHttpService, i18NOfflineService, this._i18nCache, this._localeCache, this._localeEnabledCache, this._localizedIdentifierCache);
    this._imexTemplateDataService = new ImexTemplateDataService(imexTemplateHttpService);
    this._jsonSchemaDataService = new JsonSchemaDataService(jsonSchemaHttpService, jsonSchemaOfflineService, this._jsonSchemaCache);
    this._kpiSetDataService = new KPISetDataService(kpiSetHttpService, kpiSetOfflineService, this._kpiSetCache);
    this._kpiDataService = new KPIDataService(kpiHttpService, kpiOfflineService, this._kpiCache);
    this._languageDataService = new LanguageDataService(languageHttpService, languageOfflineService, this._languageCache, this._languageEnabledCache);
    this._mobileDeviceDataService = new MobileDeviceDataService(mobileDeviceHttpService);
    this._newsPostDataService = new NewsPostDataService(newsPostHttpService, newsPostOfflineService, this._newsPostCache);
    this._notificationTemplateDataService = new NotificationTemplateDataService(notificationTemplateHttpService, notificationTemplateOfflineService, this._notificationTemplateCache);
    this._objectReadDataService = new ObjectReadDataService(objectReadHttpService, objectReadOfflineService);
    this._objectDataService = new ObjectDataService(objectHttpService, objectOfflineService, this._objectTypeCache, this._objectPropertyCache);
    this._orderDataService = new OrderDataService(orderHttpService, orderOfflineService);
    this._periodicLimitDataService = new PeriodicLimitDataService(periodicLimitHttpService, periodicLimitOfflineService, this._periodicLimitCache);
    this._personalArrangementDataService = new PersonalArrangementDataService(personalArrangementHttpService, personalArrangementOfflineService, this._personalArrangementCache);
    this._photoObjectDataService = new PhotoObjectDataService(photoObjectHttpService, photoObjectOfflineService);
    this._pingDataService = new PingDataService(pingHttpService);
    this._positionDataService = new PositionDataService(positionHttpService, positionOfflineService, this._positionCache, this._myPositionCache);
    this._productCategoryDataService = new ProductCategoryDataService(productCategoryHttpService, productCategoryOfflineService, this._productCategoryCache);
    this._productListingTemplateDataService = new ProductListingTemplateDataService(productListingTemplateHttpService, productListingTemplateOfflineService, this._productListingTemplateCache);
    this._productListingDataService = new ProductListingDataService(productListingHttpService, productListingOfflineService, this._productListingCache);
    this._productDataService = new ProductDataService(productHttpService, productOfflineService, this._productCache);
    this._projectStoreDataService = new ProjectStoreDataService(projectUserStoreHttpService, projectUserStoreOfflineService);
    this._projectDataService = new ProjectDataService(projectHttpService, projectOfflineService, this._projectCache);
    this._promoActionDataService = new PromoActionDataService(promoActionHttpService, promoActionOfflineService, this._promoActionCache);
    this._questionDataService = new QuestionDataService(questionHttpService, questionOfflineService, this._questionCache);
    this._questionnaireItemDataService = new QuestionnaireItemDataService(questionnaireItemHttpService, questionnaireItemOfflineService, this._questionnaireItemCache);
    this._questionnaireDataService = new QuestionnaireDataService(questionnaireHttpService, questionnaireOfflineService, this._questionnaireCache);
    this._requestLogDataService = new RequestLogDataService(requestLogHttpService);
    this._reportDashoardDataService = new ReportDashboardDataService(reportDashboardHttpService, reportDashboardOfflineService, this._reportDashboardCache);
    this._reportProjectDataService = new ReportProjectDataService(reportProjectHttpService);
    this._roleDataService = new RoleDataService(roleHttpService, roleOfflineService, this._roleCache);
    this._scopeDataService = new ScopeDataService(scopeHttpService, scopeOfflineService, this._scopeCache);
    this._settingsDataService = new SettingsDataService(settingsHttpService, settingsOfflineService, this._settingValueCache);
    this._storeDataService = new StoreDataService(storeHttpService, storeOfflineService, this._storeCache);
    this._systemDataService = new SystemDataService(systemHttpService, systemOfflineService);
    this._tagDataService = new TagDataService(tagHttpService, tagOfflineService, this._tagCache);
    this._todoActionDataService = new TodoActionDataService(todoActionHttpService, todoActionOfflineService, this._todoActionCache);
    this._todoListDataService = new TodoListDataService(todoListHttpService, todoListOfflineService, this._todoListCache);
    this._tourPlanChangeRequestDataService = new TourPlanChangeRequestDataService(tourPlanChangeRequestHttpService, tourPlanChangeRequestOfflineService, this._tourPlanChangeRequestCache);
    this._tourPlanDataService = new TourPlanDataService(tourPlanHttpService, tourPlanOfflineService, this._tourPlanCache, this._tourPlanCacheSearch, this._tourPlanCacheSearchInLocation);
    this._triggerDataService = new TriggerDataService(triggerHttpService, triggerOfflineService, this._triggerCache);
    this._unitConversionDataService = new UnitConversionDataService(unitConversionHttpService, unitConversionOfflineService, this._unitConversionCache);
    this._unitDataService = new UnitDataService(unitHttpService, unitOfflineService, this._unitCache);
    this._userStorageDataService = new UserStorageDataService(userStorageHttpService, userStorageOfflineService, this._userStorageCache);
    this._userTaskDataService = new UserTaskDataService(userTaskHttpService, userTaskOfflineService);
    this._userDataService = new UserDataService(userHttpService, userOfflineService, this._userCache, this._usernameCache, this._myProfileCache, this._userProfileCache);
    this._visitDataService = new VisitDataService(visitHttpService, visitOfflineService);

    this._dataServices = [
      this._bhsAggregatedDataService,
      this._billingInfoDataService,
      this._chainDataService,
      this._clientSideErrorConfDataService,
      this._clientSideErrorDataService,
      this._commentDataService,
      this._countryDataService,
      this._currencyDataService,
      this._customerDataService,
      this._dataStorageFileDataService,
      this._dataStorageFolderDataService,
      this._failSafeDataService,
      this._feedbackDataService,
      this._genericListItemDataService,
      this._genericListDataService,
      this._globalSearchDataService,
      this._holidayDataService,
      this._i18NDataService,
      this._imexTemplateDataService,
      this._jsonSchemaDataService,
      this._kpiSetDataService,
      this._kpiDataService,
      this._languageDataService,
      this._mobileDeviceDataService,
      this._newsPostDataService,
      this._notificationTemplateDataService,
      this._objectReadDataService,
      this._objectDataService,
      this._orderDataService,
      this._periodicLimitDataService,
      this._personalArrangementDataService,
      this._photoObjectDataService,
      this._pingDataService,
      this._positionDataService,
      this._productCategoryDataService,
      this._productListingTemplateDataService,
      this._productListingDataService,
      this._productDataService,
      this._projectStoreDataService,
      this._projectDataService,
      this._promoActionDataService,
      this._questionDataService,
      this._questionnaireItemDataService,
      this._questionnaireDataService,
      this._requestLogDataService,
      this._reportDashoardDataService,
      this._reportProjectDataService,
      this._roleDataService,
      this._scopeDataService,
      this._settingsDataService,
      this._storeDataService,
      this._tagDataService,
      this._todoActionDataService,
      this._todoListDataService,
      this._tourPlanChangeRequestDataService,
      this._tourPlanDataService,
      this._triggerDataService,
      this._unitConversionDataService,
      this._unitDataService,
      this._userStorageDataService,
      this._userTaskDataService,
      this._userDataService,
      this._visitDataService,
    ];

    this.handleClientModeChanged = this.handleClientModeChanged.bind(this);
    this.initSettingValueResolver = this.initSettingValueResolver.bind(this);
    this.handleVisitCancelled = this.handleVisitCancelled.bind(this);

    this.$clientModeChangeSubscription = this._clientModeService
      .clientModeChangeEvent()
      .subscribe(this.handleClientModeChanged);

    this.$settingValuesExpiredSubscription = this._settingsDataService
      .onSettingValuesExpired()
      .subscribe(this.initSettingValueResolver);

    this.$visitCancelledSubscription = this._visitDataService.onVisitCancelled
      .subscribe(this.handleVisitCancelled);

    this._settingValueResolver = new SettingValueResolver();
  }

  /**
   * Handles destroy event.
   */
  ngOnDestroy(): void {
    if (this.$clientModeChangeSubscription) {
      this.$clientModeChangeSubscription.unsubscribe();
    }

    if (this.$settingValuesExpiredSubscription) {
      this.$settingValuesExpiredSubscription.unsubscribe();
    }

    if (this.$visitCancelledSubscription) {
      this.$visitCancelledSubscription.unsubscribe();
    }
  }

  /**
   * Initializes setting value resolver.
   */
  public initSettingValueResolver(): Promise<void> {
    return this._settingsDataService
      .resolveAllValues()
      .then(values => this._settingValueResolver.init(values));
  }

  /**
   * Handles visit cancelled event from the visit data service.
   * @param {TourPlan} tp - cancelled visit (tour plan)
   */
  private handleVisitCancelled(tp: TourPlan): void {
    this._tourPlanDataService.updateInCaches(tp);
  }

  /**
   * Handles client mode changed event. Iterates over all data services and
   * switches the mode accordingly.
   * @param {ClientMode} cm - current client mode
   */
  private handleClientModeChanged(cm: ClientMode): void {
    this._dataServices.forEach(ds => {
      if (cm === ClientMode.ONLINE && ds.supportsOnline) {
        ds.mode = ClientMode.ONLINE;
      } else if (cm === ClientMode.OFFLINE && ds.supportsOffline) {
        ds.mode = ClientMode.OFFLINE;
      }
    });
  }

  /**
   * Invalidates one or more caches.
   * @param {CacheName | CacheName[]} caches - cache or caches to invalidate
   */
  public invalidateCaches(caches: CacheName | CacheName[]): void {
    if (Array.isArray(caches)) {
      caches.forEach(cache => {
        const instances = this._caches[cache].instances;
        if (Array.isArray(instances)) {
          instances.forEach(i => i.invalidate());
        } else {
          instances.invalidate();
        }
      });

      // re-init settings value resolver
      if (caches.includes('setting-value')) {
        this.initSettingValueResolver();
      }
    } else {
      const instances = this._caches[caches].instances;
      if (Array.isArray(instances)) {
        instances.forEach(i => i.invalidate());
      } else {
        instances.invalidate();
      }

      // re-init settings value resolver
      if (caches === 'setting-value') {
        this.initSettingValueResolver();
      }
    }
  }

  public get settingResolver(): SettingValueResolver {
    return this._settingValueResolver;
  }

  public get bhsAggregatedData(): BHSAggregatedDataService {
    return this._bhsAggregatedDataService;
  }

  public get billingInfo(): BillingInfoDataService {
    return this._billingInfoDataService;
  }

  public get chain(): ChainDataService {
    return this._chainDataService;
  }

  public get clientSideErrorConf(): ClientSideErrorConfDataService {
    return this._clientSideErrorConfDataService;
  }

  public get clientSideError(): ClientSideErrorDataService {
    return this._clientSideErrorDataService;
  }

  public get comment(): CommentDataService {
    return this._commentDataService;
  }

  public get country(): CountryDataService {
    return this._countryDataService;
  }

  public get currency(): CurrencyDataService {
    return this._currencyDataService;
  }

  public get customer(): CustomerDataService {
    return this._customerDataService;
  }

  public get dataStorageFile(): DataStorageFileDataService {
    return this._dataStorageFileDataService;
  }

  public get dataStorageFolder(): DataStorageFolderDataService {
    return this._dataStorageFolderDataService;
  }

  public get failSafe(): FailSafeDataService {
    return this._failSafeDataService;
  }

  public get feedback(): FeedbackDataService {
    return this._feedbackDataService;
  }

  public get genericListItem(): GenericListItemDataService {
    return this._genericListItemDataService;
  }

  public get genericList(): GenericListDataService {
    return this._genericListDataService;
  }

  public get globalSearch(): GlobalSearchDataService {
    return this._globalSearchDataService;
  }

  public get holiday(): HolidayDataService {
    return this._holidayDataService;
  }

  public get i18N(): I18NDataService {
    return this._i18NDataService;
  }

  public get imexTemplate(): ImexTemplateDataService {
    return this._imexTemplateDataService;
  }

  public get jsonSchema(): JsonSchemaDataService {
    return this._jsonSchemaDataService;
  }

  public get KPISet(): KPISetDataService {
    return this._kpiSetDataService;
  }

  public get KPI(): KPIDataService {
    return this._kpiDataService;
  }

  public get language(): LanguageDataService {
    return this._languageDataService;
  }

  public get mobileDevice(): MobileDeviceDataService {
    return this._mobileDeviceDataService;
  }

  public get newsPost(): NewsPostDataService {
    return this._newsPostDataService;
  }

  public get notificationTemplate(): NotificationTemplateDataService {
    return this._notificationTemplateDataService;
  }

  public get objectRead(): ObjectReadDataService {
    return this._objectReadDataService;
  }

  public get object(): ObjectDataService {
    return this._objectDataService;
  }

  public get order(): OrderDataService {
    return this._orderDataService;
  }

  public get periodicLimit(): PeriodicLimitDataService {
    return this._periodicLimitDataService;
  }

  public get personalArrangement(): PersonalArrangementDataService {
    return this._personalArrangementDataService;
  }

  public get photoObject(): PhotoObjectDataService {
    return this._photoObjectDataService;
  }

  public get ping(): PingDataService {
    return this._pingDataService;
  }

  public get position(): PositionDataService {
    return this._positionDataService;
  }

  public get productCategory(): ProductCategoryDataService {
    return this._productCategoryDataService;
  }

  public get productListingTemplate(): ProductListingTemplateDataService {
    return this._productListingTemplateDataService;
  }

  public get productListing(): ProductListingDataService {
    return this._productListingDataService;
  }

  public get product(): ProductDataService {
    return this._productDataService;
  }

  public get projectStore(): ProjectStoreDataService {
    return this._projectStoreDataService;
  }

  public get project(): ProjectDataService {
    return this._projectDataService;
  }

  public get promoAction(): PromoActionDataService {
    return this._promoActionDataService;
  }

  public get question(): QuestionDataService {
    return this._questionDataService;
  }

  public get questionnaireItem(): QuestionnaireItemDataService {
    return this._questionnaireItemDataService;
  }

  public get questionnaire(): QuestionnaireDataService {
    return this._questionnaireDataService;
  }

  public get reportDashboard(): ReportDashboardDataService {
    return this._reportDashoardDataService;
  }

  public get requestLog(): RequestLogDataService {
    return this._requestLogDataService;
  }

  public get reportProject(): ReportProjectDataService {
    return this._reportProjectDataService;
  }

  public get role(): RoleDataService {
    return this._roleDataService;
  }

  public get scope(): ScopeDataService {
    return this._scopeDataService;
  }

  public get settings(): SettingsDataService {
    return this._settingsDataService;
  }

  public get store(): StoreDataService {
    return this._storeDataService;
  }

  public get system(): SystemDataService {
    return this._systemDataService;
  }

  public get tag(): TagDataService {
    return this._tagDataService;
  }

  public get todoAction(): TodoActionDataService {
    return this._todoActionDataService;
  }

  public get todoList(): TodoListDataService {
    return this._todoListDataService;
  }

  public get tourPlanChangeRequest(): TourPlanChangeRequestDataService {
    return this._tourPlanChangeRequestDataService;
  }

  public get tourPlan(): TourPlanDataService {
    return this._tourPlanDataService;
  }

  public get trigger(): TriggerDataService {
    return this._triggerDataService;
  }

  public get unitConversion(): UnitConversionDataService {
    return this._unitConversionDataService;
  }

  public get unit(): UnitDataService {
    return this._unitDataService;
  }

  public get userStorage(): UserStorageDataService {
    return this._userStorageDataService;
  }

  public get userTask(): UserTaskDataService {
    return this._userTaskDataService;
  }

  public get user(): UserDataService {
    return this._userDataService;
  }

  public get visit(): VisitDataService {
    return this._visitDataService;
  }
}
