<!-- TODO: remove unused attributes -->
<app-dialog *ngIf="!(loading$ | async)"
            [title]="'entity.localized-value.dialog.edit.title' | translate"
            [actionInProgress]="false"
            [actionLabel]="'form.action.save' | translate"
            [actionDisabled]="disableAction$ | async"
            [actionVisible]="true"
            [cancelLabel]="'form.action.close' | translate"
            [cancelDisabled]="disableAction$ | async"
            [cancelVisible]="true"
            [formGroup]="form"
            (cancelClicked)="onClose()"
            (actionClicked)="onSave()">
  <app-form-row>
    <app-form-column>
      <app-input-text [formControl]="getFormControl('name')"
                      [formControlRef]="getFormControl('name')"
                      [label]="'entity.localized-value.properties.name.label' | translate"></app-input-text>
    </app-form-column>
  </app-form-row>
  <app-form-row>
    <app-form-column>
      <app-input-text [formControl]="getFormControl('description')"
                      [formControlRef]="getFormControl('description')"
                      [label]="'entity.localized-value.properties.description.label' | translate"></app-input-text>
    </app-form-column>
  </app-form-row>

  <app-form-section *ngIf="form"
                    [title]="'entity.localized-value.group.languages-section.label' | translate"
                    formGroupName="translations">

    <app-form-row *ngFor="let controlName of form.controls.translations.controls | keyvalue">
      <app-form-column>
        <app-input-text [formControl]="getTranslationsControl(controlName.key)"
                        [formControlRef]="getTranslationsControl(controlName.key)"
                        [label]="getTranslation(controlName.key)"></app-input-text>
      </app-form-column>
    </app-form-row>

  </app-form-section>
</app-dialog>