import {DataProvider} from '../../../../shared/data.provider/data-provider';
import {ExpressionEvalutaionService} from '../../../../shared/services';
import {
  ContextBase,
  ContextQuestionnaire,
  Project,
  ProjectWrapper,
  Questionnaire,
  QuestionnaireAnswer,
  QuestionnaireResult,
  QuestionnaireWrapper,
  StoreWrapper,
  TourPlan,
  User,
  UserWrapper,
  VisitWrapper,
} from '../../../../shared/models';
import {Injectable} from '@angular/core';
import {TimeWrapper} from '@retrixhouse/salesapp-shared/lib/expr-eval';
import {
  QuestionnaireItemTree,
} from '../../../../shared/utils/questionnaire-item-tree';

@Injectable()
export class QuestionnaireResultContextBuilderService {

  constructor(
    private dataProvider: DataProvider,
    private expressionEvaluationService: ExpressionEvalutaionService,
  ) {}

  private tryEvaluateExpression<T extends ContextBase>(
    expression: any,
    context: T,
  ): boolean {
    if (!expression || !context) {
      return false;
    }

    try {
      return this.expressionEvaluationService.evaluateExpression(
        expression,
        context,
      );
    } catch {
      return false;
    }
  }

  public evaluateExpression(
    questionnaireItemId: string,
    expression: any,
    context: ContextQuestionnaire,
  ): boolean {
    if (!expression || !context || !questionnaireItemId) {
      return false;
    }

    const currentQuestionWrapper = context?.questions?.find(
      f => f.id === questionnaireItemId,
    );

    if (currentQuestionWrapper) {
      context.question = currentQuestionWrapper;
    }

    return this.tryEvaluateExpression(expression, context);
  }


  async buildQuestionnaireContext(
    project: Project,
    visit: TourPlan,
    questionnaireResult: QuestionnaireResult,
    questionnaire: Questionnaire,
    resultOwner: User,
  ): Promise<ContextQuestionnaire> {
    const questionnaireContext = new ContextQuestionnaire();

    const questionnaireItemTree: QuestionnaireItemTree =
      new QuestionnaireItemTree(
        questionnaire.questions,
        questionnaire.questions?.map(qu => qu.question),
      );

    const questionsWrapperMapper = questionnaireItemTree.createQuestionWrapper(
      questionnaireResult?.questionnaireAnswers,
    );

    // set questionnaire and questions to context
    questionnaireContext.questionnaire = new QuestionnaireWrapper(questionnaire, questionnaireResult);
    questionnaireContext.questions = questionnaireItemTree.allItems?.map(
      rootItem => questionsWrapperMapper.get(rootItem.id),
    );

    // questionnaireContext.todoList = todoListContext.todoList;
    // questionnaireContext.todoAction = todoListContext.todoAction;
    // questionnaireContext.previousVisit = todoListContext.previousVisit;

    questionnaireContext.visit = new VisitWrapper(
      visit,
      [], // TODO: add executors
    );
    questionnaireContext.project = new ProjectWrapper(project, []);
    questionnaireContext.store = new StoreWrapper(visit.store);
    questionnaireContext.user = new UserWrapper(resultOwner);
    questionnaireContext.time = new TimeWrapper(new Date());
    return questionnaireContext;
  }

  updateQuestionnaireItemAnswer(
    questionnaireItemId: string,
    questionnaireAnswer: QuestionnaireAnswer,
    questionnaireContext: ContextQuestionnaire,
  ): ContextQuestionnaire {
    if (!questionnaireContext || !questionnaireItemId) {
      return questionnaireContext;
    }

    const currentQuestionWrapper = questionnaireContext?.questions?.find(
      f => f.id === questionnaireItemId,
    );

    if (!currentQuestionWrapper) {
      return questionnaireContext;
    }

    if (questionnaireAnswer) {
      currentQuestionWrapper.answer = questionnaireAnswer.answer;
      currentQuestionWrapper.openedAt = questionnaireAnswer.openedAt ? new TimeWrapper(
          questionnaireAnswer.openedAt,
        ) :
        null;
      currentQuestionWrapper.timeSpent = questionnaireAnswer.timeSpent;
    }

    questionnaireContext.question = currentQuestionWrapper;

    return questionnaireContext;
  }

  updateQuestionnaireItemAnswerValue(
    questionnaireItemId: string,
    answer: any,
    questionnaireContext: ContextQuestionnaire,
  ): ContextQuestionnaire {
    if (!questionnaireContext || !questionnaireItemId) {
      return questionnaireContext;
    }

    const currentQuestionWrapper = questionnaireContext?.questions?.find(
      f => f.id === questionnaireItemId,
    );

    if (!currentQuestionWrapper) {
      return questionnaireContext;
    }

    currentQuestionWrapper.answer = answer;
    questionnaireContext.question = currentQuestionWrapper;

    return questionnaireContext;
  }
}
