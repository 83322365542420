import {DIALOG_DATA, DialogRef} from '@angular/cdk/dialog';
import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {
  IUser,
  IUserProfile,
  IUserProfileWithRelations,
} from '@retrixhouse/salesapp-shared/lib/models';
import {UserProfileStorageService} from '@salesapp/storage';
import {combineLatest, Observable} from 'rxjs';
import {filter, map} from 'rxjs/operators';

@Component({
  selector: 'app-user-management-detail-dialog',
  templateUrl: './user-management-detail-dialog.component.html',
  styleUrls: ['./user-management-detail-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserManagementDetailDialogComponent {
  loading$: Observable<boolean>;
  user$: Observable<IUser | null>;
  userProfile$: Observable<IUserProfile | null>;

  get forceProfileUpdate() {
    return this.data.forceProfileUpdate;
  }

  constructor(
    private dialogRef: DialogRef<UserManagementDetailDialogData>,
    @Inject(DIALOG_DATA) private data: UserManagementDetailDialogData,
    private userProfileStorageService: UserProfileStorageService,
  ) {}

  ngOnInit() {
    this.userProfile$ = this.userProfileStorageService.dataById$.pipe(
      filter(dataById => !!dataById.size),
      map(dataById => {
        return dataById.get(this.data.userProfileId);
      }),
    );
    this.user$ = this.userProfile$.pipe(
      map(userProfile => {
        return (userProfile as IUserProfileWithRelations)?.user;
      }),
    );
    this.loading$ = combineLatest([
      this.userProfileStorageService.dataFetching$,
      this.userProfile$,
    ]).pipe(map(([fetching, userProfile]) => fetching || !userProfile));
  }

  onClose() {
    this.dialogRef.close();
  }
}

export interface UserManagementDetailDialogData {
  userId?: string;
  userProfileId: string;
  forceProfileUpdate?: boolean;
}
