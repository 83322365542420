import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Scope} from '../../models';
import {BaseCrudHttpService} from './base.http-service';
import {IScopeDataService} from '../../interfaces/data-service';

@Injectable()
export class ScopeHttpService
  extends BaseCrudHttpService<Scope>
  implements IScopeDataService
{
  constructor(http: HttpClient) {
    super(http, '/core/scopes');
  }

  public getScopeNames(): Promise<Scope[]> {
    return this.GET<Scope[]>('/core/scope-names');
  }

  public existsScopeName(name: string, excludeId?: string): Promise<boolean> {
    const params = {
      name: name,
    };

    if (excludeId) {
      params['excludeId'] = excludeId;
    }

    return this.GET<boolean>('/core/scopes/exists-name', {
      params: params,
    });
  }
}
