import {BHSAggregatedDataOfflineService} from './bhs-aggregated-data.offline-service';
import {BillingInfoOfflineService} from './billing-info.offline-service';
import {ChainOfflineService} from './chain.offline-service';
import {ClientSideErrorConfOfflineService} from './client-side-error-conf.offline-service';
import {CommentOfflineService} from './comment.offline-service';
import {CountryOfflineService} from './country.offline-service';
import {CurrencyOfflineService} from './currency.offline-service';
import {CustomerOfflineService} from './customer.offline-service';
import {DataStorageFileOfflineService} from './data-storage-file.offline-service';
import {DataStorageFolderOfflineService} from './data-storage-folder.offline-service';
import {FeedbackOfflineService} from './feedback.offline-service';
import {GenericListItemOfflineService} from './generic-list-item.offline-service';
import {GenericListOfflineService} from './generic-list.offline-service';
import {GlobalSearchOfflineService} from './global-search.offline-service';
import {HolidayOfflineService} from './holiday.offline-service';
import {I18NOfflineService} from './i18n.offline-service';
import {ImexTemplateOfflineService} from './imex-template.offline-service';
import {JsonSchemaOfflineService} from './json-schema.offline-service';
import {KPISetOfflineService} from './kpi-set.offline-service';
import {KPIOfflineService} from './kpi.offline-service';
import {LanguageOfflineService} from './language.offline-service';
import {NewsPostOfflineService} from './news-post.offline-service';
import {NotificationTemplateOfflineService} from './notification-template.offline-service';
import {ObjectReadOfflineService} from './object-read.offline-service';
import {ObjectOfflineService} from './object.offline-service';
import {OrderOfflineService} from './order.offline-service';
import {PeriodicLimitOfflineService} from './periodic-limit.offline-service';
import {PersonalArrangementOfflineService} from './personal-arrangement.offline-service';
import {PhotoObjectOfflineService} from './photo-object.offline-service';
import {PositionOfflineService} from './position.offline-service';
import {ProductCategoryOfflineService} from './product-category.offline-service';
import {ProductListingTemplateOfflineService} from './product-listing-template.offline-service';
import {ProductListingOfflineService} from './product-listing.offline-service';
import {ProductOfflineService} from './product.offline-service';
import {ProjectUserStoreOfflineService} from './project-store.offline-service';
import {ProjectOfflineService} from './project.offline-service';
import {PromoActionOfflineService} from './promo-action.offline-service';
import {QuestionOfflineService} from './question.offline-service';
import {QuestionnaireItemOfflineService} from './questionnaire-item.offline-service';
import {QuestionnaireOfflineService} from './questionnaire.offline-service';
import {RequestLogOfflineService} from './request-log.offline-service';
import {RoleOfflineService} from './role.offline-service';
import {ScopeOfflineService} from './scope.offline-service';
import {SettingsOfflineService} from './settings.offline-service';
import {StoreOfflineService} from './store.offline-service';
import {SystemOfflineService} from './system.offile-service';
import {TagOfflineService} from './tag.offline-service';
import {TodoActionOfflineService} from './todo-action.offline-service';
import {TodoListOfflineService} from './todo-list.offline-service';
import {TourPlanChangeRequestOfflineService} from './tour-plan-change-request.offline-service';
import {TourPlanOfflineService} from './tour-plan.offline-service';
import {TriggerOfflineService} from './trigger.offline-service';
import {UnitConversionOfflineService} from './unit-conversion.offline-service';
import {UnitOfflineService} from './unit.offline-service';
import {UserStorageOfflineService} from './user-storage.offline-service';
import {UserTaskOfflineService} from './user-task.offline-service';
import {UserOfflineService} from './user.offline-service';
import {VisitOfflineService} from './visit.offline-service';
import {ClientSideErrorOfflineService} from './client-side-error.offline-service';
import {ReportDashboardOfflineService} from './report-dashboard.offline-service';

export * from './base.offline-service';
export * from './bhs-aggregated-data.offline-service';
export * from './billing-info.offline-service';
export * from './chain.offline-service';
export * from './client-side-error-conf.offline-service';
export * from './client-side-error.offline-service';
export * from './comment.offline-service';
export * from './country.offline-service';
export * from './currency.offline-service';
export * from './customer.offline-service';
export * from './data-storage-file.offline-service';
export * from './data-storage-folder.offline-service';
export * from './feedback.offline-service';
export * from './generic-list-item.offline-service';
export * from './generic-list.offline-service';
export * from './global-search.offline-service';
export * from './holiday.offline-service';
export * from './i18n.offline-service';
export * from './imex-template.offline-service';
export * from './json-schema.offline-service';
export * from './kpi-set.offline-service';
export * from './kpi.offline-service';
export * from './language.offline-service';
export * from './news-post.offline-service';
export * from './notification-template.offline-service';
export * from './object-read.offline-service';
export * from './object.offline-service';
export * from './order.offline-service';
export * from './periodic-limit.offline-service';
export * from './personal-arrangement.offline-service';
export * from './photo-object.offline-service';
export * from './position.offline-service';
export * from './product-category.offline-service';
export * from './product-listing-template.offline-service';
export * from './product-listing.offline-service';
export * from './product.offline-service';
export * from './project-store.offline-service';
export * from './project.offline-service';
export * from './promo-action.offline-service';
export * from './question.offline-service';
export * from './questionnaire-item.offline-service';
export * from './questionnaire.offline-service';
export * from './report-dashboard.offline-service';
export * from './request-log.offline-service';
export * from './role.offline-service';
export * from './scope.offline-service';
export * from './settings.offline-service';
export * from './store.offline-service';
export * from './system.offile-service';
export * from './tag.offline-service';
export * from './todo-action.offline-service';
export * from './todo-list.offline-service';
export * from './tour-plan-change-request.offline-service';
export * from './tour-plan.offline-service';
export * from './trigger.offline-service';
export * from './unit-conversion.offline-service';
export * from './unit.offline-service';
export * from './user-storage.offline-service';
export * from './user-task.offline-service';
export * from './user.offline-service';
export * from './visit.offline-service';

export const OFFLINE_SERVICES = [
  BHSAggregatedDataOfflineService,
  BillingInfoOfflineService,
  ChainOfflineService,
  ClientSideErrorConfOfflineService,
  ClientSideErrorOfflineService,
  CommentOfflineService,
  CountryOfflineService,
  CurrencyOfflineService,
  CustomerOfflineService,
  DataStorageFileOfflineService,
  DataStorageFolderOfflineService,
  FeedbackOfflineService,
  GenericListItemOfflineService,
  GenericListOfflineService,
  GlobalSearchOfflineService,
  HolidayOfflineService,
  I18NOfflineService,
  ImexTemplateOfflineService,
  JsonSchemaOfflineService,
  KPIOfflineService,
  KPISetOfflineService,
  LanguageOfflineService,
  NewsPostOfflineService,
  NotificationTemplateOfflineService,
  ObjectOfflineService,
  ObjectReadOfflineService,
  OrderOfflineService,
  PeriodicLimitOfflineService,
  PersonalArrangementOfflineService,
  PhotoObjectOfflineService,
  PositionOfflineService,
  ProductCategoryOfflineService,
  ProductListingOfflineService,
  ProductListingTemplateOfflineService,
  ProductOfflineService,
  ProjectOfflineService,
  ProjectUserStoreOfflineService,
  PromoActionOfflineService,
  QuestionnaireItemOfflineService,
  QuestionnaireOfflineService,
  QuestionOfflineService,
  ReportDashboardOfflineService,
  RequestLogOfflineService,
  RoleOfflineService,
  ScopeOfflineService,
  SettingsOfflineService,
  StoreOfflineService,
  SystemOfflineService,
  TagOfflineService,
  TodoActionOfflineService,
  TodoListOfflineService,
  TourPlanChangeRequestOfflineService,
  TourPlanOfflineService,
  TriggerOfflineService,
  UnitConversionOfflineService,
  UnitOfflineService,
  UserOfflineService,
  UserStorageOfflineService,
  UserTaskOfflineService,
  VisitOfflineService,
];
