import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewChild,
} from '@angular/core';
import {CopyIconComponent} from '../copy-icon/copy-icon.component';

@Component({
  selector: 'app-copy-text',
  templateUrl: './copy-text.component.html',
  styleUrls: ['./copy-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CopyTextComponent {
  @Input() textToCopy: string;

  @ViewChild(CopyIconComponent) copyIcon: CopyIconComponent;

  onCopy() {
    this.copyIcon.onCopy();
  }
}
