export class VisitsPivotReportResponse {
  id: number;
  chain: string;
  store: string;
  category: string;
  product: string;
  oos: number;
  promo: string;
  date: Date;
}
