import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControlComponent,
  createFormControlProvider,
} from '@salesapp/utils/reactive-form';

@Component({
  selector: 'app-input-checkbox',
  templateUrl: './input-checkbox.component.html',
  styleUrls: ['./input-checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [createFormControlProvider(() => InputCheckboxComponent)],
})
@ControlValueAccessor()
export class InputCheckboxComponent extends FormControlComponent<boolean> {
  constructor(private changeDetector: ChangeDetectorRef) {
    super(changeDetector);
  }

  onValueChange(event) {
    this.valueChange.emit(event);
  }
}
