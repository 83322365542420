export interface NavigationMenuItem {
  id?: string;
  i18n?: string;
  text?: string;
  path?: string;
  icon?: string;
  visible?: boolean;
  items?: NavigationMenuItem[];
  showInOfflineMode?: boolean;
  visibleForUser?: boolean;
}

export const navigation: NavigationMenuItem[] = [
  {
    id: 'e665d40e-8f54-4d35-b2e0-939411fd52d8',
    i18n: 'navigation.home',
    text: 'Home',
    path: '/',
    icon: 'fa fa-house-user',
    visible: true,
    showInOfflineMode: true,
  },
  {
    id: '41f243a2-a8e1-401d-9b36-fcc5afdeb53f',
    i18n: 'navigation.store-activities',
    text: 'Store activities',
    path: '/chains-and-stores/store-activities',
    icon: 'fas fa-wave-pulse',
    visible: true,
    showInOfflineMode: true,
  },
  {
    id: '64f18fb6-1408-43b0-9f60-f81e3217dc0a',
    i18n: 'navigation.my-agenda',
    text: 'My agenda',
    icon: 'fas fa-folder-user',
    visible: true,
    showInOfflineMode: true,
    items: [
      // TODO(milan): cleanup needed
      // {
      //   id: '5569b822-abef-47ed-99d5-cde077f16965',
      //   i18n: 'navigation.my-profile',
      //   text: 'My profile',
      //   path: '/profile',
      //   icon: 'fa fa-user-circle',
      //   visible: true,
      // },
      {
        id: 'e13651f3-da92-45e6-a81e-117ed0af21d9',
        i18n: 'navigation.my-tour-plans',
        text: 'My tour plan',
        path: '/tour-plans/my',
        icon: 'fa fa-calendar-circle-user',
        visible: true,
        showInOfflineMode: true,
      },
      {
        id: 'a68c9592-9849-47f3-9ac3-2437155f58fa',
        i18n: 'navigation.my-request-change',
        text: 'My requested changes',
        path: '/tour-plans/my-change-request',
        icon: 'fal fa-calendar-edit',
        visible: true,
      },
      {
        id: '0486364a-838c-476f-a652-18768208ee9a',
        i18n: 'navigation.quick-planning',
        text: 'Quick planning',
        path: '/tour-plans/quick-planning',
        icon: 'fal fa-cart-shopping-fast',
        visible: true,
        showInOfflineMode: true,
      },
      {
        id: 'b56c451f-d9b9-46b8-b3dd-a9f67dd457f4',
        i18n: 'navigation.my-feedback',
        text: 'My feedback',
        path: '/tour-plans/my-feedback',
        icon: 'fa-solid fa-message-smile',
        visible: true,
      },
      {
        id: 'f8ca0993-4f79-4892-811f-242c060469ec',
        i18n: 'navigation.billing-info',
        text: 'Billing Info',
        icon: 'fa fa-chart-line',
        path: '/reports/billing-info/my',
        visible: true,
      },
    ],
  },

  {
    id: '15bb20c4-195e-437b-948a-196facba8400',
    i18n: 'navigation.data-storage',
    text: 'Data storage',
    icon: 'fas fa-database',
    visible: true,
    items: [
      {
        id: '03d55b75-979a-4282-882a-d9e78cb8da1c',
        i18n: 'navigation.documents-and-files',
        text: 'Data storage',
        path: '/data-storage/file-manager',
        icon: 'fa fa-folder-tree',
        visible: true,
      },
      {
        id: '00d2ae7e-8d49-4ebd-a3b8-c63a0d67a860',
        i18n: 'navigation.central-photo-gallery',
        text: 'Photo gallery',
        path: '/data-storage/central-photo-gallery',
        icon: 'fas fa-folder-image',
        visible: true,
      },
      {
        id: '570c15cd-baf4-4844-b067-6cc66da7ae1f',
        i18n: 'navigation.data-transfer-errors',
        text: 'Data Transfer Errors',
        path: '/data-storage/data-transfer-dumps',
        icon: 'fas fa-rotate-exclamation',
        visible: true,
      },
      {
        id: '38b3ae07-a6a5-49ac-ae7c-c7bc21370b80',
        i18n: 'navigation.object-attachment-templates',
        text: 'Attachment Templates',
        path: '/data-storage/object-attachment-templates',
        icon: 'fas fa-file-invoice',
        visible: true,
      },
    ],
  },

  {
    id: 'a3f09008-4726-41a0-bf82-b13ec1a1acfd',
    i18n: 'navigation.section.system',
    text: 'System',
    icon: 'fa fa-folder-gear',
    visible: true,
    items: [
      {
        id: 'a3181efd-39f7-47bf-bfce-bbb29b478cd2',
        i18n: 'navigation.system.settings',
        text: 'Settings',
        path: '/system/settings',
        icon: 'fa fa-gears',
        visible: true,
      },
      {
        id: 'd72d326a-7f6b-4db5-bea1-d0c9a93daa53',
        i18n: 'navigation.system.object-history',
        text: 'Object history',
        path: '/system/history/0',
        icon: 'fa fa-clock-rotate-left',
        visible: true,
      },
      {
        id: '2664542e-8112-4ac4-95a1-7ea1566508e2',
        i18n: 'navigation.system.state-transition-history',
        text: 'State history',
        path: '/system/state-transition/0',
        icon: 'fa fa-chart-network',
        visible: true,
      },
      {
        id: '3009b763-338c-4997-b6c5-45fc0e42b51c',
        i18n: 'navigation.system.scope-defs',
        text: 'Scope definitions',
        path: '/system/scopes',
        icon: 'fa fa-shield',
        visible: true,
      },
      {
        id: '29c53590-e27f-4a08-a860-2aec7795c25f',
        i18n: 'navigation.system.object-settings',
        text: 'Objects',
        path: '/system/object-settings',
        icon: 'fa fa-file-circle-info',
        visible: true,
      },
      // {
      //   id: '6eda0d7d-ef8b-4b76-859f-81e174786f40',
      //   i18n: 'navigation.system.object-properties',
      //   text: 'Object properties',
      //   path: '/system/properties',
      //   icon: 'fa fa-file-circle-info',
      //   visible: true,
      // },
      {
        id: 'cffeccc2-1832-4c0b-94dd-408d6b844575',
        i18n: 'navigation.system.request-logs',
        text: 'Request logs',
        path: '/system/request-logs',
        icon: 'fa fa-memo-circle-check',
        visible: true,
      },
      {
        id: '8fc13c78-5d59-4d95-bcf3-bc53f9edfcc8',
        i18n: 'navigation.system.translations',
        text: 'Translations',
        path: '/system/translations',
        icon: 'fa fa-language',
        visible: true,
      },
      {
        id: '06a7224e-820e-4899-b1a4-3926fc744571',
        i18n: 'navigation.system.generic-lists',
        text: 'Generic lists',
        path: '/system/generic-lists',
        icon: 'fa fa-rectangle-list',
        visible: true,
      },
      {
        id: '1bdd887a-453e-49db-863b-923633b86776',
        i18n: 'navigation.system.units',
        text: 'Units',
        path: '/system/units',
        icon: 'fas fa-scale-unbalanced',
        visible: true,
      },
      {
        id: 'f39cf427-0423-4804-aa41-7e297b902639',
        i18n: 'navigation.system.unit-conversions',
        text: 'Unit conversions',
        path: '/system/unit-conversions',
        icon: 'fas fa-square-root-variable',
        visible: true,
      },
      {
        id: '39a91953-367c-4bb1-be4f-92516171cd26',
        i18n: 'navigation.system.currencies',
        text: 'Currencies',
        path: '/system/currencies',
        icon: 'fas fa-euro-sign',
        visible: true,
      },
      {
        id: '4191baa0-0917-48f3-b631-ef289989ed30',
        i18n: 'navigation.system.notification-templates',
        text: 'Notification Templates',
        path: '/system/notification-templates',
        icon: 'fa fa-message-code',
        visible: true,
      },
      {
        id: 'bd424abc-3a4b-4e29-94ea-5c9bd4f6dc9b',
        i18n: 'navigation.system.holiday',
        text: 'Holidays',
        path: '/system/holiday',
        icon: 'fa fa-calendar-heart',
        visible: true,
      },
      {
        id: '4c9f0973-3829-4b5f-b791-d747934e016b',
        i18n: 'navigation.system.trigger',
        text: 'Triggers',
        path: '/system/triggers',
        icon: 'fa fa-code',
        visible: true,
      },
      {
        id: 'af19af62-3072-4cfa-a657-797c5441d530',
        i18n: 'navigation.system.caches-refresh',
        text: 'Refresh caches',
        path: '/system/caches/refresh',
        icon: 'fa fa-database',
        visible: true,
      },
    ],
  },

  {
    id: '8cc07f35-2891-4e0c-88c8-683be851c6bd',
    i18n: 'navigation.section.administration',
    text: 'Administration',
    icon: 'fa fa-user-shield',
    visible: true,
    items: [
      // {
      //   id: '9f0219bb-4dd9-46e5-b693-8e7c9708f36d',
      //   i18n: 'navigation.administration.users',
      //   text: 'Users',
      //   path: '/administration/users',
      //   icon: 'fa fa-users',
      //   visible: true,
      // },
      // {
      //   id: '08531278-39ff-40aa-b933-7f442a26d5c9',
      //   i18n: 'navigation.administration.profiles',
      //   text: 'Profiles',
      //   path: '/administration/user-profiles',
      //   icon: 'fa fa-address-card',
      //   visible: true,
      // },
      {
        id: '3d997cce-7658-44e1-98d5-e9c35d1ff28c',
        i18n: 'navigation.administration.users-management',
        text: 'Users Management',
        path: '/administration/users-management',
        icon: 'fa fa-address-card',
        visible: true,
      },
      {
        id: '27a8314d-4106-4e79-a487-adde63cf01ee',
        i18n: 'navigation.administration.roles',
        text: 'Roles',
        path: '/administration/roles',
        icon: 'fa fa-user-shield',
        visible: true,
      },
      {
        id: 'bca642d8-f905-487d-8c6a-9eb2fb49380c',
        i18n: 'navigation.administration.positions',
        text: 'Positions',
        path: '/administration/positions',
        icon: 'fa fa-user-group-crown',
        visible: true,
      },
      {
        id: '139a1860-8a99-402f-b2df-cf9a0a6c0186',
        i18n: 'navigation.administration.news-posts',
        text: 'News posts',
        path: '/administration/news-post',
        icon: 'fas fa-newspaper',
        visible: true,
      },
      {
        id: 'c7e885ff-614c-4a9d-b153-95d6e4e48ee1',
        i18n: 'navigation.administration.periodic-limit',
        text: 'Periodic Limit',
        path: '/administration/periodic-limit',
        icon: 'fa fa-users',
        visible: true,
      },
      {
        id: 'ac0643cf-9123-460c-b5cd-1a324b4933c1',
        i18n: 'navigation.administration.tags',
        text: 'Tags',
        path: '/system/tags',
        icon: 'fa fa-tags',
        visible: true,
      },
      {
        id: 'd5fc6dba-973f-4fee-8d3e-0c65d1c3f34f',
        i18n: 'navigation.administration.imex',
        text: 'Import & Export',
        path: '/system/imex',
        icon: 'fa fa-file-excel',
        visible: true,
      },
      {
        id: 'a1a3d900-5899-49e7-8714-66a45e6d34ec',
        i18n: 'navigation.administration.error-logging',
        text: 'Error logging',
        path: '/administration/error-logging',
        icon: 'fa fa-bug',
        visible: true,
      },
    ],
  },
  {
    id: '80a86a2c-75f2-41d5-9e9c-09e29b772bed',
    i18n: 'navigation.section.customer-agenda',
    text: 'Customer agenda',
    icon: 'fa fa-folder-open',
    visible: true,
    items: [
      {
        id: '4459c81b-325f-4953-9c2b-b312db50c145',
        i18n: 'navigation.customer-agenda.customers',
        text: 'Customers',
        path: '/customer-agenda/customers',
        icon: 'fa fa-handshake-simple',
        visible: true,
      },
      {
        id: '6fe0c1df-be5f-4264-8aee-5484bb355da1',
        i18n: 'navigation.customer-agenda.product-categories',
        text: 'Product Categories',
        path: '/customer-agenda/product-categories',
        icon: 'fa fa-list-tree',
        visible: true,
      },
      {
        id: '93265e01-7ce8-45ea-8783-f27b082f45ec',
        i18n: 'navigation.customer-agenda.products',
        text: 'Products',
        path: '/customer-agenda/products',
        icon: 'fa fa-box-open-full',
        visible: true,
      },
      {
        id: '3bf10e6d-5a8e-439e-a4d4-330a88d3e346',
        i18n: 'navigation.customer-agenda.product-listing',
        text: 'Product listing',
        path: '/customer-agenda/product-listing',
        icon: 'fa fa-shelves',
        visible: true,
      },
      {
        id: '4ae61375-248e-467b-8b48-943089b732ad',
        i18n: 'navigation.customer-agenda.projects',
        text: 'Projects',
        path: '/customer-agenda/projects',
        icon: 'fa fa-diagram-project',
        visible: true,
      },
      {
        id: '7373fefb-5ca0-4c7c-8d77-a8c11705d011',
        i18n: 'navigation.customer-agenda.promo-actions',
        text: 'Promotional Actions',
        path: '/customer-agenda/promo-actions',
        icon: 'fa fa-badge-dollar',
        visible: true,
      },
    ],
  },
  {
    id: '01dfb9ca-a4b5-4493-a60e-7391491db940',
    i18n: 'navigation.section.chains-and-stores',
    text: 'Chains & stores',
    icon: 'fa fa-warehouse',
    visible: true,
    showInOfflineMode: true,
    items: [
      {
        id: 'eccbe3a1-2d19-48d0-a6a3-0ac8c61236ee',
        i18n: 'navigation.chains-and-stores.retail-chains',
        text: 'Retail chains',
        path: '/chains-and-stores/chains',
        icon: 'fa fa-building',
        visible: true,
        showInOfflineMode: true,
      },
      {
        id: '5f75caa5-26f2-4934-a63a-d51312ac16af',
        i18n: 'navigation.chains-and-stores.retail-stores',
        text: 'Retail stores',
        path: '/chains-and-stores/stores',
        icon: 'fa fa-shop',
        visible: true,
        showInOfflineMode: true,
      },
    ],
  },
  {
    id: '6fdd80ad-4411-412a-ac7f-d69ba77bb8c7',
    i18n: 'navigation.section.data-collection',
    text: 'Data collection',
    icon: 'fa fa-folder-arrow-down',
    visible: true,
    items: [
      {
        id: '9e9ad8d3-8abc-4722-91d7-f66ffe55318b',
        i18n: 'navigation.data-collection.questions',
        text: 'Questions',
        path: '/data-collection/questions',
        icon: 'fa fa-question',
        visible: true,
      },
      {
        id: 'a260e37b-c0b6-49c2-8998-00dc18e688a6',
        i18n: 'navigation.data-collection.questionnaires',
        text: 'Questionnaires',
        path: '/data-collection/questionnaires',
        visible: true,
        icon: 'fa fa-question-circle',
      },
      {
        id: 'ffd6eb97-47b2-4dad-941c-100cc234fbf5',
        i18n: 'navigation.data-collection.todo-lists',
        text: 'Todo Lists',
        path: '/data-collection/todo-lists',
        icon: 'fas fa-clipboard-list',
        visible: true,
      },
      {
        id: 'eb6d3758-7a94-4c25-a3c1-3981041bcc1d',
        i18n: 'navigation.data-collection.todo-actions',
        text: 'Todo Actions',
        path: '/data-collection/todo-actions',
        icon: 'fas fa-people-carry',
        visible: true,
      },
      {
        id: 'eccd8012-4c56-409b-bbbe-648e5768096f',
        i18n: 'navigation.data-collection.kpi',
        text: 'KPIs',
        path: '/data-collection/kpi',
        icon: 'fas fa-chart-user',
        visible: true,
      },
      {
        id: '0f2dd452-c865-4b35-b01f-bab32081a66e',
        i18n: 'navigation.data-collection.kpi-sets',
        text: 'KPI Sets',
        path: '/data-collection/kpi-sets',
        icon: 'fas fa-chart-tree-map',
        visible: true,
      },
    ],
  },
  {
    id: '1d75643d-3085-43f7-b106-0cce319b521c',
    i18n: 'navigation.section.reports',
    text: 'Report',
    icon: 'fa fa-chart-column',
    visible: true,
    items: [
      {
        id: 'fbddf2c9-1911-4719-abe4-46a540b1451c',
        i18n: 'navigation.reports.report-dashboards',
        text: 'BI Dashboard',
        icon: 'fa fa-chart-pie',
        path: '/reports/report-dashboards',
        visible: true,
      },
      {
        id: 'cf804167-f2ab-467f-894b-bcbc2b9f5a56',
        i18n: 'navigation.reports.external-reports',
        text: 'External Reports',
        icon: 'fa fa-file-chart-column',
        path: '/reports/external-reports',
        visible: true,
      },
      {
        id: '19cdbf9b-fb87-466a-9989-80514672c7ec',
        i18n: 'navigation.reports.orders',
        text: 'Orders',
        icon: 'fa fa-cart-shopping',
        path: '/reports/order',
        visible: true,
      },
      {
        id: '2e5b26a2-d920-499b-9bfe-03fa23ea5822',
        i18n: 'navigation.reports.samplings',
        text: 'Samplings',
        icon: 'fa fa-gift',
        path: '/reports/sampling',
        visible: true,
      },
      {
        id: 'f2417150-9594-4cdd-be25-b04b0838a0a8',
        i18n: 'navigation.reports.personal-arrengements',
        text: 'Personal arrangmenets',
        icon: 'fa fa-handshake',
        path: '/reports/personal-arrangements',
        visible: true,
      },
      {
        id: '5a43698d-9842-4d36-9780-bd69beb2d308',
        i18n: 'navigation.reports.promo-actions',
        text: 'Promo actions',
        icon: 'fa fa-badge-dollar',
        path: '/reports/promo-actions',
        visible: true,
      },
      {
        id: '00008b13-7326-4610-8ade-86fe0d2ed6b6',
        i18n: 'navigation.reports.out-of-stock',
        text: 'Out of stock',
        icon: 'fa fa-chart-column',
        path: '/reports/product-sales',
        visible: true,
      },
      {
        id: '83ceacf9-7b90-44ec-a0e3-829194672f85',
        i18n: 'navigation.reports.price-monitoring',
        text: 'Price monitoring',
        icon: 'fa fa-chart-line',
        path: '/reports/price-monitoring',
        visible: true,
      },
      {
        id: '05a8057d-bec1-4b4d-bc94-aa46afe230ff',
        i18n: 'navigation.reports.visits',
        text: 'Visits',
        icon: 'fa fa-chart-line',
        path: '/reports/visits',
        visible: true,
      },
      {
        id: 'eca59269-b9c3-455b-b854-7404e4335681',
        i18n: 'navigation.reports.billing-info',
        text: 'Billing Info',
        icon: 'fa fa-chart-line',
        path: '/reports/billing-info',
        visible: true,
      },
      {
        id: '8ec540d9-e364-495d-9ad3-dbc631291633',
        i18n: 'navigation.reports.feedbacks',
        text: 'Feedbacks',
        icon: 'fa-solid fa-message-smile',
        path: '/reports/feedbacks',
        visible: true,
      },
    ],
  },

  {
    id: '1a250493-7a66-460e-83ca-e95d81ed8125',
    i18n: 'navigation.section.tour-plans',
    text: 'Tour plan',
    icon: 'fa fa-calendar-lines-pen',
    visible: true,
    items: [
      {
        id: '86c737d3-d9e6-42d5-be07-d5a9c50e9fd3',
        i18n: 'navigation.tour-plans.planning',
        text: 'Tour plan',
        icon: 'fa fa-calendar-days',
        path: '/tour-plans',
        visible: true,
      },
      {
        id: '0c032682-0cb6-427c-8b7c-9bbbdc1b1ef6',
        i18n: 'navigation.planning',
        text: 'Planning',
        path: '/tour-plans/planning',
        icon: 'fal fa-line-columns',
        visible: true,
        showInOfflineMode: true,
      },
      {
        id: 'cb759157-de10-46fa-aee4-62e219f33bd8',
        i18n: 'navigation.tour-plan-exchange',
        text: 'Tour plan exchange',
        icon: 'fal fa-calendar-lines-pen',
        path: '/tour-plans/exchange',
        visible: true,
      },
      {
        id: '61d68678-48c2-475c-9c24-32b17e15d852',
        i18n: 'navigation.tour-plans.change-requests',
        text: 'Change requests',
        path: '/tour-plans/change-request',
        icon: 'fa fa-calendar-circle-user',
        visible: true,
      },
      {
        id: '7e9380f0-fe12-45e1-925e-93f08f8d091c',
        i18n: 'navigation.tour-plans.user-tasks',
        text: 'User tasks',
        path: '/tour-plans/user-task',
        icon: 'fa fa-user-check',
        visible: true,
      },
    ],
  },
];
