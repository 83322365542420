import {
  ObjectArrayCache,
  ObjectMapCache,
  ObjectSingleCache,
} from '@retrixhouse/salesapp-shared/lib/caching';
import {IUserDataService} from '../../interfaces/data-service';
import {
  CreateUserRequest,
  User,
  UsernameResponse,
  UserProfile,
  WhoAmIResponse,
} from '../../models';
import {UserHttpService} from '../http';
import {UserOfflineService} from '../offline';
import {
  BaseCrudDataService,
  CachingOptions,
  TTL_DAY,
} from './base.data-service';
import deepEqual from 'deep-equal';
import cloneDeep from 'clone-deep';
import {Filter} from '@loopback/filter';

export class UserDataService extends BaseCrudDataService<
  User,
  IUserDataService,
  ObjectMapCache<string, User>
> {
  private _cacheUsernames: ObjectArrayCache<UsernameResponse>;
  private _cacheMyProfile: ObjectSingleCache<UserProfile>;
  private _cacheUserProfiles: ObjectMapCache<string, UserProfile>;
  private _lastFilterUserProfiles?: any;

  constructor(
    onlineService: UserHttpService,
    offlineService: UserOfflineService,
    cache: ObjectMapCache<string, User>,
    cacheUsernames: ObjectArrayCache<UsernameResponse>,
    cacheMyProfile: ObjectSingleCache<UserProfile>,
    cacheUserProfiles: ObjectMapCache<string, UserProfile>,
  ) {
    super(onlineService, offlineService, cache);
    this._cacheUsernames = cacheUsernames;
    this._cacheMyProfile = cacheMyProfile;
    this._cacheUserProfiles = cacheUserProfiles;

    this._cache.onItemsDeleted.on(this.userDeleted.bind(this));
    this._cache.onItemsSet.on(this.userUpdated.bind(this));
    this._cacheUserProfiles.onItemsSet.on(this.userProfileUpdated.bind(this));
    this._cacheMyProfile.onItemSet.on(this.userProfileUpdated.bind(this));
  }

  public resetUserPassword(
    userId: string,
    oneTimePassword: string,
  ): Promise<void> {
    return this.service.resetUserPassword(userId, oneTimePassword);
  }

  public async createUser(
    request: CreateUserRequest,
  ): Promise<{user: User; userProfile: UserProfile}> {
    const response = await this.service.createUser(request);
    if (this._cache.isValid) {
      this._cache.set(response.user.id, response.user);
    }

    // refresh usernames
    await this.getUsernames({forceReload: true});

    // fetch user profile and add to cache
    if (this._cacheUserProfiles.isValid) {
      const userProfile = await this.service.getUserProfileByUserId(
        response.user.id,
      );
      this._cacheUserProfiles.set(userProfile.id, userProfile);
    }

    return response;
  }

  public async existsUsername(
    username: string,
    cachingOptions?: CachingOptions,
  ): Promise<boolean> {
    // skip caching
    if (cachingOptions?.skipCache) {
      return this.service.existsUsername(username);
    }

    await this.initCacheUsernames(
      cachingOptions?.forceReload,
      cachingOptions?.ttl,
    );

    return Promise.resolve(
      this._cacheUsernames.getAll().some(u => u.username === username),
    );
  }

  public async getUsernames(
    cachingOptions?: CachingOptions,
  ): Promise<UsernameResponse[]> {
    // if skip caching
    if (cachingOptions?.skipCache) {
      return this.service.getUsernames();
    }

    await this.initCacheUsernames(
      cachingOptions?.forceReload,
      cachingOptions?.ttl,
    );

    return Promise.resolve(this._cacheUsernames.getAll());
  }

  whoAmI(): Promise<WhoAmIResponse> {
    return this.service.whoAmI();
  }

  public async getUserProfiles(
    filter?: Filter<UserProfile>,
    cachingOptions?: CachingOptions,
  ): Promise<UserProfile[]> {
    // if skip caching
    if (cachingOptions?.skipCache) {
      return this.service.getUserProfiles(filter);
    }

    await this.initCacheUserProfiles(
      filter,
      cachingOptions?.forceReload,
      cachingOptions?.ttl,
    );

    return this.service.getUserProfiles(filter);
  }

  public async getUserProfile(
    id: string,
    cachingOptions?: CachingOptions,
  ): Promise<UserProfile> {
    if (cachingOptions?.skipCache) {
      return this.service.getUserProfile(id);
    }

    // re-initialize cache if forced
    if (cachingOptions?.forceReload) {
      await this.initCacheUserProfiles(
        undefined,
        cachingOptions?.forceReload,
        cachingOptions?.ttl,
      );
    }

    // try to get the profile from the cache
    if (!this._cacheUserProfiles.isEmpty) {
      const userProfile = this._cacheUserProfiles.get(id);
      if (userProfile) {
        return Promise.resolve(userProfile);
      }
    }

    return this.service.getUserProfile(id);
  }

  public async getUserProfileByUserId(
    userId: string,
    cachingOptions?: CachingOptions,
  ): Promise<UserProfile> {
    // if skip caching
    if (cachingOptions?.skipCache) {
      return this.service.getUserProfileByUserId(userId);
    }

    // re-initialize cache if forced
    if (cachingOptions?.forceReload) {
      await this.initCacheUserProfiles(
        undefined,
        cachingOptions?.forceReload,
        cachingOptions?.ttl,
      );
    }

    // try to get the profile from the cache
    if (!this._cacheUserProfiles.isEmpty) {
      const userProfile = this._cacheUserProfiles.find(
        kv => kv[1].userId === userId,
      );
      if (userProfile) {
        return Promise.resolve(userProfile[1]);
      }
    }

    return this.service.getUserProfileByUserId(userId);
  }

  public async updateUserProfile(
    id: string,
    profile: Partial<UserProfile>,
  ): Promise<UserProfile> {
    const userProfile = await this.service.updateUserProfile(id, profile);

    if (this._cacheUserProfiles.isValid) {
      this._cacheUserProfiles.set(userProfile.id, userProfile);
    }

    return userProfile;
  }

  public async updateUserProfilePicture(
    id: string,
    version: number,
    picture: string,
  ): Promise<UserProfile> {
    const userProfile = await this.service.updateUserProfilePicture(
      id,
      version,
      picture,
    );

    if (this._cacheUserProfiles.isValid) {
      this._cacheUserProfiles.set(userProfile.id, userProfile);
    }

    return userProfile;
  }

  public async getMyProfile(
    cachingOptions?: CachingOptions,
  ): Promise<UserProfile> {
    // if skip caching
    if (cachingOptions?.skipCache) {
      return this.service.getMyProfile();
    }

    await this.initCacheMyProfile(
      cachingOptions?.forceReload,
      cachingOptions?.ttl,
    );

    return Promise.resolve(this._cacheMyProfile.get());
  }

  public async getMyUsernameResponse(): Promise<UsernameResponse> {
    return this.service.getMyUsernameResponse();
  }

  public async updateMyProfile(profile: UserProfile): Promise<UserProfile> {
    const userProfile = await this.service.updateMyProfile(profile);
    if (this._cacheMyProfile.isValid) {
      this._cacheMyProfile.set(userProfile);
    }

    return Promise.resolve(userProfile);
  }

  public uploadMyProfilePicture(pictureFile: FormData): Promise<string> {
    return this.service.uploadMyProfilePicture(pictureFile);
  }

  public uploadUserProfilePicture(
    profileId: string,
    pictureFile: FormData,
  ): Promise<string> {
    return this.service.uploadUserProfilePicture(profileId, pictureFile);
  }

  public async updateMyProfilePicture(
    id: string,
    version: number,
    picture: string,
  ): Promise<UserProfile> {
    const userProfile = await this.service.updateMyProfilePicture(
      id,
      version,
      picture,
    );
    if (this._cacheMyProfile.isValid) {
      this._cacheMyProfile.set(userProfile);
    }

    return Promise.resolve(userProfile);
  }

  public getProfileIdsForUserIds(
    userIds?: string[],
  ): Promise<{[key: string]: string}> {
    return this.service.getProfileIdsForUserIds(userIds);
  }

  public getMySubordinatesForProject(
    projectId?: string,
    includeAdministrators?: boolean,
  ): Promise<UsernameResponse[]> {
    return this.service.getMySubordinatesForProject(
      projectId,
      includeAdministrators,
    );
  }

  public getVisibleUsers(
    projectIds?: string[],
    includeAdministrators?: boolean,
  ): Promise<UsernameResponse[]> {
    return this.service.getVisibleUsers(projectIds, includeAdministrators);
  }

  /**
   * Initializes cache of usernames.
   * @param {boolean} force - determines whether to force data reload
   * @param {number} ttl - time-to-live of data in the cache
   */
  private async initCacheUsernames(
    force?: boolean,
    ttl?: number,
  ): Promise<void> {
    if (force || !this._cacheUsernames.isValid) {
      const usernames = await this.service.getUsernames();
      this._cacheUsernames.init(usernames, ttl ?? TTL_DAY);
    }
  }

  /**
   * Initializes cache of user profiles.
   * @param {any} filter - Loopback filter
   * @param {boolean} force - determines whether to force data reload
   * @param {number} ttl - time-to-live of data in the cache
   */
  private async initCacheUserProfiles(
    filter?: Filter<UserProfile>,
    force?: boolean,
    ttl?: number,
  ): Promise<void> {
    if (
      force ||
      !this._cacheUserProfiles.isValid ||
      !deepEqual(this._lastFilterUserProfiles, filter)
    ) {
      this._lastFilterUserProfiles = cloneDeep(filter);
      const profiles = await this.service.getUserProfiles(filter);
      this._cacheUserProfiles.init(
        profiles.map(p => [p.id, p]),
        ttl ?? TTL_DAY,
      );
    }
  }

  /**
   * Initializes cache of "my profile".
   * @param {boolean} force - determines whether to force data reload
   * @param {number} ttl - time-to-live of data in the cache
   */
  private async initCacheMyProfile(
    force?: boolean,
    ttl?: number,
  ): Promise<void> {
    if (force || !this._cacheMyProfile.isValid) {
      const profile = await this.service.getMyProfile();
      this._cacheMyProfile.init(profile, ttl ?? TTL_DAY);
    }
  }

  private userDeleted(e: {size: number; items: Map<string, User>}): void {
    for (const user of e.items.values()) {
      // delete from userProfile cache by userId
      if (this._cacheUserProfiles.isValid) {
        // find user profile by userId
        const userProfile = this._cacheUserProfiles
          .getValues()
          .find(up => up.userId === user.id);
        if (userProfile && this._cacheUserProfiles.has(userProfile.id)) {
          this._cacheUserProfiles.delete(userProfile.id);
        } else {
          console.warn(
            `Object with id '${userProfile.id}' not found in the cache.`,
          );
        }
      } else {
        console.warn(
          'Delete operation performed but cache is not initialized.',
        );
      }

      // delete from usernames cache
      if (this._cacheUsernames.isValid) {
        const idx = this._cacheUsernames.findIndex(i => i.id === user.id);
        if (idx >= 0) {
          this._cacheUsernames.deleteAt(idx);
        } else {
          console.warn(`Object with id '${user.id}' not found in the cache.`);
        }
      }
    }
  }

  private async userUpdated(e: {
    size: number;
    items: Map<string, User>;
  }): Promise<void> {
    await this.getUsernames({forceReload: true});
  }

  private async userProfileUpdated(e: {
    size: number;
    items: Map<string, UserProfile>;
  }): Promise<void> {
    await this.getUsernames({forceReload: true});
  }
}
