<div class="expanded"
     [class.open]="!collapsed"
     [class.delay]="!collapsed">
  <ng-content></ng-content>
</div>

<div class="collapsed"
     [class.open]="collapsed"
     [class.delay]="collapsed">
  <div class="pills">
    <ng-container *ngIf="dataSorted">
      <ng-container *ngIf="dataSorted.length > 0; else emptyTemplate">
        <div *ngFor="let item of dataSorted"
             class="pill"
             (click)="expandFilter.emit()">
          <div class="pill-content">
            <span class="pill-label">{{ item.label }}:</span>
            <span class="pill-value">{{ item.value }}</span>
          </div>
          <div *ngIf="!item.required"
               class="close"
               (click)="removeFilterValue.emit(item.key)">
            <i class="fa-solid fa-xmark"></i>
          </div>
        </div>
      </ng-container>

      <ng-template #emptyTemplate>
        <span class="empty-filter">
          {{ 'entity.feedback.filter.empty' | translate }}
        </span>
      </ng-template>
    </ng-container>
  </div>
  <dx-button (onClick)="expandFilter.emit()"
             icon="fa-solid fa-arrows-up-down"
             type="default"></dx-button>

</div>