import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {IStoreWithRelations} from '@retrixhouse/salesapp-shared/lib/models';
import {
  SettingNames,
  StoreAvatarSettings,
} from '@retrixhouse/salesapp-shared/lib/settings';
import {formatStore} from '@retrixhouse/salesapp-shared/lib/utils';
import {StoreService} from '@salesapp/shared/domain/store/services/store.service';
import {DevExtremeModule} from 'devextreme-angular';
import {BehaviorSubject} from 'rxjs';
import {environment} from 'src/environments/environment';
import {DataProvider} from '../../data.provider/data-provider';
import {Store} from '../../models';
import {TSimpleChanges} from '../../utils/angular.utils';

@Component({
  selector: 'app-store-avatar',
  templateUrl: './store-avatar.component.html',
  styleUrls: ['./store-avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StoreAvatarComponent implements OnChanges, OnInit {
  @Input() store: Store;
  @Input() showActionButton?: 'add' | 'remove' = undefined;
  @Input() showPeriodicLimitSummary = false;
  @Input() size: 'small' | 'normal' = 'normal';
  @Input() projectId: string;
  /**
   * Whether to show the chain logo or not.
   * If set, it will override the setting from the data provider
   */
  @Input() showChainLogo: boolean;
  /**
   * Whether to make the first line bold or not.
   * If set, it will override the setting from the data provider
   */
  @Input() line1Bold: boolean;
  /**
   * The template for the first line.
   * If set, it will override the setting from the data provider
   */
  @Input() line1Template: string;
  /**
   * The template for the second line.
   * If set, it will override the setting from the data provider
   */
  @Input() line2Template: string;

  @Output() actionButtonClick = new EventEmitter<Store>();

  pictureUrl: string;
  storeSettings: StoreAvatarSettings = {
    [SettingNames.StoreAvatar_Line1Bold]: undefined,
    [SettingNames.StoreAvatar_Line1Template]: undefined,
    [SettingNames.StoreAvatar_Line2Template]: undefined,
    [SettingNames.StoreAvatar_ShowChainLogo]: undefined,
  };

  storeDisplayText$ = new BehaviorSubject<{line1: string; line2: string}>(null);
  settingsEnum = SettingNames;

  constructor(
    private dataProvider: DataProvider,
    private storeService: StoreService,
  ) {}

  ngOnInit() {}

  ngOnChanges(changes: TSimpleChanges<this>): void {
    if (changes.showChainLogo?.currentValue != null) {
      this.storeSettings[SettingNames.StoreAvatar_ShowChainLogo] =
        changes.showChainLogo.currentValue;
    }

    if (changes.line1Bold?.currentValue != null) {
      this.storeSettings[SettingNames.StoreAvatar_Line1Bold] =
        changes.line1Bold.currentValue;
    }

    if (changes.line1Template?.currentValue != null) {
      this.storeSettings[SettingNames.StoreAvatar_Line1Template] =
        changes.line1Template.currentValue;
    }

    if (changes.line2Template?.currentValue != null) {
      this.storeSettings[SettingNames.StoreAvatar_Line2Template] =
        changes.line2Template.currentValue;
    }

    if (changes.projectId?.currentValue) {
      this.initilizeStoreSettings();
    }

    if (changes.store?.currentValue) {
      this.initilizeStoreSettings();

      const logo = this.store.chain?.logo;
      if (logo) {
        this.pictureUrl = `${environment.chainPictureBaseUrl}/${logo}`;
      } else {
        this.pictureUrl = `/assets/no-image.png`;
      }
    }
  }
  //
  // openStoreDetail() {
  //   this.storeService.openDetail(this.store.id);
  // }

  private initilizeStoreSettings() {
    if (this.store) {
      const settings = this.dataProvider.settingResolver.getStoreAvatarSettings(
        this.projectId,
      ) as StoreAvatarSettings;

      // first check if the settings are set as inputs, otherwise use the settings from the dataprovider

      const showChainLogo =
        this.storeSettings[SettingNames.StoreAvatar_ShowChainLogo];
      const line1Bold = this.storeSettings[SettingNames.StoreAvatar_Line1Bold];
      const line1Template =
        this.storeSettings[SettingNames.StoreAvatar_Line1Template];
      const line2Template =
        this.storeSettings[SettingNames.StoreAvatar_Line2Template];

      this.storeSettings = {
        [SettingNames.StoreAvatar_ShowChainLogo]:
          showChainLogo != null
            ? showChainLogo
            : settings[SettingNames.StoreAvatar_ShowChainLogo],
        [SettingNames.StoreAvatar_Line1Bold]:
          line1Bold != null
            ? line1Bold
            : settings[SettingNames.StoreAvatar_Line1Bold],
        [SettingNames.StoreAvatar_Line1Template]:
          line1Template != null
            ? line1Template
            : settings[SettingNames.StoreAvatar_Line1Template],
        [SettingNames.StoreAvatar_Line2Template]:
          line2Template != null
            ? line2Template
            : settings[SettingNames.StoreAvatar_Line2Template],
      };

      this.storeDisplayText$.next(
        formatStore(
          this.store as IStoreWithRelations,
          this.storeSettings,
          false,
        ),
      );
    }
  }

  actionButtonClicked(): void {
    this.actionButtonClick.emit(this.store);
  }

  getStoreDisplayText() {
    const displayText = this.storeDisplayText$.value;

    return `${displayText.line1} ${displayText.line2}`;
  }
}

@NgModule({
  imports: [DevExtremeModule, CommonModule, TranslateModule],
  declarations: [StoreAvatarComponent],
  exports: [StoreAvatarComponent],
})
export class StoreAvatarModule {}
