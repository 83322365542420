import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseCrudHttpService} from './base.http-service';
import {TodoAction} from '../../models';
import {ITodoActionDataService} from '../../interfaces/data-service';

@Injectable()
export class TodoActionHttpService
  extends BaseCrudHttpService<TodoAction>
  implements ITodoActionDataService
{
  constructor(http: HttpClient) {
    super(http, '/todo-actions');
  }
}
