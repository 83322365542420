import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Customer} from '../../models';
import {BaseCrudHttpService} from './base.http-service';
import {ICustomerDataService} from '../../interfaces/data-service';

@Injectable()
export class CustomerHttpService
  extends BaseCrudHttpService<Customer>
  implements ICustomerDataService
{
  constructor(http: HttpClient) {
    super(http, '/customers');
  }

  public deletePicture(customerId: string): Promise<void> {
    return this.DELETE<void>(`/customers/${customerId}/picture`);
  }
}
