import {ObjectMapCache} from '@retrixhouse/salesapp-shared/lib/caching';
import {ICustomerDataService} from '../../interfaces/data-service';
import {Customer} from '../../models';
import {CustomerHttpService} from '../http';
import {CustomerOfflineService} from '../offline';
import {BaseCrudDataService} from './base.data-service';

export class CustomerDataService extends BaseCrudDataService<
  Customer,
  ICustomerDataService,
  ObjectMapCache<string, Customer>
> {
  constructor(
    onlineService: CustomerHttpService,
    offlineService: CustomerOfflineService,
    cache: ObjectMapCache<string, Customer>,
  ) {
    super(onlineService, offlineService, cache);
  }

  public updatePicture(
    customerId: string,
    version: number,
    logo: string,
  ): Promise<Customer> {
    return this.service.update(customerId, {
      version: version,
      logo: logo,
    });
  }

  public deletePicture(customerId: string): Promise<void> {
    return this.service.deletePicture(customerId);
  }
}
