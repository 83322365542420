import {Injectable} from '@angular/core';
import {IGlobalSearchDataService} from '../../interfaces/data-service';
import {BaseOfflineService} from './base.offline-service';

@Injectable()
export class GlobalSearchOfflineService
  extends BaseOfflineService
  implements IGlobalSearchDataService
{
  public globalSearch(searchString: string): Promise<[]> {
    return Promise.reject('Offline service - not yet implemented');
  }
}
