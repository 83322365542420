import {TranslateService} from '@ngx-translate/core';
import {ObjectTypeNames} from '@retrixhouse/salesapp-shared/lib/common';
import {
  IObjectProperty,
  ValueType,
} from '@retrixhouse/salesapp-shared/lib/models';
import {resizeWorksheetCells} from '@retrixhouse/salesapp-shared/lib/utils/excel';
import {paramCase} from 'change-case';
import {DataType} from 'devextreme/common';
import {exportDataGrid} from 'devextreme/excel_exporter';
import {ExportingEvent} from 'devextreme/ui/data_grid';
import {Workbook} from 'exceljs';
import * as saveAs from 'file-saver';
import {FilterOperator, GridColumn} from './grid.interfaces';

export function propertiesToColumns(
  properties: IObjectProperty[],
  options: {
    translateService: TranslateService;
    objectTypeName: ObjectTypeNames;
    visibleProperties: string[];
    hiddenProperties?: string[];
    columnWidth?: number;
    dataFieldPath?: string;
    // dataFieldPathResolver?(property: IObjectProperty): string;
    columnNameResolver?(property: IObjectProperty): string;
  },
): GridColumn[] {
  const columns = properties.map(property => {
    const dataField = property.native
      ? `${options.dataFieldPath ? `${options.dataFieldPath}.` : ''}${
          property.name
        }`
      : `${
          options.dataFieldPath ? `${options.dataFieldPath}.` : ''
        }extendedProperties.${property.name}`;
    const i18nIdentifier = `entity.${paramCase(
      options.objectTypeName,
    )}.properties.${paramCase(property.name)}.label`;
    return {
      caption: options.translateService.instant(
        options.columnNameResolver
          ? options.translateService.instant(
              options.columnNameResolver(property),
            )
          : options.translateService.instant(i18nIdentifier),
      ),
      dataField,
      visible: options.visibleProperties.includes(dataField),
      hidden: options.hiddenProperties?.includes(dataField),
      width: options.columnWidth || 200,
      minWidth: 80,
      allowedHiding: false,
      propertyId: property.id,
      dataType: typeMap[property.valueType],
      cssClass: 'ellipsis',
      valueType: property.valueType,
      listId: property.listId,
      cellTemplate: cellTemplateMap[property.valueType] || null,
    };
  });
  return columns;
}

export function customExportToXlsx(props: {
  exportingEvent: ExportingEvent;
  customColumnsSettings: {
    dataField?: string;
    columnName: string;
    resolver: (rowData: any) => string;
    dataFieldResolver?: (dataField: string) => boolean;
  }[];
}) {
  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet('Main sheet');

  exportDataGrid({
    component: props.exportingEvent.component,
    worksheet: worksheet,
    customizeCell: ({gridCell, excelCell}) => {
      excelCell.font = {name: 'Arial', size: 12};
      excelCell.alignment = {horizontal: 'left'};

      if (gridCell.rowType === 'data') {
        const setting = props.customColumnsSettings.find(settings => {
          if (settings.dataFieldResolver) {
            return settings.dataFieldResolver(gridCell.column.dataField);
          }
          return settings.dataField === gridCell.column.dataField;
        });
        if (setting && setting.resolver) {
          excelCell.value = setting.resolver(gridCell.value);
        }
      }
    },
  }).then(function () {
    resizeWorksheetCells(worksheet);

    workbook.xlsx.writeBuffer().then(function (buffer: BlobPart) {
      saveAs(
        new Blob([buffer], {type: 'application/octet-stream'}),
        'DataGrid.xlsx',
      );
    });
  });
}

const typeMap: {[k in ValueType]?: DataType} = {
  [ValueType.Boolean]: 'boolean',
  [ValueType.Color]: 'string',
  [ValueType.Date]: 'date',
  [ValueType.DateTime]: 'datetime',
  [ValueType.Duration]: 'string',
  [ValueType.FileSize]: 'number',
  [ValueType.Float]: 'number',
  [ValueType.GenericList]: 'string',
  [ValueType.Integer]: 'number',
  [ValueType.MultiChoice]: 'string',
  [ValueType.PlainText]: 'string',
  [ValueType.PlainTextList]: 'string',
  [ValueType.RichText]: 'string',
  [ValueType.SingleChoice]: 'string',
  [ValueType.Time]: 'datetime',
};

const cellTemplateMap: {[k in ValueType]?: string} = {
  [ValueType.Boolean]: 'booleanCellTemplate',
  // TODO(milan): change when ValueType in shared will be updated
  [ValueType.PlainTextList]: 'plainTextListCellTemplate',
  // [ValueType.Color]: 'string',
  // [ValueType.Date]: 'date',
  // [ValueType.DateTime]: 'datetime',
  // [ValueType.Duration]: 'string',
  // [ValueType.FileSize]: 'number',
  // [ValueType.Float]: 'number',
  [ValueType.GenericList]: 'genericListCellTemplate',
  // [ValueType.Integer]: 'number',
  [ValueType.MultiChoice]: 'genericListCellTemplate',
  // [ValueType.PlainText]: 'string',
  // [ValueType.RichText]: 'string',
  [ValueType.SingleChoice]: 'genericListCellTemplate',
  // [ValueType.Time]: 'datetime',
};

export class GridUtils {
  static filterString(params: {
    filterValue: string;
    operator: FilterOperator;
    filteredValue: any;
  }) {
    const {filterValue, operator, filteredValue} = params;
    const normalizedFilterValue = this.normalizeString(filterValue);
    const normalizedFilteredValue = this.normalizeString(filteredValue);

    switch (operator) {
      case 'contains':
        return normalizedFilteredValue?.includes(normalizedFilterValue);
      case 'notcontains':
        return !normalizedFilteredValue?.includes(normalizedFilterValue);
      case 'startswith':
        return normalizedFilteredValue?.startsWith(normalizedFilterValue);
      case 'endswith':
        return normalizedFilteredValue?.endsWith(normalizedFilterValue);
      case '=':
        return normalizedFilteredValue === normalizedFilterValue;
      case '<>':
        return normalizedFilteredValue !== normalizedFilterValue;
    }
  }

  static accessPropertyData(path: string, object: {[K: string]: any}) {
    return path.split('.').reduce((o, i) => {
      if (o && o[i]) {
        return o[i];
      } else {
        return null;
      }
    }, object);
  }

  private static normalizeString(
    input: string | undefined,
  ): string | undefined {
    if (!!input) {
      return input
        .normalize('NFD')
        .replace(/\p{Diacritic}/gu, '')
        .toLowerCase();
    }
  }
}
