import {SimpleChange} from '@angular/core';
import {Subscription} from 'rxjs';

export interface TSimpleChange<T extends any> extends SimpleChange {
  previousValue: T;
  currentValue: T;
}

export type TSimpleChanges<T extends object> = {
  [K in keyof T]: TSimpleChange<T[K]>;
};

export function isNotFirstChange(change?: SimpleChange) {
  return (
    !!change &&
    change.previousValue !== change.currentValue &&
    !change.firstChange
  );
}

export function unsubscribe(
  subscription: Subscription | Array<Subscription | any> | any,
) {
  if (Array.isArray(subscription)) {
    subscription.forEach(unsubscribe);
    return [];
  }
  if (subscription instanceof Subscription) {
    subscription.unsubscribe();
    return;
  }
  return;
}

export function AutoUnsubscribe() {
  return function (constructor: Function) {
    const targetNgOnDestroy = constructor.prototype.ngOnDestroy;

    constructor.prototype.ngOnDestroy = function () {
      Object.values(this)
        .filter(property => {
          if (Array.isArray(property)) {
            return isSubscriptionArray(property);
          } else {
            return property instanceof Subscription;
          }
        })
        .forEach(property => unsubscribe(property));

      if (targetNgOnDestroy) {
        targetNgOnDestroy.apply(this);
      }
    };
  };
}

function isSubscriptionArray(arr: Array<Subscription>) {
  if (Array.isArray(arr) && arr.length) {
    return arr[0] instanceof Subscription;
  }
  return false;
}

export function trackByIndex(index: number, item: any) {
  return index;
}

export function trackById<T extends {id: string}>(index: number, item: T) {
  return item.id;
}

export function trackByKey<T>(key: keyof T) {
  return (index: number, item: T) => {
    return item[key];
  };
}
