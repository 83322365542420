import {Component, OnInit} from '@angular/core';
// import {Router} from '@angular/router';
// import {TranslateService} from '@ngx-translate/core';
// import {
//   ClientModeService,
//   NavigationService,
//   PhotoTransferService,
// } from '../../../../shared/services';
// import {
//   StoreOfflineService,
//   UserOfflineService,
// } from '../../../../shared/services/offline';
// import {DataProvider} from '../../../../shared/data.provider/data-provider';
// import {
//   IdAndVersion,
//   MobilePhoto,
//   PhotoCreate,
//   SetExtendedPropertiesRequest,
//   Store,
//   TourPlan,
//   UserProfile,
//   VisitDataResponse,
//   VisitResultRequest,
// } from '../../../../shared/models';
// import {
//   ConfirmationService,
//   ConfirmType,
// } from '../../../../shared/services/confirmation.service';
// import {ClientMode} from '../../../../shared/enums/client-mode.enum';
// import {DataTransferUploadProgress} from './data-transfer-upload.progress';
// import {arrayReplaceOrAdd, formatAddress} from '../../../../shared/utils/utils';
// import {ProjectStorePeriodicLimit} from '../../../../shared/models/project-store-periodic-limit.model';
// import {FailSafeWrapper} from './fail-safe/fail-safe.wrapper';

// import {formatDateTime} from '../../../../shared/pipes/localized-datetime.pipe';
// import {AppInterfaceService} from 'src/app/shared/app-interface/app-interface.service';

@Component({
  selector: 'app-data-transfer-upload',
  templateUrl: 'data-transfer-upload.component.html',
  styleUrls: ['data-transfer-upload.component.scss'],
})
export class DataTransferUploadComponent /*implements OnInit*/ {
  /*KEY_STORES = 'stores';
  KEY_TOUR_PLANS = 'tourPlans';
  KEY_VISIT_RESULTS = 'visitResults';
  KEY_USER_STORAGE = 'userStorage';
  KEY_USER_PHOTOS = 'userPhotos';

  FIXABLE_ERROR_CODES = ['400', '422'];
  FIXABLE_TYPE_KEYS = [
    this.KEY_STORES,
    this.KEY_TOUR_PLANS,
    this.KEY_VISIT_RESULTS,
  ];

  progressMap: Map<string, DataTransferUploadProgress> = new Map<
    string,
    DataTransferUploadProgress
  >();
  userProfile: UserProfile;

  showCompletedProgressBars = false;
  pendingObservablesCount: number;
  errorCount: {
    total: number;
    fixable: number;
    repeatable: number;
    ignore: number;
  };
  fixableErrorsCache: {[key: string]: {data: any; url: string; type: string}} =
    {};
  dataSize: number;
  stores: Store[];
  tourPlans: TourPlan[];
  failSafe: FailSafeWrapper;
  syncPending: boolean;

  constructor(
    private router: Router,
    private navigationService: NavigationService,
    public translate: TranslateService,
    private confirmationService: ConfirmationService,
    private dataProvider: DataProvider,
    private clientModeService: ClientModeService,
    private appInterfaceService: AppInterfaceService,
    private photoTransferService: PhotoTransferService,
    // offline services
    private storeOfflineService: StoreOfflineService,
    private userOfflineService: UserOfflineService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.progressMap = new Map<string, DataTransferUploadProgress>([
      [
        this.KEY_STORES,
        new DataTransferUploadProgress(
          'stores',
          'fa fa-shop',
          this.synchronizeStores,
        ),
      ],
      [
        this.KEY_TOUR_PLANS,
        new DataTransferUploadProgress(
          'user-visits',
          'fa fa-calendar-days',
          this.synchronizeVisits,
        ),
      ],
      [
        this.KEY_VISIT_RESULTS,
        new DataTransferUploadProgress(
          'visit-results',
          'fa fa-binary',
          this.synchronizeVisitResults,
        ),
      ],
      [
        this.KEY_USER_PHOTOS,
        new DataTransferUploadProgress(
          'user-photos',
          'fa fa-folder-image',
          this.synchronizePhotos,
        ),
      ],
      [
        this.KEY_USER_STORAGE,
        new DataTransferUploadProgress(
          'user-storage',
          'fa fa-folder-user',
          this.synchronizeUserStorage,
        ),
      ],
    ]);

    this.userOfflineService.getUserProfile('TODO').then(up => {
      this.failSafe = new FailSafeWrapper();
      this.userProfile = up;
      this.synchronizeData();
    });
  }

  async retryFailed(key?: string): Promise<void> {
    this.syncPending = true;
    this.failSafe.clear();
    if (key) {
      const progress = this.progressMap.get(key);
      if (!progress) {
        this.syncPending = false;
        return;
      }
      await progress.syncCallback.bind(this)();
      this.syncPending = false;
      if (this.failSafe.hasErrors()) {
        await this.storeFile();
      }
      return;
    }

    for (const [k, v] of this.progressMap.entries()) {
      if (v.error) {
        await v.syncCallback.bind(this)();
      }
    }

    this.syncPending = false;
    if (this.failSafe.hasErrors()) {
      await this.storeFile();
    }
  }

  async failSafeFinish(): Promise<void> {
    //await this.localStorageService.clearAll();
    await this.appInterfaceService.photoStorageClear();
    this.router.navigate(['/']);
  }

  async synchronizeData(): Promise<void> {
    if (!this.progressMap) {
      this.progressMap = new Map<string, DataTransferUploadProgress>();
    }

    this.syncPending = true;
    await this.synchronizeStores();
    await this.synchronizeVisits();
    await this.synchronizePhotos();
    await this.synchronizeVisitResults();
    await this.synchronizeUserStorage();

    this.syncPending = false;
    if (this.failSafe.hasErrors()) {
      await this.storeFile();
    }
  }

  async synchronizeStores(): Promise<void> {
    const [
      allStores,
      createdIds,
      updatedIds,
      deletedIds,
      extraProperties,
      storeFrequencies,
      updatedFrequencies,
    ] = await Promise.all([
      this.storeOfflineService.getList(),
      this.localStorageService.getModifiedIds('Create', 'Store'),
      this.localStorageService.getModifiedIds('Update', 'Store'),
      this.localStorageService.getModifiedIds('Delete', 'Store'),
      this.localStorageService.get<{
        [key: string]: SetExtendedPropertiesRequest;
      }>('UserObjectPropertyRequest', this.userProfile.userId),
      this.localStorageService.getAll<ProjectStorePeriodicLimit>(
        'UserStoreFrequency',
        this.userProfile.userId,
      ),
      this.localStorageService.getModifiedIds(
        'Update',
        'UserStoreFrequency',
        this.userProfile.userId,
      ),
    ]);

    this.stores = allStores;

    // init values for the map
    const createdStores: Store[] = [];
    const updatedStores: Store[] = [];
    let totalDataSize = 0;
    createdIds.forEach(id => {
      const store = allStores.find(i => i.id === id);
      if (!store) {
        return;
      }
      createdStores.push(store);
      totalDataSize += JSON.stringify(store).length;
    });

    updatedIds.forEach(id => {
      const store = allStores.find(i => i.id === id);
      if (!store) {
        return;
      }
      updatedStores.push(store);
      totalDataSize += JSON.stringify(store).length;
    });

    totalDataSize +=
      extraProperties && Object.keys(extraProperties).length > 0
        ? JSON.stringify(extraProperties).length
        : 0;

    totalDataSize += updatedFrequencies
      .map(uf => {
        const periodicLimits = (storeFrequencies ?? []).filter(
          i => i.storeId === uf,
        );

        return periodicLimits && periodicLimits.length > 0
          ? JSON.stringify(periodicLimits).length
          : 0;
      })
      .reduce((a, b) => a + b, 0);

    // init stores progress
    const modifiedCount =
      createdStores.length +
      updatedStores.length +
      deletedIds.length +
      Object.keys(extraProperties ?? {}).length +
      (updatedFrequencies ?? []).length;

    this.handleInit(this.KEY_STORES, modifiedCount, totalDataSize);

    for (const createdStore of createdStores) {
      try {
        const res = await this.dataProvider.store.create(createdStore);
        await this.localStorageService.deleteModifiedIds(
          'Create',
          'Store',
          createdStore.id,
        );
        this.stores = arrayReplaceOrAdd(this.stores, res);
        // set uid and version to updated store properly
        const updatedStoreIdx = updatedStores.findIndex(
          i => i.id === createdStore.id,
        );
        if (updatedStoreIdx !== -1) {
          const storeToUpdate = updatedStores[updatedStoreIdx];
          storeToUpdate.version = res.version;
          storeToUpdate.uid = res.uid;
          updatedStores.splice(updatedStoreIdx, 1, storeToUpdate);
        }
        this.handleSuccess(this.KEY_STORES, createdStore);
      } catch (e) {
        this.handleError(this.KEY_STORES, e, createdStore.id, createdStore);
        this.failSafe.addError(
          this.KEY_STORES,
          'create',
          createdStore.id,
          createdStore,
          e,
        );
      }
    }

    for (const updatedStore of updatedStores) {
      try {
        if (!updatedStore.version) {
          updatedStore.version =
            this.stores.find(s => s.id === updatedStore.id)?.version ?? 1;
        }

        const res = await this.dataProvider.store.update(
          updatedStore.id,
          updatedStore,
        );
        await this.localStorageService.deleteModifiedIds(
          'Update',
          'Store',
          updatedStore.id,
        );
        updatedStore.version = res.version;
        // set updated version
        this.stores = arrayReplaceOrAdd(this.stores, updatedStore);
        this.handleSuccess(this.KEY_STORES, updatedStore);
      } catch (e) {
        this.handleError(this.KEY_STORES, e, updatedStore.id, updatedStore);
        this.failSafe.addError(
          this.KEY_STORES,
          'update',
          updatedStore.id,
          updatedStore,
          e,
        );
      }
    }

    for (const deletedStore of deletedIds) {
      const {id, version} = deletedStore as IdAndVersion;
      try {
        await this.dataProvider.store.delete(id, version);
        await this.localStorageService.deleteModifiedIds('Delete', 'Store', id);
        this.handleSuccess(this.KEY_STORES);
      } catch (e) {
        this.handleError(this.KEY_STORES, e, id);
        this.failSafe.addError(this.KEY_STORES, 'delete', id, deletedStore, e);
      }
    }

    const notSyncedProperties = {};
    for (const [objectId, propertyValueList] of Object.entries(
      extraProperties ?? {},
    )) {
      try {
        await this.dataProvider.object.createOrUpdatePropertyValues(
          objectId,
          propertyValueList,
        );
        this.handleSuccess(this.KEY_STORES);
      } catch (e) {
        notSyncedProperties[objectId] = propertyValueList;
        this.handleError(this.KEY_STORES, e, objectId);
        this.failSafe.addError(
          this.KEY_STORES,
          'extraProperties',
          objectId,
          propertyValueList,
          e,
        );
      }
    }
    this.localStorageService.initDataSingle(
      'UserObjectPropertyRequest',
      notSyncedProperties,
      this.userProfile.userId,
    );

    for (const storeId of (updatedFrequencies as string[]) ?? []) {
      const periodicLimits = (storeFrequencies ?? []).filter(
        i => i.storeId === storeId,
      );
      try {
        await this.dataProvider.periodicLimit.setPeriodicLimitsForStore(
          storeId,
          periodicLimits,
        );
        await this.localStorageService.deleteModifiedIds(
          'Update',
          'UserStoreFrequency',
          storeId,
          this.userProfile.userId,
        );
        this.handleSuccess(this.KEY_STORES, periodicLimits);
      } catch (e) {
        this.handleError(this.KEY_STORES, e, storeId);
        this.failSafe.addError(
          this.KEY_STORES,
          'periodicLimits',
          storeId,
          periodicLimits,
          e,
        );
      }
    }

    this.localStorageService.initDataMap(
      'Store',
      this.stores.map(s => [s.id, s]),
    );
  }

  async synchronizeVisits(): Promise<void> {
    const [allTourPlans, createdIds, updatedIds, canceledIds] =
      await Promise.all([
        this.localStorageService.getAll<TourPlan>(
          'UserVisit',
          this.userProfile.userId,
        ),
        this.localStorageService.getModifiedIds(
          'Create',
          'UserVisit',
          this.userProfile.userId,
        ),
        this.localStorageService.getModifiedIds(
          'Update',
          'UserVisit',
          this.userProfile.userId,
        ),
        this.localStorageService.getAll<string>(
          'UserVisitCanceled',
          this.userProfile.userId,
        ),
      ]);

    this.tourPlans = allTourPlans;

    let totalDataSize = 0;
    const updatedTourPlans = [];
    const createdTourPlans = [];

    createdIds.forEach(id => {
      const tourPlan = allTourPlans.find(i => i.id === id);
      if (!tourPlan) {
        return;
      }

      // remove added navigational properties causing problems
      const {store, project, ...rest} = tourPlan;
      createdTourPlans.push(rest);
      totalDataSize += JSON.stringify(rest).length;
    });

    updatedIds.forEach(updatedId => {
      const tourPlan = allTourPlans.find(tp => tp.id === updatedId);
      if (!tourPlan) {
        return;
      }

      updatedTourPlans.push(tourPlan);
      totalDataSize += JSON.stringify(tourPlan).length;
    });

    totalDataSize +=
      canceledIds && canceledIds.length > 0
        ? canceledIds
            .map(id => JSON.stringify(id).length)
            .reduce((a, b) => a + b, 0)
        : 0;

    const modifiedCount =
      createdTourPlans.length + updatedTourPlans.length + canceledIds.length;
    this.handleInit(this.KEY_TOUR_PLANS, modifiedCount, totalDataSize);

    for (const createdTourPlan of createdTourPlans) {
      try {
        const res = await this.dataProvider.tourPlan.create(createdTourPlan);
        await this.localStorageService.deleteModifiedIds(
          'Create',
          'UserVisit',
          createdTourPlan.id,
          this.userProfile.userId,
        );
        this.tourPlans = arrayReplaceOrAdd(this.tourPlans, res);
        // set uid and version to updated store properly
        const updatedTourPlanIdx = updatedTourPlans.findIndex(
          i => i.id === createdTourPlan.id,
        );
        if (updatedTourPlanIdx !== -1) {
          const tourPlanToUpdate = updatedTourPlans[updatedTourPlanIdx];
          tourPlanToUpdate.version = res.version;
          tourPlanToUpdate.uid = res.uid;
          updatedTourPlans.splice(updatedTourPlanIdx, 1, tourPlanToUpdate);
        }
        this.handleSuccess(this.KEY_TOUR_PLANS, createdTourPlan);
      } catch (e) {
        this.handleError(
          this.KEY_TOUR_PLANS,
          e,
          createdTourPlan.id,
          createdTourPlan,
        );
        this.failSafe.addError(
          this.KEY_TOUR_PLANS,
          'create',
          createdTourPlan.id,
          createdTourPlan,
          e,
        );
      }
    }

    for (const updatedTourPlan of updatedTourPlans) {
      try {
        if (!updatedTourPlan.version) {
          updatedTourPlan.version =
            this.tourPlans.find(tp => tp.id === updatedTourPlan.id)?.version ??
            1;
        }
        const res = await this.dataProvider.tourPlan.updateIgnoreVersion(
          updatedTourPlan.id,
          updatedTourPlan,
        );
        await this.localStorageService.deleteModifiedIds(
          'Update',
          'UserVisit',
          updatedTourPlan.id,
          this.userProfile.userId,
        );

        // set updated version
        updatedTourPlan.version = res.version;
        this.tourPlans = arrayReplaceOrAdd(this.tourPlans, updatedTourPlan);
        this.handleSuccess(this.KEY_TOUR_PLANS, updatedTourPlan);
      } catch (e) {
        this.handleError(
          this.KEY_TOUR_PLANS,
          e,
          updatedTourPlan.id,
          updatedTourPlan,
        );
        this.failSafe.addError(
          this.KEY_TOUR_PLANS,
          'update',
          updatedTourPlan.id,
          updatedTourPlan,
          e,
        );
      }
    }

    const notSyncedIds = [];
    for (const canceledId of canceledIds) {
      try {
        await this.dataProvider.visit.cancelVisit(canceledId);
        this.handleSuccess(this.KEY_TOUR_PLANS, canceledId);
      } catch (e) {
        notSyncedIds.push(canceledId);
        this.handleError(this.KEY_TOUR_PLANS, e, canceledId);
        this.failSafe.addError(
          this.KEY_TOUR_PLANS,
          'cancel',
          canceledId,
          null,
          e,
        );
      }
    }

    await this.localStorageService.initDataArray(
      'UserVisitCanceled',
      notSyncedIds,
      this.userProfile.userId,
    );
    await this.localStorageService.initDataMap(
      'UserVisit',
      this.tourPlans.map(tp => [tp.id, tp]),
      this.userProfile.userId,
    );
  }

  async synchronizeVisitResults(): Promise<void> {
    const [visitResults, visitData] = await Promise.all([
      this.localStorageService.getAll<VisitResultRequest>(
        'UserVisitResultRequest',
        this.userProfile.userId,
      ),
      this.localStorageService.getAll<VisitDataResponse>(
        'UserVisitData',
        this.userProfile.userId,
      ),
    ]);

    // init progress values
    this.handleInit(
      this.KEY_VISIT_RESULTS,
      visitResults.length,
      visitResults.length > 0 ? JSON.stringify(visitResults).length : 0,
    );

    const notSyncedResults: VisitResultRequest[] = [];
    for (const visitResult of visitResults) {
      try {
        const res = await this.dataProvider.visit.saveVisitData(visitResult);
        // find in visit data one where todoListResult.id === visitResult.todoListResult.id
        // set version of the matched visitData
        const match =
          !!visitResult.todoListResult &&
          visitData.find(
            i => i.todoListResult?.id === visitResult.todoListResult.id,
          );
        if (match && match.todoListResult) {
          match.todoListResult.version = res.version;
        }

        this.handleSuccess(this.KEY_VISIT_RESULTS, visitResult);
      } catch (e) {
        notSyncedResults.push(visitResult);
        this.handleError(
          this.KEY_VISIT_RESULTS,
          e,
          visitResult.todoListResult.id,
          visitResult,
        );
        this.failSafe.addError(
          this.KEY_VISIT_RESULTS,
          'create',
          visitResult.todoListResult.id,
          visitResult,
          e,
        );
      }
    }

    // set reduced array back to the localstorage, empty hopefully
    this.localStorageService.initDataMap<string, VisitResultRequest>(
      'UserVisitResultRequest',
      notSyncedResults.map(r => [r.todoListResult.id, r]),
      this.userProfile.userId,
    );
    this.localStorageService.initDataMap<string, VisitDataResponse>(
      'UserVisitData',
      visitData.map(r => [r.visit.id, r]),
      this.userProfile.userId,
    );
  }

  async synchronizeUserStorage(): Promise<void> {
    const [userStorage, modifiedKeyNames] = await Promise.all([
      this.localStorageService.getAll<{key: string; value: string}>(
        'UserStorage',
        this.userProfile.userId,
      ),
      this.localStorageService.getModifiedIds(
        'Update',
        'UserStorage',
        this.userProfile.userId,
      ),
    ]);

    let totalDataSize = 0;
    const modifiedKeys = [];
    modifiedKeyNames.forEach(k => {
      const storageKey = userStorage.find(i => i.key === k);
      if (!storageKey) {
        return;
      }

      modifiedKeys.push(storageKey);
      totalDataSize += JSON.stringify(storageKey).length;
    });

    // init progress values
    this.handleInit(this.KEY_USER_STORAGE, modifiedKeys.length, totalDataSize);

    for (const modifiedKey of modifiedKeys) {
      try {
        await this.dataProvider.userStorage.set(
          modifiedKey.key,
          modifiedKey.value,
        );
        await this.localStorageService.deleteModifiedIds(
          'Update',
          'UserStorage',
          modifiedKey.key,
          this.userProfile.userId,
        );
        this.handleSuccess(this.KEY_USER_STORAGE, modifiedKey);
      } catch (e) {
        this.handleError(this.KEY_USER_STORAGE, e, modifiedKey);
        this.failSafe.addError(
          this.KEY_USER_STORAGE,
          'create',
          modifiedKey.key,
          modifiedKey.value,
          e,
        );
      }
    }
  }

  async synchronizePhotos(): Promise<void> {
    const [createdPhotos, deletedPhotos] = await Promise.all([
      this.localStorageService
        .getModifiedIds('Create', 'UserPhoto', this.userProfile.userId)
        .then(async (keys: string[]) => {
          if (!keys || keys.length === 0) {
            return Promise.resolve([]);
          }
          return this.localStorageService.getByKeyMulti<string, PhotoCreate>(
            'UserPhoto',
            keys,
            this.userProfile.userId,
          );
        })
        .then(res => {
          const tmp: {[key: string]: PhotoCreate} = {};
          res.forEach(r => (tmp[r[0]] = r[1]));
          return tmp;
        }),
      this.localStorageService.getModifiedIds(
        'Delete',
        'UserPhoto',
        this.userProfile.userId,
      ),
    ]);

    // collect size of photos for all created photos
    const photoSizes = await this.appInterfaceService.photoStorageKeysSize(
      Object.values(createdPhotos).map(cp => cp.photo.id),
    );

    let totalDataSize = 0;

    // add photo sizes to totalDataSize
    totalDataSize += Object.values(photoSizes).reduce((a, b) => a + b, 0);
    Object.values(createdPhotos).forEach(
      p => (totalDataSize += JSON.stringify(p).length),
    );

    totalDataSize +=
      deletedPhotos && deletedPhotos.length > 0
        ? deletedPhotos
            .map(dp => JSON.stringify(dp).length)
            .reduce((a, b) => a + b, 0)
        : 0;

    // init stores progress
    const modifiedCount =
      Object.keys(createdPhotos).length + deletedPhotos.length;
    this.handleInit(this.KEY_USER_PHOTOS, modifiedCount, totalDataSize);

    for (const [k, v] of Object.entries(createdPhotos)) {
      // k - key in format userId:tourPlanId:photoId
      // v - PhotoCreate object without base64 value
      try {
        const [userId, visitId, photoId] = k.split(':');
        const mp = {exif: v.exif, photo: v.photo} as MobilePhoto;
        if (userId !== this.userProfile.userId) {
          continue;
        }
        await this.dataProvider.photoObject.createPhoto(v);
        await this.photoTransferService.uploadInChunks(mp, v.visitId);
        await this.localStorageService.deleteModifiedIds(
          'Create',
          'UserPhoto',
          k,
          this.userProfile.userId,
        ); // remove info about created photo
        await this.localStorageService.deleteByKey(
          'UserPhoto',
          k,
          this.userProfile.userId,
        );
        await this.appInterfaceService.photoStorageDelete(v.photo.id); // remove photo binary data
        this.handleSuccess(this.KEY_USER_PHOTOS, mp);
        // add photo size to the synchronizedDataSize
        this.progressMap.get(this.KEY_USER_PHOTOS).synchronizedDataSize +=
          photoSizes[v.photo.id];
      } catch (e) {
        this.handleError(this.KEY_USER_PHOTOS, e, k, v);
        this.failSafe.addError(this.KEY_USER_PHOTOS, 'create', k, v, e);
      }
    }

    for (const deletedPhotoId of deletedPhotos as string[]) {
      try {
        await this.dataProvider.photoObject.deletePhoto(deletedPhotoId);
        await this.localStorageService.deleteModifiedIds(
          'Delete',
          'UserPhoto',
          deletedPhotoId,
          this.userProfile.userId,
        );
        this.handleSuccess(this.KEY_USER_PHOTOS, deletedPhotoId);
      } catch (e) {
        this.handleError(this.KEY_USER_PHOTOS, e, deletedPhotoId);
        this.failSafe.addError(
          this.KEY_USER_PHOTOS,
          'delete',
          deletedPhotoId,
          {id: deletedPhotoId},
          e,
        );
        this.failSafe.addError(
          this.KEY_USER_PHOTOS,
          'delete',
          deletedPhotoId,
          {id: deletedPhotoId},
          e,
        );
      }
    }
  }

  toggleShowMode(e): void {
    this.showCompletedProgressBars = !this.showCompletedProgressBars;
  }

  handleInit(key: string, modifiedCount: number, totalDataSize: number): void {
    this.progressMap.get(key).handleInit(modifiedCount, totalDataSize);
    this.refreshPendingObservablesCount();
    this.refreshErrorCount();
    this.refreshTotalDownloadedDataSize();
  }

  handleSuccess(key: string, obj?: any): void {
    this.progressMap.get(key).handleSuccess(obj);
    if (obj && this.fixableErrorsCache[obj.id]) {
      delete this.fixableErrorsCache[obj.id];
    }

    this.refreshPendingObservablesCount();
    this.refreshErrorCount();
    this.refreshTotalDownloadedDataSize();
  }

  handleError(key: string, e: any, objectId: string, object?: any): void {
    const progress = this.progressMap.get(key);
    if (key === this.KEY_USER_PHOTOS) {
      progress.handleError(objectId, 'ignore');
    } else {
      // parse error code from error
      const errorCode = this.parseErrorCode(e);
      const errorType =
        errorCode && this.FIXABLE_ERROR_CODES.includes(errorCode)
          ? 'fixable'
          : 'repeatable';

      // prepare and cache fixable error data
      if (
        errorType === 'fixable' &&
        this.FIXABLE_TYPE_KEYS.includes(key) &&
        object
      ) {
        let data;
        let url;
        if (key === this.KEY_TOUR_PLANS) {
          data = object;
          data.stateColor = '#f44336';
          data.store = this.stores?.find(s => s.id === data.storeId);
          data.storeAddress =
            data.store?.name +
            ', ' +
            formatAddress(data.store.address, true, false);
          url = this.generateFixUrl(key, objectId);
        } else if (key === this.KEY_VISIT_RESULTS) {
          data = this.tourPlans.find(
            i => object.todoListResult.tourPlanVisitId === i.id,
          ) as any;
          data.stateColor = '#f44336';
          data.store = this.stores?.find(s => s.id === data.storeId);
          data.storeAddress =
            data.store?.name +
            ', ' +
            formatAddress(data.store.address, true, false);
          url = this.generateFixUrl(key, data.id);
        } else if (key === this.KEY_STORES) {
          data = object;
          data.storeName = `${object.name} ${!!object.chain ? '/' : ''} ${
            object.chain?.name ?? ''
          }`;
          data.address = formatAddress(object.address);
          url = this.generateFixUrl(key, objectId);
        }

        // cache fixable error data
        this.fixableErrorsCache[objectId] = {
          data,
          type: key,
          url,
        };
      }

      progress.handleError(objectId, errorType);
    }

    this.refreshPendingObservablesCount();
    this.refreshErrorCount();
    this.refreshTotalDownloadedDataSize();
  }

  parseErrorCode(e: any): string {
    try {
      const {
        groups: {errorCode},
      } = /\[(?<errorCode>\d{3})\]/.exec(e);
      return errorCode;
    } catch (ignored) {
      console.error('Could not parse error code from error', e);
      return null;
    }
  }

  generateFixUrl(key: string, objectId: string): string {
    let url: string;
    switch (key) {
      case this.KEY_STORES:
        url = `/chains-and-stores/stores/${objectId}`;
        break;
      case this.KEY_TOUR_PLANS:
        url = `/tour-plans/${objectId}`;
        break;
      case this.KEY_VISIT_RESULTS:
        url = `data-collection/visit/${objectId}`;
        break;
      default:
        url = '/';
    }

    return url;
  }

  async fixUrClick(url: string): Promise<void> {
    this.clientModeService.clientMode = ClientMode.OFFLINE;
    this.router.navigate([url]);
  }

  refreshPendingObservablesCount(): void {
    this.pendingObservablesCount = Array.from(this.progressMap.keys())
      .map(k => {
        const progress = this.progressMap.get(k);
        return (progress.error && progress.error.length > 0) ||
          !progress.completed
          ? 1
          : 0;
      })
      .reduce((a, b) => a + b, 0);
  }

  refreshErrorCount(): void {
    let totalCount = 0;
    let fixableCount = 0;
    let repeatableCount = 0;
    let ignoreCount = 0;
    Array.from(this.progressMap.keys()).forEach(k => {
      const progress = this.progressMap.get(k);
      if (progress.error && progress.error.length > 0) {
        totalCount += progress.error.length;
        progress.error.forEach(pe => {
          if (pe.fixable) {
            fixableCount++;
          }
          if (pe.repeatable) {
            repeatableCount++;
          }

          if (pe.ignore) {
            ignoreCount++;
          }
        });
      }
    });

    this.errorCount = {
      total: totalCount,
      fixable: fixableCount,
      repeatable: repeatableCount,
      ignore: ignoreCount,
    };
  }

  refreshTotalDownloadedDataSize(): void {
    let dataSize = 0;
    this.progressMap.forEach((value, key) => {
      dataSize += value.synchronizedDataSize ?? 0;
    });

    this.dataSize = dataSize;
  }

  formatBtnContinueText(): string {
    const pendingObservables = this.pendingObservablesCount;
    if (pendingObservables === 0) {
      return this.translate.instant('buttons.continue');
    }

    return `${this.translate.instant('buttons.continue')} (${
      this.progressMap.size - pendingObservables
    } ${this.translate.instant('labels.of')} ${this.progressMap.size})`;
  }

  async btnContinueClick(): Promise<void> {
    await this.localStorageService.clearAll();
    await this.appInterfaceService.photoStorageClear();
    this.router.navigate(['/']);
  }

  async btnCancelClick(): Promise<void> {
    const confirmResult = await this.confirmationService.confirm(
      ConfirmType.CONFIRM,
      this.translate.instant('views.data-transfer.leave-transfer'),
      this.translate.instant('view.visit.leave-transfer-confirm'),
    );

    if (confirmResult) {
      this.clientModeService.clientMode = ClientMode.OFFLINE;
      this.router.navigate(['/']);
    }
  }

  btnOpenFileManagerClick(): void {
    this.appInterfaceService.fileStorageDisplay();
  }

  async storeFile(): Promise<void> {
    const fileName = `${formatDateTime(
      this.failSafe.initTimestamp,
      'YYYY-MM-DD HH:mm:ss',
    )}.json`;

    await this.localStorageService.fileStorageSave(
      fileName,
      this.failSafe.getSerializedContent(),
    );
  }*/
}
