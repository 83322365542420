import {DIALOG_DATA, DialogRef} from '@angular/cdk/dialog';
import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {FeedbackWithUserAndOpenedInfo} from '@salesapp/shared/models';
import {FeedbackStorageService} from '../../services/feedback-storage.service';

@Component({
  selector: 'app-feedback-detail-dialog',
  templateUrl: './feedback-detail-dialog.component.html',
  styleUrls: ['./feedback-detail-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeedbackDetailDialogComponent {
  get feedbackId() {
    return this.data.feedbackId;
  }

  constructor(
    private dialogRef: DialogRef<unknown, FeedbackDetailDialogComponent>,
    @Inject(DIALOG_DATA) private data: FeedbackDetailDialogData,
    private storageService: FeedbackStorageService,
  ) {}

  onClose() {
    this.dialogRef.close();
  }
}

export interface FeedbackDetailDialogData {
  feedbackId: string;
}
