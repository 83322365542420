import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Store} from 'src/app/shared/models';
import {BaseCrudHttpService} from './base.http-service';
import {
  IStoreActivityRequest,
  IExtendedPropertySearchRequest,
} from '@retrixhouse/salesapp-shared/lib/requests';
import {IStoreActivityResponse} from '@retrixhouse/salesapp-shared/lib/responses/store-activity';
import {IStoreDataService} from '../../interfaces/data-service';

@Injectable()
export class StoreHttpService
  extends BaseCrudHttpService<Store>
  implements IStoreDataService
{
  constructor(http: HttpClient) {
    super(http, '/stores');
  }

  getAllStores(): Promise<Store[]> {
    return this.GET<Store[]>('/stores/all');
  }

  public getStoresForChain(chainId?: string | null): Promise<Store[]> {
    let options = undefined;
    if (chainId !== undefined) {
      options = {
        params: {
          filter: JSON.stringify({
            where: {chainId},
          }),
        },
      };
    }

    return this.GET<Store[]>(`/stores`, options);
  }

  public evaluateStores(
    expression: string,
  ): Promise<{valid: boolean; total: number; match: number}> {
    return this.POST('/expression-eval/store', {expression});
  }

  public getStoreActivities(
    request: IStoreActivityRequest,
  ): Promise<IStoreActivityResponse> {
    return this.POST<IStoreActivityResponse>(
      `/stores/${request.storeId}/activity`,
      request,
    );
  }

  getStoresForExtendedProperties(request: IExtendedPropertySearchRequest): Promise<Store[]> {
    return this.POST<Store[]>('/stores/extended-property-search', request);
  }
}
