import {Injectable} from '@angular/core';
import {OldBaseStorageService} from './old-base-storage-service';
import {DataProvider} from '../../data.provider/data-provider';
import {IUsernameResponse} from '@retrixhouse/salesapp-shared/lib/responses';
import {Filter} from '@loopback/filter';
import {Observable, from} from 'rxjs';
import {UserDataService} from '../data';
import {formatUser} from '@salesapp/utils/utils';

@Injectable()
export class UsernameStorageService extends OldBaseStorageService<
  IUsernameResponse,
  IUsernameResponse[]
> {
  constructor(dataProvider: DataProvider) {
    super(dataProvider.user);
  }

  getData(input?: {filter?: Filter}): Observable<IUsernameResponse[]> {
    return from((this.dataService as UserDataService).getUsernames());
  }

  entityToInputSelectOption(entity: IUsernameResponse) {
    return {
      name: formatUser(entity),
      value: entity.id,
      data: entity,
    };
  }
}
