import {Injectable} from '@angular/core';
import * as expressionEval from 'expression-eval';

@Injectable()
export class ExpressionEvalutaionService {
  evaluateExpression(expression: any, context: any): boolean {
    if (!expression || !context) {
      return false;
    }

    try {
      const calculationExpressionParsed = expressionEval.parse(expression);
      return expressionEval.eval(calculationExpressionParsed, context);
    } catch {
      return false;
    }
  }
}
