import {Injectable} from '@angular/core';
import {BaseOfflineService} from './base.offline-service';
import {
  BulkSetSettingRequest,
  ResolveSettingsRequest,
  Setting,
} from '../../models';
import {ISettingValues} from '@retrixhouse/salesapp-shared/lib/models';
import {ISettingsDataService} from '../../interfaces/data-service';

@Injectable()
export class SettingsOfflineService
  extends BaseOfflineService
  implements ISettingsDataService
{
  public resolveSettingValue(name: string, projectId: string): Promise<any> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public resolveSettingValues(
    request: ResolveSettingsRequest,
  ): Promise<{[prop: string]: any}> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public resolveAllValues(): Promise<ISettingValues[]> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public create(setting: Setting): Promise<Setting> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public getAll(): Promise<Setting[]> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public getOne<T = any>(idOrName: string): Promise<Setting<T>> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public set(idOrName: string, value?: any): Promise<void> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public bulkSet(settingsToUpdate: BulkSetSettingRequest[]): Promise<void> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public deleteByIdOrName(settingId: string): Promise<void> {
    return Promise.reject('Offline service - not yet implemented');
  }
}
