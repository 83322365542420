import {Injectable} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {
  ContextItem,
  ObjectProperty,
  ObjectPropertyValue,
  ObjectType,
  RevisionsResponse,
  SetExtendedPropertiesRequest,
  StateTransitionRecord,
} from '../../models';
import {IObjectDataService} from '../../interfaces/data-service';
import {BaseOfflineService} from './base.offline-service';
import {IObjectForm} from '@retrixhouse/salesapp-shared/lib/models';

@Injectable()
export class ObjectOfflineService
  extends BaseOfflineService
  implements IObjectDataService
{
  public createObjectForm(form: Partial<IObjectForm>): Promise<IObjectForm> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public updateObjectForm(
    id: string,
    form: Partial<IObjectForm>,
  ): Promise<IObjectForm> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public deleteObjectForm(id: string): Promise<void> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public getNextAvailableUID(typeIdOrName: string): Promise<{uid: string}> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public getRevisions(
    id: string,
    limit?: number,
  ): Promise<RevisionsResponse[]> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public getStateTransitions(
    id: string,
    limit?: number,
  ): Promise<StateTransitionRecord[]> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public getObjectType(id: string): Promise<ObjectType> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public getContextItems(objectNameOrId: string): Promise<ContextItem[]> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public getObjectTypes(): Promise<ObjectType[]> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public getObjectProperties(typeIdOrName: string): Promise<ObjectProperty[]> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public getPropertiesValues(objectId: string): Promise<ObjectPropertyValue[]> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public getAllPropertyValuesForType(
    typeIdOrName: string,
  ): Promise<ObjectPropertyValue[]> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public deletePropertiesValues(objectId: string): Promise<void> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public createOrUpdatePropertyValues(
    objectId: string,
    propertyValueList: SetExtendedPropertiesRequest,
  ): Promise<ObjectPropertyValue[]> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public downloadPropertyValueFile(
    objectId: string,
    propertyId: string,
  ): Promise<HttpResponse<Blob>> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public createObjectProperty(
    property: Partial<ObjectProperty>,
  ): Promise<ObjectProperty> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public updateObjectProperty(
    id: string,
    property: Partial<ObjectProperty>,
  ): Promise<ObjectProperty> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public updateObjectPropertyPositions(
    positions: {id: string; position: number}[],
  ): Promise<void> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public deleteObjectProperty(id: string): Promise<void> {
    return Promise.reject('Offline service - not yet implemented');
  }
}
