import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ProjectAvatarComponent} from './project-avatar.component';

@NgModule({
  imports: [CommonModule],
  declarations: [ProjectAvatarComponent],
  providers: [],
  exports: [ProjectAvatarComponent],
})
export class ProjectAvatarModule {}
