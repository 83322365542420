<dx-tree-view
  [items]="_localTags"
  [height]="'100%'"
  [width]="'100%'"
  dataStructure="plain"
  displayExpr="name"
  keyExpr="id"
  parentIdExpr="parentId"
  [rootValue]="null"
  itemTemplate="listItem"
  (onItemSelectionChanged)="itemSelectionChanged($event)"
  (onItemClick)="itemClicked($event)"
  (onContentReady)="onContentReady($event)"
  (onItemRendered)="onItemRendered($event)"
  showCheckBoxesMode="normal"
  selectionMode="multiple"
  [selectNodesRecursive]="false"
  [expandAllEnabled]="true"
  [hoverStateEnabled]="false"
  [disabled]="readOnly"
>
  <div *dxTemplate="let tag of 'listItem'">
    <div class="tag-wrapper">
      <div class="tag" [ngClass]="{'has-error': errorKeys.has(tag.id)}">
        <span class="name">
          <span *ngIf="tag.hasItems; else leaf">
            <span class="feedback-chip feedback-chip-hover" [style]="{'background-color': tag.color}">{{ tag.name }}</span>
          </span>
          <ng-template #leaf>{{ tag.name }}</ng-template>

          <span class="error-icon" *ngIf="errorKeys.has(tag.id)">
            <span class="fa fa-circle-exclamation"></span>
          </span>
        </span>
      </div>
      <!--      <div class="description">{{ tag.description }}</div>-->
    </div>
  </div>
</dx-tree-view>
