import {ObjectMapCache} from '@retrixhouse/salesapp-shared/lib/caching';
import {IQuestionDataService} from '../../interfaces/data-service';
import {Question} from '../../models';
import {QuestionHttpService} from '../http';
import {QuestionOfflineService} from '../offline';
import {BaseCrudDataService} from './base.data-service';

export class QuestionDataService extends BaseCrudDataService<
  Question,
  IQuestionDataService,
  ObjectMapCache<string, Question>
> {
  constructor(
    onlineService: QuestionHttpService,
    offlineService: QuestionOfflineService,
    cache: ObjectMapCache<string, Question>,
  ) {
    super(onlineService, offlineService, cache);
  }
}
