import {Injectable} from '@angular/core';
// import {BaseWrapperBuilder} from '@retrixhouse/salesapp-shared/lib/expr-eval';
import {
  IGenericListItem,
  IObjectProperty,
} from '@retrixhouse/salesapp-shared/lib/models';
import {environment} from 'src/environments/environment';
import {GenericListItemStorageService} from './storage/generic-list-item-storage.service';
import {ObjectStorageService} from './storage/object-storage.service';

import {BaseWrapperBuilder} from '@retrixhouse/salesapp-shared/lib/expr-eval';
import {filter, take} from 'rxjs/operators';

@Injectable()
export class ObjectWrapperBuilderService extends BaseWrapperBuilder {
  defaultLocale = environment.defaultLocale;

  constructor(
    private objectStorageService: ObjectStorageService,
    private genericListItemStorageService: GenericListItemStorageService,
  ) {
    super();
  }

  protected async getObjectPropertiesMap(): Promise<
    Map<string, IObjectProperty[]>
  > {
    return this.objectStorageService.propertiesByObjectTypeId$
      .pipe(
        filter(propertiesByObjectId => propertiesByObjectId.size > 0),
        take(1),
      )
      .toPromise();
  }

  protected async getGenericListItemsMap(): Promise<
    Map<string, IGenericListItem>
  > {
    return this.genericListItemStorageService.dataById$
      .pipe(
        filter(genericListItemsMap => genericListItemsMap.size > 0),
        take(1),
      )
      .toPromise();
  }

  protected async getTranslationsMap?(): Promise<
    Map<string, {[k: string]: string}>
  > {
    return new Map<string, {[k: string]: string}>();
  }
}
