<app-grid appFileDropZone
          (filesDropped)="onFilesDropped($event)"
          (fileOver)="onFileOver($event)"></app-grid>
<div *ngIf="fileOver"
     class="file-over">
    <i class="fa-solid fa-file-arrow-down"></i>
    <span>{{ 'input-file.drop-message' | translate }}</span>
</div>

<div *ngIf="processingFiles$ | async"
     class="processing">
    <app-loading [customColor]="'#afafaf'"></app-loading>
    <span>
        {{ 'input-file.processing-files' | translate }}
    </span>
</div>