import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {BaseCrudHttpService} from './base.http-service';
import {DataStorageFile} from '../../models/data-storage-file.model';
import {IDataStorageFileDataService} from '../../interfaces/data-service';

@Injectable()
export class DataStorageFileHttpService
  extends BaseCrudHttpService<DataStorageFile>
  implements IDataStorageFileDataService
{
  constructor(http: HttpClient) {
    super(http, '/data-storage/files');
  }

  public uploadDataStorageFile(
    folderId: string,
    file: FormData,
  ): Promise<DataStorageFile> {
    return this.POST(`/data-storage/files/upload-file/${folderId}`, file);
  }

  public downloadDataStorageFile(fileId: string): Promise<any> {
    return this.GET(`/data-storage/files/download-file/${fileId}`, {
      observe: 'response',
      responseType: 'blob' as 'json',
    });
  }

  public downloadAsZip(fileIds: string[]): Promise<HttpResponse<Blob>> {
    return this.GET(`/data-storage/files/download-as-zip`, {
      observe: 'response',
      responseType: 'blob' as 'json',
      params: {
        ids: fileIds.join(','),
      },
    });
  }

  public copyDataStorageFile(file: DataStorageFile): Promise<any> {
    return this.POST(`/data-storage/files/copy-file`, file);
  }

  public getFilePathById(
    fileId: string,
  ): Promise<{fileName: string; filePath: string}> {
    return this.GET(`/data-storage/file-path/${fileId}`);
  }
}
