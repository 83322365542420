import {DIALOG_DATA, DialogRef} from '@angular/cdk/dialog';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {BehaviorSubject, Observable, from} from 'rxjs';
import {CustomValidators} from '../../utils/reactive-form/form-validators';
import {FormControlsOf} from '../../utils/reactive-form/reactive-form.interface';
import {LocalizedValue} from '../../models';
import * as uuid from 'uuid';
import {I18nStorageService} from '../../services/storage/i18n-storage.service';
import {getFormUpdatedValues} from '../../utils/reactive-form/form.utils';
import {DataProvider} from '../../data.provider/data-provider';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-translation-edit-dialog',
  templateUrl: './translation-edit-dialog.component.html',
  styleUrls: ['./translation-edit-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TranslationEditDialogComponent implements OnInit {
  form: FormGroup<TranslationFormGroup>;

  disableAction$: Observable<boolean>;

  loading$ = new BehaviorSubject<boolean>(true);

  get isUpdate() {
    return !!this.data?.localizedValue;
  }

  constructor(
    private dialogRef: DialogRef<any>,
    @Inject(DIALOG_DATA) private data: TranslationEditDialogData,
    private formBuilder: FormBuilder,
    private i18nStorageService: I18nStorageService,
    private dataProvider: DataProvider,
    private translateService: TranslateService,
  ) {}

  ngOnInit() {
    this.initForm();
    this.disableAction$ = this.i18nStorageService.dataCommandInProgress$;
  }

  onSave() {
    const input = getFormUpdatedValues(this.form);
    console.log(getFormUpdatedValues(this.form));
    if (this.isUpdate) {
      this.i18nStorageService
        .update({
          id: this.form.getRawValue().id,
          data: getFormUpdatedValues(this.form),
        })
        .subscribe({
          next: () => this.dialogRef.close(),
        });
    } else {
      this.i18nStorageService.create(input).subscribe({
        next: () => this.dialogRef.close(),
      });
    }
  }

  onClose() {
    this.dialogRef.close();
  }

  getFormControl(name: keyof LocalizedValueExtended) {
    return this.form.get(name) as FormControl;
  }

  getTranslationsControl(name: string) {
    return this.form.controls.translations.get(name) as FormControl;
  }

  getTranslation(key: string) {
    return this.translateService.instant(
      `entity.localized-value.properties.${key.toLowerCase()}.label`,
    );
  }

  private initForm() {
    from(this.dataProvider.i18N.getEnabledLocales()).subscribe({
      next: locales => {
        const form = this.formBuilder.group<TranslationFormGroup>({
          id: this.formBuilder.control(uuid.v4()),
          name: this.formBuilder.control(null, [CustomValidators.required]),
          description: this.formBuilder.control(null),
          translations: this.formBuilder.group({}),
        });
        // NOTE: translations are added dynamically based on enabled locales
        locales.forEach(locale => {
          form.controls.translations.addControl(
            locale.tag,
            this.formBuilder.control(null),
          );
        });

        if (this.isUpdate) {
          form.patchValue(this.data.localizedValue);
        }

        this.form = form;
        this.loading$.next(false);
      },
    });
  }
}

interface LocalizedValueExtended extends Omit<LocalizedValue, 'translations'> {
  translations: {
    [k: string]: string;
  };
}

export interface TranslationEditDialogData {
  localizedValue?: LocalizedValueExtended;
}

type TranslationFormGroup = FormControlsOf<LocalizedValueExtended>;
