<ng-container *ngIf="!customActions">
  <div class="actions-container"
       [style.justify-content]="position === 'right' ? 'end' : 'start'">
    <ng-content select="[formActionStart]"></ng-content>
    <ng-template [ngTemplateOutlet]="mobileDialogVersion ? cancelButton : actionButton"></ng-template>
    <ng-content select="[formActionCenter]"></ng-content>
    <ng-template [ngTemplateOutlet]="mobileDialogVersion ? actionButton : cancelButton"></ng-template>
    <ng-content select="[formActionEnd]"></ng-content>
  </div>
</ng-container>
<ng-container *ngIf="customActions">
  <ng-template [ngTemplateOutlet]="customActions"
               [ngTemplateOutletContext]="{
      action: actionButton,
      cancel: cancelButton
    }"></ng-template>
</ng-container>

<ng-template #actionButton>
  <dx-button *ngIf="actionVisible"
             type="default"
             [disabled]="isActionDisabled"
             [icon]="actionIcon"
             [text]="actionLabel"
             [attr.form]="formId"
             [stylingMode]="mobileDialogVersion ? 'text' : 'contained'"
             (click)="onAction(); action.emit(null)"></dx-button>
</ng-template>

<ng-template #cancelButton>
  <dx-button *ngIf="cancelVisible"
             type="normal"
             [disabled]="isCancelDisabled"
             [icon]="cancelIcon"
             [text]="cancelLabel"
             [stylingMode]="mobileDialogVersion ? 'text' : 'contained'"
             (click)="onCancel(); cancel.emit(null)"></dx-button>
</ng-template>