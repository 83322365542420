import {NgModule} from '@angular/core';
import {DxFileUploaderModule, DxButtonModule} from 'devextreme-angular';
import {FormFieldModule} from '../form-field';
import {CommonModule} from '@angular/common';
import {InputAvatarComponent} from './input-avatar.component';
import {TranslateModule} from '@ngx-translate/core';
import {CustomDirectivesModule} from '../../directives';

const DX_MODULES = [DxButtonModule, DxFileUploaderModule];

const UI_MODULES = [FormFieldModule];

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    CustomDirectivesModule,
    ...DX_MODULES,
    ...UI_MODULES,
  ],
  declarations: [InputAvatarComponent],
  exports: [InputAvatarComponent],
})
export class InputAvatarModule {}

export * from './input-avatar.component';
