import {ChangeDetectionStrategy, Component, ViewChild} from '@angular/core';
import {ObjectTypeIds} from '@retrixhouse/salesapp-shared/lib/common';
import {DxDataGridComponent} from 'devextreme-angular/ui/data-grid';
import {
  ExportingEvent,
  RowPreparedEvent,
  SelectionChangedEvent,
} from 'devextreme/ui/data_grid';
import {GridToolbarButton} from '../../grid.interfaces';
import {ObjectGridService} from '../../services/object-grid.service';

@Component({
  selector: 'app-object-grid',
  templateUrl: './object-grid.component.html',
  styleUrls: ['./object-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ObjectGridComponent {
  objectTypeIdsMap: Map<ObjectTypeIds, string>;

  shiftPressed = false;

  @ViewChild(DxDataGridComponent, {static: false})
  dataGrid: DxDataGridComponent;
  loading$ = this.gridService.loading$;

  get data$() {
    return this.gridService.data$;
  }

  get columns$() {
    return this.gridService.columns$;
  }

  get toolbarButtons$() {
    return this.gridService.toolbarButtons$;
  }

  get gridRowActions$() {
    return this.gridService.gridRowActions$;
  }

  get options() {
    return this.gridService.options;
  }

  get selectedData$() {
    return this.gridService.selectedData$;
  }

  constructor(private gridService: ObjectGridService<unknown>) {
    this.objectTypeIdsMap = this.createObjectTypeIdsMap();
  }

  onToolbarButtonClicked(button: GridToolbarButton) {
    this.gridService.handleToolbarButtonClick(button);
  }

  onExporting(exportingEvent: ExportingEvent) {
    this.gridService.exportData(exportingEvent);
  }

  onGridRowActionClicked(event, data) {
    this.gridService.handleGridRowActionClick(event, data.data);
  }

  onRowDoubleClick(event: {data: {id: string}}) {
    this.gridService.openDetail(event.data);
  }

  onRowPrepared(event: RowPreparedEvent) {
    this.gridService?.handleRowPrepared(event);
  }

  onSelectionChanged(event: SelectionChangedEvent) {
    this.gridService.handleRowSelectionChanged(event);
  }

  loadState = () => {
    return this.gridService?.loadState();
  };

  saveState = state => {
    return this.gridService?.saveState(state);
  };

  resetState = () => {
    this.gridService.resetState(this.dataGrid);
  };

  hasValue(value: unknown) {
    return value !== null && value !== undefined;
  }

  private createObjectTypeIdsMap() {
    const objectTypeIdsMap = new Map();
    Object.keys(ObjectTypeIds).forEach(key => {
      objectTypeIdsMap.set(ObjectTypeIds[key], key);
    });
    return objectTypeIdsMap;
  }
}
