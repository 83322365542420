import {ObjectArrayCache} from '@retrixhouse/salesapp-shared/lib/caching';
import {IRoleDataService} from '../../interfaces/data-service';
import {Role} from '../../models';
import {RoleHttpService} from '../http';
import {RoleOfflineService} from '../offline';
import {BaseReadonlyDataService} from './base.data-service';

export class RoleDataService extends BaseReadonlyDataService<
  Role,
  IRoleDataService,
  ObjectArrayCache<Role>
> {
  constructor(
    onlineService: RoleHttpService,
    offlineService: RoleOfflineService,
    cache: ObjectArrayCache<Role>,
  ) {
    super(onlineService, offlineService, cache);
  }
}
