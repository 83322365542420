import {Component, Input, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent {

  @Input() message: string;
  @Input() type: AlertType;

  alertTypes = AlertType;
  classes = {
    [AlertType.Success]: {
      alertClass: 'success',
      icon: 'fa-check-circle',
    },
    [AlertType.Info]: {
      alertClass: 'info',
      icon: 'fa-info-circle',
    },
    [AlertType.Warning]: {
      alertClass: 'warning',
      icon: 'fa-exclamation-triangle',
    },
    [AlertType.Error]: {
      alertClass: 'error',
      icon: 'fa-times-circle',
    },
  };

  getAlertClass(): string {
    return `alert ${this.classes[this.type].alertClass}`;
  }

  getIconClass(): string {
    return `icon fa ${this.classes[this.type].icon}`;
  }
}

export enum AlertType {
  Success,
  Info,
  Warning,
  Error,
}

@NgModule({
  declarations: [AlertComponent],
  imports: [CommonModule, RouterModule],
  exports: [AlertComponent],
})
export class AlertModule {}
