import {BaseReadonlyHttpService} from './base.http-service';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {DataTransferErrorDump} from '../../models/data-transfer-error-dump.model';
import {IFailSafeDataService} from '../../interfaces/data-service';

@Injectable()
export class FailSafeHttpService
  extends BaseReadonlyHttpService<DataTransferErrorDump>
  implements IFailSafeDataService
{
  constructor(http: HttpClient) {
    super(http, '/fail-safe/');
  }

  public initTransfer(data: {
    id: string;
    userId: string;
    clientVersion: string;
  }): Promise<{id: string}> {
    return this.POST<{id: string}>(`/fail-safe/init`, data);
  }

  public resetTransfer(id: string): Promise<{id: string}> {
    return this.POST<{id: string}>(`/fail-safe/${id}/reset`, {});
  }

  public finishTransfer(id: string): Promise<{id: string}> {
    return this.POST<{id: string}>(`/fail-safe/${id}/finish`, {});
  }

  public downloadDumpFile(objectId): Promise<HttpResponse<Blob>> {
    return this.GET(`/fail-safe/${objectId}/file`, {
      observe: 'response',
      responseType: 'blob',
    });
  }

  public remove(objectId: string): Promise<void> {
    return this.DELETE(`/fail-safe/${objectId}`);
  }
}
