import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ObjectTypeIds} from '@retrixhouse/salesapp-shared/lib/common/globals';
import {getGridServiceProvider} from '@salesapp/grid';
import {FileService, NotificationService} from '@salesapp/services';
import {AutoUnsubscribe} from '@salesapp/utils/angular.utils';
import {BehaviorSubject, Subscription} from 'rxjs';
import {delay} from 'rxjs/operators';
import {ATTACHMENT_TEMPLATES_ALLOWED_FILE_TYPES} from '../../attachment-templates.consts';
import {AttachmentTemplatesGridService} from '../../services/attachment-templates-grid.service';
import {AttachmentTemplatesService} from '../../services/attachment-templates.service';

@Component({
  selector: 'app-attachment-templates-grid',
  templateUrl: './attachment-templates-grid.component.html',
  styleUrls: ['./attachment-templates-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [getGridServiceProvider(AttachmentTemplatesGridService)],
})
@AutoUnsubscribe()
export class AttachmentTemplatesGridComponent {
  @Input() mode: 'manage' | 'generate';
  @Input() objectTypeId: ObjectTypeIds = ObjectTypeIds.UserProfile;
  @Input() objectId: string;

  fileOver: boolean = false;

  processingFiles$ = new BehaviorSubject<boolean>(false);

  private allowedFileTypes = ATTACHMENT_TEMPLATES_ALLOWED_FILE_TYPES;

  private subscriptions: Subscription[] = [];

  constructor(
    private fileService: FileService,
    private translateService: TranslateService,
    private attachmentTemplatesService: AttachmentTemplatesService,
    private attachmentTemplatesGridService: AttachmentTemplatesGridService,
  ) {}

  ngOnInit() {
    this.attachmentTemplatesGridService.init(this.mode);
    this.attachmentTemplatesGridService.setObjectId(this.objectId);
  }

  onFilesDropped(event: File[]) {
    if (event.length === 1) {
      this.processingFiles$.next(true);
      this.subscriptions.push(
        this.fileService
          .processFile({
            file: event[0],
            availableMimeTypes: this.allowedFileTypes,
            fileFormat: 'base64',
          })
          .pipe(delay(1000))
          .subscribe({
            next: base64File => {
              this.processingFiles$.next(false);
              this.attachmentTemplatesService.createAttachmentTemplate({
                objectTypeId: this.objectTypeId,
                file: event[0],
                base64File,
              });
            },
            error: error => {
              this.processingFiles$.next(false);
              NotificationService.notifyError(error);
            },
          }),
      );
    } else {
      // throw error
      NotificationService.notifyWarning(
        this.translateService.instant(
          'entity.attachment-template.grid.cannot-upload-multiple-files',
        ),
      );
    }
  }

  onFileOver(fileOver: boolean) {
    this.fileOver = fileOver;
  }
}
