<!-- TODO: remove unused attributes -->
<app-dialog [title]="'entity.user-management.dialog.create.title' | translate"
            [actionInProgress]="false"
            [actionLabel]="'form.action.save' | translate"
            [actionDisabled]="disableActions$ | async"
            [actionVisible]="true"
            [actionIcon]=""
            [cancelLabel]="'form.action.close' | translate"
            [cancelDisabled]="disableActions$ | async"
            [cancelVisible]="true"
            [cancelIcon]=""
            [formGroup]="form"
            (cancelClicked)="onClose()"
            (actionClicked)="onSave()">
  <app-form-row>
    <app-form-column>
      <app-input-text [formControl]="getFormControl('username')"
                      [formControlRef]="getFormControl('username')"
                      [label]="'entity.user.properties.username.label' | translate"></app-input-text>
      <ng-container *ngIf="usernameStatus$ | async; let usernameStatus">
        <div *ngIf="['available', 'exists'].includes(usernameStatus)"
             class="username-status"
             [ngClass]="{
                             available: usernameStatus === 'available',
                             exists: usernameStatus === 'exists'
                       }">
          <i [class]="usernameStatus === 'exists' ? 'fa-solid fa-circle-xmark' : 'fa-solid fa-circle-check'"></i>
          <span> {{ (usernameStatus === 'exists' ? 'entity.user.properties.username.status.exists' :
            'entity.user.properties.username.status.available') | translate }}</span>
        </div>
      </ng-container>
    </app-form-column>
    <app-form-column>
      <app-input-text [formControl]="getFormControl('otp')"
                      [formControlRef]="getFormControl('otp')"
                      [label]="'entity.user.properties.otp.label' | translate"
                      buttonIcon="fa-regular fa-shuffle"
                      (buttonClick)="onGeneratePassword()"></app-input-text>

    </app-form-column>
  </app-form-row>

  <app-form-row>
    <app-form-column>
      <app-input-select [formControl]="
                     getFormControl('positionId')"
                        [formControlRef]="getFormControl('positionId')"
                        [options]="positionOptions$ | async"
                        [label]="'entity.user.properties.position-id.label' | translate"></app-input-select>
    </app-form-column>

    <app-form-column>
      <app-input-select [formControl]="getFormControl('customerId')"
                        [formControlRef]="getFormControl('customerId')"
                        [options]="customerOptions$ | async"
                        [label]="'entity.user.properties.customer-id.label' | translate"></app-input-select>
    </app-form-column>
  </app-form-row>

  <app-form-row>
    <app-form-column>
      <app-input-text [formControl]="getFormControl('firstName')"
                      [formControlRef]="getFormControl('firstName')"
                      [label]="'entity.user.properties.first-name.label' | translate"></app-input-text>
    </app-form-column>

    <app-form-column>
      <app-input-text [formControl]="getFormControl('lastName')"
                      [formControlRef]="getFormControl('lastName')"
                      [label]="'entity.user.properties.last-name.label' | translate"></app-input-text>
    </app-form-column>
  </app-form-row>
</app-dialog>