import {
  animate,
  keyframes,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {AutoUnsubscribe} from '@salesapp/utils/angular.utils';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import {DialogService} from '@salesapp/dialog';
import {NotificationsDialogComponent} from '../notifications-dialog/notifications-dialog.component';
import {PushNotificationService} from '@salesapp/services';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-notifications-icon',
  templateUrl: './notifications-icon.component.html',
  styleUrls: ['./notifications-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('shake', [
      transition(':increment', [
        style({transform: 'scale(1)'}),
        animate(
          '1000ms ease-in',
          keyframes([
            style({transform: 'translate3d(-1px, 0, 0)', offset: 0.1}),
            style({transform: 'translate3d(2px, 0, 0)', offset: 0.2}),
            style({transform: 'translate3d(-4px, 0, 0)', offset: 0.3}),
            style({transform: 'translate3d(4px, 0, 0)', offset: 0.4}),
            style({transform: 'translate3d(-4px, 0, 0)', offset: 0.5}),
            style({transform: 'translate3d(4px, 0, 0)', offset: 0.6}),
            style({transform: 'translate3d(-4px, 0, 0)', offset: 0.7}),
            style({transform: 'translate3d(2px, 0, 0)', offset: 0.8}),
            style({transform: 'translate3d(-1px, 0, 0)', offset: 0.9}),
          ]),
        ),
      ]),
    ]),
  ],
})
@AutoUnsubscribe()
export class NotificationsIconComponent {
  badgeCount: number;

  private subs: Subscription;

  constructor(
    private dialogService: DialogService,
    private changeDetector: ChangeDetectorRef,
    private pushNotificationService: PushNotificationService,
  ) {}

  ngOnInit() {
    this.subs = this.pushNotificationService.notifications$.subscribe({
      next: notifications => {
        this.badgeCount = notifications.filter(
          notification => !notification.read,
        ).length;
        this.changeDetector.detectChanges();
      },
    });
  }

  onIconClick() {
    this.dialogService.open(NotificationsDialogComponent, {
      width: '100vw',
      height: '100vh',
    });
  }
}

export interface Notification {
  title: string;
  message: string;
  read: boolean;
  redirectUrl?: string;
}
