/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { create } from '../fn/object-attachment/create';
import { Create$Params } from '../fn/object-attachment/create';
import { delete$ } from '../fn/object-attachment/delete';
import { Delete$Params } from '../fn/object-attachment/delete';
import { download } from '../fn/object-attachment/download';
import { Download$Params } from '../fn/object-attachment/download';
import { generateFromTemplate } from '../fn/object-attachment/generate-from-template';
import { GenerateFromTemplate$Params } from '../fn/object-attachment/generate-from-template';
import { generateFromTemplates } from '../fn/object-attachment/generate-from-templates';
import { GenerateFromTemplates$Params } from '../fn/object-attachment/generate-from-templates';
import { getListForObject } from '../fn/object-attachment/get-list-for-object';
import { GetListForObject$Params } from '../fn/object-attachment/get-list-for-object';
import { getSingleForObject } from '../fn/object-attachment/get-single-for-object';
import { GetSingleForObject$Params } from '../fn/object-attachment/get-single-for-object';
import { ObjectAttachment } from '../models/object-attachment';
import { update } from '../fn/object-attachment/update';
import { Update$Params } from '../fn/object-attachment/update';

@Injectable({ providedIn: 'root' })
export class ObjectAttachmentApiService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `objectAttachmentControllerDownload()` */
  static readonly ObjectAttachmentControllerDownloadPath = '/core/object-attachments/download/{objectId}/{attachmentId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `download()` instead.
   *
   * This method doesn't expect any request body.
   */
  download$Response(params: Download$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return download(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `download$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  download(params: Download$Params, context?: HttpContext): Observable<void> {
    return this.download$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `objectAttachmentControllerGenerateFromTemplate()` */
  static readonly ObjectAttachmentControllerGenerateFromTemplatePath = '/core/object-attachments/generate-from-template';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `generateFromTemplate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  generateFromTemplate$Response(params?: GenerateFromTemplate$Params, context?: HttpContext): Observable<StrictHttpResponse<ObjectAttachment>> {
    return generateFromTemplate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `generateFromTemplate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  generateFromTemplate(params?: GenerateFromTemplate$Params, context?: HttpContext): Observable<ObjectAttachment> {
    return this.generateFromTemplate$Response(params, context).pipe(
      map((r: StrictHttpResponse<ObjectAttachment>): ObjectAttachment => r.body)
    );
  }

  /** Path part for operation `objectAttachmentControllerGenerateFromTemplates()` */
  static readonly ObjectAttachmentControllerGenerateFromTemplatesPath = '/core/object-attachments/generate-from-templates';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `generateFromTemplates()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  generateFromTemplates$Response(params?: GenerateFromTemplates$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return generateFromTemplates(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `generateFromTemplates$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  generateFromTemplates(params?: GenerateFromTemplates$Params, context?: HttpContext): Observable<any> {
    return this.generateFromTemplates$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `objectAttachmentControllerGetSingleForObject()` */
  static readonly ObjectAttachmentControllerGetSingleForObjectPath = '/core/object-attachments/{objectId}/{attachmentId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSingleForObject()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSingleForObject$Response(params: GetSingleForObject$Params, context?: HttpContext): Observable<StrictHttpResponse<ObjectAttachment>> {
    return getSingleForObject(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSingleForObject$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSingleForObject(params: GetSingleForObject$Params, context?: HttpContext): Observable<ObjectAttachment> {
    return this.getSingleForObject$Response(params, context).pipe(
      map((r: StrictHttpResponse<ObjectAttachment>): ObjectAttachment => r.body)
    );
  }

  /** Path part for operation `objectAttachmentControllerDelete()` */
  static readonly ObjectAttachmentControllerDeletePath = '/core/object-attachments/{objectId}/{attachmentId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `delete()` instead.
   *
   * This method doesn't expect any request body.
   */
  delete$Response(params: Delete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return delete$(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `delete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  delete(params: Delete$Params, context?: HttpContext): Observable<void> {
    return this.delete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `objectAttachmentControllerUpdate()` */
  static readonly ObjectAttachmentControllerUpdatePath = '/core/object-attachments/{objectId}/{attachmentId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `update()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  update$Response(params: Update$Params, context?: HttpContext): Observable<StrictHttpResponse<ObjectAttachment>> {
    return update(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `update$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  update(params: Update$Params, context?: HttpContext): Observable<ObjectAttachment> {
    return this.update$Response(params, context).pipe(
      map((r: StrictHttpResponse<ObjectAttachment>): ObjectAttachment => r.body)
    );
  }

  /** Path part for operation `objectAttachmentControllerGetListForObject()` */
  static readonly ObjectAttachmentControllerGetListForObjectPath = '/core/object-attachments/{objectId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getListForObject()` instead.
   *
   * This method doesn't expect any request body.
   */
  getListForObject$Response(params: GetListForObject$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ObjectAttachment>>> {
    return getListForObject(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getListForObject$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getListForObject(params: GetListForObject$Params, context?: HttpContext): Observable<Array<ObjectAttachment>> {
    return this.getListForObject$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ObjectAttachment>>): Array<ObjectAttachment> => r.body)
    );
  }

  /** Path part for operation `objectAttachmentControllerCreate()` */
  static readonly ObjectAttachmentControllerCreatePath = '/core/object-attachments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create$Response(params?: Create$Params, context?: HttpContext): Observable<StrictHttpResponse<ObjectAttachment>> {
    return create(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `create$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create(params?: Create$Params, context?: HttpContext): Observable<ObjectAttachment> {
    return this.create$Response(params, context).pipe(
      map((r: StrictHttpResponse<ObjectAttachment>): ObjectAttachment => r.body)
    );
  }

}
