import {
  IFeedbackWithUserAndOpenedInfo,
  IKPISetRelations,
  IKPISetWithRelations,
} from '@retrixhouse/salesapp-shared/lib/models';
import {FeedbackDetail} from '../components/feedback-detail/feedback-detail.component';
import {arrayToMap} from '@salesapp/utils/utils';
import {FeedbackKpiInputDisplayOptions} from '@retrixhouse/salesapp-shared/lib/common';
import {FeedbackUtils} from '../feedback.utils';
import {TranslateService} from '@ngx-translate/core';

export class FeedbackMapper {
  static feedbackToFeedbackDetail(
    feedback: IFeedbackWithUserAndOpenedInfo,
    kpiValueFormatting: FeedbackKpiInputDisplayOptions,
    translateService: TranslateService,
  ): FeedbackDetail {
    const feedbackDetail: FeedbackDetail = {
      createdBy: feedback.userInfo,
      createdAt: feedback.createdAt,
      visitFinishedAt: feedback.tourPlan.finishedAt,
      commentRequired: feedback.commentRequired,
      photoRequired: feedback.photoRequired,
      resolved: feedback.resolved,
      kpiSets: this.formatKpiSets(
        feedback,
        kpiValueFormatting,
        translateService,
      ),
      comment: this.formatComment(feedback),
    };
    return feedbackDetail;
  }

  private static formatComment(feedback: IFeedbackWithUserAndOpenedInfo) {
    if (feedback.comment) {
      return {
        text: feedback.comment?.comment,
        createdBy: feedback.tourPlanUserInfo[0],
        createdAt: feedback.comment.postedAt,
        photo: feedback.photos.find(photo => photo.objectId === feedback.id),
      };
    }

    return null;
  }

  private static formatKpiSets(
    feedback: IFeedbackWithUserAndOpenedInfo,
    formatting: FeedbackKpiInputDisplayOptions,
    translateService: TranslateService,
  ) {
    const kpiSetById = arrayToMap(
      feedback.kpiSets.map(kpiSet => ({
        ...kpiSet,
        kpiById: arrayToMap(
          (kpiSet as IKPISetWithRelations).items.map(item => item.kpi),
          'id',
        ),
      })),
      'id',
    );
    const kpiValuesByKpiId = arrayToMap(feedback.kpiValues, 'kpiId');
    return feedback.kpiValueSets.map(kpiValueSet => {
      const treshold = feedback.tourPlan.project.kpiSetThresholds
        ?.sort((a, b) => b.minValue - a.minValue)
        ?.find(treshold => kpiValueSet.rating >= treshold.minValue);
      const kpiSet = {
        rating: {
          value:
            formatting === FeedbackKpiInputDisplayOptions.PositiveNegative
              ? treshold.name
              : kpiValueSet.rating,
          description:
            formatting === FeedbackKpiInputDisplayOptions.PositiveNegative
              ? null
              : treshold.name,
          color: treshold.color,
        },
        description: kpiValueSet.text,
        photos: feedback.photos.filter(
          photo => photo.objectId === kpiValueSet.id,
        ),
        name: kpiSetById.get(kpiValueSet.kpiSetId).name,

        kpiItems: (
          kpiSetById.get(kpiValueSet.kpiSetId) as IKPISetRelations
        ).items.map(kpi => {
          const set = kpiSetById.get((kpi as any).setId);
          return {
            label: set.kpiById.get(kpi.kpiId).name,
            value: FeedbackUtils.fromatKpiValueDisplayValue({
              value: kpiValuesByKpiId.get(kpi.kpiId).value,
              formattingStyle: formatting,
              translateService: translateService,
            }),
            color: this.getKpiColor(kpiValuesByKpiId.get(kpi.kpiId).value),
          };
        }),
      };

      return kpiSet;
    });
  }

  private static getKpiColor(value) {
    if (value >= 0 && value < 30) {
      return colorMap.danger;
    }

    if (value >= 30 && value < 70) {
      return colorMap.warning;
    }

    if (value >= 70 && value <= 100) {
      return colorMap.success;
    }
  }
}

const colorMap = {
  success: '#5aab16',
  danger: '#e34d36',
  warning: '#f5a701',
};
