import {IBillingInfoDataService} from '../../interfaces/data-service';
import {BillingInfo, BillingInfoFilterRequest} from '../../models';
import {BillingInfoHttpService} from '../http';
import {BillingInfoOfflineService} from '../offline';
import {BaseDataService} from './base.data-service';

export class BillingInfoDataService extends BaseDataService<IBillingInfoDataService> {
  constructor(
    onlineService: BillingInfoHttpService,
    offlineService: BillingInfoOfflineService,
  ) {
    super(onlineService, offlineService);
  }

  public getFilteredList(
    filter: BillingInfoFilterRequest,
  ): Promise<BillingInfo[]> {
    return this.service.getFilteredList(filter);
  }
}
