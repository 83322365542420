import {Injectable} from '@angular/core';
import {BaseCrudHttpService} from './base.http-service';
import {HttpClient} from '@angular/common/http';
import {TourPlanChangeRequest} from '../../models';
import {ITourPlanChangeRequestDataService} from '../../interfaces/data-service';

@Injectable()
export class TourPlanChangeRequestHttpService
  extends BaseCrudHttpService<TourPlanChangeRequest>
  implements ITourPlanChangeRequestDataService
{
  constructor(http: HttpClient) {
    super(http, '/tour-plan-change-request');
  }

  createMany(objectData: Partial<TourPlanChangeRequest>): Promise<TourPlanChangeRequest[]> {
    return this.POST('/tour-plan-change-request', objectData);
  }

  public getMyTourPlanChangeRequests(): Promise<TourPlanChangeRequest[]> {
    return this.GET<TourPlanChangeRequest[]>('/tour-plan-change-request/my');
  }
}
