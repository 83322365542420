import {TranslateService} from '@ngx-translate/core';
import {
  GenericListStorageService,
  ObjectStorageService,
  UserStorageStorageService,
} from '@salesapp/storage';
import {DataProvider} from '../../data.provider/data-provider';
import {ActionsBuilderService} from '../../services/actions-builder.service';
import {GenericListItemStorageService} from '../../services/storage/generic-list-item-storage.service';
import {TourPlanColorService} from '../../services/tour-plan-color.service';
import {
  GridServiceImplementor,
  ObjectGridServiceImplementor,
} from './grid.interfaces';
import {GridSetupService} from './services/grid-setup.service';
import {GridService} from './services/grid.service';
import {ObjectGridService} from './services/object-grid.service';

export function getGridServiceProvider(implementor: any) {
  return {
    provide: GridService,
    useFactory: gridServiceFactory,
    deps: [
      implementor,
      DataProvider,
      ActionsBuilderService,
      GridSetupService,
      GenericListItemStorageService,
      TourPlanColorService,
    ],
  };
}

export function gridServiceFactory(
  implementor: GridServiceImplementor<unknown>,
  dataProvider: DataProvider,
  actionsBuilderService: ActionsBuilderService,
  gridSetupService: GridSetupService,
  genericListItemStorageService: GenericListItemStorageService,
  tourPlanColorService?: TourPlanColorService,
) {
  return new GridService(
    implementor,
    dataProvider,
    actionsBuilderService,
    gridSetupService,
    genericListItemStorageService,
    tourPlanColorService,
  );
}

export function getObjectGridServiceProvider(implementor: any) {
  return {
    provide: ObjectGridService,
    useFactory: objectGridServiceFactory,
    deps: [
      implementor,
      UserStorageStorageService,
      ActionsBuilderService,
      ObjectStorageService,
      GenericListStorageService,
      TranslateService,
    ],
  };
}

export function objectGridServiceFactory(
  implementor: ObjectGridServiceImplementor<unknown>,
  userStorageStorage: UserStorageStorageService,
  actionsBuilderService: ActionsBuilderService,
  objectStorageService: ObjectStorageService,
  genericListStorage: GenericListStorageService,
  translateService: TranslateService,
) {
  return new ObjectGridService(
    implementor,
    userStorageStorage,
    actionsBuilderService,
    objectStorageService,
    genericListStorage,
    translateService,
  );
}
