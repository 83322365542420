import {Injectable, EventEmitter, Output} from '@angular/core';

@Injectable()
export class LoadPanelService {
  @Output() visible$ = new EventEmitter<{
    visible: boolean;
    target?: string | Element;
  }>();

  /**
   * Call this method to display/hide the load panel over a specific DOM taget.
   * @param {boolean} visible - When true, the load panel will be visible. Otherwise, it will be hidden.
   * @param {string | Element} target - The id/Element of the target DOM to show the load panel over it. It it is not provided, the load panel will be shown over the whole window.
   * Required only when visible is true
   */
  trigger(visible: boolean, target?: string | Element) {
    this.visible$.emit({visible, target});
  }
}
