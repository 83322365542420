import {OrderState, OrderType} from '../globals';
import {BaseModel} from './base.model';
import {Product} from './product.model';
import {Project} from './project.model';
import {Store} from './store.model';
import {UsernameResponse} from './username-response.model';

export type OrderReportOptions = {
  projectId?: string;
  from?: Date;
  to?: Date;
};

export class Order extends BaseModel {
  orderState: OrderState;
  orderType: OrderType;
  createdById: string;
  storeId: string;
  projectId: string;
  createdAt: Date;
  deliveredAt?: Date;
  notes?: string;
  project?: Project;
  store?: Store;
  orderProduct?: OrderProduct[];
  products?: Product[];

  [key: string]: any;
  constructor() {
    super();

    this.orderState = OrderState.Submitted;
    this.orderType = null;

    this.storeId = null;
    this.projectId = null;
    this.deliveredAt = null;
    this.notes = null;
    this.orderProduct = [];
  }
}

export class OrderReport extends Order {
  storeUid: string;
  storeName: string;
  storeAddressLine1?: string;
  storeAddressLine2?: string;
  storeAddressZipCode?: string;
  storeAddressCity?: string;
  projectName: string;
  projectUid: string;
  todoListResultId: string;
  tourPlanId: string;
  tourPlanUid: string;
  tourPlanState: string;
  userInfo?: UsernameResponse;
}

export class OrderProduct extends BaseModel {
  productId: string;
  orderId: string;
  amount: number;
  product?: Product;
  expiresAt?: Date;

  constructor() {
    super();
    this.productId = null;
    this.orderId = null;
    this.amount = null;
  }
}
