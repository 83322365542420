export class Ping {

  greeting: string;
  version: string;
  date: string;
  url: string;

  constructor(props) {
    this.greeting = null;
    this.version = null;
    this.date = null;
    this.url = null;
  }

}
