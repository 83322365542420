import {IProjectStoreDataService} from '../../interfaces/data-service';
import {ProjectUserStore, Store} from '../../models';
import {
  SetUserStoreProjectRequest,
} from '../../models/requests/set-user-store-project.request';
import {ProjectUserStoreHttpService} from '../http';
import {ProjectUserStoreOfflineService} from '../offline';
import {BaseDataService} from './base.data-service';

export class ProjectStoreDataService extends BaseDataService<IProjectStoreDataService> {
  constructor(
    onlineService: ProjectUserStoreHttpService,
    offlineService: ProjectUserStoreOfflineService,
  ) {
    super(onlineService, offlineService);
  }

  public getStoresForCurrentUserInProjectContext(
    projectId: string,
  ): Promise<Store[]> {
    return this.service.getStoresForCurrentUserInProjectContext(projectId);
  }

  public getStoresForUserInProjectContext(
    projectId: string,
    userId: string,
    directAssignment?: boolean,
  ): Promise<Store[]> {
    return this.service.getStoresForUserInProjectContext(projectId, userId, directAssignment);
  }

  public getUserStoresRelatedToProject(
    projectId: string,
    onlyResponsibleUsers?: boolean,
  ): Promise<ProjectUserStore[]> {
    return this.service.getUserStoresRelatedToProject(
      projectId,
      onlyResponsibleUsers,
    );
  }

  public getUserStoreRelationForUser(): Promise<ProjectUserStore[]> {
    return this.service.getUserStoreRelationForUser();
  }

  public saveUserStoresRelatedToProject(
    request: SetUserStoreProjectRequest,
  ): Promise<ProjectUserStore[]> {
    return this.service.saveUserStoresRelatedToProject(request);
  }

  public checkAccessToStore(
    request: ProjectUserStore[],
  ): Promise<ProjectUserStore & {hasAccess: boolean}[]> {
    return this.service.checkAccessToStore(request);
  }
}
