import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {FormGroup, UntypedFormGroup} from '@angular/forms';
import {FormComponent} from '@salesapp/form';
import {AppInfoService} from '@salesapp/services';
import {trackByIndex} from '@salesapp/utils/angular.utils';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogComponent {
  @Input() title: string;
  @Input() formGroup: FormGroup | UntypedFormGroup;
  @Input() actionInProgress: boolean;
  @Input() disableChangedFormCheck: boolean;
  @Input() mobileVersion: boolean;

  @Input() additionalActions: DialogAdditionalAction[];

  @Input() actionLabel: string;
  @Input() actionDisabled: boolean;
  @Input() actionVisible: boolean;
  @Input() actionIcon: string;

  @Input() cancelLabel: string;
  @Input() cancelDisabled: boolean;
  @Input() cancelVisible: boolean;
  @Input() cancelIcon: string;

  @Input() noPadding: boolean;

  @Output() cancelClicked = new EventEmitter<void>();
  @Output() actionClicked = new EventEmitter<void>();

  @ContentChild('content', {static: true}) contentTemplate: TemplateRef<any>;
  @ViewChild(FormComponent, {static: false}) formComponent: FormComponent;

  trackByFn = trackByIndex;

  constructor(private appInfoService: AppInfoService) {}

  ngOnInit() {
    if (this.mobileVersion === undefined) {
      this.mobileVersion = this.appInfoService.isMobileVersion;
    }
  }

  onAction() {
    this.actionClicked.emit();
  }

  onCancel() {
    this.cancelClicked.emit();
  }

  onAdditionalAction(action: DialogAdditionalAction) {
    if (action.triggerFormValidation) {
      this.formComponent.onAction(action);
    }
  }
}

export interface DialogAdditionalAction {
  text: string;
  icon?: string;
  disabled?: boolean;
  triggerFormValidation?: boolean;
  clickHandleFn: () => void;
}
