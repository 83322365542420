import {IEnvironment} from './ienvironment';

export const environment: IEnvironment = {
  production: false,
  envName: 'beta',
  keycloakRealm: 'beta',
  keycloakClientId: 'beta-testing',
  keycloakUrl: 'https://kc.salesapp.online/auth',
  frontEndBaseUrl: 'https://beta.salesapp.online/',
  backEndBaseUrl: 'https://beta.salesapp.online/api/v1/',
  profilePictureBaseUrl: 'https://beta.salesapp.online/',
  productPictureBaseUrl: 'https://beta.salesapp.online/',
  customerPictureBaseUrl: 'https://beta.salesapp.online/',
  chainPictureBaseUrl: 'https://beta.salesapp.online/',
  photoObjectBaseUrl: 'https://beta.salesapp.online/',
  mapMarkerPictureBaseUrl: 'https://beta.salesapp.online/',
  reportingBaseUrl: 'https://beta.salesapp.online/rbe/api/',
  googleMapApiKey: 'AIzaSyAHOw0rTODowY1qNrYyXdWmIeTR28L2PQQ',
  googlePlacesCountry: 'sk',
  snapshotDelay: 10_000,
  defaultLocale: 'en-US',
  defaultCurrency: 'EUR',
};
