<app-dialog
  [title]="'entity.product-listing.dialog.copy.title' | translate"
  [actionLabel]="'buttons.copy' | translate"
  [actionVisible]="true"
  [actionIcon]="'fa fa-check'"
  [actionDisabled]="!(copyActionEnabled$ | async)"
  [cancelLabel]="'buttons.close' | translate"
  [cancelVisible]="true"
  [cancelIcon]="'fa fa-times'"
  [formGroup]="form"
  (cancelClicked)="onClose()"
  (actionClicked)="onCopy()"
>
  <app-form-row>
    <dx-progress-bar
      [visible]="false"
      width="100%"
      [min]="0"
      [max]="(copyProgress$ | async).totalRows"
      [value]="(copyProgress$ | async).processedRows"
      [class.complete]="(copyProgress$ | async).totalRows <= (copyProgress$ | async).processedRows"
    >
    </dx-progress-bar>
  </app-form-row>
  <app-form-row>
    <app-form-column>
      <app-input-text
        [formControl]="getFormControl('uid')"
        [formControlRef]="getFormControl('uid')"
        [label]="'entity.product-listing.properties.uid.label' | translate"
      ></app-input-text>
    </app-form-column>
    <app-form-column>
      <app-input-text
        [formControl]="getFormControl('name')"
        [formControlRef]="getFormControl('name')"
        [label]="'entity.product-listing.properties.name.label' | translate"
      ></app-input-text>
    </app-form-column>
  </app-form-row>
  <app-form-row>
    <app-form-column
      ><app-input-date
        [minDate]="minimumDate"
        [formControl]="getFormControl('validFrom')"
        [formControlRef]="getFormControl('validFrom')"
        [label]="'entity.product-listing.properties.valid-from.label' | translate"
      ></app-input-date
    ></app-form-column>
    <app-form-column
      ><app-input-date
        [minDate]="minimumDate"
        [formControl]="getFormControl('validTo')"
        [formControlRef]="getFormControl('validTo')"
        [label]="'entity.product-listing.properties.valid-to.label' | translate"
      ></app-input-date
    ></app-form-column>
  </app-form-row>
</app-dialog>
