import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {DxTextAreaModule, DxTextBoxModule} from 'devextreme-angular';
import {FormFieldModule} from '../form-field';
import {InputTextAreaComponent} from './input-text-area.component';

const DX_MODULES = [DxTextAreaModule];
const UI_MODULES = [FormFieldModule];

@NgModule({
  imports: [CommonModule, ...DX_MODULES, ...UI_MODULES],
  declarations: [InputTextAreaComponent],
  exports: [InputTextAreaComponent],
})
export class InputTextAreaModule {}

export * from './input-text-area.component';
