import {BaseCrudDataService} from './base.data-service';
import {ClientSideErrorConfHttpService} from '../http/client-side-error-conf.http-service';
import {ClientSideErrorConfOfflineService} from '../offline/client-side-error-conf.offline-service';
import {IClientSideErrorConf} from '@retrixhouse/salesapp-shared/lib/models';
import {IClientSideErrorConfDataService} from '../../interfaces/data-service/client-side-error-conf.iface';
import {ObjectMapCache} from '@retrixhouse/salesapp-shared/lib/caching';

export class ClientSideErrorConfDataService extends BaseCrudDataService<
  IClientSideErrorConf,
  IClientSideErrorConfDataService,
  ObjectMapCache<string, IClientSideErrorConf>
> {
  constructor(
    onlineService: ClientSideErrorConfHttpService,
    offlineService: ClientSideErrorConfOfflineService,
    cache: ObjectMapCache<string, IClientSideErrorConf>,
  ) {
    super(onlineService, offlineService, cache);
  }

  getUserConfig() {
    return this.service.getUserConfig();
  }
}
