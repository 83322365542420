import {Filter} from '@loopback/filter';
import {ObjectMapCache} from '@retrixhouse/salesapp-shared/lib/caching';
import {IProductDataService} from '../../interfaces/data-service';
import {Product} from '../../models';
import {ProductHttpService} from '../http';
import {ProductOfflineService} from '../offline';
import {
  BaseCrudDataService,
  CachingOptions,
  TTL_DAY,
} from './base.data-service';

export class ProductDataService extends BaseCrudDataService<
  Product,
  IProductDataService,
  ObjectMapCache<string, Product>
> {
  private _lastGetListOperation: {
    type: 'getList' | 'getForCustomer';
    customerId?: string;
  };

  constructor(
    onlineService: ProductHttpService,
    offlineService: ProductOfflineService,
    cache: ObjectMapCache<string, Product>,
  ) {
    super(onlineService, offlineService, cache);
  }

  public override getList(
    filter?: Filter<Product>,
    cachingOptions?: CachingOptions,
  ): Promise<Product[]> {
    this._lastGetListOperation = {type: 'getList'};
    return super.getList(
      filter,
      Object.assign(
        {},
        {forceReload: this._lastGetListOperation?.type !== 'getList'},
        cachingOptions,
      ),
    );
  }

  public async getForCustomer(
    customerId: string,
    enabledOnly: boolean = false,
    filter: Filter<Product> = {},
    cachingOptions?: CachingOptions,
  ): Promise<Product[]> {
    if (cachingOptions?.skipCache) {
      return this.service.getForCustomer(customerId, enabledOnly, filter);
    }

    if (
      this._lastGetListOperation?.type !== 'getForCustomer' ||
      this._lastGetListOperation?.customerId !== customerId ||
      cachingOptions?.forceReload
    ) {
      const myList = await this.service.getForCustomer(
        customerId,
        enabledOnly,
        filter,
      );
      this._cache.init(
        myList.map(cr => [cr.id, cr], cachingOptions?.ttl || TTL_DAY),
      );
    }

    this._lastGetListOperation = {type: 'getForCustomer', customerId};
    return this._cache.getValues();
  }

  public move(categoryId: string, productIds: string[]): Promise<void> {
    this._cache.invalidate();
    return this.service.moveProducts(categoryId, productIds);
  }

  public async updatePicture(
    productId: string,
    version: number,
    picture: string,
  ): Promise<Product> {
    const product = await this.service.updateProductPicture(
      productId,
      version,
      picture,
    );
    this._cache.set(product.id, product);
    return product;
  }

  public async deletePicture(productId: string): Promise<void> {
    await this.service.deleteProductPicture(productId);
    const product = this._cache.get(productId);
    if (product) {
      product.picture = undefined;
    }
  }
}
