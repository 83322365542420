<app-base-dialog [noPadding]="true"
                 [displayFooter]="false">
  <ng-container ngProjectAs="header">
    <div class="title">
      {{ title }}
    </div>
  </ng-container>
  <ng-container ngProjectAs="content">
    <app-loading-container [loading]="loading">
      <ng-content></ng-content>
    </app-loading-container>
  </ng-container>
</app-base-dialog>