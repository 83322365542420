<app-form-field [label]="label"
                [hint]="hint"
                [formControlRef]="formControlRef">
    <dx-number-box [value]="value"
                   [placeholder]="placeholder"
                   [disabled]="disabled"
                   [isValid]="isValid"
                   [step]="0"
                   [buttons]="buttons"
                   (valueChange)="onValueChange($event)"></dx-number-box>
</app-form-field>