/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { AttachmentTemplate } from '../../models/attachment-template';

export interface FetchAllForObjectType$Params {
  objectTypeId: string;
}

export function fetchAllForObjectType(http: HttpClient, rootUrl: string, params: FetchAllForObjectType$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AttachmentTemplate>>> {
  const rb = new RequestBuilder(rootUrl, fetchAllForObjectType.PATH, 'get');
  if (params) {
    rb.path('objectTypeId', params.objectTypeId, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<AttachmentTemplate>>;
    })
  );
}

fetchAllForObjectType.PATH = '/core/attachment-templates/{objectTypeId}';
