import {Injectable} from '@angular/core';
import {DialogService} from '@salesapp/dialog';
import {
  ProductListingCopyDialogComponent,
  ProductListingDialogData,
} from '../components/product-listing-copy-dialog/product-listing-copy-dialog.component';

@Injectable()
export class ProductListingService {
  constructor(private dialogService: DialogService) {}

  copy(listingId: string) {
    this.dialogService.open<
      ProductListingCopyDialogComponent,
      ProductListingDialogData
    >(ProductListingCopyDialogComponent, {
      data: {
        originalListingId: listingId,
      },
      width: '850px',
      height: '500px',
    });
  }
}
