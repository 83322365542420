import {Injectable} from '@angular/core';
import {BaseHttpService} from './base.http-service';
import {HttpClient} from '@angular/common/http';
import {ISystemDataService} from '../../interfaces/data-service';

@Injectable()
export class SystemHttpService
  extends BaseHttpService
  implements ISystemDataService {

  constructor(http: HttpClient) {
    super(http);
  }

  public refreshCaches(caches: string[]): Promise<string[]> {
    return this.POST<string[]>(`/sys/cache/refresh`, caches);
  }
}
