import {
  Directive,
  EventEmitter,
  HostBinding,
  HostListener,
  Output,
} from '@angular/core';

@Directive({
  selector: '[appFileDropZone]',
})
export class FileDropZoneDirective {
  @Output() filesDropped = new EventEmitter<File[]>();
  @Output() fileOver = new EventEmitter<boolean>();

  // @HostBinding('class.file-drop-zone-file-over') fileOver: boolean;

  @HostListener('dragover', ['$event'])
  onDragOver(event) {
    event.preventDefault();
    event.stopPropagation();
    this.fileOver.emit(true);
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave(event) {
    event.preventDefault();
    event.stopPropagation();
    this.fileOver.emit(false);
  }

  @HostListener('drop', ['$event'])
  onDrop(event) {
    event.preventDefault();
    event.stopPropagation();

    this.fileOver.emit(false);

    let files: File[] = event.dataTransfer.files;

    if (files.length > 0) {
      this.filesDropped.emit(files);
    }
  }
}
