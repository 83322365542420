<app-dialog [title]="'entity.client-side-error-conf.dialog.edit.title' | translate"
            [actionInProgress]="false"
            [actionLabel]="'form.action.save' | translate"
            [actionDisabled]="disableActions$ | async"
            [actionVisible]="true"
            [actionIcon]=""
            [cancelLabel]="'form.action.close' | translate"
            [cancelDisabled]="disableActions$ | async"
            [cancelVisible]="true"
            [cancelIcon]=""
            [formGroup]="form"
            (cancelClicked)="onClose()"
            (actionClicked)="onSave()">
  <app-form-row>
    <app-form-column>
      <app-input-switch [formControl]="getFormControl('enabled')"
                        [formControlRef]="getFormControl('enabled')"
                        [label]="'entity.client-side-error-conf.properties.enabled.label' | translate"></app-input-switch>
    </app-form-column>
  </app-form-row>
  <app-form-row>
    <app-form-column>
      <app-input-select [formControl]="getFormControl('userId')"
                        [formControlRef]="getFormControl('userId')"
                        [label]="'entity.client-side-error-conf.properties.user-id.label' | translate"
                        [options]="usersOptions$ | async"></app-input-select>
    </app-form-column>
  </app-form-row>
  <app-form-row>
    <app-form-column>
      <app-input-multiselect [formControl]="getFormControl('routes')"
                             [formControlRef]="getFormControl('routes')"
                             [label]="'entity.client-side-error-conf.properties.routes.label' | translate"
                             [options]="routesOptions"></app-input-multiselect>
    </app-form-column>
  </app-form-row>
  <app-form-row>
    <app-form-column>
      <app-input-text [formControl]="getFormControl('note')"
                      [formControlRef]="getFormControl('note')"
                      [label]="'entity.client-side-error-conf.properties.note.label' | translate"></app-input-text>
    </app-form-column>
  </app-form-row>
</app-dialog>