import {Injectable} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {DataStorageFile} from '../../models/data-storage-file.model';
import {IDataStorageFileDataService} from '../../interfaces/data-service';
import {BaseCrudOfflineService} from './base.offline-service';

@Injectable()
export class DataStorageFileOfflineService
  extends BaseCrudOfflineService<DataStorageFile>
  implements IDataStorageFileDataService
{
  public uploadDataStorageFile(
    folderId: string,
    file: FormData,
  ): Promise<DataStorageFile> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public downloadDataStorageFile(fileId: string): Promise<any> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public downloadAsZip(fileIds: string[]): Promise<HttpResponse<Blob>> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public copyDataStorageFile(file: DataStorageFile): Promise<any> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public getFilePathById(
    fileId: string,
  ): Promise<{fileName: string; filePath: string}> {
    return Promise.reject('Offline service - not yet implemented');
  }
}
