import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {DynamicFormAvatarUploadedEvent} from '@salesapp/dynamic-form';
import {IUserProfile} from '@retrixhouse/salesapp-shared/lib/models';
import {map} from 'rxjs/operators';
import {UserProfileStorageService} from '@salesapp/storage';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  ObjectTypeIds,
  RoleNames,
} from '@retrixhouse/salesapp-shared/lib/common';
import {
  ObjectDetailDynamicFormComponent,
  ObjectDetailForm,
} from '@salesapp/shared/modules';

@Component({
  selector: 'app-user-management-user-profile-form',
  templateUrl: './user-management-user-profile-form.component.html',
  styleUrls: ['./user-management-user-profile-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserManagementUserProfileFormComponent
  implements ObjectDetailForm<IUserProfile>
{
  @Input() userProfile: Partial<IUserProfile>;
  @Input() forceUpdate: boolean;

  @Output() actionCompleted = new EventEmitter<IUserProfile>();
  @Output() cancel = new EventEmitter<void>();

  readonlyRoles = [RoleNames.UserProfilesView];
  editRoles = [
    RoleNames.UserProfiles,
    RoleNames.UserProfilesEdit,
    RoleNames.Admin,
  ];

  actionInProgress$: Observable<boolean>;
  cancelDisabled$: Observable<boolean>;

  ObjectTypeIds = ObjectTypeIds;

  @ViewChild(ObjectDetailDynamicFormComponent)
  private objectDetailDynamicForm: ObjectDetailDynamicFormComponent;

  private updated$ = new BehaviorSubject<boolean>(false);

  get hasChanges() {
    return this.objectDetailDynamicForm.hasChanges;
  }

  constructor(private userProfileStorageService: UserProfileStorageService) {}

  ngOnInit() {
    this.actionInProgress$ =
      this.userProfileStorageService.dataCommandInProgress$;

    this.cancelDisabled$ = this.forceUpdate
      ? combineLatest([this.updated$, this.actionInProgress$]).pipe(
          map(([updated, actionInProgress]) => {
            if (!updated) {
              return true;
            }

            return actionInProgress;
          }),
        )
      : this.actionInProgress$;
  }

  onAction(formData: IUserProfile) {
    if (formData.version) {
      return this.userProfileStorageService
        .update({
          id: formData.id,
          data: formData,
        })
        .subscribe({
          next: data => {
            this.updated$.next(true);
            this.actionCompleted.emit(data);
          },
        });
    }
  }

  onCancel() {
    this.cancel.emit();
  }

  onPhotoUploadFinished(event: DynamicFormAvatarUploadedEvent) {
    if (event.responseState === 'success') {
      this.userProfileStorageService.updateUserProfilePicture({
        pictureUrl: event.value,
        id: event.objectId,
        version: event.version,
      });
    }
  }
}
