<!-- TODO: remove unused attributes -->
<app-dialog [title]="'entity.attachment-template.legend.dialog.title' | translate"
            [actionVisible]="false"
            [actionIcon]=""
            [cancelLabel]="'form.action.close' | translate"
            [cancelVisible]="true"
            [noPadding]="true"
            (cancelClicked)="onClose()">
    <div dialogContent
         class="legend">
        <ng-container [ngSwitch]="mode">
            <app-attachment-template-legend-list *ngSwitchCase="'list'"
                                                 (optionSelected)="changeMode('legend', $event)"></app-attachment-template-legend-list>
            <app-attachment-template-legend *ngSwitchCase="'legend'"
                                            [path]="selectedContextOption.path"
                                            (backClicked)="changeMode('list')"></app-attachment-template-legend>
        </ng-container>
    </div>
</app-dialog>