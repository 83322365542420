import {NgModule} from '@angular/core';
import {DxDateBoxModule} from 'devextreme-angular';
import {FormFieldModule} from '../form-field';
import {CommonModule} from '@angular/common';
import {InputDatetimeComponent} from './input-datetime.component';

const DX_MODULES = [DxDateBoxModule];

const UI_MODULES = [FormFieldModule];

@NgModule({
  imports: [CommonModule, ...DX_MODULES, ...UI_MODULES],
  declarations: [InputDatetimeComponent],
  exports: [InputDatetimeComponent],
})
export class InputDatetimeModule {}

export * from './input-datetime.component';
