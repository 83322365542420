import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {ObjectPermissions} from '@salesapp/api';
import {FormControlsOf} from '@salesapp/utils/reactive-form';
import {Observable} from 'rxjs';
import {InputMultiselectOption} from '../../../input-multiselect';
import {PositionStorageService, UserStorageService} from '@salesapp/storage';

@Component({
  selector: 'app-object-permissions-form-group',
  templateUrl: './object-permissions-form-group.component.html',
  styleUrls: ['./object-permissions-form-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ObjectPermissionsFormGroupComponent {
  @Input() formGroup: FormGroup<ObjectPermissionsFormControls>;
  @Input() disabled: boolean;

  positionOptions$: Observable<InputMultiselectOption[]>;
  userOptions$: Observable<InputMultiselectOption[]>;

  constructor(
    private positionStorageService: PositionStorageService,
    private userStorageService: UserStorageService,
  ) {
    this.positionOptions$ = this.positionStorageService.dataAsSelectOptions$;
    this.userOptions$ = this.userStorageService.dataAsSelectOptions$;
  }

  getFormControl(name: keyof ObjectPermissionsFormControls) {
    return this.formGroup.get(name) as FormControl;
  }
}

export type ObjectPermissionsFormControls = FormControlsOf<ObjectPermissions>;
