import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Comment} from '../../models/comment.model';
import {BaseCrudHttpService} from './base.http-service';
import {ICommentDataService} from '../../interfaces/data-service';

@Injectable()
export class CommentHttpService
  extends BaseCrudHttpService<Comment>
  implements ICommentDataService
{
  constructor(http: HttpClient) {
    super(http, '/core/comment');
  }

  public getCommentListByObjectId(objectId: string): Promise<Comment[]> {
    return this.GET<Comment[]>(`/core/comment/${objectId}`);
  }

  public deleteCommentsForObject(objectId: string): Promise<void> {
    return this.DELETE(`/core/comment/object/${objectId}`);
  }

  public deleteCommentList(commentIdList: string[]): Promise<void> {
    return this.POST(`/core/comment/list`, commentIdList);
  }
}
