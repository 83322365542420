import {Injectable} from '@angular/core';
import {IImexTemplateDataService} from '../../interfaces/data-service';
import {ImexFileValidationResponse} from '../../models';
import {ImexFormat, ImexTemplate} from '../../models/imex-template.model';
import {BaseReadonlyOfflineService} from './base.offline-service';

@Injectable()
export class ImexTemplateOfflineService
  extends BaseReadonlyOfflineService<ImexTemplate>
  implements IImexTemplateDataService
{
  public uploadFileForValidation(
    file: FormData,
  ): Promise<ImexFileValidationResponse> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public importFile(operationId: string, templateId: string) {
    return Promise.reject('Offline service - not yet implemented');
  }

  public exportFile(
    imexTemplateId: string,
    imexCustomColumnsOrder: string[],
    imexFormat: ImexFormat,
    exportId: string,
    filterBuilderValue: string,
  ) {
    return Promise.reject('Offline service - not yet implemented');
  }

  public getFileProcessingProgress(importExportId: string) {
    return Promise.reject('Offline service - not yet implemented');
  }

  getCurrentProcessingForUser(): Promise<any> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public getNumberOfRowsToExport(
    imexTemplateId: string,
    filterBuilderValue: string,
  ) {
    return Promise.reject('Offline service - not yet implemented');
  }

  getImexValidationResponse(operationId: string): Promise<ImexFileValidationResponse> {
    return Promise.reject('Offline service - not yet implemented');
  }
}
