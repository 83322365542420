import {sortByPosition} from '../globals';

/**
 * Helper class providing operations on the tree of items.
 */
export class TreeBase<
  T extends {
    id: string;
    parentId?: string;
    position?: number;
  },
> {
  protected _rootItems: T[];
  protected _itemsMap: Map<string, T>;
  protected _parentsMap: Map<string, T>;
  protected _subItemsMap: Map<string, T[]>;

  /**
   * Constructor.
   * @param {T[]} items - array of tree items
   */
  constructor(items: T[]) {
    this._itemsMap = new Map<string, T>(
      sortByPosition(items).map(i => [i.id, i]),
    );
    this._parentsMap = new Map<string, T>();
    this._subItemsMap = new Map<string, T[]>();

    // start with root items with no parent
    this._rootItems = items.filter(i => !i.parentId);

    // fill sub items for each root item
    this._rootItems.forEach(i => this.fillSubItems(i, items));
  }

  /**
   * Gets array of root level items.
   */
  public get rootItems(): T[] {
    return this._rootItems;
  }

  /**
   * Gets array of all items.
   */
  public get allItems(): T[] {
    return [...this._itemsMap.values()];
  }

  /**
   * Fills full tree of sub items for a given item (recursive function).
   * @param {T} item - item to fill tree for
   * @param {T[]} items - array of all items
   */
  private fillSubItems(item: T, items: T[]): void {
    const subItems = items.filter(i => {
      if (i.parentId === item.id) {
        this._parentsMap.set(i.id, item);
        return true;
      }
    });
    this._subItemsMap.set(item.id, subItems);
    subItems.forEach(i => this.fillSubItems(i, items));
  }

  /**
   * Finds particular item by id.
   * @param {string} id - id of the item to find
   * @returns {T | undefined} item or undefined if not found
   */
  public findItem(id: string): T | undefined {
    return this._itemsMap.get(id);
  }

  /**
   * Finds root item for a given item.
   * @param {string} id - id of the item to find root item for
   * @returns {T | undefined} - item or undefined if not found
   */
  public findRootItem(id: string): T | undefined {
    let i = this._itemsMap.get(id);
    let p = this._parentsMap.get(i.id);
    while (p) {
      i = this._itemsMap.get(p.id);
      p = this._parentsMap.get(i.id);
    }
    return i;
  }

  /**
   * Finds direct sub items of given item.
   * @param {string} id - id of the item to search in
   * @returns {T[] | undefined} array of direct sub items or undefined if item not found
   */
  public findSubItems(id: string): T[] | undefined {
    let i = this._itemsMap.get(id);
    if (i) {
      return this._subItemsMap.get(i.id);
    }
  }

  /**
   * Finds all sub items of given item as an array.
   * @param {string} id - id of the item to search in
   * @returns {T[] | undefined} array of sub items or undefined if item not found
   */
  public findAllSubItems(id: string): T[] | undefined {
    let i = this._itemsMap.get(id);
    if (i) {
      const result: T[] = [];
      const subItems = this._subItemsMap.get(i.id);
      if (subItems) {
        result.push(...subItems);
        for (const si of subItems) {
          const subsubItems = this.findAllSubItems(si.id);
          if (subsubItems) {
            result.push(...subsubItems);
          }
        }
      }

      return result;
    }
  }

  /**
   * Detemines whether a given item is direct sub item of another item.
   * @param {string} itemId - id of the item to search in
   * @param {string} subItemId - id of the sub item to search for
   * @returns {boolean} true if the sub item is direct descendant of the item; false otherwise
   */
  public isDirectSubItem(itemId: string, subItemId: string): boolean {
    const subItems = this.findSubItems(itemId);
    if (subItems?.find(i => i.id === subItemId)) {
      return true;
    }
    return false;
  }

  /**
   * Determines whether a given item is a sub item of another item.
   * @param {string} itemId - id of the item to search in
   * @param {string} subItemId - id of the sub item to search for
   * @returns {boolean} true if the sub item is descendant of the item on any level; false otherwise
   */
  public isSubItem(itemId: string, subItemId: string): boolean {
    const i = this._itemsMap.get(itemId);
    if (i) {
      const subItems = this._subItemsMap.get(itemId);
      if (subItems) {
        if (subItems.find(si => si.id === subItemId)) {
          return true;
        }
        for (const si of subItems) {
          if (this.isSubItem(si.id, subItemId)) {
            return true;
          }
        }
      }
    }
    return false;
  }

  /**
   * Returns the number of a specific item (e.g. 1.1.2, 3.1 ..) .
   * @param {Required<{position: number; parentId: string; id: string}>} item - the filter expression to filter by.
   */
  public getNodeNumberFromTree(item: T): string {
    let nodeNumber: string;
    if (item.parentId) {
      const parentNode = this.findItem(item.parentId);

      const nodeIndex = sortByPosition(
        [...this._itemsMap.values()].filter(i => i.parentId === item.parentId),
      ).findIndex(i => i.id === item.id);

      nodeNumber = `${this.getNodeNumberFromTree(parentNode)}.${nodeIndex + 1}`;
    } else {
      const previousQuestionItemWithParentCount = [
        ...this._itemsMap.values(),
      ].filter(i => i.parentId && i.position < item.position).length;
      nodeNumber = `${item.position - previousQuestionItemWithParentCount}`;
    }
    return nodeNumber;
  }

  /**
   * Filters items by a filter expression.
   * @param {( value: QuestionnaireItem, index: number, array: QuestionnaireItem[] )} predicate - the filter expression to filter by.
   */
  public filterItems(
    predicate: (value: T, index: number, array: T[]) => unknown,
  ): T[] | undefined {
    if (!predicate) {
      return [];
    }
    return [...this._itemsMap.values()].filter(predicate);
  }

  /**
   * Returns all items.
   * @returns {T[]} array of items
   */
  public getAllItems(): T[] {
    return [...this._itemsMap.values()];
  }

  /**
   * Finds root item for a given item.
   * @param {string} id - id of the item to find root item for
   * @returns {T | undefined} - item or undefined if not found
   */
  public findDirectRootItem(id: string): T | undefined {
    let p = this._parentsMap.get(id);
    return p;
  }

  /**
   * Finds particular item by its position.
   * @param {number} position - position of the item.
   * @returns {number | undefined} item or undefined if not found
   */
  findItemByPosition(position: number): T | undefined {
    return [...this._itemsMap.values()].find(
      item => item.position === position,
    );
  }

  /**
   * Returns the max available position of the questions
   * @returns {number} max position
   */
  getMaxAvailablePosition(): number {
    return Math.max(
      ...Array.from(this._itemsMap.values()).map(item => item.position),
    );
  }
}
