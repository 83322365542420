import {Customer} from './customer.model';
import {GenericListItem} from './generic-list.model';
import {Position} from './position.model';
import {UserProfile} from './user-profile.model';
import {BaseModel} from './base.model';

export class User extends BaseModel {
  uid: string;
  version: number;
  username: string;
  internal: boolean;
  emailVerified: boolean;
  phoneVerified: boolean;
  undeletable: boolean;
  positionId?: string | null;
  position?: Position | null;
  customerId?: string | null;
  customer?: Customer | null;
  profile?: UserProfile;
  extendedProperties: any;

  constructor() {
    super();
    this.internal = false;
    this.emailVerified = false;
    this.phoneVerified = false;
    this.positionId = null;
    this.customerId = null;
  }
}

export type UserProjectPosition = {
  userId: string;
  projectId: string;
  alias: string;
  abbreviation: string;
};
