import {Injectable} from '@angular/core';
import {SettingNames} from '@retrixhouse/salesapp-shared/lib/settings';
import {Subject} from 'rxjs';
import {DataProvider} from '../data.provider/data-provider';
import {isTileAvailableOffline, isValidTile, UserStorageKeys} from '../globals';
import {isEmptyArray} from '../utils/utils';
import {ClientModeService} from './client-mode.service';

@Injectable()
export class DashboardLayoutService {
  isReorderOpened: Subject<boolean> = new Subject<boolean>();

  constructor(
    private dataProvider: DataProvider,
    private clientModeService: ClientModeService,
  ) {}

  public openReorder(value: boolean): void {
    this.isReorderOpened.next(value);
  }

  public async getTilesOrderOptions(): Promise<string[]> {
    const [userStorage, userPosition] = await Promise.all([
      this.dataProvider.userStorage
        .get<{tiles: string[]}>(UserStorageKeys.DashboardLayout)
        .catch(ignored => Promise.resolve({tiles: []})),
      this.dataProvider.position.getForUser(),
    ]);

    const systemSetting = this.dataProvider.settingResolver.getValue(
      SettingNames.Dashboard_PositionLayout_Default,
    );
    const position = this.dataProvider.settingResolver.getValue(
      `dashboard.position-layout.${userPosition?.id}` as SettingNames,
    );

    let tiles: string[] = [];

    if (Array.isArray(userStorage?.tiles)) {
      tiles = userStorage.tiles.filter((t: string) => isValidTile(t));
    }

    if (isEmptyArray(tiles) && Array.isArray(position)) {
      tiles = position.filter((t: string) => isValidTile(t));
    }

    if (isEmptyArray(tiles) && Array.isArray(systemSetting)) {
      tiles = systemSetting.filter((t: string) => isValidTile(t));
    }

    if (!this.clientModeService.isClientOnline) {
      tiles = tiles.filter(t => isTileAvailableOffline(t));
    }

    return tiles;
  }

  public async saveTilesOrder(tiles: string[]): Promise<void> {
    await this.dataProvider.userStorage.set(UserStorageKeys.DashboardLayout, {
      tiles,
    });

    this.isReorderOpened.next(false);
  }
}
