<app-form-field [formControlRef]="formControlRef" [label]="label" [hint]="hint">
  <dx-date-box
    [value]="value"
    [placeholder]="placeholder"
    [showClearButton]="showClearButton"
    [disabled]="disabled"
    [elementAttr]="elementAttr"
    type="datetime"
    displayFormat="shortTime"
    [calendarOptions]="{visible: false}"
    [disabled]="disabled"
    (valueChange)="onValueChange($event)"
  >
    <dx-validator
      [validationGroup]="validation?.validationGroup"
      [name]="validation?.name"
      [elementAttr]="{id: elementAttr?.id + '-validator', editorName: 'dxDateBox'}"
    >
      <dxi-validation-rule *ngIf="validation?.isRequired" type="required">
      </dxi-validation-rule>
      <ng-container *ngFor="let customValidationCB of validation?.customValidationCBs">
        <dxi-validation-rule type="custom" [validationCallback]="customValidationCB">
        </dxi-validation-rule>
      </ng-container>
    </dx-validator>
  </dx-date-box>
</app-form-field>
