import {Injectable} from '@angular/core';
import {OldBaseStorageService} from './old-base-storage-service';
import {IProductListing} from '@retrixhouse/salesapp-shared/lib/models';
import {DataProvider} from '@salesapp/data-provider';
import {IProductListingCopy} from '@retrixhouse/salesapp-shared/lib/requests';
import {Observable, from} from 'rxjs';
import {IProcessingProgress} from '@retrixhouse/salesapp-shared/lib/responses';

@Injectable()
export class ProductListingStorageService extends OldBaseStorageService<
  IProductListing,
  IProductListing[]
> {
  constructor(private dataProvider: DataProvider) {
    super(dataProvider.productListing);
  }

  copyListing(data: IProductListingCopy) {
    return this.dataProvider.productListing.copyListing(data);
  }

  getCopyProgress(operationId: string): Observable<IProcessingProgress> {
    return from(this.dataProvider.productListing.getCopyProgress(operationId));
  }

  getCopyAvailability(): Observable<IProcessingProgress[]> {
    return from(this.dataProvider.productListing.getCopyAvailability());
  }
}
