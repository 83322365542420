<!-- TODO: remove unused attributes -->
<app-dialog [title]="(isUpdate ? 'entity.object-property.dialog.edit.title' : 'entity.object-property.dialog.create.title') | translate"
            [actionInProgress]="false"
            [actionLabel]="'form.action.save' | translate"
            [actionDisabled]="disableActions$ | async"
            [actionVisible]="true"
            [actionIcon]=""
            [cancelLabel]="'form.action.close' | translate"
            [cancelDisabled]="disableActions$ | async"
            [cancelVisible]="true"
            [cancelIcon]=""
            [formGroup]="form"
            (cancelClicked)="onClose()"
            (actionClicked)="onSave()">

  <app-form-row>
    <app-form-column>
      <app-input-text [formControl]="getFormControl('name')"
                      [formControlRef]="getFormControl('name')"
                      [label]="'entity.object-property.properties.name.label' | translate"></app-input-text>
    </app-form-column>
  </app-form-row>

  <app-form-row>
    <app-form-column>
      <app-input-text [formControl]="getFormControl('description')"
                      [formControlRef]="getFormControl('description')"
                      [label]="'entity.object-property.properties.description.label' | translate"></app-input-text>
    </app-form-column>
  </app-form-row>

  <app-form-row>
    <app-form-column>
      <app-input-select [formControl]="getFormControl('valueType')"
                        [formControlRef]="getFormControl('valueType')"
                        [options]="valueTypeOptions"
                        [label]="'entity.object-property.properties.value-type.label' | translate"></app-input-select>
    </app-form-column>
  </app-form-row>

  <app-form-row>
    <app-form-column>
      <app-input-select [formControl]="getFormControl('listId')"
                        [formControlRef]="getFormControl('listId')"
                        [options]="genericListOptions$ | async"
                        [label]="'entity.object-property.properties.list-id.label' | translate"></app-input-select>
    </app-form-column>
  </app-form-row>

</app-dialog>