import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {
  Feedback,
  KPISet,
  MobilePhoto,
  Photo,
  Position,
} from 'src/app/shared/models';
import cloneDeep from 'clone-deep';
import {v4 as uuid} from 'uuid';
import {
  DevExtremeModule,
  DxAccordionComponent,
  DxFormComponent,
  DxSliderComponent,
} from 'devextreme-angular';
import {
  IFeedbackKPISet,
  IProjectKpiSetThreshold,
} from '@retrixhouse/salesapp-shared/lib/models';
import {
  AuthGuardService,
  ConfirmationService,
  ScreenService,
} from '../../../services';
import {BehaviorSubject, Subscription, interval, of} from 'rxjs';
import {
  FeedbackKpiInputDisplayOptions,
  RoleNames,
} from '@retrixhouse/salesapp-shared/lib/common';
import {CommonModule} from '@angular/common';
import {ValueChangedEvent} from 'devextreme/ui/slider';
import {TSimpleChanges} from 'src/app/shared/utils/angular.utils';
import {sortByName} from '@retrixhouse/salesapp-shared/lib/utils';
import query from 'devextreme/data/query';
import {PhotoGalleryInputModule} from '../../photo-gallery-input/photo-gallery-input.component';
import {
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  switchMap,
} from 'rxjs/operators';
import {DataProvider} from 'src/app/shared/data.provider/data-provider';
import {SettingNames} from '@retrixhouse/salesapp-shared/lib/settings';

@Component({
  selector: 'app-feedback-edit',
  templateUrl: './feedback-edit.component.html',
  styleUrls: ['./feedback-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeedbackEditComponent implements OnInit, OnChanges, OnDestroy {
  @Input() tourPlanId: string;
  @Input('feedback') feedbackInput: Feedback;
  @Input() userKPISets: KPISet[];
  @Input() thresholdValues: IProjectKpiSetThreshold[] = [];
  @Input() userId: string;
  @Input() photos: Photo[];

  @Output() onSaved = new EventEmitter<Feedback>();
  @Output() onDeleted = new EventEmitter<string>();
  @Output() onCanceled = new EventEmitter<string>();
  @Output() onKpiSetPhotoAdded = new EventEmitter<
    MobilePhoto | MobilePhoto[]
  >();
  @Output() onKpiSetPhotoDeleted = new EventEmitter<string>();

  @ViewChild('feedbackForm') feedbackForm: DxFormComponent;
  @ViewChild('accordion') accordion: DxAccordionComponent;
  @ViewChildren('feedbackSlider') feedbackSliders: QueryList<DxSliderComponent>;

  _feedback$ = new BehaviorSubject<Feedback>(new Feedback());
  kpiKeyValue: {[prop: string]: number} = {};

  /**
   * Maps kpiSetId to kpiSetValue
   */
  kpiSetIdToValueMap = new Map<string, IFeedbackKPISet>();
  /**
   * Maps kpiSetId to {status, color, rating}
   */
  kpiSetStatusMap = new Map<
    string,
    {status?: string; color?: string; rating?: number}
  >();

  /**
   * Maps kpiSetValueId to photos
   */
  kpiSetValueIdToPhotosMap = new Map<string, Photo[]>();

  isSmallScreen: boolean;
  $screenChangeSubscription: Subscription;
  canDeleteFeedback: boolean;
  currentUserPosition: Position;

  photoUploadInPrgoress$ = new BehaviorSubject<boolean>(false);
  tourPlanId$ = new BehaviorSubject<string>(undefined);
  tourPlan$ = this.tourPlanId$.pipe(
    filter(v => !!v),
    distinctUntilChanged(),
    switchMap(tpId =>
      this.dataProvider.tourPlan.getSingle(tpId, {skipCache: true}),
    ),
    shareReplay(1),
  );

  /**
   * Photo settings for the current project
   */
  photoSettings$ = this.tourPlan$.pipe(
    filter(p => !!p),
    map(m => m.projectId),
    map(projectId => {
      return this.dataProvider.settingResolver.getValues(
        [
          SettingNames.PhotoGallery_MaxWidth,
          SettingNames.PhotoGallery_MaxHeight,
          SettingNames.PhotoGallery_AutoJpegCompression,
          SettingNames.PhotoGallery_MaxFileSize,
          SettingNames.PhotoGallery_MinHeight,
          SettingNames.PhotoGallery_MinWidth,
          SettingNames.PhotoGallery_RestrictGalleryToPosition,
        ],
        projectId,
      );
    }),
    map(settings => {
      const restrictSetting = (settings[
        SettingNames.PhotoGallery_RestrictGalleryToPosition
      ] ?? []) as string[];
      settings[SettingNames.PhotoGallery_RestrictGalleryToPosition] =
        restrictSetting.includes(this.currentUserPosition.id);
      return settings;
    }),
    shareReplay(1),
  );

  feedbackKpiInputDisplay$ = this.tourPlan$.pipe(
    filter(p => !!p),
    map(m => m.projectId),
    map(projectId => {
      return this.dataProvider.settingResolver.getValue(
        SettingNames.TourPlan_Feedback_KpiInputDisplay,
        projectId,
      ) as FeedbackKpiInputDisplayOptions;
    }),
    shareReplay(1),
  );

  sliderConfigs$ = this.feedbackKpiInputDisplay$.pipe(
    map(feedbackKpiInputDisplayOption => {
      if (
        feedbackKpiInputDisplayOption ===
        FeedbackKpiInputDisplayOptions.PositiveNegative
      ) {
        return {
          minimumValueLabel: this.translate.instant(
            'labels.negative-kpi-value',
          ),
          maximumValueLabel: this.translate.instant(
            'labels.positive-kpi-value',
          ),
          step: 50,
          // using interval will cause the onValueChanged event to be fired
          defaultValue: interval(0).pipe(map(m => 50)),
          displayCurrentValue: false,
        };
      } else {
        return {
          minimumValueLabel: '0%',
          maximumValueLabel: '100%',
          step: 10,
          defaultValue: of(0),
          displayCurrentValue: true,
        };
      }
    }),
    shareReplay(1),
  );

  get eachKpiSetHasValue(): boolean {
    return this.userKPISets.some(kpiSet => this.hasKpiSetValue(kpiSet));
  }

  constructor(
    private translate: TranslateService,
    private screenService: ScreenService,
    private authGuardService: AuthGuardService,
    private dataProvider: DataProvider,
    private confirmationService: ConfirmationService,
  ) {
    this.isSmallScreen = this.screenService.isSmallScreen();
    this.$screenChangeSubscription = this.screenService.changed.subscribe(
      () => {
        this.isSmallScreen = this.screenService.isSmallScreen();
      },
    );
  }

  ngOnChanges(changes: TSimpleChanges<this>): void {
    const tourPlanId = changes.tourPlanId?.currentValue;
    if (tourPlanId) {
      this.tourPlanId$.next(tourPlanId);
    }

    const userKPISets = changes.userKPISets?.currentValue;
    if (Array.isArray(userKPISets)) {
      this.userKPISets = sortByName(userKPISets);
      this.userKPISets.forEach(kpiSet => {
        kpiSet.items = query(kpiSet.items).sortBy('kpi.name').toArray();
      });
    }

    const feedback = changes['feedbackInput']?.currentValue;

    if (feedback && this.userKPISets?.length > 0) {
      this.kpiKeyValue = {};

      const _feedback: Feedback = cloneDeep(feedback);
      _feedback.kpiValues?.forEach(kpiValue => {
        this.kpiKeyValue[`${kpiValue.kpiSetId}.${kpiValue.kpiId}`] =
          kpiValue.value;
      });

      _feedback.kpiValueSets?.forEach(kpiValueSet => {
        this.kpiSetIdToValueMap.set(kpiValueSet.kpiSetId, kpiValueSet);
        const threshold = this.thresholdValues
          ?.filter(tv => tv.kpiSetId === kpiValueSet.kpiSetId)
          .sort((a, b) => b.minValue - a.minValue)
          .find(tv => kpiValueSet.rating >= tv.minValue);

        this.kpiSetStatusMap.set(kpiValueSet.kpiSetId, {
          rating: kpiValueSet.rating || 0,
          color: threshold?.color,
          status: threshold?.name,
        });
      });

      this._feedback$.next({
        ...cloneDeep(feedback),
      });

      // the following code fixes the problem of having the tooltips sticks over an X value even when the real value is different to X.
      if (this.feedbackSliders?.length > 0) {
        this.feedbackSliders.forEach(slider => {
          slider?.instance?.option('tooltip.enabled', false);
        });
        this.accordion.selectedIndex = -1;
      }
      setTimeout(() => {
        if (this.feedbackSliders?.length > 0) {
          this.feedbackSliders.forEach(slider => {
            slider?.instance?.option('tooltip.enabled', true);
          });
        }
      }, 400);
    }

    if (
      Array.isArray(this.photos) &&
      Array.isArray(this._feedback$?.value?.kpiValueSets)
    ) {
      const feedback = this._feedback$.value;
      const kpiValueSetIds = feedback.kpiValueSets?.map(kvs => kvs.id) ?? [];

      kpiValueSetIds.forEach(kpiSetValueId => {
        const kpiSetPhotos = this.photos.filter(
          p => p.objectId === kpiSetValueId,
        );
        this.kpiSetValueIdToPhotosMap.set(kpiSetValueId, kpiSetPhotos);
      });
    }
  }

  async ngOnInit(): Promise<void> {
    this.canDeleteFeedback = this.authGuardService.hasRoleAny(
      RoleNames.Admin,
      RoleNames.Feedback,
      RoleNames.FeedbackDelete,
    );

    this.currentUserPosition = await this.authGuardService.getUserPosition();
  }

  hasKpiSetValue(kpiSet: KPISet): boolean {
    const kpiSetValue = this._feedback$.value?.kpiValueSets?.find(
      f => f.kpiSetId === kpiSet.id,
    );
    const kpiSetItemValues =
      this._feedback$.value.kpiValues?.filter(f => f.kpiSetId === kpiSet.id) ??
      [];

    return (
      kpiSetItemValues?.some(s => s.value > 0) ||
      (kpiSetValue?.text && kpiSetValue.text.trim().length > 0)
    );
  }

  onKPIValueChanged(e: ValueChangedEvent, kpiValueId: string) {
    this.modifyKPIValue(kpiValueId, e.value);
  }

  setTo100(kpiValueId: string) {
    this.modifyKPIValue(kpiValueId, 100);
  }

  setToZero(kpiValueId: string) {
    this.modifyKPIValue(kpiValueId, 0);
  }

  modifyKPIValue(kpiValueId: string, value: number) {
    this.kpiKeyValue[kpiValueId] = value;
    const [kpiSetId, kpiId] = kpiValueId.split('.');

    let kpiValueList = this._feedback$.value.kpiValues;
    if (!kpiValueList) {
      kpiValueList = [];
    }

    const kpiValue = kpiValueList.find(
      f => f.kpiId === kpiId && f.kpiSetId === kpiSetId,
    );
    if (kpiValue) {
      kpiValue.value = value;
    } else {
      kpiValueList.push({
        id: uuid(),
        feedbackId: this._feedback$.value.id,
        kpiId,
        kpiSetId,
        value,
      });
    }

    // recalculate set rating
    // create set value list if it does not exist
    let kpiSetValueList = this._feedback$.value.kpiValueSets;
    if (!kpiSetValueList) {
      kpiSetValueList = [];
    }

    const kpiSetValue = kpiSetValueList.find(i => i.kpiSetId === kpiSetId);
    const kpiSet = this.userKPISets.find(i => i.id === kpiSetId);
    // calculate new rating
    const newRating = Math.round(
      kpiSet?.items
        .map(kpiItem => {
          const valueItem = kpiValueList.find(
            kvl => kvl.kpiId === kpiItem.kpi.id && kvl.kpiSetId === kpiSetId,
          );
          return valueItem?.value
            ? (valueItem.value * kpiItem.weight) / 100
            : 0;
        })
        .reduce((a, b) => a + b, 0) ?? 0,
    );
    // if set value already exists, update rating value, create new otherwise
    if (kpiSetValue) {
      kpiSetValue.rating = newRating;
      this.kpiSetIdToValueMap.set(kpiSetId, kpiSetValue);
    } else {
      const _kpiSetValue = {
        id: uuid(),
        feedbackId: this._feedback$.value.id,
        kpiSetId,
        rating: newRating,
        text: null,
      };
      kpiSetValueList.push(_kpiSetValue);
      this.kpiSetIdToValueMap.set(kpiSetId, _kpiSetValue);
    }

    const threshold = this.thresholdValues
      ?.filter(tv => tv.kpiSetId === kpiSetId)
      .sort((a, b) => b.minValue - a.minValue)
      .find(tv => newRating >= tv.minValue);

    this.kpiSetStatusMap.set(kpiSetId, {
      rating: newRating,
      color: threshold?.color,
      status: threshold?.name,
    });

    this._feedback$.next({
      ...this._feedback$.value,
      kpiValues: kpiValueList,
      kpiValueSets: kpiSetValueList,
    });
  }

  kpiSetTextChanged(e, kpiSetId: string): void {
    this.createKpiSetValueIfNotExists(kpiSetId);

    let kpiSetValueList = this._feedback$.value.kpiValueSets;
    const newText = e.value;
    const kpiSetValue = kpiSetValueList.find(i => i.kpiSetId === kpiSetId);
    // if set value exists, just set new text value, create new otherwise
    if (kpiSetValue) {
      kpiSetValue.text = newText;
    }

    this._feedback$.next({
      ...this._feedback$.value,
      kpiValueSets: kpiSetValueList,
    });
  }

  createKpiSetValueIfNotExists(kpiSetId: string) {
    // create set value list if it does not exist
    let kpiSetValueList = this._feedback$.value.kpiValueSets;
    if (!kpiSetValueList) {
      kpiSetValueList = [];
    }

    const kpiSetValue = kpiSetValueList.find(i => i.kpiSetId === kpiSetId);

    if (!kpiSetValue) {
      const newKpiValueSet = {
        id: uuid(),
        feedbackId: this._feedback$.value.id,
        kpiSetId,
        rating: 0,
        text: null,
      };

      kpiSetValueList.push(newKpiValueSet);
      this.kpiSetIdToValueMap.set(kpiSetId, newKpiValueSet);

      this._feedback$.next({
        ...this._feedback$.value,
        kpiValueSets: kpiSetValueList,
      });
    }
  }

  async handleFeedbackSaveClick(feedbackForm: DxFormComponent) {
    const validationResult = feedbackForm.instance.validate();
    if (validationResult.isValid) {
      const _feedback = this._feedback$.value;
      if (!_feedback.createdById) {
        _feedback.createdById = this.userId;
      }
      if (!_feedback.createdAt) {
        _feedback.createdAt = new Date();
      } else {
        _feedback.updatedAt = new Date();
      }

      _feedback.tourPlanId = this.tourPlanId;

      this._feedback$.next(_feedback);
      this.onSaved.emit(cloneDeep(_feedback));
    }
  }

  onPhotoUploadInProgress(inProgress: boolean, kpiSetId: string) {
    this.photoUploadInPrgoress$.next(inProgress);
  }

  async kpiSetPhotoDeleted(photoId: string, kpiSetId: string) {
    try {
      await this.dataProvider.photoObject.deletePhoto(photoId);
    } catch (e) {
      console.error(e);
    }

    this.onKpiSetPhotoDeleted.emit(photoId);
  }

  kpiSetPhotoAdded(e: MobilePhoto | MobilePhoto[], kpiSetId: string) {
    this.createKpiSetValueIfNotExists(kpiSetId);

    const newPhotos = [];
    if (Array.isArray(e)) {
      e.forEach(ph => {
        ph.photo.feedbackId = this._feedback$.value.id;
      });
      newPhotos.push(...e.map(m => m.photo));
    } else {
      e.photo.feedbackId = this._feedback$.value.id;
      newPhotos.push(e.photo);
    }

    const kpiSetValue = this.kpiSetIdToValueMap.get(kpiSetId);

    let kpiSetValuePhotos = this.kpiSetValueIdToPhotosMap.get(kpiSetValue.id);
    if (Array.isArray(kpiSetValuePhotos)) {
      kpiSetValuePhotos.push(...newPhotos);
    } else {
      kpiSetValuePhotos = newPhotos;
    }
    this.kpiSetValueIdToPhotosMap.set(kpiSetValue.id, kpiSetValuePhotos);
    this.onKpiSetPhotoAdded.emit(e);
  }

  async removeKpiSetPhotos(kpiSetValueId: string) {
    const photos = this.kpiSetValueIdToPhotosMap.get(kpiSetValueId);
    if (photos?.length > 0) {
      await this.dataProvider.photoObject.deletePhotos(photos.map(p => p.id));
    }
    this.kpiSetValueIdToPhotosMap.set(kpiSetValueId, []);
  }

  private resetValues(): void {
    this._feedback$.next(new Feedback());

    this.kpiSetStatusMap.clear();
    this.kpiSetValueIdToPhotosMap.clear();
    this.kpiSetIdToValueMap.clear();

    Object.keys(this.kpiKeyValue).forEach((key: string) => {
      this.kpiKeyValue[key] = 0;
    });

    // this.accordion.instance.repaint();
    this.feedbackForm.instance.resetValues();
    this.feedbackForm.instance.repaint();
  }

  handleCancelClick(): void {
    this.onCanceled.emit(this._feedback$.value.id);
  }

  async handelDeleteFeedbackClick(): Promise<void> {
    this.onDeleted.emit(this._feedback$.value.id);

    // photos are being deleted on backend also.
    // however, it is possible to delete the feedback before saving it.
    // so ensure that the photos are deleted.
    const allPhotos = [...(this.kpiSetValueIdToPhotosMap.values() ?? [])]
      .map(([...photos]) => photos)
      .flat();

    if (allPhotos.length) {
      await this.dataProvider.photoObject.deletePhotos(
        allPhotos.map(p => p.id),
      );
    }

    this.resetValues();
  }

  ngOnDestroy(): void {
    if (this.$screenChangeSubscription) {
      this.$screenChangeSubscription.unsubscribe();
    }
  }

  onFeedbackFieldChanged(e) {
    this._feedback$.next({
      ...this._feedback$.value,
      [e.dataField]: e.value,
    });
  }
}

@NgModule({
  imports: [
    DevExtremeModule,
    CommonModule,
    TranslateModule,
    PhotoGalleryInputModule,
  ],
  declarations: [FeedbackEditComponent],
  exports: [FeedbackEditComponent],
})
export class FeedbackEditModule {}
