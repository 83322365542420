import * as uuid from 'uuid';

export class Unit {
  id: string;
  name: string;
  abbreviation: string;
  system: string;
  category: string;

  constructor() {
    this.id = uuid.v4();
    this.name = null;
    this.abbreviation = null;
    this.system = null;
    this.category = null;
  }
}

export enum UnitCategory {
  Length = 'Length',
  Area = 'Area',
  Volume = 'Volume',
  Weight = 'Weight',
  Temperature = 'Temperature',
  Time = 'Time',
}

export enum UnitSystem {
  Imperial = 'Imperial',
  Metric = 'Metric',
}
