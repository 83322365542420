import {ProductListing} from './product-listing.model';

export class ProductListingStore {
  id: string;
  productListingId: string;
  productId: string;
  storeId: string;
}

export class ProjectProductListing {
  projectId: string;
  productListings: ProductListing[];
  storeListings: ProductListingStore[];
}
