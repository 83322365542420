import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseCrudHttpService} from './base.http-service';
import {KPISet, Project, ProjectSetting, UserKPISets} from '../../models';
import {VisitFrequencyCalculation} from '../../models/visit-frequency-calculation';
import {IProjectDataService} from '../../interfaces/data-service';
import {IProjectUser} from '@retrixhouse/salesapp-shared/lib/models';
import { IProjectCopyRequest } from '@retrixhouse/salesapp-shared/lib/requests';
import { IProcessingProgress } from '@retrixhouse/salesapp-shared/lib/responses';

@Injectable()
export class ProjectHttpService
  extends BaseCrudHttpService<Project>
  implements IProjectDataService
{
  constructor(http: HttpClient) {
    super(http, '/projects');
  }

  public getActiveList(): Promise<Project[]> {
    return this.GET<Project[]>('/projects', {params: {onlyActive: true}});
  }

  public getProjectSettings(projectTypeId: string): Promise<ProjectSetting[]> {
    const filter = {
      where: {
        or: [{typeId: projectTypeId}, {typeId: null}],
      },
    };
    return this.GET<ProjectSetting[]>(`/projects/settings`, {
      params: {filter: JSON.stringify(filter)},
    });
  }

  public getAllProjectSettings(): Promise<ProjectSetting[]> {
    return this.GET<ProjectSetting[]>(`/projects/settings`);
  }

  public getProjectsForCustomer(customerId: string): Promise<Project[]> {
    const filter = customerId ? {where: {customerId}} : {};
    return this.GET<Project[]>(`/projects`, {
      params: {filter: JSON.stringify(filter)},
    });
  }

  public getTourPlansForPeriodicLimitCalculation(
    projectStores: {projectId: string; storeId: string}[],
  ): Promise<VisitFrequencyCalculation[]> {
    return this.POST<VisitFrequencyCalculation[]>(
      '/projects/store-periodic-limits',
      projectStores,
    );
  }

  public calculateFrequenciesForUser(): Promise<VisitFrequencyCalculation[]> {
    return this.GET<VisitFrequencyCalculation[]>(
      '/projects/store-periodic-limits/for-user',
    );
  }

  public getAvailableProjectPermissions(): Promise<string[]> {
    return this.GET<string[]>('/projects/permissions');
  }

  public getUserProjectPermissions(): Promise<{[projectId: string]: string[]}> {
    return this.GET<{[projectId: string]: string[]}>(
      '/projects/permissions/current',
    );
  }

  public getProjectsWithRelations(relations?: {}): Promise<Project[]> {
    const _relations = {
      include: [
        {
          relation: 'settings',
          scope: {
            include: [{relation: 'projectSettingItem'}],
          },
        },
        {
          relation: 'customer',
          scope: {
            include: [
              {
                relation: 'address',
                scope: {
                  include: ['country'],
                },
              },
            ],
          },
        },
        {
          relation: 'responsibleUsers',
        },
        {
          relation: 'positionPermissions',
        },
        {
          relation: 'todoList',
          scope: {
            include: [
              {
                relation: 'todoActions',
                scope: {
                  include: [
                    {
                      relation: 'todoListItemProductList',
                    },
                    {
                      relation: 'todoListItemProductCategoryList',
                    },
                    {
                      relation: 'todoListItemFreeTextList',
                      scope: {
                        include: ['freeText'],
                      },
                    },
                    {
                      relation: 'todoAction',
                    },
                    {
                      relation: 'reasonList',
                      scope: {
                        include: ['items'],
                      },
                    },
                    {
                      relation: 'questionnaire',
                      scope: {
                        include: [
                          {
                            relation: 'questions',
                            scope: {
                              include: [
                                {
                                  relation: 'question',
                                },
                                {
                                  relation: 'optionList',
                                  scope: {
                                    include: ['items'],
                                  },
                                },
                                {
                                  relation: 'tags',
                                },
                                {
                                  relation: 'productCategories',
                                },
                                {
                                  relation: 'products',
                                },
                              ],
                            },
                          },
                          {
                            relation: 'theme',
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
          },
        },
        {
          relation: 'customer',
          scope: {
            include: [
              {
                relation: 'address',
                scope: {
                  include: ['country'],
                },
              },
            ],
          },
        },
      ],
    };
    return this.POST<Project[]>('/projects/filter', relations ?? _relations);
  }

  public getMyKPISets(projectId: string) {
    return this.GET<KPISet[]>(`/projects/${projectId}/kpi-sets`);
  }

  public getProjectUsersKPISets(projectId: string): Promise<UserKPISets[]> {
    return this.GET<UserKPISets[]>(`/projects/${projectId}/users-kpi-sets`);
  }

  public getProjectUser(projectId: string, userId: string): Promise<IProjectUser> {
    return this.GET<IProjectUser>(`/projects/${projectId}/responsible-users/${userId}`);
  }

  public updateProjectUser(
    projectId: string,
    userId: string,
    projectUser: Partial<IProjectUser>
  ): Promise<IProjectUser> {
    return this.PATCH<IProjectUser>(`/projects/${projectId}/responsible-users/${userId}`, projectUser);
  }

  public copyProject(
    request: IProjectCopyRequest,
  ): Promise<void> {
    return this.POST('/projects/copy', request);
  }

  public getCopyProgress(operationId: string) {
    return this.POST<IProcessingProgress>(`/projects/copy/progress`, {
      operationId,
    });
  }

  public getCopyAvailability() {
    return this.GET<undefined>(`/projects/copy/available`);
  }
}
