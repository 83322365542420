import {Injectable} from '@angular/core';
import {DataProvider} from '../data.provider/data-provider';
import {
  Customer,
  GenericList,
  Position,
  PositionWrapper,
  ProjectSetting,
  ProjectWrapper,
  QuestionnaireAnswer,
  QuestionnaireResult,
  QuestionnaireWrapper,
  ResponsibleUserWrapper,
  StoreWrapper,
  DateTimeWrapper,
  TodoActionResult,
  TodoActionWrapper,
  TodoList,
  TodoListWrapper,
  TourPlan,
  User,
  UsernameResponse,
  UserProfile,
  UserProfileWrapper,
  UserWrapper,
  VisitWrapper,
  PromoActionWrapper,
  PromoAction,
  ProductCategory,
  Product,
} from '../models';
import {
  ContextBase,
  ContextQuestionnaire,
  ContextTodoList,
  ContextVisitBase,
} from '../models/contexts/context.model';
import {Questionnaire} from '../models/questionnaire-model';
import {PositionTree} from '../utils/position-tree';
import {QuestionnaireItemTree} from '../utils/questionnaire-item-tree';
import {TodoListItemTree} from '../utils/todo-list-item-tree';
import * as moment from 'moment';
import {ExpressionEvalutaionService} from './expression-evaluation.service';
import {AuthGuardService} from './auth.service';

type ExtraData = {
  /**
   * The visit with relations.
   */
  visit: TourPlan;
  /**
   * Visit executors.
   */
  executors: UsernameResponse[];
  /**
   * The positions of the customer
   */
  customerPositionList: Position[];
  /**
   * Superior editing.
   */
  editingBySuperUser?: boolean;
  /**
   * Available only when the `superiorIsEditing` flag is set to `true`. In other words, when visit is being edited by a superior user.
   */
  originalExecutorId?: string;
  /**
   * Promotional action related to the questionnaire.
   */
  promoAction?: PromoAction;
  /**
   * Product categories.
   */
  productCategories: ProductCategory[];
  /**
   * Products.
   */
  products: Product[];
};

@Injectable()
export class ContextBuilderService {
  private todoListContext: ContextTodoList = undefined;
  private todoListItemWrapperMapper: Map<string, TodoActionWrapper> = new Map<
    string,
    TodoActionWrapper
  >();

  private _questionnaireResult: QuestionnaireResult = undefined;
  private _questionnaire: Questionnaire = undefined;
  private _visit: TourPlan = undefined;
  private _currentUserProfile: UserProfile = undefined;
  private _todoList: TodoList = undefined;
  private _cuurentUser: User = undefined;
  private _projectSettings: ProjectSetting[] = undefined;
  private _previousVisit: TourPlan = undefined;
  private _allPositions: Position[];
  private _extraData: ExtraData;

  constructor(
    private dataProvider: DataProvider,
    private expressionEvaluationService: ExpressionEvalutaionService,
    private authGuardService: AuthGuardService,
  ) {}

  evaluateExpression<T extends ContextBase>(
    expression: any,
    context: T,
  ): boolean {
    if (!expression || !context) {
      return false;
    }

    try {
      return this.expressionEvaluationService.evaluateExpression(
        expression,
        context,
      );
    } catch {
      return false;
    }
  }

  /**
   * Builds questionnaire context.
   * @param {string} visitId - the id of the visit in which the questionnaire is answered.
   * @param {string} todoListId - the id of the todo list in which the questionnaire exists.
   * @param {QuestionnaireResult} questionnaireResult - the result of the questionnaire.
   * @param {Questionnaire} questionnaire - the questionnaire.
   * @param {ExtraData} extraData - extra data used while building the context.
   * @param {string} matrixItemId - the id of matrix item to which the context is being built.
   * @returns { Promise<ContextQuestionnaire>} the created questionnaire context.
   */
  async buildQuestionnaireContext(
    visitId: string,
    todoListId: string,
    questionnaireResult: QuestionnaireResult,
    questionnaire: Questionnaire,
    extraData: ExtraData,
    matrixItemId?: string,
  ): Promise<ContextQuestionnaire> {
    if (!visitId || !todoListId || !questionnaire) {
      return undefined;
    }
    this._extraData = extraData;
    this._questionnaire = questionnaire;
    this._questionnaireResult = questionnaireResult;

    await this.loadData(visitId, todoListId);

    if (!this._questionnaire || !this._visit || !this._todoList) {
      return undefined;
    }

    const questionnaireContext = new ContextQuestionnaire();

    const questionnaireItemTree: QuestionnaireItemTree =
      new QuestionnaireItemTree(
        questionnaire.questions,
        questionnaire.questions?.map(qu => qu.question),
      );

    const questionsWrapperMapper = questionnaireItemTree.createQuestionWrapper(
      questionnaireResult?.questionnaireAnswers,
      matrixItemId,
    );

    questionnaireContext.questionnaire = new QuestionnaireWrapper(
      questionnaire,
    );

    questionnaireContext.questions = questionnaireItemTree.allItems?.map(
      rootItem => questionsWrapperMapper.get(rootItem.id),
    );

    const todoListContext = await this.buildTodoListContext(
      visitId,
      todoListId,
      extraData,
    );

    questionnaireContext.todoList = todoListContext.todoList;
    questionnaireContext.todoAction = todoListContext.todoAction;
    questionnaireContext.previousVisit = todoListContext.previousVisit;
    questionnaireContext.visit = todoListContext.visit;
    questionnaireContext.project = todoListContext.project;
    questionnaireContext.store = todoListContext.store;
    questionnaireContext.user = todoListContext.user;
    questionnaireContext.time = todoListContext.time;

    if (extraData.promoAction) {
      questionnaireContext.promoAction = new PromoActionWrapper(
        extraData.promoAction,
        extraData.productCategories,
        extraData.products,
      );
    }

    return questionnaireContext;
  }

  /**
   * Updates questionnaire context.
   * @param {string} questionnaireItemId - the id of the questionnaire to update.
   * @param {string} questionnaireAnswer - the answer of the questionnaire to update.
   * @param {ContextQuestionnaire} questionnaireContext - the questionnaire context to update.
   * @param {number} photoCount - the number of photos taken for the question.
   * @returns { ContextQuestionnaire} the updated questionnaire context.
   */
  updateQuestionnaireItemAnswer(
    questionnaireItemId: string,
    questionnaireAnswer: QuestionnaireAnswer,
    questionnaireContext: ContextQuestionnaire,
    photoCount: number = 0,
  ): ContextQuestionnaire {
    if (!questionnaireContext || !questionnaireItemId) {
      return undefined;
    }

    const currentQuestionWrapper = questionnaireContext?.questions?.find(
      f => f.id === questionnaireItemId,
    );

    if (!currentQuestionWrapper) {
      return undefined;
    }

    if (questionnaireAnswer) {
      currentQuestionWrapper.answer = questionnaireAnswer.answer;
      currentQuestionWrapper.openedAt = new DateTimeWrapper(
        questionnaireAnswer.openedAt,
      );
      currentQuestionWrapper.timeSpent = questionnaireAnswer.timeSpent;
      currentQuestionWrapper.photoCount = photoCount;
    }

    questionnaireContext.question = currentQuestionWrapper;

    return questionnaireContext;
  }

  /**
   * Sets the question property of a questionnaire context.
   * @param {string} questionnaireItemId The id of the question to use.
   * @param {ContextQuestionnaire} questionnaireContext The questionnaire context to modify.
   * @returns {ContextQuestionnaire} The modified questionnaire context.
   */
  setContextQuestion(
    questionnaireItemId: string,
    questionnaireContext: ContextQuestionnaire,
  ): ContextQuestionnaire {
    if (!questionnaireContext || !questionnaireItemId) {
      return undefined;
    }

    const currentQuestionWrapper = questionnaireContext?.questions?.find(
      f => f.id === questionnaireItemId,
    );

    if (!currentQuestionWrapper) {
      return questionnaireContext;
    }
    questionnaireContext.question = currentQuestionWrapper;

    return questionnaireContext;
  }

  /**
   * Builds todo list context.
   * @param {string} visitId - the id of the visit in which the todo list is carried.
   * @param {string} todoListId - the id of the todo list to build the context for.
   * @param {ExtraData} extraData - extra data used while building the context.
   * @returns { Promise<ContextTodoList>} the created todo list context.
   */
  async buildTodoListContext(
    visitId: string,
    todoListId: string,
    extraData: ExtraData,
  ): Promise<ContextTodoList> {
    if (!visitId || !todoListId) {
      return;
    }
    this._extraData = extraData;
    await this.loadData(visitId, todoListId);

    if (!this._visit || !this._todoList) {
      return;
    }

    const todoList = this._todoList;

    const todoListItemTree = new TodoListItemTree(
      todoList.todoActions,
      todoList.todoActions?.map(ta => ta.todoAction),
    );

    const reasonItemsList = this.getAllReasonLists();

    this.todoListItemWrapperMapper =
      todoListItemTree.createTodoActionWrapperMap(reasonItemsList);

    this.todoListContext = new ContextTodoList();

    this.todoListContext.todoList = new TodoListWrapper(todoList, [
      ...this.todoListItemWrapperMapper.values(),
    ]);
    this.todoListContext.todoAction = undefined;

    await this.buildVisitBaseContext(this.todoListContext);

    return this.todoListContext;
  }

  /**
   * Updates questionnaire context.
   * @param {string} currentTodoListItemId - the id of the todo list item to update.
   * @param {string} actionResult - the answer of the todo list to update.
   * @returns { ContextTodoList} the updated todo list context.
   */
  updateCurrentTodoActionResult(
    currentTodoListItemId: string,
    actionResult: TodoActionResult,
  ): ContextTodoList {
    if (!this.todoListContext || !currentTodoListItemId) {
      return undefined;
    }
    const currentTodoListitemWrapper = this.todoListItemWrapperMapper.get(
      currentTodoListItemId,
    );

    if (!currentTodoListitemWrapper) {
      return;
    }

    currentTodoListitemWrapper.completed = actionResult.completed;
    currentTodoListitemWrapper.openedAt = new DateTimeWrapper(
      actionResult.openedAt,
    );
    currentTodoListitemWrapper.timeSpent = actionResult.timeSpent;
    currentTodoListitemWrapper.notes = actionResult.notes;
    currentTodoListitemWrapper.reasonId = actionResult.reasonId;

    this.todoListItemWrapperMapper.set(
      currentTodoListItemId,
      currentTodoListitemWrapper,
    );

    this.todoListContext.todoAction = currentTodoListitemWrapper;

    return this.todoListContext;
  }

  /**
   * Fills visit base context properties of a context.
   * @param {string} context - the context to fill.
   */
  private async buildVisitBaseContext<T extends ContextVisitBase>(
    context: T,
  ): Promise<void> {
    const visit = this._visit;
    const previuosVisit = this._previousVisit;

    const projectWrapper = await this.getProjectWrapper(visit);

    const executorsPromises = visit?.executors?.map(projectUser => {
      return this.getUserWrapper(projectUser.userId);
    });
    const executors = await Promise.all([...(executorsPromises ?? [])]);

    const previousVisitExecutorsPromises = previuosVisit?.executors?.map(
      projectUser => {
        return this.getUserWrapper(projectUser.userId);
      },
    );

    const PreviousVisitExecutors = await Promise.all([
      ...(previousVisitExecutorsPromises ?? []),
    ]);

    context.project = projectWrapper;
    context.visit = new VisitWrapper(visit, executors);
    context.store = new StoreWrapper(visit?.store);
    context.previousVisit = this._previousVisit
      ? new VisitWrapper(this._previousVisit, PreviousVisitExecutors)
      : undefined;

    await this.fillContextBase(context);
  }

  /**
   * Fills the base context.
   * @param {ContextBase} context - the context to fill.
   */
  private async fillContextBase(context: ContextBase): Promise<void> {
    context.time = new DateTimeWrapper(new Date());
    context.user = await this.getUserWrapper();
  }

  /**
   * Returns user wrapper.
   * @param {string} userId - the id of the user to create the user wrapper for.
   * @param {string} projectPositionId - the position id of the user.
   * @returns {Promise<UserWrapper>} the created user wrapper.
   */
  private async getUserWrapper(userId?: string): Promise<UserWrapper> {
    let profile: UserProfile;
    let user: User;
    if (userId) {
      user = await this.getUserById(userId);
      profile = await this.getUserProfileByUserId(userId);
    } else {
      profile = this._currentUserProfile;
      user = this._cuurentUser;
    }

    const profileWrapper = new UserProfileWrapper(profile);

    const positionWrapper = await this.getPositionWrapper(user.positionId);

    return new UserWrapper(user, positionWrapper, profileWrapper);
  }

  /**
   * Returns position wrapper.
   * @param {string} positionId - the id of the position.
   * @param {string} customerId - the id of the customer the user belongs.
   * @returns {Promise<PositionWrapper>} the created position wrapper.
   */
  private async getPositionWrapper(
    positionId: string,
  ): Promise<PositionWrapper> {
    const customer = this.getCurrentVisitCustomer();
    const customerPositions = this.getPositionListOfCurrentCustomer();

    const positionTree = new PositionTree([
      ...this._allPositions,
      ...customerPositions,
    ]);

    const positionWrapperTree: Map<string, PositionWrapper> =
      positionTree.createPositionWrapper(customer);

    return positionWrapperTree.get(positionId);
  }

  private async getProjectWrapper(visit: TourPlan): Promise<ProjectWrapper> {
    const responsibleUsers = await Promise.all(
      visit?.project?.responsibleUsers
        ? visit?.project?.responsibleUsers?.map(
            async projectUser =>
              new ResponsibleUserWrapper(
                await this.getUserWrapper(projectUser.userId),
                new DateTimeWrapper(moment(projectUser.assignedAt).toDate()),
              ),
          )
        : [],
    );
    const projectSettings = this._projectSettings;

    return new ProjectWrapper(visit.project, projectSettings, responsibleUsers);
  }

  /**
   * Loads the required data to build the contexts.
   * @param {string} visitId - the id of the visit.
   * @param {string} todoListId - the id of the todo list.
   */
  private async loadData(visitId: string, todoListId: string): Promise<void> {
    const visit = this._extraData.visit;
    const todoList = visit?.project?.todoList;

    if (visit) {
      const generalPositionsPromise: Promise<Position[]> =
        this.dataProvider.position.getForCustomer(null);

      let positionsOfCustomer: Position[] =
        this.getPositionListOfCurrentCustomer() ?? [];
      let generalPositions: Position[] = [];
      this._projectSettings =
        visit?.project?.settings?.map(m => m.projectSettingItem) ?? [];

      [this._previousVisit, generalPositions] = await Promise.all([
        // Previous visit should be of type TodoListResult not TourPlan.
        Promise.resolve<TourPlan>(<any>{}),
        generalPositionsPromise,
      ]);

      this._allPositions = [
        ...(positionsOfCustomer ? positionsOfCustomer : []),
        ...(generalPositions ? generalPositions : []),
      ];
    }

    const user = await this.getUserById();
    this._cuurentUser = user;
    this._visit = visit;
    this._currentUserProfile = user.profile;
    this._todoList = todoList;
  }

  /**
   * Returns all available reason lists in the current visit.
   */
  private getAllReasonLists(): GenericList[] {
    return this._extraData?.visit?.project?.todoList?.todoActions
      ?.filter(todoListItem => todoListItem.reasonList !== undefined)
      .map(todoListItem => todoListItem.reasonList);
  }

  /**
   * If the userId is not provided, returns the User model of the current (original) user.
   * Otherwise, returns the User model for the provided id.
   * @param {string} userId - the id of the user.
   * @returns {User} - the user object if exist.
   */
  private async getUserById(userId?: string): Promise<User> {
    const usernameResponse = await this.getUsernameResponse(userId);
    const user: User = new User();
    if (usernameResponse) {
      user.id = usernameResponse.id;
      user.username = usernameResponse.username;
      user.positionId = usernameResponse.positionId;
      user.position = new Position();
      user.position.name = usernameResponse.positionName;
      user.position.abbreviation = usernameResponse.positionAbbreviation;
      user.profile = new UserProfile();
      user.profile.id = usernameResponse.profileId;
      user.profile.firstName = usernameResponse.firstName;
      user.profile.lastName = usernameResponse.lastName;
      user.profile.middleName = usernameResponse.middleName;
      user.profile.userId = userId;
      user.profile.picture = usernameResponse.profilePicture;
      user.profile.extendedProperties =
        usernameResponse.extendedPropertiesProfile;
      user.extendedProperties = usernameResponse.extendedPropertiesUser;
    }

    return user;
  }

  /**
   * Returns the current username response if the user id is not provided.
   * If the useId is provided, returns the username response of the provided user id.
   * @param {string} userId The id of the user.
   * @returns {UsernameResponse}
   */
  private async getUsernameResponse(
    userId?: string,
  ): Promise<UsernameResponse> {
    // If the userId is not provided, return the username object of the current user.
    if (!userId) {
      // If the visit is being edited by a superior user, use the id of the original executor.
      if (this._extraData.editingBySuperUser) {
        return this._extraData?.executors?.find(
          f => f.id === this._extraData.originalExecutorId,
        );
      }
      // If not, use the current user id.
      else {
        const up = await this.authGuardService.getUserProfile();
        return this._extraData?.executors?.find(f => f.id === up.userId);
      }
    } else {
      return this._extraData?.executors?.find(f => f.id === userId);
    }
  }

  /**
   * Returns a specific user profile.
   * @param {string} userId - the id of the user.
   * @returns {UserProfile} - the user profile object if exist.
   */
  private async getUserProfileByUserId(userId: string): Promise<UserProfile> {
    const usernameResponse = await this.getUsernameResponse(userId);
    const userProfile: UserProfile = new UserProfile();
    if (usernameResponse) {
      userProfile.id = usernameResponse.profileId;
      userProfile.firstName = usernameResponse.firstName;
      userProfile.lastName = usernameResponse.lastName;
      userProfile.middleName = usernameResponse.middleName;
    }

    return userProfile;
  }

  /**
   * Returns the customer related to the current project in which the visit is carried.
   * @returns {Customer} - customer object
   */
  private getCurrentVisitCustomer(): Customer {
    return this._extraData?.visit?.project?.customer;
  }

  /**
   * Returns the position list of the customer related to the current project in which the visit is carried.
   * @returns {Position[]} - position list.
   */
  private getPositionListOfCurrentCustomer(): Position[] {
    return this._extraData?.customerPositionList;
  }
}
