import {HttpErrorResponse} from '@angular/common/http';
import {ErrorHandler, Injectable} from '@angular/core';
import {NotificationService} from '../../shared/services/notification.service';
import {ErrorLoggerService} from './error-logger.service';

@Injectable({
  providedIn: 'root',
})
export class GlobalErrorHandler extends ErrorHandler {
  constructor(private errorLoggerService: ErrorLoggerService) {
    super();
  }

  handleError(error: any): void {
    if (error instanceof HttpErrorResponse) {
      NotificationService.notifyError(error.error);
    }

    if (error.isHttp) {
      NotificationService.notifyError(error);
    }

    this.errorLoggerService.logError(error);

    super.handleError(error);
  }
}
