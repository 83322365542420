import {AnswerType, ValueType} from '@retrixhouse/salesapp-shared/lib/models';
import {QuestionnaireItemTree} from '../utils/questionnaire-item-tree';
import {BaseModel} from './base.model';
import {QuestionnaireItem} from './questionnaire-model';

export class Question extends BaseModel {
  uid: string;
  version: number;
  text: string;
  answerType: AnswerType;

  constructor() {
    super();
    this.text = null;
    this.answerType = null;
  }
}

export function sortByText(items: Question[]): Question[] {
  return (items ?? []).sort((a, b) => a.text.localeCompare(b.text));
}

export function isBooleanQuestion(answerType: AnswerType | ValueType) {
  return (
    answerType === AnswerType.CheckBox ||
    answerType === AnswerType.YesNoDontKnow ||
    answerType === AnswerType.YesNo
  );
}

export function isRangeQuestion(answerType: AnswerType | ValueType) {
  return (
    answerType === AnswerType.DateRange ||
    answerType === AnswerType.DurationRange ||
    answerType === AnswerType.FloatRange ||
    answerType === AnswerType.IntegerRange ||
    answerType === AnswerType.TimeRange
  );
}

export function isNumericQuestion(answerType: AnswerType) {
  return (
    answerType === AnswerType.Integer ||
    answerType === AnswerType.IntegerRange ||
    answerType === AnswerType.Float ||
    answerType === AnswerType.FloatRange
  );
}

export function isChoiceQuestion(answerType: AnswerType): boolean {
  return (
    answerType === AnswerType.SingleChoice ||
    answerType === AnswerType.MultiChoice
  );
}

export function isPhotoQuestion(answerType: AnswerType | ValueType) {
  return (
    answerType === AnswerType.SinglePhoto ||
    answerType === AnswerType.MultiPhoto
  );
}

export function isMultiChoiceQuestion(answerType: AnswerType | ValueType) {
  return (
    answerType === AnswerType.MultiChoice ||
    answerType === AnswerType.MultiChoiceProduct
  );
}

export function isSingleChoiceQuestion(answerType: AnswerType) {
  return (
    answerType === AnswerType.SingleChoice ||
    answerType === AnswerType.SingleChoiceProduct
  );
}

export function isProductQuestion(answerType: AnswerType) {
  return (
    answerType === AnswerType.SingleChoiceProduct ||
    answerType === AnswerType.MultiChoiceProduct
  );
}

export const isParentQuestionMatrix = (
  qId: string,
  questionnaireItemTree: QuestionnaireItemTree,
) => {
  const question = questionnaireItemTree.findItemByQuestionId(qId);
  if (!question || !question.parentId) {
    return false;
  }
  const parentQuestion = questionnaireItemTree.findItem(question.parentId);
  if (!parentQuestion) {
    return false;
  }
  return parentQuestion.question.answerType === AnswerType.Matrix;
};
