import {Injectable} from '@angular/core';
import {ObjectAttachment, ObjectAttachmentApiService} from '@salesapp/api';
import {Create$Params} from '@salesapp/api/object-attachment/create';
import {Delete$Params} from '@salesapp/api/object-attachment/delete';
import {GetListForObject$Params} from '@salesapp/api/object-attachment/get-list-for-object';
import {Update$Params} from '@salesapp/api/object-attachment/update';
import {BaseStorage, EventBus, StorageDataStateEnum} from '@salesapp/storage';
import {AutoUnsubscribe} from '@salesapp/utils/angular.utils';
import {Subscription} from 'rxjs';
import {tap} from 'rxjs/operators';

export type ObjectAttachmentGetListParams = GetListForObject$Params;
export type ObjectAttachmentCreateParams = Create$Params;
export type ObjectAttachmentUpdateParams = Update$Params;

@Injectable()
@AutoUnsubscribe()
export class ObjectAttachmentStorage extends BaseStorage<
  ObjectAttachment,
  ObjectAttachment[],
  GetListForObject$Params,
  ObjectAttachmentCreateParams,
  ObjectAttachmentUpdateParams,
  Delete$Params
> {
  private eventListener: Subscription;

  constructor(
    protected apiService: ObjectAttachmentApiService,
    private eventBus: EventBus,
  ) {
    super(apiService);
    this.initEventListener();
  }

  getListMethod(params?: ObjectAttachmentGetListParams) {
    return this.apiService.getListForObject(this.filter);
  }

  deleteSuccessUpdateState(data, input: Delete$Params) {
    this.updateDataState(store => ({
      value: Array.isArray(store.value)
        ? store.value.filter(obj => obj.id !== input.attachmentId)
        : null,
      state: StorageDataStateEnum.Success,
    }));
  }

  private initEventListener() {
    this.eventListener = this.eventBus
      .listenTo(['attachment-template.attachment-generated'])
      .pipe(
        tap(event => {
          this.updateDataState(store => ({
            value: Array.isArray(event.data)
              ? [...store.value, ...event.data]
              : [...store.value, event.data],
            state: StorageDataStateEnum.Success,
          }));
        }),
      )
      .subscribe();
  }
}
