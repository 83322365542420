import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ActionsBuilderService} from '@salesapp/shared/services/actions-builder.service';
import {UserStorageStorageService} from '@salesapp/storage';
import {DxDataGridComponent} from 'devextreme-angular/ui/data-grid';
import {
  ExportingEvent,
  RowPreparedEvent,
  SelectionChangedEvent,
} from 'devextreme/ui/data_grid';
import {BehaviorSubject, Observable, combineLatest} from 'rxjs';
import {map, switchMap, take} from 'rxjs/operators';
import {
  BaseGridServiceImplementor,
  GridOptions,
  GridRowAction,
  GridToolbarButton,
} from '../grid.interfaces';

@Injectable()
export abstract class BaseGridService<T> {
  data$: Observable<T[]>;
  selectedData$ = new BehaviorSubject<T[]>([]);

  columns$: Observable<any[]>;
  gridRowActions$: Observable<GridRowAction[]>;
  toolbarButtons$: Observable<GridToolbarButton[]>;

  loading$: Observable<boolean>;

  get options() {
    return {
      ...DEFAULT_GRID_OPTIONS,
      ...this.implementor.gridOptions,
    };
  }

  constructor(
    protected implementor: BaseGridServiceImplementor<T>,
    private userStorageStorageService: UserStorageStorageService,
    protected actionsBuilderService: ActionsBuilderService,
    protected translateService: TranslateService,
  ) {
    this.init();
  }

  abstract exportData(exportingevent: ExportingEvent);

  openDetail(data: T) {
    return this.implementor.openDetail(data);
  }

  handleToolbarButtonClick(button: GridToolbarButton) {
    this.implementor.handleToolbarButtonClick(
      button,
      this.selectedData$.getValue(),
    );
  }

  handleGridRowActionClick(action: GridRowAction, data: T) {
    this.implementor.handleGridRowActionClick(action, data);
  }

  handleRowSelectionChanged(event: SelectionChangedEvent) {
    const selectedRowsData = this.implementor.handleRowSelectionChanged
      ? this.implementor.handleRowSelectionChanged(event)
      : event.selectedRowsData;
    this.selectedData$.next(selectedRowsData);
  }

  handleRowPrepared(event: RowPreparedEvent) {
    if (this.implementor.handleRowPrepared) {
      return this.implementor.handleRowPrepared(event);
    }
  }

  async loadState() {
    return this.userStorageStorageService
      .get(this.options.userStorageKey)
      .pipe(take(1))
      .toPromise()
      .then(response => {
        return response;
      })
      .catch(ignored => {});
  }

  saveState(state: unknown) {
    return this.userStorageStorageService
      .set({key: this.options.userStorageKey, data: state})
      .pipe(take(1))
      .toPromise();
  }

  async resetState(dataGrid: DxDataGridComponent) {
    return this.userStorageStorageService
      .deleteByKeys({keys: this.options.userStorageKey})
      .pipe(take(1))
      .toPromise()
      .then(() => {
        dataGrid.instance.state({});
        const newState = setFreshGridState(dataGrid.instance.state());
        dataGrid.instance.state(newState);
      });
  }

  private init() {
    this.data$ = this.implementor.data$;

    if (this.implementor.loading$) {
      this.loading$ = this.implementor.loading$;
    }

    if (this.implementor.gridRowActions$) {
      this.gridRowActions$ = this.implementor.gridRowActions$;
    }

    this.toolbarButtons$ = combineLatest([
      this.implementor.toolbarButtons$,
      this.selectedData$,
    ]).pipe(
      switchMap(([buttons, selectedData]) =>
        combineLatest([
          this.actionsBuilderService.init<GridToolbarButton>(buttons),
          this.data$,
        ]),
      ),
      map(([buttons, data]) =>
        buttons.map(button => {
          if (button.reflectSelection) {
            return {
              ...button,
              count:
                this.selectedData$.getValue().length === data.length
                  ? this.translateService.instant('grid.buttons.all-selected')
                  : this.selectedData$.getValue().length,
            } as GridToolbarButton;
          }
          return button;
        }),
      ),
    );
  }
}

export const DEFAULT_GRID_OPTIONS: Required<GridOptions> = {
  allowExport: true,
  allowExportSelectedData: true,
  allowColumnChooser: true,
  allowColumnReordering: true,
  allowColumnResizing: true,
  allowRestoreDefaultLayout: true,
  hoverStateEnabled: true,
  rowAlternationEnabled: true,
  showSelection: true,
  selectionMode: 'multiple',
  selectAllMode: 'allPages',
  toolbarVisible: true,
  toolbarLocation: 'top',
  userStorageKey: '',
};

export function setFreshGridState(state: any[]): any[] {
  state['columns'].map(column => {
    if (column.dataField === 'uid') {
      column.sortOrder = 'desc';
    }
  });
  return state;
}
