<dx-form
  [colCount]="3"
  #feedbackForm
  [formData]="_feedback$ | async"
  labelLocation="top"
  validationGroup="feedback-validation"
  (onFieldDataChanged)="onFeedbackFieldChanged($event)"
>
  <!--  Values start-->
  <dxi-item [colSpan]="3">
    <div *dxTemplate>
      <dx-accordion
        #accordion
        id="kpi-set-accordion"
        [dataSource]="userKPISets"
        [collapsible]="true"
        [multiple]="true"
        [animationDuration]="300"
        [hoverStateEnabled]="false"
        [selectedIndex]="userKPISets?.length > 1 ? -1 : 0"
      >
        <div *dxTemplate="let kpiSet of 'title'">
          <div class="kpi-set-header">
            {{ kpiSet.name }}
          </div>
          <div
            class="set-rating"
            *ngIf="kpiSetStatusMap.get(kpiSet.id); else noRatingTemplate"
          >
            <span *ngIf="kpiSetStatusMap.get(kpiSet.id).status">
              <span
                [ngStyle]="{color: kpiSetStatusMap.get(kpiSet.id).color}"
                class="fa fa-circle"
              ></span>
              {{ kpiSetStatusMap.get(kpiSet.id).status }} ({{ kpiSetStatusMap.get(kpiSet.id).rating }}%)
            </span>
            <span *ngIf="!kpiSetStatusMap.get(kpiSet.id).status && kpiSetStatusMap.get(kpiSet.id).rating !== undefined"
              >{{ 'labels.rating' | translate }} : {{ kpiSetStatusMap.get(kpiSet.id).rating }}</span
            >
            <span *ngIf="!kpiSetStatusMap.get(kpiSet.id).status && kpiSetStatusMap.get(kpiSet.id).rating === undefined"> {{ 'feedback.no-rating' | translate }} </span>
          </div>
        </div>
        <div
          *dxTemplate="let kpiSet of 'item'"
          class="kpi-set-item"
        >
          <ng-container *ngIf="sliderConfigs$ | async; let sliderConfigs">
            <ng-container *ngFor="let kpiSetItem of kpiSet?.items">
              <div class="item-container">
                <div class="kpi-toolbar">
                  <span class="kpi-name">{{ kpiSetItem.kpi.name }}</span>
                  <span
                    *ngIf="sliderConfigs.displayCurrentValue"
                    class="kpi-value"
                    >{{ kpiKeyValue[kpiSetItem?.setId + '.' + kpiSetItem.kpiId] ?? (sliderConfigs.defaultValue | async) }}%</span
                  >
                </div>
                <div class="kpi-slider">
                  <dx-button
                    stylingMode="text"
                    (onClick)="setToZero(kpiSetItem?.setId + '.' + kpiSetItem.kpiId)"
                    class="slider-btn"
                    >{{ sliderConfigs.minimumValueLabel }}</dx-button
                  >

                  <dx-slider
                    #feedbackSlider
                    style="flex: 1"
                    [min]="0"
                    [max]="100"
                    [step]="sliderConfigs.step"
                    [value]="kpiKeyValue[kpiSetItem?.setId + '.' + kpiSetItem.kpiId] ?? (sliderConfigs.defaultValue | async)"
                    (onValueChanged)="onKPIValueChanged($event, kpiSetItem?.setId + '.' + kpiSetItem.kpiId)"
                  >
                  </dx-slider>

                  <dx-button
                    stylingMode="text"
                    (onClick)="setTo100(kpiSetItem?.setId + '.' + kpiSetItem.kpiId)"
                    class="slider-btn"
                  >
                    {{ sliderConfigs.maximumValueLabel }}
                  </dx-button>
                </div>
              </div>
            </ng-container>
          </ng-container>
          <dx-text-area
            id="kpi-set-text"
            [label]="'labels.kpi-set-text' | translate"
            [value]="kpiSetIdToValueMap.get(kpiSet.id)?.text"
            (onValueChanged)="kpiSetTextChanged($event, kpiSet.id)"
          ></dx-text-area>

          <app-photo-gallery-input
            [readOnly]="!hasKpiSetValue(kpiSet)"
            [photos]="kpiSetValueIdToPhotosMap.get(kpiSetIdToValueMap.get(kpiSet.id)?.id) ?? []"
            [objectId]="kpiSetIdToValueMap.get(kpiSet.id)?.id"
            [projectId]="(tourPlan$ | async)?.projectId"
            [visitId]="(tourPlan$ | async)?.id"
            [userId]="userId"
            [enableTags]="false"
            [enableComments]="false"
            [enableDelete]="true"
            [photoSettings]="photoSettings$ | async"
            (onPhotoAdded)="kpiSetPhotoAdded($event, kpiSet.id)"
            (onPhotoDeleted)="kpiSetPhotoDeleted($event, kpiSet.id)"
            (photoUploadInProgress)="onPhotoUploadInProgress($event, kpiSet.id)"
          ></app-photo-gallery-input>
        </div>
      </dx-accordion>
    </div>
  </dxi-item>
  <!--  Values end-->

  <!--  Flags start-->
  <dxi-item
    dataField="commentRequired"
    dataType="boolean"
    editorType="dxSwitch"
    [label]="{text: 'labels.feedback.comment-required' | translate}"
  ></dxi-item>
  <dxi-item
    dataField="photoRequired"
    dataType="boolean"
    editorType="dxSwitch"
    [label]="{text: 'labels.feedback.photo-required' | translate}"
  ></dxi-item>
  <dxi-item
    [label]="{text: 'labels.feedback.resolved' | translate}"
    dataField="resolved"
    dataType="boolean"
    editorType="dxSwitch"
  ></dxi-item>
  <!--  Flags end-->

  <dxi-item [colSpan]="3">
    <div *dxTemplate>
      <dx-validation-summary validationGroup="feedback-validation"></dx-validation-summary>
    </div>
  </dxi-item>

  <!--  Buttons start-->
  <dxi-item [colSpan]="3">
    <div
      *dxTemplate
      class="feedback-footer"
    >
      <div class="operation-buttons">
        <div [ngClass]="{'operation-buttons-bg-screen': !isSmallScreen, 'operation-buttons-sml-screen': isSmallScreen}">
          <dx-button
            (onClick)="handleFeedbackSaveClick(feedbackForm)"
            [useSubmitBehavior]="false"
            icon="fa fa-save"
            [text]="'buttons.save' | translate"
            type="default"
            [disabled]="!eachKpiSetHasValue || (photoUploadInPrgoress$ | async)"
          ></dx-button>
          <dx-button
            [disabled]="(photoUploadInPrgoress$ | async) || !userKPISets?.length"
            *ngIf="canDeleteFeedback"
            (onClick)="handelDeleteFeedbackClick()"
            [useSubmitBehavior]="false"
            icon="fa fa-trash"
            [text]="'buttons.delete' | translate"
            type="danger"
          >
          </dx-button>
          <dx-button
            [disabled]="photoUploadInPrgoress$ | async"
            (onClick)="handleCancelClick()"
            [useSubmitBehavior]="false"
            icon="fa fa-cancel"
            [text]="'buttons.cancel' | translate"
            type="normal"
          ></dx-button>
        </div>
      </div>
    </div>
  </dxi-item>
  <!--  Buttons end-->
</dx-form>

<ng-template #noRatingTemplate>
  <div class="set-rating">
    <span> {{ 'feedback.no-rating' | translate }} </span>
  </div>
</ng-template>
