import {IFeedbackDataService} from '../../interfaces/data-service';
import {Feedback, FeedbackWithPhotosRequest} from '../../models';
import {FeedbackSearchRequest} from '../../models/requests/feedback-search.filter';
import {FeedbackHttpService} from '../http';
import {FeedbackOfflineService} from '../offline';
import {BaseCrudDataService} from './base.data-service';

export class FeedbackDataService extends BaseCrudDataService<
  Feedback,
  IFeedbackDataService
> {
  constructor(
    onlineService: FeedbackHttpService,
    offlineService: FeedbackOfflineService,
  ) {
    super(onlineService, offlineService);
  }

  public search(request: FeedbackSearchRequest): Promise<Feedback[]> {
    return this.service.search(request);
  }

  updateWithPhotos(payload: FeedbackWithPhotosRequest) {
    return this.service.updateWithPhotos(payload);
  }

  createWithPhotos(payload: FeedbackWithPhotosRequest) {
    return this.service.createWithPhotos(payload);
  }
}
