import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {
  IPhoto,
  IPhotoWithRelations,
  IProjectTag,
} from '@retrixhouse/salesapp-shared/lib/models';
import {
  DevExtremeModule,
  DxActionSheetComponent,
  DxGalleryComponent,
  DxPopupComponent,
} from 'devextreme-angular';
import {environment} from 'src/environments/environment';
import {
  Comment,
  Feedback,
  KPISet,
  PhotoHelpers,
  PhotoTag,
  Tag,
  UserProfile,
} from '../../models';
import {AuthGuardService, ScreenService} from '../../services';
import {CommentModule} from '../comment/comment.component';
import {FeedbackEditModule} from '../feedback/feedback-edit/feedback-edit.component';
import {ImgPopupModule} from '../img-popup/img-popup.component';
import {TagModule} from '../tag/tag.component';
import {v4 as uuid} from 'uuid';
import {BehaviorSubject} from 'rxjs';
import {PinchZoomModule} from '@meddv/ngx-pinch-zoom';
import {TSimpleChanges} from '../../utils/angular.utils';

@Component({
  selector: 'app-img-gallery',
  templateUrl: './img-gallery.component.html',
  styleUrls: ['./img-gallery.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
/**
 * A component used to manage the photos.
 */
export class ImgGalleryComponent implements OnInit, OnChanges {
  /**
   * All available tags.
   */
  @Input() tags: Tag[];
  /**
   * Photos to display in the gallery.
   */
  @Input() photos: IPhoto[];
  /**
   * The index of the photo to select in the first time.
   * Default: 0.
   */
  @Input() selectedPhotoIndex: number = 0;
  /**
   * The tour plan id to which the photos are related.
   */
  @Input() tourPlanId: string;
  /**
   * The available KPI sets of the current user.
   */
  @Input() myKPISets: KPISet[];
  /**
   * The feedback of the current user.
   */
  @Input() myFeedback: Feedback;
  /**
   * The available tag rules of the current project.
   */
  @Input() photoTagRules: IProjectTag[] = [];
  /**
   * The configuration variable of the allowed operations.
   * IMPORTANT: the default value for not set properties is true.
   */
  @Input() permissions: {feedback?: boolean; comment?: boolean; tag?: boolean} =
    {};
  /**
   * Whether to display the gallery or not.
   */
  @Input() visible: boolean = false;
  @Output() visibleChange = new EventEmitter<boolean>();

  @Output() onCommentAdded = new EventEmitter<{
    newComment: Comment;
    photoId: string;
  }>();
  @Output() onCommentsDeleted = new EventEmitter<{
    commentIds: string[];
    photoId: string;
  }>();
  @Output() onTagsChanged = new EventEmitter<{
    photoTagList: PhotoTag[];
    photoId: string;
  }>();
  @Output() onFeedbackSaved = new EventEmitter<Feedback>();
  @Output() onFeedbackDeleted = new EventEmitter<string>();

  @ViewChild('imgActionSheet') imgActionSheet: DxActionSheetComponent;
  @ViewChild('actionsPopup') actionsPopup: DxPopupComponent;
  @ViewChild('gallery') gallery: DxGalleryComponent;

  viewState$: BehaviorSubject<{
    popupContent?:
      | 'commentContent'
      | 'tagsContent'
      | 'feedbackContent'
      | 'sendEmailContent';
    popupHeader?: string;
    currentImg?: IPhotoWithRelations;
    currentPhotoTags?: string[];
    myKPISets?: KPISet[];
    myFeedback?: Feedback;
  }> = new BehaviorSubject({});

  isSmallScreen: boolean;
  imgActions$ = new BehaviorSubject([
    {
      action: 'CMNT',
      text: this.translate.instant('buttons.comments'),
      icon: 'fa-solid fa-comment-pen',
      type: 'default',
      stylingMode: 'contained',
      disabled: false,
    },
    {
      action: 'TAG',
      text: this.translate.instant('buttons.tags'),
      icon: 'fa-solid fa-tags',
      type: 'default',
      stylingMode: 'contained',
      disabled: false,
    },
    {
      action: 'FEEDBACK',
      text: this.translate.instant('buttons.feedbacks'),
      icon: 'fa-solid fa-message-smile',
      type: 'default',
      stylingMode: 'contained',
      disabled: false,
    },
  ]);
  photoObjectBaseUrl: string = environment.photoObjectBaseUrl;
  photoHelpers: PhotoHelpers;
  userProfile: UserProfile;

  constructor(
    private screenService: ScreenService,
    public translate: TranslateService,
    private authService: AuthGuardService,
  ) {
    this.isSmallScreen = this.screenService.isSmallScreen();

    this.hidePopup = this.hidePopup.bind(this);
  }

  async ngOnChanges(changes: TSimpleChanges<this>): Promise<void> {
    if (this.photos?.length > 0) {
      this.photos = this.photos.map(ph => {
        // when the photo was uploaded (NOT storage photos), and the uri doesn't containe `http` word, initlize the url.
        if (!ph.uri?.includes('storage') && !ph.uri?.includes('http')) {
          ph.uri = `${environment.photoObjectBaseUrl}${ph.uri}`;
          ph.uriThumbnail = `${environment.photoObjectBaseUrl}${ph.uriThumbnail}`;
        }
        return ph;
      });

      this.photoHelpers = new PhotoHelpers(this.photos);
      const viewState = this.viewState$.value;
      if (this.selectedPhotoIndex >= 0) {
        const currentImg = this.photoHelpers.getOneByIndex(
          this.selectedPhotoIndex,
        );
        this.viewState$.next({
          ...viewState,
          currentImg: currentImg,
          currentPhotoTags: currentImg?.photoTagList?.map(m => m.tagId) ?? [],
        });
      } else {
        this.selectedPhotoIndex = 0;
        const currentImg = this.photoHelpers.getOneByIndex(0);

        this.viewState$.next({
          ...viewState,
          currentImg: currentImg,
          currentPhotoTags: currentImg?.photoTagList?.map(m => m.tagId) ?? [],
        });
      }
    }

    const permissions = changes?.permissions?.currentValue;
    if (permissions) {
      if ('comment' in permissions == false) {
        this.permissions.comment = true;
      }

      if ('feedback' in permissions == false) {
        this.permissions.feedback = true;
      }

      if ('tag' in permissions == false) {
        this.permissions.tag = true;
      }

      this.imgActions$.next(
        this.imgActions$.value.map(ac => {
          ac.icon;
          switch (ac.action) {
            case 'CMNT':
              ac.disabled = !this.permissions.comment;
              break;

            case 'TAG':
              ac.disabled = !this.permissions.tag;
              break;

            case 'FEEDBACK':
              ac.disabled = !this.permissions.feedback;
              break;
          }

          return ac;
        }),
      );
    }
  }

  async ngOnInit(): Promise<void> {
    this.userProfile = await this.authService.getUserProfile();
  }

  async showImageRelationPopup(e) {
    if (!e.itemData.items) {
      const viewState = this.viewState$.value;
      switch (e.itemData.action) {
        case 'CMNT':
          {
            this.viewState$.next({
              ...viewState,
              popupContent: 'commentContent',
              popupHeader: this.translate.instant('labels.comments'),
            });

            this.actionsPopup.instance.show();
          }
          break;

        case 'TAG':
          {
            this.viewState$.next({
              ...viewState,
              popupContent: 'tagsContent',
              popupHeader: this.translate.instant('labels.tags'),
            });

            this.actionsPopup.instance.show();
          }
          break;
        case 'FEEDBACK':
          {
            this.viewState$.next({
              ...viewState,
              popupContent: 'feedbackContent',
              popupHeader: this.translate.instant('labels.visit-feedback'),
              myKPISets: this.myKPISets,
              myFeedback: this.myFeedback,
            });

            this.actionsPopup.instance.show();
          }
          break;
      }
    }
  }

  /**
   * Called when the user clicks on the three dots icon of the image.
   * On small screens, an action sheet is displayed.
   * On Large screens, a context menu is displayed.
   */
  showImgActionList() {
    if (this.isSmallScreen) {
      this.imgActionSheet.instance.show();
    } else {
      // here, the context menu will AUTOMATICALLY appear as it is configured to be displayed on clicking event of the icon
    }
  }

  hidePopup() {
    this.actionsPopup.instance.hide();
  }

  onGalleryEscapeBtn() {
    this.visible = false;
  }

  onPopupHidden(e) {
    this.visible = false;
    this.visibleChange.emit(false);
  }

  onFullScreenPopupShown(e) {
    this.gallery.instance.focus();
  }

  async commentAdded(newComment: Comment) {
    this.onCommentAdded.emit({
      newComment: {...newComment},
      photoId: this.viewState$.value.currentImg.id,
    });
  }

  async commentDeleted(deletedCommentIdList: string[]) {
    this.onCommentsDeleted.emit({
      commentIds: deletedCommentIdList,
      photoId: this.viewState$.value.currentImg.id,
    });
  }

  tagsChanged(tags: Tag[]) {
    this.onTagsChanged.emit({
      photoTagList:
        tags.map(
          t =>
            <PhotoTag>{
              id: uuid(),
              tagId: t.id,
              photoId: this.viewState$.value.currentImg.id,
              time: new Date(),
              userId: this.userProfile.userId,
            },
        ) ?? [],
      photoId: this.viewState$.value.currentImg.id,
    });
  }

  async feedbackSaved(feedback: Feedback) {
    this.onFeedbackSaved.emit(feedback);
    this.hidePopup();
  }

  async feedbackDeleted(feedbackId: string) {
    this.onFeedbackDeleted.emit(feedbackId);
    this.hidePopup();
  }

  feedbackCanceled(feedbackId: string) {
    this.hidePopup();
  }

  /**
   * Called when the user swipe right/left in the gallery.
   * It update the view state with current photo.
   * @param e
   */
  onCurrentImgChanged(e) {
    if (e.addedItems?.length > 0) {
      const selectedPhoto: IPhotoWithRelations = e.addedItems?.[0];

      this.viewState$.next({
        ...this.viewState$.value,
        currentImg: selectedPhoto,
        currentPhotoTags: selectedPhoto.photoTagList?.map(m => m.tagId) ?? [],
      });
    }
  }
}

@NgModule({
  declarations: [ImgGalleryComponent],
  imports: [
    CommonModule,
    DevExtremeModule,
    TranslateModule,
    PinchZoomModule,
    ImgPopupModule,
    CommentModule,
    TagModule,
    FeedbackEditModule,
  ],
  exports: [ImgGalleryComponent],
})
export class ImgGalleryModule {}
