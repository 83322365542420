import {Injectable} from '@angular/core';
import {MobilePhoto} from '../models';
import {
  DataProvider,
  MAX_DATA_TRANSFER_SIZE,
} from '../data.provider/data-provider';
import {AppInterfaceService} from '../app-interface/app-interface.service';
import {UploadPhotoResponse} from '@retrixhouse/salesapp-shared/lib/responses/upload-photo';

//import {LocalStorageService} from './local-storage.service';

@Injectable()
export class PhotoTransferService {
  constructor(
    private dataProvider: DataProvider,
    private appInterfaceService: AppInterfaceService,
  ) {}

  async uploadInChunks(
    mobilePhoto: MobilePhoto,
    visitId: string,
    uploadStatus?: (
      currentChunkNumber: number,
      numberOfChunks: number,
      currentChunkSize: number,
    ) => void,
  ): Promise<UploadPhotoResponse> {
    const keySizeObject = await this.appInterfaceService.photoStorageKeysSize(
      mobilePhoto.photo.id,
    );
    const photoSize = keySizeObject[mobilePhoto.photo.id];
    const numberOfChunks = Math.ceil(photoSize / MAX_DATA_TRANSFER_SIZE);

    let currentChunkNumber = 1;
    let offset = 0;
    let result: UploadPhotoResponse;
    while (currentChunkNumber <= numberOfChunks) {
      const chunk = await this.appInterfaceService.photoStorageLoad(
        mobilePhoto.photo.id,
        offset,
        MAX_DATA_TRANSFER_SIZE,
      );

      result = await this.dataProvider.visit.uploadPhotoChunk({
        photoId: mobilePhoto.photo.id,
        visitId,
        data: chunk[mobilePhoto.photo.id],
        numberOfChunks: numberOfChunks,
        currentChunk: currentChunkNumber,
      });

      if (uploadStatus) {
        uploadStatus(
          currentChunkNumber,
          numberOfChunks,
          MAX_DATA_TRANSFER_SIZE,
        );
      }

      currentChunkNumber++;
      offset += MAX_DATA_TRANSFER_SIZE;
    }

    return result;
  }
}
