<ng-container *ngIf="viewMode == 'lookup'">
  <ng-container *ngIf="selectionMode === 'single'">
    <ng-container *ngTemplateOutlet="singleSelection"></ng-container>
  </ng-container>
  <ng-container *ngIf="selectionMode === 'multiple'">
    <ng-container *ngTemplateOutlet="multipleSelection"></ng-container>
  </ng-container>
</ng-container>

<ng-container *ngIf="viewMode == 'tile'">
  <dx-toolbar [disabled]="readOnly">
    <dxi-item
      location="before"
      widget="dxButton"
      [options]="clearButtonOptions"
    >
    </dxi-item>
    <dxi-item
      location="after"
      locateInMenu="never"
    >
      <div *dxTemplate>
        <div>
          <b>{{ maxProducts ? selectedProductsCount + ' products selected of max ' + maxProducts : selectedProductsCount + ' products selected' }}</b>
        </div>
      </div>
    </dxi-item>
  </dx-toolbar>
  <dx-tile-view
    #tileView
    [dataSource]="dataSource"
    [baseItemHeight]="140"
    [baseItemWidth]="360"
    width="100%"
    [itemMargin]="10"
    direction="vertical"
    itemTemplate="product-item"
    (onItemClick)="onTileProductClick($event)"
    [disabled]="readOnly"
  >
    <div
      id="product-selector-template"
      *dxTemplate="let product of 'product-item'; let index = index"
    >
      <ng-container
        *ngTemplateOutlet="
          productLabel;
          context: {
            product: product
          }
        "
      ></ng-container>
    </div>
  </dx-tile-view>
</ng-container>

<ng-template #multipleSelection>
  <dx-tag-box
    #productSelectorTagbox
    [elementAttr]="elementAttr"
    [wrapItemText]="true"
    [dataSource]="selectionDatasource"
    [searchEnabled]="allowSearch"
    [searchExpr]="['name', 'uid', 'eanCode', 'category.name']"
    searchMode="contains"
    [searchTimeout]="1000"
    [showClearButton]="true"
    displayExpr="name"
    (onValueChanged)="onSelectedProductChanged($event)"
    itemTemplate="productSelectorTemplate"
    tagTemplate="productSelectorSelectedTemplate"
    [value]="selectedProductsIdList"
    [valueExpr]="'id'"
    [readOnly]="readOnly"
    [showSelectionControls]="true"
    [dropDownOptions]="dropDownOptions"
    (onFocusIn)="onEditorFocusIn($event)"
    (onFocusOut)="onEditorFocusOut($event)"
    (onOptionChanged)="onEditorOptionChanged($event)"
    [disabled]="disabled"
    [placeholder]="placeholder ?? 'placeholders.select-product' | translate"
    [selectAllMode]="'allPages'"
  >
    <dxi-button name="clear"></dxi-button>
    <dxi-button
      *ngIf="showBarCodeScannerButton"
      name="barcode-scanner"
      location="after"
      [options]="barcodeScannerButton"
    ></dxi-button>
    <div
      id="product-selector-template"
      *dxTemplate="let product of 'productSelectorTemplate'"
    >
      <ng-container
        *ngTemplateOutlet="
          productLabel;
          context: {
            product: product
          }
        "
      ></ng-container>
    </div>

    <div *dxTemplate="let product of 'productSelectorSelectedTemplate'">
      <div [ngClass]="{ 'dx-tag-content': true}" style="max-width: 300px">
        <app-product-avatar
          [product]="product"
          [projectId]="projectId"
        ></app-product-avatar>
        <div class="dx-tag-remove-button"></div>
      </div>
    </div>

    <dx-validator
      [validationGroup]="validation?.validationGroup"
      [name]="validation?.name"
      [elementAttr]="{id: elementAttr?.id + '-validator', editorName: 'dxTagBox'}"
    >
      <dxi-validation-rule
        *ngIf="validation?.isRequired"
        type="required"
      >
      </dxi-validation-rule>
      <ng-container *ngFor="let customValidationCB of validation?.customValidationCBs">
        <dxi-validation-rule
          type="custom"
          [validationCallback]="customValidationCB"
        ></dxi-validation-rule>
      </ng-container>
    </dx-validator>
  </dx-tag-box>
</ng-template>

<ng-template #singleSelection>
  <dx-select-box
    [elementAttr]="elementAttr"
    #productSelectorSelectbox
    [wrapItemText]="true"
    [dataSource]="selectionDatasource"
    [searchEnabled]="allowSearch"
    [searchExpr]="['name', 'uid', 'eanCode', 'category.name']"
    searchMode="contains"
    [searchTimeout]="1000"
    [showClearButton]="true"
    displayExpr="name"
    (onValueChanged)="onSelectedProductChanged($event)"
    itemTemplate="productSelectorTemplate"
    fieldTemplate="productSelectorSelectedTemplate"
    [value]="selectedProductsIdList?.length > 0 ? selectedProductsIdList[0] : []"
    [valueExpr]="'id'"
    [readOnly]="readOnly"
    [dropDownOptions]="dropDownOptions"
    (onFocusIn)="onEditorFocusIn($event)"
    (onFocusOut)="onEditorFocusOut($event)"
    (onOptionChanged)="onEditorOptionChanged($event)"
    [disabled]="disabled"
    [placeholder]="placeholder ?? 'placeholders.select-product' | translate"
  >
    <dxi-button
      name="today"
      location="after"
      [options]="barcodeScannerButton"
    >
    </dxi-button>

    <div
      id="product-selector-template"
      *dxTemplate="let product of 'productSelectorTemplate'"
    >
      <ng-container
        *ngTemplateOutlet="
          productLabel;
          context: {
            product: product
          }
        "
      ></ng-container>
    </div>

    <div *dxTemplate="let data of 'productSelectorSelectedTemplate'">
      <app-product-avatar
        [product]="data"
        [projectId]="projectId"
      >
      </app-product-avatar>
      <!-- the template should contain a text box. the following line is a hack to make it working. -->
      <!-- https://js.devexpress.com/Angular/Documentation/ApiReference/UI_Components/dxSelectBox/Configuration/#fieldTemplate -->
      <dx-text-box [visible]="false"></dx-text-box>
    </div>

    <dx-validator
      [validationGroup]="validation?.validationGroup"
      [name]="validation?.name"
      [elementAttr]="{id: elementAttr?.id + '-validator', editorName: 'dxSelectBox'}"
    >
      <dxi-validation-rule
        *ngIf="validation?.isRequired"
        type="required"
      >
      </dxi-validation-rule>
      <ng-container *ngFor="let customValidationCB of validation?.customValidationCBs">
        <dxi-validation-rule
          type="custom"
          [validationCallback]="customValidationCB"
        ></dxi-validation-rule>
      </ng-container>
    </dx-validator>
  </dx-select-box>
</ng-template>

<ng-template
  #productLabel
  let-product="product"
>
  <app-product-avatar
    [product]="product"
    [projectId]="projectId"
  >
  </app-product-avatar>
  <dx-check-box
    *ngIf="viewMode === 'tile'"
    class="selection-checkbox"
    [readOnly]="true"
    [value]="product.isSelected ? product.isSelected : false"
  ></dx-check-box>
</ng-template>

<dx-popup
  #barcodePopup
  [fullScreen]="true"
  [showTitle]="true"
  [title]="'product-selector.barcode-scanner' | translate"
  [dragEnabled]="false"
  [hideOnOutsideClick]="true"
  [showCloseButton]="true"
  [deferRendering]="true"
  (onHidden)="onBarcodePopupHidden($event)"
>
  <dxo-position
    at="center"
    my="center"
  >
  </dxo-position>
  <!-- TODO: get enabled bar code types from settings -->
  <div *dxTemplate="let data of 'content'">
    <barcode-scanner-livestream
      [config]="{
        decoder: {
          multiple: false,
          readers: ['code_128_reader', 'ean_reader', 'ean_8_reader', 'upc_reader', 'upc_e_reader']
        }
      }"
    ></barcode-scanner-livestream>
  </div>
</dx-popup>
