<div *ngIf="!hideHeader"
     class="header">
  <ng-content select="header"></ng-content>
</div>
<div class="content"
     [class.no-padding]="noPadding">
  <ng-content select="content"></ng-content>
</div>
<div *ngIf="displayFooter"
     class="footer">
  <ng-content select="footer"></ng-content>
</div>