import {Injectable} from '@angular/core';
import {OldBaseStorageService} from './old-base-storage-service';
import {DataProvider} from '../../data.provider/data-provider';
import {IProject} from '@retrixhouse/salesapp-shared/lib/models';
import { IProjectCopyRequest } from '@retrixhouse/salesapp-shared/lib/requests';
import { from, Observable } from 'rxjs';
import { IProcessingProgress } from '@retrixhouse/salesapp-shared/lib/responses';

@Injectable()
export class ProjectStorageService extends OldBaseStorageService<
  IProject,
  IProject[]
> {
  constructor(private dataProvider: DataProvider) {
    super(dataProvider.project);
  }

  entityToInputSelectOption(entity: IProject) {
    return {
      name: `${entity.name} - ${entity.uid} - ${entity.externalRef}`,
      value: entity.id,
      data: entity,
    };
  }

  copyProject(data: IProjectCopyRequest) {
    return from(this.dataProvider.project.copyProject(data));
  }

  getCopyProgress(operationId: string): Observable<IProcessingProgress> {
    return from(this.dataProvider.project.getCopyProgress(operationId));
  }

  getCopyAvailability(): Observable<IProcessingProgress[]> {
    return from(this.dataProvider.project.getCopyAvailability());
  }
}
