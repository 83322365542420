import {BehaviorSubject} from 'rxjs';
import {IStore} from '@retrixhouse/salesapp-shared/lib/models';
import {ObjectDetailTab} from '@salesapp/shared/modules';
import {RoleNames} from '@retrixhouse/salesapp-shared/lib/common';
import {TranslateService} from '@ngx-translate/core';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-store-detail',
  templateUrl: './store-detail.component.html',
  styleUrls: ['./store-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StoreDetailComponent implements OnInit {
  @Input() store: IStore;
  @Input() loading: boolean;

  @Output() cancel = new EventEmitter<void>();
  @Output() actionCompleted = new EventEmitter<void>();

  tabs: ObjectDetailTab<StoreDetailTabs>[];
  activeTabId$ = new BehaviorSubject<StoreDetailTabs>('detail');

  constructor(private translateService: TranslateService) {}

  ngOnInit() {
    this.initTabs();
  }

  onTabChange(tabId: string) {
    this.activeTabId$.next(tabId as StoreDetailTabs);
  }

  private initTabs() {
    this.tabs = [
      {
        id: 'detail',
        text: this.translateService.instant(
          'entity.store.detail.tabs.detail.label',
        ),
        enabledForRoles: [
          RoleNames.Stores,
          RoleNames.StoresEdit,
          RoleNames.StoresView,
        ],
      },
      {
        id: 'periodicLimits',
        text: this.translateService.instant(
          'entity.store.detail.tabs.periodic-limits.label',
        ),
        enabledForRoles: [
          RoleNames.StoresView,
          RoleNames.StoreFrequency,
          RoleNames.StoreFrequencyEdit,
          RoleNames.StoreFrequencyView,
        ],
      },
    ];
  }
}

type StoreDetailTabs = 'detail' | 'periodicLimits';
