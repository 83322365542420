import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseCrudHttpService} from './base.http-service';
import {TodoList} from '../../models';
import {ITodoListDataService} from '../../interfaces/data-service';

@Injectable()
export class TodoListHttpService
  extends BaseCrudHttpService<TodoList>
  implements ITodoListDataService
{
  constructor(http: HttpClient) {
    super(http, '/todo-lists');
  }
}
