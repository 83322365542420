import {
  Component,
  ElementRef,
  HostBinding,
  OnInit,
  ViewChild,
} from '@angular/core';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {SettingNames} from '@retrixhouse/salesapp-shared/lib/settings';
import {DxPopupComponent} from 'devextreme-angular';
import config from 'devextreme/core/config';
import * as moment from 'moment';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {tap} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import {AppInterfaceService} from './shared/app-interface/app-interface.service';
import {Event} from './shared/app-interface/event-model';
import {AppInfoResult, Response} from './shared/app-interface/response-model';
import {DataProvider} from './shared/data.provider/data-provider';
import {ClientMode} from './shared/enums/client-mode.enum';
import {
  ActionsService,
  AppInfoService,
  AuthGuardService,
  ClientModeService,
  ConfirmDialogButtonOptions,
  LoadPanelService,
  LocaleService,
  NavigationService,
  OPENED_VISIT_KEY,
  ScreenService,
  VISIT_EXECUTOR,
} from './shared/services';
import {
  ConfirmType,
  ConfirmationService,
} from './shared/services/confirmation.service';
import {PushNotificationService} from './shared/services/push-notification.service';
import {AutoUnsubscribe} from './shared/utils/angular.utils';
import {safeParseJSON} from './shared/utils/utils';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
@AutoUnsubscribe()
export class AppComponent implements OnInit {
  loadPanelConf: {visible: boolean; target?: string | Element} = {
    visible: false,
    target: undefined,
  };
  loading$ = new BehaviorSubject<boolean>(true);
  @ViewChild('popupRawData') popupRawData: DxPopupComponent;

  private langChangeSubscription: Subscription;
  rawData = new Observable<Object>();

  constructor(
    private dataProvider: DataProvider,
    private authGuardService: AuthGuardService,
    private screenService: ScreenService,
    public router: Router,
    private clientModeService: ClientModeService,
    public appInfoService: AppInfoService,
    private appInterfaceService: AppInterfaceService,
    public translate: TranslateService,
    private navigationService: NavigationService, // needs to be here to start collecting history
    private localeService: LocaleService,
    private loadPanelService: LoadPanelService,
    private confirmationService: ConfirmationService,
    private elementRef: ElementRef,
    private pushNotificationService: PushNotificationService,
    private actionsService: ActionsService,
  ) {
    this.dataProvider.i18N.getEnabledLocales().then(locales => {
      this.translate.addLangs(locales.map(locale => locale.tag));
    });

    if (appInfoService.isMobileVersion) {
      this.pushNotificationService.init();
    }

    loadPanelService.visible$.subscribe(payload => {
      this.loadPanelConf = payload;
    });

    if (appInfoService.isMobileVersion) {
      (window as any).salesapp = (window as any).salesapp || {};
      (window as any).salesapp.response = this.response.bind(this);
      (window as any).salesapp.event = this.event.bind(this);

      this.appInterfaceService.clientMessage('started').then(
        () => {
          console.log(
            'AppComponent.constructor - clientMessage:started succeeded',
          );
        },
        error => {
          console.log(
            'AppComponent.constructor - clientMessage:started failed',
            error,
          );
        },
      );

      let clientMode = ClientMode.ONLINE;

      // request info about which mode should client start
      appInterfaceService
        .storageLoad(ClientModeService.CLIENT_MODE_STORAGE_KEY)
        .then(res => {
          const clientModeRes = res[ClientModeService.CLIENT_MODE_STORAGE_KEY];
          if (clientModeRes === ClientMode.OFFLINE) {
            clientMode = ClientMode.OFFLINE;
          }

          if (clientMode === ClientMode.OFFLINE) {
            console.warn(
              'AppComponent.constructor - *** OFFLINE CURRENTLY MODE NOT SUPPORTED ***',
            );
            /*
            const userProfileKey = 'g:current-user-profile'; // another occurrence of key in LocalStorageService
            this.appInterfaceService
              .storageLoad(userProfileKey)
              .then(userProfileRes => {
                UserBasedOfflineService.userProfile = JSON.parse(
                  userProfileRes[userProfileKey],
                ) as UserProfile;
                this.clientModeService.clientMode = clientMode;
                this.fetchFirebaseIdAndAppInfo();
              });*/
          } else {
            this.clientModeService.clientMode = clientMode;
            this.appInfoService.downloadVersions();
            this.fetchFirebaseIdAndAppInfo();
          }
        });
    } else {
      this.appInfoService.downloadVersions();
    }

    this.detectTranslationChange();
  }

  async ngOnInit(): Promise<void> {
    try {
      this.loading$.next(true);

      const [currency, _] = await Promise.all([
        this.dataProvider.currency.getDefault(),
        // first time initialization of the setting value resolver
        this.dataProvider.initSettingValueResolver(),
        this.localeService.setLocale(),
      ]);

      config({
        defaultCurrency: currency?.isoCode ?? 'EUR',
      });

      if (this.appInfoService.isMobileVersion) {
        if (this.authGuardService.isAuthenticated()) {
          this.appInterfaceService.clientMessage('userActive').then(
            () => {
              console.log(
                'AppComponent.constructor - clientMessage:userActive succeeded',
              );
            },
            error => {
              console.log(
                'AppComponent.constructor - clientMessage:userActive failed',
                error,
              );
            },
          );
        }

        const visitId = (
          await this.appInterfaceService.storageLoad(OPENED_VISIT_KEY)
        )?.[OPENED_VISIT_KEY];

        // the executor object will contain the id and name of the ORIGINAL executor (in case where a superior is carrying out the visit).
        const executorStr = (
          await this.appInterfaceService.storageLoad(VISIT_EXECUTOR)
        )?.[VISIT_EXECUTOR];
        const executor = safeParseJSON(executorStr);

        if (visitId) {
          await this.router.navigate([`/data-collection/visit/${visitId}`], {
            state: {
              callBackUrl: '/',
              ...(executor
                ? {
                    executorId: executor.id,
                    executorName: executor.username,
                    executorUid: executor.uid,
                  }
                : {}),
            },
          });
          return;
        }

        if (this.appInfoService.isNewClientVersion) {
          const isUpdateOptional = this.dataProvider.settingResolver.getValue(
            SettingNames.Common_ClientUpdateOptional,
          );

          let confirmType = ConfirmType.DEFAULT;
          const buttonOptions: ConfirmDialogButtonOptions = {
            acceptText: this.translate.instant('update-client'),
            acceptIcon: 'fa-regular fa-cloud-arrow-down',
          };

          if (isUpdateOptional) {
            confirmType = ConfirmType.CONFIRM;
            buttonOptions.rejectText = this.translate.instant('buttons.close');
            buttonOptions.rejectIcon = 'close';
          }

          const confirmationResult = await this.confirmationService.confirm(
            confirmType,
            this.translate.instant('app.update-app-confirmation'),
            this.translate.instant('app.update-app-confirmation-message'),
            buttonOptions,
          );

          if (confirmationResult) {
            this.appInterfaceService.updateClient(
              `${environment.frontEndBaseUrl}client.zip`,
            );
          }
        }
        if (this.appInfoService.isMobileVersion) {
          const appPermissions = await this.appInterfaceService.permissions();
          const translatedPermissions = [];
          Object.entries(appPermissions).forEach(([key, value]) => {
            if (value !== 'granted') {
              const map = {
                pushNotifications: 'push-notifications',
              };

              translatedPermissions.push(
                this.translate.instant(`app.permissions.${map[key] ?? key}`),
              );
            }
          });

          if (translatedPermissions.length) {
            const confirmationResult = await this.confirmationService.confirm(
              ConfirmType.CONFIRM,
              this.translate.instant('app.missing-permissions-title'),
              `${this.translate.instant(
                'app.missing-permissions-message',
              )}: ${translatedPermissions.join(', ')}`,
              {
                acceptText: this.translate.instant('buttons.open'),
              },
            );

            if (confirmationResult) {
              this.appInterfaceService.openSettings();
            }
          }
        }
      }

      await this.checkUSerProfileUpdate();
    } finally {
      this.loading$.next(false);
    }
  }

  private async checkUSerProfileUpdate(): Promise<void> {
    const [profile, username] = await Promise.all([
      this.dataProvider.user.getMyProfile(),
      this.dataProvider.user.getMyUsernameResponse(),
    ]);
    const settings = this.dataProvider.settingResolver.getValues([
      SettingNames.UserProfile_UpdateDays,
      SettingNames.UserProfile_UpdateMaxDelay,
      SettingNames.UserProfile_UpdatePositions,
    ]);

    const updatePositions =
      settings[SettingNames.UserProfile_UpdatePositions] ?? [];
    const maxOutdatedDays = settings[SettingNames.UserProfile_UpdateDays];
    const delay = settings[SettingNames.UserProfile_UpdateMaxDelay] ?? 0;
    const updateRequiredAt = profile?.updateRequiredAt
      ? moment(profile.updateRequiredAt)
      : null;
    if (
      maxOutdatedDays &&
      (updatePositions.length === 0 ||
        updatePositions.includes(username.positionId))
    ) {
      // setting updateDays is set what means, that we have to check updateRequiredAt
      // setting updatePosition is empty, or current user position is included in setting
      const now = moment();
      if (updateRequiredAt && updateRequiredAt.isAfter(now)) {
        // updateRequiredAt is in the future, nothing to do
      } else if (
        updateRequiredAt &&
        updateRequiredAt.add(delay, 'day').isAfter(now)
      ) {
        // updateRequiredAt is in the past, but delay setting allows user to postpone profile update
        const confirmResult = await this.confirmationService.confirm(
          ConfirmType.CONFIRM,
          this.translate.instant('app.update-profile-confirmation'),
          this.translate.instant('app.update-profile-confirmation-message'),
          {
            acceptIcon: 'fa fa-user-circle',
            acceptText: this.translate.instant('buttons.user-profile'),
            rejectIcon: 'close',
            rejectText: this.translate.instant('buttons.postpone'),
          },
        );

        if (confirmResult) {
          this.actionsService.openUserDetail({
            userProfileId: profile.id,
            forceProfileUpdate: true,
          });
          return;
        }
      } else if (
        !updateRequiredAt ||
        updateRequiredAt.add(delay, 'day').isBefore(now)
      ) {
        // user does not have updateRequiredAt flag set, or
        // user postponed profile update more than delay allows
        await this.confirmationService.confirm(
          ConfirmType.DEFAULT,
          this.translate.instant('app.update-profile-confirmation'),
          this.translate.instant('app.update-profile-confirmation-message'),
          {
            acceptIcon: 'fa fa-user-circle',
            acceptText: this.translate.instant('buttons.user-profile'),
          },
        );
        this.actionsService.openUserDetail({
          userProfileId: profile.id,
          forceProfileUpdate: true,
        });
        return;
      }
    }
  }

  private fetchFirebaseIdAndAppInfo(): void {
    // subscribe to event when FCM token changes
    this.appInterfaceService.onFirebaseId().subscribe(firebaseId => {
      console.info('FCM token has changed.', firebaseId);
      // request application information to register mobile device
      this.appInterfaceService
        .getAppInfo()
        .then(appInfo => this.handleAppInfo(appInfo));
    });

    // request application information to register mobile device
    this.appInterfaceService
      .getAppInfo()
      .then(appInfo => this.handleAppInfo(appInfo));
  }

  private handleAppInfo(appInfo: AppInfoResult): void {
    if (
      appInfo.firebaseId &&
      appInfo.firebaseId.toLowerCase() !== 'unknown' &&
      appInfo.firebaseId.toLowerCase() !== 'null' &&
      this.clientModeService.clientMode === ClientMode.ONLINE
    ) {
      appInfo.clientVersion = this.appInfoService.clientVersion;
      this.dataProvider.mobileDevice.register(appInfo).then(
        res => {
          console.info('Mobile device registered successfully', res);
        },
        error => {
          console.error('Error registering mobile device', error);
        },
      );
    }
  }

  @HostBinding('class') get getClass() {
    return Object.keys(this.screenService.sizes)
      .filter(cl => this.screenService.sizes[cl])
      .join(' ');
  }

  public response(res: any): void {
    let response: Response;
    if (typeof res === 'string') {
      try {
        response = new Response(JSON.parse(res));
      } catch (error) {
        console.error('Unable to parse response', error, res);
      }
    } else {
      response = new Response(res);
    }

    if (!response.isValid()) {
      console.error('Response is not valid', response);
      return;
    }

    this.appInterfaceService.response(response);
  }

  public event(evt: any): void {
    let event: Event;
    if (typeof evt === 'string') {
      try {
        event = new Event(JSON.parse(evt));
      } catch (error) {
        console.error('Unable to parse event', error, evt);
      }
    } else {
      event = new Event(evt);
    }

    if (!event.isValid()) {
      console.error('Event is not valid', event);
      return;
    }

    this.appInterfaceService.event(event);
  }

  isAuthenticated() {
    return this.authGuardService.isAuthenticated();
  }

  private detectTranslationChange(): void {
    this.langChangeSubscription = this.translate.onLangChange
      .pipe(
        tap(() => {
          const lang = document.createAttribute('lang');
          lang.value = this.translate.currentLang.toLowerCase();
          this.elementRef.nativeElement.parentElement.parentElement.attributes.setNamedItem(
            lang,
          );
        }),
      )
      .subscribe();
  }
}
