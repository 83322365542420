import {GridColumn, GridNavigationalPropertySetting} from '../grid.interfaces';
import {Injectable} from '@angular/core';
import {IObjectType} from '@retrixhouse/salesapp-shared/lib/models';
import {map} from 'rxjs/operators';
import {ObjectStorageService} from '../../../services/storage/object-storage.service';
import {Observable} from 'rxjs';
import {propertiesToColumns} from '../grid.utils';
import {TranslateService} from '@ngx-translate/core';
import {
  ObjectTypeIds,
  ObjectTypeNames,
} from '@retrixhouse/salesapp-shared/lib/common';

@Injectable()
export class GridSetupService {
  constructor(
    private objectStorageService: ObjectStorageService,
    private translateService: TranslateService,
  ) {}

  getObjectColumns(input: {
    objectTypeId?: ObjectTypeIds;
    defaultVisibleProperties: string[];
    objectTypeName: ObjectTypeNames;
    dataFieldPath?: string;
    navigationalProperties?: GridNavigationalPropertySetting[];
  }): Observable<GridColumn[]> {
    const {
      objectTypeId,
      objectTypeName,
      defaultVisibleProperties,
      dataFieldPath,
      navigationalProperties,
    } = input;
    return this.objectStorageService.dataById$.pipe(
      map(objectTypeById => {
        let columns: GridColumn[] = [];
        if (objectTypeById.size) {
          const objectProperties = objectTypeById.get(objectTypeId).properties;
          const objectColumns = propertiesToColumns(objectProperties, {
            visibleProperties: defaultVisibleProperties,
            translateService: this.translateService,
            objectTypeName: objectTypeName,
            dataFieldPath,
          });

          if (navigationalProperties) {
            let navigationalColumns = [];
            navigationalProperties.forEach(navigationalPropertySetting => {
              const columns = this.getNavigationalPropertyColumns({
                objectTypeById,
                navigationalPropertySetting: navigationalPropertySetting,
                defaultVisibleProperties: [],
                parentNestedPath: dataFieldPath,
              });
              navigationalColumns = [...navigationalColumns, ...columns];
            });

            columns = [...columns, ...navigationalColumns];
          }

          return [...objectColumns, ...columns];
        }

        return [];
      }),
    );
  }

  private getNavigationalPropertyColumns(params: {
    objectTypeById: Map<string, IObjectType>;
    navigationalPropertySetting: GridNavigationalPropertySetting;
    parentNestedPath?: string;
    defaultVisibleProperties: string[];
  }): GridColumn[] {
    const {
      objectTypeById,
      navigationalPropertySetting,
      parentNestedPath,
      defaultVisibleProperties,
    } = params;

    const dataFieldPath = `${parentNestedPath ? `${parentNestedPath}.` : ''}${
      navigationalPropertySetting.dataPath
    }`;

    let objectProperties = objectTypeById.get(
      navigationalPropertySetting.objectTypeId,
    ).properties;

    if (navigationalPropertySetting.properties?.length) {
      objectProperties = objectProperties.filter(property =>
        navigationalPropertySetting.properties.includes(property.name),
      );
    } else if (navigationalPropertySetting.ignoredProperties?.length) {
      objectProperties = objectProperties.filter(
        property =>
          !navigationalPropertySetting.ignoredProperties.includes(
            property.name,
          ),
      );
    }

    const objectTypeName = this.getObjectNameById(
      navigationalPropertySetting.objectTypeId,
    );
    const objectColumns = propertiesToColumns(objectProperties, {
      visibleProperties: defaultVisibleProperties,
      translateService: this.translateService,
      objectTypeName,
      dataFieldPath,
    });

    return objectColumns;
  }

  private getObjectNameById(enumValue: number | string) {
    let keys = Object.keys(ObjectTypeIds).filter(
      key => ObjectTypeIds[key] == enumValue,
    );
    const key = keys.length > 0 ? keys[0] : '';

    if (key) {
      const name = ObjectTypeNames[key];
      return name || null;
    }
    return null;
  }
}
