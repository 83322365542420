import {AlertModule} from './shared/components/alert/alert.component';
import {APP_INITIALIZER, ErrorHandler, Injector, NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {AppInterfaceService} from './shared/app-interface/app-interface.service';
import {AppRoutingModule} from './app-routing.module';
import {BarcodeService} from './shared/services/bar-code.service';
import {BreadcrumbModule} from './shared/components/breadcrumb/breadcrumb.component';
import {BrowserModule} from '@angular/platform-browser';
import {ConfirmationService} from './shared/services/confirmation.service';
import {CustomPipesModule} from './shared/pipes';
import {DashboardLayoutService} from './shared/services/dashboard-layout.service';
import {DataProvider} from './shared/data.provider/data-provider';
import {DevExtremeModule} from 'devextreme-angular';
import {environment} from 'src/environments/environment';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {HTTP_SERVICES, I18NHttpService} from './shared/services/http';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {InputTextModule} from './shared/components/input-text';
import {LoadingContainerModule} from './shared/components/loading-container/index';
import {LOCATION_INITIALIZED} from '@angular/common';
import {LocationService} from './shared/services/location.service';
import {NgxObjectDiffModule} from '@everymundo/ngx-object-diff';
import {OFFLINE_SERVICES} from './shared/services/offline';
import {PhotoService} from './shared/services/photo.service';
import {PlatformModule} from '@angular/cdk/platform';
import {QuestionnaireResultContextBuilderService} from './modules/reports/promo-actions/questionnaire-edit/context-builder.service';
import {ReactiveFormsModule} from '@angular/forms';
import {ReportingServerService} from './shared/services/reporting-server.service';
import {SharedFormService} from './shared/services/shared-form-builder.service';
import {STORAGE_SERVICES} from './shared/services/storage';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {UnauthenticatedContentModule} from './unauthenticated-content';
import {UserStorageKeys} from './shared/globals';
import {
  KeycloakAngularModule,
  KeycloakEvent,
  KeycloakEventType,
  KeycloakService,
} from 'keycloak-angular';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import {
  SideNavInnerToolbarModule,
  SideNavOuterToolbarModule,
  SingleCardModule,
} from './layouts';
import {
  CommentModule,
  FooterModule,
  RequestProgressService,
} from './shared/components';
import {
  ActionsService,
  AppInfoService,
  AppScrollService,
  ClientModeService,
  ContextBuilderService,
  DevextremeService,
  ExpressionEvalutaionService,
  FileService,
  FullScreenService,
  LoadPanelService,
  LocaleService,
  NavigationService,
  ObjectDialogService,
  ObjectWrapperBuilderService,
  PermissionResolverService,
  PhotoTransferService,
  PushNotificationService,
  ScreenService,
  SubordinatesService,
  TourPlanColorService,
  VisitDataService,
} from './shared/services';
import {
  DataTransferNavigationGuard,
  UserProfileNavigationGuard,
} from './shared/guards';
import {DialogModule} from './shared/modules/dialog';
import {AppDialogsModule} from './shared/dialogs';
import {GlobalErrorHandler} from './core/error-handlers/global-error-handler.service';
import {ErrorLoggerService} from './core/error-handlers/error-logger.service';
import {ConfirmDialogComponent} from './shared/modules/dialog/components/confirm-dialog/confirm-dialog.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormDesignerModule} from './shared/modules';
import {
  ProductModule,
  StoreModule,
  UserManagementModule,
} from '@salesapp/domain';
import {ApiModule} from '@salesapp/api';
import {READ_SERVICES} from './shared/services/read';

function initializeKeycloak(
  keycloak: KeycloakService,
  dataProvider: DataProvider,
  clientModeService: ClientModeService,
) {
  keycloak.keycloakEvents$.subscribe((event: KeycloakEvent) => {
    if (event.type === KeycloakEventType.OnAuthSuccess) {
      dataProvider.userStorage
        .set(UserStorageKeys.LastLoginTimestamp, new Date().getTime())
        .then();
    }
  });
  return () => {
    return keycloak.init({
      config: {
        url: environment.keycloakUrl,
        realm: environment.keycloakRealm,
        clientId: environment.keycloakClientId,
      },
      initOptions: {
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri:
          window.location.origin + '/assets/silent-check-sso.html',
        checkLoginIframeInterval: 60,
        enableLogging: true,
      },
      loadUserProfileAtStartUp: false,
    });
  };
}

function initializeTranslations(
  i18nService: I18NHttpService,
  translate: TranslateService,
  injector: Injector,
  clientModeService: ClientModeService,
) {
  return () =>
    new Promise<any>((resolve: any) => {
      const locationInitialized = injector.get(
        LOCATION_INITIALIZED,
        Promise.resolve(null),
      );
      locationInitialized.then(() => {
        i18nService.getEnabledLocales().then(enabledLocales => {
          const promises: Promise<any>[] = [];
          enabledLocales.forEach(locale =>
            promises.push(translate.reloadLang(locale.tag).toPromise()),
          );
          Promise.all(promises).then(() => {
            translate.setDefaultLang(environment.defaultLocale);
            resolve(null);
          });
        });
      });
    });
}

export function httpLoaderFactory(
  httpClient: HttpClient,
  clientModeService: ClientModeService,
) {
  /*return new DummyLoader();*/
  return new TranslateHttpLoader(
    httpClient,
    environment.backEndBaseUrl + '/sys/i18n/translations/',
    '',
  );
}

export function initializeMapsScript(clientModeService: ClientModeService) {
  return () => {
    const s = document.createElement('script');
    s.src = `https://maps.googleapis.com/maps/api/js?key=${environment.googleMapApiKey}&libraries=places`;
    document.getElementsByTagName('head')[0].appendChild(s);
  };
}

const FORM_CONTROLS_MODULES = [InputTextModule];
const SALESAPP_MODULES = [DialogModule];

@NgModule({
  declarations: [AppComponent, ConfirmDialogComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    SideNavOuterToolbarModule,
    SideNavInnerToolbarModule,
    SingleCardModule,
    FooterModule,
    CommentModule,
    UnauthenticatedContentModule,
    AppRoutingModule,
    KeycloakAngularModule,
    HttpClientModule,
    ApiModule.forRoot({
      rootUrl: environment.backEndBaseUrl,
    }),
    DevExtremeModule,
    BreadcrumbModule,
    AlertModule,
    FontAwesomeModule,
    NgxObjectDiffModule,
    CustomPipesModule,
    PlatformModule,
    LoadingContainerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient, ClientModeService],
      },
    }),
    ...FORM_CONTROLS_MODULES,
    ...SALESAPP_MODULES,
    ReactiveFormsModule,
    AppDialogsModule,
    BrowserAnimationsModule,
    FormDesignerModule,
    ProductModule,
    StoreModule,
    UserManagementModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService, DataProvider, ClientModeService],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeTranslations,
      multi: true,
      deps: [I18NHttpService, TranslateService, Injector, ClientModeService],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeMapsScript,
      multi: true,
      deps: [ClientModeService],
    },
    /*    {
      provide: APP_BASE_HREF,
      useValue: '/app',
    },*/
    ...HTTP_SERVICES,
    ...OFFLINE_SERVICES,
    ...STORAGE_SERVICES,
    ...READ_SERVICES,
    ScreenService,
    AppScrollService,
    AppInfoService,
    ClientModeService,
    AppInterfaceService,
    ConfirmationService,
    DashboardLayoutService,
    LocationService,
    DataProvider,
    NavigationService,
    FullScreenService,
    ContextBuilderService,
    QuestionnaireResultContextBuilderService,
    AppInterfaceService,
    ExpressionEvalutaionService,
    DevextremeService,
    VisitDataService,
    DataTransferNavigationGuard,
    UserProfileNavigationGuard,
    LocaleService,
    LoadPanelService,
    PhotoTransferService,
    BarcodeService,
    TourPlanColorService,
    ActionsService,
    FileService,
    PermissionResolverService,
    SubordinatesService,
    PhotoService,
    SharedFormService,
    ReportingServerService,
    RequestProgressService,
    ErrorLoggerService,
    GlobalErrorHandler,
    PushNotificationService,
    {
      provide: ErrorHandler,
      useExisting: GlobalErrorHandler,
    },
    ObjectDialogService,
    ObjectWrapperBuilderService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
