import {NgModule} from '@angular/core';
import {DxDateBoxModule} from 'devextreme-angular';
import {FormFieldModule} from '../form-field';
import {CommonModule} from '@angular/common';
import {InputDateComponent} from './input-date.component';

const DX_MODULES = [DxDateBoxModule];

const UI_MODULES = [FormFieldModule];

@NgModule({
  imports: [CommonModule, ...DX_MODULES, ...UI_MODULES],
  declarations: [InputDateComponent],
  exports: [InputDateComponent],
})
export class InputDateModule {}

export * from './input-date.component';
