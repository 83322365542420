import {Injectable} from '@angular/core';
import {IGenericListItem} from '@retrixhouse/salesapp-shared/lib/models';
import {visibilityFilter} from '@retrixhouse/salesapp-shared/lib/utils/visibility-filter';
import {InputSelectOption} from '@salesapp/components';
import {from, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {DataProvider} from '../../data.provider/data-provider';
import {sortByPosition} from '../../globals';
import {arrayToMap} from '../../utils/utils';
import {GenericListItemDataService} from '../data';
import {OldBaseStorageService} from './old-base-storage-service';

// TODO(milan): remove
@Injectable()
export class GenericListItemStorageService extends OldBaseStorageService<
  IGenericListItem,
  IGenericListItem[]
> {
  constructor(dataProvider: DataProvider) {
    super(dataProvider.genericListItem);
  }

  dataById$: Observable<Map<string, IGenericListItem>> = this.data$.pipe(
    map(listItems => {
      return arrayToMap(listItems, 'id');
    }),
  );

  itemsByListId$: Observable<any> = this.data$.pipe(
    map(items => {
      const listItemsByListId = new Map<string, IGenericListItem[]>();
      items.forEach(item => {
        if (item.listId) {
          const existingList = listItemsByListId.has(item.listId)
            ? listItemsByListId.get(item.listId)
            : [];
          listItemsByListId.set(item.listId, [...existingList, item]);
        }
      });
      return listItemsByListId;
    }),
  );

  getData() {
    return from(
      (this.dataService as GenericListItemDataService).getListAll(),
    ) as Observable<IGenericListItem[]>;
  }

  getItemsForList(listId: string) {
    return this.data$.pipe(
      map(listItems => {
        if (listItems) {
          return sortByPosition(
            listItems.filter(listItem => listItem.listId === listId),
          );
        }

        return [];
      }),
    );
  }

  getItemsForListAsSelectOptions(
    listId: string,
  ): Observable<InputSelectOption[]> {
    return this.getItemsForList(listId).pipe(
      map(items =>
        items.map(item => {
          const visible = visibilityFilter(item);
          return {
            value: item.id,
            name: item.name,
            data: item,
            // disabled: !visible,
            inactive: !visible,
          };
        }),
      ),
    );
  }
}
