import {Injectable} from '@angular/core';
import {UserTask} from '../../models/user-task.model';
import {UserTaskSearchRequest} from '../../models/requests/user-task-search.request';
import {IUserTaskDataService} from '../../interfaces/data-service';
import {BaseCrudOfflineService} from './base.offline-service';

@Injectable()
export class UserTaskOfflineService
  extends BaseCrudOfflineService<UserTask>
  implements IUserTaskDataService
{
  public getActiveUserTasksForUser(): Promise<UserTask[]> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public getUserTasks(body: UserTaskSearchRequest): Promise<UserTask[]> {
    return Promise.reject('Offline service - not yet implemented');
  }
}
