import {ObjectMapCache} from '@retrixhouse/salesapp-shared/lib/caching';
import {ITodoListDataService} from '../../interfaces/data-service';
import {TodoList} from '../../models';
import {TodoListHttpService} from '../http';
import {TodoListOfflineService} from '../offline';
import {BaseCrudDataService} from './base.data-service';

export class TodoListDataService extends BaseCrudDataService<
  TodoList,
  ITodoListDataService,
  ObjectMapCache<string, TodoList>
> {
  constructor(
    onlineService: TodoListHttpService,
    offlineService: TodoListOfflineService,
    cache: ObjectMapCache<string, TodoList>,
  ) {
    super(onlineService, offlineService, cache);
  }
}
