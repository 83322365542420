import {Pipe, PipeTransform} from '@angular/core';
import {IUsernameResponse} from '@retrixhouse/salesapp-shared/lib/responses';
import {formatUser} from '../utils/utils';

@Pipe({name: 'formatUser'})
export class FormatUserPipe implements PipeTransform {
  transform(
    value: IUsernameResponse,
    includeUsername: boolean = false,
  ): string {
    return formatUser(value, includeUsername);
  }
}
