import * as uuid from 'uuid';

export interface Identifiable {
  id: string;
}

export interface Versionable {
  version: number;
}

export class BaseModel implements Identifiable {
  id: string;

  constructor() {
    this.id = uuid.v4();
  }
}

export class IdAndVersion implements Identifiable, Versionable {
  id: string;
  version: number;
}
