import {
  Directive,
  Input,
  OnChanges,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import {
  AppInterfaceService,
  AppPlatform,
} from '../app-interface/app-interface.service';
import {AuthGuardService, ClientModeService, ScreenService} from '../services';
import {ClientMode} from '@retrixhouse/salesapp-shared/lib/models';

@Directive({selector: '[visibleIf]'})
export class VisibleIfDirective implements OnChanges {
  @Input() visibleIf: VisibleIfOptions;
  @Input() visibleIfElse: TemplateRef<any>;

  constructor(
    private authGuardService: AuthGuardService,
    private appInterfaceService: AppInterfaceService,
    private screenService: ScreenService,
    private clientModeService: ClientModeService,
    private view: ViewContainerRef,
    private template: TemplateRef<any>,
  ) {}

  ngOnChanges(): void {
    if (
      this.resolvePlatform() &&
      this.resolveScreenSize() &&
      this.resolveClientMode() &&
      this.resolveRole() &&
      this.resolvePredicate()
    ) {
      this.view.createEmbeddedView(this.template);
    } else if (this.visibleIfElse !== undefined) {
      this.view.createEmbeddedView(this.visibleIfElse);
    } else {
      this.view.clear();
    }
  }

  private resolvePlatform(): boolean {
    if (!this.visibleIf.platform) {
      return true;
    }

    const platform = this.appInterfaceService.platform;
    const selectedPlatforms = [...this.visibleIf.platform];

    if (selectedPlatforms.includes('app')) {
      selectedPlatforms.push('ios', 'android');
    }

    return selectedPlatforms.includes(platformMap[platform]);
  }

  private resolveScreenSize() {
    if (!this.visibleIf.screenSize) {
      return true;
    }

    const screenSizes = this.screenService.sizes;
    const screenSize = Object.keys(screenSizes).find(key => !!screenSizes[key]);

    return this.visibleIf.screenSize.includes(screenSizesMap[screenSize]);
  }

  private resolveClientMode() {
    if (!this.visibleIf.clientMode) {
      return true;
    }

    const clientMode = this.clientModeService.clientMode;

    return this.visibleIf.clientMode.includes(clientModeMap[clientMode]);
  }

  private resolveRole(): boolean {
    // if not roles were provided, or user is admin, then show
    if (
      !this.visibleIf.role ||
      !this.visibleIf.role.length ||
      this.authGuardService.isAdmin()
    ) {
      return true;
    }

    return this.authGuardService.hasRoleAny(...this.visibleIf.role);
  }

  private resolvePredicate(): boolean {
    return this.visibleIf.predicate === undefined
      ? true
      : this.visibleIf.predicate;
  }
}

type Platform = 'app' | 'browser' | 'ios' | 'android';
type ScreenSize = 'x-small' | 'small' | 'medium' | 'large';
type ClientModeType = 'online' | 'offline';

export interface VisibleIfOptions {
  platform?: Platform[];
  screenSize?: ScreenSize[];
  clientMode?: ClientModeType;
  role?: string[];
  // since we can not use *ngIf with visibleIf directive predicate attr is what we would put inside ngIf
  predicate?: boolean;
  // projectContext: {
  //   projectId: string;
  //   projectSetting: Enum;
  //   projectPermissions: Enum[];
  // }
}

const platformMap: {[k in AppPlatform]: Platform} = {
  [AppPlatform.AndroidApp]: 'android',
  [AppPlatform.IosApp]: 'ios',
  [AppPlatform.WebBrowser]: 'browser',
};

const screenSizesMap = {
  'screen-x-small': 'x-small',
  'screen-small': 'small',
  'screen-medium': 'medium',
  'screen-large': 'large',
};

const clientModeMap: {[k in ClientMode]: ClientModeType} = {
  [ClientMode.Online]: 'offline',
  [ClientMode.Offline]: 'online',
};
