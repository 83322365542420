import {Directive, HostListener, Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Observable} from 'rxjs';
import {AppInfoService} from './app-info.service';

@Injectable()
export class NavigationAwayGuard {
  canDeactivate(
    component: ComponentCanDeactivate,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot,
  ) {
    return component.canDeactivate
      ? component.canDeactivate(nextState.url)
      : true;
  }
}

@Directive()
export abstract class ComponentCanDeactivate {
  abstract canDeactivate: (
    url: string,
  ) => Observable<boolean> | Promise<boolean> | boolean;

  constructor(public appInfoService: AppInfoService) {}

  @HostListener('window:beforeunload', ['$event'])
  beforeunload(event: Event) {
    if (!this.appInfoService.isMobileVersion) {
      event.returnValue = false;
    }
  }
}
