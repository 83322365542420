import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {DxSelectBoxModule, DxTextBoxModule} from 'devextreme-angular';
import {FormFieldModule} from '../form-field';
import {StoreAvatarModule} from '../store-avatar/store-avatar.component';
import {InputSelectComponent} from './input-select.component';

const DX_MODULES = [DxSelectBoxModule, DxTextBoxModule];

const UI_MODULES = [FormFieldModule, StoreAvatarModule];

@NgModule({
  imports: [CommonModule, TranslateModule, ...DX_MODULES, ...UI_MODULES],
  declarations: [InputSelectComponent],
  exports: [InputSelectComponent],
})
export class InputSelectModule {}

export * from './input-select.component';
