import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PropertyValueRendererComponent} from './property-value-renderer.component';
import {CustomPipesModule} from '@salesapp/pipes';
import {TranslateModule} from '@ngx-translate/core';
import {ProjectAvatarModule} from '../project-avatar';
import {DxPopoverModule} from 'devextreme-angular';
import {StoreAvatarModule} from '../store-avatar/store-avatar.component';
import {UserAvatarModule} from '../user-avatar/user-avatar.component';
const SALESAPP_MODULES = [
  ProjectAvatarModule,
  StoreAvatarModule,
  UserAvatarModule,
];

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    CustomPipesModule,
    DxPopoverModule,
    ...SALESAPP_MODULES,
  ],
  declarations: [PropertyValueRendererComponent],
  exports: [PropertyValueRendererComponent],
})
export class PropertyValueRendererModule {}
