import {BaseCrudDataService} from './base.data-service';
import {ClientSideErrorHttpService} from '../http/client-side-error.http-service';
import {ClientSideErrorOfflineService} from '../offline/client-side-error.offline-service';
import {IClientSideError} from '@retrixhouse/salesapp-shared/lib/models';
import {IClientSideErrorDataService} from '../../interfaces/data-service/client-side-error.iface';
import {ObjectMapCache} from '@retrixhouse/salesapp-shared/lib/caching';

export class ClientSideErrorDataService extends BaseCrudDataService<
  IClientSideError,
  IClientSideErrorDataService,
  ObjectMapCache<string, IClientSideError>
> {
  constructor(
    onlineService: ClientSideErrorHttpService,
    offlineService: ClientSideErrorOfflineService,
    cache: ObjectMapCache<string, IClientSideError>,
  ) {
    super(onlineService, offlineService, cache);
  }

  addErrors(errors: IClientSideError[]) {
    return this.service.uploadErrors(errors);
  }
}
