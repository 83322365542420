import {Injectable} from '@angular/core';
import {BaseOfflineService} from './base.offline-service';
import {
  GetVisitPhotosRequest,
  MobilePhotoChunk,
  OpenVisitRequest,
  OpenVisitResponse,
  PersonalArrangement,
  Photo,
  TodoListResult,
  TourPlan,
  VisitDataReportResponse,
  VisitDataResponse,
  VisitResultRequest,
  VisitResultResponse,
  VisitsPivotReportRequest,
  VisitsReportRequest,
} from '../../models';
import {
  IVisitPhotosResponse,
  IVisitPivotReportResponse, IVisitDataReportMatrixColumnCount,
} from '@retrixhouse/salesapp-shared/lib/responses';
import {IVisitDataService} from '../../interfaces/data-service';
import {HttpResponse} from '@angular/common/http';
import {IQuestionnaireResultChangeRequest} from '@retrixhouse/salesapp-shared/lib/requests';
import {IQuestionnaireResultWithRelations} from '@retrixhouse/salesapp-shared/lib/models';
import {UploadPhotoResponse} from '@retrixhouse/salesapp-shared/lib/responses/upload-photo';

@Injectable()
export class VisitOfflineService
  extends BaseOfflineService
  implements IVisitDataService
{
  public getVisitPhotos(visitId: string): Promise<Photo[]> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public uploadPhotoChunk(
    chunk: MobilePhotoChunk,
  ): Promise<UploadPhotoResponse> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public getVisitData(
    visitId: string,
    executorId?: string,
  ): Promise<VisitDataResponse> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public getVisitDataBulk(visitIds: string[]): Promise<VisitDataResponse[]> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public getVisitDataForUser(
    visitId: string,
    userId: string,
  ): Promise<VisitDataResponse> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public getVisitResult(tourPlanId: string): Promise<VisitDataResponse[]> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public getVisitResultTemplate(
    projectIds: string[],
  ): Promise<{projectId: string; template: Partial<VisitDataResponse>}[]> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public getPreviousVisitResultsForUser(): Promise<
    {projectId: string; storeId: string; result: TodoListResult}[]
  > {
    return Promise.reject('Offline service - not yet implemented');
  }

  public saveVisitData(
    visitDataRequest: VisitResultRequest,
  ): Promise<VisitResultResponse> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public editVisitData(
    visitResultId: string,
    visitDataRequest: VisitResultRequest,
  ): Promise<TodoListResult> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public deleteVisitData(
    visitResultId: string,
    version: number,
  ): Promise<void> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public openVisit(
    startVisitRequest: OpenVisitRequest,
  ): Promise<OpenVisitResponse> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public cancelVisit(tourPlanId: string): Promise<TourPlan> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public getPersonalArrangementsForUser(): Promise<
    {storeId: string; personalArrangement: PersonalArrangement}[]
  > {
    return Promise.reject('Offline service - not yet implemented');
  }

  public getVisitDataReport(
    request: VisitsReportRequest,
  ): Promise<VisitDataReportResponse> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public getVisitPivotReport(
    request: VisitsPivotReportRequest,
  ): Promise<IVisitPivotReportResponse> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public getVisitDataReportAsXlsx(
    request: VisitsReportRequest,
  ): Promise<HttpResponse<Blob>> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public getVisitsWithPhotos(
    request: GetVisitPhotosRequest,
  ): Promise<IVisitPhotosResponse[]> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public editQuestionnaireResult(
    todoListResultId: string,
    request: IQuestionnaireResultChangeRequest,
  ): Promise<IQuestionnaireResultWithRelations> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public getMatrixColumnCount(
    request: VisitsReportRequest,
  ): Promise<IVisitDataReportMatrixColumnCount> {
    return Promise.reject('Offline service - not yet implemented');
  }
}
