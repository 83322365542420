import {ObjectMapCache} from '@retrixhouse/salesapp-shared/lib/caching';
import {IScopeDataService} from '../../interfaces/data-service';
import {Scope} from '../../models';
import {ScopeHttpService} from '../http';
import {ScopeOfflineService} from '../offline';
import {BaseCrudDataService} from './base.data-service';

export class ScopeDataService extends BaseCrudDataService<
  Scope,
  IScopeDataService,
  ObjectMapCache<string, Scope>
> {
  constructor(
    onlineService: ScopeHttpService,
    offlineService: ScopeOfflineService,
    cache: ObjectMapCache<string, Scope>,
  ) {
    super(onlineService, offlineService, cache);
  }

  public async getScopeNames(): Promise<Scope[]> {
    return this.service.getScopeNames();
  }

  public async existsScopeName(
    name: string,
    excludeId?: string,
  ): Promise<boolean> {
    return this.service.existsScopeName(name, excludeId);
  }
}
