import {Injectable} from '@angular/core';
import {Position, UserPositionInProject} from 'src/app/shared/models';
import {IPositionDataService} from '../../interfaces/data-service';
import {BaseCrudOfflineService} from './base.offline-service';

@Injectable()
export class PositionOfflineService
  extends BaseCrudOfflineService<Position>
  implements IPositionDataService
{
  public getForCustomer(customerId?: string): Promise<Position[]> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public getForUser(): Promise<Position> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public resetPositionStructure(
    customerId: string | null,
  ): Promise<Position[]> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public getUserPositionInProjectContext(
    userProjectPairs: {userId: string; projectId: string}[],
  ): Promise<UserPositionInProject[]> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public getRoles(id: string): Promise<string[]> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public setRoles(id: string, roles: string[]): Promise<void> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public setPositionMenuItems(
    id: string,
    itemIds: string[],
  ): Promise<Position> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public setPositionRoutes(id: string, routeIds: string[]): Promise<Position> {
    return Promise.reject('Offline service - not yet implemented');
  }
}
