import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {
  ObjectTypeIds,
  RoleNames,
} from '@retrixhouse/salesapp-shared/lib/common';
import {ObjectAttachment} from '@salesapp/api';
import {PermissionResolverService} from '@salesapp/services';
import {
  CurrentUserStorageService,
  UsernameStorageService,
} from '@salesapp/storage';
import {SelectionChangedEvent} from 'devextreme/ui/data_grid';
import {Observable, of} from 'rxjs';
import {
  GridColumn,
  GridNavigationalPropertySetting,
  GridOptions,
  GridRowAction,
  GridServiceImplementor,
  GridToolbarButton,
} from '../../../modules/grid';
import {Action} from '../../actions/actions';
import {ObjectAttachmentActions} from '../object-attachment.actions';
import {ObjectAttachmentStorage} from '../object-attachment.storage';
import {ObjectAttachmentsService} from './object-attachments.service';

@Injectable()
export class ObjectAttachmentsGridService
  implements GridServiceImplementor<any>
{
  objectId: string;
  objectTypeId: ObjectTypeIds;

  gridOptions: GridOptions = {
    allowColumnChooser: false,
    allowRestoreDefaultLayout: false,
    allowExport: false,
  };
  defaultVisibleProperties: string[] = [];
  ignoredProperties: string[];
  navigationalProperties?: GridNavigationalPropertySetting[] = [
    {
      objectTypeId: ObjectTypeIds.ProductCategory,
      foreignKey: 'userId',
      dataPath: 'user',
      data$: this.usernameStorageService.dataById$,
    },
  ];
  data$: Observable<any[]>;
  toolbarButtons$: Observable<GridToolbarButton[]>;
  gridRowActions$?: Observable<GridRowAction[]>;

  constructor(
    private objectAttachmentStorage: ObjectAttachmentStorage,
    private objectAttachmentService: ObjectAttachmentsService,
    private translateService: TranslateService,
    private usernameStorageService: UsernameStorageService,
    private currentUserStorageService: CurrentUserStorageService,
    private permissionResolverService: PermissionResolverService,
  ) {
    this.initGrid();
  }

  setObjectId(objectId: string) {
    this.objectId = objectId;
    this.objectAttachmentStorage.setFilter({
      objectId: this.objectId,
    });
  }

  setObjectTypeId(objectTypeId: ObjectTypeIds) {
    this.objectTypeId = objectTypeId;
  }

  getColumns?(): Observable<GridColumn[]> {
    return of([
      {
        caption: this.translateService.instant(
          'entity.object-attachment.properties.notes.label',
        ),
        dataField: 'notes',
        width: 400,
        minWidth: 80,
        columnHidingEnabled: false,
        propertyId: '',
        dataType: 'string',
        cssClass: 'ellipsis',
        cellTemplate: 'valueOrEmptyCellTemplate',
      },
      {
        caption: this.translateService.instant(
          'entity.object-attachment.properties.user.label',
        ),
        dataField: 'user',
        width: 300,
        minWidth: 80,
        columnHidingEnabled: false,
        propertyId: '',
        dataType: 'string',
        cssClass: 'ellipsis',
        cellTemplate: 'userAvatarCellTemplate',
      },
      {
        caption: this.translateService.instant(
          'entity.object-attachment.properties.attached-at.label',
        ),
        dataField: 'attachedAt',
        width: 200,
        minWidth: 80,
        columnHidingEnabled: false,
        propertyId: '',
        dataType: 'string',
        cssClass: 'ellipsis',
        cellTemplate: 'localizedDateTimeCellTemplate',
      },
      {
        caption: this.translateService.instant(
          'entity.object-attachment.properties.file-name.label',
        ),
        dataField: 'fileName',
        width: 300,
        minWidth: 80,
        columnHidingEnabled: false,
        propertyId: '',
        dataType: 'string',
        cssClass: 'ellipsis',
      },
      {
        caption: this.translateService.instant(
          'entity.object-attachment.properties.file-size.label',
        ),
        dataField: 'fileSize',
        width: 100,
        minWidth: 80,
        columnHidingEnabled: false,
        propertyId: '',
        dataType: 'string',
        cssClass: 'ellipsis',
        cellTemplate: 'fileSizeCellTemplate',
      },
      {
        caption: this.translateService.instant(
          'entity.object-attachment.properties.content-type.label',
        ),
        dataField: 'contentType',
        width: 120,
        minWidth: 80,
        columnHidingEnabled: false,
        propertyId: '',
        dataType: 'string',
        cssClass: 'ellipsis',
        cellTemplate: 'fileTypeCellTemplate',
      },
    ]);
  }

  openDetail(data: ObjectAttachment) {
    const currentUser = this.currentUserStorageService.dataState.value;
    const updatePositionIds = data.permissions.updatePositionIds;
    const updateUserIds = data.permissions.updateUserIds;
    if (
      this.permissionResolverService.hasRoleAny([RoleNames.Admin]) ||
      (updatePositionIds.length &&
        updatePositionIds.includes(currentUser.positionId)) ||
      (updateUserIds.length && updateUserIds.includes(currentUser.id))
    ) {
      this.objectAttachmentService.editAttachment({
        objectId: this.objectId,
        attachment: data,
      });
    }
  }

  handleToolbarButtonClick(button: GridToolbarButton, selection: any[]) {
    this.handleAction(button, selection);
  }

  handleGridRowActionClick?(action: GridRowAction, data: any) {
    this.handleAction(action, data);
  }

  handleRowSelectionChanged?(e: SelectionChangedEvent): any[] {
    throw new Error('Method not implemented.');
  }

  private initGrid() {
    this.data$ = this.objectAttachmentStorage.data$;
    this.setToolbarButtons();
    this.setGridRowActions();
  }

  private setToolbarButtons() {
    this.toolbarButtons$ = of([
      ObjectAttachmentActions.ATTACHMENT_CREATE.withCustomProps({
        location: 'before',
        buttonType: 'default',
        buttonStyle: 'contained',
      }) as GridToolbarButton,
      ObjectAttachmentActions.ATTACHMENT_GENERATE.withCustomProps({
        location: 'before',
      }) as GridToolbarButton,
    ]);
  }

  private setGridRowActions() {
    this.gridRowActions$ = of([
      ObjectAttachmentActions.ATTACHMENT_EDIT,
      ObjectAttachmentActions.ATTACHMENT_DOWNLOAD,
      ObjectAttachmentActions.ATTACHMENT_DELETE,
    ]);
  }

  private handleAction(
    action: Action,
    data: ObjectAttachment | ObjectAttachment[],
  ) {
    switch (action.id) {
      case ObjectAttachmentActions.ATTACHMENT_CREATE.id:
        this.objectAttachmentService.createAttachment({
          objectId: this.objectId,
        });
        break;
      case ObjectAttachmentActions.ATTACHMENT_EDIT.id:
        this.objectAttachmentService.editAttachment({
          objectId: this.objectId,
          attachment: data as ObjectAttachment,
        });
        break;
      case ObjectAttachmentActions.ATTACHMENT_DELETE.id:
        this.objectAttachmentService.deleteAttachment(data as ObjectAttachment);
        break;
      case ObjectAttachmentActions.ATTACHMENT_DOWNLOAD.id:
        this.objectAttachmentService.downloadAttachment(
          data as ObjectAttachment,
        );
        break;
      case ObjectAttachmentActions.ATTACHMENT_GENERATE.id:
        this.objectAttachmentService.generateAttachment({
          objectId: this.objectId,
          objectTypeId: this.objectTypeId,
        });
        break;
      default:
        alert(`There is no handler implemented for action id: ${action.id}`);
        break;
    }
  }
}
