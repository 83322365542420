import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {
  BaseHttpService,
} from './base.http-service';
import {
  IProjectAggregatedDataResponse
} from '@retrixhouse/salesapp-shared/lib/responses';
import {
  IReportProjectDataService
} from '../../interfaces/data-service';
import * as moment from 'moment';

const ROOT = '/report-project-aggregated-data';

@Injectable()
export class ReportProjectHttpService
  extends BaseHttpService implements IReportProjectDataService {
  constructor(http: HttpClient) {
    super(http);
  }

  getProjectTileData(
    projectId: string,
    from: Date,
    to: Date,
  ): Promise<IProjectAggregatedDataResponse> {
    return this.GET<IProjectAggregatedDataResponse>(`${ROOT}/${projectId}`, {
      params: {
        from: moment(from).format('YYYY-MM-DD'),
        to: moment(to).format('YYYY-MM-DD'),
      }
    });
  }
}
