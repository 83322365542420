import {Injectable, Optional} from '@angular/core';
import {
  ObjectTypeIds,
  ObjectTypeNames,
} from '@retrixhouse/salesapp-shared/lib/common';
import {ValueType} from '@retrixhouse/salesapp-shared/lib/models';
import {DxDataGridComponent} from 'devextreme-angular';
import {
  ExportingEvent,
  RowPreparedEvent,
  SelectionChangedEvent,
} from 'devextreme/ui/data_grid';
import {BehaviorSubject, Observable, combineLatest} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {DataProvider} from '../../../data.provider/data-provider';
import {exportToXlsx, setFreshGridState} from '../../../globals';
import {ActionsBuilderService} from '../../../services/actions-builder.service';
import {GenericListItemStorageService} from '../../../services/storage/generic-list-item-storage.service';
import {TourPlanColorService} from '../../../services/tour-plan-color.service';
import {
  GridColumn,
  GridNavigationalPropertySetting,
  GridOptions,
  GridRowAction,
  GridServiceImplementor,
  GridToolbarButton,
} from '../grid.interfaces';
import {GridSetupService} from './grid-setup.service';

@Injectable()
export class GridService<T> {
  data$: Observable<T[]>;
  columns$: Observable<any[]>;
  gridRowActions$: Observable<GridRowAction[]>;
  toolbarButtons$: Observable<GridToolbarButton[]>;
  loading$: Observable<boolean>;

  selectedData$ = new BehaviorSubject<T[]>([]);

  defaultVisibleProperties: string[];
  objectTypeId?: ObjectTypeIds;
  objectTypeName?: ObjectTypeNames;
  // NOTE: K is name of the property in parent Object e.g. homeAddress in userProfile
  navigationalProperties: GridNavigationalPropertySetting[];
  ignoredProperties: string[];

  get gridOptions() {
    return {
      ...DEFAULT_GRID_OPTIONS,
      ...this.implementor.gridOptions,
    };
  }

  get genericListItemsById$() {
    return this.genericListItemStorageService.dataById$;
  }

  get helpers() {
    return {
      getTourPlanColorByState: this.tourPlanColorService?.getColourByState,
    };
  }

  constructor(
    private implementor: GridServiceImplementor<T>,
    private dataProvider: DataProvider,
    private actionsBuilderService: ActionsBuilderService,
    private gridSetupService: GridSetupService,
    private genericListItemStorageService: GenericListItemStorageService,
    @Optional() private tourPlanColorService: TourPlanColorService,
  ) {
    this.initGrid();
  }

  openDetail(data: T) {
    return this.implementor.openDetail(data);
  }

  loadState() {
    return this.dataProvider.userStorage
      .get(this.gridOptions.userStorageKey)
      .then((response: [] | null) => {
        return response;
      })
      .catch(ignored => {});
  }

  saveState(state) {
    return this.dataProvider.userStorage
      .set(this.gridOptions.userStorageKey, state)
      .then();
  }

  resetState(dataGrid: DxDataGridComponent) {
    return this.dataProvider.userStorage
      .delete(this.gridOptions.userStorageKey)
      .then(() => {
        dataGrid.instance.state({});

        const newState = setFreshGridState(dataGrid.instance.state());

        dataGrid.instance.state(newState);
      });
  }

  initGrid() {
    this.initGridData();
    this.navigationalProperties = this.implementor.navigationalProperties;
    this.defaultVisibleProperties = this.implementor.defaultVisibleProperties;
    this.ignoredProperties = this.implementor.ignoredProperties;
    this.objectTypeId = this.implementor.objectTypeId;
    this.objectTypeName = this.implementor.objectTypeName;

    if (this.implementor.gridRowActions$) {
      this.gridRowActions$ = this.implementor.gridRowActions$;
    }

    this.initColumns();
    this.loading$ = this.columns$.pipe(
      map(columns => {
        return !(columns && columns.length);
      }),
    );

    if (this.implementor.toolbarButtons$) {
      this.toolbarButtons$ = this.implementor.toolbarButtons$.pipe(
        switchMap(buttons =>
          this.actionsBuilderService.init<GridToolbarButton>(buttons),
        ),
      );
    }
  }

  getColumns(): Observable<GridColumn[]> {
    throw new Error('get columns method is not implemented');
  }

  initColumns() {
    const selectionColumn: GridColumn = {
      type: 'selection',
      propertyId: null,
      fixed: true,
      allowReordering: false,
      allowResizing: false,
      allowHiding: false,
      showInColumnChooser: false,
    };

    const actionsColumn: GridColumn = {
      type: 'buttons',
      propertyId: null,
      cellTemplate: 'actionsTemplate',
      fixed: true,
      allowReordering: false,
      allowResizing: false,
      allowHiding: false,
      showInColumnChooser: false,
      width: '80px',
    };
    this.columns$ = this.implementor.getColumns
      ? this.implementor.getColumns().pipe(
          map(columns => {
            if (columns.length) {
              const sortedColumns = columns
                .filter(column => {
                  if (this.implementor.ignoredProperties) {
                    return !this.ignoredProperties?.includes(column.dataField);
                  }
                  return true;
                })
                // TODO(milan): change sorting when default columns implementation will change
                .sort(
                  (a, b) =>
                    this.defaultVisibleProperties.indexOf(a.propertyId) -
                    this.defaultVisibleProperties.indexOf(b.propertyId),
                );
              const gridColumns = [selectionColumn, ...sortedColumns];

              if (this.implementor.gridRowActions$) {
                gridColumns.push(actionsColumn);
              }

              return gridColumns;
            }

            return null;
          }),
        )
      : this.gridSetupService
          .getObjectColumns({
            objectTypeId: this.objectTypeId,
            defaultVisibleProperties: this.defaultVisibleProperties,
            objectTypeName: this.objectTypeName,
            navigationalProperties: this.navigationalProperties,
          })
          .pipe(
            switchMap(data => {
              return this.genericListItemStorageService.itemsByListId$.pipe(
                map(itemsByListId => [data, itemsByListId]),
              );
            }),
            map(([columns, itemsByListId]) => {
              if (columns.length) {
                const processedColumns: GridColumn[] = [];

                columns.forEach(column => {
                  if (!this.ignoredProperties.includes(column.dataField)) {
                    if (
                      itemsByListId &&
                      [ValueType.GenericList, ValueType.SingleChoice].includes(
                        column.valueType,
                      )
                    ) {
                      // column.filterValues = itemsByListId[column.listId] || [];
                      // column.filterType = 'include';
                      column.lookup = {
                        allowClearing: true,
                        dataSource: itemsByListId.get(column.listId) || [],
                        displayExpr: 'name',
                        valueExpr: 'id',
                      };
                    }
                    processedColumns.push(column);
                  }
                });

                const sortedColumns = processedColumns.sort(
                  (a, b) =>
                    this.defaultVisibleProperties.indexOf(a.dataField) -
                    this.defaultVisibleProperties.indexOf(b.dataField),
                );
                const gridColumns = [selectionColumn, ...sortedColumns];

                if (this.implementor.gridRowActions$) {
                  gridColumns.push(actionsColumn);
                }

                return gridColumns;
              }
              return null;
            }),
          );
  }

  handleToolbarButtonClick(button: GridToolbarButton) {
    this.implementor.handleToolbarButtonClick(
      button,
      this.selectedData$.getValue(),
    );
  }

  handleGridRowActionClick(action: GridRowAction, data: T) {
    this.implementor.handleGridRowActionClick(action, data);
  }

  private initGridData() {
    const data$ = [];
    const navigationalProperties = this.implementor.navigationalProperties;
    if (navigationalProperties) {
      navigationalProperties.forEach(navigationalPropertySetting => {
        if (navigationalPropertySetting.data$) {
          data$.push(navigationalPropertySetting.data$);
        }
      });
    }
    if (!data$.length) {
      this.data$ = this.implementor.data$;
    } else {
      this.data$ = combineLatest([this.implementor.data$, ...data$]).pipe(
        map(data => {
          const primaryData = data.shift() as T[];
          const navigationalData = data as Map<string, any>[];
          const navigationalPropertiesWithData = navigationalProperties.filter(
            navigationalProperty => navigationalProperty.data$,
          );
          const enrichedPrimaryData = primaryData.map(rowData => {
            const enrichedRowData = {};
            navigationalPropertiesWithData.forEach(
              (navigationalProperty, i) => {
                const dataMap = navigationalData[i];
                enrichedRowData[navigationalProperty.dataPath] = dataMap
                  ? dataMap.get(rowData[navigationalProperty.foreignKey])
                  : null;
              },
            );
            return {
              ...rowData,
              ...enrichedRowData,
            };
          });
          return enrichedPrimaryData;
        }),
      );
    }
  }

  exportData(exportingEvent: ExportingEvent) {
    return this.implementor.customExport
      ? this.implementor.customExport(exportingEvent)
      : exportToXlsx(exportingEvent);
  }

  handleRowPrepared(event: RowPreparedEvent) {
    if (this.implementor.handleRowPrepared) {
      return this.implementor.handleRowPrepared(event);
    }
  }

  handleRowSelectionChanged(event: SelectionChangedEvent) {
    const selectedRowsData = this.implementor.handleRowSelectionChanged
      ? this.implementor.handleRowSelectionChanged(event)
      : event.selectedRowsData;
    this.selectedData$.next(selectedRowsData);
  }
}

export const DEFAULT_GRID_OPTIONS: Required<GridOptions> = {
  allowExport: true,
  allowExportSelectedData: true,
  allowColumnChooser: true,
  allowColumnReordering: true,
  allowColumnResizing: true,
  allowRestoreDefaultLayout: true,
  hoverStateEnabled: true,
  rowAlternationEnabled: true,
  showSelection: true,
  selectionMode: 'multiple',
  selectAllMode: 'allPages',
  toolbarVisible: true,
  toolbarLocation: 'top',
  userStorageKey: '',
};
