import {Inject} from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  Subscription,
  from,
  of,
  throwError,
  using,
} from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  share,
  skip,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import {arrayToMap} from '../../utils/utils';

export interface BaseApiService<GLP, GLR, CP, CR, UP, UR, DP, DR> {
  getList?(params: GLP): Observable<GLR>;
  create?(params: CP): Observable<CR>;
  update?(params: UP): Observable<UR>;
  delete?(params: DP): Observable<DR>;
  [key: string]: any;
}

export interface StorageOptions<T, F> {
  defaultStorageValue?: T;
  defaultFilter?: F;
  forceReloadAfterCommand?: boolean;
}

export abstract class BaseStorage<
  T extends {id?: string},
  StorageType,
  GetListParams,
  CreateParams,
  UpdateParams,
  DeleteParams,
> {
  protected _dataStorage$: BehaviorSubject<DataStorage<StorageType>>;

  data$ = using(
    () => this.getList().subscribe(),
    () => this.select(this.selectDataStorageValue),
  );

  dataById$: Observable<Map<string, T>> = this.data$.pipe(
    map(data => {
      // TODO(milan): change typing later
      return arrayToMap(data as any[], 'id');
    }),
  );

  dataFetching$: Observable<boolean>;
  dataSuccess$: Observable<boolean>;
  dataObsoleted$: Observable<boolean>;
  dataCommandInProgress$: Observable<boolean>;

  // TODO(milan): move to readonly store
  // dataAsSelectOptions$: Observable<InputSelectOption<T>[]> = this.data$.pipe(
  //   map(data => {
  //     if (isArray(data)) {
  //       return sortByKey(
  //         (data as any[]).map(this.entityToInputSelectOption),
  //         'name',
  //       );
  //     }

  //     return [];
  //   }),
  // );

  protected _filter$: BehaviorSubject<GetListParams>;

  protected dataObsoletedListener: Subscription;
  protected filterChangedListener: Subscription;

  get dataStorage$(): Observable<DataStorage<StorageType>> {
    return this._dataStorage$.asObservable();
  }

  get dataState(): DataStorage<StorageType> {
    return this._dataStorage$.getValue();
  }

  get filter(): GetListParams {
    return this._filter$.getValue();
  }

  constructor(
    @Inject('')
    protected apiService: BaseApiService<
      GetListParams,
      StorageType,
      CreateParams,
      T,
      UpdateParams,
      T,
      DeleteParams,
      void
    >,
    // protected apiService: BaseApiService<
    //   GetListParams,
    //   StorageType,
    //   CreateParams,
    //   T,
    //   UpdateParams,
    //   T,
    //   DeleteParams,
    //   void
    // >,
    @Inject('') protected options?: StorageOptions<StorageType, GetListParams>,
  ) {
    this._dataStorage$ = new BehaviorSubject(
      options?.defaultStorageValue
        ? {...INITIAL_DEFAULT_STORAGE_STATE, value: options.defaultStorageValue}
        : INITIAL_DEFAULT_STORAGE_STATE,
    );
    this._filter$ = new BehaviorSubject(options?.defaultFilter || null);
    this.apiService = apiService;

    // set common selectors
    this.dataFetching$ = this.select(this.selectDataStorageDataFetching);
    this.dataSuccess$ = this.select(this.selectDataStorageDataSuccess);
    this.dataObsoleted$ = this.select(this.selectDataStorageDataObsoleted);
    this.dataCommandInProgress$ = this.select(this.selectDataCommandInProgress);

    // automatically fetch data when marked as obsoleted
    this.dataObsoletedListener = this.dataObsoleted$
      .pipe(
        filter(Boolean),
        switchMap(() => this.getList()),
      )
      .subscribe();

    // refetch data when filter changes
    this.filterChangedListener = this._filter$
      .pipe(
        skip(1),
        distinctUntilChanged(),
        tap(filter => {
          this.updateDataState(state => ({
            state: StorageDataStateEnum.Obsoleted,
          }));
        }),
      )
      .subscribe();
  }

  // TODO(milan): move to readonly store
  // entityToInputSelectOption(entity: T & {name?: string}) {
  //   return {
  //     name: entity.name || 'This Entity does not have name property',
  //     value: entity.id,
  //     data: entity,
  //   };
  // }

  setFilter(data: GetListParams) {
    this._filter$.next(data);
  }

  /**
   * fetchData method will always fetch data based on type used in extending service
   */
  getList(): Observable<StorageType> {
    return this.query({
      queryMethod$: this.getListMethod.bind(this),
    });
  }

  getListMethod(params?: GetListParams): Observable<StorageType> {
    if (this.apiService.getList) {
      return this.apiService?.getList(params || this.filter);
    } else {
      throw new Error(
        'There is no getList method in provided ApiService. You have to override this method with correct ApiMethod.',
      );
    }
  }

  create(params: CreateParams, options?: CommandOptions) {
    return this.command<CreateParams, unknown, T>({
      ...options,
      commandMethod$: this.createMethod.bind(this),
      commandInput: params,
      updateState: this.createSuccessUpdateState.bind(this),
    });
  }

  createMethod(params: CreateParams) {
    if (this.apiService.create) {
      return this.apiService.create(params);
    } else {
      throw new Error(
        'There is no create method in provided ApiService. You have to override this method with correct ApiMethod.',
      );
    }
  }

  createSuccessUpdateState(data, input) {
    this.updateDataState(store => ({
      value: Array.isArray(store.value) ? [...store.value, data] : data,
      state: StorageDataStateEnum.Success,
    }));
  }

  update(params: UpdateParams, options?: CommandOptions) {
    return this.command<UpdateParams, unknown, T>({
      ...options,
      commandMethod$: this.updateMethod.bind(this),
      commandInput: params,
      updateState: this.updateSuccessUpdateState.bind(this),
    });
  }

  updateMethod<I = Partial<T>>(params: UpdateParams) {
    if (this.apiService.update) {
      return this.apiService.update(params);
    } else {
      throw new Error(
        'There is no update method in provided ApiService. You have to override this method with correct ApiMethod.',
      );
    }
  }

  updateSuccessUpdateState(data, input) {
    this.updateDataState(store => ({
      value: Array.isArray(store.value)
        ? store.value.map(obj => {
            if (obj.id === data.id) {
              return {
                ...obj,
                ...data,
              };
            }
            return obj;
          })
        : {
            ...store.value,
            ...input,
          },
      state: StorageDataStateEnum.Success,
    }));
  }

  delete(params: DeleteParams, options?: CommandOptions) {
    return this.command<DeleteParams, unknown, void>({
      ...options,
      commandMethod$: this.deleteMethod.bind(this),
      commandInput: params,
      updateState: this.deleteSuccessUpdateState.bind(this),
    });
  }

  deleteMethod(params: DeleteParams) {
    if (this.apiService.delete) {
      return this.apiService.delete(params);
    } else {
      throw new Error(
        'There is no delete method in provided ApiService. You have to override this method with correct ApiMethod',
      );
    }
  }

  deleteSuccessUpdateState(data, input) {
    this.updateDataState(store => ({
      value: Array.isArray(store.value)
        ? (store.value.filter(obj => obj.id !== input.id) as StorageType)
        : null,
      state: StorageDataStateEnum.Success,
    }));
  }

  // API CALL AND STORAGE UPDATING
  protected query<QI, R extends StorageType>(options: QueryDataOptions<QI, R>) {
    const {queryMethod$, queryMethodInput, forceQuery} = options;
    return of(null).pipe(
      withLatestFrom(this.dataStorage$),
      filter(([observable, state]) => {
        if (forceQuery) {
          return true;
        }

        return [
          StorageDataStateEnum.Idle,
          StorageDataStateEnum.Obsoleted,
        ].includes(state.state);
      }),
      switchMap(() => {
        this.updateDataStorageOnQueryDataStart();
        return from(queryMethod$(queryMethodInput)).pipe(
          tap(data => {
            this.updateDataStorageOnQueryDataSuccess(data);
          }),
          catchError(error => {
            this.updateDataStorageOnQueryDataError(error);
            return throwError(() => error);
          }),
          finalize(() => {
            this.updateDataStorageOnQueryDataOnComplete();
          }),
        );
      }),
      share(),
    );
  }

  protected command<I, E, R>(options: Command<I, E, R>) {
    const {commandInput, commandMethod$} = options;
    this.updateDataStorageOnCommandStart();
    return this.doCommand(
      from(commandMethod$(commandInput)).pipe(
        tap(data => {
          options.forceReload
            ? this.updateDataStorageOnCommandSuccess<I, R>(data, commandInput)
            : options.updateState(data, commandInput);
        }),
        catchError(error => {
          this.updateDataStorageOnCommandError(error);
          return throwError(error);
        }),
      ),
      options,
    );
  }

  markAsObsoleted() {
    this.updateDataState(storage => ({
      state: StorageDataStateEnum.Obsoleted,
    }));
  }

  protected select<K>(
    selector: (storage: DataStorage<StorageType>) => K,
  ): Observable<K> {
    return this.dataStorage$.pipe(map(selector), distinctUntilChanged());
  }

  protected selectDataStorage(storage: DataStorage<StorageType>) {
    return storage;
  }

  protected selectDataStorageValue(storage: DataStorage<StorageType>) {
    return storage.value;
  }

  protected selectDataStorageDataFetching(storage: DataStorage<StorageType>) {
    return [StorageDataStateEnum.Idle, StorageDataStateEnum.Fetching].includes(
      storage.state,
    );
  }

  protected selectDataStorageDataSuccess(storage: DataStorage<StorageType>) {
    return storage.state === StorageDataStateEnum.Success;
  }

  protected selectDataStorageDataObsoleted(storage: DataStorage<StorageType>) {
    return storage.state === StorageDataStateEnum.Obsoleted;
  }

  protected selectDataCommandInProgress(storage: DataStorage<StorageType>) {
    return storage.state === StorageDataStateEnum.CommandInProgress;
  }

  protected updateDataState<
    K extends keyof DataStorage<StorageType>,
    E extends Partial<Pick<DataStorage<StorageType>, K>>,
  >(fn: (storage: DataStorage<StorageType>) => E): void {
    const updatedState = fn(this.dataState);
    this._dataStorage$.next({...this.dataState, ...updatedState});
  }

  private doCommand<I = unknown, E = unknown, R = unknown>(
    observable$: Observable<any>,
    options: DoCommandOptions<I, E, R>,
  ) {
    return doCommand(observable$.pipe(share()));
  }

  private updateDataStorageOnQueryDataStart() {
    this.updateDataState(storage => ({
      state: StorageDataStateEnum.Fetching,
    }));
  }

  private updateDataStorageOnQueryDataSuccess(value: StorageType) {
    this.updateDataState(storage => ({
      value,
      state: StorageDataStateEnum.Success,
    }));
  }

  // TODO(milan): change type later
  private updateDataStorageOnQueryDataError(error: any) {
    this.updateDataState(storage => ({
      error,
      state: StorageDataStateEnum.Error,
    }));
  }

  /**
   * Setting Idle state is happening only when there is api call canceled and state is stucked in fetching state
   */
  private updateDataStorageOnQueryDataOnComplete() {
    this.updateDataState(storage => ({
      state:
        storage.state === StorageDataStateEnum.Fetching
          ? StorageDataStateEnum.Idle
          : storage.state,
    }));
  }

  // commands storage methdos
  // data storage manipulation
  private updateDataStorageOnCommandStart() {
    this.updateDataState(storage => ({
      state: StorageDataStateEnum.CommandInProgress,
    }));
  }

  private updateDataStorageOnCommandSuccess<I, R>(value: R, input: I) {
    this.updateDataState(storage => ({
      state: StorageDataStateEnum.Obsoleted,
    }));
  }

  private updateDataStorageOnCommandError(error: any) {
    this.updateDataState(storage => ({
      error,
      state: StorageDataStateEnum.Error,
    }));
  }

  private updateDataStorageOnCommandOnComplete() {
    this.updateDataState(storage => ({
      state:
        storage.state === StorageDataStateEnum.CommandInProgress
          ? StorageDataStateEnum.Idle
          : storage.state,
    }));
  }
}

interface AnyEntity {
  id: string;
}

export interface DataStorage<T, E = unknown> {
  state: StorageDataStateEnum;
  value: T;
  error: E;
}

export enum StorageDataStateEnum {
  Idle = 'Idle',
  Fetching = 'Fetching',
  CommandInProgress = 'CommandInProgress',
  Success = 'Success',
  Error = 'Error',
  Obsoleted = 'Obsoleted',
}

const INITIAL_DEFAULT_STORAGE_STATE: DataStorage<any, unknown> = {
  state: StorageDataStateEnum.Idle,
  value: [],
  error: null,
};

export interface QueryDataOptions<QI, R = unknown> {
  forceQuery?: boolean;
  queryMethodInput?: QI;
  queryMethod$: (input?: QI) => Observable<R>;
}

export interface CommandOptions {
  forceReload?: boolean;
}

export interface Command<CI = unknown, E = unknown, R = unknown> {
  commandInput: CI;
  commandMethod$: (input: CI) => Observable<R>;
  updateState?: (data, input) => void;
  forceReload?: boolean;
}

type DoCommandOptions<I, E, R> = Omit<Command<I, E, R>, 'commandMethod$'>;

function doCommand<T>(action$: Observable<T>) {
  action$.subscribe();
  return action$;
}
