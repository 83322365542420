import {Injectable} from '@angular/core';
import {RequestLog} from 'src/app/shared/models';
import {IRequestLogDataService} from '../../interfaces/data-service';
import {BaseOfflineService} from './base.offline-service';

@Injectable()
export class RequestLogOfflineService
  extends BaseOfflineService
  implements IRequestLogDataService
{
  public getLogs(searchOptions: {
    userIds?: string[];
    from?: Date;
    to?: Date;
    fullText?: string;
    limit?: number;
  }): Promise<RequestLog[]> {
    return Promise.reject('Offline service - not yet implemented');
  }
}
