import {IObjectForm} from '@retrixhouse/salesapp-shared/lib/models';
import {ObjectProperty} from './object-property.model';

export class ObjectType {
  id: string;
  name: string;
  description?: string | null;
  tableOrView?: string | null;
  versioned: boolean;
  userIdentified: boolean;
  uidPrefix?: string | null;
  properties?: ObjectProperty[];
  forms: IObjectForm[];
}
