import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {PillComponent} from './pill.component';

@NgModule({
  imports: [CommonModule],
  declarations: [PillComponent],
  providers: [],
  exports: [PillComponent],
})
export class PillModule {}
