import {custom} from 'devextreme/ui/dialog';
import {dxButtonOptions} from 'devextreme/ui/button';
import {TranslateService} from '@ngx-translate/core';
import {Injectable} from '@angular/core';

export enum ConfirmType {
  DELETE = 'DELETE',
  CONFIRM = 'CONFIRM',
  DEFAULT = 'DEFAULT',
}

export interface ConfirmDialogButtonOptions {
  acceptIcon?: string;
  acceptText?: string;
  rejectIcon?: string;
  rejectText?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ConfirmationService {
  lastDialog: any;
  constructor(private translate: TranslateService) {}

  public confirm(
    type: ConfirmType = ConfirmType.DEFAULT,
    title?: string,
    message?: string,
    buttonOptions?: ConfirmDialogButtonOptions,
  ): Promise<boolean> {
    const dialog = custom({
      title: title ?? 'Confirmation',
      messageHtml:
        message ??
        `<div><p>${this.translate.instant('confirm-message')}</p></div>`,
      buttons: this.getButtonOptions(type, buttonOptions),
    });

    this.lastDialog = dialog;
    return dialog.show().catch(() => Promise.resolve(false));
  }

  /**
   * Hides the last displayed dialog.
   */
  public hide() {
    this.lastDialog?.hide();
  }

  private getButtonOptions(
    type: ConfirmType,
    buttonOptions?: ConfirmDialogButtonOptions,
  ): Array<dxButtonOptions> {
    switch (type) {
      case ConfirmType.CONFIRM:
        return [
          {
            icon: buttonOptions?.acceptIcon ?? 'check',
            onClick: () => true,
            stylingMode: 'contained',
            text:
              buttonOptions?.acceptText ??
              this.translate.instant('buttons.confirm'),
            type: 'default',
          },
          {
            onClick: () => false,
            icon: buttonOptions?.rejectIcon ?? 'close',
            text:
              buttonOptions?.rejectText ??
              this.translate.instant('buttons.close'),
            type: 'normal',
          },
        ];
      case ConfirmType.DELETE:
        return [
          {
            icon: buttonOptions?.acceptIcon ?? 'trash',
            onClick: () => true,
            stylingMode: 'contained',
            text:
              buttonOptions?.acceptText ??
              this.translate.instant('buttons.delete'),
            type: 'danger',
          },
          {
            onClick: () => false,
            icon: buttonOptions?.rejectIcon ?? 'close',
            text:
              buttonOptions?.rejectText ??
              this.translate.instant('buttons.cancel'),
            type: 'normal',
          },
        ];
      case ConfirmType.DEFAULT:
      default:
        return [
          {
            icon: buttonOptions?.acceptIcon ?? 'back',
            onClick: () => true,
            stylingMode: 'contained',
            text:
              buttonOptions?.acceptText ?? this.translate.instant('buttons.ok'),
            type: 'default',
          },
        ];
    }
  }
}
