import {Filter} from '@loopback/filter';
import {ClientMode} from '../../enums/client-mode.enum';

export class BaseOfflineService {
  public get supportedMode(): ClientMode {
    return ClientMode.OFFLINE;
  }
}

export class BaseReadonlyOfflineService<T> extends BaseOfflineService {
  public getList(filter?: Filter): Promise<T[]> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public getSingle(id: string): Promise<T> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public getCount(filter?: Filter): Promise<number> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public exists(filter?: Filter): Promise<boolean> {
    return Promise.reject('Offline service - not yet implemented');
  }
}

export class BaseCrudOfflineService<T> extends BaseReadonlyOfflineService<T> {
  public create(objectData: Partial<T>): Promise<T> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public update(id: string, objectData: Partial<T>): Promise<T> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public delete(id: string, version?: number): Promise<void> {
    return Promise.reject('Offline service - not yet implemented');
  }
}
