import {ConnectionType} from '../enums/connection-type.enum';

export enum EventName {
  FirebaseId = 'firebaseId',
  Connectivity = 'connectivity',
  Location = 'location',
  PushNotification = 'pushNotification',
  AppWillBecomeInactive = 'appWillBecomeInactive',
  AppDidBecomeActive = 'appDidBecomeActive',
}

export class Event {
  id: string;
  timestamp: number;
  name: EventName;
  params?: {
    [prop: string]: any;
  };

  constructor(eventData: any) {
    this.id = eventData.id;
    this.timestamp = eventData.timestamp;
    this.name = eventData.name;
    this.params = eventData.params;
  }

  public isValid(): boolean {
    return (
      !!this.id &&
      !!this.timestamp &&
      !!this.name &&
      Object.values(EventName).includes(this.name)
    );
  }
}

export type ConnectivityEvent = {
  type: ConnectionType;
};

export type LocationEvent = {
  latitude: number;
  longitude: number;
  altitude?: number;
  accuracy?: number;
};

export type PushNotificationEvent = {
  title?: string;
  message?: string;
};

export type FirebaseIdEvent = {
  firebaseId: string;
};
