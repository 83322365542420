import {Country} from './country.model';
import {BaseModel} from './base.model';

export class Address extends BaseModel {
  line1?: string;
  line2?: string;
  zipCode?: string;
  city?: string;
  region?: string;
  state?: string;
  countryId?: string;
  country?: Country;

  constructor() {
    super();
    this.line1 = null;
    this.line2 = null;
    this.zipCode = null;
    this.city = null;
    this.region = null;
    this.state = null;
    this.countryId = null;
  }
}
