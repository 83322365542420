import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnInit,
} from '@angular/core';

@Component({
  selector: 'app-form-column',
  templateUrl: './form-column.component.html',
  styleUrls: ['./form-column.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormColumnComponent implements OnInit {
  @Input()
  @HostBinding('style.min-width')
  minWidth?: string;
  constructor() {}

  ngOnInit(): void {}
}
