import {CommonModule} from '@angular/common';
import {
  Component,
  Input,
  NgModule,
  OnInit,
  Output,
  EventEmitter,
  TemplateRef,
} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {DevExtremeModule} from 'devextreme-angular';
import {PinchZoomModule} from '@meddv/ngx-pinch-zoom';
import {ScreenService} from '../../services';

@Component({
  selector: 'app-img-popup',
  templateUrl: './img-popup.component.html',
  styleUrls: ['./img-popup.component.scss'],
})
export class ImgPopupComponent implements OnInit {
  @Input() visible: boolean = false;
  @Input() allowSidePanle: boolean = false;

  @Output() visibleChange = new EventEmitter<boolean>();
  /**
   * Used only when no template is provided.
   */
  @Input() imageUrl: string;
  // use if you want to provide a custom template to show the img.
  @Input() imgTemplate: TemplateRef<any>;

  @Output() onImageLoaded = new EventEmitter();
  @Output() onImageLoadError = new EventEmitter();
  @Output() onShown = new EventEmitter();
  @Output() onHidden = new EventEmitter();

  isSmallScreen: boolean;

  constructor(screenService: ScreenService) {
    this.isSmallScreen = screenService.isSmallScreen();
  }

  ngOnInit(): void {}

  closePhotoViewMode(e) {
    this.visible = false;
    this.visibleChange.emit(this.visible);
  }

  onPopupHidden(e) {
    this.visible = false;
    this.visibleChange.emit(this.visible);
    this.onHidden.emit(e);
  }

  onPopupShown(e) {
    this.onShown.emit(e);
  }

  contentReady(e) {
    var contentElement: HTMLElement = e.component.content();
    contentElement.style.padding = '0px';
    contentElement.style.backgroundColor = 'black';
    contentElement.style.display = 'flex';
    contentElement.style.alignItems = 'center';
    contentElement.style.alignContent = 'center';
  }

  imgLoadError(e) {
    this.onImageLoadError.emit(e);
  }

  imageLoaded(e) {
    this.onImageLoaded.emit(e);
  }
}

@NgModule({
  declarations: [ImgPopupComponent],
  imports: [CommonModule, DevExtremeModule, TranslateModule, PinchZoomModule],
  exports: [ImgPopupComponent],
})
export class ImgPopupModule {}
