import {Injectable} from '@angular/core';
import {
  IGenericList,
  IGenericListItem,
  IGenericListWithRelations,
} from '@retrixhouse/salesapp-shared/lib/models';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {DataProvider} from '../../data.provider/data-provider';
import {OldBaseStorageService} from './old-base-storage-service';

@Injectable()
export class GenericListStorageService extends OldBaseStorageService<
  IGenericListWithRelations,
  IGenericListWithRelations[]
> {
  constructor(dataProvider: DataProvider) {
    super(dataProvider.genericList);
  }

  // TODO(milan): remove this method
  /**
   * Returns a Map of generic lists.
   * Map<id, IGenericList>
   */
  dataAsMap$: Observable<Map<string, IGenericList>> = this.data$.pipe(
    map(data => {
      if (data) {
        return new Map<string, IGenericList>(
          data.map(item => [item.id, item] as [string, IGenericList]),
        );
      }

      return new Map<string, IGenericList>();
    }),
  );

  genericListItemsById$ = this.data$.pipe(
    map(data => {
      const map = new Map<string, IGenericListItem>();

      data.forEach(genericList => {
        genericList.items?.forEach(item => map.set(item.id, item));
      });
      return map;
    }),
  );
}
