import {Injectable} from '@angular/core';
import {DialogService} from '@salesapp/dialog';
import {
  ProductDetailDialogComponent,
  ProductDetailDialogData,
} from '../components/product-detail-dialog/product-detail-dialog.component';

@Injectable()
export class ProductService {
  constructor(private dialogService: DialogService) {}

  openDetail(productId: string) {
    this.dialogService.open<
      ProductDetailDialogComponent,
      ProductDetailDialogData
    >(ProductDetailDialogComponent, {
      data: {productId},
      width: '90%',
      height: '90%',
    });
  }
}
