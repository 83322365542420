export class ExportPhotosAsZipRequest {
  photoIds: string[];
  type?: ZipExportTypes;
}

export enum ZipExportTypes {
  Flat = 'Flat',
  Store = 'Store',
  StoreScheduledStart = 'Store-ScheduledStart',
  ScheduledStartStore = 'ScheduledStart-Store',
  StoreScheduledStartTodoAction = 'Store-ScheduledStart-TodoAction',
  TodoActionStore = 'TodoAction-Store',
  TodoActionStoreScheduledStart = 'TodoAction-Store-ScheduledStart',
}
