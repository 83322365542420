<div class="info-message">
  <i class="fa-solid fa-circle-info"></i>
  <div class="info-message-body">
    <span class="info-message-title">
      {{ 'form-designer.dialog.edit-form-control.location-settings.title' | translate }}
    </span>
    <span class="info-message-description">
      {{ 'form-designer.dialog.edit-form-control.location-settings.description' | translate }}
    </span>
  </div>
</div>

<div class="setting-row">
  <span class="label">{{ 'form-designer.dialog.edit-form-control.location-settings.longitude' | translate}} *</span>
  <i class="fa-solid fa-arrow-right-long"></i>
  <app-input-select [formControl]="getFormControl('longitudePropertyId')"
                    [formControlRef]="getFormControl('longitudePropertyId')"
                    [options]="longitudeOptions$ | async"
                    [hint]="'form-designer.dialog.edit-form-control.location-settings.longitude-hint' | translate"></app-input-select>
</div>
<div class="setting-row">
  <span class="label">{{ 'form-designer.dialog.edit-form-control.location-settings.latitude' | translate}} *</span>
  <i class="fa-solid fa-arrow-right-long"></i>
  <app-input-select [formControl]="getFormControl('latitudePropertyId')"
                    [formControlRef]="getFormControl('latitudePropertyId')"
                    [options]="latitudeOptions$ | async"
                    [hint]="'form-designer.dialog.edit-form-control.location-settings.latitude-hint' | translate"></app-input-select>
</div>
<div class="setting-row">
  <span class="label">{{ 'form-designer.dialog.edit-form-control.location-settings.altitude' | translate}}</span>
  <i class="fa-solid fa-arrow-right-long"></i>
  <app-input-select [formControl]="getFormControl('altitudePropertyId')"
                    [formControlRef]="getFormControl('altitudePropertyId')"
                    [options]="altitudeOptions$ | async"
                    [hint]="'form-designer.dialog.edit-form-control.location-settings.altitude-hint' | translate"></app-input-select>
</div>