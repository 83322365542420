import {BaseModel} from './base.model';
import {GenericListItem} from './generic-list.model';
import {Order} from './order.model';
import {PersonalArrangement} from './personal-arrangement.model';
import {QuestionnaireResult} from './questionnaire-result.model';

export enum TodoActionResultState {
  Approved = 'Approved',
  Denied = 'Denied',
  ReworkRequested = 'ReworkRequested',
  Pending = 'Pending',
}

export class TodoActionResult extends BaseModel {
  openedAt?: Date;
  timeSpent: number;
  completed: boolean;
  notes: string;
  reasonId?: string;
  reason?: GenericListItem;
  todoListResultId: string;
  state?: TodoActionResultState;
  todoListItemId?: string;
  personalArrangements?: PersonalArrangement[];
  orders?: Order[];
  questionnaireResults?: QuestionnaireResult[];
  highlightColor?: string;

  constructor() {
    super();
  }
}
