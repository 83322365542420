import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {
  InputMultiSelectModule,
  InputNumberModule,
  InputSelectModule,
  InputTextModule,
} from '@salesapp/components';
import {DialogModule} from '@salesapp/dialog';
import {FormModule} from '@salesapp/form';
import {GridModule} from '@salesapp/grid';
import {ExternalReportDetailDialogComponent} from './components/external-report-detail-dialog/external-report-detail-dialog.component';
import {ExternalReportEditDialogComponent} from './components/external-report-edit-dialog/external-report-edit-dialog.component';
import {ExternalReportGridComponent} from './components/external-report-grid/external-report-grid.component';
import {ExternalReportGridService} from './services/external-report-grid.service';
import {ExternalReportService} from './services/external-report.service';

const SALESAPP_MODULES = [DialogModule, FormModule, GridModule];

const UI_COMPONENTS = [
  InputTextModule,
  InputSelectModule,
  InputNumberModule,
  InputMultiSelectModule,
];

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    ...SALESAPP_MODULES,
    ...UI_COMPONENTS,
  ],
  declarations: [
    ExternalReportGridComponent,
    ExternalReportDetailDialogComponent,
    ExternalReportEditDialogComponent,
  ],
  providers: [ExternalReportGridService, ExternalReportService],
  exports: [ExternalReportGridComponent],
})
export class ExternalReportModule {}

export * from './components/external-report-grid/external-report-grid.component';
export * from './services/external-report.service';
