import {ObjectMapCache} from '@retrixhouse/salesapp-shared/lib/caching';
import {ITriggerDataService} from '../../interfaces/data-service';
import {Trigger, TriggerExecutionResult} from '../../models';
import {TriggerHttpService} from '../http';
import {TriggerOfflineService} from '../offline';
import {BaseCrudDataService} from './base.data-service';

export class TriggerDataService extends BaseCrudDataService<
  Trigger,
  ITriggerDataService,
  ObjectMapCache<string, Trigger>
> {
  constructor(
    onlineService: TriggerHttpService,
    offlineService: TriggerOfflineService,
    cache: ObjectMapCache<string, Trigger>,
  ) {
    super(onlineService, offlineService, cache);
  }

  public validateTriggerDefinition(definition: string): Promise<boolean> {
    return this.service.validateTriggerDefinition(definition);
  }

  public execute(id: string, context: any): Promise<TriggerExecutionResult> {
    return this.service.execute(id, context);
  }
}
