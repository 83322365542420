import {HttpResponse} from '@angular/common/http';
import {
  IVisitPhotosResponse,
  IVisitPivotReportResponse, IVisitDataReportMatrixColumnCount,
} from '@retrixhouse/salesapp-shared/lib/responses';
import {Subject} from 'rxjs';
import {IVisitDataService} from '../../interfaces/data-service';
import {
  GetVisitPhotosRequest,
  MobilePhotoChunk,
  OpenVisitRequest,
  OpenVisitResponse,
  PersonalArrangement,
  Photo,
  TodoListResult,
  TourPlan,
  VisitDataReportResponse,
  VisitDataResponse,
  VisitResultRequest,
  VisitResultResponse,
  VisitsPivotReportRequest,
  VisitsReportRequest,
} from '../../models';
import {VisitHttpService} from '../http';
import {VisitOfflineService} from '../offline';
import {BaseDataService} from './base.data-service';
import {IQuestionnaireResultChangeRequest} from '@retrixhouse/salesapp-shared/lib/requests';
import {IQuestionnaireResultWithRelations} from '@retrixhouse/salesapp-shared/lib/models';
import {UploadPhotoResponse} from '@retrixhouse/salesapp-shared/lib/responses/upload-photo';

export class VisitDataService extends BaseDataService<IVisitDataService> {
  private _onVisitCancelled: Subject<TourPlan>;

  public get onVisitCancelled(): Subject<TourPlan> {
    return this._onVisitCancelled;
  }

  constructor(
    onlineService: VisitHttpService,
    offlineService: VisitOfflineService,
  ) {
    super(onlineService, offlineService);
    this._onVisitCancelled = new Subject<TourPlan>();
  }

  public getVisitPhotos(visitId: string): Promise<Photo[]> {
    return this.service.getVisitPhotos(visitId);
  }

  public uploadPhotoChunk(
    chunk: MobilePhotoChunk,
  ): Promise<UploadPhotoResponse> {
    return this.service.uploadPhotoChunk(chunk);
  }

  public getVisitData(
    visitId: string,
    executorId?: string,
  ): Promise<VisitDataResponse> {
    return this.service.getVisitData(visitId, executorId);
  }

  public getVisitDataBulk(visitIds: string[]): Promise<VisitDataResponse[]> {
    return this.service.getVisitDataBulk(visitIds);
  }

  public getVisitDataForUser(
    visitId: string,
    userId: string,
  ): Promise<VisitDataResponse> {
    return this.service.getVisitDataForUser(visitId, userId);
  }

  public getVisitResult(tourPlanId: string): Promise<VisitDataResponse[]> {
    return this.service.getVisitResult(tourPlanId);
  }

  public getVisitResultTemplate(
    projectIds: string[],
  ): Promise<{projectId: string; template: Partial<VisitDataResponse>}[]> {
    return this.service.getVisitResultTemplate(projectIds);
  }

  public getPreviousVisitResultsForUser(): Promise<
    {projectId: string; storeId: string; result: TodoListResult}[]
  > {
    return this.service.getPreviousVisitResultsForUser();
  }

  public saveVisitData(
    visitDataRequest: VisitResultRequest,
  ): Promise<VisitResultResponse> {
    return this.service.saveVisitData(visitDataRequest);
  }

  public editVisitData(
    visitResultId: string,
    visitDataRequest: VisitResultRequest,
  ): Promise<TodoListResult> {
    return this.service.editVisitData(visitResultId, visitDataRequest);
  }

  public deleteVisitData(
    visitResultId: string,
    version: number,
  ): Promise<void> {
    return this.service.deleteVisitData(visitResultId, version);
  }

  public openVisit(
    startVisitRequest: OpenVisitRequest,
  ): Promise<OpenVisitResponse> {
    return this.service.openVisit(startVisitRequest);
  }

  public async cancelVisit(tourPlanId: string): Promise<TourPlan> {
    const tp = await this.service.cancelVisit(tourPlanId);
    this._onVisitCancelled.next(tp);
    return tp;
  }

  public getPersonalArrangementsForUser(): Promise<
    {storeId: string; personalArrangement: PersonalArrangement}[]
  > {
    return this.service.getPersonalArrangementsForUser();
  }

  public getVisitDataReport(
    request: VisitsReportRequest,
  ): Promise<VisitDataReportResponse> {
    return this.service.getVisitDataReport(request);
  }

  public getVisitPivotReport(
    request: VisitsPivotReportRequest,
  ): Promise<IVisitPivotReportResponse> {
    return this.service.getVisitPivotReport(request);
  }

  public getVisitDataReportAsXlsx(
    request: VisitsReportRequest,
  ): Promise<HttpResponse<Blob>> {
    return this.service.getVisitDataReportAsXlsx(request);
  }

  public getVisitsWithPhotos(
    request: GetVisitPhotosRequest,
  ): Promise<IVisitPhotosResponse[]> {
    return this.service.getVisitsWithPhotos(request);
  }

  public editQuestionnaireResult(
    todoListResultId: string,
    request: IQuestionnaireResultChangeRequest,
  ): Promise<IQuestionnaireResultWithRelations> {
    return this.service.editQuestionnaireResult(todoListResultId, request);
  }

  public getMatrixColumnCount(
    request: VisitsReportRequest,
  ): Promise<IVisitDataReportMatrixColumnCount> {
    return this.service.getMatrixColumnCount(request);
  }
}
