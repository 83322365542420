import {IPosition} from '@retrixhouse/salesapp-shared/lib/models';
import {OldBaseStorageService} from './old-base-storage-service';
import {Injectable} from '@angular/core';
import {DataProvider} from '../../data.provider/data-provider';
import {InputSelectOption} from '../../components';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {sortByKey} from '../../globals';
import {arrayToMap} from '@salesapp/utils/utils';

@Injectable()
export class PositionStorageService extends OldBaseStorageService<
  IPosition,
  IPosition[]
> {
  dataAsSelectOptions$: Observable<InputSelectOption<IPosition>[]> =
    this.data$.pipe(
      map(data =>
        sortByKey(
          data
            .filter(position => !position.customerId)
            .map(position => ({
              name: position.name,
              value: position.id,
              data: position,
            })),
          'name',
        ),
      ),
    );

  DataWithoutCustomerAsSelectOptions$: Observable<
    InputSelectOption<IPosition>[]
  > = this.data$.pipe(
    map(data => {
      const filteredData = data.filter(
        position => position.customerId === null,
      );

      return sortByKey(
        filteredData.map(position => ({
          name: position.name,
          value: position.id,
          data: position,
        })),
        'name',
      );
    }),
  );

  dataWithoutCustomerById$: Observable<Map<string, IPosition>> =
    this.data$.pipe(
      map(data => {
        const result = data.filter(p => !p.customerId);
        return arrayToMap(result as any[], 'id');
      }),
    );

  constructor(dataProvider: DataProvider) {
    super(dataProvider.position);
  }
}
