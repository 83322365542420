import {
  ObjectArrayCache,
  ObjectMapCache,
} from '@retrixhouse/salesapp-shared/lib/caching';
import {
  ITourPlanDataService,
  SearchInLocation,
} from '../../interfaces/data-service';
import {
  TourPlan,
  TourPlanExchangeInfo,
  TourPlanQuickEditRequest,
  TourPlanSearchRequest,
  TourPlanWithExchangeInfo,
} from '../../models';
import {TourPlanHttpService} from '../http';
import {TourPlanOfflineService} from '../offline';
import {
  BaseCrudDataService,
  CachingOptions,
  TTL_DAY,
} from './base.data-service';
import deepEqual from 'deep-equal';
import cloneDeep from 'clone-deep';
import {
  ITourPlanExchangeCheckRequest,
  ITourPlanExchangeRequest,
  ITourPlanExchangeSearchRequest,
  ITourPlanCopyRequest,
} from '@retrixhouse/salesapp-shared/lib/requests';
import {IProcessingProgress} from '@retrixhouse/salesapp-shared/lib/responses';
import {HttpResponse} from '@angular/common/http';
import {TourPlanState} from '@retrixhouse/salesapp-shared/lib/models';

export type TourPlanCaches = {
  cache: boolean;
  cacheSearch: boolean;
  cacheSearchInLocation: boolean;
};

export class TourPlanDataService extends BaseCrudDataService<
  TourPlan,
  ITourPlanDataService,
  ObjectMapCache<string, TourPlan>
> {
  private _cacheSearch: ObjectArrayCache<TourPlan>;
  private _cacheSearchInLocation: ObjectArrayCache<TourPlan>;
  private _lastSearchFilter?: TourPlanSearchRequest;
  private _lastSearchInLocation?: SearchInLocation;

  constructor(
    onlineService: TourPlanHttpService,
    offlineService: TourPlanOfflineService,
    cache: ObjectMapCache<string, TourPlan>,
    cacheSearch: ObjectArrayCache<TourPlan>,
    cacheSearchInLocation: ObjectArrayCache<TourPlan>,
  ) {
    super(onlineService, offlineService, cache);
    this._cacheSearch = cacheSearch;
    this._cacheSearchInLocation = cacheSearchInLocation;
  }

  public override async update(
    id: string,
    tourPlan: Partial<TourPlan>,
  ): Promise<TourPlan> {
    const updatedTourPlan = await super.update(id, tourPlan);
    if (updatedTourPlan) {
      this.updateInCaches(updatedTourPlan, {
        cache: false,
        cacheSearch: true,
        cacheSearchInLocation: true,
      });
    }
    return updatedTourPlan;
  }

  public override async delete(id: string, version?: number): Promise<void> {
    await super.delete(id, version);
    this.deleteFromCaches(id);
  }

  public async bulkCreate(
    tourPlans: TourPlan[],
    reloadAfter = false,
  ): Promise<TourPlan[]> {
    const createdTourPlans = await this.service.bulkCreate(tourPlans);
    if (reloadAfter && this._lastSearchFilter) {
      await this.search(this._lastSearchFilter, {forceReload: true});
    } else {
      this.addToCaches(createdTourPlans);
    }
    return createdTourPlans;
  }

  public async updateIgnoreVersion(
    objectId: string,
    objectData: Partial<TourPlan>,
  ): Promise<TourPlan> {
    const updatedTourPlan = await this.service.updateIgnoreVersion(
      objectId,
      objectData,
    );
    this.updateInCaches(updatedTourPlan);
    return updatedTourPlan;
  }

  public async search(
    filter: TourPlanSearchRequest,
    cachingOptions?: CachingOptions,
  ): Promise<TourPlan[]> {
    // no caching
    if (cachingOptions?.skipCache) {
      return this.service.search(filter);
    }

    // with caching (remember last filter)
    if (
      cachingOptions?.forceReload ||
      !this._cacheSearch.isValid ||
      !deepEqual(this._lastSearchFilter, filter)
    ) {
      this._lastSearchFilter = cloneDeep(filter);
      const tourPlans = await this.service.search(filter);
      this._cacheSearch.init(tourPlans, TTL_DAY);
    }

    return this._cacheSearch.getAll();
  }

  public async searchInLocation(
    search: SearchInLocation,
    cachingOptions?: CachingOptions,
  ): Promise<TourPlan[]> {
    // no caching
    if (cachingOptions?.skipCache) {
      return this.service.searchInLocation(search);
    }

    // with caching (remember last location)
    if (
      cachingOptions?.forceReload ||
      !this._cacheSearchInLocation.isValid ||
      !deepEqual(this._lastSearchInLocation, search)
    ) {
      this._lastSearchInLocation = cloneDeep(search);
      const tourPlans = await this.service.searchInLocation(search);
      this._cacheSearchInLocation.init(tourPlans, TTL_DAY);
    }

    return this._cacheSearchInLocation.getAll();
  }

  public async isScheduledStartValid(
    scheduledStart: Date,
    projectId: string,
  ): Promise<boolean> {
    return this.service.isScheduledStartValid(scheduledStart, projectId);
  }

  public async updateStateIgnoreVersion(
    tourPlanId: string,
    state: TourPlanState,
  ): Promise<TourPlan> {
    const tourPlan = await this.service.updateStateIgnoreVersion(
      tourPlanId,
      state,
    );
    this.updateInCaches(tourPlan);
    return tourPlan;
  }

  public async deleteVisitForUser(
    visitId: string,
    version: number,
  ): Promise<void> {
    await this.service.deleteVisitForUser(visitId, version);
    this.deleteFromCaches(visitId);
  }

  public exchangeSearch(
    request: ITourPlanExchangeSearchRequest,
  ): Promise<TourPlanWithExchangeInfo[]> {
    return this.service.exchangeSearch(request);
  }

  public exchangeCheck(
    request: ITourPlanExchangeCheckRequest[],
  ): Promise<TourPlanExchangeInfo[]> {
    return this.service.exchangeCheck(request);
  }

  public exchange(request: ITourPlanExchangeRequest[]): Promise<string[]> {
    return this.service.exchange(request);
  }

  public async quickEdit(
    request: TourPlanQuickEditRequest,
  ): Promise<TourPlan[]> {
    const tourPlans = await this.service.quickEdit(request);
    tourPlans?.forEach(tp => this.updateInCaches(tp));
    return tourPlans;
  }

  public async unlockTourPlans(
    request: string | string[],
  ): Promise<TourPlan[]> {
    const tourPlanIds = Array.isArray(request) ? request : [request];
    const tourPlans = await this.service.unlockTourPlans(tourPlanIds);
    tourPlans?.forEach(tp => this.updateInCaches(tp));
    return tourPlans;
  }

  public async approveTourPlans(
    request: {tourPlanId: string; approved: boolean}[],
  ): Promise<TourPlan[]> {
    const tourPlans = await this.service.approveTourPlans(request);
    tourPlans?.forEach(tp => this.updateInCaches(tp));
    return tourPlans;
  }

  public async copyTourPlans(
    request: ITourPlanCopyRequest,
  ): Promise<{createdTourPlans: TourPlan[]; ignoredTourPlans?: TourPlan[]}> {
    const copyResult = await this.service.copyTourPlans(request);
    copyResult?.createdTourPlans.forEach(tp => this.updateInCaches(tp));
    return copyResult;
  }

  public async getCopyProgress(
    operationId: string,
  ): Promise<IProcessingProgress> {
    return this.service.getCopyProgress(operationId);
  }

  public async getCopyAvailability(): Promise<IProcessingProgress[]> {
    return this.service.getCopyAvailability();
  }

  public async groupReportAsXlsx(
    request: TourPlanSearchRequest,
  ): Promise<HttpResponse<Blob>> {
    return this.service.groupReportAsXlsx(request);
  }

  /**
   * (Wish for friends like in C++)
   * Adds newly created tour plans to all caches.
   * @param {TourPlan[]} tourPlans - tour plans to add
   * @param {TourPlanCaches} which - determines to which caches to add
   */
  public addToCaches(tourPlans: TourPlan[], which?: TourPlanCaches): void {
    if (this._cache.isValid && (!which || which?.cache)) {
      tourPlans.forEach(tp => this._cache.set(tp.id, tp));
    }

    if (this._cacheSearch.isValid && (!which || which?.cacheSearch)) {
      this._cacheSearch.push(tourPlans);
    }

    if (
      this._cacheSearchInLocation.isValid &&
      (!which || which?.cacheSearchInLocation)
    ) {
      this._cacheSearchInLocation.push(tourPlans);
    }
  }

  /**
   * (Wish for friends like in C++)
   * Updates tour plan in caches.
   * @param {TourPlan} tourPlan - tour plan to update
   * @param {TourPlanCaches} which - determines in which caches to update
   */
  public updateInCaches(tourPlan: TourPlan, which?: TourPlanCaches): void {
    if (this._cache.isValid && (!which || which?.cache)) {
      this._cache.set(tourPlan.id, tourPlan);
    }

    if (this._cacheSearch.isValid && (!which || which?.cacheSearch)) {
      const idx = this._cacheSearch.findIndex(tp => tp.id === tourPlan.id);
      if (idx >= 0) {
        this._cacheSearch.setAt(idx, tourPlan);
      }
    }

    if (
      this._cacheSearchInLocation.isValid &&
      (!which || which?.cacheSearchInLocation)
    ) {
      const idx = this._cacheSearchInLocation.findIndex(
        tp => tp.id === tourPlan.id,
      );
      if (idx >= 0) {
        this._cacheSearchInLocation.setAt(idx, tourPlan);
      }
    }
  }

  /**
   * (Wish for friends like in C++)
   * Deletes tour plan from all caches.
   * @param {string} id - id of the tour plan to delete
   * @param {TourPlanCaches} which - determines from which caches to delete
   */
  public deleteFromCaches(id: string, which?: TourPlanCaches): void {
    if (this._cache.isValid && (!which || which?.cache)) {
      this._cache.delete(id);
    }

    if (this._cacheSearch.isValid && (!which || which?.cacheSearch)) {
      const idx = this._cacheSearch.findIndex(tp => tp.id === id);
      if (idx >= 0) {
        this._cacheSearch.deleteAt(idx);
      }
    }

    if (
      this._cacheSearchInLocation.isValid &&
      (!which || which?.cacheSearchInLocation)
    ) {
      const idx = this._cacheSearchInLocation.findIndex(tp => tp.id === id);
      if (idx >= 0) {
        this._cacheSearchInLocation.deleteAt(idx);
      }
    }
  }
}
