import {Injectable} from '@angular/core';
import {ProductListingTemplate} from '../../models';
import {IProductListingTemplateDataService} from '../../interfaces/data-service';
import {BaseCrudOfflineService} from './base.offline-service';

@Injectable()
export class ProductListingTemplateOfflineService
  extends BaseCrudOfflineService<ProductListingTemplate>
  implements IProductListingTemplateDataService
{
  public createForProductListing(
    productListingId: string,
    productListingTemplate: ProductListingTemplate,
  ): Promise<void> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public overwriteExistingProductListingTemplate(
    productListingTemplateId: string,
    productListingId: string,
  ): Promise<void> {
    return Promise.reject('Offline service - not yet implemented');
  }
}
