import {Customer, CustomerWrapper, Position, PositionWrapper} from '../models';
import {TreeBase} from './tree-base';

/**
 * Helper class providing operations on the tree of positions.
 */
export class PositionTree extends TreeBase<Position> {
  /**
   * Constructor.
   * @param {Position[]} position - array of positions to construct tree from
   */
  constructor(position: Position[]) {
    super(position);
  }

  /**
   * Creates a PositionId to PositionWrapper Map.
   * @param {Customer} customer - customer to which the user belongs.
   * @returns {Map<string, PositionWrapper>} PositionId to PositionWrapper map.
   */
  createPositionWrapper(customer: Customer): Map<string, PositionWrapper> {
    let positionWrappersMap = new Map<string, PositionWrapper>();
    this.allItems.forEach(item => {
      positionWrappersMap.set(
        item.id,
        new PositionWrapper(
          item,
          customer ? new CustomerWrapper(customer) : undefined,
        ),
      );
    });

    positionWrappersMap.forEach(wrapper => {
      const position = this.findItem(wrapper.id);

      const childWrappers = [...positionWrappersMap.values()].filter(
        wrapper => wrapper.parentId === position.id,
      );
      wrapper.subordinates = childWrappers;

      if (!position.parentId) {
        wrapper.superior = null;
      } else {
        const parentItem = this.findItem(position.parentId);
        wrapper.superior = positionWrappersMap.get(parentItem.id);
      }
    });

    return positionWrappersMap;
  }
}
