<div class="form-header">
  <div class="form-header-info">
    <div class="form-header-name-section">
      <span class="form-header-name">
        {{'form-designer.form-name' | translate}}: {{ objectForm.name }}
      </span>
      <div class="form-header-actions">
        <i *ngIf="!readonlyMode"
           class="fa-solid fa-gear"
           (click)="onEditFormSettings()"></i>
      </div>

    </div>
    <div *ngIf="objectForm.default"
         class="form-header-positions">
      {{'form-designer.form-positions' | translate}}: {{ 'form-designer.form-positions-all' | translate }}
    </div>
    <div *ngIf="!objectForm.default"
         class="form-header-positions">
      {{'form-designer.form-positions' | translate}}:
      <span *ngIf="!objectForm.positionIds || !objectForm.positionIds.length; else positions"
            class="form-header-empty-positions">
        {{ 'form-designer.form-positions-empty' | translate }}
        <i class="fa-solid fa-triangle-exclamation"></i>
      </span>
      <ng-template #positions>
        <span *ngIf="objectForm.positionIds.length">
          {{ objectForm.positionIds | positionNames$ | async }}
        </span>
      </ng-template>
    </div>
  </div>
  <ng-container *ngIf="!readonlyMode">
    <ng-container *ngIf="validationState$ | async; let validationState">
      <div class="form-header-validation-state"
           [ngClass]="{
              'warning': validationState === 'invalid',
              'success': validationState === 'valid'
            }">
        <i *ngIf="validationState === 'invalid'"
           class="fa-solid fa-triangle-exclamation"></i>
        <i *ngIf="validationState === 'valid'"
           class="
      fa-solid fa-circle-check"></i>
        <div>
          {{ 'form-designer.validation-state.' + (validationState$ | async) | translate }}
        </div>

      </div>
    </ng-container>
  </ng-container>

  <!-- <div *ngIf="!objectForm.default"
       class="form-header-positions">Positions: {{ objectForm.positions }}</div> -->
</div>
<div class="container">
  <div class="content"
       cdkDropListGroup>
    <ng-container *ngIf="layout$| async; let layout">
      <div id="form-layout"
           class="form-layout  form-drop-list"
           cdkDropList
           cdkDropListConnectedTo="row-columns"
           [cdkDropListData]="layout"
           (cdkDropListDropped)="onFormLayoutDrop($event)">

        <ng-container *ngFor="let row of layout.rows ; trackBy: trackByFn">
          <div class="form-layout-row"
               [class.readonly]="readonlyMode"
               cdkDrag>
            <div class="delete"
                 (click)="onDeleteRow(row.id)">
              <i class="fa-solid fa-trash"></i>
            </div>
            <div class="form-layout-row-placeholder"
                 *cdkDragPlaceholder>
            </div>
            <ng-container *ngFor="let column of row.columns; trackBy: trackByFn">

              <div class="form-layout-row-column"
                   [class.warning-border]="(invalidRowColumnIds$ | async).includes(column.id)"
                   [id]="'row-column-' + column.id"
                   cdkDropList
                   [cdkDropListConnectedTo]="columnConnectedListsIds$ | async"
                   [cdkDropListData]="column"
                   (cdkDropListDropped)="onFormRowColumnDrop($event)">

                <div *ngIf="column?.group"
                     class="form-layout-row-column-group"
                     cdkDrag
                     [cdkDragData]="column.group">
                  <div class="group-layout-options-placeholder"
                       *cdkDragPlaceholder>
                  </div>
                  <div class="form-layout-row-column-group-header">
                    <div class="form-layout-row-column-group-title">
                      <i *ngIf="(invalidGroupIds$ | async).includes(column.group.id)"
                         class="fa-solid fa-triangle-exclamation warning"></i>
                      <div class="form-layout-row-column-group-title-text">
                        {{column.group.title}}
                      </div>
                    </div>
                    <div class="form-layout-row-column-group-actions">
                      <i class="fa-solid fa-gear"
                         (click)="onEditGroup(column.group)"></i>
                      <i class="fa-solid fa-trash"
                         (click)="onDeleteGroup(column.group.id)"></i>
                    </div>
                  </div>
                  <div class="form-layout-row-column-group-form-control-columns">
                    <ng-container *ngFor="let formControlColumn of column?.group.controlColumns; trackBy: trackByFn">
                      <div class="form-layout-row-column-group-form-control-column"
                           [class.warning-border]="(invalidControlColumnIds$ | async).includes(formControlColumn.id)"
                           [id]="'row-column-group-form-control-column-' + formControlColumn.id"
                           cdkDropList
                           [cdkDropListConnectedTo]="controlColumnConnectedListIds$ | async"
                           [cdkDropListData]="formControlColumn"
                           (cdkDropListDropped)="onFormRowColumnFormControlColumnDrop($event)">
                        <div *ngFor="let inputControl of formControlColumn.inputControls"
                             class="input-control"
                             cdkDrag
                             [cdkDragData]="inputControl">
                          <!-- placeholder -->
                          <div></div>
                          <app-form-designer-control [type]="inputControl.inputType"
                                                     [hint]="inputControl.hint"
                                                     [label]="inputControl.label"
                                                     [propertyId]="inputControl.propertyId"></app-form-designer-control>

                          <div class="input-control-actions">


                            <i class="fa-solid fa-gear"
                               (click)="onEditFormControl(inputControl)"></i>
                            <i class="fa-solid fa-trash"
                               (click)="onDeleteFormControl(inputControl.id)"></i>

                            <i *ngIf="inputControl.visibilityExpression"
                               class="info fa-solid fa-eye"
                               [title]="'form-designer.control-icons.visibility.title' | translate"></i>

                            <i *ngIf="inputControl.validationExpression"
                               class="info fa-solid fa-money-check-pen"
                               [title]="'form-designer.control-icons.validation.title' | translate"></i>

                            <i *ngIf="inputControl.readonlyExpression || isPropertySystemReadOnly(inputControl.propertyId)"
                               class="info fa-solid fa-glasses"
                               [title]="'form-designer.control-icons.readonly.title' | translate"></i>

                            <i *ngIf="inputControl.requiredExpression"
                               class="info fa-solid fa-asterisk"
                               [title]="'form-designer.control-icons.required.title' | translate"></i>

                            <i *ngIf="(invalidFormInputControlIds$ | async).includes(inputControl.id)"
                               class="fa-solid fa-triangle-exclamation warning visible-warning"></i>
                          </div>

                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>

              </div>
            </ng-container>
          </div>
        </ng-container>
      </div>

      <div *ngIf="!layout.rows.length"
           class="form-layout-info">
        Drag layout items from sidebar
      </div>

    </ng-container>
  </div>

  <div *ngIf="!readonlyMode"
       class="sidebar"
       cdkDropListGroup>
    <dx-tabs [dataSource]="tabs"
             [height]="50"
             [width]="'auto'"
             [keyExpr]="'id'"
             [selectedItemKeys]="[selectedTab]"
             (onItemClick)="onTabClick($event)"></dx-tabs>

    <ng-container [ngSwitch]="selectedTab">

      <div *ngSwitchCase="'layout'"
           class="sidebar-content">
        <div class="sidebar-header">
          {{ 'form-designer.row-layouts' | translate }}
        </div>
        <div id="row-columns"
             class="options"
             cdkDropList
             cdkDropListSortingDisabled
             [cdkDropListConnectedTo]="['form-layout']"
             [cdkDropListData]="rowLayouts"
             (cdkDropListEntered)="onDragListEntered()"
             (cdkDropListExited)="onDragListExited($event)"
             [cdkDropListEnterPredicate]="noReturnPredicate">
          <ng-container *ngFor="let rowLayout of rowLayouts; let rowIndex = index; trackBy: trackByFn">
            <div id="row-column"
                 class="row-layout-options"
                 cdkDrag
                 [cdkDragData]="rowLayout">
              <div class="row-layout-options-placeholder"
                   *cdkDragPlaceholder>
              </div>
              <div *ngFor="let _ of Array(rowLayout.numberOfColumns)"
                   class="square"></div>
            </div>
            <div *ngIf="draggedItem === rowLayout"
                 class="row-layout-options-placeholder">
            </div>
          </ng-container>
        </div>

        <div class="sidebar-header">
          {{ 'form-designer.group-layouts' | translate }}
        </div>

        <div id="group"
             class="options"
             cdkDropList
             cdkDropListSortingDisabled
             [cdkDropListConnectedTo]="rowColumnConnectedListsIds$ | async"
             [cdkDropListData]="groupLayouts"
             (cdkDropListEntered)="onDragListEntered()"
             (cdkDropListExited)="onDragListExited($event)"
             [cdkDropListEnterPredicate]="noReturnPredicate">
          <ng-container *ngFor="let groupLayout of groupLayouts; let rowIndex = index; trackBy: trackByFn">
            <div id="group-column"
                 class="group-layout-options"
                 cdkDrag
                 [cdkDragData]="groupLayout">
              <div class="group-layout-options-placeholder"
                   *cdkDragPlaceholder>
              </div>
              <app-skeleton width="30%"></app-skeleton>
              <div class="group-layout-options-columns">
                <div *ngFor="let _ of Array(groupLayout.numberOfColumns)"
                     class="square"></div>
              </div>
            </div>
            <div *ngIf="draggedItem === groupLayout"
                 class="group-layout-options-placeholder">
            </div>
          </ng-container>
        </div>
      </div>

      <div *ngSwitchCase="'prefilledFormControls'"
           class="sidebar-prefilled">
        <app-input-text [placeholder]="'search' | translate"
                        [value]="prefilledSearchValue$ | async"
                        (valueChange)="onSearchPrefilledFormControlsChange($event)"></app-input-text>
        <div class="sidebar-prefilled-content">
          <div id="form-controls"
               class="form-controls"
               cdkDropListSortingDisabled
               cdkDropList
               [cdkDropListConnectedTo]="rowColumnGroupFormControlColumnIds$ | async"
               (cdkDropListEntered)="onDragListEntered()"
               (cdkDropListExited)="onDragListExited($event)">

            <ng-container *ngFor="let formControl of prefilledSystemRequiredFormControls$ | async; trackBy: trackByFn">
              <div id="form-control"
                   cdkDrag
                   [cdkDragData]="formControl"
                   class="form-controls-option form-control-required">
                <div class="form-controls-option-placeholder"
                     *cdkDragPlaceholder>
                </div>
                <div class="form-controls-option-icon">
                  <i [class]="formControl.icon"></i>
                </div>
                <div class="form-controls-option-content">
                  <span class="form-controls-option-text">{{ formControl.name }}</span>
                  <div class="form-controls-option-details">
                    <i class="fa-solid fa-triangle-exclamation"></i>
                    system required
                  </div>
                </div>
              </div>
              <div *ngIf="draggedItem === formControl"
                   class="form-controls-option-placeholder">
              </div>
            </ng-container>

            <ng-container *ngFor="let formControl of prefilledFormControls$ | async; trackBy: trackByFn">
              <div id="form-control"
                   cdkDrag
                   [cdkDragData]="formControl"
                   class="form-controls-option">
                <div class="form-controls-option-placeholder"
                     *cdkDragPlaceholder>
                </div>
                <div class="form-controls-option-icon">
                  <i [class]="formControl.icon"></i>
                </div>
                <span class="form-controls-option-text">{{ formControl.name }}</span>
              </div>
              <div *ngIf="draggedItem === formControl"
                   class="form-controls-option-placeholder">
              </div>
            </ng-container>
          </div>
        </div>
      </div>

      <div *ngSwitchCase="'formControls'"
           class="sidebar-content">
        <div id="form-controls"
             class="form-controls"
             cdkDropListSortingDisabled
             cdkDropList
             [cdkDropListConnectedTo]="rowColumnGroupFormControlColumnIds$ | async"
             (cdkDropListEntered)="onDragListEntered()"
             (cdkDropListExited)="onDragListExited($event)">
          <ng-container *ngFor="let formControl of formControls; trackBy: trackByFn">
            <div id="form-control"
                 cdkDrag
                 [cdkDragData]="formControl"
                 class="form-controls-option">
              <div class="form-controls-option-placeholder"
                   *cdkDragPlaceholder>
              </div>
              <div class="form-controls-option-icon">
                <i [class]="formControl.icon"></i>
              </div>
              <span class="form-controls-option-text">{{ formControl.name }}</span>
            </div>
            <div *ngIf="draggedItem === formControl"
                 class="form-controls-option-placeholder">
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>
</div>