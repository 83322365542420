<app-user-management-user-profile-form *ngIf="forceProfileUpdate"
                                       #form
                                       [userProfile]="userProfile"
                                       [forceUpdate]="forceProfileUpdate"
                                       (actionCompleted)="actionCompleted.emit()"
                                       (cancel)="cancel.emit()"></app-user-management-user-profile-form>

<app-object-detail *ngIf="!forceProfileUpdate"
                   [tabs]="tabs"
                   [activeTabId]="activeTabId$ | async"
                   [tabsDisabled]="tabsDisabled$ | async"
                   (activeTabChanged)="onTabChange($event)">
  <ng-container *ngIf="!loading">
    <ng-container *ngIf="activeTabId$ | async; let activeTabId">
      <ng-container [ngSwitch]="activeTabId">

        <app-user-management-user-profile-form *ngSwitchCase="'userProfile'"
                                               #form
                                               [userProfile]="userProfile"
                                               (actionCompleted)="actionCompleted.emit()"
                                               (cancel)="cancel.emit()"></app-user-management-user-profile-form>

        <app-user-management-user-form *ngSwitchCase="'user'"
                                       #form
                                       [user]="user"
                                       (actionCompleted)="actionCompleted.emit()"
                                       (cancel)="cancel.emit()"></app-user-management-user-form>

        <div *ngSwitchCase="'attachments'"
             class="attachments">
          <app-object-attachments class="attachments-content"
                                  [objectId]="userProfile.id"
                                  [objectTypeId]="ObjectTypeIds.UserProfile"></app-object-attachments>
          <div class="attachments-footer">
            <app-form-actions #formActions
                              cancelIcon="fa fa-times"
                              [cancelLabel]="'cancel' | translate"
                              [cancelVisible]="true"
                              (cancel)="cancel.emit()"></app-form-actions>
          </div>
        </div>
      </ng-container>
    </ng-container>

  </ng-container>
</app-object-detail>