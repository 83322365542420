<!-- TODO: remove unused attributes -->
<app-dialog [title]="(isUpdate ? 'entity.attachment.dialog.update.title' : 'entity.attachment.dialog.create.title') | translate"
            [actionInProgress]=""
            [actionLabel]="'form.action.save' | translate"
            [actionDisabled]=""
            [actionVisible]="true"
            [actionIcon]=""
            [cancelLabel]="'form.action.close' | translate"
            [cancelDisabled]=""
            [cancelVisible]="true"
            [cancelIcon]=""
            [formGroup]="form"
            (cancelClicked)="onClose()"
            (actionClicked)="onSave()">
  <ng-container [formGroup]="form.controls.attachment">
    <app-form-row>
      <app-form-column>
        <app-input-text [formControl]="getAttachmentFormControl('notes')"
                        [formControlRef]="getAttachmentFormControl('notes')"
                        [label]="'entity.attachment.properties.notes.label' | translate"></app-input-text>
      </app-form-column>
    </app-form-row>
  </ng-container>
  <app-form-row *ngIf="!isUpdate">
    <app-form-column>
      <app-input-file [formControlRef]="getFormControl('fileData')"
                      [formControl]="getFormControl('fileData')"
                      [label]="'entity.attachment.properties.file-data.label' | translate"
                      [allowedFileTypes]="allowedFileTypes"
                      [initialFiles]="initialFiles"
                      (filesChange)="onFilesChange($event)"></app-input-file>
    </app-form-column>
  </app-form-row>

  <app-form-section *visibleIf="{
          role: [RoleNames.ObjectAttachmentsPermissions]
  }"
                    [title]="'entity.attachment.properties.permissions.label' | translate">
    <app-object-permissions-form-group [formGroup]="permissionsFromGroup"></app-object-permissions-form-group>
  </app-form-section>
</app-dialog>