import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {IBHSAggregatedDataService} from '../../interfaces/data-service';
import {BaseHttpService} from './base.http-service';

@Injectable()
export class BHSAggregatedDataHttpService
  extends BaseHttpService
  implements IBHSAggregatedDataService
{
  constructor(http: HttpClient) {
    super(http);
  }

  public getPersonalArrangements(
    userId: string,
    from?: Date,
    to?: Date,
  ): Promise<any> {
    const params = {};

    if (from) {
      params['from'] = JSON.stringify(from);
    }

    if (to) {
      params['to'] = JSON.stringify(to);
    }

    return this.GET<any>(
      `/bhs-aggregated-data/personal-arrangements/${userId}`,
      {params: params},
    );
  }

  public getPromoActions(userId: string, from?: Date, to?: Date): Promise<any> {
    const params = {};

    if (from) {
      params['from'] = JSON.stringify(from);
    }

    if (to) {
      params['to'] = JSON.stringify(to);
    }

    return this.GET<any>(`/bhs-aggregated-data/promo-actions/${userId}`, {
      params: params,
    });
  }
}
