import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseCrudHttpService} from './base.http-service';
import {KPI} from '../../models';
import {IKPIDataService} from '../../interfaces/data-service';

@Injectable()
export class KPIHttpService
  extends BaseCrudHttpService<KPI>
  implements IKPIDataService
{
  constructor(http: HttpClient) {
    super(http, '/kpis');
  }
}
