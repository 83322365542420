import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {DxListModule} from 'devextreme-angular/ui/list';
import {CustomPipesModule} from '../../pipes';
import {SimpleFileListComponent} from './simple-file-list.component';

@NgModule({
  imports: [CommonModule, DxListModule, CustomPipesModule],
  declarations: [SimpleFileListComponent],
  exports: [SimpleFileListComponent],
})
export class SimpleFileListModule {}
