import {Filter} from '@loopback/filter';
import {ObjectMapCache} from '@retrixhouse/salesapp-shared/lib/caching';
import {IProductListingDataService} from '../../interfaces/data-service';
import {
  ProductListing,
  ProductListingStore,
  ProductListingStoreRequest,
  ProjectProductListing,
} from '../../models';
import {ProductListingHttpService} from '../http';
import {ProductListingOfflineService} from '../offline';
import {BaseCrudDataService} from './base.data-service';
import {IProductListingCopy} from '@retrixhouse/salesapp-shared/lib/requests';
import {IProductListing} from '@retrixhouse/salesapp-shared/lib/models';
import {IProcessingProgress} from '@retrixhouse/salesapp-shared/lib/responses';

export class ProductListingDataService extends BaseCrudDataService<
  ProductListing,
  IProductListingDataService,
  ObjectMapCache<string, ProductListing>
> {
  constructor(
    onlineService: ProductListingHttpService,
    offlineService: ProductListingOfflineService,
    cache: ObjectMapCache<string, ProductListing>,
  ) {
    super(onlineService, offlineService, cache);
  }

  public getStoreList(filter: Filter<ProductListingStore>) {
    return this.service.getProductListingStoreList(filter);
  }

  public updateStore(productListingStoreRequest: ProductListingStoreRequest) {
    return this.service.updateProductListingStore(productListingStoreRequest);
  }

  public getActiveForProjects(
    projectIds: string[],
  ): Promise<ProjectProductListing[]> {
    return this.service.getActiveProductListingsForProjects(projectIds);
  }

  public async copyListing(
    request: IProductListingCopy,
  ): Promise<{createdProductListing: IProductListing}> {
    const copyResult = await this.service.copyListing(request);
    return copyResult;
  }

  public async getCopyProgress(
    operationId: string,
  ): Promise<IProcessingProgress> {
    return this.service.getCopyProgress(operationId);
  }

  public async getCopyAvailability(): Promise<IProcessingProgress[]> {
    return this.service.getCopyAvailability();
  }
}
