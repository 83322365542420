import {Injectable} from '@angular/core';
import {
  TourPlan,
  TourPlanExchangeInfo,
  TourPlanQuickEditRequest,
  TourPlanSearchRequest,
  TourPlanWithExchangeInfo,
} from '../../models';

import {
  ITourPlanExchangeCheckRequest,
  ITourPlanExchangeRequest,
  ITourPlanExchangeSearchRequest,
  ITourPlanCopyRequest,
} from '@retrixhouse/salesapp-shared/lib/requests';
import {IProcessingProgress} from '@retrixhouse/salesapp-shared/lib/responses';
import {TourPlanState} from '@retrixhouse/salesapp-shared/lib/models';
import {
  ITourPlanDataService,
  SearchInLocation,
} from '../../interfaces/data-service';
import {BaseCrudOfflineService} from './base.offline-service';
import {HttpResponse} from '@angular/common/http';

@Injectable()
export class TourPlanOfflineService
  extends BaseCrudOfflineService<TourPlan>
  implements ITourPlanDataService
{
  public updateIgnoreVersion(
    objectId: string,
    objectData: Partial<TourPlan>,
  ): Promise<TourPlan> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public async search(filter: TourPlanSearchRequest): Promise<TourPlan[]> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public bulkCreate(tourPlans: TourPlan[]): Promise<TourPlan[]> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public searchInLocation(search: SearchInLocation): Promise<TourPlan[]> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public isScheduledStartValid(
    scheduledStart: Date,
    projectId: string,
  ): Promise<boolean> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public updateStateIgnoreVersion(
    tourPlanId: string,
    state: TourPlanState,
  ): Promise<TourPlan> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public deleteVisitForUser(visitId: string, version: number): Promise<void> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public exchangeSearch(
    request: ITourPlanExchangeSearchRequest,
  ): Promise<TourPlanWithExchangeInfo[]> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public exchangeCheck(
    request: ITourPlanExchangeCheckRequest[],
  ): Promise<TourPlanExchangeInfo[]> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public exchange(request: ITourPlanExchangeRequest[]): Promise<string[]> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public quickEdit(request: TourPlanQuickEditRequest): Promise<TourPlan[]> {
    return Promise.reject('Offline service - not yet implemented');
  }

  unlockTourPlans(tourPlanIds: string[]): Promise<TourPlan[]> {
    return Promise.reject('Offline service - not yet implemented');
  }

  approveTourPlans(
    request: {tourPlanId: string; approved: boolean}[],
  ): Promise<TourPlan[]> {
    return Promise.reject('Offline service - not yet implemented');
  }

  copyTourPlans(
    request: ITourPlanCopyRequest,
  ): Promise<{createdTourPlans: TourPlan[]; ignoredTourPlans?: TourPlan[]}> {
    return Promise.reject('Offline service - not yet implemented');
  }

  getCopyProgress(operationId: string): Promise<IProcessingProgress> {
    return Promise.reject('Offline service - not yet implemented');
  }

  getCopyAvailability(): Promise<IProcessingProgress[]> {
    return Promise.reject('Offline service - not yet implemented');
  }

  groupReportAsXlsx(
    request: TourPlanSearchRequest,
  ): Promise<HttpResponse<Blob>> {
    return Promise.reject('Offline service - not yet implemented');
  }
}
