import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {FeedbackDetailKpiSet} from '../feedback-detail/feedback-detail.component';
import {trackByIndex} from '@salesapp/utils/angular.utils';
import {environment} from 'src/environments/environment';

@Component({
  selector: 'app-feedback-detail-item',
  templateUrl: './feedback-detail-item.component.html',
  styleUrls: ['./feedback-detail-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeedbackDetailItemComponent {
  @Input() kpiSet: FeedbackDetailKpiSet;
  @Output() openImage = new EventEmitter<{photos: any; index: number}>();

  trackByFn = trackByIndex;

  baseUrl = environment.frontEndBaseUrl;

  onImageClicked(photos: any, index: number) {
    this.openImage.emit({photos, index});
  }
}
