import {CommonModule} from '@angular/common';
import {DxTabsModule} from 'devextreme-angular';
import {DynamicFormModule} from '../dynamic-form';
import {FormModule} from '../form';
import {NgModule} from '@angular/core';
import {ObjectDetailComponent} from './components/object-detail/object-detail.component';
import {ObjectDetailDynamicFormComponent} from './components/object-detail-dynamic-form/object-detail-dynamic-form.component';
import {TranslateModule} from '@ngx-translate/core';

const SALESAPP_MODULES = [DynamicFormModule, FormModule];
const DX_MODULES = [DxTabsModule];

@NgModule({
  imports: [CommonModule, TranslateModule, ...SALESAPP_MODULES, ...DX_MODULES],
  declarations: [ObjectDetailComponent, ObjectDetailDynamicFormComponent],
  providers: [],
  exports: [ObjectDetailComponent, ObjectDetailDynamicFormComponent],
})
export class ObjectDetailModule {}

export * from './components/object-detail-dynamic-form/object-detail-dynamic-form.component';
export * from './components/object-detail/object-detail.component';
