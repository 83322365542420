<app-dialog [title]="'dialog.push-notification.title' | translate"
            [actionVisible]="false"
            [cancelLabel]="'form.action.close' | translate"
            [cancelVisible]="true"
            [noPadding]="true"
            (cancelClicked)="onClose()">
  <div dialogContent
       class="content">
    <div class="list">
      <dx-list [items]="notifications$ | async"
               height="100%"
               width="100%"
               [keyExpr]="'value'">
        <div *dxTemplate="let item of 'item'"
             class="item"
             [class.highlighted]="!item.read">
          <div class="notification"
               (click)="onNotificationClick(item)">
            <div class="notification-left">
              <div class="notification-marker"
                   [class.notification-marker-visible]="!item.read"></div>
            </div>
            <div class="notification-body">
              <div class="notification-header">
                <div class="notification-title">{{ item.title }}</div>
                <div class="date">
                  {{ item.sentAt | dateFormat:'L LT' }}
                </div>
              </div>
              <div class="notification-message">{{ item.message }}</div>
            </div>
            <div>
            </div>
          </div>
        </div>
      </dx-list>
    </div>
  </div>
</app-dialog>