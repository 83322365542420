<app-dialog
  *ngIf="!(loading$ | async)"
  [title]="'captions.news-post-basic' | translate"
  [actionInProgress]="false"
  [actionLabel]="'form.action.save' | translate"
  [actionDisabled]="disableAction$ | async"
  [actionVisible]="true"
  [cancelLabel]="'form.action.close' | translate"
  [cancelDisabled]="disableAction$ | async"
  [cancelVisible]="true"
  [formGroup]="form"
  (cancelClicked)="onClose()"
  (actionClicked)="onSave()"
>
  <app-form-row>
    <app-form-column>
      <app-input-text [formControl]="getFormControl('uid')" [formControlRef]="getFormControl('uid')" [label]="'entity.news-post.properties.uid.label' | translate"></app-input-text>
    </app-form-column>
    <app-form-column>
      <app-input-date [formControl]="getFormControl('validUntil')" [label]="'entity.news-post.properties.valid-until.label' | translate"> </app-input-date>
    </app-form-column>
  </app-form-row>
  <app-form-row>
    <app-form-column>
      <app-input-text
        [formControl]="getFormControl('caption')"
        [formControlRef]="getFormControl('caption')"
        [label]="'entity.news-post.properties.caption.label' | translate"
      ></app-input-text>
    </app-form-column>
  </app-form-row>
  <app-form-row>
    <app-form-column>
      <app-input-switch
        [formControl]="getFormControl('important')"
        [formControlRef]="getFormControl('important')"
        [label]="'entity.news-post.properties.important.label' | translate"
      ></app-input-switch>
    </app-form-column>
    <app-form-column>
      <app-input-switch
        [formControl]="getFormControl('published')"
        [formControlRef]="getFormControl('published')"
        [label]="'entity.news-post.properties.published.label' | translate"
      ></app-input-switch>
    </app-form-column>
  </app-form-row>
  <app-form-row>
    <app-form-column>
      <app-input-multiselect
        [required]="true"
        [formControl]="getFormControl('projectIds')"
        [formControlRef]="getFormControl('projectIds')"
        [label]="'entity.news-post.properties.projects.label' | translate"
        [options]="projectOptions$ | async"
      ></app-input-multiselect>
    </app-form-column>
  </app-form-row>
  <app-form-row>
    <app-form-column>
      <app-input-multiselect
        [formControl]="getFormControl('chainIds')"
        [formControlRef]="getFormControl('chainIds')"
        [label]="'entity.news-post.properties.chains.label' | translate"
        [options]="chainOptions$ | async"
      ></app-input-multiselect>
    </app-form-column>
  </app-form-row>
  <app-form-row>
    <app-form-column>
      <app-input-multiselect
        [formControl]="getFormControl('storeIds')"
        [formControlRef]="getFormControl('storeIds')"
        [label]="'entity.news-post.properties.stores.label' | translate"
        [options]="storeOptions$ | async"
      ></app-input-multiselect>
    </app-form-column>
  </app-form-row>
  <app-form-row>
    <app-form-column>
      <app-input-multiselect
        [formControl]="getFormControl('positionIds')"
        [formControlRef]="getFormControl('positionIds')"
        [label]="'entity.news-post.properties.positions.label' | translate"
        [options]="positionOptions$ | async"
      ></app-input-multiselect>
    </app-form-column>
  </app-form-row>
  <app-form-row>
    <app-form-column>
      <app-input-multiselect
        [formControl]="getFormControl('userIds')"
        [formControlRef]="getFormControl('userIds')"
        [label]="'entity.news-post.properties.users.label' | translate"
        [options]="userOptions$ | async"
      ></app-input-multiselect>
    </app-form-column>
  </app-form-row>
  <app-form-row>
    <app-form-column>
      <app-input-rich-text
        height="400"
        [formControl]="getFormControl('body')"
        [formControlRef]="getFormControl('body')"
        [label]="'entity.news-post.properties.body.label' | translate"
      >
      </app-input-rich-text>
    </app-form-column>
  </app-form-row>
</app-dialog>
