<app-dialog [title]="'entity.user-management.dialog.reset-password.title' | translate"
            [actionLabel]="'form.action.save' | translate"
            [actionVisible]="true"
            [cancelLabel]="'form.action.close' | translate"
            [cancelVisible]="true"
            [formGroup]="form"
            (cancelClicked)="onClose()"
            (actionClicked)="onSave()">
  <app-form-row>
    <dx-button type="default"
               icon="fa-solid fa-shuffle"
               [text]="'entity.user-management.button.generate-password' | translate"
               (onClick)="onGeneratePassword()"></dx-button>

  </app-form-row>
  <app-notice [title]="'entity.user-management.dialog.reset-password.notice.title' | translate"
              [description]="'entity.user-management.dialog.reset-password.notice.description' | translate"
              type="warning"></app-notice>
  <app-form-row>
    <app-form-column>
      <app-input-text [formControl]="getFormControl('password')"
                      [formControlRef]="getFormControl('password')"
                      [placeholder]="'entity.user-management.temporary-password.placeholder' | translate"></app-input-text>
    </app-form-column>
  </app-form-row>
</app-dialog>