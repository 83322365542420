import {Injectable} from '@angular/core';
import {
  OldBaseStorageService,
  StorageDataStateEnum,
} from './old-base-storage-service';
import {DataProvider} from '../../data.provider/data-provider';
import {ILocalizedValue} from '@retrixhouse/salesapp-shared/lib/models';
import {LocalizedValue} from '../../models';

// TODO(milan): change localized interface for ILocalizedValue
@Injectable()
export class I18nStorageService extends OldBaseStorageService<
  LocalizedValue,
  LocalizedValue[]
> {
  constructor(public dataProvider: DataProvider) {
    super(dataProvider.i18N);
  }

  deleteMultiple(input: {id: string; version?: number}[]) {
    // TODO(milan): change this code after discussion with Marek Implement bulk delete on BE
    Promise.all(
      input.map(product => {
        return this.dataProvider.i18N.delete(product.id, product.version);
      }),
    ).then(response => {
      this.updateDataState(storage => ({
        state: StorageDataStateEnum.Obsoleted,
      }));
    });
  }
}
