<app-img-popup [imgTemplate]="imgTemplate"
               [visible]="visible"
               (onHidden)="onPopupHidden($event)"
               (onShown)="onFullScreenPopupShown($event)">
  <i id="action-icon"
     (click)="showImgActionList()"
     class="fas fa-ellipsis-v"></i>
</app-img-popup>

<ng-template #imgTemplate>
  <dx-gallery #gallery
              height="100%"
              width="100%"
              [dataSource]="photos ?? []"
              [loop]="false"
              [showNavButtons]="true"
              [showIndicator]="false"
              [selectedIndex]="selectedPhotoIndex"
              [animationEnabled]="true"
              (keydown.escape)="onGalleryEscapeBtn()"
              (onSelectionChanged)="onCurrentImgChanged($event)">
    <div *dxTemplate="let photo of 'item'">
      <pinch-zoom style="height: 100%">
        <img [src]="photo?.uri" />
      </pinch-zoom>
    </div>
  </dx-gallery>
</ng-template>

<!-- START: The action sheet buttons are displayed only on small screens -->
<dx-action-sheet *ngIf="isSmallScreen"
                 #imgActionSheet
                 [title]="'img-gallery.select-action' | translate"
                 [items]="imgActions$ | async"
                 [visible]="false"
                 [showTitle]="true"
                 [showCancelButton]="true"
                 (onItemClick)="showImageRelationPopup($event)">
</dx-action-sheet>
<!-- END: The action sheet buttons are displayed only on small screens -->

<!-- START: The popup has a dynamic content and it is used to show the comments, tags and feedbacks. -->
<dx-popup #actionsPopup
          [showTitle]="true"
          [dragEnabled]="false"
          [showCloseButton]="false"
          container=".dx-viewport"
          [fullScreen]="isSmallScreen"
          [contentTemplate]="(viewState$ | async)?.popupContent">
  <dxi-toolbar-item [text]="(viewState$ | async)?.popupHeader"
                    location="before"> </dxi-toolbar-item>
  <dxi-toolbar-item widget="dxButton"
                    location="after"
                    [options]="{
      text: 'buttons.ok' | translate | uppercase,
      onClick: hidePopup
    }">
  </dxi-toolbar-item>

  <div *dxTemplate="let data of 'commentContent'">
    <dx-scroll-view width="100%"
                    height="100%">
      <app-comment [objectId]="(viewState$ | async)?.currentImg?.id"
                   [commentList]="(viewState$ | async)?.currentImg?.commentList"
                   (onCommentDeleted)="commentDeleted($event)"
                   (onCommentAdded)="commentAdded($event)"></app-comment>
    </dx-scroll-view>
  </div>

  <div *dxTemplate="let data of 'tagsContent'">
    <dx-scroll-view width="100%"
                    height="100%">
      <app-tag [tags]="tags"
               [tagProperties]="photoTagRules"
               [selectedTags]="(viewState$ | async)?.currentPhotoTags"
               (onSelectionChanged)="tagsChanged($event)"></app-tag>
    </dx-scroll-view>
  </div>

  <div *dxTemplate="let data of 'feedbackContent'">
    <dx-scroll-view width="100%"
                    height="100%">
      <app-feedback-edit [userId]="userProfile?.userId"
                         [tourPlanId]="tourPlanId"
                         [userKPISets]="myKPISets"
                         [feedback]="myFeedback"
                         (onSaved)="feedbackSaved($event)"
                         (onDeleted)="feedbackDeleted($event)"
                         (onCanceled)="feedbackCanceled($event)"></app-feedback-edit>
    </dx-scroll-view>
  </div>
</dx-popup>
<!-- START: The popup has a dynamic content and it is used to show the comments, tags and feedbacks. -->

<!-- START: The context menu is displayed only on large screens -->
<dx-context-menu *ngIf="!isSmallScreen"
                 showEvent="dxcontextmenu click"
                 [dataSource]="imgActions$ | async"
                 [width]="200"
                 target="#action-icon"
                 (onItemClick)="showImageRelationPopup($event)">
</dx-context-menu>
<!-- START: The context menu is displayed only on large screens -->