<ng-container *ngIf="filteredActions$ | async; let filteredActions">
  <ng-container *ngIf="filteredActions?.length === 1">
    <div class="icon-button"
         (click)="onItemClick(filteredActions[0])">
      <i [class]="filteredActions[0].icon"></i>
    </div>
  </ng-container>

  <ng-container *ngIf="filteredActions?.length > 1">
    <dx-drop-down-button [dropDownOptions]="{ width: 230 }"
                         [items]="filteredActions"
                         displayExpr="text"
                         keyExpr="id"
                         (onItemClick)="onItemClick($event.itemData)"></dx-drop-down-button>
  </ng-container>
</ng-container>