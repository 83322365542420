import {
  IObjectType,
  IObjectTypeWithRelations,
} from '@retrixhouse/salesapp-shared/lib/models';
import {DataProvider} from '../../data.provider/data-provider';
import {Injectable} from '@angular/core';
import {OldBaseStorageService} from './old-base-storage-service';
import {map, share} from 'rxjs/operators';
import {Filter} from '@loopback/filter';
import {AnyObject} from '@loopback/filter/dist/types';
import {Observable, from} from 'rxjs';

@Injectable()
export class ObjectTypeStorageService extends OldBaseStorageService<
  IObjectTypeWithRelations,
  IObjectTypeWithRelations[]
> {
  constructor(private dataProvider: DataProvider) {
    super(dataProvider.object);
  }

  getData(input?: {
    filter?: Filter<AnyObject>;
  }): Observable<IObjectTypeWithRelations[]> {
    return from(this.dataProvider.object.getObjectTypes()) as Observable<any>;
  }

  getObjectTypeById(objectTypeId: string) {
    return this.data$.pipe(
      map(objectTypes => {
        if (objectTypes) {
          return objectTypes.find(objectType => objectType.id === objectTypeId);
        }

        return null;
      }),
      share(),
    );
  }
}
