import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {IGlobalSearchDataService} from '../../interfaces/data-service';
import {BaseHttpService} from './base.http-service';

@Injectable()
export class GlobalSearchHttpService
  extends BaseHttpService
  implements IGlobalSearchDataService
{
  constructor(http: HttpClient) {
    super(http);
  }

  public globalSearch(searchString: string): Promise<[]> {
    return this.GET('/core/global-search', {
      params: {searchString: searchString},
    });
  }
}
