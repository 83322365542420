<ng-container *ngIf="!isValueEmpty; else empty">
  <ng-container [ngSwitch]="property.valueType">
    <span *ngSwitchCase="ValueType.Boolean">
      <i *ngIf="value"
         class="fa-solid fa-circle-check"></i>
      <i *ngIf="!value"
         class="fa-solid fa-circle-x"></i>
    </span>
    <span *ngSwitchCase="ValueType.Date">{{ value | dateFormat : 'LL' }}</span>
    <span *ngSwitchCase="ValueType.DateTime">{{ value | dateFormat : 'LLL' }}</span>
    <span *ngSwitchCase="ValueType.Enum">{{ enumTranslationMarker | translate }}</span>
    <div *ngSwitchCase="ValueType.RelationToManyData"
         class="relation-to-many">
      <ng-container *ngIf="value.length; else empty">

        <ng-container *ngIf="unavailableCount < value.length; else unavailableContent">
          <ng-container *ngTemplateOutlet="relatedObject; context: {$implicit: relationToManyValue[0]}"></ng-container>
          <div *ngIf="value.length > 1"
               class="counter"
               [id]="'pvr-' + uuid">+{{ value.length - 1 }}</div>
          <dx-popover *ngIf="value.length > 1"
                      [target]="'#pvr-' + uuid"
                      showEvent="dxclick"
                      [width]="320">
            <div *ngFor="let relatedObjectValue of relationToManyValue; trackBy: trackByIndex"
                 class="relation-object">
              <ng-container *ngTemplateOutlet="relatedObject; context: {$implicit: relatedObjectValue}"></ng-container>
            </div>
            <ng-container *ngIf="!!unavailableCount">
              <div class="ellipsis unavailable-content border">
                {{ 'property-value-unavailable' | translate: { count: unavailableCount } }}
              </div>
            </ng-container>
          </dx-popover>
        </ng-container>

        <ng-template #unavailableContent>
          <span class="unavailable-content">
            {{ 'property-value-unavailable' | translate: { count: unavailableCount } }}
          </span>
        </ng-template>

      </ng-container>
    </div>
    <div *ngSwitchCase="ValueType.RelationToOneData">
      <ng-container *ngTemplateOutlet="relatedObject; context: {$implicit: value}"></ng-container>
    </div>
    <span *ngSwitchCase="ValueType.RichText"
          class="rich-text"
          [innerHtml]="value | safeHtml"></span>
    <ng-container *ngSwitchCase="ValueType.SingleChoice">
      <ng-container *ngIf="getGenericListItem(value) | async; let item">
        <ng-container *ngTemplateOutlet="selectItem; context: {$implicit: item}"></ng-container>
      </ng-container>
    </ng-container>
    <div *ngSwitchCase="ValueType.MultiChoice"
         class="multiselect">
      <ng-container *ngFor="let item of getGenericListItems(value) | async; trackBy: trackByIndex">
        <ng-container *ngTemplateOutlet="selectItem; context: {$implicit: item}"></ng-container>
      </ng-container>
    </div>
    <span *ngSwitchDefault>{{ value }}</span>
  </ng-container>
</ng-container>

<ng-template #empty>
  <span class="empty">
    {{ 'property-value-empty' | translate }}
  </span>
</ng-template>

<ng-template #relatedObject
             let-data>
  <ng-container [ngSwitch]="property.objectTypeId">
    <app-user-avatar *ngSwitchCase="ObjectTypeIds.UserProfile"
                     [firstName]="data.firstName"
                     [userUid]="data.uid"
                     [lastName]="data.lastName"
                     [picture]="data.profilePicture"
                     [userUid]="data.uid"
                     [position]="data.positionAbbreviation"
                     [username]="data.username"></app-user-avatar>
    <app-project-avatar *ngSwitchCase="ObjectTypeIds.Project"
                        [project]="data"></app-project-avatar>
    <app-store-avatar *ngSwitchCase="ObjectTypeIds.Store"
                      [store]="data"></app-store-avatar>
    <ng-container *ngSwitchDefault>
      <div *ngIf="data?.name"
           class="ellipsis">{{ data.name }}</div>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #selectItem
             let-data>
  <span class="select-item"
        [class.inactive]="!data.visible">
    <i *ngIf="!data.visible"
       class="fa-solid fa-square-exclamation"></i>{{data.name}}</span>
</ng-template>