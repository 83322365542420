import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-skeleton',
  templateUrl: './skeleton.component.html',
  styleUrls: ['./skeleton.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SkeletonComponent {
  @Input() shape: 'circle' | 'rectangle';
  @Input() animation: 'wave' | 'none' = 'none';
  @Input() borderRadius: string | null = null;
  @Input() size: string | null = null;
  @Input() width: string = '100%';
  @Input() height: string = '1rem';

  get cssClasses() {
    return {
      skeleton: true,
      'skeleton-circle': this.shape === 'circle',
      'skeleton-without-animation': this.animation === 'none',
    };
  }

  get cssStyle() {
    if (this.size) {
      return {
        width: this.size,
        height: this.size,
        borderRadius: this.borderRadius,
      };
    } else {
      return {
        width: this.width,
        height: this.height,
        borderRadius: this.borderRadius,
      };
    }
  }
  constructor() {}
}
