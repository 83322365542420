import * as uuid from 'uuid';
import {arrayToMap} from '@salesapp/utils/utils';
import {AutoUnsubscribe} from '@salesapp/utils/angular.utils';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {filter, map, tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {NotificationService} from '@salesapp/services';
import {ObjectStorageService} from '@salesapp/storage';
import {ObjectTypeIds} from '@retrixhouse/salesapp-shared/lib/common';
import {TranslateService} from '@ngx-translate/core';
import {
  IObjectForm,
  IObjectFormGroup,
  IObjectFormInputControl,
  IObjectFormInputControlColumn,
  IObjectFormLocationControlSetting,
  IObjectFormRow,
  IObjectFormRowColumn,
  IObjectFormSchema,
  IObjectProperty,
  ObjectFormInputControlTypeEnum,
} from '@retrixhouse/salesapp-shared/lib/models';

@Injectable()
@AutoUnsubscribe()
export class FormLayoutService {
  allPropertiesById: Map<string, IObjectProperty>;

  layout$: Observable<IObjectFormSchema>;

  availableProperties$: Observable<IObjectProperty[]>;
  allProperties$: Observable<IObjectProperty[]>;
  propertiesById$: Observable<Map<string, IObjectProperty>>;

  private _objectTypeId: ObjectTypeIds;
  private _layout$ = new BehaviorSubject<IObjectFormSchema>(null);

  get layout() {
    return this._layout$.getValue();
  }

  get objectTypeId() {
    return this._objectTypeId;
  }

  constructor(
    private translateService: TranslateService,
    private objectStorageService: ObjectStorageService,
  ) {
    this.layout$ = this._layout$.asObservable();
  }

  init(formSchema: IObjectFormSchema, objectTypeId: ObjectTypeIds) {
    this._objectTypeId = objectTypeId;
    this._layout$.next(formSchema);
    this.initProperties(objectTypeId);
  }

  destroy() {
    this._layout$.next(null);
  }

  // ROWS START
  createRow(rowLayout: RowLayout): IObjectFormRow {
    const columns: IObjectFormRowColumn[] = [];

    for (let index = 0; index < rowLayout.numberOfColumns; index++) {
      columns.push({
        group: null,
        id: uuid.v4(),
      });
    }

    const row: IObjectFormRow = {
      id: uuid.v4(),
      columns,
    };

    return row;
  }

  updateRows(rows: IObjectFormRow[]) {
    this._layout$.next({
      ...this.layout,
      rows,
    });
  }

  deleteRow(rowId: string) {
    const newRows = this.layout.rows.filter(row => row.id !== rowId);
    this.updateRows(newRows);
  }

  addRow(props: {data: RowLayout; addToIndex: number}) {
    const rows = this.layout.rows;
    const newRow = this.createRow(props.data);

    rows.splice(props.addToIndex, 0, newRow);

    this.updateRows(rows);
  }

  reorderRows(props: {currentIndex: number; previousIndex: number}) {
    const rows = this.layout.rows;
    moveItemInArray(rows, props.previousIndex, props.currentIndex);
    this.updateRows(rows);
  }
  // ROWS END

  // ROW COLUMN START
  updateRowColumn(props: {
    rowId: string;
    columnId: string;
    group: IObjectFormGroup;
    previousColumnId?: string;
    previousRowId?: string;
    groupToReplace?: any;
  }) {
    const {
      rowId,
      columnId,
      group,
      previousColumnId,
      previousRowId,
      groupToReplace,
    } = props;
    const newRows = this.layout.rows.map(row => {
      if (row.id === rowId) {
        return {
          ...row,
          columns: row.columns.map(column => {
            if (column.id === columnId) {
              return {
                ...column,
                group,
              };
            }
            if (column.id === previousColumnId) {
              return {
                ...column,
                group: groupToReplace ? groupToReplace : null,
              };
            }
            return column;
          }),
        };
      }

      if (row.id === previousRowId) {
        return {
          ...row,
          columns: row.columns.map(column => {
            if (column.id === previousColumnId) {
              return {
                ...column,
                group: groupToReplace ? groupToReplace : null,
              };
            }
            return column;
          }),
        };
      }
      return row;
    });
    this.updateRows(newRows);
  }
  // ROW COLUMN END

  // GROUP START
  createGroup(groupLayout: GroupLayout): IObjectFormGroup {
    const columns: IObjectFormInputControlColumn[] = [];

    for (let index = 0; index < groupLayout.numberOfColumns; index++) {
      columns.push({
        inputControls: [],
        id: uuid.v4(),
      });
    }

    return {
      id: uuid.v4(),
      title: this.translateService.instant('form-designer.new-group-title'),
      controlColumns: columns,
      visibilityExpression: null,
    };
  }

  updateGroup(group: Omit<IObjectFormGroup, 'controlColumns'>) {
    const newRows = this.layout.rows.map(row => {
      return {
        ...row,
        columns: row.columns.map(column => {
          if (column.group?.id === group.id) {
            return {
              ...column,
              group: {
                ...column.group,
                ...group,
              },
            };
          }

          return column;
        }),
      };
    });
    this.updateRows(newRows);
  }

  deleteGroup(groupId: string) {
    const newRows = this.layout.rows.map(row => {
      return {
        ...row,
        columns: row.columns.map(column => {
          if (column.group?.id === groupId) {
            return {
              ...column,
              group: null,
            };
          }

          return column;
        }),
      };
    });
    this.updateRows(newRows);
  }

  deleteGroupFromRowColumn(groupId: string) {
    const newRows = this.layout.rows.map(row => {
      return {
        ...row,
        columns: row.columns.map(column => {
          if (column.group?.id === groupId) {
            return {
              ...column,
              group: null,
            };
          }

          return column;
        }),
      };
    });
    this.updateRows(newRows);
  }

  findGroupParents(groupId: string) {
    const rows = this.layout.rows;

    let row: IObjectFormRow;
    let rowColumn: IObjectFormRowColumn;

    rows.some(row => {
      let match = false;

      row.columns.some(column => {
        if (column.group.id === groupId) {
          row = row;
          rowColumn = column;
          match = true;
          return true;
        }
      });

      if (match) {
        return true;
      }
    });

    return {
      row,
      rowColumn,
    };
  }

  addGroupToRowColumn(props: {group: GroupLayout; columnId: string}) {
    const rows = this.layout.rows;

    let rowId;
    let columnId;
    let selectedColumn;

    rows.forEach(row => {
      const column = row.columns.find(
        column => `row-column-${column.id}` === props.columnId,
      );
      if (column) {
        rowId = row.id;
        columnId = column.id;
        selectedColumn = column;
      }
    });

    if (!!selectedColumn.group) {
      NotificationService.notifyError(
        this.translateService.instant('form-designer.column-has-group-message'),
      );
    } else {
      this.updateRowColumn({
        rowId,
        columnId,
        group: this.createGroup(props.group),
      });
    }
  }

  // TODO(milan): change input of this method later
  moveGroup(event: CdkDragDrop<any, any, any>) {
    const row = this.layout.rows.find(row => {
      return row.columns.find(column => column.id === event.container.data.id);
    });

    const previousRow = this.layout.rows.find(row => {
      return row.columns.find(
        column => column.id === event.previousContainer.data.id,
      );
    });

    const column = row.columns.find(
      column => column.id === event.container.data.id,
    );

    const groupToMove = event.item.data;

    if (!column.group) {
      this.updateRowColumn({
        rowId: row.id,
        columnId: column.id,
        previousColumnId: event.previousContainer.data.id,
        previousRowId: previousRow.id,
        group: groupToMove as any,
      });
    } else {
      this.updateRowColumn({
        rowId: row.id,
        columnId: column.id,
        previousColumnId: event.previousContainer.data.id,
        previousRowId: previousRow.id,
        group: groupToMove as any,
        groupToReplace: column.group,
      });
    }
  }
  // GROUP END

  // FORM CONTROL START
  createFormControl(props: {
    inputType: ObjectFormInputControlTypeEnum;
    propertyId?: string;
  }): IObjectFormInputControl {
    return {
      id: uuid.v4(),
      label: null,
      hint: null,
      propertyId: props.propertyId ?? null,
      inputType: props.inputType,
      visibilityExpression: null,
      validationExpression: null,
      requiredExpression: null,
      readonlyExpression: null,
    };
  }

  updateFormControl(control: IObjectFormInputControl) {
    this.processFormControlAction({action: 'update', control});
  }

  deleteFormControl(controlId: string) {
    this.processFormControlAction({
      action: 'delete',
      control: {id: controlId} as IObjectFormInputControl,
    });
  }

  moveFormControl(props: {
    previousControlColumnId: string;
    currentControlColumnId: string;
    previousIndex: number;
    currentIndex: number;
    formControl: IObjectFormInputControl;
  }) {
    const currentParents = this.findFormControlColumnParents(
      props.currentControlColumnId,
    );

    let updatedRows;

    if (props.previousControlColumnId === props.currentControlColumnId) {
      updatedRows = this.layout.rows.map(row => {
        if (row.id === currentParents.row.id) {
          return {
            ...row,
            columns: row.columns.map(column => {
              if (column.id === currentParents.rowColumn.id) {
                return {
                  ...column,
                  group: {
                    ...column.group,
                    controlColumns: column.group.controlColumns.map(
                      controlColumn => {
                        if (
                          controlColumn.id ===
                          currentParents.groupControlColumn.id
                        ) {
                          const inputControls = controlColumn.inputControls;
                          moveItemInArray(
                            inputControls,
                            props.previousIndex,
                            props.currentIndex,
                          );
                          return {
                            ...controlColumn,
                            inputControls,
                          };
                        }
                        return controlColumn;
                      },
                    ),
                  },
                };
              }

              return column;
            }),
          };
        }

        return row;
      });
    } else {
      const previousParents = this.findFormControlColumnParents(
        props.previousControlColumnId,
      );
      updatedRows = this.layout.rows.map(row => {
        if ([currentParents.row.id, previousParents.row.id].includes(row.id)) {
          return {
            ...row,
            columns: row.columns.map(column => {
              if (
                [
                  currentParents.rowColumn.id,
                  previousParents.rowColumn.id,
                ].includes(column.id)
              ) {
                return {
                  ...column,
                  group: {
                    ...column.group,
                    controlColumns: column.group.controlColumns.map(
                      controlColumn => {
                        if (
                          controlColumn.id ===
                          currentParents.groupControlColumn.id
                        ) {
                          const inputControls = controlColumn.inputControls;
                          inputControls.splice(
                            props.currentIndex,
                            0,
                            props.formControl,
                          );
                          return {
                            ...controlColumn,
                            inputControls,
                          };
                        }

                        if (
                          controlColumn.id ===
                          previousParents.groupControlColumn.id
                        ) {
                          return {
                            ...controlColumn,
                            inputControls: controlColumn.inputControls.filter(
                              control => control.id !== props.formControl.id,
                            ),
                          };
                        }
                        return controlColumn;
                      },
                    ),
                  },
                };
              }

              return column;
            }),
          };
        }
        return row;
      });
    }

    this.updateRows(updatedRows);
  }

  updateGroupInputControlColumn(props: {
    rowId: string;
    previousRowId: string;
    group: IObjectFormGroup;
  }) {
    const newRows = this.layout.rows.map(row => {});
  }

  findFormControlColumnParents(controlColumnId: string) {
    const rows = [...this.layout.rows];

    let selectedRow: IObjectFormRow;
    let rowColumn: IObjectFormRowColumn;
    let groupControlColumn: IObjectFormInputControlColumn;
    rows.some(row => {
      let match = false;
      row.columns.some(column => {
        const controlColumn = column.group?.controlColumns.find(
          controlColumn => controlColumn.id === controlColumnId,
        );

        if (controlColumn) {
          selectedRow = row;
          rowColumn = column;
          groupControlColumn = controlColumn;
          match = true;
          return true;
        }
      });

      return match;
    });

    return {
      row: selectedRow,
      rowColumn,
      groupControlColumn,
    };
  }

  findInputControlParents(controlId: string) {
    const rows = [...this.layout.rows];

    let selectedRow: IObjectFormRow;
    let rowColumn: IObjectFormRowColumn;
    let groupControlColumn: IObjectFormInputControlColumn;
    let selectedControl: IObjectFormInputControl;

    rows.some(row => {
      let match = false;
      row.columns.some(column => {
        column.group.controlColumns.some(controlColumn => {
          const control = controlColumn.inputControls.find(
            control => control.id === controlId,
          );

          if (control) {
            if (controlColumn) {
              selectedRow = row;
              rowColumn = column;
              groupControlColumn = controlColumn;
              selectedControl = control;
              match = true;
              return true;
            }
          }
        });

        return match;
      });

      return match;
    });

    return {
      row: selectedRow,
      rowColumn,
      groupControlColumn,
      control: selectedControl,
    };
  }

  addFormControlToGroupColumn(props: {
    controlColumnId: string;
    addToIndex: number;
    formControl: FormControlOption;
  }) {
    const {row, rowColumn, groupControlColumn} =
      this.findFormControlColumnParents(props.controlColumnId);

    const inputControls = groupControlColumn.inputControls;
    inputControls.splice(
      props.addToIndex,
      0,
      this.createFormControl({
        inputType: props.formControl.type,
        propertyId: props.formControl.propertyId,
      }),
    );

    const updatedGroup: IObjectFormGroup = {
      ...rowColumn.group,
      controlColumns: rowColumn.group.controlColumns.map(controlColumn => {
        if (controlColumn.id === groupControlColumn.id) {
          return {
            ...controlColumn,
            inputControls,
          };
        }

        return controlColumn;
      }),
    };

    this.updateRowColumn({
      rowId: row.id,
      columnId: rowColumn.id,
      group: updatedGroup,
    });
  }

  private initProperties(objectTypeId: ObjectTypeIds) {
    this.allProperties$ = this.objectStorageService.dataById$.pipe(
      filter(data => {
        return !!data;
      }),
      map(data => {
        return data.get(objectTypeId).properties;
      }),
      tap(data => {
        this.allPropertiesById = arrayToMap(data, 'id');
      }),
    );
    this.availableProperties$ = combineLatest([
      this.allProperties$,
      this.layout$,
    ]).pipe(
      map(([allProperties, layout]) => {
        const usedPropertyIds = [];

        layout.rows.forEach(row => {
          row.columns.forEach(column => {
            if (column.group) {
              column.group.controlColumns.forEach(controlColumn => {
                controlColumn.inputControls.forEach(inputControl => {
                  usedPropertyIds.push(inputControl.propertyId);
                });
              });
            }
          });
        });

        return allProperties.filter(
          property => !usedPropertyIds.includes(property.id),
        );
      }),
    );

    this.propertiesById$ = this.allProperties$.pipe(
      map(properties => arrayToMap(properties, 'id')),
    );
  }

  private processFormControlAction(props: {
    action: 'update' | 'delete';
    control: IObjectFormInputControl;
  }) {
    const controlParents = this.findInputControlParents(props.control.id);

    const updatedRows = this.layout.rows.map(row => {
      if (row.id === controlParents.row.id) {
        return {
          ...row,
          columns: row.columns.map(column => {
            if (column.id === controlParents.rowColumn.id) {
              return {
                ...column,
                group: {
                  ...column.group,
                  controlColumns: column.group.controlColumns.map(
                    controlColumn => {
                      if (
                        controlColumn.id ===
                        controlParents.groupControlColumn.id
                      ) {
                        const inputControls =
                          props.action === 'update'
                            ? controlColumn.inputControls.map(control =>
                                control.id === props.control.id
                                  ? props.control
                                  : control,
                              )
                            : controlColumn.inputControls.filter(
                                control => control.id !== props.control.id,
                              );

                        return {
                          ...controlColumn,
                          inputControls,
                        };
                      }
                      return controlColumn;
                    },
                  ),
                },
              };
            }

            return column;
          }),
        };
      }

      return row;
    });

    this.updateRows(updatedRows);
  }

  formValidationState() {
    return combineLatest([this.availableProperties$, this._layout$]).pipe(
      map(([availableProperties, layout]) => {
        const availableSystemRequiredProperties = availableProperties.filter(
          property => property.systemRequired && !property.readonly,
        );

        const invalidGroupIds = [];
        const invalidControlColumnIds = [];
        const invalidRowColumnIds = [];
        const invalidFormInputControlIds = [];

        layout.rows.forEach(row => {
          row.columns.forEach(column => {
            const columnWithGroup = column.group;

            if (!columnWithGroup) {
              invalidRowColumnIds.push(column.id);
            } else {
              column.group.controlColumns.forEach(controlColumn => {
                const controlColumnWithControls =
                  controlColumn.inputControls.length;

                if (!controlColumnWithControls) {
                  invalidControlColumnIds.push(controlColumn.id);
                  invalidGroupIds.push(column.group.id);
                } else {
                  controlColumn.inputControls.forEach(control => {
                    switch (control.inputType) {
                      case ObjectFormInputControlTypeEnum.Location:
                        const locationSettings =
                          control.settings as IObjectFormLocationControlSetting;

                        if (
                          !locationSettings ||
                          !locationSettings.latitudePropertyId ||
                          !locationSettings.longitudePropertyId
                        ) {
                          invalidFormInputControlIds.push(control.id);
                        }
                        break;
                      default:
                        if (!control.propertyId) {
                          invalidFormInputControlIds.push(control.id);
                        }
                        break;
                    }
                  });
                }
              });
            }
          });
        });

        return {
          invalidGroupIds,
          invalidControlColumnIds,
          invalidRowColumnIds,
          invalidFormInputControlIds,
          availableSystemRequiredProperties,
        };
      }),
    );
  }
}

const FORM_CONTROL_TYPES_WITHOUT_PROPERTY_ID = [
  ObjectFormInputControlTypeEnum.Location,
];

export interface RowLayout {
  numberOfColumns: number;
}

export type GroupLayout = RowLayout;

export interface FormControlOption {
  icon: string;
  type: ObjectFormInputControlTypeEnum;
  name: string;
  propertyId?: string;
  systemRequired?: boolean;
  readonly?: boolean;
}

export type FormSettings = Pick<IObjectForm, 'name' | 'positionIds'>;
