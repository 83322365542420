import {BaseCrudHttpService} from './base.http-service';
import {HttpClient} from '@angular/common/http';
import {IClientSideErrorConf} from '@retrixhouse/salesapp-shared/lib/models';
import {IClientSideErrorConfDataService} from '../../interfaces/data-service/client-side-error-conf.iface';
import {Injectable} from '@angular/core';

const ENDOPOINT = '/sys/client-side/confs';
@Injectable()
export class ClientSideErrorConfHttpService
  extends BaseCrudHttpService<IClientSideErrorConf>
  implements IClientSideErrorConfDataService
{
  constructor(http: HttpClient) {
    super(http, ENDOPOINT);
  }

  getUserConfig(): Promise<IClientSideErrorConf[] | null> {
    return this.GET(`${ENDOPOINT}/my`);
  }
}
