import {Injectable} from '@angular/core';
import {Customer} from '../../models';
import {ICustomerDataService} from '../../interfaces/data-service';
import {BaseCrudOfflineService} from './base.offline-service';

@Injectable()
export class CustomerOfflineService
  extends BaseCrudOfflineService<Customer>
  implements ICustomerDataService
{
  public deletePicture(customerId: string): Promise<void> {
    return Promise.reject('Offline service - not yet implemented');
  }
}
