import {Injectable} from '@angular/core';

import {
  IFeedback,
  IFeedbackWithUserAndOpenedInfo,
} from '@retrixhouse/salesapp-shared/lib/models';
import {DataProvider} from '@salesapp/data-provider';
import {FeedbackDataService} from '@salesapp/shared/services/data';
import {Observable, from, of} from 'rxjs';
import {FeedbackFilter} from '../feedback.interfaces';
import {Feedback, FeedbackWithPhotosRequest} from '@salesapp/shared/models';
import * as moment from 'moment';
import {OldBaseStorageService} from '../../../services/storage/old-base-storage-service';

@Injectable()
export class FeedbackStorageService extends OldBaseStorageService<
  IFeedbackWithUserAndOpenedInfo,
  IFeedbackWithUserAndOpenedInfo[],
  FeedbackFilter
> {
  constructor(dataProvider: DataProvider) {
    super(dataProvider.feedback);
  }

  getData(input?: {
    filter?: FeedbackFilter;
  }): Observable<IFeedbackWithUserAndOpenedInfo[]> {
    if (input?.filter || this.filter) {
      return from(
        (this.dataService as FeedbackDataService).search(
          (input?.filter as FeedbackFilter) || this.filter,
        ),
      ) as any;
    } else {
      return of([]);
    }
  }

  updateData<FeedbackWithPhotosRequest>(input: {
    id: string;
    data: FeedbackWithPhotosRequest;
  }) {
    return (this.dataService as FeedbackDataService).updateWithPhotos(
      input.data as any,
    );
  }

  createData(input: {data: FeedbackWithPhotosRequest}) {
    return (this.dataService as FeedbackDataService).createWithPhotos(
      input.data,
    );
  }
}

const DEFAULT_FILTER: FeedbackFilter = {
  from: moment().startOf('month').toDate(),
  to: moment().endOf('month').toDate(),
};
