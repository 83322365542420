import {AutoUnsubscribe} from '@salesapp/utils/angular.utils';
import {DynamicFormAvatarUploadedEvent} from '@salesapp/dynamic-form';
import {IProduct} from '@retrixhouse/salesapp-shared/lib/models';
import {Observable} from 'rxjs';
import {ProductStorageService} from '@salesapp/storage';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  ObjectTypeIds,
  RoleNames,
} from '@retrixhouse/salesapp-shared/lib/common';

@Component({
  selector: 'app-product-form',
  templateUrl: './product-form.component.html',
  styleUrls: ['./product-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@AutoUnsubscribe()
export class ProductFormComponent implements OnInit {
  @Input() product: Partial<IProduct>;

  @Output() actionCompleted = new EventEmitter<IProduct>();
  @Output() cancel = new EventEmitter<void>();

  readonlyRoles = [RoleNames.ProductsView];
  editRoles = [RoleNames.Products, RoleNames.ProductsEdit, RoleNames.Admin];

  actionInProgress$: Observable<boolean>;

  ObjectTypeIds = ObjectTypeIds;

  get isUpdate() {
    return !!this.product;
  }

  constructor(private productStorageService: ProductStorageService) {}

  ngOnInit() {
    this.actionInProgress$ = this.productStorageService.dataCommandInProgress$;
  }

  onAction(formData: IProduct) {
    if (formData.version) {
      return this.productStorageService
        .update({
          id: formData.id,
          data: formData,
        })
        .subscribe({
          next: data => {
            this.actionCompleted.emit(data);
          },
        });
    } else {
      return this.productStorageService.create(formData).subscribe({
        next: data => {
          this.actionCompleted.emit(data);
        },
      });
    }
  }

  onCancel() {
    this.cancel.emit();
  }

  onPhotoUploadFinished(event: DynamicFormAvatarUploadedEvent) {
    if (event.responseState === 'success') {
      this.productStorageService.updatePicture({
        pictureUrl: event.value,
        id: event.objectId,
        version: event.version,
      });
    }
  }
}
