import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseHttpService} from './base.http-service';
import {IJsonSchemaDataService} from '../../interfaces/data-service';

@Injectable()
export class JsonSchemaHttpService
  extends BaseHttpService
  implements IJsonSchemaDataService
{
  constructor(http: HttpClient) {
    super(http);
  }

  public getOpenApiDefinition(): Promise<any> {
    return this.GET('/openapi.json');
  }
}
