import {Customer} from './customer.model';
import {BaseModel} from './base.model';

export class ProductCategory extends BaseModel {
  uid: string;
  version: number;
  name: string;
  textColor?: string;
  backgroundColor?: string;
  parentId?: string;
  position: number;
  customerId: string;
  customer: Customer;

  constructor() {
    super();
    this.uid = null;
    this.version = null;
    this.name = null;
    this.textColor = null;
    this.backgroundColor = null;
    this.parentId = null;
    this.position = 0;
    this.customerId = null;
  }
}

export function generateFullPath(items: ProductCategory[]): ProductCategory[] {
  return (
    items
      .map(c => {
        let parentNames = [c.name];
        let parentCategoryId = c.parentId;
        while (!!parentCategoryId) {
          const productCategory = items.find(pc => pc.id === parentCategoryId);
          if (!productCategory) {
            break;
          }
          parentNames = [productCategory.name, ...parentNames];
          parentCategoryId = productCategory.parentId;
        }
        return {category: c, names: parentNames};
      })
      // update category name by joining parentNames and return category
      .map(sc => {
        const {category, names} = sc;
        category.name = names.join(' / ');
        return category;
      })
      // sort alphabetically
      .sort((a, b) => a.name.localeCompare(b.name))
  );
}
