import {Injectable} from '@angular/core';
import {Trigger, TriggerExecutionResult} from '../../models';
import {ITriggerDataService} from '../../interfaces/data-service';
import {BaseCrudOfflineService} from './base.offline-service';

@Injectable()
export class TriggerOfflineService
  extends BaseCrudOfflineService<Trigger>
  implements ITriggerDataService
{
  public validateTriggerDefinition(definition: string): Promise<boolean> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public execute(id: string, context: any): Promise<TriggerExecutionResult> {
    return Promise.reject('Offline service - not yet implemented');
  }
}
