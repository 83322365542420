import {ObjectTypeIds} from '@retrixhouse/salesapp-shared/lib/common';
import {paramCase} from 'change-case';

export function getTranslationMarkersForObjectProperty(input: {
  objectTypeId: ObjectTypeIds;
  propertyName: string;
}) {
  const {objectTypeId, propertyName} = input;
  const objectTypeName = Object.entries(ObjectTypeIds).find(
    ([key, value]) => value === objectTypeId,
  )[0];
  const core = `entity.${paramCase(objectTypeName)}.properties.${paramCase(
    propertyName,
  )}`;
  return {
    label: `${core}.label`,
    hint: `${core}.hint`,
  };
}

export function getTranslationMarkerForObjectType(params: {
  objectTypeId: ObjectTypeIds;
}) {
  const {objectTypeId} = params;
  const objectTypeName = Object.entries(ObjectTypeIds).find(
    ([key, value]) => value === objectTypeId,
  )[0];
  return `entity.${paramCase(objectTypeName)}.name`;
}
