import {
  ObjectArrayCache,
  ObjectMapCache,
} from '@retrixhouse/salesapp-shared/lib/caching';
import {IReportDashboard} from '@retrixhouse/salesapp-shared/lib/models';
import {IReportDashboardDataService} from '../../interfaces/data-service/report-dashboard-data.iface';
import {ReportDashboardHttpService} from '../http/report-dashboard.http-service';
import {ReportDashboardOfflineService} from '../offline/report-dashboard.offline-service';
import {BaseCrudDataService} from './base.data-service';
export class ReportDashboardDataService extends BaseCrudDataService<
  IReportDashboard,
  IReportDashboardDataService,
  ObjectMapCache<string, IReportDashboard>
> {
  constructor(
    onlineService: ReportDashboardHttpService,
    offlineService: ReportDashboardOfflineService,
    cache: ObjectMapCache<string, IReportDashboard>,
  ) {
    super(onlineService, offlineService, cache);
  }

  /**
   * Create or update existing entity.
   * @param {string} id - id of the entity to update
   * @param {Partial<IReportDashboard>} objectData - entity data
   * @returns {Promise<IReportDashboard>} updated entity
   */
  public async upsert(objectData: IReportDashboard): Promise<IReportDashboard> {
    // update object using the underlying data service
    const updatedObject = await this.service.upsert(objectData);

    // update the object in the cache
    if (this.supportsCaching) {
      if (this._cache instanceof ObjectArrayCache) {
        const cache = this._cache as ObjectArrayCache<IReportDashboard>;
        if (cache.isValid) {
          const idx = cache.findIndex(i => i.id === updatedObject.id);
          if (idx >= 0) {
            cache.setAt(idx, updatedObject);
          } else {
            console.warn(
              'Updated object not found in the cache.',
              updatedObject,
            );
          }
        } else {
          console.warn(
            'Initializing cache after update operation with a single object.',
          );
          cache.init([updatedObject], this._defaultTtl);
        }
      } else if (this._cache instanceof ObjectMapCache) {
        const cache = this._cache as ObjectMapCache<string, IReportDashboard>;
        if (cache.isValid) {
          if (cache.has(updatedObject.id)) {
            cache.set(updatedObject.id, updatedObject);
          } else {
            console.warn(
              'Updated object not found in the cache.',
              updatedObject,
            );
          }
        } else {
          console.warn(
            'Initializing cache after update operation with a single object.',
          );
          cache.init([[updatedObject.id, updatedObject]], this._defaultTtl);
        }
      } else if (this._cache) {
        console.warn('Unsupported cache type.');
      }
    }

    return updatedObject;
  }
}
