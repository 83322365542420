import {Output, Injectable, EventEmitter} from '@angular/core';

@Injectable()
export class FullScreenService {
  @Output() fullScreen = new EventEmitter<boolean>();

  constructor() {}

  openFullScreen() {
    this.fullScreen.emit(true);
  }

  closeFullScreen() {
    this.fullScreen.emit(false);
  }
}
