import {IFeedback, INewsPost} from '@retrixhouse/salesapp-shared/lib/models';
import {
  IAction,
  PermissionOperator,
  ArrayOperator,
  ActionResolver,
  ActionCustomContextResolver,
} from './actions.iface';
import {
  ProjectContextPermissions,
  RoleNames,
} from '@retrixhouse/salesapp-shared/lib/common';

/**
 * Representation of single action as class
 */
export class Action {
  id: string;
  icon: string;
  text?: string;
  i18nIdentifier: string;
  permissions?: {
    [key in PermissionOperator]?: {
      roles?: {
        [key in ArrayOperator]?: RoleNames[];
      };
      projectContext?: {
        [key in ArrayOperator]?: ProjectContextPermissions[];
      };
      customResolver?: ActionResolver;
      customContextResolver?: (ctx: ActionCustomContextResolver) => boolean;
    };
  };
  disabled?: boolean;
  disabledResolver: ActionResolver;
  customPermissionResolver?: ActionResolver;

  constructor(action: IAction) {
    Object.assign(this, action);
  }

  withCustomPermissionResolver(customPermissionResolver: {
    resolver: (params: unknown) => boolean;
    resolverParams: unknown;
  }) {
    if (this.permissions) {
      console.warn(
        'If custom permission resolver is provided then default permissions are ignored',
      );
    }

    this.customPermissionResolver = customPermissionResolver;
    return this;
  }

  withCustomConditionResolver(customConditionResolver: ActionResolver) {
    if (this.permissions?.and) {
      this.permissions.and = {
        ...this.permissions.and,
        customResolver: customConditionResolver,
      };
    } else if (this.permissions?.or) {
      this.permissions.or = {
        ...this.permissions.or,
        customResolver: customConditionResolver,
      };
    } else {
      this.permissions = {
        or: {
          customResolver: customConditionResolver,
        },
      };
    }
    return this;
  }

  withDisabledResolver(disabledResolver: ActionResolver) {
    this.disabledResolver = disabledResolver;
    this.disabled = disabledResolver.resolver(disabledResolver.resolverParams);
    return this;
  }

  withTranslation(text: string) {
    this.text = text;
    return this;
  }

  withCustomProps(props: {[k: string]: any}) {
    Object.assign(this, props);
    return this;
  }
}

/**
 * List of actions provided as static content
 * for centralized approach for generating buttons and actions in application
 */
export class Actions {
  static CLIENT_SIDE_ERROR_CONF_SHOW = new Action({
    id: 'clientSideErrorConfShow',
    icon: 'fa-solid fa-comment-pen',
    i18nIdentifier: 'entity.client-side-error-conf.actions.show',
    permissions: {
      or: {
        roles: {any: [RoleNames.Admin]},
      },
    },
  });

  static CLIENT_SIDE_ERROR_CONF_CREATE = new Action({
    id: 'clientSideErrorConfCreate',
    icon: 'fa-solid fa-plus-circle',
    i18nIdentifier: 'entity.client-side-error-conf.actions.create',
    permissions: {
      or: {
        roles: {any: [RoleNames.Admin]},
      },
    },
  });

  static CLIENT_SIDE_ERROR_CONF_UPDATE = new Action({
    id: 'clientSideErrorConfUpdate',
    icon: 'fa-solid fa-pen-to-square',
    i18nIdentifier: 'entity.client-side-error-conf.actions.update',
    permissions: {
      or: {
        roles: {any: [RoleNames.Admin]},
      },
    },
  });

  static CLIENT_SIDE_ERROR_CONF_DELETE = new Action({
    id: 'clientSideErrorConfDelete',
    icon: 'fa-solid fa-trash',
    i18nIdentifier: 'entity.client-side-error-conf.actions.delete',
    permissions: {
      or: {
        roles: {any: [RoleNames.Admin]},
      },
    },
  });

  static COMMENT_SHOW = new Action({
    id: 'commentShow',
    icon: 'fa-solid fa-comment-pen',
    i18nIdentifier: 'buttons.comments',
    permissions: {
      or: {
        roles: {any: [RoleNames.Comment, RoleNames.CommentView]},
      },
    },
  });

  static COMMENT_CREATE = new Action({
    id: 'commentCreate',
    icon: '',
    i18nIdentifier: '',
    permissions: {
      or: {
        roles: {any: [RoleNames.Comment, RoleNames.CommentPost]},
      },
    },
  });

  static COMMENT_DELETE = new Action({
    id: 'commentDelete',
    icon: '',
    i18nIdentifier: '',
    permissions: {
      or: {
        roles: {any: [RoleNames.Comment, RoleNames.CommentDelete]},
      },
    },
  });

  static FEEDBACK_VIEW = new Action({
    id: 'feedbackView',
    icon: 'fa-solid fa-message-smile',
    i18nIdentifier: 'buttons.feedbacks',
    permissions: {
      or: {
        roles: {any: [RoleNames.Feedback, RoleNames.FeedbackView]},
        projectContext: {any: [ProjectContextPermissions.Feedback]},
      },
    },
  });

  static FEEDBACK_RESOLVE = new Action({
    id: 'feedbackResolve',
    icon: 'fa-solid fa-handshake',
    i18nIdentifier: 'buttons.feedback-resolve',
    permissions: {
      or: {
        roles: {
          any: [RoleNames.Feedback, RoleNames.FeedbackEdit, RoleNames.Admin],
        },
        projectContext: {any: [ProjectContextPermissions.Feedback]},
        customContextResolver: (
          ctx: ActionCustomContextResolver<IFeedback>,
        ) => {
          return ctx.data.createdById == ctx.currentUserUsername.id;
        },
      },
    },
  });

  static FEEDBACK_UNRESOLVE = new Action({
    id: 'feedbackUnresolve',
    icon: 'fa-regular fa-handshake-slash',
    i18nIdentifier: 'buttons.feedback-unresolve',
    permissions: {
      or: {
        roles: {
          any: [RoleNames.Feedback, RoleNames.FeedbackEdit, RoleNames.Admin],
        },
        projectContext: {any: [ProjectContextPermissions.Feedback]},
        customContextResolver: (
          ctx: ActionCustomContextResolver<IFeedback>,
        ) => {
          return ctx.data?.createdById == ctx.currentUserUsername?.id;
        },
      },
    },
  });

  static OBJECT_FORM_CREATE = new Action({
    id: 'objectFormCreate',
    icon: 'dx-icon fa fa-plus-circle',
    i18nIdentifier: 'entity.object-form.actions.create',
    permissions: {
      or: {
        roles: {
          any: [
            RoleNames.Admin,
            RoleNames.ObjectProperties,
            RoleNames.ObjectPropertiesEdit,
          ],
        },
      },
    },
  });

  static OBJECT_FORM_EDIT = new Action({
    id: 'objectFormEdit',
    icon: 'dx-icon fa fa-pen-to-square',
    i18nIdentifier: 'entity.object-form.actions.edit',
    permissions: {
      or: {
        roles: {
          any: [
            RoleNames.Admin,
            RoleNames.ObjectProperties,
            RoleNames.ObjectPropertiesEdit,
          ],
        },
      },
    },
  });

  static OBJECT_FORM_COPY = new Action({
    id: 'objectFormCopy',
    icon: 'fa-solid fa-copy',
    i18nIdentifier: 'entity.object-form.actions.copy',
    permissions: {
      or: {
        roles: {
          any: [
            RoleNames.Admin,
            RoleNames.ObjectProperties,
            RoleNames.ObjectPropertiesEdit,
          ],
        },
      },
    },
  });

  static OBJECT_FORM_DELETE = new Action({
    id: 'objectFormDelete',
    icon: 'dx-icon fa fa-trash',
    i18nIdentifier: 'entity.object-form.actions.delete',
    permissions: {
      or: {
        roles: {
          any: [
            RoleNames.Admin,
            RoleNames.ObjectProperties,
            RoleNames.ObjectPropertiesDelete,
          ],
        },
      },
    },
  });

  static OBJECT_PROPERTY_CREATE = new Action({
    id: 'objectPropertyCreate',
    icon: 'dx-icon fa fa-plus-circle',
    i18nIdentifier: 'entity.object-property.actions.create',
    permissions: {
      or: {
        roles: {
          any: [
            RoleNames.Admin,
            RoleNames.ObjectProperties,
            RoleNames.ObjectPropertiesEdit,
          ],
        },
      },
    },
  });

  static OBJECT_PROPERTY_EDIT = new Action({
    id: 'objectPropertyEdit',
    icon: 'dx-icon fa fa-pen-to-square',
    i18nIdentifier: 'entity.object-property.actions.edit',
    permissions: {
      or: {
        roles: {
          any: [
            RoleNames.Admin,
            RoleNames.ObjectProperties,
            RoleNames.ObjectPropertiesEdit,
          ],
        },
      },
    },
  });

  static OBJECT_PROPERTY_DELETE = new Action({
    id: 'objectPropertyDelete',
    icon: 'dx-icon fa fa-trash',
    i18nIdentifier: 'entity.object-property.actions.delete',
    permissions: {
      or: {
        roles: {
          any: [
            RoleNames.Admin,
            RoleNames.ObjectProperties,
            RoleNames.ObjectPropertiesDelete,
          ],
        },
      },
    },
  });

  static PRODUCT_CREATE = new Action({
    id: 'productCreate',
    icon: 'dx-icon fa fa-plus-circle',
    i18nIdentifier: 'entity.product.actions.create',
    permissions: {
      or: {
        roles: {any: [RoleNames.Products, RoleNames.ProductsEdit]},
      },
    },
  });

  static PRODUCT_DELETE = new Action({
    id: 'productDelete',
    icon: 'fa fa-regular fa-trash',
    i18nIdentifier: 'entity.product.actions.delete',
    permissions: {
      or: {
        roles: {any: [RoleNames.Products, RoleNames.ProductsDelete]},
      },
    },
  });

  static PRODUCT_VIEW = new Action({
    id: 'productView',
    icon: 'fa-solid ??', // TODO(milan): add icon
    i18nIdentifier: 'entity.product.actions.view',
    permissions: {
      or: {
        roles: {any: [RoleNames.Products, RoleNames.ProductsView]},
      },
    },
  });

  static SHOW_RAW_DATA = new Action({
    id: 'showRawData',
    icon: 'fa fa-regular fa-brackets-curly',
    i18nIdentifier: 'global.actions.show-raw-data',
    permissions: {
      or: {
        roles: {any: [RoleNames.Admin]},
      },
    },
  });

  static TRANSLATION_CREATE = new Action({
    id: 'translationCreate',
    icon: 'dx-icon fa fa-plus-circle',
    i18nIdentifier: 'entity.translation.actions.create',
    permissions: {
      or: {
        roles: {any: [RoleNames.Admin]},
      },
    },
  });

  static TRANSLATION_EDIT = new Action({
    id: 'translationEdit',
    icon: 'dx-icon fa fa-pen-to-square',
    i18nIdentifier: 'entity.translation.actions.edit',
    permissions: {
      or: {
        roles: {any: [RoleNames.Admin]},
      },
    },
  });

  static TRANSLATION_DELETE = new Action({
    id: 'translationDelete',
    icon: 'dx-icon fa fa-trash',
    i18nIdentifier: 'entity.translation.actions.delete',
    permissions: {
      or: {
        roles: {any: [RoleNames.Admin]},
      },
    },
  });

  static NEWS_POST_CREATE = new Action({
    id: 'newsPostCreate',
    icon: 'dx-icon fa fa-plus-circle',
    i18nIdentifier: 'entity.news-post.actions.create',
    permissions: {
      or: {
        roles: {
          any: [
            RoleNames.Admin,
            RoleNames.NewsPosts,
            RoleNames.NewsPostsEdit,
            RoleNames.NewsPostsEditAnother,
          ],
        },
      },
    },
  });

  static NEWS_POST_EDIT = new Action({
    id: 'newsPostEdit',
    icon: 'dx-icon fa fa-pen-to-square',
    i18nIdentifier: 'entity.news-post.actions.edit',
    permissions: {
      or: {
        roles: {
          any: [
            RoleNames.Admin,
            RoleNames.NewsPosts,
            RoleNames.NewsPostsEditAnother,
          ],
        },
        customResolver: {
          resolver(params, ctx: ActionCustomContextResolver<INewsPost>) {
            return (
              ctx.data &&
              !!ctx.data.authorId &&
              ctx.data.authorId === ctx.currentUserUsername.id
            );
          },
          resolverParams: null,
        },
      },
    },
  });

  static NEWS_POST_DELETE = new Action({
    id: 'newsPostDelete',
    icon: 'dx-icon fa fa-trash',
    i18nIdentifier: 'entity.news-post.actions.delete',
    permissions: {
      or: {
        roles: {
          any: [
            RoleNames.Admin,
            RoleNames.NewsPosts,
            RoleNames.NewsPostsEditAnother,
          ],
        },
        customResolver: {
          resolver(params, ctx: ActionCustomContextResolver<INewsPost>) {
            return (
              ctx.data &&
              !!ctx.data.authorId &&
              ctx.data.authorId === ctx.currentUserUsername.id
            );
          },
          resolverParams: null,
        },
      },
    },
  });

  static USER_CREATE = new Action({
    id: 'userCreate',
    icon: 'dx-icon fa fa-plus-circle',
    i18nIdentifier: 'entity.user.actions.create',
    permissions: {
      or: {
        roles: {any: [RoleNames.Users, RoleNames.UsersEdit]},
      },
    },
  });

  static USER_DELETE = new Action({
    id: 'userDelete',
    icon: 'dx-icon fa fa-trash',
    i18nIdentifier: 'entity.user.actions.delete',
    permissions: {
      or: {
        roles: {any: [RoleNames.Users, RoleNames.UsersDelete]},
      },
    },
  });

  static USER_VIEW = new Action({
    id: 'userView',
    icon: 'fa-solid ??', // TODO(milan): add icon
    i18nIdentifier: 'entity.user.actions.view',
    permissions: {
      or: {
        roles: {any: [RoleNames.Users, RoleNames.UsersDelete]},
      },
    },
  });

  static USER_RESET_PASSWORD = new Action({
    id: 'userView',
    icon: 'fa-solid fa-key',
    i18nIdentifier: 'entity.user.actions.reset-password',
    permissions: {
      or: {
        roles: {any: [RoleNames.Admin, RoleNames.UserResetPassword]},
      },
    },
  });

  static USER_MANAGEMENT_CREATE = new Action({
    id: 'userManagementCreate',
    icon: 'dx-icon fa fa-plus-circle',
    i18nIdentifier: 'entity.user.actions.create',
    permissions: {
      or: {
        roles: {
          any: [RoleNames.Admin, RoleNames.Users, RoleNames.UsersEdit],
        },
      },
    },
  });

  static USER_MANAGEMENT_DELETE = new Action({
    id: 'userManagementDelete',
    icon: 'dx-icon fa fa-trash',
    i18nIdentifier: 'entity.user.actions.delete',
    permissions: {
      or: {
        roles: {
          any: [RoleNames.Admin, RoleNames.Users, RoleNames.UsersDelete],
        },
      },
    },
  });

  static USER_MANAGEMENT_VIEW = new Action({
    id: 'userManagementView',
    icon: 'fa-solid',
    i18nIdentifier: 'entity.user.actions.view',
    permissions: {
      or: {
        roles: {
          any: [
            RoleNames.Admin,
            RoleNames.Users,
            RoleNames.UserProfiles,
            RoleNames.UsersView,
            RoleNames.UserProfilesView,
          ],
        },
      },
    },
  });

  static EDIT_REPORT_DASHBOARD = new Action({
    id: 'editReportDashboard',
    icon: 'fa-solid fa-gears',
    i18nIdentifier: 'entity.report-dashboard.actions.edit',
    permissions: {
      or: {
        roles: {
          any: [RoleNames.Admin],
        },
      },
    },
  });

  static VISIT_OPEN = new Action({
    id: 'visitOpen',
    icon: 'fa-solid fa-eye',
    i18nIdentifier: 'entity.visit.actions.open',
    permissions: {
      or: {
        roles: {
          any: [RoleNames.Admin, RoleNames.VisitEditor],
        },
      },
    },
  });
}
