import {IMobileDeviceDataService} from '../../interfaces/data-service';
import {MobileDevice, RegisterDeviceRequest} from '../../models';
import {MobileDeviceHttpService} from '../http';
import {BaseReadonlyDataService} from './base.data-service';

export class MobileDeviceDataService extends BaseReadonlyDataService<
  MobileDevice,
  IMobileDeviceDataService
> {
  constructor(onlineService: MobileDeviceHttpService) {
    super(onlineService);
  }

  public register(request: RegisterDeviceRequest): Promise<MobileDevice> {
    return this.service.register(request);
  }
}
