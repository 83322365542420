import {Injectable} from '@angular/core';
import {BaseCrudHttpService} from './base.http-service';
import {HttpClient} from '@angular/common/http';
import {Question} from '../../models';
import {IQuestionDataService} from '../../interfaces/data-service';

@Injectable()
export class QuestionHttpService
  extends BaseCrudHttpService<Question>
  implements IQuestionDataService
{
  constructor(http: HttpClient) {
    super(http, '/questions');
  }
}
