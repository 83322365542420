import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {InputFileComponent} from './input-file.component';
import {CustomDirectivesModule} from '@salesapp/shared/directives';
import {TranslateModule} from '@ngx-translate/core';
import {DxButtonModule} from 'devextreme-angular';
import {CustomPipesModule} from '@salesapp/pipes';
import {LoadingModule} from '../loading';
import {FormFieldModule} from '../form-field';

const SALESAPP_MODULES = [CustomDirectivesModule, CustomPipesModule];
const UI_MODULES = [FormFieldModule, LoadingModule];

const DX_MODULES = [DxButtonModule];

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    ...SALESAPP_MODULES,
    ...DX_MODULES,
    ...UI_MODULES,
  ],
  declarations: [InputFileComponent],
  providers: [],
  exports: [InputFileComponent],
})
export class InputFileModule {}
