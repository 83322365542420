import {Injectable} from '@angular/core';
import packageJson from '../../../../package.json';
import {environment} from 'src/environments/environment';
import {satisfies, validate} from 'compare-versions';
import {Platform} from '@angular/cdk/platform';
import {
  AppInterfaceService,
  AppPlatform,
} from '../app-interface/app-interface.service';
import {PingHttpService} from './http';

@Injectable()
export class AppInfoService {
  private _serverVersion = '';
  private _clientVersion = '';
  private _clientVersionOnline = '';

  constructor(
    private pingService: PingHttpService,
    private appInterfaceService: AppInterfaceService,
    private platform: Platform,
  ) {
    this._clientVersion = packageJson.version;
  }

  public downloadVersions(): void {
    this.pingService.ping().then(ping => (this._serverVersion = ping.version));
    const xhr = new XMLHttpRequest();
    xhr.open('GET', `${environment.frontEndBaseUrl}version.txt`);
    xhr.onreadystatechange = () => {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        let status = xhr.status;
        if (status === 0 || (status >= 200 && status < 400)) {
          if (xhr.responseText) {
            const ver = xhr.responseText.trim();
            if (validate(ver)) {
              this._clientVersionOnline = ver;
            }
          }
        }
      }
    };
    xhr.send();
  }

  public get title(): string {
    return 'SalesApp®';
  }

  public get currentYear(): number {
    return new Date().getFullYear();
  }

  public get clientVersion(): string {
    return this._clientVersion;
  }

  public get serverVersion(): string {
    return this._serverVersion;
  }

  public get clientVersionOnline(): string {
    return this._clientVersionOnline;
  }

  public get isNewClientVersion(): boolean {
    if (this._clientVersion && this._clientVersionOnline) {
      return satisfies(this._clientVersion, `<${this._clientVersionOnline}`);
    }

    return false;
  }

  public get isMobileVersion(): boolean {
    return (
      this.appInterfaceService.platform === AppPlatform.AndroidApp ||
      this.appInterfaceService.platform === AppPlatform.IosApp
    );
  }

  public get platformInfo(): string {
    if (this.appInterfaceService.platform === AppPlatform.WebBrowser) {
      if (this.platform.FIREFOX) {
        return 'firefox';
      }

      if (this.platform.EDGE) {
        return 'edge';
      }

      if (this.platform.SAFARI) {
        return 'safari';
      }

      if (this.platform.BLINK) {
        return 'chrome';
      }

      if (this.platform.WEBKIT) {
        return 'opera';
      }

      if (this.platform.TRIDENT) {
        return 'ie';
      }
    } else if (this.appInterfaceService.platform === AppPlatform.IosApp) {
      return 'ios-app';
    } else if (this.appInterfaceService.platform === AppPlatform.AndroidApp) {
      return 'android-app';
    }

    return 'unknown';
  }
}
