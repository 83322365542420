import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {NotificationsIconComponent} from './components/notifications-icon/notifications-icon.component';
import {NotificationsDialogComponent} from './components/notifications-dialog/notifications-dialog.component';
import {DialogModule} from '../dialog';
import {TranslateModule} from '@ngx-translate/core';
import {DxButtonModule, DxListModule} from 'devextreme-angular';
import {CustomDirectivesModule} from '@salesapp/shared/directives';
import {CustomPipesModule} from '@salesapp/pipes';

const DX_MODULES = [DxListModule, DxButtonModule];

@NgModule({
  imports: [
    CommonModule,
    DialogModule,
    TranslateModule,
    CustomDirectivesModule,
    CustomPipesModule,
    ...DX_MODULES,
  ],
  declarations: [NotificationsIconComponent, NotificationsDialogComponent],
  providers: [],
  exports: [NotificationsIconComponent],
})
export class NotificationsModule {}
