import {GenericList} from '../generic-list.model';
import {Photo} from '../photo.model';
import {Position} from '../position.model';
import {ProductCategory} from '../product-category.model';
import {ProductListing} from '../product-listing.model';
import {Product} from '../product.model';
import {Tag} from '../tag.model';
import {TodoListResult} from '../todo-list-result.model';
import {TourPlan} from '../tour-plan.model';
import {UsernameResponse} from '../username-response.model';
import {
  PromoActionWithRelationMandatory,
  PromoAction,
} from '../promo-action.model';
import {UserTask} from '../user-task.model';
import {PersonalArrangement} from '../personal-arrangement.model';
import {ObjectType} from '../object-type.model';
import {ObjectPropertyValue} from '../object-property-value.model';
import {Feedback} from '../feedback.model';
import {Comment} from '../comment.model';
import {QuestionnaireResult} from '../questionnaire-result.model';

export class VisitDataResponse {
  visit: TourPlan;
  previousVisit: TodoListResult;
  productCategories: ProductCategory[];
  products: Product[];
  productListings: ProductListingWithProductIds[];
  tags: Tag[];
  todoListResult?: TodoListResult;
  photos: Photo[];
  users: UsernameResponse[];
  personalArrangements?: PersonalArrangement[];
  personalArrangementDriveList: GenericList;
  personalArrangementDisplayList: GenericList;
  personalArrangementPositionList: GenericList;
  customerPositionList: Position[];
  promoActions: PromoActionWithRelationMandatory[];
  futurePromoActions: PromoAction[];
  promoActionRecentQResults?: QuestionnaireResult[];
  storeObjectType: ObjectTypePropertyValues;
  productObjectType: ObjectTypePropertyValues;
  // systemSettings is removed because it is no longer used. (instead dataProvider.resolveSettingValues is used)
  // systemSettings?: {[prop: string]: any};
  previousVisitData?: VisitHistoryData[];
  editableBySuperior?: boolean;
  userTasks: UserTask[];
  activeFeedbacks: Feedback[];
  feedbackComment?: Comment[];
}

export type VisitHistoryData = {id: string; startedAt: Date};

export class ProductListingWithProductIds extends ProductListing {
  listedProductIds: string[];
  constructor(productIds: string[], data?: Partial<ProductListing>) {
    super();
    this.listedProductIds = productIds;
  }
}

export type ObjectTypePropertyValues = {
  type: ObjectType;
  propertyValues: ObjectPropertyValue[] | null;
};
