import {AutoUnsubscribe} from '@salesapp/utils/angular.utils';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {FormControl, FormGroup} from '@angular/forms';
import {FormDesignerObjectFormLocationControlSettingFormControls} from '../form-designer-update-control-dialog/form-designer-update-control-dialog.component';
import {FormLayoutService} from '../../services/form-layout.service';
import {getTranslationMarkersForObjectProperty} from '@salesapp/utils/translation.utils';
import {InputSelectOption} from '@salesapp/components';
import {map, startWith} from 'rxjs/operators';
import {ObjectTypeIds} from '@retrixhouse/salesapp-shared/lib/common';
import {TranslateService} from '@ngx-translate/core';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {
  IObjectFormLocationControlSetting,
  ValueType,
} from '@retrixhouse/salesapp-shared/lib/models';

@Component({
  selector: 'app-form-designer-location-control-settings',
  templateUrl: './form-designer-location-control-settings.component.html',
  styleUrls: ['./form-designer-location-control-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@AutoUnsubscribe()
export class FormDesignerLocationControlSettingsComponent implements OnInit {
  @Input()
  settingsFormGroup: FormGroup<FormDesignerObjectFormLocationControlSettingFormControls>;

  altitudeOptions$: Observable<InputSelectOption[]>;
  latitudeOptions$: Observable<InputSelectOption[]>;
  longitudeOptions$: Observable<InputSelectOption[]>;

  private formChangedSubscription: Subscription;

  constructor(
    private formLayoutService: FormLayoutService,
    private translateService: TranslateService,
    private changeDetector: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.initOptions();
  }

  ngDoCheck() {
    // NOTE(milan): because of  change detection is not working here
    this.changeDetector.markForCheck();
  }

  getFormControl(name: keyof IObjectFormLocationControlSetting) {
    return this.settingsFormGroup.get(name) as FormControl;
  }

  private initOptions() {
    this.latitudeOptions$ = combineLatest([
      this.formLayoutService.allProperties$,
      this.settingsFormGroup.valueChanges.pipe(startWith(null)),
    ]).pipe(
      map(([properties, formValue]) => {
        const values = this.settingsFormGroup.getRawValue();
        return properties
          .filter(property => {
            return (
              property.valueType === ValueType.Float &&
              property.id !== values.longitudePropertyId &&
              property.id !== values.altitudePropertyId
            );
          })
          .map(property => ({
            value: property.id,
            name: this.translateService.instant(
              getTranslationMarkersForObjectProperty({
                objectTypeId: property.typeId as ObjectTypeIds,
                propertyName: property.name,
              }).label,
            ),
          }));
      }),
    );

    this.longitudeOptions$ = combineLatest([
      this.formLayoutService.allProperties$,
      this.settingsFormGroup.valueChanges.pipe(startWith(null)),
    ]).pipe(
      map(([properties, formValue]) => {
        const values = this.settingsFormGroup.getRawValue();
        return properties
          .filter(property => {
            return (
              property.valueType === ValueType.Float &&
              property.id !== values.latitudePropertyId &&
              property.id !== values.altitudePropertyId
            );
          })
          .map(property => ({
            value: property.id,
            name: this.translateService.instant(
              getTranslationMarkersForObjectProperty({
                objectTypeId: property.typeId as ObjectTypeIds,
                propertyName: property.name,
              }).label,
            ),
          }));
      }),
    );

    this.altitudeOptions$ = combineLatest([
      this.formLayoutService.allProperties$,
      this.settingsFormGroup.valueChanges.pipe(startWith(null)),
    ]).pipe(
      map(([properties, formValue]) => {
        const values = this.settingsFormGroup.getRawValue();
        return properties
          .filter(property => {
            return (
              property.valueType === ValueType.Float &&
              property.id !== values.latitudePropertyId &&
              property.id !== values.longitudePropertyId
            );
          })
          .map(property => ({
            value: property.id,
            name: this.translateService.instant(
              getTranslationMarkersForObjectProperty({
                objectTypeId: property.typeId as ObjectTypeIds,
                propertyName: property.name,
              }).label,
            ),
          }));
      }),
    );
  }
}
