import {Component} from '@angular/core';
import {ClientModeService, NavigationService} from '../../../shared/services';
import {Router} from '@angular/router';
import {ClientMode} from '../../../shared/enums/client-mode.enum';
import {ConnectionSpeed} from '../../../shared/enums/connection-type.enum';
import {NotificationService} from '../../../shared/services/notification.service';
import {TranslateService} from '@ngx-translate/core';
import {
  ConfirmationService,
  ConfirmType,
} from '../../../shared/services/confirmation.service';

export enum TransferDirection {
  DOWNLOAD = 'DOWNLOAD',
  UPLOAD = 'UPLOAD',
}

@Component({
  templateUrl: 'data-transfer.component.html',
  styleUrls: ['data-transfer.component.scss'],
})
export class DataTransferComponent {
  transferDirection: TransferDirection;
  transferDirectionEnum = TransferDirection;
  connectionSpeedChecked: boolean;

  constructor(
    private router: Router,
    private navigationService: NavigationService,
    private clientModeService: ClientModeService,
    private confirmationService: ConfirmationService,
    private translate: TranslateService,
  ) {
    const navigation = this.router.getCurrentNavigation();
    const extras = navigation.extras?.state;

    if (!extras?.direction) {
      this.navigationService.back();
      return;
    }

    // check connection speed
    const connectionSpeed = this.clientModeService.connectionSpeed;
    if (connectionSpeed === ConnectionSpeed.OK) {
      this.connectionSpeedChecked = true;
    } else if (connectionSpeed === ConnectionSpeed.SLOW) {
      const dialog = this.confirmationService.confirm(
        ConfirmType.CONFIRM,
        this.translate.instant('views.data-transfer.slow-connection'),
        this.translate.instant('views.data-transfer.slow-connection-confirm'),
      );
      dialog.then(dialogResult => {
        if (dialogResult) {
          this.connectionSpeedChecked = true;
        } else {
          router.navigate(['/']);
          // revert client mode
          this.clientModeService.clientMode =
            this.clientModeService.clientMode === ClientMode.ONLINE
              ? ClientMode.OFFLINE
              : ClientMode.ONLINE;
        }
      });
    } else if (connectionSpeed === ConnectionSpeed.NONE) {
      NotificationService.notifyError(
        this.translate.instant('views.data-transfer.no-connection'),
      );
      router.navigate(['/']);
      return;
    }

    const direction = extras.direction;
    this.transferDirection = direction;
    switch (direction) {
      case TransferDirection.DOWNLOAD:
        this.clientModeService.clientMode = ClientMode.OFFLINE;
        break;
      case TransferDirection.UPLOAD:
        this.clientModeService.clientMode = ClientMode.ONLINE;
        break;
      default:
        console.error('Invalid data transfer direction ' + direction);
        this.navigationService.back();
        return;
    }
  }
}
