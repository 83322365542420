import {IPhoto, TourPlanState} from '@retrixhouse/salesapp-shared/lib/models';
import {Address} from '../address.model';
import {Product} from '../product.model';
import {Store} from '../store.model';
import {TodoList} from '../todo-list.model';
import {UsernameResponse} from '../username-response.model';
import {
  IVisitDataResultProductListing,
} from '@retrixhouse/salesapp-shared/lib/responses';

export class VisitDataReportResponse {
  rows: VisitDataReportRow[];
  todoList: TodoList;
  products: Product[];
  productListings: IVisitDataResultProductListing[];
  matrix: {productId: string; questionnaireItemId: string; empty: boolean}[];
  photoList: IPhoto[];
}

export class VisitDataReportRow {
  tourPlanId: string;
  visitUid: string;
  visitState: TourPlanState;
  startedAt?: Date;
  visitDate?: Date;
  finishedAt?: Date;
  todoListResultId: string;
  chainId?: string;
  chainName?: string;
  storeId: string;
  store: Store;
  storeName: string;
  storeAddressId?: string;
  storeAddress?: Address;
  agencyRegion?: string;
  userId: string;
  userInfo?: UsernameResponse;
  scheduledDuration?: number;
  realDuration?: number;
  highlightColor?: string;

  // todoListItemId -> general action result (completed not-completed)
  // todoListItemId-questionnaireItemId -> questionnaire action results
  [prop: string]: any;
}
