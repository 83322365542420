import {Injectable} from '@angular/core';
import {Store} from 'src/app/shared/models';
import {
  IStoreActivityRequest,
  IExtendedPropertySearchRequest,
} from '@retrixhouse/salesapp-shared/lib/requests';
import {IStoreActivityResponse} from '@retrixhouse/salesapp-shared/lib/responses/store-activity';
import {IStoreDataService} from '../../interfaces/data-service';
import {BaseCrudOfflineService} from './base.offline-service';

@Injectable()
export class StoreOfflineService
  extends BaseCrudOfflineService<Store>
  implements IStoreDataService
{
  public getAllStores(): Promise<Store[]> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public getStoresForChain(chainId?: string | null): Promise<Store[]> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public evaluateStores(
    expression: string,
  ): Promise<{valid: boolean; total: number; match: number}> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public getStoreActivities(
    request: IStoreActivityRequest,
  ): Promise<IStoreActivityResponse> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public getStoresForExtendedProperties(request: IExtendedPropertySearchRequest): Promise<Store[]> {
    return Promise.reject('Offline service - not yet implemented');
  }
}
