import {IOrderDataService} from '../../interfaces/data-service';
import {Order, OrderReport, ReportRangeRequest} from '../../models';
import {OrderHttpService} from '../http';
import {OrderOfflineService} from '../offline';
import {BaseCrudDataService} from './base.data-service';

export class OrderDataService extends BaseCrudDataService<
  Order,
  IOrderDataService
> {
  constructor(
    onlineService: OrderHttpService,
    offlineService: OrderOfflineService,
  ) {
    super(onlineService, offlineService);
  }

  public getReport(rangeRequest: ReportRangeRequest): Promise<OrderReport[]> {
    return this.service.getReport(rangeRequest);
  }
}
