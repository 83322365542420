import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {ObjectTypeIds} from '@retrixhouse/salesapp-shared/lib/common';
import {
  IObjectFormGooglePlacesControlSetting,
  ValueType,
} from '@retrixhouse/salesapp-shared/lib/models';
import {InputSelectOption} from '@salesapp/components';
import {AutoUnsubscribe} from '@salesapp/utils/angular.utils';
import {FormControlsOf} from '@salesapp/utils/reactive-form/reactive-form.interface';
import {getTranslationMarkersForObjectProperty} from '@salesapp/utils/translation.utils';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {FormLayoutService} from '../../services/form-layout.service';
import {FormDesignerObjectFormGooglePlacesControlSettingFormControls} from '../form-designer-update-control-dialog/form-designer-update-control-dialog.component';

@Component({
  selector: 'app-form-designer-google-places-control-settings',
  templateUrl: './form-designer-google-places-control-settings.component.html',
  styleUrls: ['./form-designer-google-places-control-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@AutoUnsubscribe()
export class FormDesignerGooglePlacesControlSettingsComponent
  implements OnInit
{
  @Input()
  settingsFormGroup: FormGroup<FormDesignerObjectFormGooglePlacesControlSettingFormControls>;

  addressOptions$: Observable<InputSelectOption[]>;
  latitudeOptions$: Observable<InputSelectOption[]>;
  longitudeOptions$: Observable<InputSelectOption[]>;

  private formChangedSubscription: Subscription;

  constructor(
    private formLayoutService: FormLayoutService,
    private translateService: TranslateService,
  ) {}

  ngOnInit() {
    this.initOptions();
  }

  getFormControl(name: keyof IObjectFormGooglePlacesControlSetting) {
    return this.settingsFormGroup.get(name) as FormControl;
  }

  private initOptions() {
    this.latitudeOptions$ = combineLatest([
      this.formLayoutService.allProperties$,
      this.settingsFormGroup.valueChanges.pipe(startWith(null)),
    ]).pipe(
      map(([properties, formValue]) => {
        const values = this.settingsFormGroup.getRawValue();
        return properties
          .filter(property => {
            return (
              property.valueType === ValueType.Float &&
              property.id !== values.longitude
            );
          })
          .map(property => ({
            value: property.id,
            name: this.translateService.instant(
              getTranslationMarkersForObjectProperty({
                objectTypeId: property.typeId as ObjectTypeIds,
                propertyName: property.name,
              }).label,
            ),
          }));
      }),
    );

    this.longitudeOptions$ = combineLatest([
      this.formLayoutService.allProperties$,
      this.settingsFormGroup.valueChanges.pipe(startWith(null)),
    ]).pipe(
      map(([properties, formValue]) => {
        const values = this.settingsFormGroup.getRawValue();
        return properties
          .filter(property => {
            return (
              property.valueType === ValueType.Float &&
              property.id !== values.latitude
            );
          })
          .map(property => ({
            value: property.id,
            name: this.translateService.instant(
              getTranslationMarkersForObjectProperty({
                objectTypeId: property.typeId as ObjectTypeIds,
                propertyName: property.name,
              }).label,
            ),
          }));
      }),
    );

    this.addressOptions$ = this.formLayoutService.allProperties$.pipe(
      map(properties => {
        return properties
          .filter(property => {
            return (
              property.valueType === ValueType.RelationToOneForeignKey &&
              property.objectTypeId === ObjectTypeIds.Address
            );
          })
          .map(property => ({
            value: property.id,
            name: this.translateService.instant(
              getTranslationMarkersForObjectProperty({
                objectTypeId: property.typeId as ObjectTypeIds,
                propertyName: property.name,
              }).label,
            ),
          }));
      }),
    );
  }
}

type FormDesignerUpdateControlDialogFormControls =
  FormControlsOf<IObjectFormGooglePlacesControlSetting>;
