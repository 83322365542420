<app-dialog [title]="'entity.project.dialog.copy.title' | translate"
            [actionLabel]="'buttons.copy' | translate"
            [actionVisible]="true"
            [actionIcon]="'fa fa-check'"
            [actionDisabled]="!(copyActionEnabled$ | async)"
            [cancelLabel]="'buttons.close' | translate"
            [cancelVisible]="true"
            [cancelIcon]="'fa fa-times'"
            [formGroup]="form"
            (cancelClicked)="onClose()"
            (actionClicked)="onCopy()">

  <!-- PROGRESS BAR -->
  <div *ngIf="copyInProgress"
       class="copy-in-progress-container">
    <h4>{{ 'entity.project.dialog.copy.in-progress' | translate }}</h4>
    <dx-progress-bar [visible]="true"
                     width="100%"
                     [min]="0"
                     [max]="(copyProgress$ | async).totalRows"
                     [value]="(copyProgress$ | async).processedRows"
                     [class.complete]="(copyProgress$ | async).totalRows <= (copyProgress$ | async).processedRows">
    </dx-progress-bar>
  </div>

  <!-- FORM CONTROLS -->
  <ng-container *ngIf="!copyInProgress">
    <app-form-row>
      <app-form-column>
        <app-input-text [formControl]="getFormControl('sourceProjectUid')"
                        [formControlRef]="getFormControl('sourceProjectUid')"
                        [label]="'entity.project.properties.source-uid.label' | translate"></app-input-text>
      </app-form-column>
      <app-form-column>
        <app-input-text [formControl]="getFormControl('newProjectId')"
                        [formControlRef]="getFormControl('newProjectId')"
                        [label]="'entity.project.properties.new-id.label' | translate"></app-input-text>
      </app-form-column>
    </app-form-row>

    <app-form-row>
      <app-form-column>
        <app-input-text [formControl]="getFormControl('newExternalRef')"
                        [formControlRef]="getFormControl('newExternalRef')"
                        [label]="'entity.project.properties.new_external_ref.label' | translate"></app-input-text>
      </app-form-column>
      <app-form-column>
        <app-input-text [formControl]="getFormControl('name')"
                        [formControlRef]="getFormControl('name')"
                        [label]="'entity.project.properties.new-name.label' | translate"></app-input-text>
      </app-form-column>
    </app-form-row>

    <app-form-row>
      <app-form-column>
        <app-input-date [minDate]="minimumDate"
                        [formControl]="getFormControl('begin')"
                        [formControlRef]="getFormControl('begin')"
                        [label]="'entity.project.properties.begin.label' | translate"></app-input-date>
      </app-form-column>
      <app-form-column>
        <app-input-date [minDate]="minimumDate"
                        [formControl]="getFormControl('end')"
                        [formControlRef]="getFormControl('end')"
                        [label]="'entity.project.properties.end.label' | translate"></app-input-date>
      </app-form-column>
    </app-form-row>

    <app-form-row>
      <app-form-column>
        <dx-check-box class="checkbox"
                      [formControl]="getFormControl('settings')"
                      text="{{ 'entity.project.options.copy-settings.label' | translate }}"></dx-check-box>
      </app-form-column>
      <app-form-column>
        <dx-check-box class="checkbox"
                      [formControl]="getFormControl('responsibleUsers')"
                      text="{{ 'entity.project.options.copy-users.label' | translate }}"></dx-check-box>
      </app-form-column>
    </app-form-row>

    <app-form-row>
      <app-form-column>
        <dx-check-box class="checkbox"
                      [formControl]="getFormControl('positionPermissions')"
                      text="{{ 'entity.project.options.copy-positions.label' | translate }}"></dx-check-box>
      </app-form-column>
      <app-form-column>
        <dx-check-box class="checkbox"
                      [formControl]="getFormControl('tags')"
                      text="{{ 'entity.project.options.copy-tags.label' | translate }}"></dx-check-box>
      </app-form-column>
    </app-form-row>

    <app-form-row>
      <app-form-column>
        <dx-check-box class="checkbox"
                      [formControl]="getFormControl('kpiSets')"
                      text="{{ 'entity.project.options.copy-kpi.label' | translate }}"></dx-check-box>
      </app-form-column>
    </app-form-row>
  </ng-container>
</app-dialog>