import {CommonModule} from '@angular/common';
import {DxButtonModule, DxDataGridModule} from 'devextreme-angular';
import {FeedbackGridComponent} from './components/feedback-grid/feedback-grid.component';
import {FeedbackGridFilterComponent} from './components/feedback-grid-filter/feedback-grid-filter.component';
import {FeedbackGridService} from './services/feedback-grid.service';
import {FeedbackStorageService} from './services/feedback-storage.service';
import {FormModule} from '../form/index';
import {GridModule} from '../grid/index';
import {InputDateRangeModule} from '../../components/input-date-range/index';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {DialogModule} from '../dialog/index';
import {
  InputDateModule,
  InputMultiSelectModule,
  InputSelectModule,
  InputSwitchModule,
  UserAvatarModule,
} from '@salesapp/components';
import {FeedbackDetailComponent} from './components/feedback-detail/feedback-detail.component';
import {FeedbackDetailDialogComponent} from './components/feedback-detail-dialog/feedback-detail-dialog.component';
import {FeedbackService} from './services/feedback.service';
import {CustomPipesModule} from '@salesapp/pipes';
import {FeedbackDetailItemComponent} from './components/feedback-detail-item/feedback-detail-item.component';
import {PillModule} from '@salesapp/shared/components/pill';
import {ImgGalleryModule} from '@salesapp/shared/components/img-gallery/img-gallery.component';
import {FeedbackGridMyFilterComponent} from './components/feedback-grid-my-filter/feedback-grid-my-filter.component';

const DX_MODULES = [DxButtonModule, DxDataGridModule];

const SALESAPP_MODULES = [GridModule, DialogModule, FormModule];

const UI_MODULES = [
  ImgGalleryModule,
  InputDateModule,
  InputDateRangeModule,
  InputMultiSelectModule,
  InputSelectModule,
  InputSwitchModule,
  PillModule,
  UserAvatarModule,
];

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    CustomPipesModule,
    ...SALESAPP_MODULES,
    ...UI_MODULES,
    ...DX_MODULES,
  ],
  declarations: [
    FeedbackGridComponent,
    FeedbackGridFilterComponent,
    FeedbackGridMyFilterComponent,
    FeedbackDetailComponent,
    FeedbackDetailItemComponent,
    FeedbackDetailDialogComponent,
  ],
  providers: [FeedbackGridService, FeedbackStorageService, FeedbackService],
  exports: [
    FeedbackGridComponent,
    FeedbackGridFilterComponent,
    FeedbackGridMyFilterComponent,
    FeedbackDetailComponent,
    FeedbackDetailDialogComponent,
  ],
})
export class FeedbackModule {}

export * from './services/feedback-storage.service';
