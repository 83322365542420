/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { create } from '../fn/report-external-controller/create';
import { Create$Params } from '../fn/report-external-controller/create';
import { delete$ } from '../fn/report-external-controller/delete';
import { Delete$Params } from '../fn/report-external-controller/delete';
import { getAll } from '../fn/report-external-controller/get-all';
import { GetAll$Params } from '../fn/report-external-controller/get-all';
import { ReportExternal } from '../models/report-external';
import { update } from '../fn/report-external-controller/update';
import { Update$Params } from '../fn/report-external-controller/update';

@Injectable({ providedIn: 'root' })
export class ReportExternalControllerApiService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `reportExternalControllerDelete()` */
  static readonly ReportExternalControllerDeletePath = '/report-external/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `delete()` instead.
   *
   * This method doesn't expect any request body.
   */
  delete$Response(params: Delete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return delete$(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `delete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  delete(params: Delete$Params, context?: HttpContext): Observable<void> {
    return this.delete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `reportExternalControllerUpdate()` */
  static readonly ReportExternalControllerUpdatePath = '/report-external/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `update()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  update$Response(params: Update$Params, context?: HttpContext): Observable<StrictHttpResponse<ReportExternal>> {
    return update(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `update$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  update(params: Update$Params, context?: HttpContext): Observable<ReportExternal> {
    return this.update$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReportExternal>): ReportExternal => r.body)
    );
  }

  /** Path part for operation `reportExternalControllerGetAll()` */
  static readonly ReportExternalControllerGetAllPath = '/report-external';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAll$Response(params?: GetAll$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ReportExternal>>> {
    return getAll(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAll(params?: GetAll$Params, context?: HttpContext): Observable<Array<ReportExternal>> {
    return this.getAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ReportExternal>>): Array<ReportExternal> => r.body)
    );
  }

  /** Path part for operation `reportExternalControllerCreate()` */
  static readonly ReportExternalControllerCreatePath = '/report-external';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create$Response(params?: Create$Params, context?: HttpContext): Observable<StrictHttpResponse<ReportExternal>> {
    return create(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `create$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create(params?: Create$Params, context?: HttpContext): Observable<ReportExternal> {
    return this.create$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReportExternal>): ReportExternal => r.body)
    );
  }

}
