import {Injectable} from '@angular/core';
import {IReportDashboard} from '@retrixhouse/salesapp-shared/lib/models';
import {DataProvider} from '../../data.provider/data-provider';
import {OldBaseStorageService} from './old-base-storage-service';
@Injectable()
export class ReportDashboardStorageService extends OldBaseStorageService<
  IReportDashboard,
  IReportDashboard[]
> {
  constructor(private dataProvider: DataProvider) {
    super(dataProvider.reportDashboard);
  }

  upsert(input: Partial<IReportDashboard>) {
    return this.command({
      commandMethod$: this.upsertReportDashboard.bind(this),
      commandInput: input,
    });
  }

  private upsertReportDashboard(input: IReportDashboard) {
    return this.dataProvider.reportDashboard.upsert(input);
  }
}
