<!-- TODO: remove unused attributes -->
<app-dialog [title]="'entity.attachment-template.dialog.generate.title' | translate"
            [actionInProgress]="generating$ | async"
            [actionVisible]="true"
            [actionLabel]="'form.action.generate' | translate"
            [actionDisabled]=""
            [cancelLabel]="'form.action.close' | translate"
            [cancelVisible]="true"
            [cancelIcon]=""
            (cancelClicked)="onClose()"
            (actionClicked)="onGenerate()"
            [formGroup]="form">
  <app-form-row>
    <app-form-column>
      <app-input-select [formControl]="getFormControl('fileType')"
                        [formControlRef]="getFormControl('fileType')"
                        [label]="'entity.attachment-template.properties.generated-file-type.label' | translate"
                        [options]="fileTypesOptions"></app-input-select>
    </app-form-column>
  </app-form-row>
</app-dialog>