import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ObjectTypeIds} from '@retrixhouse/salesapp-shared/lib/common';
import {getGridServiceProvider} from '@salesapp/grid';
import {FileService, NotificationService} from '@salesapp/services';
import {AutoUnsubscribe} from '@salesapp/utils/angular.utils';
import {BehaviorSubject, Subscription} from 'rxjs';
import {delay} from 'rxjs/operators';
import {OBJECT_ATTACHMENT_ALLOWED_FILE_TYPES} from '../../object-attachment.consts';
import {ObjectAttachmentStorage} from '../../object-attachment.storage';
import {ObjectAttachmentsGridService} from '../../services/object-attachments-grid.service';
import {ObjectAttachmentsService} from '../../services/object-attachments.service';

@Component({
  selector: 'app-object-attachments',
  templateUrl: './object-attachments.component.html',
  styleUrls: ['./object-attachments.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    getGridServiceProvider(ObjectAttachmentsGridService),
    ObjectAttachmentStorage,
  ],
})
@AutoUnsubscribe()
export class ObjectAttachmentsComponent implements OnInit {
  @Input() objectId: string;
  @Input() objectTypeId: ObjectTypeIds;

  fileOver: boolean = false;

  processingFiles$ = new BehaviorSubject<boolean>(false);

  private allowedFileTypes = OBJECT_ATTACHMENT_ALLOWED_FILE_TYPES;

  private subscriptions: Subscription[] = [];

  constructor(
    private objectAttachmentsGridService: ObjectAttachmentsGridService,
    private fileService: FileService,
    private translateService: TranslateService,
    private objectAttachmentsService: ObjectAttachmentsService,
  ) {}

  ngOnInit() {
    this.objectAttachmentsGridService.setObjectId(this.objectId);
    this.objectAttachmentsGridService.setObjectTypeId(this.objectTypeId);
  }

  onFilesDropped(event: File[]) {
    if (event.length === 1) {
      this.processingFiles$.next(true);
      this.subscriptions.push(
        this.fileService
          .processFile({
            file: event[0],
            availableMimeTypes: this.allowedFileTypes,
            fileFormat: 'base64',
          })
          .pipe(delay(1000))
          .subscribe({
            next: base64File => {
              this.processingFiles$.next(false);
              this.objectAttachmentsService.createAttachment({
                objectId: this.objectId,
                file: event[0],
                base64File,
              });
            },
            error: error => {
              this.processingFiles$.next(false);
              NotificationService.notifyError(error);
            },
          }),
      );
    } else {
      // throw error
      NotificationService.notifyWarning(
        this.translateService.instant(
          'entity.object-attachment.grid.cannot-upload-multiple-files',
        ),
      );
    }
  }

  onFileOver(fileOver: boolean) {
    this.fileOver = fileOver;
  }
}
