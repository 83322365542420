import {BaseModel} from './base.model';
import {GenericList, GenericListItem} from './generic-list.model';
import {Customer} from './customer.model';
import {TodoList} from './todo-list.model';
import {PromoAction} from './promo-action.model';
import {
  IProject,
  IProjectKpiSetThreshold,
  IProjectTag,
  SettingValueType,
} from '@retrixhouse/salesapp-shared/lib/models';
import {
  ProjectContextPermissions,
} from '@retrixhouse/salesapp-shared/lib/common';
import memo from 'memo-decorator';

export class Project extends BaseModel implements IProject {
  uid: string;
  version: number;
  name: string;
  typeId: string;
  type: GenericListItem;
  categoryId: string;
  category: GenericListItem;
  begin: Date;
  end: Date;
  customerId: string;
  customer: Customer;
  todoListId: string;
  todoList: TodoList;
  settings: ProjectSettingValue[];
  enabledStores: ProjectEnabledStore[];
  responsibleUsers: ProjectResponsibleUser[];
  positionPermissions: ProjectPositionPermission[];
  promoActions: PromoAction[];
  externalRef: string;
  tags: IProjectTag[];

  kpiSetThresholds: IProjectKpiSetThreshold[];

  @memo({
    resolver: (project: IProject) => project?.id,
  })
  static formatProject(project: IProject): string {
    if (!project) {
      return '';
    }
    return `${project.name} - ${project.uid} - ${project.externalRef}`;
  }

  constructor() {
    super();
    this.name = null;
    this.typeId = null;
    this.categoryId = null;
    this.begin = null;
    this.end = null;
    this.customerId = null;
    this.todoListId = null;
    this.externalRef = null;
  }
}

export class ProjectSetting extends BaseModel {
  name: string;
  typeId: string;
  type: GenericListItem;
  valueType: SettingValueType;
  defaultValue: string;
  group: string;
  position: number;
  groupPosition: number;
  validationExpression: string;
  genericListId: string;
  answerOptions: GenericList;
  objectTypeId?: string;

  constructor() {
    super();
    this.name = null;
    this.typeId = null;
    this.valueType = null;
    this.defaultValue = null;
    this.group = null;
    this.genericListId = null;
  }
}

export class ProjectSettingValue extends BaseModel {
  projectId: string;
  settingId: string;
  value: any;
  projectSettingItem: ProjectSetting;

  constructor() {
    super();
    this.projectId = null;
    this.settingId = null;
    this.value = null;
  }
}

export class ProjectSettingWithValue extends ProjectSetting {
  valueId: string;
  value: any;

  constructor() {
    super();
    this.value = null;
    this.valueId = null;
  }
}

export class ProjectEnabledStore extends BaseModel {
  projectId: string;
  storeId: string;

  constructor() {
    super();
    this.projectId = null;
    this.storeId = null;
  }
}

export class ProjectResponsibleUser extends BaseModel {
  projectId: string;
  userId: string;
  superiorId?: string;
  allVisible: boolean;
  assignedAt: string;
  permissions?: string[];
  kpiSets?: string[];

  constructor() {
    super();
    this.projectId = null;
    this.userId = null;
    this.assignedAt = null;
  }
}

export class ProjectPositionPermission extends BaseModel {
  projectId: string;
  positionId: string;
  permissions: ProjectContextPermissions[];
  constructor() {
    super();
    this.projectId = null;
    this.positionId = null;
    this.permissions = [];
  }
}
