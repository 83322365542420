import {DIALOG_DATA, DialogRef} from '@angular/cdk/dialog';
import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {RoleNames} from '@retrixhouse/salesapp-shared/lib/common';
import {ObjectAttachment} from '@salesapp/api';
import {ObjectPermissionsFormControls} from '@salesapp/components';
import {CustomValidators, FormControlsOf} from '@salesapp/utils/reactive-form';
import * as uuid from 'uuid';
import {OBJECT_ATTACHMENT_ALLOWED_FILE_TYPES} from '../../object-attachment.consts';
import {
  ObjectAttachmentCreateParams,
  ObjectAttachmentStorage,
} from '../../object-attachment.storage';

@Component({
  selector: 'app-object-attachment-edit-dialog',
  templateUrl: './object-attachment-edit-dialog.component.html',
  styleUrls: ['./object-attachment-edit-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ObjectAttachmentEditDialogComponent {
  form: FormGroup<ObjectAttachmentEditDialogFormControls>;

  allowedFileTypes = OBJECT_ATTACHMENT_ALLOWED_FILE_TYPES;

  RoleNames = RoleNames;

  get isUpdate() {
    return this.data.attachment;
  }

  get permissionsFromGroup() {
    return this.form.controls.attachment.controls
      .permissions as FormGroup<ObjectPermissionsFormControls>;
  }

  get initialFiles() {
    return [this.data.file];
  }

  constructor(
    private dialogRef: DialogRef<void>,
    @Inject(DIALOG_DATA) private data: ObjectAttachmentEditDialogData,
    private formBuilder: FormBuilder,
    private objectAttachmentStorage: ObjectAttachmentStorage,
  ) {}

  ngOnInit() {
    this.initForm();
  }

  onClose() {
    this.dialogRef.close();
  }

  onSave() {
    const formData = this.form.getRawValue();
    if (this.isUpdate) {
      this.objectAttachmentStorage
        .update({
          objectId: formData.attachment.objectId,
          attachmentId: formData.attachment.id,
          body: formData.attachment,
        })
        .subscribe({
          next: () => this.dialogRef.close(),
        });
    } else {
      this.objectAttachmentStorage.create({body: formData}).subscribe({
        next: () => this.dialogRef.close(),
      });
    }
  }

  onFilesChange(files: File[]) {
    if (files) {
      const file = files[0];
      const attachmentGroup = this.form.controls.attachment;
      attachmentGroup.controls.fileName.setValue(file.name);
      attachmentGroup.controls.fileSize.setValue(file.size);
      attachmentGroup.controls.contentType.setValue(file.type);
    }
  }

  getFormControl(name: keyof ObjectAttachmentCreateParams['body']) {
    return this.form.get(name) as FormControl;
  }

  getAttachmentFormControl(
    name: keyof ObjectAttachmentCreateParams['body']['attachment'],
  ) {
    return this.form.controls.attachment.get(name) as FormControl;
  }

  private initForm() {
    const form = this.formBuilder.group<ObjectAttachmentEditDialogFormControls>(
      {
        attachment:
          this.formBuilder.group<ObjectAttachmentAttachmentFormControls>({
            attachedAt: this.formBuilder.control(null),
            contentType: this.formBuilder.control(null),
            fileName: this.formBuilder.control(null),
            fileSize: this.formBuilder.control(null),
            id: this.formBuilder.control(uuid.v4()),
            notes: this.formBuilder.control(null),
            objectId: this.formBuilder.control(this.data.objectId),
            templateId: this.formBuilder.control(null),
            userId: this.formBuilder.control(null),
            permissions: this.formBuilder.group<ObjectPermissionsFormControls>({
              deletePositionIds: this.formBuilder.control([]),
              deleteUserIds: this.formBuilder.control([]),
              readPositionIds: this.formBuilder.control([]),
              readUserIds: this.formBuilder.control([]),
              updatePositionIds: this.formBuilder.control([]),
              updateUserIds: this.formBuilder.control([]),
            }),
          }),
        fileData: this.formBuilder.control(null),
      },
    );

    if (this.data) {
      form.controls.attachment.patchValue(this.data.attachment);

      if (this.data.base64File && this.data.file) {
        form.controls.fileData.patchValue(this.data.base64File);
        const attachmentGroup = form.controls.attachment;
        attachmentGroup.controls.fileName.setValue(this.data.file.name);
        attachmentGroup.controls.fileSize.setValue(this.data.file.size);
        attachmentGroup.controls.contentType.setValue(this.data.file.type);
      }
    }

    if (!this.isUpdate) {
      form.controls.fileData.addValidators([CustomValidators.required]);
    }

    this.form = form;
  }
}

export interface ObjectAttachmentEditDialogData {
  objectId: string;
  attachment?: ObjectAttachment;
  file?: File;
  base64File?: string;
}

type ObjectAttachmentAttachmentFormControls = FormControlsOf<
  ObjectAttachmentCreateParams['body']['attachment']
>;

type ObjectAttachmentEditDialogFormControls = FormControlsOf<
  ObjectAttachmentCreateParams['body']
>;
