import {Injectable} from '@angular/core';
import {BaseCrudHttpService} from './base.http-service';
import {HttpClient} from '@angular/common/http';
import {Questionnaire} from '../../models';
import {IQuestionnaireDataService} from '../../interfaces/data-service';

@Injectable()
export class QuestionnaireHttpService
  extends BaseCrudHttpService<Questionnaire>
  implements IQuestionnaireDataService
{
  constructor(http: HttpClient) {
    super(http, '/questionnaires');
  }
}
