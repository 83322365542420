import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UsernameResponse} from '../../models/username-response.model';
import {BaseCrudHttpService} from './base.http-service';
import {IUserDataService} from '../../interfaces/data-service';
import {
  CreateUserRequest,
  User,
  UserProfile,
  WhoAmIResponse,
} from '../../models';
import {Filter} from '@loopback/filter';

@Injectable()
export class UserHttpService
  extends BaseCrudHttpService<User>
  implements IUserDataService
{
  constructor(http: HttpClient) {
    super(http, '/auth/users/');
  }

  public resetUserPassword(
    userId: string,
    oneTimePassword: string,
  ): Promise<void> {
    return this.POST<void>(`/auth/users/${userId}/reset-password`, {
      otp: oneTimePassword,
    });
  }

  public createUser(
    request: CreateUserRequest,
  ): Promise<{user: User; userProfile: UserProfile}> {
    return this.POST<{user: User; userProfile: UserProfile}>(
      '/auth/users',
      request,
    );
  }

  public existsUsername(username: string): Promise<boolean> {
    return this.GET<boolean>('/auth/usernames/exists', {
      params: {username: username},
    });
  }

  public getUsernames(): Promise<UsernameResponse[]> {
    return this.GET<UsernameResponse[]>('/auth/usernames');
  }

  getUserProfiles(filter?: Filter<UserProfile>): Promise<UserProfile[]> {
    return this.GET<UserProfile[]>(
      '/auth/user-profiles',
      filter ? {params: {filter: JSON.stringify(filter)}} : {},
    );
  }

  public getUserProfile(id: string): Promise<UserProfile> {
    return this.GET<UserProfile>(`/auth/user-profiles/${id}`);
  }

  public getUserProfileByUserId(userId: string) {
    return this.GET<UserProfile>(`/auth/user-profiles/user/${userId}`);
  }

  public updateUserProfile(
    id: string,
    profile: Partial<UserProfile>,
  ): Promise<UserProfile> {
    return this.PATCH(`/auth/user-profiles/${id}`, profile);
  }

  public updateUserProfilePicture(
    id: string,
    version: number,
    picture: string,
  ): Promise<UserProfile> {
    return this.PATCH(`/auth/user-profiles/${id}`, {
      id: id,
      version: version,
      picture: picture,
    });
  }

  public whoAmI(): Promise<WhoAmIResponse> {
    return this.GET<WhoAmIResponse>('/auth/who-am-i', {requestTimeout: 5_000});
  }

  public getMyProfile(): Promise<UserProfile> {
    return this.GET<UserProfile>('/auth/my-profile');
  }

  public getMyUsernameResponse(): Promise<UsernameResponse> {
    return this.GET<UsernameResponse>('/auth/my-username');
  }

  public updateMyProfile(profile: UserProfile): Promise<UserProfile> {
    return this.PATCH('/auth/my-profile', profile);
  }

  public uploadMyProfilePicture(pictureFile: FormData): Promise<string> {
    return this.POST('/auth/my-profile/picture', pictureFile, {
      responseType: 'text',
    });
  }

  public uploadUserProfilePicture(
    profileId: string,
    pictureFile: FormData,
  ): Promise<string> {
    return this.POST(`/auth/user-profiles/${profileId}/picture`, pictureFile, {
      responseType: 'text',
    });
  }

  public updateMyProfilePicture(
    id: string,
    version: number,
    picture: string,
  ): Promise<UserProfile> {
    return this.PATCH('/auth/my-profile', {
      id: id,
      version: version,
      picture: picture,
    });
  }

  public override getList(filter?: Filter<User>): Promise<User[]> {
    const defaultFilter = {
      include: ['customer', 'position'],
    };

    return super.getList(Object.assign({}, defaultFilter, filter));
  }

  public getProfileIdsForUserIds(
    userIds?: string[],
  ): Promise<{[key: string]: string}> {
    return this.POST<{[key: string]: string}>(
      'auth/user-profiles/profile-for-user-ids',
      userIds ?? [],
    );
  }

  public getMySubordinatesForProject(
    projectId: string,
    includeAdministrators?: boolean,
  ): Promise<UsernameResponse[]> {
    const params: {projectId?: string; includeAdministrators?: boolean} = {};
    if (projectId) {
      params.projectId = projectId;
    }

    if (includeAdministrators !== undefined) {
      params.includeAdministrators = includeAdministrators;
    }
    return this.GET<UsernameResponse[]>(`/auth/my-subordinates`, {params});
  }

  getVisibleUsers(
    projectIds?: string[],
    includeAdministrators?: boolean,
  ): Promise<UsernameResponse[]> {
    const params: {projectIds?: string; includeAdministrators?: boolean} = {};
    if (projectIds) {
      params.projectIds = projectIds.join(',');
    }

    if (includeAdministrators !== undefined) {
      params.includeAdministrators = includeAdministrators;
    }
    return this.GET<UsernameResponse[]>(`/auth/my-visible-users`, {params});
  }
}
