export class CreateUserRequest {
  // userUid: string;
  // profileUid: string;
  username: string;
  otp: string;
  positionId: string;
  customerId?: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  localeId?: string;
}
