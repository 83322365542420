import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
} from '@angular/core';

@Component({
  selector: 'app-copy-icon',
  templateUrl: './copy-icon.component.html',
  styleUrls: ['./copy-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CopyIconComponent implements OnDestroy {
  @Input() textToCopy: string;

  icon: 'fa-copy' | 'fa-check' = 'fa-copy';

  private feedbackDelay: any;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngOnDestroy() {
    clearTimeout(this.feedbackDelay);
  }

  onCopy() {
    clearTimeout(this.feedbackDelay);
    copyText(this.textToCopy);
    this.icon = 'fa-check';
    this.changeDetectorRef.detectChanges();
    this.feedbackDelay = setTimeout(() => {
      this.icon = 'fa-copy';
      this.changeDetectorRef.detectChanges();
    }, 1000);
  }
}

function copyText(text: string) {
  // Create new element
  const el = document.createElement('textarea');
  // Set value (string to be copied)
  el.value = text;
  // Set non-editable to avoid focus and move outside of view
  el.setAttribute('readonly', '');
  el.style.cssText = 'position: absolute; left: -9999px;';
  document.body.appendChild(el);
  // Select text inside element
  el.select();
  // Copy text to clipboard
  document.execCommand('copy');
  // Remove temporary element
  document.body.removeChild(el);
}
