import {ObjectMapCache} from '@retrixhouse/salesapp-shared/lib/caching';
import {IPersonalArrangementDataService} from '../../interfaces/data-service';
import {
  PersonalArrangement,
  PersonalArrangementReport,
  PersonalArrangementRequest,
} from '../../models';
import {PersonalArrangementHttpService} from '../http';
import {PersonalArrangementOfflineService} from '../offline';
import {BaseCrudDataService} from './base.data-service';

export class PersonalArrangementDataService extends BaseCrudDataService<
  PersonalArrangement,
  IPersonalArrangementDataService,
  ObjectMapCache<string, PersonalArrangement>
> {
  constructor(
    onlineService: PersonalArrangementHttpService,
    offlineService: PersonalArrangementOfflineService,
    cache: ObjectMapCache<string, PersonalArrangement>,
  ) {
    super(onlineService, offlineService, cache);
  }

  public getReport(
    request: PersonalArrangementRequest,
  ): Promise<PersonalArrangementReport[]> {
    return this.service.getReport(request);
  }
}
