import {BaseModel} from './base.model';
import {ITag} from '@retrixhouse/salesapp-shared/lib/models';

export class Tag extends BaseModel implements ITag {
  name: string;
  description?: string;
  color?: string;
  parentId?: string;
  position: number;

  constructor() {
    super();
  }
}
