import {ObjectMapCache} from '@retrixhouse/salesapp-shared/lib/caching';
import {IPeriodicLimitDataService} from '../../interfaces/data-service';
import {PeriodicLimit, ProjectStorePeriodicLimit} from '../../models';
import {PeriodicLimitHttpService} from '../http';
import {PeriodicLimitOfflineService} from '../offline';
import {BaseCrudDataService} from './base.data-service';
import {PeriodicLimitType} from '@retrixhouse/salesapp-shared/lib/models';

export class PeriodicLimitDataService extends BaseCrudDataService<
  PeriodicLimit,
  IPeriodicLimitDataService,
  ObjectMapCache<string, PeriodicLimit>
> {
  constructor(
    onlineService: PeriodicLimitHttpService,
    offlineService: PeriodicLimitOfflineService,
    cache: ObjectMapCache<string, PeriodicLimit>,
  ) {
    super(onlineService, offlineService, cache);
  }

  public getPeriodicLimitsForAvailableStoresStore(): Promise<
    ProjectStorePeriodicLimit[]
  > {
    return this.service.getPeriodicLimitsForAvailableStoresStore();
  }

  public getPeriodicLimitsForStore(
    storeId: string,
  ): Promise<ProjectStorePeriodicLimit[]> {
    return this.service.getPeriodicLimitsForStore(storeId);
  }

  public createPeriodicLimitForStore(
    periodicLimit: ProjectStorePeriodicLimit,
  ): Promise<ProjectStorePeriodicLimit> {
    return this.service.createPeriodicLimitForStore(periodicLimit);
  }

  public deletePeriodicLimitForStore(id: string): Promise<void> {
    return this.service.deletePeriodicLimitForStore(id);
  }

  public setPeriodicLimitsForStore(
    storeId: string,
    periodicLimits: ProjectStorePeriodicLimit[],
  ): Promise<ProjectStorePeriodicLimit[]> {
    return this.service.setPeriodicLimitsForStore(storeId, periodicLimits);
  }

  public validatePeriodicLimit(
    type: PeriodicLimitType,
    offset: number,
    duration: number,
  ): Promise<boolean> {
    return this.service.validatePeriodicLimit(type, offset, duration);
  }
}
