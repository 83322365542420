import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {NoticeComponent} from './notice.component';

@NgModule({
  imports: [CommonModule],
  declarations: [NoticeComponent],
  providers: [],
  exports: [NoticeComponent],
})
export class NoticeModule {}

export * from './notice.component';
