import {DIALOG_DATA, DialogRef} from '@angular/cdk/dialog';
import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {ReportExternalViewMode} from '@retrixhouse/salesapp-shared/lib/models';
import {ReportExternal} from '@salesapp/api';
import {InputMultiselectOption, InputSelectOption} from '@salesapp/components';
import {ExternalReportStorage, UserStorageService} from '@salesapp/storage';
import {AutoUnsubscribe} from '@salesapp/utils/angular.utils';
import {InputSelectOptions} from '@salesapp/utils/input-select-options';
import {CustomValidators, FormControlsOf} from '@salesapp/utils/reactive-form';
import {Observable, Subscription} from 'rxjs';
import * as uuid from 'uuid';

@Component({
  selector: 'app-external-report-edit-dialog',
  templateUrl: './external-report-edit-dialog.component.html',
  styleUrls: ['./external-report-edit-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@AutoUnsubscribe()
export class ExternalReportEditDialogComponent {
  form: FormGroup<ExternalReportEditDialogFormControls>;

  viewModeOptions: InputSelectOption[];

  userOptions$: Observable<InputMultiselectOption[]>;
  actionInProgress$: Observable<boolean>;

  private formChangedSubscription: Subscription;

  get isUpdate() {
    return this.data?.externalReport;
  }

  constructor(
    private dialogRef: DialogRef<void>,
    @Inject(DIALOG_DATA) private data: ExternalReportEditDialogData,
    private formBuilder: FormBuilder,
    private userStorageService: UserStorageService,
    private translateService: TranslateService,
    private externalReportStorage: ExternalReportStorage,
  ) {
    this.userOptions$ = this.userStorageService.dataAsSelectOptions$;
    this.actionInProgress$ = this.externalReportStorage.dataCommandInProgress$;
  }

  ngOnInit() {
    this.setViewModeOptions();
    this.initForm();
  }

  getFormControl(name: keyof ExternalReportEditDialogFormControls) {
    return this.form.get(name) as FormControl;
  }

  onClose() {
    this.dialogRef.close();
  }

  onSave() {
    const formData = this.form.getRawValue();
    if (this.isUpdate) {
      this.externalReportStorage
        .update({
          id: formData.id,
          body: formData,
        })
        .subscribe({
          next: () => this.dialogRef.close(),
        });
    } else {
      this.externalReportStorage.create({body: formData}).subscribe({
        next: () => this.dialogRef.close(),
      });
    }
  }

  private initForm() {
    const form = this.formBuilder.group<ExternalReportEditDialogFormControls>({
      id: this.formBuilder.control(uuid.v4(), [CustomValidators.required]),
      name: this.formBuilder.control(null, [CustomValidators.required]),
      url: this.formBuilder.control(null, [CustomValidators.required]),
      viewMode: this.formBuilder.control(null, [CustomValidators.required]),
      width: this.formBuilder.control({value: undefined, disabled: true}),
      height: this.formBuilder.control({value: undefined, disabled: true}),
      userIds: this.formBuilder.control([]),
    });

    if (this.data) {
      form.patchValue(this.data.externalReport);

      if (this.data.externalReport.viewMode === ReportExternalViewMode.Popup) {
        form.controls.width.enable();
        form.controls.height.enable();
      }
    }

    this.form = form as FormGroup<ExternalReportEditDialogFormControls>;

    this.formChangedSubscription = this.form.valueChanges.subscribe({
      next: value => {
        if (value.viewMode === ReportExternalViewMode.Popup) {
          this.form.controls.width.enable({emitEvent: false});
          this.form.controls.height.enable({emitEvent: false});
        } else {
          this.form.controls.width.disable({emitEvent: false});
          this.form.controls.width.setValue(undefined, {emitEvent: false});
          this.form.controls.height.disable({emitEvent: false});
          this.form.controls.height.setValue(undefined, {emitEvent: false});
        }
      },
    });
  }

  private setViewModeOptions() {
    this.viewModeOptions = InputSelectOptions.enumToSelectOptions({
      enumName: 'ReportExternalViewMode',
      translateService: this.translateService,
    });
  }
}

export interface ExternalReportEditDialogData {
  externalReport: ReportExternal;
}

type ExternalReportEditDialogFormControls = FormControlsOf<ReportExternal>;
