import {Gender, IUserProfile} from '@retrixhouse/salesapp-shared/lib/models';
import {Address} from './address.model';
import {Currency} from './currency.model';
import {Language} from './language.model';
import {Locale} from './locale.model';
import {UnitSystem} from './unit.model';

export class UserProfile implements IUserProfile {
  id: string;
  version: number;
  uid: string;
  userId: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  titlesBefore?: string;
  titlesAfter?: string;
  gender?: Gender;
  picture?: string;
  languageId?: string;
  language?: Language;
  localeId?: string;
  locale?: Locale;
  currencyId?: string;
  currency?: Currency;
  email?: string;
  phone?: string;
  birthDate?: Date;
  personalId?: string;
  iban?: string;
  homeAddressId?: string;
  homeAddress?: Address;
  correspondenceAddressId?: string;
  correspondenceAddress?: Address;
  unitSystem: UnitSystem;
  updateRequiredAt: Date;
  extendedProperties: any;
}
