import {ChangeDetectorRef, Pipe, PipeTransform} from '@angular/core';
import {TranslatePipe, TranslateService} from '@ngx-translate/core';

@Pipe({
  name: 't',
})
export class TranslateExtendedPipe
  extends TranslatePipe
  implements PipeTransform
{
  constructor(translate: TranslateService, changeDetector: ChangeDetectorRef) {
    super(translate, changeDetector);
  }

  transform(value: string, options: {default: string | null}) {
    const translation = super.transform(value);
    if (translation === value) {
      return options.default;
    }
    return translation;
  }
}
