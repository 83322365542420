import {DIALOG_DATA, DialogRef} from '@angular/cdk/dialog';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
} from '@angular/forms';
import {SettingNames} from '@retrixhouse/salesapp-shared/lib/settings';
import {UserStorageService} from '../../services/storage/user-storage.service';
import {IClientSideErrorConf} from '@retrixhouse/salesapp-shared/lib/models';
import {CustomValidators} from '../../utils/reactive-form/form-validators';
import {FormControlsOf} from '../../utils/reactive-form/reactive-form.interface';
import {Router} from '@angular/router';
import {processRoutesToListOfRoutes} from '../../utils/routes.utils';
import {InputMultiselectOption} from '../../components';
import {ClientSideErrorConfStorageService} from '../../services/storage/client-side-error-conf-storage.service';
import * as uuid from 'uuid';
import {map} from 'rxjs/operators';
import {Observable, combineLatest} from 'rxjs';
import {InputSelectOption} from '../../components/input-select/input-select.component';

@Component({
  selector: 'app-client-side-error-conf-dialog',
  templateUrl: './client-side-error-conf-dialog.component.html',
  styleUrls: ['./client-side-error-conf-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientSideErrorConfDialogComponent implements OnInit {
  form: FormGroup;

  routesOptions: InputMultiselectOption[] = [];

  usersOptions$: Observable<InputSelectOption[]>;

  disableActions$: Observable<boolean>;

  get isUpdate() {
    return !!this.data?.setting;
  }

  constructor(
    private dialogRef: DialogRef<string>,
    @Inject(DIALOG_DATA) private data: ClientSideErrorConfDialogData,
    private formBuilder: FormBuilder,
    private userStorageService: UserStorageService,
    private router: Router,
    private clientSideErrorConfStorageService: ClientSideErrorConfStorageService,
  ) {}

  ngOnInit() {
    this.initForm();
    this.initUserOptions();
    this.initRouteOptions();
    this.disableActions$ =
      this.clientSideErrorConfStorageService.dataCommandInProgress$;
  }

  getFormControl(name: string) {
    return this.form.get(name) as FormControl;
  }

  onSave() {
    const input: IClientSideErrorConf = this.form.getRawValue();
    if (this.isUpdate) {
      this.clientSideErrorConfStorageService
        .update({
          id: input.id,
          data: input,
        })
        .subscribe({next: () => this.dialogRef.close()});
    } else {
      this.clientSideErrorConfStorageService
        .create(input)
        .subscribe({next: () => this.dialogRef.close()});
    }
  }

  onClose() {
    this.dialogRef.close();
  }

  private initForm() {
    const form = this.formBuilder.group<ClientSideErrorFormGroup>({
      id: this.formBuilder.control(uuid.v4(), [CustomValidators.required]),
      userId: this.formBuilder.control(null, [CustomValidators.required]),
      routes: this.formBuilder.control([], [CustomValidators.required]),
      enabled: this.formBuilder.control(true),
      note: this.formBuilder.control(null),
    });

    if (this.data?.setting) {
      form.patchValue(this.data.setting);
      form.controls.userId.disable();
    }

    this.form = form;
  }

  private initRouteOptions() {
    const allRoutes = [];
    processRoutesToListOfRoutes(allRoutes, this.router.config, '');

    this.routesOptions = allRoutes.map(route => ({
      value: route.path,
      name: route.path,
    }));
  }

  private initUserOptions() {
    this.usersOptions$ = combineLatest([
      this.userStorageService.dataAsSelectOptions$,
      this.clientSideErrorConfStorageService.data$,
    ]).pipe(
      map(([userOptions, confs]) => {
        if (confs?.length) {
          const configuredUsers = confs.map(conf => conf.userId);
          return userOptions.filter(user => {
            if (user.value === this.form.getRawValue().userId) {
              return true;
            } else {
              return !configuredUsers.includes(user.value);
            }
          });
        } else {
          return userOptions;
        }
      }),
    );
  }
}

export interface ClientSideErrorConfDialogData {
  setting?: IClientSideErrorConf;
}

type ClientSideErrorFormGroup = FormControlsOf<IClientSideErrorConf>;
