import {Injectable} from '@angular/core';
import {OldBaseStorageService} from './old-base-storage-service';
import {DataProvider} from '../../data.provider/data-provider';
import {ILocale} from '@retrixhouse/salesapp-shared/lib/models';
import {Filter} from '@loopback/filter';
import {I18NDataService} from '../data';
import {Observable, from} from 'rxjs';

@Injectable()
export class LocaleStorageService extends OldBaseStorageService<
  ILocale,
  ILocale[]
> {
  constructor(dataProvider: DataProvider) {
    super(dataProvider.i18N);
  }

  getData(input?: {filter?: Filter<ILocale>}): Observable<ILocale[]> {
    return from((this.dataService as I18NDataService).getEnabledLocales());
  }
}
