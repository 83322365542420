import {IUser} from '@retrixhouse/salesapp-shared/lib/models';
import {Observable} from 'rxjs';
import {UserProfileStorageService, UserStorageService} from '@salesapp/storage';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  ObjectTypeIds,
  RoleNames,
} from '@retrixhouse/salesapp-shared/lib/common';
import {
  ObjectDetailDynamicFormComponent,
  ObjectDetailForm,
} from '@salesapp/shared/modules';

@Component({
  selector: 'app-user-management-user-form',
  templateUrl: './user-management-user-form.component.html',
  styleUrls: ['./user-management-user-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserManagementUserFormComponent
  implements ObjectDetailForm<IUser>
{
  @Input() user: Partial<IUser>;

  @Output() actionCompleted = new EventEmitter<IUser>();
  @Output() cancel = new EventEmitter<void>();

  actionInProgress$: Observable<boolean>;

  ObjectTypeIds = ObjectTypeIds;

  readonlyRoles = [RoleNames.UsersView];
  editRoles = [RoleNames.Users, RoleNames.UsersEdit, RoleNames.Admin];

  @ViewChild(ObjectDetailDynamicFormComponent)
  private objectDetailDynamicForm: ObjectDetailDynamicFormComponent;

  get hasChanges() {
    return this.objectDetailDynamicForm.hasChanges;
  }

  constructor(
    private userStorageService: UserStorageService,
    private userProfileStorageService: UserProfileStorageService,
  ) {}

  ngOnInit() {
    this.actionInProgress$ = this.userStorageService.dataCommandInProgress$;
  }

  onAction(formData: IUser) {
    if (formData.version) {
      return this.userStorageService
        .update({
          id: formData.id,
          data: formData,
        })
        .subscribe({
          next: data => {
            this.userProfileStorageService.markAsObsoleted();
            this.actionCompleted.emit(data);
          },
        });
    } else {
      return this.userStorageService.create(formData).subscribe({
        next: data => {
          this.actionCompleted.emit(data);
        },
      });
    }
  }

  onCancel() {
    this.cancel.emit();
  }
}
