import {Injectable} from '@angular/core';
import {OldBaseStorageService} from './old-base-storage-service';
import {DataProvider} from '../../data.provider/data-provider';
import {IProductCategory} from '@retrixhouse/salesapp-shared/lib/models';

@Injectable()
export class ProductCategoryStorageService extends OldBaseStorageService<
  IProductCategory,
  IProductCategory[]
> {
  constructor(dataProvider: DataProvider) {
    super(dataProvider.productCategory);
  }
}
