import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseCrudHttpService} from './base.http-service';
import {QuestionnaireItem} from '../../models';
import {IQuestionnaireItemDataService} from '../../interfaces/data-service';

@Injectable()
export class QuestionnaireItemHttpService
  extends BaseCrudHttpService<QuestionnaireItem>
  implements IQuestionnaireItemDataService
{
  constructor(http: HttpClient) {
    super(http, '/questionnaire-items');
  }
}
