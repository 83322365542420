import {Injectable} from '@angular/core';
import {OldBaseStorageService} from './old-base-storage-service';
import {DataProvider} from '../../data.provider/data-provider';
import {IPeriodicLimit} from '@retrixhouse/salesapp-shared/lib/models';

@Injectable()
export class PeriodicLimitStorageService extends OldBaseStorageService<
  IPeriodicLimit,
  IPeriodicLimit[]
> {
  constructor(dataProvider: DataProvider) {
    super(dataProvider.periodicLimit);
  }
}
