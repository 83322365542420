import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Currency} from 'src/app/shared/models';
import {BaseCrudHttpService} from './base.http-service';
import {ICurrencyDataService} from '../../interfaces/data-service';

@Injectable()
export class CurrencyHttpService
  extends BaseCrudHttpService<Currency>
  implements ICurrencyDataService
{
  constructor(http: HttpClient) {
    super(http, '/core/currencies');
  }

  public getEnabled(): Promise<Currency[]> {
    return this.GET<Currency[]>(`/core/currencies/enabled`);
  }

  getDefault(): Promise<Currency> {
    return this.GET<Currency>(`/core/currencies/default`);
  }
}
