import {AttachmentTemplateStorage} from './attachment-template.storage';
import {ChainStorageService} from './chain-storage.service';
import {ClientSideErrorConfStorageService} from './client-side-error-conf-storage.service';
import {ClientSideErrorStorageService} from './client-side-error-storage.service';
import {CountryStorageService} from './country-storage.service';
import {CurrencyStorageService} from './currency-storage.service';
import {CurrentUserStorageService} from './current-user-storage.service';
import {CustomerStorageService} from './customer-storage.service';
import {EventBus} from './event-bus';
import {ExternalReportStorage} from './external-report.storage';
import {FileStorageService} from './file-storage.service';
import {FolderStorageService} from './folder-storage.service';
import {GenericListItemStorageService} from './generic-list-item-storage.service';
import {GenericListStorageService} from './generic-list-storage.service';
import {I18nStorageService} from './i18n-storage.service';
import {LocaleStorageService} from './locale-storage.service';
import {NewsPostStorageService} from './news-post-storage.service';
import {ObjectStorageService} from './object-storage.service';
import {ObjectTypeStorageService} from './object-type-storage.service';
import {PeriodicLimitStorageService} from './periodic-limit-storage.service';
import {PositionStorageService} from './position-storage.service';
import {ProductCategoryStorageService} from './product-category-storage.service';
import {ProductStorageService} from './product-storage.service';
import {ProductListingStorageService} from './product-listing-storage.service';
import {ProjectStorageService} from './project-storage.service';
import {PushNotificationStorage} from './push-notification.storage';
import {ReportDashboardStorageService} from './report-dashboard-storage.service';
import {StorageService} from './storage.service';
import {StoreStorageService} from './store-storage.service';
import {UnitStorageService} from './unit-storage.service';
import {UserProfileStorageService} from './user-profile-storage.service';
import {UserStorageStorageService} from './user-storage-storage.service';
import {UserStorageService} from './user-storage.service';
import {UserSubordinatesStorageService} from './user-subordinates-storage.service';
import {UsernameStorageService} from './username-storage.service';
import {VisibleUsersStorageService} from './visible-users-storage.service';

export const STORAGE_SERVICES = [
  EventBus,
  AttachmentTemplateStorage,
  ChainStorageService,
  ClientSideErrorConfStorageService,
  ClientSideErrorStorageService,
  CountryStorageService,
  CurrencyStorageService,
  CurrentUserStorageService,
  CustomerStorageService,
  ExternalReportStorage,
  FileStorageService,
  FolderStorageService,
  GenericListItemStorageService,
  GenericListStorageService,
  I18nStorageService,
  LocaleStorageService,
  ObjectStorageService,
  ObjectTypeStorageService,
  PeriodicLimitStorageService,
  PositionStorageService,
  ProductCategoryStorageService,
  ProductStorageService,
  ProductListingStorageService,
  ProjectStorageService,
  PushNotificationStorage,
  ReportDashboardStorageService,
  StorageService,
  StoreStorageService,
  UnitStorageService,
  UsernameStorageService,
  UserProfileStorageService,
  UserStorageService,
  UserStorageStorageService,
  UserSubordinatesStorageService,
  VisibleUsersStorageService,
  NewsPostStorageService,
];

// export * from './old-base-storage-service';
export * from './attachment-template.storage';
export * from './base.storage';
export * from './chain-storage.service';
export * from './client-side-error-conf-storage.service';
export * from './client-side-error-storage.service';
export * from './country-storage.service';
export * from './currency-storage.service';
export * from './current-user-storage.service';
export * from './customer-storage.service';
export * from './event-bus';
export * from './external-report.storage';
export * from './file-storage.service';
export * from './folder-storage.service';
export * from './generic-list-item-storage.service';
export * from './generic-list-storage.service';
export * from './i18n-storage.service';
export * from './locale-storage.service';
export * from './news-post-storage.service';
export * from './object-storage.service';
export * from './object-type-storage.service';
export * from './periodic-limit-storage.service';
export * from './position-storage.service';
export * from './product-category-storage.service';
export * from './product-storage.service';
export * from './product-listing-storage.service';
export * from './project-storage.service';
export * from './push-notification.storage';
export * from './report-dashboard-storage.service';
export * from './storage.service';
export * from './store-storage.service';
export * from './unit-storage.service';
export * from './user-profile-storage.service';
export * from './user-storage-storage.service';
export * from './user-storage.service';
export * from './username-storage.service';
export * from './visible-users-storage.service';
