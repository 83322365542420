import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {IUsernameResponse} from '@retrixhouse/salesapp-shared/lib/responses';
import {SettingNames} from '@retrixhouse/salesapp-shared/lib/settings';
import {formatStore} from '@retrixhouse/salesapp-shared/lib/utils';
import {InputMultiselectOption} from '@salesapp/components';
import {DataProvider} from '@salesapp/data-provider';
import {GridFilterDataFormatted} from '@salesapp/grid';
import {AuthGuardService} from '@salesapp/services';
import {sortByKey} from '@salesapp/shared/globals';
import {formatDateTime} from '@salesapp/shared/pipes/localized-datetime.pipe';
import {CurrentUserStorageService} from '@salesapp/shared/services/storage/current-user-storage.service';
import {
  ProjectStorageService,
  StoreStorageService,
  VisibleUsersStorageService,
} from '@salesapp/storage';
import {AutoUnsubscribe} from '@salesapp/utils/angular.utils';
import {CustomValidators} from '@salesapp/utils/reactive-form/form-validators';
import {removeEmptyValues} from '@salesapp/utils/reactive-form/form.utils';
import {FormControlsOf} from '@salesapp/utils/reactive-form/reactive-form.interface';
import {paramCase} from 'change-case';
import * as moment from 'moment';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {map} from 'rxjs/operators';
import {FeedbackFilter} from '../../feedback.interfaces';
import {FeedbackGridService} from '../../services/feedback-grid.service';

@Component({
  selector: 'app-feedback-grid-my-filter',
  templateUrl: './feedback-grid-my-filter.component.html',
  styleUrls: ['./feedback-grid-my-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@AutoUnsubscribe()
export class FeedbackGridMyFilterComponent implements OnInit {
  form: FormGroup<FeedbackMyFilterFormGroup>;

  collapsed = false;

  projectOptions$ = this.projectStorageService.dataAsSelectOptions$;
  storeOptions$ = this.storeStorageService.dataAsSelectOptions$;
  usernameOptions$: Observable<InputMultiselectOption<IUsernameResponse>[]>;

  filterFormatted$: Observable<GridFilterDataFormatted[]>;

  private currentUser: IUsernameResponse;

  private createdByMeValueChangeSubscription: Subscription;
  private createdForMeValueChangeSubscription: Subscription;
  private currentUserSubscription: Subscription;

  get formValue() {
    return this.form.getRawValue();
  }

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthGuardService,
    private projectStorageService: ProjectStorageService,
    private storeStorageService: StoreStorageService,
    private visibleUsersStorageService: VisibleUsersStorageService,
    private translationService: TranslateService,
    private feedbackGridService: FeedbackGridService,
    private dataProvider: DataProvider,
    private changeDetector: ChangeDetectorRef,
    private currentUserStorageService: CurrentUserStorageService,
  ) {}

  ngOnInit() {
    this.initForm();
  }

  getFormGroupControl(name: string) {
    return this.form.get(name) as FormControl;
  }

  onFormAction() {
    this.collapsed = !this.collapsed;
    this.updateFilter();
  }

  onDeleteFilterItem(key: string) {
    const control = this.form.get(key);

    if (control) {
      control.setValue(null);
      this.updateFilter();
    }
  }

  private updateFilter() {
    this.feedbackGridService.updateFilter(
      this.filterToFeedbackFilter(this.form.getRawValue() as any),
    );
  }

  private initForm() {
    this.currentUserSubscription =
      this.currentUserStorageService.data$.subscribe({
        next: data => {
          if (data) {
            this.feedbackGridService.updateFilter({
              from: moment().startOf('month').toDate(),
              to: moment().endOf('month').toDate(),
              executorIds: [data.id],
            });

            this.currentUser = data;

            const maxReportDays = this.dataProvider.settingResolver.getValue(
              SettingNames.Common_MaxReportDays,
            );

            this.form = this.formBuilder.group<FeedbackMyFilterFormGroup>({
              projects: this.formBuilder.control(null),
              store: this.formBuilder.control(null),
              fromTo: this.formBuilder.control(
                [
                  moment().startOf('month').toDate(),
                  moment().endOf('month').toDate(),
                ],
                [
                  CustomValidators.required,
                  CustomValidators.validateDateRange(maxReportDays || 93),
                ],
              ) as any,
              createdByMe: this.formBuilder.control(false),
              createdForMe: this.formBuilder.control(true),
            });

            this.initFormattedData();

            this.createdByMeValueChangeSubscription =
              this.form.controls.createdByMe.valueChanges.subscribe({
                next: value => {
                  if (value) {
                    this.form.controls.createdForMe.setValue(false, {
                      emitEvent: false,
                    });
                  } else {
                    this.form.controls.createdForMe.setValue(true, {
                      emitEvent: false,
                    });
                  }
                },
              });

            this.createdForMeValueChangeSubscription =
              this.form.controls.createdForMe.valueChanges.subscribe({
                next: value => {
                  if (value) {
                    this.form.controls.createdByMe.setValue(false, {
                      emitEvent: false,
                    });
                  } else {
                    this.form.controls.createdByMe.setValue(true, {
                      emitEvent: false,
                    });
                  }
                },
              });
          }

          this.changeDetector.detectChanges();
        },
      });
  }

  private filterToFeedbackFilter(
    data: Partial<FeedbackMyFilterForm>,
  ): FeedbackFilter {
    const filter = {
      storeId: data.store,
      executorIds: data.createdForMe ? [this.currentUser.id] : [],
      createdByIds: data.createdByMe ? [this.currentUser.id] : [],
      projectIds: data.projects || [],
      from: data.fromTo[0],
      to: data.fromTo[1],
      isMyFeedback: true,
    };
    return removeEmptyValues(filter);
  }

  private createTranslationMarker(key: string) {
    return `entity.feedback.filter.${paramCase(key)}.label`;
  }

  private initFormattedData() {
    this.filterFormatted$ = combineLatest([
      this.form.valueChanges,
      this.projectStorageService.dataById$,
      this.storeStorageService.dataById$,
      this.visibleUsersStorageService.dataById$,
    ]).pipe(
      map(([formValue, projectById, storeById, usernameById]) => {
        const filterFormatted: GridFilterDataFormatted[] = [];
        Object.entries(formValue).forEach(([key, value]) => {
          if (value) {
            switch (key) {
              case 'projects':
                filterFormatted.push({
                  label: this.translationService.instant(
                    this.createTranslationMarker(key),
                  ),
                  value: (value as string[])
                    .map(id => projectById.get(id as string).name)
                    .join(', '),
                  position: 1,
                  key,
                });
                break;
              case 'store':
                filterFormatted.push({
                  label: this.translationService.instant(
                    this.createTranslationMarker(key),
                  ),
                  value: formatStore(storeById.get(value as string)),
                  position: 2,
                  key,
                });
                break;
              case 'fromTo':
                filterFormatted.push({
                  label: this.translationService.instant(
                    this.createTranslationMarker(key),
                  ),
                  value: (value as string[])
                    .map(date => formatDateTime(date, 'L'))
                    .join(' - '),
                  position: 3,
                  key,
                  required: true,
                });
                break;
              case 'createdForMe':
                filterFormatted.push({
                  label: this.translationService.instant(
                    this.createTranslationMarker(key),
                  ),
                  value: this.translationService.instant('labels.yes'),
                  position: 4,
                  key,
                });
                break;
              case 'createdByMe':
                filterFormatted.push({
                  label: this.translationService.instant(
                    this.createTranslationMarker(key),
                  ),
                  value: this.translationService.instant('labels.yes'),
                  position: 5,
                  key,
                });
                break;
            }
          }
        });
        return sortByKey(filterFormatted, 'position');
      }),
    );
  }
}

interface FeedbackMyFilterForm {
  projects: string[];
  store: string;
  fromTo: Date[];
  createdByMe: boolean;
  createdForMe: boolean;
}

type FeedbackMyFilterFormGroup = FormControlsOf<FeedbackMyFilterForm>;
