import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Position, UserPositionInProject} from 'src/app/shared/models';
import {BaseCrudHttpService} from './base.http-service';
import {IPositionDataService} from '../../interfaces/data-service';

@Injectable()
export class PositionHttpService
  extends BaseCrudHttpService<Position>
  implements IPositionDataService
{
  constructor(http: HttpClient) {
    super(http, '/positions');
  }

  public getForCustomer(customerId?: string): Promise<Position[]> {
    return this.GET<Position[]>('/positions', {
      params: {
        customerId,
      },
    });
  }

  public getForUser(): Promise<Position> {
    return this.GET<Position>('/positions/current');
  }

  public resetPositionStructure(
    customerId: string | null,
  ): Promise<Position[]> {
    return this.POST<Position[]>(`/positions/reset`, {customerId});
  }

  public getUserPositionInProjectContext(
    userProjectPairs: {userId: string; projectId: string}[],
  ): Promise<UserPositionInProject[]> {
    return this.POST('/positions/project-context', userProjectPairs);
  }

  public getRoles(id: string): Promise<string[]> {
    return this.GET<string[]>(`/positions/${id}/roles`);
  }

  public setRoles(id: string, roles: string[]): Promise<void> {
    return this.POST(`/positions/${id}/roles`, roles);
  }

  public setPositionMenuItems(
    id: string,
    itemIds: string[],
  ): Promise<Position> {
    return this.PATCH<Position>(`/positions/${id}`, {
      menuItems: itemIds,
    });
  }

  public setPositionRoutes(id: string, routeIds: string[]): Promise<Position> {
    return this.PATCH<Position>(`/positions/${id}`, {
      appRoutes: routeIds,
    });
  }
}
