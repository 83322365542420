import {IBHSAggregatedDataService} from '../../interfaces/data-service';
import {BHSAggregatedDataHttpService} from '../http';
import {BHSAggregatedDataOfflineService} from '../offline';
import {BaseDataService} from './base.data-service';

export class BHSAggregatedDataService extends BaseDataService<IBHSAggregatedDataService> {
  constructor(
    onlineService: BHSAggregatedDataHttpService,
    offlineService: BHSAggregatedDataOfflineService,
  ) {
    super(onlineService, offlineService);
  }

  public getPersonalArrangements(
    userId: string,
    from?: Date,
    to?: Date,
  ): Promise<any> {
    return this.service.getPersonalArrangements(userId, from, to);
  }

  public getPromoActions(userId: string, from?: Date, to?: Date): Promise<any> {
    return this.service.getPromoActions(userId, from, to);
  }
}
