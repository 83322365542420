import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseCrudHttpService} from './base.http-service';
import {UnitConversion} from '../../models';
import {IUnitConversionDataService} from '../../interfaces/data-service';

@Injectable()
export class UnitConversionHttpService
  extends BaseCrudHttpService<UnitConversion>
  implements IUnitConversionDataService
{
  constructor(http: HttpClient) {
    super(http, '/core/unit-conversions');
  }
}
