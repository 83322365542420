import {HttpResponse} from '@angular/common/http';
import {IPhotoObjectDataService} from '../../interfaces/data-service';
import {
  ExportPhotosAsZipRequest,
  Photo,
  PhotoCreateRequest,
  PhotoTag,
  RotatePhotoRequest,
  SendPhotosByEmailRequest,
} from '../../models';
import {PhotoObjectHttpService} from '../http';
import {PhotoObjectOfflineService} from '../offline';
import {BaseCrudDataService} from './base.data-service';

export class PhotoObjectDataService extends BaseCrudDataService<
  Photo,
  IPhotoObjectDataService
> {
  constructor(
    onlineService: PhotoObjectHttpService,
    offlineService: PhotoObjectOfflineService,
  ) {
    super(onlineService, offlineService);
  }

  public getPhotoListByObjectId(objectId: string): Promise<Photo[]> {
    return this.service.getPhotoListByObjectId(objectId);
  }

  public getPhotoListForObjectIdList(objectIds: string[]): Promise<Photo[]> {
    return this.service.getPhotoListForObjectIdList(objectIds);
  }

  public deletePhoto(photoId: string): Promise<void> {
    return this.service.deletePhoto(photoId);
  }

  public deletePhotos(photoIds: string[]): Promise<void> {
    return this.service.deletePhotos(photoIds);
  }

  public createPhoto(photo: PhotoCreateRequest): Promise<void> {
    return this.service.createPhoto(photo);
  }

  /**
   * Rotates the image by a number of degrees (counter-clockwise).
   */
  rotatePhoto(payload: RotatePhotoRequest) {
    return this.service.rotatePhoto(payload);
  }

  public addPhotoTags(photoId: string, tagIdList: string): Promise<void> {
    return this.service.addPhotoTags(photoId, tagIdList);
  }

  public getPhotoTags(objectId: string): Promise<PhotoTag[]> {
    return this.service.getPhotoTags(objectId);
  }

  public exportAsPdf(
    photoIdList: string[],
    tempUuid?: string,
  ): Promise<HttpResponse<Blob>> {
    return this.service.exportAsPdf(photoIdList, tempUuid);
  }

  public exportAsZip(
    payload: ExportPhotosAsZipRequest,
    tempUuid?: string,
  ): Promise<HttpResponse<Blob>> {
    return this.service.exportAsZip(payload, tempUuid);
  }

  public sendByEmail(payload: SendPhotosByEmailRequest): Promise<void> {
    return this.service.sendByEmail(payload);
  }

  public downloadPhoto(
    photoId: string,
    thumbnail?: boolean,
  ): Promise<HttpResponse<Blob>> {
    return this.service.downloadPhoto(photoId, thumbnail);
  }

  public uploadVisitPhoto(form: FormData, visitId: string, objectId: string) {
    return this.service.uploadVisitPhoto(form, visitId, objectId);
  }
}
