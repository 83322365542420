import {Injectable} from '@angular/core';
import {DialogService} from '@salesapp/dialog';
import {
  UserManagementDetailDialogComponent,
  UserManagementDetailDialogData,
} from '../components/user-management-detail-dialog/user-management-detail-dialog.component';

@Injectable()
export class UserManagementService {
  constructor(private dialogService: DialogService) {}

  openUserDetail(userId: string, userProfileId: string) {
    this.dialogService.open<
      UserManagementDetailDialogComponent,
      UserManagementDetailDialogData
    >(UserManagementDetailDialogComponent, {
      data: {userId, userProfileId},
      width: '90%',
      height: '90%',
    });
  }
}
