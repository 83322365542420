import {DialogRef} from '@angular/cdk/dialog';
import {ChangeDetectionStrategy, Component} from '@angular/core';
import {ObjectTypeNames} from '@retrixhouse/salesapp-shared/lib/common';
import {IProduct} from '@retrixhouse/salesapp-shared/lib/models';
import {SharedFormService} from '@salesapp/services';
import {BehaviorSubject} from 'rxjs';
import {filter, take} from 'rxjs/operators';
import * as uuid from 'uuid';

@Component({
  selector: 'app-product-create-dialog',
  templateUrl: './product-create-dialog.component.html',
  styleUrls: ['./product-create-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductCreateDialogComponent {
  loading$ = new BehaviorSubject<boolean>(true);

  product: Partial<IProduct>;

  constructor(
    private dialogRef: DialogRef<IProduct, ProductCreateDialogComponent>,
    private sharedFormService: SharedFormService,
  ) {}

  ngOnInit() {
    this.initData();
  }

  onClose() {
    this.dialogRef.close();
  }

  onSave(formData: IProduct) {
    this.dialogRef.close(formData as IProduct);
  }

  private initData() {
    this.sharedFormService
      .getNextUidForObject(ObjectTypeNames.Product)
      .pipe(
        filter(uid => !!uid),
        take(1),
      )
      .subscribe({
        next: uidResponse => {
          this.product = {
            id: uuid.v4(),
            uid: uidResponse.uid,
          };
          this.loading$.next(false);
        },
      });
  }
}
