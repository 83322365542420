import {Injectable} from '@angular/core';
import {AttachmentTemplate, AttachmentTemplateApiService} from '@salesapp/api';
import {Create$Params} from '@salesapp/api/attachment-template/create';
import {Delete$Params} from '@salesapp/api/attachment-template/delete';
import {FetchAllForObjectType$Params} from '@salesapp/api/attachment-template/fetch-all-for-object-type';
import {Update$Params} from '@salesapp/api/attachment-template/update';
import {Observable} from 'rxjs';
import {BaseStorage} from './base.storage';

export type AttachmentTemplateGetListParams = FetchAllForObjectType$Params;
export type AttachmentTemplateCreateParams = Create$Params;
export type AttachmentTemplateUpdateParams = Update$Params;

@Injectable()
export class AttachmentTemplateStorage extends BaseStorage<
  AttachmentTemplate,
  AttachmentTemplate[],
  AttachmentTemplateGetListParams,
  AttachmentTemplateCreateParams,
  AttachmentTemplateUpdateParams,
  Delete$Params
> {
  constructor(protected apiService: AttachmentTemplateApiService) {
    super(apiService);
  }

  getListMethod(
    params?: AttachmentTemplateGetListParams,
  ): Observable<AttachmentTemplate[]> {
    return this.apiService.fetchAllForObjectType(this.filter);
  }
}
