import {UsernameResponse} from './username-response.model';

export class BillingInfo {
  id: string;
  userId: string;
  projectId: string;
  month: number;
  year: number;
  hoursScheduled: number;
  hoursRealized: number;
  hoursConfirmedPerc: number;
  remunerationVisits: number;
  remunerationPersArrs: number;
  remunerationDrives: number;
  remunerationOthers: number;
  deductionUnrealized: number;
  deductionKpis: number;
  vacations: number;
  holidays: number;
  travelExpenses: number;
  total: number;
}

export class BillingInfoWithUserInfo extends BillingInfo {
  userInfo?: UsernameResponse;
}
