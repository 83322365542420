<div class='content-block-card responsive-paddings'>
  <dx-form id='form' [formData]='formData'>
    <dxi-item dataField='cycleCount'>
      <dxi-validation-rule type='required'></dxi-validation-rule>
      <dxi-validation-rule type='range' [min]='1'></dxi-validation-rule>
    </dxi-item>
    <dxi-item dataField='chunkSize'>
      <dxi-validation-rule type='required'></dxi-validation-rule>
    </dxi-item>
    <dxi-item dataField='dataSize'>
      <dxi-validation-rule type='required'></dxi-validation-rule>
    </dxi-item>
    <dxi-item [template]="'actionBarTemplate'">
      <dxo-label [visible]='false'></dxo-label>
    </dxi-item>

    <div *dxTemplate="let content of 'actionBarTemplate'">
      <dx-button
        type='normal'
        text='Start simple test'
        (onClick)='btnStartTestClick("simple")'
        [width]='"100%"'
        [disabled]='startTestBtnDisabled'
      >
      </dx-button>
      &nbsp;
      <dx-button
        type='normal'
        text='Start performance test'
        (onClick)='btnStartTestClick("performance")'
        [width]='"100%"'
        [disabled]='startTestBtnDisabled'
      >
      </dx-button>
    </div>
  </dx-form>

</div>

<div class='content-block-card responsive-paddings' id='logs'>
  <ng-container *ngIf='logs.length > 0 else noData'>
    <div class='clear-button-container'>
      <span (click)=clearLogs() class='clear-button fa fa-trash'></span>
    </div>
    <div [class]="'log-level-' + log.level" *ngFor='let log of logs'>
      [{{log.timestamp | dateFormat: 'HH:mm:ss.SSS'}}] - {{log.message}}
    </div>
  </ng-container>
  <ng-template #noData>
    <div>No logs to show</div>
  </ng-template>


</div>
