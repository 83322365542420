import {ObjectArrayCache} from '@retrixhouse/salesapp-shared/lib/caching';
import {ILanguageDataService} from '../../interfaces/data-service';
import {Language} from '../../models';
import {LanguageHttpService} from '../http';
import {LanguageOfflineService} from '../offline';
import {
  BaseReadonlyDataService,
  CachingOptions,
  TTL_DAY,
} from './base.data-service';

export class LanguageDataService extends BaseReadonlyDataService<
  Language,
  ILanguageDataService,
  ObjectArrayCache<Language>
> {
  private _cacheEnabledLanguages: ObjectArrayCache<Language>;

  constructor(
    onlineService: LanguageHttpService,
    offlineService: LanguageOfflineService,
    cache: ObjectArrayCache<Language>,
    cacheEnabledLanguages: ObjectArrayCache<Language>,
  ) {
    super(onlineService, offlineService, cache);
    this._cacheEnabledLanguages = cacheEnabledLanguages;
  }

  public async getEnabled(
    cachingOptions?: CachingOptions,
  ): Promise<Language[]> {
    if (cachingOptions?.skipCache) {
      return this.service.getEnabled();
    }

    if (cachingOptions?.forceReload || !this._cacheEnabledLanguages.isValid) {
      const locales = await this.service.getEnabled();
      this._cacheEnabledLanguages.init(locales, cachingOptions?.ttl ?? TTL_DAY);
    }

    return this._cacheEnabledLanguages.getAll();
  }
}
