import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ProjectCopyDialogComponent } from "./components/project-copy-dialog/project-copy-dialog.component";
import { ProjectService } from "./services/project.service";
import { DialogModule } from "../../modules/dialog/index";
import { FormModule } from "@salesapp/form";
import { TranslateModule } from "@ngx-translate/core";
import {
  InputDateModule,
  InputSelectModule,
  InputTextModule,
} from "@salesapp/components";
import {
  DxCheckBoxModule,
  DxProgressBarModule
} from "devextreme-angular";
import { ReactiveFormsModule } from "@angular/forms";

const SALESAPP_MODULES = [
  DialogModule,
  FormModule,
];
const UI_COMPONENTS = [
  InputSelectModule,
  InputTextModule,
  InputDateModule,
  DxProgressBarModule,
  DxCheckBoxModule,
];

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    ...SALESAPP_MODULES,
    ...UI_COMPONENTS,
  ],
  declarations: [ProjectCopyDialogComponent],
  providers: [ProjectService],
  exports: [],
})
export class ProjectModule { }
