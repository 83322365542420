import {IPingDataService} from '../../interfaces/data-service';
import {Ping} from '../../models';
import {PingHttpService} from '../http';
import {BaseDataService} from './base.data-service';

export class PingDataService extends BaseDataService<IPingDataService> {
  constructor(onlineService: PingHttpService) {
    super(onlineService);
  }

  public ping(): Promise<Ping> {
    return this.service.ping();
  }
}
