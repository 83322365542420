import {ObjectMapCache} from '@retrixhouse/salesapp-shared/lib/caching';
import {
  IStoreActivityRequest,
  IExtendedPropertySearchRequest,
} from '@retrixhouse/salesapp-shared/lib/requests';
import {IStoreActivityResponse} from '@retrixhouse/salesapp-shared/lib/responses';
import {IStoreDataService} from '../../interfaces/data-service';
import {Store} from '../../models';
import {StoreHttpService} from '../http';
import {StoreOfflineService} from '../offline';
import {
  BaseCrudDataService,
  CachingOptions,
  TTL_DAY,
} from './base.data-service';

export class StoreDataService extends BaseCrudDataService<
  Store,
  IStoreDataService,
  ObjectMapCache<string, Store>
> {
  constructor(
    onlineService: StoreHttpService,
    offlineService: StoreOfflineService,
    cache: ObjectMapCache<string, Store>,
  ) {
    super(onlineService, offlineService, cache);
  }

  getAllStores(): Promise<Store[]> {
    return this.service.getAllStores();
  }

  public async getStoresForChain(
    chainId?: string | null,
    cachingOptions?: CachingOptions,
  ): Promise<Store[]> {
    if (cachingOptions?.skipCache) {
      return this.service.getStoresForChain(chainId);
    }

    if (cachingOptions?.forceReload || !this._cache.isValid) {
      const stores = await this.service.getList();
      this.initCache(stores, cachingOptions?.ttl ?? TTL_DAY);
    }

    // if chainId is undefined, we want to return all stores
    // if chainId is null, we want to return stores with chainId === null
    // if chainId is provided, we want to return stores with chainId === chanId
    return chainId !== undefined
      ? this._cache.getValues().filter(s => s.chainId === chainId)
      : this._cache.getValues();
  }

  public evaluateStores(
    expression: any,
  ): Promise<{valid: boolean; total: number; match: number}> {
    return this.service.evaluateStores(expression);
  }

  public getStoreActivities(
    request: IStoreActivityRequest,
  ): Promise<IStoreActivityResponse> {
    return this.service.getStoreActivities(request);
  }

  public getStoresForExtendedProperties(
    request: IExtendedPropertySearchRequest,
  ): Promise<Store[]> {
    return this.service.getStoresForExtendedProperties(request);
  }
}
