import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';

@Injectable()
export class ReportingServerService {
  baseUrl = environment.reportingBaseUrl;

  constructor(private httpClient: HttpClient) {}

  getAllDashboards(): Observable<{id: string; name: string}[]> {
    return this.httpClient.get<{id: string; name: string}[]>(
      `${this.baseUrl}dashboard/dashboards`,
    );
  }
}
