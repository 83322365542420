<ng-container *ngIf="mode === 'singleLine'">
  <div [ngClass]="isSmallScreen ? 'user-avatar-singleline-sm' : 'user-avatar-singleline-bg'"
       [ngStyle]="{padding: padding}">
    <img src="{{ this.imgSrc }}"
         class="user-avatar-image-{{ this.pictureSize }}" />
    <span *ngIf="!(this.firstName || this.lastName) && userUid">{{ userUid }}</span>
    <span *ngIf="this.firstName || this.lastName">{{ this.lastName }} {{ this.firstName }}</span><span *ngIf="this.position">, {{
      this.position }}</span><span *ngIf="this.username"><span fxHide.lt-sm>&nbsp;</span>({{ this.username }})</span>
  </div>
</ng-container>

<ng-container *ngIf="mode === 'multiLine'">
  <div class="user-avatar-multiline"
       [ngStyle]="{padding: this.padding, fontSize: fontSize}">
    <div>
      <img src="{{ this.imgSrc }}"
           class="user-avatar-image-{{ this.pictureSize }}" />
    </div>
    <div class="user-avatar-info wrap-text">
      <span *ngIf="!(this.firstName || this.lastName) && userUid">{{ userUid }}</span>
      <span *ngIf="this.firstName || this.lastName">{{ this.lastName }} {{ this.firstName }}</span><span *ngIf="this.position">, {{
        this.position }}</span>
      <br />
      <div class="user-avatar-username">
        <span *ngIf="this.username">{{ this.username }}</span>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="mode === 'contact'">
  <div class="user-avatar-contact"
       [ngStyle]="{padding: this.padding, fontSize: fontSize}">
    <div>
      <img src="{{ this.imgSrc }}"
           class="user-avatar-image-{{ this.pictureSize }}" />
    </div>
    <div class="user-avatar-info wrap-text">
      <span id='first-name'
            *ngIf="!(this.firstName || this.lastName) && userUid">{{ userUid }}</span>
      <span id='last-name'
            *ngIf="this.firstName || this.lastName">{{ this.lastName }} {{ this.firstName }}</span>
      <span id='position'
            *ngIf="this.position"> ({{ this.position}})</span>
      <span id='username'
            *ngIf="this.username || this.userId"><br />({{this.userUid ?? ''}}, {{ this.username ?? ''}})</span>
      <br />
      <div class='user-avatar-contact-info'>
        <div *ngIf="this.email"
             class="user-avatar-username"
             [ngClass]="{interactive: interactive}">
          <i class="fa-regular fa-at"></i>&nbsp;
          <!-- Not interactive, show only username-->
          <ng-container *ngIf="!interactive">
            <span>{{ this.email }}</span>
          </ng-container>

          <!-- Interactive in web-->
          <ng-container *ngIf="interactive && !appInfoService.isMobileVersion">
            <a [href]="'mailto:' + this.email">{{ this.email }}</a>
          </ng-container>

          <!-- Interactive in mobile -->
          <ng-container *ngIf="interactive && appInfoService.isMobileVersion">
            <a (click)="openContactUrl('mailto:' + this.email)">{{ this.email }}</a>
          </ng-container>
        </div>
        <div *ngIf="this.phoneNumber"
             class="user-avatar-phone-number"
             [ngClass]="{interactive: interactive}">
          <i class="fa-solid fa-phone"></i>&nbsp;
          <!-- Not interactive, show only phone number-->
          <ng-container *ngIf="!interactive">
            <span>{{ this.phoneNumber }}</span>
          </ng-container>

          <!-- Interactive in web-->
          <ng-container *ngIf="interactive && !appInfoService.isMobileVersion">
            <a [href]="'tel:' + this.phoneNumber">{{ this.phoneNumber }}</a>
          </ng-container>

          <!-- Interactive in mobile -->
          <ng-container *ngIf="interactive && appInfoService.isMobileVersion">
            <a (click)="openContactUrl('tel:' + this.phoneNumber)">{{ this.phoneNumber }}</a>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div *visibleIf="{predicate: !!showDetailNavigationLink && !!userId, role: [RoleNames.PersonalInfo]}"
       class='user-avatar-navigation-link'
       [ngStyle]="{fontSize: fontSize}">
    <a (click)="openUserDetail()">{{ 'navigation.link.user-profile' | translate}}</a>
  </div>
</ng-container>
