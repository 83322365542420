import {CommonModule} from '@angular/common';
import {Component, NgModule, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {AppInfoService, ScreenService} from '../../services';
import {
  ConfirmationService,
  ConfirmType,
} from '../../services/confirmation.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnDestroy {
  smallScreen: boolean;

  $screenChangeSubscription: Subscription;


  constructor(
    public appInfoService: AppInfoService,
    public screenService: ScreenService,
    public confirmationService: ConfirmationService,
    public translateService: TranslateService,
    private router: Router,
  ) {
    this.$screenChangeSubscription = this.screenService.changed.subscribe(
      n => (this.smallScreen = this.screenService.isSmallScreen()),
    );
    this.smallScreen = this.screenService.isSmallScreen();
  }

  ngOnDestroy(): void {
    this.$screenChangeSubscription.unsubscribe();
  }


  async restartClient() {
    const confirmationResult = await this.confirmationService.confirm(
      ConfirmType.CONFIRM,
      this.translateService.instant('views.footer.restart-client'),
      this.translateService.instant('views.footer.restart-client-confirm'),
    );

    if (confirmationResult) {
      // reloading the view inside the visits is causing problems.
      // that's why, only when the user is in a visit, the reloda should navigate him to the home view.
      // hence we have a snapshot saved, the visit will be opened and the user will be navigated back to the same visit he was in.
      if (
        this.router.url.includes('data-collection/questionnaire-answering') ||
        this.router.url.includes('data-collection/visit')
      ) {
        location.href = '/';
      } else {
        window.location.reload();
      }
    }
  }
}

@NgModule({
  declarations: [FooterComponent],
  imports: [CommonModule, TranslateModule],
  exports: [FooterComponent],
})
export class FooterModule {}
