import {Injectable} from '@angular/core';
import {IObjectReadDataService} from '../../interfaces/data-service';
import {BaseOfflineService} from './base.offline-service';

@Injectable()
export class ObjectReadOfflineService
  extends BaseOfflineService
  implements IObjectReadDataService
{
  public isObjectRead(objectId: string): Promise<boolean> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public areObjectsRead(
    objectIds: string | string[],
  ): Promise<{[objectId: string]: boolean}> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public markObjectAsRead(objectIds: string | string[]): Promise<void> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public markObjectAsUnread(objectIds: string | string[]): Promise<void> {
    return Promise.reject('Offline service - not yet implemented');
  }
}
