<!-- TODO: remove unused attributes -->
<app-dialog [title]="'form-designer.dialog.form-settings.title' | translate"
            [actionLabel]="'form.action.save' | translate"
            [actionVisible]="true"
            [cancelLabel]="'form.action.close' | translate"
            [cancelVisible]="true"
            [formGroup]="form"
            (cancelClicked)="onClose()"
            (actionClicked)="onSave()">
  <app-form-row>
    <app-form-column>
      <app-input-text [formControl]="getFormControl('name')"
                      [formControlRef]="getFormControl('name')"
                      [label]="'form-designer.form.name.label' | translate"></app-input-text>
    </app-form-column>
  </app-form-row>
  <app-form-row>
    <app-form-column>
      <app-input-multiselect [formControl]="getFormControl('positionIds')"
                             [formControlRef]="getFormControl('positionIds')"
                             [label]="'form-designer.form.position-ids.label' | translate"
                             [options]="positionOptions$ | async"></app-input-multiselect>
    </app-form-column>
  </app-form-row>
</app-dialog>