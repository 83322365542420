<div class="content">
  {{'form.dialog.discard-changes.message' | translate}}
</div>
<div class="footer">
  <dx-button icon=""
             [text]="'form.dialog.discard-changes.button.action' | translate"
             class="warning"
             (click)="onAccept()"></dx-button>
  <dx-button icon=""
             [text]="'form.dialog.discard-changes.button.cancel' | translate"
             stylingMode="text"
             (click)="onReject()"></dx-button>
</div>