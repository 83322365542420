import {Injectable} from '@angular/core';
import {Filter} from '@loopback/filter';
import {AnyObject} from '@loopback/filter/dist/types';
import {UserStorageKeys} from '@retrixhouse/salesapp-shared/lib/common';
import {Observable, from} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {DataProvider} from '../../data.provider/data-provider';
import {UserStorageDataService} from '../data';
import {
  OldBaseStorageService,
  StorageDataStateEnum,
} from './old-base-storage-service';

@Injectable()
export class UserStorageStorageService extends OldBaseStorageService<
  any,
  {key: string; value: any}[]
> {
  get userStorageDataService() {
    return this.dataService as UserStorageDataService;
  }
  constructor(dataProvider: DataProvider) {
    super(dataProvider.userStorage);
  }

  getCurrentValueByKey(key: UserStorageKeys) {
    return this.data$.pipe(
      map(data => {
        if (!data) {
          return null;
        }
        const storedData = data.find(d => d.key === key);
        return storedData ? storedData.value : null;
      }),
    );
  }

  getData(input?: {
    filter?: Filter<AnyObject>;
  }): Observable<{key: string; value: any}[]> {
    return from(this.userStorageDataService.getAll());
  }

  get<T extends {key: string; value: any}>(key: string): Observable<T> {
    return this.data$.pipe(
      filter(
        () =>
          ![StorageDataStateEnum.Fetching, StorageDataStateEnum.Idle].includes(
            this.dataState.state,
          ),
      ),
      map(data => data.find(storedData => storedData.key === key).value),
    ) as Observable<T>;
  }

  set(input: {key: string; data: any}) {
    return this.command({
      commandMethod$: this.setStorageData.bind(this),
      commandInput: input,
    });
  }

  deleteByKeys(input: {keys: string | string[]}) {
    return this.command({
      commandMethod$: this.deleteStorageData.bind(this),
      commandInput: input,
    });
  }

  private setStorageData(input: {key: string; data: any}) {
    return this.userStorageDataService.set(input.key, input.data);
  }

  private deleteStorageData(input: {keys: string | string[]}) {
    return this.userStorageDataService.delete(input.keys);
  }
}
