import {Pipe, PipeTransform} from '@angular/core';

/**
 * This simple pipe created has been created as a 'hack' to concat the from and to strings to the
 * id of the question in all.at.once.component.html file because the concatination was not working in some cases.
 */
@Pipe({name: 'concat'})
export class StringConcatPipe implements PipeTransform {
  transform(text: string, tail: string) {
    return `${text}${tail}`;
  }
}
