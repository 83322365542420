import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {createFormControlProvider} from '../../utils/reactive-form/form.utils';
import {ControlValueAccessor} from '../../utils/reactive-form/control-value-accessor';
import {FormControlComponent} from '../../utils/reactive-form/form-control.component';

@Component({
  selector: 'app-input-time-select',
  templateUrl: './input-time-select.component.html',
  styleUrls: ['./input-time-select.component.scss'],
  providers: [createFormControlProvider(() => InputTimeSelectComponent)],
})
@ControlValueAccessor()
export class InputTimeSelectComponent extends FormControlComponent<string> {
  
  @Input() elementAttr: { id: any, parentId: any };
  @Input() showClearButton: boolean = true;

  @Input() validation: {
      validationGroup?: string;
      name?: string;
      isRequired?: boolean;
      customValidationCBs?: Function[];
  };

  constructor(private changeDetector: ChangeDetectorRef) {
    super(changeDetector);
  }

  onValueChange(event) {
    if (!!event) {
      this.valueChange.emit(new Date(event).toISOString());
    } else {
      this.valueChange.emit(event);
    }
  }

}
