import { Injectable } from '@angular/core';
import {
  ObjectTypeIds,
  ProjectContextPermissions,
  RoleNames,
} from '@retrixhouse/salesapp-shared/lib/common';
import { IUsernameResponse } from '@retrixhouse/salesapp-shared/lib/responses';
import { Observable, from } from 'rxjs';
import { DataProvider } from '../data.provider/data-provider';
import { Action, ArrayOperator } from '../domain';
import { AuthGuardService } from './auth.service';

@Injectable()
export class PermissionResolverService {
  constructor(
    private authGuardService: AuthGuardService,
    private dataProvider: DataProvider,
  ) {}

  hasRoleAny(roles: RoleNames[]) {
    return this.authGuardService.hasRoleAny(...roles);
  }

  canRead(objectTypeId: ObjectTypeIds) {
    const map = {
      [ObjectTypeIds.Product]: [
        RoleNames.Products,
        RoleNames.ProductsEdit,
        RoleNames.ProductsView,
      ],
      [ObjectTypeIds.Store]: [
        RoleNames.Stores,
        RoleNames.StoresEdit,
        RoleNames.StoresView,
      ],
      [ObjectTypeIds.User]: [
        RoleNames.Users,
        RoleNames.UsersEdit,
        RoleNames.UsersView,
      ],
      [ObjectTypeIds.UserProfile]: [
        RoleNames.UserProfiles,
        RoleNames.UserProfilesEdit,
        RoleNames.UserProfilesView,
      ],
    };
    return this.authGuardService.hasRoleAny(
      RoleNames.Admin,
      ...map[objectTypeId],
    );
  }

  canEdit(objectTypeId: ObjectTypeIds) {
    const map = {
      [ObjectTypeIds.Product]: [RoleNames.Products, RoleNames.ProductsEdit],
      [ObjectTypeIds.Store]: [RoleNames.Stores, RoleNames.StoresEdit],
      [ObjectTypeIds.User]: [RoleNames.Users, RoleNames.UsersEdit],
      [ObjectTypeIds.UserProfile]: [
        RoleNames.UserProfiles,
        RoleNames.UserProfilesEdit,
      ],
    };
    return this.authGuardService.hasRoleAny(
      RoleNames.Admin,
      ...map[objectTypeId],
    );
  }

  canDelete(objectTypeId: ObjectTypeIds) {
    const map = {
      [ObjectTypeIds.Product]: [RoleNames.Products, RoleNames.ProductsDelete],
      [ObjectTypeIds.Store]: [RoleNames.Stores, RoleNames.StoresDelete],
      [ObjectTypeIds.User]: [RoleNames.Users, RoleNames.UsersDelete],
    };

    return this.authGuardService.hasRoleAny(
      RoleNames.Admin,
      ...map[objectTypeId],
    );
  }

  getUserProjectsPermissions(): Observable<{
    [projectId: string]: ProjectContextPermissions[];
  }> {
    return from(this.dataProvider.project.getUserPermissions()) as Observable<{
      [projectId: string]: ProjectContextPermissions[];
    }>;
  }

  resolveAction(
    action: Action,
    params: {
      projectId?: string;
      userProjectsPermissions?: {
        [projectId: string]: ProjectContextPermissions[];
      };
      data?: unknown;
      currentUserUsername: IUsernameResponse;
    },
  ): boolean {
    if (action.permissions) {
      const permissionsOperator = !!action.permissions.and ? 'and' : 'or';
      const {roles, projectContext, customResolver, customContextResolver} =
        action.permissions.and || action.permissions.or;

      const ctx = {
        data: params.data,
        currentUserUsername: params.currentUserUsername,
      };

      let roleResolution;
      let projectResolution;
      let customResolverResolution;
      let customContextResolverResolution;

      if (roles) {
        roleResolution = roles.all
          ? this.authGuardService.hasRoleAll(...roles.all)
          : this.authGuardService.hasRoleAny(...roles.any);
      }

      if (projectContext && params.projectId) {
        projectResolution = this.resolveProjectPermissions(
          projectContext,
          params.projectId,
          params.userProjectsPermissions,
        );
      }

      if (customResolver) {
        customResolverResolution = customResolver.resolver(
          customResolver.resolverParams,
          ctx,
        );
      }

      if (customContextResolver) {
        customContextResolverResolution = customContextResolver(ctx);
      }

      if (permissionsOperator === 'and') {
        return [
          roleResolution,
          projectResolution,
          customResolverResolution,
          customContextResolverResolution,
        ]
          .filter(el => el !== undefined)
          .every(Boolean);
      }

      return [
        roleResolution,
        projectResolution,
        customResolverResolution,
        customContextResolverResolution,
      ]
        .filter(el => el !== undefined)
        .some(Boolean);
    }

    return true;
  }

  private resolveProjectPermissions(
    projectContext: {[key in ArrayOperator]?: ProjectContextPermissions[]},
    projectId: string,
    userProjectsPermissions: {[projectId: string]: ProjectContextPermissions[]},
  ) {
    const projectContextOperator = !!projectContext.all ? 'all' : 'any';
    const projectContextPermissions = projectContext.all || projectContext.any;
    const projectPermissions = userProjectsPermissions[projectId];

    if (projectContextOperator === 'all') {
      return projectContextPermissions.every(permission =>
        projectPermissions.includes(permission),
      );
    } else {
      return projectContextPermissions.some(permission =>
        projectPermissions.includes(permission),
      );
    }
  }
}
