import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';

@Component({
  selector: 'app-pill',
  templateUrl: './pill.component.html',
  styleUrls: ['./pill.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PillComponent {
  @Input() text: string | number;

  @Input()
  @HostBinding('style.background-color')
  color: string;

  @Input()
  @HostBinding('style.color')
  textColor: string = '#000000';

  @Input()
  @HostBinding('style.font-size.px')
  fontSize: number = 12;
}
