import {ChangeDetectorRef, Component} from '@angular/core';
import {FormControlComponent} from '../../utils/reactive-form/form-control.component';
import {ControlValueAccessor} from '../../utils/reactive-form/control-value-accessor';
import {createFormControlProvider} from '../../utils/reactive-form/form.utils';

@Component({
  selector: 'app-input-switch',
  templateUrl: './input-switch.component.html',
  styleUrls: ['./input-switch.component.scss'],
  providers: [createFormControlProvider(() => InputSwitchComponent)],
})
@ControlValueAccessor()
export class InputSwitchComponent extends FormControlComponent<boolean> {
  constructor(private changeDetector: ChangeDetectorRef) {
    super(changeDetector);
  }

  onValueChange(event) {
    this.valueChange.emit(event);
  }
}
