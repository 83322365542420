<app-form *ngIf="initialized$ | async; else loading"
          [formActions]="formActions"
          [formGroup]="form"
          (action)="onFormAction()"
          (cancel)="onFormCancel()">
  <!-- TODO: remove unused attributes -->
  <app-form-row *ngFor="let row of formSchema.rows; trackBy: trackById">
    <app-form-column *ngFor="let column of row.columns; trackBy: trackById">
      <app-form-section [title]="column.group.title"
                        [class.not-visible]="!(visibleGroupIds$ | async).includes(column.group.id)">
        <app-form-row>
          <app-form-column *ngFor="let formControlColumn of column.group.controlColumns; trackBy: trackById">
            <ng-container *ngFor="let formControl of formControlColumn.inputControls; trackBy: trackById">
              <ng-container [ngSwitch]="formControl.inputType">

                <app-input-address *ngSwitchCase="ObjectFormInputControlTypeEnum.Address"
                                   [formGroup]="getFormGroup(formControl)"></app-input-address>

                <app-input-avatar *ngSwitchCase="ObjectFormInputControlTypeEnum.Avatar"
                                  [class.not-visible]="!(visibleFormInputControlIds$ | async).includes(formControl.id)"
                                  [formControl]="getFormControl(formControl.propertyId)"
                                  [objectId]="form.controls.id.value"
                                  [objectTypeId]="objectTypeId"
                                  (uploadFinished)="onAvatarPhotoUploadFinished($event, formControl)"></app-input-avatar>

                <app-input-date *ngSwitchCase="ObjectFormInputControlTypeEnum.Date"
                                [class.not-visible]="!(visibleFormInputControlIds$ | async).includes(formControl.id)"
                                [label]="getLabel(formControl)"
                                [hint]="getHint(formControl)"
                                [formControl]="getFormControl(formControl.propertyId)"
                                [formControlRef]="getFormControl(formControl.propertyId)"></app-input-date>

                <app-input-datetime *ngSwitchCase="ObjectFormInputControlTypeEnum.Datetime"
                                    [class.not-visible]="!(visibleFormInputControlIds$ | async).includes(formControl.id)"
                                    [label]="getLabel(formControl)"
                                    [hint]="getHint(formControl)"
                                    [formControl]="getFormControl(formControl.propertyId)"
                                    [formControlRef]="getFormControl(formControl.propertyId)"></app-input-datetime>

                <app-input-google-places *ngSwitchCase="ObjectFormInputControlTypeEnum.GoogleLocationId"
                                         [class.not-visible]="!(visibleFormInputControlIds$ | async).includes(formControl.id)"
                                         [label]="getLabel(formControl)"
                                         [formControl]="getFormControl(formControl.propertyId)"
                                         [formControlRef]="getFormControl(formControl.propertyId)"
                                         (addressValue)="onGooglePlacesInputAddressChange($event, formControl)"
                                         (longitudeLatitudeValue)="onGooglePlacesInputLongitudeLatitudeChange($event, formControl)"></app-input-google-places>

                <ng-container *ngSwitchCase="ObjectFormInputControlTypeEnum.Location">
                  <app-input-location *ngIf="getLocationFormControls(formControl); let controls"
                                      [longitudeFormControl]="controls.longitudeControl"
                                      [latitudeFormControl]="controls.latitudeControl"
                                      [altitudeFormControl]="controls.altitudeControl"></app-input-location>
                </ng-container>

                <app-input-multiselect *ngSwitchCase="ObjectFormInputControlTypeEnum.MultiSelect"
                                       [class.not-visible]="!(visibleFormInputControlIds$ | async).includes(formControl.id)"
                                       [label]="getLabel(formControl)"
                                       [hint]="getHint(formControl)"
                                       [options]="options$[formControl.propertyId] | async"
                                       [formControl]="getFormControl(formControl.propertyId)"
                                       [formControlRef]="getFormControl(formControl.propertyId)"></app-input-multiselect>

                <app-input-number *ngSwitchCase="ObjectFormInputControlTypeEnum.Number"
                                  [class.not-visible]="!(visibleFormInputControlIds$ | async).includes(formControl.id)"
                                  [label]="getLabel(formControl)"
                                  [hint]="getHint(formControl)"
                                  [formControl]="getFormControl(formControl.propertyId)"
                                  [formControlRef]="getFormControl(formControl.propertyId)"></app-input-number>

                <app-input-rich-text *ngSwitchCase="ObjectFormInputControlTypeEnum.RichText"
                                     [class.not-visible]="!(visibleFormInputControlIds$ | async).includes(formControl.id)"
                                     [label]="getLabel(formControl)"
                                     [hint]="getHint(formControl)"
                                     [formControl]="getFormControl(formControl.propertyId)"
                                     [formControlRef]="getFormControl(formControl.propertyId)"></app-input-rich-text>

                <app-input-select *ngSwitchCase="ObjectFormInputControlTypeEnum.Select"
                                  [class.not-visible]="!(visibleFormInputControlIds$ | async).includes(formControl.id)"
                                  [label]="getLabel(formControl)"
                                  [hint]="getHint(formControl)"
                                  [options]="options$[formControl.propertyId] | async"
                                  [formControl]="getFormControl(formControl.propertyId)"
                                  [formControlRef]="getFormControl(formControl.propertyId)"></app-input-select>

                <app-input-switch *ngSwitchCase="ObjectFormInputControlTypeEnum.Switch"
                                  [class.not-visible]="!(visibleFormInputControlIds$ | async).includes(formControl.id)"
                                  [label]="getLabel(formControl)"
                                  [hint]="getHint(formControl)"
                                  [formControl]="getFormControl(formControl.propertyId)"
                                  [formControlRef]="getFormControl(formControl.propertyId)"></app-input-switch>

                <app-input-tags *ngSwitchCase="ObjectFormInputControlTypeEnum.Tags"
                                [class.not-visible]="!(visibleFormInputControlIds$ | async).includes(formControl.id)"
                                [label]="getLabel(formControl)"
                                [hint]="getHint(formControl)"
                                [formControl]="getFormControl(formControl.propertyId)"
                                [formControlRef]="getFormControl(formControl.propertyId)"></app-input-tags>

                <app-input-text *ngSwitchCase="ObjectFormInputControlTypeEnum.Text"
                                [class.not-visible]="!(visibleFormInputControlIds$ | async).includes(formControl.id)"
                                [label]="getLabel(formControl)"
                                [hint]="formControl.hint"
                                [formControl]="getFormControl(formControl.propertyId)"
                                [formControlRef]="getFormControl(formControl.propertyId)"></app-input-text>

              </ng-container>
            </ng-container>

          </app-form-column>
        </app-form-row>
      </app-form-section>
    </app-form-column>
  </app-form-row>
</app-form>

<ng-template #loading>
  <app-loading></app-loading>
</ng-template>