import {Dialog} from '@angular/cdk/dialog';
import {ComponentType} from '@angular/cdk/portal';
import {Injectable} from '@angular/core';
import {AppInfoService} from '@salesapp/services';
import {
  ConfirmDialogComponent,
  ConfirmDialogOptions,
  ConfirmDialogResponse,
} from '../components/confirm-dialog/confirm-dialog.component';

@Injectable()
export class DialogService {
  constructor(private dialog: Dialog, private appInfoService: AppInfoService) {}

  open<C, D = unknown, R = unknown>(
    component: ComponentType<C>,
    options?: {
      width?: string;
      height?: string;
      data?: D | null;
    },
  ) {
    return this.dialog.open<R, D, C>(component, {
      data: options?.data,
      width: this.appInfoService.isMobileVersion ? '100%' : options?.width,
      height: this.appInfoService.isMobileVersion ? '100%' : options?.height,
      disableClose: true,
    });
  }

  danger(
    options: Omit<
      ConfirmDialogOptions,
      'acceptButtonType' | 'rejectButtonType' | 'type'
    >,
  ) {
    return this.simpleDialog({
      ...options,
      type: 'danger',
      acceptButtonType: 'danger',
      rejectButtonType: 'normal',
    });
  }

  warning(
    options: Omit<
      ConfirmDialogOptions,
      'acceptButtonType' | 'rejectButtonType' | 'type'
    >,
  ) {
    return this.simpleDialog({
      ...options,
      type: 'warning',
      acceptButtonType: 'warning',
      rejectButtonType: 'normal',
    });
  }

  confirm(
    options: Omit<
      ConfirmDialogOptions,
      'acceptButtonType' | 'rejectButtonType' | 'type'
    >,
  ) {
    return this.simpleDialog({
      ...options,
      type: 'confirm',
      acceptButtonType: 'default',
      rejectButtonType: 'normal',
    });
  }

  simpleDialog(options: ConfirmDialogOptions) {
    return this.dialog.open<
      ConfirmDialogResponse,
      ConfirmDialogOptions,
      ConfirmDialogComponent
    >(ConfirmDialogComponent, {
      data: options,
      width: this.appInfoService.isMobileVersion ? '90vw' : '400px',
    });
  }
}
