<app-form-field [formControlRef]="formControlRef"
                [label]="label"
                [hint]="hint"
                [class.show-warning]="inactiveOptionSelected">
  <i *ngIf="inactiveOptionSelected"
     class="fa-solid fa-square-exclamation"
     [title]="'inactive' | translate"></i>
  <dx-select-box [value]="value"
                 [disabled]="disabled"
                 [dataSource]="dataSource$ | async"
                 [searchEnabled]="searchEnabled"
                 [placeholder]="placeholder"
                 [grouped]="grouped"
                 [showClearButton]="true"
                 searchExpr="name"
                 valueExpr="value"
                 displayExpr="name"
                 (onValueChanged)="onValueChange($event)">
    <!-- CUSTOM ITEM RENDERING -->
    <div *dxTemplate="let data of 'item'">
      <ng-container *ngIf="itemType">
        <ng-container [ngSwitch]="itemType">
          <ng-container *ngSwitchCase="'store'">
            <app-store-avatar [store]="data.data"
                              [showChainLogo]="false"></app-store-avatar>
          </ng-container>
        </ng-container>
      </ng-container>
      <div *ngIf="!itemType"
           class="select-option">
        <span>{{ data?.name }}</span>
        <!-- <span *ngIf="data?.data?.inactive">{{ 'input.inactive' | translate }}</span> -->
      </div>
    </div>
  </dx-select-box>
</app-form-field>