import {ObjectMapCache} from '@retrixhouse/salesapp-shared/lib/caching';
import {IChainDataService} from '../../interfaces/data-service';
import {Chain} from '../../models';
import {ChainHttpService} from '../http';
import {ChainOfflineService} from '../offline';
import {BaseCrudDataService} from './base.data-service';

export class ChainDataService extends BaseCrudDataService<
  Chain,
  IChainDataService,
  ObjectMapCache<string, Chain>
> {
  constructor(
    onlineService: ChainHttpService,
    offlineService: ChainOfflineService,
    cache: ObjectMapCache<string, Chain>,
  ) {
    super(onlineService, offlineService, cache);
  }

  public updatePicture(
    chainId: string,
    version: number,
    picture: string,
  ): Promise<Chain> {
    return this._onlineService.updatePicture(chainId, version, picture);
  }

  public deletePicture(chainId: string): Promise<void> {
    return this._onlineService.deletePicture(chainId);
  }
}
