import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FlexLayoutModule} from '@angular/flex-layout';

import {
  ActionsService,
  AppInfoService,
  AuthGuardService,
  ClientModeService,
  LocaleService,
  ScreenService,
} from '../../services';
import {UserPanelModule} from '../user-panel/user-panel.component';
import {DxButtonModule} from 'devextreme-angular/ui/button';
import {DxToolbarModule} from 'devextreme-angular/ui/toolbar';
import {DxTextBoxModule} from 'devextreme-angular/ui/text-box';

import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {Language, Locale} from 'src/app/shared/models';
import {
  CacheName,
  DataProvider,
} from 'src/app/shared/data.provider/data-provider';
import {DashboardLayoutService} from '../../services/dashboard-layout.service';
import {
  ConfirmationService,
  ConfirmType,
} from '../../services/confirmation.service';
import {UserStorageKeys} from '../../globals';
import {RoleNames} from '@retrixhouse/salesapp-shared/lib/common';
import {
  DevExtremeModule,
  DxListComponent,
  DxSelectBoxComponent,
  DxSelectBoxModule,
} from 'devextreme-angular';
import {UserInfo} from '../../models/user-info.model';
import {
  AppInterfaceService,
  AppPlatform,
} from '../../app-interface/app-interface.service';
import {environment} from 'src/environments/environment';
import {ClientMode} from '../../enums/client-mode.enum';
import {filter, map, switchMap} from 'rxjs/operators';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import * as moment from 'moment';
import {NotificationsModule} from '../../modules/notifications';
import {AdminUtilityMenuModule} from '../admin-utility-menu';
import {ConnectionType} from '@salesapp/shared/enums/connection-type.enum';
import {
  CurrentUserStorageService,
  UserProfileStorageService,
} from '@salesapp/storage';

@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @ViewChild('globalSearch', {static: false})
  selectBox: DxSelectBoxComponent;
  @ViewChild('listCacheManager', {static: false})
  listCacheManager: DxListComponent;

  @Output()
  menuToggle = new EventEmitter<boolean>();

  @Input()
  menuToggleEnabled = false;

  @Input()
  title: string;

  tooltipNewClientVersion = false;
  isDataTransferView = false;

  user: UserInfo = {email: '', firstName: '', lastName: ''};

  userMenuItems = [];

  languages: Language[];
  locales: Locale[];
  tokenExpire = '';
  fewMinutesLeft = false;

  searchValue = '';
  searchHistory: string[] = [];
  searchOptionButton: any = {
    stylingMode: 'text',
    icon: 'fa fa-magnifying-glass',
    onClick: e => {
      this.onGlobalSearchClick(e);
    },
  };

  caches: {id: CacheName; text: string}[];

  connectivity$: BehaviorSubject<ConnectionType>;
  isSmallScreen: boolean;
  isLargeScreen: boolean;
  $screenChangeSubscription: Subscription;
  $routeChangeSubscription: Subscription;
  $connectivitySubscription: Subscription;
  localCacheManagerVisible: boolean;
  btnDeleteCachesEnabled: boolean;

  user$: Observable<any>;

  get isMobileVersion() {
    return this.appInfoService.isMobileVersion;
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dataProvider: DataProvider,
    private authGuardService: AuthGuardService,
    public translate: TranslateService,
    private dashboardService: DashboardLayoutService,
    private confirmationService: ConfirmationService,
    public appInfoService: AppInfoService,
    public clientModeService: ClientModeService,
    private appInterfaceService: AppInterfaceService,
    public localeService: LocaleService,
    private screenService: ScreenService,
    private actionsService: ActionsService,
    private currentUserStorageService: CurrentUserStorageService,
    private userProfileStorageService: UserProfileStorageService,
  ) {
    // prettier-ignore
    this.caches = [
      { id: 'chain', text: this.translate.instant('caches.chain') },
      { id: 'country', text: this.translate.instant('caches.country') },
      { id: 'currency', text: this.translate.instant('caches.currency') },
      { id: 'customer', text: this.translate.instant('caches.customer') },
      { id: 'data-storage-file', text: this.translate.instant('caches.data-storage-file') },
      { id: 'data-storage-folder', text: this.translate.instant('caches.data-storage-folder') },
      { id: 'generic-list-item', text: this.translate.instant('caches.generic-list-item') },
      { id: 'generic-list', text: this.translate.instant('caches.generic-list') },
      { id: 'holiday', text: this.translate.instant('caches.holiday') },
      { id: 'i18n', text: this.translate.instant('caches.i18n') },
      { id: 'json-schema', text: this.translate.instant('caches.json-schema') },
      { id: 'kpi-set', text: this.translate.instant('caches.kpi-set') },
      { id: 'kpi', text: this.translate.instant('caches.kpi') },
      { id: 'language', text: this.translate.instant('caches.language') },
      { id: 'news-post', text: this.translate.instant('caches.news-post') },
      { id: 'notification-template', text: this.translate.instant('caches.notification-template') },
      { id: 'object', text: this.translate.instant('caches.object') },
      { id: 'periodic-limit', text: this.translate.instant('caches.periodic-limit') },
      { id: 'personal-arrangement', text: this.translate.instant('caches.personal-arrangement') },
      { id: 'position', text: this.translate.instant('caches.position') },
      { id: 'my-position', text: this.translate.instant('caches.my-position') },
      { id: 'product-category', text: this.translate.instant('caches.product-category') },
      { id: 'product-listing-template', text: this.translate.instant('caches.product-listing-template') },
      { id: 'product-listing', text: this.translate.instant('caches.product-listing') },
      { id: 'product', text: this.translate.instant('caches.product') },
      { id: 'project', text: this.translate.instant('caches.project') },
      { id: 'promo-action', text: this.translate.instant('caches.promo-action') },
      { id: 'question', text: this.translate.instant('caches.question') },
      { id: 'questionnaire-item', text: this.translate.instant('caches.questionnaire-item') },
      { id: 'questionnaire', text: this.translate.instant('caches.questionnaire') },
      { id: 'role', text: this.translate.instant('caches.role') },
      { id: 'scope', text: this.translate.instant('caches.scope') },
      { id: 'setting-value', text: this.translate.instant('caches.setting-value') },
      { id: 'store', text: this.translate.instant('caches.store') },
      { id: 'tag', text: this.translate.instant('caches.tag') },
      { id: 'todo-action', text: this.translate.instant('caches.todo-action') },
      { id: 'todo-list', text: this.translate.instant('caches.todo-list') },
      { id: 'tour-planchangerequest', text: this.translate.instant('caches.tour-planchangerequest') },
      { id: 'tour-plan', text: this.translate.instant('caches.tour-plan') },
      { id: 'tour-plan-cache', text: this.translate.instant('caches.tour-plan-cache') },
      { id: 'tour-plan-cache-search-in-location', text: this.translate.instant('caches.tour-plan-cache-search-in-location') },
      { id: 'trigger', text: this.translate.instant('caches.trigger') },
      { id: 'unit-conversion', text: this.translate.instant('caches.unit-conversion') },
      { id: 'unit', text: this.translate.instant('caches.unit') },
      { id: 'user', text: this.translate.instant('caches.user') },
      { id: 'username', text: this.translate.instant('caches.username') },
      { id: 'my-profile', text: this.translate.instant('caches.my-profile') },
      { id: 'user-profile', text: this.translate.instant('caches.user-profile') },
      { id: 'user-storage', text: this.translate.instant('caches.user-storage') },
    ];

    this.connectivity$ = new BehaviorSubject<ConnectionType>(
      ConnectionType.WIFI,
    );
    this.$connectivitySubscription = this.appInterfaceService
      .onConnectivity()
      .pipe(map(v => v.type))
      .subscribe(this.connectivity$);

    this.btnDeleteCachesEnabled = false;
    this.localCacheManagerVisible = false;
    this.isSmallScreen = this.screenService.isSmallScreen();
    this.isLargeScreen = this.screenService.isLargeScreen();
    this.$screenChangeSubscription = this.screenService.changed.subscribe(
      () => {
        this.isSmallScreen = this.screenService.isSmallScreen();
        this.isLargeScreen = this.screenService.isLargeScreen();
      },
    );

    this.$routeChangeSubscription = this.router.events
      .pipe(filter(e => e instanceof NavigationEnd))
      .subscribe(e => this.navigationHandler(e as NavigationEnd));
    // this.translate.onLangChange.subscribe(() => this.buildUserMenu());
    this.timerHandler = this.timerHandler.bind(this);
    this.onGlobalSearchClick = this.onGlobalSearchClick.bind(this);

    // rebuild menu when user changes client mode
    this.clientModeService.clientModeChangeEvent().subscribe(newState => {
      this.buildUserMenu();
    });

    this.timerHandler();
    setInterval(this.timerHandler, 1000 * 60);

    // TODO(milan): change this implementation when there will be better handling for current user and editing users in user management (event bus or something else)
    this.user$ = this.currentUserStorageService.data$.pipe(
      filter(currentUser => !!currentUser),
      switchMap(user =>
        this.userProfileStorageService.dataById$.pipe(
          map(userProfilesById => userProfilesById.get(user.profileId)),
        ),
      ),
    );
  }

  toggleTooltipNewClientVersion(): void {
    this.tooltipNewClientVersion = !this.tooltipNewClientVersion;
  }

  navigationHandler(e: NavigationEnd): void {
    this.isDataTransferView = e.url === '/data-transfer';
    // console.log('isDataTransferView', this.isDataTransferView);
  }

  timerHandler(): void {
    if (this.authGuardService.isTokenAboutToExpire()) {
      this.authGuardService.logout();
    }

    // get remaining time token expiration
    const token = this.authGuardService.getParsedKeycloakToken();
    const rem = moment.duration(moment(token.exp * 1000).diff(moment()));
    this.tokenExpire = `${rem.hours().toString().padStart(2, '0')}:${rem
      .minutes()
      .toString()
      .padStart(2, '0')}`;

    // if 5 minutes left
    // display time in red color
    if (rem.hours() === 0 && rem.minutes() <= 5) {
      this.fewMinutesLeft = true;
    } else {
      this.fewMinutesLeft = false;
    }
  }

  ngOnInit(): void {
    this.dataProvider.userStorage
      .get(UserStorageKeys.GlobalSearchHistory)
      .then((history: string[]) => {
        this.searchHistory = history;
      })
      .catch(ignored => {});

    this.authGuardService.getProfile().then(profile => {
      this.user.email = profile.email;
      this.user.firstName = profile.firstName;
      this.user.lastName = profile.lastName;
    });
    this.authGuardService.getUserProfile().then(userProfile => {
      this.user.profile = userProfile;
      this.authGuardService.updateProfilePicture(userProfile?.picture);
    });

    Promise.all([
      this.dataProvider.language.getEnabled(),
      this.dataProvider.i18N.getEnabledLocales(),
    ]).then(result => {
      this.languages = result[0];
      this.locales = result[1];
      this.buildUserMenu();
    });
  }

  ngOnDestroy(): void {
    if (this.$screenChangeSubscription) {
      this.$screenChangeSubscription.unsubscribe();
    }

    if (this.$routeChangeSubscription) {
      this.$routeChangeSubscription.unsubscribe();
    }

    if (this.$connectivitySubscription) {
      this.$connectivitySubscription.unsubscribe();
    }
  }

  buildUserMenu(): void {
    const languageSwitchItems = [];

    if (this.locales) {
      for (const localeCode of this.locales) {
        const isoCode = localeCode.tag.substring(0, 2);
        languageSwitchItems.push({
          text: this.languages.find(lang => lang.isoCode1 === isoCode)
            ?.nativeName,
          onClick: () => {
            this.localeService.switchLocaleAndReload(localeCode.tag);
          },
        });
      }
    }

    let menuItems = [];
    // add buttons to switch to online / offline mode in the mobile application
    if (this.appInfoService.isMobileVersion) {
      /*if (this.clientModeService.clientMode === ClientMode.ONLINE) {
        menuItems.push({
          id: 'client-mode-switch',
          visible: true,
          icon: 'fa fa-cloud-slash',
          text: this.translate.instant('client-mode.switch-offline'),
          onClick: () => {
            if (!this.appInfoService.isMobileVersion) {
              // should not happen, do not allow switch on non-mobile devices
              return;
            }

            DataTransferNavigationGuard.usedBackButton = undefined;
            this.router.navigate(['/data-transfer'], {
              state: {direction: TransferDirection.DOWNLOAD},
            });
          },
        });
      } else {
        menuItems.push({
          id: 'client-mode-switch',
          visible: true,
          icon: 'fa fa-cloud-check',
          text: this.translate.instant('client-mode.switch-online'),
          onClick: () => {
            if (!this.appInfoService.isMobileVersion) {
              // should not happen, do not allow switch on non-mobile devices
              return;
            }

            DataTransferNavigationGuard.usedBackButton = undefined;
            this.router.navigate(['/data-transfer'], {
              state: {direction: TransferDirection.UPLOAD},
            });
          },
        });
      }*/

      menuItems.push({
        id: 'app-interface-testing',
        text: 'App interface Testing',
        icon: 'fa fa-microscope',
        visible: this.authGuardService.hasRole(RoleNames.Development),
        onClick: () => {
          this.router.navigate(['/app-interface']);
        },
      });
    }

    menuItems = menuItems.concat([
      {
        id: 'profile',
        text: this.translate.instant('profile'),
        icon: 'fa fa-user-circle',
        // beginGroup: this.appInfoService.isMobileVersion,
        visible: this.clientModeService.clientMode === ClientMode.ONLINE,
        onClick: async () => {
          const userProfile = await this.authGuardService.getUserProfile();
          this.actionsService.openUserDetail({userProfileId: userProfile.id});
        },
      },
      {
        id: 'cache-manager',
        text: this.translate.instant('captions.local-cache'),
        icon: 'fa fa-database',
        visible: true,
        onClick: () => {
          this.localCacheManagerVisible = true;
        },
      },
      {
        id: 'user-storage.delete',
        text: this.translate.instant('user-storage.delete'),
        icon: 'fa fa-trash-can-check',
        visible: this.clientModeService.clientMode === ClientMode.ONLINE,
        onClick: () => {
          this.onDeleteAllUserStorageValues();
        },
      },
      {
        id: 'file-manager',
        text: this.translate.instant('file-manager'),
        icon: 'fa fa-folder-open',
        visible:
          this.clientModeService.clientMode === ClientMode.ONLINE &&
          this.appInfoService.isMobileVersion,
        onClick: () => {
          this.appInterfaceService.fileStorageDisplay();
        },
      },
      {
        id: 'language',
        text: this.translate.instant('language'),
        icon: 'fa fa-language',
        items: languageSwitchItems,
      },
      {
        id: 'account-management',
        text: this.translate.instant('account-management'),
        icon: 'fa fa-lock',
        visible: this.clientModeService.clientMode === ClientMode.ONLINE,
        onClick: () => {
          this.authGuardService.accountManagement();
        },
      },
      {
        id: 'update-client',
        text: this.translate.instant('update-client'),
        icon: 'fa fa-cloud-arrow-down',
        visible:
          this.appInfoService.isNewClientVersion &&
          this.clientModeService.clientMode === ClientMode.ONLINE,
        onClick: () => {
          this.updateClient();
        },
      },
      {
        id: 'logout',
        text: this.translate.instant('logout'),
        icon: 'fa fa-power-off',
        beginGroup: true,
        visible: this.clientModeService.clientMode === ClientMode.ONLINE,
        onClick: () => {
          this.authGuardService.logout();
        },
      },
    ]);

    if (this.screenService.isLargeScreen()) {
      // get the index of the profile item and insert the element after it.
      const profileIndex = menuItems.findIndex(f => f.id === 'profile');

      menuItems.splice(profileIndex + 1, 0, {
        id: 'reorder-dashboard',
        text: this.translate.instant('reorder-dashboard'),
        icon: 'fa fa-clone',
        visible: this.clientModeService.clientMode === ClientMode.ONLINE,
        onClick: () => {
          this.dashboardService.openReorder(true);
        },
      });
    }

    this.userMenuItems = menuItems;
  }

  historyDeleteItem(e: any, searchValue: string): void {
    e.event.stopPropagation();
    this.searchHistory.find((item, i) =>
      item === searchValue ? this.searchHistory.splice(i, 1) : null,
    );
    this.dataProvider.userStorage.set(
      UserStorageKeys.GlobalSearchHistory,
      this.searchHistory,
    );

    this.selectBox?.instance.option('value') === '';
  }

  async onGlobalSearchClick(e): Promise<void> {
    const searchValue = this.selectBox?.instance.option('value');

    if (!searchValue || searchValue.trim() === '') {
      this.selectBox?.instance.reset();
      return;
    }

    await this.dataProvider.globalSearch.globalSearch(searchValue);

    this.dataProvider.userStorage
      .get(UserStorageKeys.GlobalSearchHistory)
      .then((history: string[]) => {
        this.searchHistory = history;
        this.selectBox?.instance.reset();
      })
      .catch(ignored => {});
  }

  onDeleteAllUserStorageValues(): void {
    const dialog = this.confirmationService.confirm(
      ConfirmType.CONFIRM,
      this.translate.instant('captions.delete-storage-values'),
      this.translate.instant('messages.confirmation-delete-storage-values'),
    );
    dialog.then(dialogResult => {
      if (dialogResult) {
        return this.dataProvider.userStorage
          .delete(
            Object.values(UserStorageKeys).filter(name =>
              name.startsWith('ui-'),
            ),
          )
          .then(() => {
            window.location.reload();
          });
      }
    });
  }

  openSelectBox(e): void {
    this.dataProvider.userStorage
      .get(UserStorageKeys.GlobalSearchHistory)
      .then((history: string[]) => {
        this.searchHistory = history;
      })
      .catch(ignored => {});
  }

  toggleMenu = () => {
    this.menuToggle.emit();
  };

  openScanner = () => {
    this.router.navigateByUrl('/barcode-scanner');
  };

  onValueChanged(e): void {
    this.searchValue = e.value;
  }

  updateClient(): void {
    this.confirmationService
      .confirm(
        ConfirmType.CONFIRM,
        this.translate.instant('update-client'),
        this.translate.instant('messages.confirmation-update-client'),
      )
      .then(dialogResult => {
        if (dialogResult) {
          if (this.appInterfaceService.platform === AppPlatform.WebBrowser) {
            window.location.reload();
          } else {
            this.appInterfaceService.updateClient(
              `${environment.frontEndBaseUrl}client.zip`,
            );
          }
        }
      });
  }

  btnDeleteCacheManagerClick(e): void {
    this.btnDeleteCachesEnabled = false;
    this.dataProvider.invalidateCaches(this.listCacheManager.selectedItemKeys);
    this.localCacheManagerVisible = false;
    this.listCacheManager.selectedItemKeys = [];
  }

  btnCancelCacheManagerClick(e): void {
    this.localCacheManagerVisible = false;
  }

  listCacheManagerSelectionChanged(e): void {
    this.btnDeleteCachesEnabled =
      this.listCacheManager.selectedItemKeys.length !== 0;
  }
}

@NgModule({
  imports: [
    CommonModule,
    DxButtonModule,
    UserPanelModule,
    DxToolbarModule,
    DxTextBoxModule,
    FlexLayoutModule,
    DxSelectBoxModule,
    DevExtremeModule,
    TranslateModule,
    NotificationsModule,
    AdminUtilityMenuModule,
  ],
  declarations: [HeaderComponent],
  exports: [HeaderComponent],
})
export class HeaderModule {}
