import {Injectable} from '@angular/core';
import {FeedbackSearchRequest} from '../../models/requests/feedback-search.filter';
import {Feedback, FeedbackWithPhotosRequest} from '../../models';
import {IFeedbackDataService} from '../../interfaces/data-service';
import {BaseCrudOfflineService} from './base.offline-service';

@Injectable()
export class FeedbackOfflineService
  extends BaseCrudOfflineService<Feedback>
  implements IFeedbackDataService
{
  public search(request: FeedbackSearchRequest): Promise<Feedback[]> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public createWithPhotos(payload: FeedbackWithPhotosRequest): Promise<void> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public updateWithPhotos(payload: FeedbackWithPhotosRequest): Promise<void> {
    return Promise.reject('Offline service - not yet implemented');
  }
}
