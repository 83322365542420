import {RoleNames} from '@retrixhouse/salesapp-shared/lib/common';
import {Action} from '../actions/actions';

export class ExternalReportActions {
  static EXTERNAL_REPORT_CREATE = new Action({
    id: 'externalReportCreate',
    icon: 'fa-solid fa-plus-circle',
    i18nIdentifier: 'entity.external-report.actions.create',
    permissions: {
      or: {
        roles: {
          any: [RoleNames.Admin],
        },
      },
    },
  });

  static EXTERNAL_REPORT_EDIT = new Action({
    id: 'externalReportEdit',
    icon: 'fa-solid fa-pen-to-square',
    i18nIdentifier: 'entity.external-report.actions.edit',
    permissions: {
      or: {
        roles: {
          any: [RoleNames.Admin],
        },
      },
    },
  });

  static EXTERNAL_REPORT_DELETE = new Action({
    id: 'externalReportDelete',
    icon: 'fa-solid fa-trash',
    i18nIdentifier: 'entity.external-report.actions.delete',
    permissions: {
      or: {
        roles: {
          any: [RoleNames.Admin],
        },
      },
    },
  });
}
