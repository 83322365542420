import {UsernameResponse} from './username-response.model';

export class StateTransitionRecord {
  id: string;
  typeId: string;
  objectId: string;
  requestId?: string;
  userId?: string;
  time: Date;
  prev: string;
  next: string;
  notes?: string;
  userInfo?: UsernameResponse;
}
