import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ObjectTypeIds} from '@retrixhouse/salesapp-shared/lib/common';
import {AttachmentTemplate} from '@salesapp/api';
import {DialogService} from '@salesapp/dialog';
import {AttachmentTemplateStorage} from '@salesapp/storage';
import {triggerBrowserDownloadPopup} from '@salesapp/utils/utils';
import {environment} from 'src/environments/environment';
import {
  AttachmentTemplateEditDialogComponent,
  AttachmentTemplateEditDialogData,
} from '../components/attachment-template-edit-dialog/attachment-template-edit-dialog.component';
import {AttachmentTemplateLegendDialogComponent} from '../components/attachment-template-legend-dialog/attachment-template-legend-dialog.component';
import {
  AttachmentTemplatesGenerateDialogComponent,
  AttachmentTemplatesGenerateDialogData,
} from '../components/attachment-templates-generate-dialog/attachment-templates-generate-dialog.component';

@Injectable()
export class AttachmentTemplatesService {
  constructor(
    private dialogService: DialogService,
    private translateService: TranslateService,
    private attachmentTemplateStorage: AttachmentTemplateStorage,
    private httpClient: HttpClient,
  ) {}

  createAttachmentTemplate(
    data: Omit<AttachmentTemplateEditDialogData, 'template'>,
  ) {
    return this.openEditAttachmentTemplateDialog(data);
  }

  editAttachmentTemplate(data: AttachmentTemplateEditDialogData) {
    return this.openEditAttachmentTemplateDialog(data);
  }

  downloadAttachmentTemplate(template: AttachmentTemplate) {
    // NOTE: we can't use generated api service because there is no way how to send custom headers without using http interceptor. We can implemeent custom interceptor if needed in the future
    this.httpClient
      .get(
        `${environment.backEndBaseUrl}/core/attachment-templates/download/${template.id}`,
        {
          observe: 'response',
          responseType: 'blob' as 'json',
        },
      )
      .subscribe({
        next: response => {
          triggerBrowserDownloadPopup(response as any, template.name);
        },
      });
  }

  deleteAttachmentTemplate(template: AttachmentTemplate) {
    return this.dialogService
      .danger({
        acceptLabel: this.translateService.instant('buttons.delete'),
        rejectLabel: this.translateService.instant('buttons.cancel'),
        showCloseButton: false,
        title: this.translateService.instant(
          'entity.attachment-template.dialog.delete.title',
        ),
      })
      .closed.subscribe({
        next: response => {
          if (response === 'accepted') {
            // do something
            this.attachmentTemplateStorage.delete({
              id: template.id,
            });
          }
        },
      });
  }

  generateAttachment(params: {
    templates: AttachmentTemplate[];
    objectId: string;
    objectTypeId: ObjectTypeIds;
  }) {
    return this.dialogService.open<
      AttachmentTemplatesGenerateDialogComponent,
      AttachmentTemplatesGenerateDialogData
    >(AttachmentTemplatesGenerateDialogComponent, {
      data: params,
    });
  }

  showLegend() {
    return this.dialogService.open<AttachmentTemplateLegendDialogComponent>(
      AttachmentTemplateLegendDialogComponent,
      {width: '900px', height: '90%'},
    );
  }

  private openEditAttachmentTemplateDialog(
    data: AttachmentTemplateEditDialogData,
  ) {
    return this.dialogService.open<AttachmentTemplateEditDialogComponent>(
      AttachmentTemplateEditDialogComponent,
      {data, width: '600px', height: '470px'},
    );
  }
}
