import {Injectable} from '@angular/core';
import {BaseHttpService} from './base.http-service';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {
  GetVisitPhotosRequest,
  MobilePhotoChunk,
  OpenVisitRequest,
  OpenVisitResponse,
  PersonalArrangement,
  Photo,
  TodoListResult,
  TourPlan,
  VisitDataReportResponse,
  VisitDataResponse,
  VisitResultRequest,
  VisitResultResponse,
  VisitsPivotReportRequest,
  VisitsReportRequest,
} from 'src/app/shared/models';
import {
  IVisitPhotosResponse,
  IVisitPivotReportResponse, IVisitDataReportMatrixColumnCount,
} from '@retrixhouse/salesapp-shared/lib/responses';
import {IVisitDataService} from '../../interfaces/data-service';
import {IQuestionnaireResultChangeRequest} from '@retrixhouse/salesapp-shared/lib/requests';
import {IQuestionnaireResultWithRelations} from '@retrixhouse/salesapp-shared/lib/models';
import {UploadPhotoResponse} from '@retrixhouse/salesapp-shared/lib/responses/upload-photo';

@Injectable()
export class VisitHttpService
  extends BaseHttpService
  implements IVisitDataService
{
  constructor(http: HttpClient) {
    super(http);
  }

  public getVisitPhotos(visitId: string): Promise<Photo[]> {
    return this.GET<Photo[]>(`/visit/photos/${visitId}`);
  }

  public uploadPhotoChunk(
    chunk: MobilePhotoChunk,
  ): Promise<UploadPhotoResponse> {
    return this.POST(`/photo/photo-object/upload-chunk`, chunk);
  }

  public getVisitData(
    visitId: string,
    executorId?: string,
  ): Promise<VisitDataResponse> {
    return this.GET<VisitDataResponse>(`/visit/data/${visitId}`, {
      params: {executorId: executorId ?? ''},
    });
  }

  public getVisitDataBulk(visitIds: string[]): Promise<VisitDataResponse[]> {
    return this.POST<VisitDataResponse[]>(`/visit/data`, {visitIds});
  }

  public getVisitDataForUser(
    visitId: string,
    userId: string,
  ): Promise<VisitDataResponse> {
    return this.GET<VisitDataResponse>(`/visit/data/${visitId}/user/${userId}`);
  }

  public getVisitResult(tourPlanId: string): Promise<VisitDataResponse[]> {
    return this.GET<VisitDataResponse[]>(`/visit/result/${tourPlanId}`);
  }

  public getVisitResultTemplate(
    projectIds: string[],
  ): Promise<{projectId: string; template: Partial<VisitDataResponse>}[]> {
    return this.POST<
      {projectId: string; template: Partial<VisitDataResponse>}[]
    >('/visit/data/template', projectIds);
  }

  public getPreviousVisitResultsForUser(): Promise<
    {projectId: string; storeId: string; result: TodoListResult}[]
  > {
    return this.GET<
      {projectId: string; storeId: string; result: TodoListResult}[]
    >('/visit/result/previous-for-user');
  }

  public saveVisitData(
    visitDataRequest: VisitResultRequest,
  ): Promise<VisitResultResponse> {
    return this.POST<VisitResultResponse>(`/visit/result`, visitDataRequest);
  }

  public editVisitData(
    visitResultId: string,
    visitDataRequest: VisitResultRequest,
  ): Promise<TodoListResult> {
    return this.PATCH<TodoListResult>(
      `/visit/result/${visitResultId}`,
      visitDataRequest,
    );
  }

  public deleteVisitData(
    visitResultId: string,
    version: number,
  ): Promise<void> {
    return this.DELETE(`/visit/result/${visitResultId}`, {params: {version}});
  }

  public openVisit(
    startVisitRequest: OpenVisitRequest,
  ): Promise<OpenVisitResponse> {
    return this.POST<OpenVisitResponse>(`/visit/open`, startVisitRequest);
  }

  public cancelVisit(tourPlanId: string): Promise<TourPlan> {
    return this.POST<TourPlan>(`/visit/cancel/${tourPlanId}`, {});
  }

  public getPersonalArrangementsForUser(): Promise<
    {storeId: string; personalArrangement: PersonalArrangement}[]
  > {
    return this.GET<
      {storeId: string; personalArrangement: PersonalArrangement}[]
    >('/personal-arrangements/for-user');
  }

  public getVisitDataReport(
    request: VisitsReportRequest,
  ): Promise<VisitDataReportResponse> {
    return this.POST<VisitDataReportResponse>(
      '/visit/visit-data-report',
      request,
    );
  }

  public getVisitPivotReport(
    request: VisitsPivotReportRequest,
  ): Promise<IVisitPivotReportResponse> {
    return this.POST<IVisitPivotReportResponse>(
      '/visit/visit-pivot-report',
      request,
    );
  }

  public getVisitDataReportAsXlsx(
    request: VisitsReportRequest,
  ): Promise<HttpResponse<Blob>> {
    return this.POST<HttpResponse<Blob>>('/visit/visit-data-report', request, {
      params: {format: 'xlsx'},
      observe: 'response',
      responseType: 'blob',
    });
  }

  public async getVisitsWithPhotos(
    request: GetVisitPhotosRequest,
  ): Promise<IVisitPhotosResponse[]> {
    return this.POST<IVisitPhotosResponse[]>(
      '/visit/get-visits-with-related-photos',
      request,
    );
  }

  public editQuestionnaireResult(
    todoListResultId: string,
    request: IQuestionnaireResultChangeRequest,
  ): Promise<IQuestionnaireResultWithRelations> {
    return this.PATCH<IQuestionnaireResultWithRelations>(
      `visit/result/${todoListResultId}/questionnaire`,
      request,
    );
  }

  public getMatrixColumnCount(
    request: VisitsReportRequest,
  ): Promise<IVisitDataReportMatrixColumnCount> {
    return this.POST<IVisitDataReportMatrixColumnCount>(
      '/visit/visit-data-matrix-columns',
      request,
    );
  }
}
