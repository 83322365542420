import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseCrudHttpService} from './base.http-service';
import {ProjectStorePeriodicLimit} from '../../models/project-store-periodic-limit.model';
import {PeriodicLimit} from '../../models';
import {IPeriodicLimitDataService} from '../../interfaces/data-service';
import {PeriodicLimitType} from '@retrixhouse/salesapp-shared/lib/models';

@Injectable()
export class PeriodicLimitHttpService
  extends BaseCrudHttpService<PeriodicLimit>
  implements IPeriodicLimitDataService
{
  constructor(http: HttpClient) {
    super(http, '/periodic-limit');
  }

  public getPeriodicLimitsForAvailableStoresStore(): Promise<
    ProjectStorePeriodicLimit[]
  > {
    return this.GET<ProjectStorePeriodicLimit[]>(`/periodic-limit/store/`);
  }

  public getPeriodicLimitsForStore(
    storeId: string,
  ): Promise<ProjectStorePeriodicLimit[]> {
    return this.GET<ProjectStorePeriodicLimit[]>(
      `/periodic-limit/store/${storeId}`,
    );
  }

  public createPeriodicLimitForStore(
    periodicLimit: ProjectStorePeriodicLimit,
  ): Promise<ProjectStorePeriodicLimit> {
    return this.POST(`/periodic-limit/store/`, periodicLimit);
  }

  public deletePeriodicLimitForStore(id: string): Promise<void> {
    return this.DELETE<void>(`/periodic-limit/store/${id}`);
  }

  public setPeriodicLimitsForStore(
    storeId: string,
    periodicLimits: ProjectStorePeriodicLimit[],
  ): Promise<ProjectStorePeriodicLimit[]> {
    return this.POST<ProjectStorePeriodicLimit[]>(
      `/periodic-limit/store/${storeId}`,
      periodicLimits,
    );
  }

  public validatePeriodicLimit(
    type: PeriodicLimitType,
    offset: number,
    duration: number,
  ): Promise<boolean> {
    return this.GET<boolean>(`/periodic-limit/validate`, {
      params: {type, offset, duration},
    });
  }
}
