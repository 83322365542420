/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getAllPushNotificationsForCurrentUser } from '../fn/notification/get-all-push-notifications-for-current-user';
import { GetAllPushNotificationsForCurrentUser$Params } from '../fn/notification/get-all-push-notifications-for-current-user';
import { markPushNotificationAsRead } from '../fn/notification/mark-push-notification-as-read';
import { MarkPushNotificationAsRead$Params } from '../fn/notification/mark-push-notification-as-read';
import { PushNotificationRead } from '../models/push-notification-read';

@Injectable({ providedIn: 'root' })
export class NotificationApiService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `notificationControllerMarkPushNotificationAsRead()` */
  static readonly NotificationControllerMarkPushNotificationAsReadPath = '/sys/notifications/push/{notificationId}/mark-as-read';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `markPushNotificationAsRead()` instead.
   *
   * This method doesn't expect any request body.
   */
  markPushNotificationAsRead$Response(params: MarkPushNotificationAsRead$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return markPushNotificationAsRead(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `markPushNotificationAsRead$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  markPushNotificationAsRead(params: MarkPushNotificationAsRead$Params, context?: HttpContext): Observable<void> {
    return this.markPushNotificationAsRead$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `notificationControllerGetAllPushNotificationsForCurrentUser()` */
  static readonly NotificationControllerGetAllPushNotificationsForCurrentUserPath = '/sys/notifications/push';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllPushNotificationsForCurrentUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllPushNotificationsForCurrentUser$Response(params?: GetAllPushNotificationsForCurrentUser$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PushNotificationRead>>> {
    return getAllPushNotificationsForCurrentUser(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllPushNotificationsForCurrentUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllPushNotificationsForCurrentUser(params?: GetAllPushNotificationsForCurrentUser$Params, context?: HttpContext): Observable<Array<PushNotificationRead>> {
    return this.getAllPushNotificationsForCurrentUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PushNotificationRead>>): Array<PushNotificationRead> => r.body)
    );
  }

}
