import {BaseModel} from './base.model';
import {Project} from './project.model';
import {Store} from './store.model';
import {GenericListItem} from './generic-list.model';
import {Photo} from './photo.model';
import {TodoListResult} from './todo-list-result.model';
import {getNumberAsHHMM, isNumberOrString} from '../utils/utils';
import {User} from './user.model';
import {UsernameResponse} from './username-response.model';
import * as moment from 'moment';
import {
  TourPlanState,
  TourPlanDurationHidden,
} from '@retrixhouse/salesapp-shared/lib/models';

export class TourPlan extends BaseModel {
  uid: string;
  version: number;
  state: TourPlanState;
  scheduledStart: Date;
  scheduledFinish: Date;
  exactTime: boolean;
  startedAt: Date;
  finishedAt: Date;
  createdAt: Date;
  adHoc: boolean;
  differentFieldMarketers: boolean;
  notes: string;
  personalArrangement: boolean;
  substituted: boolean;
  substitutesId: string;
  executors: TourPlanExecutor[];
  projectId: string;
  project: Project;
  storeId: string;
  store: Store;
  typeId: string;
  type: GenericListItem;
  stateColor: string;
  photos: Photo[];
  todoListResults?: TodoListResult[];
  headcountNeeded: number;
  scheduledDuration?: number | TourPlanDurationHidden;
  highlightColor?: string;
  completedBySystem?: boolean;
  approved?: boolean;
  monetaryValue: number;

  public static formatDateProperties(tourPlan: TourPlan): void {
    if (isNumberOrString(tourPlan.scheduledStart)) {
      tourPlan.scheduledStart = new Date(tourPlan.scheduledStart);
    }

    if (isNumberOrString(tourPlan.scheduledFinish)) {
      tourPlan.scheduledFinish = new Date(tourPlan.scheduledFinish);
    }

    if (isNumberOrString(tourPlan.startedAt)) {
      tourPlan.startedAt = new Date(tourPlan.startedAt);
    }

    if (isNumberOrString(tourPlan.finishedAt)) {
      tourPlan.finishedAt = new Date(tourPlan.finishedAt);
    }

    if (isNumberOrString(tourPlan.createdAt)) {
      tourPlan.createdAt = new Date(tourPlan.createdAt);
    }
  }

  public static calculateVisitDuration = (tourPlan: TourPlan) => {
    if (!tourPlan.scheduledDuration) {
      return '';
    }

    if (tourPlan.scheduledDuration === 'N') {
      return 'N';
    }

    let visitDuration = '';
    if (tourPlan.scheduledDuration > 0) {
      visitDuration = getNumberAsHHMM(tourPlan.scheduledDuration);
    } else if (!tourPlan.scheduledDuration && tourPlan.exactTime) {
      const diff = moment(tourPlan.scheduledFinish).diff(
        tourPlan.scheduledStart,
        'hours',
        true,
      );
      visitDuration = getNumberAsHHMM(diff);
    }

    return visitDuration;
  };
}

export type TourPlanEx = TourPlan & {
  stateColor: string;
  storeAddress?: string;
  options?: any[];
  day?: string;
  soonestVisitStart?: Date;
  latestVisitStart?: Date;
};

export type TourPlanExchangeInfo = {
  id: string;
  exchangeUserId?: string;
  sameDayVisits?: number;
  sameDayVisitsDuration?: number;
  overlappingVisits?: number;
};

export type TourPlanWithExchangeInfo = TourPlan & TourPlanExchangeInfo;

export class TourPlanExecutor extends BaseModel {
  tourPlanId: string;
  user: User;
  userId: string;
  hourlyRate: number;
  plannedStart: string;
  plannedFinish: string;
  claimTravelDistance: number;
  claimExpensesAmount: number;
  claimExpensesNote: string;
  claimWorkStart: string;
  claimWorkFinish: string;
  claimWorkDuration?: number;
  claimBreakStart: string;
  claimBreakFinish: string;
  claimBreakDuration?: number;
  missingStoreLocation?: boolean;
  missingUserLocation?: boolean;
  userNotInRange?: boolean;
  startLatitude?: number;
  startLongitude?: number;
  startAltitude?: number;
  startAccuracy?: number;
  resourceTypeId: string;
  resourceType: GenericListItem;

  constructor() {
    super();
    this.tourPlanId = null;
    this.userId = null;
    this.resourceTypeId = null;
    this.hourlyRate = null;
    this.plannedStart = null;
    this.plannedFinish = null;
    this.claimTravelDistance = null;
    this.claimExpensesAmount = null;
    this.claimExpensesNote = null;
    this.claimWorkStart = null;
    this.claimWorkFinish = null;
    this.claimBreakStart = null;
    this.claimBreakFinish = null;
  }
}

export class TourPlanExecutorWithUserInfo extends TourPlanExecutor {
  userInfo?: UsernameResponse;
  superior?: {
    internal?: UsernameResponse[][] | null;
    external?: UsernameResponse[][] | null;
  };
}

export type ExecuterClaims = {
  claimTravelDistance?: number;
  claimExpensesAmount?: number;
  claimExpensesNote?: string;
  claimWorkStart?: number;
  claimWorkFinish?: number;
  claimBreakStart?: number;
  claimBreakFinish?: number;
  claimWorkDuration?: number;
  claimBreakDuration?: number;
};

export class TourPlanPhoto extends BaseModel {
  tourPlanId: string;
  photoId: string;

  constructor() {
    super();
    this.tourPlanId = null;
    this.photoId = null;
  }
}

/**
 * The value of the `state` query parameter which is used instead of Angular router state when the visit is opened in a new tab.
 */
export class VisitState {
  /**
   * The id of the executor. Used only when the visit is opened by a superior user on behalf of a different user.
   * NOTE: should be used with executorName.
   */
  executorId?: string;
  /**
   * The name of the executor. Used only when the visit is opened by a superior user on behalf of a different user.
   * NOTE: should be use with executorId.
   */
  executorName?: string;
  executorUid?: string;
  readonlyMode?: boolean;
  callBackUrl?: string;
  scrollTo?: 'PersonalArrangements' | 'PromoActions';
}
