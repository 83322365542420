import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {
  Locale,
  LocalizedIdentifier,
  LocalizedValue,
} from 'src/app/shared/models';
import {BaseCrudHttpService} from './base.http-service';
import {II18nDataService} from '../../interfaces/data-service';
import {Filter} from '@loopback/filter';

@Injectable()
export class I18NHttpService
  extends BaseCrudHttpService<LocalizedValue>
  implements II18nDataService
{
  constructor(http: HttpClient) {
    super(http, '/sys/i18n/values');
  }

  public override async getCount(filter?: Filter): Promise<number> {
    const result = await this.GET<number>('/sys/i18n/identifiers', {
      params: {
        filter: filter ? JSON.stringify(filter) : undefined,
        meta: 'count',
      },
    });

    if (typeof result === 'number') {
      return Promise.resolve(result);
    }

    return Promise.reject(
      `Response value type is not a number: probably the endpoint GET '/sys/i18n/identifiers' doesn't support meta param.`,
    );
  }

  public override async exists(filter?: Filter): Promise<boolean> {
    const result = await this.GET<boolean>('/sys/i18n/identifiers', {
      params: {
        filter: filter ? JSON.stringify(filter) : undefined,
        meta: 'exists',
      },
    });

    if (typeof result === 'boolean') {
      return Promise.resolve(result);
    }

    return Promise.reject(
      `Response value type is not a boolean: probably the endpoint GET '/sys/i18n/identifiers' doesn't support meta param.`,
    );
  }

  public getLocales(): Promise<Locale[]> {
    return this.GET<Locale[]>(`/sys/i18n/locales`);
  }

  public getEnabledLocales(): Promise<Locale[]> {
    return this.GET<Locale[]>(`/sys/i18n/locales/enabled`);
  }

  public getIdentifiers(
    filter?: Filter<LocalizedIdentifier>,
  ): Promise<LocalizedIdentifier[]> {
    return this.GET<LocalizedIdentifier[]>(
      '/sys/i18n/identifiers',
      filter ? {params: {filter: JSON.stringify(filter)}} : {},
    );
  }
}
