import {Route} from '@angular/router';

export function processRoutesToListOfRoutes(
  allRoutes: {path: string; id: string}[],
  routes: Route[],
  parentsPath: string,
): void {
  routes.forEach(r => {
    const path = `${parentsPath}/${r.path}`;
    if (r.data?.id) {
      allRoutes.push({
        id: r.data.id,
        path: path,
      });
    }

    if (r.children) {
      processRoutesToListOfRoutes(allRoutes, r.children, path);
    } else {
      // TODO: this is a hack - better solution?
      const x: any = r as any;
      if (x._loadedRoutes?.length) {
        processRoutesToListOfRoutes(allRoutes, x._loadedRoutes, path);
      }
    }
  });
}
