import {Injectable} from '@angular/core';
import {
  ReportExternal,
  ReportExternalControllerApiService,
} from '@salesapp/api';
import {Create$Params} from '@salesapp/api/report-external-controller/create';
import {Delete$Params} from '@salesapp/api/report-external-controller/delete';
import {Update$Params} from '@salesapp/api/report-external-controller/update';
import {Observable} from 'rxjs';
import {BaseStorage} from './base.storage';

export type ExternalReportCreateParams = Create$Params;
export type ExternalReportUpdateParams = Update$Params;

@Injectable()
export class ExternalReportStorage extends BaseStorage<
  ReportExternal,
  ReportExternal[],
  never,
  ExternalReportCreateParams,
  ExternalReportUpdateParams,
  Delete$Params
> {
  constructor(protected apiService: ReportExternalControllerApiService) {
    super(apiService);
  }

  getListMethod(params?: never): Observable<ReportExternal[]> {
    return this.apiService.getAll();
  }
}
