import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Role} from '../../models';
import {BaseReadonlyHttpService} from './base.http-service';
import {IRoleDataService} from '../../interfaces/data-service';

@Injectable()
export class RoleHttpService
  extends BaseReadonlyHttpService<Role>
  implements IRoleDataService
{
  constructor(http: HttpClient) {
    super(http, '/auth/roles');
  }
}
