<app-grid-filter [formattedFilter]="filterFormatted$ | async"
                 [collapsed]="collapsed"
                 (expandFilter)="collapsed = !collapsed"
                 (removeFilterValue)="onDeleteFilterItem($event)">
  <app-form *ngIf="form"
            [formGroup]="form"
            [formActions]="formActions"
            [actionInProgress]=""
            (action)="onFormAction()">

    <app-form-row>
      <app-form-column>
        <app-input-date-range [formControl]="getFormGroupControl('fromTo')"
                              [formControlRef]="getFormGroupControl('fromTo')"
                              [label]="'entity.feedback.filter.from-to.label' | translate"></app-input-date-range>
      </app-form-column>
    </app-form-row>

    <app-form-row>
      <app-form-column>
        <app-input-multiselect [formControl]="getFormGroupControl('projects')"
                               [formControlRef]="getFormGroupControl('projects')"
                               [label]="'entity.feedback.filter.projects.label' | translate"
                               [options]="projectOptions$ | async"></app-input-multiselect>
      </app-form-column>
      <app-form-column>
        <app-input-select [formControl]="getFormGroupControl('store')"
                          [formControlRef]="getFormGroupControl('store')"
                          [label]="'entity.feedback.filter.store.label' | translate"
                          [options]="storeOptions$ | async"
                          [itemType]="'store'"></app-input-select>
      </app-form-column>
    </app-form-row>

    <app-form-row>
      <app-form-column>
        <app-input-multiselect [formControl]="getFormGroupControl('executors')"
                               [formControlRef]="getFormGroupControl('executors')"
                               [label]="'entity.feedback.filter.executors.label' | translate"
                               [options]="usernameOptions$ | async"
                               [itemType]="'username'"></app-input-multiselect>
      </app-form-column>
      <app-form-column>
        <app-input-multiselect [formControl]="getFormGroupControl('creators')"
                               [formControlRef]="getFormGroupControl('creators')"
                               [label]="'entity.feedback.filter.creators.label' | translate"
                               [options]="usernameOptions$ | async"
                               [itemType]="'username'"></app-input-multiselect>
      </app-form-column>
    </app-form-row>
    <app-form-actions #formActions
                      actionIcon="fa-solid fa-magnifying-glass"
                      cancelIcon="fa fa-times"
                      [actionLabel]="'form.action.search' | translate"
                      [actionDisabled]=""
                      [actionVisible]="true"
                      [position]="'right'"></app-form-actions>
  </app-form>
</app-grid-filter>
