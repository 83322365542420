import {
  ObjectTypeIds,
  ObjectTypeNames,
} from '@retrixhouse/salesapp-shared/lib/common';
import {
  IObjectProperty,
  ValueType,
} from '@retrixhouse/salesapp-shared/lib/models';
import {ButtonStyle, ButtonType} from 'devextreme/common';
import {
  Column,
  ColumnButton,
  ExportingEvent,
  RowPreparedEvent,
  SelectionChangedEvent,
} from 'devextreme/ui/data_grid';
import {Observable} from 'rxjs';
import {Action} from '../../domain';

export interface GridOptions {
  allowExport?: boolean;
  allowExportSelectedData?: boolean;
  allowColumnChooser?: boolean;
  allowColumnReordering?: boolean;
  allowColumnResizing?: boolean;
  allowRestoreDefaultLayout?: boolean;
  showSelection?: boolean;
  selectionMode?: 'single' | 'multiple';
  selectAllMode?: 'page' | 'allPages';
  hoverStateEnabled?: boolean;
  rowAlternationEnabled?: boolean;
  toolbarVisible?: boolean;
  toolbarLocation?: 'bottom' | 'top';
  userStorageKey?: string;
}

export interface GridToolbarButton extends Action {
  i18nIdentifierHint?: string;
  buttonType?: ButtonType;
  buttonStyle?: ButtonStyle;
  location: 'after' | 'before' | 'center';
  disabledWhenEmptySelection?: boolean;
  reflectSelection: boolean;
  count?: string | number;
}

export interface GridRowAction extends Action {
  visibilityResolver?: (data: unknown) => boolean;
}

export interface BaseGridServiceImplementor<T> {
  gridOptions: GridOptions;
  data$: Observable<T[]>;
  toolbarButtons$: Observable<GridToolbarButton[]>;
  gridRowActions$?: Observable<GridRowAction[]>;
  // columns$?: Observable<GridColumn[]>;
  loading$?: Observable<boolean>;
  openDetail(data: T): unknown;
  handleRowPrepared?(e: RowPreparedEvent): void;
  handleToolbarButtonClick(button: GridToolbarButton, selection: T[]);
  handleGridRowActionClick?(action: GridRowAction, data: T);
  handleRowSelectionChanged?(e: SelectionChangedEvent): T[];
}

export interface ObjectGridServiceImplementor<T>
  extends BaseGridServiceImplementor<T> {
  objectTypeId: ObjectTypeIds;
}

export interface GridServiceImplementor<T> {
  gridOptions: GridOptions;
  /**
   * List of object properties (e.g. 'name') or nested properties (e.g. 'homeAddress.city')
   * which will be used as default columns when user would like to reset layout of the grid
   */
  defaultVisibleProperties: string[];
  /**
   * List of object properties (e.g. 'version')
   * which will be always ignored and not visible in grid or in column chooser
   */
  ignoredProperties: string[];
  navigationalProperties?: GridNavigationalPropertySetting[];
  /**
   * ObjectTypeId is used for automatic fetching of columns (properties)
   */
  objectTypeId?: ObjectTypeIds;
  objectTypeName?: ObjectTypeNames;
  data$: Observable<T[]>;
  toolbarButtons$: Observable<GridToolbarButton[]>;
  gridRowActions$?: Observable<GridRowAction[]>;
  getColumns?(): Observable<GridColumn[]>;
  openDetail(data: T): unknown;
  handleToolbarButtonClick(button: GridToolbarButton, selection: T[]);
  handleGridRowActionClick?(action: GridRowAction, data: T);
  customExport?(exportingEvent: ExportingEvent): void;
  handleRowPrepared?(e: RowPreparedEvent): void;
  handleRowSelectionChanged?(e: SelectionChangedEvent): T[];
}

export interface GridNavigationalPropertySetting {
  objectTypeId: ObjectTypeIds;
  /**
   * List of object properties (e.g. 'version')
   * which will be visible in grid or in column chooser
   * if provided then ignored properties will not work
   */
  properties?: string[];
  /**
   * List of object properties (e.g. 'version')
   * which will be always ignored and not visible in grid or in column chooser
   */
  ignoredProperties?: string[];
  /**
   * Observable with object type data
   * which will be always ignored and not visible in grid or in column chooser
   */
  data$?: Observable<Map<string, any>>;
  /**
   * foreignKey will be used for matching data on parent entity with navigational entity data e.g. homeAddressId
   */
  foreignKey?: string;
  /**
   * dataPath will be used as a key for merged data with parent entity e.g. when adding homeAddress it will be matched by foreign key and it will be inserted to parent entity under this key e.g homeAddress
   */
  dataPath?: string;
}

export type GridColumnButton = ColumnButton;

type PredefinedFormat =
  | 'billions'
  | 'currency'
  | 'day'
  | 'decimal'
  | 'exponential'
  | 'fixedPoint'
  | 'largeNumber'
  | 'longDate'
  | 'longTime'
  | 'millions'
  | 'millisecond'
  | 'month'
  | 'monthAndDay'
  | 'monthAndYear'
  | 'percent'
  | 'quarter'
  | 'quarterAndYear'
  | 'shortDate'
  | 'shortTime'
  | 'thousands'
  | 'trillions'
  | 'year'
  | 'dayOfWeek'
  | 'hour'
  | 'longDateLongTime'
  | 'minute'
  | 'second'
  | 'shortDateShortTime';

export type GridColumnFormat =
  | PredefinedFormat
  | string
  | ((value: number | Date) => string)
  | ((value: Date) => string)
  | ((value: number) => string);

export type GridColumn = Column & {
  propertyId?: string;
  columnHidingEnabled?: boolean;
  valueType?: ValueType;
  listId?: string;
  property?: IObjectProperty;
};

export type FilterOperator =
  | 'contains'
  | 'notcontains'
  | 'startswith'
  | 'endswith'
  | '='
  | '<>';
