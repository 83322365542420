import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {DxTagBoxModule, DxTextBoxModule} from 'devextreme-angular';
import {FormFieldModule} from '../form-field';
import {UserAvatarModule} from '../user-avatar/user-avatar.component';
import {InputMultiselectComponent} from './input-multiselect.component';

const DX_MODULES = [DxTagBoxModule, DxTextBoxModule];
const UI_MODULES = [FormFieldModule, UserAvatarModule];

@NgModule({
  imports: [CommonModule, TranslateModule, ...DX_MODULES, ...UI_MODULES],
  declarations: [InputMultiselectComponent],
  exports: [InputMultiselectComponent],
})
export class InputMultiSelectModule {}

export * from './input-multiselect.component';
