import {ObjectMapCache} from '@retrixhouse/salesapp-shared/lib/caching';
import {ICountryDataService} from '../../interfaces/data-service';
import {Country} from '../../models';
import {CountryHttpService} from '../http';
import {CountryOfflineService} from '../offline';
import {BaseReadonlyDataService} from './base.data-service';

export class CountryDataService extends BaseReadonlyDataService<
  Country,
  ICountryDataService,
  ObjectMapCache<string, Country>
> {
  constructor(
    onlineService: CountryHttpService,
    offlineService: CountryOfflineService,
    cache: ObjectMapCache<string, Country>,
  ) {
    super(onlineService, offlineService, cache);
  }
}
