import {BaseDataService} from './base.data-service';
import {ISystemDataService} from '../../interfaces/data-service';
import {SystemOfflineService} from '../offline';
import {SystemHttpService} from '../http';

export class SystemDataService extends BaseDataService<ISystemDataService> {

  constructor(
    onlineService: SystemHttpService,
    offlineService: SystemOfflineService,
  ) {

    super(onlineService, offlineService);
  }

  refreshCaches(caches: string[]): Promise<string[]> {
    return this.service.refreshCaches(caches);
  }

}
