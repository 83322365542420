import {Injectable, isDevMode} from '@angular/core';
import {Router} from '@angular/router';
import {PushNotificationRead} from '@salesapp/api';
import {AutoUnsubscribe} from '@salesapp/utils/angular.utils';
import {Observable, Subscription} from 'rxjs';
import {AppInterfaceService} from '../app-interface/app-interface.service';
import {ObjectDialogService} from './object-dialog.service';
import {PushNotificationStorage} from './storage/push-notification.storage';

@Injectable({
  providedIn: 'root',
})
@AutoUnsubscribe()
export class PushNotificationService {
  notifications$: Observable<PushNotificationRead[]> =
    this.pushNotificationStorage.data$;

  private notificationsUpdatedSubscription: Subscription;
  private notificationsListenerSubscription: Subscription;
  private appDidBecameActiveListenerSubsctiption: Subscription;

  constructor(
    private pushNotificationStorage: PushNotificationStorage,
    private objectDialogService: ObjectDialogService,
    private appInterfaceService: AppInterfaceService,
    private router: Router,
  ) {}

  notificationRecieved(data) {
    if (isDevMode()) {
      console.log('pushNotificationRecieved:data', data);
    }
    this.pushNotificationStorage.markAsObsoleted();
    const formattedData = this.formatIncomingNotificationData(
      JSON.parse(data.data),
    );

    if (isDevMode()) {
      console.log('pushNotificationRecieved:dataFormatted', formattedData);
    }

    // This part of the code is for handling notification click from device notification center so it should be triggered only once
    if (
      formattedData &&
      formattedData['salesapp.received_in_background'] &&
      formattedData['salesapp.received_in_background'] === 'true'
    ) {
      this.notificationClicked(formattedData, true);
    }
  }

  markAsRead(notificationId: string) {
    this.pushNotificationStorage.markAsRead({notificationId});
  }

  notificationClicked(
    data: PushNotificationRead | {[k: string]: string},
    fromBackground = false,
  ) {
    if (fromBackground) {
      this.markAsRead(data['salesapp.notificationId']);
    } else {
      if (!data.read) {
        this.markAsRead(data.notificationId);
      }
    }

    this.handleNotificationAction(data, fromBackground);
  }

  init() {
    this.initNotificationsListener();
    this.initAppActivatedFromBackground();
    this.initNotificationsUpdatedProcessing();
  }

  private initNotificationsListener() {
    this.notificationsListenerSubscription = this.appInterfaceService
      .onPushNotification()
      .subscribe({
        next: data => {
          this.notificationRecieved(data);
        },
      });
  }

  private initNotificationsUpdatedProcessing() {
    this.notificationsUpdatedSubscription = this.notifications$.subscribe(
      notifications => {
        this.updateDeviceBadgeCount(notifications);
      },
    );
  }

  private initAppActivatedFromBackground() {
    this.appDidBecameActiveListenerSubsctiption = this.appInterfaceService
      .onAppDidBecomeActive()
      .subscribe(() => {
        this.pushNotificationStorage.markAsObsoleted();
      });
  }

  /**
   * Handles the action to be taken when a push notification is clicked.
   * Right now we support only open dialog and redirect to url.
   */
  private handleNotificationAction(
    data: PushNotificationRead | {[k: string]: string},
    fromBackground = false,
  ) {
    const redirectUrl = fromBackground
      ? data['salesapp.redirectUrl']
      : data?.data['redirectUrl'];
    const openDialog = fromBackground
      ? data['salesapp.openDialog']
        ? JSON.parse(data['salesapp.openDialog'])
        : null
      : data?.data['openDialog'];

    if (openDialog) {
      this.objectDialogService.openObjectDialog({
        objectType: openDialog.objectType,
        objectId: openDialog.objectId,
      });
    }

    if (redirectUrl) {
      this.router.navigateByUrl(redirectUrl);
    }
  }

  private formatIncomingNotificationData(
    props: {key: string; value: string}[],
  ): {[key: string]: string} {
    const data = {};

    props.forEach(prop => {
      if (prop.key.startsWith('salesapp.')) {
        data[prop.key] = prop.value;
      }
    });

    return Object.keys(data).length ? data : null;
  }

  private updateDeviceBadgeCount(notifications: PushNotificationRead[]) {
    const unreadNotifications = notifications.filter(
      notification => !notification.read,
    );
    this.appInterfaceService.setBadgeCount(unreadNotifications.length);
  }
}
