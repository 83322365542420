import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoadingContainerComponent} from './loading-container.component';
import {LoadingModule} from '../loading';

const UI_MODULES = [LoadingModule];

@NgModule({
  imports: [CommonModule, ...UI_MODULES],
  declarations: [LoadingContainerComponent],
  exports: [LoadingContainerComponent],
})
export class LoadingContainerModule {}

export * from './loading-container.component';
