import {
  ObjectArrayCache,
  ObjectMapCache,
} from '@retrixhouse/salesapp-shared/lib/caching';
import {ICurrencyDataService} from '../../interfaces/data-service';
import {Currency} from '../../models';
import {CurrencyHttpService} from '../http';
import {CurrencyOfflineService} from '../offline';
import {
  BaseCrudDataService,
  CachingOptions,
  TTL_DAY,
} from './base.data-service';

export class CurrencyDataService extends BaseCrudDataService<
  Currency,
  ICurrencyDataService,
  ObjectMapCache<string, Currency>
> {
  private _cacheEnabledCurrencies: ObjectArrayCache<Currency>;

  constructor(
    onlineService: CurrencyHttpService,
    offlineService: CurrencyOfflineService,
    cache: ObjectMapCache<string, Currency>,
    cacheEnabledCurrencies: ObjectArrayCache<Currency>,
  ) {
    super(onlineService, offlineService, cache);
    this._cacheEnabledCurrencies = cacheEnabledCurrencies;
  }

  public async getEnabled(
    cachingOptions?: CachingOptions,
  ): Promise<Currency[]> {
    if (cachingOptions?.skipCache) {
      return this.service.getEnabled();
    }

    if (cachingOptions?.forceReload || !this._cacheEnabledCurrencies.isValid) {
      const locales = await this.service.getEnabled();
      this._cacheEnabledCurrencies.init(
        locales,
        cachingOptions?.ttl ?? TTL_DAY,
      );
    }

    return this._cacheEnabledCurrencies.getAll();
  }

  getDefault() {
    return this.service.getDefault();
  }
}
