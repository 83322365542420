import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {IReportDashboard} from '@retrixhouse/salesapp-shared/lib/models';
import {IReportDashboardDataService} from '../../interfaces/data-service/report-dashboard-data.iface';
import {BaseCrudHttpService} from './base.http-service';

const ROOT = '/report-dashboard';
@Injectable()
export class ReportDashboardHttpService
  extends BaseCrudHttpService<IReportDashboard>
  implements IReportDashboardDataService
{
  constructor(http: HttpClient) {
    super(http, ROOT);
  }

  upsert(input: IReportDashboard) {
    return this.PUT<IReportDashboard>(ROOT, input);
  }
}
