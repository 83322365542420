import {ObjectMapCache} from '@retrixhouse/salesapp-shared/lib/caching';
import {IPromoActionDataService} from '../../interfaces/data-service';
import {
  PromoAction,
  PromoActionReportRequest,
  PromoActionReportResult,
} from '../../models';
import {PromoActionHttpService} from '../http';
import {PromoActionOfflineService} from '../offline';
import {
  BaseCrudDataService,
  CachingOptions,
  TTL_DAY,
} from './base.data-service';
import {Filter} from '@loopback/filter';

export class PromoActionDataService extends BaseCrudDataService<
  PromoAction,
  IPromoActionDataService,
  ObjectMapCache<string, PromoAction>
> {
  constructor(
    onlineService: PromoActionHttpService,
    offlineService: PromoActionOfflineService,
    cache: ObjectMapCache<string, PromoAction>,
  ) {
    super(onlineService, offlineService, cache);
  }

  public getListWithChainIds(filter?: Filter<PromoAction & {
    chainId?: string
  }>): Promise<(PromoAction & {chainIds: string[]})[]> {
    return this.service.getListWithChainIds(filter);
  }

  public async getForCustomer(
    customerId: string,
    cachingOptions?: CachingOptions,
  ): Promise<PromoAction[]> {
    if (cachingOptions?.skipCache) {
      return this.service.getForCustomer(customerId);
    }

    if (cachingOptions?.forceReload || !this._cache.isValid) {
      const promoActions = await this.service.getList();
      this.initCache(promoActions, cachingOptions?.ttl ?? TTL_DAY);
    }

    return customerId
      ? this._cache.getValues().filter(pa => pa.project?.customerId === customerId)
      : this._cache.getValues();

  }

  public getPromoActionsWithRelations(
    projectIds: string[],
  ): Promise<PromoAction[]> {
    return this.service.getPromoActionsWithRelations(projectIds);
  }

  public getReport(
    request: PromoActionReportRequest,
  ): Promise<PromoActionReportResult> {
    return this.service.getReport(request);
  }
}
