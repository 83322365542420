import {AnswerType} from '@retrixhouse/salesapp-shared/lib/models';
import {BaseModel} from './base.model';
import {GenericList, GenericListItem} from './generic-list.model';
import {Question} from './question.model';

export class Questionnaire extends BaseModel {
  uid: string;
  version: number;
  name: string;
  description?: string;
  presentationMode: PresentationMode;
  fullScreen: boolean;
  themeId?: string;
  theme?: GenericListItem;
  questions: QuestionnaireItem[];

  constructor() {
    super();
    this.name = null;
    this.description = null;
  }
}

export enum PresentationMode {
  OneByOne = 'OneByOne',
  AllAtOnce = 'AllAtOnce',
}

export enum PhotoTagging {
  Optional = 'optional',
  Mandatory = 'mandatory',
  Disabled = 'disabled',
}

export enum PhotoTaggingMode {
  PredefinedSet = 'predefined-set',
  Path = 'tags-in-the-path',
}

export class QuestionnaireItem extends BaseModel {
  questionnaireId: string;
  questionId: string;
  question: Question;
  parentId?: string;
  position: number;
  required: boolean;
  measured: boolean;
  barcodeScanner: boolean;
  conditionExpression?: string;
  validationExpression?: string;
  minValue?: number;
  maxValue?: number;
  optionListId?: string;
  hint?: string;
  photoTagging?: PhotoTagging;
  photoTaggingMode?: PhotoTaggingMode;
  text: string;
  answerType: AnswerType;
  products: QuestionnaireItemProduct[];
  productCategories: QuestionnaireItemProductCategory[];
  tags: QuestionnaireItemTag[];
  optionList?: GenericList;
  purposeId?: string;
  purpose?: GenericListItem;

  // extra properties
  tagIds: string[];
  productIds: string[];
  productCategoryIds: string[];
  hasSubQuestions?: boolean;
  hasParent?: boolean;
  matrixSubQuestion?: boolean;
  questionNumber?: string;
  listItems: any;

  constructor() {
    super();
    this.questionnaireId = null;
    this.questionId = null;
    this.parentId = null;
    this.position = null;
    this.required = false;
    this.measured = false;
    this.conditionExpression = null;
    this.validationExpression = null;
    this.optionListId = null;
    this.hint = null;
    this.photoTagging = null;
    this.photoTaggingMode = null;
    this.products = [];
    this.productCategories = [];
    this.tags = [];
  }
}

export class QuestionnaireItemTag extends BaseModel {
  tagId: string;
  questionnaireItemId: string;

  constructor() {
    super();
    this.tagId = null;
    this.questionnaireItemId = null;
  }
}

export class QuestionnaireItemProduct extends BaseModel {
  productId: string;
  questionnaireItemId: string;

  constructor() {
    super();
    this.productId = null;
    this.questionnaireItemId = null;
  }
}

export class QuestionnaireItemProductCategory extends BaseModel {
  productCategoryId: string;
  questionnaireItemId: string;

  constructor() {
    super();
    this.productCategoryId = null;
    this.questionnaireItemId = null;
  }
}
