import {
  AfterContentChecked,
  ChangeDetectorRef,
  Directive,
  DoCheck,
  Input,
  IterableDiffer,
  IterableDiffers,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';

// Directive inspired by https://medium.com/@vyakymenko/increasing-rendering-performance-in-angular-with-lazy-render-ngfor-ae8c5d16e194
@Directive({
  selector: '[lazyFor]',
})
export class LazyForDirective implements DoCheck, OnInit, AfterContentChecked {

  lazyForContainer: HTMLElement;

  itemHeight: number;
  itemTagName: string;
  private templateElem: HTMLElement;
  private beforeListElem: HTMLElement;
  private afterListElem: HTMLElement;
  private list: any[] = [];
  private itemHeightList: number[] = [];
  private initialized = false;
  private firstUpdate = true;
  private differ: IterableDiffer<any>;
  private lastChangeTriggeredByScroll = false;

  constructor(private vcr: ViewContainerRef,
              private tpl: TemplateRef<any>,
              private chd: ChangeDetectorRef,
              private iterableDiffers: IterableDiffers) { }

  @Input()
  set lazyForOf(list: any[]) {
    this.list = list;

    if (list) {
      this.differ = this.iterableDiffers.find(list).create();

      if (this.initialized) {
        this.update();
      }
    }
  }

  ngOnInit(): void {
    this.templateElem = this.vcr.element.nativeElement;
    this.lazyForContainer = this.templateElem.parentElement;

    // Add event listener to listen on scroll
    this.lazyForContainer.addEventListener('scroll', () => {
      this.lastChangeTriggeredByScroll = true;
      this.chd.detectChanges();
    });

    this.initialized = true;
  }

  ngDoCheck(): void {
    if (this.differ && Array.isArray(this.list)) {

      if (this.lastChangeTriggeredByScroll) {
        this.update();
        this.lastChangeTriggeredByScroll = false;
      } else {
        const changes = this.differ.diff(this.list);

        if (changes !== null) {
          this.update();
        }
      }
    }
  }

  /**
   * Goes through all children of container (our generated rows)
   * In this step we know the actual height of rows, because they are
   * already rendered. Store this height into list of heights to correct position
   * for further computations. Attribute rowIdx identifies position of our row, and it is
   * defined in the template. This attribute is needed to distinguish our rows,
   * because container contains other elements as well.
   */
  ngAfterContentChecked(): void {
    for (let i = 0; i <= this.lazyForContainer.children.length; i++) {
      const el = this.lazyForContainer.children.item(i);

      if (!el) { continue; }
      const rowIdxAttr = el.getAttribute('rowidx');
      if (!rowIdxAttr) { continue; }

      this.itemHeightList[rowIdxAttr] = el?.clientHeight ?? 0;
    }
  }



  /**
   * List update
   * Show items that can fit into container and set up before and after elements for scroll
   */
  private update(): void {
    // Can't run the first update unless there is an element in the list
    if (this.list.length === 0) {
      this.vcr.clear();
      if (!this.firstUpdate) {
        this.beforeListElem.style.height = '0';
        this.afterListElem.style.height = '0';
      }
      return;
    }

    if (this.firstUpdate) {
      this.onFirstUpdate();
    }

    const scrollTop = this.lazyForContainer.scrollTop;

    // The height of anything inside the container but above the lazyFor content
    const fixedHeaderHeight =
      (this.beforeListElem.getBoundingClientRect().top - this.beforeListElem.scrollTop) -
      (this.lazyForContainer.getBoundingClientRect().top - this.lazyForContainer.scrollTop);

    // This needs to run after the scrollTop is retrieved.
    this.vcr.clear();

    // Get start index
    let listStartI = this.getStartIdx(scrollTop - fixedHeaderHeight);
    listStartI = this.limitToRange(listStartI, 0, this.list.length);

    let listEndI = this.getEndIdx(listStartI);
    listEndI = this.limitToRange(listEndI, -1, this.list.length - 1);

    for (let i = listStartI; i <= listEndI; i++) {
      this.vcr.createEmbeddedView(this.tpl, {
        $implicit: this.list[i],
        index: i,
      });
    }

    this.beforeListElem.style.height = `${this.sumArrayHeights(this.itemHeightList.slice(0, Math.max(listStartI - 1, 0)))}px`;
    this.afterListElem.style.height = `${this.sumArrayHeights(this.itemHeightList.slice(listStartI))}px`;
  }

  /**
   * Get the start idx
   * Computes height of all elements until the scroll height and based on
   * that we know which element to show as first
   * @param scroll number of px defining how much user scrolled
   * @returns idx - Index of first element shown in container
   */
  private getStartIdx(scroll: number): number {
    let idx = 0;
    let height = 0;
    while (height < scroll) {
      height += this.getItemHeight(idx);
      idx++;
    }

    return idx;
  }

  /**
   * Get the end idx
   * Compute how many of rows can fit into container and return the end index.
   * @param startIdx index of first element shown in container
   * @returns idx - Index of last element shown in container
   */
  private getEndIdx(startIdx: number): number {
    let idx = startIdx;
    let height = 0;
    while (height < this.lazyForContainer.clientHeight) {
      height += this.getItemHeight(idx);
      idx++;
    }
    return idx;
  }

  /**
   * Returns height of element. If we know its actual height (present in itemHeightList),
   * retrieve it, else use default itemHeight.
   * @param itemIdx index of element
   * @returns height of element
   */
  private getItemHeight(itemIdx: number): number {
    return this.itemHeightList[itemIdx] !== 0 ? this.itemHeightList[itemIdx] : this.itemHeight;
  }

   /**
    * Helper function to sum height of all elements of array.
    * If the height of element is 0, its actual height is not known and
    * thus use default itemHeight.
    * @param array to sum elements in
    * @returns number presenting sum of heights of array
    */
  private sumArrayHeights(array: number[]): number {
    return array.reduce((sum, x ) => sum + (x !== 0 ? x : this.itemHeight), 0);
  }

  /**
   * First update. Runs only once.
   * Initializes the height map, default height, before and after elements
   * to set up proper scroll.
   */
  private onFirstUpdate(): void {
    this.itemHeightList = this.list.map(i => 0); // init empty height list


    // Create sample element to get itemHeight and element tag
    // This element will be removed in further steps
    let sampleItemElem: HTMLElement;
    if (this.itemHeight === undefined || this.itemTagName === undefined) {
      this.vcr.createEmbeddedView(this.tpl, {
        $implicit: this.list[0],
        index: 0,
      });
      sampleItemElem = this.templateElem.previousSibling as HTMLElement;
    }

    if (this.itemHeight === undefined) {
      this.itemHeight = sampleItemElem?.clientHeight;
    }

    if (this.itemTagName === undefined) {
      this.itemTagName = sampleItemElem?.tagName;
    }

    this.beforeListElem = document.createElement(this.itemTagName);
    this.templateElem.parentElement.insertBefore(this.beforeListElem, this.templateElem);

    this.afterListElem = document.createElement(this.itemTagName);
    this.templateElem.parentElement.insertBefore(this.afterListElem, this.templateElem.nextSibling);

    // If you want to use <li> elements
    if (this.itemTagName.toLowerCase() === 'li') {
      this.beforeListElem.style.listStyleType = 'none';
      this.afterListElem.style.listStyleType = 'none';
    }

    this.firstUpdate = false;
  }

  /**
   * Limit To Range
   *
   * @param num - Element number.
   * @param min - Min element number.
   * @param max - Max element number.
   */
  private limitToRange(num: number, min: number, max: number): number {
    return Math.max(
      Math.min(num, max),
      min,
    );
  }
}
