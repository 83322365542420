import {BaseModel} from './base.model';

export class ProjectStorePeriodicLimit extends BaseModel {

  projectId: string;
  storeId: string;
  periodicLimitId: string;
  constructor() {
    super();
  }
}
