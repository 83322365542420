import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {
  FeedbackKpiInputDisplayOptions,
  ObjectTypeIds,
  ObjectTypeNames,
} from '@retrixhouse/salesapp-shared/lib/common';
import {
  IFeedback,
  IFeedbackWithUserAndOpenedInfo,
  IKPISetWithRelations,
} from '@retrixhouse/salesapp-shared/lib/models';
import {SettingNames} from '@retrixhouse/salesapp-shared/lib/settings';
import {formatStore} from '@retrixhouse/salesapp-shared/lib/utils';
import {DataProvider} from '@salesapp/data-provider';
import {Actions} from '@salesapp/domain';
import {customExportToXlsx} from '@salesapp/grid';
import {ActionsService} from '@salesapp/services';
import {ActionCustomContextResolver} from '@salesapp/shared/domain';
import {UserStorageKeys} from '@salesapp/shared/globals';
import {Feedback} from '@salesapp/shared/models';
import {DateDiffPipe} from '@salesapp/shared/pipes/date-diff.pipe';
import {FormatProjectPipe} from '@salesapp/shared/pipes/format-project.pipe';
import {GenericListItemStorageService} from '@salesapp/storage';
import {AutoUnsubscribe, unsubscribe} from '@salesapp/utils/angular.utils';
import {InputSelectOptions} from '@salesapp/utils/input-select-options';
import {
  formatUser,
  projectContainsString,
  storeContainsString,
  userInfoContainsString,
} from '@salesapp/utils/utils';
import {ExportingEvent} from 'devextreme/ui/data_grid';
import {
  BehaviorSubject,
  Observable,
  Subscription,
  combineLatest,
  from,
  of,
} from 'rxjs';
import {filter, map, switchMap} from 'rxjs/operators';
import {
  GridColumn,
  GridNavigationalPropertySetting,
  GridOptions,
  GridRowAction,
  GridServiceImplementor,
  GridToolbarButton,
} from '../../grid/grid.interfaces';
import {FeedbackFilter} from '../feedback.interfaces';
import {FeedbackUtils} from '../feedback.utils';
import {FeedbackStorageService} from './feedback-storage.service';
import {FeedbackService} from './feedback.service';

@Injectable()
@AutoUnsubscribe()
export class FeedbackGridService implements GridServiceImplementor<IFeedback> {
  gridOptions: GridOptions = GRID_OPTIONS;
  defaultVisibleProperties: string[] = [];
  ignoredProperties: string[] = [];
  navigationalProperties?: GridNavigationalPropertySetting[];
  objectTypeId?: ObjectTypeIds;
  objectTypeName?: ObjectTypeNames;
  data$: Observable<IFeedback[]>;
  toolbarButtons$: Observable<GridToolbarButton[]>;
  gridRowActions$?: Observable<GridRowAction[]> = of([
    Actions.FEEDBACK_RESOLVE.withCustomProps({
      visibilityResolver: (data: Feedback) => {
        return !data.resolved;
      },
    }),
    Actions.FEEDBACK_UNRESOLVE.withCustomProps({
      visibilityResolver: (data: Feedback) => {
        return data.resolved;
      },
    }),
    Actions.VISIT_OPEN.withCustomConditionResolver({
      resolver: (
        params,
        ctx: ActionCustomContextResolver<IFeedbackWithUserAndOpenedInfo>,
      ) => {
        return ctx.data?.tourPlan?.executors
          .map(executor => executor.userId)
          .includes(ctx.currentUserUsername.id);
      },
      resolverParams: null,
      // visibilityResolver: (data: IFeedbackWithUserAndOpenedInfo) => {
      //   return data.tourPlan.executors.map(executor => executor.userId).includes(this.currentUser)
      // }
    }),
  ]);

  private filter$ = new BehaviorSubject<FeedbackFilter>(null);

  private genericListItemSubscription: Subscription;

  constructor(
    private storageService: FeedbackStorageService,
    private dataProvider: DataProvider,
    private translationService: TranslateService,
    private feedbackService: FeedbackService,
    private dateDiffPipe: DateDiffPipe,
    private formatProjectPipe: FormatProjectPipe,
    private genericListItemStorageService: GenericListItemStorageService,
    private actionsService: ActionsService,
  ) {
    this.init();
  }

  updateFilter(filter: FeedbackFilter) {
    this.filter$.next(filter);
    this.storageService.setFilter(filter);
  }

  getColumns(): Observable<GridColumn[]> {
    return combineLatest([this.getDefaultColumns(), this.filter$]).pipe(
      switchMap(([feedbackPropertiesColumns, filter]) => {
        if (filter?.projectIds?.length === 1) {
          return from(
            this.dataProvider.project.getMyKPISets(filter.projectIds[0]),
          ).pipe(
            map(kpiSets => {
              return [
                ...feedbackPropertiesColumns,
                ...kpiSets.map(this.kpiSetToColumn.bind(this)),
              ] as GridColumn[];
            }),
          );
        }
        return of(feedbackPropertiesColumns || []);
      }),
    );
  }

  openDetail(data: IFeedback) {
    this.feedbackService.openDetail(data);
  }

  handleToolbarButtonClick(button: GridToolbarButton, selection: IFeedback[]) {
    throw new Error('Method not implemented.');
  }

  handleGridRowActionClick?(
    action: GridRowAction,
    data: IFeedbackWithUserAndOpenedInfo,
  ) {
    switch (action.id) {
      case Actions.FEEDBACK_RESOLVE.id:
        this.feedbackService.resolveFeedback(data);
        break;
      case Actions.FEEDBACK_UNRESOLVE.id:
        this.feedbackService.unresolveFeedback(data);
        break;
      case Actions.VISIT_OPEN.id:
        this.actionsService.openVisit({
          data: {
            readonlyMode: true,
            executorId: data.tourPlanUserInfo[0].id,
            executorName: formatUser(data.tourPlanUserInfo[0]),
            executorUid: data.tourPlanUserInfo?.[0]?.uid,
          },
          tourPlanId: data.tourPlanId,
        });
      default:
        break;
    }
  }

  customExport = (exportingEvent: ExportingEvent): void => {
    unsubscribe(this.genericListItemSubscription);
    this.genericListItemSubscription =
      this.genericListItemStorageService.dataById$.subscribe({
        next: dataById => {
          customExportToXlsx({
            exportingEvent,
            customColumnsSettings: [
              {
                dataField: 'tourPlan.store',
                columnName: this.translationService.instant(
                  'entity.visit.properties.store.label',
                ),
                resolver: value => {
                  return formatStore(value);
                },
              },
              {
                dataField: 'tourPlan.project',
                columnName: this.translationService.instant(
                  'entity.project.properties.name.label',
                ),
                resolver: value => {
                  return this.formatProjectPipe.transform(value);
                },
              },
              {
                dataField: 'executor',
                columnName: this.translationService.instant(
                  'entity.visit.properties.executor.label',
                ),
                resolver: value => {
                  return formatUser(value);
                },
              },
              {
                dataField: 'userInfo',
                columnName: this.translationService.instant(
                  'entity.feedback.properties.created-by.label',
                ),
                resolver: value => {
                  return formatUser(value);
                },
              },
              {
                dataField: 'tourPlan.store.agencyRegionId',
                columnName: this.translationService.instant(
                  'entity.store.properties.agency-region-id.label',
                ),
                resolver: value => {
                  return dataById.get(value)?.name;
                },
              },
              {
                columnName: this.translationService.instant(
                  'entity.kpi-set.properties.rating.label',
                ),
                resolver: value => {
                  return value?.value;
                },
                dataFieldResolver: (dataField: string) => {
                  const dataFieldParts = dataField.split('.');
                  return (
                    dataFieldParts.length === 3 &&
                    dataFieldParts[0] === 'kpiValueSets' &&
                    dataFieldParts[2] === 'rating'
                  );
                },
              },
            ],
          });
        },
      });
  };

  private init() {
    this.data$ = this.filter$.pipe(
      filter(Boolean),
      switchMap((filter: FeedbackFilter) => {
        return this.storageService.data$.pipe(
          map(data => {
            const dataFormatted: any = data.map(row =>
              this.formatFeedbackRow(
                row,
                filter.projectIds?.length === 1 ? filter.projectIds[0] : null,
              ),
            );
            return dataFormatted;
          }),
        );
      }),
    );
  }

  private formatFeedbackRow(
    row: IFeedbackWithUserAndOpenedInfo,
    projectId: string | null,
  ) {
    const kpiValueSets = {};
    const rendering = this.dataProvider.settingResolver.getValue(
      SettingNames.TourPlan_Feedback_KpiInputDisplay,
      projectId,
    );
    const kpiSetTresholds = row.tourPlan.project.kpiSetThresholds?.sort(
      (a, b) => b.minValue - a.minValue,
    );
    row.kpiValueSets.forEach(kpiValueSet => {
      kpiValueSets[kpiValueSet.kpiSetId] = {
        ...kpiValueSet,
        rating: {
          value:
            rendering === FeedbackKpiInputDisplayOptions.PositiveNegative
              ? kpiSetTresholds?.find(
                  treshold => kpiValueSet.rating >= treshold.minValue,
                )?.name
              : kpiValueSet.rating,
          color: kpiSetTresholds?.find(
            treshold => kpiValueSet.rating >= treshold.minValue,
          )?.color,
        },
      };
    });

    const kpiValues = {};
    if (row.kpiValues && row.kpiValues.length) {
      row.kpiValues.forEach(kpiValue => {
        kpiValues[kpiValue.kpiId] = {
          ...kpiValue,
          value: FeedbackUtils.fromatKpiValueDisplayValue({
            value: kpiValue.value,
            formattingStyle: rendering,
            translateService: this.translationService,
          }),
        };
      });
    }
    return {
      ...row,
      kpiValueSets,
      kpiValues,
      executor: row.tourPlanUserInfo[0],
      createdAtDiff: this.dateDiffPipe.transform(
        row.tourPlan?.finishedAt,
        row.createdAt,
      ),
    };
  }

  private kpiSetToColumn(kpiSet: IKPISetWithRelations) {
    return {
      caption: kpiSet.name,
      visible: true,
      columns: [
        ...kpiSet.items.map(kpi => ({
          dataField: `kpiValues.${kpi.kpiId}.value`,
          caption: kpi.kpi.name,
          visible: true,
          allowHiding: false,
          cssClass: 'ellipsis',
          width: 200,
          minWidth: 80,
          columnHidingEnabled: false,
          cellTemplate: 'valueOrEmptyCellTemplate',
        })),
        {
          dataField: `kpiValueSets.${kpiSet.id}.text`,
          caption: this.translationService.instant(
            'entity.kpi-set.properties.text.label',
          ),
          visible: true,
          allowHiding: false,
          cssClass: 'ellipsis',
          width: 200,
          minWidth: 80,
          columnHidingEnabled: false,
          cellTemplate: 'valueOrEmptyCellTemplate',
        },
        {
          dataField: `kpiValueSets.${kpiSet.id}.rating`,
          caption: this.translationService.instant(
            'entity.kpi-set.properties.rating.label',
          ),
          visible: true,
          allowHiding: false,
          cssClass: 'ellipsis',
          width: 80,
          minWidth: 80,
          columnHidingEnabled: false,
          cellTemplate: 'pillValueOrEmptyCellTemplate',
        },
      ],
      hidden: false,
      minWidth: 80,
      columnHidingEnabled: false,
      cssClass: 'ellipsis',
      allowHiding: false,
    };
  }

  private getDefaultColumns(): Observable<GridColumn[]> {
    return this.genericListItemStorageService.itemsByListId$.pipe(
      map(genericListItemsById => {
        return [
          {
            caption: this.translationService.instant('entity.visit.name'),
            visible: true,
            dataField: 'visit',
            columns: [
              {
                dataField: `tourPlan.uid`,
                caption: this.translationService.instant(
                  'entity.visit.properties.uid.label',
                ),
                visible: true,
                allowHiding: true,
                cssClass: 'ellipsis',
                width: 120,
                minWidth: 80,
              },
              {
                dataField: `tourPlan.state`,
                caption: this.translationService.instant(
                  'entity.visit.properties.state.label',
                ),
                visible: true,
                allowHiding: true,
                cssClass: 'ellipsis',
                width: 100,
                minWidth: 80,
                cellTemplate: 'tourplanStatusCellTemplate',
                lookup: {
                  allowClearing: true,
                  dataSource: InputSelectOptions.tourPlanStateOptions(
                    this.translationService,
                  ),
                  displayExpr: 'name',
                  valueExpr: 'value',
                },
              },
              {
                dataField: `tourPlan.scheduledStart`,
                caption: this.translationService.instant(
                  'entity.visit.properties.scheduled-start.label',
                ),
                visible: true,
                allowHiding: true,
                cssClass: 'ellipsis',
                width: 120,
                minWidth: 80,
                dataType: 'date',
              },
              {
                dataField: `tourPlan.store`,
                caption: this.translationService.instant(
                  'entity.visit.properties.store.label',
                ),
                visible: true,
                allowHiding: true,
                cssClass: 'ellipsis',
                width: 300,
                minWidth: 80,
                cellTemplate: 'storeAvatarCellTemplate',
                calculateFilterExpression: (
                  filterValue,
                  selectedFilterOperation,
                  target,
                ) => {
                  // tslint:disable-next-line:only-arrow-functions
                  return function (data: any) {
                    const store: any = data?.tourPlan?.store;
                    return storeContainsString(filterValue, store);
                  };
                },
              },
              {
                dataField: `tourPlan.store.agencyRegionId`,
                caption: this.translationService.instant(
                  'entity.store.properties.agency-region-id.label',
                ),
                visible: true,
                allowHiding: true,
                cssClass: 'ellipsis',
                width: 120,
                // minWidth: 80,
                cellTemplate: 'genericListCellTemplate',
                lookup: {
                  allowClearing: true,
                  dataSource: genericListItemsById.get(
                    '5ea6e43f-b503-4c51-8245-67997d4f7102',
                  ),
                  displayExpr: 'name',
                  valueExpr: 'id',
                },
              },
              {
                dataField: `tourPlan.project`,
                caption: this.translationService.instant(
                  'entity.project.properties.name.label',
                ),
                visible: true,
                allowHiding: true,
                cssClass: 'ellipsis',
                width: 200,
                minWidth: 80,
                cellTemplate: 'projectAvatarCellTemplate',
                calculateFilterExpression: (
                  filterValue,
                  selectedFilterOperation,
                  target,
                ) => {
                  // tslint:disable-next-line:only-arrow-functions
                  return function (data: any) {
                    const project: any = data?.tourPlan?.project;
                    return projectContainsString(filterValue, project);
                  };
                },
              },
              {
                dataField: `executor`,
                caption: this.translationService.instant(
                  'entity.visit.properties.executor.label',
                ),
                visible: true,
                allowHiding: true,
                cssClass: 'ellipsis',
                minWidth: 220,
                cellTemplate: 'userAvatarCellTemplate',
                calculateFilterExpression: (
                  filterValue,
                  selectedFilterOperation,
                  target,
                ) => {
                  // tslint:disable-next-line:only-arrow-functions
                  return function (data: any) {
                    const executor: any = data?.executor;
                    return userInfoContainsString(filterValue, executor);
                  };
                },
              },
            ],
            minWidth: 80,
            columnHidingEnabled: false,
            cssClass: 'ellipsis',
            allowHiding: true,
          },
          {
            dataField: `userInfo`,
            caption: this.translationService.instant(
              'entity.feedback.properties.created-by.label',
            ),
            visible: true,
            allowHiding: true,
            cssClass: 'ellipsis',
            minWidth: 220,
            cellTemplate: 'userAvatarCellTemplate',
            calculateFilterExpression: (
              filterValue,
              selectedFilterOperation,
              target,
            ) => {
              // tslint:disable-next-line:only-arrow-functions
              return function (data: any) {
                const user: any = data?.userInfo;
                return userInfoContainsString(filterValue, user);
              };
            },
          },
          {
            dataField: `createdAt`,
            caption: this.translationService.instant(
              'entity.feedback.properties.created-at.label',
            ),
            visible: true,
            allowHiding: true,
            cssClass: 'ellipsis',
            width: 200,
            minWidth: 80,
            dataType: 'datetime',
          },
          {
            dataField: `createdAtDiff`,
            caption: this.translationService.instant(
              'entity.feedback.properties.created-at-diff.label',
            ),
            visible: true,
            allowHiding: true,
            cssClass: 'ellipsis',
            width: 120,
            minWidth: 80,
            dataType: 'datetime',
          },
          {
            dataField: `commentRequired`,
            caption: this.translationService.instant(
              'entity.feedback.properties.comment-required.label',
            ),
            visible: true,
            allowHiding: true,
            cssClass: 'ellipsis',
            width: 100,
            minWidth: 80,
            cellTemplate: 'booleanCellTemplate',
          },
          {
            dataField: `photoRequired`,
            caption: this.translationService.instant(
              'entity.feedback.properties.photo-required.label',
            ),
            visible: true,
            allowHiding: true,
            cssClass: 'ellipsis',
            width: 100,
            minWidth: 80,
            cellTemplate: 'booleanCellTemplate',
          },
          {
            dataField: `resolved`,
            caption: this.translationService.instant(
              'entity.feedback.properties.resolved.label',
            ),
            visible: true,
            allowHiding: true,
            cssClass: 'ellipsis',
            width: 100,
            minWidth: 80,
            cellTemplate: 'booleanCellTemplate',
          },
        ];
      }),
    );
  }
}

const GRID_OPTIONS: GridOptions = {
  userStorageKey: UserStorageKeys.DataGridMyFeedback,
};
