import {HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {IPhotoObjectDataService} from '../../interfaces/data-service';
import {
  ExportPhotosAsZipRequest,
  Photo,
  PhotoCreateRequest,
  PhotoTag,
  RotatePhotoRequest,
  SendPhotosByEmailRequest,
} from '../../models';
import {BaseCrudOfflineService} from './base.offline-service';

@Injectable()
export class PhotoObjectOfflineService
  extends BaseCrudOfflineService<Photo>
  implements IPhotoObjectDataService
{
  public getPhotoListByObjectId(objectId: string): Promise<Photo[]> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public getPhotoListForObjectIdList(objectIds: string[]): Promise<Photo[]> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public deletePhoto(photoId: string): Promise<void> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public deletePhotos(photoIds: string[]): Promise<void> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public createPhoto(photo: PhotoCreateRequest): Promise<void> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public rotatePhoto(payload: RotatePhotoRequest): Promise<void> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public addPhotoTags(photoId: string, tagIdList: string): Promise<void> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public getPhotoTags(objectId: string): Promise<PhotoTag[]> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public exportAsPdf(
    photoIdList: string[],
    tempUuid?: string,
  ): Promise<HttpResponse<Blob>> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public exportAsZip(
    payload: ExportPhotosAsZipRequest,
    tempUuid?: string,
  ): Promise<HttpResponse<Blob>> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public sendByEmail(payload: SendPhotosByEmailRequest): Promise<void> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public downloadPhoto(
    photoId: string,
    thumbnail?: boolean,
  ): Promise<HttpResponse<Blob>> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public uploadVisitPhoto(
    form: FormData,
    visitId: string,
    objectId: string,
  ): Promise<any> {
    return Promise.reject('Offline service - not yet implemented');
  }
}
