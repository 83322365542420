import {BaseModel} from './base.model';
import {GenericListItem} from './generic-list.model';
import {Questionnaire} from './questionnaire-model';
import {Project} from './project.model';
import {isNumberOrString} from '../utils/utils';
import {UsernameResponse} from './username-response.model';
import {TourPlan} from './tour-plan.model';
import {Product} from './product.model';
import {
  IPhoto,
  PromoActionFillOutFrequency,
  IProduct,
} from '@retrixhouse/salesapp-shared/lib/models';
import {QuestionnaireResult} from './questionnaire-result.model';
import {TodoListResult} from './todo-list-result.model';

export class PromoAction extends BaseModel {
  name: string;
  uid: string;
  version: number;
  typeId: string;
  type: GenericListItem;
  active: boolean;
  hideFilledOut: boolean;
  mandatory: PromoActionFillOutFrequency;
  mandatoryDays?: number;
  validFrom: Date;
  validTo: Date;
  ordersFrom?: Date;
  projectId: string;
  project: Project;
  questionnaireId: string;
  questionnaire: Questionnaire;
  assignStoreExpression: string;
  notes: string;
  productCategories?: PromoActionProductCategory[];
  products?: PromoActionProduct[];
  stores?: PromoActionStore[];
  chainIds?: string;
  constructor() {
    super();
    this.mandatory = PromoActionFillOutFrequency.OnEachVisit;
  }

  public static formatDateProperties(pa: PromoAction): void {
    if (isNumberOrString(pa.validFrom)) {
      pa.validFrom = new Date(pa.validFrom);
    }

    if (isNumberOrString(pa.validTo)) {
      pa.validTo = new Date(pa.validTo);
    }

    if (isNumberOrString(pa.ordersFrom)) {
      pa.ordersFrom = new Date(pa.ordersFrom);
    }
  }
}

export class PromoActionProductCategory extends BaseModel {
  productCategoryId: string;
  promoActionId: string;

  constructor() {
    super();
  }
}

export class PromoActionProduct extends BaseModel {
  productId: string;
  promoActionId: string;

  constructor() {
    super();
  }
}

export class PromoActionStore extends BaseModel {
  storeId: string;
  promoActionId: string;

  constructor() {
    super();
  }
}

export class PromoActionReportRequest {
  from: Date;
  to: Date;
  projectIds: string[];
  promoActionTypeId?: string;
  promoActionIds?: string[];
  executorIds?: string[];
  chainId?: string;
  agencyRegionId?: string;
  city?: string;
  customerId?: string;
}

export class PromoActionReport {
  id: string;
  userInfo: UsernameResponse;
  visit: TourPlan;
  promoActionId: string;
  todoActionResultId: string;
  questionnaireResult?: QuestionnaireResult;
  todoListResult?: TodoListResult;
}

export class PromoActionReportResult {
  rows: PromoActionReport[];
  promoActions: PromoAction[];
  projects: Project[];
  products: {[customerId: string]: Product[]};
  photoList: IPhoto[];
}

export type PromoActionWithRelationMandatory = PromoAction & {
  isRequired: boolean;
  hidden: boolean;
};
