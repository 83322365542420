import {ClientMode} from '../enums/client-mode.enum';
import {BaseModel} from './base.model';
import {QuestionnaireAnswer} from './questionnaire-answer.model';

export class QuestionnaireResult extends BaseModel {
  mode?: ClientMode;
  startedAt?: Date;
  finishedAt?: Date;
  preloadResultId?: string;
  questionnaireId: string;
  userId?: string;
  todoActionResultId: string;
  promotionalActionId?: string;
  questionnaireAnswers?: QuestionnaireAnswer[];
  highlightColor?: string;

  constructor() {
    super();
  }

  /**
   * Initializes new result
   */
  static getNewQuestionnaireResult(
    questionnaireId: string,
    todoActionResultId: string,
    userId: string,
    promoActionId?: string,
  ): QuestionnaireResult {
    const newResult = new QuestionnaireResult();
    newResult.questionnaireId = questionnaireId;
    newResult.todoActionResultId = todoActionResultId;
    newResult.userId = userId;
    newResult.promotionalActionId = promoActionId;
    newResult.startedAt = new Date();
    newResult.questionnaireAnswers = [];

    return newResult;
  }
}
