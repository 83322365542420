import {ChangeDetectorRef, Component, Input} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Tokenizer} from '@retrixhouse/salesapp-shared/lib/utils';
import {
  ControlValueAccessor,
  FormControlComponent,
  createFormControlProvider,
} from '@salesapp/utils/reactive-form';
import {arrayToMap} from '@salesapp/utils/utils';
import {LoadOptions} from 'devextreme/data';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import {BehaviorSubject} from 'rxjs';
import {TSimpleChanges} from '../../utils/angular.utils';

@Component({
  selector: 'app-input-select',
  templateUrl: './input-select.component.html',
  styleUrls: ['./input-select.component.scss'],
  providers: [createFormControlProvider(() => InputSelectComponent)],
})
@ControlValueAccessor()
export class InputSelectComponent extends FormControlComponent<string> {
  @Input() options:
    | InputSelectOption[]
    | {key: string; items: InputSelectOption[]}[];
  @Input() grouped: boolean = false;
  @Input() itemType: 'store';
  @Input() sortBy: 'name'[] | null = ['name'];

  dataSource$ = new BehaviorSubject<DataSource>(null);

  private optionsByValue: Map<string, InputSelectOption>;

  get searchEnabled() {
    return this.options?.length > 7;
  }

  get inactiveOptionSelected() {
    return this.optionsByValue?.get(this.value)?.inactive;
  }

  constructor(
    private changeDetector: ChangeDetectorRef,
    private translateService: TranslateService,
  ) {
    super(changeDetector);
  }

  ngOnChanges(changes: TSimpleChanges<InputSelectComponent>): void {
    if (changes.options) {
      this.handleOptionsChanged();
    }
  }

  onValueChange(event) {
    this.valueChange.emit(event.value);
  }

  getFormattedStringValue(value) {
    if (!value) {
      return null;
    } else {
      return `${value.name}${
        value.inactive
          ? ` (${this.translateService.instant('input.inactive')})`
          : ''
      }`;
    }
  }

  private handleOptionsChanged() {
    this.setOptionByValue();
    if (this.itemType) {
      this.dataSource$.next(
        new DataSource({
          paginate: true,
          pageSize: 100,
          store: new ArrayStore<
            InputSelectOption[] | {key: string; items: InputSelectOption[]}[]
          >({
            key: 'value',
            data: this.options as any,
          }),
          sort: this.sortBy,
          useDefaultSearch: false,
          load: (loadOptions: LoadOptions) => {
            const startIndex = loadOptions.skip;
            const endIndex = loadOptions.skip + loadOptions.take;
            let loadResult = [];
            // no searchValue part of return all users
            if (
              !loadOptions.searchValue ||
              loadOptions.searchValue.length === 0
            ) {
              loadResult = this.options?.slice(startIndex, endIndex) ?? [];
            } else {
              // search value - apply filter
              const searchWords = Array.from(
                Tokenizer.words(loadOptions.searchValue),
              );

              loadResult = (this.options as InputSelectOption[])
                .filter((option: InputSelectOption) => {
                  return (
                    option.value !== this.value &&
                    searchWords.every(sw =>
                      (option.searchValue ?? []).some(esw => esw.includes(sw)),
                    )
                  );
                })
                .slice(startIndex, endIndex);
            }
            return loadResult;
          },
        }),
      );
    } else {
      this.dataSource$.next(
        new DataSource({
          paginate: true,
          pageSize: 100,
          store: new ArrayStore<
            InputSelectOption[] | {key: string; items: InputSelectOption[]}[]
          >({
            key: 'value',
            data: this.options.map(option => ({
              ...option,
              visible: this.value === option.value ? true : !option.inactive,
            })),
          }),
          sort: this.sortBy,
        }),
      );
    }
  }

  private setOptionByValue() {
    if (Array.isArray(this.options)) {
      this.optionsByValue = arrayToMap(this.options as [], 'value');
    } else {
      const optionsByValue = new Map();
      (this.options as {key: string; items: InputSelectOption[]}[]).forEach(
        group => {
          group.items.forEach(item => {
            optionsByValue.set(item.value, item);
          });
        },
      );
      this.optionsByValue = optionsByValue;
    }
  }
}

export interface InputSelectOption<T = unknown> {
  value: string;
  name: string;
  icon?: string;
  data?: T;
  disabled?: boolean;
  html?: string;
  template?: any;
  visible?: boolean;
  searchValue?: string[];
  inactive?: boolean;
}
