import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ObjectTypeIds} from '@retrixhouse/salesapp-shared/lib/common';
import {ObjectAttachment} from '@salesapp/api';
import {DialogService} from '@salesapp/dialog';
import {triggerBrowserDownloadPopup} from '@salesapp/utils/utils';
import {environment} from 'src/environments/environment';
import {
  ObjectAttachmentEditDialogComponent,
  ObjectAttachmentEditDialogData,
} from '../components/object-attachment-edit-dialog/object-attachment-edit-dialog.component';
import {
  ObjectAttachmentGenerateDialogComponent,
  ObjectAttachmentGenerateDialogData,
} from '../components/object-attachment-generate-dialog/object-attachment-generate-dialog.component';
import {ObjectAttachmentStorage} from '../object-attachment.storage';

@Injectable()
export class ObjectAttachmentsService {
  constructor(
    private dialogService: DialogService,
    private translateService: TranslateService,
    private objectAttachmentStorage: ObjectAttachmentStorage,
    private httpClient: HttpClient,
  ) {}

  createAttachment(params: Omit<ObjectAttachmentEditDialogData, 'attachment'>) {
    this.openEditAttachmentDialog(params);
  }

  editAttachment(attachment: ObjectAttachmentEditDialogData) {
    this.openEditAttachmentDialog(attachment);
  }

  downloadAttachment(attachment: ObjectAttachment) {
    // NOTE: we can't use generated api service because there is no way how to send custom headers without using http interceptor. We can implemeent custom interceptor if needed in the future
    this.httpClient
      .get(
        `${environment.backEndBaseUrl}/core/object-attachments/download/${attachment.objectId}/${attachment.id}`,
        {
          observe: 'response',
          responseType: 'blob' as 'json',
        },
      )
      .subscribe({
        next: response => {
          triggerBrowserDownloadPopup(response as any, attachment.fileName);
        },
      });
  }

  deleteAttachment(attachment: ObjectAttachment) {
    return this.dialogService
      .danger({
        acceptLabel: this.translateService.instant('buttons.delete'),
        rejectLabel: this.translateService.instant('buttons.cancel'),
        showCloseButton: false,
        title: this.translateService.instant(
          'entity.object-attachment.dialog.delete.title',
        ),
      })
      .closed.subscribe({
        next: response => {
          if (response === 'accepted') {
            // do something
            this.objectAttachmentStorage.delete({
              objectId: attachment.objectId,
              attachmentId: attachment.id,
            });
          }
        },
      });
  }

  generateAttachment(params: {objectId: string; objectTypeId: ObjectTypeIds}) {
    this.dialogService.open<
      ObjectAttachmentGenerateDialogComponent,
      ObjectAttachmentGenerateDialogData
    >(ObjectAttachmentGenerateDialogComponent, {
      data: params,
      width: '80%',
      height: '80%',
    });
  }

  private openEditAttachmentDialog(data?: ObjectAttachmentEditDialogData) {
    this.dialogService.open<ObjectAttachmentEditDialogComponent>(
      ObjectAttachmentEditDialogComponent,
      {
        data,
        width: '60%',
        height: '60%',
      },
    );
  }
}
