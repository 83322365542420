import {Injectable} from '@angular/core';
import {Scope} from '../../models';
import {IScopeDataService} from '../../interfaces/data-service';
import {BaseCrudOfflineService} from './base.offline-service';

@Injectable()
export class ScopeOfflineService
  extends BaseCrudOfflineService<Scope>
  implements IScopeDataService
{
  public getScopeNames(): Promise<Scope[]> {
    return Promise.reject('Offline service - not yet implemented');
  }

  public existsScopeName(name: string, excludeId?: string): Promise<boolean> {
    return Promise.reject('Offline service - not yet implemented');
  }
}
