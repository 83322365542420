import {ObjectMapCache} from '@retrixhouse/salesapp-shared/lib/caching';
import {IProductListingTemplateDataService} from '../../interfaces/data-service';
import {ProductListingTemplate} from '../../models';
import {ProductListingTemplateHttpService} from '../http';
import {ProductListingTemplateOfflineService} from '../offline';
import {BaseCrudDataService} from './base.data-service';

export class ProductListingTemplateDataService extends BaseCrudDataService<
  ProductListingTemplate,
  IProductListingTemplateDataService,
  ObjectMapCache<string, ProductListingTemplate>
> {
  constructor(
    onlineService: ProductListingTemplateHttpService,
    offlineService: ProductListingTemplateOfflineService,
    cache: ObjectMapCache<string, ProductListingTemplate>,
  ) {
    super(onlineService, offlineService, cache);
  }

  public createForProductListing(
    productListingId: string,
    productListingTemplate: ProductListingTemplate,
  ): Promise<void> {
    return this.service.createForProductListing(
      productListingId,
      productListingTemplate,
    );
  }

  public overwriteExistingProductListingTemplate(
    productListingTemplateId: string,
    productListingId: string,
  ): Promise<void> {
    return this.service.overwriteExistingProductListingTemplate(
      productListingTemplateId,
      productListingId,
    );
  }
}
