import {Injectable} from '@angular/core';
import {Filter} from '@loopback/filter';
import {IUsernameResponse} from '@retrixhouse/salesapp-shared/lib/responses';
import {from, Observable} from 'rxjs';
import {DataProvider} from '../../data.provider/data-provider';
import {UserDataService} from '../data';
import {
  DataStorage,
  OldBaseStorageService,
  StorageDataStateEnum,
} from './old-base-storage-service';

@Injectable()
export class CurrentUserStorageService extends OldBaseStorageService<
  IUsernameResponse,
  IUsernameResponse
> {
  constructor(dataProvider: DataProvider) {
    super(dataProvider.user, INITIAL_DEFAULT_STORAGE_STATE);
  }

  getData(input?: {
    filter?: Filter<IUsernameResponse>;
  }): Observable<IUsernameResponse> {
    return from((this.dataService as UserDataService).getMyUsernameResponse());
  }
}

const INITIAL_DEFAULT_STORAGE_STATE: DataStorage<IUsernameResponse, unknown> = {
  state: StorageDataStateEnum.Idle,
  value: null,
  error: null,
};
