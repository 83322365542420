import {
  DIALOG_DATA, DialogRef
} from '@angular/cdk/dialog';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { IProject } from '@retrixhouse/salesapp-shared/lib/models';
import { IProjectCopyRequest } from '@retrixhouse/salesapp-shared/lib/requests';
import { IProcessingProgress } from '@retrixhouse/salesapp-shared/lib/responses';
import { NotificationService } from '@salesapp/services';
import { ProjectStorageService } from '@salesapp/storage';
import {
  CustomValidators,
  FormControlsOf
} from '@salesapp/utils/reactive-form';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';
import { ProjectService } from '../../services/project.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-project-copy-dialog',
  templateUrl: './project-copy-dialog.component.html',
  styleUrls: ['./project-copy-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectCopyDialogComponent implements OnInit, OnDestroy {
  form: FormGroup<ProjectCopyFormGroup>;
  copyProgressInterval;
  minimumDate: Date = new Date();
  copyInProgress: boolean = false;
  copyActionEnabled$ = new BehaviorSubject<boolean>(false);
  copyProgress$ = new BehaviorSubject<IProcessingProgress>({
    totalRows: 0,
    processedRows: 0,
  });

  constructor(
    private dialogRef: DialogRef<ProjectCopyDialogData>,
    @Inject(DIALOG_DATA) private data: ProjectCopyDialogData,
    private projectStorageService: ProjectStorageService,
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private projectService: ProjectService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.checkAvailability();
  }

  ngOnDestroy(): void {
    clearInterval(this.copyProgressInterval);
  }

  getFormControl(name: keyof ProjectCopyFormGroup) {
    return this.form.get(name) as FormControl;
  }

  onClose(): void {
    this.dialogRef.close();
  }

  onCopy(): void {
    this.copyInProgress = true;
    const request = this.createRequest();
    this.projectStorageService.copyProject(request)
      .subscribe({
        next: () => {
          NotificationService.notifySuccess(
            this.translateService.instant(
              'views.project.project-copy-success',
            ),
          );
          this.dialogRef.close();
          this.router.navigate(['/customer-agenda/projects', request.newProjectId]);
        },
        error: () => {
          NotificationService.notifyError(
            this.translateService.instant(
              'views.project.project-copy-error',
            ),
          );
          this.copyInProgress = false;
        },
      }
    );
    this.copyActionEnabled$.next(false);
    this.createCheckInterval(request.operationId);
  }

  private initForm(): void {
    this.form = this.formBuilder.group<ProjectCopyFormGroup>({
      sourceProjectUid: this.formBuilder.control({ value: null, disabled: true }),
      newProjectId: this.formBuilder.control({value: uuid(), disabled: true}),
      newExternalRef: this.formBuilder.control(
        null,
        [CustomValidators.required],
        [CustomValidators.asyncUniqeValue(
          this.projectService.extRefExists.bind(this.projectService),
        )],
      ),
      name: this.formBuilder.control(
        null,
        [CustomValidators.required],
        [CustomValidators.asyncUniqeValue(
          this.projectService.nameExists.bind(this.projectService),
        )],
      ),
      begin: this.formBuilder.control(null, [CustomValidators.required]),
      end: this.formBuilder.control(null, [CustomValidators.required]),
      settings: this.formBuilder.control(true),
      responsibleUsers: this.formBuilder.control(true),
      positionPermissions: this.formBuilder.control(true),
      tags: this.formBuilder.control(true),
      kpiSets: this.formBuilder.control(true)
    });

    this.projectStorageService.dataById$
      .pipe(filter(data => data.size !== 0))
      .subscribe(projects => {
        this.updateFormData(projects.get(this.data.sourceProjectId));
      });

    this.copyProgress$.subscribe(data => this.checkCopyProgress(data));
  }

  private updateFormData(data: IProject) {
    let validFrom = new Date();

    if (data.end && moment(data.end).diff(new Date(), 'days') > 0) {
      validFrom.setDate(validFrom.getDate() + 1);
    } else {
      validFrom = new Date();
    }
    this.minimumDate = validFrom;

    const formData = {
      sourceProjectUid: data.uid,
      begin: validFrom,
      end: null,
      name: `${data.name} - copy`,
    };

    this.form.patchValue(formData);
  }

  private checkAvailability() {
    this.projectStorageService.getCopyAvailability().subscribe(data => {
      const length = data.length;
      if (length !== 0 && data[length - 1].operationId) {
        this.copyInProgress = true;
        this.createCheckInterval(data[length - 1].operationId);
      } else {
        this.copyActionEnabled$.next(true);
      }
    });
  }

  private getCopyProgress(operationId: string) {
    this.projectStorageService
      .getCopyProgress(operationId)
      .subscribe(data => this.copyProgress$.next(data));
  }

  private checkCopyProgress(data: IProcessingProgress) {
    if (data.totalRows <= data.processedRows && data.processedRows > 0) {
      clearInterval(this.copyProgressInterval);

      this.form.markAsPristine();
      this.form.enable();

      this.copyInProgress = false;
      this.copyActionEnabled$.next(true);
      this.copyProgress$.next({
        totalRows: 0,
        processedRows: -1,
      });

      this.dialogRef.close();
    }
  }

  private createCheckInterval(operationId: string) {
    this.copyProgressInterval = setInterval(
      operationId => this.getCopyProgress(operationId),
      2000,
      operationId,
    );
  }

  private createRequest(): IProjectCopyRequest {
    const formData = this.form.getRawValue();
    const beginDate = moment(formData.begin)
      .startOf('day')
      .toDate();
    const endDate = moment(formData.end)
      .endOf('day')
      .subtract(1, 'second')
      .toDate();
    delete formData.sourceProjectUid;
    delete formData.begin;
    delete formData.end;    

    const request: IProjectCopyRequest = {
      operationId: uuid(),
      sourceProjectId: this.data.sourceProjectId,
      begin: beginDate,
      end: endDate,
      ...formData,
    };
    
    return request;
  }
}

export interface ProjectCopyValue {
  sourceProjectUid: string;
  newProjectId: string;
  newExternalRef: string;
  name: string;
  begin: Date;
  end: Date;
  settings: boolean;
  responsibleUsers: boolean;
  positionPermissions: boolean;
  tags: boolean;
  kpiSets: boolean;
}

export interface ProjectCopyDialogData {
  sourceProjectId?: string;
}

type ProjectCopyFormGroup = FormControlsOf<ProjectCopyValue>;
