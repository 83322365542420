import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseCrudHttpService} from './base.http-service';
import {
  PersonalArrangement,
  PersonalArrangementReport,
  PersonalArrangementRequest,
} from '../../models';
import {IPersonalArrangementDataService} from '../../interfaces/data-service';

@Injectable()
export class PersonalArrangementHttpService
  extends BaseCrudHttpService<PersonalArrangement>
  implements IPersonalArrangementDataService
{
  constructor(http: HttpClient) {
    super(http, '/personal-arrangements');
  }

  public getReport(
    request: PersonalArrangementRequest,
  ): Promise<PersonalArrangementReport[]> {
    return this.POST(`/personal-arrangements/report`, request);
  }
}
