import {Injectable} from '@angular/core';
import {map, take} from 'rxjs/operators';
import {ProductService} from '../domain/product/services/product.service';
import {StoreService} from '../domain/store/services/store.service';
import {UserManagementService} from '../domain/user-management/services/user-management.service';
import {UserProfileReadService} from './read/user-profile-read.service';

@Injectable()
export class ObjectDialogService {
  constructor(
    private userManagementService: UserManagementService,
    private userProfileReadService: UserProfileReadService,
    private productService: ProductService,
    private storeService: StoreService,
  ) {}

  openObjectDialog(params: {
    objectType: 'UserProfile' | 'Product' | 'Store';
    objectId: string;
  }) {
    const {objectType, objectId} = params;
    switch (objectType) {
      case 'UserProfile':
        this.userProfileReadService
          .getUserIdByUserProfileId(objectId)
          .pipe(
            take(1),
            map(userId => {
              this.userManagementService.openUserDetail(userId, objectId);
            }),
          )
          .subscribe();
        break;
      case 'Product':
        this.productService.openDetail(objectId);
        break;
      case 'Store':
        this.storeService.openDetail(objectId);
        break;
      default:
        break;
    }
  }
}
