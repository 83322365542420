import {
  ProjectContextPermissions,
  RoleNames,
} from '@retrixhouse/salesapp-shared/lib/common';
import {IUser, IUserProfile} from '@retrixhouse/salesapp-shared/lib/models';
import {IUsernameResponse} from '@retrixhouse/salesapp-shared/lib/responses';

export type PermissionOperator = 'and' | 'or';
export type ArrayOperator = 'all' | 'any';

export type ActionResolver = {
  resolver: (params: unknown, ctx?: ActionCustomContextResolver) => boolean;
  resolverParams: unknown;
};

export interface IAction {
  id: string;
  icon: string;
  text?: string;
  i18nIdentifier: string;
  permissions?: {
    [K in PermissionOperator]?: {
      roles?: {
        [K in ArrayOperator]?: RoleNames[];
      };
      projectContext?: {
        [K in ArrayOperator]?: ProjectContextPermissions[];
      };
      customContextResolver?: (ctx: ActionCustomContextResolver) => boolean;
      customResolver?: ActionResolver;
    };
  };

  disabled?: ActionResolver;

  customPermissionResolver?: ActionResolver;
}

export interface ActionCustomContextResolver<T = unknown> {
  currentUserUsername: IUsernameResponse;
  data: T;
}
