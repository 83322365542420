import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {
  ObjectTypeIds,
  RoleNames,
} from '@retrixhouse/salesapp-shared/lib/common';
import {AttachmentTemplate} from '@salesapp/api';
import {
  GridColumn,
  GridNavigationalPropertySetting,
  GridOptions,
  GridRowAction,
  GridServiceImplementor,
  GridToolbarButton,
} from '@salesapp/grid';
import {PermissionResolverService} from '@salesapp/services';
import {sortByKey} from '@salesapp/shared/globals';
import {
  AttachmentTemplateStorage,
  UsernameStorageService,
} from '@salesapp/storage';
import {InputSelectOptions} from '@salesapp/utils/input-select-options';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {Action} from '../../actions/actions';
import {AttachmentTemplateActions} from '../attachment-templates.actions';
import {AttachmentTemplatesService} from './attachment-templates.service';
@Injectable()
export class AttachmentTemplatesGridService
  implements GridServiceImplementor<AttachmentTemplate>
{
  // NOTE: just for generating attachemnts so we need object id when mode is "generate"
  objectId: string;

  filterObjectTypeId: ObjectTypeIds;

  gridOptions: GridOptions = {
    allowColumnChooser: false,
    allowRestoreDefaultLayout: false,
    allowExport: false,
  };
  defaultVisibleProperties: string[] = [];
  ignoredProperties: string[];
  navigationalProperties?: GridNavigationalPropertySetting[] = [
    {
      objectTypeId: ObjectTypeIds.ProductCategory,
      foreignKey: 'userId',
      dataPath: 'user',
      data$: this.usernameStorageService.dataById$,
    },
  ];

  toolbarButtons$: BehaviorSubject<GridToolbarButton[]> = new BehaviorSubject(
    [],
  );
  gridRowActions$?: BehaviorSubject<GridRowAction[]> = new BehaviorSubject([]);

  data$: Observable<AttachmentTemplate[]>;

  private mode: 'manage' | 'generate';

  constructor(
    private attachmentTemplateStorage: AttachmentTemplateStorage,
    private attachmentTemplateService: AttachmentTemplatesService,
    private permissionResolverService: PermissionResolverService,
    private translateService: TranslateService,
    private usernameStorageService: UsernameStorageService,
  ) {
    this.data$ = this.attachmentTemplateStorage.data$.pipe(
      map(data =>
        sortByKey(
          data.map(template => ({
            ...template,
            objectTypeTranslated: ObjectTypeIds[template.objectTypeId],
          })),
          'name',
        ),
      ),
    );
  }

  init(mode: 'manage' | 'generate') {
    this.setMode(mode);
    this.setObjectTypeId(ObjectTypeIds.UserProfile);
    this.setActions();
  }

  setMode(mode: 'manage' | 'generate') {
    this.mode = mode;
  }

  setObjectTypeId(objectTypeId: ObjectTypeIds) {
    this.filterObjectTypeId = objectTypeId;
    this.attachmentTemplateStorage.setFilter({
      objectTypeId: this.filterObjectTypeId,
    });
  }

  setObjectId(objectId: string) {
    this.objectId = objectId;
  }

  getColumns?(): Observable<GridColumn[]> {
    const columns: GridColumn[] = [
      {
        caption: this.translateService.instant(
          'entity.attachment-template.properties.name.label',
        ),
        dataField: 'name',
        width: 400,
        minWidth: 80,
        columnHidingEnabled: false,
        propertyId: '',
        dataType: 'string',
        cssClass: 'ellipsis',
      },
      {
        caption: this.translateService.instant(
          'entity.object-attachment.properties.user.label',
        ),
        dataField: 'user',
        width: 300,
        minWidth: 80,
        columnHidingEnabled: false,
        propertyId: '',
        dataType: 'string',
        cssClass: 'ellipsis',
        cellTemplate: 'userAvatarCellTemplate',
      },
      {
        caption: this.translateService.instant(
          'entity.object-attachment.properties.created-at.label',
        ),
        dataField: 'createdAt',
        width: 200,
        minWidth: 80,
        columnHidingEnabled: false,
        propertyId: '',
        dataType: 'string',
        cssClass: 'ellipsis',
        cellTemplate: 'localizedDateTimeCellTemplate',
      },
      {
        caption: this.translateService.instant(
          'entity.object-attachment.properties.file-name.label',
        ),
        dataField: 'fileName',
        width: 300,
        minWidth: 80,
        columnHidingEnabled: false,
        propertyId: '',
        dataType: 'string',
        cssClass: 'ellipsis',
      },
      {
        caption: this.translateService.instant(
          'entity.object-attachment.properties.file-size.label',
        ),
        dataField: 'fileSize',
        width: 100,
        minWidth: 80,
        columnHidingEnabled: false,
        propertyId: '',
        dataType: 'string',
        cssClass: 'ellipsis',
        cellTemplate: 'fileSizeCellTemplate',
      },
      {
        caption: this.translateService.instant(
          'entity.object-attachment.properties.content-type.label',
        ),
        dataField: 'contentType',
        width: 120,
        minWidth: 80,
        columnHidingEnabled: false,
        propertyId: '',
        dataType: 'string',
        cssClass: 'ellipsis',
        cellTemplate: 'fileTypeCellTemplate',
      },
      {
        caption: this.translateService.instant(
          'entity.attachment-template.properties.object-type-id.label',
        ),
        dataField: 'objectTypeId',
        width: 300,
        minWidth: 80,
        columnHidingEnabled: false,
        propertyId: '',
        dataType: 'string',
        cssClass: 'ellipsis',
        cellTemplate: 'objectTypeIdCellTemplate',
        lookup: {
          allowClearing: true,
          dataSource: InputSelectOptions.enumToSelectOptions({
            enumName: 'ObjectTypeIds',
            translateService: this.translateService,
          }),
          displayExpr: 'name',
          valueExpr: 'value',
        },
      },
    ];
    return of(columns);
  }

  openDetail(data: AttachmentTemplate): void {
    if (
      this.mode === 'manage' &&
      this.permissionResolverService.hasRoleAny([
        RoleNames.Admin,
        RoleNames.AttachmentTemplates,
        RoleNames.AttachmentTemplatesEdit,
      ])
    ) {
      this.attachmentTemplateService.editAttachmentTemplate({
        objectTypeId: this.filterObjectTypeId,
        template: data,
      });
    }
  }

  handleToolbarButtonClick(
    button: GridToolbarButton,
    selection: AttachmentTemplate[],
  ): void {
    this.handleAction(button, selection);
  }

  handleGridRowActionClick?(
    action: GridRowAction,
    data: AttachmentTemplate,
  ): void {
    this.handleAction(action, data);
  }

  private handleAction(
    action: Action,
    data: AttachmentTemplate | AttachmentTemplate[],
  ) {
    switch (action.id) {
      case AttachmentTemplateActions.ATTACHMENT_TEMPLATE_CREATE.id:
        this.attachmentTemplateService.createAttachmentTemplate({
          objectTypeId: this.filterObjectTypeId,
        });
        break;
      case AttachmentTemplateActions.ATTACHMENT_TEMPLATE_EDIT.id:
        this.attachmentTemplateService.editAttachmentTemplate({
          objectTypeId: this.filterObjectTypeId,
          template: data as AttachmentTemplate,
        });
        break;
      case AttachmentTemplateActions.ATTACHMENT_TEMPLATE_DELETE.id:
        this.attachmentTemplateService.deleteAttachmentTemplate(
          data as AttachmentTemplate,
        );
        break;
      case AttachmentTemplateActions.ATTACHMENT_TEMPLATE_DOWNLOAD.id:
        this.attachmentTemplateService.downloadAttachmentTemplate(
          data as AttachmentTemplate,
        );
        break;
      case AttachmentTemplateActions.ATTACHMENT_TEMPLATE_GENERATE_ATTACHMENT.id:
        this.attachmentTemplateService.generateAttachment({
          objectId: this.objectId,
          objectTypeId: this.filterObjectTypeId,
          templates: Array.isArray(data) ? data : [data],
        });
        break;
      case AttachmentTemplateActions.ATTACHMENT_TEMPLATE_SHOW_LEGEND.id:
        this.attachmentTemplateService.showLegend();
        break;
      default:
        alert(`There is no handler implemented for action id: ${action.id}`);
        break;
    }
  }

  private setActions() {
    this.toolbarButtons$.next(
      this.mode === 'manage'
        ? [
            AttachmentTemplateActions.ATTACHMENT_TEMPLATE_CREATE.withCustomProps(
              {
                location: 'before',
                buttonType: 'default',
                buttonStyle: 'contained',
              },
            ) as GridToolbarButton,
            AttachmentTemplateActions.ATTACHMENT_TEMPLATE_SHOW_LEGEND.withCustomProps(
              {
                location: 'before',
                buttonType: 'default',
                buttonStyle: 'contained',
              },
            ) as GridToolbarButton,
          ]
        : [
            AttachmentTemplateActions.ATTACHMENT_TEMPLATE_GENERATE_ATTACHMENT.withCustomProps(
              {
                location: 'before',
                buttonType: 'default',
                buttonStyle: 'contained',
              },
            ) as GridToolbarButton,
          ],
    );

    this.gridRowActions$.next(
      this.mode === 'manage'
        ? [
            AttachmentTemplateActions.ATTACHMENT_TEMPLATE_EDIT,
            AttachmentTemplateActions.ATTACHMENT_TEMPLATE_DOWNLOAD,
            AttachmentTemplateActions.ATTACHMENT_TEMPLATE_DELETE,
          ]
        : [AttachmentTemplateActions.ATTACHMENT_TEMPLATE_GENERATE_ATTACHMENT],
    );
  }
}
