import {Injectable} from '@angular/core';
import {OldBaseStorageService} from './old-base-storage-service';
import {DataProvider} from '../../data.provider/data-provider';
import {IDataStorageFolder} from '@retrixhouse/salesapp-shared/lib/models';

@Injectable()
export class FolderStorageService extends OldBaseStorageService<
  IDataStorageFolder,
  IDataStorageFolder[]
> {
  constructor(dataProvider: DataProvider) {
    super(dataProvider.dataStorageFolder);
  }
}
