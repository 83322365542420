import {Injectable} from '@angular/core';
import {Filter} from '@loopback/filter';
import {
  IObjectForm,
  IObjectProperty,
  IObjectType,
} from '@retrixhouse/salesapp-shared/lib/models';
import {Observable, from} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {DataProvider} from '../../data.provider/data-provider';
import {arrayToMap} from '../../utils/utils';
import {ObjectDataService} from '../data';
import {OldBaseStorageService} from './old-base-storage-service';

@Injectable()
export class ObjectStorageService extends OldBaseStorageService<
  IObjectType,
  IObjectType[]
> {
  get objectDataService() {
    return this.dataService as ObjectDataService;
  }

  constructor(dataProvider: DataProvider) {
    super(dataProvider.object, undefined, DEFAULT_FILTER);
  }

  dataById$: Observable<Map<string, IObjectType>> = this.data$.pipe(
    map(listItems => {
      return arrayToMap(listItems, 'id');
    }),
  );

  formsByObjectTypeId$ = this.data$.pipe(
    map(objects => {
      const formsByObjectId = new Map<string, IObjectForm[]>();

      objects.forEach(object => {
        formsByObjectId.set(object.id, object.forms);
      });

      return formsByObjectId;
    }),
  );

  propertiesByObjectTypeId$ = this.data$.pipe(
    map(objects => {
      const propertiesByObjectId = new Map<string, IObjectProperty[]>();

      objects.forEach(object => {
        propertiesByObjectId.set(object.id, object.properties);
      });

      return propertiesByObjectId;
    }),
  );

  propertiesById$ = this.data$.pipe(
    filter(objects => objects?.length > 0),
    map(objects => {
      const propertiesMap = new Map<string, IObjectProperty>([]);

      objects.forEach(object => {
        object.properties?.forEach(property => {
          propertiesMap.set(property.id, property);
        });
      });

      return propertiesMap;
    }),
  );

  getData(input?: {filter?: Filter}): Observable<IObjectType[]> {
    // NOTE: typing is forced because objectdataservice uses ObjectType instead of IObjectType
    return from(
      (this.dataService as ObjectDataService).getObjectTypes(),
    ) as Observable<any[]>;
  }

  deleteProperty(input: {id: string}) {
    return this.command({
      commandMethod$: this.deletePropertyMethod.bind(this),
      commandInput: input,
    });
  }

  updateProperty(input: {id: string; data: Partial<IObjectProperty>}) {
    return this.command({
      commandMethod$: this.updatePropertyMethod.bind(this),
      commandInput: input,
    });
  }
  createProperty(input: IObjectProperty) {
    return this.command({
      commandMethod$: this.createPropertyMethod.bind(this),
      commandInput: input,
    });
  }

  deleteForm(input: {id: string}) {
    return this.command({
      commandMethod$: this.deleteFormMethod.bind(this),
      commandInput: input,
    });
  }

  updateForm(input: {id: string; data: Partial<IObjectForm>}) {
    return this.command({
      commandMethod$: this.updateFormMethod.bind(this),
      commandInput: input,
    });
  }
  createForm(input: IObjectForm) {
    return this.command({
      commandMethod$: this.createFormMethod.bind(this),
      commandInput: input,
    });
  }

  private deletePropertyMethod(input: {id: string}) {
    return this.objectDataService.deleteObjectProperty(input.id);
  }

  private updatePropertyMethod(input: {
    id: string;
    data: Partial<IObjectProperty>;
  }) {
    return this.objectDataService.updateObjectProperty(input.id, input.data);
  }
  private createPropertyMethod(input: IObjectProperty) {
    return this.objectDataService.createObjectProperty(input);
  }

  private deleteFormMethod(input: {id: string}) {
    return this.objectDataService.deleteObjectForm(input.id);
  }

  private updateFormMethod(input: {id: string; data: Partial<IObjectForm>}) {
    return this.objectDataService.updateObjectForm(input.id, input.data);
  }

  private createFormMethod(input: IObjectForm) {
    return this.objectDataService.createObjectForm(input);
  }
}

const DEFAULT_FILTER: Filter<IObjectType> = {
  include: [{relation: 'properties'}, {relation: 'forms'}],
};
