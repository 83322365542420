import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AppInterfaceService} from '../../../shared/app-interface/app-interface.service';
import {DxFormComponent} from 'devextreme-angular';
import {EventName} from '../../../shared/app-interface/event-model';
import {NotificationService} from '../../../shared/services/notification.service';
import * as uuid from 'uuid';
import {Observable, Subscription} from 'rxjs';

@Component({
  templateUrl: 'app-interface.component.html',
  styleUrls: ['app-interface.component.scss'],
})
export class AppInterfaceComponent implements OnInit, OnDestroy {
  createFormData: any = {};
  resolveRequestData: any = {params: '{"result": 2}'};
  promiseList: any[];

  subscribeEventFormData: any = {};
  fireEventFormData: any = {};
  subscribedEventMap = new Map<string, Subscription>();
  subscribedEventNames = [];

  @ViewChild('createRequestForm', {static: false})
  createRequestForm: DxFormComponent;

  @ViewChild('resolveRequestForm', {static: false})
  resolveRequestForm: DxFormComponent;

  @ViewChild('subscribeEventForm', {static: false})
  subscribeEventForm: DxFormComponent;

  @ViewChild('fireEventForm', {static: false})
  fireEventForm: DxFormComponent;

  constructor(private nativeDeviceService: AppInterfaceService) {
    this.createRequest = this.createRequest.bind(this);
    this.resolveRequest = this.resolveRequest.bind(this);

    this.subscribeToEvent = this.subscribeToEvent.bind(this);
    this.fireEvent = this.fireEvent.bind(this);
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.subscribedEventMap.forEach(v => {
      if (v) {
        v.unsubscribe();
      }
    });
  }

  refreshPromiseList(): any {
    const result = [];
    this.nativeDeviceService.getPromiseMap().forEach((value, key) => {
      result.push({
        key,
        name: value.request.name,
        displayName: `${key} - ${value.request.name}`,
      });
    });
    this.promiseList = result;
  }

  promiseClicked(e): void {
    this.resolveRequestData.id = e.itemData.key;
  }

  async createRequest(): Promise<void> {
    const result = this.createRequestForm.instance.validate();
    if (!result.isValid) {
      return;
    }

    // switch based on selected requestType
    const requestTYpe = this.createFormData.requestType;
    let promise;
    switch (requestTYpe) {
      case 'storageSave':
        promise = this.nativeDeviceService.storageSave(
          this.createFormData.params,
        );
        break;
      case 'storageDelete':
        promise = this.nativeDeviceService.storageDelete(
          this.createFormData.params,
        );
        break;
      case 'storageLoad':
        promise = this.nativeDeviceService.storageLoad(
          this.createFormData.params,
        );
        break;
      case 'storageKeys':
        promise = this.nativeDeviceService.storageKeys();
        break;
      case 'storageSize':
        promise = this.nativeDeviceService.storageSize();
        break;
      case 'storageClear':
        promise = this.nativeDeviceService.storageClear();
        break;
      case 'photoCapture':
        promise = this.nativeDeviceService.photoCapture(
          this.createFormData.params,
        );
        break;
      case 'photoLibrary':
        promise = this.nativeDeviceService.photoLibrary(
          this.createFormData.params,
        );
        break;
      case 'locationGet':
        promise = this.nativeDeviceService.locationGet();
        break;
      case 'locationTrack':
        promise = this.nativeDeviceService.locationTrack(
          this.createFormData.params,
        );
        break;
      case 'scanBarcode':
        promise = this.nativeDeviceService.scanBarcode();
        break;
      case 'updateClient':
        promise = this.nativeDeviceService.updateClient(
          this.createFormData.params,
        );
        break;
      case 'getAppInfo':
        promise = this.nativeDeviceService.getAppInfo();
        break;
      case 'openUrl':
        promise = this.nativeDeviceService.openUrl(this.createFormData.params);
        break;
    }

    this.refreshPromiseList();

    promise
      .then(res => {
        NotificationService.notifySuccess(
          `${requestTYpe} success: ${JSON.stringify(res)}`,
        );
        console.log('Resolve from promise', res);
        this.refreshPromiseList();
      })
      .catch(error => {
        NotificationService.notifyError(
          `${requestTYpe} error: ${JSON.stringify(error)}`,
        );
        console.error('Error from promise', error);
        this.refreshPromiseList();
      });
  }

  resolveRequest(): void {
    const result = this.resolveRequestForm.instance.validate();
    if (!result.isValid) {
      return;
    }

    const {id, params} = this.resolveRequestData;
    const additionalParams =
      params.trim().length > 0 ? JSON.parse(params) : {result: null};
    const body = JSON.stringify({id, ...additionalParams});
    (window as any).salesapp.response(body);
    this.resolveRequestData = {params: '{"result": 2}'};
    this.refreshPromiseList();
  }

  subscribeToEvent(): void {
    const result = this.subscribeEventForm.instance.validate();
    if (!result.isValid) {
      return;
    }

    const {eventType} = this.subscribeEventFormData;
    if (this.subscribedEventMap.has(eventType)) {
      NotificationService.notifyError('Event already subscribed');
      return;
    }

    let event: Observable<any>;
    switch (eventType) {
      case EventName.FirebaseId:
        event = this.nativeDeviceService.onFirebaseId();
        break;
      case EventName.Connectivity:
        event = this.nativeDeviceService.onConnectivity();
        break;
      case EventName.Location:
        event = this.nativeDeviceService.onLocation();
        break;
      case EventName.PushNotification:
        event = this.nativeDeviceService.onPushNotification();
        break;
    }

    const subscription = event.subscribe(res => {
      NotificationService.notifySuccess(
        `${eventType} success: ${JSON.stringify(res)}`,
      );
      console.log(eventType, res);
    });
    this.subscribedEventMap.set(eventType, subscription);
    this.subscribedEventNames.push(eventType);
    this.subscribeEventFormData = {};
  }

  deleteEvent(e): void {
    e.cancel = true;
    const eventType = e.itemData;
    const subscription = this.subscribedEventMap.get(eventType);
    if (!subscription) {
      return;
    }

    subscription.unsubscribe();
    this.subscribedEventMap.delete(eventType);
    this.subscribedEventNames.splice(
      this.subscribedEventNames.indexOf(eventType, 1),
    );
  }

  fireEvent(): void {
    const result = this.fireEventForm.instance.validate();
    if (!result.isValid) {
      return;
    }

    const {eventType, params} = this.fireEventFormData;
    const additionalParams =
      params && params.trim().length > 0 ? JSON.parse(params) : null;
    const id = uuid.v4();
    const body = JSON.stringify({
      id,
      name: eventType,
      timestamp: new Date().getTime(),
      params: additionalParams,
    });
    (window as any).salesapp.event(body);
    this.fireEventFormData = {};
  }
}
