import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseCrudHttpService} from './base.http-service';
import {Trigger, TriggerExecutionResult} from '../../models';
import {ITriggerDataService} from '../../interfaces/data-service';

@Injectable()
export class TriggerHttpService
  extends BaseCrudHttpService<Trigger>
  implements ITriggerDataService
{
  constructor(http: HttpClient) {
    super(http, '/sys/triggers');
  }

  public validateTriggerDefinition(definition: string): Promise<boolean> {
    return this.POST<boolean>(
      '/sys/triggers/validate-definition',
      JSON.stringify(definition),
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
  }

  public execute(id: string, context: any): Promise<TriggerExecutionResult> {
    return this.POST<TriggerExecutionResult>(
      `/sys/triggers/${id}/execute`,
      JSON.stringify(context),
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
  }
}
