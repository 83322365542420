import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseCrudHttpService} from './base.http-service';
import {GenericListItem} from '../../models';
import {IGenericListItemDataService} from '../../interfaces/data-service';
import {all} from '@devexpress/analytics-core/analytics-elements-metadata';

@Injectable()
export class GenericListItemHttpService
  extends BaseCrudHttpService<GenericListItem>
  implements IGenericListItemDataService
{
  constructor(http: HttpClient) {
    super(http, '/core/generic-list-items');
  }

  public updateItemPositions(
    positions: {id: string; position: number}[],
  ): Promise<void> {
    return this.PATCH<void>(`/core/generic-list-items/positions`, positions);
  }
  public getListAll(): Promise<GenericListItem[]> {
    return this.GET<GenericListItem[]>(`/core/generic-list-items/`, {
      params: {
        all: true,
      },
    });
  }
}
