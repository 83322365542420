import {ObjectMapCache} from '@retrixhouse/salesapp-shared/lib/caching';
import {ITodoActionDataService} from '../../interfaces/data-service';
import {TodoAction} from '../../models';
import {TodoActionHttpService} from '../http';
import {TodoActionOfflineService} from '../offline';
import {BaseCrudDataService} from './base.data-service';

export class TodoActionDataService extends BaseCrudDataService<
  TodoAction,
  ITodoActionDataService,
  ObjectMapCache<string, TodoAction>
> {
  constructor(
    onlineService: TodoActionHttpService,
    offlineService: TodoActionOfflineService,
    cache: ObjectMapCache<string, TodoAction>,
  ) {
    super(onlineService, offlineService, cache);
  }
}
