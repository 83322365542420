<app-form-field [label]="label"
                [hint]="hint"
                [formControlRef]="formControlRef">
  <dx-text-box [value]="value"
               [placeholder]="placeholder"
               [disabled]="disabled"
               [mask]="mask"
               [isValid]="isValid"
               valueChangeEvent="change input"
               (valueChange)="onValueChange($event)">
    <dxi-button *ngIf="buttonIcon"
                name="input-button"
                location="after"
                [options]="{
                  icon: buttonIcon,
                  type: 'normal',
                  onClick: onButtonClick.bind(this)
                }"></dxi-button>
  </dx-text-box>
</app-form-field>