import {Filter} from '@loopback/filter';
import {ObjectMapCache} from '@retrixhouse/salesapp-shared/lib/caching';
import {IGenericListDataService} from '../../interfaces/data-service';
import {GenericList} from '../../models';
import {GenericListHttpService} from '../http';
import {GenericListOfflineService} from '../offline';
import {
  BaseCrudDataService,
  CachingOptions,
  InitCacheOptions,
} from './base.data-service';

export class GenericListDataService extends BaseCrudDataService<
  GenericList,
  IGenericListDataService,
  ObjectMapCache<string, GenericList>
> {
  constructor(
    onlineService: GenericListHttpService,
    offlineService: GenericListOfflineService,
    cache: ObjectMapCache<string, GenericList>,
  ) {
    super(onlineService, offlineService, cache);
  }

  private defaultFilter(filter?: Filter<GenericList>): Filter<GenericList> {
    return Object.assign(
      {},
      {
        include: [
          {
            relation: 'items',
            scope: {order: ['position asc', 'name asc']},
          },
        ],
      },
      filter,
    );
  }

  public override getList(
    filter?: Filter<GenericList>,
    cachingOptions?: CachingOptions,
  ): Promise<GenericList[]> {
    return super.getList(this.defaultFilter(filter), cachingOptions);
  }

  public override async getSingle(
    id: string,
    cachingOptions?: CachingOptions,
  ): Promise<GenericList> {
    const defaultCachingOptions: CachingOptions & InitCacheOptions = {
      forceReload: cachingOptions?.forceReload,
      ttl: cachingOptions?.ttl,
      skipCache: cachingOptions?.skipCache,
      initBehavior: 'list',
      initFilter: this.defaultFilter(),
    };

    return super.getSingle(id, defaultCachingOptions);
  }

  getSingleForEdit(id: string) {
    return this.service.getSingleForEdit(id);
  }

  public async getAsDataSource(id: string): Promise<{id: string, label: string}[]> {
    const list = await this.getSingle(id);
    return (list.items ?? []).map(item => ({id: item.id, label: item.name}));
  }
}
