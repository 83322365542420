import {NgModule} from '@angular/core';
import {FormModule} from '@salesapp/form';
import {ProductListingCopyDialogComponent} from './components/product-listing-copy-dialog/product-listing-copy-dialog.component';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {DialogModule} from '@salesapp/dialog';
import {
  InputDateModule,
  InputSelectModule,
  InputTextModule,
} from '@salesapp/components';
import {DxProgressBarModule} from 'devextreme-angular';
import {ReactiveFormsModule} from '@angular/forms';
import {ProductListingService} from './services/product-listing.service';

const SALESAPP_MODULES = [DialogModule, FormModule];
const UI_COMPONENTS = [
  InputSelectModule,
  InputTextModule,
  InputDateModule,
  DxProgressBarModule,
];

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    ...SALESAPP_MODULES,
    ...UI_COMPONENTS,
  ],
  providers: [ProductListingService],
  declarations: [ProductListingCopyDialogComponent],
  exports: [ProductListingCopyDialogComponent],
})
export class ProductListingModule {}
