import {ObjectMapCache} from '@retrixhouse/salesapp-shared/lib/caching';
import {IDataStorageFolderDataService} from '../../interfaces/data-service';
import {DataStorageFolder} from '../../models';
import {DataStorageFolderHttpService} from '../http';
import {DataStorageFolderOfflineService} from '../offline';
import {BaseCrudDataService} from './base.data-service';

export class DataStorageFolderDataService extends BaseCrudDataService<
  DataStorageFolder,
  IDataStorageFolderDataService,
  ObjectMapCache<string, DataStorageFolder>
> {
  constructor(
    onlineService: DataStorageFolderHttpService,
    offlineService: DataStorageFolderOfflineService,
    cache: ObjectMapCache<string, DataStorageFolder>,
  ) {
    super(onlineService, offlineService, cache);
  }
}
