<app-form-field [label]="label"
                [hint]="hint"
                [formControlRef]="formControlRef">
  <div *ngIf="!value"
       class="upload-container"
       appFileDropZone
       (filesDropped)="onFilesDropped($event)"
       (fileOver)="onFileOver($event)">


    <div *ngIf="processing$ | async; else content"
         class="loading">
      <app-loading [customColor]="'#afafaf'"></app-loading>
    </div>
    <ng-template #content>
      <div *ngIf="fileOver"
           class="file-over">
        <i class="fa-solid fa-file-arrow-down"></i>
        <span>{{ 'input-file.drop-message' | translate }}</span>
      </div>

      <div *ngIf="!fileOver"
           class="upload-content">
        <div class="upload-body">
          <i class="fa-solid fa-upload"></i>
          <span>{{ 'input-file.info-message' | translate }}</span>
          <span>{{ 'input-file.or' | translate }}</span>
          <dx-button type="default">
            {{ 'input-file.button.select-file' | translate }}
            <input class="hidden-file-input"
                   type="file"
                   [accept]="allowedFileTypes?.join(',')"
                   [value]="null"
                   (change)="onBrowserUploadFile($event);$event.stopPropagation()"
                   (cancel)="$event.stopImmediatePropagation()" />
          </dx-button>

        </div>
        <span class="allowed-files">{{'input-file.allowed-file-types' | translate }}: {{ getAllowedFileTypes() }}</span>
      </div>
    </ng-template>
  </div>

  <div *ngIf="value"
       class="file-list">
    <div *ngFor="let file of files; let i = index; trackBy: trackByIndex"
         class="file">
      <div class="file-icon">
        <i class="{{ getIconForFile(file) }}"></i>
      </div>
      <div class="file-body">
        <div class="file-body-name">
          {{file.name}}
        </div>
        <div class="file-body-size">
          {{file.size | fileSize}}
        </div>
      </div>
      <div class="file-actions"
           (click)="onDeleteFile(i)">
        <i class="file-actions-delete fa-solid fa-trash"
           (click)="onDeleteFile(i)"></i>
      </div>
    </div>
  </div>
</app-form-field>