import {AuthGuardService, FileService} from '../../services';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {ControlValueAccessor} from '../../utils/reactive-form/control-value-accessor';
import {createFormControlProvider} from '../../utils/reactive-form/form.utils';
import {DataProvider} from '../../data.provider/data-provider';
import {environment} from 'src/environments/environment';
import {FormControlComponent} from '../../utils/reactive-form/form-control.component';
import {from, Observable} from 'rxjs';
import {map, switchMap, tap} from 'rxjs/operators';
import {PhotoService} from '../../services/photo.service';
import {TSimpleChanges} from '../../utils/angular.utils';
import {ObjectTypeIds} from '@retrixhouse/salesapp-shared/lib/common';
import {MimeTypes} from '@salesapp/utils/file.utils';

@Component({
  selector: 'app-input-avatar',
  templateUrl: './input-avatar.component.html',
  styleUrls: ['./input-avatar.component.scss'],
  providers: [createFormControlProvider(() => InputAvatarComponent)],
})
@ControlValueAccessor()
export class InputAvatarComponent extends FormControlComponent<string> {
  @Input() uploadDisabled?: boolean;
  @Input() objectTypeId: ObjectTypeIds;
  @Input() objectId?: string;
  @Input() entityVersion?: number;

  @Output() uploadFinished = new EventEmitter<'success' | 'error'>();

  headers$: Observable<any>;

  imageUrl: string;

  get uploadUrl() {
    switch (this.objectTypeId) {
      case ObjectTypeIds.Product:
        return `${environment.backEndBaseUrl}/products/${this.objectId}/picture`;
      case ObjectTypeIds.UserProfile:
        return `${environment.backEndBaseUrl}/auth/user-profiles/${this.objectId}/picture`;
      default:
        console.error(
          `There is no upload url for objectTypeId: ${this.objectTypeId}`,
        );
        break;
    }
  }

  get type() {
    switch (this.objectTypeId) {
      case ObjectTypeIds.Product:
        return 'product';
      case ObjectTypeIds.UserProfile:
        return 'user';
      default:
        console.error(
          `There is no type for objectTypeId: ${this.objectTypeId}`,
        );
        break;
    }
  }

  constructor(
    private changeDetector: ChangeDetectorRef,
    private authGuardService: AuthGuardService,
    private photoService: PhotoService,
    private fileService: FileService,
    private dataProvider: DataProvider,
  ) {
    super(changeDetector);
  }

  ngOnInit() {
    this.setHeaders();
    this.setImageUrl();
  }

  ngOnChanges(changes: TSimpleChanges<InputAvatarComponent>): void {
    if (changes.value) {
      this.setImageUrl();
    }
  }

  onPhotoUploaded(event) {
    if (event.request.status === 200) {
      this.valueChange.emit(event.request.response);
      this.uploadFinished.emit('success');
    } else {
      this.uploadFinished.emit('error');
    }
  }

  async capturePhoto() {
    const photo = await this.photoService.capturePhoto();
    this.uploadPhoto(photo).subscribe();
  }

  async selectFromGallery() {
    const photo = await this.photoService.selectFromGallery();
    this.uploadPhoto(photo).subscribe();
  }

  private setHeaders() {
    this.headers$ = from(this.authGuardService.getToken()).pipe(
      map(token => ({Authorization: 'Bearer ' + token})),
    );
  }

  private uploadPhoto(photo) {
    return this.fileService
      .processFile({
        file: photo,
        availableMimeTypes: [MimeTypes.JPEG],
        fileFormat: 'base64',
      })
      .pipe(
        switchMap(photoInBase64 => {
          const file = new File([photoInBase64], photo.uuid, {
            type: 'image/jpeg',
          });
          const formData = new FormData();
          formData.append(photo.uuid, file);

          switch (this.type) {
            // case 'self':
            //   return from(
            //     this.dataProvider.user.uploadMyProfilePicture(formData),
            //   );
            case 'user':
              return from(
                this.dataProvider.user.uploadUserProfilePicture(
                  this.objectId,
                  formData,
                ),
              );
          }
        }),
        tap(
          photoUrl => {
            this.valueChange.emit(photoUrl);
            this.setImageUrl();
            this.uploadFinished.emit('success');
          },
          error => this.uploadFinished.emit('error'),
        ),
      );
  }

  private setImageUrl() {
    const timestamp = new Date().getTime();

    switch (this.type) {
      case 'user':
        this.imageUrl = this.value
          ? `${environment.profilePictureBaseUrl}${this.value}?t=${timestamp}`
          : `${environment.profilePictureBaseUrl}/assets/no-avatar.png`;
        break;
      case 'product':
        this.imageUrl = this.value
          ? `${environment.productPictureBaseUrl}${this.value}?t=${timestamp}`
          : `${environment.productPictureBaseUrl}/assets/no-avatar.png`;
        break;
      default:
        break;
    }
  }
}
