import {Address, BaseModel, Store, TourPlan, UsernameResponse} from '../models';
import * as moment from 'moment';
import {sprintf} from 'sprintf-js';
import {HttpResponse} from '@angular/common/http';
import {IUsernameResponse} from '@retrixhouse/salesapp-shared/lib/responses';
import {Tokenizer} from '@retrixhouse/salesapp-shared/lib/utils';
import {sortByKeys} from '../globals';
import {IProject, IUserProfile} from '@retrixhouse/salesapp-shared/lib/models';

export const WEEKDAYS = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

/**
 * Determines whether the input is an empty array.
 * @param {any} input - input value to check
 * @returns true if the input is an empty array; false otherwise
 */
export function isEmptyArray(input: any): boolean {
  return Array.isArray(input) && input.length === 0;
}

/**
 * Determines whether the input is a non-empty array.
 * @param {any} input - input value to check
 * @returns true if the input is a non-empty array; false otherwise
 */
export function isNonEmptyArray(input: any): boolean {
  return Array.isArray(input) && input.length > 0;
}

/**
 * Create map from array of objects
 * @param {any} arr input array of objects
 * @param key key in object
 * @returns new Map
 */
export function arrayToMap<
  K extends Extract<keyof T, string>,
  T extends object = any,
>(arr: T[], key: K): Map<T[K], T> {
  return new Map(arr.map(obj => [obj[key], obj]));
}

export function arrayToTree<
  K extends Extract<keyof T, string>,
  T extends object = any,
>(arr: T[], keyExpr: K, parentKeyExpr: K): Array<T & {items: T[]}> {
  const keyMap = new Map<T[K], T[]>();

  arr.forEach(i => {
    if (keyMap.has(i[parentKeyExpr])) {
      keyMap.get(i[parentKeyExpr])!.push(i);
    } else {
      keyMap.set(i[parentKeyExpr], []);
    }
  });

  const result = arr.map(item => ({...item, items: []}));

  result.forEach(item => {
    if (keyMap.has(item[keyExpr])) {
      item.items = keyMap.get(item[keyExpr]);
    }
  });

  return result.filter(item => !item[parentKeyExpr]);
}

/**
 * Try to find element with id in array
 * If element is present - replace, if not push new element to array
 */
export function arrayReplaceOrAdd<T extends BaseModel>(
  array: T[],
  obj: T,
): T[] {
  array = array ?? [];
  const idx = array.findIndex(i => i.id === obj.id);
  if (idx === -1) {
    array.push(obj);
  } else {
    array.splice(idx, 1, obj);
  }

  return array;
}

/**
 * Safely parses integer.
 * @param {string} input - input value to parse
 * @returns number if parsed successfuly; undefined otherwise
 */
export function safeParseInt(input: string): number | undefined {
  try {
    return parseInt(input);
  } catch {}
}

export const TIME_OF_DAY_REGEXP =
  /^(?<h>[0-9]{2}):(?<m>[0-9]{2}):(?<s>[0-9]{2})$/;

/**
 * Determines whether an input string is a valid time of day (HH:MM:SS).
 * @param {string} input - input string to check
 * @returns {boolean} true if input string is valid; false otherwise
 */
export function isValidTimeOfDay(input: string): boolean {
  const match = TIME_OF_DAY_REGEXP.exec(input);

  if (match) {
    const hours = safeParseInt(match.groups['h']);
    const minutes = safeParseInt(match.groups['m']);
    const seconds = safeParseInt(match.groups['s']);

    if (
      hours >= 0 &&
      hours <= 23 &&
      minutes >= 0 &&
      minutes <= 59 &&
      seconds >= 0 &&
      seconds <= 59
    ) {
      return true;
    }
  }

  return false;
}

/**
 * Safely parses an array from an input string.
 * @param {string} input - input string to parse
 * @returns parsed array if suceeded
 */
export function safeParseJSONArray(input: string): [] | undefined {
  try {
    const tmp = JSON.parse(input);
    if (Array.isArray(tmp)) {
      return tmp as [];
    }
  } catch {}

  return undefined;
}

/**
 * Safely parses time of day.
 * @param {string} input - input string to parse
 * @returns parsed time of day or undefined when failed
 */
export function safeParseTimeOfDay(
  input: string,
): {h: number; m: number; s: number} | undefined {
  const match = TIME_OF_DAY_REGEXP.exec(input);

  if (match) {
    const hours = safeParseInt(match.groups['h']);
    const minutes = safeParseInt(match.groups['m']);
    const seconds = safeParseInt(match.groups['s']);

    if (
      hours >= 0 &&
      hours <= 23 &&
      minutes >= 0 &&
      minutes <= 59 &&
      seconds >= 0 &&
      seconds <= 59
    ) {
      return {
        h: hours,
        m: minutes,
        s: seconds,
      };
    }
  }
}

export const DURATION_REGEXP =
  /^(?<d>[0-9]{3})(?<h>[0-9]{2})(?<m>[0-9]{2})(?<s>[0-9]{2})$/;

/**
 * Determines whether an input string is a valid duration.
 * @param {string} input - input string to check
 * @returns {boolean} true if input string is valid; false otherwise
 */
export function isValidDuration(input: string): boolean {
  const match = DURATION_REGEXP.exec(input);

  if (match) {
    const days = safeParseInt(match.groups['d']);
    const hours = safeParseInt(match.groups['h']);
    const minutes = safeParseInt(match.groups['m']);
    const seconds = safeParseInt(match.groups['s']);

    if (
      days >= 0 &&
      days <= 999 &&
      hours >= 0 &&
      hours <= 23 &&
      minutes >= 0 &&
      minutes <= 59 &&
      seconds >= 0 &&
      seconds <= 59
    ) {
      return true;
    }
  }

  return false;
}

export const SECONDS_PER_DAY = 86400;
export const SECONDS_PER_HOUR = 3600;
export const SECONDS_PER_MINUTE = 60;

export function convertDurationToSeconds(input: string): number {
  const isDurationValid = isValidDuration(input);
  if (isDurationValid) {
    const match = DURATION_REGEXP.exec(input);
    const days = safeParseInt(match.groups['d']);
    const hours = safeParseInt(match.groups['h']);
    const minutes = safeParseInt(match.groups['m']);
    const seconds = safeParseInt(match.groups['s']);

    const totalSeconds =
      seconds +
      minutes * SECONDS_PER_MINUTE +
      hours * SECONDS_PER_HOUR +
      days * SECONDS_PER_DAY;
    return totalSeconds;
  } else {
    return 0;
  }
}

export function convertSecondsToDuration(seconds: number): string {
  if (!seconds || seconds === 0) {
    return '';
  }
  const value = Math.abs(seconds);
  const days = Math.trunc(value / SECONDS_PER_DAY);
  const hours = Math.trunc((value - days * SECONDS_PER_DAY) / SECONDS_PER_HOUR);
  const min = Math.trunc(
    (value - days * SECONDS_PER_DAY - hours * SECONDS_PER_HOUR) /
      SECONDS_PER_MINUTE,
  );
  const sec = value % SECONDS_PER_MINUTE;
  return `${seconds < 0 ? '-' : ''}${
    days > 0
      ? days < 10
        ? '00' + days
        : days < 100
        ? '0' + days
        : days
      : '000'
  }.${hours < 10 ? '0' + hours : hours}:${min < 10 ? '0' + min : min}:${
    sec < 10 ? '0' + sec : sec
  }`;
}

export function convertSecondsToDurationPadded(seconds: number): string {
  if (!seconds || seconds <= 0) {
    return '';
  }
  const value = Math.abs(seconds);
  const days = Math.trunc(value / SECONDS_PER_DAY);
  const hours = Math.trunc((value - days * SECONDS_PER_DAY) / SECONDS_PER_HOUR);
  const min = Math.trunc(
    (value - days * SECONDS_PER_DAY - hours * SECONDS_PER_HOUR) /
      SECONDS_PER_MINUTE,
  );
  const sec = value % SECONDS_PER_MINUTE;

  return `${(days ? days.toString() : '0').padStart(3, '0')}.${(hours
    ? hours.toString()
    : '0'
  ).padStart(2, '0')}:${(min ? min.toString() : '0').padStart(2, '0')}:${(sec
    ? sec.toString()
    : '0'
  ).padStart(2, '0')}`;
}

/**
 * Helper function to format number of days.
 * @param {number} days - value to format (number of days)
 * @param {object} options - optional strings representing day (singular) or days (plural)
 * @returns formatted value
 */
export function formatDays(
  days: number,
  options?: {
    day?: string;
    days?: string;
  },
): string {
  let formattedDays = '';
  if (days) {
    formattedDays += days.toString();
    if (days == 1) {
      if (options?.day) {
        formattedDays += ' ' + options.day + ' ';
      } else {
        formattedDays += '.';
      }
    } else {
      if (options?.days) {
        formattedDays += ' ' + options.days + ' ';
      } else {
        formattedDays += '.';
      }
    }
  }

  return formattedDays;
}

export function userInfoContainsString(
  searchValue: string,
  userInfo: UsernameResponse,
) {
  const normalizedSearchValue = Tokenizer.normalize(searchValue);
  const username = Tokenizer.normalize(userInfo.username ?? '');
  const firstName = Tokenizer.normalize(userInfo.firstName ?? '');
  const lastName = Tokenizer.normalize(userInfo.lastName ?? '');
  const middleName = Tokenizer.normalize(userInfo.middleName ?? '');
  const fullname = Tokenizer.normalize(formatUser(userInfo) ?? '');
  const uid = Tokenizer.normalize(userInfo.uid ?? '');

  return (
    username.includes(normalizedSearchValue) ||
    firstName.includes(normalizedSearchValue) ||
    lastName.includes(normalizedSearchValue) ||
    middleName.includes(normalizedSearchValue) ||
    fullname.includes(normalizedSearchValue) ||
    uid.includes(normalizedSearchValue)
  );
}

export function storeContainsString(searchValue: string, store: Store) {
  const normalizedSearchValue = Tokenizer.normalize(searchValue);
  const username = Tokenizer.normalize(store.name ?? '');
  const uid = Tokenizer.normalize(store.uid ?? '');
  const line1 = Tokenizer.normalize(store.address?.line1 ?? '');
  const line2 = Tokenizer.normalize(store.address?.line2 ?? '');
  const zipCode = Tokenizer.normalize(store.address?.zipCode ?? '');
  const city = Tokenizer.normalize(store.address?.city ?? '');
  const region = Tokenizer.normalize(store.address?.region ?? '');
  const state = Tokenizer.normalize(store.address?.state ?? '');
  const countryName = Tokenizer.normalize(store.address?.country?.name ?? '');
  const chainSpecificId = Tokenizer.normalize(store.chainSpecificId ?? '');

  return (
    username.includes(normalizedSearchValue) ||
    uid.includes(normalizedSearchValue) ||
    line1.includes(normalizedSearchValue) ||
    line2.includes(normalizedSearchValue) ||
    zipCode.includes(normalizedSearchValue) ||
    city.includes(normalizedSearchValue) ||
    region.includes(normalizedSearchValue) ||
    state.includes(normalizedSearchValue) ||
    countryName.includes(normalizedSearchValue) ||
    chainSpecificId.includes(normalizedSearchValue)
  );
}

export function projectContainsString(searchValue: string, project: IProject) {
  const normalizedSearchValue = Tokenizer.normalize(searchValue);
  const name = Tokenizer.normalize(project.name ?? '');
  const uid = Tokenizer.normalize(project.uid ?? '');
  const externalRef = Tokenizer.normalize(project.externalRef ?? '');

  return (
    name.includes(normalizedSearchValue) ||
    uid.includes(normalizedSearchValue) ||
    externalRef.includes(normalizedSearchValue)
  );
}

/**
 * Formats duration.
 * @param {string} input - input string representing duration
 * @param options - options for formatting
 */
export function formatDuration(
  input: string | number,
  options?: {
    day?: string;
    days?: string;
  },
): string | null {
  if (typeof input === 'string') {
    const match = DURATION_REGEXP.exec(input);

    if (match) {
      const days = safeParseInt(match.groups['d']);
      const hours = match.groups['h'];
      const minutes = match.groups['m'];
      const seconds = match.groups['s'];
      const formattedDays = formatDays(days, options);

      if (hours !== '00' && minutes !== '00' && seconds !== '00') {
        return `${formattedDays}${hours}:${minutes}:${seconds}`;
      }

      return formattedDays;
    }
  }

  if (typeof input === 'number') {
    const value = Math.abs(input);
    const days = Math.trunc(value / SECONDS_PER_DAY);
    const hours = Math.trunc(
      (value - days * SECONDS_PER_DAY) / SECONDS_PER_HOUR,
    );
    const minutes = Math.trunc(
      (value - days * SECONDS_PER_DAY - hours * SECONDS_PER_HOUR) /
        SECONDS_PER_MINUTE,
    );
    const seconds = input % SECONDS_PER_MINUTE;
    const formattedDays = formatDays(days, options);

    return sprintf('%s%02d:%02d:%02d', formattedDays, hours, minutes, seconds);
  }

  return null;
}

export function isNumberOrString(p: any): boolean {
  return !!p && (typeof p === 'number' || typeof p === 'string');
}

/**
 * Determines whether a name is consistent with: system.naming-convention
 * @param {string} name - name to validate
 * @returns {boolean} true if valid; false otherwise
 */
export function isValidName(name: string): boolean {
  // can't be empty
  if (!name) {
    return false;
  }

  // can't start or end with a dot
  // can't contain two or more dots in a row
  if (name.startsWith('.') || name.endsWith('.') || name.includes('..')) {
    return false;
  }

  // split and check each part
  const parts = name.split('.');
  for (const part of parts) {
    // can't contain anything else but: a-z, 0-9, dash
    if (!part.match('^[a-z0-9-]+$')) {
      return false;
    }

    // can't start or end with a dash
    // can't contain two or more dashes in a row
    if (part.startsWith('-') || part.endsWith('-') || part.includes('--')) {
      return false;
    }
  }

  return true;
}

/**
 * Determines whether a name is a valida property name: camelCase.
 * @param {string} name - name to validate
 * @returns {boolean} true if valid; false otherwise
 */
export function isValidPropertyName(name: string): boolean {
  // can't be empty
  if (!name) {
    return false;
  }

  // must start with: a-z and then contain only: a-z, A-Z or 0-9
  if (name.match('^[a-z][a-zA-Z0-9]*$')) {
    return true;
  }

  return false;
}

/**
 * Sorts the users
 *
 * @param {UsernameResponse[]|IUserProfile[]} users List of users to be sorted
 * @returns {UsernameResponse[]} Sorted list of users by last, middle and first name in this order
 *
 * Todo(Dominik): Allow sorting also for the IUserProfile objects
 */
export function sortUsers(users: IUsernameResponse[]) {
  return sortByKeys(users, ['lastName', 'middleName', 'firstName']);
}

/**
 * Formats username response for displaying.
 * @param {UsernameResponse} user - username response item to format
 * @param {boolean} includeUsername - determines whether to include username
 * @returns {string} formatted username response as <username> (<first name> <last name>)
 */
export function formatUser(
  user: IUsernameResponse,
  includeUsername: boolean = false,
): string {
  if (user) {
    let fullName = '';

    if (user.firstName) {
      fullName += user.firstName.trim();
    }

    if (user.lastName) {
      fullName += ' ' + user.lastName.trim();
    }

    fullName = fullName.trim();

    // format when user has any full name
    if (fullName) {
      if (includeUsername) {
        return `${fullName} (${user.username})`;
      }

      return fullName;
    }

    // otherwise return just username
    return user.username || user.uid;
  }

  return '';
}

/**
 * Formats address for displaying to the user.
 * @param {Address} address - address object to format
 * @param {boolean} singleLine - determines whether to format address in a single line or multi-line
 * @param {boolean} includeCountry - determines whether to include country or not
 * @param {boolean} includeZipCode - determines whether to include zipCode or not
 * @returns {string} formatted addres or empty string if no address
 */
export function formatAddress(
  address: Address,
  singleLine: boolean = true,
  includeCountry: boolean = false,
  includeZipCode: boolean = false,
): string {
  if (address) {
    const elements = [];

    if (address.line1) {
      elements.push(address.line1);
    }

    if (address.line2) {
      elements.push(address.line2);
    }

    let zipCodeAndCity = '';

    if (includeZipCode && address.zipCode) {
      zipCodeAndCity += address.zipCode + ' ';
    }

    if (address.city) {
      zipCodeAndCity += address.city;
    }

    zipCodeAndCity = zipCodeAndCity.trim();

    if (zipCodeAndCity) {
      elements.push(zipCodeAndCity);
    }

    if (address.region) {
      elements.push(address.region);
    }

    if (includeCountry && address.state) {
      elements.push(address.state);
    }

    if (includeCountry && address?.country?.name) {
      elements.push(address.country.name);
    }

    return elements.join(singleLine ? ', ' : '\n');
  }

  return '';
}

export function isValidDate(date: string) {
  return new Date(date).toString() !== 'Invalid Date';
}

/**
 * Safely parses date.
 * @param {string} input - input value to parse
 * @returns Date if parsed successfuly; undefined otherwise
 */
export function safeParseDate(input: string): Date | undefined {
  try {
    return new Date(input);
  } catch {}
}

/**
 * Ensures creation of a Date from input value.
 * @param {string | number | Date} input - input value
 * @returns {Date} created date
 */
export function ensureDate(input: string | number | Date): Date {
  if (typeof input === 'string' || typeof input === 'number') {
    return new Date(input);
  }

  return input;
}

/**
 * Takes 2 intervals and checks if they are overlapping
 * @param {from: Date, to: Date} interval1 first date range
 * @param {from: Date, to: Date} interval2 second date range
 * @returns boolean
 */
export function intervalsOverlapping(
  interval1: {from: Date; to: Date},
  interval2: {from: Date; to: Date},
): boolean {
  return interval1.from <= interval2.to && interval2.from <= interval1.to;
}

/**
 * Returns the decimal part of any provided number.
 * @param {number} number - the number to get the decimal part of.
 * @returns {number} - the decimal part.
 */
export function getDecimalPart(number: number) {
  var decimals = number - Math.floor(number);
  return Number(decimals.toFixed(2));
}

/**
 * Converts any given decimal number to HHMM format.
 * @param {any} hoursNumber - number of hours. (like 5.5, 12)
 * @returns {string} - the provieded decimal number formatted as HHMM
 */
export function getNumberAsHHMM(hoursNumber: any): string {
  const mins = Math.floor(getDecimalPart(hoursNumber) * 60);
  const hours = Number.parseInt(hoursNumber);
  const hoursWithZero = hours >= 10 ? hours : '0' + hours;
  const minsWithZero = mins >= 10 ? mins : '0' + mins;
  return hoursWithZero + ':' + minsWithZero;
}

/**
 * Converts time to decimal value.
 * E.g. converts 2022-02-25 11:30:00 to 11.5
 * @param {Date} date - date to get time from.
 * @returns {number} - converting result.
 */
export function getTimeAsDecimal(date: Date): number {
  const hours = date.getHours();
  const mins = date.getMinutes();
  const decimalPart = mins / 60;
  const total = hours + decimalPart;
  return Number.isNaN(total) ? undefined : total;
}

/**
 * Get label for relative offset between date and current date
 * If it is today - returns today
 * If date is tomorrow - return tomorrow
 * Else returns name of day
 * @param date date to get offset for
 * @returns label for translate service as converting result.
 */
export function getDateRelativeOffset(date: Date): string {
  const day = moment(date).locale('en').format('dddd');
  const today = moment().locale('en').format('dddd');
  const tomorrow = moment(moment()).add(1, 'day').locale('en').format('dddd');

  if (day === today) {
    return 'labels.today';
  }

  if (day === tomorrow) {
    return 'labels.tommorrow';
  }

  return `days-of-week.${day.toLowerCase()}`;
}

/**
 * Compares two visits.
 * @param {TourPlan} a - visit A to compare
 * @param {TourPlan} b - visit B to compare
 * @returns 0 if a is equal to b, -1 is a is less tan b; 1 if a is greater than b
 */
export function compareTourPlans(a: TourPlan, b: TourPlan): number {
  if (typeof a.scheduledStart === 'string') {
    a.scheduledStart = new Date(a.scheduledStart);
  }

  if (typeof b.scheduledStart === 'string') {
    b.scheduledStart = new Date(b.scheduledStart);
  }

  let res =
    a.scheduledStart.getTime() === b.scheduledStart.getTime()
      ? 0
      : a.scheduledStart.getTime() > b.scheduledStart.getTime()
      ? 1
      : -1;

  if (res === 0) {
    // if scheduled start is the same, compare store address:
    // city, line1, line2
    const aCity = a.store?.address?.city ?? '';
    const bCity = b.store?.address?.city ?? '';
    res = aCity.localeCompare(bCity);

    if (res === 0) {
      const aLine1 = a.store?.address?.line1 ?? '';
      const bLine1 = b.store?.address?.line1 ?? '';

      res = aLine1.localeCompare(bLine1);

      if (res === 0) {
        const aLine2 = a.store?.address?.line2 ?? '';
        const bLine2 = b.store?.address?.line2 ?? '';

        return aLine1.localeCompare(bLine1);
      }
    }
  }

  return res;
}

/**
 * Safelly parses json and returns the result if the json is valid, otherwise returns undefined.
 * @param {string} json - input json string.
 * @returns {Promise<any | undefined>} the parsed json object or undefined if the input json is not valid.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const safeParseJSON = (json: string): any => {
  try {
    return JSON.parse(json);
  } catch (SyntaxError) {
    return undefined;
  }
};

/**
 * Retrun the chunks of a provided array.
 * @param {string} str the string to chunk
 * @param {number} len the length of chunks.
 * @returns {string[]} chunks array of the provided string.
 */
export const chunkString = (str: string, len: number): string[] => {
  const size = Math.ceil(str.length / len);
  const r = Array(size);
  let offset = 0;

  for (let i = 0; i < size; i++) {
    r[i] = str.substring(offset, len + offset);
    offset += len;
  }

  return r;
};

/**
 * Triggers the download popup of the browser.
 * For this to work properly:
 *
 *  1- Extend the options of the http request with the following two properties `{observe: 'response', responseType: 'blob' as 'json'}`
 *
 *  2- On the backend side, set the `File-Name` header with the file name and the `Content-Type` header.
 * @param response The response returned by the backend.
 */
export function triggerBrowserDownloadPopup(
  response: HttpResponse<Blob>,
  defaultFileName = 'download',
) {
  if (!response?.body) {
    return;
  }

  const buffer = response.body;
  const blob = new Blob([buffer], {
    type: 'blob',
  });

  var link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);

  let fileName = decodeURI(response.headers.get('File-Name'));
  const contentDisposition = response.headers.get('Content-Disposition');
  const contentType = response.headers.get('Content-Type');

  if (
    (!fileName || fileName == 'null' || fileName == 'undefined') &&
    contentDisposition
  ) {
    const rr = /^attachment; filename=(.*)$/.exec(contentDisposition);
    if (rr?.length === 2) {
      fileName = decodeURI(rr[1]);
    }
  }

  if (!fileName) {
    switch (contentType) {
      case 'application/zip':
        fileName = `${defaultFileName}.zip`;
        break;
      case 'image/jpeg':
        fileName = `${defaultFileName}.jpg`;
        break;
      case 'application/pdf':
        fileName = `${defaultFileName}.pdf`;
        break;
      case 'application/json':
        fileName = `${defaultFileName}.json`;
        break;
      case 'application/vnd.ms-excel':
        fileName = `${defaultFileName}.xls`;
        break;
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        fileName = `${defaultFileName}.xlsx`;
        break;
      case 'text/csv':
        fileName = `${defaultFileName}.csv`;
        break;
    }
  }

  link.download = fileName;
  document.body.appendChild(link);

  link.click();
  window.URL.revokeObjectURL(link.href);
}

/**
 * Validates whether the provided string is a valid email.
 * @param {string} email email to validate.
 * @returns {boolean} `true` if the provided email is valid. Otherwise, `false`.
 */
export function isEmailValid(email: string): boolean {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

/**
 * Converts base64 string to blob
 * @param {string} mimeType the MIME type of the base64
 * @param {string} base64Data the base64
 * @returns {Promise<Blob>}
 */
export async function base64ToBlob(
  mimeType: string,
  base64Data: string,
): Promise<Blob> {
  const res = await fetch(`data:${mimeType};base64,` + base64Data);
  return res.blob();
}

export function ensureArray<T>(item: T | T[]): T[] {
  if (!item) {
    return [];
  }

  return Array.isArray(item) ? item : [item];
}
