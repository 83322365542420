import {DIALOG_DATA, DialogRef} from '@angular/cdk/dialog';
import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {AttachmentTemplate} from '@salesapp/api';
import {
  AttachmentTemplateCreateParams,
  AttachmentTemplateStorage,
} from '@salesapp/storage';
import {CustomValidators, FormControlsOf} from '@salesapp/utils/reactive-form';
import * as uuid from 'uuid';
import {ATTACHMENT_TEMPLATES_ALLOWED_FILE_TYPES} from '../../attachment-templates.consts';

@Component({
  selector: 'app-attachment-template-edit-dialog',
  templateUrl: './attachment-template-edit-dialog.component.html',
  styleUrls: ['./attachment-template-edit-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttachmentTemplateEditDialogComponent {
  form: FormGroup<AttachmentTemplateEditDialogFormControls>;

  allowedFileTypes = ATTACHMENT_TEMPLATES_ALLOWED_FILE_TYPES;

  get isUpdate() {
    return this.data.template;
  }

  get initialFiles() {
    return [this.data.file];
  }

  constructor(
    private dialogRef: DialogRef<void>,
    @Inject(DIALOG_DATA) private data: AttachmentTemplateEditDialogData,
    private formBuilder: FormBuilder,
    private attachmentTemplateStorage: AttachmentTemplateStorage,
  ) {}

  ngOnInit() {
    this.initForm();
  }

  onClose() {
    this.dialogRef.close();
  }

  onSave() {
    const formData = this.form.getRawValue();
    if (this.isUpdate) {
      this.attachmentTemplateStorage
        .update({
          id: formData.template.id,
          body: formData.template,
        })
        .subscribe({
          next: () => this.dialogRef.close(),
        });
    } else {
      this.attachmentTemplateStorage.create({body: formData}).subscribe({
        next: () => this.dialogRef.close(),
      });
    }
  }

  getFormControl(name: keyof AttachmentTemplateCreateParams['body']) {
    return this.form.get(name) as FormControl;
  }

  getAttachmentTemplateFormControl(
    name: keyof AttachmentTemplateCreateParams['body']['template'],
  ) {
    return this.form.controls.template.get(name) as FormControl;
  }

  onFilesChange(files: File[]) {
    if (files) {
      const file = files[0];
      const attachmentTemplateGroup = this.form.controls.template;
      attachmentTemplateGroup.controls.fileName.setValue(file.name);
      attachmentTemplateGroup.controls.fileSize.setValue(file.size);
      attachmentTemplateGroup.controls.contentType.setValue(file.type);
    }
  }

  private initForm() {
    const form =
      this.formBuilder.group<AttachmentTemplateEditDialogFormControls>({
        template: this.formBuilder.group<AttachmentTemplateFormControls>({
          id: this.formBuilder.control(uuid.v4()),
          name: this.formBuilder.control(null, [CustomValidators.required]),
          objectTypeId: this.formBuilder.control(this.data.objectTypeId),
          createdAt: this.formBuilder.control(null),
          contentType: this.formBuilder.control(null),
          fileName: this.formBuilder.control(null),
          fileSize: this.formBuilder.control(null),
          userId: this.formBuilder.control(null),
        }),
        fileData: this.formBuilder.control(null),
      });

    if (this.data) {
      form.controls.template.patchValue(this.data.template);

      if (this.data.base64File && this.data.file) {
        form.controls.fileData.patchValue(this.data.base64File);
        const templateGroup = form.controls.template;
        templateGroup.controls.fileName.setValue(this.data.file.name);
        templateGroup.controls.fileSize.setValue(this.data.file.size);
        templateGroup.controls.contentType.setValue(this.data.file.type);
      }
    }

    if (!this.isUpdate) {
      form.controls.fileData.addValidators([CustomValidators.required]);
    }

    this.form = form;
  }
}

export interface AttachmentTemplateEditDialogData {
  objectTypeId: string;
  template?: AttachmentTemplate;
  file?: File;
  base64File?: string;
}

type AttachmentTemplateFormControls = FormControlsOf<
  AttachmentTemplateCreateParams['body']['template']
>;

type AttachmentTemplateEditDialogFormControls = FormControlsOf<
  AttachmentTemplateCreateParams['body']
>;
