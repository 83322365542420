import {Component, Input, NgModule, OnInit, ViewChild} from '@angular/core';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {DevExtremeModule, DxTreeListComponent} from 'devextreme-angular';
import * as uuid from 'uuid';
import {Position, Setting} from 'src/app/shared/models/';
import CustomStore from 'devextreme/data/custom_store';
import {NotificationService} from '../../services/notification.service';
import {
  ConfirmationService,
  ConfirmType,
} from '../../services/confirmation.service';
import {DataProvider} from '../../data.provider/data-provider';
import {ObjectTypeNames, positionColumnChooser} from '../../globals';
import {GridColumnWidth} from '../../enums/data-grid-column.enum';
import {Router} from '@angular/router';
import {SettingValueType} from '@retrixhouse/salesapp-shared/lib/models';

@Component({
  selector: 'app-positions',
  templateUrl: './position.component.html',
})
export class PositionComponent implements OnInit {
  forceReload: boolean;
  dataSource: CustomStore;
  @ViewChild(DxTreeListComponent, {static: false})
  treeList: DxTreeListComponent;
  @Input() customerId: string | null = null;
  positions: Position[];

  translations;
  public GridColumnWidth = GridColumnWidth;

  maxValues: {name: number; abbreviation: number} = {
    name: null,
    abbreviation: null,
  };

  constructor(
    private router: Router,
    private dataProvider: DataProvider,
    private translate: TranslateService,
    private confirmationService: ConfirmationService,
  ) {
    // this.translate.onLangChange.subscribe(() => this.fetchTranslations());
    this.onClickPermissions = this.onClickPermissions.bind(this);
    this.onReorder = this.onReorder.bind(this);
  }

  ngOnInit(): void {
    this.dataProvider.jsonSchema
      .getProperties(ObjectTypeNames.Position)
      .then(object => {
        this.maxValues = {
          name: object['name'].maxLength,
          abbreviation: object['abbreviation'].maxLength,
        };
      });

    this.dataSource = new CustomStore({
      key: 'id',
      load: () => {
        return this.dataProvider.position
          .getForCustomer(this.customerId, {forceReload: this.forceReload})
          .then(response => {
            this.positions = response;
            return {
              data: response,
            };
          });
      },
      insert: (values: Partial<Position>): any => {
        const position: Position = Object.assign(new Position(), values, {
          id: uuid.v4(),
          customerId: this.customerId,
        });
        this.dataProvider.position
          .create(position)
          .then(position => {
            this.refreshTreeList();
            if (!position.customerId) {
              const setting = new Setting();
              setting.name = `dashboard.position-layout.${position.id}`;
              setting.group = 'dashboard';
              setting.valueType = SettingValueType.MultiChoice;
              setting.position = 0;
              setting.groupPosition = 0;
              this.dataProvider.settings.create(setting).then(() => {});
            }
          })
          .catch(error => {
            console.log(error);
            NotificationService.notifyError(
              this.translations['views.positions.error-creating-position'],
            );
          });
      },
      update: (positionId: string, values: Partial<Position>): any => {
        this.dataProvider.position
          .update(positionId, values)
          .then(() => this.refreshTreeList())
          .catch(error => {
            console.error(error);
            NotificationService.notifyError(
              this.translations['views.positions.error-updating-position'],
            );
          });
      },
      remove: (positionId: string): any => {
        this.dataProvider.position
          .delete(positionId)
          .then(() => {
            this.refreshTreeList();
            this.positions.find(position => {
              if (position.id === positionId && !position.customerId) {
                return this.dataProvider.settings.deleteByIdOrName(
                  `dashboard.position-layout.${positionId}`,
                );
              }
            });
          })
          .catch(error => {
            console.error(error);
            NotificationService.notifyError(
              this.translations['views.positions.error-position-not-deleted'],
            );
            throw new Error(
              this.translations['views.positions.error-deleting-position'],
            );
          });
      },
    });

    this.fetchTranslations();
  }

  expandCollapseRows(expandCollapse: 'EXPAND' | 'COLLAPSE') {
    if (expandCollapse === 'COLLAPSE') {
      this.treeList.instance.forEachNode(node => {
        this.treeList.instance.collapseRow(node.key);
      });
    } else {
      this.treeList.instance.forEachNode(node => {
        this.treeList.instance.expandRow(node.key);
      });
    }
  }

  onContentReady(event): void {
    positionColumnChooser(event);
  }

  fetchTranslations(): void {
    this.translations = this.translate.instant([
      'buttons.revert',
      'views.positions.error-creating-position',
      'views.positions.error-updating-position',
      'views.positions.error-deleting-position',
      'views.positions.error-position-not-deleted',
      'views.positions.reset-hierarchy-of-positions',
      'views.positions.reset-hierarchy-of-positions-confirm',
      'views.positions.error-reverting-hierarchy-of-positions',
    ]);
  }

  refreshTreeList(): void {
    this.forceReload = true;
    this.treeList.instance.refresh().then(() => {
      this.forceReload = false;
    });
  }

  onPositionResetClick(): void {
    this.confirmationService
      .confirm(
        ConfirmType.DELETE,
        this.translations['views.positions.reset-hierarchy-of-positions'],
        this.translations[
          'views.positions.reset-hierarchy-of-positions-confirm'
        ],
        {acceptText: this.translations['buttons.revert'], acceptIcon: 'revert'},
      )
      .then(dialogResult => {
        if (!dialogResult) {
          return;
        }

        this.dataProvider.position
          .resetPositionStructure(this.customerId)
          .then(() => this.refreshTreeList())
          .catch(error => {
            console.error(error);
            NotificationService.notifyError(
              this.translations[
                'views.positions.error-reverting-hierarchy-of-positions'
              ],
            );
          });
      });
  }

  onToolbarPreparing(event): void {
    event.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      disabled: false,
      options: {
        icon: 'revert',
        hint: this.translations['views.positions.reset-hierarchy-of-positions'],
        onClick: this.onPositionResetClick.bind(this),
      },
    });
  }

  /*
   * Prevent a node from being moved inside its child node
   * */
  onDragChange(event): void {
    const visibleRows = event.component.getVisibleRows();
    const sourceNode = event.component.getNodeByKey(event.itemData.id);
    let targetNode = visibleRows[event.toIndex].node;

    while (targetNode && targetNode.data) {
      if (targetNode.data.id === sourceNode.data.id) {
        event.cancel = true;
        break;
      }
      targetNode = targetNode.parent;
    }
  }

  onReorder(event): void {
    const visibleRows = event.component.getVisibleRows();
    const sourceData = event.itemData;
    const targetData = visibleRows[event.toIndex].data;

    if (!event.dropInsideItem) {
      sourceData.parentId = null;
    } else {
      sourceData.parentId = targetData.id;
    }

    this.dataSource.update(sourceData.id, sourceData);
  }

  onClickPermissions(event): void {
    if (!event?.row?.isNewRow && event?.row?.data?.id) {
      this.router.navigate([
        '/administration/positions/' + event?.row?.data?.id,
      ]);
    }
  }
}

@NgModule({
  declarations: [PositionComponent],
  imports: [DevExtremeModule, TranslateModule],
  exports: [PositionComponent],
})
export class PositionModule {}
