import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ClientSideErrorConfDialogComponent} from './client-side-error-conf-dialog/client-side-error-conf-dialog.component';
import {DialogModule} from '../modules/dialog';
import {FormModule} from '../modules';
import {ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {
  InputMultiSelectModule,
  InputSelectModule,
  InputSwitchModule,
  InputDateModule,
  InputTextModule,
} from '../components';
import {RawDataDialogComponent} from './raw-data-dialog/raw-data-dialog.component';
import {TranslationEditDialogComponent} from './translation-edit-dialog/translation-edit-dialog.component';
import {ObjectPropertyDialogComponent} from './object-property-dialog/object-property-dialog.component';
import {NewsPostEditDialogComponent} from './news-post-edit-dialog/news-post-edit-dialog.component';
import {DxTagBoxModule} from 'devextreme-angular';
import {InputRichTextModule} from '../components/input-rich-text';
import {DxoLabelModule} from 'devextreme-angular/ui/nested';

const DIALOGS = [
  ClientSideErrorConfDialogComponent,
  RawDataDialogComponent,
  TranslationEditDialogComponent,
  ObjectPropertyDialogComponent,
  NewsPostEditDialogComponent,
];
const SALESAPP_MODULES = [DialogModule, FormModule, ReactiveFormsModule];
const UI_COMPONENTS = [
  FormModule,
  InputSelectModule,
  InputMultiSelectModule,
  InputTextModule,
  InputSwitchModule,
  InputDateModule,
  InputRichTextModule,
  DxTagBoxModule,
  DxoLabelModule,
];

@NgModule({
  imports: [CommonModule, TranslateModule, ...SALESAPP_MODULES, UI_COMPONENTS],
  declarations: [...DIALOGS],
  exports: [...DIALOGS],
})
export class AppDialogsModule {}
