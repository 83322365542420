import * as uuid from 'uuid';
import {ProductCategory} from './product-category.model';
import {Currency} from './currency.model';
import {GenericListItem} from './generic-list.model';
import {Unit} from './unit.model';
import {Customer} from './customer.model';

export class Product {
  id: string;
  uid: string;
  version: number;
  name: string;
  description?: string;
  customerId: string;
  categoryId: string;
  category: ProductCategory;
  price?: number;
  currencyId?: string;
  currency?: Currency;
  picture?: string;
  unitId?: string;
  unit?: Unit;
  eanCode?: string;
  customerCode?: string;
  unitsPerPackage?: number;
  type?: string;
  productType?: GenericListItem;
  enabled: boolean;
  enabledSampling: boolean;
  enabledOrders: boolean;
  enabledPersonalArrangement: boolean;
  productPictureUrl?: string;
  globCodes: string[];
  customer?: Customer;
  extendedProperties: any;

  [key: string]: any;

  constructor() {
    this.id = uuid.v4();
    this.name = null;
    this.description = null;
    this.categoryId = null;
    this.price = null;
    this.currencyId = null;
    this.picture = null;
    this.unitId = null;
    this.eanCode = null;
    this.customerCode = null;
    this.unitsPerPackage = null;
    this.type = null;
    this.enabled = false;
    this.enabledSampling = false;
    this.enabledOrders = false;
    this.enabledPersonalArrangement = false;
  }
}
