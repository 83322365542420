import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-loading-container',
  templateUrl: './loading-container.component.html',
  styleUrls: ['./loading-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingContainerComponent implements OnInit {
  @Input() loading: boolean;
  @Input() showLogo: boolean;
  @Input() reverse: boolean;

  constructor() {}

  ngOnInit(): void {}
}
