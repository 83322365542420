import {ICommentDataService} from '../../interfaces/data-service';
import {Comment} from '../../models';
import {CommentHttpService} from '../http';
import {CommentOfflineService} from '../offline';
import {BaseCrudDataService} from './base.data-service';

export class CommentDataService extends BaseCrudDataService<
  Comment,
  ICommentDataService
> {
  constructor(
    onlineService: CommentHttpService,
    offlineService: CommentOfflineService,
  ) {
    super(onlineService, offlineService);
  }

  public getCommentListByObjectId(objectId: string): Promise<Comment[]> {
    return this.service.getCommentListByObjectId(objectId);
  }

  public deleteCommentsForObject(objectId: string): Promise<void> {
    return this.service.deleteCommentsForObject(objectId);
  }

  public deleteCommentList(commentIdList: string[]): Promise<void> {
    return this.service.deleteCommentList(commentIdList);
  }
}
