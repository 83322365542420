import {Injectable} from '@angular/core';
import {BaseCrudHttpService} from './base.http-service';
import {HttpClient} from '@angular/common/http';
import {
  Product,
  ProductCategory,
  ProductCategoryPositionUpdate,
} from '../../models';
import {IProductCategoryDataService} from '../../interfaces/data-service';

@Injectable()
export class ProductCategoryHttpService
  extends BaseCrudHttpService<ProductCategory>
  implements IProductCategoryDataService
{
  constructor(http: HttpClient) {
    super(http, '/product-category');
  }

  public getForCustomer(customerId: string): Promise<ProductCategory[]> {
    return this.GET<ProductCategory[]>('/product-category', {
      params: {customerId},
    });
  }

  public getProductsInCategory(categoryId: string): Promise<Product[]> {
    return this.GET<Product[]>(`product-category/${categoryId}/products`);
  }

  public checkIfCategoryCanBeDeleted(categoryId: string): Promise<number> {
    return this.GET<number>(`product-category/${categoryId}/count`);
  }

  public getProccesProgress(id: string) {
    return this.GET(`/product-category/progress/${id}`);
  }

  public updateProductCategoryPosition(
    pcPositionList: ProductCategoryPositionUpdate[],
  ) {
    return this.POST(`/product-category/update-positions`, pcPositionList);
  }
}
