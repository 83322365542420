import {
  ProjectWrapper,
  QuestionnaireWrapper,
  QuestionWrapper,
  StoreWrapper,
  DateTimeWrapper,
  TodoActionWrapper,
  TodoListWrapper,
  UserWrapper,
  VisitWrapper,
  PromoActionWrapper,
} from './wrappers.model';

/**
 * Base class for all contexts.
 */
export abstract class ContextBase {
  /**
   * Time when the context was created.
   */
  time: DateTimeWrapper;
  /**
   * The current user.
   */
  user: UserWrapper;
}

/**
 * Base class for visit related contexts.
 */
export abstract class ContextVisitBase extends ContextBase {
  /**
   * The previous visit of the same store and the same project.
   */
  previousVisit?: VisitWrapper;
  /**
   * The current visit being executed.
   */
  visit: VisitWrapper;
  /**
   * The project the visit belongs to.
   */
  project: ProjectWrapper;
  /**
   * The store of the visit.
   */
  store: StoreWrapper;
}

/**
 * Context - carrying out a visit.
 */
export class ContextTodoList extends ContextVisitBase {
  /**
   * The current TODO action.
   */
  todoAction: TodoActionWrapper;
  /**
   * The TODO list of the visit.
   */
  todoList: TodoListWrapper;
}

/**
 * Context - answering a question in a questionnaire.
 */
export class ContextQuestionnaire extends ContextTodoList {
  /**
   * The current question being answered.
   */
  question: QuestionWrapper;
  /**
   * The questionnaire the question belongs to.
   */
  questionnaire: QuestionnaireWrapper;
  /**
   * The root level questions of the questionnaire (tree).
   */
  questions: QuestionWrapper[];
  /**
   * Promo action associated with the questionnaire.
   */
  promoAction?: PromoActionWrapper;
}
