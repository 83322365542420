import {EventEmitter, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {DeferredPromise} from './deffered-promise';
import {
  ConnectivityEvent,
  Event,
  EventName,
  FirebaseIdEvent,
  LocationEvent,
  PushNotificationEvent,
} from './event-model';
import {
  PhotoLibraryRequest,
  Request,
  RequestName,
  RequestParams,
  ResamplingOptions,
  RotationOption,
} from './request-model';
import {
  AppInfoResult,
  KeyValuePairs,
  LocationResponseResult,
  PermissionsResult,
  PhotoFileResult,
  PhotoResult,
  Response,
  RotationResult,
  StorageSizeResult,
} from './response-model';

export enum AppPlatform {
  AndroidApp,
  IosApp,
  WebBrowser,
}

@Injectable()
export class AppInterfaceService {
  private DEFAULT_PHOTO_MAX_WIDTH = 1200;
  private DEFAULT_PHOTO_MAX_HEIGHT = 1200;
  private DEFAULT_JPEG_QUALITY = 70;

  private readonly _promiseMap: Map<string, DeferredPromise>;
  private readonly _platform: AppPlatform;

  // eventEmitters
  private readonly appInfoEventEmitter = new EventEmitter<any>();
  private readonly connectivityEventEmitter = new EventEmitter<any>();
  private readonly locationEventEmitter = new EventEmitter<any>();
  private readonly pushNotificationEventEmitter = new EventEmitter<any>();
  private readonly newVersionEventEmitter = new EventEmitter<any>();
  private readonly appWillBecomeInactiveEventEmitter = new EventEmitter<any>();
  private readonly appDidBecomeActiveEventEmitter = new EventEmitter<any>();

  constructor() {
    this._promiseMap = new Map<string, DeferredPromise>();

    // Check which mobile platform are we currently on
    if ((window as any).webkit?.messageHandlers?.salesapp?.postMessage) {
      this._platform = AppPlatform.IosApp;
    } else if ((window as any).Android?.request) {
      this._platform = AppPlatform.AndroidApp;
    } else {
      this._platform = AppPlatform.WebBrowser;
    }
  }

  public get platform(): AppPlatform {
    return this._platform;
  }

  // Delete after finish of testing and delete web interface
  public getPromiseMap(): Map<string, DeferredPromise> {
    return this._promiseMap;
  }

  /**
   * Sends client message to native application.
   * @param {string} msg - message to send to native application
   */
  public clientMessage(msg: string): Promise<void> {
    return this.request('clientMessage', {
      message: msg,
    });
  }

  /**
   * Request application info.
   * @see https://github.com/retrixhouse/salesapp-client/wiki/JSON-get-app-info
   * @returns {Promise<AppInfoResult>} application information object
   */
  public getAppInfo(): Promise<AppInfoResult> {
    return this.request('getAppInfo');
  }

  /**
   * Request application permissions.
   * @see https://github.com/retrixhouse/salesapp-client/wiki/JSON-permissions
   * @returns {Promise<PermissionsResult>} permissions information object
   */
  public permissions(): Promise<PermissionsResult> {
    return this.request('permissions');
  }

  /**
   * Persist string data under a key. If a key already exists it is silently overwritten.
   * @see https://github.com/retrixhouse/salesapp-client/wiki/JSON-storage-save
   * @param {KeyValuePairs} kv - key / value pairs
   * @returns {Promise<number>} number of successfully saved keys
   */
  public storageSave(kv: KeyValuePairs): Promise<number> {
    return this.request('storageSave', kv);
  }

  /**
   * Persist string data under a key. If a key already exists value is added to existing one
   * @see https://github.com/retrixhouse/salesapp-client/wiki/JSON-storage-save-append
   * @param {KeyValuePairs} kv - key / value pairs
   * @returns {Promise<number>} number of successfully saved keys
   */
  public storageSaveAppend(kv: KeyValuePairs): Promise<number> {
    return this.request('storageSaveAppend', kv);
  }

  /**
   * Delete string data under a key or keys.
   * @see https://github.com/retrixhouse/salesapp-client/wiki/JSON-storage-delete
   * @param {string | string[]} keys - key or keys to delete
   * @returns {Promise<number>} number of successfully deleted keys
   */
  public storageDelete(keys: string | string[]): Promise<number> {
    return this.request('storageDelete', {
      keys: Array.isArray(keys) ? keys : [keys],
    });
  }

  /**
   * Load string data under a key or keys.
   * @see https://github.com/retrixhouse/salesapp-client/wiki/JSON-storage-load
   * @param {string | string[]} keys - key or keys to load
   * @param {number} offset - starting index of the first character of value string to be returned. Starting at start of value string if null.
   * @param {number} length - length of the returned string, starting at offset. Ending at end of value string if null.
   * @returns {Promise<KeyValuePairs>} key value pairs
   */
  public storageLoad(
    keys: string | string[],
    offset?: number,
    length?: number,
  ): Promise<KeyValuePairs> {
    return this.request('storageLoad', {
      keys: Array.isArray(keys) ? keys : [keys],
      offset,
      length,
    });
  }

  /**
   * Get size (in bytes) of string data under a key(s).
   * @see https://github.com/retrixhouse/salesapp-client/wiki/JSON-storage-keys-size
   * @param {string | string[]} keys - Keys to get the size of.
   * @returns {Promise<{[key: string]: number}>} - Key/size in bytes pairs of successfully loaded keys
   * @deprecated Use storageKeysInfo instead.
   */
  public storageKeysSize(
    keys: string | string[],
  ): Promise<{[key: string]: number}> {
    console.warn(
      'AppInterfaceService.storageKeysSize() is deprecated. Use AppInterfaceService.storageKeysInfo() instead.',
    );
    return this.request('storageKeysSize', {
      keys: Array.isArray(keys) ? keys : [keys],
    });
  }

  /**
   * Rotates image in local Storage.
   * @see https://github.com/retrixhouse/salesapp-client/wiki/JSON-photo-rotate
   * @param {RotationOption} params - The rotating options
   * @returns {Promise<RotationResult>} - Rotation result
   */
  public rotatePhoto(params: RotationOption): Promise<RotationResult> {
    return this.request('photoRotate', params);
  }

  /**
   * Request to delete photo storage files under a uuid.
   * @see https://github.com/retrixhouse/salesapp-client/wiki/JSON-photo-storage-delete
   * @param {string | string} uuids - UUIDs of photo storage files to delete.
   * @returns {Promise<number>} - number of successfully deleted files.
   */
  public photoStorageDelete(uuids: string[] | string): Promise<number> {
    return this.request('photoStorageDelete', {
      uuids: Array.isArray(uuids) ? uuids : [uuids],
    });
  }

  /**
   * Request to clear all keys in the photo storage.
   * @see https://github.com/retrixhouse/salesapp-client/wiki/JSON-photo-storage-clear
   * @returns {Promise<number>} - number of successfully deleted files.
   */
  public photoStorageClear(): Promise<number> {
    return this.request('photoStorageClear');
  }

  /**
   * Request to load photo storage files' binary data sizes (in bytes) under an uuid.
   * @see https://github.com/retrixhouse/salesapp-client/wiki/JSON-photo-storage-keys-size
   * @param {string[] | string} uuids - UUIDs of photo storage files to fetch sizes for
   * @returns {Promise<{[uuid: string]: number}>} - key / value (key = photo storage uuid, value = file size in bytes)
   * @deprecated Use photoStorageKeysInfo instead.
   */
  public photoStorageKeysSize(
    uuids: string[] | string,
  ): Promise<{[uuid: string]: number}> {
    console.warn(
      'AppInterfaceService.photoStorageKeysSize() is deprecated. Use AppInterfaceService.photoStorageKeysInfo() instead.',
    );
    return this.request('photoStorageKeysSize', {
      uuids: Array.isArray(uuids) ? uuids : [uuids],
    });
  }

  /**
   * Request to get all photo storage file uuids.
   * @see https://github.com/retrixhouse/salesapp-client/wiki/JSON-photo-storage-keys
   * @returns {Promise<string[]>} - set of keys in the photo storage (uuids)
   */
  public photoStorageKeys(): Promise<string[]> {
    return this.request('photoStorageKeys');
  }

  /**
   * Request to determine if photo storage files exist. If a files for given uuid exist, the response is true for the given uuid, otherwise it's false.
   * @see https://github.com/retrixhouse/salesapp-client/wiki/JSON-photo-storage-keys-exist
   * @param {string[] | string} uuids - UUIDs of photo storage files to check existence of
   * @returns {Promise<{[uuid: string]: number}>} - key / value (key = photo storage uuid, value = exists)
   */
  public photoStorageKeysExist(
    uuids: string[] | string,
  ): Promise<{[uuid: string]: boolean}> {
    return this.request('photoStorageKeysExist', {
      uuids: Array.isArray(uuids) ? uuids : [uuids],
    });
  }

  /**
   * Request to load photo storage files as base64 under an uuid.
   * @see https://github.com/retrixhouse/salesapp-client/wiki/JSON-photo-storage-load
   * @param {string | string[]} uuids - UUIDs of photo storage files to load.
   * @param {number} offset - starting index of the first character of value string to be returned. Starting at start of value string if null.
   * @param {number} length - length of the returned string, starting at offset. Ending at end of value string if null.
   * @returns {Promise<KeyValuePairs>} key value pairs
   */
  public photoStorageLoad(
    uuids: string | string[],
    offset?: number,
    length?: number,
  ): Promise<KeyValuePairs> {
    return this.request('photoStorageLoad', {
      uuids: Array.isArray(uuids) ? uuids : [uuids],
      offset,
      length,
    });
  }

  /**
   * Request to save photo storage files under uuids.
   * @see https://github.com/retrixhouse/salesapp-client/wiki/JSON-photo-storage-save
   * @param {KeyValuePairs} kv - key / value pairs
   * @returns {Promise<number>} number of successfully saved files
   */
  public photoStorageSave(kv: KeyValuePairs): Promise<number> {
    return this.request('photoStorageSave', kv);
  }

  /**
   * Append binary data to photo storage files under uuids.
   * @see https://github.com/retrixhouse/salesapp-client/wiki/JSON-photo-storage-save-append
   * @param {KeyValuePairs} kv - key / value pairs
   * @returns {Promise<number>} number of successfully saved keys
   */
  public photoStorageSaveAppend(kv: KeyValuePairs): Promise<number> {
    return this.request('photoStorageSaveAppend', kv);
  }

  /**
   * Request to rotate image in local Photo Storage.
   * @see https://github.com/retrixhouse/salesapp-client/wiki/JSON-photo-storage-rotate
   * @param {RotationOption} params - The rotating options
   * @returns {Promise<RotationResult>} - Rotation result
   */
  public photoStorageRotate(params: RotationOption): Promise<RotationResult> {
    return this.request('photoStorageRotate', params);
  }

  /**
   * Lists all available keys in the storage.
   * @see https://github.com/retrixhouse/salesapp-client/wiki/JSON-storage-keys
   * @returns {Promise<string[]>} list of all available keys in storage
   */
  public storageKeys(): Promise<string[]> {
    return this.request('storageKeys');
  }

  /**
   * Gets size / usage information about the storage.
   * @see https://github.com/retrixhouse/salesapp-client/wiki/JSON-storage-size
   * @returns {Promise<StorageSizeResult>} storage size information object
   */
  public storageSize(): Promise<StorageSizeResult> {
    return this.request('storageSize');
  }

  /**
   * Clear all keys in the storage.
   * @see https://github.com/retrixhouse/salesapp-client/wiki/JSON-storage-clear
   * @returns {Promise<number>} number of deleted keys
   */
  public storageClear(): Promise<number> {
    return this.request('storageClear');
  }

  /**
   * Capture a photo using device's built-in camera. Selecting an image from library is not allowed in this case.
   * @see https://github.com/retrixhouse/salesapp-client/wiki/JSON-photo-capture
   * @param {ResamplingOptions} params - photo resampling options
   * @returns {Promise<PhotoResult>} photo result object
   */
  public photoCapture(params: ResamplingOptions): Promise<PhotoResult> {
    if (!params.maxWidth) {
      params.maxWidth = this.DEFAULT_PHOTO_MAX_WIDTH;
    }

    if (!params.maxHeight) {
      params.maxHeight = this.DEFAULT_PHOTO_MAX_HEIGHT;
    }

    if (!params.compressionQuality) {
      params.compressionQuality = this.DEFAULT_JPEG_QUALITY;
    }

    return this.request('photoCapture', params);
  }

  /**
   * Restore a captured photo using device's built-in camera (after the client application was terminated).
   * @see https://github.com/retrixhouse/salesapp-client/wiki/JSON-photo-capture-pending
   * @param {ResamplingOptions} params - photo resampling options
   * @returns {Promise<PhotoResult>} photo result object
   */
  public photoCapturePending(params: ResamplingOptions): Promise<PhotoResult> {
    if (!params.maxWidth) {
      params.maxWidth = this.DEFAULT_PHOTO_MAX_WIDTH;
    }

    if (!params.maxHeight) {
      params.maxHeight = this.DEFAULT_PHOTO_MAX_HEIGHT;
    }

    if (!params.compressionQuality) {
      params.compressionQuality = this.DEFAULT_JPEG_QUALITY;
    }

    return this.request('photoCapturePending', params);
  }

  /**
   * Select a previously saved photo(s) from device's photo gallery.
   * @see https://github.com/retrixhouse/salesapp-client/wiki/JSON-photo-library
   * @param {PhotoLibraryRequest} params - maximal amount of images to select and photo resampling options
   * @returns {Promise<PhotoFileResult[]>} array of photo file result objects
   */
  public photoLibrary(params: PhotoLibraryRequest): Promise<PhotoFileResult[]> {
    if (!params.maxWidth) {
      params.maxWidth = this.DEFAULT_PHOTO_MAX_WIDTH;
    }

    if (!params.maxHeight) {
      params.maxHeight = this.DEFAULT_PHOTO_MAX_HEIGHT;
    }

    if (!params.compressionQuality) {
      params.compressionQuality = this.DEFAULT_JPEG_QUALITY;
    }

    return this.request('photoLibrary', params);
  }

  /**
   * Restore a photo gallery selection (after the client application was terminated).
   * @see https://github.com/retrixhouse/salesapp-client/wiki/JSON-photo-library-pending
   * @param {PhotoLibraryRequest} params - maximal amount of images to select and photo resampling options
   * @returns {Promise<PhotoFileResult[]>} array of photo file result objects
   */
  public photoLibraryPending(
    params: PhotoLibraryRequest,
  ): Promise<PhotoFileResult[]> {
    if (!params.maxWidth) {
      params.maxWidth = this.DEFAULT_PHOTO_MAX_WIDTH;
    }

    if (!params.maxHeight) {
      params.maxHeight = this.DEFAULT_PHOTO_MAX_HEIGHT;
    }

    if (!params.compressionQuality) {
      params.compressionQuality = this.DEFAULT_JPEG_QUALITY;
    }

    return this.request('photoLibraryPending', params);
  }

  /**
   * Fetch current location of the device.
   * @see https://github.com/retrixhouse/salesapp-client/wiki/JSON-location-get
   * @returns {Promise<LocationResponseResult>} location response result
   */
  public locationGet(): Promise<LocationResponseResult> {
    // console.warn(
    //   'The function locationGet is temporarily disabled! Waiting for bug fix!!!',
    // );
    // return Promise.resolve({});
    return this.request('locationGet');
  }

  /**
   * Start or stop tracking device's location.
   * @see https://github.com/retrixhouse/salesapp-client/wiki/JSON-location-track
   * @param {boolean} switcH - true = start tracking, false = stop tracking
   * @returns {Promise<boolean>} True if tracking successfully switched on or is already switched on. False if tracking successfully switched off or is already switched off.
   */
  public locationTrack(switcH: boolean): Promise<boolean> {
    return this.request('locationTrack', {
      switch: switcH,
    });
  }

  /**
   * Scan a barcode using device's built-in camera.
   * @see https://github.com/retrixhouse/salesapp-client/wiki/JSON-scan-barcode
   * @param {string[]} allowedBarcodes list of supported bar code types
   * @returns {Promise<string>} scanned barcode
   */
  public scanBarcode(allowedBarcodes?: string[]): Promise<string> {
    return this.request('scanBarcode', {
      allowedBarcodes,
    });
  }

  /**
   * Open an url in the native system browser.
   * @see https://github.com/retrixhouse/salesapp-client/wiki/JSON-open-url
   * @param {string} url - URL to open
   * @returns {Promise<void>}
   */
  public openUrl(url: string): Promise<void> {
    return this.request('openUrl', {
      url: url,
    });
  }

  /**
   * Opens application settings in a native Settings app.
   * @see https://github.com/retrixhouse/salesapp-client/wiki/JSON-open-settings
   * @returns {Promise<void>}
   */
  public openSettings() {
    return this.request('openSettings');
  }

  /**
   * Update the client as a result of user's choice if a new version is available and this is notified to the client by the app.
   * @see https://github.com/retrixhouse/salesapp-client/wiki/JSON-update-client
   * @param {string} url - URL to use to update the client
   * @returns {Promise<void>}
   */
  public updateClient(url: string): Promise<void> {
    return this.request('updateClient', {
      url: url,
    });
  }

  /**
   * Store file to application storage
   * @see https://github.com/retrixhouse/salesapp-client/wiki/JSON-filestorage-save
   * @param {string} fileName Name of the file to be created with extension.
   * @param {string} content base64-encoded string of the file data to be stored.
   * @returns {Promise<void>}
   */
  public fileStorageSave(fileName: string, content: string): Promise<void> {
    return this.request('fileStorageSave', {
      filename: fileName,
      data: content,
    });
  }

  /**
   * Append data to a file in application storage
   * @see https://github.com/retrixhouse/salesapp-client/wiki/JSON-filestorage-save-append
   * @param {string} fileName Name of the file to be created with extension.
   * @param {string} content base64-encoded string of the file data to be stored.
   * @returns {Promise<void>}
   */
  public fileStorageSaveAppend(
    fileName: string,
    content: string,
  ): Promise<void> {
    return this.request('fileStorageSaveAppend', {
      filename: fileName,
      data: content,
    });
  }

  /**
   * Display native file storage window exposing saved files
   * @see https://github.com/retrixhouse/salesapp-client/wiki/JSON-filestorage-screen
   * @returns {Promise<void>}
   */
  public fileStorageDisplay(): Promise<void> {
    return this.request('fileStorageDisplay');
  }

  /**
   * Request to update app icon badge number.
   * @see https://github.com/retrixhouse/salesapp-client/wiki/JSON-set-badge-count
   * @param {number} count
   * @returns {Promise<void>}
   */
  public setBadgeCount(count: number): Promise<void> {
    return this.request('setBadgeCount', {
      count: count,
    });
  }

  // Responses end

  // Event emitters
  public onFirebaseId(): Observable<FirebaseIdEvent> {
    return this.appInfoEventEmitter.asObservable();
  }

  public onConnectivity(): Observable<ConnectivityEvent> {
    return this.connectivityEventEmitter.asObservable();
  }

  public onLocation(): Observable<LocationEvent> {
    return this.locationEventEmitter.asObservable();
  }

  public onPushNotification(): Observable<PushNotificationEvent> {
    return this.pushNotificationEventEmitter.asObservable();
  }

  public onAppDidBecomeActive(): Observable<any> {
    return this.appDidBecomeActiveEventEmitter.asObservable();
  }

  // Event emitters end

  public response(response: Response): void {
    const promise = this._promiseMap.get(response.id);

    if (!promise) {
      console.error(`Could not find promise with id ${response.id}`);
      return;
    }

    // delete promise from map
    this._promiseMap.delete(response.id);

    // handle if there is an error
    if (response.error) {
      return promise.reject(response.error);
    }

    return promise.resolve(response.result);
  }

  public event(event: Event): void {
    switch (event.name) {
      case EventName.FirebaseId:
        this.appInfoEventEmitter.emit(event.params);
        break;
      case EventName.Connectivity:
        this.connectivityEventEmitter.emit(event.params);
        break;
      case EventName.Location:
        this.locationEventEmitter.emit(event.params);
        break;
      case EventName.PushNotification:
        this.pushNotificationEventEmitter.emit(event.params);
        break;
      case EventName.AppDidBecomeActive:
        this.appDidBecomeActiveEventEmitter.emit(event.params);
        break;
      case EventName.AppWillBecomeInactive:
        this.appWillBecomeInactiveEventEmitter.emit(event.params);
        break;
      default:
        console.error('Unsupported event', event.name);
    }
  }

  private request(name: RequestName, params?: RequestParams): Promise<any> {
    // create request and wrap it into Deferred promise, so we could change
    // promise resolve / reject function after it was created, and we do not
    // need to send callback functions to request methods
    const request = new Request(name, params);
    const promise = new DeferredPromise(request);
    this._promiseMap.set(request.id, promise);
    // add timeout function as default
    setTimeout(() => {
      this._promiseMap.delete(request.id);
      promise.reject(
        new Error('Request timeout' + JSON.stringify({name, params})),
      );
    }, 120_000); // Make timeout configurable

    // Send actual request to native application
    if (this._platform === AppPlatform.IosApp) {
      (window as any).webkit.messageHandlers.salesapp.postMessage(
        JSON.stringify(request),
      );
    } else if (this._platform === AppPlatform.AndroidApp) {
      (window as any).Android.request(JSON.stringify(request));
    } else {
      // do no send request, just let it expire
      console.error(
        'Unable to send request to native application: running in a web browser.',
      );
    }

    return promise.promise;
  }
}
