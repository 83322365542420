import {IVisitPhotosRequest} from '@retrixhouse/salesapp-shared/lib/requests';
import * as moment from 'moment/moment';

export class GetVisitPhotosRequest implements IVisitPhotosRequest {
  projectIds: string[];
  todoListIds: string[];
  todoListItemIds: string[];
  from?: Date;
  to?: Date;
  executors: string[];
  chains: string[];

  constructor() {
    this.projectIds = [];
    this.todoListItemIds = [];
    this.executors = [];
    this.chains = [];

    this.from = moment(new Date()).startOf('week').toDate();
    this.to = moment(new Date()).endOf('week').toDate();
  }
}
