<ng-container>
  <dx-form [colCount]="3" [(formData)]="range">
    <dxi-item
      [label]="{text: 'labels.projects' | translate}"
      [colSpan]="3"
      dataField="projectIds"
      [editorType]="'dxTagBox'"
      [editorOptions]="{items: projects, displayExpr: formatProject, valueExpr: 'id', showClearButton: true, searchEnabled: true}"
    >
    </dxi-item>
    <dxi-item [label]="{text: 'labels.date-from' | translate}" dataField="from" [editorType]="'dxDateBox'" [editorOptions]="{type: 'date'}"></dxi-item>
    <dxi-item [label]="{text: 'labels.date-to' | translate}" dataField="to" [editorType]="'dxDateBox'" [editorOptions]="{type: 'date'}"></dxi-item>
    <dxi-item [label]="{text: 'labels.limit' | translate}" dataField="limit" [editorType]="'dxNumberBox'" [editorOptions]="{type: 'number'}">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-item>
    <dxi-item
      [colSpan]="3"
      name="submitButton"
      itemType="button"
      horizontalAlignment="right"
      [buttonOptions]="{text: 'buttons.search' | translate, type: 'default', icon: 'fa fa-search', useSubmitBehavior: true, onClick: btnSearchClick}"
    ></dxi-item>
  </dx-form>
</ng-container>
