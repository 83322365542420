import {DIALOG_DATA, DialogRef} from '@angular/cdk/dialog';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {ObjectTypeIds} from '@retrixhouse/salesapp-shared/lib/common';
import {
  IObjectProperty,
  ValueType,
} from '@retrixhouse/salesapp-shared/lib/models';
import {InputSelectOption} from '@salesapp/components';
import {
  GenericListStorageService,
  ObjectStorageService,
} from '@salesapp/storage';
import {AutoUnsubscribe} from '@salesapp/utils/angular.utils';
import {CustomValidators} from '@salesapp/utils/reactive-form/form-validators';
import {FormControlsOf} from '@salesapp/utils/reactive-form/reactive-form.interface';
import {Observable, Subscription} from 'rxjs';
import * as uuid from 'uuid';

@Component({
  selector: 'app-object-property-dialog',
  templateUrl: './object-property-dialog.component.html',
  styleUrls: ['./object-property-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@AutoUnsubscribe()
export class ObjectPropertyDialogComponent implements OnInit {
  form: FormGroup<ObjectPropertyFormGroup>;

  valueTypeOptions: InputSelectOption[];
  genericListOptions$: Observable<InputSelectOption[]>;
  disableActions$: Observable<boolean>;

  private formChangedSubscription: Subscription;

  get isUpdate() {
    return !!this.data?.objectProperty;
  }

  constructor(
    private dialogRef: DialogRef<IObjectProperty>,
    @Inject(DIALOG_DATA) private data: ObjectPropertyDialogData,
    private formBuilder: FormBuilder,
    private objectStorageService: ObjectStorageService,
    private translateService: TranslateService,
    private genericListStorageService: GenericListStorageService,
  ) {}

  getFormControl(name: string) {
    return this.form.get(name) as FormControl;
  }

  ngOnInit() {
    this.initForm();
    this.setValueTypeOptions();
    this.genericListOptions$ =
      this.genericListStorageService.dataAsSelectOptions$;
    this.disableActions$ = this.objectStorageService.dataCommandInProgress$;
  }

  onSave() {
    const input: IObjectProperty = this.form.getRawValue();
    if (this.isUpdate) {
      this.objectStorageService
        .updateProperty({
          id: input.id,
          data: input,
        })
        .subscribe({next: () => this.dialogRef.close()});
    } else {
      this.objectStorageService
        .createProperty(input)
        .subscribe({next: () => this.dialogRef.close()});
    }
  }

  onClose() {
    this.dialogRef.close();
  }

  private initForm() {
    const form = this.formBuilder.group<ObjectPropertyFormGroup>({
      id: this.formBuilder.control(uuid.v4(), [CustomValidators.required]),
      typeId: this.formBuilder.control(this.data.objectTypeId, [
        CustomValidators.required,
      ]),
      name: this.formBuilder.control('', [CustomValidators.required]),
      valueType: this.formBuilder.control(null, [CustomValidators.required]),
      native: this.formBuilder.control(false),
      listId: this.formBuilder.control({value: null, disabled: true}),
      description: this.formBuilder.control(null),
      systemRequired: this.formBuilder.control(false, [
        CustomValidators.required,
      ]),
      readonly: this.formBuilder.control(false, [CustomValidators.required]),
    });

    if (this.data?.objectProperty) {
      form.patchValue(this.data.objectProperty);
      form.controls.valueType.disable();
      form.controls.name.disable();
    }

    this.form = form;

    this.formChangedSubscription =
      this.form.controls.valueType.valueChanges.subscribe(valueType => {
        if (
          !this.isUpdate &&
          [ValueType.SingleChoice, ValueType.MultiChoice].includes(valueType)
        ) {
          this.form.controls.listId.addValidators([CustomValidators.required]);
          this.form.controls.listId.enable({emitEvent: true});
        } else {
          this.form.controls.listId.removeValidators([
            CustomValidators.required,
          ]);
          this.form.controls.listId.disable();
          this.form.controls.listId.setValue(null, {emitEvent: true});
        }
      });
  }

  private setValueTypeOptions() {
    this.valueTypeOptions = Object.keys(ValueType)
      .filter(
        k =>
          ![
            ValueType.Color,
            ValueType.DateTime,
            ValueType.Duration,
            ValueType.Enum,
            ValueType.FileSize,
            ValueType.GenericList,
            ValueType.PictureUrl,
            ValueType.RelationToManyForeignKeys,
            ValueType.RelationToOneForeignKey,
            ValueType.Time,
          ].includes(k as ValueType),
      )
      .map(vt => {
        return {
          value: vt,
          name: this.translateService.instant(
            'value-types.' + vt.toLowerCase(),
          ),
          disabled: [
            ValueType.DateTime,
            ValueType.Duration,
            ValueType.Time,
          ].includes(vt as ValueType),
        };
      });
  }
}

export interface ObjectPropertyDialogData {
  objectProperty?: IObjectProperty;
  objectTypeId: ObjectTypeIds;
}

type ObjectPropertyFormGroup = FormControlsOf<IObjectProperty>;
