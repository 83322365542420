import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {
  InputFileModule,
  InputTextModule,
  LoadingModule,
  ObjectPermissionsModule,
} from '@salesapp/components';
import {FormModule} from '@salesapp/form';
import {CustomDirectivesModule} from '@salesapp/shared/directives';
import {DialogModule} from '../../modules/dialog';
import {GridModule} from '../../modules/grid';
import {AttachmentTemplatesModule} from '../attachment-templates';
import {ObjectAttachmentEditDialogComponent} from './components/object-attachment-edit-dialog/object-attachment-edit-dialog.component';
import {ObjectAttachmentGenerateDialogComponent} from './components/object-attachment-generate-dialog/object-attachment-generate-dialog.component';
import {ObjectAttachmentsComponent} from './components/object-attachments/object-attachments.component';
import {ObjectAttachmentStorage} from './object-attachment.storage';
import {ObjectAttachmentsGridService} from './services/object-attachments-grid.service';
import {ObjectAttachmentsService} from './services/object-attachments.service';

const SALESAPP_MODULES = [
  DialogModule,
  GridModule,
  FormModule,
  CustomDirectivesModule,
];

const SALESAPP_UI = [
  InputFileModule,
  InputTextModule,
  ObjectPermissionsModule,
  LoadingModule,
];

const SALESAPP_DOMAIN_MODULES = [AttachmentTemplatesModule];

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    ...SALESAPP_MODULES,
    ...SALESAPP_UI,
    ...SALESAPP_DOMAIN_MODULES,
  ],
  declarations: [
    ObjectAttachmentsComponent,
    ObjectAttachmentEditDialogComponent,
    ObjectAttachmentGenerateDialogComponent,
  ],
  providers: [
    ObjectAttachmentStorage,
    ObjectAttachmentsService,
    ObjectAttachmentsGridService,
  ],
  exports: [ObjectAttachmentsComponent],
})
export class ObjectAttachmentModule {}

export * from './components/object-attachments/object-attachments.component';
