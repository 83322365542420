import {IGlobalSearchDataService} from '../../interfaces/data-service';
import {GlobalSearchHttpService} from '../http';
import {GlobalSearchOfflineService} from '../offline';
import {BaseDataService} from './base.data-service';

export class GlobalSearchDataService extends BaseDataService<IGlobalSearchDataService> {
  constructor(
    onlineService: GlobalSearchHttpService,
    offlineService: GlobalSearchOfflineService,
  ) {
    super(onlineService, offlineService);
  }

  public globalSearch(searchString: string): Promise<[]> {
    return this.service.globalSearch(searchString);
  }
}
