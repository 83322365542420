import {IImexTemplateDataService} from '../../interfaces/data-service';
import {ImexFileValidationResponse} from '../../models';
import {ImexFormat, ImexTemplate} from '../../models/imex-template.model';
import {ImexTemplateHttpService} from '../http';
import {BaseReadonlyDataService} from './base.data-service';

export class ImexTemplateDataService extends BaseReadonlyDataService<
  ImexTemplate,
  IImexTemplateDataService
> {
  constructor(onlineService: ImexTemplateHttpService) {
    super(onlineService);
  }

  public uploadFileForValidation(
    file: FormData,
    templateId: string
  ): Promise<ImexFileValidationResponse> {
    return this.service.uploadFileForValidation(file, templateId);
  }

  public importFile(operationId: string, templateId: string) {
    return this.service.importFile(operationId, templateId);
  }

  public exportFile(
    imexTemplateId: string,
    imexCustomColumnsOrder: string[],
    imexFormat: ImexFormat,
    exportId: string,
    filterBuilderValue: string,
  ) {
    return this.service.exportFile(
      imexTemplateId,
      imexCustomColumnsOrder,
      imexFormat,
      exportId,
      filterBuilderValue,
    );
  }

  public getFileProcessingProgress(importExportId: string) {
    return this.service.getFileProcessingProgress(importExportId);
  }

  public getNumberOfRowsToExport(
    imexTemplateId: string,
    filterBuilderValue: string,
  ) {
    return this.service.getNumberOfRowsToExport(
      imexTemplateId,
      filterBuilderValue,
    );
  }

  public getCurrentProcessingForUser(): Promise<any> {
    return this.service.getCurrentProcessingForUser();
  }

  public getImexValidationResponse(
    operationId: string,
  ): Promise<ImexFileValidationResponse> {
    return this.service.getImexValidationResponse(operationId);
  }
}
