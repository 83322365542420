import {IStore} from '@retrixhouse/salesapp-shared/lib/models';
import {Observable} from 'rxjs';
import {StoreStorageService} from '@salesapp/storage';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  ObjectTypeIds,
  RoleNames,
} from '@retrixhouse/salesapp-shared/lib/common';
import {
  ObjectDetailDynamicFormComponent,
  ObjectDetailForm,
} from '@salesapp/shared/modules';

@Component({
  selector: 'app-store-form',
  templateUrl: './store-form.component.html',
  styleUrls: ['./store-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StoreFormComponent implements ObjectDetailForm<IStore> {
  @Input() store: Partial<IStore>;

  @Output() actionCompleted = new EventEmitter<IStore>();
  @Output() cancel = new EventEmitter<void>();

  loading: boolean = true;
  readonlyRoles = [RoleNames.StoresView];
  editRoles = [RoleNames.Stores, RoleNames.StoresEdit, RoleNames.Admin];

  actionInProgress$: Observable<boolean>;

  ObjectTypeIds = ObjectTypeIds;

  @ViewChild(ObjectDetailDynamicFormComponent)
  private objectDetailDynamicForm: ObjectDetailDynamicFormComponent;

  get isUpdate() {
    return !!this.store;
  }

  get hasChanges() {
    return this.objectDetailDynamicForm.hasChanges;
  }

  constructor(private storeStorageService: StoreStorageService) {}

  ngOnInit() {
    this.actionInProgress$ = this.storeStorageService.dataCommandInProgress$;
  }

  onAction(formData: IStore) {
    if (formData.version) {
      return this.storeStorageService
        .update({
          id: formData.id,
          data: formData,
        })
        .subscribe({
          next: data => {
            this.actionCompleted.emit(data);
          },
        });
    } else {
      return this.storeStorageService.create(formData).subscribe({
        next: data => {
          this.actionCompleted.emit(data);
        },
      });
    }
  }

  onCancel() {
    this.cancel.emit();
  }
}
