export enum ConnectionType {
  NONE = 'none',
  UNKNOWN = 'unknown',
  WIFI = 'wifi',
  CT_2G = '2G',
  CT_3G = '3G',
  CT_4G = '4G',
  CT_5G = '5G',
}

export enum ConnectionSpeed {
  OK = 'ok',
  SLOW = 'slow',
  NONE = 'none'
}
