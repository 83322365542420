import {DIALOG_DATA, DialogRef} from '@angular/cdk/dialog';
import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {ReportExternal} from '@salesapp/api';

@Component({
  selector: 'app-external-report-detail-dialog',
  templateUrl: './external-report-detail-dialog.component.html',
  styleUrls: ['./external-report-detail-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExternalReportDetailDialogComponent {
  safeUrl: SafeUrl;
  get externalReport() {
    return this.data.externalReport;
  }

  constructor(
    private dialogRef: DialogRef<void>,
    @Inject(DIALOG_DATA) private data: ExternalReportDetailDialogData,
    private sanitizer: DomSanitizer,
  ) {}

  ngOnInit() {
    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.externalReport.url,
    );
  }

  onClose() {
    this.dialogRef.close();
  }
}

export interface ExternalReportDetailDialogData {
  externalReport: ReportExternal;
}
