import {Injectable} from '@angular/core';
import {
  FILE_EXTENSION_TO_MIME_TYPE,
  MIME_TYPE_TO_FILE_EXTENSION,
  MimeTypes,
} from '@salesapp/utils/file.utils';
import {forkJoin, Observable, Observer, Subject, throwError} from 'rxjs';
import {switchMap} from 'rxjs/operators';

@Injectable()
export class FileService {
  constructor() {}

  processFiles(params: {
    files: File[];
    availableMimeTypes: MimeTypes[];
    fileFormat: 'base64';
  }) {
    const {files, availableMimeTypes, fileFormat} = params;
    const fileProcesses: Observable<string>[] = [];

    files.forEach(file => {
      fileProcesses.push(
        this.processFile({file, availableMimeTypes, fileFormat}),
      );
    });

    return forkJoin(fileProcesses);
  }

  processFile(options: {
    file: File;
    availableMimeTypes: MimeTypes[];
    fileFormat: 'base64';
  }) {
    // validate file if header signature is same as file extension and also included in avialableMimeTypes param
    return this.validateFile(options.file, options.availableMimeTypes).pipe(
      switchMap(() => {
        switch (options.fileFormat) {
          case 'base64':
            return this.fileToBase64(options.file);
          default:
            return throwError(() => new Error('Unknown file format'));
        }
      }),
    );
  }

  fileToBase64(file: File) {
    const processedFile$ = new Subject<string>();
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      const base64File = (fileReader.result as string).split(',')[1];
      processedFile$.next(base64File);
      processedFile$.complete();
    };
    fileReader.onerror = error => {
      processedFile$.error(error);
    };
    return processedFile$.asObservable();
  }

  validateFile(
    file: File,
    availableMimeTypes: MimeTypes[],
  ): Observable<{isValid: boolean}> {
    const fileExtension = this.getFileExtension(file.name);
    if (!fileExtension) {
      return throwError(() => new Error('Unknown extension file'));
    }

    if (
      !availableMimeTypes.includes(FILE_EXTENSION_TO_MIME_TYPE[fileExtension])
    ) {
      return throwError(
        () =>
          new Error(
            `Unsupported file type. Supported file types are: ${availableMimeTypes
              .map(mimeType => `.${MIME_TYPE_TO_FILE_EXTENSION[mimeType]}`)
              .join(', ')}`,
          ),
      );
    }
    const fileSignature = this.getSignature(fileExtension);
    const fileSlice = file.slice(
      fileSignature.offset,
      fileSignature.offset + fileSignature.size,
    );
    const fileReader = new FileReader();
    const fileReader$ = new Observable((observer: Observer<any>) => {
      fileReader.addEventListener('loadend', () => {
        let signature: string;
        const buffer = fileReader.result as ArrayBuffer;
        const dataView = new DataView(buffer);
        if (dataView.byteLength == 8) {
          signature =
            dataView.getUint32(0, false).toString(16) +
            dataView.getUint32(4, false).toString(16);
        } else {
          signature = dataView.getUint32(0, false).toString(16);
        }
        if (fileSignature.signatures.includes(signature.toLowerCase())) {
          observer.next({isValid: true});
        } else {
          observer.error(new Error(`Unsupported ${fileExtension} type`));
        }
        observer.complete();
      });
    });
    fileReader.readAsArrayBuffer(fileSlice);
    return fileReader$;
  }

  private getFileExtension(fileName: string) {
    const matches = fileName.match(/\.([^.]+)$/);
    return matches ? matches[1].toLowerCase() : null;
  }

  private getSignature(fileExtension: string): {
    signatures: string[];
    offset: number;
    size: number;
  } {
    switch (fileExtension) {
      case 'jpeg':
      case 'jpg':
        return {
          signatures: [
            'ffd8ffe0',
            'ffd8ffe1',
            'ffd8ffe2',
            'ffd8ffe3',
            'ffd8ffe8',
          ],
          offset: 0,
          size: 4,
        };
      case 'png':
        return {
          signatures: ['89504e47'],
          offset: 0,
          size: 4,
        };
      case 'docx':
        return {
          signatures: ['504b0304'],
          offset: 0,
          size: 4,
        };
      case 'doc':
        return {
          signatures: ['d0cf11e0a1b11ae1'],
          offset: 0,
          size: 8,
        };
      case 'pdf':
        return {
          signatures: ['25504446'],
          offset: 0,
          size: 4,
        };
      default:
        console.error('UNSUPORTED FILE EXTENSION');
        return {
          signatures: [],
          offset: 0,
          size: 4,
        };
    }
  }
}
