import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
} from '@angular/core';
import {ObjectFormInputControlTypeEnum} from '@retrixhouse/salesapp-shared/lib/models';
import {FormLayoutService} from '../../services/form-layout.service';
import {TSimpleChanges} from '@salesapp/utils/angular.utils';
import {BehaviorSubject, Observable, combineLatest} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {map} from 'rxjs/operators';
import {getTranslationMarkersForObjectProperty} from '@salesapp/utils/translation.utils';

@Component({
  selector: 'app-form-designer-control',
  templateUrl: './form-designer-control.component.html',
  styleUrls: ['./form-designer-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormDesignerControlComponent implements OnInit, OnChanges {
  @Input() type: ObjectFormInputControlTypeEnum;
  @Input() label: string;
  @Input() hint?: string;
  @Input() propertyId: string;

  label$: Observable<string>;

  ObjectFormInputControlTypeEnum = ObjectFormInputControlTypeEnum;

  private propertyId$ = new BehaviorSubject<string>(null);

  constructor(
    private formLayoutService: FormLayoutService,
    private translateService: TranslateService,
  ) {}

  ngOnChanges(changes: TSimpleChanges<FormDesignerControlComponent>): void {
    if (changes.propertyId) {
      this.propertyId$.next(changes.propertyId.currentValue);
    }
  }

  ngOnInit() {
    this.initLabel();
  }

  private initLabel() {
    this.label$ = combineLatest([
      this.formLayoutService.propertiesById$,
      this.propertyId$,
    ]).pipe(
      map(([propertiesById, propertyId]) => {
        if (this.label) {
          return this.label;
        }

        if (propertyId) {
          const property = propertiesById.get(propertyId);
          return this.translateService.instant(
            getTranslationMarkersForObjectProperty({
              objectTypeId: this.formLayoutService.objectTypeId,
              propertyName: property.name,
            }).label,
          );
        }

        return this.translateService.instant(
          'form-designer.empty-control-label',
        );
      }),
    );
  }
}
