export class TourPlanQuickEditRequest {
  tourPlanIds: string[];
  newScheduledStartDate?: Date;
  newScheduledStartOffset?: number;
  newExecutorId?: string | null;
  newExecutorPermanent?: boolean;
  newDuration?: number;

  constructor(
    tourPlanIds: string[],
    newScheduledStartDate?: Date,
    newScheduledStartOffset?: number,
    newExecutorId?: string | null,
    newExecutorPermanent?: boolean,
    newDuration?: number,
  ) {
    this.tourPlanIds = tourPlanIds;
    this.newScheduledStartDate = newScheduledStartDate;
    this.newScheduledStartOffset = newScheduledStartOffset;
    this.newExecutorId = newExecutorId;
    this.newExecutorPermanent = newExecutorPermanent;
    this.newDuration = newDuration;
  }
}
