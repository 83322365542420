<app-notice type="info"
            [title]="'entity.attachment-template.legend.list.notice.title' | translate"
            [description]="'entity.attachment-template.legend.list.notice.description' | translate"></app-notice>

<div class="context-header">
    {{'entity.attachment-template.legend.list.context.title' | translate}}
</div>

<div class="context-options">
    <div *ngFor="let option of contextOptions; trackBy: trackByIndex"
         class="context-option"
         (click)="optionSelected.emit(option)">
        <span>
            {{ option.name }}
        </span>
        <i class="fa-solid fa-chevron-right"></i>
    </div>
</div>