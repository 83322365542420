import {Injectable} from '@angular/core';
import {IReportDashboard} from '@retrixhouse/salesapp-shared/lib/models';
import {IReportDashboardDataService} from '../../interfaces/data-service/report-dashboard-data.iface';
import {BaseCrudOfflineService} from './base.offline-service';
@Injectable()
export class ReportDashboardOfflineService
  extends BaseCrudOfflineService<IReportDashboard>
  implements IReportDashboardDataService
{
  upsert(input: IReportDashboard): Promise<IReportDashboard> {
    return Promise.reject('Offline service - not yet implemented');
  }
}
